import { ajax } from 'rxjs/ajax';

import Urls from '../../../../config/urls';

export const apiGetLeaderboard = (combineId, token) => (
  ajax.getJSON(
    Urls.combines.getLeaderboard(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'leaderboard.api';
