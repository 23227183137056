export default class AlbumRelMedia {
  constructor(albumId = '', mediaId = '', isAPhoto = true) {
    this.albumId = albumId;
    this.mediaId = mediaId;
    this.ordering = 0;
    this.isAPhoto = isAPhoto;
  }

  fromApiResponse(media, albumId, isAPhoto) {
    this.ordering = media.pivot.ordering;
    this.albumId = albumId;
    this.mediaId = media.id;
    this.isAPhoto = isAPhoto;
  }
}
