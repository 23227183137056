import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';

const CellContent = ({ athlete, rank }) => (
  <div className="LeaderboardNumberCell">
    <div className="LeaderboardNumberCell_Rank">{rank}</div>
    {
      athlete.rankChange !== 0 ?
        <div className="LeaderboardNumberCell_StatChange">
          <div className="LeaderboardNumberCell_StatChangeTxt">{athlete.rankChange}</div>
          <Icon
            className={`LeaderboardNumberCell_${athlete.rankChangeDirection}Arrow`}
            iconName={`${athlete.rankChangeDirection}-arrow`}
          />
        </div> : '-'
    }
  </div>
);
CellContent.propTypes = {
  athlete: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired,
};

export default CellContent;
