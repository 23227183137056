import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { Link, Redirect } from 'react-router-dom';
import { Routes } from '../../../store/actions/ui/routes';

import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';
import { parserLower } from '../../../utils/parsers';
import { Roles } from '../../../store/actions/data/user/profile/models';

class SignIn extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      this.props.formInit();
    }
  };

  submit = () => {
    this.props.submit();
  }

  renderRedirect = () => {
    const { profile ,toRedirectUrl} = this.props;

    if (toRedirectUrl) {
      return <Redirect to={toRedirectUrl} />
    }

    if (profile.roleId === Roles.coach) {
      return <Redirect to={`/${Routes.coach}/${Routes.dashboard}`} />;
    }
    return <Redirect to={`/${Routes.profile}`} />;
  };

  render = () => {
    const { profile, loggedIn } = this.props;

    if (loggedIn && profile) {
      return this.renderRedirect();
    }
    // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
    // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg" style={{marginTop: '7%', marginBottom: '3%'}}>Membership Sign In</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model="forms.login" onSubmit={this.submit}>
              <TextControlGroup
                label="Email"
                model="forms.login.username"
                parser={parserLower}
              />
              <TextControlGroup label="Password" type="password" model="forms.login.password" />
              {
                this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{this.props.formGeneralError}</div>
                </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Sign In</button>
              </div>
            </Form>
            <div className="d-flex justify-content-center">
              <Link className="welcome-link" to={`/${Routes.welcome}/${Routes.forgot}`}>
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default SignIn;
