import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import katyFootball from '../../../assets/images/football-player.jpeg';
import vcLogo from '../../../assets/images/vc-white.png';
import { Routes } from '../../../store/actions/ui/routes';

class ErrorPage extends PureComponent {
  static propTypes = {
    errorName: PropTypes.string.isRequired,
    errorMsg: PropTypes.string.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      errorName,
      errorMsg,
    } = this.props;

    return (
      <div
        className="CodeSignUp_Backdrop"
      >
        <div className="CodeSignUp_BackdropTint" />
        <div className="CodeSignUp_Wrapper">
          <div className="ErrorPage">
            <div className="ErrorPage_Name">{errorName}</div>
            <div className="ErrorPage_Msg">{errorMsg}</div>
            <Link className="ErrorPage_Link" to={`/${Routes.welcome}`}>
              <img className="ErrorPage_Logo" src={vcLogo} alt="Virtual Combine" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
