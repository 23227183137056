import { combineReducers } from 'redux';
import {
  SIDEBAR_SHOW_MENU,
  SIDEBAR_HIDE_MENU,
  SIDEBAR_SHOW_INFO,
  SIDEBAR_HIDE_INFO,
  SIDEBAR_FOCUS_SEARCH,
  SIDEBAR_UNFOCUS_SEARCH,
  PLAYER_PROFILE_GET_DATA_SUC,
  SIDEBAR_SHOW_LOGOUT_DIALOG,
  SIDEBAR_HIDE_LOGOUT_DIALOG,
  SIDEBAR_OPEN_SHARE_DIALOG,
  SIDEBAR_CLOSE_SHARE_DIALOG, ENABLE_CALENDLY_MODAL,
} from '../../../actions/ui/uxProfile';

const isMenuShown = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_SHOW_MENU:
    case SIDEBAR_FOCUS_SEARCH:
      return true;
    case SIDEBAR_HIDE_MENU:
      return false;
    default:
      return state;
  }
};

const isCalendlyModalShown = (state = false, action) => {
  switch (action.type) {
    case ENABLE_CALENDLY_MODAL:
      return action.val;
    default:
      return state;
  }
};

const isSearchFocused = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_FOCUS_SEARCH:
      return true;
    case SIDEBAR_UNFOCUS_SEARCH:
    case SIDEBAR_HIDE_MENU:
      return false;
    default:
      return state;
  }
};

const playerData = (state = {}, action) => {
  const pData = {};
  switch (action.type) {
    case PLAYER_PROFILE_GET_DATA_SUC:
      action.data.forEach((data) => {
        pData[data.sport_id] = {
          height: data.height,
          weight: data.weight,
          position: data.position,
        };
      });
      return pData;
    default:
      return state;
  }
};

const isInfoShown = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_SHOW_INFO:
      return true;
    case SIDEBAR_HIDE_INFO:
      return false;
    default:
      return state;
  }
};

const isLogoutDialogShown = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_SHOW_LOGOUT_DIALOG:
      return true;
    case SIDEBAR_HIDE_LOGOUT_DIALOG:
      return false;
    default:
      return state;
  }
};

const shareDialogUri = (state = '', action) => {
  switch (action.type) {
    case SIDEBAR_OPEN_SHARE_DIALOG:
      return action.uri;
    case SIDEBAR_CLOSE_SHARE_DIALOG:
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  isMenuShown,
  isCalendlyModalShown,
  isSearchFocused,
  playerData,
  isInfoShown,
  isLogoutDialogShown,
  shareDialogUri,
});

export default reducer;
