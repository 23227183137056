import { connect } from 'react-redux';
import Component from '../../components/App/App';
import { getCurrentUser } from '../../store/actions/data/cognito/auth';
import { getSchoolAwardLogo } from '../../store/actions/data/awardLogo/actions';
import { routeChangedAlert, redirect , redirectUrl} from '../../store/actions/ui/routes';
import { getCurrentUuid, getCurrentUuidSchoolStyle,getCurrentSportId } from '../UxCommon/Utils';
import { selectMySchoolIds } from '../../modules/CoachWorld/+store/coachWorld';

const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  return {
  authenticating: state.data.cognito.authenticating,
  authMsg: state.data.cognito.authMsg,
  token: state.data.cognito.token,
  uuid: state.data.cognito.uuid,
  redirectTo: state.ui.app.routes.redirectTo,
  currentUuid,
  schoolStyle: getCurrentUuidSchoolStyle(state, ownProps.match),
  schoolIds: selectMySchoolIds(state, ownProps),
  sports: state.ui.app.mobile.currentSport,
}};

const mapDispatchToProps = dispatch => ({
  checkLogin: getCurrentUser(dispatch),
  schoolAwardLogo: schoolId => {
    dispatch(getSchoolAwardLogo(schoolId))
  },
  routeChangeAlert: (pathname, query) => {
    dispatch(routeChangedAlert(pathname, query));
  },
  redirect: (history, to) => {
    dispatch(redirect(history, to));
  },
  redirectUrl: (url) => {
    dispatch(redirectUrl(url));
  }
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
