import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Sports, Routes } from '../../../../store/actions/ui/routes';
import { getSportIconName } from '../../../../utils';

const SportLink = ({
  uuid,
  sportId,
  selected,
  currentPage,
  schoolId
}) => (
  <Link
    className={['SportLink', selected ? 'selected' : ''].join(' ')}
    to={`/${Routes.profile}/${uuid}/${currentPage}/${Sports[sportId]}${schoolId ? `/${schoolId}`: ''}`}
  >
    <i className={`SportLink_Icon icon-${getSportIconName(sportId)}`} />
  </Link>
);
SportLink.propTypes = {
  uuid: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default SportLink;
