import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '../../../../../UxCommon';

class VideoView extends PureComponent {
  static propTypes = {
    video: PropTypes.object.isRequired,
    onLoadCallback: PropTypes.func,
  };

  onLoad = (e) => {
    const { onLoadCallback } = this.props;
    if (onLoadCallback) {
      onLoadCallback({
        height: e.target.height,
        width: e.target.width,
      });
    }
  };

  render() {
    const {
      video,
    } = this.props;
    return (
      <React.Fragment>
        {
          !!video.processed &&
          <img
            className="UxProfile_Photos_VideoView_Img"
            src={video.getThumb()}
            alt="Thumbnail"
            onLoad={this.onLoad}
          />
        }
        {
          !video.processed &&
          <LoadingSpinner
            loading
            loadingMsg="Processing video, check back later."
            style={{ zIndex: 1 }}
          />
        }
      </React.Fragment>
    );
  }
}

export default VideoView;
