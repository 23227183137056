import { combineEpics, ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';

import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment, getTokenFragment, checkForkErrors } from '../../../../store/actions/ui/uxProfile/utils';

import { GET_REPORT, GET_REPORTS, CREATE_REPORT, GET_TEAM_REPORT, GET_ATHLETE_REPORT, GET_REPORT_RESULTS } from './report.actions';
import { apiGetReport, apiGetReports, apiCreateReport, apiGetAthleteReport, apiGetTeamReport } from './report.api';
import { Report } from './report.models';
import { getRoster } from '../roster';

const getReportEpic = action$ => (
  action$.pipe(
    ofType(GET_REPORT),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetReport(action.reportId, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getReport',
        { token },
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const sportId = result.response.combine_report_result.combineTemplate.sport_id;
        const schoolId = result.response.combine_report_result.combine.school_uuid;
        result.action.dispatch(getRoster(schoolId, sportId));
        const apiCalls = result.response.combine_report_result.teams.map(team => (
          apiGetTeamReport(result.action.reportId, team.id, result.token)
            .pipe(manualCancelApiCallFragment(
              action$,
              result.action,
              'getReportResults',
            ))
        ));
        return forkJoin(...apiCalls).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action: result.action,
                result,
              };
            }
            return {
              ...forkResults,
              action: result.action,
              result,
            };
          }),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(result);
      }
      return of(result, {
        success: true,
        action: result.action,
        result: result.result,
        results: result.succeeded,
      });
    }),
    switchMap((result) => {
      if (result.success) {

        const teams = result.results.filter(r => r.success).map(r => r.response);
        const report = result.result.response.combine_report_result;

        const columns = [];
        const banksObj = {};
        const athletes = [];
        const teamsWith = [];
        if (teams.length) {
          ['physique', 'perf', 'strength', 'hitting', 'pitching', 'stats'].forEach((bank) => {
            const { subbanks } = teams[0][bank] ? teams[0][bank] : {};
            if (subbanks) {
              Object.entries(subbanks).forEach(([key1, subbank]) => {
                Object.entries(subbank.categories).forEach(([key2, cat]) => {
                  if (!(bank in banksObj)) {
                    banksObj[bank] = true;
                  }
                  columns.push({
                    ...cat,
                    bank,
                    path: `${bank}.subbanks.${key1}.categories.${key2}`,
                  });
                });
              });
            }
          });

          [
            ['vc_score', 'VC Score'],
            ['perf', 'Performance'],
            ['strength', 'Strength'],
            ['stats', 'Stats']
          ].forEach(([bank, label]) => {
            columns.push({
              standard_test_category_name: label,
              bank: 'vc_score',
              path: `${bank}`,
            });
          });

          teams.forEach((team) => {
            team.athletes.forEach((athlete) => {
              const athletePositions = report.roster[athlete.athlete_uuid];
              if (athletePositions) {
                const reportAthlete = athletePositions.find(ap => (
                  ap.school_team_id === athlete.school_team.school_team_id
                ));
                if (reportAthlete) {
                  athletes.push({
                    ...athlete,
                    ...reportAthlete,
                    teamId: team.team_uuid,
                  });
                }
              }
            });
          });

          teams.forEach((team) => {
            const teamDetails = report.teams.find(t => t.id === team.team_uuid);
            if (teamDetails) {
              teamsWith.push({
                ...team,
                ...teamDetails,
              });
            }
          });
        }

        const banks = Object.keys(banksObj);
        return of(asyncFinishAction(result.action.type, 'getReport', {
          reportId: result.action.reportId,
          report,
          teams: teamsWith,
          banks,
          columns,
          athletes,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getReportResultsEpic = action$ => (
  action$.pipe(
    ofType(GET_REPORT_RESULTS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetTeamReport(action.reportId, action.teamId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getReportResults',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getTeamReport', {
          report: null,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const getTeamReportEpic = action$ => (
  action$.pipe(
    ofType(GET_TEAM_REPORT),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetTeamReport(action.reportId, action.teamId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getTeamReport',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getTeamReport', {
          report: result.response,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getAthleteReportEpic = action$ => (
  action$.pipe(
    ofType(GET_ATHLETE_REPORT),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetAthleteReport(action.reportId, action.athleteId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getAthleteReport',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getAthleteReport', {
          report: result.response,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getReportsEpic = action$ => (
  action$.pipe(
    ofType(GET_REPORTS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      forkJoin(...action.schoolIds.map(schoolId => (
        apiGetReports(schoolId, token).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getReports',
          { schoolId },
        ))
      ))).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        const reports = {};
        result.action.schoolIds.forEach((schoolId) => {
          reports[schoolId] = [];
        });
        result.results.forEach((result1) => {
          if (result1.response) {
            reports[result1.schoolId] = (
              result1.response.map(apiReport => Report.fromApi(apiReport))
            );
          }
        });
        return of(asyncFinishAction(result.action.type, 'getReport', {
          reports,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const createReportEpic = action$ => (
  action$.pipe(
    ofType(CREATE_REPORT),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiCreateReport(
        action.combineId,
        action.secondCombineId,
        token,
      ).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'createReport',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'createReport', {
          report: Report.fromApi(result.response),
          schoolId: result.action.schoolId,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(
  getReportEpic,
  getReportsEpic,
  createReportEpic,
  getTeamReportEpic,
  getAthleteReportEpic,
  getReportResultsEpic,
);
