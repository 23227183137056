import { connect } from 'react-redux';
import Component from './Combines.component';
import {
  getSchoolCombines,
  getCombineTemplates,
  showCreateEditDialog,
  hideCreateEditDialog,
  hideEditDialog,
  showEditDialog,
  getCombineTemplatesBySchool,
} from '../../+store/combine/combine.actions';
import { selectCoachSchoolIds, selectFilteredCombines } from '../../+store';
import { checkIfUpdateNeeded, dashboardCombinesUpdateId } from '../../+store/combine/combine.utils';
import { getReports } from '../../+store/report';
import { updateCombine, updateOrCreateCombineAndAwards } from '../../+store/combine';

const mapStateToProps = (state, ownProps) => {
  const schoolIds = selectCoachSchoolIds(state);

  const profile = state.data.user && state.data.user.profiles[state.data.cognito.uuid];
  const routeMatches = state.ui.app.routes.currentRoute.match(/dashboard\/(.*?)\/combine/);
  return {
    schoolIds,
    currentSchoolId: routeMatches && routeMatches[1] ? routeMatches[1] : 0,
    combines: selectFilteredCombines(state, ownProps),
    reports: state.modules.coachWorld.report.reports,
    athleteCombineResults: state.modules.coachWorld.combine.athleteCount,
    templates: state.modules.coachWorld.combine.combineTemplates,
    isCreateEditDialogVisible: state.modules.coachWorld.combine.isCreateEditDialogVisible,
    isEditDialogVisible: state.modules.coachWorld.combine.isEditDialogVisible,
    coachId: state.data.cognito.uuid,
    shouldUpdate: checkIfUpdateNeeded(
      state.modules.coachWorld.combine.shouldUpdate,
      dashboardCombinesUpdateId(schoolIds),
    ),
    profile,
    combineTemplate: state.modules.coachWorld.combine.combineTemplates[ownProps.match.params.schoolId],
    orgAffSports: state.data.sport.sportsOrgAff,
  };
};

const mapDispatchToProps = dispatch => ({
  getSchoolCombines: (schoolIds) => {
    dispatch(getSchoolCombines(schoolIds));
  },
  getSchoolReports: (schoolIds) => {
    dispatch(getReports(schoolIds));
  },
  getCombineTemplatesBySchool: (schoolIds) => {
    dispatch(getCombineTemplatesBySchool(schoolIds));
  },
  showCreateEditDialog: () => dispatch(showCreateEditDialog()),
  hideCreateEditDialog: () => dispatch(hideCreateEditDialog()),
  showEditDialog: () => dispatch(showEditDialog()),
  hideEditDialog: () => dispatch(hideEditDialog()),
  updateCombine: (data, combine) => dispatch(updateCombine(data, combine)),
  updateOrCreateCombineAndAwards: (data, combine, schoolId) => dispatch(updateOrCreateCombineAndAwards(data, combine, schoolId)),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
