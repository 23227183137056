import { ajax } from "rxjs/ajax";
import Urls from "../../../../config/urls";

export const apiGetAllTimeLeaderboardResults = (token, action) => (
  ajax.getJSON(
    Urls.allTimeLeaderboard.results(action),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);


export const apiGetAllTimeLeaderboardTemplate = (token, schoolId, sportId) => (
  ajax.getJSON(
    Urls.allTimeLeaderboard.template(schoolId, sportId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);

export const apiGetAllTimeLeaderboardSports = (schoolId) => (
  ajax.getJSON(
    Urls.allTimeLeaderboard.sports(schoolId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);

export const apiGetAllTimeLeaderboardGradYears = (schoolId) => (
  ajax.getJSON(
    Urls.allTimeLeaderboard.gradYears(schoolId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);

export default "allTimeLeaderboard.api";

