import React from 'react';

const VcLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
    <circle cx="28" cy="28" r="28" fill="#171717" />
    <path fill="#fff" d="M27.995 38.068c-4.186 0-7.597-3.594-7.597-8.027 0-4.422 3.411-8.026 7.597-8.026 1.446 0 2.797.429 3.94 1.168l-3.94 6.938-3.175-5.6a6.368 6.368 0 0 0-1.946 1.996l5.121 9.025 10.867-19.128h-3.08l-2.486 4.393a9.743 9.743 0 0 0-10.602 0l-2.476-4.373h-3.08l3.477 6.13c-1.767 1.946-2.853 4.582-2.853 7.477 0 5.96 4.592 10.812 10.233 10.812 2.901 0 5.519-1.277 7.38-3.334l-1.427-2.506c-1.389 1.857-3.543 3.055-5.953 3.055z" />
  </svg>
);

export default VcLogo;
