import React from 'react';
import PropTypes from 'prop-types';

const TableCornerCell = ({ className, style, children }) => (
  <td
    className={['LeaderboardTableCell', 'LeaderboardTableCornerCell', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </td>
);
TableCornerCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};

export default TableCornerCell;
