import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { actions } from 'react-redux-form';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../../../async';
import {
  closeMoveAlbumDialog,
  closeMultiDelete,
  completeMultiAlbumChangeSuccessfully,
  MARK_FAVORITE,
  MULTI_CHANGE_ALBUM_EXECUTE,
  MULTI_UPDATE_TAGS_SUC,
  SAVE_TAGS,
  UPDATE_PHOTO,
  UPDATE_VIDEO,
} from '../actions';
import {
  addMediaToAlbum,
  favoritePhotoSport,
  favoriteVideoSport,
  getAlbum,
  getPhoto,
  getVideo,
  mapPhotoApiToUi,
  mapTagDetailsApiToUi,
  mapVideoApiToUi,
  photoMarkPhotoFavoriteSuccess,
  photoMarkVideoFavoriteSuccess,
  photoMultiUpdateTagsSuccess,
  photoPhotoUpdateSuccess,
  photoVideoUpdateSuccess,
  removeMediaFromAlbum,
  unfavoritePhotoSport,
  unfavoriteVideoSport,
  updatePhoto,
  updateTag,
  updateVideo,
} from '../../../../data/photo';
import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../utils';
import { Routes } from '../../../routes';
import { mapAlbumUpdateToUi, mapPhotosVideosAlbumsApiToUi } from '../../../../data/photo/models';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import AlbumRelMedia from '../../../../data/models/AlbumRelMedia';
import { setBottomBanner } from '../../banners';

const formValidation = {
  'forms.expandedPhoto.caption': [
  ],
};

const asyncStartEpic = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.expandedPhoto', 'expandedPhoto'),
  switchMap((action) => {
    if (action.attrs.media.isAPhoto) {
      return of(
        asyncStartAction(UPDATE_PHOTO, 'Saving photo'),
        actions.setErrors('forms.expandedPhoto', { general: '' }),
      );
    }
    return of(
      asyncStartAction(UPDATE_VIDEO, 'Saving video'),
      actions.setErrors('forms.expandedPhoto', { general: '' }),
    );
  }),
);

// SAVE_TAGS
const multiUpdateTagEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(SAVE_TAGS),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCalls = [];
      action.tags.forEach((tag) => {
        apiCalls.push(updateTag(tag.id, token, { label: tag.val, athlete_id: uuid() }).pipe(
          map(response => ({ success: true, tag: mapTagDetailsApiToUi(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, () => action.uuid)),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(MULTI_UPDATE_TAGS_SUC, 'saveTags', error),
          })),
        ));
      });
      return forkJoin(...apiCalls).pipe(map((forkResults) => {
        const failed = [];
        forkResults.forEach((result) => {
          if (!result.success) {
            if (result.actions) {
              result.actions.forEach(a => failed.push(a));
            } else {
              failed.push(result.action);
            }
          }
        });
        if (failed.length) {
          return {
            success: false,
            actions: failed,
          };
        }
        return {
          action,
          success: true,
          mediaResults: forkResults,
        };
      }));
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          photoMultiUpdateTagsSuccess(result.mediaResults.map(x => x.tag), uuid()),
          asyncFinishAction(
            SAVE_TAGS,
            'saveTags',
          ),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};

export const MULTI_DELETE_EXECUTE = 'photosVideos.multiDelete.execute';
const multiChangeAlbumEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(MULTI_CHANGE_ALBUM_EXECUTE),
    getTokenFragment(),
    switchMap(({ action, token }) => forkJoin(...action.mediaList
      .map((media) => {
        if (!media.oldAlbumId) {
          return of({ media, success: true });
        }
        return removeMediaFromAlbum(
          token,
          media.oldAlbumId,
          media.id,
          media.isAPhoto,
        ).pipe(
          map(() => ({ media, success: true })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos,  uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            actions: [
              asyncErrorAction(
                MULTI_DELETE_EXECUTE,
                `add${media.isAPhoto ? 'Photo' : 'Video'}ToAlbum`,
                error,
              ),
              actions.setErrors(
                'forms.expandedPhoto',
                { general: `Uh oh, there was an error saving your ${media.isAPhoto ? 'photo' : 'video'}` },
              ),
            ],
          })),
        );
      })).pipe(
      checkForkErrors(),
      map((forkResults) => {
        if (forkResults.success) {
          return {
            action,
            token,
            success: true,
            mediaResults: forkResults.results,
          };
        }
        return forkResults;
      }),
    )),
    switchMap((forkResults) => {
      if (forkResults.success) {
        return forkJoin(...forkResults.mediaResults.map((mediaI) => {
          const { media } = mediaI;
          let albumMedia;
          if (media.isAPhoto) {
            albumMedia = {
              photo_id: media.id,
              ordering: 0,
              athlete_id: uuid(),
            };
          } else {
            albumMedia = {
              video_id: media.id,
              ordering: 0,
              athlete_id: uuid(),
            };
          }
          return addMediaToAlbum(
            forkResults.token,
            media.newAlbumId,
            media.id,
            albumMedia,
            media.isAPhoto,
          ).pipe(
            map(response => ({ media, album: response, success: true })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              actions: [
                asyncErrorAction(
                  forkResults.incoming.asyncModel,
                  `add${forkResults.incoming.media.isAPhoto ? 'Photo' : 'Video'}ToAlbum`,
                  error,
                ),
                actions.setErrors(
                  'forms.expandedPhoto',
                  { general: `Uh oh, there was an error saving your ${forkResults.incoming.media.isAPhoto ? 'photo' : 'video'}` },
                ),
              ],
            })),
          );
        })).pipe(
          checkForkErrors(),
          map((results) => {
            if (results.success) {
              return {
                success: true,
                mediaResults: results.results,
              };
            }
            return results;
          }),
        );
      }
      return of(forkResults);
    }),
    switchMap((result) => {
      if (result.success) {
        const albumRelMedia = [];
        let id = '';
        let albumName = '';
        result.mediaResults.forEach((res) => {
          const arm = new AlbumRelMedia(res.media.newAlbumId, res.media.id, res.media.isAPhoto);
          id = res.media.newAlbumId;
          albumName = res.album.title;
          albumRelMedia.push(arm);
        });
        return of(
          completeMultiAlbumChangeSuccessfully(result.mediaResults, uuid(), albumRelMedia),
          asyncFinishAction(
            MULTI_CHANGE_ALBUM_EXECUTE,
            'multiDelete',
            { id, uuid: uuid(), successMessage: `All media has been moved to ${albumName} successfully` },
          ),
          closeMoveAlbumDialog(),
          closeMultiDelete(),
          setBottomBanner(null),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};


export const addToAlbumEpicFragment = (action$, state$) => (source) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return source.pipe(
    switchMap((incoming) => {
      if (incoming.success) {
        if (incoming.noAlbumChange) {
          return of(incoming);
        }
        if (!incoming.media.albumIds.length) {
          return of(incoming);
        }
        return forkJoin(...incoming.media.albumIds.map(albumId => (
          removeMediaFromAlbum(
            incoming.token,
            albumId,
            incoming.media.id,
            incoming.media.isAPhoto,
            uuid()
          ).pipe(
            map(() => ({ success: true })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              actions: [
                asyncErrorAction(
                  incoming.asyncModel,
                  `add${incoming.media.isAPhoto ? 'Photo' : 'Video'}ToAlbum`,
                  error,
                ),
                actions.setErrors(
                  'forms.expandedPhoto',
                  { general: `Uh oh, there was an error saving your ${incoming.media.isAPhoto ? 'photo' : 'video'}` },
                ),
              ],
            })),
          )
        ))).pipe(
          checkForkErrors(),
          map(results => (results.success ? incoming : results)),
        );
      }
      return of(incoming);
    }),
    switchMap((incoming) => {
      if (incoming.success) {
        if (incoming.noAlbumChange) {
          return of(incoming);
        }
        if (incoming.form.album === '') {
          return of({ ...incoming, album: null });
        }
        let albumMedia;
        if (incoming.media.isAPhoto) {
          albumMedia = {
            photo_id: incoming.media.id,
            ordering: 0,
            athlete_id: uuid(),
          };
        } else {
          albumMedia = {
            video_id: incoming.media.id,
            ordering: 0,
            athlete_id: uuid(),
          };
        }
        if (incoming.form.album && incoming.form.album !== Routes.noAlbum) {
          return addMediaToAlbum(
            incoming.token,
            incoming.form.album,
            incoming.media.id,
            albumMedia,
            incoming.media.isAPhoto,
          ).pipe(
            map(response => ({...incoming, album: response})),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              actions: [
                asyncErrorAction(
                  incoming.asyncModel,
                  `add${incoming.media.isAPhoto ? 'Photo' : 'Video'}ToAlbum`,
                  error,
                ),
                actions.setErrors(
                  'forms.expandedPhoto',
                  {general: `Uh oh, there was an error saving your ${incoming.media.isAPhoto ? 'photo' : 'video'}`},
                ),
              ],
            })),
          );
        }
      }
      return of(incoming);
    }),
    switchMap((results) => {
      if (results.success) {
        if (results.noAlbumChange) {
          return of(results);
        }
        if (!results.album) {
          return of(results);
        }
        return getAlbum(results.album.id, results.token).pipe(
          map(response => ({ ...results, album: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(results.asyncModel, 'getAlbumDetails', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        if (results.noAlbumChange) {
          return of(results);
        }
        const photos = state$.value.data.photo.photos[results.uuid] || [];
        const videos = state$.value.data.photo.videos[results.uuid] || [];
        const { albums } = mapPhotosVideosAlbumsApiToUi(
          [],
          [],
          results.album ? [results.album] : [],
          [],
          [results.media, ...photos, ...videos],
        );
        return of(Object.assign({}, results, {
          media: results.media,
          album: albums.length ? albums[0] : null,
        }));
      }
      return of(results);
    }),
  );
};

const checkAlbumChange = (newAlbumId, albumIds) => (
  ((albumIds.length === 1) &&
    (newAlbumId === albumIds[0]))
  || !newAlbumId
);

const submitMediaEpic = (action$, state$) => {
  const form = () => state$.value.forms.expandedPhoto;
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const currentSportId = () => state$.value.ui.app.routes.currentSportId;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    isPendingIsValid(state$, 'forms.expandedPhoto', 'expandedPhoto'),
    filter(action => !!action.attrs),
    getTokenFragment(),
    map(({ action, token }) => ({
      asyncModel: action.attrs.media.isAPhoto ? UPDATE_PHOTO : UPDATE_VIDEO,
      uuid: uuid(),
      token,
      form: form(),
      media: action.attrs.media,
      isAPhoto: action.attrs.media.isAPhoto,
      success: true,
      oldAbumIds: action.attrs.media.albumIds.slice(),
      noAlbumChange: checkAlbumChange(form().album, action.attrs.media.albumIds),
      outer: action,
    })),
    addToAlbumEpicFragment(action$, state$),
    switchMap((results) => {
      if (results.success) {
        const apiCall = results.isAPhoto ? updatePhoto : updateVideo;
        const { outer, ...otherResults } = results;
        return apiCall(
          results.media.id,
          results.token,
          { caption: form().caption, athlete_id: uuid() },
        ).pipe(
          map(() => ({ action: outer, ...otherResults })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            actions: [
              asyncErrorAction(results.asyncModel, `update${results.isAPhoto ? 'Photo' : 'Video'}`, error),
              actions.setErrors('forms.expandedPhoto', { general: 'Uh oh, there was an error saving your photo' }),
            ],
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const apiCall = results.media.isAPhoto ? getPhoto : getVideo;
        const mapFunc = results.media.isAPhoto ? mapPhotoApiToUi : mapVideoApiToUi;
        return apiCall(
          results.token,
          results.media.id,
        ).pipe(
          map(response => ({ ...results, newMedia: mapFunc(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(results.action.type, 'updatePhoto', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const successAction = results.isAPhoto ? photoPhotoUpdateSuccess : photoVideoUpdateSuccess;
        return of(
          successAction(
            results.newMedia,
            results.noAlbumChange,
            results.album,
            results.oldAbumIds
              .map(albumId => state$.value.data.photo.albums[results.uuid]
                .find(album => album.id === albumId)),
            uuid(),
            mapAlbumUpdateToUi(
              results.newMedia.id, results.album && results.album.id,
              results.isAPhoto,
            ),
          ),
          asyncFinishAction(results.asyncModel, 'updatePhoto', {
            uuid: results.uuid,
            sport: currentSportId()
          }),
        );
      }
      return of(...results.actions);
    }),
  );
};

// diff media.favorites and sportIds
// if deletes do deletes
// if adds do adds
// if all to all
const markFavoriteEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(MARK_FAVORITE),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCalls = [];
      Object.entries(action.sportIds).forEach(([sportId, fav]) => {
        if (sportId !== 0) {
          if (action.media.favorites) {
            if (action.media.favorites[sportId]) {
              if (!fav) {
                // delete
                const apiCall = action.isAPhoto ? unfavoritePhotoSport : unfavoriteVideoSport;
                apiCalls.push(apiCall(action.media.id, sportId, token, uuid()).pipe(
                  map(response => ({ success: true, response })),
                  takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                  ajaxErrorHandlerEpicFragment(),
                  catchError(error => of({
                    success: false,
                    action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
                  })),
                ));
              }
            } else if (fav) {
              // add
              const apiCall = action.isAPhoto ? favoritePhotoSport : favoriteVideoSport;
              apiCalls.push(apiCall(action.media.id, sportId, token, isCoach() ? { athlete_id: uuid() } : {}).pipe(
                map(response => ({ success: true, response })),
                takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                ajaxErrorHandlerEpicFragment(),
                catchError(error => of({
                  success: false,
                  action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
                })),
              ));
            }
          } else if (fav) {
            // add
            const apiCall = action.isAPhoto ? favoritePhotoSport : favoriteVideoSport;
            apiCalls.push(apiCall(action.media.id, sportId, token, isCoach() ? { athlete_id: uuid() } : {}).pipe(
              map(response => ({ success: true, response })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
              })),
            ));
          }
        }
      });
      if (action.sportIds[0]) {
        if (!action.media.favorites || !action.media.favorites[0]) {
          // set favorite
          const apiCall = action.isAPhoto ? updatePhoto : updateVideo;
          apiCalls.push(apiCall(
            action.media.id,
            token,
            { favorite: true, athlete_id: uuid() },
          ).pipe(
            map(response => ({ success: true, response })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
            })),
          ));
        }
      } else if (action.media.favorites && action.media.favorites[0]) {
        // unset favorite
        const apiCall = action.isAPhoto ? updatePhoto : updateVideo;
        apiCalls.push(apiCall(
          action.media.id,
          token,
          { favorite: false, athlete_id: uuid() },
        ).pipe(
          map(response => ({ success: true, response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
          })),
        ));
      }
      if (apiCalls.length) {
        return forkJoin(...apiCalls).pipe(
          checkForkErrors(),
          map((results) => {
            if (results.success) {
              return {
                success: true,
                action,
                token,
              };
            }
            return results;
          }),
        );
      }
      return of({ success: true, action, token });
    }),
    switchMap((results) => {
      if (results.success) {
        const apiCall = results.action.media.isAPhoto ? getPhoto : getVideo;
        const mapFunc = results.action.media.isAPhoto ? mapPhotoApiToUi : mapVideoApiToUi;
        return apiCall(
          results.token,
          results.action.media.id,
        ).pipe(
          map(response => ({ ...results, media: mapFunc(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(MARK_FAVORITE, 'markFavorite', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const updateAction = results.action.isAPhoto ?
          photoMarkPhotoFavoriteSuccess :
          photoMarkVideoFavoriteSuccess;
        return of(
          updateAction(results.media, uuid()),
          asyncFinishAction(MARK_FAVORITE, 'markFavorite'),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const updateEpics = combineEpics(
  submitMediaEpic,
  asyncStartEpic,
  validationOnSubmitEpicFactory(formValidation, 'forms.expandedPhoto', 'expandedPhoto'),
  validationFailedEpicFactory('forms.expandedPhoto', 'expandedPhoto'),
  ...formValidationEpicsFactory(formValidation, 'expandedPhoto'),
  markFavoriteEpic,
  multiUpdateTagEpic,
  multiChangeAlbumEpic,
);

export const dummy = {};
