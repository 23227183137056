import React from 'react';
import {Link} from "react-router-dom";
import  PropTypes  from 'prop-types'
import {Routes, Sports} from "../../../../../store/actions/ui/routes";
import ExternalProfileLogo from './ExternalProfileLogo';
import useHasMembership from "../../../../../hooks/useHasMembership";

const ExternalProfileCard = ( props ) => {
 const {externalProfile ,match,currentSportId, isMyProfile }  = props;
 const hasMembership = useHasMembership(match);
 let currentSportExternalProfile  =
 externalProfile ? externalProfile.filter( ep => ep.sport_id ===  currentSportId ) : [];
  return (
    <div className="external-profile-key">
      <div className ="row vc-side-profile-list prospect_external">
        {currentSportExternalProfile && currentSportExternalProfile.map( (res) =>
          (
            <div  key = {res.id}>
              <Link to={{ pathname: res.url}} target = "_blank" rel='noopener noreferrer'>
                <ExternalProfileLogo
                company = {res.company}
                />
              </Link>
            </div>
          )
         )}
      </div>
      { (isMyProfile) &&
        <Link
          to = {`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.externalProfile}/${Sports[currentSportId]}${match.params.schoolId ? `/${match.params.schoolId}`: ''}`}
          className={[
            'UxCommon_Form_Button',
            'UxEditProfile_MoreActions_Button',
            'add-external-btn',
           !hasMembership ?  'External_Profile_Disabled' : ''
          ].join(' ')}
          id="forms.userSport.submit"
          type="submit">
           External Profile
        </Link>
      }


    </div>
  )
}

ExternalProfileCard.proptypes = {
  externalProfile: PropTypes.object,
  match: PropTypes.object,
  currentSportId: PropTypes.number,
  isMyProfile: PropTypes.bool.isRequired,
}

export default ExternalProfileCard;
