import moment from 'moment';
import {BANKORDER} from "./combine.constants";

// comment: ""
// result: "729"
// standard_test_object_id: 101
// test_id: 618

// I need to know
// standard_test_object_id
// test_id
// result
// standard_test_category.name
// standard_test_category.

export class BulkSuccessData {
  static fromApi(response) {
    return response.map((dt) => {
      const testResult = {
        ...dt.testResult,
        isLaser: dt.testResult.laser_bool,
      };
      return {
        ...dt,
        testResult,
      };
    });
  }
}

export class StandardFormat {
  static fromApi(response) {
    const format = new StandardFormat();
    format.code = response.code;
    format.description = response.description;
    format.id = response.id;
    format.orderNum = response.orderNum;
    return format;
  }
}

export class CombineColumn {
  static fromApi(response) {
    const combineColumn = new CombineColumn();
    // eslint-disable-next-line max-len
    combineColumn.standardTestObjectId = response.standard_test_category.standard_test_objects[0].id;
    // eslint-disable-next-line max-len
    combineColumn.displayStandardUnitOfMeasureCode = response.standard_test_category.display_standard_unit_of_measure.code;
    // eslint-disable-next-line max-len
    combineColumn.standardUnitOfMeasureCode = response.standard_test_category.standard_unit_of_measure.code;
    combineColumn.testCategoryCode = response.standard_test_category.code;
    combineColumn.testCategoryName = response.standard_test_category.name;
    // eslint-disable-next-line max-len
    combineColumn.testBankCode = response.standard_test_category.standard_test_objects[0].standard_test_subbank.standard_test_bank.code;
    combineColumn.awardable = response.standard_test_category.awardable === 1;
    combineColumn.standardFormat = StandardFormat
      .fromApi(response.standard_test_category.standard_stat_format);
    combineColumn.isDashr = response.standard_test_category.is_dashr;
    combineColumn.useLaser = response.standard_test_category.use_laser;
    combineColumn.useReps = response.standard_test_category.use_reps;
    combineColumn.tooltip = response.standard_test_category.tooltip_example;
    combineColumn.best = response.standard_test_category.best;
    combineColumn.comparable = !!response.standard_test_category.comparable;
    combineColumn.subbankCode = response.standard_test_category.standard_test_objects[0].standard_test_subbank.code;
    combineColumn.abbreviation = response.standard_test_category.abbreviation_name;
    combineColumn.standardTestCategoryId = response.standard_test_category_id;
    combineColumn.allLaser = response.all_laser;
    combineColumn.standardTestRange = response.standard_test_category.standard_test_range;
    return combineColumn;
  }

  static fromAName(name, bankCode, stdTestObjId, measurementCode, isVcTest = false) {
    const column = new CombineColumn();
    column.displayStandardUnitOfMeasureCode = measurementCode;
    column.standardTestObjectId = stdTestObjId;
    column.standardUnitOfMeasureCode = measurementCode;
    column.testBankCode = bankCode;
    column.testCategoryCode = name.toUpperCase();
    column.testCategoryName = name;
    const stdFormat = new StandardFormat();
    stdFormat.code = 'SCORE';
    column.standardFormat = stdFormat;
    column.best = 'max';
    column.comparable = true;
    column.abbreviation = name;
    column.isVcTest = isVcTest;
    return column;
  }
}

export const vcScoreColumnsObjConst = {
  VC_SCORE_PERFORMANCE_ID: 2212,
  VC_SCORE_STRENGTH_ID: 2213,
  VC_SCORE_VC_ID: 2214,
};

export const vcScoreColumnsObj = {
  performance: CombineColumn.fromAName('Performance', 'VC_SCORE', vcScoreColumnsObjConst.VC_SCORE_PERFORMANCE_ID, 'COUNT', true),
  strength: CombineColumn.fromAName('Strength', 'VC_SCORE', vcScoreColumnsObjConst.VC_SCORE_STRENGTH_ID, 'COUNT', true),
  vc: CombineColumn.fromAName('VC Score', 'VC_SCORE', vcScoreColumnsObjConst.VC_SCORE_VC_ID, 'COUNT', true),
};

export const vcScoreColumns = Object.values(vcScoreColumnsObj);

export class Combine {
  static fromApi(response) {
    // console.log('response', response);
    const combine = new Combine();
    combine.template = response.combine_template;
    combine.description = response.description;
    combine.name = response.name;
    combine.orderNum = response.order_num;
    combine.createdByUuid = response.created_by_uuid;
    combine.id = response.id;
    combine.schoolId = response.school_uuid;
    combine.combineTemplateId = response.combine_template_id;
    combine.awardsGeneratedBool = response.awards_generated_bool;
    combine.openDate = response.open_date ? moment(response.open_date) : null;
    combine.creatorName = response.combine_creator_name ? response.combine_creator_name : null;
    combine.endDate = response.end_date ? moment(response.end_date) : null;
    combine.closeDate = response.close_date ? moment(response.close_date)
      : moment(response.closed_at);
    combine.isOpen = !!response.is_open;
    combine.lockedTeams = response.teams;
    combine.sportId = response.sport_id;
    combine.leaderboardPrivacy = !!response.leaderboard_privacy;
    combine.isCombineCreator = response.is_combine_creator;
    // combine.stdTestCats = response._embedded.combineStandardTestCategories;
    combine.columns = [];
    const banks = {};
    if (response._embedded && response._embedded.combineStandardTestCategories) {
      const combineStandardTestCategories = response._embedded.combineStandardTestCategories.sort((a, b) => a.order_num - b.order_num);
      combineStandardTestCategories.forEach((column) => {
        const combineColumn = CombineColumn.fromApi(column);
        banks[combineColumn.testBankCode] = true;
        combine.columns.push(combineColumn);
      });
    } else if (response.combine_standard_test_categories) {
      response.combine_standard_test_categories.forEach((column) => {
        const combineColumn = CombineColumn.fromApi(column);
        banks[combineColumn.testBankCode] = true;
        combine.columns.push(combineColumn);
      });
    }
    const combineBanks = [];
    BANKORDER.forEach((bk)=>{
      if(bk in banks){
        combineBanks.push(bk)
      }
    });
    combine.banks = combineBanks;

    return combine;
  }
}

// athlete_uuid: "aeb85655-a209-4fa7-9725-2953b5838d08"
// close_date: null
// combine_id: 1
// comment: null
// created_at: "2019-03-23 19:07:32"
// created_by_uuid: "bcf80c09-ed12-42f1-bc35-cd6cfa63ad5b"
// id: 621
// is_editable: 0
// name: null
// result_date: "2018-12-31"
// updated_at: "2019-03-23 19:07:33"
// verified_at: null
// verified_by_uuid: null

export class CombineTest {
  static fromApi(response) {
    const test = new CombineTest();
    test.userId = response.athlete_uuid;
    test.closeDate = response.close_date ? moment(response.close_date) : null;
    test.comment = response.comment;
    test.createdBy = response.created_by_uuid;
    test.id = response.id;
    test.isEditable = !!response.is_editable;
    test.name = response.name;
    test.resultDate = response.result_date ? moment(response.result_date) : null;
    test.verifiedAt = response.verified_at ? moment(response.verified_at) : null;
    test.verifiedBy = response.verified_by_uuid;
    test.vedioId= response.video_Id || null;
    return test;
  }
}

// comment: null
// created_at: "2019-03-23 19:07:33"
// id: 1965
// laser_bool: 0
// multi_rep_max: 3
// reps: null
// result: 3
// result_normalized: 3.175
// standard_test_object_id: 106
// test_id: 621
// updated_at: "2019-03-23 19:07:33"

export class CombineTestResult {
  static fromApi(response) {
    const testResult = new CombineTestResult();
    testResult.comment = response.comment;
    testResult.id = response.id;
    testResult.laser = response.laser_bool;
    testResult.multiRepMax = response.multi_rep_max;
    testResult.reps = response.reps;
    testResult.result = response.result;
    testResult.resultNormalized = response.result_normalized;
    testResult.stdTestObjId = response.standard_test_object_id;
    testResult.ratedResult = response.rated_result;
    testResult.relativeStrength = response.relative_strength;
    testResult.testId = response.test_id;
    testResult.videoId = response.video_id;
    testResult.videoProcessed = response.video_processed;
    return testResult;
  }

  static fromVcScore(rating, column, testId) {
    const testResult = new CombineTestResult();
    testResult.comment = '';
    testResult.id = column.standardTestObjectId;
    testResult.laser = true;
    testResult.multiRepMax = rating;
    testResult.reps = 1;
    testResult.result = rating;
    testResult.resultNormalized = null;
    testResult.stdTestObjId = column.standardTestObjectId;
    testResult.ratedResult = null;
    testResult.relativeStrength = null;
    testResult.testId = testId;
    return testResult;
  }
}

export class CombineVcScore {
  static fromApi(response) {
    const vcScore = new CombineVcScore();
    vcScore.performance = response.performance_rating;
    vcScore.strength = response.strength_rating;
    vcScore.vc = response.vc_rating;
    return vcScore;
  }
}

export class AthleteCombineResults {
  static fromApi(response) {
    const results = new AthleteCombineResults();
    results.athleteId = response.athlete_uuid;
    if (response.test) {
      results.combineId = response.test.combine_id;
      results.test = CombineTest.fromApi(response.test);
    } else {
      response.test = null;
    }
    const testResults = {};
    if (response.test_results) {
      response.test_results.forEach((result) => {
        testResults[result.standard_test_object_id] = CombineTestResult.fromApi(result);
      });
    }

    if (response.vc_score) {
      results.vcScore = CombineVcScore.fromApi(response.vc_score);
      const testId = results.test ? results.test.id : 0;
      testResults[vcScoreColumnsObj.performance
        .standardTestObjectId] = CombineTestResult.fromVcScore(
        results.vcScore.performance,
        vcScoreColumnsObj.performance,
        testId,
      );
      testResults[vcScoreColumnsObj.strength
        .standardTestObjectId] = CombineTestResult.fromVcScore(
        results.vcScore.strength,
        vcScoreColumnsObj.strength,
        testId,
      );
      testResults[vcScoreColumnsObj.vc
        .standardTestObjectId] = CombineTestResult.fromVcScore(
        results.vcScore.vc,
        vcScoreColumnsObj.vc,
        testId,
      );
    } else {
      results.vcScore = null;
    }
    results.testResults = testResults;
    return results;
  }
}
