import { combineEpics, ofType } from 'redux-observable';
import { switchMap, map, takeUntil, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { asyncFinishAction, asyncErrorAction } from '../../../../store/actions/ui/async';
import {
  manualCancelApiCallFragment,
  getTokenFragment,
  cancelOnAction
} from '../../../../store/actions/ui/uxProfile/utils';
import { getTeamTypes, getUsersTeams, checkCode } from './codeSignUp.api';
import { GET_TEAM_TYPES, CHECK_CODE } from './codeSignUp.actions';
import { ajaxErrorHandlerEpicFragment } from '../../../../store/actions/ui/ajaxErrorHandlers';
import Urls from '../../../../config/urls';
import { registerCode } from '../accountPage/accountPage.api';

const getTeamTypeEpic = action$ => (
  action$.pipe(
    ofType(GET_TEAM_TYPES),
    switchMap(action => (
      getTeamTypes().pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getTeamTypes',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(
          result.action.type,
          'codeSignUpGetTeamTypes', {
            teamTypes: result.response,
          },
        ));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

// const getSchoolTeamsEpics = action$ => (
//   action$.pipe(
//     ofType(GET_TEAM_TYPES),
//     switchMap(action => (
//       getSchoolTeams().pipe(manualCancelApiCallFragment(
//         action$,
//         action,
//         'getTeamTypes',
//       ))
//     )),
//     switchMap((result) => {
//       if (result.success) {
//         return of(asyncFinishAction(
//           result.action.type,
//           'codeSignUpGetTeamTypes', {
//             teamTypes: result.response,
//           },
//         ));
//       }
//       if (result.actions) return of(...result.actions);
//       return of(result.action);
//     }),
//   )
// );

const checkCodeEpic = action$ => (
  action$.pipe(
    ofType(CHECK_CODE),
    getTokenFragment(),
    switchMap(({ action, token, uuid }) => (
      checkCode(action.code).pipe(
        map(response => ({
          action, success: true, response, token, uuid,
        })),
        takeUntil(cancelOnAction(action$)),
        catchError((error) => {
          if ((error.name === 'AjaxError')
            && (error.status === 500)
            && (error.request.url === Urls.schools.testCode(action.code))) {
            return of({
              success: false,
              action: asyncFinishAction(action.type, 'checkCode', {
                isValid: false,
              }),
            });
          }
          throw error;
        }),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(action.type, 'checkCodeIsValid', error, { action }),
        })),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        if (result.action.isMobile && result.action.code !== result.response.athlete_code) {
          return of({
            ...result,
            success: false,
            isValid: false,
          });
        }
        if (result.token) {
          return getUsersTeams(result.uuid, result.token).pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'checkCode',
            {
              ...result,
              codeResponse: result.response,
            },
          ));
        }
        return of({
          ...result,
          codeResponse: result.response,
        });
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        if (result.token) {
          if (!result.response.find(schoolInfo => schoolInfo.id === result.codeResponse.id)) {
            // If a user is logged in but not already signed up for a school sign them up
            return registerCode(result.token, {
              code: result.action.code,
            }).pipe(manualCancelApiCallFragment(
              action$,
              result.action,
              'codeSignUpRegisterCode',
              {
                ...result,
                userTeams: result.response,
              },
            ));
          }
        }
        return of({
          ...result,
          userTeams: result.response,
        });
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'checkCode', {
          isValid: true,
          schoolInfo: result.response.length ? result.response[1] : result.response,
        }));
      }
      if (!result.isValid) {
        return of(asyncFinishAction(result.action.type, 'checkCode', {
          isValid: false,
          notValidMsg: 'Cannot join team with coach code on mobile app.',
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(
  getTeamTypeEpic,
  checkCodeEpic,
);
