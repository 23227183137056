import {connect} from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/PhotosRow/withMembershipPhotosRow';
import {getCurrentUuid, getSelectedSport} from '../UxCommon/Utils';
import {photoVideoFavoriteLimit} from '../../config/_env';

const getFavorites = (currUuid, currSport, state) => {
  const favorites = state.data.photo.favorites[currUuid] || [];
  if (!currSport) return [];
  return favorites.filter((favorite) => {
    if (favorite.favorites) {
      return (favorite.favorites[0] ||
        favorite.favorites[currSport.sportId]) && (favorite.resizedPhotos || favorite.thumbPaths);
    }
    return false;
  }).slice(0, photoVideoFavoriteLimit);
};

const mapStateToProps = (state, ownProps) => {
  const currUuid = getCurrentUuid(state, ownProps.match);
  const currSport = getSelectedSport(state, ownProps.match);
  const favorites = getFavorites(currUuid, currSport, state);

  return {
    isMyProfile: currUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    favorites,
    loadingMsg: state.ui.uxProfile.playerProfile.photosLoadingMsg,
    loading: !!state.ui.uxProfile.playerProfile.photosLoadingMsg,
    currSport,
  };
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
