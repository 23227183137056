 import { ofType } from 'redux-observable';

import { ROUTE_CHANGED } from '../routes';

export const cancelOnRouteChange = action$ => action$.pipe(ofType(ROUTE_CHANGED));

export const accountFormInputs = [
  'first',
  'last',
  'email',
  'phone',
  'birthdate',
  'gender',
  'heightFt',
  'heightInch',
  'weight',
  'parentsName',
  'parentsEmail',
  'parentsPhone',
  'state',
  'city',
  'zipcode'
];

export const accountMobileFormInputs = [
  'first',
  'last',
  'email',
  'phone',
  'birthdate',
  'gender',
  'height',
  'weight',
  'parentsName',
  'parentsEmail',
  'parentsPhone',
  'state',
  'city',
  'zipcode'
];

export const academicsFormInputs = [
  'school',
  'grade',
  'gradYear',
  'act',
  'sat',
  'coreGpa',
  'classRank',
  'classSize',
  'schoolCredits',
  'ncaaRegistered',
  'ncaaIdNumber'

];

export const changePasswordFormInputs = [
  'oldPassword',
  'newPassword',
  'confirmNewPassword',
];

export const editSportFormInputs = [
  'aboutMe',
  'bio',
  'commits',
  'offers',
  'jerseyNumbers',
  'primary',
];
export const dummy = '';
