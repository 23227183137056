import React from 'react';

import {
  validateEmail,
  validateLength,
  validatePhone,
  validateRequired, validateSAPhone,validatePasswordLength
} from '../../../store/actions/ui/formUtils/validators';
import { passwordHelpMsg } from '../../../store/reducers/ui/welcome/utils';

export const checkConfirmPassword = (password, confirm) => {
  let error = '';
  if (password !== confirm) {
    error = 'Passwords must match';
  }
  return error ? [error] : [];
};

export const checkConfirmUsername = (username, confirmUsername) => {
  let error = '';
  if (username !== confirmUsername) {
    error = 'Emails must match';
  }
  return error ? [error] : [];
};

export const checkName = (value) => {
  if (!value) {
    return ['This field is required'];
  }
  return (!value || (value &&
      (/^[a-zA-Z0-9\s-_'"]*$/i).test(value)
      // /^[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z'][\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zA-Z' -]*$/.test(value)
    )
  )
    ? []
    : ['This field can only contain letters and spaces with _"-\' with special character'];
};

export const checkConfirmPhone = (phone, confirmPhone) => {
  let error = '';
  if (phone !== confirmPhone) {
    error = 'Phone number must match';
  }
  return error ? [error] : [];
};

export const passwordHelp = (value) => {
  const passwordValidationObject = passwordHelpMsg(value);
  return (
    <div>
      <div className="AccountPage_PasswordHelpLabel">
        {passwordValidationObject.msg}
      </div>
      {passwordValidationObject.tests.map((test, index) => (
        <div
          key={index}
          className={test.passing
                        ? 'AccountPage_PasswordHelpPassing'
                        : 'AccountPage_PasswordHelpFailing'}
        >
          {test.msg}
        </div>
            ))}
    </div>
  );
};

export const checkPassword = (value) => {
  const helps = passwordHelpMsg(value);
  const errors = helps.tests.reduce((prev, test) => {
    if (!test.passing) {
      prev.push(test.msg);
    }
    return prev;
  }, []);
  return errors;
};

export const checkRequired = (value, msg = '') => {
  const error = validateRequired(msg)(value).required;
  return error ? [error] : [];
};

export const checkPasswordEasy = (value, msg = '') => {
  const errors = [];
  const { required } = validateRequired(msg)(value);
  if (required) errors.push(required);
  const { length } = validatePasswordLength(6)(value);
  if (length) errors.push(length);
  return errors;
};

export const checkUsername = (value) => {
  const possibleErrors = [
    validateRequired()(value).required,
    validateEmail()(value).email,
  ];
  const errors = possibleErrors.reduce((prev, curr) => {
    if (curr) {
      prev.push(curr);
    }
    return prev;
  }, []);
  return errors;
};

export const checkPhone = (value) => {
  let possibleErrors;
  const countryCode = value.substr(0, value.indexOf(' '));
  let phoneNumber = '';
  if (value.indexOf(' ') !== -1) {
    phoneNumber = value.substr(value.indexOf(' ') + 1);
  }

  switch (countryCode) {
    case '+27':
      possibleErrors = [
        validateSAPhone()(phoneNumber).phone,
      ];
      break;
    default:
      possibleErrors = [
        validatePhone()(phoneNumber).phone,
      ];
  }
  return possibleErrors.reduce((prev, curr) => {
    if (curr) {
      prev.push(curr);
    }
    return prev;
  }, []);
};

export const checkComment = (value) => {
  if (!value || (value.trim()).length === 0 ) {
    return ['This field is required'];
  }

  if (value.length >= 1001) {
    return ['This field can only contain 1000 letters']
  }

};
