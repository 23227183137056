import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { SAVE_PHYSIQUE } from './physiquePage.actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === SAVE_PHYSIQUE) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === SAVE_PHYSIQUE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === SAVE_PHYSIQUE) {
        return (action.error && action.error.message) || '';
      }
      return state;
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.model === SAVE_PHYSIQUE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  errorMsg,
});

export default reducer;
