import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import Dropzone from '../../../UxCommon/Dropzone/Dropzone';
import { Line, ButtonRect, CloseButton, LinkButton, LoadingSpinner } from '../../../UxCommon';
import { Errors } from '../../../UxCommon/Form';
import AddNewCard from '../PhotoVideoList/AddNewCard/AddNewCard';
import AlbumForm from './AlbumForm/AlbumForm';
import TagsForm from './TagsForm/TagsForm';
import StagedPhotoCard from './PhotoCard/StagedPhotoCard/StagedPhotoCard';
import AlbumPhotoCard from './PhotoCard/AlbumPhotoCard/AlbumPhotoCard';
import DeleteAlbumDialog from './DeleteAlbumDialog/DeleteAlbumDialog';
import ExternalVideoDialog from '../../../../containers/UxProfile/Photos/ExternalVideo';

class AlbumDialog extends PureComponent {
  static propTypes = {
    photoList: PropTypes.array.isRequired,
    stagedMedia: PropTypes.array.isRequired,
    albums: PropTypes.array.isRequired,
    createMode: PropTypes.bool.isRequired,
    onDrop: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    getPhotoCardRrfForm: PropTypes.func.isRequired,
    getPhotoCardRrfFormModel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    rrfForm: PropTypes.object.isRequired,
    albumToEdit: PropTypes.object,
    clearNewAlbumName: PropTypes.func.isRequired,
    clearAlbumSelect: PropTypes.func.isRequired,
    formInit: PropTypes.func.isRequired,
    loadingProgress: PropTypes.object,
    stageAlbumMedia: PropTypes.func.isRequired,
    unstageNewMedia: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    onDeleteAlbumOnlyClick: PropTypes.func.isRequired,
    onDeleteEverythingClick: PropTypes.func.isRequired,
    rrfTagForm: PropTypes.object.isRequired,
    onAddTagClick: PropTypes.func.isRequired,
    onDeleteTagClick: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    selectedTags: PropTypes.array,
    allTags: PropTypes.array,
    isExternalVideoDialogOpen: PropTypes.bool.isRequired,
    onExternalVideoCancelClick: PropTypes.func.isRequired,
    onExternalVideoClick: PropTypes.func.isRequired,
    showMediaIsNotAccepted: PropTypes.func,
    tagRelMedia: PropTypes.array,
  }

  state = {
    scrollTop: 0,
    deleteDialogShown: false,
  };


  componentWillMount() {
    const { formInit, albumToEdit } = this.props;
    formInit(albumToEdit);
    this.scrollElement = document.getElementById('uxProfileRight');
    // this.scrollElement.addEventListener('scroll', this.onScroll);
    this.saveScrollTop(this.scrollElement.scrollTop);
    this.setState({
      deleteDialogShown: false,
    });
  }

  componentDidMount(): void {
    const button = document.getElementById('UxProfile_Photos_AlbumDialog_PhotosVideos');
    button.scrollIntoView();
  }

  componentWillUnmount() {
    // this.scrollElement.removeEventListener('scroll', this.onScroll);
  }

  // onScroll = (e) => {
  //   console.log(e);
  // }

  onAddPhotoVideoClick = () => {
    this.dropZoneRef.open();
  };

  onAddTagClick = () => {

  };

  onDeleteAlbumClick = () => {
    this.setState({
      deleteDialogShown: true,
    });
  }

  onCancelDeleteAlbumClick = () => {
    this.setState({
      deleteDialogShown: false,
    });
  };

  onDeleteAlbumOnlyClick = () => {
    const { onDeleteAlbumOnlyClick, albumToEdit } = this.props;
    onDeleteAlbumOnlyClick(albumToEdit);
  };

  onDeleteEverythingClick = () => {
    const { onDeleteEverythingClick, albumToEdit } = this.props;
    onDeleteEverythingClick(albumToEdit);
  };

  getPaddingTop = () => {
    const { scrollTop } = this.state;
    if (scrollTop > 112) {
      return scrollTop - 84;
    }
    return 28;
  }

  getDialogHeight = () => {
    const { scrollTop } = this.state;
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (scrollTop > 112) {
      return h - 116;
    }
    return h - 228;
  }

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  render() {
    const {
      onDrop,
      albums,
      onCloseClick,
      onSaveClick,
      // deleteAlbumDialogIsShowing,
      createMode,
      stagedMedia,
      getPhotoCardRrfForm,
      getPhotoCardRrfFormModel,
      loading,
      loadingMsg,
      rrfForm,
      clearNewAlbumName,
      clearAlbumSelect,
      loadingProgress,
      photoList,
      stageAlbumMedia,
      unstageNewMedia,
      deleteMedia,
      rrfTagForm,
      onAddTagClick,
      onDeleteTagClick,
      tags,
      selectedTags,
      allTags,
      isExternalVideoDialogOpen,
      onExternalVideoCancelClick,
      onExternalVideoClick,
      showMediaIsNotAccepted,
      tagRelMedia,
    } = this.props;
    const { deleteDialogShown } = this.state;
    return (
      <div className="UxProfile_Photos_AlbumDialog_Backdrop">
        <div className="UxProfile_Photos_AlbumDialog" style={{ height: `${this.getDialogHeight()}px` }}>
          {
            isExternalVideoDialogOpen && (
              <ExternalVideoDialog
                onCancel={onExternalVideoCancelClick}
              />
            )
          }
          <CloseButton size={10} onClick={onCloseClick} />
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
            style={{ zIndex: 20 }}
            loadingProgress={loadingProgress}
          />
          <div className="UxProfile_Photos_AlbumDialog_LeftCol" style={{ overflow: 'auto' }}>
            <Form model="forms.albumDialog">
              <AlbumForm
                rrfForm={rrfForm}
                albums={albums}
                createMode={createMode}
                clearNewAlbumName={clearNewAlbumName}
                clearAlbumSelect={clearAlbumSelect}
              />
              <Line />
              <TagsForm
                rrfForm={rrfTagForm}
                onAddTagClick={onAddTagClick}
                onDeleteTagClick={onDeleteTagClick}
                tags={tags}
              />
            </Form>
          </div>
          <div className="UxProfile_Photos_AlbumDialog_RightCol">
            {
              deleteDialogShown && (
                <DeleteAlbumDialog
                  onDeleteEverythingClick={this.onDeleteEverythingClick}
                  onDeleteAlbumOnlyClick={this.onDeleteAlbumOnlyClick}
                  onCancelClick={this.onCancelDeleteAlbumClick}
                />
              )
            }
            <Dropzone
              disableClick
              accept={['image/*', 'video/*']}
              ref={this.saveDropZoneRef}
              onDrop={onDrop}
            >
              <div className="UxProfile_Photos_AlbumDialog_PhotosVideosWrapper">
                <div className="UxProfile_Photos_AlbumDialog_PhotosVideos" id="UxProfile_Photos_AlbumDialog_PhotosVideos">
                  <AddNewCard
                    onClick={this.onAddPhotoVideoClick}
                    iconName="plus"
                    text="Add photo or video"
                  />
                  <AddNewCard
                    onClick={onExternalVideoClick}
                    iconName="link"
                    text="Add a video from YouTube or Hudl"
                  />
                  {
                    stagedMedia.filter(s => s.isNew).map(media => (
                      <StagedPhotoCard
                        key={media.id}
                        media={media}
                        getRrfForm={getPhotoCardRrfForm}
                        getRrfFormModel={getPhotoCardRrfFormModel}
                        onDeleteClick={unstageNewMedia}
                        selectedTags={selectedTags}
                        showMediaIsNotAccepted={showMediaIsNotAccepted}
                      />
                    ))
                  }
                  {
                    photoList.map(media => (
                      <AlbumPhotoCard
                        key={media.id}
                        media={media}
                        getRrfForm={getPhotoCardRrfForm}
                        getRrfFormModel={getPhotoCardRrfFormModel}
                        onDeleteClick={deleteMedia}
                        stageAlbumMedia={stageAlbumMedia}
                        alreadyStaged={!!stagedMedia.find(s => s.id === media.id)}
                        selectedTags={selectedTags}
                        allTags={allTags}
                        tagRelMedia={tagRelMedia}
                      />
                    ))
                  }
                </div>
                {
                  !rrfForm.$form.valid && (
                    <div className="UxProfile_Photos_AlbumDialog_Errors">
                      <Errors valid={rrfForm.$form.valid} errors={rrfForm.$form.errors} />
                    </div>
                  )
                }
                <div className="UxProfile_Photos_AlbumDialog_Btns" id="UxProfile_Photos_AlbumDialog_Btns">
                  {
                    !createMode && (
                      <LinkButton
                        iconName="trash"
                        onClick={this.onDeleteAlbumClick}
                        className="UxProfile_Photos_AlbumDialog_DeleteBtn"
                      >
                        Delete
                      </LinkButton>
                    )
                  }
                  <ButtonRect
                    style={{ marginLeft: 'auto', width: '100px' }}
                    className="Photos_ExpandedPhotoDialog_Button"
                    onClick={onCloseClick}
                  >
                    Cancel
                  </ButtonRect>
                  <ButtonRect
                    className="Photos_ExpandedPhotoDialog_Button primary"
                    onClick={onSaveClick}
                    style={{ width: '120px' }}
                  >
                    Save
                  </ButtonRect>
                </div>
              </div>
            </Dropzone>
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumDialog;
