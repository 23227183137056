import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ChechboxControl from '../FormControls/CheckboxControl';
import Collapsable from '../../../../components/UxCommon/Collapsable/Collapsable';
import { getFilters, setFilter, setEvent } from '../../+store/coachWorld/coachWorld.utils';


class CombineSidebarEventFilterComponent extends PureComponent {
  static propTypes = {
    filterName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  onChange = (name, currentValues) => (value) => {
    const { location, history, filterName,page } = this.props;
    const currentId = currentValues && [...currentValues] || [];
    if(value){
      currentId.push(name);
    }
    else{
      const indexOfId = currentId.indexOf(name);
      if(indexOfId>-1){
        currentId.splice(indexOfId,1);
      }
    }
    setFilter(location, history, filterName, name, value,currentId, page);
    return value;
  }

  render() {
    const {
      filters,
      filterName,
      label,
      className,
      location,
    } = this.props;

    const allFilters = getFilters(null, { location });
    let selectedValues = [], allValue =[], filter, currentValues;
    filters && filters.map((filter) => {
      const {value} = filter;
      allValue.push(value);
    });

    if (allFilters) {
      filter = allFilters.find(f => f.name === filterName);
      if (filter) {
        selectedValues = filter.selected;
      }
    }

   if(allValue && filter)
    currentValues = allValue.filter((id) => selectedValues.includes(id))

    return (
      <div className={`CoachSidebarMultiSelectCallbackFilter_Divider ${className || ''}`}>
        <Collapsable
          keepInside
          useFluidContainer
          title={label}
          topRowStyle={{ minHeight: '59px' }}
          className="CoachSidebarMultiSelectCallbackFilter"
        >
          <div />
          <div className={`CoachSidebarMultiSelectCallbackFilter_CheckboxContainer ${className || ''}`}>
            {
              filters.map(({ name, value }) => (
                <ChechboxControl
                  key={value}
                  label={name}
                  checked={selectedValues.includes(value)}
                  myOnChange={this.onChange(value,currentValues)}
                  className="filters"
                />
              ))
            }
          </div>
        </Collapsable>
      </div>
    );
  }
}

export default CombineSidebarEventFilterComponent;
