const videos = [
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb1',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Onboarding athletes with the manage roster feature',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/i7G_yiVBbg8/hqdefault.jpg' },
    embedPath: 'https://youtu.be/i7G_yiVBbg8',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb2',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Coaches Dashboard Overview',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/2B7m9x8xmJQ/hqdefault.jpg' },
    embedPath: 'https://youtu.be/2B7m9x8xmJQ',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb3',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Onboarding athlete with the team code',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/iNew75LHR9k/hqdefault.jpg' },
    embedPath: 'https://youtu.be/iNew75LHR9k',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb4',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Onboarding Coaches',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/C4Nm18l3FH8/hqdefault.jpg' },
    embedPath: 'https://youtu.be/C4Nm18l3FH8',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb5',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to adjust your rosters for the off-season',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/OnZFmQO5sTQ/hqdefault.jpg' },
    embedPath: 'https://youtu.be/OnZFmQO5sTQ',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb6',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Make sure your athlete’s grade level is correct',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/kTTNcwvxkiA/hqdefault.jpg' },
    embedPath: 'https://youtu.be/kTTNcwvxkiA',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb7',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Change team names to correct off-season/in-season groups',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/Uw9a0w7OkjE/hqdefault.jpg' },
    embedPath: 'https://youtu.be/Uw9a0w7OkjE',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb8',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Move athletes to the correct teams',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/ZSbLEMIxPEU/hqdefault.jpg' },
    embedPath: 'https://youtu.be/ZSbLEMIxPEU',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb9',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Add or remove players',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: {'1080p': 'https://i1.ytimg.com/vi/zW7PRbvNRgA/hqdefault.jpg'},
    embedPath: 'https://youtu.be/zW7PRbvNRgA',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb10',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Taking/adding roster pics with a phone/iPad',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/9REeSbnRL0A/hqdefault.jpg' },
    embedPath: 'https://youtu.be/9REeSbnRL0A',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb11',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Edit your combine template',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/s4VfP-7Qh6A/hqdefault.jpg' },
    embedPath: 'https://youtu.be/s4VfP-7Qh6A',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb12',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Run Combines',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/b-pHE44-tnE/hqdefault.jpg' },
    embedPath: 'https://youtu.be/b-pHE44-tnE',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb13',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Data Entry Tips',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/A9IJAgIolxI/hqdefault.jpg' },
    embedPath: 'https://youtu.be/A9IJAgIolxI',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb14',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Close Combines',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/AfjiVjiUh3c/hqdefault.jpg' },
    embedPath: 'https://youtu.be/AfjiVjiUh3c',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb15',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to grant/deny and execute combine awards',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/Ah_NHsAxg_c/hqdefault.jpg' },
    embedPath: 'https://youtu.be/Ah_NHsAxg_c',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb16',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to generate a report',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/A8QXnT4Px-k/hqdefault.jpg' },
    embedPath: 'https://youtu.be/A8QXnT4Px-k',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb17',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to project your leaderboard',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/zeoBr4qX_gs/hqdefault.jpg' },
    embedPath: 'https://youtu.be/zeoBr4qX_gs',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb18',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to navigate through the leaderboard',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/nW9iceoYgCY/hqdefault.jpg' },
    embedPath: 'https://youtu.be/nW9iceoYgCY',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb19',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to share the leaderboard to social media',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/pg8UpRZ6_a4/hqdefault.jpg' },
    embedPath: 'https://youtu.be/pg8UpRZ6_a4',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb20',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Finding the right school in the college directory',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/zuSefbepogQ/hqdefault.jpg' },
    embedPath: 'https://youtu.be/zuSefbepogQ',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb21',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Navigating your VC profile',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: {'1080p': 'https://i1.ytimg.com/vi/h7CqFkh6xZc/hqdefault.jpg'},
    embedPath: 'https://youtu.be/h7CqFkh6xZc',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb22',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'Adding offers/committed in Manage Rosters',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/1pzM4VqW49o/hqdefault.jpg' },
    embedPath: 'https://youtu.be/1pzM4VqW49o',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb23',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to view strength and performance data during combine testing',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/dE9LIUyp3Qo/hqdefault.jpg' },
    embedPath: 'https://youtu.be/dE9LIUyp3Qo',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb24',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to add game stats',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/k3fr-i9Ipfk/hqdefault.jpg' },
    embedPath: 'https://youtu.be/k3fr-i9Ipfk',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb25',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to upload a video from HUDL or YouTube',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/cRtS6DWyhkg/hqdefault.jpg' },
    embedPath: 'https://youtu.be/cRtS6DWyhkg',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb26',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to upload a video from your phone, tablet, or computer',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/u-I_DtluRiU/hqdefault.jpg' },
    embedPath: 'https://youtu.be/u-I_DtluRiU',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb27',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How do you add an award',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/rVjzTQjQeLo/hqdefault.jpg' },
    embedPath: 'https://youtu.be/rVjzTQjQeLo',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb28',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to share a video to Social Media',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/crseymQjwS8/hqdefault.jpg' },
    embedPath: 'https://youtu.be/crseymQjwS8',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb29',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to share combine awards to Social Media',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/38Y-Jon7RS4/hqdefault.jpg' },
    embedPath: 'https://youtu.be/38Y-Jon7RS4',
    isTutorial: true,
  },
  {
    id: 'afad75a0-2170-11ea-a422-a9a25aa46bb30',
    ownerId: null,
    createdAt: '2021-03-02 08:30:45',
    updatedAt: '2021-13-02 08:31:44',
    caption: 'How to share player data to Social Media',
    uploadComplete: true,
    processed: true,
    isAPhoto: false,
    thumbPaths: { '1080p': 'https://i1.ytimg.com/vi/2HpyvDPQsXg/hqdefault.jpg' },
    embedPath: 'https://youtu.be/2HpyvDPQsXg',
    isTutorial: true,
  },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb1',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Athlete Onboarding',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/ds-oX9Z-a6w/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/ds-oX9Z-a6w',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb2',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Coach Onboarding',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/xfh4fUC75uo/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/xfh4fUC75uo',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb3',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Coach Dashboard Overview',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/yo9e4GpMOes/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/yo9e4GpMOes',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb4',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Athlete Profile Overview',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/QrnsE2YLIx0/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/QrnsE2YLIx0',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb5',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Rosters',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/qxUD4xCQn8E/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/qxUD4xCQn8E',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb6',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Creating a Combine or Showcase Template',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/HPa_ARc1n2Y/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/HPa_ARc1n2Y',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb7',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Running a Combine or Showcase',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/8gGXtF9O2Kc/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/8gGXtF9O2Kc',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb8',
  //   ownerId: null,
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Awards',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/ibF8HLgeHMs/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/ibF8HLgeHMs',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb9',
  //   ownerId: 'acee597c-ead9-4f3b-aa96-252899354489',
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Generating Reports',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/gAT6fePemsg/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/gAT6fePemsg',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb10',
  //   ownerId: 'acee597c-ead9-4f3b-aa96-252899354489',
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Reports',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/xZg-YxsIsns/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/xZg-YxsIsns',
  //   isTutorial: true,
  // },
  // {
  //   id: 'afad75a0-2170-11ea-a422-a9a25aa46bb11',
  //   ownerId: 'acee597c-ead9-4f3b-aa96-252899354489',
  //   createdAt: '2019-12-18 08:30:45',
  //   updatedAt: '2019-12-18 08:31:44',
  //   caption: 'Leaderboards',
  //   uploadComplete: true,
  //   processed: true,
  //   isAPhoto: false,
  //   thumbPaths: { '1080p': 'https://i.ytimg.com/vi/moY4mpfEaa8/hqdefault.jpg' },
  //   embedPath: 'https://youtu.be/moY4mpfEaa8',
  //   isTutorial: true,
  // },
];

export default videos;
