import { connect } from 'react-redux';
import Component from './AthleteBase.component';
import { selectLoadingMsg, getCombineNames, selectSchoolStyle } from '../../+store/coachWorld';
import { selectFilteredReportAthletes } from '../../+store/report/report.selectors';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const { teamReports } = state.modules.coachWorld.report;

  const {
    teamId, reportId, bank, athleteId,
  } = ownProps.match.params;
  const report = state.modules.coachWorld.report.report[reportId];
  const combineNames = getCombineNames(state, reportId);
  const teamReport = teamReports
    ? teamReports.find(t => t.team_uuid === teamId)
    : null;
  const athleteReport = teamReport
    ? teamReport.athletes.find(a => a.athlete_uuid === athleteId)
    : null;
  const athletes = selectFilteredReportAthletes(state, ownProps);
  let currentAthlete = {};
  if (athletes) {
    currentAthlete = athletes.find(a => a.athlete_uuid === athleteId);
  }
  return {
    currentAthlete,
    report,
    teamReport,
    athleteReport,
    reportId,
    athleteId,
    teamId,
    bank,
    loading: !!loadingMsg,
    loadingMsg,
    combineNames,
    schoolStyle: selectSchoolStyle(state, ownProps) || {},
  };
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
