import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatResult } from '../../../../../containers/UxCommon/Utils';

class ProfileStatsRow extends PureComponent {
  static propTypes = {
    season: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    stdCats: PropTypes.array.isRequired,
    currentSport: PropTypes.object.isRequired,
  }

  componentDidMount() {}

  isLastTdInCat = (statPositions, statPosIndex, stdCats, catIndex) => {
    if ((statPosIndex === (statPositions
      .filter(sp => sp.bigLeagueCardBool).length - 1))
    && (catIndex !== (stdCats.length - 1))) {
      return true;
    }
    return false;
  }

  seasonDisplayDate = (season, currentSport) => {
    if (!currentSport.usesSeasonEndDate) {
      return season.startDate.format('MM/DD/YY');
    }
    return season.name;
  }

  render() {
    const {
      season,
      categories,
      stdCats,
      currentSport,
    } = this.props;
    return (
      <tr>
        <td className="Profile_StatsTable_NameCell">
          { this.seasonDisplayDate(season, currentSport) }
        </td>
        {
          stdCats.map((cat, catIndex) => cat.statPositions
          .map((statPos, statPosIndex) => {
            const userCategory = categories.find(c => c.category.id === cat.category.id);
            let stat = null;
            if (userCategory) {
              stat = userCategory.stats
                .find(st => st.stdStatPosition.id === statPos.id);
            }
            if (stat) {
              return (
                <td
                  key={statPos.id}
                  className={
                    this.isLastTdInCat(cat.statPositions, statPosIndex, stdCats, catIndex)
                    ? 'CatBorder'
                    : ''
                  }
                >
                  {formatResult(stat.results, stat.statFormatCode)}
                </td>
              );
            }
            return (
              <td
                key={statPos.id}
                className={
                  this.isLastTdInCat(cat.statPositions, statPosIndex, stdCats, catIndex)
                  ? 'CatBorder'
                  : ''
                }
              >
                -
              </td>
            );
          }))
        }
      </tr>

    );
  }
}

export default ProfileStatsRow;
