import queryString from 'query-string';
import React, {PureComponent, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, Switch} from 'react-router-dom';
import LeaderboardTable from './LeaderboardTable';
// import bgImage from '../../../assets/images/Leaderboard-background.png';
import Banner from './Banner';
import FilterSidebar from '../common/FiltersSidebar';
import SidebarLinkFilter from '../common/SidebarLinkFilter';
import SidebarMultiSelectCallbackFilter from '../common/SidebarMultiSelectCallbackFilter';
import {gender, positions, batThrow } from '../../../components/UxCommon/Form/utils';
import {AthleteGrades} from '../+store';
import {Routes, expandedLeaderboardDialogPath, Sports} from '../../../store/actions/ui/routes';
import Header from './Header';
import {BankSlugs} from '../+store/combine';
import LoadingIndicator from '../common/LoadingIndicator';
import SidebarEventFilter from "../common/SidebarEventFilter";
import ExpandedPhotoDialog from '../../../containers/UxProfile/Photos/ExpandedPhotoDialog';
import Video from '../../../store/actions/data/models/Video';
import CombineSidebarEventFilter from '../common/CombineSidebarEventFilter';
import {setSortBy} from "../+store/coachWorld";
import SearchControl from '../common/SearchControl';
import LeaderboardPrivacyPage from "../../../components/UxCommon/ErrorPage/LeaderboardPrivacyPage";

const getSidebarFilters = ({
                             filterSchoolTeamList,
                             sportId,
                             gradYears
                           }) => {
  const linkFilters = [];

  let queryStringFilters = [
    {
      name: 'schoolTeamId',
      label: 'Teams',
      items: filterSchoolTeamList.map(st => ({
        name: st.team.name,
        value: st.team.id,
      })),
    },
    {
      name: 'position',
      label: 'Positions',
      items: positions(sportId)
        .map(({value, name}) => ({
          name,
          value,
        })),
    },
    {
      name: 'gender',
      label: 'Genders',
      items: gender()
        .map(({text, value}) => ({
          name: text,
          value,
        })),
    },
  ];

  if(sportId === 2 || sportId === 4){
   const bats = {
      name: 'bats',
      label: 'BATS',
      items: batThrow.map(({name, value}) => ({name, value})),
    };
    const throws = {
      name: 'throws',
      label: 'Throws',
      items: batThrow.map(({name, value}) => ({name, value})),
    };

    queryStringFilters.splice(2, 0, bats,throws);
  }

  if (gradYears.length) {
    const gradYearFilter = {
      name: 'gradYear',
      label: 'Grad Year',
      items: gradYears.map((value) => ({
        name: value,
        value,
      })),
    };

    (sportId === 2 || sportId === 4) ? queryStringFilters.splice(4, 0, gradYearFilter)
     : queryStringFilters.splice(2, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters,
  };
};

class Leaderboard extends PureComponent {
  static propTypes = {
    getCombine: PropTypes.func.isRequired,
    shouldUpdatePrev: PropTypes.bool.isRequired,
    shouldUpdateCurr: PropTypes.bool.isRequired,
    getCombineTestRes: PropTypes.func.isRequired,
    combineId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    combine: PropTypes.object,
    results: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    columns: PropTypes.array,
    handleTestSortClicked: PropTypes.func.isRequired,
    sortByTestId: PropTypes.number,
    viewSelection: PropTypes.string.isRequired,
    onResultsChange: PropTypes.func.isRequired,
    numResultsShown: PropTypes.string.isRequired,
    schoolTeamsList: PropTypes.array,
    onScoreTypeChange: PropTypes.func.isRequired,
    selectedScoreType: PropTypes.string.isRequired,
    measureableColumns: PropTypes.array.isRequired,
    schoolStyle: PropTypes.object,
    getTestCategories: PropTypes.func.isRequired,
    testCats: PropTypes.object,
    isLoggedIn: PropTypes.bool.isRequired,
    selectedEvent: PropTypes.any,
    gradYears: PropTypes.array.isRequired,
    testAverage:PropTypes.array.isRequired,
  };

  state = {
    numShowMore: 0,
    showMobileFilters: false,
    isVideoDialogOpen: false,
  };

  componentDidMount() {
    const {getCombineTestRes, combineId} = this.props;
    this.leaderBoardFetched = true;
    getCombineTestRes(combineId, false);
    this.shouldUpdate();
  }

  componentDidUpdate() {
    const {combine} = this.props;
    this.shouldUpdate();
    if (combine && combine.isOpen) {
      this.leaderBoardFetched = false;
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  onHeaderClick = (stdTestObjectId) => {
    const {handleTestSortClicked, location, history} = this.props;
    handleTestSortClicked(location, history, stdTestObjectId);
  };

  onResultsChange = (value) => {
    const {onResultsChange, location, history} = this.props;
    onResultsChange(location, history, value);
    this.setState({numShowMore: 0});
  };

  onScoreTypeChange = (value) => {
    const {onScoreTypeChange, location, history} = this.props;
    onScoreTypeChange(location, history, value);
  };

  onShowMoreClick = () => {
    const {numShowMore} = this.state;
    this.setState({numShowMore: numShowMore + 10});
  };

  setRef = (table) => {
    this.table = table;
  };

  table = null;

  combineCurrFetched = false;
  combinePrevFetched = false;
  testCatsFetched = false;
  intervalId = null;
  leaderBoardFetched = false;

  shouldUpdate = () => {
    const {
      getCombine,
      combine,
      shouldUpdateCurr,
      combineId,
      getCombineTestRes,
      match,
      selectedScoreType,
      onScoreTypeChange,
      location,
      history,
      testCats,
      getTestCategories,
      isCoach,
      isCoachPartOfTeam,
      isLoggedIn,
      leaderboardPrivacy
    } = this.props;
    if (!testCats.organizedCats && !this.testCatsFetched) {
      this.testCatsFetched = true;
      getTestCategories();
    }
    if (!this.leaderBoardFetched) {
      this.leaderBoardFetched = true;
      if (combine && (!leaderboardPrivacy || isCoachPartOfTeam)) {
        setTimeout(() => {
          getCombineTestRes(combineId, true);
        }, 5000);
      }
    }
    if (shouldUpdateCurr && !this.combineCurrFetched) {
      this.combineCurrFetched = true;
      getCombine(combineId);
    }
    if (((match.params.bank === BankSlugs.PERF)
      && (selectedScoreType === 'relative'))
      || ((match.params.bank === BankSlugs.VC_SCORE)
        && (selectedScoreType !== 'raw'))) {
      onScoreTypeChange(location, history, 'raw');
    }
  };

  redirectToBank = () => {
    const {combine, match} = this.props;
    const bankSlug = BankSlugs[combine.banks[0]];
    return (<Redirect
      to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${combine.id}/${bankSlug}`}
    />);
  };

  renderTable = () => {
    const {
      columns,
      sortByTestId,
      results,
      viewSelection,
      loading,
      numResultsShown,
      selectedScoreType,
      measureableColumns,
      isLoggedIn,
      schoolStyle,
      selectedEvent,
      selectedFilters,
      location,
      match,
      isCoach,
      sportId,
      history,
      testAverage,
    } = this.props;
    if (loading) return null;
    const {numShowMore} = this.state;
    const numShown = Number(numResultsShown) + numShowMore;
    const data = results.slice(0, numShown);

    // now we are not limiting anything for any user/guest
    // if (!isLoggedIn) {
    //   data = results.slice(0, 10);
    // }
    // let displayShowMore = false;
    // if (isLoggedIn && results.length > numShown) {
    //   displayShowMore = true;
    // }

    // now we are not limiting anything for any user/guest
    const displayShowMore = true;

    let selectedEventFilter = null;
    if(location && location.search) {
      const parsedQuery = queryString.parse(location.search);
      const filtersJson = parsedQuery.filters;
      if(filtersJson) {
        const eventFilters = JSON.parse(filtersJson);
        eventFilters.forEach((filter) => {
          if (filter.name === 'events') {
            selectedEventFilter = columns.filter(c =>
              filter.selected.includes(c.standardTestObjectId)
            )
            selectedEventFilter = (selectedEventFilter.length === 0 ? columns : selectedEventFilter);
          }
        });
      }
    }

    const eventFilter = selectedFilters ? selectedFilters.find(fl => fl.name === 'events') : null;
    const selectedEvents = eventFilter ? eventFilter.selected : null;

    const filteredColumns = selectedEvents && selectedEvents.length ?
      columns.filter(c => selectedEvents.includes(c.standardTestObjectId)) : null

    const selectedTest = filteredColumns && filteredColumns.length && !(filteredColumns.find(fc => fc.standardTestObjectId === sortByTestId)) ?
      setSortBy(location, history, filteredColumns[0].standardTestObjectId) : sortByTestId;

    return (
      <div className="Leaderboard_TableWrapper_Container">
        <div className="Leaderboard_TableWrapper">
          <LeaderboardTable
            data={data}
            testAverage={testAverage}
            schoolStyle={schoolStyle}
            columns={selectedEventFilter ? selectedEventFilter : columns}
            onHeaderClick={this.onHeaderClick}
            location={location}
            match={match}
            sortByTestId={selectedTest}
            viewSelection={viewSelection}
            loading={loading}
            onShowMoreClick={this.onShowMoreClick}
            displayShowMore={displayShowMore}
            selectedScoreType={selectedScoreType}
            measureableColumns={measureableColumns}
            isLoggedIn={isLoggedIn}
            enableAthleteLink={false}
            isCoach={isCoach}
            sport={Sports[sportId]}
          />
        </div>
      </div>
    );
  };

  render() {
   const {
      loading,
      loadingMsg,
      combine,
      match,
      history,
      location,
      columns,
      numResultsShown,
      schoolTeamsList,
      selectedScoreType,
      schoolStyle,
      isLoggedIn,
      combineTemplateSportId,
      videos,
      gradYears,
      isCoachPartOfTeam,
      leaderboardPrivacy
    } = this.props;

    if (leaderboardPrivacy === null || isCoachPartOfTeam === null) {
      return <LoadingIndicator
        loading={loading}
        loadingMsg={loadingMsg}
        leaderboard
        wrapperStyle={{top: 100}}
      />;
    }


    if (leaderboardPrivacy && !isCoachPartOfTeam) {
      return <LeaderboardPrivacyPage schoolStyle={schoolStyle}/>;
    }

    let sportId = 0;
    if (combine && combine.combineTemplate) {

      ({sportId} = combine.combineTemplate);
    } else {
      sportId = combineTemplateSportId
    }

    let filterSchoolTeamList = [];
    if (combine && combine.lockedTeams && schoolTeamsList) {
      const combineTeamArray = [].concat(combine.lockedTeams.map(team => team.id));
      filterSchoolTeamList = schoolTeamsList.filter(schoolTeam => combineTeamArray.includes(schoolTeam.team.id))
    }
    const {linkFilters, queryStringFilters} = getSidebarFilters({
      sportId,
      filterSchoolTeamList,
      gradYears,
    });

    let bgImage;
    let bgColor;
    if (schoolStyle) {
      if (schoolStyle.leaderboardBackground) {
        bgImage = `url(${schoolStyle.leaderboardBackground})`;
      }
      if (schoolStyle.leaderboardStyle === 'dark') {
        bgColor = 'black';
      } else {
        bgColor = 'white';
      }
    } else {
      bgColor = 'black';
    }
    const themeClass = schoolStyle
    && (schoolStyle.leaderboardStyle === 'dark') ? 'dark' : 'light';
    const banks = combine && combine.banks ? combine.banks : [];

    const photoVideos = videos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });

    return (
      <React.Fragment>
        <Switch>
          <Route
            path={expandedLeaderboardDialogPath}
            render={(props) => (
              <ExpandedPhotoDialog photoList={photoVideos} {...props} location={location} isSingleVideo={true} page={`${Routes.leaderboard}`} />
            )}
          />
        </Switch>
        {/*{combine && isCoachPartOfTeam!==null &&*/}
          <div className={`LeaderboardPage ${themeClass}-theme`}>
            <div className="Leaderboard_Background" style={{
              backgroundImage: bgImage,
              backgroundColor: bgColor,
            }}/>
            <Banner match={match} location={location}/>
            <div className="Leaderboard_BodyWrapper">
              <Header
                match={match}
                location={location}
                onResultsChange={this.onResultsChange}
                numResultsShown={numResultsShown}
                onScoreTypeChange={this.onScoreTypeChange}
                selectedScoreType={selectedScoreType}
                isLoggedIn={isLoggedIn}
                banks={banks}
                showFilters={() => this.setState({showMobileFilters: true})}
              />
              <LoadingIndicator
                loading={loading}
                loadingMsg={loadingMsg}
                leaderboard
                wrapperStyle={{top: 100}}
              />
              {/* <div className="leaderBoard_SearchBox">
                <SearchControl
                  placeholder="Search Athlete"
                  location={location}
                  history={history}
                />
            </div> */}
              <div className="Leaderboard_Body">
                <FilterSidebar
                  displayNone={!!loading}
                  className="Leaderboard"
                  showMobileFilters={v => this.setState({showMobileFilters: v})}
                  defaultIsHidden={!this.state.showMobileFilters}
                >
                  <CombineSidebarEventFilter
                    filterName="events"
                    label="Events"
                    filters={columns.map(column => ({
                      name: column.testCategoryName,
                      value: column.standardTestObjectId,
                    }))}
                    history={history}
                    location={location}
                    className="Events"
                  />
                  {
                    linkFilters.map(({name, label, items}) => (
                      <SidebarLinkFilter
                        key={name}
                        filterName={name}
                        label={label}
                        filters={items}
                        match={match}
                      />
                    ))
                  }
                  {
                    queryStringFilters.map(({name, label, items}) => (
                      <SidebarMultiSelectCallbackFilter
                        key={name}
                        filterName={name}
                        label={label}
                        filters={items}
                        history={history}
                        location={location}
                        className="Leaderboard"
                      />
                    ))
                  }
                </FilterSidebar>
                {
                  combine && (
                    <Switch>
                      <Route
                        path={`/${Routes.school}/:schoolId/${Routes.leaderboard}/:combineId/:bank`}
                        render={this.renderTable}
                      />
                      <Route
                        path={`/${Routes.school}/:schoolId/${Routes.leaderboard}/:combineId`}
                        render={this.redirectToBank}
                      />

                    </Switch>
                  )
                }
              </div>
            </div>
          </div>
      {/*}*/}
      </React.Fragment>
    );
  }
}

export default Leaderboard;
