import { asyncStartAction } from '../../async';

export const PD_GET_STATS = 'playerData.getStats';
export const PD_CREATE_UPDATE_STATS = 'playerData.createUpdateStats';
export const PD_STATS_MODEL = 'playerData.stats';
export const playerStatsGet = (uuid, sportId, msg) => (dispatch) => {
  dispatch(asyncStartAction(PD_GET_STATS, msg));
  dispatch({
    type: PD_GET_STATS,
    uuid,
    sportId,
  });
};

export const PD_STATS_REGISTER_SEASONS = 'playerData.stats.registerSeasons';
export const playerStatsRegisterSeasons = seasons => ({
  type: PD_STATS_REGISTER_SEASONS,
  seasons,
});

export const PD_STATS_REGISTER_POSITION = 'playerData.stats.registerPosition';
export const playerStatsRegisterPosition = position => ({
  type: PD_STATS_REGISTER_POSITION,
  position,
});

export const PD_STATS_ADD_SEASON = 'playerData.stats.addSeason';
export const playerStatsAddSeason = (form, msg) => (dispatch) => {
  dispatch({
    type: PD_STATS_ADD_SEASON,
    form,
  });
  dispatch(asyncStartAction(PD_STATS_MODEL, msg));
};

export const PD_STATS_SAVE_TABLE_ERROR = 'playerData.stats.saveTable.error';
export const playerStatsSaveTableError = (positionId, error) => ({
  type: PD_STATS_SAVE_TABLE_ERROR,
  positionId,
  error,
});

export const PD_STATS_TABLE_FORM_INIT = 'playerData.stats.formInit';
export const statsTableFormInit = (positionId, stats, standardStats) => ({
  type: PD_STATS_TABLE_FORM_INIT,
  positionId,
  stats,
  standardStats,
});

export const statsFormModelRegex = /^forms[.]dynamic[.]stats[.]pos(\d+)$/;
export const statsFormModelPosition = positionId => `forms.dynamic.stats.pos${positionId}`;
export const statsFormModelStat = (positionId, seasonId, statPosId) => `forms.dynamic.stats.pos${positionId}.season${seasonId}.stats.stat${statPosId}`;
export const statsFormModelSeasonName = (positionId, seasonId) => `forms.dynamic.stats.pos${positionId}.season${seasonId}.name`;
export const statsFormModelNewStat = (positionId, seasonId, statPosId) => `forms.dynamic.stats.pos${positionId}.newSeason${seasonId}.stats.stat${statPosId}`;
export const statsFormModelNewSeasonName = (positionId, seasonId) => `forms.dynamic.stats.pos${positionId}.newSeason${seasonId}.name`;
export const statsFormModelSeason = (positionId, seasonId) => `forms.dynamic.stats.pos${positionId}.season${seasonId}`;
export const statsFormModelNewSeason = (positionId, seasonId) => `forms.dynamic.stats.pos${positionId}.newSeason${seasonId}`;
export const statsFormObjectPosition = (state, positionId) => state.forms.dynamic.stats[`pos${positionId}`];

export const statsSeasonTableFormModel = (teamId, positionId) =>
  `forms.dynamic.stats.teams.${teamId}.positions.${positionId}`;
export const statsSeasonTableFormObject = (state, teamId, positionId) => {
  if (state.forms.forms.dynamic.stats) {
    if (state.forms.forms.dynamic.stats.teams) {
      if (state.forms.forms.dynamic.stats.teams[teamId]) {
        return state.forms.forms.dynamic.stats.teams[teamId].positions[positionId];
      }
    }
  }
  return {};
};
export const statsSeasonTableFormDataObject = (state, teamId, positionId) => {
  if (state.forms.dynamic.stats) {
    if (state.forms.dynamic.stats.teams) {
      return state.forms.dynamic.stats.teams[teamId].positions[positionId];
    }
  }
  return {};
};
export const statsSeasonRowFormModel = (teamId, positionId, seasonId) =>
  `forms.dynamic.stats.teams.${teamId}.positions.${positionId}.seasons.${seasonId}`;
export const statsSeasonRowFormObject = (state, teamId, positionId, seasonId) => {
  if (state.forms.forms.dynamic.stats) {
    if (state.forms.forms.dynamic.stats.teams) {
      return state.forms.forms.dynamic.stats.teams[teamId].positions[positionId].seasons[seasonId];
    }
  }
  return {};
};

export const statsAddSeasonFormModel = (teamId, positionId) => `forms.dynamic.stats.addSeason.${teamId}.${positionId}`;
export const statsAddSeasonFormObject = (state, teamId, positionId) => {
  if (state.forms.forms.dynamic.stats) {
    if (state.forms.forms.dynamic.stats.addSeason) {
      if (state.forms.forms.dynamic.stats.addSeason[teamId] &&
      state.forms.forms.dynamic.stats.addSeason[teamId][positionId]) {
        return state.forms.forms.dynamic.stats.addSeason[teamId][positionId];
      }
    }
  }
  return {};
};
export const statsAddSeasonFormDataObject = (state, teamId, positionId) => {
  if (state.forms.dynamic.stats) {
    if (state.forms.dynamic.stats.addSeason) {
      if (state.forms.dynamic.stats.addSeason[teamId]) {
        return state.forms.dynamic.stats.addSeason[teamId][positionId];
      }
    }
  }
  return {};
};
export const isStatsAddSeasonFormModel = (model) => {
  const re = /^forms[.]dynamic[.]stats[.]addSeason[.](\d+)[.](\d+)$/;
  return re.test(model);
};

export const isStatsFormModel = (model) => {
  const re = /^forms[.]dynamic[.]stats[.]teams[.](\d+)[.]positions[.](\d+)$/;
  return re.test(model);
};

export const PD_STATS_NEW_SEASON = 'playerData.stats.newseason';
export const openNewSeasonRow = (teamId, positionId, rowId, season) => ({
  type: PD_STATS_NEW_SEASON,
  teamId,
  positionId,
  rowId,
  season,
});
export const PD_STATS_CANCEL_NEW_SEASON = 'playerData.stats.cancelNewSeason';
export const cancelNewSeasonRow = (teamId, positionId, rowId) => ({
  type: PD_STATS_CANCEL_NEW_SEASON,
  teamId,
  positionId,
  rowId,
});
export const SHOW_ADD_SEASON_FORM = 'playerData.stats.addSeasonForm.show';
export const showAddSeasonForm = (teamId, positionId) => ({
  type: SHOW_ADD_SEASON_FORM,
  teamId,
  positionId,
});
export const HIDE_ADD_SEASON_FORM = 'playerData.stats.addSeasonForm.hide';
export const hideAddSeasonForm = (teamId, positionId) => ({
  type: HIDE_ADD_SEASON_FORM,
  teamId,
  positionId,
});

export const PD_STATS_EDIT_TABLE = 'playerData.stats.editTable';
export const editStatsTable = (teamId, positionId) => ({
  type: PD_STATS_EDIT_TABLE,
  teamId,
  positionId,
});
export const PD_STATS_CANCEL_EDIT_TABLE = 'playerData.stats.cancelEditTable';
export const cancelEditStatsTable = (teamId, positionId) => ({
  type: PD_STATS_CANCEL_EDIT_TABLE,
  teamId,
  positionId,
});
export const PD_STATS_SHOW_DEL_SEASON_DIALOG = 'playerData.stats.deleteSeasonDialog.show';
export const showDeleteSeasonDialog = (teamId, positionId, season) => ({
  type: PD_STATS_SHOW_DEL_SEASON_DIALOG,
  teamId,
  positionId,
  season,
});
export const PD_STATS_HIDE_DEL_SEASON_DIALOG = 'playerData.stats.deleteSeasonDialog.hide';
export const hideDeleteSeasonDialog = () => ({
  type: PD_STATS_HIDE_DEL_SEASON_DIALOG,
});

export const PD_STATS_DEL_SEASON = 'playerData.stats.deleteSeason';
export const statsDeleteSeason = (teamId, positionId, season) => (dispatch) => {
  dispatch(asyncStartAction(
    PD_STATS_DEL_SEASON,
    'Deleting season',
    { teamId, positionId, season },
  ));
  dispatch({
    type: PD_STATS_DEL_SEASON,
    teamId,
    positionId,
    season,
  });
};

export const SHOW_FIRST_SEASON_FORM = 'playerData.stats.firstSeasonForm.show';
export const showFirstSeasonForm = () => ({
  type: SHOW_FIRST_SEASON_FORM,
});
export const HIDE_FIRST_SEASON_FORM = 'playerData.stats.firstSeasonForm.hide';
export const hideFirstSeasonForm = () => ({
  type: HIDE_FIRST_SEASON_FORM,
});
