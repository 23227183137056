import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Error404Page } from '../../UxCommon';

class PrivateRoute extends PureComponent {
  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]).isRequired,
    path: PropTypes.string.isRequired,
    redirect: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    notSure: PropTypes.bool.isRequired,
    authMsg: PropTypes.string.isRequired,
  }

  renderRoute = (routeProps) => {
    const {
      component: Component,
      notSure,
      authMsg,
      isAuthenticated,
      redirect,
      ...rest
    } = this.props;
    if (notSure) {
      return <div className="Authenticating">{authMsg}</div>;
    }
    if (isAuthenticated) {
      return <Component {...rest} {...routeProps} />;
    }
    const { location } = routeProps;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: redirect,
            state: { from: location },
          }}
        />
      );
    }
    return <Error404Page />;
  };

  render() {
    const { path } = this.props;
    return (
      <Route path={path} render={this.renderRoute} />
    );
  }
}

export default PrivateRoute;
