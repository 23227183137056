import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';

class SocialLink extends PureComponent {
  static propTypes = {
    setMsg: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    currentMsg: PropTypes.string.isRequired,
  }

  onMouseEnter = () => {
    const { msg, setMsg } = this.props;
    this.copyIn = true;
    setTimeout(() => {
      if (this.copyIn) {
        setMsg(msg);
      }
    }, 100);
  }

  onMouseLeave = () => {
    const { setMsg, msg } = this.props;
    setTimeout(() => {
      const { currentMsg } = this.props;
      if ((!this.copyIn) && (currentMsg === msg)) {
        setMsg('');
      }
    }, 250);
    this.copyIn = false;
  }

  onClick = () => {
    const { link } = this.props;
    window.open(link, 'Popup', 'location,status,scrollbars,resizable,width=600,height=600');
  }

  render() {
    const { iconName, className } = this.props;
    return (
      <button
        onClick={this.onClick}
        className={`UxCommon_ShareModal_SocialLink ${className}`}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Icon iconName={iconName} />
      </button>
    );
  }
}

export default SocialLink;
