import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import EditButton from '../../../../common/EditButton';
import LoadingIndicatorCard from '../LoadingIndicatorCard/LoadingIndicatorCard.component';
import { Routes } from '../../../../../../store/actions/ui/routes';
import CloseCombineModal from '../../../../Combine/CloseCombineModal';
import OpenCombineModal from '../../../../Combine/OpenCombineModal';
import TooltipIcon from '../../../../common/TooltipIcon/TooltipIcon.component';

class CombineCard extends PureComponent {
  static propTypes = {
    combine: PropTypes.object,
    athleteCombineResults: PropTypes.array,
    showEditDialog: PropTypes.func,
    updateCombine: PropTypes.func,
    updateOrCreateCombineAndAwards: PropTypes.func

  };
  state = {
    showCloseCombineDialogue: false,
    showOpenCombineDialouge: false,
    today: moment(),
  }

  onCancelClickHandler = () => {
    this.setState({
      showCloseCombineDialogue: false,
      showOpenCombineDialouge: false,
    });
  }

  onCloseCombine=() => {
    const { combine, updateCombine, updateOrCreateCombineAndAwards, match } = this.props;
    const data = {
      ...combine,
      closed_at: moment().utc().utcOffset(-6).format('YYYY-MM-DD HH:mm:ss'),
    };
    this.setState({
      showCloseCombineDialogue: false,
    });
    updateOrCreateCombineAndAwards(data, combine, match.params.schoolId);
    // updateCombine(data, combine);
  }

  onOpenCombine=() => {
    const { combine, updateCombine } = this.props;
    const DayDiff = moment(combine.endDate.format('MM.DD.YY')).diff(moment(moment().format('MM.DD.YY')), 'days');
    const data = {
      ...combine,
      closed_at: null,
    };
    if(DayDiff <= 5) {
      Object.assign(data, {end_date: moment(`${moment(moment().format('MM/DD/YYYY')).clone().add(5, 'days').format('MM/DD/YYYY')} ${moment().utc().utcOffset(-6).format('HH:mm:ss')}`).format(), })
    }
    this.setState({
      showOpenCombineDialouge: false,
    });
    updateCombine(data, combine);
  }

  handleEditClick = () => {
    this.props.showEditDialog(this.props.combine.id);
  }

  closeCombineHandler = () => {
    this.setState({
      showCloseCombineDialogue: true,
    });
  }
  openCombineHandler = () => {
    this.setState({
      showOpenCombineDialouge: true,
    });
  }

  render() {
    const {
      combine,
      athleteCombineResults,
      showEditDialog,
    } = this.props;
    const { showCloseCombineDialogue, showOpenCombineDialouge, today } = this.state;
    let athleteCount = null;
    let toDate = '';
    const countObject = athleteCombineResults.find(a => a.id === combine.id) || null;
    athleteCount = (countObject !== null) ? countObject.athleteCount : null;
    // Check null situation because of invalid data I saved.
    const openDate = combine.openDate ? combine.openDate.format('MM.DD.YY') : '';
    let isTodaySurpassedCombineEndDate = false;
    if (combine.isOpen) {
      toDate = combine.endDate ? combine.endDate.format('MM.DD.YY') : '';
      isTodaySurpassedCombineEndDate = moment(today.format('MM.DD.YY')).isAfter(toDate);
    } else {
      toDate = combine.closeDate ? combine.closeDate.format('MM.DD.YY') : '';
    }
    const urlDecider = combine.type === 'report' ? Routes.report : Routes.combine;
    const urlIdDecider = combine.type === 'report' ? combine.reportId : combine.id;
    const combineAlertTooltip = combine.isOpen ? `So reporting data can populate, <br /> ${combine.isCombineCreator ? '<b> you </b> need' : `<b> ${combine.creatorName} </b> needs`}  to close this combine.` : '';
    const combineReOpenTooltip = `To correct any mistakes in the combine, <br />  ${combine.isCombineCreator ? '<b> you </b> need' : `<b> ${combine.creatorName} </b> needs`}  to re-open this combine.`;
    const combineProgressTooltip = 'This combine is still in progress.';
    return (
      <div className="CombineCard_Container vc-combine-container">
        { showOpenCombineDialouge && (
          <OpenCombineModal
            onCancel={this.onCancelClickHandler}
            onOpenCombine={this.onOpenCombine}
            combine={combine}
          />
         )
        }
        { showCloseCombineDialogue && (
          <CloseCombineModal
            onCancel={this.onCancelClickHandler}
            onCloseCombine={this.onCloseCombine}
            combine={combine}
          />
        )
      }
        <div className="CombineCard_Header">
          <div className="CombineCard_Season">
            {
              combine && isTodaySurpassedCombineEndDate &&
                <button className="CombineCard_NoStyle_Btn" onClick={combine.isCombineCreator ? this.closeCombineHandler : null}>
                  <TooltipIcon style={{ color: 'red', fontSize: '20px' }} iconName="alert" tooltip={combineAlertTooltip} />
                </button>
            }
            <span>  {`${openDate} - ${toDate}`} </span>
          </div>
          {
           combine && combine.isCombineCreator && showEditDialog &&
             <div className="CombineCard_Edit">
               <EditButton onClick={this.handleEditClick} iconName="edit" />
             </div>
          }
        </div>

        <div>
          <Link
            className="CombineCard_Body_Footer"
            to={`/${Routes.school}/${combine.schoolId}/${urlDecider}/${urlIdDecider}`}
          >
            <div className="CombineCard_Body">
              {combine.name}
            </div>
            <div className="CombineCard_Footer">
              <div className="CombineCard_AthletesCount">
                {
                  athleteCount !== null
                    ? <div className="CombineCard_AthletesCount_Text"> {`${athleteCount} Athletes`} </div>
                    : <LoadingIndicatorCard loading loadingMsg="" />
                }
              </div>
            </div>
          </Link>
          {
              (combine.type !== 'report') && (
                <div className="CombineCard_IsOpen" >
                  {combine.isOpen ? (
                    <button
                      className="CombineCard_NoStyle_Btn"
                      onClick={combine.isCombineCreator ? this.closeCombineHandler : null}
                    >
                      <TooltipIcon
                        iconName="custom-unlock"
                        style={{ color: 'green',fontSize: '19.4px' }}
                        tooltip={combineProgressTooltip}
                      />
                    </button>
                 ) : (
                   <button
                     className="CombineCard_NoStyle_Btn"
                     onClick={combine.isCombineCreator ? this.openCombineHandler : null}
                   >
                     <TooltipIcon
                       className="CombineCard_IsOpen_Icon"
                       iconName="custom-lock"
                       style={{fontSize: '20px'}}
                       tooltip={combineReOpenTooltip}
                     />
                   </button>
                 )
               }
                </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default CombineCard;
