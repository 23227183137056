import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/UxCommon/Icon/Icon';

const HideButton = ({ className, onClick }) => (
  <button className={`CoachHideButton ${className || ''}`} onClick={onClick}>
    <Icon iconName="hide" className="CoachHideButton_Icon" />
    <div>Hide</div>
  </button>
);
HideButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default HideButton;
