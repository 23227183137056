import { combineEpics } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { commonApiCallFragment, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { createVanityUrl, updateVanityUrl } from '../../../../data/user/vanity/apiCalls';
import { CREATE_VANITY_URL, UPDATE_VANITY_URL } from '../actions';
import { asyncErrorAction, asyncFinishAction } from '../../../async';
import { vanityUrlSaveSuccess } from '../../../../data/user/vanity';

const createUpdateVanityUrlEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    filter(action => (
      (action.type === CREATE_VANITY_URL)
      || (action.type === UPDATE_VANITY_URL)
    )),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (!(action.hasPrevValue) && !action.name) {
        return of({
          success: false,
          actions: [
            actions.setErrors('forms.vanityUrl.url', {
              validation: 'You forgot to type instagram handle',
            }),
            asyncErrorAction(action.type, 'createVanityUrl'),
          ],
        });
      }
      const apiCall = action.type === CREATE_VANITY_URL
        ? createVanityUrl
        : updateVanityUrl;
      const body = isCoach() ? { 'aws_uuid': uuid() } : {};
      return apiCall(action.name, token, body).pipe(commonApiCallFragment(
        action$,
        action,
        'vanityUrl',
        Routes.edit,
      ));
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          vanityUrlSaveSuccess(uuid(), results.action.name),
          asyncFinishAction(results.action.type, 'createVanityUrl', { name: results.action.name }),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const createUpdateEpics = combineEpics(createUpdateVanityUrlEpic);

export default 'vanity/create.js';
