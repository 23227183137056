import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getSportsAvatars } from '../../../../utils';

class AvatarBg extends PureComponent {
  static propTypes = {
    selectedUserSport: PropTypes.object,
    user: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
  }

  render() {
    const {
      selectedUserSport,
      user,
      className,
      children,
    } = this.props;
    const sportId = selectedUserSport ? selectedUserSport.sportId : 1;
    const gender = user ? user.gender : 'male';
    const bgImage = getSportsAvatars(sportId, gender);
    return (
      <div
        className={`${className} AvatarBg` || 'AvatarBg'}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        {children}
      </div>
    );
  }
}

export default AvatarBg;
