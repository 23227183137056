import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Routes, Sports } from '../../../../store/actions/ui/routes';
import HeaderMenuLink from './HeaderMenuLink/HeaderMenuLink';
import HeaderMenuButton from './HeaderMenuButton/HeaderMenuButton';
import Icon from '../../../UxCommon/Icon/Icon';
import { getSportIconName } from '../../../../utils';
import HeaderSportLink from './HeaderSportLink/HeaderSportLink';
import AvatarBg from '../../PlayerCard/AvatarBg/AvatarBg';
import { createPlayerProfileData } from '../../../../utils/playerCard';

const SportButton = ({ sportId, iconClassName, ...other }) => (
  <button {...other}>
    <i className={`${iconClassName} icon-${getSportIconName(sportId)}`} />
  </button>
);

SportButton.propTypes = {
  sportId: PropTypes.number.isRequired,
  iconClassName: PropTypes.string.isRequired,
};

class PageHeader extends PureComponent {
  static propTypes = {
    selectedUserSport: PropTypes.object,
    user: PropTypes.object,
    onMenuClick: PropTypes.func.isRequired,
    userSports: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    authorizedUuid: PropTypes.string.isRequired,
  }

  state = {
    cardIndex: 0,
  }

  onSportClick = () => {
    this.setState({
      cardIndex: 1,
    });
  }

  onSportBackClick = () => {
    this.setState({
      cardIndex: 0,
    });
  }

  renderSportLinks = (userSports, match) => (
    <React.Fragment>
      {
        userSports.map(userSport => (
          <HeaderSportLink
            key={userSport.sportId}
            match={match}
            sportId={userSport.sportId}
            onClick={this.onSportBackClick}
          />
        ))
      }
    </React.Fragment>
  )

  render() {
    const {
      selectedUserSport,
      user,
      onMenuClick,
      userSports,
      match,
      authorizedUuid,
    } = this.props;
    const { cardIndex } = this.state;
    let info = '';
    if (user) {
      if (user.school) {
        info = `${user.first} ${user.last} - ${user.school}`;
      } else {
        info = `${user.first} ${user.last}`;
      }
    } else {
      info = "Loading athlete's info ...";
    }
    const { schoolId } = match.params;

    const sportId = selectedUserSport ? selectedUserSport.sportId : 1;
    const playerProfileData = createPlayerProfileData(user, selectedUserSport);

    return (
      <div className="PageHeader">
        <AvatarBg user={user} selectedUserSport={selectedUserSport} className="PageHeader_Avatar">
          <div className="PageHeader_Menu">
            <HeaderMenuButton className="selected" iconName="vc" onClick={onMenuClick} />
            <HeaderMenuLink match={match} iconName="player-data" page={Routes.player} />
            <HeaderMenuLink match={match} iconName="data" page={Routes.data} />
            <HeaderMenuLink match={match} iconName="photo" page={Routes.photos} nudge={{ left: '-1px' }} />
            <HeaderMenuLink match={match} iconName="award" page={Routes.awards} />
          </div>
          <div className="PageHeader_Card">
            <div className="PagesWrapper" style={{ left: `-${100 * cardIndex}%` }}>
              <div className="PageHeader_CardPage1">
                <div className="PageHeader_Photo">
                  {
                    (selectedUserSport && selectedUserSport.photoUrl) ? (
                      <div className="PageHeader_PhotoImg" style={{ backgroundImage: `url('${selectedUserSport.photoUrl}')` }} alt="Virtual Combine Profile" />
                    ) : (
                      <Icon iconName="default-photo" className="PageHeader_DefaultPic" />
                    )
                  }
                </div>
                <div className="PageHeader_InfoWrapper">
                  <div className="PageHeader_Info hidden-lg-up">
                    <div className="PageHeader_InfoDash" />
                    {
                      user ? (
                        <div className="PageHeader_InfoName">
                          {`${user.first} ${user.last}`}
                        </div>
                      ) : (
                        <div className="PageHeader_InfoName">
                          Loading athlete info ...
                        </div>
                      )
                    }
                    {
                      playerProfileData.map((i, index) => (
                        <div key={index} className="UxProfile_S_ProfileSchool">
                          {i.info}
                        </div>
                      ))
                    }
                    <div className="PageHeader_InfoDash" style={{ background: 'none' }} />
                  </div>
                  {
                    user && user.uuid ? (
                      <Link
                        className="PageHeader_InfoNameSchool hidden-md-down"
                        to={`/${Routes.profile}/${user.uuid}/${Routes.player}/${sportId? Sports[sportId]: ''}${schoolId ? `/${schoolId}` : ''}`}
                      >
                        {info}
                      </Link>
                    ) : (
                      <div className="PageHeader_InfoNameSchool hidden-md-down">
                        {info}
                      </div>
                    )
                  }
                </div>
                <div className="hidden-lg-up">
                  <SportButton
                    onClick={this.onSportClick}
                    className="PageHeader_SportButton"
                    iconClassName="PageHeader_SportButtonIcon"
                    sportId={sportId}
                  />
                </div>
                <div className="PageHeader_SportsNavWrapper">
                  <div className="PageHeader_SportsNav">
                    {this.renderSportLinks(userSports, match)}
                  </div>
                  <div style={{ lineHeight: 0 }}>
                    {
                      authorizedUuid ? (
                        <Link
                          className="PageHeader_VcLink"
                          to={`/${Routes.profile}/${authorizedUuid}/${Routes.player}/${Sports[sportId]}${schoolId ? `/${schoolId}` : ''}`}
                        >
                          <Icon iconName="vc" className="PageHeader_VcLinkIcon" />
                        </Link>
                      ) : (
                        <Link
                          className="PageHeader_VcLink"
                          to={`/${Routes.welcome}`}
                        >
                          <Icon iconName="vc" className="PageHeader_VcLinkIcon" />
                        </Link>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="PageHeader_CardPage2">
                <button
                  className="PageHeader_CardPage2BackBtn"
                  onClick={this.onSportBackClick}
                  style={{ opacity: cardIndex ? 1 : 0 }}
                >
                  <Icon iconName="back-arrow" />
                  <div>Go Back</div>
                </button>
                {this.renderSportLinks(userSports, match)}
              </div>
            </div>
          </div>
        </AvatarBg>
      </div>
    );
  }
}

export default PageHeader;
