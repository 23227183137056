import { connect } from 'react-redux';
import Component from '../../components/UxProfile/Common/PageHeader/PageHeader';
import { sidebarShowMenu } from '../../store/actions/ui/uxProfile';
import { getSortedSports, getSelectedSport, getProfile } from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => ({
  user: getProfile(state, ownProps.match),
  userSports: getSortedSports(state, ownProps.match),
  selectedUserSport: getSelectedSport(state, ownProps.match),
  authorizedUuid: state.data.cognito.uuid,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onMenuClick: () => {
      dispatch(sidebarShowMenu());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
