import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonIconOnly } from '../..';
import Icon from '../../Icon/Icon';

class SpeedWidget extends PureComponent {
  static propTypes = {
    onSetSpeed: PropTypes.func.isRequired,
    currentSpeed: PropTypes.number.isRequired,
    onOpenSpeed: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  state = {
    isOpen: false,
  };

  componentDidUpdate() {
  }

  onMouseEnter = () => {
    this.mouseIn = true;
  }

  onMouseLeave = () => {
    this.mouseIn = false;
  }

  render() {
    const { onSetSpeed, currentSpeed, onOpenSpeed } = this.props;
    return (
          <div className="VideoPlayer_SettingsWidget">
            <button
              className="VideoPlayer_BackButton"
              onClick={() => onOpenSpeed(false)}
              style={{ borderBottomWidth: 1, borderBottomColor: 'white' }}
            >
              <div className="VideoPlayer_BackButton_Left">
                <Icon iconName="chevron-left"/>
              </div>
              <div className="VideoPlayer_BackButton_Right">
                Back
              </div>
            </button>
            <button
              className="VideoPlayer_ButtonWithIcon"
              style={{ fontWeight: currentSpeed === 0.5 ? 'bold' : 'normal', fontSize: currentSpeed === 0.5 ? 15 : 12 }}
              onClick={() => onSetSpeed(0.5)}
            >
              0.5x
            </button>
            <button
              className="VideoPlayer_ButtonWithIcon"
              style={{ fontWeight: currentSpeed === 1 ? 'bold' : 'normal', fontSize: currentSpeed === 1 ? 15 : 12 }}
              onClick={() => onSetSpeed(1)}
            >
              Normal
            </button>
            <button
              className="VideoPlayer_ButtonWithIcon"
              style={{ fontWeight: currentSpeed === 1.5 ? 'bold' : 'normal', fontSize: currentSpeed === 1.5 ? 15 : 12 }}
              onClick={() => onSetSpeed(1.5)}
            >
              1.5x
            </button>
        </div>
    );
  }
}

export default SpeedWidget;
