import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/SecondSignIn/SecondSignIn';
import { secondSignInFormInit } from '../../store/actions/ui/welcome';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { routeChangedAlert } from '../../store/actions/ui/routes';

const mapStateToProps = state => ({
  formGeneralError: state.ui.welcome.secondSignIn.formError,
  rrfForm: state.forms.forms.secondSignIn,
  needsInit: state.ui.welcome.secondSignIn.formNeedsInit,
  username: state.ui.welcome.secondSignIn.username,
  password: state.ui.welcome.secondSignIn.password,
  msg: state.ui.welcome.secondSignIn.msg,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    autoSubmit: (username, password) => {
      dispatch(actions.change('forms.secondSignIn.password', password));
      dispatch(formSubmit(
        'forms.secondSignIn',
        { username },
      ));
    },
    submit: () => {
      dispatch(formSubmit(
        'forms.secondSignIn',
        { username: stateProps.username },
      ));
    },
    formInit: () => {
      dispatch(actions.setInitial('forms.secondSignIn'));
      dispatch(actions.reset('forms.secondSignIn'));
      dispatch(secondSignInFormInit());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
