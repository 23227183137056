import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '../../store/actions/ui/routes';
import Welcome from '../../containers/Welcome/Welcome';
import Profile from '../../containers/UxProfile/UxProfile';
import { Error404Page } from '../UxCommon';
import PrivateRoute from '../../containers/App/PrivateRoute';
import { appVersion, gitCommit } from '../../config/_env';
import CodeSignUp from '../../modules/CodeSignUp';
import CoachWorld from '../../modules/CoachWorld/CoachWorld.container';
import cssVar from './App.utils';
import ProspectSheet from '../../modules/ProspectSheet';
import NationalLeaderboard from '../../modules/CoachWorld/NationalLeaderboard';
import StripeCheckoutComponent from "../Welcome/StripeCheckout/StripeCheckout.component";
import ActivateProfileMembership from "../ActivateProfileMembership/ActivateProfileMembership";
import MailChimpUserInviteComponent from "../MailChimpUserInvite/MailChimpUserInvite.component";

class App extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkLogin: PropTypes.func.isRequired,
    routeChangeAlert: PropTypes.func.isRequired,
    redirectTo: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    currentUuid: PropTypes.string.isRequired,
    schoolStyle: PropTypes.object,
    schoolIds: PropTypes.array,
  };

  state = {
    schoolStyleId: '',
  };

  componentWillMount() {
    const { routeChangeAlert } = this.props;
    routeChangeAlert(window.location.pathname, window.location.search);
    this.unlisten = this.props.history.listen(() => {
      routeChangeAlert(window.location.pathname, window.location.search);
    });
  }

  componentDidMount() {
    this.props.checkLogin();

    console.log('Virtual Combine!'); // Do not delete this line
    console.log(`Version: ${appVersion} (${gitCommit})`);
  }

  componentDidUpdate(prevProps) {
    const {
      redirectTo,
      redirect,
      history,
      schoolStyle,
      schoolAwardLogo,
      schoolIds,
    } = this.props;

    const schoolId =
      schoolStyle && schoolStyle.id ? [schoolStyle.id] : schoolIds;
    if (
      schoolId.length > 0 &&
      !this.schoolAwardLogoUpdated &&
      schoolAwardLogo
    ) {
      schoolAwardLogo(schoolId);
      this.schoolAwardLogoUpdated = true;
    }
    if (
      schoolStyle &&
      (this.state.schoolStyleId !== schoolStyle.id ||
        schoolStyle.color2 !== prevProps.schoolStyle.color2 ||
        schoolStyle.color1 !== prevProps.schoolStyle.color1)
    ) {
      const primary = schoolStyle.color1;
      const secondary = schoolStyle.color2;
      cssVar('primary', primary);
      cssVar('secondary', secondary);
      this.setState({ schoolStyleId: schoolStyle.id });
    }
    if (!schoolStyle && this.state.schoolStyleId !== schoolStyle) {
      this.setState({ schoolStyleId: '' });
    }
    if (redirectTo) {
      history.push(redirectTo);
      redirect();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  schoolStyleUpdated = false;
  schoolAwardLogoUpdated = false;

  // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
  // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
  redirectToPlayerProfile = (props) => {
    const { uuid } = this.props;
    const visibleUuid = props.match.params.uuid || uuid;
    if (visibleUuid) {
      return (
        <Redirect to={`/${Routes.profile}/${visibleUuid}/${Routes.player}`} />
      );
    }
    return <Route render={this.renderHome} />;
  };


  redirectToCombineReport = (props) => {
    const { isAuthenticated, redirectUrl } = this.props;
    const { reportId, schoolId} = props.match.params;
    const url = `/${Routes.school}/${schoolId}/${Routes.report}/${reportId}`;
    redirectUrl({url : url})
    if (isAuthenticated) {
      return <Redirect to={`/${Routes.school}/${schoolId}/${Routes.report}/${reportId}`}/>
    }
    return <Redirect to={`/${Routes.welcome}/${Routes.login}`}/>
  };

  // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
  // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
  renderHome = () => (
    <Redirect to={`${this.props.match.url}${Routes.welcome}`} />
  );

  render() {
    const { match, uuid, currentUuid } = this.props;
    if (currentUuid === 'unknown') {
      return <Error404Page />;
    }
    return (
      <Switch>
        <Route path={`/${Routes.activateProfileMembership}/:uuid/:sportId/:schoolId`} component={ActivateProfileMembership} />
        <Route path={`/${Routes.mailChimpUserInvite}/player/:uuid/school/:schoolId/sport/:sportId`} component={MailChimpUserInviteComponent} />
        <Route path={`/${Routes.mailChimpUserInvite}/player/:uuid/sport/:sportId`} component={MailChimpUserInviteComponent} />

        <Route
          path={`/${Routes.coach}/${Routes.nationalLeaderboard}/:bank?`}
          component={CoachWorld}
        />
        <Route
          path={`/${Routes.coach}/${Routes.northEastLeaderboard}/:bank?`}
          component={CoachWorld}
        />
        <Route
          path={`/${Routes.coach}/:schoolId/${Routes.allTimeLeaderboard}/:bank?`}
          component={CoachWorld}
        />
        <Route
          path={`/${Routes.coach}/:schoolId/${Routes.districtLeaderboard}/:bank?`}
          component={CoachWorld}
        />
        <Route
          // exact
          path={`/${Routes.school}/:schoolId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/${Routes.prospectSheet}/:sport`}
          component={ProspectSheet}
        />
        <Route path={`${match.url}${Routes.welcome}/signup`} render={this.renderHome}  />
        <Route path={`/${Routes.welcome}/${Routes.activateProfileMembership}/:uuid/:teamName/:sportId/:userTeamId/:schoolId`} component={Welcome} />
        <Route path={`/${Routes.welcome}/${Routes.pma}/${Routes.invited}/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId`} component={Welcome} />
        <Route
          path={`${match.url}${Routes.welcome}/:page?/:${Routes.invited}?/:uuid?/:token?/:teamName?/:coachName?/:sportId?/:userTeamId?/:schoolId?`}
          component={Welcome}
        />
        <Route path={`/${Routes.code}/:code`} component={CodeSignUp} />
        <Route exact path={`${match.url}`} render={this.renderHome} />
        <Route
          exact
          path={`/${Routes.profile}`}
          render={this.redirectToPlayerProfile}
        />
        <Route
          exact
          path={`/${Routes.profile}/:uuid`}
          render={this.redirectToPlayerProfile}
        />
        <Route exact path={`/${Routes.combineReport}/${Routes.school}/:schoolId/${Routes.report}/:reportId/:tab?`} render={this.redirectToCombineReport}/>
        <Route
          path={
            `/${Routes.profile}/:uuid/` +
            `${Routes.photos}/expanded/(${Routes.photo}|${Routes.video})/:videoId/:sport?/:schoolId?`
          }
          component={Profile}
        />
        <Route
          path={
            `/${Routes.profile}/:uuid/` +
            `(${Routes.player}|${Routes.data}|${Routes.team}|${Routes.photos}|${Routes.awards}|${Routes.externalProfile}|${Routes.endorsements}|${Routes.joinTeam})` +
            '/:sport?/:schoolId?/edit/:awardId?'
          }
          component={Profile}
        />
        <Route
          path={
            `/${Routes.profile}/:uuid/` +
            `(${Routes.player}|${Routes.data}|${Routes.team}|${Routes.photos}|${Routes.awards}|${Routes.externalProfile}|${Routes.endorsements}|${Routes.joinTeam})` +
            '/:sport?/:schoolId?'
          }
          component={Profile}
        />
        <PrivateRoute
          path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/:schoolId?`}
          uuid={uuid}
          component={Profile}
        />
        <Route path={`/${Routes.school}/:schoolId`} component={CoachWorld} />
        <Route
          path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}`}
          component={CoachWorld}
        />
        <PrivateRoute
          path={`/${Routes.coach}/:page?`}
          uuid={uuid}
          component={CoachWorld}
        />

        <PrivateRoute
          path={`/${Routes.athlete}/${Routes.dashboard}/:schoolId/:page?`}
          uuid={uuid}
          component={CoachWorld}
        />
        <Route
          path={`/${Routes.profile}/:uuid/:page`}
          component={Error404Page}
        />
        <Route render={this.renderHome} />
      </Switch>
    );
  }
}

export default App;
