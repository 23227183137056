import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { ButtonIconOnly } from '../../../../UxCommon';
import StatsEntry from '../StatsRow/StatsEntry/StatsEntry';

class StatsRowNew extends PureComponent {
  static propTypes = {
    season: PropTypes.object.isRequired,
    stdCats: PropTypes.array.isRequired,
    currentSport: PropTypes.object.isRequired,
    onCancelSeasonClick: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    rowId: PropTypes.number.isRequired,
    getFormatCode: PropTypes.func.isRequired,
    rrfFormChange: PropTypes.func.isRequired,
  }

  componentDidMount() { }

  isLastTdInCat = (statPositions, statPosIndex, stdCats, catIndex) => {
    if ((statPosIndex === (statPositions.length - 1))
      && (catIndex !== (stdCats.length - 1))) {
      return true;
    }
    return false;
  }

  seasonDisplayDate = (season, currentSport) => {
    if (!currentSport.usesSeasonEndDate) {
      return season.startDate.format('MM/DD/YY');
    }
    if (season.startDate.year() !== season.stopDate.year()) {
      return `${season.startDate.year()} - ${season.stopDate.year()}`;
    }
    return `${season.startDate.year()}`;
  }

  render() {
    const {
      season,
      stdCats,
      currentSport,
      onCancelSeasonClick,
      getRrfFormModel,
      rowId,
      getFormatCode,
      rrfFormChange,
    } = this.props;
    const formModel = getRrfFormModel();
    return (
      <tr>
        <td className="PlayerData_StatsTable_NameCell">
          {this.seasonDisplayDate(season, currentSport)}
          <Control
            type="hidden"
            model={`${formModel}.new`}
            defaultValue
            value
          />
          <Control
            type="hidden"
            model={`${formModel}.rowId`}
            defaultValue={rowId}
            value={rowId}
          />
        </td>
        {
          stdCats.map((cat, catIndex) => cat.statPositions.map((statPos, statPosIndex) => {
            const stat = { results: '', statFormatCode: getFormatCode(statPos.stdStat.standardStatFormatId) };
            return (
              <td
                className={
                  this.isLastTdInCat(cat.statPositions, statPosIndex, stdCats, catIndex)
                    ? 'CatBorder'
                    : ''
                }
                key={statPos.id}
              >
                {
                  statPos.stdStat.computed ?
                    0
                    :
                    <React.Fragment>
                      <StatsEntry
                        className="PlayerData_StatsRow_Input"
                        model={`${formModel}.stats.${statPos.id}.results`}
                        stat={stat}
                        rrfFormChange={rrfFormChange}
                      />
                      <Control
                        model={`${formModel}.stats.${statPos.id}.id`}
                        type="hidden"
                        defaultValue={statPos.id}
                        value={statPos.id}
                        readOnly
                      />
                      <Control
                        model={`${formModel}.stats.${statPos.id}.statPosId`}
                        type="hidden"
                        defaultValue={statPos.id}
                        value={statPos.id}
                        readOnly
                      />
                    </React.Fragment>
                }
              </td>
            );
          }))
        }
        <td className="PlayerData_StatsRow_DeleteCell">
          <ButtonIconOnly
            type="button"
            className="PlayerData_StatsRow_DeleteButton"
            iconName="delete"
            onClick={onCancelSeasonClick}
          />
        </td>
      </tr>

    );
  }
}

export default StatsRowNew;
