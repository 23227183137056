import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetStats extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    // getStats: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number,
  };

  state = {
    // loadStatsRequired: false,
    sport: 0,
  };

  // static getDerivedStateFromProps(props, state) {
  //   const derivedState = {
  //     loadStatsRequired: false,
  //   };
  //   if (props.uuid !== state.prevUuid) {
  //     derivedState.loadStatsRequired = true;
  //     derivedState.prevUuid = props.uuid;
  //   }
  //   if (props.sportId !== state.prevSportId) {
  //     derivedState.loadStatsRequired = true;
  //     derivedState.prevSportId = props.sportId;
  //   }
  //   return derivedState;
  // }

  // componentDidMount() {
  //   const {uuid,sportId,getTests} =  this.props;
  //   if(sportId){
  //     getTests(uuid,sportId,"Loading Combine Records")
  //   }
  // }
  // componentDidUpdate(){
  //   const { uuid, getTests, sportId } =  this.props;
  //   if(this.state.sport !== sportId){
  //     getTests(uuid,sportId,"Loading Combine Records");
  //     this.setState({sport: sportId})
  //   }
  // }
  // componentDidUpdate() {
  //   const {
  //     uuid,
  //     sportId,
  //     getTests,
  //   } = this.props;
  //   const {
  //     loadStatsRequired,
  //   } = this.state;
  //   if (loadStatsRequired) {
  //     getTests(uuid,sportId,"Loading Combine Records")
  //   }
  // }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetStats;
