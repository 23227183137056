import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/Photos';
import {
  onPhotoDrop,
  selectCategoryPhotos,
  selectCategoryVideos,
  selectCategoryAll,
  openAlbumDialog,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { openMultiDelete, closeMultiDelete, uploadPhoto, uploadVideo, markFavorite, showFilterDialog, clearAllPhotoFilters } from '../../../store/actions/ui/uxProfile/photosVideos/actions';
import { setBottomBanner, sidebarOpenShareDialog } from '../../../store/actions/ui/uxProfile';
import { getCurrentUuid, getProfile, getCurrentSportId } from '../../UxCommon/Utils';
import { redirect, routeChangedAlert, photoPageShareUri } from '../../../store/actions/ui/routes';
import { makeGetMediaList } from '../../../selectors/Photos';
import { getCoachAndAthleteTeams } from '../../../modules/CoachWorld/+store/coachWorld/coachWorld.actions';

const getSports = (state, ownProps) => {
  const myProfile = getProfile(state, ownProps.match);
  if (myProfile) {
    const { sports } = state.data.sport;
    if (sports.length) {
      const mySports = [];
      myProfile.sports.forEach((userSport) => {
        const sport = sports.find(s => s.id === userSport.sportId);
        mySports.push(sport);
      });
      return mySports;
    }
  }
  return [];
};

const getFavorites = (state, media) => {
  const favorites = state.ui.uxProfile.photosVideos.tempFavorites;
  const tempFavoriteObj = favorites.find(m => m.id === media.id);
  if (tempFavoriteObj) {
    return tempFavoriteObj.favorites;
  }
  return media.favorites;
};

const getFilterApplied = state => (
  !!state.ui.uxProfile.photosVideos.selectedAlbumFilters.length
  || !!state.ui.uxProfile.photosVideos.selectedTagsFilters.length
  || state.ui.uxProfile.photosVideos.selectedCategory !== 'all'
);

const mapStateToProps = (state, ownProps) => {
  const photoList = makeGetMediaList(state);
  return {
    authenticating: state.ui.app.mobile.authenticating,
    photoList: photoList(state, ownProps),
    multiDeleteIsOpen: state.ui.uxProfile.photosVideos.multiDelete.stage === 'open',
    multiDeleteSelected: state.ui.uxProfile.photosVideos.multiDelete.selected,
    selectedCategory: state.ui.uxProfile.photosVideos.selectedCategory,
    albumDialogIsShowing: state.ui.uxProfile.photosVideos.albumDialog.isShowing,
    selectAlbumDialogIsShowing: state.ui.uxProfile.photosVideos.selectAlbumDialog.isShowing,
    expandedDialogIsOpen: !!state.ui.uxProfile.photosVideos.expandedDialog.selectedMedia,
    isFilterListShown: state.ui.uxProfile.photosVideos.showFilterList,
    tagDialogIsShowing: state.ui.uxProfile.photosVideos.tagDialog.isShowing,
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    mySports: getSports(state, ownProps),
    photosLoading: state.ui.uxProfile.photosVideos.loading.loading,
    isPhotoFilterDialogShown: state.ui.uxProfile.photosVideos.isPhotoFilterDialogShown,
    isFilterApplied: getFilterApplied(state),
    currentSportId: getCurrentSportId(state),
    currentRoute: ownProps.currentRoute || state.ui.app.routes.currentRoute,
    uuid: state.data.cognito.uuid,
    isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
    user: getProfile(state, ownProps.match),
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { isCoach,currentSportId,state, ...newStateProps } = stateProps;
  const uuid = getCurrentUuid(state, ownProps.match);
  const coachSelectedSchoolID  = ownProps.match.params.schoolId;
  return {
    ...newStateProps,
    ...ownProps,
    photosShareUri: photoPageShareUri(uuid,coachSelectedSchoolID,currentSportId),
    onDeleteClick: (component) => {
      dispatch(openMultiDelete());
      dispatch(setBottomBanner(component));
    },
    onMultiDeleteCancelClick: () => {
      dispatch(closeMultiDelete());
      dispatch(setBottomBanner(null));
    },
    onAddClick: () => {
      dispatch(openAlbumDialog(null));
    },
    onPhotosClick: () => {
      dispatch(selectCategoryPhotos());
    },
    onVideosClick: () => {
      dispatch(selectCategoryVideos());
    },
    onAllClick: () => {
      dispatch(selectCategoryAll());
    },
    onShareClick: () => {
      dispatch(sidebarOpenShareDialog(photoPageShareUri(uuid,coachSelectedSchoolID,currentSportId)));
    },
    onDrop: onPhotoDrop(dispatch),
    changeRoute: (pathname) => {
      dispatch(redirect());
      dispatch(routeChangedAlert(pathname));
    },
    uploadPhoto: (photo) => {
      dispatch(uploadPhoto(photo));
    },
    uploadVideo: (video) => {
      dispatch(uploadVideo(video));
    },
    onSaveFavorites: (media, sportIds) => {
      // dispatch(favoriteAward(award.id, sportIds));
      dispatch(markFavorite(media, media.isAPhoto, sportIds));
    },
    // eslint-disable-next-line
    getPhotoVideoFavorites: (media) => {
      return getFavorites(state, media);
    },
    onShowFilterDialogClick: () => {
      dispatch(showFilterDialog());
    },
    onClearAllFiltersClick: () => {
      dispatch(clearAllPhotoFilters());
    },
    getCoachAndAthleteTeams: (coachId, athleteId) => {
      dispatch(getCoachAndAthleteTeams(coachId, athleteId));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
