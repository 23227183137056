import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class OldPhoto extends PureComponent {
  static propTypes = {
    srcUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  state = {
    status: 'loading',
  };

  componentDidMount() {
    const { srcUrl } = this.props;
    this.img = new Image();
    this.img.onload = () => {
      this.setState({
        status: 'good',
      });
    };
    this.img.onerror = () => {
      this.setState({
        status: 'bad',
      });
    };
    this.img.src = srcUrl;
  }

  componentWillUnmount() {
    this.img.onload = null;
    this.img.onerror = null;
  }

  onClick = () => {
    const { onClick, srcUrl } = this.props;
    onClick(srcUrl);
  }

  render() {
    const { srcUrl } = this.props;
    const { status } = this.state;
    if (status === 'bad') {
      return null;
    }
    return (
      <button className="UPPDialog_OldProfilePhotos_PhotoButton" onClick={this.onClick}>
        <div className="UPPDialog_OldProfilePhotos_Photo">
          {
            status === 'loading' ?
              <div>Loading...</div>
            :
              <div className="UxProfile_S_ProfilePhotoImg" style={{ backgroundImage: `url('${srcUrl}')` }} />
          }
        </div>
      </button>
    );
  }
}

export default OldPhoto;
