import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import cssVar from '../../../../components/App/App.utils';
import vcLogo from "../../../../assets/images/vc-black.png";
import moment from 'moment';

const PrintExperiment = (props) => {
  const {
    children,
    report
  } = props;
  let combines = 'Combine Comparison Report';
  let closeDate = '';
  if (report) {
    const closeDate1 = moment(report.combine.closed_at);
    const closeDate2 = moment(report.second_combine.closed_at);
    const compareDate = closeDate1 < closeDate2;
    if (compareDate) {
      combines = `${report.combine.name} & ${report.second_combine.name}`;
      closeDate = ` - ${closeDate1.format('MM.DD.YYYY')}`;
    } else {
      combines = `${report.second_combine.name} & ${report.combine.name}`;
      closeDate = ` - ${closeDate2.format('MM.DD.YYYY')}`;
    }
  }
  const styles = document.getElementsByTagName('style')[0].innerHTML;
  return (
    <Frame className="PrintReport_IFrame" id="printable">
      <div>
        <style>{styles}</style>
        <style>
          {`
            * {
              -webkit-print-color-adjust: exact;
              --primary: ${cssVar('primary')};
              --secondary: ${cssVar('secondary')};
            }
            html, body, #root {
              overflow: hidden;
              height: unset;
            }
            @page {
              margin-bottom: 0;
            }
            html {
              overflow: auto;
            }
            .CoachTable {
              width: 100%;
            }
            .CoachBanner {
              height: 40px;
              margin-top: -25px;
              margin-bottom: 40px;
            }
            .CoachBanner_UpperRight {
              top: 20px;
              right: 20px;
              bottom: unset;
            }
            .CoachRoster_BannerChildren {
              height: 100%;
              justify-content: center;
              padding-top: 30px;
            }
            .CoachRoster_BannerChildren2 {
              height: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: flex-start;
              padding-top: 30px;
            }
            .CoachRoster_BannerLeft {
              padding-bottom: 0px;
            }
            .CoachTableRow {
            }
            tbody .CoachTableRow {
              height: 20px;
            }
            .CoachTableCell {
              justify-content: center;
              align-items: center;
            }
            .CoachTableCell:first-child > * {
              width: 100%;
            }
            .CoachRoster_BannerChildren {
              height: 100%;
              justify-content: center;
              top: 0;
              left: 0;
              width: 100%;
            }
            .RawComparisonPage_TeamCell {
              padding: 15px;
            }
            .CoachRoster_BannerFullSubtitle {
              margin: 0;
            }
            .Print_VCLogo {
              height:40px;
              width:40px;
              margin-right:10px;
            }
            .Print_ReportCombineName {
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
            }
          `}
        </style>
        {
          report && (
            <div className="CoachBanner">
              <div className="CoachRoster_BannerChildren2" >
              <img className="Print_VCLogo" src={vcLogo} alt="BACKGRO0ND" />
              <div className="Print_ReportCombineName">
              <div className="CoachRoster_BannerFullTitle">{combines}</div>
              <div className="CombineReportPage_BannerText">{`REPORT${closeDate}`}</div>
              </div>
              </div>
            </div>
          )
        }
        {children}
      </div>
    </Frame>
  );
};
PrintExperiment.propTypes = {
  children: PropTypes.any,
};

export default PrintExperiment;
