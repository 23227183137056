import React from 'react';
import PropTypes from 'prop-types';
import { StatsDecimalControl, StatsTimeControl, StatsInchesControl } from '../../../../../UxCommon/Form';

class StatsEntry extends React.PureComponent {
  static propTypes = {
    stat: PropTypes.object.isRequired,
    className: PropTypes.string,
    model: PropTypes.string.isRequired,
    rrfFormChange: PropTypes.func.isRequired,
  }

  componentDidMount() { }

  render() {
    const {
      stat,
      className,
      model,
      rrfFormChange,
    } = this.props;
    switch (stat.statFormatCode) {
      
      case 'NUMERIC-HUNDREDTHS':
        return (
          <StatsDecimalControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 123.25"
            placesAfterDecimal={2}
            rrfFormChange={rrfFormChange}
          />
        );
      case 'NUMERIC-TENTHS':
      return (
        <StatsDecimalControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 16.1"
            placesAfterDecimal={1}
            rrfFormChange={rrfFormChange}
            stdStatCode = {stat.stdStat ? stat.stdStat.code : ""}
          />
        );
      case 'NUMERIC-INTEGER':
        return (
          <StatsDecimalControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 123"
            placesAfterDecimal={0}
            rrfFormChange={rrfFormChange}
          />
        );
      case 'NUMERIC-THOUSANDTHS':
        return (
          <StatsDecimalControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 123.125"
            placesAfterDecimal={3}
            rrfFormChange={rrfFormChange}
          />
        );
      case 'LENGTH-QUARTERINCH':
        return (
          <StatsInchesControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 123.25"
            placesAfterDecimal={2}
            rrfFormChange={rrfFormChange}
          />
        );
      case 'TIME-HUNDREDTHS':
        return (
          <StatsTimeControl
            className={className}
            model={model}
            defaultValue={stat.results}
            rrfFormChange={rrfFormChange}
          />
        );
      case 'TIME-INTEGER':
        return (
          <StatsTimeControl
            className={className}
            model={model}
            defaultValue={stat.results}
            rrfFormChange={rrfFormChange}
            isInteger
          />
        );
      default:
        return (
          <StatsDecimalControl
            className={className}
            model={model}
            defaultValue={stat.results}
            help="Example: 123.25"
            placesAfterDecimal={2}
            rrfFormChange={rrfFormChange}
          />
        );
    }
  }
}

export default StatsEntry;
