import React, { useRef } from 'react';
import PropTypes from 'prop-types';

export const GetMediaDimension = ({ imgUrl, updateDimension }) => {
  const imgRef = useRef(null);
  const onLoad = () => {
    if (imgRef) {
      const { naturalHeight, naturalWidth } = imgRef.current;
      updateDimension(naturalWidth, naturalHeight);
    }
  };
  return (
    <img
      style={{ display: 'none' }}
      alt="thumbnail"
      src={imgUrl}
      ref={imgRef}
      onLoad={onLoad}
    />
  );
};

GetMediaDimension.prototype = {
  imgUrl: PropTypes.string.isRequired,
  updateDimension: PropTypes.func.isRequired,
};

export default GetMediaDimension;
