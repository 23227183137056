import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../../assets/images/spinner-red.gif';

const LoadingSpinner = ({
  loading,
  loadingMsg,
  loadingProgress,
  className,
  style,
}) => {
  if (!loading) return <div />;
  return (
    <div className="UxCommon_LoadingSpinnerContainer" style={style || {}}>
      <div className={`UxCommon_LoadingSpinner ${className || ''}`}>
        {/* <div>Loading ...</div> */}
        <img src={Spinner} alt="Loading ..." />
        <div>{loadingMsg}</div>
        {
          loadingProgress &&
            <div>{`${loadingProgress.percent}%`}</div>
        }
      </div>
    </div>
  );
};
LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingMsg: PropTypes.string.isRequired,
  loadingProgress: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingSpinner;
