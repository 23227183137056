import {combineEpics, ofType} from 'redux-observable';
import {catchError, delay, map, mergeMap, retryWhen, switchMap, takeUntil, tap,} from 'rxjs/operators';
import {forkJoin, of, Subscriber} from 'rxjs';
import {actions} from 'react-redux-form';
import {asyncErrorAction, asyncFinishAction,} from '../../../../store/actions/ui/async';
import {
  checkForkErrors,
  getTokenFragment,
  getTokenFragmentMap,
  getTokenFragmentMergeMap,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import {
  ADD_USER_TO_SCHOOL_TEAM,
  ATHLETE_INVITE,
  CHANGE_ATHLETES_TEAM,
  CHECK_IF_CARD_VIEW,
  CONFIRM_MERGE_PROFILES,
  CREATE_ATHLETE_PROFILE,
  CREATE_ATHLETE_PROFILE_AND_INVITE,
  CREATE_BULK_ATHLETE_PROFILES,
  CREATE_BULK_ATHLETE_PROFILES_AND_INVITE,
  CREATE_HEAD_COACH,
  CREATE_RECRUITING_COORDINATOR,
  DELETE_HEAD_COACH,
  DELETE_RECRUITING_COORDINATOR, DELETE_SCHEDULE,
  DELETE_TWITTER_LINK,
  deleteHeadCoach,
  GET_PROFILE_BY_EMAIL,
  GET_PROFILES,
  GET_ROSTER, GET_SCHOOL_TEAM_SCHEDULE,
  getRoster, getSchoolTeamScheduleSuccess,
  GUEST_PROFILE_BY_EMAIL_GET, REMOVE_USER_FROM_PROSPECT_TAM,
  REMOVE_USER_FROM_TEAM,
  SET_LOADING_FOR_RECONFIGURE_PAGE,
  UPDATE_ATHLETE_INFO,
  UPDATE_ATHLETE_INVITE_CHUNK_STATE,
  UPDATE_ATHLETE_PROFILE_INFO,
  UPDATE_ATHLETE_TEAM_INFO,
  UPDATE_CARD_VIEW,
  UPDATE_HEAD_COACH,
  UPDATE_RECRUITING_COORDINATOR,
  UPDATE_TEAM_NAME,
  UPDATE_TWITTER_LINK,
  UPDATE_USER_SPORTS_INFO,
  UPDATE_USER_TEAM_INFO,
  updateHeadCoach,
  UPLOAD_SCHEDULE
} from "./roster.actions";
import {
  addUserToSchoolTeam,
  checkCardView,
  createBulkProfiles,
  createHeadCoachApi,
  createProfile,
  createRecruitingCoordinatorApi,
  createSchedule,
  deleteHeadCoachApi,
  deleteRecruitingCoordinatorApi, deleteScheduleApi,
  deleteTwitterLinkApi, flagScheduleUploaded,
  getSchoolTeams, getSchoolTeamSchedule,
  inviteAthlete, removeUserFromProspectTeam,
  removeUserFromTeam,
  updateHeadCoachApi,
  updateRecruitingCoordinatorApi,
  updatesRosterView,
  updatesTeamName,
  updateTwitterLinkApi,
  updateUserSportInfo,
  updateUserTeamInfo, uploadScheduleToS3,
} from './roster.api';
import {
  getUserProfile,
  HeadCoach,
  mapUserUiToSportsApi,
  mapUserUiToSportsInviteApi,
  RecruitingCoordinator,
  SchoolTeam,
  SchoolTeamMember,
  TwitterLink
} from "./roster.models";
import {
  getAjaxProfileByEmail,
  getBulkProfilesSuccess,
  getGuestProfileByEmail,
  getPlayerProfile,
  getPlayerProfileSuccess,
  mergeGridProfile,
  profileUpdate,
  profileUpdateSuccess
} from "../../../../store/actions/data/user/profile";
import {mapScheduleApiToUi, mapUserApiToUi, mapUserUiToApi} from '../../../../store/actions/data/user/profile/models';
import {
  addMediaToAlbum,
  createAlbum,
  createPhoto,
  flagPhotoUploaded,
  flagTranscriptUploaded,
  getAlbums,
  getPhoto,
  mapAlbumApiToUi,
  mapPhotoApiToUi,
  mapTranscriptApiToUi,
  uploadPhotoToS3,
  uploadTranscriptToS3,
} from '../../../../store/actions/data/photo';
import {Routes} from '../../../../store/actions/ui/routes';
import {cancelOnRouteChange} from '../../../../store/actions/ui/uxEditProfile/utils';
import {ajaxErrorHandlerEpicFragment} from '../../../../store/actions/ui/ajaxErrorHandlers';
import {fixPhotoOrientation} from '../../../../containers/UxCommon/NativeUtils';
import {
  EP_PHOTO_REMOVE_ATHLETE,
  EP_PHOTO_SAVE_NEW_ATHLETE,
  uploadProgressUpdate,
  USER_ATHLETE_PHOTO,
} from './roster.photo.actions';
import {userSportUpdateSuccess} from '../../../../store/actions/data/user';
import {UPLOAD_TRANSCRIPT} from "../../../ProspectSheet/+store";

const getSchoolTeamsEpic = action$ => (
  action$.pipe(
    ofType(GET_ROSTER),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getSchoolTeams(action.schoolId, action.sportId, token, action.query)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getRoster',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const allAthletes = [];
        const allCoaches = [];
        const allAdmins = [];
        const allSchoolTeams = [];
        result.response.forEach((schoolTeamResponse) => {
          const {
            team, athletes, coaches, admins,
          } = SchoolTeam.fromApi(schoolTeamResponse);
          athletes.forEach(a => allAthletes.push(a));
          allSchoolTeams.push(team);
          coaches.forEach(c => allCoaches.push(c));
          admins.forEach(a => allAdmins.push(a));
        });
        return of(asyncFinishAction(result.action.type, 'getUsersTeams', {
          schoolTeams: allSchoolTeams,
          athletes: allAthletes,
          coaches: allCoaches,
          admins: allAdmins,
          schoolId: result.action.schoolId,
          sportId: result.action.sportId,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getSchoolTeamsScheduleEpic = action$ => (
    action$.pipe(
        ofType(GET_SCHOOL_TEAM_SCHEDULE),
        getTokenFragment(),
        switchMap(({action, token}) => (
          forkJoin(...action.schoolTeamIds.map(schoolTeamId => (
            getSchoolTeamSchedule(schoolTeamId, token)
              .pipe(
                tap(response => action.dispatch(getSchoolTeamScheduleSuccess({
                  schedule: mapScheduleApiToUi(response),
                }))),
                manualCancelApiCallFragment(
                  action$,
                  action,
                  GET_SCHOOL_TEAM_SCHEDULE,
                ),
              )
          )))
            .pipe(
              checkForkErrors(),
              map((forkResults) => {
                if (forkResults.success) {
                  return {
                    ...forkResults,
                    action,
                  };
                }
                return forkResults;
              }),
            )
        )),
        switchMap((result) => {
            if (result.success) {
                return of(asyncFinishAction(result.action.type, GET_SCHOOL_TEAM_SCHEDULE, {
                }));
            }
            if (result.actions) return of(...result.actions);
            return of(result.action);
        }),
    )
);

const updateHeadCoachEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_HEAD_COACH),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateHeadCoachApi(token, action.id, action.props)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateHeadCoach',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const headCoach = HeadCoach.fromApi(result.response[0]);
        return of(asyncFinishAction(result.action.type, 'updateHeadCoach', {
          headCoach
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteHeadCoachEpic = (action$, state$) => (
  action$.pipe(
    ofType(DELETE_HEAD_COACH),
    getTokenFragment(),
    switchMap(({action, token}) => (
      deleteHeadCoachApi(token, action.id)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteHeadCoach',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'deleteHeadCoach', {
          schoolTeamId: result.action.schoolTeamId
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteScheduleEpic = (action$, state$) => (
    action$.pipe(
        ofType(DELETE_SCHEDULE),
        getTokenFragment(),
        switchMap(({action, token}) => (
            deleteScheduleApi(token, action.schoolTeamId, action.scheduleId)
                .pipe(manualCancelApiCallFragment(
                    action$,
                    action,
                    DELETE_SCHEDULE,
                ))
        )),
        switchMap((result) => {
            if (result.success) {
                return of(asyncFinishAction(result.action.type, DELETE_SCHEDULE, {
                  schoolTeamId: result.action.schoolTeamId
                }));
            }
            if (result.actions) return of(...result.actions);
            return of(result.action);
        }),
    )
);

const createHeadCoachEpic = action$ => (
  action$.pipe(
    ofType(CREATE_HEAD_COACH),
    getTokenFragment(),
    switchMap(({action, token}) => (
      createHeadCoachApi(token, action.props)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'createHeadCoach',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const headCoach = HeadCoach.fromApi(result.response[0]);
        return of(asyncFinishAction(result.action.type, 'createHeadCoach', {
          headCoach
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const updateTwitterLinkEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_TWITTER_LINK),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateTwitterLinkApi(token, action.props)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateTwitterLink',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const twitterLink = TwitterLink.fromApi(result.response[0]);
        return of(asyncFinishAction(result.action.type, 'updateTwitterLink', {
          twitterLink
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteTwitterLinkEpic = (action$, state$) => (
  action$.pipe(
    ofType(DELETE_TWITTER_LINK),
    getTokenFragment(),
    switchMap(({action, token}) => (
      deleteTwitterLinkApi(token, action.id)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteTwitterLink',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'deleteTwitterLink', {
          schoolTeamId: result.action.id
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const updateRecruitingCoordinatorEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_RECRUITING_COORDINATOR),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateRecruitingCoordinatorApi(token, action.id, action.props)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateRecruitingCoordinator',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const recruitingCoordinator = RecruitingCoordinator.fromApi(result.response[0]);
        return of(asyncFinishAction(result.action.type, 'updateRecruitingCoordinator', {
          recruitingCoordinator
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteRecruitingCoordinatorEpic = (action$, state$) => (
  action$.pipe(
    ofType(DELETE_RECRUITING_COORDINATOR),
    getTokenFragment(),
    switchMap(({action, token}) => (
      deleteRecruitingCoordinatorApi(token, action.id)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteRecruitingCoordinator',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'deleteRecruitingCoordinator', {
          schoolTeamId: result.action.schoolTeamId
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const createRecruitingCoordinatorEpic = action$ => (
  action$.pipe(
    ofType(CREATE_RECRUITING_COORDINATOR),
    getTokenFragment(),
    switchMap(({action, token}) => (
      createRecruitingCoordinatorApi(token, action.props)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'createRecruitingCoordinator',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const recruitingCoordinator = RecruitingCoordinator.fromApi(result.response[0]);
        return of(asyncFinishAction(result.action.type, 'createRecruitingCoordinator', {
          recruitingCoordinator
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const getProfilesEpic = action$ => (
  action$.pipe(
    ofType(GET_PROFILES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.uuids.map(uuid => (
        getPlayerProfile(uuid, token)
          .pipe(
            tap(response => action.dispatch(getPlayerProfileSuccess(
              uuid,
              mapUserApiToUi(response),
            ))),
            manualCancelApiCallFragment(
              action$,
              action,
              'getProfiles',
            ),
          )
      )))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(GET_PROFILES, 'getProfiles', {}));
      }
      if (result.actions) {
        return of(
          ...result.actions,
          asyncErrorAction(GET_PROFILES, 'getProfiles', {}),
        );
      }
      return of(result.action);
    }),
  )
);

const updateAthleteProfileInfoEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_ATHLETE_PROFILE_INFO),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => profileUpdate(
      action.uuid,
      mapUserUiToApi(action.profile.uuid, action.profile),
      token,
      true,
    )
      .pipe(
        map(() => ({
          success: true,
          action,
          token: token
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(action.type, 'userProfileUpdate', error),
        })),
      )),
    mergeMap((result) => {
      if(result.action.profile.isTeamNameChanged){
        const values = {
          name: result.action.profile.teamName,
          school_team_id: result.action.profile.schoolTeamId,
          sport_id: result.action.profile.sportId,
          team_reference_id: result.action.profile.teamRefId
        }
        return  updatesTeamName(result.token, values)
          .pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'updateTeamName',
          ));
      }
      return of(result);
    }),
    mergeMap((result) => {
      if (result.success) {
        return getPlayerProfile(result.action.uuid, result.token)
          .pipe(
            map(playerProfile => ({
              ...result,
              profile:
                mapUserApiToUi(
                  playerProfile,
                  result.action.profile.schools,
                  result.action.profile.sports,
                  null,
                  null,
                  result.action.profile.teamName,
                  result.action.profile.schoolTeamId,
                  result.action.profile.isTeamNameChanged,
                ),
              success: true,
            })),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(result.action.type, 'userProfileUpdate', error),
            })),
          );
      }
      return of(result);
    }),
    mergeMap(({
                success,
                profile,
                action,
              }) => {
      if (success) {
        return of(profileUpdateSuccess(profile));
      }
      return of(action);
    }),
  );


const createBulkAthleteProfilesEpic = action$ => action$.pipe(
  ofType(CREATE_BULK_ATHLETE_PROFILES),
  getTokenFragmentMergeMap(),
  mergeMap(({action, token}) =>
    createBulkProfiles(
      token,
      action.profiles,
    )
      .pipe(
        map(response => ({
          success: true,
          action,
          response,
          token,
          dispatch: action.dispatch,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(() => of({
          success: false,
          action: asyncErrorAction(action.type, 'createBulkAthleteProfiles', {}),
        })),
      )),
  mergeMap((result) => {
    if (result.success) {
      result.dispatch(getRoster(result.action.schoolId, result.action.sportId));
      const profiles = {};
      result.response.forEach((profile) => {
        profiles[profile.aws_uuid] = mapUserApiToUi(profile);
      });
      return of(
        getBulkProfilesSuccess(profiles),
        asyncFinishAction(result.action.type, 'createBulkAthleteProfiles', {}),
      );
    }
    return of(asyncErrorAction(CREATE_BULK_ATHLETE_PROFILES, 'createBulkAthleteProfiles', {}));
  }),
);


let lastCreatedAthlete = '';
const createAthleteProfileEpic = action$ => action$.pipe(
  ofType(CREATE_ATHLETE_PROFILE),
  getTokenFragmentMergeMap(),
  mergeMap(({action, token}) => {
    if (lastCreatedAthlete === action.rowNumber) {
      return of({type: 'IGNORE'});
    }
    lastCreatedAthlete = action.rowNumber;
    return createProfile(
      token,
      mapUserUiToSportsApi(action.profile),
    )
      .pipe(
        map(response => ({
          success: true,
          action,
          response,
          token,
          dispatch: action.dispatch,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(() => of({
          success: false,
          action: asyncErrorAction(action.type, 'createAthleteProfile', {}),
        })),
      );
  }),
  mergeMap((result) => {
    if (result.success) {
      return getPlayerProfile(result.response.aws_uuid, result.token)
        .pipe(
          map(response => ({
            success: true,
            ...result,
            profile: mapUserApiToUi(response),
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createAthleteProfile', {}),
          })),
        );
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      result.dispatch(getRoster(result.action.schoolId, result.action.sportId));
      return of(
        getPlayerProfileSuccess(
          result.profile.uuid,
          result.profile,
        ),
        asyncFinishAction(result.action.type, 'createAthleteProfile', {}),
      );
    }
    return of(asyncErrorAction(CREATE_ATHLETE_PROFILE, 'createAthleteProfile', {}));
  }),
);

let lastInvitedAthlete = '';
const athleteInviteEpic = action$ => action$.pipe(
  ofType(ATHLETE_INVITE),
  getTokenFragmentMap,
  mergeMap(({action, token}) => {
    if (lastInvitedAthlete === action.rowNumber) {
      return of({type: 'IGNORE'});
    }
    lastInvitedAthlete = action.rowNumber;
    return (
      inviteAthlete(
        token,
        mapUserUiToSportsInviteApi({uuid: action.profile.userId, ...action.profile}, action.sportId),
      )
        .pipe(
          map(response => ({
            success: true,
            response,
            uuid: action.profile.userId,
            action,
            token,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(action.type, 'athleteInvite', {}),
          })),
        ));
  }),
  mergeMap((result) => {
    if (result.success) {
      return getPlayerProfile(result.uuid, result.token)
        .pipe(
          map(response => ({
            success: true,
            ...result,
            profile: mapUserApiToUi(response),
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'athleteInvite', {}),
          })),
        );
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      return of(
        getPlayerProfileSuccess(
          result.profile.uuid,
          result.profile,
        ),
        asyncFinishAction(result.action.type, 'athleteInvite', {userId: result.action.profile.userId}),
        asyncFinishAction(UPDATE_ATHLETE_INVITE_CHUNK_STATE, UPDATE_ATHLETE_INVITE_CHUNK_STATE, {userId:result.profile.uuid}),
      );
    }
    return of(asyncErrorAction(ATHLETE_INVITE, 'athleteInvite', {}));
  }),
);

const createBulkAthleteProfilesAndInviteEpic = action$ => action$.pipe(
  ofType(CREATE_BULK_ATHLETE_PROFILES_AND_INVITE),
  getTokenFragmentMap,
  mergeMap(({action, token}) => (
    createBulkProfiles(
      token,
      {
        school_team_id: action.profiles.schoolTeamId,
        users: action.profiles.users.map(mapUserUiToSportsApi),
      },
    )
      .pipe(
        map(response => ({
          success: true,
          action,
          response,
          token,
          dispatch: action.dispatch,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(() => of({
          success: false,
          action: asyncErrorAction(action.type, 'createBulkAthleteProfilesAndInvite', {}),
        })),
      ))),
  mergeMap((result) => {
    if (result.success) {
      if (result.response && result.response.length) {
        return forkJoin(result.response.filter(p => !!p.parent_email || !!p.email).map((profile) => {
          const userProfile = getUserProfile(result.action.profiles, profile);
          const mappedProfile = mapUserUiToSportsInviteApi(
            {uuid: profile.aws_uuid, ...userProfile},
            result.action.sportId,
          );
          if (!mappedProfile && ( !mappedProfile.parent_email || !mappedProfile.email )) {
            return of({
              action: result.action,
              success: true,
            });
          }
          return (
            inviteAthlete(
              result.token,
              mappedProfile,
            ).pipe(
              map(response => ({
                success: true,
                response,
                uuid: profile.aws_uuid,
                ...profile,
              })),
              ajaxErrorHandlerEpicFragment(),
              catchError(() => of({
                success: false,
                action: asyncErrorAction(result.action.type, 'createBulkAthleteProfilesAndInvite', {}),
              })),
            )
          );
        })).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action: result.action,
              };
            }
            return forkResults;
          }),
        );
      }
      return of({
        uuid: result.response.aws_uuid,
        success: true,
        ...result,
      });
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      result.action.dispatch(getRoster(result.action.schoolId, result.action.sportId));
      const profiles = {};
      result.results && result.results.forEach((profile) => {
        profiles[profile.aws_uuid] = mapUserApiToUi(profile);
      });
      return of(
        getBulkProfilesSuccess(profiles),
        asyncFinishAction(result.action.type, 'createAthleteProfileAndInvite', {}),
      );
    }
    return of(asyncErrorAction(CREATE_BULK_ATHLETE_PROFILES_AND_INVITE, 'createAthleteProfileAndInvite', {}));
  }),
);

let lastCreatedInvitedAthlete = '';

const createAthleteProfileAndInviteEpic = action$ => action$.pipe(
  ofType(CREATE_ATHLETE_PROFILE_AND_INVITE),
  getTokenFragmentMap,
  mergeMap(({action, token}) => {
    if (lastCreatedInvitedAthlete === action.rowNumber) {
      return of({type: 'IGNORE'});
    }
    lastCreatedInvitedAthlete = action.rowNumber;
    return (
      createProfile(
        token,
        mapUserUiToSportsApi(action.profile),
      )
        .pipe(
          map(response => ({
            success: true,
            action,
            response,
            token,
            dispatch: action.dispatch,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(action.type, 'createAthleteProfileAndInvite', {}),
          })),
        ));
  }),
  mergeMap((result) => {
    if (result.success) {
      if (result.action.profile.email) {
        return (
          inviteAthlete(
            result.token,
            mapUserUiToSportsInviteApi({uuid: result.response.aws_uuid, ...result.action.profile}, result.action.sportId),
          )
            .pipe(
              map(response => ({
                success: true,
                response,
                uuid: result.response.aws_uuid,
                ...result,
              })),
              ajaxErrorHandlerEpicFragment(),
              catchError(() => of({
                success: false,
                action: asyncErrorAction(result.action.type, 'createAthleteProfileAndInvite', {}),
              })),
            ));
      }
      return of({
        uuid: result.response.aws_uuid,
        success: true,
        ...result,
      });
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      return getPlayerProfile(result.uuid, result.token)
        .pipe(
          map(response => ({
            success: true,
            ...result,
            profile: mapUserApiToUi(response),
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createAthleteProfileAndInvite', {}),
          })),
        );
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      result.dispatch(getRoster(result.action.schoolId, result.action.sportId));
      return of(
        getPlayerProfileSuccess(
          result.profile.uuid,
          result.profile,
        ),
        asyncFinishAction(result.action.type, 'createAthleteProfileAndInvite', {}),
      );
    }
    return of(asyncErrorAction(CREATE_ATHLETE_PROFILE_AND_INVITE, 'createAthleteProfileAndInvite', {}));
  }),
);

// Todo: This epic is untested
const updateAthleteTeamInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ATHLETE_TEAM_INFO),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => {
      return updateUserTeamInfo(token, action.values)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateAthleteTeamInfo',
        ))
    }),
    mergeMap((result) => {
      if (result.success) {
        const currentTeam = state$ && state$.value.modules.coachWorld.roster.schoolTeams &&
          state$.value.modules.coachWorld.roster.schoolTeams.find(e => e.id === result.response.school_team_id);
        if(result.action.total === result.action.currentCount){
          return of(asyncFinishAction(
              result.action.type, result.action,
              SchoolTeamMember.fromSchoolTeamApi(
                result.response.user_id,
                result.action.schoolId,
                result.response,
                result.action.values.teamName,
                currentTeam,
              ),
            ),
            asyncFinishAction(SET_LOADING_FOR_RECONFIGURE_PAGE, SET_LOADING_FOR_RECONFIGURE_PAGE, {})
          );
        }else{
          return of(asyncFinishAction(
              result.action.type, result.action,
              SchoolTeamMember.fromSchoolTeamApi(
                result.response.user_id,
                result.action.schoolId,
                result.response,
                result.action.values.teamName,
                currentTeam,
              ),
            )
          );
        }
      }
      return of(asyncFinishAction(result.action.type, result.action, {}));

    }),
  );

const updateAthleteInfoEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_ATHLETE_INFO),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      updateUserTeamInfo(token, action.values)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateAthleteInfo',
        )))),
    mergeMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(
            result.action.type, result.action,
            SchoolTeamMember.fromRosterTeamApi(
              result.response.user_id,
              result.action.schoolId,
              result.response,
            ),
          ),
          asyncFinishAction(SET_LOADING_FOR_RECONFIGURE_PAGE, SET_LOADING_FOR_RECONFIGURE_PAGE, {}));
      }
      return of(asyncFinishAction(result.action.type, result.action, {}));
    }),
  );

const updateSportInfoEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_USER_SPORTS_INFO),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      updateUserSportInfo(token, action.userId, action.sportId, action.values)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateAthleteTeamInfo',
        )))),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(
          result.action.type, result.action,
          SchoolTeamMember.fromUserSportApi(
            result.action.userId,
            result.action.sportId,
            result.response,
            result.action.userTeamId,
          ),
        ));
      }
      return of(asyncFinishAction(result.action.type, result.action, {}));
    }),
  );

const updateUserTeamInfoEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_USER_TEAM_INFO),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      updateUserTeamInfo(token, action.values)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateAthleteTeamInfo',
        )))),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(
          result.action.type, result.action,
          SchoolTeamMember.fromRosterApi(
            result.action.schoolId,
            result.response,
          ),
        ));
      }
      return of(asyncFinishAction(result.action.type, result.action, {}));
    }),
  );

// Todo: This epic is untested
const changeAthletesTeamEpic = action$ => (
  action$.pipe(
    ofType(CHANGE_ATHLETES_TEAM),
    getTokenFragment(),
    switchMap(({action, token}) => (
      // Todo: This is just a placeholder
      updateUserTeamInfo(action.schoolId, action.sportId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateAthleteTeamInfo',
          {token},
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        // Todo: This is just a placeholder assuming we'll
        // need a second api call to update users team
        return updateUserTeamInfo(
          result.action.schoolId,
          result.action.sportId,
          result.token,
        )
          .pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'updateAthleteTeamInfo',
          ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'updateAthleteTeamInfo', {}));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export const createMediaEpicFragment = action$ => source =>
  source.pipe(
    switchMap((incoming) => {
      const {
        uuid,
        token,
        asyncModel,
        caption,
        file,
      } = incoming;
      if (file) {
        return createPhoto(token, {caption})
          .pipe(
            map(response => ({
              incoming,
              success: true,
              response,
            })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              actions: [
                asyncErrorAction(asyncModel, 'create photo', error),
                actions.setErrors('forms.albumDialog', {
                  general: 'Uh oh, there was an error saving your photo',
                }),
              ],
            })),
          );
      }
      return of({
        success: false,
        actions: [asyncErrorAction(asyncModel, 'create photo', 'Unknown Error')],
      });
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          asyncModel,
          file,
        } = result.incoming;
        if (file && file.originalRotation && file.originalRotation !== 0) {
          return fixPhotoOrientation(file)
            .pipe(
              map(response => ({
                ...result,
                incoming: {
                  ...result.incoming,
                  file: response,
                },
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(asyncModel, 'fixPhotoOrientation', error),
              })),
            );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          asyncModel,
          file,
          dispatch,
          totalBytes,
          mediaId,
        } = result.incoming;
        if (file) {
          const ps = Subscriber.create(
            (e) => {
              dispatch(uploadProgressUpdate(e, totalBytes, mediaId));
            },
            e => console.log('error', e),
            () => console.log('upload complete'),
          );
          return uploadPhotoToS3(result.response.upload_url, file, ps)
            .pipe(
              map(response => ({
                ...result,
                s3Response: response,
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(asyncModel, 'uploadPhotoToS3', error),
              })),
            );
        }
        dispatch(uploadProgressUpdate({
          total: 1,
          loaded: 1,
          lengthComputable: true,
        }, totalBytes, mediaId));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          token,
          asyncModel,
          file,
        } = result.incoming;
        if (file) {
          return flagPhotoUploaded(result.response.id, token)
            .pipe(
              map(response => ({
                ...result,
                response,
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(asyncModel, 'flagPhotoUploaded', error),
              })),
            );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {uuid, token, asyncModel} = result.incoming;
        return getAlbums(uuid, token)
          .pipe(
            map(response => ({
              ...result,
              albums: response,
            })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(asyncModel, 'flagPhotoUploaded', error),
            })),
          );
      }
      return of({...result});
    }),
    switchMap((result) => {
      if (result.success) {
        const {albums, incoming} = result;
        let rosterAlbum = null;
        if (albums && albums.length) {
          rosterAlbum = result.albums.find(x => x.title === 'Roster Headshots');
        }
        if (!rosterAlbum) {
          return createAlbum(incoming.token, {
            title: 'Roster Headshots',
            description: '',
          })
            .pipe(
              map(response => ({
                ...result,
                album: mapAlbumApiToUi(response),
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => incoming.uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(incoming.outer.type, 'createAlbum', error),
              })),
            );
        }
        return of({
          ...result,
          album: rosterAlbum,
        });
      }
      return of({...result});
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          token,
          asyncModel,
        } = result.incoming;
        const myAlbum = result.album;
        if (myAlbum) {
          const media = {
            photo_id: result.response.id,
            ordering: 0,
          };
          return addMediaToAlbum(token, myAlbum.id, result.response.id, media, true)
            .pipe(
              map(response => ({
                ...result,
                album: mapAlbumApiToUi(response),
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(asyncModel, 'addPhotoToAlbum', error),
              })),
            );
        }
        return of({...result});
      }
      return of(...result);
    }),
  );

export const saveNewAthletePhotoEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  const teamsForCoach = () => state$.value.modules.coachWorld.coachWorld.myTeams;
  return action$.pipe(
    ofType(EP_PHOTO_SAVE_NEW_ATHLETE),
    getTokenFragment(),
    map(({action, token}) => ({
      uuid: uuid(),
      token,
      asyncModel: action.type,
      athleteId: action.athleteId,
      coachId: action.coachId,
      file: action.body,
      isAPhoto: true,
      album: null,
      dispatch: action.dispatch,
      totalBytes: action.body.size,
      isNew: true,
      outer: action,
    })),
    tap(incoming => incoming.outer.dispatch(uploadProgressUpdate(
      null,
      incoming.outer.body.size,
      '',
      'Uploading photo',
      true,
    ))),
    createMediaEpicFragment(action$),
    tap((results) => {
      if (results.success) {
        results.incoming.outer.dispatch(uploadProgressUpdate(
          null,
          results.incoming.outer.body.size,
          '',
          'Processing photo',
        ));
      }
    }),
    switchMap((results) => {
      if (results.success) {
        const media = mapPhotoApiToUi(results.response);
        let retryCount = 0;
        return getPhoto(results.incoming.token, media.id)
          .pipe(
            map((response) => {
              if (response.resized_photos) {
                return {
                  ...results,
                  media: mapPhotoApiToUi(response),
                };
              }
              retryCount += 1;
              const error = {
                tryAgain: true,
              };
              throw error;
            }),
            ajaxErrorHandlerEpicFragment(),
            catchError((error) => {
              if (retryCount > 60) {
                return of({
                  success: false,
                  action: asyncErrorAction(
                    results.incoming.asyncModel,
                    'pollResized',
                    {
                      name: 'Timeout',
                      desc: 'Timed out while waiting for photo to process',
                    },
                  ),
                });
              }
              if (error.tryAgain) throw error;
              return of({
                success: false,
                action: asyncErrorAction(results.incoming.asyncModel, 'pollResized', error),
              });
            }),
            retryWhen(errors => errors.pipe(
              delay(1000),
              tap(val => console.log('Trying again', val)),
            )),
            takeUntil(cancelOnRouteChange(action$, Routes.player, uuid)),
          );
      }
      return results;
    }),
    tap((results) => {
      if (results.success) {
        results.incoming.outer.dispatch(uploadProgressUpdate(
          null,
          results.incoming.outer.body.size,
          '',
          'Saving photo',
        ));
      }
    }),
    switchMap((results) => {
      if (results.success) {
        const {media} = results;
        if (results.incoming.athleteId) {
          const objAthlete = {
            headshot_url: media.resizedPhotos.medium,
            user_team_id: results.incoming.athleteId,
            photo_id:media.id,
          };
          return updateUserTeamInfo(results.incoming.token, objAthlete)
            .pipe(
              map(response => ({
                ...results,
                athlete: SchoolTeamMember.fromApi(response),
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(EP_PHOTO_SAVE_NEW_ATHLETE, 'addPhotoToAlbum', error),
              })),
            );
        }
        const teams = teamsForCoach();
        return forkJoin(...teams.map((team) => {
          const objAthlete = {
            user_team_id: team.id,
            headshot_url: media.resizedPhotos.medium,
          };
          return (
            updateUserTeamInfo(results.incoming.token, objAthlete)
              .pipe(
                map(response => ({
                  ...results,
                  coach: SchoolTeamMember.fromApi(response),
                })),
                takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                ajaxErrorHandlerEpicFragment(),
                catchError(error => of({
                  success: false,
                  action: asyncErrorAction(EP_PHOTO_SAVE_NEW_ATHLETE, 'addPhotoToAlbum', error),
                })),
              ));
        }))
          .pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  ...forkResults,
                };
              }
              return forkResults;
            }),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        let action;
        let data;
        if (Array.isArray(results.results)) {
          action = results.results[0].incoming.outer;
          data = results.results.map(r => r.coach);
          data.isCoach = true;
        } else {
          action = results.incoming.outer;
          data = results.athlete;
          data.isCoach = false;
        }
        return of(asyncFinishAction(
          EP_PHOTO_SAVE_NEW_ATHLETE, action,
          data,
        ));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const updateAthletePhotoEpic = action$ => action$.pipe(
  ofType(EP_PHOTO_REMOVE_ATHLETE),
  getTokenFragment(),
  switchMap(({action, token}) => {
    const objAthlete = {
      headshot_url: '',
      user_team_id: action.athleteId,
    };
    return updateUserTeamInfo(
      token,
      objAthlete,
    )
      .pipe(
        map(response => mapUserApiToUi(response)),
        map(athlete => ({
          success: true,
          athlete,
        })),
        takeUntil(cancelOnRouteChange(action$)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(USER_ATHLETE_PHOTO, 'save', error),
        })),
      );
  }),
  switchMap((results) => {
    if (results.success) {
      return of(
        asyncFinishAction(USER_ATHLETE_PHOTO, 'save'),
        userSportUpdateSuccess(results.athlete),
      );
    }
    return of(results.action);
  }),
);

const updateTeamNameEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_TEAM_NAME),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updatesTeamName(token, action.values)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateTeamName',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const updatedTeam = result.response;
        return of(asyncFinishAction(result.action.type, 'updatedTeamName', {
          schoolTeam: updatedTeam,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const removeUserFromTeamEpic = action$ => (
  action$.pipe(
    ofType(REMOVE_USER_FROM_TEAM),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      removeUserFromTeam(action.userTeamId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'removeUserFromTeam',
        ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'removeUserFromTeam', {
          id: result.action.userTeamId,
          total:result.action.total,
          currentCount:result.action.currentCount,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const removeUserFromProspectTeamEpic = action$ => (
  action$.pipe(
    ofType(REMOVE_USER_FROM_PROSPECT_TAM),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      removeUserFromProspectTeam(action.userId,action.schoolTeamId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'removeUserFromProspectTeam',
        ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(result.action.type, REMOVE_USER_FROM_PROSPECT_TAM, {userId:result.action.userId, schoolTeamId:result.action.schoolTeamId}),
          asyncFinishAction(SET_LOADING_FOR_RECONFIGURE_PAGE, SET_LOADING_FOR_RECONFIGURE_PAGE, {})
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const addUserToSchoolTeamEpic = action$ => (
  action$.pipe(
    ofType(ADD_USER_TO_SCHOOL_TEAM),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      addUserToSchoolTeam(action.userId,action.values, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'addUserToSchoolTeam',
        ))
    )),
    mergeMap((result) => {
      if (result.success) {
        let removedAthlete = result.action.athlete;
        removedAthlete.id = result.response.data.id;
        return of(asyncFinishAction(result.action.type, 'addUserToSchoolTeam', {
          removedAthlete: removedAthlete,
          total:result.action.total,
          currentCount:result.action.currentCount,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export const createScheduleEpic = (action$) => {

  return action$.pipe(
    ofType(UPLOAD_SCHEDULE),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      return createSchedule(token, { schoolTeamId: action.schoolTeamId, ext: action.file.name.split('.')[1] }).pipe(
        map(response => ({
          action,
          success: true,
          response,
          token,
        })),
        takeUntil(cancelOnRouteChange(action$, Routes.schedule, action.schoolTeamId)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(action.type, UPLOAD_SCHEDULE, error),
        })),
      )}),
    switchMap((result) => {
      if (result.success) {
        return uploadScheduleToS3(result.response.upload_url, result.action.file).pipe(
          map(response => ({ ...result, s3Response: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.transcript, result.action.schoolTeamId)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, UPLOAD_SCHEDULE, error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return flagScheduleUploaded(result.action.schoolTeamId, result.response.id, result.token).pipe(
          map(response => ({ ...result, flagUploadedResponse: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.schedule, result.action.schoolTeamId)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, UPLOAD_SCHEDULE, error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(UPLOAD_SCHEDULE, UPLOAD_SCHEDULE, {
            schedule: mapScheduleApiToUi(result.response),
          }),
        )
      }
      return of(result.action);
    }),
  );
};


const getProfileByEmailEpic = action$ => (
  action$.pipe(
    ofType(GET_PROFILE_BY_EMAIL),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => (
      getAjaxProfileByEmail(action.email, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getProfileByEmail',
        ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getProfileByEmail', {
          email: result.action.email,
          first: result.response[0].given_name,
          last: result.response[0].family_name,
          gender: result.response[0].gender,
          grade: result.response[0].grade,
          photoUrl: result.response[0].photo_url,
          uuid: result.response[0].aws_uuid,
          registered: result.response[0].registered,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const guestProfileByEmailGetEpic = action$ => (
  action$.pipe(
    ofType(GUEST_PROFILE_BY_EMAIL_GET),
    getTokenFragmentMergeMap(),
    mergeMap(({action}) => (
      getGuestProfileByEmail(action.email)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          GUEST_PROFILE_BY_EMAIL_GET,
        ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, GUEST_PROFILE_BY_EMAIL_GET, {
          email: result.action.email,
          first: result.response.given_name,
          last: result.response.family_name,
          uuid: result.response.aws_uuid,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const confirmMergeProfileEpic = action$ => action$.pipe(
  ofType(CONFIRM_MERGE_PROFILES),
  getTokenFragmentMergeMap(),
  mergeMap(({action, token}) => mergeGridProfile(
    {
      roster_athlete_uuid: action.athleteUuid,
      existing_athlete_uuid: action.existingUuid,
    },
    token,
  )
    .pipe(
      map(response => ({
        success: true,
        action,
        response,
        token,
        uuid: action.existingUuid,
        dispatch: action.dispatch,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(() => of({
        success: false,
        action: asyncErrorAction(action.type, 'mergeGridProfiles', {}),
      })),
    )),
  mergeMap((result) => {
    if (result.success) {
      return getPlayerProfile(result.uuid, result.token)
        .pipe(
          map(response => ({
            success: true,
            ...result,
            profile: mapUserApiToUi(response),
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(() => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'mergeGridProfiles', {}),
          })),
        );
    }
    return of(result);
  }),
  mergeMap((result) => {
    if (result.success) {
      result.dispatch(getRoster(result.action.schoolId, result.action.sportId));
      return of(
        getPlayerProfileSuccess(
          result.profile.uuid,
          result.profile,
        ),
        asyncFinishAction(result.action.type, 'mergeGridProfiles', {}),
      );
    }
    return of(asyncErrorAction(CONFIRM_MERGE_PROFILES, 'mergeGridProfiles', {}));
  }),
);

const updateRosterViewEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  return action$.pipe(
    ofType(UPDATE_CARD_VIEW),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updatesRosterView(token, uuid(), action.viewSelection)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'changeViewSelection',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, UPDATE_CARD_VIEW, {
          viewSelection: result.response.isCardView ? 'card' : 'list',
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};


const checkRosterViewEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  return action$.pipe(
    ofType(CHECK_IF_CARD_VIEW),
    getTokenFragment(),
    switchMap(({action, token}) => (
      checkCardView(token, uuid())
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'checkIfCardView',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, CHECK_IF_CARD_VIEW, {
          viewSelection: result.response.isCardView ? 'card' : 'list',
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

export default combineEpics(
  addUserToSchoolTeamEpic,
  getSchoolTeamsEpic,
  getProfilesEpic,
  updateAthleteInfoEpic,
  updateAthleteTeamInfoEpic,
  changeAthletesTeamEpic,
  updateAthletePhotoEpic,
  saveNewAthletePhotoEpic,
  updateTeamNameEpic,
  removeUserFromTeamEpic,
  removeUserFromProspectTeamEpic,
  updateAthleteProfileInfoEpic,
  createAthleteProfileEpic,
  createBulkAthleteProfilesAndInviteEpic,
  createAthleteProfileAndInviteEpic,
  athleteInviteEpic,
  getProfileByEmailEpic,
  confirmMergeProfileEpic,
  createBulkAthleteProfilesEpic,
  updateUserTeamInfoEpic,
  updateSportInfoEpic,
  updateHeadCoachEpic,
  deleteHeadCoachEpic,
  createHeadCoachEpic,
  updateTwitterLinkEpic,
  deleteTwitterLinkEpic,
  updateRecruitingCoordinatorEpic,
  deleteRecruitingCoordinatorEpic,
  createRecruitingCoordinatorEpic,
  guestProfileByEmailGetEpic,
  updateRosterViewEpic,
  checkRosterViewEpic,
  createScheduleEpic,
  getSchoolTeamsScheduleEpic,
  deleteScheduleEpic
);
