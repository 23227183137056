import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import LeaderboardTable from "../Leaderboard/LeaderboardTable";
import Banner from "../NorthEastLeaderboard/Banner";
import FilterSidebar from "../common/FiltersSidebar";
import SidebarLinkFilter from "../common/SidebarLinkFilter";
import SidebarMultiSelectCallbackFilter from "../common/SidebarMultiSelectCallbackFilter";
import { gender, positions } from "../../../components/UxCommon/Form/utils";
import { expandedNorthEastLeaderboardDialogPath, Routes } from "../../../store/actions/ui/routes";
import { BankSlugs } from "../+store/combine";
import NationalLeaderboardLoadingIndicator from "../common/NationalLeaderboardLoadingIndicator";
import NorthEastLeaderboardHeader from "./Header";
import { schoolStyle, ScoreType } from "../+store/nationalLeaderboard/nationalLeaderboard.constants";
import SidebarSportSelectFilter from "../common/SidebarSportSelectFilter/SidebarSportSelectFilter.container";
import SidebarAthleteSearch from "../common/SidebarAthleteSearch";
import { setSortBy } from "../+store/coachWorld";
import ExpandedPhotoDialog from "../../../containers/UxProfile/Photos/ExpandedPhotoDialog";
import Video from "../../../store/actions/data/models/Video";

const getSidebarFilters = ({sportId, gradYears}) => {
  const linkFilters = [];

  let queryStringFilters = [
    {
      name: 'position',
      label: 'Positions',
      items: positions(sportId).map(({value, name}) => ({
        name,
        value,
      })),
    },
    // {
    //   name: 'grade',
    //   label: 'Grades',
    //   items: AthleteGrades.map(([value, text]) => ({
    //     name: text,
    //     value,
    //   })),
    // },
    {
      name: 'gender',
      label: 'Genders',
      items: gender().map(({text, value}) => ({name: text, value})),
    },
  ];

  if (gradYears.length) {
    const gradYearFilter = {
      name: 'gradYear',
      label: 'Grad Year',
      items: gradYears.map((value) => ({
        name: value,
        value,
      })),
    };

    queryStringFilters.splice(1, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters,
  };
};

class NorthEastLeaderboardComponent extends PureComponent {
  static propTypes = {
    getNationalLeaderboardTemplate: PropTypes.func.isRequired,
    template: PropTypes.object,
    getNorthEastLeaderboardTestRes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    results: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    columns: PropTypes.array,
    handleTestSortClicked: PropTypes.func.isRequired,
    sortByTestId: PropTypes.number,
    viewSelection: PropTypes.string.isRequired,
    onResultsChange: PropTypes.func.isRequired,
    numResultsShown: PropTypes.string.isRequired,
    onScoreTypeChange: PropTypes.func.isRequired,
    selectedScoreType: PropTypes.string.isRequired,
    measureableColumns: PropTypes.array.isRequired,
    getTestCategories: PropTypes.func.isRequired,
    testCats: PropTypes.object,
    isLoggedIn: PropTypes.bool.isRequired,
    getSports: PropTypes.func.isRequired,
    sports: PropTypes.array.isRequired,
    parsedFilters: PropTypes.string,
    currentSport: PropTypes.string,
    zip: PropTypes.string,
    radius: PropTypes.string,
    search: PropTypes.string,
    displayShowMore: PropTypes.bool.isRequired,
    gradYears: PropTypes.array.isRequired,
  }

  componentDidMount() {
    const {getSports} = this.props;
    getSports();
    this.sportsFetched = true;
    this.shouldUpdate();
  }

  isFilterChanged = (currentFilter, prevFilter) => {
    let filters = [];
    const currentFilterWithoutEvents = currentFilter && currentFilter.length ? currentFilter.filter(cF => cF.name !== 'events') : null;
    if (currentFilterWithoutEvents && currentFilterWithoutEvents.length) {
      filters = currentFilter.filter(cF => {
        const pF = prevFilter && prevFilter.find(f => f.name === cF.name);
        if (!pF) return true;
        return !(cF.selected.sort().toString() === pF.selected.sort().toString());
      })
    }
    if ((!currentFilterWithoutEvents || currentFilterWithoutEvents.length === 0) && prevFilter && prevFilter.length > 0) {
      filters = prevFilter.filter(pF => {
        return pF.name !== 'events';
      })
    }
    return filters.length > 0;
  };

  componentDidUpdate(prevProps) {
    const {
      currentSport, sortByTestId, zip, radius, search, selectedScoreType, selectedFilters
    } = this.props;

    if (this.isFilterChanged(selectedFilters, prevProps.selectedFilters) && (!currentSport || (currentSport && prevProps.currentSport === currentSport))) {
      this.shouldLeaderBoardFetch = true;
    }
    if (currentSport && prevProps.currentSport !== currentSport) {
      this.shouldTemplateFetch = true;
    }
    if (sortByTestId && prevProps.sortByTestId !== sortByTestId) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.zip !== zip) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.radius !== radius) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.search !== search) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.selectedScoreType !== selectedScoreType) {
      this.shouldLeaderBoardFetch = true;
    }
    this.shouldUpdate();
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  }

  onHeaderClick = (stdTestObjectId) => {
    this.shouldLeaderBoardFetch = true;
    const {handleTestSortClicked, location, history} = this.props;
    handleTestSortClicked(location, history, stdTestObjectId);
  }

  onResultsChange = (value) => {
    this.shouldLeaderBoardFetch = true;
    const {onResultsChange, location, history} = this.props;
    onResultsChange(location, history, value);
  }

  onScoreTypeChange = (value) => {
    const {onScoreTypeChange, location, history} = this.props;
    onScoreTypeChange(location, history, value);
  }

  onShowMoreClick = () => {
    this.loadLeaderboard(true);
  }

  getCurrentSportId = () => {
    const sport = this.getCurrentSport();
    return sport ? sport.id : null;
  }

  getCurrentSport = () => {
    const {currentSport, sports} = this.props;
    const selectedSport = currentSport ? sports.find(s => s.code === currentSport) : null;
    if (selectedSport) {
      return selectedSport;
    }
    return sports.length ? sports[0] : null;
  }

  getCurrentSportCode = () => {
    const sport = this.getCurrentSport();
    return sport ? sport.code : null;
  }

  getSortByCatId = () => {
    const {columns, sortByTestId} = this.props;
    const sortByColumn = columns.find(column =>
      column.standardTestObjectId === sortByTestId);
    return sortByColumn.test_cat_id || sortByColumn.standardTestObjectId;
  }

  setRef = (table) => {
    this.table = table;
  }

  getOrderBy = () => {
    const {columns, sortByTestId, selectedScoreType} = this.props;
    if (selectedScoreType && selectedScoreType !== 'raw') {
      return 'desc';
    }
    const sortByColumn = columns.find(column =>
      column.standardTestObjectId === sortByTestId);
    return sortByColumn.best === 'min' ? 'asc' : 'desc';
  }

  loadLeaderboard = (loadMore = null) => {
    const {
      selectedScoreType,
      getNorthEastLeaderboardTestRes,
      numResultsShown,
      parsedFilters,
      zip,
      radius,
      search,
    } = this.props;
    const sortBy = this.getSortByCatId();
    this.shouldLeaderBoardFetch = false;
    const scoreType = selectedScoreType ? ScoreType[selectedScoreType] : null;
    let perPage = Number(numResultsShown);
    let page = 1;
    if (loadMore) {
      this.page++
      page = this.page;
      perPage = 50;
      if (page === 1) {
        perPage = Number(perPage) + Number(numResultsShown);
        perPage = perPage - (perPage % 50);
        this.page = (perPage / 50);
      }
    } else {
      this.page = 0;
    }
    const orderBy = this.getOrderBy();
    const sportId = this.getCurrentSportId();
    getNorthEastLeaderboardTestRes({
      sortBy, perPage, scoreType, parsedFilters, page, loadMore, orderBy, zip, radius, search, sportId
    });
  }

  shouldUpdate = () => {
    const {
      match,
      selectedScoreType,
      onScoreTypeChange,
      location,
      history,
      testCats,
      getTestCategories,
      sortByTestId,
      columns,
      getSports,
      getNationalLeaderboardTemplate,
    } = this.props;
    if (!testCats.organizedCats && !this.testCatsFetched) {
      this.testCatsFetched = true;
      getTestCategories();
    }
    const sportId = this.getCurrentSportId();
    if (this.shouldTemplateFetch && sportId) {
      getNationalLeaderboardTemplate(sportId);
      this.shouldTemplateFetch = false;
    }
    if (!this.sportsFetched) {
      this.sportsFetched = true;
      getSports();
    }
    const {bank} = match.params;
    if (this.currentBank && this.currentBank !== bank) {
      this.shouldLeaderBoardFetch = true;
      this.currentBank = bank;
    }
    if (this.shouldLeaderBoardFetch && sortByTestId && columns.length) {
      this.currentBank = bank;
      this.loadLeaderboard();
    }
    if (((match.params.bank === BankSlugs.PERF)
      && (selectedScoreType === 'relative'))
      || ((match.params.bank === BankSlugs.VC_SCORE)
        && (selectedScoreType !== 'raw'))) {
      onScoreTypeChange(location, history, 'raw');
    }
  }

  showFilterRank = filters => !!(filters && filters.find(fl => fl.name !== 'events'));


  redirectToBank = () => {
    const {template} = this.props;
    const bankSlug = BankSlugs[template.banks[0]];
    return (<Redirect
      to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${bankSlug}`}
    />);
  }

  table = null;
  testCatsFetched = false;
  intervalId = null;
  shouldLeaderBoardFetch = true;
  shouldTemplateFetch = true;
  currentBank = null;
  sportsFetched = false;
  page = 0;

  renderTable = () => {
    const {
      columns,
      sortByTestId,
      results,
      viewSelection,
      loading,
      selectedScoreType,
      measureableColumns,
      isLoggedIn,
      displayShowMore,
      selectedFilters,
      location,
      history,
      match,
    } = this.props;
    const isLoading = loading && (!results || results.length === 0);
    if (isLoading) return null;
    const eventFilter = selectedFilters ? selectedFilters.find(fl => fl.name === 'events') : null;
    const selectedEvents = eventFilter ? eventFilter.selected : null;

    const filteredColumns = selectedEvents && selectedEvents.length ?
      columns.filter(c => selectedEvents.includes(c.standardTestObjectId)) :
      null

    const selectedTest = filteredColumns && filteredColumns.length && !(filteredColumns.find(fc => fc.standardTestObjectId === sortByTestId)) ?
      setSortBy(location, history, filteredColumns[0].standardTestObjectId) : sortByTestId;

    return (
      <div className="Leaderboard_TableWrapper_Container">
        <div className="Leaderboard_TableWrapper">
          <LeaderboardTable
            data={results}
            columns={filteredColumns && filteredColumns.length ? filteredColumns : columns}
            onHeaderClick={this.onHeaderClick}
            sortByTestId={selectedTest}
            viewSelection={viewSelection}
            loading={isLoading}
            onShowMoreClick={this.onShowMoreClick}
            displayShowMore={displayShowMore}
            selectedScoreType={selectedScoreType}
            measureableColumns={measureableColumns}
            isLoggedIn={isLoggedIn}
            showFilterRank={this.showFilterRank(selectedFilters)}
            isNl={true}
            sport={(this.getCurrentSportCode()).toLowerCase()}
            enableAthleteLink
            match={match}
            location={location}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      loading,
      loadingMsg,
      match,
      history,
      location,
      numResultsShown,
      selectedScoreType,
      isLoggedIn,
      template,
      sports,
      columns,
      results,
      gradYears,
      videos,
    } = this.props;
    let sportId = 0;
    if (template) {
      ({sportId} = template);
    }

    const filteredSports = sports ? sports.filter(sport => ['FOOTBALL'].includes(sport.code)) : sports;
    const {linkFilters, queryStringFilters} = getSidebarFilters({
      sportId,
      gradYears,
    });

    let bgImage;
    let bgColor;
    if (schoolStyle) {
      if (schoolStyle.leaderboardBackground) {
        bgImage = `url(${schoolStyle.leaderboardBackground})`;
      }
      if (schoolStyle.leaderboardStyle === 'dark') {
        bgColor = 'black';
      } else {
        bgColor = 'white';
      }
    } else {
      bgColor = 'black';
    }
    const themeClass = schoolStyle
    && (schoolStyle.leaderboardStyle === 'dark') ? 'dark' : 'light';
    const banks = template && template.banks ? template.banks : [];

    const photoVideos = videos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });

    return (
      <React.Fragment>
         <Switch>
          <Route
            path={expandedNorthEastLeaderboardDialogPath}
            render={(props) => (
              <ExpandedPhotoDialog photoList={photoVideos} {...props} location={location} isSingleVideo={true} page={`${Routes.northEastLeaderboard}`} />
            )}
          />
        </Switch>

      <div className={`LeaderboardPage ${themeClass}-theme`}>
        <div
          className="Leaderboard_Background"
          style={{
            backgroundImage: bgImage,
            backgroundColor: bgColor,
          }}
        />
        <Banner match={match} location={location}/>
        <div className="Leaderboard_BodyWrapper">
          <NorthEastLeaderboardHeader
            match={match}
            location={location}
            onResultsChange={this.onResultsChange}
            numResultsShown={numResultsShown}
            onScoreTypeChange={this.onScoreTypeChange}
            selectedScoreType={selectedScoreType}
            isLoggedIn={isLoggedIn}
            sport={this.getCurrentSportCode()}
            banks={banks}
          />
          <SidebarAthleteSearch
            location={location}
            history={history}
            className=""
          />
          <NationalLeaderboardLoadingIndicator
            loading={loading}
            loadingMsg={loadingMsg}
            leaderboard
            wrapperStyle={{top: 100}}
          />
          <div className="Leaderboard_Body">
            <FilterSidebar
              displayNone={!!loading && (!results || results.length === 0)}
              className="Leaderboard ntlLeaderboard"
            >

              {
                filteredSports.length && (
                  <SidebarSportSelectFilter
                    label="Sports"
                    filters={filteredSports.map(column => ({
                      name: column.name,
                      value: column.code,
                    }))}
                    history={history}
                    location={location}
                    className="Leaderboard"
                    defaultValue={filteredSports[0].code}
                  />
                )
              }
              <SidebarMultiSelectCallbackFilter
                key='Events'
                filterName='events'
                label={'Events'}
                filters={columns.map(column => ({
                  name: column.testCategoryName,
                  value: column.standardTestObjectId,
                }))}
                history={history}
                location={location}
                className="Leaderboard"
              />
              {/*Moved to second phase*/}
              {/*<SidebarZipCodeSearch*/}
              {/*  label="Location"*/}
              {/*  location={location}*/}
              {/*  history={history}*/}
              {/*  className="Leaderboard leaderboardLocation"*/}
              {/*/>*/}
              {
                linkFilters.map(({name, label, items}) => (
                  <SidebarLinkFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    match={match}
                  />
                ))
              }
              {
                queryStringFilters.map(({name, label, items}) => (
                  <SidebarMultiSelectCallbackFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    history={history}
                    location={location}
                    className="Leaderboard"
                  />
                ))
              }
            </FilterSidebar>
            {
              template && (
                <Switch>
                  <Route
                    path={`/${Routes.coach}/${Routes.northEastLeaderboard}/:bank`}
                    render={this.renderTable}
                  />
                  <Route
                    path={`/${Routes.coach}/${Routes.northEastLeaderboard}`}
                    render={this.redirectToBank}
                  />
                </Switch>
              )
            }
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default NorthEastLeaderboardComponent;
