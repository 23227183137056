import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../UxCommon/Icon/Icon';

class ShareRow extends PureComponent {
  static propTypes = {
    onShareClick: PropTypes.func.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const { onShareClick } = this.props;
    return (
      <div className="PlayerCard_ShareRow">
        <button className="PlayerCard_ShareRow_Row PlayerCard_ShareRow_Btn" onClick={onShareClick}>
          <div className="PlayerCard_ShareRow_Label">Share</div>
          <Icon
            iconName="share"
            className="PlayerCard_ShareRow_Icon"
          />
        </button>
      </div>
    );
  }
}

export default ShareRow;
