import React, { Component } from "react";
import InvitedSignUp from "../../../containers/Welcome/InvitedSignUp";
import LoadingIndicator from "../../../modules/CoachWorld/common/LoadingIndicator";
import PropTypes from "prop-types";
import MergeProfiles from "./MergingModals/MergeProfiles.component";
import { Link, Redirect } from "react-router-dom";
import { mergeAnotherAccount, mergeSignIn, Routes } from "../../../store/actions/ui/routes";
import { MergeTypes } from "../MergeSignIn/MergeSignIn";
import { Roles } from "../../../store/actions/data/user/profile/models";
import StripeCheckoutComponent from "../StripeCheckout/StripeCheckout.component";
import { profileShowCreateNewAccount } from "../../../store/actions/ui/uxProfile";

const invitationCompleted = 'Oops! Expired Link';
const invitationCompletedDesc = ` You have clicked on a link that is no longer valid. Please head back to your inbox and click on the most recent email invitation. If you get stuck,`;
let contactEmail = `mailto:support@virtualcombine.com`;

const invitationInvalid = 'Invitation Invalid';
const invitationInvalidDesc = ` This invite link is no longer active.\n
                Please contact your coach to see if there is a new link available.`;

class ValidateInviteComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    checkIfInviteIsValid: PropTypes.func,
    getProfile: PropTypes.func,
    profile: PropTypes.object,
    shouldCheckInviteValid: PropTypes.bool,
    isInvitationValid: PropTypes.object,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    getDuplicateProfiles: PropTypes.func.isRequired,
    duplicateProfiles: PropTypes.array.isRequired,
    loadingDuplicateProfiles: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    forceLogout: PropTypes.func.isRequired,
    mergeProfile: PropTypes.any,
    profileShowCreateNewAccount: PropTypes.func.isRequired,
    showCreateNewAccount: PropTypes.bool,
  };

  checkInvitationValid = (userTeamId, token) => {
    const { checkIfInviteIsValid } = this.props;
    checkIfInviteIsValid(userTeamId, token);
  };

  componentDidMount() {
    const { match, getProfile, getDuplicateProfiles } = this.props;
    if (match.params.uuid) {
      getProfile(match.params.uuid);
      getDuplicateProfiles(match.params.uuid);
    }
  }

  componentDidUpdate() {
    const { match, getProfile, profile } = this.props;
    if (!this.isInvitationInvalid() && (!profile && match.params.uuid)) {
      getProfile(match.params.uuid);
    }
  }

  onNext = (value) => {
    const { match, history , profileShowCreateNewAccount} = this.props;
    switch (value) {
      case "1":
        return history.push(mergeSignIn(
          MergeTypes.mergeBoth,
          match.params.uuid,
          match.params.token,
          match.params.teamName,
          match.params.coachName,
          match.params.sportId,
          match.params.userTeamId,
          match.params.schoolId,
        ));
      case "2":
        return history.push(mergeAnotherAccount(
          MergeTypes.signIn,
          match.params.uuid,
          match.params.token,
          match.params.teamName,
          match.params.coachName,
          match.params.sportId,
          match.params.userTeamId,
          match.params.schoolId,
        ));

      case "3":
        profileShowCreateNewAccount(true);
        return false;
      default:
        return false;
    }
  };

  isInvitationInvalid = () => {
    const { shouldCheckInviteValid, isInvitationValid } = this.props;
    return (!shouldCheckInviteValid && (!isInvitationValid || isInvitationValid.isValid === false));
  };

  renderInvitationInvalid = () => {
    const { isInvitationValid } = this.props;

    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">
              {!isInvitationValid ? invitationCompleted : invitationInvalid}
            </div>
            <div className="welcome-desc">
              {!isInvitationValid
                ? invitationCompletedDesc
                : invitationInvalidDesc}

              { !isInvitationValid &&
                <a href={contactEmail}> Email us.</a>
              }
              <div className="welcome-btns">
                <Link
                  className="welcome-btn"
                  to={`/${Routes.welcome}/${Routes.login}`}>
                  {' '}
                  Sign In{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getRedirectUrl = () => {
    const { params } = this.props.match;
    const { uuid, teamName, token, coachName, sportId, userTeamId, schoolId } = params;
    if(schoolId) {
      return `/${Routes.welcome}/${Routes.pma}/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
    }
    return `/${Routes.welcome}/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}`;
  };

  renderRedirect = () => {
    const { authenticatedProfile, match, isInvitationValid } = this.props;
    let redirectTo, msg;
    if (!isInvitationValid.isValid) {
      if (authenticatedProfile.roleId === Roles.athlete) {
        redirectTo = `/${Routes.profile}`;
        msg = "Go to Profile";
      } else if (authenticatedProfile.roleId === Roles.coach) {
        redirectTo = `/${Routes.coach}/${Routes.dashboard}`;
        msg = "Go to Dashboard";
      }
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">
                {!isInvitationValid ? invitationCompleted : invitationInvalid}
              </div>
              <div className="welcome-desc">
                {!isInvitationValid
                  ? invitationCompletedDesc
                  : invitationInvalidDesc}

                { !isInvitationValid &&
                  <a href={contactEmail}> Email us.</a>
                }
                <div className="welcome-btns">
                  <Link
                    className="welcome-btn"
                    to={`/${Routes.welcome}/${Routes.login}`}>
                    {' '}
                    Sign In{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Redirect
        to={mergeSignIn(
          MergeTypes.mergeBoth,
          match.params.uuid,
          match.params.token,
          match.params.teamName,
          match.params.coachName,
          match.params.sportId,
          match.params.userTeamId
        )}
      />);
  };

  isValidToken = () =>{
    const { isInvitationValid } = this.props;
    return isInvitationValid && isInvitationValid.isValid
  }

  loadView = () => {
    const {
      isInvitationValid,
      profile,
      duplicateProfiles,
      match,
      loadingDuplicateProfiles,
      loggedIn,
      isInviteUserLoggedIn,
      authenticatedProfile,
      forceLogout,
      shouldCheckInviteValid,
      mergeProfile,
      showCreateNewAccount,
    } = this.props;


    if(shouldCheckInviteValid){
      return false;
    }

    if(this.isInvitationInvalid() ){
      return this.renderInvitationInvalid();
    }

    if (isInvitationValid === null || !profile) {
      return true;
    }
    if (profile.isPaidProfile && !profile.isSubscribed && this.isValidToken()) {
      return <StripeCheckoutComponent profile={profile} match={match}/>;
    }

    if (isInvitationValid && isInvitationValid.isValid && loggedIn && !isInviteUserLoggedIn) {
      forceLogout(this.getRedirectUrl());
    } else if (loggedIn && authenticatedProfile) {
      return this.renderRedirect();
    }

    if (this.isInvitationInvalid()) {
      return this.renderInvitationInvalid();
    }

    if (isInvitationValid === null || !profile || loadingDuplicateProfiles) {
      return false;
    }

    if (duplicateProfiles.length === 0 || showCreateNewAccount) {
      return <InvitedSignUp />;
    }
    return (
      <MergeProfiles
        match={match}
        profile={profile}
        duplicateProfile={mergeProfile ? mergeProfile : duplicateProfiles[0]}
        onNext={this.onNext}
      />
    );
  };

  render() {
    const { match, shouldCheckInviteValid, loading, loadingMsg } = this.props;

    if (shouldCheckInviteValid) {
      const { userTeamId, token } = match.params;
      this.checkInvitationValid(userTeamId, token);
    }
    return (
      <div>
        <LoadingIndicator
          loading={loading}
          loadingMsg={loadingMsg}
          wrapperStyle={{ top: 120 }}
        />
        {this.loadView()}
      </div>
    );
  }
}

export default ValidateInviteComponent;
