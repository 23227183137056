import { combineReducers } from 'redux';

import {
  ASYNC_ERROR,
  ASYNC_FINISH,
  ASYNC_START, asyncFinishAction,
} from '../../../../store/actions/ui/async';
import {
  ATHLETE_INVITE,
  CHANGE_SORT_BY,
  CHANGE_VIEW_SELECTION,
  CREATE_BULK_ATHLETE_PROFILES,
  CREATE_BULK_ATHLETE_PROFILES_AND_INVITE,
  CREATE_HEAD_COACH,
  CREATE_RECRUITING_COORDINATOR,
  DELETE_HEAD_COACH,
  DELETE_TWITTER_LINK,
  DELETE_RECRUITING_COORDINATOR,
  GET_PROFILE_BY_EMAIL,
  GET_PROFILES,
  GET_ROSTER,
  GET_SCHOOL,
  REMOVE_USER_FROM_TEAM,
  RESET_GRID_EMAIL_PROFILE,
  UPDATE_ATHLETE_INFO,
  UPDATE_ATHLETE_TEAM_INFO,
  UPDATE_HEAD_COACH,
  UPDATE_TWITTER_LINK,
  UPDATE_RECRUITING_COORDINATOR,
  UPDATE_SEARCH,
  UPDATE_TEAM_NAME,
  UPDATE_USER_SPORTS_INFO,
  UPDATE_USER_TEAM_INFO,
  CHECK_IF_CARD_VIEW,
  UPDATE_CARD_VIEW,
  SET_LOADING_FOR_RECONFIGURE_PAGE,
  ADD_USER_TO_SCHOOL_TEAM,
  ATHLETE_INVITE_COUNT,
  ATHLETE_INVITE_CHUNK_STATE,
  START_ATHLETE_INVITE_COUNT,
  ATHLETE_INVITE_CHUNK_COUNT,
  UPDATE_ATHLETE_INVITE_CHUNK_STATE,
  UPLOAD_SCHEDULE,
  GET_SCHOOL_TEAM_SCHEDULE,
  DELETE_SCHEDULE, GET_SCHOOL_TEAM_SCHEDULE_SUCCESS, REMOVE_USER_FROM_PROSPECT_TAM,
} from './roster.actions';
import { RosterSortByOptions, RosterViewOptions } from './roster.constants';
import { GET_COMBINE } from '../combine';
import {
  EP_PHOTO_DISCARD_ATHLETE,
  EP_PHOTO_DROP_ATHLETE,
  EP_PHOTO_DROP_ERROR_ATHLETE,
  EP_PHOTO_DROP_ONLOAD_ATHLETE,
  EP_PHOTO_SAVE_NEW_ATHLETE,
  PHOTO_MODE_DISABLED,
  PHOTO_MODE_ENABLED,
  UPLOAD_PROGRESS_ATHLETE,
  USER_ATHLETE_PHOTO,
} from './roster.photo.actions';
import { ROUTE_CHANGED } from '../../../../store/actions/ui/routes';
import { SchoolTeamMember } from './roster.models';
import { CANCEL_API_REQUEST } from '../../../../store/actions/ui/uxProfile/utils';
import { USER_PROFILE_UPDATE_SUC } from '../../../../store/actions/data/user';
import moment from "moment";
import {UPDATE_SCHOOL_STYLES} from "../coachWorld";
import {sortAthletes} from "./roster.selectors";
import {DELETE_TRANSCRIPT, GET_TRANSCRIPT, UPLOAD_TRANSCRIPT} from "../../../ProspectSheet/+store";

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === GET_ROSTER ||
        action.model === REMOVE_USER_FROM_TEAM ||
        action.model === SET_LOADING_FOR_RECONFIGURE_PAGE ||
        action.model === ADD_USER_TO_SCHOOL_TEAM
      ) {
        return 'Loading ...';
      }
      if (action.model === CREATE_BULK_ATHLETE_PROFILES_AND_INVITE) {
        return 'Adding and inviting players ...';
      }
      if (action.model === CREATE_BULK_ATHLETE_PROFILES) {
        return 'Adding profiles ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        [
          GET_ROSTER,
          REMOVE_USER_FROM_TEAM,
          CREATE_BULK_ATHLETE_PROFILES,
          CREATE_BULK_ATHLETE_PROFILES_AND_INVITE,
          SET_LOADING_FOR_RECONFIGURE_PAGE,
          ADD_USER_TO_SCHOOL_TEAM,
        ].includes(action.model)
      ) {
        if(action.data.total && action.data.total !== action.data.currentCount){
          return state;
        }else{
          return '';
        }
      }
      return state;
    case CANCEL_API_REQUEST:
      return '';
    default:
      return state;
  }
};

const loadingProfiles = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_PROFILES) {
        return true;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_PROFILES) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const savingProfiles = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === UPDATE_ATHLETE_TEAM_INFO ||
        action.model === UPDATE_USER_TEAM_INFO ||
        action.model === UPDATE_ATHLETE_INFO
      ) {
        return true;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        action.model === UPDATE_ATHLETE_TEAM_INFO ||
        action.model === UPDATE_USER_TEAM_INFO ||
        action.model === UPDATE_ATHLETE_INFO
      ) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const schoolTeams = (state = [], action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_SUC:
      const schoolTeamId = action.user.schoolTeamId;
      const updatedSchoolTeam = action.user.teamName;
      const isTeamNameChanged = action.user.isTeamNameChanged;
      if(updatedSchoolTeam && isTeamNameChanged){
        const teams = state.slice();
        const i = teams.findIndex((t) => t.id === schoolTeamId);
        teams[i].name = updatedSchoolTeam;
        return teams;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_ROSTER) {
        return action.data.schoolTeams || [];
      }
      if (action.model === UPDATE_TEAM_NAME) {
        const updatedschoolTeam = action.data.schoolTeam;
        const teams = state.slice();
        const i = teams.findIndex((t) => t.id === updatedschoolTeam.id);
        teams[i].name = updatedschoolTeam.name;
        return teams;
      }

      if (action.model === DELETE_HEAD_COACH) {
        const teams = state.slice();
        const { schoolTeamId } = action.data;
        return teams.map((st) => {
          if (st.id === schoolTeamId) {
            st.headCoach = null;
          }
          return st;
        });
      }

      if (action.model === DELETE_TWITTER_LINK) {
        const teams = state.slice();
        const { schoolTeamId } = action.data;
        return teams.map((st) => {
          if (st.id === schoolTeamId) {
            st.twitter = null;
          }
          return st;
        });
      }

      if (action.model === DELETE_RECRUITING_COORDINATOR) {
        const teams = state.slice();
        const { schoolTeamId } = action.data;
        return teams.map((st) => {
          if (st.id === schoolTeamId) {
            st.recruitingCoordinator = null;
          }
          return st;
        });
      }

      if (
        action.model === UPDATE_HEAD_COACH ||
        action.model === CREATE_HEAD_COACH
      ) {
        const teams = state.slice();
        const { headCoach } = action.data;
        return teams.map((st) => {
          if (st.id === headCoach.schoolTeamId) {
            st.headCoach = headCoach;
          }
          return st;
        });
      }

      if (action.model === UPDATE_TWITTER_LINK) {
        const teams = state.slice();
        const { twitterLink } = action.data;
        return teams.map((st) => {
          if (st.id === twitterLink.schoolTeamId) {
            st.twitter = twitterLink.twitter;
          }
          return st;
        });
      }

      if (
        action.model === UPDATE_RECRUITING_COORDINATOR ||
        action.model === CREATE_RECRUITING_COORDINATOR
      ) {
        const teams = state.slice();
        const { recruitingCoordinator } = action.data;
        return teams.map((st) => {
          if (st.id === recruitingCoordinator.schoolTeamId) {
            st.recruitingCoordinator = recruitingCoordinator;
          }
          return st;
        });
      }
      return state;
    default:
      return state;
  }
};

const athletes = (state = [], action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_SUC:
      return state.map((roster) => {
        if (roster.userId === action.user.uuid) {
          let birthdate = action.user.birthdate;
          if(birthdate){
            birthdate = new moment(birthdate,'YYYY-MM-DD').format('MM/DD/YY');
          }

          // just to avoid, when roster came as a plain object instead of class object
          if(!!roster.clone){
            const newRoster = roster;
            newRoster.givenName = action.user.first;
            newRoster.familyName = action.user.last;
            newRoster.birthdate = birthdate;
            return newRoster;
          }
          return {
            ...roster,
            givenName : action.user.first,
            familyName : action.user.last,
            birthdate,
          };
        }
        return roster;
      });
    case ASYNC_FINISH:
      if (action.model === UPDATE_SCHOOL_STYLES) {
        return state.map((athlete) => {
          if(athlete.headshotUrl === action.data.schoolStyle.old_school_logo) {
            athlete.headshotUrl = action.data.schoolStyle.school_logo;
            return athlete;
          }
          else {
            return athlete;
          }
        });
      }
      if (action.model === GET_ROSTER) {
        return action.data.athletes || [];
      }

      if (action.model === UPDATE_ATHLETE_TEAM_INFO) {
        const updatedState = state.map((athlete) => {
          if (athlete.userId=== action.data.userId) {
            const updateAthlete = athlete;
            updateAthlete.teamName = action.data.newTeamName;
            updateAthlete.schoolTeamId = action.data.schoolTeamId;
            updateAthlete.teamOrderBy = action.data.teamOrderBy;
            return updateAthlete;
          }
          return athlete;
        });
        updatedState.sort((a, b) => {
          const teamComp = () => {
            const comp = a.teamOrderBy - b.teamOrderBy;
            if (!comp && a.teamName) {
              // Natural sort if both are within same order because teams' name are alphanumerical
              return a.teamName.localeCompare(b.teamName, undefined, {numeric: true, sensitivity: 'base'})
            }
            return comp;
          }
          return teamComp() !== 0 ? teamComp() : a.name.localeCompare(b.name);
        });
        return updatedState;
      }

      if (
        action.model === UPDATE_ATHLETE_TEAM_INFO ||
        action.model === UPDATE_USER_TEAM_INFO ||
        action.model === UPDATE_ATHLETE_INFO
      ) {
        const existingAthlete = state.find(
          (athlete) => athlete.id === action.data.id,
        );
        // filtered undefined/null values
        const filteredObj = Object.keys(action.data).reduce((p, c) => {
          if (action.data[c] !== null || action.data[c] !== undefined) {
            p[c] = action.data[c];
          }
          return p;
        }, {});
        const ac = {
          ...existingAthlete,
          ...filteredObj,
        };

        if (
          action.data.isPrivate &&
          action.data.id !== action.data.oldUserTeamId
        ) {
          if (state.find((ath) => ath.id === action.data.id)) {
            return [...state];
          }
          const original = state.find(
            (ath) => ath.id === action.data.oldUserTeamId,
          );
          const newAthlete = {
            ...original,
            id: action.data.id,
            schoolTeamId: action.data.schoolTeamId,
          };
          return [...state, { ...newAthlete }];
        }
        return state.map((athlete) => {
          if (athlete.id === action.data.id) {
            return SchoolTeamMember.fromObject(ac);
          }
          return athlete;
        });
      }

      if (action.model === REMOVE_USER_FROM_PROSPECT_TAM) {
        return state.filter(athlete => !(athlete.userId === action.data.userId && athlete.schoolTeamId === action.data.schoolTeamId));
      }

      if (action.model === UPDATE_USER_SPORTS_INFO) {
        const existingAthlete = state.find(
          (athlete) => athlete.id === action.data.userTeamId,
        );
        // filtered undefined/null values
        const filteredObj = Object.keys(action.data).reduce((p, c) => {
          if (action.data[c] !== null || action.data[c] !== undefined) {
            p[c] = action.data[c];
          }
          return p;
        }, {});
        const ac = {
          ...existingAthlete,
          ...filteredObj,
        };

        return state.map((athlete) => {
          if (athlete.id === action.data.userTeamId) {
            return SchoolTeamMember.fromObject(ac);
          }
          return athlete;
        });
      }

      else if (action.model === EP_PHOTO_SAVE_NEW_ATHLETE && !action.data.isCoach) {
        const existingAthlete = state.find(athlete => (athlete.id === action.data.id));

        // filtered undefined/null values
        const filteredObj = Object.keys(action.data).reduce((p, c) => {
          if (action.data[c]) p[c] = action.data[c];
          return p;
        }, {});
        const ac = {
          ...existingAthlete,
          ...filteredObj,
        };

        return state.map((athlete) => {
          if (athlete.id === action.data.id) {
            return SchoolTeamMember.fromObject(ac);
          }
          return athlete;
        });
      } else if (action.model === REMOVE_USER_FROM_TEAM) {
        return state.filter((athlete) => athlete.id !== action.data.id);
      } else if (action.model === ADD_USER_TO_SCHOOL_TEAM) {
        const newState = [...state, action.data.removedAthlete];
        newState.sort((a, b) => {
          const teamComp = () => {
            const comp = a.teamOrderBy - b.teamOrderBy;
            if (!comp && a.teamName) {
              // Natural sort if both are within same order because teams' name are alphanumerical
              return a.teamName.localeCompare(b.teamName, undefined, {numeric: true, sensitivity: 'base'})
            }
            return comp;
          }
          return teamComp() !== 0 ? teamComp() : a.name.localeCompare(b.name);
        });
        return newState
      }
      if (action.model === ATHLETE_INVITE) {
        return state.map((athlete) => {
          if (athlete.userId === action.data.userId) {
            const newAthlete = Object.assign({}, athlete);
            newAthlete.invitationSend = true;
            return newAthlete;
          }
          return athlete;
        });
      }
      if (action.model === GET_COMBINE) {
        return action.data.athletes || [];
      }
      return state;
    default:
      return state;
  }
};

const athleteInviteChunkCount = (state = 0, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === ATHLETE_INVITE_CHUNK_COUNT) {
        return state + 1;
      }
      return state;
    default:
      return state;
  }
}

const athleteInviteChunkState = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === ATHLETE_INVITE_CHUNK_STATE) {
        return action.data.chunks;
      }
      if (action.model === UPDATE_ATHLETE_INVITE_CHUNK_STATE) {
        return state.map(item => item.filter(e => e.userId !== action.data.userId));
      }
      return state;
    default:
      return state;
  }
}

const removedAthlete = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === ADD_USER_TO_SCHOOL_TEAM){
        return [...state,action.data.removedAthlete];
      }
      return state;
    default:
      return state;
  }
}

const coaches = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ROSTER) {
        return action.data.coaches || [];
      } else if (
        action.model === EP_PHOTO_SAVE_NEW_ATHLETE &&
        action.data.isCoach
      ) {
        const coachIds = action.data.map((coach) => coach.id);
        return [
          ...state.filter((coach) => !coachIds.includes(coach.id)),
          ...action.data,
        ];
      }
      return state;
    default:
      return state;
  }
};

const admins = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ROSTER) {
        return action.data.admins || [];
      }
      return state;
    default:
      return state;
  }
};

const school = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL) {
        return action.data.school || {};
      }
      return state;
    default:
      return state;
  }
};

const viewSelection = (state = RosterViewOptions.list, action) => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasRosterView = searchParams.has('rosterView');
  const rosterView = hasRosterView ? searchParams.get('rosterView') : 'default';
  switch (action.type) {
    case CHANGE_VIEW_SELECTION:
      return action.viewSelection;
    case ASYNC_FINISH:
      if (action.model === CHECK_IF_CARD_VIEW || action.modal === UPDATE_CARD_VIEW) {
        return action.data.viewSelection;
      } else {
        if (hasRosterView) {
          switch (rosterView) {
            case 'card':
              return rosterView;
            case 'list' :
              return rosterView;
            case 'default' :
              state = RosterViewOptions.list
              return state
          }
        }
      }
      return state;
    default:
      return state;
  }
};

const dynamicRosterView = (state = RosterViewOptions.list, action) => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasRosterView = searchParams.has('rosterView');
  const rosterView = hasRosterView ? searchParams.get('rosterView') : 'default';
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_IF_CARD_VIEW || action.modal === UPDATE_CARD_VIEW) {
        return action.data.viewSelection;
      }
      else {
        if (hasRosterView) {
          switch (rosterView) {
            case 'card':
              return rosterView;
            case 'list' :
              return rosterView;
            case 'default' :
              state = RosterViewOptions.list
              return state
          }
        }
      }
      return state;
    default:
      return state;
  }
}


const search = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SEARCH: {
      return action.search || '';
    }
    default:
      return state;
  }
};

const sortBy = (state = RosterSortByOptions.firstNameAZ, action) => {
  switch (action.type) {
    case CHANGE_SORT_BY: {
      return action.sortBySelection;
    }
    default:
      return state;
  }
};

const loadingAthletePhotoMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === USER_ATHLETE_PHOTO ||
        action.model === EP_PHOTO_SAVE_NEW_ATHLETE
      ) {
        return action.msg;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (
        action.model === USER_ATHLETE_PHOTO ||
        action.model === EP_PHOTO_SAVE_NEW_ATHLETE
      ) {
        return '';
      }
      return state;
    case UPLOAD_PROGRESS_ATHLETE:
      if (action.msg) {
        return action.msg;
      }
      return state;
    default:
      return state;
  }
};

const loadingAthletePhoto = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === USER_ATHLETE_PHOTO ||
        action.model === EP_PHOTO_SAVE_NEW_ATHLETE
      ) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (
        action.model === USER_ATHLETE_PHOTO ||
        action.model === EP_PHOTO_SAVE_NEW_ATHLETE
      ) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const photoDropError = (state = false, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ERROR_ATHLETE:
      return true;
    case ROUTE_CHANGED:
      return false;
    default:
      return state;
  }
};

const photoFileDropped = (state = null, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ATHLETE:
      return action.file;
    case EP_PHOTO_DISCARD_ATHLETE:
    case EP_PHOTO_SAVE_NEW_ATHLETE:
      return null;
    default:
      return state;
  }
};

const photoCropDataLoading = (state = false, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ATHLETE:
      return true;
    case EP_PHOTO_DROP_ONLOAD_ATHLETE:
      return false;
    case EP_PHOTO_DISCARD_ATHLETE:
      return false;
    default:
      return state;
  }
};

const photoDataToCrop = (state = null, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ONLOAD_ATHLETE:
      return action.image;
    case EP_PHOTO_DISCARD_ATHLETE:
    case EP_PHOTO_SAVE_NEW_ATHLETE:
      return null;
    default:
      return state;
  }
};

const initialUploadProgress = {
  totalLoaded: 0,
  totalBytes: 0,
  percent: 0,
};
const loadingProgress = (state = initialUploadProgress, action) => {
  switch (action.type) {
    case UPLOAD_PROGRESS_ATHLETE:
      if (action.reset) {
        return Object.assign({}, initialUploadProgress);
      }
      if (action.progressEvent && action.progressEvent.lengthComputable) {
        return Object.assign({}, state, {
          totalBytes: action.totalBytes,
          totalLoaded: action.progressEvent.loaded,
          percent: Math.round(
            (action.progressEvent.loaded / action.totalBytes) * 100,
          ),
        });
      }
      return state;
    case ASYNC_START:
      if (action.model === USER_ATHLETE_PHOTO) {
        return null;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === USER_ATHLETE_PHOTO) {
        return Object.assign({}, initialUploadProgress);
      }
      return state;
    default:
      return state;
  }
};

const addingPhotoModeEnabled = (state = false, action) => {
  switch (action.type) {
    case PHOTO_MODE_ENABLED:
      return true;
    case PHOTO_MODE_DISABLED:
      return false;
    case ASYNC_FINISH:
      if (action.model === EP_PHOTO_SAVE_NEW_ATHLETE) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const currentProfile = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_PROFILE_BY_EMAIL) {
        return action.data;
      }
      return state;
    case RESET_GRID_EMAIL_PROFILE:
      return null;
    default:
      return state;
  }
};

const loadingSchedule = (state=false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPLOAD_SCHEDULE ||
        action.model === UPLOAD_SCHEDULE ||
        action.model === DELETE_SCHEDULE
      ) {
        return true;
      }
    case ASYNC_FINISH:
      if (action.model === UPLOAD_SCHEDULE ||
        action.model === UPLOAD_SCHEDULE ||
         action.model === DELETE_SCHEDULE ) {
        return false;
      }
    default:
      return state;
  }
}

const schedules= (state={}, action) => {
  switch (action.type) {
    case GET_SCHOOL_TEAM_SCHEDULE_SUCCESS:
      state[action.data.schedule.schoolTeamId] = {...action.data.schedule};
      return {...state};
    case ASYNC_FINISH:
      if (action.model === UPLOAD_SCHEDULE ) {
        state[action.data.schedule.schoolTeamId] = {...action.data.schedule};
        return {...state};
      }
      if(action.model === DELETE_SCHEDULE){
        const newState = { ...state };
        delete newState[action.data.schoolTeamId]
        return newState;
      }
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  loadingMsg,
  schoolTeams,
  athletes,
  removedAthlete,
  athleteInviteChunkCount,
  athleteInviteChunkState,
  coaches,
  admins,
  viewSelection,
  dynamicRosterView,
  search,
  sortBy,
  loadingProfiles,
  savingProfiles,
  loadingAthletePhoto,
  loadingAthletePhotoMsg,
  photoFileDropped,
  photoDataToCrop,
  photoDropError,
  photoCropDataLoading,
  loadingProgress,
  addingPhotoModeEnabled,
  school,
  currentProfile,
  schedules,
  loadingSchedule
});

export default reducer;
