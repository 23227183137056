import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ButtonLink = ({ children, className, ...props }) => (
  <Link className={['UxCommon_Form_Button', className || ''].join(' ')} {...props}>
    {children}
  </Link>
);
ButtonLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ButtonLink;
