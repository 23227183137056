import { connect } from 'react-redux';

import Component from '../../components/UxEditProfile/MoreActions/MoreActions';

const mapStateToProps = state => ({
  loading: state.ui.uxEditProfile.account.loading,
  uuid: state.data.cognito.uuid,
  sportId: state.ui.app.routes.currentSportId,
});

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
