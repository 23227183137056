import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

const years = () => {
  const ys = [];
  let y = 1900;
  while (y <= (moment().year() + 10)) {
    ys.push(y);
    y += 1;
  }
  return ys;
};

const months = () => {
  const ms = [];
  for (let i = 0; i < 12; i += 1) {
    ms.push(moment().month(i + 1));
  }
  return ms;
};

class Caption extends PureComponent {
  static propTypes = {
    month: momentPropTypes.momentObj.isRequired,
    onMonthSelect: PropTypes.func.isRequired,
    onYearSelect: PropTypes.func.isRequired,
  }

  selectMonth = (e) => {
    this.props.onMonthSelect(this.props.month, e.target.value);
  }

  selectYear = (e) => {
    this.props.onYearSelect(this.props.month, e.target.value);
  }

  render() {
    const { month } = this.props;
    return (
      <div>
        <select className="react-dates-select" value={month.month()} onChange={this.selectMonth}>
          {months().map(m => <option key={m.month()} value={m.month()}>{m.format('MMM')}</option>)}
        </select>
        <select className="react-dates-select" value={month.year()} onChange={this.selectYear}>
          {years().map(y => <option key={y} value={y}>{y}</option>)}
        </select>
      </div>
    );
  }
}

export default Caption;
