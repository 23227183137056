import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LeaderboardCard extends PureComponent {
  static propTypes = {
    combine: PropTypes.object.isRequired,
    athleteCombineResults: PropTypes.array,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => {}

  render() {
    const { combine, athleteCombineResults } = this.props;

    let athleteCount = null;
    const countObject = athleteCombineResults ?
      athleteCombineResults.find(a => a.combineId === combine.id) : null;
    athleteCount = countObject ? countObject.count : '-';

    return (
      <div className="CoachDashboardLeaderboards_Card">
        <div className="CoachDashboardLeaderboards_CardName">
          {`${combine.name}`}
        </div>
        <div className="CoachDashboardLeaderboards_Athlete_Link">
          <div className="CoachDashboardLeaderboards_CardAthlete">
            {`${athleteCount} Athletes`}
          </div>
          <div className="CoachDashboardLeaderboards_CardLink">
            View Leaderboard
          </div>
        </div>
      </div>
    );
  }
}

export default LeaderboardCard;
