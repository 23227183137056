import { connect } from 'react-redux';
import Component from './SignOutModal.component';
import { logout } from '../../../../store/actions/data/cognito';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onSignOut: () => {
    dispatch(logout());
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
