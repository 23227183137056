import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

import Urls from '../../../../config/urls';

export const getCombine = (combineId, token) => (
  ajax.getJSON(
    Urls.combines.get(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getCombineWithPrevCombine = (combineId, token) => (
  ajax.getJSON(
    Urls.combines.getWithPrevCombine(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getCombineResults = (combineId, token) => (
  ajax.getJSON(
    Urls.combineResults.get(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetCombineResultsPerAthlete = (combineId, athleteId, token) => (
  ajax.getJSON(
    Urls.combineResults.getAthlete(combineId, athleteId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetCombineResultsAllAthlete = (combineId, token) => (
  ajax.getJSON(
    Urls.combineResults.getAllAthleteResults(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getCombines = (schoolId, token) => (
  ajax.getJSON(
    Urls.combines.getAll(schoolId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const createCombine = (data, token) => (
  ajax.post(
    Urls.combines.post(),
    JSON.stringify(data),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const updateCombine = (data, combineId, token) => ajax.put(
  Urls.combines.put(combineId),
  JSON.stringify(data),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const bulkTestCreateOrUpdate = (data, token) => ajax.post(
  Urls.combineAction.post(),
  JSON.stringify(data),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const bulkTestDelete = (data, token) => ajax.post(
  Urls.combineAction.delete(),
  JSON.stringify(data),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);


export const deleteCombine = (combineId, token) => ajax.delete(
  Urls.combines.delete(combineId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const apiGetCombineTemplates = token => (
  ajax.getJSON(
    Urls.combineTemplates.getAll(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetCombineTemplateByCombine = (combineId, token) => (
  ajax.getJSON(
    Urls.combineStandardTestCategories.get(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetCombineTemplatesBySchool = (token, schoolId) => (
  ajax.getJSON(
    Urls.combineTemplates.getAllBySchool(schoolId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getCombineAwardsByCombineId = (token, combineId) => (
  ajax.getJSON(
    Urls.combineAwards.getAll(combineId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetTestCategories = () => (
  ajax.getJSON(
    Urls.combineTemplates.getTestCategories(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  )
);

export const apiGetTestBanks = () => (
  ajax.getJSON(
    Urls.combineTemplates.getTestBanks(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  )
);

export const apiGetTestSubbanks = () => (
  ajax.getJSON(
    Urls.combineTemplates.getTestSubbanks(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  )
);

export const createCombineAward = (data, combineId, token) => (
  ajax.post(
    Urls.combineAwards.post(combineId),
    JSON.stringify(data),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiCreateTest = (token, props = {}) => (
  ajax.post(
    Urls.tests.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

// comment: ""
// result: "729"
// standard_test_object_id: 101
// test_id: 618
export const apiCreateTestResult = (token, props = {}) => (
  ajax.post(
    Urls.testResults.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const apiUpdateTestResult = (token, testResultId, props = {}) => (
  ajax.put(
    Urls.testResults.put(testResultId),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const apiDeleteTestResult = (token, testResultId) => (
  ajax.delete(
    Urls.testResults.delete(testResultId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);
