export const mapPhysiqueProfileDataUiToApi = (fields, teamData = {}) => ({
    gender: fields.gender,
    height: fields.height,
    weight: fields.weight,
    privacy_weight: fields.gender === 'female',
    privacy_height: fields.gender === 'female',
    grad_year: fields.grad_year,
    zip: fields.zip,
    city: fields.city,
    state: fields.state,
    address: fields.address,
    birthday: fields.birthday,
    teamData: teamData
  });

export const mapAcademicProfileDataUiToApi = (fields) =>( {
  sportId: fields.sport_Id,
  ncca_id: fields.ncca_id,
  twitter: fields.twitter,
  instagram: fields.instagram,
  school: fields.school,
  gpa: fields.gpa,
  act: fields.act,
  sat: fields.sat,
  class_rank: fields.class_rank,
  class_size: fields.class_size,
  school_credit: fields.school_credit,
  committed_to: fields.committed_to,
  club_team: fields.club_team,
  club_coach_name: fields.club_coach_name,
  club_coach_email: fields.club_coach_email,
  club_coach_mobile_phone_number: fields.club_coach_mobile_phone_number,
  high_school_coach_name: fields.high_school_coach_name,
  high_school_coach_email: fields.high_school_coach_email,
  high_school_coach_phone_number: fields.high_school_coach_phone_number
});

// export const getTwitterOrInstagramValidation = (state) => {
//   let validation = {
//     twitter: [state.ui.uxEditProfile.twitterHandle.isAlreadyExistTwitterValidationMessage],
//     instagram: [state.ui.uxEditProfile.vanityUrl.isAlreadyExistInstagramValidationMessage],
//   };

//   return validation || null;
// };
