import { combineReducers } from 'redux';

import {
  CLOSE_ALBUM_DIALOG,
  OPEN_ALBUM_DIALOG,
  STAGE_NEW_MEDIA,
  CREATE_ALBUM,
  UPDATE_ALBUM,
  CLEAR_STAGED_MEDIA,
  UPLOAD_PROGRESS,
  CLEAR_LOADING_PROGRESS,
  STAGE_ALBUM_MEDIA,
  UNSTAGE_NEW_MEDIA,
  DELETE_PHOTO,
  DELETE_VIDEO,
  DELETE_ALBUM_EVERYTHING,
  DELETE_ALBUM_ONLY,
  STAGE_NEW_TAG,
  INIT_ALBUM_DIALOG,
  UNSTAGE_NEW_TAG,
  CHECK_VIDEO_URL,
} from '../../../../actions/ui/uxProfile/photosVideos';
import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../actions/ui/async';
import { EP_PHOTO_SAVE_NEW } from '../../../../actions/ui/uxEditProfile';
import { ALBUM_UPDATE_SUC, ALBUM_CREATE_SUC } from '../../../../actions/data/photo';

const isShowing = (state = false, action) => {
  switch (action.type) {
    case OPEN_ALBUM_DIALOG:
      return true;
    case CLOSE_ALBUM_DIALOG:
      return false;
    case ASYNC_FINISH:
      if ((action.model === CREATE_ALBUM) ||
        (action.model === UPDATE_ALBUM) ||
        (action.model === DELETE_ALBUM_EVERYTHING) ||
        (action.model === DELETE_ALBUM_ONLY)) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const albumToEdit = (state = null, action) => {
  switch (action.type) {
    case OPEN_ALBUM_DIALOG:
      return action.album;
    case ALBUM_CREATE_SUC:
    case CLOSE_ALBUM_DIALOG:
    case ALBUM_UPDATE_SUC:
      return null;
    default:
      return state;
  }
};

const albumTag = (state = null, action) => {
  switch (action.type) {
    case INIT_ALBUM_DIALOG:
      return action.albumTags;
    default:
      return state;
  }
};

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

const stagedMedia = (state = [], action) => {
  switch (action.type) {
    case STAGE_NEW_MEDIA:
      return [...state, {
        id: `${getRandomInt(10000000)}`,
        ...action.media,
        caption: '',
        tags: [],
        isNew: true,
      }];
    case STAGE_ALBUM_MEDIA:
      return [...state, {
        ...action.media,
        tags: [],
        isNew: false,
        isAPhoto: action.isAPhoto,
      }];
    case UNSTAGE_NEW_MEDIA:
      return state.filter(m => m.id !== action.media.id);
    case CLEAR_STAGED_MEDIA:
      return [];
    case ASYNC_FINISH:
      if (action.model === CHECK_VIDEO_URL) {
        return [...state, {
          id: `${getRandomInt(10000000)}`,
          isAPhoto: false,
          externalVideo: {
            ...action.data,
          },
          caption: action.data.title,
          tags: [action.data.provider],
          isNew: true,
        }];
      }
      return state;
    default:
      return state;
  }
};

const stagedTag = (state = [], action) => {
  switch (action.type) {
    case STAGE_NEW_TAG:
      return [...state, {
        id: `${getRandomInt(10000000)}`,
        label: action.newTag,
      }];
    case UNSTAGE_NEW_TAG:
      return state.filter(m => m.id !== action.tag[0].id);
    case CLEAR_STAGED_MEDIA:
      return [];
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CREATE_ALBUM) {
        return 'Creating album and saving media';
      }
      if (action.model === UPDATE_ALBUM) {
        return 'Saving album and media';
      }
      if (action.model === DELETE_PHOTO) {
        return 'Deleting photo';
      }
      if (action.model === DELETE_VIDEO) {
        return 'Deleting video';
      }
      if (action.model === DELETE_ALBUM_EVERYTHING) {
        return 'Deleting album and everything in it';
      }
      if (action.model === DELETE_ALBUM_ONLY) {
        return 'Deleting album only';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === CREATE_ALBUM) ||
        (action.model === UPDATE_ALBUM) ||
        (action.model === EP_PHOTO_SAVE_NEW) ||
        (action.model === DELETE_PHOTO) ||
        (action.model === DELETE_VIDEO) ||
        (action.model === DELETE_ALBUM_EVERYTHING) ||
        (action.model === DELETE_ALBUM_ONLY)) {
        return '';
      }
      return state;
    case UPLOAD_PROGRESS:
      if (action.msg) {
        return action.msg;
      }
      return state;
    default:
      return state;
  }
};

const progressMedia = (state, action) => (
  Object.assign(state.media, {
    [action.mediaId]: {
      total: action.progressEvent.total,
      loaded: action.progressEvent.loaded,
    },
  })
);
const calcTotalLoaded = (progresses) => {
  let loaded = 0;
  Object.values(progresses).forEach((progressObj) => {
    loaded += progressObj.loaded;
  });
  return loaded;
};

const initialUploadProgress = {
  media: {},
  totalLoaded: 0,
  totalBytes: 0,
  percent: 0,
};
const loadingProgress = (state = initialUploadProgress, action) => {
  let media;
  let totalLoaded;
  switch (action.type) {
    case UPLOAD_PROGRESS:
      if (action.reset) {
        return Object.assign({}, initialUploadProgress, {
          media: {},
          totalBytes: action.totalBytes,
        });
      }
      if ((action.progressEvent) && (action.progressEvent.lengthComputable)) {
        media = progressMedia(state, action);
        totalLoaded = calcTotalLoaded(media);
        return Object.assign({}, state, {
          media,
          totalLoaded,
          percent: Math.round((totalLoaded / state.totalBytes) * 100),
        });
      }
      return state;
    case ASYNC_START:
      if ((action.model === DELETE_ALBUM_EVERYTHING) ||
      (action.model === DELETE_ALBUM_ONLY)) {
        return {
          media: {},
          totalLoaded: 0,
          totalBytes: 0,
          percent: 0,
        };
      }
      return state;
    case CLEAR_LOADING_PROGRESS:
      return initialUploadProgress;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isShowing,
  albumToEdit,
  stagedMedia,
  stagedTag,
  loadingMsg,
  loadingProgress,
  albumTag,
});

export default reducer;
