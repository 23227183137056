import Video from '../models/Video';
import TagRelMedia from '../models/TagRelMedia';
import AlbumRelMedia from '../models/AlbumRelMedia';
import Tag from '../models/Tag';
import Album from '../models/Album';
import Photo from '../models/Photo';

export const mapVideoApiToUi = (rawVideo, newTagId) => {
  const video = new Video();
  const arrAlbumMedia = [];
  const objTagRelMedia = new TagRelMedia();

  video.fromApiResponse(rawVideo);
  if (rawVideo.albums) {
    rawVideo.albums.forEach((album) => {
      const objAlbumRelMedia = new AlbumRelMedia(album.id, rawVideo.id);
      arrAlbumMedia.push(objAlbumRelMedia);
    });
  }
  if (newTagId) {
    objTagRelMedia.mediaId = rawVideo.id;
    objTagRelMedia.tadIg = newTagId;
  }

  // TODO here arrAlbumMedia & objTagRelMedia will be returned (maybe)

  return video;
};

export const mapVideosApiToUi = response => (
  response.map(rawVideo => mapVideoApiToUi(rawVideo))
);

export const mapTagApiToUi = (rawTag) => {
  const tag = new Tag();
  tag.fromApiResponse(rawTag);
  return tag;
};

export const mapAlbumUpdateToUi = (mediaId, albumId, isAPhoto) => {
  const arm = new AlbumRelMedia();
  arm.isAPhoto = isAPhoto;
  arm.mediaId = mediaId;
  arm.albumId = albumId;
  return arm;
};

export const mapAlbumApiToUi = (rawAlbum) => {
  const album = new Album();
  album.fromApiResponse(rawAlbum);
  return album;
};

export const mapAlbumsApiToUi = response => (
  response.map(rawAlbum => mapAlbumApiToUi(rawAlbum))
);

export const mapAlbumDetailsApiToUi = (rawAlbumDetails, albumMediaColection = []) => {
  const album = new Album();
  album.fromApiResponse(rawAlbumDetails);
  rawAlbumDetails.photos.forEach((media) => {
    const albumMedia = new AlbumRelMedia();
    albumMedia.fromApiResponse(media, album.id, true);
    albumMediaColection.push(albumMedia);
  });

  rawAlbumDetails.videos.forEach((media) => {
    const albumMedia = new AlbumRelMedia();
    albumMedia.fromApiResponse(media, album.id, false);
    albumMediaColection.push(albumMedia);
  });

  return album;
};

export const mapTagDetailsApiToUi = (rawTagDetails, tagRelMediaCollection = []) => {
  const tag = new Tag();
  tag.fromApiResponse(rawTagDetails);
  if (rawTagDetails.photos) {
    rawTagDetails.photos.forEach((media) => {
      const tagRelMedia = new TagRelMedia();
      tagRelMedia.fromApiResponse(media.id, tag.id);
      tagRelMediaCollection.push(tagRelMedia);
    });
  }

  if (rawTagDetails.videos) {
    rawTagDetails.videos.forEach((media) => {
      const tagRelMedia = new TagRelMedia();
      tagRelMedia.fromApiResponse(media.id, tag.id);
      tagRelMediaCollection.push(tagRelMedia);
    });
  }

  return tag;
};
export const mapTagMediaApiToUI = (mediaId, newTagId) => {
  const tagRelMedia = new TagRelMedia();
  tagRelMedia.fromApiResponse(mediaId, newTagId);
  return tagRelMedia;
};

// TODO new tag id will be removed after backend get fixed
export const mapPhotoApiToUi = (rawPhoto) => {
  const photo = new Photo();
  photo.fromApiResponse(rawPhoto);
  return photo;
};

export const mapPhotosApiToUi = response => (
  response.map(rawPhoto => mapPhotoApiToUi(rawPhoto))
);

export const mapVideoAndAlbumsApiToUi = (rawVideo) => {
  const video = new Video();
  video.fromApiResponse(rawVideo);
  return video;
};

export const mapPhotosAndAlbumsApiToUi = (rawPhoto) => {
  const photo = new Photo();
  photo.fromApiResponse(rawPhoto);
  return photo;
};

export const mapPhotosVideosAlbumsApiToUi = (
  photosResponse,
  videosResponse,
  albumsResponse,
  tagsResponse,
) => {
  const albumRelMediaCollection = [];
  const tagRelMediaCollection = [];
  const albums = albumsResponse.map(rawAlbum =>
    mapAlbumDetailsApiToUi(rawAlbum, albumRelMediaCollection));
  const videos = videosResponse.map(rawVideo => mapVideoAndAlbumsApiToUi(rawVideo));
  const photos = photosResponse.map(rawPhoto => mapPhotosAndAlbumsApiToUi(rawPhoto));
  const tags = tagsResponse.map(rawTag => mapTagDetailsApiToUi(rawTag, tagRelMediaCollection));

  return {
    albums,
    videos,
    photos,
    tags,
    albumRelMediaCollection,
    tagRelMediaCollection,
  };
};

export const mapTranscriptApiToUi = (response) => {
  
  return {
    id : response.id,
    ownerId: response.owner_id,
    key: response.key,
    uploadedBy: response.uploaded_by,
    uploadUrl: response.upload_url,
    uploadComplete: response.upload_complete,
    sportId: response.sport_id,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    url: response.url,
    ext: response.ext || '',
  }
}