import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../../../UxCommon';

class EditMenu extends PureComponent {
  static propTypes = {
    stdTestObject: PropTypes.object.isRequired,
    onAddAnotherClick: PropTypes.func.isRequired,
    onImDoneClick: PropTypes.func.isRequired,
  }

  onAddAnotherClick = () => {
    const { stdTestObject, onAddAnotherClick } = this.props;
    onAddAnotherClick(stdTestObject);
  }

  render() {
    const { stdTestObject, onImDoneClick } = this.props;
    return (
      <div className="PlayerData_EditMenu">
        <div className="PlayerData_EditMenu_Label">
          {`Edit ${stdTestObject.cat.name} Data`}
        </div>
        <div className="PlayerData_EditMenu_Line" />
        <div style={{ flexGrow: 1 }} />
        <div className="PlayerData_EditMenu_Btns">
          <ButtonRect
            className="PlayerData_EditMenu_Btn"
            onClick={this.onAddAnotherClick}
          >
            Add Another Data Bar
          </ButtonRect>
          <ButtonRect
            className="PlayerData_EditMenu_Btn primary"
            onClick={onImDoneClick}
          >
            {`I'm Done${''}`}
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default EditMenu;
