import { connect } from 'react-redux';
import Component from './GenerateReportModal.component';
import { getSchoolCombines } from '../../+store/combine/combine.actions';
import { selectFilteredCombineAthletes } from '../../+store/combine/combine.selectors';
import { selectLoadingMsg } from '../../+store';
import { createReport, getReports } from '../../+store/report';


const mapStateToProps = (state, ownProps) => {
  const athletes = selectFilteredCombineAthletes(state, ownProps);
  const loadingMsg = selectLoadingMsg(state);
  const { reports } = state.modules.coachWorld.report;
  const { schoolId } = ownProps.match.params;
  return {
    loading: !!loadingMsg,
    loadingMsg,
    currentCombine: state.modules.coachWorld.combine.currentCombine,
    schoolId,
    athleteCountsList: state.modules.coachWorld.combine.athleteCount,
    allCombines: state.modules.coachWorld.combine.schoolCombines,
    athletes,
    reports: reports[schoolId] || [],
    reportsError: state.modules.coachWorld.report.reportsError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    getReports: (schoolId) => {
      dispatch(getReports([schoolId]));
    },
    getSchoolCombines: (schoolId) => {
      dispatch(getSchoolCombines([schoolId]));
    },
    generateReport: (schoolId, combineId, secondCombineId) => {
      dispatch(createReport(schoolId, combineId, secondCombineId));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
