import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {companyLogoUrl, transparentCompanyLogos } from "../../../UxProfile.constants";

const ExternalProfileLogo = ({company}) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const hoverMsg = `Check out my <strong> ${company} </strong> profile`;

  const imagePath = companyLogoUrl(company, !!transparentCompanyLogos.includes(company) )

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
    }
  }, [isLoaded]);

  return (
    isLoaded &&
    <div>
       <img data-tip= {hoverMsg} src  = {imagePath} className={`ExternalProfile_Logo_Card ${company}`} alt= {`${company} logo`} data-for='path' />
      <ReactTooltip id='path'  html={true} />
    </div>
  );
}
ExternalProfileLogo.prototype = {
  company: PropTypes.string.isRequired
}
export default ExternalProfileLogo;
