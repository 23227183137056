import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

class GetAwards extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    uuid: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    needsToLoadAwards: PropTypes.bool.isRequired,
    getProfile: PropTypes.func.isRequired,
    needsToLoadProfile: PropTypes.bool.isRequired,
    getAwards: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      uuid,
      getProfile,
      needsToLoadProfile,
      getAwards,
    } = this.props;
    if (uuid) {
      getAwards(uuid);
    }
    if (needsToLoadProfile && uuid) {
      getProfile(uuid);
    }
  }

  componentDidUpdate() {
    const {
      needsToLoadAwards,
      uuid,
      getProfile,
      getAwards,
      needsToLoadProfile,
    } = this.props;
    if (needsToLoadAwards && uuid) {
      getAwards(uuid);
    }
    if (needsToLoadProfile && uuid) {
      getProfile(uuid);
    }
  }

  render() {
    const { children, loading, loadingMsg } = this.props;
    return (
      <React.Fragment>
        {
          loading
          ? <LoadingSpinner loading loadingMsg={loadingMsg} />
          : children
        }
      </React.Fragment>
    );
  }
}

export default GetAwards;
