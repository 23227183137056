import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PositionWidgetBtn extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  }

  onClick = () => {
    const { value, onClick } = this.props;
    onClick(value);
  }

  render() {
    const { name, value, selected } = this.props;
    let showNameAndValue = true;
    if (name === value) {
      showNameAndValue = false;
    }
    return (
      <button
        className={[
          'EditSports_PositionWidgetBtn',
          selected ? 'selected' : '',
        ].join(' ')}
        type="button"
        onClick={this.onClick}
      >
        <div className="EditSports_PositionWidgetBtn_Value">
          {value}
        </div>
        {
          showNameAndValue && (
          <div className="EditSports_PositionWidgetBtn_Name">
            {name}
          </div>
        )
        }
      </button>
    );
  }
}

export default PositionWidgetBtn;
