import React from 'react';
import PropTypes from 'prop-types';
import CheckboxControl from '../../../../common/FormControls/CheckboxControl';
import { renderSubbankCategoryLogo } from './CategoryLogo';

const CategoryPicker = (props) => {
  const {
    name,
    cats,
    selectedCats,
    onChange,
    code
  } = props;
  let isChecked;
  return (
    <div className="CombineTemplate_CategoryPicker">
      <div className="CombineTemplate_SubbankName inline-flex" style={{ marginBottom: 12 }}>
        { renderSubbankCategoryLogo(code) }
        {name}
      </div>
      <div className="CombineTemplateWrapper_SubbankCol">
        {
           cats.map(cat => {
              isChecked = !!selectedCats.find(c => c.id === cat.id);
                  if (  cat.isActiveTest || isChecked) {
                      return (
                        <CheckboxControl
                        key={cat.code}
                        label= {cat.templateCatName}
                        checked={isChecked}
                        isDashr = {cat.isDashr}
                        myOnChange={onChange(cat.code)}
                        // className="filters"
                        arrangement="left"
                        subBankCode = {code}
                        // isEnableToEdit={isEnableToEdit}
                      />
                    )
                  }
              }
            )
        }
      </div>
    </div>
  );
};
CategoryPicker.propTypes = {
  name: PropTypes.string,
  cats: PropTypes.array,
  selectedCats: PropTypes.array,
  onChange: PropTypes.func,
  code: PropTypes.string
};

export default CategoryPicker;
