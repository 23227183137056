import { connect } from "react-redux";
import queryString from "query-string";
import Component from "./NorthEastLeaderboard.component";
import { getFilters, getQueryFromFilters, selectLoadingMsg, setSortBy } from "../+store/coachWorld";
import { BankSlugs, getTestCategories } from "../+store/combine";
import {
  currentSport,
  getCurrentTemplate,
  getRadius,
  getSearch,
  getZipCode,
  selectCurrentColumns,
  selectSortBy
} from "../+store/nationalLeaderboard/nationalLeaderboard.selectors";
import { getNationalLeaderboardTemplate, getSports } from "../+store/nationalLeaderboard";
import { getNorthEastLeaderboardResults } from "../+store/northEastLeaderboard";
import { selectNorthEastLeaderBoard } from "../+store/northEastLeaderboard/northEastLeaderboard.selectors";


const getNumResultsShown = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { results } = parsedQuery;
  return results || "100";
};

const setNumResultsShown = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.results = value;
  history.push({
    search: queryString.stringify(parsedQuery)
  });
};

const getScoreType = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { score } = parsedQuery;
  return score || "raw";
};

const setScoreType = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.score = value;
  history.push({
    search: queryString.stringify(parsedQuery)
  });
};

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const gradYears = [2022, 2023, 2024, 2025];
  return {
    isLoggedIn: !!state.data.cognito.uuid,
    numResultsShown: getNumResultsShown(state),
    loading: !!state.modules.coachWorld.northEastLeaderboard.loading,
    displayShowMore: state.modules.coachWorld.northEastLeaderboard.results ? state.modules.coachWorld.northEastLeaderboard.results.showMore : false,
    loadingMsg,
    template: getCurrentTemplate(state),
    columns: selectCurrentColumns(state, ownProps),
    measureableColumns: selectCurrentColumns(state, {
      match: {
        params: {
          bank: BankSlugs.PHYSIQUE
        }
      }
    }),
    sortByTestId: selectSortBy(state, ownProps),
    zip: getZipCode(state, ownProps),
    videos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    search: getSearch(state, ownProps),
    radius: getRadius(state, ownProps),
    currentSport: currentSport(state, ownProps),
    viewSelection: state.modules.coachWorld.leaderboard.viewSelection,
    selectedScoreType: getScoreType(state),
    results: selectNorthEastLeaderBoard(state, ownProps),
    testCats: state.modules.coachWorld.combine.standardTestCats,
    sports: state.data.sport.sports,
    selectedFilters: getFilters(state, ownProps),
    parsedFilters: getQueryFromFilters(state, ownProps),
    gradYears
  };
};

const mapDispatchToProps = dispatch => ({
  getNorthEastLeaderboardTestRes: (params) => {
    dispatch(getNorthEastLeaderboardResults(params));
  },
  getNationalLeaderboardTemplate: (sportId) => {
    dispatch(getNationalLeaderboardTemplate(sportId));
  },
  getTestCategories: () => {
    dispatch(getTestCategories());
  },
  getSports: () => {
    dispatch(getSports());
  },
  handleTestSortClicked: (location, history, stdTestObjectId) => {
    setSortBy(location, history, stdTestObjectId);
  },
  onResultsChange: (location, history, value) => {
    setNumResultsShown(location, history, value);
  },
  onScoreTypeChange: (location, history, value) => {
    setScoreType(location, history, value);
  }
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default Container;
