import { combineReducers } from 'redux';
import welcome from './welcome';
import uxProfile from './uxProfile';
import uxEditProfile from './uxEditProfile';
import uxPlayerData from './uxPlayerData';
import app from './app';

const reducer = combineReducers({
  welcome,
  uxProfile,
  uxEditProfile,
  uxPlayerData,
  app,
});

export default reducer;
