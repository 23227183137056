import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingSpinner from './Common/LoadingSpinner';
import LogoText from './Common/LogoText';
import { Routes } from '../../store/actions/ui/routes';
import AccountPage from './AccountPage';
import PhysiquePage from './PhysiquePage';
import SportsPage from './SportsPage';
import { getRole, redirectCheck, toProperCase } from './Common/utils';
import ErrorPage from './ErrorPage';
import SignInPage from './SignInPage';
import { Roles } from '../../store/actions/data/user/profile/models';


class CodeSignUp extends PureComponent {
  static propTypes = {
    authenticating: PropTypes.bool.isRequired,
    uuid: PropTypes.string,
    profile: PropTypes.object,
    getUserProfile: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    getUserTeams: PropTypes.func.isRequired,
    getTypesOfTeams: PropTypes.func.isRequired,
    checkCode: PropTypes.func.isRequired,
    isCodeValid: PropTypes.bool,
    schoolInfo: PropTypes.object,
    forceLogout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { checkCode, match } = this.props;
    document.getElementsByTagName('html')[0].classList.add('wrap-welcome');
    const { code } = match.params;
    checkCode(code);
  }

  componentDidUpdate() {
    const {
      uuid,
      profile,
      getUserProfile,
      getUserTeams,
      getTypesOfTeams,
      loggedIn,
      schoolInfo,
      isCodeValid,
    } = this.props;

    if (!this.profileFetched && uuid) {
      getUserProfile(uuid);
      this.profileFetched = true;
    }
    if (!this.teamsFetched && profile && uuid) {
      getUserTeams(uuid);
      this.teamsFetched = true;
    }
    if (!this.teamTypesFetched) {
      getTypesOfTeams();
      this.teamTypesFetched = true;
    }

    if (loggedIn && profile && schoolInfo && isCodeValid === true) {
      this.validateCode();
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('html')[0].classList.remove('wrap-welcome');
  }

  getRedirectUrl = () => {
    const { match } = this.props;
    return `/code/${match.params.code}/${Routes.signup}`;
  };

  getUserRole = () => {
    const { profile } = this.props;
    return Object.keys(Roles).find(key => Roles[key] === profile.roleId);
  };

  redirectToCorrectPage = (props) => {
    const { match } = props;
    const {
      uuid,
      profile,
      loading,
      schoolInfo,
    } = this.props;
    const redirect = redirectCheck(uuid, profile, match, schoolInfo);
    if (redirect && !loading) return <Redirect to={redirect}/>;
    return null;
  };

  welcomeString = () => {
    const {
      schoolInfo,
    } = this.props;
    return `You've signed up for ${schoolInfo.name}`;
  };

  redirectToSignUpStep = () => null;

  profileFetched = false;
  teamsFetched = false;
  teamTypesFetched = false;

  validateCode = () => {
    const { match, schoolInfo, forceLogout } = this.props;
    const codeRole = getRole(match.params.code, schoolInfo);
    const userRole = this.getUserRole();
    if (codeRole !== userRole) {
      forceLogout(this.getRedirectUrl());
      this.profileFetched = false;
      this.teamsFetched = false;
      this.teamTypesFetched = false;
    }
  };

  renderHome = () => (
    <Redirect to={`/${Routes.welcome}`}/>
  )

  render() {
    const {
      authenticating,
      loading,
      loadingMsg,
      isCodeValid,
      schoolInfo,
      match,
    } = this.props;

    if (authenticating) {
      return (
        <div className="CodeSignUp_AuthMsg">Authenticating ...</div>
      );
    }
    if (isCodeValid === false) {
      return (
        <ErrorPage
          errorName="Sign Up Code Invalid"
          errorMsg="We don't recognize the code you entered, please try again"
        />
      );
    }

    let welcomeMsg = '';
    let role = '';
    if (schoolInfo) {
      role = getRole(match.params.code, schoolInfo);
      role = `${role.charAt(0).toUpperCase()}${role.slice(1)}`;
      welcomeMsg = this.welcomeString();
    }
    return (
      <div
        className="CodeSignUp_Backdrop"
      >
        <div className="CodeSignUp_BackdropTint"/>
        {(loading || loading) && (
          <LoadingSpinner
            loading={loading || loading}
            loadingMsg={loadingMsg || loadingMsg}
          />
        )}


        <div className="CodeSignUp_Wrapper">
          <div className="CodeSignUp">
            <Route path={`/${Routes.code}/:code/:page?`} render={this.redirectToCorrectPage}/>
            {schoolInfo && (
              <div className="CodeSignUp_WelcomeMsg">
                <span style={{ fontSize: '28px' }}>{`Hey ${role}! `}</span>
                {welcomeMsg}
              </div>
            )}
            <LogoText/>
            <Switch>
              <Route exact path={`/${Routes.code}/:code`} render={this.redirectToSignUpStep}/>
              <Route exact path={`/${Routes.code}/:code/${Routes.signup}`} component={AccountPage}/>
              <Route exact path={`/${Routes.code}/:code/${Routes.login}`} component={SignInPage}/>
              <Route exact path={`/${Routes.code}/:code/${Routes.physique}`} component={PhysiquePage}/>
              <Route exact path={`/${Routes.code}/:code/${Routes.mySports}`} component={SportsPage}/>
              <Route exact path="" render={this.renderHome}/>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default CodeSignUp;
