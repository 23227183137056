import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/ConfirmAccount/ConfirmAccount';
import { confirmAccountFormInit } from '../../store/actions/ui/welcome';
import { resendCode } from '../../store/actions/data/cognito';
import { formSubmit } from '../../store/actions/ui/formUtils';

const mapStateToProps = state => ({
  formGeneralError: state.ui.welcome.confirmAccount.formError,
  needsInit: state.ui.welcome.confirmAccount.formNeedsInit,
  rrfForm: state.forms.forms.confirmAccount,
  username: state.ui.welcome.confirmAccount.username,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    formInit: (isMobile = false) => {
      dispatch(actions.setInitial('forms.confirmAccount'));
      dispatch(actions.reset('forms.confirmAccount'));
      dispatch(confirmAccountFormInit());
      if (isMobile) {
        dispatch(actions.change('forms.confirmAccount.username', state.ui.welcome.secondSignIn.username));
        dispatch(actions.change('forms.confirmAccount.password', state.ui.welcome.secondSignIn.password));
      }
    },
    submit: (isMobile = false) => {
      let username;
      if (isMobile) {
        // eslint-disable-next-line prefer-destructuring
        username = state.ui.welcome.confirmAccount.username;
      } else {
        username = decodeURI(ownProps.match.params.username).replace('aAdDoOtT', '.');
      }
      dispatch(formSubmit(
        'forms.confirmAccount',
        {
          username,
        },
      ));
    },
    resendConfirmCode: (isMobile = false) => {
      let username;
      if (isMobile) {
        ({ username } = state.ui.welcome.confirmAccount);
      } else {
        username = decodeURI(ownProps.match.params.username).replace('aAdDoOtT', '.');
      }
      dispatch(resendCode(username));
    },
    autoConfirm: (code, isMobile = false) => {
      let username;
      if (isMobile) {
        username = state.ui.welcome.confirmAccount.email;
      } else {
        username = decodeURI(ownProps.match.params.username).replace('aAdDoOtT', '.');
      }
      dispatch(actions.change('forms.confirmAccount.code', code));
      dispatch(formSubmit(
        'forms.confirmAccount',
        {
          username,
        },
      ));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
