import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';
import {AthleteGradesTh} from '../../+store';

class AthleteMedia extends PureComponent {
  static propTypes = {
    athlete: PropTypes.object.isRequired,
    style: PropTypes.object,
    isPrintProcess: PropTypes.bool,
  };

  static defaultProps = {
    isPrintProcess: false,
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => {
  };

  render() {
    const {
      athlete,
      style,
      isPrintProcess,
    } = this.props;
    return (
      <div className="AthleteMedia" style={style || {}}>
        {
          athlete.headshotUrl ? (
              !isPrintProcess && <div
                className="AthleteMedia_Headshot"
                style={{
                  backgroundImage: `url(${athlete.headshotUrl})`,
                }}
              />
            ) :
            (
              !isPrintProcess &&
              <div className="AthleteMedia_Headshot">
                <Icon iconName="photo2" className="AthleteMedia_PhotoIcon"/>
              </div>
            )
        }
        <div className="AthleteMedia_MediaBody">
          <div
            className="AthleteMedia_MediaName"
          >{`${athlete.givenName || ''} ${athlete.familyName || ''}`}
          </div>
          <div className="AthleteMedia_MediaInfo">
            <div className="AthleteMedia_Grade">
              {athlete.gradYear}
            </div>
            <div className="AthleteMedia_Position">
              {athlete.combineNumber ? (
                `${athlete.position ? athlete.position : '-'} | #${athlete.combineNumber}`
              ) : (
                `${athlete.position ? athlete.position : '-'}`
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AthleteMedia;
