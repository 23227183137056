import { connect } from 'react-redux';
import Component from './GridView.component';
import { getProfiles } from '../../+store/roster/roster.actions';
import { selectAthletes, selectCoaches, selectCanEditRoster } from '../../+store/roster/roster.selectors';
import { removeUserFromTeam, selectCoachSchoolIds, updateAthleteProfileInfo } from '../../+store';

const mapStateToProps = (state, ownProps) => {
  const { team } = ownProps;
  const allAthletes = selectAthletes(state, ownProps);
  const allCoaches = selectCoaches(state, ownProps);
  const teamName = team ? team.name || team.teamRef.team : '';

  return {
    athletes: team ? allAthletes[team.id] : [],
    coaches: team ? allCoaches[team.id] : [],
    team,
    teamName,
    profilesLoading: state.modules.coachWorld.roster.loadingProfiles,
    viewSelection: state.modules.coachWorld.roster.viewSelection,
    canEdit: selectCanEditRoster(state, ownProps),
    coachSchoolIds: selectCoachSchoolIds(state),
    userProfileId: state.data.cognito.uuid,
    profiles: state.data.user.profiles,
    school: state.modules.coachWorld.coachWorld.schoolTeamsLists &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId] &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId][0]
      .team.school,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    removeUserFromTeam: (userTeamId) => {
      dispatch(removeUserFromTeam(userTeamId));
    },
    updateAthleteProfileInfo: (uuid, profile) => {
      dispatch(updateAthleteProfileInfo(uuid, profile));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
