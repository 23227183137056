import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { privacyType } from '../../../utils/index';
import  PrivacyControl  from '../../../containers/UxCommon/PrivacyControl/PrivacyControl';

import { Container, Row, Col, LoadingSpinner, Line } from '../../UxCommon';
import {
  TextControl,
  Button,
  Headline,
  SelectControl,
  DatePicker,
  GeneralError,
} from '../../UxCommon/Form';
import { formOnEnterPress, gender } from '../../UxCommon/Form/utils';
import {
  parserOnlyLetters,
  parserLower,
  parserTelephone,
  parserName,
  parserAlphaLettersWithRmSpecialChar,
  parseAddress
} from '../../../utils/parsers';
import PhoneControl from '../../UxCommon/Form/PhoneControl/PhoneControl';
import MergeProfiles from "../../Welcome/ValidateInvite/MergingModals/MergeProfiles.component";
import axios from "axios";
import Urls from "../../../config/urls";
import MergeAthleteAccount from "./MergingModals/MergeAthleteAccount.component";
import {
  mergeAnotherAccount,
  mergeAnotherAccountAuth,
  mergeSignIn,
  mergeSignInAuth
} from "../../../store/actions/ui/routes";
import { MergeTypes } from "../../Welcome/MergeSignIn/MergeSignIn";

class Account extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onBirthdateChange: PropTypes.func.isRequired,
    onFirstNameKeyUp: PropTypes.func.isRequired,
    onLastNameKeyUp: PropTypes.func.isRequired,
    phoneHelp: PropTypes.array.isRequired,
    parentsPhoneHelp: PropTypes.array.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    formInit: PropTypes.func.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    isCoach: PropTypes.bool,
    isMetricUnits: PropTypes.bool.isRequired,
    onPhoneChange:PropTypes.func.isRequired,
    signOutWithRedirect:PropTypes.func.isRequired,
  }

  state = {
    allowedToEditEmail: false,
    showMergePopup: false,
    duplicateProfile: null,
  }
  componentDidMount() {
    const { formInit, profile, isCoach  } = this.props;
    if (profile) {
      this.setState( {
        allowedToEditEmail: isCoach && profile.email ? true : false
      })
      formInit();
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { profile, formNeedsInit, formInit,isCoach } = this.props;

    if (profile && formNeedsInit) {
      if (previousProps.profile.email !== this.props.profile.email) {
        this.setState( {
          allowedToEditEmail: isCoach && profile.email ? true : false
        })
      }
      formInit();
    }
  }

  phoneHelp = () => {
    const { phoneHelp, rrfForm } = this.props;
    return (
      rrfForm.phone.focus &&
      <React.Fragment>
        {
          phoneHelp.map((line, index) => (
            <div key={index}>{line}</div>
          ))
        }
      </React.Fragment>
    );
  };

  parentsPhoneHelp = () => {
    const { parentsPhoneHelp, rrfForm } = this.props;
    return (
      rrfForm.parentsPhone.focus &&
      <React.Fragment>
        {
          parentsPhoneHelp.map((line, index) => (
            <div key={index}>{line}</div>
          ))
        }
      </React.Fragment>
    );
  };

  parents2PhoneHelp = () => {
    const { parentsPhoneHelp, rrfForm } = this.props;
    return (
      rrfForm.parents2Phone.focus &&
      <React.Fragment>
        {
          parentsPhoneHelp.map((line, index) => (
            <div key={index}>{line}</div>
          ))
        }
      </React.Fragment>
    );
  };

  submit = async () => {
    const { isMetricUnits , rrfForm, profile} = this.props;
    try {
      this.setState({showLoader: true});

      //there are existing athletes which are fully onboarded with the same email before onboarding2.0
      // so pno need to merge if email doesn't change
      if(profile.email === rrfForm.email.value){
        throw new Error("No need to check for duplicate profile!");
      }
      const response = await axios.get(`${Urls.getDuplicateProfile(profile.uuid, rrfForm.email.value)}`);
      const duplicateProfile = response.data;
      this.setState({duplicateProfile: duplicateProfile, showMergePopup: true});
    } catch (error) {
      this.props.submit(false, isMetricUnits);
    }finally {
      this.setState({showLoader: false});
    }

  }

  onNext = (value) => {
    const { match, history, signOutWithRedirect , profile, rrfForm} = this.props;
    switch (value) {
      case "1":
        return history.push(mergeSignInAuth(
          MergeTypes.mergeBoth,
          rrfForm.email.value,
        ))

      case "2":
        return history.push(mergeAnotherAccountAuth(
          MergeTypes.mergeBoth,
        ));
      default:
        return false;
    }
  };

  render() {
    const {
      loading,
      loadingMsg,
      rrfForm,
      onBirthdateChange,
      formGeneralError,
      onFirstNameKeyUp,
      onLastNameKeyUp,
      isCoach,
      isMetricUnits,
      onPhoneChange,
      match,
      profile,
    } = this.props;

    const { showMergePopup, duplicateProfile } = this.state;

    return (
      <div className="UxEditProfile_Account">
        <LoadingSpinner
          loading={loading}
          loadingMsg={loadingMsg}
          style={{
            position: 'relative',
            height: 200,
            zIndex: 'unset',
          }}
        />
        {
          !!showMergePopup && (
            <MergeAthleteAccount
              match={match}
              profile={profile}
              duplicateProfile={duplicateProfile}
              onNext={this.onNext}
              onCancel={(_)=>this.setState({showMergePopup : false})}
            />
          )
        }

        <Container>
          {
            !loading && (
              <Row className="justify-content-center">
                <Col className="col-auto UxEditProfile_Acccount_Col">
                  <Form
                    model="forms.account"
                    onSubmit={this.submit}
                    //onKeyDown={formOnEnterPress('forms.account.submit')}
                  >
                    <Headline>Account Info</Headline>
                    <Row>
                      <Col>
                        <TextControl
                          label="First"
                          placeholder="First"
                          model="forms.account.first"
                          rrfField={rrfForm.first}
                          parser={parserAlphaLettersWithRmSpecialChar}
                          onKeyUp={onFirstNameKeyUp}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Last"
                          placeholder="Last"
                          model="forms.account.last"
                          rrfField={rrfForm.last}
                          parser={parserAlphaLettersWithRmSpecialChar}
                          onKeyUp={onLastNameKeyUp}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          type="email"
                          label="Email Address"
                          placeholder="Email Address"
                          model="forms.account.email"
                          rrfField={rrfForm.email}
                          parser={parserLower}
                          disabled={this.state.allowedToEditEmail}
                          // disabled={isCoach && rrfForm.email.value ? true: false}
                        />
                      </Col>
                      <Col>
                        <PhoneControl
                          type="tel"
                          label="Mobile Phone"
                          placeholder="Mobile Phone"
                          model="forms.account.phone"
                          rrfField={rrfForm.phone}
                          parser={parserTelephone}
                          help={this.phoneHelp()}
                          helpClass="left"
                          disabled={isCoach}
                          onPhoneChange={onPhoneChange}
                          defaultCountry = {profile.country}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DatePicker
                          label="Date of Birth"
                          placeholder="Date of Birth"
                          model="forms.account.birthdate"
                          rrfField={rrfForm.birthdate}
                          onDateChange={onBirthdateChange}
                        />
                      </Col>
                      <Col>
                        <SelectControl
                          label="Gender"
                          placeholder="Gender"
                          model="forms.account.gender"
                          rrfField={rrfForm.gender}
                          options={gender()}
                          help="Gender factors into the calculations for VC rating"
                          helpClass="left"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          isMetricUnits ? (
                            <TextControl
                              label="Height (m)"
                              placeholder="Height (m)"
                              model="forms.account.heightMt"
                              rrfField={rrfForm.heightMt}
                            />
                          ) : (
                            <TextControl
                              label="Height (ft)"
                              placeholder="Height (ft)"
                              model="forms.account.heightFt"
                              rrfField={rrfForm.heightFt}
                            />
                          )
                        }
                      </Col>
                      <Col>
                        {
                          isMetricUnits ? (
                            <TextControl
                              label="Height (cm)"
                              placeholder="Height (cm)"
                              model="forms.account.heightCm"
                              rrfField={rrfForm.heightCm}
                              defaultValue="0"
                            />
                          ) : (
                            <TextControl
                              label="Height (inches)"
                              placeholder="Height (inches)"
                              model="forms.account.heightInch"
                              rrfField={rrfForm.heightInch}
                              defaultValue="0"
                            />
                          )
                        }
                          <PrivacyControl
                            match={match}
                            privacyType={privacyType.HEIGHT}
                            className="BioRow_PrivacyControl privacy_weight"
                            loadingStyle={{ left: -35 }}
                            buttonStyle={{ right: -50 }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        { isMetricUnits ? (
                          <TextControl
                            label="Weight (kg)"
                            placeholder="Weight (kg)"
                            model="forms.account.weightKg"
                            rrfField={rrfForm.weightKg}
                          />
                        ) : (
                          <TextControl
                            label="Weight (lbs)"
                            placeholder="Weight (lbs)"
                            model="forms.account.weight"
                            rrfField={rrfForm.weight}
                          />
                        )
                        }
                        {
                          <PrivacyControl
                            match={match}
                            privacyType={privacyType.WEIGHT}
                            className="BioRow_PrivacyControl privacy_weight"
                            loadingStyle={{ left: -35 }}
                            buttonStyle={{ right: -50 }}
                          />
                        }
                      </Col>
                      <Col>
                        &nbsp;
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          label="Parent's Name"
                          placeholder="Parent's Name"
                          model="forms.account.parentsName"
                          rrfField={rrfForm.parentsName}
                          parser={parserAlphaLettersWithRmSpecialChar}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Parent 2 Name"
                          placeholder="Parent 2 Name"
                          model="forms.account.parent2Name"
                          rrfField={rrfForm.parent2Name}
                          parser={parserAlphaLettersWithRmSpecialChar}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Parent's Email Address"
                          placeholder="Parent's Email Address"
                          model="forms.account.parentsEmail"
                          rrfField={rrfForm.parentsEmail}
                          parser={parserLower}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Parent 2 Email Address"
                          placeholder="Parent 2 Email Address"
                          model="forms.account.parent2Email"
                          rrfField={rrfForm.parent2Email}
                          parser={parserLower}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          type="tel"
                          label="Parent's Mobile Phone"
                          placeholder="Parent's Mobile Phone"
                          model="forms.account.parentsPhone"
                          rrfField={rrfForm.parentsPhone}
                          parser={parserTelephone}
                          helpClass="left"
                          help={this.parentsPhoneHelp()}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          type="tel"
                          label="Parent 2 Mobile Phone"
                          placeholder="Parent 2 Mobile Phone"
                          model="forms.account.parents2Phone"
                          rrfField={rrfForm.parents2Phone}
                          parser={parserTelephone}
                          helpClass="left"
                          help={this.parents2PhoneHelp()}
                        />
                      </Col>
                      <Col>
                      {
                        (rrfForm.address &&
                        <TextControl
                          label="Address"
                          placeholder="Address"
                          model="forms.account.address"
                          rrfField={rrfForm.address}
                          parser ={parseAddress}
                          type = "textarea"
                        />
                        )
                      }
                      </Col>
                      <Col>
                        <TextControl
                          label="City"
                          placeholder="City"
                          model="forms.account.city"
                          rrfField={rrfForm.city}
                          parser ={parserName}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          label="State"
                          placeholder="State"
                          model="forms.account.state"
                          rrfField={rrfForm.state}
                          parser ={parserName}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Zipcode"
                          placeholder="Zipcode"
                          model="forms.account.zip"
                          rrfField={rrfForm.zip}
                          // parser={parserTelephone}
                        />
                      </Col>
                    </Row>

                    <GeneralError error={formGeneralError} />
                    <div className="d-flex justify-content-between">
                      <div className="UxEditProfile_VanityUrl_BtnWrapper">
                        <Button
                          id="forms.account.submit"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            )
          }
          <Line style={{ paddingTop: '50px' }} />
        </Container>
      </div>
    );
  }
}

export default Account;
