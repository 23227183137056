import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../config/urls';

export const getPhotos = (uuid, token) =>
  ajax.getJSON(
    Urls.photos.getAll(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getFavoritePhotos = (uuid, sportId, token) =>
  ajax.getJSON(
    Urls.photos.getFavorites(uuid, sportId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getPhoto = (token, photoId) =>
  ajax.getJSON(
    Urls.photos.get(photoId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getTranscript = (token, sportId, athleteId ) =>
ajax.getJSON(
  Urls.transcript.get(athleteId, sportId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const getVideos = (uuid, token) =>
  ajax.getJSON(
    Urls.videos.getAll(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getFavoriteVideos = (uuid, sportId, token) =>
  ajax.getJSON(
    Urls.videos.getFavorites(uuid, sportId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getVideo = (token, videoId) =>
  ajax.getJSON(
    Urls.videos.get(videoId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export const createPhoto = (token, props = {
  caption: '',
  athlete_id: '',
}) =>
  ajax.post(
    Urls.photos.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const uploadPhotoToS3 = (uploadUrl, body, progressSubscriber) => ajax({
  url: uploadUrl,
  method: 'put',
  body: body.uri ? {
    uri: body.uri,
    type: 'image/jpeg',
    name: body.fileName,
  } : body,
  headers: {
    'Content-Type': 'image/jpeg',
  },
  progressSubscriber,
})
  .pipe(map(response => response.response));

export const flagPhotoUploaded = (photoId, token) =>
  ajax.put(
    Urls.photoUploaded(photoId),
    '{}',
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const createTranscript = (token, props) =>
  ajax.post(
    Urls.transcript.post(props.athlete_id, props.sport_id),
    { ext: props.ext },
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const uploadTranscriptToS3 = (uploadUrl, body, progressSubscriber) => ajax({
  url: uploadUrl,
  method: 'put',
  body: body,
  headers: {
    'Content-Type': body.type || 'application/octet-stream',
  },
  progressSubscriber,
})
  .pipe(map(response => response.response));

  export const flagTranscriptUploaded = (athleteId, sportId, transcriptId, token) =>
  ajax.put(
    Urls.transcriptUploaded(athleteId, sportId, transcriptId),
    '{}',
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
  

export const createVideo = (token, props = {
  caption: '',
  athlete_id: '',
}) =>
  ajax.post(
    Urls.videos.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const uploadVideoToS3 = (uploadUrl, body, progressSubscriber) =>
  ajax({
    url: uploadUrl,
    method: 'put',
    body,
    headers: {
      'Content-Type': body.type || 'application/octet-stream',
    },
    progressSubscriber,
  })
    .pipe(map(response => response.response));

export const flagVideoUploaded = (photoId, token, body = null) =>
  ajax.put(
    Urls.videoUploaded(photoId),
    body ? JSON.stringify(body) : null,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const deletePhoto = (photoId, token, uuid = null) =>
  ajax.delete(
    Urls.photos.delete(photoId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const deleteTranscript = (athleteId, sportId, transcriptId, token) =>
ajax.delete(
  Urls.transcript.delete(athleteId, sportId, transcriptId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
)
  .pipe(map(response => response.response));

export const deleteVideo = (videoId, token, uuid = null) =>
  ajax.delete(
    Urls.videos.delete(videoId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const favoritePhotoSport = (photoId, sportId, token, body = {}) =>
  ajax.post(
    Urls.photos.favorites.post(photoId, sportId),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const favoriteVideoSport = (photoId, sportId, token, body = {}) =>
  ajax.post(
    Urls.videos.favorites.post(photoId, sportId),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const unfavoritePhotoSport = (photoId, sportId, token, uuid = null) =>
  ajax.delete(
    Urls.photos.favorites.delete(photoId, sportId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const unfavoriteVideoSport = (photoId, sportId, token, uuid = null) =>
  ajax.delete(
    Urls.videos.favorites.delete(photoId, sportId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const deleteTag = (tagId, token, uuid = null) =>
  ajax.delete(
    Urls.tags.delete(tagId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));


export const deleteTagFromPhoto = (token, tag, photoId, uuid = null) =>
  ajax.delete(
    Urls.photos.tags.delete(photoId, tag, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const deleteTagFromVideo = (token, tag, videoId, uuid = null) =>
  ajax.delete(
    Urls.videos.tags.delete(videoId, tag, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const updatePhoto = (photoId, token, fields) =>
  ajax.put(
    Urls.photos.put(photoId),
    JSON.stringify(fields),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const updateVideo = (videoId, token, fields) =>
  ajax.put(
    Urls.videos.put(videoId),
    JSON.stringify(fields),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const updateTag = (tagId, token, label) =>
  ajax.put(
    Urls.tags.put(tagId),
    JSON.stringify(label),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const getAlbums = (uuid, token) =>
  ajax.getJSON(
    Urls.albums.getAll(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const getAlbum = (albumId, token) =>
  ajax.getJSON(
    Urls.albums.get(albumId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const createAlbum = (token, album) =>
  ajax.post(
    Urls.albums.post(),
    JSON.stringify(album),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
export const updateAlbum = (albumId, token, album) =>
  ajax.put(
    Urls.albums.put(albumId),
    JSON.stringify(album),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
export const deleteAlbum = (albumId, token, uuid = null) =>
  ajax.delete(
    Urls.albums.delete(albumId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const addMediaToAlbum = (token, albumId, albumMediaId, albumMedia, isAPhoto) =>
  ajax.post(
    isAPhoto ?
      Urls.albums.photos.post(albumId) :
      Urls.albums.videos.post(albumId, albumMediaId),
    JSON.stringify(albumMedia),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
export const updateMediaIdAlbum = (token, albumId, mediaId, albumMedia, isAPhoto) =>
  ajax.put(
    isAPhoto ?
      Urls.albums.photos.put(albumId, mediaId) :
      Urls.albums.videos.put(albumId, mediaId),
    JSON.stringify(albumMedia),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
export const removeMediaFromAlbum = (token, albumId, mediaId, isAPhoto, uuid = null) =>
  ajax.delete(
    isAPhoto ?
      Urls.albums.photos.delete(albumId, mediaId, uuid) :
      Urls.albums.videos.delete(albumId, mediaId, uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const createTag = (token, tag) => ajax.post(
  Urls.tags.post(),
  JSON.stringify(tag),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
)
  .pipe(map(response => response.response));

export const getTags = (uuid, token) =>
  ajax.getJSON(
    Urls.tags.getAll(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export const getTag = (tagId, token) => ajax.getJSON(
  Urls.tags.get(tagId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const addTagToPhoto = (token, tag, photoId) =>
  ajax.post(
    Urls.photos.tags.post(photoId),
    JSON.stringify(tag),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const addTagToVideo = (token, tag, videoId) =>
  ajax.post(
    Urls.videos.tags.post(videoId),
    JSON.stringify(tag),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));
export const getVideoMeta = url =>
  ajax.getJSON(
    Urls.videos.meta(url),
    {
      Accept: 'application/json',
    },
  );
