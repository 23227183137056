import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { Routes, expandedPhotoDialogPath } from '../../../../store/actions/ui/routes';
import PhotosVidoesPicker from './PhotosVideosPicker/PhotosVideosPicker';
import Albums from './Albums/Albums';
import { Line } from '../../../UxCommon';
import Tags from './Tags/Tags';

class SideBar extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    onPhotosClick: PropTypes.func.isRequired,
    onVideosClick: PropTypes.func.isRequired,
    onAllClick: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    albums: PropTypes.array.isRequired,
    onAlbumSelected: PropTypes.func.isRequired,
    onAlbumUnselected: PropTypes.func.isRequired,
    selectedAlbums: PropTypes.array.isRequired,
    onEditAlbumsClick: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    onTagSelected: PropTypes.func.isRequired,
    onTagUnselected: PropTypes.func.isRequired,
    selectedTagsFilters: PropTypes.array.isRequired,
    onEditTagsClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    tagsRelMedia: PropTypes.array.isRequired,
  }

  componentDidMount() {
  }

  getRoute = (page) => {
    const { match } = this.props;
    return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}`;
  };

  renderHoverlay = () => (
    <div className="UxProfile_Photos_SideBar_Hoverlay" />
  )

  render() {
    const {
      onPhotosClick,
      onVideosClick,
      onAllClick,
      selectedCategory,
      albums,
      selectedAlbums,
      onAlbumSelected,
      onAlbumUnselected,
      onEditAlbumsClick,
      isMyProfile,
      tags,
      selectedTagsFilters,
      onTagSelected,
      onTagUnselected,
      onEditTagsClick,
      tagsRelMedia,
    } = this.props;
    return (
      <div className="UxProfile_Photos_SideBar">
        <Route
          path={expandedPhotoDialogPath}
          render={this.renderHoverlay}
        />
        <PhotosVidoesPicker
          onPhotosClick={onPhotosClick}
          onVideosClick={onVideosClick}
          onAllClick={onAllClick}
          selectedCategory={selectedCategory}
        />
        <Line />
        <Albums
          onEditClick={onEditAlbumsClick}
          albums={albums}
          onAlbumSelected={onAlbumSelected}
          onAlbumUnselected={onAlbumUnselected}
          selectedAlbums={selectedAlbums}
          isMyProfile={isMyProfile}
        />
        { <Line /> }
        { <Tags
          onEditClick={onEditTagsClick}
          tags={tags}
          onTagSelected={onTagSelected}
          onTagUnselected={onTagUnselected}
          selectedTagsFilters={selectedTagsFilters}
          tagsRelMedia={tagsRelMedia}
          isMyProfile={isMyProfile}
        /> }
      </div>
    );
  }
}

export default SideBar;
