import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment } from '../../../../store/actions/ui/uxProfile/utils';
import { VERIFY_EMAIL_PHONE } from './account.actions';
import { cognitoVerifyPhone, cognitoVerifyEmail } from '../../../../store/actions/data/cognito';

const verifyEmailPhoneEpic = action$ => (
  action$.pipe(
    ofType(VERIFY_EMAIL_PHONE),
    switchMap((action) => {
      let verify;
      if (action.isTelephone) {
        verify = cognitoVerifyPhone;
      } else {
        verify = cognitoVerifyEmail;
      }
      return from(verify(action.code)).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'verifyEmailPhone',
      ));
    }),
    switchMap((result) => {
      if (result.success) {
        let successMsg;
        if (result.action.isTelephone) {
          successMsg = 'Thank you.  Your phone number has been verified.';
        } else {
          successMsg = 'Thank you.  Your email has been verified.';
        }
        return of(asyncFinishAction(result.action.type, 'verifyEmailPhone', {
          successMsg,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


export default combineEpics(verifyEmailPhoneEpic);

