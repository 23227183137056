import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetGameStats extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getStats: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number,
  };

  // state = {
  //   loadStatsRequired: false,
  // };

  // static getDerivedStateFromProps(props, state) {
  //   const derivedState = {
  //     loadStatsRequired: false,
  //   };
  //   if (props.uuid !== state.prevUuid) {
  //     derivedState.loadStatsRequired = true;
  //     derivedState.prevUuid = props.uuid;
  //   }
  //   if (props.sportId !== state.prevSportId) {
  //     derivedState.loadStatsRequired = true;
  //     derivedState.prevSportId = props.sportId;
  //   }
  //   return derivedState;
  // }

  // componentDidMount() {
  //   const {
  //     getStats,
  //     uuid,
  //     sportId,
  //   } = this.props;
  //   if (sportId) {
  //     getStats(uuid, sportId);
  //   }
  // }

  // componentDidUpdate() {
  //   const {
  //     uuid,
  //     sportId,
  //     getStats,
  //   } = this.props;
  //   const {
  //     loadStatsRequired,
  //   } = this.state;
  //   if (loadStatsRequired) {
  //     getStats(uuid, sportId, 'Loading stats');
  //   }
  // }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetGameStats;
