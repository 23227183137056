import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Awards/Awards';
import { alertOrNavigate } from '../../UxCommon/NativeUtils';
import {
  getCurrentUuid,
  getProfile,
  getCurrentUuidSchoolStyle
} from '../../UxCommon/Utils';
import { favoriteAward, endorseAward } from '../../../store/actions/ui/uxProfile/awards';
import { sidebarOpenShareDialog } from '../../../store/actions/ui/uxProfile';
import { awardPageShareUri } from '../../../store/actions/ui/routes';

const getAwards = (state, uuid) => {
  const awards = state.data.user.awards[uuid];
  return awards || [];
};

const getSports = (state, ownProps) => {
  const myProfile = getProfile(state, ownProps.match);
  if (myProfile) {
    const { sports } = state.data.sport;
    if (sports.length) {
      const mySports = [];
      myProfile.sports.forEach((userSport) => {
        const sport = sports.find(s => s.id === userSport.sportId);
        mySports.push(sport);
      });
      return mySports;
    }
  }
  return [];
};

const getFavorites = (state, award) => {
  const favorites = state.ui.uxProfile.awards.tempFavorites;
  const tempFavoriteObj = favorites.find(a => a.id === award.id);
  if (tempFavoriteObj) {
    return tempFavoriteObj.favorites;
  }
  return award.favorites;
};

const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  const currentUuidSchoolStyle =  getCurrentUuidSchoolStyle(state, ownProps.match);
  const schoolId = currentUuidSchoolStyle && currentUuidSchoolStyle.id;
  const schoolAwardLogo =  state.data.awardLogo.schoolAwardLogo
                             && state.data.awardLogo.schoolAwardLogo[schoolId];
  const profile = state.data.user.profiles[currentUuid]
  return {
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    isAuthorized: !!state.data.cognito.uuid,
    authorizedUuid: state.data.cognito.uuid,
    awards: getAwards(state, currentUuid),
    mySports: getSports(state, ownProps),
    loadingMsg: state.ui.uxProfile.awards.loadingMsg,
    loading: !!state.ui.uxProfile.awards.loadingMsg,
    currentUuid,
    isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
    state,
    schoolAwardLogo,
    profile
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { isCoach,state, currentUuid, ...newStateProps } = stateProps;
  const coachSelectedSchoolID  = ownProps.match.params.schoolId;
  const currentSport = ownProps.match.params.sport;
  return {
    ...newStateProps,
    ...ownProps,
    awardsShareUri: awardPageShareUri(currentUuid,coachSelectedSchoolID,currentSport),
    onHideClick: () => {
      dispatch({ type: 'noop' });
    },
    onSaveFavorites: (award, sportIds) => {
      dispatch(favoriteAward(award, sportIds));
    },
    isFavorite: (award) => {
      let isFav = false;
      const favs = getFavorites(state, award);
      if (!favs) return false;
      Object.values(favs).forEach((fav) => {
        if (fav) isFav = true;
      });
      return isFav;
    },
    getEndorsementCount: (award) => {
      const endorsements = state.ui.uxProfile.awards.tempEndorsements;
      const tempEndorsementsObj = endorsements.find(a => a.id === award.id);
      if (tempEndorsementsObj) {
        return { count: tempEndorsementsObj.endorsementCount, blocked: true };
      }
      return { count: award.endorsementCount, blocked: false };
    },
    // eslint-disable-next-line
    getAwardFavorites: (award) => {
      return getFavorites(state, award);
    },
    onEndorseAwardClick: (award, isEndorsing) => {
      dispatch(endorseAward(award, isEndorsing));
    },
    onShareClick: () => {
      dispatch(sidebarOpenShareDialog(awardPageShareUri(currentUuid,coachSelectedSchoolID,currentSport)));
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
