import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Banner from '../common/Banner';
import SignOutModal from '../Dashboard/SignOutModal';
import LoadingIndicator from '../common/LoadingIndicator';
import SchoolLogo from '../common/SchoolLogo';
import NavBar from '../common/NavBar';
import NavBarLink from '../common/NavBarLink';
import { Routes } from '../../../store/actions/ui/routes';
import ChangePassword from './ChangePassword';
import Profile from './Profile';
import ConfirmEmailPhone from './ConfirmEmailPhone';

class CoachAccount extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    schoolStyles: PropTypes.array.isRequired,
    emailNeedsConfirmed: PropTypes.bool.isRequired,
    phoneNeedsConfirmed: PropTypes.bool.isRequired,
  }

  state = {
    signOutModalOpen: false,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onSignOutClick = () => {
    this.setState({
      signOutModalOpen: true,
    });
  }

  onCancelSignOut = () => {
    this.setState({
      signOutModalOpen: false,
    });
  }

  shouldUpdate = () => { }
  formRef = React.createRef();

  render() {
    const { signOutModalOpen } = this.state;
    const {
      match,
      loading,
      loadingMsg,
      schoolStyles,
      emailNeedsConfirmed,
      phoneNeedsConfirmed,
    } = this.props;

    return (
      <div className="CoachAccount">
        <Banner onSignOutClick={this.onSignOutClick} onDashboard match={match} >
          <div className="CoachRoster_BannerChildren">
            <div className="CoachRoster_BannerLeft">
              <SchoolLogo className="CoachRoster_BannerTeamLogo" schoolStyle={schoolStyles[0]} />
              <div className="CoachRoster_BannerFullTitle">
                My Account
              </div>
            </div>
          </div>
        </Banner>
        <div className="CoachAccount_BodyWrapper">
          <NavBar>
            <div className="CoachNavBar_Left">
              <NavBarLink
                to={`/${Routes.coach}/${Routes.account}`}
                match={match}
              >
                Profile
              </NavBarLink>
              {/* <NavBarLink
                to={`/${Routes.coach}/${Routes.account}/${Routes.changePassword}`}
                match={match}
              >
                Change Password
              </NavBarLink> */}
              {
                emailNeedsConfirmed && (
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.account}/${Routes.confirmEmail}`}
                    match={match}
                  >
                    Verify Your Email
                  </NavBarLink>
                )
              }
              {
                phoneNeedsConfirmed && (
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.account}/${Routes.confirmPhone}`}
                    match={match}
                  >
                    Verify Your Phone Number
                  </NavBarLink>
                )
              }
            </div>
          </NavBar>
          {
            signOutModalOpen && (
              <SignOutModal onCancel={this.onCancelSignOut} />
            )
          }
          <div className="CoachAccount_Body">
            <LoadingIndicator
              loading={loading}
              loadingMsg={loadingMsg}
              wrapperStyle={{ top: 20 }}
            />
            <Switch>
              <Route path={`/${Routes.coach}/${Routes.account}/${Routes.changePassword}`} component={ChangePassword} />
              <Route path={`/${Routes.coach}/${Routes.account}/${Routes.confirmEmail}`} component={ConfirmEmailPhone} />
              <Route path={`/${Routes.coach}/${Routes.account}/${Routes.confirmPhone}`} component={ConfirmEmailPhone} />
              <Route path={`/${Routes.coach}/${Routes.account}`} component={Profile} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default CoachAccount;
