import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_FAILED,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { formatPhone } from '../../../../utils';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../actions/ui/async';

export const formErrorReducer = (model, formInitAction, errorFunc) => (state = '', action) => {
  switch (action.type) {
    case FORM_SERVER_ERROR:
      if (action.model === model) {
        return errorFunc(action.error);
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (action.model === model) {
        return 'Please correct the errors above';
      }
      return state;
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case 'rrf/blur':
    case 'rrf/batch':
      return '';
    default:
      if (action.type === formInitAction) {
        return '';
      }
      return state;
  }
};

/*
  More cases is an object like this: {
    'actionType': () => responseToActionType,
  }
*/
export const formLoadingReducer = (model, defaultState, moreCases = {}) =>
  (state = defaultState, action) => {
    let response;
    switch (action.type) {
      case FORM_SUBMIT_INTENT:
        if (action.model === model) {
          return true;
        }
        return false;
      case FORM_SUBMIT_FAILED:
      case FORM_SERVER_ERROR:
      case FORM_SUBMIT_SUCCESS:
        if (action.model === model) {
          return false;
        }
        return state;
      default:
        response = moreCases[action.type];
        if (response) {
          return response(state, action);
        }
        return state;
    }
  };

export const formLoadingMsgReducer = (model, defaultState, msg, moreCases = {}) =>
  (state = defaultState, action) => {
    let response;
    switch (action.type) {
      case FORM_SUBMIT_INTENT:
        if (action.model === model) {
          return msg;
        }
        return '';
      case FORM_SUBMIT_FAILED:
      case FORM_SERVER_ERROR:
      case FORM_SUBMIT_SUCCESS:
        if (action.model === model) {
          return '';
        }
        return state;
      default:
        response = moreCases[action.type];
        if (response) {
          return response(state, action);
        }
        return state;
    }
  };

export const asyncLoadingMsgReducer = (model, defaultState, msg = '', moreCases = {}) =>
  (state = defaultState, action) => {
    let response;
    switch (action.type) {
      case ASYNC_START:
        if (action.model === model) {
          return action.msg || msg;
        }
        return state;
      case ASYNC_FINISH:
      case ASYNC_ERROR:
        if (action.model === model) {
          return '';
        }
        return state;
      default:
        response = moreCases[action.type];
        if (response) {
          return response(state, action);
        }
        return state;
    }
  };

export const asyncLoadingReducer = (model, defaultState, moreCases = {}) =>
  (state = defaultState, action) => {
    let response;
    switch (action.type) {
      case ASYNC_START:
        if (action.model === model) {
          return true;
        }
        return state;
      case ASYNC_FINISH:
      case ASYNC_ERROR:
        if (action.model === model) {
          return false;
        }
        return state;
      default:
        response = moreCases[action.type];
        if (response) {
          return response(state, action);
        }
        return state;
    }
  };

const phoneHelpMsg = (value) => {
  const numbers = value ? value.replace(/[^0-9]+/g, '') : '';
  if (numbers.length > 11) {
    return ['That looks a little long for an American phone number.'];
  }
  return [
    'Like this: +1 (512) 234-5678',
    'Or this: 512-234-5678',
    'Or just like this: 5122345678',
  ];
};

const formPhoneHelpTriage = (state, action, model) => {
  switch (action.type) {
    case 'rrf/change':
      if (action.model === model) {
        return phoneHelpMsg(action.value);
      }
      return state;
    default:
      return state;
  }
};

export const formPhoneHelp = (model, initAction) => (state = phoneHelpMsg(''), action) => {
  let newState = state;
  switch (action.type) {
    case initAction:
      return phoneHelpMsg('');
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = formPhoneHelpTriage(newState, a, model);
      });
      return newState;
    default:
      return formPhoneHelpTriage(state, action, model);
  }
};

const passwordHelpMsg = (value) => {
  const tests = [
    {
      msg: 'At least one lowercase letter',
      passing: value ? value.toUpperCase() !== value : value,
    },
    {
      msg: 'At least one uppercase letter',
      passing: value ? value.toLowerCase() !== value : value,
    },
    {
      msg: 'At least one number',
      passing: (/[0-9]/.test(value)),
    },
    {
      msg: 'At least one special character',
      passing: (/[-!$%@#^&*()_+|~=`{}[\]:";'<>?,./]/.test(value)),
    },
    {
      msg: 'Minimum length: 8',
      passing: value ? value.length >= 8 : false,
    },
  ];
  const passing = !tests.find(test => !test.passing);
  let msg;
  if (passing) {
    msg = 'Perfect!';
  } else {
    msg = "Let's make sure your password is super secure";
  }
  return { msg, tests };
};

const formPasswordHelpTriage = (state, action, model) => {
  switch (action.type) {
    case 'rrf/change':
      if (action.model === model) {
        return passwordHelpMsg(action.value);
      }
      return state;
    default:
      return state;
  }
};

export const formPasswordHelp = (model, initAction) => (state = passwordHelpMsg(''), action) => {
  let newState = state;
  switch (action.type) {
    case initAction:
      return passwordHelpMsg('');
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = formPasswordHelpTriage(newState, a, model);
      });
      return newState;
    default:
      return formPasswordHelpTriage(state, action, model);
  }
};

export const phoneOnBlur = getter => (state) => {
  const phone = getter(state);
  return Object.assign({}, state, {
    phone: formatPhone(phone),
  });
};

const formResponderTriage = formResponse => (state, action) => {
  const responses = formResponse[action.model];
  if (responses) {
    const response = responses[action.type];
    if (response) {
      return response(state, action);
    }
  }
  return state;
};

/*
  init: is a callback for initialization
  Responses is an object of field-model, callback-object pairs
  Ex: {
    form.joinNow.first: {
      'rrf/blur': () => Do something special, return new value,
      'rrf/change': () => Do something different
    }
  }
*/
export const formResponder = (
  initialForm,
  initAction,
  onInit,
  formResponse,
) => (state = initialForm, action) => {
  let newState = state;
  const triage = formResponderTriage(formResponse);
  switch (action.type) {
    case initAction:
      return onInit(state, action);
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = triage(newState, a);
      });
      return newState;
    default:
      return triage(state, action);
  }
};
