import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ButtonRect} from "../../../../components/UxCommon";
import TextControl from '../../common/FormControls/TextControl';
import {formatPhone} from "../../../../utils";
import {checkUsername} from "./RecruitingModal.validation";
import {checkName, checkPhone} from "../../../CodeSignUp/AccountPage/AccountPage.validation";


class RecruitingCoordinatorComponent extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    schoolTeamId: PropTypes.string.isRequired,
    recruitingCoordinator: PropTypes.any,
    updateRecruitingCoordinator: PropTypes.func.isRequired,
    deleteRecruitingCoordinator: PropTypes.func.isRequired,
    createRecruitingCoordinator: PropTypes.func.isRequired,
  }

  state = {
    errors: {},
  }

  onClickDelete = (e) => {
    const {deleteRecruitingCoordinator, recruitingCoordinator, onCancelClick} = this.props;
    deleteRecruitingCoordinator(recruitingCoordinator.id, recruitingCoordinator.schoolTeamId);
    onCancelClick();
    e.preventDefault()
  }

  onPhoneBlur = type => (value) => {
    const phone = formatPhone(value);
    const errors = checkPhone(phone);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    if(errors.length){
      return value;
    }
    return phone;
  }

  onNameBlur = type => (value) => {
    const errors = checkName(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }

  onEmailBlur = type => (value) => {
    const errors = checkUsername(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }


  onClickSave = (e) => {
    const form = this.formRef.current;
    const phone = formatPhone((form.phone.value));
    const errors = {
      name: checkName(form.name.value),
      email: checkUsername(form.email.value),
      phone: checkPhone(phone),
    };

    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({errors});

    if (success) {
      const values = {};
      const {updateRecruitingCoordinator, recruitingCoordinator, createRecruitingCoordinator, schoolTeamId, onCancelClick} = this.props;
      values.name = form.name.value;
      values.phone = form.phone.value;
      values.email = form.email.value;
      values.title = form.title.value;
      if (recruitingCoordinator) {
        updateRecruitingCoordinator(recruitingCoordinator.id, values);
      } else {
        values.schoolTeamId = schoolTeamId;
        createRecruitingCoordinator(values);
      }
      onCancelClick();
    }
    e.preventDefault();
  }

  formRef = React.createRef();

  render() {
    const {onCancelClick, recruitingCoordinator} = this.props;
    const {errors} = this.state;

    return (
      <div className="ConfirmDialogWrapper">
        <div className="ConfirmDialog Roster-ConfirmDialog">
          <div className="AlbumDialod_DeleteAlbumDialog_Title recruitingCoordinatorTitle">
            {
              recruitingCoordinator ? 'Edit Recruiting Coordinator Information' : 'Add Recruiting Coordinator Information'
            }
          </div>
          <div className="RecruitingCoordinatorForm" style={{width: '100%'}}>
            <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachAccount_Form">
              <TextControl
                className="CoachAccount_Ctrl recruitingCoordinatorCtrl"
                name="title"
                label="Title"
                placeholder="Title"
                defaultValue={recruitingCoordinator ? recruitingCoordinator.title : 'Recruiting Coordinator'}
                errors={errors.title}
              />
              <TextControl
                className="CoachAccount_Ctrl recruitingCoordinatorCtrl"
                name="name"
                label="Name"
                placeholder="Name"
                myOnChange={this.onNameBlur('name')}
                myOnBlur={this.onNameBlur('name')}
                defaultValue={recruitingCoordinator ? recruitingCoordinator.name : ''}
                errors={errors.name}
              />
              <TextControl
                className="CoachAccount_Ctrl recruitingCoordinatorCtrl"
                name="email"
                label="Email Id"
                placeholder="Email"
                myOnChange={this.onEmailBlur('email')}
                myOnBlur={this.onEmailBlur('email')}
                defaultValue={recruitingCoordinator ? recruitingCoordinator.email : ''}
                errors={errors.email}
              />
              <TextControl
                className="CoachAccount_Ctrl recruitingCoordinatorCtrl"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                myOnChange={this.onPhoneBlur('phone')}
                myOnBlur={this.onPhoneBlur('phone')}
                defaultValue={recruitingCoordinator ? recruitingCoordinator.phone : ''}
                errors={errors.phone}
              />
            </form>
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={this.onClickSave}
          >
            Save
          </ButtonRect>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
          {
            recruitingCoordinator && (
              <ButtonRect
                className="AlbumDialod_DeleteAlbumDialog_Btn"
                onClick={this.onClickDelete}
              >
                Delete
              </ButtonRect>
            )
          }
        </div>
      </div>
    )
  }
}

export default RecruitingCoordinatorComponent;
