// import 'react-dates/lib/css/_datepicker.css';
// import 'react-dates/initialize';

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import storeConfig from './store/config';
import './styles/scss/index.scss';
import App from './containers/App/App';

const Root = () => {
  const store = storeConfig();
  return (
    <Provider store={store}>
      <Router>
        <Route strict path="/" component={App} />
      </Router>
    </Provider>
  );
};

export default Root;
