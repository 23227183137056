import moment from 'moment';

/*
  photos: must be array
  videos: must be array
*/
export const combineAndSortMedia = (photos, videos) => {
  const combined = photos.concat(videos);
  combined.sort((a, b) => {
    if (moment(a.updatedAt).isAfter(moment(b.updatedAt))) {
      return 1;
    } else if (a.updatedAt === b.updatedAt) {
      return 0;
    }
    return -1;
  });
  combined.reverse();
  return combined;
};

export const getNextMediaFromList = (photoList, media) => {
  const index = photoList.indexOf(media);
  if (index >= 0) {
    if ((index + 1) < photoList.length) {
      return photoList[index + 1];
    }
    return photoList[0];
  }
  return null;
};
export const getPrevMediaFromList = (photoList, media) => {
  const index = photoList.indexOf(media);
  if (index >= 0) {
    if ((index - 1) >= 0) {
      return photoList[index - 1];
    }
    return photoList[photoList.length - 1];
  }
  return null;
};

export const getImageUrl = (media, getLarge = false) => {
  let imgUrl = media ? media.url || null : null;
  if (media.resizedPhotos) {
    imgUrl = media.resizedPhotos.medium || imgUrl;
    if (getLarge) {
      imgUrl = media.resizedPhotos.large || imgUrl;
    }
  }
  return imgUrl;
};
