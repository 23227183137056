import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import SingleDatePickerWrapper from './SingleDatePickerWrapper';

const DatePicker = (props) => {
  const {
    label,
    model,
    help,
    rrfField,
    errors,
    defaultValue,
    ...otherProps
  } = props;
  const hiddenControlProps = {};
  if (defaultValue !== undefined) {
    hiddenControlProps.defaultValue = defaultValue;
    if (rrfField.value === undefined) {
      hiddenControlProps.value = defaultValue;
    }
  }
  if (rrfField.value !== undefined) {
    if (rrfField.value === null) {
      hiddenControlProps.value = '';
    } else {
      hiddenControlProps.value = rrfField.value;
    }
  }
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.value ? 'not-empty' : '',
          rrfField.valid ? '' : 'error',
        ].join(' ')}
      >
        <div className="UxCommon_Form_ErrorMarker" />
        <div className="UxCommon_Form_LabelWrapper">
          <label
            className={[
              'UxCommon_Form_Label',
              rrfField.value ? 'show' : '',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        <SingleDatePickerWrapper
          id={model}
          date={rrfField.value}
          block
          numberOfMonths={1}
          {...otherProps}
        />
      </div>
      {
        help &&
          <HelpPopper focus={rrfField.focus}>
            {help}
          </HelpPopper>
      }
      {
        typeof rrfField.errors === 'object' &&
          <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
      }
      <Control
        type="hidden"
        model={model}
        {...hiddenControlProps}
      />
    </div>
  );
};
DatePicker.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  help: PropTypes.any,
  rrfField: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  defaultValue: PropTypes.any,
};

export default DatePicker;
