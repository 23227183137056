import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ButtonRect from  '../../../../components/UxCommon/ButtonRect/ButtonRect'
import {checkComment} from '../../../CodeSignUp/AccountPage/AccountPage.validation'

class AddCoachCommentModal extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    selectedAthlete: PropTypes.any,
  }

  state = {
    errors: {},
    value: '',
    defaultValue: '',
  };

  handleChange = event => {
    const errors = {
      coachComment: checkComment(event.target.value),
    }
    this.setState({errors})
    this.setState({value: event.target.value})
  }

  handleBlur = event => {
    const errors = {
      coachComment: checkComment(event.target.value),
    }
    this.setState({errors})
  }

  componentDidMount() {
    this.checkDefault();
  }

  componentDidUpdate() {
    this.checkDefault();
  }

  setDefault = (defaultValue) => {
    let value = defaultValue;
    this.setState({
      defaultValue,
      value,
    });
  }

  checkDefault = () => {
    const { selectedAthlete } = this.props;
    if (selectedAthlete.coachComment
      && selectedAthlete.coachComment !== this.state.defaultValue) {
      this.setDefault(selectedAthlete.coachComment);
    }
  }

  onClickSave = (e) => {
    const form = this.formRef.current;
    const errors = {
      coachComment: checkComment(form.coachComment.value),
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({errors});
    if (success) {
      e.preventDefault();
      const { onCancelClick, selectedAthlete,onSaveClick} = this.props;
      onSaveClick(selectedAthlete.id,selectedAthlete.userId,selectedAthlete.schoolTeamId,form.coachComment.value)
      onCancelClick();
    }
  }

  OnClickDelete = (e) => {
    e.preventDefault();
    const coachComment = ''
    const { onCancelClick, selectedAthlete,onSaveClick} = this.props;
    onSaveClick(selectedAthlete.id,selectedAthlete.userId,selectedAthlete.schoolTeamId,coachComment)
    onCancelClick();
  }

  formRef = React.createRef();
  AddCoachCommentForm
  render() {
    let {errors,value} = this.state;
    const {onCancelClick,selectedAthlete } = this.props;
    return (
      <div className="ConfirmDialogWrapper">
      <div className="ConfirmDialog">
        <div className="AlbumDialod_DeleteAlbumDialog_Title headCoachTitle">
            {'Add Coach Comment'}
        </div>
        <div className="AddCoachCommentForm">
          <form ref={this.formRef} onSubmit={this.onClickSave} className="CoachComment_Form">
            <textarea
             className="CoachComment_Ctrl"
             name="coachComment"
             label="Comment"
             placeholder="Comment"
             rows="4"
             cols="50"
             onChange={this.handleChange}
             onBlur = {this.handleBlur}
             value = {value}
             errors = {errors.testError}
             maxLength="1001"
            />
            {  errors.coachComment &&
              <div style= {{fontSize: "1rem", color: "red", fontWeight: "400"}}>
                {errors.coachComment}
              </div>
            }
          </form>
        </div>
        <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={this.onClickSave}
            >
            Save
          </ButtonRect>
          { (selectedAthlete.coachComment && selectedAthlete.coachComment !== '') &&
              <ButtonRect
                className="AlbumDialod_DeleteAlbumDialog_Btn"
                onClick={this.OnClickDelete}
              >
                Delete
              </ButtonRect>
          }
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
      </div>
    </div>
    )
  }
}
export default AddCoachCommentModal;
