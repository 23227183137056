import { Form } from 'react-redux-form';
import TextControlGroup from '../../../UxCommon/Form/TextControlGroup/TextControlGroup';
import {
  parserLower,
  parserName,
  parserPassword,
} from '../../../../utils/parsers';
import PhoneControlComponent from '../../../../modules/CodeSignUp/SimpleForm/PhoneControl';
import React from 'react';

const SignupForm = ({
  countryCode,
  rrfForm,
  profile,
  submit,
  formOnKeyDown,
  onPhoneChange,
  selectedCountry,
  formGeneralError,
  handlePwFocus,
  onParentPhoneChange,
  onParent2PhoneChange,
}) => {
  const passwordClassName = rrfForm.password.errors.length
    ? 'invite_password_error'
    : '';
  const confirmPasswordClassName = rrfForm.confirmPassword.errors.match
    ? 'invite_confirm_Password_error'
    : '';

  return (
    <div className="col-lg-6">
      <Form
        className="welcome-form"
        model="forms.invitedSignUp"
        onSubmit={submit}
        onKeyDown={formOnKeyDown}>
        <TextControlGroup
          label="Athlete First Name"
          placeholder="Athlete First Name"
          parser={parserName}
          model="forms.invitedSignUp.first"
        />
        <TextControlGroup
          label="Athlete Last Name"
          placeholder="Athlete Last Name"
          parser={parserName}
          model="forms.invitedSignUp.last"
        />
        <TextControlGroup
          classname={'custom-margin'}
          label="Athlete Email"
          placeholder="Athlete Email"
          parser={parserLower}
          model="forms.invitedSignUp.confirmUsername"
        />
        <div className="invite_email_info_text">
          💡 The athlete email address is used as the username to log in to
          update the athlete's profile.
        </div>
        <PhoneControlComponent
          className="form-label"
          placeholder="Athlete Mobile Phone"
          label="Athlete Mobile Phone"
          name="phone"
          myOnChange={onPhoneChange()}
          defaultCountry={countryCode}
          defaultValue={profile.phone}
          model="forms.invitedSignUp.phone"
          errors={
            rrfForm.phone.errors
              ? Object.keys(rrfForm.phone.errors).map(
                  (k) => rrfForm.phone.errors[k],
                )
              : []
          }
        />
        <PhoneControlComponent
          className="form-label"
          placeholder="Confirm Athlete Phone"
          label="Confirm Athlete Phone"
          name="confirmPhone"
          myOnChange={onPhoneChange(true)}
          defaultCountry={selectedCountry}
          model="forms.invitedSignUp.confirmPhone"
          defaultValue={profile.phone}
          disabled={true}
          errors={
            rrfForm.confirmPhone.errors
              ? Object.keys(rrfForm.confirmPhone.errors).map(
                  (k) => rrfForm.confirmPhone.errors[k],
                )
              : []
          }
        />
        <TextControlGroup
          label="Parent Name"
          placeholder="Parent Name"
          parser={parserName}
          model="forms.invitedSignUp.parentName"
        />
        <TextControlGroup
          label="Parent 2 Name"
          placeholder="Parent 2 Name"
          parser={parserName}
          model="forms.invitedSignUp.parent2Name"
        />
        <TextControlGroup
          label="Parent Email"
          placeholder="Parent Email"
          parser={parserLower}
          model="forms.invitedSignUp.parentsEmail"
        />
        <TextControlGroup
          label="Confirm Parent Email"
          placeholder="Confirm Parent Email"
          parser={parserLower}
          model="forms.invitedSignUp.confirmParentsEmail"
        />
        <TextControlGroup
          label="Parent 2 Email"
          placeholder="Parent 2 Email"
          parser={parserLower}
          model="forms.invitedSignUp.parent2Email"
        />
        <TextControlGroup
          label="Confirm Parent 2 Email"
          placeholder="Confirm Parent 2 Email"
          parser={parserLower}
          model="forms.invitedSignUp.confirmParent2Email"
        />
        <PhoneControlComponent
          className="form-label"
          placeholder="Parent Mobile Number"
          label="Parent Mobile Number"
          name="parentMobile"
          myOnChange={onParentPhoneChange()}
          defaultCountry={countryCode}
          defaultValue={ profile.parentsPhone}
          model="forms.invitedSignUp.parentMobile"
          errors={
            rrfForm.parentMobile.errors
              ? Object.keys(rrfForm.parentMobile.errors).map(
                  (k) => rrfForm.parentMobile.errors[k],
                )
              : []
          }
        />
        <PhoneControlComponent
          className="form-label"
          placeholder="Confirm Parent Mobile Number"
          label="Confirm Parent Mobile Number"
          name="confirmParentMobile"
          myOnChange={onParentPhoneChange(true)}
          defaultCountry={selectedCountry}
          model="forms.invitedSignUp.confirmParentMobile"
          defaultValue={ profile.parentsPhone}
          disabled={true}
          errors={
            rrfForm.confirmParentMobile.errors
              ? Object.keys(rrfForm.confirmParentMobile.errors).map(
                  (k) => rrfForm.confirmParentMobile.errors[k],
                )
              : []
          }
        />
        <PhoneControlComponent
          className="form-label"
          placeholder="Parent 2 Mobile Number"
          label="Parent 2 Mobile Number"
          name="parents2Mobile"
          myOnChange={onParent2PhoneChange()}
          defaultCountry={countryCode}
          defaultValue={ profile.parents2Phone}
          model="forms.invitedSignUp.parent2Mobile"
          errors={
            rrfForm.parent2Mobile.errors
              ? Object.keys(rrfForm.parent2Mobile.errors).map(
                (k) => rrfForm.parent2Mobile.errors[k],
              )
              : []
          }
        />
        <PhoneControlComponent
          className="form-label"
          placeholder="Confirm Parent 2 Mobile Number"
          label="Confirm Parent 2 Mobile Number"
          name="confirmParent2Mobile"
          myOnChange={onParent2PhoneChange(true)}
          defaultCountry={selectedCountry}
          model="forms.invitedSignUp.confirmParent2Mobile"
          defaultValue={ profile.parents2Phone}
          disabled={true}
          errors={
            rrfForm.confirmParent2Mobile.errors
              ? Object.keys(rrfForm.confirmParent2Mobile.errors).map(
                (k) => rrfForm.confirmParent2Mobile.errors[k],
              )
              : []
          }
        />
        <TextControlGroup
          label="Please set a password."
          placeholder="Password"
          type="password"
          model="forms.invitedSignUp.password"
          parser={parserPassword}
          classname={passwordClassName}
          onFocus={handlePwFocus}
        />
        <TextControlGroup
          label="Now, confirm your password."
          placeholder="Password"
          type="password"
          model="forms.invitedSignUp.confirmPassword"
          classname={confirmPasswordClassName}
          parser={parserPassword}
        />
        {formGeneralError && (
          <div className="form-group">
            <div className="form-general-error">{formGeneralError}</div>
          </div>
        )}
        <div className="welcome-btns">
          <button
            id="forms.invitedSignUp.submit"
            className="welcome-btn"
            type="submit">
            Next
          </button>
        </div>
      </Form>
    </div>
  );
};

export default SignupForm;
