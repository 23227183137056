import React,{useRef} from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton} from '../../../../components/UxCommon';
import AddNewCard from '../../../../components/UxProfile/Photos/PhotoVideoList/AddNewCard/AddNewCard';
import Dropzone from '../../../../components/UxCommon/Dropzone/Dropzone';
import StagedPhotoCard from '../../../../components/UxProfile/Photos/AlbumDialog/PhotoCard/StagedPhotoCard/StagedPhotoCard';
import ExternalVideoDialog from '../../../../containers/UxProfile/Photos/ExternalVideo';

const UploadVideoModal = (props) => {

const dropZoneRef = useRef(null);
const onAddPhotoVideoClick = () => {
    dropZoneRef.current.open();
};
const onSaveClickHandler = () => {
    props.onSaveClick()
}

const { 
  loading, onCancel, onDrop,unstageNewMedia,
  stagedMedia,getPhotoCardRrfForm,getPhotoCardRrfFormModel,selectedTags,
  showMediaIsNotAccepted,onExternalVideoClick,onExternalVideoCancelClick,
  isExternalVideoDialogOpen
} =props;

return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
        {
        isExternalVideoDialogOpen && (
          <ExternalVideoDialog
            onCancel={onExternalVideoCancelClick}
          />
        )
        }
      <CloseButton size={15} onClick={onCancel} />
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">Upload Video</div>
        {<div className="CloseCombineModal_Text2">
        <Dropzone
        disableClick
        accept={'video/*'}
        ref={dropZoneRef}  
        onDrop={onDrop} 
        multiple={false}
        >
        {stagedMedia.length === 0 && !loading && <div className={"AddVideoCardModal"}>
        <AddNewCard
        onClick={onAddPhotoVideoClick}
        iconName="plus"
        text="Upload video"
        />
        <AddNewCard
          onClick={onExternalVideoClick}
          iconName="link"
          text="Add a video from YouTube or Hudl"
        />
        </div>
        }
        {
        stagedMedia.filter(s => s.isNew).map(media => (
            <StagedPhotoCard
            key={media.id}
            media={media}
            getRrfForm={getPhotoCardRrfForm}
            getRrfFormModel={getPhotoCardRrfFormModel}
            onDeleteClick={unstageNewMedia}
            selectedTags={selectedTags}
            showMediaIsNotAccepted={showMediaIsNotAccepted}
            />
        ))
        }
       
        {
            !props.rrfForm.$form.valid && (
            <div className="UxProfile_Photos_AlbumDialog_Errors">
                <Errors valid={rrfForm.$form.valid} errors={rrfForm.$form.errors} />
            </div>
            )
        }
        </Dropzone>
        </div>}
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn primary"
          type="button"
          disabled={stagedMedia.length === 0 ? true : false}
          onClick={onSaveClickHandler}
        >
          Upload
        </ButtonRect>
        <ButtonRect
          className="CloseCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
};

export default UploadVideoModal;
