import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../components/UxCommon';
import TextControl from '../../common/FormControls/TextControl';

class ChangePassword extends PureComponent {
  static propTypes = {};

  state = {
    errors: {},
    form: {},
  }

  componentDidMount() {}

  componentDidUpdate() {}

  onValueChange = name => (value) => {
    const errors = {};
    let adjustedValue;
    let newPassword;
    let confirm;
    switch (name) {
      case 'oldPassword':
        if (!value) {
          errors.oldPassword = ['This field is required'];
        } else {
          errors.oldPassword = [];
        }
        adjustedValue = value;
        break;
      case 'newPassword':
        confirm = this.formRef.current.confirmPassword.value;
        if (!value) {
          errors.newPassword = ['This field is required'];
        } else {
          errors.newPassword = [];
        }
        adjustedValue = value;
        if (value !== confirm) {
          errors.confirmPassword = ['Not matched'];
        } else {
          errors.confirmPassword = [];
        }
        break;
      case 'confirmPassword':
        newPassword = this.formRef.current.newPassword.value;
        if (value !== newPassword) {
          errors.confirmPassword = ['Not matched'];
        } else {
          errors.confirmPassword = [];
        }
        adjustedValue = value;
        break;
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      form: Object.assign({}, this.state.form, errors),
    });
    return adjustedValue;
  }

  onClickSavePassword = (e) => {
    const form = this.formRef.current;
    const values = {};
    values.oldPassword = form.oldPassword.value;
    values.newPassword = form.newPassword.value;
    // console.log('Change Password', values);
    e.preventDefault();
  }

  formRef = React.createRef();

  render() {
    const { errors } = this.state;
    return (
      <div className="CoachChangePassword">
        <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachChangePassword_Form" >
          <div className="CoachChangePassword_InputWrapper">
            <div className="CoachChangePassword_FormLabel" >Current Password</div>
            <TextControl
              className="ChangePassword_Ctrl"
              name="oldPassword"
              placeholder="Current Password"
              myOnChange={this.onValueChange('oldPassword')}
              myOnBlur={this.onValueChange('oldPassword')}
              type="password"
              errors={errors.oldPassword}
            />
          </div>
          <div className="CoachChangePassword_InputWrapper">
            <div className="CoachChangePassword_FormLabel" >New Password</div>
            <TextControl
              className="ChangePassword_Ctrl"
              name="newPassword"
              placeholder="New Password"
              myOnChange={this.onValueChange('newPassword')}
              myOnBlur={this.onValueChange('newPassword')}
              type="password"
              errors={errors.newPassword}
            />
          </div>
          <div className="CoachChangePassword_InputWrapper">
            <label className="CoachChangePassword_FormLabel" htmlFor="confirm">Confirm Password</label>
            <TextControl
              className="ChangePassword_Ctrl"
              name="confirmPassword"
              placeholder="Confirm Password"
              myOnChange={this.onValueChange('confirmPassword')}
              myOnBlur={this.onValueChange('confirmPassword')}
              type="password"
              errors={errors.confirmPassword}
            />
          </div>
          <div className="ChangePassword_Btn">
            <ButtonRect
              className="Photos_ExpandedPhotoDialog_Button primary"
              style={{ width: '120px' }}
              type="submit"
              onClick={this.onClickSavePassword}
            >
              Save Password
            </ButtonRect>
          </div>
        </form>
      </div>
    );
  }
}

export default ChangePassword;
