import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';
import { parserOnlyNumbers , parserPassword} from '../../../utils/parsers';
import {
  mergeAuthResetPassword,
  mergeResetPassword,
  mergeSignIn,
  mergeSignInAuth,
  Routes
} from "../../../store/actions/ui/routes";
import { MergeTypes } from "../MergeSignIn/MergeSignIn";

class ResetPassword extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      this.props.formInit(this.props.match.params.code);
    }
  };

  getRedirectURL = () =>{
    const { match } = this.props;

    const isMergeLoginResetPassword = match.url.match(Routes.mergeLoginResetPassword);
    if (isMergeLoginResetPassword) {
      return mergeSignIn(
        MergeTypes.mergeBoth,
        match.params.uuid,
        match.params.token,
        match.params.teamName,
        match.params.coachName,
        match.params.sportId,
        match.params.userTeamId,
        match.params.schoolId,
      )
    }

    const isMergeLoginAuthResetPassword = match.url.match(Routes.mergeLoginAuthResetPassword);
    if (isMergeLoginAuthResetPassword) {
      return mergeSignInAuth(
        MergeTypes.mergeBoth,
        match.params.username,
      )
    }

    return null;
  }

  submit = () => {
    this.props.submit(false, this.getRedirectURL());
  }

  validateConfirmPassword = val => val === this.props.form.password;

  render() {
    const { match } = this.props;
    const { code } = match.params;
    const username = match.params.username.replace('aAdDoOtT', '.');
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">Reset Password</div>
            {
              (code) ?
                <div className="welcome-desc">{username}</div>
              :
                <div className="welcome-desc">{`In order to confirm your identity we sent a confirmation code to ${username}.`}</div>
            }
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model="forms.resetPassword" onSubmit={this.submit}>
              {
                (!code) &&
                  <TextControlGroup
                    label="Please enter your confirmation code below."
                    placeholder="Confirmation Code"
                    parser={parserOnlyNumbers}
                    model="forms.resetPassword.code"
                  />
              }
              <TextControlGroup label="Enter your new password" type="password" model="forms.resetPassword.password" parser={parserPassword}/>
              <TextControlGroup
                label="Confirm your new password"
                placeholder="Password"
                type="password"
                model="forms.resetPassword.confirmPassword"
                parser={parserPassword}
              />
              {
                this.props.formGeneralError &&
                  <div className="form-group">
                    <div className="form-general-error">{this.props.formGeneralError}</div>
                  </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Reset Password</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
