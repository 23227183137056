import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children, style, className }) => (
  <div className={`UxCommon_Header ${className || ''}`} style={style || {}}>
    {children}
  </div>
);
Header.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Header;
