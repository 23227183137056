export class CollegeContactResults {
  static fromApi(response) {
    return response.map(contact => ({
      division: contact.division,
      state: contact.state,
      school: contact.school,
      athleticWebsite: contact.athletic_website,
      admissionLink: contact.admission_link,
      staffDirectory: contact.staff_directory,
    }));
  }
}
