import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createPlayerProfileData, isK13Student, getAthleteDefaultImage } from '../../../../utils/playerCard';
import ShareRow from '../ShareRow/ShareRow';
import AcademicBox from '../AcademicBox/AcademicBox';
import { positions, softballBaseballIdsArray } from '../../../UxCommon/Form/utils';
import ProfilePositions from './ProfilePositions';
import OfferBox from '../OfferBox/OfferBox';
import { ButtonIconOnly } from '../../../UxCommon';
import Icon from '../../../UxCommon/Icon/Icon';
import PrivacyControl from '../../../../containers/UxCommon/PrivacyControl/PrivacyControl';
import { privacyType } from '../../../../utils';
import {Routes} from "../../../../store/actions/ui/routes";
import ProfileActivationButton from './ActivateProfileMembership/ProfileActivationButton/ProfileActivationButton';

class CardContent extends PureComponent {
  static propTypes = {
    selectedUserSport: PropTypes.object,
    user: PropTypes.object,
    className: PropTypes.string,
    onShareClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    otherPositionsCount: PropTypes.number,
    athleteTeamRosters: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.userPositions = [];
    this.allPositionsForSport = [];
  }

  state = {
    positionsDisplay: { display: 'none' },
    iconName: 'chevron-right',
  };

  componentWillUpdate() {
    this.onUpdate();
  }

  componentDidUpdate() {
    const elem = document.getElementById('divProfilePositions');
    const hiddenItems = [];
    let childrenWidth = 0;
    let othersCount = 0;
      if (elem.children) {
      for (let index = 0; index < elem.children.length; index += 1) {
        const element = elem.children[index];
        const childWidth = element.offsetWidth;
        childrenWidth += childWidth;
        if (index > 0) {
          if (index == elem.children.length - 1 && hiddenItems.length > 0){
            this.otherPositionsCount = othersCount;
            element.innerHTML = `, +${othersCount} others`
              for (let index2 = 0; index2 < hiddenItems.length; index2 += 1) {
                const elementH = hiddenItems[index2];
                elementH.style.display = 'none';
              }
          } else {
            if(element.innerHTML != ',&nbsp;'){
               othersCount += 1;
            }
            hiddenItems.push(element);
          }
        }
      }
    }
}

  onUpdate() {
    const { selectedUserSport, user } = this.props;
    if (selectedUserSport && user) {
      this.allPositionsForSport = positions(selectedUserSport.sportId, user.gender);
    }
    if (selectedUserSport && selectedUserSport.positionArray) {
      // only include user positions that are currently defined
      // ignore saved positions that are no longer configured
      const selectedUserPositionsFilter = [];
      selectedUserSport.positionArray.filter(e =>
        selectedUserPositionsFilter.push(e.trim()));
      this.userPositions = selectedUserPositionsFilter
        .filter(e => this.allPositionsForSport
          .indexOf(this.allPositionsForSport.find(item => item.value === e)) > -1);
    }
  }

  togglePositionsDisplayHandler = () => {
    if (this.state.positionsDisplay.display === 'none') {
      this.setState({
        positionsDisplay: { display: 'flex' },
        iconName: 'down-chevron',
      });
    } else {
      this.setState({
        positionsDisplay: { display: 'none' },
        iconName: 'chevron-right',
      });
    }
  };

  createPlayerInfoData = () => {
    const { user, selectedUserSport } = this.props;
    return [
      {
        label: 'NCAA ID',
        info: user && user.ncaaIdNumber ? user.ncaaIdNumber : '-',
      },
      {
        label: 'Committed to',
        info: selectedUserSport && selectedUserSport.commits ? selectedUserSport.commits : 'N/A',
      },
    ];
  };

  isPartOfSchoolTeam = () =>{
    const { athleteTeamRosters , match, user } = this.props;
    if(match && match.params.schoolId){
      return !! (athleteTeamRosters && athleteTeamRosters.find(team => team.schoolId === match.params.schoolId));
    }
    else{ 
    if(user && user.enrolledSchool && user.enrolledSchool.length){
      const activatingSchool = user && user.enrolledSchool.find(schools => {
          if(schools.user_school_team.school.is_paid != 0 && schools.user_school_team.school.enable_nl === 1){
            return true;
          }
          else{
            return false;
          }
      })
      return (activatingSchool);
     }
   }
  }

  getTwitterLink = handle => encodeURI(`https://twitter.com/${handle.replaceAll(/[@.]/gi, '')}`);
  getInstagramKink = handle => encodeURI(`https://www.instagram.com/${handle.replace('@', '')}`)

  render() {
    // let index = 0;
    const { iconName, positionsDisplay } = this.state;
    const {
      selectedUserSport,
      user,
      className,
      onShareClick,
      isMyProfile,
      match,
    } = this.props;
    const sortOffers = (selectedUserSport && selectedUserSport.offers) && selectedUserSport.offers.split(",");
    const sortedOffers = sortOffers && sortOffers.map( offer => offer.trim().toUpperCase()).sort().join(',');
    const playerInfoData = this.createPlayerInfoData();
    const playerProfileData = createPlayerProfileData(user, selectedUserSport,isMyProfile);
    let profileImage ='';
    let sportId;
    const { schoolId } = match.params;
    if (selectedUserSport)
    {
      profileImage =  selectedUserSport.photoUrl || getAthleteDefaultImage(user, match);
      sportId = selectedUserSport.sportId
    }
    const hasPositions = (this.userPositions.length && this.userPositions.length > 0) ? true : null;
    return (
      <div className={className || ''}>
        {( user && user.isPaidProfile && sportId && this.isPartOfSchoolTeam()) ? ( user.isSubscribed && user.registered ? "" : <ProfileActivationButton profile = {user} />) : ""}
        <div className="UxProfile_S_Profile">
          <div className="UxProfile_S_ProfilePhoto">
            {
              profileImage ?
                <div
                  className="UxProfile_S_ProfilePhotoImg"
                  style={{ backgroundImage: `url('${profileImage}')` }}
                />
                :
                <svg viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z"
                  />
                </svg>
            }
          </div>
          <div className="UxProfile_S_ProfileNameBox">
            <div className="UxProfile_S_ProfileName">
              <PrivacyControl
                match={match}
                privacyType={privacyType.ALL}
                className="UxProfile_S_Profile_PrivacyControl"
              />
              <div>
                {user && `${user.first}`}
              </div>
              <div>
                {user && `${user.last}`}
              </div>
            </div>
            {
              !!user && !!user.twitterHandle && user.twitterHandle !== '@' &&
              <div className="UxProfile_S_ProfileTwitterHandle">
                <a href={this.getTwitterLink(user.twitterHandle)} target="_blank">
                  <Icon iconName="twitter" />
                  {user.twitterHandle.replace('.','')}
                </a>
              </div>
            }
            {!!user && !!user.instagram &&
              <div className="UxProfile_S_ProfileInstagramHandle">
              <a href={this.getInstagramKink(user.instagram)} target="_blank">
              <Icon iconName="instagram" />
              {user.instagram}
              </a>
              </div>
            }
            {
              playerProfileData.map((i, index) => (
                <div
                  key={index}
                  className="UxProfile_S_ProfileSchool"
                  style={{ paddingLeft: '1px' }}
                >
                  {i.info}
                </div>
              ))
            }
            <div className="UxProfile_S_PositionRow">
              <div id="divProfilePositions" className= "UxProfile_S_Positions">
                {
                  selectedUserSport &&
                  this.userPositions.map((i, index) =>
                    (
                      <ProfilePositions
                        key={index}
                        code={i}
                        last={this.userPositions.length === index + 1}
                        count = {this.otherPositionsCount}
                        state = {this.state.positionsDisplay}
                      />
                    ))
                }
              </div>
              {hasPositions &&
              <div
                className="UxProfile_S_ProfileSchool"
                id="abc"
                style={{
                display: 'flex',
                float: 'right',
              }}
              >
                <ButtonIconOnly
                  id="listPositionsToggleButton"
                  onClick={this.togglePositionsDisplayHandler}
                  className="positionIconSelector"
                  iconName={`${iconName}`}
                  style={{
                    height: '18px',
                    fontSize: positionsDisplay.display === 'flex' ? '10px' : '12px',
                  }}
                />
              </div>
              }
            </div>
            <div className="UxProfile_S_ProfileSchool" style={this.state.positionsDisplay}>
              <ul style={{
                listStyle: 'none',
                paddingLeft: '0px',
                paddingTop: '4px',
              }}
              >
                {selectedUserSport && this.userPositions.map((i, index) => (
                  <li
                    key={i}
                    style={{ textAlign: 'left' }}
                  >{this.allPositionsForSport.find(item => item.value === i).name+(this.otherPositionsCount ? (index == this.otherPositionsCount ? "" : ",") : "")}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="UxProfile_S_Info">
          {
            !!user && (isK13Student(user.grade) || user.grade === 'N/A') &&
            <React.Fragment>
              {
                playerInfoData.map((i, index) => (
                  <div key={index} className="UxProfile_S_InfoRow">
                    <div className="UxProfile_S_InfoLabel">
                      {i.label.toUpperCase()}
                    </div>
                    <div className="UxProfile_S_InfoInfo">
                      {i.info}
                    </div>
                  </div>
                ))
              }
              {
                  (selectedUserSport && ! (softballBaseballIdsArray.includes(selectedUserSport.sportId )) &&
                    <OfferBox offers={sortedOffers.trim().split(/\s*,\s*/)}/>
                  )
              }
              {
                (isMyProfile || (user && !user.privacy_academic)) && <AcademicBox user={user} />
              }
            </React.Fragment>
          }
          {
            selectedUserSport && <ShareRow onShareClick={onShareClick} />
          }


          {/*<Link*/}
          {/*  to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Routes.addSport}`}*/}
          {/*  className={[*/}
          {/*    'UxProfile_PP_SportNav_AddSport',*/}
          {/*    match.params.sport === Routes.addSport ? 'selected' : '',*/}
          {/*  ].join(' ')}*/}
          {/*>*/}


        </div>
      </div>
    );
  }
}

export default CardContent;
