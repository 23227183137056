import React from 'react';
import PropTypes from 'prop-types';

const Headline = ({ children }) => (
  <div className="UxCommon_Form_Headline">
    {children}
  </div>
);
Headline.propTypes = {
  children: PropTypes.any,
};

export default Headline;
