import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};

const checkInviteUserLoggedIn = (state, invitedUserId, uuid) => {
  let flag = false;
  if (invitedUserId && uuid) {
    flag = invitedUserId === uuid;
  }
  return flag;
};

const useInviteSelector = () => {
  const { uuid } = useParams();
  return useSelector((state) => {
    const authUuid = state.data.cognito.uuid;
    return {
      loading: !!state.ui.welcome.checkInvitation.loadingMsg,
      loadingMsg: state.ui.welcome.checkInvitation.loadingMsg,
      needsInit: state.ui.welcome.invitedSignUp.formNeedsInit,
      shouldCheckInviteValid: state.ui.welcome.checkInvitation.needsInit,
      isInvitationValid: state.ui.welcome.checkInvitation.isInvitationValid,
      loggedIn: !!state.data.cognito.token,
      authenticatedProfile: getProfile(state, authUuid),
      profile: getProfile(state, uuid),
      duplicateProfiles: state.data.user.duplicateProfiles,
      mergeProfile: state.ui.welcome.mergeAnotherAccount.mergeProfile,
      loadingDuplicateProfiles: state.data.user.loadingDuplicateProfiles,
      showCreateNewAccount: state.data.user.showCreateNewAccount,
      isInviteUserLoggedIn: checkInviteUserLoggedIn(state, uuid, authUuid),
      authUuid,
    };
  });
};

export default useInviteSelector;
