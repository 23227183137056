import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
// import Errors from '../Errors/Errors';

const MultiSelectControl = (props) => {
  const {
    label,
    model,
    help,
    rrfField,
    placeholder,
    options,
    ...otherProps
  } = props;
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.valid ? '' : 'error',
        ].join(' ')}
        style={{
          height: '71px',
        }}
      >
        <div className="UxCommon_Form_LabelWrapper">
          <label
            className={[
              'UxCommon_Form_Label',
              'show',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        <div className={[
            'UxCommon_Form_Input UxCommon_Form_MultiSelect',
            rrfField.value && rrfField.value.length ? 'not-empty' : '',
          ].join(' ')}
        >
          <Control.select
            multiple="multiple"
            className="UxCommon_Form_MultiSelectSelect"
            model={model}
            id={model}
            {...otherProps}
          >
            <option value="" disabled hidden>{placeholder || label}</option>
            {
              options.map((option, index) => (
                <option
                  key={index}
                  value={option.value}
                >
                  {option.name}
                </option>
              ))
            }
          </Control.select>
        </div>
      </div>
      {
        help &&
          <HelpPopper focus={rrfField.focus}>
            {help}
          </HelpPopper>
      }
    </div>
  );
};
MultiSelectControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.any,
  options: PropTypes.array.isRequired,
  rrfField: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default MultiSelectControl;
