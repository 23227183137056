import { connect } from 'react-redux';
import Component from '../../components/UxEditProfile/Personal/Personal';
import {getAuthenticatedUserProfile, getProfile} from '../UxCommon/Utils';


const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    profile: isCoach ? getProfile(state, ownProps.match) : getAuthenticatedUserProfile(state),
    loading: !!state.ui.uxEditProfile.vanityUrl.loadingMsg || !!state.ui.uxEditProfile.twitterHandle.loadingMsg,
    loadingMsg: state.ui.uxEditProfile.vanityUrl.loadingMsg || state.ui.uxEditProfile.twitterHandle.loadingMsg,
    state,
  });
};


const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
