import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../../../../../UxCommon/Form/HelpPopper/HelpPopper';
import Errors from '../../../../../UxCommon/Form/Errors/Errors';
import { CheckForNewLine } from '../../../../../UxCommon/Form/utils';
import { ButtonIconOnly } from '../../../../../UxCommon';

const TeamTypeAheadTextControl = (props) => {
  const {
    label,
    model,
    type,
    help,
    helpClass,
    rrfField,
    errors,
    customIcon,
    onCustomIconClick,
    rrfRelatedField,
    ...otherProps
  } = props;
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.valid ? '' : 'error',
          type === 'textarea' ? 'UxCommon_Form_TextareaGroup' : '',
        ].join(' ')}
      >
        <div className="UxCommon_Form_ErrorMarker" />
        <div className="UxCommon_Form_LabelWrapper">
          <label
            className={[
              'UxCommon_Form_Label',
              rrfField.value && rrfField.value.length ? 'show' : '',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        {
          type === 'textarea' ?
            <Control.textarea
              className={[
                'UxCommon_Form_Input UxCommon_Form_Textarea',
                rrfField.value && rrfField.value.length ? 'not-empty' : '',
              ].join(' ')}
              type={type || 'text'}
              model={model}
              id={model}
              onKeyDown={CheckForNewLine(model)}
              {...otherProps}
            />
            :
            <div>
              <Control
                className={[
                  'UxCommon_Form_Input',
                  rrfField.value && rrfField.value.length ? 'not-empty' : '',
                ].join(' ')}
                type={type || 'text'}
                model={model}
                id={model}
                {...otherProps}
              />
              { onCustomIconClick && <ButtonIconOnly
                className="trashClass"
                iconName={customIcon}
                onClick={onCustomIconClick}
              />}
            </div>

        }
      </div>
      {
        help &&
        <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
      }
      <Errors errors={errors || rrfRelatedField.errors} valid={rrfRelatedField.valid} />
    </div>
  );
};
TeamTypeAheadTextControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  help: PropTypes.any,
  rrfField: PropTypes.object.isRequired,
  helpClass: PropTypes.string,
  onCustomIconClick: PropTypes.func,
  errors: PropTypes.object,
  customIcon: PropTypes.string,
  rrfRelatedField: PropTypes.object,
};

export default TeamTypeAheadTextControl;
