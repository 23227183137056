import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { Link } from 'react-router-dom';
import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';
// import PasswordHelp from '../../Common/Form/PasswordHelp';
import { Routes } from '../../../store/actions/ui/routes';
import {
  parserName,
  parserLower,
} from '../../../utils/parsers';

// const isDuringSchoolYear = () => (moment().month() >= 10) && (moment().month() <= 4);

class JoinNow extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    phoneHelp: PropTypes.array.isRequired,
    // passwordHelp: PropTypes.object,
    showPhoneHelp: PropTypes.bool.isRequired,
    // showPasswordHelp: PropTypes.bool.isRequired,
    resetPwErrors: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      this.props.formInit();
    }
  };

  validateConfirmPassword = val => val === this.props.form.password;
  validateEmail = val => val.indexOf('@') > 0 && val.indexOf('.') > 1;
  validateConfirmEmail = val => val === this.props.form.username;
  validateOptionalEmail = val => (val ? ((val.indexOf('@') > 0) && (val.indexOf('.') > 1)) : true);
  validatePhone = (val) => {
    const { length } = val.replace(/[^0-9+]+/g, '');
    return (length > 10) && (length <= 13);
  };
  validatePassword = val => (
    (val.toUpperCase() !== val) &&
    (val.toLowerCase() !== val) &&
    (/[0-9]/.test(val)) &&
    (/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(val)) &&
    (val.length >= 8)
  );

  isOutsideRange = () => false;

  formOnKeyDown = (e) => { if (e.key === 'Enter') document.getElementById('forms.joinNow.submit').focus(); };

  phoneHelp = () => (
    this.props.showPhoneHelp &&
      <React.Fragment>
        {
          this.props.phoneHelp.map((line, index) => (
            <div key={index}>{line}</div>
          ))
        }
      </React.Fragment>
  );

  // passwordHelp = () => (
  //   this.props.showPasswordHelp &&
  //     <PasswordHelp passwordHelp={this.props.passwordHelp} />
  // );

  submit = () => {
    this.props.submit();
  };

  handlePwFocus = () => {
    this.props.resetPwErrors();
  }

  render = () => (
    <div className="container welcome-first">
      <div className="row">
        <div className="col">
          <div className="welcome-msg">Welcome to Virtual Combine!</div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Form
            className="welcome-form"
            model="forms.joinNow"
            onSubmit={this.submit}
            onKeyDown={this.formOnKeyDown}
          >
            <TextControlGroup
              label="What is your first name?"
              placeholder="First Name"
              parser={parserName}
              model="forms.joinNow.first"
            />
            <TextControlGroup
              label="And what is your last?"
              placeholder="Last Name"
              parser={parserName}
              model="forms.joinNow.last"
            />
            <TextControlGroup
              label="Now enter an email address"
              placeholder="Email"
              parser={parserLower}
              model="forms.joinNow.username"
            />
            <TextControlGroup
              label="Confirm your email"
              placeholder="Confirm Email"
              parser={parserLower}
              model="forms.joinNow.confirmUsername"
            />
            <TextControlGroup
              label="Please create a password."
              placeholder="Password"
              type="password"
              model="forms.joinNow.password"
              // help={this.passwordHelp()}
              onFocus={this.handlePwFocus}
            />
            <TextControlGroup
              label="Now, confirm your password."
              placeholder="Password"
              type="password"
              model="forms.joinNow.confirmPassword"
            />
            {
              this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{this.props.formGeneralError}</div>
                </div>
            }
            <div className="welcome-btns">
              <button id="forms.joinNow.submit" className="welcome-btn" type="submit">Join Now</button>
            </div>
          </Form>
          <div className="d-flex justify-content-center">
            <Link className="welcome-link" to={`/${Routes.welcome}/${Routes.login}`}>
              Already have an account? Sign In.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinNow;
