import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PhotosIconButton } from '../../../../UxCommon';
import TagItem from './TagItem';

class Tags extends PureComponent {
  static propTypes = {
    onEditClick: PropTypes.func,
    tags: PropTypes.array,
    onTagSelected: PropTypes.func,
    onTagUnselected: PropTypes.func,
    selectedTagsFilters: PropTypes.array,
    tagsRelMedia: PropTypes.array,
    isMyProfile: PropTypes.bool,
  }

  componentDidMount() {
  }

  render() {
    const {
      onEditClick,
      tags,
      selectedTagsFilters,
      onTagSelected,
      onTagUnselected,
      tagsRelMedia,
      isMyProfile,
    } = this.props;
    return (
      <div>
        <div className="UxProfile_Photos_SideBar_Tags">
          <div className="UxProfile_Photos_SideBar_Tags_Txt">
            Tags
          </div>
          { isMyProfile && <PhotosIconButton className="hidden-md-down" iconName="edit" onClick={onEditClick} /> }
        </div>
        <div className="UxProfile_Photos_SideBar_Tags_TagBtns" >
          { tags.map((tag, index) => (
            <TagItem
              key={index}
              tag={tag}
              isEditMode={false}
              isInModal={false}
              isNewTagEnable={false}
              onTagSelected={onTagSelected}
              onTagUnselected={onTagUnselected}
              selectedTagsFilters={selectedTagsFilters}
              tagNumber={tagsRelMedia && tagsRelMedia.filter(rel => rel.tagId === tag.id).length}
            />
            ))
          }
        </div>
      </div>
    );
  }
}

export default Tags;
