import { combineEpics } from 'redux-observable';

import { readEpics } from './read';
import { createUpdateEpics } from './createUpdate';
import { deleteEpics } from './delete';

export const testsEpics = combineEpics(
  readEpics,
  createUpdateEpics,
  deleteEpics,
);

export const dummy2 = {};
