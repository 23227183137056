import { connect } from 'react-redux';
import Component from './Leaderboards.component';
import { selectCoachOrAthleteSchoolIds, selectFilteredCombines } from '../../+store';
import { getSchoolCombines } from "../../+store/combine";
import {getCurrentUuid} from "../../../../containers/UxCommon/Utils";


const mapStateToProps = (state, ownProps) => {
  const user = state.data.user.profiles[state.data.cognito.uuid];
  return ({
    schoolIds: selectCoachOrAthleteSchoolIds(state),
    combines: selectFilteredCombines(state, ownProps),
    athleteCombineResults: state.modules.coachWorld.combine.athleteCount,
    sports: state.data.sport.sports,
    coachSports: state.data.sport.sportsOrgAff || {},
    isCoach: user && (user.roleId ===4),
    coachProfile: state.data.user.profiles[state.data.cognito.uuid],
    schoolStyles: state.modules.coachWorld.coachWorld.schoolStyle,
  });
};

const mapDispatchToProps = dispatch => ({
  getSchoolCombines: (schoolIds) => {
    dispatch(getSchoolCombines(schoolIds));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
