import { connect } from 'react-redux';
import queryString from 'query-string';

import Component from './SearchControl.component';

const mapStateToProps = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { search } = parsedQuery;
  return {
    value: search || '',
  };
};

const mapDispatchToProps = () => ({
  onChange: (location, history, value) => {
    const parsedQuery = queryString.parse(location.search);
    parsedQuery.search = value;
    history.push({
      search: queryString.stringify(parsedQuery),
    });
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
