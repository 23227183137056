import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '../../../../components/UxCommon';

class AthletePhoto extends PureComponent {
  static propTypes = {
    photoUrl: PropTypes.string,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    loadingProgress: PropTypes.object,
  }

  componentDidMount() {
  }

  render() {
    const {
      photoUrl,
      loading,
      loadingMsg,
      loadingProgress,
    } = this.props;
    return (
      <div className={`UxEditProfile_ProfilePhoto_Pic ${loading ? '' : 'UxEditProfile_ProfilePhoto_Pic_sm'}`}>
        {
          loading
            ? (
              <LoadingSpinner
                loading
                loadingMsg={loadingMsg}
                loadingProgress={loadingProgress}
                style={{ background: 'none', padding: '20px' }}
                className="UxEditProfile_ProfilePhoto_LoadingSpinner"
              />
            ) :
            <div className="UxProfile_S_ProfilePhotoImg" style={{ backgroundImage: `url('${photoUrl}')` }}/>
        }
      </div>
    );
  }
}

export default AthletePhoto;
