import { combineReducers } from "redux";
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from "../../actions/ui/async";
import { STRIPE_CHECKOUT, STRIPE_SCHOOL_PRODUCT } from "../../actions/ui/welcome/StripeCheckout";

const loading = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === STRIPE_CHECKOUT) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === STRIPE_CHECKOUT) {
        return false;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === STRIPE_CHECKOUT) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const errorMessage = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === STRIPE_CHECKOUT) {
        return action.error.response.error.message;
      }
      return state;
    case ASYNC_FINISH:
    default:
      return state;
  }
};

const subscriptionSuccess = (state = false, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === STRIPE_CHECKOUT) {
        return true;
      }
      return state;
    default:
      return state;
  }
};

const product = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === STRIPE_SCHOOL_PRODUCT) {
        return action.data.product;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loading,
  errorMessage,
  subscriptionSuccess,
  product
});

export default reducer;
