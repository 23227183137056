import { combineReducers } from 'redux';
import { SET_TOP_BANNER, SET_BOTTOM_BANNER, CLOSE_TOP_BANNER_MSG } from '../../../actions/ui/uxProfile';
import {
  PHONE_NEEDS_CONFIRMED,
  EMAIL_NEEDS_CONFIRMED,
  EMAIL_SUCCESS_MERGED
} from "../../../actions/ui/uxEditProfile/account";
import { getRandomInt } from '../../../../utils/cognito';
import { NO_INTERNET, CLIENT_ERROR, SERVER_ERROR, UNKNOWN_COMMS_ERROR } from '../../../actions/ui/ajaxErrorHandlers';
import { USER_SPORT_UPDATE_SUC } from '../../../actions/data/user/userSport';
import { USER_PROFILE_UPDATE_SUC } from '../../../actions/data/user/profile';
import { ASYNC_FINISH, ASYNC_ERROR } from '../../../actions/ui/async';
import { FAVORITE_AWARD, UPDATE_AWARD, CREATE_AWARD, DELETE_AWARD, HIDE_AWARD } from '../../../actions/ui/uxProfile/awards';
import {
  UPDATE_ALBUM,
  CREATE_ALBUM,
  STAGE_MEDIA_PROBLEM,
  MULTI_CHANGE_ALBUM_EXECUTE,
  MEDIA_NOT_FOUND,
} from '../../../actions/ui/uxProfile/photosVideos';
import {
  CREATE_EXTERNAL_PROFILE,
  DELETE_EXTERNAL_PROFILE,
  UPDATE_EXTERNAL_PROFILE
} from "../../../actions/ui/uxProfile/externalProfile";
import { UPDATE_INVITE_PROFILE_FORM_FINAL } from '../../../../components/Welcome/AthletInviteOnboarding/+Store/AthletePhysique/AthletePhysique.action';

const topBannerComponent = (state = null, action) => {
  switch (action.type) {
    case SET_TOP_BANNER:
      return action.component;
    default:
      return state;
  }
};

const bottomBannerComponent = (state = null, action) => {
  switch (action.type) {
    case SET_BOTTOM_BANNER:
      return action.component;
    default:
      return state;
  }
};

const topBannerMsgs = (state = [], action) => {
  switch (action.type) {
    case PHONE_NEEDS_CONFIRMED:
      if (!state.find(msg => msg.type === PHONE_NEEDS_CONFIRMED)) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'warn',
          ...action,
        }];
      }
      return state;
    case EMAIL_SUCCESS_MERGED:
      if (!state.find(msg => msg.type === EMAIL_SUCCESS_MERGED)) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'success',
          ...action,
        }];
      }
      return state;
    case EMAIL_NEEDS_CONFIRMED:
      if (!state.find(msg => msg.type === EMAIL_NEEDS_CONFIRMED)) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'warn',
          ...action,
        }];
      }
      return state;
    case NO_INTERNET:
    case CLIENT_ERROR:
    case SERVER_ERROR:
    case UNKNOWN_COMMS_ERROR:
      if (!state.find(msg => msg.type === NO_INTERNET)) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'error',
          ...action,
        }];
      }
      return state;
    case STAGE_MEDIA_PROBLEM:
      return [...state, {
        id: getRandomInt(0, 1000),
        msgType: 'error',
        msgDesc: action.msg,
        ...action,
      }];
    case MEDIA_NOT_FOUND:
      return [...state, {
        id: getRandomInt(0, 1000),
        msgType: 'error',
        msgDesc: action.msg,
        ...action,
      }];
    case CLOSE_TOP_BANNER_MSG:
      return state.filter(msg => msg.id !== action.id);
    case USER_SPORT_UPDATE_SUC:
    case USER_PROFILE_UPDATE_SUC:
      if (!state.find(msg => msg.type === USER_SPORT_UPDATE_SUC)) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'success',
          ...action,
        }];
      }
      return state;
    case ASYNC_FINISH:
      if ((action.model === FAVORITE_AWARD) ||
      (action.model === CREATE_AWARD) ||
      (action.model === UPDATE_AWARD) ||
      (action.model === DELETE_AWARD) ||
      (action.model === HIDE_AWARD) ||
      (action.model === MULTI_CHANGE_ALBUM_EXECUTE) ||
      (action.model === CREATE_EXTERNAL_PROFILE ) ||
      (action.model === UPDATE_EXTERNAL_PROFILE ) ||
      (action.model === DELETE_EXTERNAL_PROFILE ) ||
      (action.model === UPDATE_INVITE_PROFILE_FORM_FINAL)
      ) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'success',
          ...action,
        }];
      }
      return state;
    case ASYNC_ERROR:
      if ((action.model === UPDATE_ALBUM || action.model === CREATE_ALBUM) &&
        (action.desc === 'pollResized') &&
        (!state.find(msg => (msg.model === UPDATE_ALBUM || msg.model === CREATE_ALBUM)))) {
        return [...state, {
          id: getRandomInt(0, 1000),
          msgType: 'success',
          ...action,
        }];
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  topBannerComponent,
  bottomBannerComponent,
  topBannerMsgs,
});

export default reducer;
