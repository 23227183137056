import React, {useEffect, useState} from "react";

import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Form} from "react-redux-form";
import TextControlGroup from "../../../UxCommon/Form/TextControlGroup/TextControlGroup";
import {useDispatch, useSelector} from "react-redux";
import {stripeCheckout, stripeSchoolProduct} from "../../../../store/actions/ui/welcome/StripeCheckout";
import LoadingSpinner from "../../../Common/LoadingSpinner/LoadingSpinner";
import StripePayButtonComponent from "./StripePayButton/StripePayButton.component";
import CheckoutOptionsComponent from "./CheckoutOptions/CheckoutOptions.component";


const CheckoutForm = ({profile, match}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const [billingDetails, setBillingDetails] = useState({
    name: profile.parentName
  });
  const {schoolId} = match.params;
  const [showLoader, setShowLoader] = useState(false);
  const [paymentRequestLoading, setPaymentRequestLoading] = useState(true);
  const [showPaymentOptions, setShowPaymentOptions] = useState(null);
  const [showCreditCard, setShowCreditCard] = useState(null);
  const [supportWallet, setSupportWallet] = useState(false);
  const loading = useSelector((state) => state.stripe.loading);

  const subscribeError = useSelector((state) => state.stripe.errorMessage);

  const [stripeError, setStripeError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const {userTeamId} = match.params;
  const product = useSelector((state) => state.stripe.product);

  useEffect(() => {
    if (stripe && product) {
      const pr = stripe.paymentRequest({
        country: 'US', currency: 'usd', total: {
          label: product.name, amount: product.price * 100,
        }, requestPayerName: true, requestPayerEmail: true, disableWallets: ['link']
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        setPaymentRequestLoading(false);
        if (result) {
          setPaymentRequest(pr);
          if (result.applePay || result.googlePay) {
            setShowPaymentOptions(true);
            setShowCreditCard(false);
            setSupportWallet(true);
          } else {
            setShowCreditCard(true)
            setShowPaymentOptions(false);
          }
        } else {
          setShowCreditCard(true);
          setShowPaymentOptions(false);
        }
      });
    }
  }, [stripe, product]);

  useEffect(() => {
    dispatch(stripeSchoolProduct({
      schoolId
    }));
  }, []);


  useEffect(() => {
    setShowLoader(loading);
    setStripeError(subscribeError);
  }, [loading, subscribeError]);

  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      return;
    }

    setShowLoader(true);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "card", card: elements.getElement(CardElement), billing_details: {
        ...billingDetails, address: {
          //country: "US"
        }
      }
    });

    if (!error) {
      setShowLoader(false);
      dispatch(stripeCheckout({
        paymentMethodId: paymentMethod.id,
        email: profile.email,
        parentName: billingDetails.name,
        uuid: profile.uuid,
        userTeamId,
        productId: product.id,
        schoolId,
      }));
    } else {
      setStripeError(error.message);
    }
  };

  if (paymentRequest) {

    paymentRequest.on('paymentmethod', async (ev) => {


      dispatch(stripeCheckout({
        paymentMethodId: ev.paymentMethod.id,
        email: profile.email,
        parentName: billingDetails.name,
        uuid: profile.uuid,
        userTeamId: userTeamId,
        productId: product.id,
        schoolId,
        googlePayEvent: ev
      }));

    });
  }

  if (paymentRequestLoading) {
    return (<div className="container welcome-first">
      <LoadingSpinner
        loading={showLoader}
        loadingMsg={"Payment Processing ..."}
        wrapperStyle={{top: 300}}
      />
    </div>)
  }

  if (showPaymentOptions) {
    return (
      <CheckoutOptionsComponent setShowCreditCard={setShowCreditCard} setShowPaymentOptions={setShowPaymentOptions}
                                paymentRequest={paymentRequest}/>)
  }


  return (showCreditCard && (<div className="container welcome-first">
    <LoadingSpinner
      loading={showLoader}
      loadingMsg={"Payment Processing ..."}
      wrapperStyle={{top: 300}}
    />

    <div className="row justify-content-center">
      <div className="col-lg-6">
        <Form
          className="welcome-form"
          model="forms.joinNow"
          onSubmit={handleSubmit}
        >
          <TextControlGroup
            label="Name on the card"
            model="forms.stripe.name"
            id="name"
            type="text"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({...billingDetails, name: e.target.value});
            }}
          />
          <CardElement
            onChange={(e) => {
              if (e.error) {
                setStripeError(e.error.message);
              } else {
                setStripeError("");
              }
            }}
          />
          {stripeError && (<div className="form-group">
            <div className="form-general-error">{stripeError}</div>
          </div>)}
          <div className="welcome-logo">
            <a title="Powered By Stripe">
              <img
                className="fast-pitch-logo-img"
                src="https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/stripe-logo.png"
                alt="Virtual Combine Home"
              />
            </a>
          </div>
          <div className="welcome-btns">
            {product && (<StripePayButtonComponent product={product}/>)}
          </div>
        </Form>
      </div>
    </div>
  </div>));
};

export default CheckoutForm;
