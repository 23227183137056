import { connect } from 'react-redux';
import Component from './UpdatePhotoDialog';

import { updateProfilePhoto } from '../../../../store/actions/ui/uxEditProfile';
import { routeChangedAlert } from '../../../../store/actions/ui/routes';
import { onAthletePhotoDrop } from '../../+store/roster/roster.photo.actions';

const mapStateToProps = state => ({
  uuid: state.data.cognito.uuid,
  loadingPhoto: state.modules.coachWorld.roster.loadingAthletePhoto,
  loadingPhotoMsg: state.modules.coachWorld.roster.loadingAthletePhotoMsg,
  loadingPhotoProgress: state.modules.coachWorld.roster.loadingProgress,
  dropError: state.modules.coachWorld.roster.photoDropError,
  photoDataToCrop: state.modules.coachWorld.roster.photoDataToCrop,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onOldProfilePhotoSelected: updateProfilePhoto(dispatch),
    onDrop: onAthletePhotoDrop(dispatch),
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
