import React from 'react';
import PropTypes from 'prop-types';
import {
  supportMultiple,
  fileAccepted,
  allFilesAccepted,
  fileMatchSize,
  onDocumentDragOver,
  getDataTransferItems,
  isIeOrEdge,
} from '../../../utils';

const styles = {
  rejected: {
    backgroundColor: '#eee',
  },
  disabled: {
    opacity: 0.5,
  },
  active: {
    backgroundColor: '#eee',
  },
  default: {
    width: '100%',
    height: '100%',
  },
};

class Dropzone extends React.Component {
  static propTypes = {
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    disableClick: PropTypes.bool,
    disabled: PropTypes.bool,
    disablePreview: PropTypes.bool,
    preventDropOnDocument: PropTypes.bool,
    inputProps: PropTypes.object,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    acceptClassName: PropTypes.string,
    rejectClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    style: PropTypes.object,
    activeStyle: PropTypes.object,
    acceptStyle: PropTypes.object,
    rejectStyle: PropTypes.object,
    disabledStyle: PropTypes.object,
    onClick: PropTypes.func,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.isFileDialogActive = false;

    this.state = {
      draggedFiles: [],
      acceptedFiles: [],
      rejectedFiles: [],
    };
  }

  componentDidMount() {
    const { preventDropOnDocument } = this.props;
    this.dragTargets = [];

    if (preventDropOnDocument) {
      document.addEventListener('dragover', onDocumentDragOver, false);
      document.addEventListener('drop', this.onDocumentDrop, false);
    }
    this.fileInputEl.addEventListener('click', this.onInputElementClick, false);
    window.addEventListener('focus', this.onFileDialogCancel, false);
  }

  componentWillUnmount() {
    const { preventDropOnDocument } = this.props;
    if (preventDropOnDocument) {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', this.onDocumentDrop);
    }
    if (this.fileInputEl != null) {
      this.fileInputEl.removeEventListener('click', this.onInputElementClick, false);
    }
    window.removeEventListener('focus', this.onFileDialogCancel, false);
  }

  onDocumentDrop = (event) => {
    if (this.node && this.node.contains(event.target)) {
      return;
    }
    event.preventDefault();
    this.dragTargets = [];
  };

  onDragStart = (event) => {
    if (this.props.onDragStart) {
      this.props.onDragStart.call(this, event);
    }
  };

  onDragEnter = (event) => {
    event.preventDefault();

    if (this.dragTargets.indexOf(event.target) === -1) {
      this.dragTargets.push(event.target);
    }

    this.setState({
      isDragActive: true,
      draggedFiles: getDataTransferItems(event),
    });

    if (this.props.onDragEnter) {
      this.props.onDragEnter.call(this, event);
    }
  };

  onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      event.dataTransfer.dropEffect = this.isFileDialogActive ? 'none' : 'copy';
    } catch (err) {
      // continue regardless of error
    }

    if (this.props.onDragOver) {
      this.props.onDragOver.call(this, event);
    }
    return false;
  };

  onDragLeave = (event) => {
    event.preventDefault();

    this.dragTargets = this.dragTargets.filter(el => el !== event.target && this.node.contains(el));
    if (this.dragTargets.length > 0) {
      return;
    }

    this.setState({
      isDragActive: false,
      draggedFiles: [],
    });

    if (this.props.onDragLeave) {
      this.props.onDragLeave.call(this, event);
    }
  };

  onDrop = (event) => {
    // // this.node.scrollToBottom();
    // this.node.scrollIntoView({ behavior: 'smooth', block: 'end' });
    // window.s = this.node;
    setTimeout(() => {
      const btn = document.querySelector('.UxProfile_Photos_AlbumDialog_Btns');
      if (btn) {
        btn.scrollIntoView();
      }
    }, 100);

    const {
      onDrop, onDropAccepted, onDropRejected, multiple, disablePreview, accept,
    } = this.props;
    const fileList = getDataTransferItems(event);
    const acceptedFiles = [];
    const rejectedFiles = [];

    event.preventDefault();

    this.dragTargets = [];
    this.isFileDialogActive = false;

    fileList.forEach((file) => {
      if (!disablePreview) {
        try {
          file.preview = window.URL.createObjectURL(file); // eslint-disable-line no-param-reassign
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            console.error('Failed to generate preview for file', file, err); // eslint-disable-line no-console
          }
        }
      }

      if (
        fileAccepted(file, accept) &&
        fileMatchSize(file, this.props.maxSize, this.props.minSize)
      ) {
        acceptedFiles.push(file);
      } else {
        rejectedFiles.push(file);
      }
    });

    if (!multiple) {
      rejectedFiles.push(...acceptedFiles.splice(1));
    }

    if (onDrop) {
      onDrop.call(this, acceptedFiles, rejectedFiles, event);
    }

    if (rejectedFiles.length > 0 && onDropRejected) {
      onDropRejected.call(this, rejectedFiles, event);
    }

    if (acceptedFiles.length > 0 && onDropAccepted) {
      onDropAccepted.call(this, acceptedFiles, event);
    }

    this.draggedFiles = null;

    this.setState({
      isDragActive: false,
      draggedFiles: [],
      acceptedFiles,
      rejectedFiles,
    });
  };

  onClick = (event) => {
    const { onClick, disableClick } = this.props;
    if (!disableClick) {
      event.stopPropagation();

      if (onClick) {
        onClick.call(this, event);
      }
      if (isIeOrEdge()) {
        setTimeout(this.open.bind(this), 0);
      } else {
        this.open();
      }
    }
  }

  onInputElementClick = (event) => {
    event.stopPropagation();
    if (this.props.inputProps && this.props.inputProps.onClick) {
      this.props.inputProps.onClick();
    }
  }

  onFileDialogCancel = () => {
    const { onFileDialogCancel } = this.props;
    if (this.isFileDialogActive) {
      setTimeout(() => {
        if (this.fileInputEl != null) {
          // Returns an object as FileList
          const { files } = this.fileInputEl;

          if (!files.length) {
            this.isFileDialogActive = false;
          }
        }

        if (typeof onFileDialogCancel === 'function') {
          onFileDialogCancel();
        }
      }, 300);
    }
  };

  setRef = (ref) => {
    this.node = ref;
  };

  setRefs = (ref) => {
    this.fileInputEl = ref;
  };

  composeHandlers = (handler) => {
    if (this.props.disabled) {
      return null;
    }
    return handler;
  };

  open = () => {
    this.isFileDialogActive = true;
    this.fileInputEl.value = null;
    this.fileInputEl.click();
  };

  renderChildren = (children, isDragActive, isDragAccept, isDragReject) => {
    if (typeof children === 'function') {
      return children({
        ...this.state,
        isDragActive,
        isDragAccept,
        isDragReject,
      });
    }
    return children;
  };

  render() {
    const {
      accept,
      acceptClassName,
      activeClassName,
      children,
      disabled,
      disabledClassName,
      inputProps,
      multiple,
      name,
      rejectClassName,
      ...rest
    } = this.props;

    let {
      acceptStyle,
      activeStyle,
      className = '',
      disabledStyle,
      rejectStyle,
      style,
      // eslint-disable-next-line prefer-const
      ...props
    } = rest;

    const { isDragActive, draggedFiles } = this.state;
    const filesCount = draggedFiles.length;
    const isMultipleAllowed = multiple || filesCount <= 1;
    const isDragAccept = filesCount > 0 && allFilesAccepted(draggedFiles, this.props.accept);
    const isDragReject = filesCount > 0 && (!isDragAccept || !isMultipleAllowed);
    const noStyles =
      !className && !style && !activeStyle && !acceptStyle && !rejectStyle && !disabledStyle;

    if (isDragActive && activeClassName) {
      className += ` ${activeClassName}`;
    }
    if (isDragAccept && acceptClassName) {
      className += ` ${acceptClassName}`;
    }
    if (isDragReject && rejectClassName) {
      className += ` ${rejectClassName}`;
    }
    if (disabled && disabledClassName) {
      className += ` ${disabledClassName}`;
    }

    if (noStyles) {
      style = styles.default;
      activeStyle = styles.active;
      acceptStyle = style.active;
      rejectStyle = styles.rejected;
      disabledStyle = styles.disabled;
    }

    let appliedStyle = { ...style };
    if (activeStyle && isDragActive) {
      appliedStyle = {
        ...style,
        ...activeStyle,
      };
    }
    if (acceptStyle && isDragAccept) {
      appliedStyle = {
        ...appliedStyle,
        ...acceptStyle,
      };
    }
    if (rejectStyle && isDragReject) {
      appliedStyle = {
        ...appliedStyle,
        ...rejectStyle,
      };
    }
    if (disabledStyle && disabled) {
      appliedStyle = {
        ...style,
        ...disabledStyle,
      };
    }

    const inputAttributes = {
      accept,
      disabled,
      type: 'file',
      style: { display: 'none' },
      multiple: supportMultiple && multiple,
      ref: this.setRefs,
      onChange: this.onDrop,
      autoComplete: 'off',
    };

    if (name && name.length) {
      inputAttributes.name = name;
    }

    const {
      acceptedFiles,
      preventDropOnDocument,
      disablePreview,
      disableClick,
      onDropAccepted,
      onDropRejected,
      onFileDialogCancel,
      maxSize,
      minSize,
      ...divProps
    } = props;

    return (
      <div
        className={className}
        style={appliedStyle}
        {...divProps}
        ref={this.setRef}
        onClick={this.composeHandlers(this.onClick)}
        onDragStart={this.composeHandlers(this.onDragStart)}
        onDragEnter={this.composeHandlers(this.onDragEnter)}
        onDragOver={this.composeHandlers(this.onDragOver)}
        onDragLeave={this.composeHandlers(this.onDragLeave)}
        onDrop={this.composeHandlers(this.onDrop)}
        role="presentation"
        aria-disabled={disabled}
      >
        {this.renderChildren(children, isDragActive, isDragAccept, isDragReject)}
        <input
          {...inputProps}
          {...inputAttributes}
        />
      </div>
    );
  }
}

Dropzone.defaultProps = {
  preventDropOnDocument: true,
  disabled: false,
  disablePreview: false,
  disableClick: false,
  multiple: true,
  maxSize: Infinity,
  minSize: 0,
};


export default Dropzone;
