import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VcRating from '../../../../components/UxProfile/VcRating/VcRating';
import LoadingIndicator from '../../common/LoadingIndicator';
import { displayCategory, displayCategoryImprovement } from '../Common/utils';

class SummaryBar extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    match: PropTypes.object.isRequired,
  }

  render() {
    const { report, match } = this.props;

    if (!report) {
      return <LoadingIndicator loading loadingMsg=""/>;
    }
    let mostRecent;
    let last;
    let recentText;
    let lastText;
    let teamAve;
    let teamRank;
    let personalTrend;
    let improvementPercent = null;
    if (match.params.bank === 'strength') {
      mostRecent = report.strength.most_recent;
      ({ last } = report.strength);
      recentText = 'Most Recent Strength';
      lastText = 'Last Strength';
      teamAve = report.strength.team_avg || null;
      teamRank = report.strength.team_ranking || null;
      personalTrend = report.strength.personal_trend || null;
      improvementPercent = report.strength.improvement_percent || null;
    } else if (match.params.bank === 'perf') {
      mostRecent = report.perf.most_recent;
      ({ last } = report.perf);
      recentText = 'Most Recent Performance';
      lastText = 'Last Performance';
      teamAve = report.perf.team_avg || null;
      teamRank = report.perf.team_ranking || null;
      personalTrend = report.perf.personal_trend || null;
      improvementPercent = report.perf.improvement_percent || null;
    }
    else if (match.params.bank === 'hitting') {
      mostRecent = report.perf.most_recent;
      ({ last } = report.perf);
      recentText = 'Most Recent Performance';
      lastText = 'Last Performance';
      teamAve = report.perf.team_avg || null;
      teamRank = report.perf.team_ranking || null;
      personalTrend = report.perf.personal_trend || null;
      improvementPercent = report.perf.improvement_percent || null;
    }

    else if (match.params.bank === 'pitching') {
      mostRecent = report.perf.most_recent;
      ({ last } = report.perf);
      recentText = 'Most Recent Performance';
      lastText = 'Last Performance';
      teamAve = report.perf.team_avg || null;
      teamRank = report.perf.team_ranking || null;
      personalTrend = report.perf.personal_trend || null;
      improvementPercent = report.perf.improvement_percent || null;
    }
    else {
      mostRecent = report.vc_score.most_recent;
      ({ last } = report.vc_score);
      recentText = 'Most Recent Score';
      lastText = 'Last Score';
      teamAve = report.vc_score.team_avg || null;
      teamRank = report.vc_score.team_ranking || null;
      personalTrend = report.vc_score.personal_trend || null;
      improvementPercent = report.vc_score.improvement_percent || null;
    }


    const overall = {
      rating: report.vc_score.most_recent,
      performance: report.perf.most_recent,
      strength: report.strength.most_recent,
    };
    return (
      <div className="CombineReportSummaryBar">
        <div className="CombineReportSummaryBar_Col">
          <VcRating small vcRating={overall}/>
        </div>
        <div className="CombineReportSummaryBar_Col">
          <div className="CombineReportSummaryBar_ColText">
            {recentText}
          </div>
          <div className="CombineReportSummaryBar_ColScore">
            {displayCategory(
              mostRecent,
              'SCORE',
            )}
          </div>
        </div>
        <div className="CombineReportSummaryBar_Col">
          <div className="CombineReportSummaryBar_ColText">
            {lastText}
          </div>
          <div className="CombineReportSummaryBar_ColScore">
            {displayCategory(
              last,
              'SCORE',
            )}
          </div>
        </div>
        <div className="CombineReportSummaryBar_Col">
          <div className="CombineReportSummaryBar_ColText">
            Improvement
          </div>
          <div className="CombineReportSummaryBar_ColScore">
            {displayCategoryImprovement(
              improvementPercent,
              'max',
              true,
              'PERCENTAGE-HUNDREDTHS',
            )}
          </div>
        </div>
        {
          report.athlete_uuid && (
            <React.Fragment>
              <div className="CombineReportSummaryBar_Col">
                <div className="CombineReportSummaryBar_ColText">
                  Team Avg
                </div>
                <div className="CombineReportSummaryBar_ColScore">
                  {displayCategory(
                    teamAve,
                    'SCORE',
                  )}
                </div>
              </div>
              <div className="CombineReportSummaryBar_Col">
                <div className="CombineReportSummaryBar_ColText">
                  Team Rank
                </div>
                <div className="CombineReportSummaryBar_ColScore">
                  {displayCategory(
                    teamRank,
                    'COUNT',
                  )}
                </div>
              </div>
              <div className="CombineReportSummaryBar_Col">
                <div className="CombineReportSummaryBar_ColText">
                  Personal Trend
                </div>
                <div className="CombineReportSummaryBar_ColScore">
                  {displayCategoryImprovement(
                    personalTrend,
                    'max',
                    true,
                    'COUNT',
                  )}
                </div>
              </div>
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default SummaryBar;
