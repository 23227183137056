import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { apiGetTestCategories, apiGetTestBanks, apiGetTestSubbanks } from './combine.api';
import {
  checkForkErrors,
  getTokenFragment,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import { GET_TEST_CATEGORIES } from './combine.actions';
import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { StandardTestCategory, CombineTemplate, StandardTestBank, StandardTestSubbank, CombineTemplateDetail } from './combineTemplates.models';
import {
  GET_COMBINE_TEMPLATE,
  CREATE_COMBINE_TEMPLATE,
  UPDATE_COMBINE_TEMPLATE,
  DELETE_COMBINE_TEMPLATE, GET_COMBINE_TEMPLATE_COMBINES, UPDATE_COMBINE_TEST,
} from './combineTemplates.actions';
import {
  apiGetCombineTemplate,
  apiDeleteCombineTemplate,
  apiCreateCombineTemplate,
  apiUpdateCombineTemplate,
  apiAddCombineTemplateStdTest,
  apiDeleteCombineTemplateStdTest, apiCombineTemplateDetails, apiAddCombineStdTest, apiDeleteCombineStdTest,
} from './combineTemplates.api';

const getTestCategoriesEpic = action$ => (
  action$.pipe(
    ofType(GET_TEST_CATEGORIES),
    switchMap(action => (
      forkJoin(
        apiGetTestCategories().pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getTestCategories',
        )),
        apiGetTestBanks().pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getTestBanks',
        )),
        apiGetTestSubbanks().pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getTestSubbanks',
        )),
      ).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return {
            ...forkResults,
            action,
          };
        }),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        const testCategories = result.results[0].response._embedded.map(apiCat => (
          StandardTestCategory.fromApi(apiCat)
        ));
        const testBanks = result.results[1].response._embedded.map(apiBank => (
          StandardTestBank.fromApi(apiBank)
        ));
        const testSubbanks = result.results[2].response.map(apiSubbank => (
          StandardTestSubbank.fromApi(apiSubbank)
        ));
        const sportedCats = [];
        testBanks.forEach((bank) => {
          bank.subbanks.forEach((subbankId) => {
            const subbank = testSubbanks.find(sb => sb.id === subbankId);
            subbank.standardTestObjects.forEach((testObj) => {
              const cat = testCategories.find(c => c.id === testObj.catId);
              if (cat) {
                if (cat.sportIds.length) {
                  cat.sportIds.forEach((sportId) => {
                    sportedCats.push(cat.cloneWithSport(sportId, bank.code, subbank.code));
                  });
                } else {
                  sportedCats.push(cat.cloneWithSport(0, bank.code, subbank.code));
                }
              }
            });
          });
        });

        const uniqueSports = {};
        sportedCats.forEach((cat) => {
          uniqueSports[cat.sportId] = true;
        });

        const uniqueCats = {};
        sportedCats.forEach((cat) => {
          uniqueCats[cat.code] = cat;
        });
        const parsedCats = Object.values(uniqueCats);

        const organizedCats = {};
        parsedCats.forEach((cat) => {
          organizedCats[cat.code] = cat;
        });

        organizedCats.TOTAL = {
          best: 'max',
          code: 'TOTAL',
          comparable: 1,
          description: 'Total for strength',
          id: 999,
          name: 'Total',
          rateable: 0,
          adultRateable: 1,
          youthRateable: 1,
          sportId: 19,
          bankCode: 'STRENGTH',
          subbankCode: 'LEGHIP',
        };

        const organizedSubbanks = {};
        testSubbanks.forEach((subbank) => {
          const bank = testBanks.find(b => b.id === subbank.standardTestBankId);
          subbank.bankCode = bank.code;
          organizedSubbanks[subbank.code] = subbank;
        });

        return of(asyncFinishAction(result.action.type, 'getTestCategories', {
          stdTestCategories: parsedCats,
          sportedCats,
          organizedSubbanks,
          organizedCats,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineTemplateEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetCombineTemplate(action.combineTemplateId, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getCombineTemplate',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const combineTemplate = CombineTemplate.fromApi(result.response);
        return of(asyncFinishAction(result.action.type, 'getCombineTemplate', {
          combineTemplate,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteCombineTemplateEpic = action$ => (
  action$.pipe(
    ofType(DELETE_COMBINE_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiDeleteCombineTemplate(action.combineTemplateId, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'deleteCombineTemplate',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'deleteCombineTemplate', {
          combineTemplateId: result.action.combineTemplateId,
          schoolId: result.action.schoolId,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const createCombineTemplateEpic = action$ => (
  action$.pipe(
    ofType(CREATE_COMBINE_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiCreateCombineTemplate(action.data, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'createCombineTemplate',
        { token },
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const combineTemplateId = result.response.id;
        if (result.action.stdTestIds.length) {
          const apiCalls=[] ;
           apiCalls.push(
            apiAddCombineTemplateStdTest(combineTemplateId, mapCreateCombineUiToApi(result.action.stdTestIds,combineTemplateId), result.token)
              .pipe(manualCancelApiCallFragment(
                action$,
                result.action,
                'createCombineTemplate',
              ))
          );
          return forkJoin(...apiCalls).pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  ...forkResults,
                  action: result.action,
                  result,
                };
              }
              return {
                ...forkResults,
                action: result.action,
                result,
              };
            }),
          );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        let combineTemplate;
        if (result.results.length) {
          combineTemplate = CombineTemplate.fromApi(result.results.pop().response);
        } else {
          combineTemplate = CombineTemplate.fromApi(result.result.response);
        }
        return of(asyncFinishAction(result.action.type, 'createCombineTemplate', {
          combineTemplate,
          schoolId: result.action.schoolId,
          redirectToCombine: result.action.redirectToCombine,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const updateCombineTestEpic = action$ => (
    action$.pipe(
        ofType(UPDATE_COMBINE_TEST),
        getTokenFragment(),
        switchMap(({ action, token }) => (
            apiUpdateCombineTemplate(action.data, action.combineTemplateId, token)
                .pipe(manualCancelApiCallFragment(
                    action$,
                    action,
                    UPDATE_COMBINE_TEST,
                    { token },
                ))
        )),
        switchMap((result) => {
          if (result.success) {
            const combineTemplateId = result.response.id;
            const combineId = result.action.combineId;
            const apiCalls = [];
            if(result.action.newStdTestIds.length > 0 ) {

              // this will add new test on combine template
              // needs client confirmation to proceed
              // apiCalls.push(apiAddCombineTemplateStdTest(
              //     combineTemplateId,
              //     mapCreateCombineUiToApi(result.action.newStdTestIds,combineTemplateId),
              //     result.token,
              // ).pipe(manualCancelApiCallFragment(
              //     action$,
              //     result.action,
              //     'addCombineTemplateStdTests',
              // )));

              if(combineId){
                apiCalls.push(apiAddCombineStdTest(
                    combineId,
                    mapCreateCombineTestUiToApi(result.action.newStdTestIds, combineId),
                    result.token,
                ).pipe(manualCancelApiCallFragment(
                    action$,
                    result.action,
                    'addCombineStdTests',
                )));
              }

            }

            if(result.action.removedStdTestIds.length > 0) {
              // this will add new test on combine template
              // needs client confirmation to proceed
              // apiCalls.push(apiDeleteCombineTemplateStdTest(
              //     combineTemplateId,
              //     mapRemoveUpdateCombineUiToApi(result.action.removedStdTestIds,combineTemplateId),
              //     result.token,
              // ).pipe(manualCancelApiCallFragment(
              //     action$,
              //     result.action,
              //     'addCombineTemplateStdTests',
              // )));

              if(combineId) {
                apiCalls.push(apiDeleteCombineStdTest(
                    combineId,
                    mapRemoveUpdateCombineTestUiToApi(result.action.removedStdTestIds, combineId),
                    result.token,
                ).pipe(manualCancelApiCallFragment(
                    action$,
                    result.action,
                    'addCombineStdTests',
                )));
              }


            }
            if (apiCalls.length) {
              return forkJoin(...apiCalls).pipe(
                  checkForkErrors(),
                  map((forkResults) => {
                    if (forkResults.success) {
                      return {
                        ...forkResults,
                        action: result.action,
                        result,
                      };
                    }
                    return {
                      ...forkResults,
                      action: result.action,
                      result,
                    };
                  }),
              );
            }
          }
          return of(result);
        }),
        switchMap((result) => {
          if (result.success) {
            // let combineTemplate;
            // if (result.results && result.results.length) {
            //   combineTemplate = CombineTemplate.fromApi(result.results.pop().response);
            // } else {
            //   combineTemplate = CombineTemplate.fromApi(result.response);
            // }
            return of(asyncFinishAction(result.action.type, UPDATE_COMBINE_TEST, {
              // combineTemplate,
              schoolId: result.action.schoolId,
              redirectToCombine: result.action.redirectToCombine,
              redirectToCombineDataEntry: result.action.redirectToCombineDataEntry,
              combineId: result.action.combineId,
            }));
          }
          if (result.actions) return of(...result.actions);
          return of(result.action);
        }),
    )
);



const updateCombineTemplateEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_COMBINE_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiUpdateCombineTemplate(action.data, action.combineTemplateId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateCombineTemplate',
          { token },
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const combineTemplateId = result.response.id;
        const apiCalls = [];
        if(result.action.newStdTestIds.length > 0 ) {
          apiCalls.push(apiAddCombineTemplateStdTest(
            combineTemplateId,
            mapCreateCombineUiToApi(result.action.newStdTestIds,combineTemplateId),
            result.token,
          ).pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'addCombineTemplateStdTests',
          )));
        }

        if(result.action.removedStdTestIds.length > 0) {
          apiCalls.push(apiDeleteCombineTemplateStdTest(
            combineTemplateId,
            mapRemoveUpdateCombineUiToApi(result.action.removedStdTestIds,combineTemplateId),
            result.token,
          ).pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'addCombineTemplateStdTests',
          )));
        }
        if (apiCalls.length) {
          return forkJoin(...apiCalls).pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  ...forkResults,
                  action: result.action,
                  result,
                };
              }
              return {
                ...forkResults,
                action: result.action,
                result,
              };
            }),
          );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        let combineTemplate;
        if (result.results && result.results.length) {
          combineTemplate = CombineTemplate.fromApi(result.results.pop().response);
        } else {
          combineTemplate = CombineTemplate.fromApi(result.response);
        }
        return of(asyncFinishAction(result.action.type, 'updateCombineTemplate', {
          combineTemplate,
          schoolId: result.action.schoolId,
          redirectToCombine: result.action.redirectToCombine,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const mapCreateCombineUiToApi = (stdIds, combineTemplateId) => ({
  stdTestCatIds : stdIds,
  combineTemplateId
})

const mapCreateCombineTestUiToApi = (stdIds, combineId) => ({
  stdTestCatIds : stdIds,
  combineId
})

const mapRemoveUpdateCombineUiToApi = (stdIds, combineTemplateId) =>({
  removeTestCatIds : stdIds,
  combineTemplateId
})

const mapRemoveUpdateCombineTestUiToApi = (stdIds, combineId) =>({
  removeTestCatIds : stdIds,
  combineId
})

const getCombineTemplateCombinesEpic = action$ => action$.pipe(
    ofType(GET_COMBINE_TEMPLATE_COMBINES),
    getTokenFragment(),
    switchMap( ({ action , token }) => {
      const { combineTemplateId } = action;
       return apiCombineTemplateDetails(combineTemplateId, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'combineTemplateCombines',
            { token },
          ))
      }),
    switchMap( ( result) => {
        if (result.success) {
          const combineTemplateDetails = CombineTemplateDetail.fromApi(result.response.response);
          const data = {};
          data[combineTemplateDetails.id] = combineTemplateDetails;
          return of(asyncFinishAction( result.action.type, 'combineTemplateCombines', data));
        }
        if (result.action) return of (...result.action);
        return  of(result.action);
    })
)

export default combineEpics(
  getTestCategoriesEpic,
  getCombineTemplateEpic,
  deleteCombineTemplateEpic,
  createCombineTemplateEpic,
  updateCombineTemplateEpic,
  getCombineTemplateCombinesEpic,
  updateCombineTestEpic,
);
