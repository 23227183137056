import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Video from '../../../../store/actions/data/models/Video';
import PhotoVideoList from '../../../../components/UxProfile/Photos/PhotoVideoList/PhotoVideoList';
import { Col } from '../../../../components/UxCommon';
import videos from './Tutorials.constant';
import {Route, Switch} from "react-router-dom";
import {expandedPhotoDialogPath, expandedTutorialsDialogPath} from "../../../../store/actions/ui/routes";
import ExpandedPhotoDialog from "../../../../containers/UxProfile/Photos/ExpandedPhotoDialog";

// import PropTypes from 'prop-types';

class Tutorials extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  dummy = _ => _;

  shouldUpdate = () => {
  }

  render() {
    const photoVideos = videos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });

    const { match } = this.props;

    return (
      <div className="CoachDashboardPhotos WrapTutorials">
        <Switch>
          <Route
            path={expandedTutorialsDialogPath}
            render={props => (
              <ExpandedPhotoDialog photoList={photoVideos} {...props} isTutorials />
            )}
          />
        </Switch>
        <PhotoVideoList
          match={match}
          photosVideos={photoVideos}
          onAddPhotoVideoClick={this.dummy}
          selectAlbumDialogIsShowing={false}
          expandedDialogIsOpen={false}
          tagDialogIsShowing={false}
          photoList={photoVideos}
          isMyProfile={false}
          mySports={[]}
          onSaveFavorites={this.dummy}
          getPhotoVideoFavorites={this.dummy}
          isFilterApplied={false}
          onClearAllFiltersClick={this.dummy}
        />
      </div>
    );
  }
}

export default Tutorials;
