import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Redirect, Switch,matchPath } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

import { Routes, Sports } from '../../store/actions/ui/routes';
import PlayerProfile from '../../containers/UxProfile/PlayerProfile';
import Sidebar from '../../containers/UxProfile/PlayerCard';
import VcLogo from './Common/VcLogo/VcLogo';
import VCLogo_large from  '../../assets/images/VirtualCombine_logo_rev.png';
import GetProfile from '../../containers/UxCommon/Loaders/GetProfile';
import GetPlayerData from '../../containers/UxCommon/Loaders/GetPlayerData';
import GetAwards from '../../containers/UxCommon/Loaders/GetAwards';
import PlayerData from '../../containers/UxProfile/PlayerData/PlayerData';
import EditProfile from '../../containers/UxEditProfile/UxEditProfile';
import Photos from '../../containers/UxProfile/Photos/Photos';
import Awards from '../../containers/UxProfile/Awards/Awards';
import {YesNoModal, LoadingSpinner, CloseButton, ButtonRect} from '../UxCommon';
import TopBanner from '../../containers/UxProfile/TopBanner';
import BottomBanner from '../../containers/UxProfile/BottomBanner';
import ShareModal from '../UxCommon/ShareModal/ShareModal';
import PrivacyPage from '../UxCommon/ErrorPage/PrivacyPage';
import JoinTeamComponent from './JoinTeam/JoinTeam.component';
import { Roles } from '../../store/actions/data/user/profile/models';
import GetExternalProfile from '../../containers/UxProfile/ExternalProfile/GetExternalProfile';
import PrivateProfilePage from '../UxCommon/ErrorPage/PrivateProfilePage';
import ProfileActivationModal from './PlayerCard/CardContent/ActivateProfileMembership/ProfileActivationModal/ProfileActivationModal';
import { ATHLETE_COMPLETE_ONBOARDING } from '../Welcome/Invite/constants/Invite.constants';
import cssVar from "../App/App.utils";

class Profile extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    signOut: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    selectedUserSport: PropTypes.object,
    updateSportIdInStore: PropTypes.func.isRequired,
    isLogoutDialogShown: PropTypes.bool.isRequired,
    hideLogoutDialog: PropTypes.func.isRequired,
    profile: PropTypes.object,
    shareDialogUri: PropTypes.string.isRequired,
    onCloseShareDialogClick: PropTypes.func.isRequired,
    getProfile: PropTypes.func,
    isCoach: PropTypes.bool,
    checkIfCanEdit: PropTypes.func,
    canEditProfile: PropTypes.object,
    getExternalProfile: PropTypes.func,
    showActivateMembershipModal: PropTypes.any,
    toggleActivationMembershipModal: PropTypes.func,
    loggedIn: PropTypes.bool.isRequired,
  };

  state = {
    imageWidth: 0,
    imageHeight: 0,
    orgLogo: '',
    isCoachEnter: null,
  };

  componentDidMount() {
    const {
      updateSportIdInStore,
      getProfile,
      match,
      checkIfCanEdit,
      canEditProfile,
      getExternalProfile,
      isCoach,
    } = this.props;
    getProfile(match.params.uuid);
    getExternalProfile(match.params.uuid);
    updateSportIdInStore();
    if (
      !canEditProfile ||
      (canEditProfile && canEditProfile.playerUuid !== match.params.uuid)
    ) {
      checkIfCanEdit(match.params.uuid);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      updateSportIdInStore,
      checkIfCanEdit,
      canEditProfile,
      match,
      isCoach,
      getCoachData,
      coachUuid,
      profile,
    } = this.props;
    updateSportIdInStore();
    if (
      !canEditProfile ||
      (canEditProfile && canEditProfile.playerUuid !== match.params.uuid)
    ) {
      checkIfCanEdit(match.params.uuid);
    }
    if (isCoach && coachUuid && prevProps.coachUuid !== coachUuid) {
      getCoachData(coachUuid);
    }
    this.updateSchool();

    if(profile && match.params.schoolId){
      const schoolStyle = profile.schools.find(e => e.school_id === match.params.schoolId);
      const primaryColor = schoolStyle && schoolStyle.school.color1;
      const secondaryColor = schoolStyle && schoolStyle.school.color2;
      cssVar('primary', primaryColor);
      cssVar('secondary', secondaryColor);
    }

  }

  updateSchool = () => {
    const { imageWidth, isCoachEnter } = this.state;
    const { profile, match, isCoach, updateSchoolIdInStore } = this.props;
    if (!imageWidth) {
      const coachSelectedSchoolId = match.params.schoolId;
      const userSchools =
        profile &&
        profile.enrolledSchool &&
        profile.enrolledSchool.find(
          (userSchool) =>
            userSchool.user_school_team.school_id === coachSelectedSchoolId,
        );
      const schoolDetail = userSchools
        ? userSchools.user_school_team
        : profile && profile.latestSchool;
      const school = schoolDetail && schoolDetail.school;
      if (school && school.school_logo) {
        let img = new Image();
        img.src = school.school_logo;
        this.setState({
          imageWidth: img.width,
          imageHeight: img.height,
          orgLogo: school.school_logo,
        });
      } else {
        this.setState({
          orgLogo:
            'https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/virtual-combine-logo.png',
        });
      }
    }

    if (isCoachEnter == null) {
      if (
        isCoach &&
        match.params.hasOwnProperty('schoolId') &&
        match.params.schoolId
      ) {
        this.setState({ isCoachEnter: isCoach });
        updateSchoolIdInStore(match.params.schoolId);
      } else {
        this.setState({ isCoachEnter: isCoach });
        updateSchoolIdInStore('');
      }
    }
  };

  // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
  // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
  redirectToPlayerProfile = () => {
    const { uuid } = this.props;
    const visibleUuid = this.props.match.params.uuid || uuid;
    return (
      <Redirect to={`/${Routes.profile}/${visibleUuid}/${Routes.player}`} />
    );
  };

  setImage = () => {
    const { imageHeight, imageWidth, orgLogo } = this.state;
    let className = '';
    if (imageWidth > imageHeight) {
      className = 'Org_Logo_Width';
    } else {
      className = 'Org_Logo_Profile';
    }

    return (
      <img
        className={className}
        id={'schoolLogoImg'}
        src={orgLogo}
        alt={'Organization Logo'}
      />
    );
  };

  signInToUpdate = () => {
    const {history} = this.props;
    history.push({
      pathname: `/${Routes.welcome}/${Routes.login}`,
    });
  }

  renderPlayerProfile = (props) => {
    const {
      uuid,
      profile,
      canEditProfile,
      selectedUserSport,
      loggedIn,
      showActivateMembershipModal,
      toggleActivationMembershipModal,
    } = this.props;
    const sportId = selectedUserSport && selectedUserSport.sportId;
    if (canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }
    const { match } = props;
    const { schoolId } = match.params;
    const visibleUuid = match.params.uuid || uuid;
    if (uuid !== visibleUuid && profile && profile.privacy_all) {
      return <PrivacyPage />;
    }
    return (
      <GetProfile {...props}>
        {
          !loggedIn && profile && !!profile.registered && !!profile.isSubscribed && (
        <span className="mobile-header-signin" onClick={this.signInToUpdate}>Sign In</span>
          )
        }
        <Link
          className="UxProfile_VcLogo Org_Logo Org_Log_top_right"
          to={`/${Routes.profile}/${visibleUuid}/${Routes.player}/${
            Sports[sportId]
          }${schoolId ? `/${schoolId}` : ''}`}>
          {/* <VcLogo /> */}
          {this.setImage()}
        </Link>
        <PlayerProfile {...props} />
      </GetProfile>
    );
  };

  renderPlayerData = (props) => {
    const { uuid, profile, canEditProfile } = this.props;
    if (canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }
    const visibleUuid = props.match.params.uuid || uuid;
    if (
      visibleUuid !== uuid &&
      profile &&
      (profile.privacy_all || (profile.privacy_tests && profile.privacy_stats))
    ) {
      return <PrivacyPage />;
    }
    return (
      <GetPlayerData {...props}>
        <PlayerData {...props} />
      </GetPlayerData>
    );
  };

  renderMedia = (props) => {
    const { uuid, profile, canEditProfile } = this.props;
    if (!profile || canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }
    const visibleUuid = props.match.params.uuid || uuid;
    if (
      visibleUuid !== uuid &&
      profile &&
      (!profile.id || profile.privacy_media || profile.privacy_all)
    ) {
      return <PrivacyPage />;
    }
    return <Photos {...props} />;
  };

  renderAwards = (props) => {
    const { uuid, profile, canEditProfile } = this.props;
    if (!profile || canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }
    const visibleUuid = props.match.params.uuid || uuid;
    if (
      visibleUuid !== uuid &&
      profile &&
      (profile.privacy_awards || profile.privacy_all)
    ) {
      return <PrivacyPage />;
    }
    return (
      <GetAwards {...props}>
        <Awards {...props} />
      </GetAwards>
    );
  };

  renderExternalCompaniesProfile = (props) => {
    const { uuid, profile, canEditProfile } = this.props;
    if (!profile || canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }

    return <GetExternalProfile {...props} />;
  };

  renderJoinTeam = (props) => {
    const { uuid, profile, isCoach, canEditProfile } = this.props;

    if (!profile || canEditProfile === null) {
      return <LoadingSpinner loading loadingMsg="" />;
    }

    const visibleUuid = props.match.params.uuid || uuid;
    if (profile && (visibleUuid !== uuid || isCoach)) {
      return <PrivacyPage />;
    }

    return <JoinTeamComponent {...props} profile={profile} />;
  };

  redirectToCalendly = () => {
    window.location.href ='https://calendly.com/virtualcombine/virtual-combine-profile-consultation?back=1&month=2024-01';
  }

  render() {
    const {
      isLogoutDialogShown,
      hideLogoutDialog,
      signOut,
      match,
      profile,
      shareDialogUri,
      onCloseShareDialogClick,
      webLoadingProfile,
      uuid,
      selectedUserSport,
      isCoach,
      canEditProfile,
      showActivateMembershipModal,
      loggedIn,
      redirect,
      history,
      location,
      isCalendlyModalShown,
      updateCalendlyModal,
      isMyProfile,
    } = this.props;
    const visibleUuid = match.params.uuid || uuid;
    if (
      isMyProfile &&
      loggedIn &&
      profile &&
      profile.step &&
      profile.step !== ATHLETE_COMPLETE_ONBOARDING &&
      profile.uuid === match.params.uuid
    ) {
      history.push(`/${profile.onboardingUrl}`);
    }

    if (profile && !profile.id) {
      return <PrivacyPage />;
    }
    if (profile && profile.roleId === Roles.coach) {
      return <Redirect to={`/${Routes.coach}/${Routes.dashboard}`} />;
    }
    if (!webLoadingProfile && profile && profile.sports.length === 0) {
      const re = pathToRegexp(
        `/${Routes.profile}/:uuid/${Routes.edit}/:sport?`,
      );
      const params = re.exec(window.location.pathname);
      if (!params) {
        // Don't redirect to the same place
        return (
          <Redirect
            to={`/${Routes.profile}/${match.params.uuid}/${Routes.edit}`}
          />
        );
      }
    }

    let hasMembership = true;
    if (
      (profile && !profile.isPaidProfile) ||
      isCoach ||
      typeof isCoach !== 'boolean'
    ) {
      hasMembership = true;
    } else if (profile) {
      hasMembership = !!(
        profile &&
        profile.isPaidProfile &&
        profile.isSubscribed
      );
    }

    const isProfileLandingPage = !!matchPath(location.pathname, {
      path:`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`,
      exact: true,
    });


    const CalendlyModal = () => (
      <div className="CalendlyModal_Backdrop">
        <div className="CalendlyModal">
          <CloseButton size={15} onClick={()=> {
            updateCalendlyModal();
          }} />
          <div className="CalendlyModal_Header">
            <div className="logo"><img src={VCLogo_large} alt="VC-logo"/></div>
            <div className="header-title">Ready to Level Up?</div>
          </div>
          <div className="CalendlyModal_Body CalendlyModal_Body_Content">
           <div className="content-wrap">
             <p>Schedule a short consultation to discover the benefits of our 'Level Up' services designed to elevate your game. Virtual Combine 'Level Up' packages help you with:</p>
             <ol>
               <li>Maximizing your player profile resources, such as videos, utilizing the college coaches directory, data tracking, and more.</li>
               <li>Harnessing the power of social media to amplify your exposure.</li>
               <li>Learning efficient ways to distribute your data with college coaches through email, texting, and social media effectively.</li>
               <li>Understanding the pivotal role parents play through an athlete's journey.</li>
               <li>Strategizing to shape your athletic future and stand out from the competition.</li>
             </ol>
             <p>Our Virtual Combine staff comprises seasoned professionals with extensive backgrounds in collegiate and professional play, as well as coaching accolades. They are dedicated to your success.</p>
             <p>Once your athletic organization has enrolled you in VC software services, you're on the brink of unlocking your full potential. Take the next step by scheduling a meeting below to fine-tune your resources and craft a winning strategy for the future.</p>
           </div>
          </div>
          <div className="CalendlyModal_Footer">
            <div className="calendly-badge-widget" onClick={this.redirectToCalendly}>
              <div className="calendly-badge-content">
                Schedule your FREE demo<span>powered by Calendly</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    // let athleteOnboardingMail = profile.email ? profile.email : "";
    // let parentsEmail = profile.parentsEmail ? profile.parentsEmail : "";
    return (
      <div className="UxProfile">
        {
          isCalendlyModalShown && (
            <CalendlyModal />
          )
        }
        {!!showActivateMembershipModal && (
          <ProfileActivationModal profile={profile} />
        )}
        <TopBanner match={match} />
        <div className="UxProfile_Body">
          {(visibleUuid === uuid || (profile && !profile.privacy_all)) && (
            <Route
              path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`}
              component={Sidebar}
            />
          )}
          <YesNoModal
            show={isLogoutDialogShown}
            onClose={hideLogoutDialog}
            onNo={hideLogoutDialog}
            onYes={signOut}
            title="Sign Out"
            question="Are you sure you want to sign out of your account?"
            noTxt="No, Stay Signed In"
            yesTxt="Yes, Sign Out"
          />
          <ShareModal uri={shareDialogUri} onClose={onCloseShareDialogClick} />
          <div id="uxProfileRight" className={`UxProfile_Right ${isProfileLandingPage ? `UxProfile_page_main` : ''}`}>
            {hasMembership ? (
              <Switch>
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`}
                  render={this.renderPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`}
                  render={this.renderPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.data}/:sport?/:schoolId?`}
                  render={this.renderPlayerData}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.team}/:sport?/:schoolId?`}
                  render={this.renderTeamData}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/:schoolId?`}
                  component={EditProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.photos}/:sport?/:schoolId?`}
                  component={this.renderMedia}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.awards}/:sport?/:schoolId?`}
                  render={this.renderAwards}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.joinTeam}/:sport?/:schoolId?`}
                  render={this.renderPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.externalProfile}/:sport?/:schoolId?`}
                  render={this.renderExternalCompaniesProfile}
                />
              </Switch>
            ) : (
              <Switch>
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`}
                  render={this.renderPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.data}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.team}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.photos}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.awards}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.joinTeam}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
                <Route
                  path={`/${Routes.profile}/:uuid/${Routes.externalProfile}/:sport?/:schoolId?`}
                  render={this.redirectToPlayerProfile}
                />
              </Switch>
            )}
          </div>
        </div>
        <BottomBanner />
      </div>
    );
  }
}

export default Profile;
