import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { Routes, expandedPhotoDialogPath, awardsDialogTo } from '../../../../store/actions/ui/routes';
import { SecondaryIconButton, SecondaryIconLink } from '../../../UxCommon';

class TopBar extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    onShareClick: PropTypes.func.isRequired,
    profile: PropTypes.object,
  };

  onAddClick = () => {
    const { history, match } = this.props;
    history.push(awardsDialogTo(match.params.uuid, 'new', match.params.sport, match.params.schoolId));
  }

  getRoute = (page) => {
    const { match } = this.props;
    const { sport, schoolId } = match.params;
    return `/${Routes.profile}/${match.params.uuid}/${page}${sport ? `/${sport}` : ''}${schoolId? `/${schoolId}`: ''}`;
  };

  renderHoverlay = () => (
    <div className="UxProfile_Photos_TopBar_BtnsHoverlay" />
  )

  signInToUpdate = () => {
    const {history} = this.props;
    history.push({
      pathname: `/${Routes.welcome}/${Routes.login}`,
    });
  }

  render() {
    const {
      isMyProfile,
      onShareClick,
      profile,
    } = this.props;
    return (
      <div className="Awards_TopBar">
        <div className="Awards_TopBar_Title">
          Combine Awards
        </div>
        <div className="Awards_TopBar_BtnLayer">
          <Route
            path={expandedPhotoDialogPath}
            render={this.renderHoverlay}
          />
          <div className="UxProfile_Photos_TopBar_Btns">
            <div className="d-flex" style={{ marginRight: 'auto' }}>
              <SecondaryIconLink iconName="back-arrow" to={this.getRoute(Routes.player)}>
                <span className="hidden-lg-up">To Profile</span>
                <span className="hidden-md-down"> {isMyProfile ? 'Back to My Profile' : 'Back to Profile'}</span>
              </SecondaryIconLink>
              <SecondaryIconButton className="Photos_TopBar_ShareBtn" iconName="share" onClick={onShareClick}>
                Share
              </SecondaryIconButton>
            </div>
            {
              isMyProfile && (
                <React.Fragment>
                  {/* <SecondaryIconButton iconName="plus" onClick={onHideClick}>
                    Hide
                  </SecondaryIconButton> */}
                  <SecondaryIconButton iconName="plus" onClick={this.onAddClick}>
                    Add
                  </SecondaryIconButton>
                </React.Fragment>
              )
            }
            {
              !isMyProfile && profile && !!profile.registered && !!profile.isSubscribed && (
                <SecondaryIconButton
                  className="Photos_TopBar_signInBtn" iconName="login-1" onClick={this.signInToUpdate}>
                  Sign In<span> to Update</span>
                </SecondaryIconButton>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
