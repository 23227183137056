import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import moment from 'moment';

import Urls from '../../../../config/urls';
import { Team } from './teams/models';

export const STATS_GET_STD_SUC = 'sport.stats.getStdSuccess';
export const statsGetStandardSuccess = (std, stdFormats) => ({
  type: STATS_GET_STD_SUC,
  std,
  stdFormats,
});

export const STATS_GET_USER_STATS_SUC = 'sport.stats.getUserStatsSuccess';
export const statsGetUserStatsSuccess = (uuid, sportId, stats, allTeams) => ({
  type: STATS_GET_USER_STATS_SUC,
  uuid,
  sportId,
  stats,
  allTeams,
});
export const STATS_DEL_SEASON_SUC = 'sport.stats.deleteSeason.success';
export const statsDeleteSeasonSuccess = (positionId, seasonId) => ({
  type: STATS_DEL_SEASON_SUC,
  positionId,
  seasonId,
});

export const statsStandardPositionsGet = statsId =>
  ajax.getJSON(Urls.standardPositions(statsId));
export const statsStandardFormatsGet = () =>
  ajax.getJSON(Urls.standardStatsFormat());
export const statsStandardStatCategoriesGet = statsId =>
  ajax.getJSON(Urls.standardStatCategories(statsId));
export const statsStdStatsByCategoryGet = (sportId, stdStatCategoryId) =>
  ajax.getJSON(Urls.standardStatsByCategory(sportId, stdStatCategoryId));
export const statsStdStatsGet = sportId =>
  ajax.getJSON(Urls.standardStats(sportId));
export const statsStandardStatPositionsGet = sportId =>
  ajax.getJSON(Urls.standardStatPositions(sportId));
export const statsSeasonsGet = (uuid, statsId) =>
  ajax.getJSON(Urls.seasons(uuid, statsId));
export const statsUserStatsGet = (seasonId, uuid) =>
  ajax.getJSON(Urls.userStats(seasonId, uuid));
export const statsTeamsGet = () =>
  ajax.getJSON(Urls.teams.getAll());
export const statsPlayerStatsGet = (uuid, sportId, token) =>
  ajax.getJSON(
    Urls.playerStats(uuid, sportId),
    {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );
export const statsUserStatCreate = (seasonId, apiStat, token) =>
  ajax.post(
    Urls.userStat(seasonId),
    apiStat,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const statsUserStatUpdate = (seasonId, userStatId, apiStat, token) =>
  ajax.put(
    Urls.userStat(seasonId, userStatId),
    apiStat,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const statsUserStatDelete = (seasonId, userStatId, token) =>
  ajax.delete(
    Urls.userStat(seasonId, userStatId),
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const statsSeasonCreate = (apiSeason, token) =>
  ajax.post(
    Urls.season(),
    apiSeason,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const statsSeasonUpdate = (apiSeason, seasonId, token) =>
  ajax.put(
    Urls.season(seasonId),
    apiSeason,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const statsSeasonDelete = (seasonId, token) =>
  ajax.delete(
    Urls.season(seasonId),
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

class StdPosition {
  constructor() {
    this.id = 0;
    this.sportId = 0;
    this.code = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.sportId = response.sport_id;
    this.code = response.code;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}

class StdStatCategory {
  constructor() {
    this.id = 0;
    this.sportId = 0;
    this.code = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.sportId = response.sport_id;
    this.code = response.code;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}

class StdStat {
  constructor() {
    this.id = 0;
    this.stdCategory = null;
    this.code = '';
    this.computed = false;
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.standardStatFormatId = 0;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.computed = response.computed_bool;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
    this.standardStatFormatId = response.standard_stat_format_id;
  }

  setStdCategory(stdCategory) {
    this.stdCategory = stdCategory;
  }
}

class StdStatPosition {
  constructor() {
    this.id = 0;
    this.stdPosition = null;
    this.stdStat = null;
    this.createdAt = '';
    this.updatedAt = '';
    this.bigLeagueCardBool = false;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.bigLeagueCardBool = response.big_league_card_bool;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  setStdPosition(stdPosition) {
    this.stdPosition = stdPosition;
  }

  setStdStat(stdStat) {
    this.stdStat = stdStat;
  }
}

export class Season {
  constructor() {
    this.id = 0;
    this.team = null;
    this.userId = '';
    this.name = '';
    this.startDate = null;
    this.stopDate = null;
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.teamId = response.teamId;
    this.userId = response.aws_uuid;
    this.name = response.name;
    this.startDate = moment(response.start_dt);
    this.stopDate = moment(response.stop_dt);
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  fromApiPost(response) {
    this.id = response.id;
    this.teamId = response.team.id;
    this.userId = response.aws_uuid;
    this.name = response.name;
    this.startDate = moment(response.start_dt);
    this.stopDate = moment(response.stop_dt);
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  fromPlayerStatsRoute(response) {
    this.id = response.id;
    this.teamId = response.team.id;
    this.userId = response.aws_uuid;
    this.name = response.name;
    this.startDate = moment(response.start_dt);
    this.stopDate = moment(response.stop_dt);
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  toApiPost(uuid=null) {
    if (uuid) {
      return {
        aws_uuid: uuid,
        name: this.name,
        start_dt: moment(this.startDate).format('YYYY-MM-DD'),
        stop_dt: moment(this.stopDate).format('YYYY-MM-DD'),
        team_id: this.team.id,
      };
    }
    return {
      name: this.name,
      start_dt: moment(this.startDate).format('YYYY-MM-DD'),
      stop_dt: moment(this.stopDate).format('YYYY-MM-DD'),
      team_id: this.team.id,
    };
  }

  setTeam(team) {
    this.team = team;
  }
}

class UserStat {
  constructor() {
    this.id = 0;
    this.userId = '';
    this.season = null;
    this.results = 0;
    this.stdStatPosition = null;
    this.stdPosition = null;
    this.stdStat = null;
    this.stdCategory = null;
    this.createdAt = '';
    this.updatedAt = '';
    this.onCard = false;
    this.computed = false;
  }

  fromPlayerStatsRoute(response, uuid, stdCats, stdStatPositions) {
    this.id = response.user_season_stat_id;
    this.onCard = response.on_card;
    this.computed = response.computed;
    this.userId = uuid;
    this.statFormatCode = response.stat_format_code;
    this.results = response.result;
    this.stdStatPosition = stdStatPositions
      .find(sp => ((sp.stdStat.id === response.stat_id)
      && (sp.stdPosition.id === response.position_id)));
    this.stdPosition = this.stdStatPosition.stdPosition;
    this.totalType = response.total_type;
    this.stdStat = this.stdStatPosition.stdStat;
    this.stdCategory = stdCats.find(c => c.id === response.category_id);
  }

  setSeason(season) {
    this.season = season;
  }

  setStdPosition(stdPosition) {
    this.stdPosition = stdPosition;
  }

  setStdStat(stdStat) {
    this.stdStat = stdStat;
  }

  setStdStatPosition(stdStatPosition) {
    this.stdStatPosition = stdStatPosition;
  }
}

export const mapStdPositionsApiToUi = response => (
  response.map((apiPosition) => {
    const pos = new StdPosition();
    pos.fromApiResponse(apiPosition);
    return pos;
  })
);

export const mapStdStatCategoriesApiToUi = response => (
  response.map((apiPosition) => {
    const cat = new StdStatCategory();
    cat.fromApiResponse(apiPosition);
    return cat;
  })
);

export const mapStandardStatsApiToUi = (apiStdStats, categories) => {
  const stats = apiStdStats.map((apiStdStat) => {
    const stat = new StdStat();
    stat.fromApiResponse(apiStdStat);
    const category = categories.find(c => c.id === apiStdStat.standard_stat_category_id);
    stat.setStdCategory(category);
    return stat;
  });
  return stats;
};

export const mapStdStatPositionsApiToUi = (stats, positions, rawStatPositions) => (
  rawStatPositions.map((rawStatPos) => {
    const statPos = new StdStatPosition();
    statPos.fromApiResponse(rawStatPos);
    statPos.setStdPosition(positions.find(p => p.id === rawStatPos.standard_position_id));
    statPos.setStdStat(stats.find(s => s.id === rawStatPos.standard_stat_id));
    return statPos;
  })
);

export const mapSeasonsApiToUi = (response) => {
  const teamIds = new Set();
  const teams = {};
  const seasons = [];
  response.forEach((apiSeason) => {
    // team
    const apiTeam = apiSeason.team;
    let team;
    if (!teamIds.has(apiTeam.id)) {
      teamIds.add(apiTeam.id);
      team = new Team();
      team.fromApiResponse(apiTeam);
      teams[team.id] = team;
    } else {
      team = teams[apiTeam.id];
    }
    // season
    const season = new Season();
    season.fromApiResponse(apiSeason);
    season.setTeam(team);
    seasons.push(season);
  });
  return { teams: Object.values(teams), seasons };
};

export const mapSeasonUserStatsApiToUi = (
  apiUserStats,
  season,
  statPositions,
) => {
  const seasonUserStats = apiUserStats.map((apiUserStat) => {
    const userStat = new UserStat();
    userStat.fromApiResponse(apiUserStat);
    userStat.setSeason(season);
    userStat.setStdStatPosition(statPositions.find(sp =>
      sp.id === apiUserStat.standard_stat_position_id));
    return userStat;
  });
  return seasonUserStats;
};

export const mapSeasonUiToApi = form => ({
  name: form.name,
  start_dt: moment(form.startDate).format('YYYY-MM-DD'),
  stop_dt: moment(form.stopDate).format('YYYY-MM-DD'),
  team_id: form.teamId,
});

export const mapUserStatUiToApi = (form, uuid=null) => {
  if (uuid) {
    return ({
      aws_uuid: uuid,
      result: form.results,
      standard_stat_position_id: form.statPosId,
    });
  }
  return ({
    result: form.results,
    standard_stat_position_id: form.statPosId,
  });
}

export const mapPlayerStatsApiToUi = (
  uuid,
  categories,
  statPositions,
  seasons,
  playerStats,
) => {
  const userStats = [];
  playerStats.forEach((sportObj) => {
    sportObj.season_stats.forEach((seasonObj) => {
      const seasonId = seasonObj.season.id;
      seasonObj.stats.forEach(({ stat }) => {
        const userStat = new UserStat();
        userStat.fromPlayerStatsRoute(stat, uuid, categories, statPositions);
        userStat.setSeason(seasons.find(s => s.id === seasonId));
        userStats.push(userStat);
      });
    });
  });
  return userStats;
};
