import { asyncStartAction } from '../../../../store/actions/ui/async';

export const GET_REPORT = 'report.getReport';
export const getReport = reportId => (dispatch) => {
  dispatch(asyncStartAction(GET_REPORT));
  dispatch({
    type: GET_REPORT,
    reportId,
    dispatch,
  });
};

export const GET_REPORT_RESULTS = 'report.getReportResults';
export const getReportResults = reportId => (dispatch) => {
  dispatch(asyncStartAction(GET_REPORT_RESULTS));
  dispatch({
    type: GET_REPORT_RESULTS,
    reportId,
  });
};

export const GET_TEAM_REPORT = 'report.getTeamReport';
export const getTeamReport = (reportId, teamId) => (dispatch) => {
  dispatch(asyncStartAction(GET_TEAM_REPORT));
  dispatch({
    type: GET_TEAM_REPORT,
    reportId,
    teamId,
  });
};

export const GET_ATHLETE_REPORT = 'report.getAthleteReport';
export const getAthleteReport = (reportId, athleteId) => (dispatch) => {
  dispatch(asyncStartAction(GET_ATHLETE_REPORT));
  dispatch({
    type: GET_ATHLETE_REPORT,
    reportId,
    athleteId,
  });
};

export const GET_REPORTS = 'report.getReports';
export const getReports = schoolIds => (dispatch) => {
  dispatch(asyncStartAction(GET_REPORTS));
  dispatch({
    type: GET_REPORTS,
    schoolIds,
  });
};

export const CREATE_REPORT = 'report.createReport';
export const createReport = (schoolId, combineId, secondCombineId) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_REPORT));
  dispatch({
    type: CREATE_REPORT,
    schoolId,
    combineId,
    secondCombineId,
  });
};
