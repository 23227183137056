
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const TooltipIcon = ({
  className, iconName, style, tooltip, offset, tooltipClass, tooltipPlace, tooltipEffect,
}) => (
  <React.Fragment>
    <i
      className={[`icon-${iconName}-icon`, 'CoachIcon', className].join(' ')}
      style={style || {}}
      data-tip={tooltip}
      data-offset={offset}
      data-place={tooltipPlace || 'top'}
      data-effect={tooltipEffect || 'solid'}
      data-class={['customTooltipClass', tooltipClass].join(' ')}
    />
    <ReactTooltip html={true} />
  </React.Fragment>
);

TooltipIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  style: PropTypes.object,
  tooltip: PropTypes.string.isRequired,
  offset: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipPlace: PropTypes.string,
  tooltipEffect: PropTypes.string,
};

export default TooltipIcon;
