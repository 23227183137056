import accepts from 'attr-accept';

export * from './validators';
export * from './cognito';
export * from './playerCard';
export * from './messages';
import moment from 'moment';

export const isMobileApp =
  typeof navigator != 'undefined' && navigator.product == 'ReactNative';
export const supportMultiple =
  typeof document !== 'undefined' && document && document.createElement
    ? 'multiple' in document.createElement('input')
    : true;

export function getDataTransferItems(event) {
  let dataTransferItemsList = [];

  if (event.dataTransfer) {
    const dt = event.dataTransfer;
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files;
  }
  return Array.prototype.slice.call(dataTransferItemsList);
}

export function fileAccepted(file, accept) {
  return file.type === 'application/x-moz-file' || accepts(file, accept);
}

export function fileMatchSize(file, maxSize, minSize) {
  return file.size <= maxSize && file.size >= minSize;
}

export function allFilesAccepted(files, accept) {
  return files.every((file) => fileAccepted(file, accept));
}

export function onDocumentDragOver(evt) {
  evt.preventDefault();
}

function isIe(userAgent) {
  return (
    userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1
  );
}

function isEdge(userAgent) {
  return userAgent.indexOf('Edge/') !== -1;
}

export function isIeOrEdge(userAgent = window.navigator.userAgent) {
  return isIe(userAgent) || isEdge(userAgent);
}

// Photos

export function dataURItoBlob(dataURI, type = 'image/jpeg') {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type });
}

export function getCroppedImg(image, pixelCrop) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const width = img.width * (pixelCrop.width / 100);
      const height = img.height * (pixelCrop.height / 100);
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(
        img,
        img.width * (pixelCrop.x / 100),
        img.height * (pixelCrop.y / 100),
        width,
        height,
        0,
        0,
        width,
        height,
      );

      document.body.appendChild(canvas);

      const dataUrl = canvas.toDataURL('image/jpeg');
      const blobData = dataURItoBlob(dataUrl);
      resolve(blobData);
    };
    img.src = image;
  });
}

export const formatPhone = (phone) => {
  let country = '';
  let area = '';
  let part1 = '';
  let part2 = '';
  let numbers = '';
  if (phone) {
    numbers = phone.replace(/[^0-9]+/g, '');
    if (phone.slice(0, 2) === '+1') {
      numbers = numbers.slice(1);
    }
    if (numbers.length >= 1) {
      country = '+1';
      area = ` (${numbers.slice(0, 3)})`;
    }
    if (numbers.length >= 3) {
      part1 = ` ${numbers.slice(3, 6)}`;
    }
    if (numbers.length >= 6) {
      part2 = `-${numbers.slice(6)}`;
    }
    if (numbers.length > 10 && numbers.length <= 13) {
      country = `+${numbers.slice(0, numbers.length - 10)}`;
      area = ` (${numbers.slice(numbers.length - 10, numbers.length - 7)})`;
      part1 = ` ${numbers.slice(numbers.length - 7, numbers.length - 4)}`;
      part2 = `-${numbers.slice(numbers.length - 4)}`;
    }
    return country + area + part1 + part2;
  }
  return '';
};

export const formatBirthday = (
  birthday,
  fromFormat = 'MM/DD/YYYY',
  toFormat = 'YYYY-MM-DD',
) => {
  let dateWrapper = new moment(birthday, fromFormat);
  if (dateWrapper.isValid()) {
    return dateWrapper.format(toFormat);
  }
  return '';
};

export const copyToClipboard = (str) => {
  const el = document.createElement('input');
  el.value = str;
  el.contentEditable = true;
  el.readOnly = false;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(el);
    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);
    document.execCommand('copy');
  } else {
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }
  document.body.removeChild(el);
};

export const privacyType = {
  ALL: 'privacy_all',
  ACADEMIC: 'privacy_academic',
  STATS: 'privacy_stats',
  TESTS: 'privacy_tests',
  MEDIA: 'privacy_media',
  AWARDS: 'privacy_awards',
  ENDORSEMENTS: 'privacy_endorsements',
  NETWORK: 'privacy_network',
  WEIGHT: 'privacy_weight',
  HEIGHT: 'privacy_height',
  RECORDS: 'privacy_combine_records',
  BIO: 'privacy_bio',
  LEADERBOARD:'privacy_leaderboard'
};

export const privacyTypeArr = [
  'privacy_all',
  'privacy_academic',
  'privacy_stats',
  'privacy_tests',
  'privacy_media',
  'privacy_awards',
  'privacy_endorsements',
  'privacy_network',
  'privacy_weight',
  'privacy_height',
  'privacy_combine_records',
  'privacy_bio',
];

export const reloadDataSeconds = 60;
