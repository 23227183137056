import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import moment from 'moment';
import { ButtonRect } from '../../../../components/UxCommon';
import TextControl from '../../common/FormControls/TextControl';
import Calendar from '../../common/Calendar';
import SelectControl2 from '../../common/FormControls/SelectControl/SelectControl.component';
import { isEmail } from '../../../../utils';
import { parserTelephone } from '../../../../utils/parsers';
import { phoneHelpMsg } from '../../../../store/reducers/ui/welcome/utils';

const dateFormat = 'MM.DD.YY';
const genders = {
  male: 'Male',
  female: 'Female',
};
class CoachProfileForm extends PureComponent {
  static propTypes = {
    coachProfile: PropTypes.object,
    saveCoach: PropTypes.func,
  }

  state = {
    errors: {},
    form: {},
    showDobCalendar: false,
    dobDate: '',
    phone: '',
    focus: false,
    help: '',
    hobbiesHelp: 'Hobbies are comma separated (e.g., Football, Music, Reading)',
    hobbyFocus: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onValueChange = name => (value) => {
    const errors = {};
    let adjustedValue;
    switch (name) {
      case 'firstName':
        if (!value) {
          errors.firstName = ['This field is required'];
        } else {
          errors.firstName = [];
        }
        adjustedValue = value;
        break;
      case 'lastName':
        if (!value) {
          errors.lastName = ['This field is required'];
        } else {
          errors.lastName = [];
        }
        adjustedValue = value;
        break;
      case 'dobDate': {
        const dateValue = moment(value, 'MM.DD.YY');
        const isValid = dateValue.isValid();

        if (!isValid) {
          errors.dobDate = ['Please enter a valid date (01.30.19)'];
        } else {
          errors.dobDate = [];
          this.setState({ dobDate: value });
        }
        adjustedValue = value;
        break;
      }
      case 'email': {
        const isValid = isEmail(value);
        if (!value) {
          errors.email = ['This field is required'];
        }
        if (!isValid) {
          errors.email = ['Please enter a valid e-mail address'];
        } else {
          errors.email = [];
        }
        adjustedValue = value;
        break;
      }
      case 'phone': {
        const parsedVal = parserTelephone(value);
        adjustedValue = parsedVal;
        const help = phoneHelpMsg(adjustedValue);
        this.setState({
          help,
          phone: adjustedValue,
        });
        break;
      }
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      form: Object.assign({}, this.state.form, errors),
    });
    return adjustedValue;
  }

  onClickSaveCoach = (e) => {
    const form = this.formRef.current;
    const values = {};
    const { saveCoach, coachProfile } = this.props;
    values.given_name = form.firstName.value;
    values.family_name = form.lastName.value;
    values.birthday = moment(form.dobDate.value, 'MM.DD.YY').format('YYYY-MM-DD');
    values.email = form.email.value;
    values.gender = form.gender.value;
    values.phone = form.phone.value;
    values.coach_position = form.position.value;
    values.coach_hobbies = form.hobbies.value;
    values.coach_career_coaching = form.coachingYears.value;
    values.coach_career_playing = form.playingYears.value;
    values.coach_college = form.college.value;
    values.coach_high_school = form.highSchool.value;
    values.coach_spouse = form.spouse.value;
    values.coach_kids = form.kids.value;
    // values.twitter = form.twitter.value;
    // values.instagram = form.instagram.value;
    // values.coach_title = form.coachTitle.value;
    // values.coach_secondary_email = form.coachSecondaryEmail.value;
    // values.coach_secondary_phone = form.coachSecondaryPhone.value;
    // values.organization_website = form.organizationWebsite.value;
    values
    if (Object.values(this.state.errors).reduce((x, y) => x + y.length, 0) === 0) {
      saveCoach(values, coachProfile.uuid);
    }
    e.preventDefault();
  }

  onDobDateChanged = (day) => {
    const dateString = dateFns.format(day, dateFormat);
    this.setState({
      showDobCalendar: false,
      dobDate: dateString,
    }, () => {
      this.onValueChange('dobDate')(dateString);
    });
  }

  onDobDateTextChanged = (e) => {
    const dobDate = e.target.value;
    this.setState({
      dobDate,
      showDobCalendar: false,
    }, () => {
      this.onValueChange('dobDate')(dobDate);
    });
  }

  setDobDateCalendarRef = (node) => {
    this.dobDateCalendarRef = node;
  }

  dobDateCalendarClicked = () => {
    this.setState({ showDobCalendar: !this.state.showDobCalendar });
  }

  handleClickOutside = (event) => {
    if (this.state.showDobCalendar && !this.dobDateCalendarRef.contains(event.target)) {
      this.setState({ showDobCalendar: false });
    }
  }

  handleFocus = () => {
    this.setState({
      focus: true,
    });
  }

  handleBlur = () => {
    this.setState({
      focus: false,
    });
  }

  handleHobbyFocus = () => {
    this.setState({
      hobbyFocus: true,
    });
  }

  handleHobbyBlur = () => {
    this.setState({
      hobbyFocus: false,
    });
  }

  formRef = React.createRef();

  render() {
    const { coachProfile } = this.props;
    const {
      errors,
      showDobCalendar,
      dobDate,
      phone,
      focus,
      help,
      hobbiesHelp,
      hobbyFocus,
    } = this.state;
    let defaultDobDate = moment().format('MM.DD.YY');
    if (coachProfile.birthdate) {
      defaultDobDate = moment(coachProfile.birthdate).format('MM.DD.YY');
    }
    return (
      <div className="CoachAccount_BodyRight">
        {
          coachProfile &&
          (
            <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachAccount_Form" >
              <TextControl
                className="CoachAccount_Ctrl"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                myOnChange={this.onValueChange('firstName')}
                myOnBlur={this.onValueChange('firstName')}
                defaultValue={coachProfile.first}
                errors={errors.firstName}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                defaultValue={coachProfile.last}
                myOnChange={this.onValueChange('lastName')}
                myOnBlur={this.onValueChange('lastName')}
                errors={errors.lastName}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="email"
                label="Email"
                placeholder="E-Mail"
                defaultValue={coachProfile.email}
                myOnChange={this.onValueChange('email')}
                myOnBlur={this.onValueChange('email')}
                type="email"
                errors={errors.email}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="phone"
                label="Phone"
                placeholder="Phone"
                type="phone"
                help={help}
                helpClass="CoachAccount_Ctrl_Help left"
                focus={focus}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                defaultValue={coachProfile.phone}
                myOnChange={this.onValueChange('phone')}
                myOnBlur={this.onValueChange('phone')}
                value={phone}
              />
              <div
                ref={this.setDobDateCalendarRef}
                className="CoachAccount_Ctrl"
              >
                <TextControl
                  defaultValue={defaultDobDate}
                  name="dobDate"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  myOnChange={this.onValueChange('dobDate')}
                  myOnBlur={this.onValueChange('dobDate')}
                  errors={errors.dobDate}
                  iconName="calendar"
                  onClick={this.dobDateCalendarClicked}
                  autoComplete="false"
                  onChange={this.onDobDateTextChanged}
                  value={dobDate}
                />
                {showDobCalendar &&
                  <Calendar
                    onDateChanged={this.onDobDateChanged}
                    selectedDate={dobDate}
                    className="CoachCalendar"
                  />}
              </div>
              <SelectControl2
                name="gender"
                label="Gender"
                options={genders}
                myOnChange={this.onValueChange('gender')}
                myOnBlur={this.onValueChange('gender')}
                defaultValue={coachProfile.gender}
                style={{ zIndex: 3 }}
                className="CoachAccount_Ctrl"
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="position"
                label="Email"
                placeholder="Email"
                defaultValue={coachProfile.coachPosition || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="hobbies"
                label="Organization Website"
                placeholder="Organization Website"
                defaultValue={coachProfile.coachHobbies || ''}
                helpClass="CoachAccount_Ctrl_Help left"
                focus={hobbyFocus}
                onFocus={this.handleHobbyFocus}
                onBlur={this.handleHobbyBlur}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="coachingYears"
                label="Instagram Handle"
                placeholder="Instagram Handle"
                defaultValue={coachProfile.coachCareerCoaching || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="playingYears"
                label="Twitter Handle"
                placeholder="Twitter Handle"
                defaultValue={coachProfile.coachCareerPlaying || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="college"
                label="Phone Number"
                placeholder="Phone Number"
                defaultValue={coachProfile.coachCollege || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="highSchool"
                label="Title (Director, Coach, Recruiting Coordinator, etc.)"
                placeholder="Title (Director, Coach, Recruiting Coordinator, etc.)"
                defaultValue={coachProfile.coachHighSchool || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="spouse"
                label="N/A"
                placeholder="N/A"
                defaultValue={coachProfile.coachSpouse || ''}
              />
              <TextControl
                className="CoachAccount_Ctrl"
                name="kids"
                label="N/A"
                placeholder="N/A"
                defaultValue={coachProfile.coachKids || ''}
              />
              <div className="CoachAccount_Btns">
                <ButtonRect
                  className="Photos_ExpandedPhotoDialog_Button primary"
                  style={{ width: '120px' }}
                  type="submit"
                  onClick={this.onClickSaveCoach}
                >
                  Save
                </ButtonRect>
              </div>
            </form>
          )
        }
      </div>
    );
  }
}

export default CoachProfileForm;
