import {
  PHOTO_GET_MEDIA_SUC,
  PHOTO_UPLOAD_SUC,
  ALBUM_CREATE_SUC,
  ALBUM_UPDATE_SUC,
  PHOTO_RESIZE_SUC,
  PHOTO_UPDATE_SUC,
  MARK_PHOTO_FAVORITE_SUC,
  PHOTO_DELETE_SUC,
  MULTI_DELETE_SUC,
  DELETE_ALBUM_EVERYTHING_SUC,
} from '../../../actions/data/photo';
import { ASYNC_ERROR,ASYNC_FINISH } from '../../../actions/ui/async';
import { CREATE_ALBUM, UPDATE_ALBUM, UPDATE_ATHLETE_VIDEOID } from '../../../actions/ui/uxProfile/photosVideos/actions';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../../actions/data/cognito';

const photos = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === UPDATE_ATHLETE_VIDEOID){
        return Object.assign({}, state, {
          [action.data]: [],
        });
      }
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.photos,
      });
    case PHOTO_UPLOAD_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [...state[action.uuid], action.photo],
      });
    case ALBUM_CREATE_SUC:
      if (action.photos.length > 0 && action.uuid !== '') {
        return Object.assign({}, state, {
          [action.uuid]: [...state[action.uuid], ...action.photos],
        });
      }
      return state;
    case ALBUM_UPDATE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(photo =>
            (!action.photos.find(p => p.id === photo.id))),
          ...action.photos,
        ],
      });
    case PHOTO_RESIZE_SUC:
    case PHOTO_UPDATE_SUC:
    case MARK_PHOTO_FAVORITE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(photo => (photo.id !== action.photo.id)),
          action.photo,
        ],
      });
    case PHOTO_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(photo => (photo.id !== action.mediaId)),
      });
    case MULTI_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(photo => (action.ids.indexOf(photo.id) < 0)),
      });
    case DELETE_ALBUM_EVERYTHING_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(photo => (action.photoIds.indexOf(photo.id) < 0)),
      });
    case ASYNC_ERROR:
      if ((action.model === UPDATE_ALBUM || action.model === CREATE_ALBUM) &&
        (action.desc === 'pollResized')) {
        const tempObject = Object.assign(
          {}, state[action.data.uuid].find(photo => (photo.id === action.data.photoId)),
          { isTimedOut: true },
        );
        return Object.assign({}, state, {
          [action.data.uuid]: [
            ...state[action.data.uuid].filter(photo => (photo.id !== action.data.photoId)),
            tempObject,
          ],
        });
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default photos;
