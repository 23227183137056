import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../../components/UxProfile/PlayerData/Stats/StatsRow/StatsRow';
import {
  showDeleteSeasonDialog,
  statsSeasonRowFormModel,
  statsSeasonRowFormObject,
} from '../../../store/actions/ui/uxPlayerData';

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onDeleteSeasonClick: () => {
      const { team, position, season } = ownProps;
      dispatch(showDeleteSeasonDialog(team.id, position.id, season));
    },
    getRrfFormModel: () => {
      const { team, position, season } = ownProps;
      return statsSeasonRowFormModel(
        team.id,
        position.id,
        season.id,
      );
    },
    getRrfFormObject: () => {
      const { team, position, season } = ownProps;
      return statsSeasonRowFormObject(
        state,
        team.id,
        position.id,
        season.id,
      );
    },
    rrfFormChange: (model, value) => {
      dispatch(actions.change(model, value));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
