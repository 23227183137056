
export const recordNotFoundMsg = {
  combines: 'Let\'s create a combine!',
  reports: 'No report have been generated yet.\n'
}

export const authorizedToDelete = {
  combineTemplate: "You are not authorized to delete this combine template because you did not create this combine template",
}

export const authorizedToDeleteSport = {
  coachSport: "This sport is added by the coach. Only coach can delete this sport.",
  athleteDeleteSportMessage: `Oops! Unfortunately, you cannot delete the sport from your profile because
                        your coach has you associated with a team. If needed, your coach can remove
                        you from the team, so you can delete the sport. `,
  coachDeleteSportMessage: `Oops! Unfortunately, since your athlete is part of a team, you cannot delete the sport from
                       the athlete’s profile, but there is a quick fix.To delete the sport for the athlete, you
                       need first to remove the player from the team and then remove the sport.`
}
