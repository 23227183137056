import { createSelector } from 'reselect';
import { getCurrentUuid, sortByNewestCreated, sortByUpdatedAt } from '../../containers/UxCommon/Utils';
import { isMobileApp } from '../../utils';

const filterOutExpicitPhotos = photos =>
  (photos ? photos.filter(photo => !(photo.resizedPhotos &&
    (photo.resizedPhotos.length === 0) &&
    photo.explicitTags)) : null);

const getAlbums = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return state.data.photo.albums[uuid] || [];
};

export const getAlbumRelMedia = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return state.data.photo.albumRelMediaCollection[uuid] || [];
};

const getSelectedMediaId = (state, ownProps) => {
  if (ownProps.match) {
    if (ownProps.match.params.mediaId) {
      return ownProps.match.params.mediaId;
    }
  }
  if (isMobileApp) {
    const media = state.ui.uxProfile.photosVideos.expandedDialog.selectedMedia;
    return media ? media.id : null;
  }
  return null;
};

const getTags = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  let tags = state.data.photo.tags[uuid] || [];
  tags = tags.slice();
  tags.sort(sortByUpdatedAt);
  return tags;
};

const getTagRelMedia = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return state.data.photo.tagRelMediaCollection[uuid] || [];
};

const getPhotoList = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  let photos = state.data.photo.photos[uuid] || [];
  photos = filterOutExpicitPhotos(photos);
  return photos;
};

const getVideoList = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  const videos = state.data.photo.videos[uuid] || [];
  if (isMobileApp) {
    // videos = videos.filter(v => !!v.hlsUrl);
  }
  return videos;
};

const getAlbumFilterList = (state) => {
  const albDialog = state.ui.uxProfile.photosVideos.albumDialog.albumToEdit;
  const { isShowing } = state.ui.uxProfile.photosVideos.albumDialog;
  if (isShowing && albDialog === null) {
    return -1;
  }
  const selectedAlbFilters = state.ui.uxProfile.photosVideos.selectedAlbumFilters;
  return (albDialog ? [albDialog.id] : selectedAlbFilters);
};

const getTagsFilterList = state => state.ui.uxProfile.photosVideos.selectedTagsFilters;

const getSelectedCategory = state => state.ui.uxProfile.photosVideos.selectedCategory;

export const makeGetAlbumsState = () => createSelector(
  getAlbums,
  (albums) => {
    const albumsSliced = (albums ? albums.slice() : []);
    albumsSliced.sort(sortByUpdatedAt);
    return albumsSliced;
  },
);

export const makeGetSelectedAlbumState = () => createSelector(
  getAlbums,
  getAlbumRelMedia,
  getSelectedMediaId,
  (albums, albumsRelMedia, mediaId) => {
    if (mediaId) {
      const relObject =
        (albumsRelMedia ? albumsRelMedia.find(item => item.mediaId === mediaId) : null);
      if (relObject && relObject.albumId) {
        return albums.find(item => item.id === relObject.albumId);
      }
    }
    return {};
  },
);

export const makeGetTags = () => createSelector(
  getTags,
  (tags) => {
    const tagsSliced = (tags ? tags.slice() : []);
    tagsSliced.sort(sortByUpdatedAt);
    return tagsSliced;
  },
);

export const makeGetTagRelMedia = () => createSelector(
  getTagRelMedia,
  tagRelMedia => tagRelMedia,
);

export const makeGetSelectedTags = () => createSelector(
  getTags,
  getTagRelMedia,
  getSelectedMediaId,
  (tags, tagRelMedia, mediaId) => {
    if (mediaId) {
      const relArray =
        (tagRelMedia ? tagRelMedia.filter(item => item.mediaId === mediaId) : null);
      if (relArray && relArray.length > 0) {
        const tagsIds = relArray.map(rel => rel.tagId);
        return tags.filter(item => tagsIds.includes(item.id));
      }
    }
    return [];
  },
);

// export const makeGetAlbumTags = () => createSelector(
//   getPhotoList,
//   getTagRelMedia,
//   (mediaList)
// )

export const makeGetMediaList = state => createSelector(
  getPhotoList,
  getVideoList,
  getAlbumFilterList,
  getTagsFilterList,
  getAlbumRelMedia,
  getTagRelMedia,
  getSelectedCategory,
  (
    photosList, videosList, albumFilters, tagsFilters,
    albumsRelMedia, tagRelMedia, selectedCategory,
  ) => {
    if (albumFilters === -1) {
      return [];
    }
    let photos = [];
    let videos = [];
    const filteredRelAlbum = albumsRelMedia.filter(alb => albumFilters.includes(alb.albumId));

    const mediaIdsByAlbum = filteredRelAlbum.map(rel => rel.mediaId);
    let photosAlbum = [];
    let videosAlbum = [];
    let photosTag = [];
    let videosTag = [];

    const isProfile = state.ui.app.routes.currentRoute === 'profile' || false;
    if (albumFilters.length) {
      photosAlbum = photosList
        .filter(photo => mediaIdsByAlbum.includes(photo.id));
      if (photosAlbum && photosAlbum.length > 0 && !isProfile) {
        photos = photosAlbum;
      }
      videosAlbum = (videosList ? videosList
        .filter(video => mediaIdsByAlbum.includes(video.id)) : null);
      if (videosAlbum && videosAlbum.length > 0) {
        videos = videosAlbum;
      }
    }
    const filteredRelTag =
      (tagRelMedia ? tagRelMedia.filter(alb => tagsFilters.includes(alb.tagId)) : null);
    const mediaIdsByTag = (filteredRelTag ? filteredRelTag.map(rel => rel.mediaId) : null);
    if (tagsFilters.length) {
      photosTag =
        (photosList ? photosList.filter(photo => mediaIdsByTag.includes(photo.id)) : null);
      if (photosTag && photosTag.length > 0) {
        if (photosAlbum && photosAlbum.length > 0) {
          photos = [...(photos || []), ...photosTag.filter(p => !photos.includes(p))];
        } else {
          photos = photosTag;
        }
      }
      videosTag =
        (videosList ? videosList.filter(video => mediaIdsByTag.includes(video.id)) : null);

      if (videosTag && videosTag.length > 0) {
        if (videosAlbum && videosAlbum.length > 0) {
          videos = [...(videos || []), ...videosTag.filter(v => !videos.includes(v))];
        } else {
          videos = videosTag;
        }
      }
    } else if (!albumFilters.length) {
      photos = photosList;
      videos = videosList;
    }


    let combined;
    // const sortBy = isMobileApp ?
    //   sortByNewestCreated : sortByUpdatedAt;
    const sortBy = sortByUpdatedAt;
    switch (selectedCategory) {
      case 'photos':
        return photos ? photos.sort(sortBy) : null;
      case 'videos':
        return videos ? videos.sort(sortBy) : null;
      default:
        if (photos && photos.length >= 0) {
          combined = photos;
        }
        if (videos && videos.length >= 0) {
          combined = (combined && combined.length >= 0 ? combined : []).concat(videos);
        }
        if (combined && combined.length > 0) {
          combined.sort(sortBy);
        }
        return combined;
    }
  },
);
