import {
  ALBUM_CREATE_SUC,
  ALBUM_UPDATE_SUC,
  DELETE_ALBUM_EVERYTHING_SUC,
  MARK_PHOTO_FAVORITE_SUC,
  MARK_VIDEO_FAVORITE_SUC,
  MULTI_DELETE_SUC,
  PHOTO_DELETE_SUC,
  PHOTO_GET_MEDIA_SUC,
  PHOTO_RESIZE_SUC,
  PHOTO_UPDATE_SUC,
  PHOTO_UPLOAD_SUC, VIDEO_DELETE_SUC,
  VIDEO_RESIZE_SUC,
  VIDEO_UPDATE_SUC,
  VIDEO_UPLOAD_SUC,
} from '../../../actions/data/photo';
import { ASYNC_ERROR } from '../../../actions/ui/async';
import { CREATE_ALBUM, UPDATE_ALBUM } from '../../../actions/ui/uxProfile/photosVideos';
import { AUTH2_DEL_ACCOUNT_SUC, AUTH2_LOGOUT_SUC, AUTH2_LOGOUT_SUC_WITH_REDIRECT } from '../../../actions/data/cognito';

const mediaUpdatedFlag = (state = null, action) => {
  const flag = Math.floor(Math.random() * 1000);
  switch (action.type) {
    case ALBUM_CREATE_SUC:
      if (action.photos.length > 0 && action.uuid !== '') {
        return flag;
      }
      return state;
    case VIDEO_RESIZE_SUC:
    case VIDEO_UPDATE_SUC:
    case MARK_VIDEO_FAVORITE_SUC:
      if (action.uuid !== '') {
        return flag;
      }
      return state;
    case PHOTO_GET_MEDIA_SUC:
    case PHOTO_UPLOAD_SUC:
    case ALBUM_UPDATE_SUC:
    case PHOTO_RESIZE_SUC:
    case PHOTO_UPDATE_SUC:
    case MARK_PHOTO_FAVORITE_SUC:
    case PHOTO_DELETE_SUC:
    case MULTI_DELETE_SUC:
    case DELETE_ALBUM_EVERYTHING_SUC:
    case VIDEO_DELETE_SUC:
    case VIDEO_UPLOAD_SUC:
      return flag;
    case ASYNC_ERROR:
      if ((action.model === UPDATE_ALBUM || action.model === CREATE_ALBUM) &&
        (action.desc === 'pollResized')) {
        return flag;
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

export default mediaUpdatedFlag;
