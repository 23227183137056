import { connect } from 'react-redux';
import Component from './PhysiquePage.component';
import { savePhysique } from '../+store/physiquePage/physiquePage.actions';
import { routeChangedAlert } from '../../../store/actions/ui/routes';
import { isMetricUnits } from '../+store/common/common.utils';

const mapStateToProps = (state) => {
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return {
    uuid: state.data.cognito.uuid,
    currentRoute: state.ui.app.routes.currentRoute,
    profile,
    metricUnits: isMetricUnits(state),
  };
};

const mapDispatchToProps = dispatch => ({
  savePhysique: (uuid, fields, code) => {
    dispatch(savePhysique(uuid, fields, code));
  },
  changeRoute: (pathname) => {
    dispatch(routeChangedAlert(pathname));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
