import { combineReducers } from 'redux';

import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_FAILED,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';

import {
  AUTH2_RESEND_CODE_ERR,
  AUTH2_RESEND_CODE_REQ,
  AUTH2_RESEND_CODE_SUC,
} from '../../../actions/data/cognito';

import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { EP_PHOTO_SAVE_NEW } from '../../../actions/ui/uxEditProfile/profilePhoto';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../actions/ui/async';
import { PP_GET_REQ } from '../../../actions/ui/uxProfile';
import { CHECK_IF_INVITE_IS_VALID } from '../../../actions/ui/welcome';
import {UPDATE_INVITE_PROFILE, UPDATE_INVITE_PROFILE_FORM_FINAL} from '../../../../components/Welcome/AthletInviteOnboarding/+Store/AthletePhysique/AthletePhysique.action';

const loading = (state = false, action) => {
  switch (action.type) {
    case AUTH2_RESEND_CODE_REQ:
    case EP_PHOTO_SAVE_NEW:
      return !!action.isMobile;
    case FORM_SUBMIT_INTENT:
      return true;
    case FORM_SUBMIT_SUCCESS:
    case FORM_SUBMIT_FAILED:
    case FORM_SERVER_ERROR:
      return false;
    case ROUTE_CHANGED:
    case AUTH2_RESEND_CODE_SUC:
    case AUTH2_RESEND_CODE_ERR:
      return false;
    case ASYNC_START:
      if (action.model === PP_GET_REQ) {
        return true;
      }
      if (action.model === UPDATE_INVITE_PROFILE) {
        return true;
      }
      if (action.model === UPDATE_INVITE_PROFILE_FORM_FINAL) {
        return true;
      }
      if (action.model === CHECK_IF_INVITE_IS_VALID) {
        return true;
      }
      return state;
    case ASYNC_ERROR:
      
    case ASYNC_FINISH:
      if (action.model === PP_GET_REQ) {
        return false;
      }
      if (action.model === CHECK_IF_INVITE_IS_VALID) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CHECK_IF_INVITE_IS_VALID)
      {
        return 'Loading ...'
      }
      return state;
    case AUTH2_RESEND_CODE_REQ:
      return action.msg;
    case FORM_SUBMIT_INTENT:
      if (action.model === 'forms.joinNow') {
        return 'Creating your account';
      }
      if (action.model === 'forms.login') {
        return 'Signing in';
      }
      if (action.model === 'forms.secondLogin') {
        return 'Signing in';
      }
      if (action.model === 'forms.forgot') {
        return 'Sending your link';
      }
      if (action.model === 'forms.confirmAccount') {
        return 'Authorizing your account';
      }
      if (action.model === 'forms.resetPassword') {
        return 'Saving your new password';
      }
      return state;
    case FORM_SUBMIT_SUCCESS:
    case FORM_SERVER_ERROR:
    case AUTH2_RESEND_CODE_SUC:
    case AUTH2_RESEND_CODE_ERR:
      return '';
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ( [CHECK_IF_INVITE_IS_VALID].includes(action.model) )
      {
          return ''
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loading,
  loadingMsg,
});

export default reducer;
