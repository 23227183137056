import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../UxCommon/Icon/Icon';
import { Button } from '../../../UxCommon/Form';

class ErrorMsg extends PureComponent {
  static propTypes = {
    msg: PropTypes.any.isRequired,
    btnMsg: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
  };

  onClose = () => {
    const { onClose, id } = this.props;
    onClose(id);
  }

  render() {
    const {
      msg,
      btnMsg,
      onClick,
    } = this.props;
    return (
      <div className="P_TopBanner_ErrorMsg_Msg">
        <div className="P_TopBanner_ErrorMsg_MsgTxt">
          <Icon iconName="alert" className="P_TopBanner_ErrorMsg_MsgIcon" />
          {msg}
        </div>
        {
          onClick && (
            <Button
              className="P_TopBanner_ErrorMsg_MsgBtn"
              onClick={onClick}
            >
              {btnMsg}
            </Button>
          )
        }
        <button
          className="P_TopBanner_ErrorMsg_CloseBtn"
          onClick={this.onClose}
        >
          <Icon className="P_TopBanner_ErrorMsg_CloseBtnIcon" iconName="close" />
        </button>
      </div>
    );
  }
}

export default ErrorMsg;
