import {asyncStartAction} from '../../../../store/actions/ui/async';


export const GET_ALL_TIME_LEADERBOARD_TEMPLATE = 'allTimeLeaderboard.template.get';
export const getAllTimeLeaderboardTemplate = (schoolId, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_ALL_TIME_LEADERBOARD_TEMPLATE));
  dispatch({
    type: GET_ALL_TIME_LEADERBOARD_TEMPLATE,
    schoolId,
    sportId,
    dispatch,
  });
};

export const GET_ALL_TIME_LEADERBOARD_SPORTS = 'allTimeLeaderboard.sports.get';
export const getAllTimeLeaderboardSports = (schoolId) => (dispatch) => {
  dispatch(asyncStartAction(GET_ALL_TIME_LEADERBOARD_SPORTS));
  dispatch({
    type: GET_ALL_TIME_LEADERBOARD_SPORTS,
    schoolId,
    dispatch,
  });
};

export const GET_ALL_TIME_LEADERBOARD_GRAD_YEARS = 'allTimeLeaderboard.gradYears.get';
export const getAllTimeLeaderboardGradYears = (schoolId) => (dispatch) => {
  dispatch(asyncStartAction(GET_ALL_TIME_LEADERBOARD_GRAD_YEARS));
  dispatch({
    type: GET_ALL_TIME_LEADERBOARD_GRAD_YEARS,
    schoolId,
    dispatch,
  });
};


export const GET_ALL_TIME_LEADERBOARD_RESULTS = 'allTimeLeaderboard.results.get';
export const getAllTimeLeaderboardResults = ({
                                               sortBy,
                                               perPage,
                                               scoreType,
                                               parsedFilters,
                                               page,
                                               loadMore,
                                               orderBy,
                                               zip,
                                               radius,
                                               search,
                                               sportId,
                                               schoolId,
                                               lastFilterRank
                                             }) =>
  (dispatch) => {
    dispatch(asyncStartAction(GET_ALL_TIME_LEADERBOARD_RESULTS));
    dispatch({
      type: GET_ALL_TIME_LEADERBOARD_RESULTS,
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      loadMore,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
      lastFilterRank,
      dispatch,
    });
  };

export default 'allTimeLeaderboard.actions.js';
