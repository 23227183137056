import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextControl from '../../common/FormControls/TextControl';
import { ButtonRect } from '../../../../components/UxCommon';
import ControlErrors from '../../common/FormControls/ControlErrors';
import LoadingIndicator from '../../common/LoadingIndicator';

const validation = {
  code: (value) => {
    if (!value) {
      return ['Required'];
    }
    return [];
  },
};

class ConfirmEmailPhone extends PureComponent {
  static propTypes = {
    isTelephone: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string,
    resendCode: PropTypes.func.isRequired,
    verify: PropTypes.func.isRequired,
    loadingMsg: PropTypes.string,
    errorMsg: PropTypes.string,
    successMsg: PropTypes.string,
  }

  state = {
    values: {
      code: '',
    },
    errors: {},
    touched: {},
  }

  onValueChange = name => (value) => {
    const { values, touched, errors } = this.state;
    this.setState({
      values: {
        ...values,
        [name]: value,
      },
      touched: {
        ...touched,
        [name]: true,
      },
      errors: {
        ...errors,
        [name]: validation[name](value),
      },
    });
  }

  onResendClick = () => {
    const { resendCode, isTelephone } = this.props;
    resendCode(isTelephone);
  }

  onVerifyClick = (e) => {
    const { verify, isTelephone } = this.props;
    const { values } = this.state;
    const newErrors = {};
    const newTouched = {};
    e.preventDefault();
    ['code'].forEach((name) => {
      newTouched[name] = true;
      newErrors[name] = validation[name](values[name]);
    });
    this.setState({
      errors: newErrors,
      touched: newTouched,
    });
    let errorsCount = 0;
    Object.entries(newErrors).forEach(([, errorList]) => {
      errorsCount += errorList.length;
    });
    if (!errorsCount) {
      verify(isTelephone, values.code);
    }
  }

  render() {
    const {
      isTelephone, email, phone, loadingMsg, successMsg, errorMsg,
    } = this.props;
    const {
      values, errors,
    } = this.state;
    let title;
    let help;
    let username;
    if (isTelephone) {
      title = 'Verify Your Phone Number';
      help = 'Enter the code you received';
      username = `We've sent a verification code to ${phone}. Enter it below to verify your phone number.`;
    } else {
      title = 'Verify Your Email Address';
      help = 'Enter the code you recieved';
      username = `We've sent a verification code to ${email}. Enter it below to verify your email.`;
    }
    return (
      <div className="CoachConfirmEmailPhone">
        <LoadingIndicator loading={!!loadingMsg} loadingMsg={loadingMsg} />
        {
          successMsg && (
            <div className="CoachConfirmEmailPhone_Success">
              {successMsg}
            </div>
          )
        }
        <div className="CoachConfirmEmailPhone_Title">
          {title}
        </div>
        <div className="CoachConfirmEmailPhone_Username">
          {username}
        </div>
        <form onSubmit={this.onVerifyClick}>
          <TextControl
            className="CoachConfirmEmailPhone_Input"
            name="code"
            label="Code"
            placeholder="Code"
            myOnChange={this.onValueChange('code')}
            myOnBlur={this.onValueChange('code')}
            help={help}
            type="text"
            value={values.code}
            errors={errors.code}
          />
          <ControlErrors errors={errorMsg ? [errorMsg] : []} />
          <div className="CoachConfirmEmailPhone_Btns">
            <ButtonRect
              className="CoachConfirmEmailPhone_Btn"
              style={{ width: '120px' }}
              type="button"
              onClick={this.onResendClick}
            >
              Resend Code
            </ButtonRect>
            <ButtonRect
              className="CoachConfirmEmailPhone_Btn primary"
              style={{ width: '120px' }}
              type="submit"
            >
              Verify
            </ButtonRect>
          </div>
        </form>
      </div>
    );
  }
}

export default ConfirmEmailPhone;
