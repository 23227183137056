import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';
import Urls from '../../../../config/urls';

export const getSchoolSportTeamsList = (schoolId, sportId = 0, token) => (
    ajax.getJSON(
        Urls.schools.getSchoolTeamsList(schoolId, sportId),
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    )
);

export const postSportTeam = (uuid, token, props = {}) => (ajax.post(
        Urls.schools.teamSignUp(),
        JSON.stringify(props),
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    ).pipe(map(response => response.response))
);

export const addSportToProfile = (uuid, token, userSport) => ajax.post(
    Urls.userSports(uuid),
    JSON.stringify(userSport),
    {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    },
).pipe(map(response => response.response));

export const updateSportProfile = (uuid, sportId, token, userSport) => ajax.put(
    Urls.userSports(uuid, sportId),
    JSON.stringify(userSport),
    {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    },
).pipe(map(response => response.response));


export const updateSchoolSportTeam = (token, team) => ajax.put(
    Urls.schools.updateSchoolTeam(),
    JSON.stringify(team),
    {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    },
).pipe(map(response => response.response));

export const getUsersTeams = (uuid, token) => (
    ajax.getJSON(
        Urls.schools.getUsersTeams(uuid),
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    )
);

export default 'sportsPage.api.js';
