import { combineReducers } from 'redux';
import { AWARDS_FORM_INIT, CREATE_AWARD, UPDATE_AWARD, GET_AWARDS, HIDE_AWARD, DELETE_AWARD, REGISTER_NODE_HANDLE } from '../../../../actions/ui/uxProfile/awards';
import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../../actions/ui/async';

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if ((action.model === CREATE_AWARD) ||
      (action.model === UPDATE_AWARD) ||
      (action.model === GET_AWARDS) ||
      (action.model === HIDE_AWARD) ||
      (action.model === DELETE_AWARD)) {
        return true;
      }
      return state;
    case AWARDS_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CREATE_AWARD) {
        return 'Creating award';
      }
      if (action.model === UPDATE_AWARD) {
        return 'Saving award';
      }
      if (action.model === GET_AWARDS) {
        return 'Loading awards...';
      }
      if (action.model === HIDE_AWARD) {
        return 'Hiding award';
      }
      if (action.model === DELETE_AWARD) {
        return 'Deleting award';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === CREATE_AWARD) ||
      (action.model === UPDATE_AWARD) ||
      (action.model === GET_AWARDS) ||
      (action.model === HIDE_AWARD) ||
      (action.model === DELETE_AWARD)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};


const reducer = combineReducers({
  loadingMsg,
  needsInit,
});

export default reducer;
