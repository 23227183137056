import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { actions } from 'react-redux-form';

import {getPlayerLatestSchool, profileUpdate, profileUpdateSuccess} from '../../data/user';
import {
  mapUserAccountInfoUiToApi,
  mapUserApiToUi, mapUserLatestSchoolToProfile,
  mapUserUiToApi,
} from '../../data/user/profile/models';
import {
  cognitoDeleteAttributes,
  cognitoUpdateUser,
  cognitoUpdateUserSuccess,
} from '../../data/cognito';

import {
  validateDecimalToOnePositionNumber,
  validateEmail,
  validateHeight,
  validateInteger,
  validateLength,
  validateLetters, validateLettersWithRMSpecialCharacter,
  validateNumber,
  validatePhone,
  validateRequired,
  validateSAPhone,
  validateZipcode,
  validateGradYear,
} from '../formUtils/validators';
import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';
import { formServerError, formSubmitSuccess } from '../formUtils/actions';
import { formatPhoneForCognito, isMobileApp } from '../../../../utils';
import { cognitoGetCurrent } from '../../data/cognito/auth';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { getTokenFragment } from '../uxProfile/utils';
import { getPlayerProfile } from '../../data/user/profile';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../async';

export const ACCOUNT_FORM_INIT = 'editProfile.account.formInit';
export const accountFormInit = profile => ({ type: ACCOUNT_FORM_INIT, profile });

export const ACCOUNT_INIT = 'editProfile.account.init';
export const accountInit = () => ({ type: ACCOUNT_INIT });


const accountFormValidation = {
  'forms.account.first': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateLettersWithRMSpecialCharacter()(value),
  ],
  'forms.account.last': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateLettersWithRMSpecialCharacter()(value),
  ],
  'forms.account.email': [
    value => validateRequired()(value),//don't change this order
    value => validateEmail()(value),
    // (value, form) => validateEmailOrPhoneRequired()(form.phone, value),
  ],
  'forms.account.phone': [
    (value) => {
      const countryCode = value.substr(0, value.indexOf(' '));
      let phoneNumber = '';
      if (value.indexOf(' ') !== -1) {
        phoneNumber = value.substr(value.indexOf(' ') + 1);
      }

      switch (countryCode) {
        case '+27':
          return validateSAPhone()(phoneNumber);
        default:
          return validatePhone()(phoneNumber);
      }
    },
  ],
  'forms.account.heightFt': [
    value => validateInteger()(value),
  ],
  'forms.account.heightInch': [
    // value => validateInteger()(value),
    value => validateDecimalToOnePositionNumber()(value),
  ],
  'forms.account.heightMt': [
    value => validateInteger()(value),
  ],
  'forms.account.heightCm': [
    value => validateDecimalToOnePositionNumber()(value),
  ],
  'forms.account.height': [
    // value => validateInteger()(value),
    value => validateHeight()(value),
  ],
  'forms.account.weight': [
    value => validateNumber()(value),
  ],
  'forms.account.weightKg': [
    value => validateNumber()(value),
  ],
  'forms.account.parentsName': [
    value => validateLettersWithRMSpecialCharacter()(value),
  ],
  'forms.account.parentsEmail': [
    value => validateEmail()(value),
    // (value, form) => validateEmailOrPhoneRequired()(form.phone, value),
  ],
  'forms.account.parentsPhone': [
    value => validatePhone()(value),
  ],
  'forms.account.gradYear': [
    value => validateGradYear()(value),
  ],
  'forms.account.act': [
    value => validateInteger()(value),
  ],
  'forms.account.sat': [
    value => validateInteger()(value),
  ],
  'forms.account.classRank': [
    value => validateInteger()(value),
  ],
  'forms.account.classSize': [
    value => validateInteger()(value),
  ],
  'forms.account.schoolCredits': [
    value => validateInteger()(value),
  ],
  'forms.account.coreGpa': [
    value => validateNumber()(value),
  ],
  'forms.account.birthdate': [],
  // 'forms.account.zip': [
  //   value => validateZipcode()(value),
  // ],
};

export const REGISTER_HANDLE = 'cognito.Auth.verifyPhone.success';
export const PHONE_NEEDS_CONFIRMED = 'account.phone.needsConfirmed';
export const EMAIL_SUCCESS_MERGED = 'account.email.merged.success';
export const EMAIL_NEEDS_CONFIRMED = 'account.email.needsConfirmed';
export const phoneNeedsConfirmed = () => ({ type: PHONE_NEEDS_CONFIRMED });
export const emailNeedsConfirmed = () => ({ type: EMAIL_NEEDS_CONFIRMED });
export const emailMergedSuccess = () => ({ type: EMAIL_SUCCESS_MERGED });

export const SAVE_ACCOUNT = 'editProfile.account.saveAccount';
export const saveAccountInfo = accountInfo => (dispatch) => {
  dispatch(asyncStartAction(SAVE_ACCOUNT));
  dispatch({
    type: SAVE_ACCOUNT,
    accountInfo,
  });
};

const saveAccountInfoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const cognitoUser = () => state$.value.data.cognito.cognitoUser;
  const profile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    ofType(SAVE_ACCOUNT),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (isCoach()) {
        return of({ action, token });
      }
      return from(cognitoDeleteAttributes(
        cognitoUser(),
        {
          email: action.accountInfo.email,
          phone: formatPhoneForCognito(action.accountInfo.phone),
        },
      )).pipe(
        map(awsResponse => ({
          success: true,
          deleteAttrsResponse: awsResponse,
          action,
          token,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(SAVE_ACCOUNT, 'awsUpdate', error),
        })),
      );
    }),
    switchMap((result) => {
      if (result.success && !isCoach()) {
        return from(cognitoUpdateUser(
          cognitoUser(),
          {
            email: result.action.accountInfo.email,
            // phone: formatPhoneForCognito(form().phone),
          },
          isMobileApp,
        )).pipe(
          map(awsResponse => ({
            awsResponse,
            ...result,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(SAVE_ACCOUNT, 'awsUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((results) => {
      if (results.success || isCoach()) {
        return profileUpdate(
          uuid(),
          mapUserAccountInfoUiToApi(isCoach() ? uuid() : results.awsResponse.userSub, results.action.accountInfo, profile()),
          results.token,
          isCoach(),
        ).pipe(
          map(() => ({
            success: true,
            awsResponse: results.awsResponse,
            action: results.action,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(SAVE_ACCOUNT, 'usUpdate', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((result) => {
      if (result.success) {
        return getPlayerProfile(uuid(), result.token).pipe(
          map(playerProfile => ({
            ...result,
            profileRes: mapUserApiToUi(playerProfile, profile().schools, profile().sports),
            success: true,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(SAVE_ACCOUNT, 'usUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success && !isCoach()) {
        return from(cognitoGetCurrent()).pipe(
          map(awsResponse => ({
            ...result,
            awsResponse,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(SAVE_ACCOUNT, 'awsUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap(({
      success,
      awsResponse,
      profileRes,
      action,
    }) => {
      if (success) {
        const coach = isCoach();
        const successActions = coach ? [
          asyncFinishAction(SAVE_ACCOUNT, { awsResponse, profileRes }),
          profileUpdateSuccess(profileRes),
        ] : [
          asyncFinishAction(SAVE_ACCOUNT, { awsResponse, profileRes }),
          cognitoUpdateUserSuccess(awsResponse),
          profileUpdateSuccess(profileRes),
        ];

        if (!coach && awsResponse.attributes.phone_number
          && !awsResponse.attributes.phone_number_verified) {
          successActions.push(phoneNeedsConfirmed());
        }
        if (!coach && awsResponse.attributes.email && !awsResponse.attributes.email_verified) {
          successActions.push(emailNeedsConfirmed());
        }
        return of(...successActions);
      }
      return of(action);
    }),
  );
};

const accountFormSubmitEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const form = () => state$.value.forms.account;
  const cognitoUser = () => state$.value.data.cognito.cognitoUser;
  const profile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    isPendingIsValid(state$, 'forms.account', 'account'),
    getTokenFragment(),
    switchMap(({ action, token }) => {

      if(action.attrs === undefined ){
        return false;
      }
      if (isCoach()) {
        return of({ action, token });
      }
      return from(cognitoDeleteAttributes(
        cognitoUser(),
        {
          email: form().email,
          phone: formatPhoneForCognito(form().phone),
        },
      )).pipe(
        map(awsResponse => ({
          success: true,
          deleteAttrsResponse: awsResponse,
          outer: action,
          token,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: formServerError('forms.account', 'awsUpdate', error),
        })),
      );
    }),
    switchMap((result) => {
      if (result.success && !isCoach()) {
        return from(cognitoUpdateUser(
          cognitoUser(),
          {
            email: form().email,
            // phone: formatPhoneForCognito(form().phone),
          },
          isMobileApp,
        )).pipe(
          map(awsResponse => ({
            awsResponse,
            ...result,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'awsUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((results) => {
      if (results.success || isCoach()) {
        const isMetricUnits = results.outer ?
          results.outer.attrs.isMetricUnits :
          results.action.attrs.isMetricUnits;
        return profileUpdate(
          uuid(),
          mapUserUiToApi(
            isCoach() ? uuid() : results.awsResponse.userSub,
            form(),
            isMetricUnits,
          ),
          results.token,
          isCoach(),
        ).pipe(
          map(() => ({
            success: true,
            awsResponse: results.awsResponse,
            outer: results.outer,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'usUpdate', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((result) => {
      if (result.success) {
        return getPlayerProfile(uuid(), result.token).pipe(
          map(playerProfile => ({
            ...result,
            profile: mapUserApiToUi(playerProfile, profile().schools, profile().sports),
            success: true,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'usUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return getPlayerLatestSchool(uuid(), result.token).pipe(
          map(latestSchool => ({
            ...result,
            profile: {...result.profile , latestSchool},
            success: true,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'usUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success && !isCoach()) {
        return from(cognitoGetCurrent()).pipe(
          map(awsResponse => ({
            ...result,
            awsResponse,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'awsUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap(({
      success,
      awsResponse,
      profile,
      action,
    }) => {
      if (success) {
        const coach = isCoach();
        const successActions = coach ? [
          formSubmitSuccess('forms.account', {
            awsResponse,
            profile,
          }),
          profileUpdateSuccess(profile),
        ] : [
          formSubmitSuccess('forms.account', {
            awsResponse,
            profile,
          }),
          cognitoUpdateUserSuccess(awsResponse),
          profileUpdateSuccess(profile),
        ];

        if (!coach && awsResponse.attributes.phone_number
          && !awsResponse.attributes.phone_number_verified) {
          successActions.push(phoneNeedsConfirmed());
        }
        if (!coach && awsResponse.attributes.email && !awsResponse.attributes.email_verified) {
          successActions.push(emailNeedsConfirmed());
        }
        return of(...successActions);
      }
      return of(action);
    }),
  );
};

const phoneOnBlurEpic = (model, name) => (action$, state$) => {
  const phone = (field = 'phone') => state$.value.forms.account[field];
  return action$.pipe(
    filter((action) => {
      if (action.type === 'rrf/blur') {
        return action.model === model;
      }
      if ((action.type === 'rrf/batch')
        && (action.actions.find(a => a.type === 'rrf/blur'))) {
        return action.model === model;
      }
      return false;
    }),
    map(() => actions.change(model, phone(name))),
  );
};

export const accountEpics = combineEpics(
  accountFormSubmitEpic,
  saveAccountInfoEpic,
  validationOnSubmitEpicFactory(accountFormValidation, 'forms.account', 'account'),
  validationFailedEpicFactory('forms.account', 'account'),
  ...formValidationEpicsFactory(accountFormValidation, 'account'),
  phoneOnBlurEpic('forms.account.phone', 'phone'),
  phoneOnBlurEpic('forms.account.parentsPhone', 'parentsPhone'),
);

