import { connect } from 'react-redux';
import Component from './CreateEditCombine.component';

import {
  showArchiveDialog,
  hideArchiveDialog,
  getPureCombine,
  createCombine,
  updateCombine,
  deleteCombine,
} from '../../../+store/combine';

const getTemplatesDataSource = (templates) => {
  let res = {};
  templates.forEach((template) => {
    res = {
      ...res,
      [template.id]: template.name,
    };
  });
  // res = {
  //   ...res,
  //   // createNew: 'Create Combine Template',
  // };
  return res;
};

const getSchoolUuids = (teams) => {
  const uuids = [];
  teams.forEach((team) => {
    uuids.push({
      [team.id]: team.name,
    });
  });
  return uuids;
};



const mapStateToProps = (state, ownProps) => {
  const { uuid } = state.data.cognito;
  const schoolOptions = getSchoolUuids(state.modules.coachWorld.coachWorld.schoolStyle);
  const combineTemplateId = state.modules.coachWorld.combine.combineTemplateId;
  const combineTemplates = state.modules.coachWorld.combine.combineTemplates[ownProps.match.params.schoolId];
  const currentTemplate = combineTemplates.find(template => template.id === combineTemplateId);
  const schools = schoolOptions.reduce((prev, curr) => {
  const [schoolId, schoolName] = Object.entries(curr)[0];
  return {
    ...prev,
    [schoolId]: schoolName,
  };
}, {});

  return{
  loadingMsg: state.modules.coachWorld.combine.loadingMsgModal,
  loading: !!state.modules.coachWorld.combine.loadingMsgModal,
  isArchiveDialogVisible: state.modules.coachWorld.combine.isArchiveDialogVisible,
  combine: state.modules.coachWorld.combine.combineToEdit,
  combineTemplateId,
  schoolOptions: schoolOptions,
  schools,
  currentTemplate,
  templates: state.modules.coachWorld.combine.combineTemplates,
  // allTemplate: state.modules.coachWorld.combine.combineTemplates,
  teams: state.modules.coachWorld.coachWorld.myTeams,
  combineTemplates,
  profile: state.data.user.profiles[uuid],
  sports: state.data.sport.sports,
}};

const mapDispatchToProps = dispatch => ({
  getCombine: (combineId) => {
    dispatch(getPureCombine(combineId));
  },
  showArchiveDialog: () => dispatch(showArchiveDialog()),
  hideArchiveDialog: () => dispatch(hideArchiveDialog()),
  createCombine: data => dispatch(createCombine(data)),
  updateCombine: (data, combine) => dispatch(updateCombine(data, combine, true)),
  deleteCombine: combineId => dispatch(deleteCombine(combineId)),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
