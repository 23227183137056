import { asyncStartAction } from '../../../../store/actions/ui/async';

export const CREATE_COMBINE_TEMPLATE = 'combine.createCombineTemplate';
export const createCombineTemplate = (data, stdTestIds, schoolId, redirectToCombine) =>
  (dispatch) => {
    dispatch(asyncStartAction(CREATE_COMBINE_TEMPLATE));
    dispatch({
      type: CREATE_COMBINE_TEMPLATE,
      data,
      stdTestIds,
      schoolId,
      redirectToCombine,
    });
  };


export const UPDATE_COMBINE_TEMPLATE = 'combine.updateCombineTemplate';
export const updateCombineTemplate =
  (data, combineTemplateId, newStdTestIds, removedStdTestIds, schoolId, redirectToCombine) =>
    (dispatch) => {
      dispatch(asyncStartAction(UPDATE_COMBINE_TEMPLATE));
      dispatch({
        type: UPDATE_COMBINE_TEMPLATE,
        data,
        newStdTestIds,
        removedStdTestIds,
        combineTemplateId,
        schoolId,
        redirectToCombine,
      });
    };

export const UPDATE_COMBINE_TEST = 'combine.updateCombineTest';
export const updateCombineTest =
    (data, combineTemplateId, newStdTestIds, removedStdTestIds, schoolId, redirectToCombine, combineId, redirectToCombineDataEntry) =>
        (dispatch) => {
          dispatch(asyncStartAction(UPDATE_COMBINE_TEST));
          dispatch({
            type: UPDATE_COMBINE_TEST,
            data,
            newStdTestIds,
            removedStdTestIds,
            combineTemplateId,
            schoolId,
            redirectToCombine,
            combineId,
            redirectToCombineDataEntry,
          });
        };


export const DELETE_COMBINE_TEMPLATE = 'combine.deleteCombineTemplate';
export const deleteCombineTemplate = (combineTemplateId, schoolId) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_COMBINE_TEMPLATE));
  dispatch({
    type: DELETE_COMBINE_TEMPLATE,
    combineTemplateId,
    schoolId,
  });
};

export const GET_COMBINE_TEMPLATE = 'combine.getCombineTemplate';
export const getCombineTemplate = combineTemplateId => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_TEMPLATE));
  dispatch({
    type: GET_COMBINE_TEMPLATE,
    combineTemplateId,
  });
};

export const TOGGLE_DELETE_MODAL = 'combine.toggleDeleteModel';
export const toggleDeleteModal = (value = null) => (dispatch) => {
  dispatch({
    type: TOGGLE_DELETE_MODAL,
    value,
  });
};

export const CLEAR_DELETE_MODAL = 'combine.clearDeleteModel';
export const clearDeleteModal = (value = null) => (dispatch) => {
  dispatch({
    type: CLEAR_DELETE_MODAL,
    value,
  });
};


export const GET_COMBINE_TEMPLATE_COMBINES = "combine.combineTemplateCombines";
export const getCombineTemplateCombines = combineTemplateId => dispatch => {
  dispatch({
    type:GET_COMBINE_TEMPLATE_COMBINES,
    combineTemplateId
  })
}
