import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TeamBanner = ({ report, teamId }) => {
  if (!report) {
    return <div className="CoachRoster_BannerFullTitle">Team Report</div>;
  }
  let closeDate = '';
  const closeDate1 = moment(report.combine.closed_at);
  const closeDate2 = moment(report.second_combine.closed_at);
  const compareDate = closeDate1 > closeDate2;
  if (compareDate) {
    closeDate = ` - ${closeDate1.format('MM.DD.YYYY')}`;
  } else {
    closeDate = ` - ${closeDate2.format('MM.DD.YYYY')}`;
  }
  const team = report.teams.find(t => t.id === teamId);
  const teamName = team.name ? team.name : team.team_reference.team;
  return (
    <div className="CombineReportPage_Banner">
      <div className="CombineReportPage_BannerText">{`REPORT${closeDate}`}</div>
      <div className="CoachRoster_BannerFullTitle">{teamName}</div>
    </div>
  );
};
TeamBanner.propTypes = {
  report: PropTypes.object,
  teamId: PropTypes.string,
};

export default TeamBanner;
