import { combineReducers } from 'redux';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { CHECK_IF_INVITE_IS_VALID } from '../../../actions/ui/welcome';
import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../actions/ui/async';


const loadingMsg = (state = '', action ) => {
  switch( action.type)
  {
    case ASYNC_START:
      if (action.model === CHECK_IF_INVITE_IS_VALID)
      {
        return 'Loading ...'
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ( [CHECK_IF_INVITE_IS_VALID].includes(action.model) )
      {
        return '';
      }
      return state;
    default:
      return state;
  }
}

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
      return true;
    case CHECK_IF_INVITE_IS_VALID:
      return false;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === CHECK_IF_INVITE_IS_VALID) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const isInvitationValid = (state = null, action) => {
  switch (action.type) {
    case CHECK_IF_INVITE_IS_VALID:
      return null;
    case ASYNC_FINISH:
      if (action.model === CHECK_IF_INVITE_IS_VALID) {
        return {
          isValid: action.data.isValid,
          isTokenExist: action.data.isTokenExist
        };
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === CHECK_IF_INVITE_IS_VALID) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  needsInit,
  isInvitationValid,
});

export default reducer;
