import {connect} from 'react-redux';
import Component from './AccountPage.component';
import {checkEmailExists, createAccount} from '../+store/accountPage/accountPage.actions';
import {getCountryCode} from '../+store/common/common.utils';

const mapStateToProps = (state) => {
  const {uuid} = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return {
    profile,
    uuid,
    schoolInfo: state.modules.codeSignUp.codeSignUp.schoolInfo,
    errorMsg: state.modules.codeSignUp.accountPage.errorMsg,
    duplicateEmail: state.modules.codeSignUp.accountPage.duplicateEmail,
    emailExistsMessage: state.modules.codeSignUp.accountPage.emailExistsMessage,
    countryCode: getCountryCode(state),
  };
};

const mapDispatchToProps = dispatch => ({
  checkEmailExists: (email) => {
    dispatch(checkEmailExists(email))
  },
  createAccount: (fields, code) => {
    dispatch(createAccount(fields, code));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
