import React , { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-redux-form";

import { Control } from 'react-redux-form';
import {
  TextControl,
  Errors,
  CustomSelectControl
} from '../../../../UxCommon/Form';

import {  LinkButton, ButtonRect, ButtonLinkRect } from '../../../../UxCommon';
import { Routes } from '../../../../../store/actions/ui/routes';
import DeleteExternalProfileDialog from "../DeleteDialog/DeleteDialog";


class ExternalProfileForm extends PureComponent {

  static propTypes = {
    companyLogo: PropTypes.string,
    rrfForm: PropTypes.object.isRequired,
    onExternalProfileSelected: PropTypes.func,
    match: PropTypes.object.isRequired,
    externalProfileSportIcons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    externalProfile: PropTypes.object
  }

  state = {
    deleteDialogIsShown: false,
  }

  onDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: true,
    });
  }

  onConfirmDeleteClick = () => {
    const { onDeleteClick, externalProfile } = this.props;
    this.setState({
      deleteDialogIsShown: false,
    })
    onDeleteClick(externalProfile);
  }

  onCancelDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: false,
    });
  }

  render() {
    const { deleteDialogIsShown } = this.state;
    const { rrfForm, onExternalProfileSelected, match, externalProfileSportIcons, multipleObject, onSubmitForm, companyLogo, externalProfile } = this.props;
    return (
      <div className="Awards_AwardDetailsForm">
        {
          deleteDialogIsShown && (
            <DeleteExternalProfileDialog
              onConfirmDeleteClick={this.onConfirmDeleteClick}
              onCancelClick={this.onCancelDeleteClick}
              // onHideClick={this.onHideImmediatelyClick}
              externalProfile={externalProfile}
            />
          )
        }
        <div className="Awards_AwardDetailsForm_TopBtnsRow">


        </div>
        <Form
          model="forms.externalProfile"
          onSubmit={onSubmitForm}
        >

          <CustomSelectControl
            label="Profile Company"
            placeholder="Profile Company"
            model="forms.externalProfile.company"
            rrfField={rrfForm.company}
            multipleObject ={multipleObject}
            onOptionSelected={onExternalProfileSelected}
            options={externalProfileSportIcons}
          />

          <TextControl
            label="Enter URL"
            placeholder="Enter URL"
            model="forms.externalProfile.url"
            rrfField={rrfForm.url}
          />

          <Errors
            className="Awards_AwardsDetailsForm_Errors"
            valid={rrfForm.$form.valid}
            errors={rrfForm.$form.errors}
          />
          <div className="Awards_AwardsDetailsForm_ButtonsRow vc-external-profile-btn">
            {
              (externalProfile &&
                  <LinkButton
                    className="Awards_AwardsDetailsForm_Button"
                    iconName="trash"
                    onClick={this.onDeleteClick}
                    type="button"
                    style={{ width: '100px', marginRight: '24px' }}
                  >
                    Delete
                  </LinkButton>
                )

            }
            <ButtonLinkRect
              style={{ marginLeft: 'auto', width: '100px' }}
              className="Awards_AwardsDetailsForm_Button"
              to={`/${Routes.profile}/${match.params.uuid}/${Routes.externalProfile}${match.params.sport ? `/${match.params.sport}` : ''}${match.params.schoolId ? `/${match.params.schoolId}`: ''}`}
            >

              Cancel
            </ButtonLinkRect>
            <ButtonRect
              className="Awards_AwardsDetailsForm_Button primary"
              style={{ width: '120px' }}
              type="submit"
            >
              Save
            </ButtonRect>
          </div>
        </Form>
      </div>
    );
  }

}

export default ExternalProfileForm;
