import React from 'react';
import PropTypes from 'prop-types';

const NavBar = ({ children, className }) => (
  <div className={`CoachNavBar ${className || ''}`}>
    {children}
  </div>
);
NavBar.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default NavBar;

