export const FORM_SUBMIT_INTENT = 'forms.submitIntent';
export const formSubmit = (model, attrs = {}, toRedirectUrl) => ({
  type: FORM_SUBMIT_INTENT,
  model,
  attrs,
  toRedirectUrl,
});

export const FORM_SUBMIT_PENDING = 'forms.submitPending';
export const formSubmitPending = (model, attrs) => ({
  type: FORM_SUBMIT_PENDING,
  model,
  attrs,
});

export const FORM_SUBMIT_FAILED = 'forms.submitFailed';
export const formSubmitFailed = (model, attrs) => ({
  type: FORM_SUBMIT_FAILED,
  model,
  attrs,
});

export const FORM_SUBMIT_SUCCESS = 'forms.submitSuccess';
export const formSubmitSuccess = (model, data) => ({
  type: FORM_SUBMIT_SUCCESS,
  model,
  data,
});

export const FORM_SERVER_ERROR = 'forms.serverError';
export const formServerError = (model, desc, error, data) => ({
  type: FORM_SERVER_ERROR,
  model,
  desc,
  error,
  data,
});
