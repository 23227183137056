export function isEmpty(val) {
  return !val || !val.length;
}

export function isEmail(val) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(val).toLowerCase());
}

export function validateConfirmPassword(val) {
  return val && val === this.props.form.password;
}

const isPhone = (value) => {
  const { length } = value.replace(/[^0-9]+/g, '');
  return ((((value[0] === '+') && (length > 10)) || (length > 9)) && (length <= 13));
};

export const emailOrPhone = (value) => {
  if (isPhone(value)) {
    return 'phone';
  }
  if (isEmail(value)) {
    return 'email';
  }
  return '';
};

export default {};
