import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ProfilePositions extends PureComponent {
    static propTypes = {
      code: PropTypes.string,
      last: PropTypes.bool,
      count: PropTypes.number,
      displayToggle: PropTypes.object
    }

    render() {
      const { code, last, count, state } = this.props;
      return (
      <React.Fragment>
        <span key={code} style={{ paddingRight: '0px', paddingLeft: '1px', display: 'flex' }}>
          {code}
        </span>
        {last ? <span>{count > 0 ?  ", +"+count+" others" : ""}</span> : <span>,&nbsp;</span>}
      </React.Fragment>
     )}
}

export default ProfilePositions;
