import { combineReducers } from "redux";
import { ASYNC_FINISH, ASYNC_START } from "../../../../store/actions/ui/async";
import {
  GET_NATIONAL_LEADERBOARD_RESULTS,
  GET_NATIONAL_LEADERBOARD_TEMPLATE
} from "../nationalLeaderboard";
import { GET_NORTH_EAST_LEADERBOARD_RESULTS } from "./northEastLeaderboard.actions";


const loading = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE
        || action.model === GET_NORTH_EAST_LEADERBOARD_RESULTS) {
        return 'loading';
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE
        || action.model === GET_NORTH_EAST_LEADERBOARD_RESULTS) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const results = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NORTH_EAST_LEADERBOARD_RESULTS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  results,
  loading,
});

export default reducer;
