import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AwardRow from '../AwardRow';
import AthleteRow from '../AthleteRow';
import { ShareModal } from '../../../../../components/UxCommon';
import {  RoleType } from '../../../../../store/actions/data/user/profile/models';
import { camelCase } from '../../../../../containers/UxCommon/Utils';
import { Link } from 'react-router-dom';

class CombineAwards extends PureComponent {
  static propTypes = {
    combineId: PropTypes.string,
    getLeaderboard: PropTypes.func,
    awards: PropTypes.object,
    combine: PropTypes.object,
    roster: PropTypes.array,
    schoolAwardLogo: PropTypes.object,
    profile:PropTypes.object
  }

  state = {
    shareDialogUri: '',
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  fetchedLeaderboard = false;
  shouldUpdate = () => {
    const { getLeaderboard, combineId } = this.props;
    if (!this.fetchedLeaderboard) {
      this.fetchedLeaderboard = true;
      getLeaderboard(combineId);
    }
  }

  openShareDialog = (uri) => {
    this.setState({ shareDialogUri: uri });
  }

  closeShareDialog = () => {
    this.setState({ shareDialogUri: '' });
  }

  render() {
    const { awards, combine, roster, schoolAwardLogo,profile, schoolId, combineId, schoolStyle} = this.props;
    const roleType = profile ? camelCase(RoleType[profile.roleId]) : 'Guest' ;
    const sportId = combine && combine.sportId;
    const { shareDialogUri } = this.state;
    const message = combine && combine.isOpen ? 'Hey Coach! You need to close this combine to share the combine awards.'
                    : !Object.keys(awards).length && combine && !combine.isOpen

    return (
      <div className="CombineAwards vc-review-awards">
        {
          !!shareDialogUri && (
            <ShareModal
              uri={shareDialogUri}
              onClose={this.closeShareDialog}
              backdropClassName=""
            />
          )
        }
        {
          combine && combine.isOpen && (
            <div>
              Hey Coach! You need to close this combine to share the combine awards.
            </div>
          )
        }
        {
            !Object.keys(awards).length && combine && !combine.isOpen && (
              <div>
                Hey coach! Award has not been generated for this combine. To generate the awards you need to reopen and close the combine again.
              </div>
            )
          }
        {
          awards && roster && Object.entries(awards).map(([userId, userAwards]) => {
            const user = roster.find(u => u.userId === userId);
            return (
              <div key={userId} style={{ marginBottom: 15 }}>
                <AthleteRow
                user={user}
                sportId = {combine && combine.sportId}
                schoolId = {schoolId}
                />
                {
                  userAwards.map(award => (
                    <AwardRow
                      key={award.id}
                      award={award}
                      openShareDialog={this.openShareDialog}
                      schoolAwardLogo = {schoolAwardLogo}
                      sportId = {sportId}
                      schoolId = {schoolId}
                      schoolStyle = {schoolStyle}
                    />
                  ))
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default CombineAwards;
