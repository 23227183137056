import React, {useEffect, useRef, useState} from "react";
import {Sports} from "../../../../../store/actions/ui/routes";
import SelectControl from "../../../common/FormControls/SelectControl";
import CombineLister from "./CategoryLister/CategoryLister.component";
import ControlErrors from "../../../common/FormControls/ControlErrors";
import {ButtonRect} from "../../../../../components/UxCommon";
import {useDispatch, useSelector} from "react-redux";
import {getTestCategories, updateCombineTest} from "../../../+store/combine";
import {createSelector} from "reselect";
import {selectCombineTemplate} from "./EditCreateCombineTemplate.container-bak";
import {validateRequired} from "../../../../../store/actions/ui/formUtils/validators";
import Icon from "../../../common/Icon";

const getSports = state => state.data.sport.sports;

const selectSports = createSelector(
    getSports,
    sports => sports.reduce((prev, curr) => (
        {...prev, [curr.id]: curr.name}
    ), {}),
);

const selectedSportsCode = createSelector(
    getSports,
    sports => sports.reduce((prev, curr) => (
        {...prev, [curr.id]: curr.code}
    ), {}),
);

const getStandardTestCats = state =>
    state.modules.coachWorld.combine.standardTestCats.standardTestCats || [];

const selectStandardTestCats = createSelector(
    getStandardTestCats,
    selectCombineTemplate,
    cats => cats.reduce((prev, curr) => (
        {...prev, [curr.id]: curr.name}
    ), {}),
);


export const checkRequired = (value, msg = '') => {
    const error = validateRequired(msg)(value).required;
    return error ? [error] : [];
};

const CatButton = ({cat, onClick}) => (
    <button onClick={onClick(cat)} className="CombineTemplateWrapper_CategoryBadge">
        <div className="CombineTemplate_BadgeText">
            <div className="CombineTemplate_BadgeTextName">{cat.name}</div>
            <div className="CombineTemplate_BadgeTextDesc">{cat.description}</div>
        </div>
        <Icon iconName="close"/>
    </button>
);


const EditCreateCombineTemplate = ({
                                       match,
                                       location,
                                       sportId,
                                   }) => {

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        name: '',
        sport: '',
        cat: '',
        desc: '',
    });
    const [addedCats, setAddedCats] = useState([]);
    const [required, setRequired] = useState([]);
    const [optional, setOptional] = useState([]);
    const [sportUpdated, setSportUpdated] = useState(false);
    const formRef = useRef();
    const dispatch = useDispatch();
    const standardTestCats = useSelector(state => state.modules.coachWorld.combine.standardTestCats.stdTestCategories || []);
    const sports = useSelector(state => selectSports(state));
    const combineTemplate = useSelector(state => state.modules.coachWorld.combine.combineTemplate)
    const organizedSubbanks = useSelector(state => state.modules.coachWorld.combine.standardTestCats.organizedSubbanks || {})
    const loading = useSelector(state => !!state.modules.coachWorld.combine.loadingMsg)
    const loadingMsg = useSelector(state => state.modules.coachWorld.combine.loadingMsg)
    const sportedCats = useSelector(state => state.modules.coachWorld.combine.standardTestCats.sportedCats || []);
    const sportsCode = useSelector(state => selectedSportsCode(state));


    useEffect(() => {
        if (combineTemplate
            && standardTestCats.length && sportedCats.length) {
            combineTemplate.standardTestCategories.forEach((cat) => {
                const stdCat = standardTestCats.find(c => c.id === cat.standard_test_category_id);
                const isExist = addedCats.find(cat => cat.id === stdCat.id);
                if (stdCat && !isExist) {
                    setAddedCats((addedCats) => {
                        return [...addedCats, stdCat];
                    })
                }
            });

            const required = sportedCats.filter(c =>
                !!c.adultRateable
                && c.sportId === combineTemplate.sportId);
            const optional = standardTestCats.filter(c => !required.find(r => r.code === c.code));
            setErrors({});
            setValues({
                sport: combineTemplate.sportId || sportId,
                cat: '',
            });
            setRequired(required);
            setOptional(optional);
        }
    }, [combineTemplate, standardTestCats, sportedCats]);

    useEffect(() => {
        if (!sportUpdated && values.sport) {
            setRequiredAndOptional(values.sport);
            setSportHeightAndWeight(values.sport);
            setSportUpdated(true);
        }

    }, [sportUpdated, values])

    useEffect(() => {
        dispatch(getTestCategories())
    }, [])

    const onSubmit = (event) => {
        event.preventDefault();
        handleSubmit(false, true);
    };

    const onSubmitAndRedirect = (event) => {
        event.preventDefault();
        handleSubmit(true, false);
    };

    const setSportHeightAndWeight = (sportId) => {
        let filterAddedCats = [];
        const cloneAddedCats = Object.assign([], addedCats);
        if (sportsCode[sportId]) {
            cloneAddedCats.forEach(function (values, index) {
                if (sportsCode[sportId] === 'INTLRUGBY' || sportsCode[sportId] == 'INTLNETBALL') {
                    if (values.code == 'HEIGHT') {
                        delete cloneAddedCats[index];
                        filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICHEIGHT'));
                    }
                    if (values.code == 'WEIGHT') {
                        delete cloneAddedCats[index];
                        filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICWEIGHT'));
                    }
                } else {
                    if (values.code == 'METRICHEIGHT') {
                        delete cloneAddedCats[index];
                        filterAddedCats.push(standardTestCats.find(ar => ar.code === 'HEIGHT'));
                    }
                    if (values.code == 'METRICWEIGHT') {
                        delete cloneAddedCats[index];
                        filterAddedCats.push(standardTestCats.find(ar => ar.code === 'WEIGHT'));
                    }
                }
                filterAddedCats.push(cloneAddedCats[index]);
            });
        }
        setAddedCats(filterAddedCats.filter(item => item != null));
    }

    const onRemoveCat = cat => () => {
        setAddedCats((addedCats) => {
            return addedCats.filter(c => c.id !== cat.id)
        });
    };

    const onCategoryPicked = key => (value) => {
        const cat = standardTestCats.find(c => c.code === key);
        if (value) {
            setAddedCats((addedCats) => {
                return [...addedCats, cat];
            });
            setValues((values) => {
                return Object.assign({}, values, {
                    [key]: value,
                })
            });

        } else {

            setAddedCats((addedCats) => {
                return addedCats.filter(c => c.id !== cat.id);
            });
            setValues((values) => {
                return Object.assign({}, values, {
                    [key]: value,
                })
            });
        }
    }

    const setRequiredAndOptional = (sportId) => {
        const required = sportedCats.filter(c => !!c.adultRateable && c.sportId === sportId);
        const optional = standardTestCats.filter(c => !required.find(r => r.code === c.code))
        setRequired(required);
        setOptional(optional);
    }

    const handleSubmit = (redirectToCombine = false, redirectToCombineDataEntry = false) => {

        const {schoolId, combineId} = match.params;
        const form = formRef.current;
        const errors = {
            sport: combineTemplate ? [] : checkRequired(form.sport.value),
            cat: addedCats.length ? null : ['Please select at least one test'],
        };
        let success = true;
        Object.values(errors).forEach((errorList) => {
            if (errorList && errorList.length) {
                success = false;
            }
        });
        setErrors(errors);
        if (success) {
            const formStdTestCats = addedCats.map(c => c.id);
            const date = new Date().toJSON().slice(0, 10);
            const formData = {
                name: `${Sports[form.sport.value]} TEMPLATE ${date}`,
                description: "DESCRIPTION",
                school_uuid: schoolId,
            };
            if (!combineTemplate) {
                formData.sport_id = form.sport.value;
            }


            const curStdTestCategories = combineTemplate.standardTestCategories
                .map(c => c.standard_test_category_id);
            const newCats = formStdTestCats.filter(el => !curStdTestCategories.includes(el));
            const removedCats = curStdTestCategories.filter(el => !formStdTestCats.includes(el));

            dispatch(updateCombineTest(
                formData,
                combineTemplate.combineTemplateId,
                newCats,
                removedCats,
                schoolId,
                redirectToCombine,
                combineId,
                redirectToCombineDataEntry
            ));

        }
    };

    return (
        <React.Fragment>
            <form
                ref={formRef}
                onSubmit={onSubmit}
                className="CombineTemplate_Form"
                style={{display: loading ? 'none' : ''}}
            >
                <div className="CombineTemplate_Cols">
                    <div className="CombineTemplate_LeftCol">
                        <div className="CombineTemplate_MetaData">
                            {
                                <SelectControl
                                    label="Sport"
                                    options={sports}
                                    name="sport"
                                    style={{zIndex: 5, display: "none"}}
                                    defaultValue={values.sport}
                                />
                            }
                        </div>
                        <CombineLister
                            title="Speed, Agility, Performance, & Strength Tests"
                            help={
                                <React.Fragment>
                                    These tests are industry standard to measure your<br/>
                                    athlete's overall athleticism and strength
                                </React.Fragment>
                            }
                            missingMsg="Please select a sport"
                            subbanks={Object.values(organizedSubbanks)}
                            cats={required}
                            filters={{adultRateable: 1, sportId: values.sport || 1}}
                            selectedCats={addedCats}
                            onChange={onCategoryPicked}
                        />
                        <div style={{marginBottom: 35}}/>
                        <CombineLister
                            title="Sport Specific/Optional Tests"
                            help="Add as many of the tests below as you'd like"
                            subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode !== 'PHYSIQUE')}
                            cats={optional}
                            filters={{}}
                            selectedCats={addedCats}
                            onChange={onCategoryPicked}
                        />
                        <div style={{marginBottom: 35}}/>
                        <CombineLister
                            title="Measureables"
                            help={
                                <React.Fragment>
                                    {"Add as many of the measurables below as you'd like"}<br/>
                                    Height and weight are required to generate a VC Score
                                </React.Fragment>
                            }
                            subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode === 'PHYSIQUE')}
                            cats={standardTestCats}
                            filters={{adultRateable: 0}}
                            selectedCats={addedCats}
                            onChange={onCategoryPicked}
                        />
                    </div>
                    <div className="CombineTemplateWrapper_CategoryContainer">
                        <div className="CombineTemplateWrapper_CategoryBadges">
                            {
                                addedCats.map(cat => (
                                    <CatButton key={cat.id} onClick={onRemoveCat} cat={cat}/>
                                ))
                            }
                        </div>
                        <ControlErrors errors={errors.cat}/>
                    </div>
                </div>
                <div className="CombineTemplate_Btns">
                    {

                        <ButtonRect
                            type="submit"
                            onClick={onSubmit}
                            className="secondary"
                            style={{width: '45%', minWidth: 200}}
                        >
                            Save and Run Combine
                        </ButtonRect>

                    }


                </div>
            </form>
        </React.Fragment>
    )

}

export default EditCreateCombineTemplate;
