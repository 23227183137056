import React, {PureComponent,createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Icon from '../../../../../components/UxCommon/Icon/Icon'
import EditButton from "../../../../CoachWorld/common/EditButton/EditButton.component";

export default (props) => {
  const buttonClicked = () => {
   const {addClickCoachCommentHandler} = props.context.componentParent ;
   addClickCoachCommentHandler(props.data);
  };

  return (
    (props.data.coachComment && props.data.coachComment !== '') ?
      <div>
      <div className='CoachComment_ellipsis'>
        <span>{props.data.coachComment}</span>
        <EditButton className='ml-2' iconName="edit"
                    onClick={() => buttonClicked()}/>
      </div>
    </div>
    :
    <button
      onClick={() => buttonClicked()}
        className="GridView_Blue_Secondary_IconButton addHeadCoach">
        <div style={{marginRight: 3}}>
          <Icon iconName="plus"/>
        </div>
        <div>
          <div>Add Comment</div>
        </div>
    </button>
    );
};
