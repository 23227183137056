import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader, TableRow, TableCell, TableBody } from '../../common/Table';
import { changeHeaderName } from '../Common/utils';

class BlueTable extends PureComponent {
  static propTypes = {
    topRow: PropTypes.array.isRequired,
    sections: PropTypes.array.isRequired,
  };

  getFirstColWidth = () => '20%'

  getColWidth = numCols => `${(100 - 20) / numCols}%`

  // Team or Athlete Strength or Performance Reports
  render() {
    const {
      topRow, sections,
    } = this.props;
    return (
      <Table className="BlueReportTable" style={{ height: 'unset', marginBottom: 30, marginTop: 45 }}>
        <TableHeader>
          <TableRow>
            <TableCell style={{ width: this.getFirstColWidth() }} />
            {
              topRow[0].map((cell, index) => (
                <TableCell
                  className="CoachBlueTableCatTop"
                  key={index}
                  colSpan={cell.colSpan}
                >
                  {cell.name}
                </TableCell>
              ))
            }
          </TableRow>
          <TableRow>
            <TableCell style={{ width: this.getFirstColWidth() }} />
            {
              topRow[1].map((cell, index, arr) => (
                <TableCell
                  className="CoachBlueTableCatBot"
                  key={index}
                  style={{ width: this.getColWidth(arr.length) }}
                >
               { changeHeaderName(cell.name, cell.code) }
                </TableCell>
              ))
            }
          </TableRow>
        </TableHeader>
        <TableBody fixedHeaders={false}>
          {
            sections.map((section, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <td
                    className="CombineReportBlueTableSectionHead"
                    colSpan={section.colSpan}
                  >
                    {section.name}
                  </td>
                </TableRow>
                {
                  section.rows.map((row, index2) => (
                    <TableRow key={index2}>
                      <TableCell className="BlueReportTableFirstCol" style={{ width: this.getFirstColWidth() }}>
                        {row[0]}
                      </TableCell>
                      {
                        row.slice(1).map((cell, index3) => (
                          <TableCell key={index3}>
                            {cell}
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  ))
                }
              </React.Fragment>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

export default BlueTable;
