import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../UxCommon/Icon/Icon';
import ButtonLink from '../../../UxCommon/ButtonLink/ButtonLink';

class ConfirmMessage extends PureComponent {
  static propTypes = {
    msg: PropTypes.any.isRequired,
    btnMsg: PropTypes.string,
    to: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
  };

  onClose = () => {
    const { onClose, id } = this.props;
    onClose(id);
  }

  render() {
    const {
      msg,
      btnMsg,
      to,
    } = this.props;
    return (
      <div className="P_TopBanner_ConfirmMsg_Msg">
        <div className="P_TopBanner_ConfirmMsg_MsgTxt">
          <Icon iconName="alert" className="P_TopBanner_ConfirmMsg_MsgIcon" />
          {msg}
        </div>
        {
          btnMsg  && (
            <ButtonLink
              className="P_TopBanner_ConfirmMsg_MsgBtn"
              to={to}
              onClick={this.onClose}
            >
              {btnMsg}
            </ButtonLink>
          )
        }
        <button
          className="P_TopBanner_ConfirmMsg_CloseBtn"
          onClick={this.onClose}
        >
          <Icon className="P_TopBanner_ConfirmMsg_CloseBtnIcon" iconName="close" />
        </button>
      </div>
    );
  }
}

export default ConfirmMessage;
