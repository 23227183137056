import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../UxCommon/Icon/Icon';

const EndorseIcon = ({
  onClick,
  endorsements,
  className,
}) => (
  <button
    className={[
      'Awards_EndorseIcon',
      className || '',
      endorsements > 0 ? 'endorsed' : '',
    ].join(' ')}
    onClick={onClick}
  >
    <Icon className={['Awards_EndorseIcon_Icon', endorsements === 0 ? 'zero' : ''].join(' ')} iconName="hookem" />
    {
      (endorsements > 0) && (
        <div className="Awards_EndorseIcon_Num">
          {endorsements}
        </div>
      )
    }
  </button>
);
EndorseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  endorsements: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default EndorseIcon;
