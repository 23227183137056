import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/PlayerData/Stats/AddSeasonForm/AddSeasonForm';
import {
  hideAddSeasonForm,
  statsAddSeasonFormModel,
  statsAddSeasonFormObject,
} from '../../../store/actions/ui/uxPlayerData';
import { formSubmit } from '../../../store/actions/ui/formUtils';

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onCancel: () => {
      const { position, team } = ownProps;
      dispatch(hideAddSeasonForm(team.id, position.id));
    },
    submit: (rowId) => {
      const { position, team, currentSport } = ownProps;
      dispatch(formSubmit(
        statsAddSeasonFormModel(team.id, position.id),
        {
          teamId: team.id,
          positionId: position.id,
          sportId: currentSport.id,
          rowId,
        },
      ));
    },
    getRrfFormModel: () => {
      const { position, team } = ownProps;
      return statsAddSeasonFormModel(team.id, position.id);
    },
    getRrfFormObject: () => {
      const { position, team } = ownProps;
      return statsAddSeasonFormObject(state, team.id, position.id);
    },
    onSeasonStartChange: (date) => {
      const { position, team } = ownProps;
      dispatch(actions.change(`${statsAddSeasonFormModel(team.id, position.id)}.seasonStart`, date));
    },
    onSeasonEndChange: (date) => {
      const { position, team } = ownProps;
      dispatch(actions.change(`${statsAddSeasonFormModel(team.id, position.id)}.seasonEnd`, date));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
