import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapsable, ButtonRect } from '../../../../UxCommon';
import TagItem from '../../SideBar/Tags/TagItem';

class TagsForm extends PureComponent {
  static propTypes = {
    onAddTagClick: PropTypes.func,
    rrfForm: PropTypes.object.isRequired,
    onDeleteTagClick: PropTypes.func.isRequired,
    tags: PropTypes.array,
  };

  render() {
    const {
      onAddTagClick,
      rrfForm,
      onDeleteTagClick,
      tags,
    } = this.props;

    return (
      <div className="Photos_AlbumDialog_AlbumForm">
        <div className="UxProfile_Photos_AlbumDialog_LeftColBottom">
          <Collapsable
            title="Create a tag"
            keepInside
            topRowStyle={{ minHeight: '59px' }}
          >
            <div />
            <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
              <TagItem
                isEditMode
                isInModal
                isNewTagEnable
                rrfForm={rrfForm}
              />
              <ButtonRect
                style={{ marginLeft: 'auto', width: '100px', float: 'right' }}
                className="Photos_ExpandedPhotoDialog_Button"
                onClick={onAddTagClick}
                type="button"
              >
              Create Tag
              </ButtonRect>
            </div>
          </Collapsable>
          {
          !!tags.length && (
            <Collapsable
              title="Edit Tags"
              keepInside
              topRowStyle={{ minHeight: '59px' }}
            >
              <div />
              <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                { tags.map((tag, index) => (
                  <TagItem
                    key={index}
                    tag={tag}
                    isEditMode
                    isInModal
                    rrfForm={rrfForm}
                    onTagDelete={onDeleteTagClick}
                    isNewTagEnable={false}
                  />
                  ))
                }
              </div>
            </Collapsable>
          )
        }
        </div>
      </div>
    );
  }
}

export default TagsForm;
