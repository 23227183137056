import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Routes } from '../../../../../store/actions/ui/routes';
import Icon from '../../../../UxCommon/Icon/Icon';

class HeaderMenuLink extends PureComponent {
  static propTypes = {
    iconName: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    nudge: PropTypes.object,
    match: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  componentDidMount() {}

  render() {
    const {
      page,
      iconName,
      nudge,
      className,
      match,
      ...other
    } = this.props;
    return (
      <NavLink
        className={['PageHeader_HeaderMenuLink', className || ''].join(' ')}
        to={`/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}`}
        activeClassName="selected"
        {...other}
      >
        <Icon
          iconName={iconName}
          className="PageHeader_HeaderMenuLink_Icon"
          style={nudge || {}}
        />
      </NavLink>
    );
  }
}

export default HeaderMenuLink;
