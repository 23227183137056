import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil,  take, tap, filter, retry, delay,repeat,timeout } from 'rxjs/operators';
import { forkJoin, of, interval } from 'rxjs';

import {
  getAlbum,
  getAlbums,
  getPhotos,
  getTag,
  getTags,
  getVideos,
  getVideo,
  mapAlbumsApiToUi,
  mapPhotosVideosAlbumsApiToUi,
  mapVideoAndAlbumsApiToUi,
  photoGetMediaSuccess,
} from '../../../../data/photo';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import {
  cancelOnRouteChange,
  checkForkErrors,
  commonApiCallFragment,
  getTokenFragment,
} from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { GET_VIDEO, LOAD_MEDIA } from '../actions';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

export const tagsPolling = (
  action$,
  action,
  errorLabel,
  page,
  token,
) => source => source.pipe(
  map(response => ({ action, success: true, response })),
  takeUntil(cancelOnRouteChange(action$, page, () => action.uuid)),
  ajaxErrorHandlerEpicFragment(),
  catchError(error => of({
    success: false,
    action: asyncErrorAction(action.type, errorLabel, error),
  })),
  switchMap((result) => {
    if (result.success) {
      if (result.response.length) {
        return forkJoin(...result.response.map(tag => (
          getTag(tag.id, token).pipe(
            map(response => ({ success: true, tag, tagDetailsResponse: response })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => action.uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(action.type, 'getTagDetails', error),
            })),
          )
        ))).pipe(checkForkErrors());
      }
      return of({ success: true, results: [] });
    }
    return of(result);
  }),
);

export const albumsPolling = (
  action$,
  action,
  errorLabel,
  page,
  token,
) => source => source.pipe(
  map(response => ({ action, success: true, response })),
  takeUntil(cancelOnRouteChange(action$, page, () => action.uuid)),
  ajaxErrorHandlerEpicFragment(),
  catchError(error => of({
    success: false,
    action: asyncErrorAction(action.type, errorLabel, error),
  })),
  switchMap((result) => {
    if (result.success) {
      const albums = mapAlbumsApiToUi(result.response);
      if (albums && albums.length) {
        return forkJoin(...albums.map(album => (
          getAlbum(album.id, token).pipe(
            map(response => ({ success: true, album, albumDetailsResponse: response })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => action.uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(action.type, 'getAlbumDetails', error),
            })),
          )
        ))).pipe(checkForkErrors());
      }
      return of({ success: true, results: [] });
    }
    return of(result);
  }),
);

const getMediaEpic = action$ => (
  action$.pipe(
    ofType(LOAD_MEDIA),
    getTokenFragment(),
    // takeUntil(action$.ofType(LOAD_MEDIA_CANCELLED)),
    switchMap(({ action, token }) => forkJoin(
      getVideos(action.uuid, token).pipe(commonApiCallFragment(action$, action, 'getVideos', Routes.photos)),
      getPhotos(action.uuid, token).pipe(commonApiCallFragment(action$, action, 'getPhotos', Routes.photos)),
      getTags(action.uuid, token).pipe(tagsPolling(action$, action, 'getTags', Routes.photos, token)),
      getAlbums(action.uuid, token).pipe(albumsPolling(action$, action, 'getAlbums', Routes.photos, token)),
    ).pipe(
      checkForkErrors(),
      map((forkResults) => {
        if (forkResults.success) {
          return {
            ...forkResults,
            action,
            token,
          };
        }
        return forkResults;
      }),
    )),
    switchMap((results) => {
      if (results.success) {
        const {
          photos,
          videos,
          albums,
          tags,
          albumRelMediaCollection,
          tagRelMediaCollection,
        } = mapPhotosVideosAlbumsApiToUi(
          results.results[1].response,
          results.results[0].response,
          results.results[3].results.map(result => result.albumDetailsResponse),
          results.results[2].results.map(result => result.tagDetailsResponse),
        );

        return of(
          photoGetMediaSuccess(
            photos,
            videos,
            albums,
            tags,
            albumRelMediaCollection,
            tagRelMediaCollection,
            results.action.uuid,
          ),
          asyncFinishAction(results.action.type, 'getMedia', {}),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);

const getVideoEpic = action$ => (
  action$.pipe(
    ofType(GET_VIDEO),
    getTokenFragment(),
    switchMap(({ action, token }) =>  {
      return getVideo(token, action.videoId).pipe(
        commonApiCallFragment(action$, action, 'getVideo', Routes.photos),
    )}),
    switchMap(result => {
      if (result.success) {
        const data =  mapVideoAndAlbumsApiToUi(result.response);
        return of( asyncFinishAction(result.action.type, 'getVideo', data))
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    })
));


export const readEpics = combineEpics(getMediaEpic,getVideoEpic);

export const dummy = {};
