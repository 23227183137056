import { connect } from 'react-redux';
import Component from '../../components/UxProfile/UxProfile';
import { logout } from '../../store/actions/data/cognito/auth';
import {
  playerProfileGet,
  sidebarHideLogoutDialog,
  sidebarCloseShareDialog,
  getCoachData, playerProfileActivationMembershipModal, enableCalendlyModal
} from "../../store/actions/ui/uxProfile";
import { getSelectedSport, getProfile, getCurrentUuid } from '../UxCommon/Utils';
import {
  routeChangedAlert,
  updateSportIdInStore,
  updateSchoolIdStore,
  profileShareUri,
  redirectUrl, redirect
} from "../../store/actions/ui/routes";
import { checkIfCanEdit } from '../../store/actions/ui/app';
import {getAllExternalProfile} from "../../store/actions/ui/uxProfile/externalProfile";

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    isCoach,
    canEditProfile,
    uuid: isCoach ? getCurrentUuid(state, ownProps.match) : state.data.cognito.uuid,
    showActivateMembershipModal: state.ui.uxProfile.playerProfile.showActivationMembershipModal,
    coachUuid: isCoach ? state.data.cognito.uuid : '',
    selectedUserSport: getSelectedSport(state, ownProps.match),
    authenticating: state.ui.app.mobile.authenticating,
    currentRoute: ownProps.currentRoute || state.ui.app.routes.currentRoute,
    loadingProfile: state.ui.app.mobile.loadingProfile,
    isLogoutDialogShown: state.ui.uxProfile.sidebar.isLogoutDialogShown,
    shareDialogUri: state.ui.uxProfile.sidebar.shareDialogUri,
    profile: getProfile(state, ownProps.match),
    sports: state.data.sport.sports,
    webLoadingProfile: state.ui.uxProfile.playerProfile.loadingMsg,
    loggedIn: !!state.data.cognito.token,
    isCalendlyModalShown: state.ui.uxProfile.sidebar.isCalendlyModalShown,
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid,
    state,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const { selectedUserSport } = newStateProps;
  return {
    ...newStateProps,
    ...ownProps,
    profileShareUri: selectedUserSport ? profileShareUri(
      getCurrentUuid(state, ownProps.match),
      newStateProps.selectedUserSport.sportId,
    ) : undefined,
    signOut: () => {
      dispatch(sidebarHideLogoutDialog());
      dispatch(logout());
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    toggleActivationMembershipModal: (toggle)=>{
      dispatch(playerProfileActivationMembershipModal(toggle));
    },
    getExternalProfile: (uuid) => {
      dispatch( getAllExternalProfile(uuid) )
    },
    updateSportIdInStore: () => {
      if (stateProps.selectedUserSport) {
        const { sportId } = stateProps.selectedUserSport;
        dispatch(updateSportIdInStore(sportId));
      }
    },
    updateSchoolIdInStore: (schoolId)=>{
      dispatch(updateSchoolIdStore(schoolId))
    },
    hideLogoutDialog: () => { dispatch(sidebarHideLogoutDialog()); },
    onCloseShareDialogClick: () => {
      dispatch(sidebarCloseShareDialog());
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    checkIfCanEdit: (uuid) => {
      dispatch(checkIfCanEdit(uuid));
    },
    getCoachData: (uuid)=> {
      dispatch(getCoachData(uuid));
    },
    redirect: (history, to) => {
      dispatch(redirect(history, to));
    },
    updateCalendlyModal: () => {
      dispatch(enableCalendlyModal(false))
    }
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
