export const AWARD_CREATE_SUC = 'award.create.success';
export const awardCreateSuccess = (award, uuid) => ({
  type: AWARD_CREATE_SUC,
  award,
  uuid,
});

export const AWARD_UPDATE_SUC = 'award.update.success';
export const awardUpdateSuccess = (award, uuid) => ({
  type: AWARD_UPDATE_SUC,
  award,
  uuid,
});

export const AWARD_ENDORSE_SUC = 'award.endorse.success';
export const awardEndorseSuccess = (award, uuid) => ({
  type: AWARD_ENDORSE_SUC,
  award,
  uuid,
});

export const AWARD_UNENDORSE_SUC = 'award.unendorse.success';
export const awardUnendorseSuccess = (award, uuid) => ({
  type: AWARD_UNENDORSE_SUC,
  award,
  uuid,
});

export const AWARDS_GET_SUC = 'awards.getAll.success';
export const awardsGetAllSuccess = (awards, uuid) => ({
  type: AWARDS_GET_SUC,
  awards,
  uuid,
});

export const AWARD_DEL_SUC = 'award.delete.success';
export const awardDeleteSuccess = (awardId, uuid) => ({
  type: AWARD_DEL_SUC,
  awardId,
  uuid,
});

export const AWARDS_GET_FAVS_SUC = 'award.getFavorites.success';
export const awardGetFavoritesSuccess = (awards, uuid, sportId) => ({
  type: AWARDS_GET_FAVS_SUC,
  awards,
  uuid,
  sportId
});

