import React from 'react';
import PropTypes from 'prop-types';


const CustomIcon = ({
  className, iconName, style, tooltip,place
}) => (
  <React.Fragment>
    <i
      id={`${iconName}-menu-icon`}
      className={[`icon-${iconName}-icon`, className].join(' ')}
      style={style || {}} 
    />
    <div className={["custom_tooltip", place].join(' ')}>
       {tooltip}
    </div>
  </React.Fragment>
);
CustomIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
};

export default CustomIcon;
