import React from 'react';
import PropTypes from 'prop-types';
import CategoryPicker from '../CategoryPicker/CategoryPicker.component';

const CombineLister = (props) => {
  const {
    title,
    help,
    subbanks,
    cats,
    filters,
    selectedCats,
    onChange,
    missingMsg,

  } = props;
  let filteredCats = cats.slice();
  Object.entries(filters).forEach(([key, value]) => {
    filteredCats = filteredCats.filter(c => (c[key] === value));
  });
  return (
    <div className="CombineTemplate_OptionalTests">
      <div className="CombineTemplate_Header">{title}</div>
      {
        filteredCats.length ? (
          <div className="CombineTemplate_Help">
            {help}
          </div>
        ) : (
          <div className="CombineTemplate_Help">
            {missingMsg}
          </div>
        )
      }
      <div style={{ marginBottom: 16 }} />
      <div className="CombineTemplate_Pickers">
        {
          subbanks.map((subbank) => {
            const subbankCats = filteredCats
              .filter(c => (c.subbankCode === subbank.code));
            if (!subbankCats.length) return null;
            return (
              <CategoryPicker
                key={subbank.code}
                code={subbank.code}
                name={subbank.name}
                cats={subbankCats}
                selectedCats={selectedCats}
                onChange={onChange}
                // isEnableToEdit={isEnableToEdit}
              />
            );
          })
        }
      </div>
    </div>
  );
};
CombineLister.propTypes = {
  title: PropTypes.string,
  help: PropTypes.any,
  subbanks: PropTypes.array,
  cats: PropTypes.array,
  filters: PropTypes.object,
  selectedCats: PropTypes.array,
  onChange: PropTypes.func,
  missingMsg: PropTypes.string,
};

export default CombineLister;
