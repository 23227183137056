import { connect } from 'react-redux';
import Component from './SportsPage.component';
import { saveSports, getSchoolSportTeamsList } from '../+store/sportsPage/sportsPage.actions';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';
import { checkSignUpCode } from '../+store/codeSignUp/codeSignUp.actions';

const mapStateToProps = (state) => {
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return {
    sports: state.data.sport.sports,
    uuid: state.data.cognito.uuid,
    profile,
    teamTypes: state.modules.codeSignUp.codeSignUp.teamTypes,
    schoolInfo: state.modules.codeSignUp.codeSignUp.schoolInfo,
    sportTeamList: state.modules.codeSignUp.sportsPage.sportTeamList,
  };
};

const mapDispatchToProps = dispatch => ({
  saveSports: (params, isMobile = false) => {
    dispatch(saveSports(params, isMobile));
  },
  getUserProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  checkCode: (code, isMobile = false) => {
    dispatch(checkSignUpCode(code, isMobile));
  },
  getSportTeam: (schoolId, sportId) => {
    dispatch(getSchoolSportTeamsList(schoolId, sportId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
