import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  CloseButton,
  LoadingSpinner,
  ButtonRect,
} from '../../../UxCommon';
import TagItem from '../SideBar/Tags/TagItem';

class TagDialog extends PureComponent {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    onAddTagClick: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onDeleteTagClick: PropTypes.func.isRequired,
    onSaveTagsClick: PropTypes.func.isRequired,
  };

  componentWillMount() {}

  render() {
    const {
      onCloseClick,
      loading,
      loadingMsg,
      tags,
      onAddTagClick,
      rrfForm,
      onDeleteTagClick,
      onSaveTagsClick,
    } = this.props;
    return (
      <div className="Photos_SelectAlbumDialog_Backdrop TagDialog">
        <div className="Photos_SelectAlbumDialog">
          <CloseButton
            onClick={onCloseClick}
            size={10}
            style={{ top: 22, right: 18 }}
          />
          <div className="Photos_SelectAlbumDialog_Title">
            Create / Edit Tags
          </div>
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
            style={{ backgroundColor: 'rgba(256, 256, 256, 0.7)' }}
          />
          <div className="Photos_SelectAlbumDialog_Albums">
            { tags.map((tag, index) => (
              <TagItem
                key={index}
                tag={tag}
                isNewTagEnable={false}
                isEditMode
                isInModal
                rrfForm={rrfForm}
                onTagDelete={onDeleteTagClick}
              />
              ))
            }
            <TagItem
              isEditMode
              isInModal
              isNewTagEnable
              rrfForm={rrfForm}
            />
          </div>
          <div className="Photos_SelectTagDialog_BtnsRow">
            <ButtonRect
              className="Photos_SelectAlbumDialog_Btn"
              onClick={onAddTagClick}
            >
              Add Tag
            </ButtonRect>
            <ButtonRect
              className="Photos_SelectAlbumDialog_Btn TagFlex primary"
              onClick={onSaveTagsClick}
            >
              Save Tags
            </ButtonRect>
          </div>
        </div>
      </div>
    );
  }
}

export default TagDialog;
