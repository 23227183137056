import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { capitalizeLetterAfterSpaceHyphen } from '../../../../store/actions/ui/formUtils/validators';
import {
  batOptions,
  positions,
} from '../../../../components/UxCommon/Form/utils';
import { formatBirthday, formatPhone } from '../../../../utils';
import {
  addressValidation,
  socialHandleValidation,
  validateAlphaNumericWithSpecialCharacter,
  validateBirthday,
  validateEmail,
  validateHeightFtInteger,
  validateHeightInchInteger,
  validateInteger,
  validateIntegerWeight,
  validateLetterNumberSpecialCharacter,
  validateLetters,
  validateLettersWithRMSpecialCharacter,
  validateNumber,
  validatePhone,
  validateRequired,
  validateTwitter,
  validateZipcode,
  validateGradYear,
  formatPositionResponse,
} from '../../../../store/actions/ui/formUtils/validators';
import { Routes, SportIds } from '../../../../store/actions/ui/routes';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import ErrorToolTipRenderer from '../../common/ErrorToolTipRenderer/ErrorToolTipRenderer.component';
import ConfirmDialog from '../../common/ConfirmDialog/ConfirmDialog.component';
import ChangeTeamDialog from '../../common/ChangeTeamDialog/ChangeTeamDialog.component';
import PrintFieldSelector from '../../common/PrintFieldSelector/PrintFieldSelector.component';
import PrintRoster from '../../common/PrintFieldSelector/PrintRoster.component';
import AddTeamModal from '../../Dashboard/AddTeamModal/AddTeamModal.component';
import AddCoachCommentModal from '../AddCoachCommentModal/AddCoachCommentModal.component';
import HeadCoachModal from '../HeadCoachModal';
import RecruitingCoordinatorModal from '../RecruitingCoordinatorModal';
import EditButton from '../../common/EditButton';
import AddCoachCommentModalRender from './Renderer/AddCoachCommentModalRender';
import PositionCellComponentEditor from './Editor/PositionCellComponentEditor';
import TeamTwitterLinkModal from '../TeamTwitterLinkModal';
import moment from 'moment';
import AlertDialog from '../AlertDialog';
import ConfirmAthleteModal from '../ConfirmAthleteModal/ConfirmAthleteModal';
import InviteButtonComponentRenderer from "./Renderer/InviteButtonComponentRenderer";
import queryString from "query-string";
import LoadingIndicator from "../../common/LoadingIndicator";

const genders = ['Male', 'Female'];

const grades = [
  '12th',
  '11th',
  '10th',
  '9th',
  '8th',
  '7th',
  '6th',
  '5th',
  '4th',
  '3rd',
  '2nd',
  '1st',
  'K',
  '13th',
  'Redshirt Freshman',
  'College Freshman',
  'College Sophomore',
  'College Junior',
  'College Senior',
];

const gradYear = [
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
  '2031',
  '2032',
  '2033',
  '2034',
  '2035',
  '2036',
  '2037',
  '2038',
  '2039',
  '2040'
]

export const sortedGrade = {
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  13: '13th',
  12: '12th',
  11: '11th',
  10: '10th',
  9: '9th',
  8: '8th',
  7: '7th',
  6: '6th',
  5: '5th',
  4: '4th',
  3: '3rd',
  2: '2nd',
  1: '1st',
  K: 'K',
};

const gradesMap = {
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  '13th': 13,
  '12th': 12,
  '11th': 11,
  '10th': 10,
  '9th': 9,
  '8th': 8,
  '7th': 7,
  '6th': 6,
  '5th': 5,
  '4th': 4,
  '3rd': 3,
  '2nd': 2,
  '1st': 1,
  K: 'K',
};

const batsThrows = ['Right', 'Left', 'Switch'];

const batThrowMap = {
  Right: 'R',
  Left: 'L',
  Switch: 'S',
};

const EmailRenderer = (params) => (
  <span>
    {params.value && (
      <div className="GridView_Email_Renderer">
        <a href={`mailto:${params.value}`}>
          <Icon iconName="mail" />
        </a>
        <div className="GridView_Grid_Email_Cell">{params.value}</div>
      </div>
    )}
  </span>
);

const BtnCellRenderer = (params) => (
  <span>
    {params.value && (
      <div className="GridView_Email_Renderer">
        <a href={`mailto:${params.value}`}>
          <Icon iconName="mail" />
        </a>
        <div className="GridView_Grid_Email_Cell">{params.value}</div>
      </div>
    )}
  </span>
);

const PhoneRenderer = (params) => (
  <span>
    <div className="GridView_Email_Renderer">
      <a href={`tel:${params.value}`}>
        <Icon iconName="phone" />
      </a>
      <div className="GridView_Grid_Email_Cell">{params.value}</div>
    </div>
  </span>
);

const isInvitePending = (status) => status !== 2;

class ReconfigureTeamGridView extends PureComponent {
  static propTypes = {
    athletes: PropTypes.array.isRequired,
    sportId: PropTypes.number.isRequired,
    profiles: PropTypes.object.isRequired,
    updateAthleteProfileInfo: PropTypes.func.isRequired,
    school: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    removeUserFromTeam: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    userProfileId: PropTypes.string,
    invite: PropTypes.func.isRequired,
    getProfileIfExists: PropTypes.func.isRequired,
    schoolTeams: PropTypes.array.isRequired,
    savePlayer: PropTypes.func.isRequired,
    saveUserTeam: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    schoolStyles: PropTypes.array.isRequired,
    coachCode: PropTypes.string,
    athleteCode: PropTypes.string,
    currentExistingProfile: PropTypes.object,
    confirmMergeProfile: PropTypes.func,
    updateUserSport: PropTypes.func.isRequired,
    myTeam: PropTypes.object,
    setBulkInviteState: PropTypes.func.isRequired,
  };

  state = {
    errors: {},
    emailExistsErrors: {},
    showConfirmDialog: false,
    showChangeTeamDialog: false,
    showPrintDialog: false,
    confirmMsg: '',
    confirmTitle: '',
    confirmBtnMsg: '',
    confirmOnYesClick: null,
    confirmOnCancelClick: null,
    buttonsActive: false,
    clipboardMessage: '',
    isCoachModalVisible: false,
    tempUserId: null,
    totalColumn: [],
    showHeadCoachModal: false,
    showTeamTwitterLinkModal: false,
    showAddCoachModal: false,
    selectedAthleteId: '',
    showRecruitingCoordinatorModal: false,
    showCoachRCwitterDetails: false,
    showAlertDialog: false,
    undoState: [],
    undoStep: 0,
  };

  onConfirmPlayer = () => {
    const { tempUserId } = this.state;
    const { currentExistingProfile, confirmMergeProfile, sportId, match } =
      this.props;
    confirmMergeProfile(
      currentExistingProfile.uuid,
      tempUserId,
      sportId,
      match.params.schoolId,
    );
    this.setState({ tempUserId: null });
  };

  onConfirmPlayerEnd = () => {
    const { tempUserId } = this.state;
    if (tempUserId) {
      this.setState({ tempUserId: null });
    }
  };

  isInviteStateUpdated = false;
  componentDidUpdate= ()=> {
    const {
      athleteInviteChunkState,
      athleteInviteChunkCount,
      setAthleteInviteChunkCount,
      school
    } = this.props;

    if(this.isInviteStateUpdated && !athleteInviteChunkState.length) { setAthleteInviteChunkCount(); return; }

    if (athleteInviteChunkState[athleteInviteChunkCount] && !athleteInviteChunkState[athleteInviteChunkCount].length) {
      this.isInviteStateUpdated = false;
      setAthleteInviteChunkCount();
    }

    if (!this.isInviteStateUpdated && athleteInviteChunkState[athleteInviteChunkCount] && athleteInviteChunkState[athleteInviteChunkCount].length) {
      this.isInviteStateUpdated = true;

      athleteInviteChunkState[athleteInviteChunkCount].forEach((athlete) => {
        if (this.isOnboarded(athlete)) return true;

        const athleteInfo = Object.assign({}, athlete);
        if (
          (school.isPaid ? !!athleteInfo.parentsEmail : true)
        ) {
          this.sendInvitationByCommonMethod(athleteInfo);
        }
      });

    }
  }

  onPasteEnd = async (params) => {
    const {
      updateAthleteProfileInfo,
      saveUserTeam,
      match,
      updateUserSport,
      athletes,
    } = this.props;
    params.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (
        this.pasteRowsIndexes.length > 0 &&
        this.pasteRowsIndexes.includes(index)
      ) {
        if (this.getErrors(rowNode.data, index)) {
          return false;
        }
        const athleteByUserIdArray = athletes.filter(
          (athlete) => athlete.userId === rowNode.data.userId,
        );
        const newProfile = {
          ...rowNode.data,
          email: rowNode.data && rowNode.data.email && rowNode.data.email.trim(),
          parentsEmail: rowNode.data && rowNode.data.parentsEmail && rowNode.data.parentsEmail.trim(),
          parent2Email: rowNode.data && rowNode.data.parent2Email && rowNode.data.parent2Email.trim(),
          gradYear: rowNode.data && rowNode.data.gradYear,
          grade: gradesMap[rowNode.data.grade],
          phone: formatPhone(rowNode.data.phone),
          birthdate: rowNode.data.birthdate
            ? formatBirthday(rowNode.data.birthdate)
            : '',
          parentsPhone: formatPhone(rowNode.data.parentsPhone),
          parents2Phone: formatPhone(rowNode.data.parents2Phone),
          heightFt: rowNode.data.heightFt
            ? rowNode.data.heightFt.replace(/'/g, '')
            : '',
          heightInch: rowNode.data.heightInch
            ? rowNode.data.heightInch.replace(/"/g, '')
            : '',
          teamName: rowNode.data.teamName,
          profileDetails:{
            club_team:rowNode.data.clubTeam || '',
            club_coach_email: rowNode.data.clubCoachEmail || '',
            club_coach_mobile_phone_number: formatPhone(rowNode.data.clubCoachMobilePhoneNumber) || '',
            club_coach_name:rowNode.data.clubCoachName || '',
            high_school_coach_email:rowNode.data.highSchoolCoachEmail || '',
            high_school_coach_name:rowNode.data.highSchoolCoachName || '',
            high_school_coach_phone_number: formatPhone(rowNode.data.highSchoolCoachPhoneNumber) || '',
          }
        };
        updateAthleteProfileInfo(rowNode.data.userId, newProfile);
        const userTeam = {
          position: rowNode.data.position,
          jerseyNumber: rowNode.data.jerseyNumber,
          combineNumber: rowNode.data.combineNumber,
        };
        saveUserTeam(
          userTeam,
          rowNode.data.id,
          rowNode.data.userId,
          match.params.schoolId,
        );
        const userSports = {
          commits: rowNode.data.commits,
        };
        // let selectedBatsOption='', selectedThrowsOption='';
        if (rowNode.data.bats) {
          const val = rowNode.data.bats;
          const selectedBatsOption = Object.entries(batOptions).find(
            ([key, value]) => {
              if (key === val || value === val) {
                return true;
              }
            },
          );
          if (selectedBatsOption) {
            userSports['bats'] = selectedBatsOption[0];
          }
        }

        if (rowNode.data.throws) {
          const val = rowNode.data.throws;
          const selectedThrowsOption = Object.entries(batOptions).find(
            ([key, value]) => {
              if (key === val || value === val) {
                return true;
              }
            },
          );
          if (selectedThrowsOption) {
            userSports['throws'] = selectedThrowsOption[0];
          }
        }
        const sportId = SportIds[match.params.sport];
        if (
          userSports.commits !== athleteByUserIdArray[0].commits ||
          userSports.bats ||
          userSports.throws
        ) {
          updateUserSport(
            userSports,
            rowNode.data.userId,
            sportId,
            rowNode.data.id,
          );
        }
      }
    });
    this.pasteRowsIndexes = [];
    this.pastedEmails = [];
  };

  onCellEditingStopped = async (event) => {
    const row = this.gridApi.getDisplayedRowAtIndex(event.node.rowIndex);
    const { emailExistsErrors } = this.state;

    if (event.source === 'paste') {
      this.pasteRowsIndexes = [...this.pasteRowsIndexes, event.rowIndex];
      this.pastedEmails = [...this.pastedEmails, event.value];
      return true;
    }
    const {
      profiles,
      updateAthleteProfileInfo,
      getProfileIfExists,
      saveUserTeam,
      match,
      athletes,
      updateUserSport,
    } = this.props;

    const profile = profiles[event.data.userId];
    const athlete = athletes.find((a) => a.userId === event.data.userId);
    if (!this.getErrors(event.data, event.rowIndex)) {
      if (event.oldValue !== event.newValue) {
        const newProfile = Object.assign({}, profile);
        let updated = true;
        console.log(newProfile,'newProfilenewProfile')
        switch (event.column.colId) {
          case 'email':
            if (event.value) {
              newProfile.email = event.value;
              this.setState({
                emailExistsErrors: {
                  ...emailExistsErrors,
                  [event.rowIndex]: '',
                },
              });
              event.colDef.cellStyle = {
                backgroundColor: 'transparent',
                fontWeight: 'normal',
              };
              this.gridApi.redrawRows({ rowNodes: [row] });
            }
            newProfile[event.column.colId] = event.value && event.value.trim();
            break;
          case 'phone':
          case 'parentsEmail':
            newProfile[event.column.colId] = event.value && event.value.trim();
            break;
          case 'parent2Email':
            newProfile[event.column.colId] = event.value && event.value.trim();
            break;
          case 'parentsPhone':
            newProfile[event.column.colId] = formatPhone(event.value);
            break;
          case 'parents2Phone':
            newProfile[event.column.colId] = formatPhone(event.value);
            break;
          case 'gradYear':
            newProfile[event.column.colId] = event.value;
            break;
          case 'grade':
            newProfile[event.column.colId] = gradesMap[event.value];
            break;
          case 'combineNumber':
            const obj = {
              ...athlete,
              combineNumber: event.value || '',
            };
            saveUserTeam(
              obj,
              athlete.id,
              athlete.userId,
              match.params.schoolId,
            );
            updated = false;
            break;
          case 'bats':
          case 'throws':
          case 'commits':
            const values = {};
            if (
              event.column.colId === 'bats' ||
              event.column.colId === 'throws'
            ) {
              values[event.column.colId] = batThrowMap[event.value] || '';
            } else {
              values[event.column.colId] = event.value || '';
            }
            const sportId = SportIds[match.params.sport];
            updateUserSport(values, athlete.userId, sportId, athlete.id);
            updated = false;
            break;
          case 'jerseyNumber':
          {
            const obj = {
              ...athlete,
              jerseyNumber: event.value || '',
            };
            saveUserTeam(
              obj,
              athlete.id,
              athlete.userId,
              match.params.schoolId,
            );
          }
            updated = false;
            break;
          case 'position':
          {
            const obj = {
              ...athlete,
              position: event.value || '',
            };
            saveUserTeam(
              obj,
              athlete.id,
              athlete.userId,
              match.params.schoolId,
            );
          }
            updated = false;
            break;
          case 'birthdate':
            newProfile[event.column.colId] = event.value
              ? formatBirthday(event.value)
              : '';
            break;
          case 'heightFt':
            newProfile[event.column.colId] = event.value
              ? event.value.replace(/'/g, '')
              : '';
            break;
          case 'heightInch':
            newProfile[event.column.colId] = event.value
              ? event.value.replace(/"/g, '')
              : '';
            break;
          case 'teamName':
            newProfile[event.column.colId] = event.newValue || event.oldValue;
            newProfile['isTeamNameChanged'] = true;
            break;
          case 'clubTeam':
            newProfile['profileDetails'].club_team = event.value || '';
            break;
          case'clubCoachName':
            newProfile['profileDetails'].club_coach_name = event.value || '';
            break;
          case'clubCoachEmail':
            newProfile['profileDetails'].club_coach_email = event.value || '';
            break;
          case'highSchoolCoachName':
            newProfile['profileDetails'].high_school_coach_name = event.value || '';
            break;
          case'highSchoolCoachEmail':
            newProfile['profileDetails'].high_school_coach_email = event.value || '';
            break;
          case'clubCoachMobilePhoneNumber':
            newProfile['profileDetails'].club_coach_mobile_phone_number = event.value ? formatPhone(event.value) : '';
            break;
          case 'highSchoolCoachPhoneNumber':
            newProfile['profileDetails'].high_school_coach_phone_number = event.value ? formatPhone(event.value) : '';
            break;
          default:
            newProfile[event.column.colId] = event.value || '';
            break;
        }
        if (updated) {
          updateAthleteProfileInfo(profile.userId, newProfile);
        }
      } else {
        this.setState({
          emailExistsErrors: {
            ...emailExistsErrors,
            [event.rowIndex]: '',
          },
        });
        event.colDef.cellStyle = {
          backgroundColor: 'transparent',
          fontWeight: 'normal',
        };
        this.gridApi.redrawRows({ rowNodes: [row] });
      }
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.hidePivotSection();
    this.gridColumnApi.autoSizeAllColumns(false);
  };

  onSelectionChanged = () => {
    const athletes = this.gridApi.getSelectedRows();
    if (athletes.length > 0) {
      this.setState({ buttonsActive: true });
    } else this.setState({ buttonsActive: false });
  };

  getErrors = (data, rowIndex = null) => {
    const errors = {};
    errors.first =
      validateRequired()(data.first).required;
    errors.last =
      validateRequired()(data.last).required;
    if (!data.gender) {
      errors.gender = 'Gender is required';
    } else if (!genders.includes(data.gender)) {
      errors.gender = 'Not a supported option.';
    }
    if (!data.grade) {
      errors.grade = 'Grade is required';
    } else if (!grades.includes(data.grade)) {
      errors.grade = 'Not a supported option.';
    }
    errors.email =
      !validateEmail()(data.email).email || !data.email
        ? ''
        : 'Email format is not correct';
    //errors.parentsName = validateRequired()(data.parentsName).required;
    errors.phone = data.phone ? validatePhone()(data.phone).phone : '';
    errors.combineNumber = data.combineNumber
      ? validateAlphaNumericWithSpecialCharacter()(data.combineNumber).valid
      : '';
    errors.commits = data.commits
      ? validateLetterNumberSpecialCharacter()(data.commits).valid
      : '';
    errors.jerseyNumber = data.jerseyNumber
      ? data.jerseyNumber.trim()
        ? validateAlphaNumericWithSpecialCharacter()(data.jerseyNumber.trim())
          .valid
        : ''
      : '';
    if(data.gradYear && !gradYear.includes(data.gradYear)){
      errors.gradYear = 'Not a supported option.';
    }else{
      errors.gradYear = '';
    }
    errors.twitter = data.twitter ? validateTwitter()(data.twitter).valid : '';
    errors.instagram = data.instagram
      ? socialHandleValidation()(data.instagram).valid
      : '';
    errors.ncaaIdNumber = data.ncaaIdNumber
      ? validateInteger()(data.ncaaIdNumber).integer
      : '';
    errors.parentsPhone = data.parentsPhone
      ? validatePhone()(data.parentsPhone).phone
      : '';
    errors.parentsEmail = data.parentsEmail
      ? validateEmail()(data.parentsEmail).email
      : '';
    errors.parents2Phone = data.parents2Phone
      ? validatePhone()(data.parents2Phone).phone
      : '';
    errors.parent2Email = data.parent2Email
      ? validateEmail()(data.parent2Email).email
      : '';
    errors.address = data.address
      ? addressValidation()(data.address).valid
      : '';
    errors.city = data.city ? validateLetters()(data.city).letters : '';
    errors.state = data.state ? validateLetters()(data.state).letters : '';
    //errors.school = data.school ? validateLettersWithRMSpecialCharacter()(data.school).valid : '';
    errors.coreGpa = data.coreGpa ? validateNumber()(data.coreGpa).number : '';
    errors.heightFt = data.heightFt
      ? validateHeightFtInteger()(data.heightFt).integer
      : '';
    errors.heightInch = data.heightInch
      ? validateHeightInchInteger()(data.heightInch).integer
      : '';
    errors.weight = data.weight
      ? validateIntegerWeight()(data.weight).integer
      : '';
    errors.act = data.act ? validateInteger()(data.act).integer : '';
    errors.sat = data.sat ? validateInteger()(data.sat).integer : '';
    errors.classRank = data.classRank
      ? validateInteger()(data.classRank).integer
      : '';
    errors.classSize = data.classSize
      ? validateInteger()(data.classSize).integer
      : '';
    errors.schoolCredits = data.schoolCredits
      ? validateInteger()(data.schoolCredits).integer
      : '';
    // errors.zip = data.zip ? validateZipcode()(data.zip).integer : '';
    errors.birthdate = data.birthdate
      ? validateBirthday()(data.birthdate).valid
      : '';
    errors.club_coach_email = data.club_coach_email
      ? validateEmail()(data.club_coach_email).email
      : '';
    errors.club_coach_mobile_phone_number = data.club_coach_mobile_phone_number
      ? validatePhone()(data.club_coach_mobile_phone_number).club_coach_mobile_phone_number
      : '';
    errors.high_school_coach_email = data.high_school_coach_email
      ? validateEmail()(data.high_school_coach_email).email
      : '';
    errors.high_school_coach_phone_number = data.high_school_coach_phone_number
      ? validatePhone()(data.high_school_coach_phone_number).high_school_coach_phone_number
      : '';

    if (rowIndex !== null) {
      this.setState({
        errors: {
          ...this.state.errors,
          [rowIndex]: errors,
        },
      });
    }
    return (
      errors.first ||
      errors.last ||
      errors.gender ||
      errors.grade ||
      errors.email ||
      errors.combineNumber ||
      errors.commits ||
      errors.jerseyNumber ||
      errors.parentsName ||
      errors.parentsPhone ||
      errors.parentsEmail ||
      errors.parents2Phone ||
      errors.parent2Email ||
      errors.phone ||
      errors.gradYear ||
      errors.twitter ||
      errors.instagram ||
      errors.ncaaIdNumber ||
      errors.address ||
      errors.city ||
      errors.state ||
      errors.school ||
      errors.coreGpa ||
      errors.act ||
      errors.sat ||
      errors.classRank ||
      errors.classSize ||
      errors.schoolCredits ||
      errors.heightFt ||
      errors.heightInch ||
      errors.weight ||
      errors.birthdate ||
      errors.club_coach_email ||
      errors.club_coach_mobile_phone_number ||
      errors.high_school_coach_email ||
      errors.high_school_coach_phone_number
    );
  };

  addClickCoachCommentHandler = (athlete) => {
    this.setState({
      showAddCoachModal: true,
      selectedAthlete: athlete,
    });
  };

  getColumnDefs = () => {
    const { canEdit, reconfigureView, sportId, school } = this.props;
    let col = [
      {
        headerName: 'ATHLETE',
        children: [
          {
            headerName: '',
            pinned: 'left',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            maxWidth: 50,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
          },
          {
            headerName: 'First Name',
            suppressSizeToFit: false,
            field: 'first',
            pinned: 'left',
            cellClass: 'GridView_Athlete_Cell_Style',
            minWidth: 120,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            comparator: (a, b, nodeA, nodeB, isInverted) => {
              const A = a.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
              const B = b.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
              if (A === B) {
                const lastNameA = nodeA.data.familyName
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]/g, '');
                const lastNameB = nodeB.data.familyName
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]/g, '');
                return lastNameA === lastNameB
                  ? 0
                  : lastNameA > lastNameB
                    ? 1
                    : -1;
              }
              return A > B ? 1 : -1;
            },
            valueSetter: (params) => {
              params.data.first = params.newValue;
              return true;
            },
            cellStyle: (params) =>
              !validateRequired()(params.value).required
                ? { backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].first
                : '',
            }),
          },
          {
            headerName: 'Last Name',
            suppressSizeToFit: false,
            field: 'last',
            pinned: 'left',
            cellClass: 'GridView_Athlete_Cell_Style',
            minWidth: 120,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            comparator: (a, b, nodeA, nodeB, isInverted) => {
              const A = a.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
              const B = b.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
              if (A === B) {
                const firstNameA = nodeA.data.givenName
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]/g, '');
                const firstNameB = nodeB.data.givenName
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]/g, '');
                return firstNameA === firstNameB
                  ? 0
                  : firstNameA > firstNameB
                    ? 1
                    : -1;
              }
              return A > B ? 1 : -1;
            },
            valueSetter: (params) => {
              params.data.last = params.newValue;
              return true;
            },
            cellStyle: (params) =>
              !validateRequired()(params.value).required
                ? { backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].last
                : '',
            }),
          },
        ],
      },
      {
        headerName: 'TEAM',
        children: [
          {
            headerName: "Team Name",
            field: 'teamName',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].teamName
                : '',
            }),
          },
        ],
      },
      {
        headerName: 'PERSONAL INFO',
        children: [
          {
            headerName: 'Gender',
            suppressSizeToFit: false,
            field: 'gender',
            minWidth: 90,
            width: 90,
            maxWidth: 90,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            valueSetter: (params) => {
              const newValue = params.newValue
                ? params.newValue.replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '')
                : '';
              let mappedValue = null;
              if (newValue) {
                if (newValue.toLowerCase() === 'female') {
                  mappedValue = 'Female';
                } else if (newValue.toLowerCase() === 'male') {
                  mappedValue = 'Male';
                }
              }
              params.data.gender = mappedValue || newValue;

              return true;
            },
            cellStyle: (params) =>
              genders.includes(params.value)
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: genders,
            },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].gender
                : '',
            }),
          },
          {
            headerName: 'Athlete Email',
            field: 'email',
            suppressSizeToFit: false,
            cellRenderer: 'emailRenderer',
            sortable: true,
            editable: (params) =>
              params.data.is_permission_for_edit &&
              canEdit &&
              isInvitePending(params.node.data.invited),
            cellStyle: (params) =>
              !params.value ||
              (!validateEmail()(params.value).email &&
                !this.state.emailExistsErrors[params.rowIndex])
                ? {
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                  padding: '0 2px',
                }
                : {
                  backgroundColor: '#ff9999',
                  fontWeight: 'bold',
                  padding: '0 2px',
                },
            minWidth: 180,
            maxWidth: 180,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error:
                this.state.emailExistsErrors[params.rowIndex] ||
                (this.state.errors[params.rowIndex]
                  ? this.state.errors[params.rowIndex].email
                  : ''),
            }),
          },
          {
            headerName: "Parent's Email",
            field: 'parentsEmail',
            suppressSizeToFit: false,
            cellRenderer: 'emailRenderer',
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateEmail()(params.value).email
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            // editable: params => (params.data.is_permission_for_edit && canEdit),
            minWidth: 180,
            maxWidth: 180,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parentsEmail
                : '',
            }),
          },
          {
            headerName: "Parent 2 Email",
            field: 'parent2Email',
            suppressSizeToFit: false,
            cellRenderer: 'emailRenderer',
            sortable: true,
            editable: (params) => true,
            cellStyle: (params) =>
              !params.value || !validateEmail()(params.value).email
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            // editable: params => (params.data.is_permission_for_edit && canEdit),
            minWidth: 180,
            maxWidth: 180,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parent2Email
                : '',
            }),
          },
          {
            headerName:
              school && school.isPaid ? `RevShare Invite${school && school.isSMSEnabled ? ' + SMS' : ''}` : `VC Invite${school && school.isSMSEnabled ? ' + SMS' : ''}`,
            field: 'invite',
            headerTooltip: school && school.isSMSEnabled ? 'Email and SMS invites will be sent to parents and athletes.' : '',
            suppressSizeToFit: false,
            cellRenderer: 'inviteButtonRenderer',
            colId: 'invite',
            minWidth: 80,
            width: 200,
            maxWidth: 200,
            sortable: true,
            cellRendererParams: {
              school,
            },
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
              // if (nodeA.data.isSubscribed > nodeB.data.isSubscribed && nodeA.data.invited === nodeB.data.invited) return 1;
              // if (nodeA.data.isSubscribed < nodeB.data.isSubscribed && nodeA.data.invited === nodeB.data.invited) return -1;
              // return nodeA.data.invited > nodeB.data.invited ? 1 : -1;
              return nodeA.data.inviteOrder > nodeB.data.inviteOrder ? 1 : -1;

            },
          },
          {
            headerName: 'Phone',
            field: 'phone',
            suppressSizeToFit: false,
            sortable: true,
            valueFormatter: (params) =>
              params.value ? formatPhone(params.value) : '',
            // cellRenderer: 'phoneRenderer',
            cellStyle: (params) =>
              !params.value || !validatePhone()(params.value).phone
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].phone
                : '',
            }),
          },
          {
            headerName: 'Combine Number',
            field: 'combineNumber',
            suppressSizeToFit: false,
            comparator: (a, b, nodeA, nodeB, isInverted) => {
              // console.log({nodeA}, {nodeB})
              // const valueA = nodeA.familyName;
              // const valueB = nodeB.familyName;
              // if (valueA === valueB) {
              //   return 0;
              // }
              A = a;
              B = b;
              a = a ? a.replaceAll(' ', '') : '';
              b = b ? b.replaceAll(' ', '') : '';

              const valueA = nodeA.data.familyName;
              const valueB = nodeB.data.familyName;
              if (!Boolean(a)) {
                if (!Boolean(b) && valueA < valueB) {
                  return -1;
                }
                return 1;
              } else if (!Boolean(b)) {
                return -1;
              }

              const AInt = parseInt(a, 10);
              const BInt = parseInt(b, 10);

              if (isNaN(AInt) && isNaN(BInt)) {
                const aA = a.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                const bA = b.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                if (aA === bA) {
                  const aN = parseInt(a.replace(/[^0-9]/g, ''), 10);
                  const bN = parseInt(b.replace(/[^0-9]/g, ''), 10);
                  return aN === bN ? 0 : aN > bN ? 1 : -1;
                }
                sortnum = aA.localeCompare(bA, 'en', { numeric: true });
                return sortnum;
                // return aA > bA ? 1 : -1;
              } else if (isNaN(AInt)) {
                //A is not an Int
                return 1;
              } else if (isNaN(BInt)) {
                //B is not an Int
                return -1;
              }
              if (AInt === BInt) {
                const Aa = a.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                const Ba = b.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                if (Aa === Ba) {
                  if (A.toLowerCase() === Aa) {
                    return -1;
                  }
                  return 1;
                }
                sortcombine = Aa.localeCompare(Ba, 'en', { numeric: true });
                return sortcombine;

                // const aA1 = a.toLowerCase() === a.toLowerCase();
              }
              return AInt > BInt ? 1 : -1;
            },
            // valueSetter: (params) => {
            //   params.data.combineNumber = params.newValue.replace(/[^\w\s-'"]/g, '')
            //   return true;
            // },
            cellStyle: (params) =>
              !params.value ||
              !validateAlphaNumericWithSpecialCharacter()(params.value).valid
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },

            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 140,
            maxWidth: 150,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].combineNumber
                : '',
            }),
          },
          {
            headerName: 'Jersey Number',
            field: 'jerseyNumber',
            suppressSizeToFit: false,
            sortable: true,
            cellStyle: (params) =>
              !params.value ||
              !validateAlphaNumericWithSpecialCharacter()(params.value).valid
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },

            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 140,
            maxWidth: 150,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].jerseyNumber
                : '',
            }),
          },
          {
            headerName: 'Height-Feet',
            field: 'heightFt',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            valueSetter: (params) => {
              if (params.newValue && !/[\d]'{1}$/.test(params.newValue)) {
                params.newValue = `${params.newValue}'`;
                params.data.heightFt = params.newValue.replace(
                  /[^0-9][$']+/g,
                  '',
                );
              } else {
                params.data.heightFt = params.newValue;
              }

              return true;
            },
            cellStyle: (params) =>
              !params.value || !validateHeightFtInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].heightFt
                : '',
            }),
          },
          {
            headerName: 'Height-Inches',
            field: 'heightInch',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            valueSetter: (params) => {
              if (params.newValue && !/[\d]"{1}$/.test(params.newValue)) {
                params.newValue = `${params.newValue}"`;
                params.data.heightInch = params.newValue.replace(
                  /[^0-9][$"]+/g,
                  '',
                );
              } else {
                params.data.heightInch = params.newValue;
              }

              return true;
            },
            cellStyle: (params) =>
              !params.value ||
              !validateHeightInchInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].heightInch
                : '',
            }),
          },
          {
            headerName: 'Weight(lbs)',
            field: 'weight',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateIntegerWeight()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].weight
                : '',
            }),
          },
          {
            headerName: 'Birth Date',
            field: 'birthdate',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            valueGetter: (params) => {
              const format = 'MM/DD/YYYY';
              if (params.data.birthdate) {
                const date = new moment(params.data.birthdate, format);
                if (date.isValid()) {
                  return date.format(format);
                }
              }
              return params.data.birthdate;
            },
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
              if (!valueA && !valueB) {
                return 0;
              }
              if (valueA && !valueB) {
                return isInverted ? 1 : -1;
              }
              if (!valueA && valueB) {
                return isInverted ? -1 : 1;
              }

              const format = 'MM/DD/YYYY';
              const date1 = new moment(valueA, format);
              const date2 = new moment(valueB, format);
              if (date1.isSame(date2)) return 0;
              return date1.isAfter(date2) ? 1 : -1;
            },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateBirthday()(params.value).valid
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: 'common' }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].birthdate
                : '',
            }),
          },
          {
            headerName: 'School',
            field: 'school',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            // valueSetter: (params) => {
            //   params.data.school =  params.newValue.replace(/[^\w\s-'"]/g, '')
            //   return true;
            // },
            cellStyle: (params) => ({
              fontWeight: 'normal',
              backgroundColor: 'transparent',
            }),
            // (!params.value || !(validateAlphaNumericWithSpecialCharacter()(params.value).valid))
            //   ? {fontWeight: 'normal', backgroundColor: 'transparent'}
            //   : {backgroundColor: '#ff9999', fontWeight: 'bold'}
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].school
                : '',
            }),
          },
          {
            headerName: 'GPA',
            field: 'coreGpa',
            suppressSizeToFit: false,
            minWidth: 100,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateNumber()(params.value).number
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].coreGpa
                : '',
            }),
          },
          {
            headerName: 'ACT',
            field: 'act',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].act
                : '',
            }),
          },
          {
            headerName: 'SAT',
            field: 'sat',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].sat
                : '',
            }),
          },
          {
            headerName: 'Class Rank',
            field: 'classRank',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].classRank
                : '',
            }),
          },
          {
            headerName: 'Class Size',
            field: 'classSize',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].classSize
                : '',
            }),
          },
          {
            headerName: 'School Credits',
            field: 'schoolCredits',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].schoolCredits
                : '',
            }),
          },
          {
            headerName: 'HS Grad Year',
            field: 'gradYear',
            suppressSizeToFit: false,
            sortable: true,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
              values: gradYear,
            },
            valueSetter: (params) => {
              params.data.gradYear = params.newValue;
              return true;
            },
            cellStyle: (params) => {
              return  !params.value || gradYear.includes(params.value)
                ? {backgroundColor: 'transparent', fontWeight: 'normal'}
                : {backgroundColor: '#ff9999', fontWeight: 'bold'}
            },

            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].gradYear
                : '',
            }),
          },
          {
            headerName: 'NCAA ID Number',
            field: 'ncaaIdNumber',
            suppressSizeToFit: false,
            sortable: true,
            cellStyle: (params) =>
              !params.value || !validateInteger()(params.value).integer
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },

            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].ncaaIdNumber
                : '',
            }),
          },
          {
            headerName: 'Twitter',
            field: 'twitter',
            suppressSizeToFit: false,
            sortable: true,
            cellStyle: (params) =>
              !params.value ||
              !validateTwitter()(params.value.replace('.', '')).valid
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            valueSetter: (params) => {
              params.newValue = params.newValue
                ? params.newValue.replaceAll('.', '')
                : '';
              if (
                params.newValue &&
                !/^[@]+[a-zA-Z0-0]/.test(params.newValue.replace('.', ''))
              ) {
                params.newValue = `@${params.newValue.replace('.', '')}`;
              }
              if (!validateTwitter()(params.newValue.replace('.', '')).valid) {
                params.data.twitter = params.newValue.replace('.', '');
              }
              params.data.twitter = params.newValue.replace('.', '');

              return true;
            },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].twitter
                : '',
            }),
          },
          {
            headerName: 'Instagram',
            field: 'instagram',
            suppressSizeToFit: false,
            sortable: true,
            cellStyle: (params) =>
              !params.value || !socialHandleValidation()(params.value).valid
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].instagram
                : '',
            }),
            valueSetter: (params) => {
              if (
                params.newValue &&
                !/^[@]+[a-zA-Z0-0]/.test(params.newValue)
              ) {
                params.newValue = `@${params.newValue}`;
              }
              if (!socialHandleValidation()(params.newValue).valid) {
                params.data.instagram = params.newValue;
              }

              return true;
            },
          },
          {
            headerName: "Parent's Name",
            field: 'parentsName',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: () => ({fontWeight: 'normal', backgroundColor: 'transparent'}),
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parentsName
                : '',
            }),
          },
          {
            headerName: "Parent 2 Name",
            field: 'parent2Name',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: () => ({fontWeight: 'normal', backgroundColor: 'transparent'}),
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parent2Name
                : '',
            }),
          },
          {
            headerName: "Parent's Phone",
            field: 'parentsPhone',
            suppressSizeToFit: false,
            sortable: true,
            // valueFormatter: 'phoneRenderer',
            valueFormatter: (params) => formatPhone(params.value),
            cellStyle: (params) =>
              !params.value || !validatePhone()(params.value).phone
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parentsPhone
                : '',
            }),
          },
          {
            headerName: "Parent 2 Phone",
            field: 'parents2Phone',
            suppressSizeToFit: false,
            sortable: true,
            // valueFormatter: 'phoneRenderer',
            valueFormatter: (params) => formatPhone(params.value),
            cellStyle: (params) =>
              !params.value || !validatePhone()(params.value).phone
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parents2Phone
                : '',
            }),
          },
          {
            headerName: 'Address',
            field: 'address',
            suppressSizeToFit: false,
            sortable: true,
            valueSetter: (params) => {
              params.data.address = params.newValue
                ? params.newValue.replace(/[^\w\s-'"@&:;*,.()]/g, '')
                : '';
              return true;
            },
            cellStyle: (params) =>
              !params.value || !addressValidation()(params.value).valid
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 180,
            maxWidth: 210,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].address
                : '',
            }),
          },
          {
            headerName: 'City',
            field: 'city',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateLetters()(params.value).letters
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].city
                : '',
            }),
          },
          {
            headerName: 'State',
            field: 'state',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateLetters()(params.value).letters
                ? { fontWeight: 'normal', backgroundColor: 'transparent' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].state
                : '',
            }),
          },
          {
            headerName: 'Zip',
            field: 'zip',
            suppressSizeToFit: false,
            sortable: true,
            minWidth: 100,
            maxWidth: 150,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].zip
                : '',
            }),
          },
          {
            headerName: 'Committed To',
            field: 'commits',
            suppressSizeToFit: false,
            sortable: true,
            cellStyle: (params) =>
              !params.value ||
              !validateLetterNumberSpecialCharacter()(params.value).valid
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 140,
            maxWidth: 150,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].commits
                : '',
            }),
          },
          {
            headerName: 'Position',
            field: 'position',
            suppressSizeToFit: false,
            minWidth: 140,
            maxWidth: 160,
            sortable: true,
            valueSetter: (params) => {
              const newValue = params.newValue;
              const sportPositions = positions(
                this.props.sportId,
                params.data.gender,
              );
              const allSportPosition = sportPositions.map(
                (sportPosition) => sportPosition.value,
              );
              const notPos =
                newValue &&
                newValue
                  .split(',')
                  .find((value) => allSportPosition.indexOf(value) === -1);
              params.data.position = !!notPos ? params.oldValue : newValue;
              return true;
            },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellEditor: 'positionCellEditor',
            cellEditorParams: { sportId: this.props.sportId },
          },
          {
            headerName: 'Club Team',
            field: 'clubTeam',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: () => ({fontWeight: 'normal', backgroundColor: 'transparent'}),
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].clubTeam
                : '',
            }),
          },
          {
            headerName: 'Club Coach Name',
            field: 'clubCoachName',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: () => ({fontWeight: 'normal', backgroundColor: 'transparent'}),
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].clubTeam
                : '',
            }),
          },
          {
            headerName: 'Club Coach Email',
            field: 'clubCoachEmail',
            suppressSizeToFit: false,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateEmail()(params.value).email
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            // editable: params => (params.data.is_permission_for_edit && canEdit),
            minWidth: 180,
            maxWidth: 180,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].parentsEmail
                : '',
            }),
          },
          {
            headerName: 'Club Coach Phone Number',
            field: 'clubCoachMobilePhoneNumber',
            suppressSizeToFit: false,
            sortable: true,
            valueFormatter: (params) =>
              params.value ? formatPhone(params.value) : '',
            // cellRenderer: 'phoneRenderer',
            cellStyle: (params) =>
              !params.value || !validatePhone()(params.value).phone
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 150,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].clubCoachMobilePhoneNumber
                : '',
            }),
          },
          {
            headerName: 'HS Coach Name',
            field: 'highSchoolCoachName',
            suppressSizeToFit: false,
            minWidth: 150,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: () => ({fontWeight: 'normal', backgroundColor: 'transparent'}),
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].highSchoolCoachName
                : '',
            }),
          },
          {
            headerName: 'HS Coach Email',
            field: 'highSchoolCoachEmail',
            suppressSizeToFit: false,
            sortable: true,
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            cellStyle: (params) =>
              !params.value || !validateEmail()(params.value).email
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            minWidth: 180,
            maxWidth: 180,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].highSchoolCoachEmail
                : '',
            }),
          },
          {
            headerName: 'HS Coach Phone Number',
            field: 'highSchoolCoachPhoneNumber',
            suppressSizeToFit: false,
            sortable: true,
            valueFormatter: (params) =>
              params.value ? formatPhone(params.value) : '',
            // cellRenderer: 'phoneRenderer',
            cellStyle: (params) =>
              !params.value || !validatePhone()(params.value).phone
                ? { backgroundColor: 'transparent', fontWeight: 'normal' }
                : { backgroundColor: '#ff9999', fontWeight: 'bold' },
            editable: (params) => params.data.is_permission_for_edit && canEdit,
            minWidth: 130,
            maxWidth: 140,
            tooltipValueGetter: (params) => ({ value: params.value }),
            tooltipComponentParams: (params) => ({
              error: this.state.errors[params.rowIndex]
                ? this.state.errors[params.rowIndex].highSchoolCoachPhoneNumber
                : '',
            }),
          },
          {
            headerName: 'Add Comment',
            field: 'coachComment',
            suppressSizeToFit: false,
            cellRenderer: 'addCommentButton',
            colId: 'coachComment',
            minWidth: 200,
            maxWidth: 300,
            editable: false,
          },
        ],
      },
    ];

    if (Number(sportId) === 2 || Number(sportId) === 4) {
      const bats = {
        headerName: 'BATS',
        field: 'bats',
        suppressSizeToFit: false,
        minWidth: 140,
        maxWidth: 160,
        sortable: true,
        editable: (params) => params.data.is_permission_for_edit && canEdit,
        cellEditor: 'agRichSelectCellEditor',
        valueSetter: (params) => {
          const newValue = params.newValue
            ? params.newValue.replace(/^[^a-zA-Z]*|[^a-zA-Z]*$/g, '')
            : '';
          let mappedValue = null;
          if (newValue) {
            mappedValue = Object.entries(batOptions).find(([key, value]) => {
              if (
                key.toLowerCase() === newValue.toLowerCase() ||
                value.toLowerCase() === newValue.toLowerCase()
              ) {
                return true;
              }
            });
          }
          params.data.bats = mappedValue ? mappedValue[1] : params.oldValue;
          return true;
        },
        cellEditorParams: {
          values: batsThrows,
        },
      };
      const throws = {
        headerName: 'THROWS',
        field: 'throws',
        suppressSizeToFit: false,
        minWidth: 140,
        maxWidth: 160,
        sortable: true,
        editable: (params) => params.data.is_permission_for_edit && canEdit,
        cellEditor: 'agRichSelectCellEditor',
        valueSetter: (params) => {
          const newValue = params.newValue
            ? params.newValue.replace(/^[^a-zA-Z]*|[^a-zA-Z]*$/g, '')
            : '';
          let mappedValue = null;
          if (newValue) {
            mappedValue = Object.entries(batOptions).find(([key, value]) => {
              if (
                key.toLowerCase() === newValue.toLowerCase() ||
                value.toLowerCase() === newValue.toLowerCase()
              ) {
                return true;
              }
            });
          }
          params.data.throws = mappedValue ? mappedValue[1] : params.oldValue;
          return true;
        },
        cellEditorParams: {
          values: batsThrows,
        },
      };

      col[2].children.splice(11, 0, bats, throws);
    }
    return col;
  };

  sendInvitationByCommonMethod = (athleteInfo) => {
    const { match, team, profiles, userProfileId, school, sportId, invite } =
      this.props;

    if (!athleteInfo.parentsEmail && school.isPaid) {
      this.setState({ showAlertDialog: true });
      return false;
    }

    if (!athleteInfo.email && !athleteInfo.parentsEmail && school.isPaid) {
      this.setState({ showAlertDialog: true });
      return false;
    }
    // comment to update the build
    athleteInfo.email = athleteInfo.email
      ? athleteInfo.email.trim()
      : athleteInfo.email;
    athleteInfo.parentsEmail = athleteInfo.parentsEmail
      ? athleteInfo.parentsEmail.trim()
      : athleteInfo.parentsEmail;
    athleteInfo.roleId = 2;
    athleteInfo.inviteType = 'invite';
    athleteInfo.coachFullName = `${profiles[userProfileId].first} ${profiles[userProfileId].last}`;
    athleteInfo.schoolName = school.name;
    athleteInfo.gender = athleteInfo.gender.toLowerCase();
    invite(athleteInfo, match.params.schoolId, sportId, uuidv4());
  };

  handleCloseAddTeamModal = () => {
    this.setState({ isCoachModalVisible: false });
  };

  handleAthleteCopy2Clipboard = () => {
    this.copyToClipboard('athleteCodeText');
    this.setState({
      clipboardMessage: 'Athlete code has been copied to clipboard!',
    });
  };

  handleCoachCopy2Clipboard = () => {
    this.copyToClipboard('coachCodeText');
    this.setState({
      clipboardMessage: 'Coach code has been copied to clipboard!',
    });
  };

  copyToClipboard = (containerid) => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand('copy');
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().addRange(range);
      document.execCommand('copy');
    }
  };

  handleCloseConfirmDialog = () => this.setState({ showConfirmDialog: false });

  printGrid = (totalColumn) => {
    let pri;
    this.setState({ totalColumn: [...totalColumn], showPrintDialog: false });
    setTimeout(() => {
      pri = document.getElementById('emptyprintframe').contentWindow;
      pri.focus();
      pri.print();
    }, 1000);
  };

  isOnboarded = (athlete) => athlete.invited === 2;
  handleConfirmInvite = () => {
    const { school, setBulkInviteState } = this.props;

    const athletes = this.gridApi.getSelectedRows();

    if (athletes.length > 20) {
      const chunk = (arr, size) =>
        Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
          arr.slice(i * size, i * size + size)
        );

      const athleteInChunk = chunk(athletes.filter(item => item.invited <= 1), 20);

      setBulkInviteState(athleteInChunk);

    } else {
      athletes.forEach((athlete) => {
        // do not send invite to onBoarded athletes
        if (this.isOnboarded(athlete)) return true;

        const athleteInfo = Object.assign({}, athlete);
        if (
          (school.isPaid ? !!athleteInfo.parentsEmail : true)
        ) {
          this.sendInvitationByCommonMethod(athleteInfo);
        }
      });
    }
    this.setState({ showConfirmDialog: false });
  };

  getConfirmMessage = () => {
    const {school} = this.props;
    let countBlankParentEmail = 0;
    const athletes = this.gridApi.getSelectedRows();
    athletes.forEach((athlete) => {
      if (!athlete.parentsEmail) {
        countBlankParentEmail++;
      }
    });
    if (countBlankParentEmail && school.isPaid) {
      return (
        `HEY COACH!\n` +
        `\n` +
        `Invites require a parent email.\n` +
        `\n` +
        `It looks like you need to update ${countBlankParentEmail} invite(s).`
      );
    }
    return 'Are you sure you want to invite selected athletes?';
  };

  inviteSelected = () => {
    this.setState({
      showConfirmDialog: true,
      confirmMsg: this.getConfirmMessage(),
      confirmTitle: 'Invite Users?',
      confirmBtnMsg: 'Invite',
      confirmOnYesClick: this.handleConfirmInvite,
      confirmOnCancelClick: this.handleCloseConfirmDialog,
    });
  };

  moveSelected = (teamId) => {
    const {savePlayer, history, match, getMovedAthlete, allRosterAthlete, myTeam} =
      this.props;
    const {undoState, undoStep} = this.state;
    const athletes = this.gridApi.getSelectedRows();
    const newTeam = myTeam.find(e => e.schoolTeamId === teamId);
    athletes.forEach((athlete,index) => {
      athletes[index].prevSchoolTeamId = teamId;
      const values = {};
      values.position = athlete.position || '';
      values.prevSchoolTeamId = athlete.schoolTeamId;
      values.schoolTeamId = teamId;
      values.jerseyNumber = athlete.jerseyNumber || '';
      values.teamName = newTeam && newTeam.schoolTeams[0].name || '';
       savePlayer(values, athlete.id, athlete.userId, match.params.schoolId,athletes.length,(index + 1));
    });

    if(undoState.length > 1){
      undoState.shift();
    }
    this.setState({undoState: [...undoState, {type: 'move', data: athletes}]});

    this.setState({undoStep: (undoStep === 2 ? undoStep : undoStep + 1)});
    this.setState({showChangeTeamDialog: false});
    this.setState({ buttonsActive: false });
  };

  undoMoveRemove = () => {
    const {savePlayer, addUserToSchoolTeam, match,removedAthlete} = this.props;
    const {undoState, undoStep} = this.state;
    if (undoStep < 3) {
      const undoData = undoState[undoStep - 1];
      const athletes = undoData.data;
      athletes.forEach((athlete,index)=>{
        const values = {};
        values.position = athlete.position || '';
        values.prevSchoolTeamId = athlete.prevSchoolTeamId;
        values.schoolTeamId = athlete.schoolTeamId;
        values.jerseyNumber = athlete.jerseyNumber || '';
        values.teamName = athlete.teamName || '';
        values.coachComment = athlete.coachComment || '';
        values.invited = athlete.invited;
        values.combineNumber = athlete.combineNumber || null;
        values.headshotUrl = athlete.headshotUrl || '';
        if (undoData.type === 'move') {
          savePlayer(values, athlete.id, athlete.userId, match.params.schoolId,athletes.length, (index + 1));
        } else {
          addUserToSchoolTeam(values,athlete.userId,athlete,athletes.length,(index + 1));
        }
      });
      undoState.splice(-1);
      this.setState({undoState: undoState});
      this.setState({undoStep: (undoStep - 1)});
    }
  };

  removeSelected = () => {
    this.setState({
      showConfirmDialog: true,
      confirmMsg:
        'Are you sure you want to remove selected athletes from the team?',
      confirmTitle: 'Remove Users?',
      confirmBtnMsg: 'Remove',
      confirmOnYesClick: this.handleConfirmDelete,
      confirmOnCancelClick: this.handleCloseConfirmDialog,
    });
  };

  handleConfirmDelete = () => {
    const { removeUserFromTeam, history, match } = this.props;
    const {undoState, undoStep} = this.state;
    const athletes = this.gridApi.getSelectedRows();
    athletes.forEach((athlete,index) => {
      removeUserFromTeam(athlete.id, athletes.length, (index + 1));
    });
    if (undoState.length > 1) {
      undoState.shift();
    }
    this.setState({undoState: [...undoState, {type: 'remove', data: athletes}]});
    this.setState({undoStep: (undoStep === 2 ? undoStep : undoStep + 1)});
    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}/${Routes.reconfigureGrid}/${Routes.team}/${match.params.teamId}`,
      search: history.location.search,
    });
    this.setState({
      showConfirmDialog: false,
      buttonsActive: false,
    });
  };

  goToRoster = () => {
    const {history, match} = this.props;
    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}/${Routes.grid}/${Routes.team}/${match.params.teamId}`,
      params: history.location.search,
      search: '',
    });
  };

  hidePivotSection = () => {
    const columnToolPanel = this.gridApi.getToolPanelInstance('columns');
    if (columnToolPanel) {
      columnToolPanel.setPivotModeSectionVisible(false);
      columnToolPanel.setPivotSectionVisible(false);
    }
  };

  addCoachCommentSaveHandler = (userTeamId, userId, schoolId, comment) => {
    const { saveUserTeam } = this.props;
    saveUserTeam({ coachComment: comment }, userTeamId, userId, schoolId);
  };

  pasteRowsIndexes = [];
  pastedEmails = [];

  render() {
    const {
      canEdit,
      athletes,
      team,
      match,
      schoolStyles,
      coachCode,
      athleteCode,
      myTeam,
      location,
      athleteInviteChunkCount,
      athleteInviteChunkState,
    } = this.props;
    const { headCoach, recruitingCoordinator, twitter } = team;
    const {
      showConfirmDialog,
      confirmOnCancelClick,
      confirmOnYesClick,
      confirmBtnMsg,
      confirmMsg,
      confirmTitle,
      showChangeTeamDialog,
      showPrintDialog,
      buttonsActive,
      totalColumn,
      clipboardMessage,
      isCoachModalVisible,
      showHeadCoachModal,
      showTeamTwitterLinkModal,
      showAddCoachModal,
      selectedAthlete,
      showRecruitingCoordinatorModal,
      showCoachRCwitterDetails,
      showAlertDialog,
      undoState,
    } = this.state;
    const filteredChunkList = athleteInviteChunkState.filter(e => e.length && e.find(item => item.email));
    const currentStyle = schoolStyles.find(
      (s) => s.id === match.params.schoolId,
    );
    const { schoolId } = match.params;
    let noDataMsg;
    if (location.search) {
      const parsedQuery = queryString.parse(location.search);
      const filtersJson = parsedQuery.filters
      const filters = JSON.parse(filtersJson);
      const getTeamFilters = filters.find(e => e.name === 'schoolTeamId');
      if (!getTeamFilters.selected.length) {
        noDataMsg = 'No teams are selected. Select a team or teams on the left.';
      } else {
        noDataMsg = 'No Rows To Show';
      }
      setTimeout(() => {
        if (!athletes.length) {
          this.gridApi.showNoRowsOverlay();
        }
      }, 500)
    }

    const inviteLoaderMsg = `Sending invite to ${(athletes.length - (athleteInviteChunkCount * 20))} of ${athletes.length} athletes`;
    if (filteredChunkList.length) {
      return <LoadingIndicator loading={true}
                               loadingMsg={inviteLoaderMsg}/>;
    }

    return (
      <div style={{ backgroundColor: '#F4F4F4', paddingTop: 10 }}>
        <ConfirmAthleteModal
          onCancelAthlete={this.onConfirmPlayerEnd}
          onConfirmAthlete={this.onConfirmPlayer}
        />
        {
          <PrintRoster
            gridApi={this.gridApi}
            propData={this.props}
            currentSchoolStyle={currentStyle}
            totalColumn={totalColumn}
            headCoach={headCoach}
            recruitingCoordinator={recruitingCoordinator}
            twitter={twitter}
          />
        }
        {showConfirmDialog && (
          <ConfirmDialog
            onArchiveClick={confirmOnYesClick}
            onCancelClick={confirmOnCancelClick}
            msg={confirmMsg}
            title={confirmTitle}
            btnMsg={confirmBtnMsg}
          />
        )}
        {showAlertDialog && (
          <AlertDialog
            onCancelClick={() => this.setState({ showAlertDialog: false })}
            msg={'Parent Email is required to send invites'}
            title={'Hey coach!'}
            btnMsg={'Button message'}
          />
        )}
        {showChangeTeamDialog && (
          <ChangeTeamDialog
            teams={myTeam.filter(
              (mt) => mt.sportId === team.sportId && mt.schoolId === schoolId && !mt.schoolTeams[0].isPrivate,
            )}
            onArchiveClick={this.moveSelected}
            onCancelClick={() => this.setState({ showChangeTeamDialog: false })}
            msg="Please choose the team you want to move select the athletes"
            title="Change Team"
            btnMsg="Move"
          />
        )}
        {isCoachModalVisible && (
          <AddTeamModal
            coachCode={coachCode}
            athleteCode={athleteCode}
            onCancel={this.handleCloseAddTeamModal}
            handleCoachCopy2Clipboard={this.handleCoachCopy2Clipboard}
            handleAthleteCopy2Clipboard={this.handleAthleteCopy2Clipboard}
            teamName={currentStyle.name}
            message={clipboardMessage}
          />
        )}
        {showPrintDialog && (
          <PrintFieldSelector
            gridApi={this.gridApi}
            onArchiveClick={this.printGrid}
            onCancelClick={() => this.setState({ showPrintDialog: false })}
            msg="Select the fields which you want to print"
            title="Print Table"
            btnMsg="Print"
          />
        )}
        {showHeadCoachModal && (
          <HeadCoachModal
            onCancelClick={() => this.setState({ showHeadCoachModal: false })}
            schoolTeamId={team.id}
            headCoach={team.headCoach}
          />
        )}
        {showTeamTwitterLinkModal && (
          <TeamTwitterLinkModal
            onCancelClick={() =>
              this.setState({ showTeamTwitterLinkModal: false })
            }
            schoolTeamId={team.id}
            twitter={team.twitter}
          />
        )}
        {showAddCoachModal && (
          <AddCoachCommentModal
            onCancelClick={() => this.setState({ showAddCoachModal: false })}
            schoolTeamId={team.id}
            selectedAthlete={selectedAthlete}
            onSaveClick={this.addCoachCommentSaveHandler}
          />
        )}
        {showRecruitingCoordinatorModal && (
          <RecruitingCoordinatorModal
            onCancelClick={() =>
              this.setState({ showRecruitingCoordinatorModal: false })
            }
            schoolTeamId={team.id}
            recruitingCoordinator={team.recruitingCoordinator}
          />
        )}
        <div className="GridView_Outer_Top_Sticky_Div">
          <div
            className="flex-lg-row flex-column"
            style={{
              display: 'flex',
              margin: 10,
              marginTop: 20,
              marginRight: 0,
              flexDirection: 'row',
              width: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}>
              <div style={{ display: 'flex', marginRight: 5 }}>
                <button
                  onClick={this.goToRoster}
                  className="GridView_Back_Button">
                  <Icon iconName="chevron-left" />
                </button>
              </div>
              <div className="GridView_CardSchoolName roster-heading">
                Back To Manage Rosters
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: 10,
              marginLeft: 2,
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <button
              onClick={() => this.setState({ showPrintDialog: true })}
              className="GridView_Secondary_Icon">
              <div style={{ marginRight: 3 }}>
                <Icon iconName="print" />
              </div>
            </button>
          </div>
          <div
            className={`roster-row ${
              headCoach || recruitingCoordinator || twitter
                ? ''
                : 'null-RosterDeatils'
            }`}>

           <div className="roster-wrapper justify-content-center"><h2>Reconfigure Teams</h2></div>

            {showCoachRCwitterDetails && (
              <div className="card card-body">
                <div className="row no-gutters">
                  <div className="col-md-4">
                    {headCoach && (
                      <div>
                        <div className="row my-2">
                          <div className="col-md-5">Name</div>
                          <div className="col-md-7 bold-text break-line">
                            {' '}
                            {headCoach.name}{' '}
                          </div>
                        </div>
                        {headCoach.email && (
                          <div className="row my-2">
                            <div className="col-md-5">Email</div>
                            <div className="col-md-7 break-line bold-text">
                              {headCoach.email}
                            </div>
                          </div>
                        )}
                        {headCoach.phone && (
                          <div className="row my-2">
                            <div className="col-md-5">Phone Number</div>
                            <div className="col-md-7 bold-text">
                              {headCoach.phone}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-md-4 custom-min-width">
                    {recruitingCoordinator && (
                      <div>
                        <div className="row my-2">
                          <div className="col-md-5">Name</div>
                          <div className="col-md-7 bold-text break-line">
                            {' '}
                            {recruitingCoordinator.name}{' '}
                          </div>
                        </div>
                        {recruitingCoordinator.email && (
                          <div className="row my-2">
                            <div className="col-md-5">Email</div>
                            <div className="col-md-7 break-line bold-text">
                              {recruitingCoordinator.email}
                            </div>
                          </div>
                        )}
                        {recruitingCoordinator.phone && (
                          <div className="row my-2">
                            <div className="col-md-5">Phone Number</div>
                            <div className="col-md-7 bold-text">
                              {recruitingCoordinator.phone}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    {twitter && (
                      <div className="row my-2">
                        <div className="col-md-5">Handle</div>
                        <div className="col-md-7">
                          <a
                            className="break-line"
                            href={`https://twitter.com/${twitter}`}
                            target="_blank">
                            <span>@{twitter}</span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="mobile-btn-alignment"
            style={{
              display: 'flex',
              margin: 10,
              marginTop: 15,
              flexDirection: 'row',
              width: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}>
              <div>
                <button
                  disabled={!undoState.length || undoState.length > 2}
                  className="GridView_Secondary_Button"
                  onClick={this.undoMoveRemove}
                >
                  <Icon iconName="undo" />
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginRight: 10,
                  marginBottom: 15,
                  justifyContent: 'flex-start',
                }}>
                <button
                  disabled={!buttonsActive}
                  className="GridView_Secondary_Button"
                  onClick={this.inviteSelected}>
                  Invite
                </button>
                <button
                  disabled={!buttonsActive}
                  className="GridView_Secondary_Button"
                  onClick={this.removeSelected}>
                  Remove
                </button>
                {!team.isPrivate && (
                  <button
                    disabled={!buttonsActive}
                    className="GridView_Secondary_Button"
                    onClick={() => this.setState({ showChangeTeamDialog: true })}>
                    Move
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          id="existing-grid"
          className="ag-theme-alpine"
          style={{
            height: 600,
          }}>
          <AgGridReact
            rowSelection="multiple"
            stopEditingWhenGridLosesFocus
            enableRangeSelection
            onGridReady={this.onGridReady}
            columnDefs={this.getColumnDefs()}
            columnTypes={{
              editableEmailColumn: { editable: canEdit },
            }}
            headerHeight={35}
            groupHeaderHeight={35}
            rowHeight={35}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            tooltipMouseTrack
            onCellValueChanged={this.onCellEditingStopped}
            onPasteEnd={this.onPasteEnd}
            // sideBar="columns"
            defaultColDef={{
              editable: canEdit,
              flex: 1,
              resizable: true,
              tooltipComponent: 'errorTooltip',
              cellClass: 'GridView_Grid_Cell',
              sortable: true,
              comparator: (value1, value2, nodeA, nodeB, isInverted) => {
                if (value1 == null || value1.trim() == '') {
                  return isInverted ? -1 : 1;
                }

                if (value2 == null || value2.trim() == '') {
                  return isInverted ? 1 : -1;
                }

                let integerValue1 = parseInt(value1, 10);
                let integerValue2 = parseInt(value2, 10);

                if (isNaN(integerValue1) && isNaN(integerValue2)) {
                  let regexString = /[^a-zA-Z]/g;
                  let regexNumber = /[^0-9]/g;
                  let stringValue1 = value1.replace(regexString, '');
                  let stringValue2 = value2.replace(regexString, '');
                  if (stringValue1 === stringValue2) {
                    let numberValue1 = parseInt(
                      value1.replace(regexNumber, ''),
                      10,
                    );
                    let numberValue2 = parseInt(
                      value2.replace(regexNumber, ''),
                      10,
                    );
                    return numberValue1 === numberValue2
                      ? 0
                      : numberValue1 > numberValue2
                        ? 1
                        : -1;
                  } else {
                    return stringValue1 > stringValue2 ? 1 : -1;
                  }
                } else if (isNaN(integerValue1)) {
                  return 1;
                } else if (isNaN(integerValue2)) {
                  return -1;
                } else {
                  return integerValue1 > integerValue2 ? 1 : -1;
                }
              },
            }}
            context={{ componentParent: this }}
            rowData={
              athletes
                ? athletes.map((athlete) => {
                  return {
                    ...athlete,
                    first: athlete.givenName || athlete.first,
                    last:
                      capitalizeLetterAfterSpaceHyphen(athlete.familyName) ||
                      capitalizeLetterAfterSpaceHyphen(athlete.last),
                    grade: sortedGrade[athlete.grade],
                    heightFt: athlete.heightFt ? `${athlete.heightFt}'` : '',
                    heightInch: athlete.heightInch
                      ? `${parseInt(athlete.heightInch)}"`
                      : athlete.heightIn ? `${parseInt(athlete.heightIn)}"` : '',
                    birthdate: athlete.birthdate ? athlete.birthdate : '',
                    gender: athlete.gender
                      ? athlete.gender.charAt(0).toUpperCase() +
                      athlete.gender.slice(1)
                      : athlete.gender,
                    bats: batOptions[athlete.bats],
                    throws: batOptions[athlete.throws],
                    position: athlete.position
                      ? formatPositionResponse(athlete.position)
                      : '',
                    teamName: athlete.teamName,
                    coreGpa: athlete.coreGpa || '',
                    act: athlete.act || '',
                    sat: athlete.sat || '',
                    classRank: athlete.classRank || '',
                    classSize: athlete.classSize || '',
                    schoolCredits: athlete.schoolCredits || '',
                  };
                })
                : []
            }
            suppressRowClickSelection
            onRowSelected={this.onSelectionChanged}
            animateRows
            suppressScrollOnNewData
            groupUseEntireRow
            suppressAggFuncInHeader
            frameworkComponents={{
              // datePicker: DatePicker,
              emailRenderer: EmailRenderer,
              phoneRenderer: PhoneRenderer,
              inviteButtonRenderer: InviteButtonComponentRenderer,
              errorTooltip: ErrorToolTipRenderer,
              addCommentButton: AddCoachCommentModalRender,
              positionCellEditor: PositionCellComponentEditor,
            }}
            modules={AllModules}
            overlayNoRowsTemplate={noDataMsg}
          />
        </div>
      </div>
    );
  }
}

export default ReconfigureTeamGridView;
