import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import { mergeAnotherAccountAuth, mergeSignInAuth } from "../../../store/actions/ui/routes";

import TextControlGroup from "../../UxCommon/Form/TextControlGroup/TextControlGroup";
import { parserLower } from "../../../utils/parsers";
import axios from "axios";
import Urls from "../../../config/urls";
import MergeAthleteAccount from "../../UxEditProfile/Account/MergingModals/MergeAthleteAccount.component";
import { MergeTypes } from "../MergeSignIn/MergeSignIn";


class MergeAnotherAccountAuth extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    authenticatedProfile: PropTypes.object,
    rrfForm: PropTypes.object,
    updatePosition: PropTypes.func,
    getProfile: PropTypes.func,
    authUuid: PropTypes.string,
    updateFormError: PropTypes.func.isRequired,
  };

  state = {
    showMergePopup : false,
    duplicateProfile: null,
  }

  componentDidMount() {
    const {
      formInit,
      authUuid,
      authenticatedProfile,
      getProfile
    } = this.props;
    if(!authenticatedProfile){
      getProfile(authUuid);
    }
    formInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    const { needsInit, formInit } = this.props;
    if (needsInit) {
      formInit();
    }
  };

  submit = async () => {
    const { updateFormError, rrfForm, authUuid} = this.props;
    try {
      await axios.get(`${Urls.exists.checkEmailForMerge(rrfForm.username.value)}`);
      const response = await axios.get(`${Urls.getDuplicateProfile(authUuid, rrfForm.username.value)}`);
      const duplicateProfile = response.data;
      this.setState({duplicateProfile: duplicateProfile, showMergePopup: true});
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error));
      if (err.status === 404) {
        updateFormError(rrfForm.username.value, "UserNotFoundException");
      }
    }
  };


  onNext = (value) => {
    const { history, rrfForm} = this.props;
    switch (value) {
      case "1":
        return history.push(mergeSignInAuth(
          MergeTypes.mergeBoth,
          rrfForm.username.value,
        ))

      case "2":
        this.setState({ showMergePopup : false });
        return true;
      default:
        return false;
    }
  };

  render = () => {
    const {
      formGeneralError,
      authenticatedProfile,
      match,
    } = this.props;

    const { showMergePopup, duplicateProfile } = this.state;


    return (
      <div className="container welcome-first">

        {
          showMergePopup && (
            <MergeAthleteAccount
              match={match}
              profile={authenticatedProfile}
              duplicateProfile={duplicateProfile}
              onNext={this.onNext}
              onCancel={(_)=>this.setState({showMergePopup : false})}
            />
          )
        }
        <div className="row">
          <div className="col">
            <div className="welcome-desc">
              {`Please provide the email address associated with the correct athlete.`}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model={"forms.mergeAnotherAccount"}
                  onSubmit={this.submit}>
              {
                <TextControlGroup
                  label="Email"
                  model="forms.mergeAnotherAccount.username"
                  parser={parserLower}
                />
              }
              {
                this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{formGeneralError}</div>
                </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Submit</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
}

export default MergeAnotherAccountAuth;
