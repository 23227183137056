import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ButtonRect} from "../../../../components/UxCommon";
import TextControl from '../../common/FormControls/TextControl';
import { checkRequired } from "../../../CodeSignUp/AccountPage/AccountPage.validation";

class TeamTwitterLinkComponent extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    schoolTeamId: PropTypes.string.isRequired,
    updateTwitterLink: PropTypes.func.isRequired,
    deleteTwitterLink: PropTypes.func.isRequired,
  }

  state = {
    errors: {},
  }

  onTwitterBlur = type => (value) => {
    const errors = checkRequired(value.replace('@', ''));
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    if(!value) {
      return '@'+value;
    }
    return value;
  }

  onHandleChange = type => (value) => {
    value = value.substr(value.indexOf('@'))+value.substr(0,value.indexOf('@'));
    value = value.replaceAll(/[`~!@#$%^&*()=|:" <>?;',.\\//{}[*?\]]/gi, '');
    value = '@'+value;
    const errors = checkRequired(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }

  onClickDelete = (e) => {
    const {schoolTeamId, deleteTwitterLink, onCancelClick} = this.props;
    deleteTwitterLink(schoolTeamId);
    onCancelClick();
    e.preventDefault()
  }

  onClickSave = (e) => {
    const form = this.formRef.current;
    const errors = {
      teamTwitterLink : checkRequired(form.teamTwitterLink.value.replace('@', '')),
    };

    let success = true;

    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({errors});

    if (success) {
      const values = {};
      const {updateTwitterLink, schoolTeamId, onCancelClick} = this.props;
      values.teamTwitterLink = form.teamTwitterLink.value.replace('@', '');
      values.schoolTeamId = schoolTeamId;
      updateTwitterLink(values);
      onCancelClick();
    }
    e.preventDefault();
  }

  formRef = React.createRef();

  render() {
    const {onCancelClick, twitter} = this.props;
    const {errors} = this.state;

    return (
      <div className="ConfirmDialogWrapper">
        <div className="ConfirmDialog">
          <div className="AlbumDialod_DeleteAlbumDialog_Title headCoachTitle">
            {
              twitter ? 'Edit Team Twitter' : 'Add Team Twitter'
            }
          </div>
          <div className="HeadCoachForm" style={{width: '100%'}}>
            <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachAccount_Form">
              <TextControl
                className="CoachAccount_Ctrl HeadCoachCtrl"
                name="teamTwitterLink"
                label="Link"
                placeholder="Link"
                myOnChange={this.onHandleChange('teamTwitterLink')}
                myOnBlur={this.onTwitterBlur('teamTwitterLink')}
                defaultValue={twitter ? `@${twitter}` : '@'}
                errors={errors.teamTwitterLink}
                maxLength = {16}
              />
            </form>
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={this.onClickSave}
          >
            Save
          </ButtonRect>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
          {
            twitter && (
              <ButtonRect
                className="AlbumDialod_DeleteAlbumDialog_Btn"
                onClick={this.onClickDelete}
              >
                Delete
              </ButtonRect>
            )
          }
        </div>
      </div>
    )
  }
}

export default TeamTwitterLinkComponent;
