import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CustomOption extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
  }

  onClick = () => {
    const { option, onClick } = this.props;
    onClick(option);
  }

  render() {
    const { option } = this.props;
    return (
      <button
        type="button"
        className={[
          'CustomSelectControl_CustomOption',
          option.type === 'category' ? 'category' : '',
        ].join(' ')}
        onClick={this.onClick}
      >
        {option.name}
      </button>
    );
  }
}

export default CustomOption;
