import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../UxCommon/Icon/Icon';

class NavButton extends PureComponent {
  static propTypes = {
    isMenuShown: PropTypes.bool.isRequired,
    iconName: PropTypes.string.isRequired,
    txt: PropTypes.string.isRequired,
    isSearchFocused: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    submenu: PropTypes.bool,
    nudge: PropTypes.object,
    tooltip: PropTypes.string,
  }

  componentDidMount() {
  }

  render() {
    const {
      txt,
      isMenuShown,
      isSearchFocused,
      submenu,
      onClick,
      iconName,
      nudge,
      tooltip,
      className,
    } = this.props;
    return (
      <button
        onClick={onClick}
        className={[
          'UxProfile_SNav_NavButton',
          isSearchFocused ? 'hidden' : '',
          isMenuShown ? 'open' : '',
          submenu ? 'submenu' : '',
          className
        ].join(' ')}
      >
        <div className="UxProfile_SNav_NavButton_IconWrapper">
          <Icon
            iconName={iconName}
            className="UxProfile_SNav_NavButton_Icon"
            style={nudge || {}}
            tooltip={tooltip}
          />
        </div>
        <div className="UxProfile_SNav_NavButton_Txt">
          {txt}
        </div>
      </button>
    );
  }
}

export default NavButton;
