import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MarketingUrl } from '../../../../config/env-urls';

import vcTextLogo from '../../../../assets/images/logo.png';

class LogoText extends PureComponent {
  static propTypes = {
    width: PropTypes.string,
  }

  render() {
    const {
      width,
    } = this.props;

    return (
      <div className="LogoText" style={{ width, cursor: 'pointer' }} onClick={() => window.location = MarketingUrl}>
        <img src={vcTextLogo} alt="Virtual Combine Home" title="Virtual Combine Home" />
        <div className="LogoText_Line"/>
      </div>
    );
  }
}

export default LogoText;
