import React from 'react';
import PropTypes from 'prop-types';

const ButtonRect = ({ children, className,disabled=false, ...props }) => (
  <button className={['UxCommon_ButtonRect', className || ''].join(' ')}
   disabled={disabled}
   {...props}
    >
    {children}
  </button>
);
ButtonRect.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ButtonRect;
