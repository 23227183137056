import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from '../../../../UxCommon';
import { Button } from '../../../../UxCommon/Form';
import NewSeasonForm from '../../../../../containers/UxProfile/PlayerData/NewSeasonForm';
import { Sports } from '../../../../../store/actions/ui/routes';

class ZeroStats extends PureComponent {
  static propTypes = {
    onEnterStatsClick: PropTypes.func.isRequired,
    onCancelEnterStats: PropTypes.func.isRequired,
    newSeasonFormIsShown: PropTypes.bool.isRequired,
    selectedUserSport: PropTypes.object,
    match: PropTypes.object.isRequired,
    veryFirst: PropTypes.bool.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    sport: PropTypes.object,
  }

  componentDidMount() {}

  render() {
    const {
      onEnterStatsClick,
      newSeasonFormIsShown,
      onCancelEnterStats,
      selectedUserSport,
      match,
      veryFirst,
      isMyProfile,
      sport,
    } = this.props;
    let sportStr = '';
    if (selectedUserSport) {
      sportStr = Sports[selectedUserSport.sportId];
    }
    if (!isMyProfile && !veryFirst) {
      return null;
    }
    const sportName = sport ? sport.name.toLocaleLowerCase() : '';
    return (
      newSeasonFormIsShown
        ? (
          <Container className="UxProfile_ZeroStats_FormWrapper">
            <NewSeasonForm onCancel={onCancelEnterStats} match={match} />
          </Container>
        ) : (
          <Container className="UxProfile_ZeroStats">
            {
              isMyProfile ? (
                <React.Fragment>
                  <div className={['UxProfile_ZeroStats_Txt', veryFirst ? '' : 'UxProfile_ZeroStats_Txt_VeryFirst'].join(' ')}>
                    {
                      veryFirst
                      ? `You haven't entered any ${sportStr} stats yet`
                      : 'Would you like to enter stats for another team or position?'
                    }
                  </div>
                  <Row className="justify-content-center UxProfile_ZeroStats_EnterStats">
                    <Col className="col-auto">
                      <Button
                        onClick={onEnterStatsClick}
                      >
                        Enter Stats
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <div className="UxProfile_ZeroStats_Txt">
                  {`No stats have been entered yet for ${sportName}`}
                </div>
              )
            }
          </Container>
        )
    );
  }
}

export default ZeroStats;
