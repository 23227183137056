import React, { PureComponent } from 'react';
import { SingleDatePicker } from 'react-dates-custom-month-year-navigation';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import Caption from './DatePickerCaption';

class SingleDatePickerWrapper extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    date: momentPropTypes.momentObj,
    onDateChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    isOutsideRange: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      focused: props.autoFocus,
    };
  }

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  }

  isOutsideRange = () => false;

  renderCaption = (captionProps) => {
    const { onMonthSelect, onYearSelect, ...otherProps } = captionProps;

    const myOnMonthSelect = (month, value) => {
      this.props.onDateChange(moment(month).month(value));
      onMonthSelect(month, value);
    };

    const myOnYearSelect = (month, value) => {
      this.props.onDateChange(moment(month).year(value));
      onYearSelect(month, value);
    };

    /* eslint-disable */
    return (
      <Caption
        {...otherProps}
        onMonthSelect={myOnMonthSelect}
        onYearSelect={myOnYearSelect}
      />
    );
    /* eslint-enable */
  }

  render() {
    const { focused } = this.state;

    return (
      <SingleDatePicker
        {...this.props}
        isOutsideRange={this.props.isOutsideRange || this.isOutsideRange}
        focused={focused}
        onFocusChange={this.onFocusChange}
        renderCaption={this.renderCaption}
      />
    );
  }
}

export default SingleDatePickerWrapper;
