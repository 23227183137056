import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../UxCommon/Icon/Icon';

class OfferBox extends PureComponent {
  static propTypes = {
    offers: PropTypes.array,
  }

  state = {
    open: false,
  }

  componentDidMount() {
    this.initState();
  }

  onClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  initState = () => {
    this.setState({
      open: false,
    });
  }

  render() {
    const { offers } = this.props;
    const { open } = this.state;
    return (
      <div className="PlayerCard_AcademicInfo">
        <button className="PlayerCard_AcademicInfo_Row PlayerCard_AcademicInfo_Btn" onClick={this.onClick}>
          <div className="PlayerCard_AcademicInfo_Label">Offers</div>
          <Icon
            iconName="down-chevron"
            className={[
              'PlayerCard_AcademicInfo_Arrow',
              open ? 'open' : '',
            ].join(' ')}
          />
        </button>
        {
          offers && open && offers.sort().map((value, index) => (
            <div key={index} className="PlayerCard_AcademicInfo_Row">
              <div className="PlayerCard_AcademicInfo_Label" />
              <div className="PlayerCard_AcademicInfo_Value">
                {value.toUpperCase()}
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default OfferBox;
