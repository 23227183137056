import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from './ActivateProfileMembership.models';
import { Routes } from '../../store/actions/ui/routes';
import { playerProfileGet } from '../../store/actions/ui/uxProfile';
import useHasGuestMembership from '../../hooks/useHasGuestMembership';
import LoadingSpinner from '../Common/LoadingSpinner/LoadingSpinner';
import { getSchoolStyles } from '../../modules/CoachWorld/+store';
import { stripeSchoolProduct } from '../../store/actions/ui/welcome/StripeCheckout';
import {
  getEmailSection,
  getInviteLink,
} from './ActivateProfileMembership.api';
import ChecklistComponent from './components/ChecklistComponent.component';
import SectionBeforeChecklistComponent from './components/SectionBeforeChecklist.component';
import SectionPricingComponent from './components/SectionPricing.component';
import InviteLinkComponent from './components/InviteLinkComponent';
import SectionComponent from './components/Section.component';

const ActivateProfileMembership = ({ match, location }) => {
  const { uuid, schoolId, sportId } = useParams();
  const hasMembership = useHasGuestMembership(match, uuid);
  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfile(state, uuid));
  const isRegistered = profile && profile.registered;
  const schoolStyle = useSelector((state) =>
    state.modules.coachWorld.coachWorld.schoolStyle.find(
      (school) => school.id === schoolId,
    ),
  );
  const product = useSelector((state) => state.stripe.product);
  const [inviteLink, setInviteLink] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const schoolRegisteringName = schoolStyle && schoolStyle.name;

  useEffect(() => {
    dispatch(getSchoolStyles([schoolId]));
  }, [schoolId]);

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('wrap-welcome');
    return () =>
      document.getElementsByTagName('html')[0].classList.remove('wrap-welcome');
  }, [uuid]);

  useEffect(() => {
    getInviteLink(uuid, sportId, schoolId).then((inviteURL) =>
      setInviteLink(inviteURL),
    );
  }, [uuid]);

  useEffect(() => {
    getEmailSection(schoolId).then((data) => setEmailTemplate(data));
  }, [uuid]);

  useEffect(() => {
    dispatch(
      stripeSchoolProduct({
        schoolId,
      }),
    );
  }, [schoolId]);

  useEffect(() => {
    if (!profile) {
      dispatch(playerProfileGet(uuid));
    }
  }, [profile]);

  const redirectToPLayerProfile = () => {
    return <Redirect to={`/${Routes.profile}/${uuid}`} />;
  };

  const redirectToOnboarding = () => {
    return <Redirect to={inviteLink} />;
  };

  if (!profile || !schoolStyle || !product || !inviteLink || !emailTemplate) {
    return <LoadingSpinner loading loadingMsg={'Loading'} />;
  }

  if (hasMembership) {
    return redirectToOnboarding();
  }

  if (isRegistered && hasMembership) {
    return redirectToPLayerProfile();
  }

  const replaceWarningEmoji = (message) => {
    return message.replaceAll(
      '⚠',
      '<img src="https://fonts.gstatic.com/s/e/notoemoji/15.0/26a0/72.png" height="20" width="20"/>',
    );
  };

  return (
    <React.Fragment>
      <div className="welcome-bg-pic" />
      <div className="team-invite">
        <div className="container">
          <h1>
            <b>{schoolId === '8e1bbd47-eed0-4ce9-b098-4a5109981b4f' ? `Welcome to ${schoolRegisteringName}, ${profile.first}!` : `Hi, ${profile.first}`}</b>
          </h1>
          <div>
            <img
              src={schoolStyle.paidInviteSchoolLogo}
              width="200"
              alt={schoolStyle.name}
            />
            {!!emailTemplate.template.teamInvite && <h2>Team Invite </h2>}
            <div style={{ marginTop: '20px' }}></div>
            <SectionBeforeChecklistComponent
              checklists={emailTemplate.template.sectionBeforeChecklist}
            />
            <ChecklistComponent checklists={emailTemplate.template.checklist} />
            <div>
            {emailTemplate.template.sectionAfterChecklist ? 
            <React.Fragment>
              <SectionComponent
                content={replaceWarningEmoji(
                  emailTemplate.template.sectionAfterChecklist[0],
                )}
              />
              <SectionComponent
                content={emailTemplate.template.sectionAfterChecklist[1]}
              />
            </React.Fragment>
              : ''}
              <SectionComponent
                content = {emailTemplate.template.sectionGreeting.replace(
                  '[firstName]',
                  profile.first+'\'s',
                ).replace(
                  '[firstNameWithoutApostrophe]',
                  profile.first,
                )}
              />
            </div>
            <SectionPricingComponent
              content={emailTemplate.template.sectionPricing}
            />
          </div>

          <InviteLinkComponent inviteLink={inviteLink} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActivateProfileMembership;
