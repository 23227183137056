import { validateEmail } from '../../../../store/actions/ui/formUtils/validators';

export const checkHeightFtValidation = (value) => {
    let error = checkRequired(value);
    if (!error) {
        if (value > 9) {
            error =  "Please enter the valid height in ft.";
        }
    }
    return error ? [error] : [];
}

export const checkHeightInchValidation = (value) => {
    let error = checkRequired(value);
    if (!error) {
        if (value > 12) {
            error =  "Please enter the valid height in inches.";
        }
    }
    return error ? [error] : [];
}

export const checkAddress = (value) => {
    let error = '';
    error = checkRequired(value);
  
    if(!error) {
        if(!(/^[a-zA-Z0-9\s,.():;*@"'_&-]*$/i).test(value)) {
            error = 'This field can only contain letters with space and some special characters';
        }
    }
    return error ? [error] : []; 
  }
  
  export const checkAddress2 = (value) => {
    let error = '';  
    let isError = false;
    if(!(/^[a-zA-Z0-9\s,.():;*@"'_&-]*$/i).test(value)) {
      isError = true;
      error = 'This field can only contain letters with space and some special characters';
    }
    return isError ? [error] : [];
  }

export const checkZipCode = (value) => {
    let error = checkRequired(value);
    return error ? [error] : [];
}

export const checkGender = (value) => {
    let error = checkRequired(value);
    return error ? [error] : '';
}

export const checkRequired = (value) => {
    return !value ? 'This field is required' : '';
}

export const checkCityState = (value) => {
    let error = '';
    error = checkRequired(value);
  
    if (!error) {
      if(!(/^[a-zA-Z\s]*$/i).test(value)) {
        error = 'This field can only contain letters';
      }
    }
    return error ? [error] : [];
}

export const validateRequired = (value) => {
    return !value ? ['This field is required'] : [];
}

export const validatePositionRequired = (value) => {
  return value.length === 0  ? ['This field is required'] : []
}

export const checkUsername = (value) => {
    const possibleErrors = [
      validateEmail()(value).email,
    ];
    const errors = possibleErrors.reduce((prev, curr) => {
      if (curr) {
        prev.push(curr);
      }
      return prev;
    }, []);
    return errors;
  };
  