import { combineEpics } from 'redux-observable';

import { playerStatsGetEpic } from './read';
import { createUpdateEpics, firstSeasonFormEpics, newSeasonFormEpics } from './createUpdate';
import { deleteSeasonEpic, deleteSeasonFromFormsEpic } from './delete';

export const statsEpics = combineEpics(
  playerStatsGetEpic,
  createUpdateEpics,
  deleteSeasonEpic,
  deleteSeasonFromFormsEpic,
  firstSeasonFormEpics,
  newSeasonFormEpics,
);

export const dummy = {};
