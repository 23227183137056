import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTokenFragmentMergeMap,
  manualCancelApiCallFragment,
} from '../uxProfile/utils';
import {
  asyncErrorAction,
  asyncFinishAction,
  asyncStartAction,
} from '../async';
import { checkIfInviteIsValid } from '../../data/user/profile';
import { ajax } from 'rxjs/ajax';
import Urls from '../../../../config/urls';
import { GET_PROFILES } from '../../../../modules/CoachWorld/+store';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { actions } from 'react-redux-form';

export const subscribe = (data) =>
  ajax.post(Urls.stripe.subscribe(), JSON.stringify(data), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

export const getSchoolProducts = schoolId =>
  ajax.getJSON(
    Urls.stripe.products(schoolId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );

export const STRIPE_CHECKOUT = 'stripeCheckout';
export const stripeCheckout = (payload) => (dispatch) => {
  dispatch(asyncStartAction(STRIPE_CHECKOUT));
  dispatch({ type: STRIPE_CHECKOUT, payload });
};

export const STRIPE_SCHOOL_PRODUCT = 'stripeSchoolProduct';
export const stripeSchoolProduct = (payload) => (dispatch) => {
  dispatch(asyncStartAction(STRIPE_SCHOOL_PRODUCT));
  dispatch({ type: STRIPE_SCHOOL_PRODUCT, payload });
};

export const CONTINUE_ONBOARDING = 'continueOnBoarding';
export const continueOnBoarding = (payload) => (dispatch) => {
  dispatch(
    asyncFinishAction(CONTINUE_ONBOARDING, CONTINUE_ONBOARDING, payload),
  );
};

const stripeCheckoutEpic = (action$) =>
  action$.pipe(
    ofType(STRIPE_CHECKOUT),
    getTokenFragmentMergeMap(),
    switchMap(({ action }) =>
      subscribe(action.payload)
        .pipe(manualCancelApiCallFragment(action$, action, STRIPE_CHECKOUT))
        .pipe(
          ajaxErrorHandlerEpicFragment(),
          catchError((error) =>
            of({
              success: false,
              actions: [asyncErrorAction(action.type, STRIPE_CHECKOUT, error)],
            }),
          ),
        ),
    ),
    switchMap((result) => {
      if (result.success) {
        if(result.action.payload.googlePayEvent){
          result.action.payload.googlePayEvent.complete('success');
        }
        return of(
          asyncFinishAction(result.action.type, 'stripeCheckout', {
            email: result.action.payload.email,
            parentName: result.action.payload.parentName,
            uuid: result.action.payload.uuid,
          }),
        );
      }
      if (result.actions) {
        return of(...results.actions);
      }
      return of(result.action);
    }),
  );


const stripeSchoolProductsEpic = (action$) =>
  action$.pipe(
    ofType(STRIPE_SCHOOL_PRODUCT),
    getTokenFragmentMergeMap(),
    switchMap(({ action }) =>
      getSchoolProducts(action.payload.schoolId)
        .pipe(manualCancelApiCallFragment(action$, action, STRIPE_SCHOOL_PRODUCT))
        .pipe(
          ajaxErrorHandlerEpicFragment(),
          catchError((error) =>
            of({
              success: false,
              actions: [asyncErrorAction(action.type, STRIPE_SCHOOL_PRODUCT, error)],
            }),
          ),
        ),
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(result.action.type, STRIPE_SCHOOL_PRODUCT, {
            product: result.response[0]
          }),
        );
      }
      if (result.actions) {
        return of(...results.actions);
      }
      return of(result.action);
    }),
  );


export const stripeCheckoutEpics = combineEpics(
  stripeCheckoutEpic,
  stripeSchoolProductsEpic,
);
