import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/SportNav/SportNav';
import {
  getSelectedSport,
  getSortedSports,
  getCurrentUuid,
} from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  return {
    uuid: getCurrentUuid(state, ownProps),
    userSports: getSortedSports(state, ownProps.match),
    selectedUserSport,
    editUserSportId: state.ui.uxEditProfile.editSports.editSportId,
  };
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
