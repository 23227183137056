import React from 'react';

const SectionComponent = ({ content }) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: content,
      }}></p>
  );
};

export default SectionComponent;
