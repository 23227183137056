import {connect} from 'react-redux';
import Component from './Combine.component';
import {getAthletes, selectIsCoach, selectLoadingMsg} from '../+store/coachWorld';
import {deleteLastPasteDataCombine, getCombine, setExpiringCombineMsg, updateCombine, testCodesForZeroValues} from '../+store/combine';
import {getFilters} from '../+store/coachWorld/coachWorld.utils';
import {
  selectFilteredCombineAthletes,
  selectFilteredCombineCoachAthletes,
  selectSchoolTeamsListFromCombine,
  selectCoachSchoolTeamsListFromCombine,
  selectLongLoading,
} from '../+store/combine/combine.selectors';
import {checkIfUpdateNeededInFastMode, combineUpdateId, paginateCombineBy} from '../+store/combine/combine.utils';
import {
  onPhotoDrop,
  closeAlbumDialog,
  albumMediaFormModel,
  albumMediaFormObject,
  clearStagedMedia,
  stageAlbumMedia,
  unstageNewMedia,
  deletePhoto,
  stageNewTag,
  closeExternalVideoDialog,
  initAlbumDialog,
  unstageNewTag,
  openExternalVideoDialog,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import {makeGetAlbumsState, makeGetTags, makeGetMediaList, makeGetTagRelMedia} from '../../../selectors/Photos/index';
import {uploadVideo, clearAthleteVideoId} from '../../../store/actions/ui/uxProfile/photosVideos/actions';
import {formSubmit} from '../../../store/actions/ui/formUtils';
import {saveTestResults, deleteBulkTestCombine, createCombineAward, getCombinesAwards,getCombinePreCombineTestRes, saveTestResultsWithoutResult} from '../+store/combine';
import {upadateVideoId} from '../../../store/actions/ui/uxPlayerData/tests';
import {getComparedAwards} from '../../CoachWorld/+store'


const getAlbumTags = (photoList, tagRel) => {
  const resultArr = [];
  const filteredTagRel = tagRel.filter(rel => photoList.some(p => rel.mediaId === p.id));

  const allMediaIds = filteredTagRel.map(item => item.mediaId);
  const allTagIds = filteredTagRel.map(item => item.tagId);
  const uniqueTagIds = [...new Set(allTagIds)];
  const uniqueMediaIds = [...new Set(allMediaIds)];

  uniqueTagIds.forEach((uTagId) => {
    const tempObj = filteredTagRel.filter(fTag => fTag.tagId === uTagId);
    if (tempObj && tempObj.length === uniqueMediaIds.length) {
      resultArr.push(tempObj[0].tagId);
    }
  });
  return resultArr;
};

const mapStateToProps = (state, ownProps) => {
  const {schoolId} = ownProps.match.params;
  const loadingMsg = selectLoadingMsg(state);
  const {combineId} = ownProps.match.params;
  const selectedFilters = getFilters(state, ownProps);
  const athletes = selectFilteredCombineCoachAthletes(state, ownProps);
  const paginated = paginateCombineBy(athletes, 'familyName', 5, 150);
  const paginatedKeys = Object.keys(paginated);
  const combine = combineId && state.modules.coachWorld.combine.combine[combineId];
  const schoolAwardLogo = state.data.awardLogo.schoolAwardLogo[schoolId]
  && Object.keys(state.data.awardLogo.schoolAwardLogo[schoolId]).length
    ? state.data.awardLogo.schoolAwardLogo[schoolId] : null;

  const rosters = getAthletes(state);
  const gradYears = [...new Set(rosters
    .filter(ath => !!ath.gradYear)
    .map(ath => ath.gradYear)
  )].sort((a, b) => b - a);
  const photoList = makeGetMediaList(state);
  const getTagsState = makeGetTags(state);
  const getTagRelMedia = makeGetTagRelMedia(state);

  const albumTags = getAlbumTags(photoList(state, ownProps), getTagRelMedia(state, ownProps));
  const allTags = getTagsState(state, ownProps);
  let selectedTags = albumTags;
  selectedTags = allTags.filter(tag => selectedTags &&
    selectedTags.some(selectedTag => selectedTag === tag.id));
  const tags = state.ui.uxProfile.photosVideos.albumDialog.stagedTag;
  if (tags && tags.length > 0) {
    selectedTags = [...selectedTags, ...tags];
  }
  const getAlbumsState = makeGetAlbumsState();

  return {
    combineId,
    loading: !!loadingMsg,
    loadingMsg,
    paginatedKeys,
    paginated,
    selectedFilters,
    coachId: state.data.cognito.uuid,
    stagedMedia: state.ui.uxProfile.photosVideos.albumDialog.stagedMedia,
    combine: combine,
    isCombineOpen: combine &&  combine.isOpen,
    athletes,
    bank: ownProps.match.params.bank,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
    shouldUpdate: checkIfUpdateNeededInFastMode(
      state.modules.coachWorld.combine.shouldUpdate,
      combineUpdateId(combineId),
    ),
    state,
    updateCombineLoader: state.modules.coachWorld.combine.updateCombineLoader,
    loadingComparedTestResult: state.modules.coachWorld.combine.loadingComparedTestResult,
    athleteDataProcessing: state.modules.coachWorld.combine.athleteCombineResultProcessing,
    processingAllLaser: state.modules.coachWorld.combine.processingAllLaser,
    rrfForm: state.forms.forms.albumDialog,
    albums: getAlbumsState(state, ownProps),
    tagRelMedia: getTagRelMedia(state, ownProps),
    photoList: photoList(state, ownProps),
    expandedDialogVideos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    videoList: state.data.photo.videos,
    videoLoading: !!state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    videoLoadingMsg: state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    loadingProgress: state.ui.uxProfile.photosVideos.albumDialog.loadingProgress,
    isExternalVideoDialogOpen: state.ui.uxProfile.photosVideos.externalVideo.isDialogOpen,
    selectedTags,
    refreshPopUp: state.modules.coachWorld.combine.refreshPopUp,
    schoolTeamsList: selectCoachSchoolTeamsListFromCombine(state, ownProps),
    staging: state.modules.coachWorld.combine.combineResultsStaging,
    longLoading: !!selectLongLoading(state, ownProps),
    schoolAwardLogo,
    gradYears,
    isCoach: selectIsCoach(state),
    combineAwards: state.modules.coachWorld.combine.combineAwards,
    savedCombineAwards: state.modules.coachWorld.combine.savedCombineAwards,
    comparedAwards: getComparedAwards(state, ownProps),
    isSaveCombineAwardsDialogVisible:
      state.modules.coachWorld.combine.isSaveCombineAwardsDialogVisible,
    isAwardGeneratedDialogVisible: state.modules.coachWorld.combine.isAwardGeneratedDialogVisible,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, {dispatch}, ownProps) => {
  const {state, ...newStateProps} = stateProps;
  const photoList = makeGetMediaList(state);
  const getTagRelMedia = makeGetTagRelMedia(state);
  const sportId = newStateProps.combine && newStateProps.combine.sportId;

  return {
    ...newStateProps,
    ...ownProps,
    getCombine: (combineId) => {
      dispatch(getCombine(combineId));
    },
    clearAthleteVideoId: () => {
      dispatch(clearAthleteVideoId())
    },
    onDrop: onPhotoDrop(dispatch),
    onCloseClick: () => {
      dispatch(closeAlbumDialog());
    },
    getPhotoCardRrfForm: mediaId => albumMediaFormObject(state, mediaId),
    getPhotoCardRrfFormModel: albumMediaFormModel,
    formInit: (album) => {
      dispatch(actions.reset('forms.albumDialog'));
      dispatch(clearStagedMedia());
      if (album) {
        dispatch(actions.merge('forms.albumDialog', {
          newAlbumName: '',
          selectedAlbum: album.id,
          newTagName: '',
          albumName: album.title,
        }));
        dispatch(initAlbumDialog(getAlbumTags(
          photoList(state, ownProps),
          getTagRelMedia(state, ownProps),
        )));
      } else {
        dispatch(actions.setInitial('forms.albumDialog'));
      }
    },
    saveTestResults: (athlete, column, combine, value, isUploadingVideo=false, oldValue = {}) => {
      const testResultId = (athlete.testResults
        && athlete.testResults[column.standardTestObjectId])
        ? athlete.testResults[column.standardTestObjectId].id
        : null;
      let result = value;
      const testObject = {
        testId: athlete.test
          ? athlete.test.id
          : athlete.testId ? athlete.testId
          : null,
        testResultId,
        stdTestObjId: column.standardTestObjectId,
        result: value ? value : 0,
        oldResultVideo: (oldValue && oldValue.videoId) ? oldValue.videoId : null,
        openDate: combine.openDate.format('YYYY-MM-DD'),
        combineId: combine.id,
        columnRowId: `${athlete.userId}:${column.standardTestObjectId}`,
        videoId: value.videoId ,
        processed: value.processed ,
        isZeroRepAllowed: false,
        isFeetInches: column && column.standardUnitOfMeasureCode === 'FEET-INCHES'
      };
      if (value && value.reps) {
        result = Number(value.result) > 0 ? Number(value.result)
                  : Number(value.result) === 0 ? 0
                  : null;
        if (value.reps === '0' && column && column.standardUnitOfMeasureCode === 'FEET-INCHES') {
          testObject.isZeroRepAllowed = true;
        } else {
          testObject.reps = value.reps ? Number(value.reps) : 1;
        }
        testObject.result = result;
      }
      if (value && typeof value.isLaser !== 'undefined') {
        result = Number(value.result) > 0 ? Number(value.result)
                  : Number(value.result) === 0 ? 0
                  : null;
        testObject.isLaser = value.isLaser;
        testObject.result = result;
      }
      if (value) {
        result = (Number(value.result) > 0
                  || ((column.standardFormat.code === "NEGATIVE-DEGREE-HUNDREDTH"
                      || column.standardFormat.code === "NEGATIVE-INCHES-HUNDREDTH"
                      || column.standardFormat.code === "NEGATIVE-NUMERIC-HUNDREDTHS"
                      || column.standardFormat.code === "NEGATIVE-PERCENTAGE-HUNDREDTHS"
                      || column.standardFormat.code === "NEGATIVE-TIME-SEC-HUNDREDTHS")
                  && Number(value.result) < 0))
                  ? Number(value.result)
                  : Number(value.result) === 0 ? 0
                  : null;
        testObject.result = result;
      }
      if ((testResultId || result || (result == 0 && testCodesForZeroValues.includes(column.testCategoryCode))) && !isUploadingVideo) {
        dispatch(saveTestResults(athlete.userId, testObject));
      }

      if (isUploadingVideo) {
        dispatch(saveTestResultsWithoutResult(athlete.userId, testObject));
      }
    },
    deleteBulkTestCombine: (deleteData) =>{
      dispatch(deleteBulkTestCombine(deleteData));
    },
    deleteLastPasteDataCombine: (deleteData) =>{
      dispatch(deleteLastPasteDataCombine(deleteData));
    },
    saveVideoTestResult: (testResult, videoId, categoryId, videoProcessed) => {
      dispatch(upadateVideoId(testResult, videoId, sportId, categoryId, videoProcessed))
    },
    showMediaIsNotAccepted: (videoLimitMsg) => {
      dispatch(stageMediaProblem(videoLimitMsg));
    },
    clearNewAlbumName: () => {
      dispatch(actions.change('forms.albumDialog.newAlbumName', ''));
    },
    clearAlbumSelect: () => {
      dispatch(actions.change('forms.albumDialog.selectedAlbum', ''));
    },
    stageAlbumMedia: (media) => {
      dispatch(stageAlbumMedia(media, media.isAPhoto));
    },
    unstageNewMedia: (media) => {
      dispatch(unstageNewMedia(media));
    },
    deleteMedia: (media) => {
      if (media.isAPhoto) {
        dispatch(deletePhoto(media.id));
      } else {
        dispatch(deleteVideo(media.id));
      }
    },
    onSaveClick: () => {
      const album = newStateProps.albums.find(a => a.id === state.forms.albumDialog.selectedAlbum);
      dispatch(formSubmit('forms.albumDialog', {album, dispatch}));
    },
    createAward: (combineId, data, schoolId) => {
      dispatch(createCombineAward(data, combineId, schoolId));
    },
    onAddTagClick: () => {
      const newValue = state.forms.tagDialog.newTagName.new;
      if (newValue !== '') {
        const existingTag = newStateProps.tags.find(tag => tag.label === newValue);
        if (existingTag) {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList));
        } else {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList, newValue));
        }
        dispatch(actions.change('forms.tagDialog.newTagName.new', ''));
      }
    },
    onDeleteTagClick: (tagId) => {
      dispatch(unstageNewTag(newStateProps.tags.filter(a => a.id === tagId), newStateProps.media));
    },
    setExpiringCombineMsg: (msg, msgType, seconds) => {
      dispatch(setExpiringCombineMsg(msg, msgType, seconds));
    },
    updateCombine: (data, combine) => dispatch(updateCombine(data, combine)),
    onExternalVideoCancelClick: () => {
      dispatch(closeExternalVideoDialog());
    },
    onExternalVideoClick: () => {
      dispatch(openExternalVideoDialog());
    },
    getCombineAwards: (combineIds) => {
      dispatch(getCombinesAwards(combineIds));
    },
    getCombineAndPrevCombineTest: (combineId) => {
      dispatch(getCombinePreCombineTestRes(combineId));
    },
  }
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;

