import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/UxEditProfile/EditSports/EditSports';
import {
  getSelectedSport,
  getProfile,
  getUserTeams,
  getSortedSports, getAuthenticatedUserProfile, getCurrentUuid,
} from '../UxCommon/Utils';
import { alertOrNavigate } from '../UxCommon/NativeUtils';
import {
  editSportFormInit,
  editSportInit,
  editSportShowDeleteDialog,
  editSportHideDeleteDialog,
  editSportDelete,
  showUpdateProfilePhotoDialog,
  toggleActiveSwitch,
  saveNewProfilePhoto,
  updateProfilePhoto,
  saveUserSport,
} from '../../store/actions/ui/uxEditProfile';
import { onEditUserSport } from '../../store/actions/ui/uxEditProfile/editSports';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { routeChangedAlert } from '../../store/actions/ui/routes';
import { editSportFormInputs } from '../../store/actions/ui/uxEditProfile/utils';

const mapStateToProps = (state, ownProps) => {
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  const allUserTeams = (canEditProfile && canEditProfile.allUserSchoolTeam) && canEditProfile.allUserSchoolTeam;
  let stdPositions = [];
  if (selectedUserSport
    && state.data.sport.statsStd
    && state.data.sport.statsStd[selectedUserSport.sportId]) {
    stdPositions = state.data.sport.statsStd[selectedUserSport.sportId].positions;
  }
  return {
    uuid: isCoach ? getCurrentUuid(state, ownProps.match) : state.data.cognito.uuid,
    selectedUserSport,
    stdPositions,
    editUserSportId: state.ui.uxEditProfile.editSports.editSportId,
    user: getProfile(state, ownProps.match),
    rrfForm: state.forms.forms.userSport,
    formNeedsInit: state.ui.uxEditProfile.editSports.formNeedsInit,
    needsInit: state.ui.uxEditProfile.editSports.needsInit,
    sports: state.data.sport.sports,
    profile: isCoach ? getProfile(state, ownProps.match) : getAuthenticatedUserProfile(state),
    isDeleteDialogShown: state.ui.uxEditProfile.editSports.isDeleteDialogShown,
    loading: state.ui.uxEditProfile.editSports.loading,
    loadingMsg: state.ui.uxEditProfile.editSports.loadingMsg,
    userSports: getSortedSports(state, ownProps.match),
    userTeams: getUserTeams(state, ownProps.match) || [],
    addingNewSport: state.ui.uxEditProfile.editSports.addingNewSport,
    formSubmitSuccess: state.ui.uxEditProfile.editSports.formSuccess,
    formGeneralError: '',
    inputs: editSportFormInputs,
    allUserTeams,
    isCoach,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    formInit: editSportFormInit(dispatch),
    init: editSportInit(dispatch),
    saveImage: saveNewProfilePhoto(dispatch),
    onOldProfilePhotoSelected: updateProfilePhoto(dispatch),
    changeFieldValue: (field, value) => {
      dispatch(actions.change(`forms.userSport.${field}`, value));
    },
    submit: () => {
      dispatch(formSubmit('forms.userSport', {
        profile: state.data.user.profiles[newStateProps.uuid],
      }));
    },
    saveUserSport: (userSport) => {
      saveUserSport(userSport, state.data.user.profiles[newStateProps.uuid])(dispatch);
    },
    showDeleteDialog: editSportShowDeleteDialog(dispatch),
    hideDeleteDialog: editSportHideDeleteDialog(dispatch),
    deleteUserSport: editSportDelete(dispatch),
    onSportPick: (sportId) => {
      dispatch(actions.change('forms.userSport.sportId', sportId));
    },
    handleEditUserSport: (sportId) => {
      dispatch(onEditUserSport(sportId));
    },
    onPositionSelect: (codes) => {
      dispatch(actions.change('forms.userSport.positionArray', codes));
    },
    onUpdateProfilePhotoClicked: () => {
      dispatch(showUpdateProfilePhotoDialog());
    },
    onRemoveProfilePhotoClicked: () => {
      dispatch(actions.change('forms.userSport.photoUrl', ''));
      dispatch(formSubmit('forms.userSport', {
        profile: state.data.user.profiles[newStateProps.uuid],
      }));
    },
    onSwitchClicked: () => {
      dispatch(toggleActiveSwitch());
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
