import { ofType } from 'redux-observable';

import { ROUTE_CHANGED } from '../../routes';

export const cancelOnRouteChange = action$ => action$.pipe(ofType(ROUTE_CHANGED));

export const awardFormInputs = [
  'name',
  'type',
  'date',
  'url',
  'desc',
  'display',
];
