import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import SportNav from '../../../containers/UxProfile/SportNav';
import { Routes, Sports } from '../../../store/actions/ui/routes';
import {Line, ButtonLink, Collapsable, SecondaryIconButton} from '../../UxCommon';
import PhotosRow from '../../../containers/UxProfile/PhotosRow';
import BioRow from './BioRow/BioRow';
import ProfileStats from '../../../containers/UxProfile/ProfileStats';
import GetStats from '../../../containers/UxCommon/Loaders/GetStats';
import GetFavorites from '../../../containers/UxCommon/Loaders/GetFavorites';
import GetFavoriteAwards from '../../../containers/UxCommon/Loaders/GetFavoriteAwards';
import AwardsRow from '../../../containers/UxProfile/AwardsRow';
import CardContent from '../PlayerCard/CardContent/CardContent';
import AvatarBg from '../PlayerCard/AvatarBg/AvatarBg';
import MainMenuButton from '../../../containers/UxCommon/MainMenuButton';
import { privacyType } from '../../../utils';
import PrivacyControl from '../../../containers/UxCommon/PrivacyControl/PrivacyControl';
import GetAllTeams from '../../../containers/UxCommon/Loaders/GetAllTeams';
import TeamsRow from '../../../containers/UxProfile/TeamsRow';
import GetGameStats from '../../../containers/UxCommon/Loaders/GetGameStats';
import GameStats from '../../../containers/UxProfile/GameStats';
import ExternalProfileCard from "../PlayerCard/CardContent/ExternalProfileCard/ExternalProfileCard";
import {RosterSortByOptions} from "../../../modules/CoachWorld/+store";


class PlayerProfile extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    user: PropTypes.object,
    userSports: PropTypes.array.isRequired,
    selectedUserSport: PropTypes.object,
    sports: PropTypes.array.isRequired,
    favorites: PropTypes.array.isRequired,
    onShareClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    athleteTeamRosters: PropTypes.array,
    athleteTeamRostersLoadingMsg: PropTypes.string,
    isCoach: PropTypes.bool,
  };

  state = {
    cardHeight: 500,
    sport:"",
  }

  componentDidMount() {
    this.setCardHeight();
  }

  componentDidUpdate(){
    const {currUuid,getTests, selectedUserSport,getFavoriteAwards,getStats} =  this.props;
    const sportId = selectedUserSport && selectedUserSport.sportId || '';
    const sport = Sports[sportId];
    if(this.state.sport !== sport && sportId){
      getTests(currUuid,sportId,"Loading Combine Records");
      getStats(currUuid, sportId, 'Loading stats');
      getFavoriteAwards(currUuid,sportId);
      this.setState({sport:sport})
    }
  }

  setCardHeight = () => {
    this.setState({
      cardHeight: document.querySelector('.PlayerProfile_PlayerCard').offsetHeight,
    });
  }

  getRoute = (page, hash = '') => {
    const { match } = this.props;
    const {sport} = this.state;
    const {schoolId}=  match.params;
    return `/${Routes.profile}/${match.params.uuid}/${page}/${sport}${schoolId ? `/${schoolId}`: ''}${hash}`;
  };

  renderSportNav = (props) => {
    const { userSports } = this.props;
    return <SportNav {...props} showAddSport={userSports.length === 0} />;
  };

  signInToUpdate = () => {
    const {history} = this.props;
    history.push({
      pathname: `/${Routes.welcome}/${Routes.login}`,
    });
  }

  render() {
    const {
      match,
      user,
      selectedUserSport,
      sports,
      favorites,
      onShareClick,
      isMyProfile,
      combineRecords,
      favoritesAwards,
      userStats,
      personalRecords,
      userSports,
      canEditProfile,
      externalProfile,
      currentSportId,
      isCoach,
      athleteTeamRosters,
      isLoggedIn
    } = this.props;
    // console.log(this.props,'plyer profile js');
    const { cardHeight } = this.state;
    let selectedSportHasStats = false;
    if (selectedUserSport) {
      const selectedSport = sports.filter(sport => ((sport.id === selectedUserSport.sportId)));
      if (selectedSport[0] && selectedSport[0].hasStats === 1) {
        selectedSportHasStats = true;
      }
    }


    let hasMembership;
    if ((isCoach) || (user && !user.isPaidProfile)) {
      hasMembership = true;
    }
    else {
      hasMembership =  !!(user && user.isPaidProfile && user.isSubscribed);
    }
    return (
      <div>
        <div className="UxProfile_PlayerProfile">
          <MainMenuButton colorBoundary={(cardHeight + 36)} isCoach={isCoach} isMyProfile={isMyProfile}  />
          <AvatarBg className="PlayerProfile_PlayerCard hidden-md-up" user={user} selectedUserSport={selectedUserSport} >
            <CardContent
              className="PlayerProfile_PlayerCardContent"
              user={user}
              selectedUserSport={selectedUserSport}
              onShareClick={onShareClick}
              isMyProfile={isMyProfile}
              match={match}
              athleteTeamRosters={athleteTeamRosters}
            />
            <ExternalProfileCard
              externalProfile ={externalProfile}
              match ={match}
              currentSportId ={currentSportId}
              isMyProfile ={isMyProfile}
            />
          </AvatarBg>
          {/* <BioRow isMyProfile={isMyProfile} user={user} match={match} />
          <Line className="UxProfile_PlayerProfile_Line SportNav_Line" /> */}
          <div
            className={['UXProfile_Player_TopBar', userSports.length > 2 ? `UxProfile_maxSports` : ''].join(' ')}>
          <Collapsable title="Player's Sport - Select a sport to view" className="UxProfile_Collapsable">
            <Route path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`} render={this.renderSportNav} />
          </Collapsable>
            {
              !isLoggedIn && user && !!user.registered && !!user.isSubscribed &&
              <SecondaryIconButton
                className="Photos_TopBar_signInBtn" iconName="login-1" onClick={this.signInToUpdate}>
                Sign In to Update
              </SecondaryIconButton>
            }
          </div>
          {
           selectedUserSport && selectedUserSport.bio &&
           (isMyProfile || (user && !user.privacy_bio)) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <Collapsable title="Bio" className="UxProfile_Collapsable bio">
              <PrivacyControl
                match={match}
                privacyType={privacyType.BIO}
              />
              <p className={"bio_Para"}>{`${selectedUserSport.bio}`}</p>
              <div/>
              </Collapsable>
            </React.Fragment>
          }
          <Line className="UxProfile_PlayerProfile_Line" />
          <Collapsable title="Teams" className="UxProfile_Collapsable">
            <div />
            <GetAllTeams match={match}>
              <TeamsRow />
            </GetAllTeams>
            {/* <div
              className="UxProfile_Team_Wrapper"
              style={{ minHeight: (athleteTeamRostersLoadingMsg ? '300px' : '0px') }}
            >
              <LoadingSpinner
                loading={!!athleteTeamRostersLoadingMsg}
                loadingMsg={athleteTeamRostersLoadingMsg}
                style={{ textTransform: 'none' }}
              />
            </div> */}
          </Collapsable>
          {
            (isMyProfile || (user && !user.privacy_media)) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <Collapsable title="Photos and Videos" className="UxProfile_Collapsable">
                <ButtonLink
                  style={{ display: !favorites.length ? 'none' : 'flex' }}
                  to={this.getRoute(Routes.photos)}
                  className={ !hasMembership ? 'disabled' : '' }
                >
                  View All
                </ButtonLink>
                <GetFavorites match={match}>
                  <PhotosRow match={match} isMember={ hasMembership } />
                </GetFavorites>
                <PrivacyControl match={match} privacyType={privacyType.MEDIA} />
              </Collapsable>
            </React.Fragment>
          }
          {
            combineRecords && combineRecords.length > 0 &&
            (isMyProfile || (user && !user.privacy_combine_records)) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <Collapsable title="Combine Records" className="UxProfile_Collapsable">
                <div style={{ display: 'flex' }}>
                  <ButtonLink
                    className={ !hasMembership ? 'disabled' : '' }
                    to={this.getRoute(Routes.data, Routes.hashPerformance)}
                  >
                    View All
                  </ButtonLink>
                  <PrivacyControl
                    match={match}
                    privacyType={privacyType.RECORDS}
                    buttonStyle={{
                      marginRight: window.innerWidth > 768 ? 0 : 8,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <GetStats match={match}>
                  <ProfileStats match={match} personalRecords={combineRecords}/>
                </GetStats>
              </Collapsable>
            </React.Fragment>
          }
          {/* {
            selectedSportHasStats && userStats.length>0 &&
            (isMyProfile || (user && !user.privacy_stats)) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <Collapsable title="Stats" className="UxProfile_Collapsable">
                <div style={{ display: 'flex' }}>
                  <ButtonLink
                    to={this.getRoute(Routes.data, Routes.hashStats)}
                  >
                    View All
                  </ButtonLink>
                  <PrivacyControl
                    match={match}
                    privacyType={privacyType.STATS}
                    buttonStyle={{
                      marginRight: window.innerWidth > 768 ? 0 : 8,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <GetGameStats match={match}>
                  <GameStats match={match} />
                </GetGameStats>
              </Collapsable>
            </React.Fragment>
          } */}
          {
            favoritesAwards.length > 0 &&
            (isMyProfile || (user && !user.privacy_awards)) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <Collapsable title="Awards" className="UxProfile_Collapsable">
                <div style={{ display: 'flex' }}>
                  <ButtonLink
                    to={this.getRoute(Routes.awards)}
                    className={ !hasMembership ? 'disabled' : '' }
                  >
                    View All
                  </ButtonLink>
                  <PrivacyControl
                    match={match}
                    privacyType={privacyType.AWARDS}
                    buttonStyle={{
                      marginRight: window.innerWidth > 768 ? 0 : 8,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <GetFavoriteAwards match={match}>
                  <AwardsRow match={match} />
                </GetFavoriteAwards>
              </Collapsable>
            </React.Fragment>
          }
          {
            // (isMyProfile || (user && !user.privacy_endorsements)) &&
            // <React.Fragment>
            //   <Line className="UxProfile_PlayerProfile_Line" />
            //   <Collapsable title="Endorsements" className="UxProfile_Collapsable">
            //     <ButtonLink
            //       to={this.getRoute(Routes.endorsements)}
            //       style={{ display: 'none' }}
            //     >
            //       View All
            //     </ButtonLink>
            //     <div style={{ marginBottom: '40px' }}>
            //       <div>{`"Endorsements" coming soon!${''}`}</div>
            //     </div>
            //   </Collapsable>
            // </React.Fragment>
          }
          {
            // (isMyProfile || (user && !user.privacy_network)) &&
            // <React.Fragment>
            //   <Line className="UxProfile_PlayerProfile_Line" />
            //   <Collapsable title="Player's Network" className="UxProfile_Collapsable">
            //     <ButtonLink
            //       to={this.getRoute(Routes.playersNetwork)}
            //       style={{ display: 'none' }}
            //     >
            //       View All
            //     </ButtonLink>
            //     <div style={{ marginBottom: '40px' }}>
            //       <div>{`"Player's Network" coming soon!${''}`}</div>
            //     </div>
            //   </Collapsable>
            // </React.Fragment>
          }
          {/* <Line className="UxProfile_PlayerProfile_Line" />
          <Collapsable title="Others Viewed" className="UxProfile_Collapsable">
            <ButtonLink
              to={this.getRoute(Routes.othersViewed)}
              style={{ display: 'none' }}
            >
              View All
            </ButtonLink>
            <div style={{ marginBottom: '40px' }}>
              <div>{`"Others Viewed" coming soon!${''}`}</div>
            </div>
          </Collapsable>
          <Line className="UxProfile_PlayerProfile_Line" /> */}
        </div>
      </div>
    );
  }
}

export default PlayerProfile;
