import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { YesNoModal, LoadingSpinner } from '../../../UxCommon';
import StatsTable from '../../../../containers/UxProfile/PlayerData/StatsTable';
import ZeroStats from '../../../../containers/UxProfile/PlayerData/ZeroStats';

class Stats extends PureComponent {
  static propTypes = {
    userStats: PropTypes.object,
    onCancelDeleteSeasonClick: PropTypes.func.isRequired,
    isDeleteSeasonDialogShown: PropTypes.bool.isRequired,
    deleteSeason: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    selectedUserSport: PropTypes.object,
    stdPositions: PropTypes.array.isRequired,
    newSeasons: PropTypes.array.isRequired,
    teams: PropTypes.array,
    addSeasonFormIsShown: PropTypes.bool.isRequired,
    sportInfo: PropTypes.object,
  }

  componentDidMount() {
  }

  render() {
    const {
      userStats,
      onCancelDeleteSeasonClick,
      isDeleteSeasonDialogShown,
      deleteSeason,
      loading,
      loadingMsg,
      match,
      selectedUserSport,
      newSeasons,
      stdPositions,
      teams,
      addSeasonFormIsShown,
      sportInfo,
    } = this.props;

    // Handle the case when there is a new stats row for a new team/position table
    let tables = [];
    if (userStats) {
      newSeasons.forEach((ns) => {
        const teamTable = userStats.tables.find(t => (t.team.id === ns.teamId));
        let positionTable = null;
        if (teamTable) {
          positionTable = teamTable.positions.find(p => (p.position.id === ns.positionId));
        }
        if (!teamTable || !positionTable) {
          tables.push({
            team: teams.find(t => t.id === ns.teamId),
            positions: [{
              position: stdPositions.find(p => p.id === ns.positionId),
              seasons: [],
            }],
          });
        }
      });
      if (tables.length) {
        tables = [...userStats.tables, ...tables];
      } else {
        ({ tables } = userStats);
      }
    }
    return (
      <div className="PlayerData_Stats" style={{ marginBottom: addSeasonFormIsShown ? '500px' : 0 }}>
        <div style={{ marginBottom: '20px' }} />
        <div className="PlayerData_Stats_Container">
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
          {
            userStats && tables.map(({ team, positions }) => (
              positions
              .map(({ position, seasons }) => (
                <StatsTable
                  key={`${team.id}_${position.id}`}
                  team={team}
                  position={position}
                  seasons={seasons}
                  sportId={selectedUserSport.sportId}
                  match={match}
                />
              ))
            ))
          }
          {
            userStats &&
              <ZeroStats match={match} veryFirst={tables.length === 0} sport={sportInfo} />
          }
        </div>
        <YesNoModal
          show={isDeleteSeasonDialogShown}
          onClose={onCancelDeleteSeasonClick}
          onNo={onCancelDeleteSeasonClick}
          onYes={deleteSeason}
          title="Delete Season"
          question="Are you sure you want to delete this entire season of stats?"
          noTxt="No, go back"
          yesTxt="Yes, delete this season"
        />
      </div>
    );
  }
}

export default Stats;
