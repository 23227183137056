import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {Table, TableHeader, TableRow, TableCell, TableBody} from '../Table';
import {LeaderboardViewOptions, SubbankCodes, SubbankTooltip} from '../../+store';
import Icon from '../../common/Icon';
import LeaderboardRow from '../LeaderboardRow';
import { Sports } from "../../../../store/actions/ui/routes";
import {calcDistancePartsFromInches} from "../../../../containers/UxCommon/Utils";

class LeaderboardTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    testAverage:PropTypes.array.isRequired,
    onHeaderClick: PropTypes.func.isRequired,
    sortByTestId: PropTypes.number,
    viewSelection: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onShowMoreClick: PropTypes.func.isRequired,
    displayShowMore: PropTypes.bool.isRequired,
    selectedScoreType: PropTypes.string.isRequired,
    measureableColumns: PropTypes.array.isRequired,
    schoolStyle: PropTypes.object,
    showFilterRank: PropTypes.bool,
    enableAthleteLink: PropTypes.bool.isRequired,
    isAllTimeLeaderboard: PropTypes.bool,
    rankHeading: PropTypes.string,
    eventAverage: PropTypes.any,
  }

  state = {
    screenWidth: null,
    tableColumnsWidth: [],
    tableColumnsWidthUnit: 'px',
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    if (this.props.columns && this.props.columns.length) {
      this.calculateWidth();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.columns && this.props.columns && this.props.columns.length) ||
      (prevState.screenWidth !== this.state.screenWidth) ||
      (
        prevProps.columns && this.props.columns &&
        prevProps.columns.length !== this.props.columns.length
      )
    ) {
      this.calculateWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getColumnWidth = (i) => {
    const { tableColumnsWidth, tableColumnsWidthUnit } = this.state;
    let width  = tableColumnsWidth.length ? `${tableColumnsWidth[i]}${tableColumnsWidthUnit}` : 'auto';
    // console.log(width, 'width....', tableColumnsWidth, 'tableColumnsWidth',i);
    return width;
  }

  updateDimensions = () => {
    if (this.state.screenWidth !== window.innerWidth) {
      this.setState({
        screenWidth: window.innerWidth,
        tableColumnsWidth: [],
        tableColumnsWidthUnit: 'px',
      });
    }
  }

  isSortByColumn = (column) => {
    const { sortByTestId } = this.props;
    return column.standardTestObjectId === sortByTestId;
  }

  isCustomWidth = () => {
    const { columns } = this.props;
    const { screenWidth } = this.state;
    // console.log(screenWidth, 'screenWidth screenWidth');
    return (375 + (columns.length * 140)) > screenWidth;
  }

  isViewExpanded = () => {
    const { viewSelection } = this.props;
    return viewSelection === LeaderboardViewOptions.detailed;
  }

  calculateWidth = () => {
    const { columns } = this.props;
    const { screenWidth } = this.state;
    if (screenWidth) {
      let unit = 'px';
      const widths = columns.map((header) => {
        if (screenWidth <= 576) {
          unit = 'rem';
          const headerValue = header.abbreviation || header.testCategoryName;
          console.log(headerValue.length, 'headerValue.length', headerValue,'headerValue');
          return Math.max(15 * 0.85, 6.2);
        }
        if (this.isCustomWidth()) {
          return 140;
        }
        unit = '%';
        return (100 - 30) / columns.length;
      });
      this.setState({
        tableColumnsWidth: widths,
        tableColumnsWidthUnit: unit,
      });
    }
  }

  render() {
    const {
      columns,
      data,
      onHeaderClick,
      loading,
      onShowMoreClick,
      displayShowMore,
      sortByTestId,
      selectedScoreType,
      measureableColumns,
      schoolStyle,
      showFilterRank,
      enableAthleteLink,
      isAllTimeLeaderboard,
      rankHeading,
      sport,
      setVideoDialog,
      location,
      match,
      isLoggedIn,
      isCoach,
      testAverage,
      eventAverage
    } = this.props;
    const { screenWidth, tableColumnsWidth, tableColumnsWidthUnit } = this.state;
    const isMobile = screenWidth < 768;
    const isTablet = screenWidth < 992;
    const getWidth = (customWidth, defaultWidth, left = null) => {
      if (screenWidth < 768) {
        return {};
      }
      if (left && left.width) {
        return { width: this.isCustomWidth() ? customWidth : defaultWidth, left: left.width };
      }
      return { width: this.isCustomWidth() ? customWidth : defaultWidth };
    };

    let styling = { display: loading ? 'none' : 'block' };
    const tableWidth = tableColumnsWidth.reduce((a, b) => a + b, 0);
    if (screenWidth <= 576) {
      styling = { ...styling, width: `${20 + tableWidth}${tableColumnsWidthUnit}` };
    } else if (screenWidth < 768) {
      // debugger
      styling = { ...styling, width: `${260 + tableWidth}${tableColumnsWidthUnit}` };
    } else {
      styling = { ...styling, minWidth: this.isCustomWidth() ? `${375 + tableWidth}${tableColumnsWidthUnit}` : '100%' };
    }
    if (!tableWidth) {
      return null;
    }

    return (
      <Table key={`LeaderboardTable-${columns.length}${tableWidth || ''}`} style={styling}>
        <TableHeader fixedHeaders>
          <TableRow>
            {
              showFilterRank && (
                <TableCell
                  className="LeaderboardTable_NumberCell"
                  style={getWidth(isMobile || isTablet ? 50 : 65, '5%')}
                >
                  Rank
                </TableCell>
              )}
            <TableCell
              className="LeaderboardTable_NumberCell"
              style={getWidth(isMobile || isTablet ? 50 : 65, '5%')}
            >
              { rankHeading ? rankHeading : 'Rank' }
            </TableCell>
            <TableCell
              className="LeaderboardTable_PlayerInfoCell"
              style={getWidth(isMobile || isTablet ? 210 : 310, '25%', getWidth(isMobile || isTablet ? 50 : 65, '5%'))}
            >
              Athlete
            </TableCell>
            {
              columns.map((header, index) =>{
                let eventAvg = null;
                if (testAverage) {
                  const getTest = testAverage.find(item => item.column === header.testCategoryCode);
                  if (getTest) {
                    eventAvg = getTest.avg !== 0 && parseFloat(getTest.avg).toFixed(2);
                    if(header.standardUnitOfMeasureCode === 'FEET-INCHES' && getTest.avg){
                      const {feetInt, inchesInt, decimalString} = calcDistancePartsFromInches(Number(getTest.avg));
                      eventAvg = `${feetInt}' ${inchesInt}.${decimalString}"`;
                    }
                  }
                }

                let eventAverageNLATL = eventAverage;
                // convert avg from inches to feet & inches for ATL and NL
                if(header.standardUnitOfMeasureCode === 'FEET-INCHES' && eventAverage) {
                  const {feetInt, inchesInt, decimalString} = calcDistancePartsFromInches(Number(eventAverage));
                  eventAverageNLATL = `${feetInt}' ${inchesInt}.${decimalString}"`;
                }

                return <TableCell
                  className={this.isSortByColumn(header) ? 'sortBy' : ''}
                  key={header.standardTestObjectId}
                  id={header.standardTestObjectId}
                  onClick={onHeaderClick}
                  style={{ width: this.getColumnWidth(index) }}
                >
                  {
                    this.isSortByColumn(header)
                      ? (
                        <div className="LeaderboardTable_ColumnHeader">
                          {/* {this.renderCategoryLogo(header.subbankCode)} */}
                          <div className="LeaderboardTable_ColumnHeaderText">
                            <div className="ShowTextOnDesktop"> {header.testCategoryName} </div>
                            <div className="ShowTextOnMobile">  {header.abbreviation}  </div>
                            <div className="ShowEventAverage">
                              {
                                !!eventAverage ? `(AVG: ${eventAverageNLATL})`
                                  : !!eventAvg ? `(AVG: ${eventAvg})` : ''
                              }
                            </div>
                          </div>
                          <i className="icon-right-arrow-icon LeaderboardTable_ColumnHeaderIcon"/>
                        </div>
                      ) : (
                        <div className="">
                          <div className="ShowTextOnDesktop"> {header.testCategoryName} </div>
                          <div className="ShowTextOnMobile">  {header.abbreviation}  </div>
                        </div>
                      )
                  }
                </TableCell>
              })
            }
          </TableRow>
        </TableHeader>
        <TableBody fixedHeaders>
          {
            data.map((athlete, index) => (
              <LeaderboardRow
                key={`${athlete.userId}:${athlete.schoolTeamId}`}
                isViewExpanded={this.isViewExpanded()}
                isSortByColumn={this.isSortByColumn}
                getColumnWidth={this.getColumnWidth}
                index={index}
                athlete={athlete}
                location={location}
                match={match}
                schoolStyle={schoolStyle}
                columns={columns}
                showFilterRank={showFilterRank}
                sortByTestId={sortByTestId}
                selectedScoreType={selectedScoreType}
                measureableColumns={measureableColumns}
                isCustomWidth={this.isCustomWidth()}
                enableAthleteLink={enableAthleteLink}
                isAllTimeLeaderboard={isAllTimeLeaderboard}
                sport={sport ? sport : Sports[athlete.sportId]}
                isLoggedIn = {isLoggedIn}
                isCoach = {isCoach}
              />
            ))
          }
          {
            displayShowMore && (
              <TableRow>
                <TableCell colSpan={columns.length + 2} className="Leaderboard_ShowMoreTableCell">
                  <button className="Leaderboard_ShowMore" onClick={onShowMoreClick}>
                    <Icon iconName="right-arrow" className="Leaderboard_ShowMoreIcon"/>
                    <div className="Leaderboard_ShowMoreText">Show More</div>
                    <Icon iconName="right-arrow" className="Leaderboard_ShowMoreIcon"/>
                  </button>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    );
  }
}

export default LeaderboardTable;
