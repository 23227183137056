import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import {
  albumDeleteAlbumOnlySuccess,
  albumDeleteEverythingSuccess,
  deletePhoto,
  deleteTag,
  deleteTagFromPhoto,
  deleteTagFromVideo,
  deleteVideo,
  photoMultiDeleteSuccess,
  photoMultiDeleteTagPhotoSuccess,
  photoPhotoDeleteSuccess,
  photoTagDeleteSuccess,
  photoVideoDeleteSuccess,
} from '../../../../data/photo';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import {
  albumFilterUnselected,
  DELETE_ALBUM_EVERYTHING,
  DELETE_ALBUM_ONLY,
  DELETE_PHOTO,
  DELETE_TAG,
  DELETE_VIDEO,
  MULTI_DELETE_EXECUTE,
  tagFilterUnselected,
} from '../actions';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { deleteAlbum } from '../../../../data/photo/apiCalls';

export const deletePhotoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const currSportId = () => state$.value.ui.app.routes.currentSportId;
  const currSchoolId =() => state$.value.ui.app.routes.currentSchoolId;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(DELETE_PHOTO),
    getTokenFragment(),
    switchMap(({ action, token }) => deletePhoto(action.photoId, token, uuid()).pipe(
      map(() => ({ action, success: true })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(DELETE_PHOTO, 'deletePhoto', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          photoPhotoDeleteSuccess(result.action.photoId, uuid()),
          asyncFinishAction(DELETE_PHOTO, 'deletePhoto',
           { 
            id: result.action.photoId, 
            uuid: uuid(),
            sportId: currSportId(),
            schoolId: currSchoolId()
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

const multiDeleteTagFromPhotoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(DELETE_TAG),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const tag = action.selectedTag;
      if (action.media) {
        return (action.media.isAPhoto ? deleteTagFromPhoto(token, tag.id, action.media.id, uuid()) :
          deleteTagFromVideo(token, tag.id, action.media.id, uuid())).pipe(
          map(() => ({
            success: true,
            tag,
            media: action.media,
          })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(MULTI_DELETE_EXECUTE, 'deleteTag', error),
          })),
        );
      }
      return deleteTag(tag.id, token, uuid()).pipe(
        map(() => ({
          success: true,
          tag,
        })),
        takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(MULTI_DELETE_EXECUTE, 'deleteTag', error),
        })),
      );
    }),
    switchMap((result) => {
      if (result.success) {
        if (result.media) {
          return of(
            photoMultiDeleteTagPhotoSuccess(result.tag, uuid(), result.media),
            asyncFinishAction(
              MULTI_DELETE_EXECUTE,
              'multiDelete',
            ),
          );
        }
        return of(
          photoTagDeleteSuccess(result.tag.id, uuid()),
          tagFilterUnselected(result.tag.id),
          asyncFinishAction(MULTI_DELETE_EXECUTE, 'deleteTag', { id: result.tag.id }),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};

export const deleteVideoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const currSportId = () => state$.value.ui.app.routes.currentSportId;
  const currSchoolId =() => state$.value.ui.app.routes.currentSchoolId;
  return action$.pipe(
    ofType(DELETE_VIDEO),
    getTokenFragment(),
    switchMap(({ action, token }) => deleteVideo(action.videoId, token, uuid()).pipe(
      map(() => ({ action, success: true })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(DELETE_VIDEO, 'deleteVideo', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          photoVideoDeleteSuccess(result.action.videoId, uuid()),
          asyncFinishAction(DELETE_VIDEO, 'deleteVideo', 
          { 
            id: result.action.videoId, 
            uuid: uuid(),
            sportId: currSportId(),
            schoolId: currSchoolId()
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

const multiDeleteEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(MULTI_DELETE_EXECUTE),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCalls = [];
      action.selectedMedia.forEach((media) => {
        if (media.isAPhoto) {
          apiCalls.push(deletePhoto(media.id, token, uuid()).pipe(
            map(() => ({ success: true, media })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(MULTI_DELETE_EXECUTE, 'deletePhoto', error),
            })),
          ));
        } else {
          apiCalls.push(deleteVideo(media.id, token, uuid()).pipe(
            map(() => ({ success: true, media })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(MULTI_DELETE_EXECUTE, 'deleteVideo', error),
            })),
          ));
        }
      });
      return forkJoin(...apiCalls).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              action,
              success: true,
              mediaResults: forkResults.results,
            };
          }
          return forkResults;
        }),
      );
    }),
    switchMap((result) => {
      if (result.success) {
        const ids = result.mediaResults.map(r => r.media.id);
        return of(
          photoMultiDeleteSuccess(ids, uuid()),
          asyncFinishAction(
            MULTI_DELETE_EXECUTE,
            'multiDelete',
            { ids },
          ),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};

const deleteAlbumEverythingEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(DELETE_ALBUM_EVERYTHING),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCalls = [];
      action.albumRelMedia.forEach((media) => {
        if (media.isAPhoto) {
          apiCalls.push(deletePhoto(media.mediaId, token, uuid()).pipe(
            map(() => ({ success: true, media })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(DELETE_ALBUM_EVERYTHING, 'deletePhoto', error),
            })),
          ));
        } else {
          apiCalls.push(deleteVideo(media.mediaId, token, uuid()).pipe(
            map(() => ({ success: true, media })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(DELETE_ALBUM_EVERYTHING, 'deleteVideo', error),
            })),
          ));
        }
      });
      if (apiCalls.length) {
        return forkJoin(...apiCalls).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                action,
                token,
                success: true,
                mediaResults: forkResults.results,
              };
            }
            return forkResults;
          }),
        );
      }
      return of({
        action,
        token,
        success: true,
        mediaResults: [],
      });
    }),
    switchMap((results) => {
      if (results.success) {
        return deleteAlbum(results.action.album.id, results.token, uuid()).pipe(
          map(() => ({ ...results, album: results.action.album })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(DELETE_ALBUM_EVERYTHING, 'deleteVideo', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((result) => {
      if (result.success) {
        const albumId = result.album.id;
        const photoIds = result.mediaResults
          .filter(r => r.media.isAPhoto).map(r => r.media.mediaId);
        const videoIds = result.mediaResults.filter(r => !r.media.isAPhoto)
          .map(r => r.media.mediaId);
        return of(
          albumDeleteEverythingSuccess(uuid(), albumId, photoIds, videoIds),
          albumFilterUnselected(albumId),
          asyncFinishAction(
            DELETE_ALBUM_EVERYTHING,
            'Delete everthing',
            { id: albumId },
          ),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};

const deleteAlbumOnlyEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(DELETE_ALBUM_ONLY),
    getTokenFragment(),
    switchMap(({ action, token }) => deleteAlbum(action.album.id, token, uuid()).pipe(
      map(() => ({ success: true, album: action.album })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(DELETE_ALBUM_ONLY, 'deleteVideo', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          albumDeleteAlbumOnlySuccess(uuid(), result.album.id),
          asyncFinishAction(
            DELETE_ALBUM_ONLY,
            'Delete album only',
            { id: result.albumId },
          ),
        );
      }
      if (result.actions) {
        return of(...result.actions);
      }
      return of(result.action);
    }),
  );
};

export const deleteEpics = combineEpics(
  deletePhotoEpic,
  deleteVideoEpic,
  multiDeleteEpic,
  multiDeleteTagFromPhotoEpic,
  deleteAlbumEverythingEpic,
  deleteAlbumOnlyEpic,
);
