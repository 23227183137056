import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonLink, Line } from '../../../UxCommon';
import { Routes } from '../../../../store/actions/ui/routes';

const TeamsRow = ({
  athleteTeamRosters,
}) =>
  (
    <div className="UxProfile_Team_Wrapper">
      {athleteTeamRosters && athleteTeamRosters.map((team, i) => (
        <React.Fragment key={i}>
          <div className="UxProfile_Team">
            <div className="UxProfile_Team_Header">
              {/* <div className="UxProfile_Team_Title_Btn">
                {`${team.schoolName} ${team.sportId.charAt(0).toUpperCase() + team.sportId.slice(1)}`}
              </div> */}
              <div className="UxProfile_Team_Title_Btn">
                <ButtonLink
                  to={`/${Routes.school}/${team.schoolId}/${Routes.roster}/${team.sportId}`}
                >
                 {`${team.schoolName} ${team.sportId.charAt(0).toUpperCase() + team.sportId.slice(1)}`}
                </ButtonLink>
              </div>
            </div>
            {/*  
            ******
              Hide as per client  request of Leaderboards and Reports
            ******
            {
              team.combines &&
              <div className="UxProfile_Team_Reports_Title">
                <div className="UxProfile_Team_Reports_TitleText">
                  Leaderboards
                </div>
                <div className="UxProfile_Team_Reports_Content_Wrapper">
                  {
                    team.combines.map((combine, j) => (
                      <div key={j} className="UxProfile_Team_Reports_Content">
                        <Link
                          className="UxProfile_Team_Reports_Content_Link"
                          to={combine.leaderboard}
                        >
                          {`${combine.name} (${combine.date.format('MM/DD/YYYY')})`}
                        </Link>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
            {
              team.combines &&
              <div className="UxProfile_Team_Reports_Title">
                <div className="UxProfile_Team_Reports_TitleText">
                  Reports
                </div>
                <div className="UxProfile_Team_Reports_Content_Wrapper">
                  {
                    team.combines.map((combine, j) => (
                      <div key={j} className="UxProfile_Team_Reports_Content">
                        <Link
                          className="UxProfile_Team_Reports_Content_Link"
                          to={combine.report}
                        >
                          {`${combine.name} (${combine.date.format('MM/DD/YYYY')})`}
                        </Link>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
             */}
          </div>
          {(i !== athleteTeamRosters.length - 1) &&
            <React.Fragment>
              <Line className="UxProfile_PlayerProfile_Line" />
              <div style={{ marginBottom: 25 }} />
            </React.Fragment>
          }
        </React.Fragment>
      ))}
    </div>
  );
TeamsRow.propTypes = {
  athleteTeamRosters: PropTypes.array.isRequired,
};

export default TeamsRow;
