import { combineReducers } from 'redux';
import signIn from './signIn';
import mergeSignIn from './mergeSignIn';
import joinNow from './joinNow';
import resetPass from './resetPass';
import confirmAccount from './confirmAccount';
import forgotPass from './forgotPass';
import secondSignIn from './secondSignIn';
import welcome from './welcome';
import invitedSignUp from './invitedSignUp';
import checkInvitation from './checkInvitation';
import mergeAnotherAccount from './mergeAnotherAccount';


const reducer = combineReducers({
  welcome,
  signIn,
  joinNow,
  resetPass,
  confirmAccount,
  forgotPass,
  secondSignIn,
  invitedSignUp,
  mergeSignIn,
  checkInvitation,
  mergeAnotherAccount,
});

export default reducer;
