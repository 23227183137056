import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../UxCommon/Icon/Icon';
import {matchPath} from "react-router-dom";
import {Routes} from "../../../store/actions/ui/routes";
import {ButtonRect, CloseButton} from "../index";
import {Tooltip} from "../../../store/actions/ui/tooltip";

class MainMenuButton extends PureComponent {
  static propTypes = {
    colorBoundary: PropTypes.number,
    onMenuClick: PropTypes.func.isRequired,
    isMenuShown: PropTypes.bool.isRequired,
  }

  state = {
    scroll: 0,
    calendlyModal:false,
  }

  componentDidMount() {
    document.getElementById('uxProfileRight').addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.getElementById('uxProfileRight').removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scroll: document.getElementById('uxProfileRight').scrollTop,
    });
  }

  render() {
    const { colorBoundary, isCoach,isMyProfile, onMenuClick, isMenuShown,updateCalendlyModal } = this.props;
    const { scroll,calendlyModal } = this.state;
    const boundary = colorBoundary || -100;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const isProfileLandingPage = !!matchPath(location.pathname, {
      path:`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`,
      exact: true,
    });

    return (
      <div>
        <button
          className={[
            'UxProfile_MenuButton',
            'hidden-md-up',
            isMenuShown ? 'hidden' : '',
            scroll > boundary ? 'black' : '',
          ].join(' ')}
          onClick={onMenuClick}
        >
          <Icon iconName="hambuger" style={{ fontSize: '18px' }} />
        </button>
        {isProfileLandingPage && !isMenuShown && !isCoach && isMyProfile && (
          <span className="badge badge-danger" onClick={onMenuClick}>1</span>
        )
        }
      </div>
    );
  }
}

export default MainMenuButton;
