import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { capitalizeLetterAfterSpaceHyphen } from '../../../../store/actions/ui/formUtils/validators';

import {
  validateRequired,
  validateEmail,
  validateLetters,
  validateInteger,
  validateLettersWithRMSpecialCharacter,
  validateGradYear,
  validatePhone,
} from '../../../../store/actions/ui/formUtils/validators';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import ErrorToolTipRenderer from '../../common/ErrorToolTipRenderer/ErrorToolTipRenderer.component';
import { AllModules, RowNode } from '@ag-grid-enterprise/all-modules';
import { formatPhone } from '../../../../utils';
const isAnyOtherCellPopulated = (data, field) =>
  Object.keys(data)
    .filter((k) => k !== field)
    .some((k) => !!data[k]);

const isAnyPopulatedOtherThan = (data, fields) =>
  Object.keys(data).some((k) => !!data[k] && !fields.includes(k));

const cellStyle = {
  // borderWidth: 1,
  // borderStyle: 'solid',
  // borderColor: 'lightgray',
  // borderTopWidth: 0,
  // borderBottomWidth: 0,
};

const genders = ['Male', 'Female'];

const grades = [
  '12th',
  '11th',
  '10th',
  '9th',
  '8th',
  '7th',
  '6th',
  '5th',
  '4th',
  '3rd',
  '2nd',
  '1st',
  'K',
  '13th',
  'Redshirt Freshman',
  'College Freshman',
  'College Sophomore',
  'College Junior',
  'College Senior',
];

const gradYear = [
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
  '2031',
  '2032',
  '2033',
  '2034',
  '2035',
  '2036',
  '2037',
  '2038',
  '2039',
  '2040'
]

const gradesMap = {
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  '13th': 13,
  '12th': 12,
  '11th': 11,
  '10th': 10,
  '9th': 9,
  '8th': 8,
  '7th': 7,
  '6th': 6,
  '5th': 5,
  '4th': 4,
  '3rd': 3,
  '2nd': 2,
  '1st': 1,
  K: 'K',
};

class NewGridView extends PureComponent {
  static propTypes = {
    profiles: PropTypes.object.isRequired,
    school: PropTypes.object.isRequired,
    match: PropTypes.object,
    createProfiles: PropTypes.func.isRequired,
    createProfilesAndInvite: PropTypes.func.isRequired,
    team: PropTypes.object.isRequired,
    canEdit: PropTypes.bool.isRequired,
    userProfileId: PropTypes.string,
    sportId: PropTypes.number.isRequired,
    changeView: PropTypes.func.isRequired,
    newTeam: PropTypes.bool.isRequired,
    athletes: PropTypes.array,
  };

  state = {
    athletes: [],
    errors: {},
    emailExistsErrors: {},
    enableRemove: false,
    focusedRow: -1,
    populatedRows: [],
  };

  componentDidMount() {
    this.init();
  }

  onCellFocused = (params) => {
    if (this.state.focusedRow !== params.rowIndex) {
      // Changed rows
      this.setState(() => ({ focusedRow: params.rowIndex }));
      // When rows are changed, refresh all cells
      params.api.refreshCells({ force: true });
    }
  };

  onCellEditingStopped = (params) => {
    this.getErrors(params.data, params.rowIndex);
    params.api.refreshCells({
      force: true,
      rowNodes: [params.api.getRowNode(params.rowIndex)],
    });
  };

  onPasteEnd = (params) => {
    this.setState({ focusedRow: -1 });
    this.gridApi.forEachNode((rowNode, index) => {
      if (isAnyOtherCellPopulated(rowNode.data, '')) {
        this.getErrors(rowNode.data, index);
      }
    });
    // When pasting is done, refresh all cells
    params.api.refreshCells({ force: true });
  };

  getColumnDefs = () => {
    const { school } = this.props;
    const parentsEmailField = {
      headerName: 'Parent Email*',
      field: 'parentEmail',
      cellStyle: (params) => {
        const isError =
          params.value && validateEmail()(params.value).parentEmail;
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error:
          this.state.emailExistsErrors[params.rowIndex] ||
          (this.state.errors[params.rowIndex]
            ? this.state.errors[params.rowIndex].parentEmail
            : ''),
      }),
    };
    const athletesEmailField = {
      headerName: 'Athlete Email',
      field: 'email',
      cellStyle: (params) => {
        const isError = params.value && validateEmail()(params.value).email;
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error:
          this.state.emailExistsErrors[params.rowIndex] ||
          (this.state.errors[params.rowIndex]
            ? this.state.errors[params.rowIndex].email
            : ''),
      }),
    };
    const parentsPhoneField = {
      headerName: "Parent's Mobile",
      field: 'parentsPhone',
      valueFormatter: (params) => formatPhone(params.value),
      cellStyle: (params) =>
        !params.value || !validatePhone()(params.value).phone
          ? { backgroundColor: 'transparent', fontWeight: 'normal' }
          : { backgroundColor: '#ff9999', fontWeight: 'bold' },
      minWidth: 130,
      maxWidth: 140,
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].parentsPhone
          : '',
      }),
    };
    const athletePhoneField = {
      headerName: "Athlete's Mobile",
      field: 'athletePhone',
      valueFormatter: (params) => formatPhone(params.value),
      cellStyle: (params) =>
        !params.value || !validatePhone()(params.value).phone
          ? { backgroundColor: 'transparent', fontWeight: 'normal' }
          : { backgroundColor: '#ff9999', fontWeight: 'bold' },
      minWidth: 130,
      maxWidth: 140,
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].athletePhone
          : '',
      }),
    };

    return school && school.isPaid
      ? [
          ...this.defaultColumnDef,
          parentsEmailField,
          parentsPhoneField,
          athletesEmailField,
          athletePhoneField,
        ]
      : [...this.defaultColumnDef, athletesEmailField];
  };

  defaultColumnDef = [
    {
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      maxWidth: 50,
      cellStyle: {
        ...cellStyle,
        backgroundColor: '#F4F4F4',
      },
    },
    {
      width: 75,
      maxWidth: 80,
      headerName: 'Row',
      valueGetter: (params) => params.node.rowIndex + 1,
      cellStyle: {
        ...cellStyle,
        textAlign: 'center',
        backgroundColor: '#F4F4F4',
      },
    },
    {
      headerName: 'First Name*',
      field: 'first',
      sortable: true,
      valueSetter: (params) => {
        params.data.first =
          params.newValue && params.newValue.replace(/[^A-Za-z0-9.\-_'"]/g, '');
        return true;
      },
      cellStyle: (params) => {
        const areFieldsPopulated =
          this.state.focusedRow === params.node.childIndex
            ? // This checks the cells after the current cell if populated.
              isAnyPopulatedOtherThan(params.node.data, ['first'])
            : // This checks all the cells if populated, regardless of the order to current cell
              isAnyOtherCellPopulated(params.node.data, 'first');
        const isError =
          (!params.value && areFieldsPopulated) ||
          validateLettersWithRMSpecialCharacter()(params.value).letters;
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].first
          : '',
      }),
    },
    {
      headerName: 'Last Name*',
      field: 'last',
      sortable: true,
      valueSetter: (params) => {
        params.data.last =
          params.newValue &&
          capitalizeLetterAfterSpaceHyphen(params.newValue);
        return true;
      },
      cellStyle: (params) => {
        const areFieldsPopulated =
          this.state.focusedRow === params.node.childIndex
            ? isAnyPopulatedOtherThan(params.node.data, ['first', 'last'])
            : isAnyOtherCellPopulated(params.node.data, 'last');
        const isError =
          (!params.value && areFieldsPopulated) ||
          validateLettersWithRMSpecialCharacter()(params.value).letters;
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].last
          : '',
      }),
    },
    {
      headerName: 'Gender*',
      field: 'gender',
      sortable: true,
      cellStyle: (params) => {
        const areFieldsPopulated =
          this.state.focusedRow === params.node.childIndex
            ? isAnyPopulatedOtherThan(params.node.data, [
                'first',
                'last',
                'gender',
              ])
            : isAnyOtherCellPopulated(params.node.data, 'gender');
        const isError =
          (!params.value && areFieldsPopulated) ||
          (params.value && !genders.includes(params.value));
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: genders,
      },
      valueSetter: (params) => {
        const newValue = params.newValue
          ? params.newValue.replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '')
          : '';
        let mappedValue = null;
        if (newValue) {
          if (newValue.toLowerCase() === 'female') {
            mappedValue = 'Female';
          } else if (newValue.toLowerCase() === 'male') {
            mappedValue = 'Male';
          }
        }
        params.data.gender = mappedValue || newValue;
        return true;
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].gender
          : '',
      }),
    },
    {
      headerName: 'Grade*',
      field: 'grade',
      sortable: true,
      cellStyle: (params) => {
        const areFieldsPopulated =
          this.state.focusedRow === params.node.childIndex
            ? isAnyPopulatedOtherThan(params.node.data, [
                'first',
                'last',
                'gender',
                'grade',
              ])
            : isAnyOtherCellPopulated(params.node.data, 'grade');
        const isError =
          (!params.value && areFieldsPopulated) ||
          (params.value && !grades.includes(params.value));
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: grades,
      },
      valueSetter: (params) => {
        const newValue = params.newValue
          ? params.newValue.replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/g, '')
          : '';
        let mappedValue = null;
        if (newValue) {
          mappedValue = Object.entries(gradesMap).find(
            ([key, value]) =>
              key.toLowerCase() === newValue.toLowerCase() ||
              value.toString().toLowerCase() ===
                newValue.toString().toLowerCase(),
          );
        }
        params.data.grade = mappedValue ? mappedValue[0] : newValue;
        return true;
      },
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].grade
          : '',
      }),
    },
    {
      headerName: 'HS Grad Year*',
      field: 'gradYear',
      sortable: true,
      cellEditorParams: {
        values: gradYear
      },
      cellEditor: 'agRichSelectCellEditor',
      valueSetter: (params) => {
        params.data.gradYear = params.newValue;
        return true;
      },
      cellStyle: (params) => {
        const areFieldsPopulated =
          this.state.focusedRow === params.node.childIndex
            ? isAnyPopulatedOtherThan(params.node.data, [
                'first',
                'last',
                'gender',
                'grade',
                'gradYear',
              ])
            : isAnyOtherCellPopulated(params.node.data, 'gradYear');
        const isError =
          (!params.value && areFieldsPopulated) ||
          (params.value && !gradYear.includes(params.value));
        return {
          ...cellStyle,
          backgroundColor: isError ? '#ff9999' : 'transparent',
          fontWeight: isError ? 'bold' : 'normal',
        };
      },
      minWidth: 130,
      maxWidth: 140,
      tooltipValueGetter: (params) => ({ value: params.value }),
      tooltipComponentParams: (params) => ({
        error: this.state.errors[params.rowIndex]
          ? this.state.errors[params.rowIndex].gradYear
          : '',
      }),
    },
  ];

  getErrors = (data, rowIndex = null) => {
    const { populatedRows } = this.state;
    const { school } = this.props;
    const errors = {};
    // Check if there is data before
    const isEmpty = !isAnyOtherCellPopulated(data, '');
    if (!isEmpty) {
      errors.first =
        validateRequired()(data.first).required ||
        validateLettersWithRMSpecialCharacter()(data.first).letters;
      errors.last =
        validateRequired()(data.last).required ||
        validateLettersWithRMSpecialCharacter()(data.last).letters;
      if (!data.gender) {
        errors.gender = 'Gender is required';
      } else if (!genders.includes(data.gender)) {
        errors.gender = 'Not a supported option.';
      }
      if (!data.grade) {
        errors.grade = 'Grade is required';
      } else if (!grades.includes(data.grade)) {
        errors.grade = 'Not a supported option.';
      }
      if (!data.gradYear) {
        errors.gradYear = 'Grad Year is required';
      } else if (!gradYear.includes(data.gradYear)) {
        errors.gradYear = 'Not a supported option.';
      }
      if (data.parentsPhone) {
        errors.parentsPhone = data.parentsPhone
          ? validatePhone()(data.parentsPhone).phone
          : '';
      }
      if (data.athletePhone) {
        errors.athletePhone = data.athletePhone
          ? validatePhone()(data.athletePhone).phone
          : '';
      }
      if (data.email) {
        if (validateEmail()(data.email).email) {
          errors.email = 'Email format is not correct';
        }
      } else {
        errors.email = '';
      }
      if (school.isPaid) {
        if (data.parentEmail) {
          if (validateEmail()(data.parentEmail).parentEmail) {
            errors.parentEmail = 'Email format is not correct';
          }
        } else {
          errors.parentEmail = validateRequired()(data.parentEmail).required;
        }
      }
    }
    const error =
      errors.first ||
      errors.last ||
      errors.gender ||
      errors.grade ||
      errors.gradYear ||
      errors.email ||
      errors.parentEmail;

    if (rowIndex !== null) {
      let newPopulatedRows = populatedRows;
      if (populatedRows.includes(rowIndex) && isEmpty) {
        newPopulatedRows = populatedRows.filter((x) => x !== rowIndex);
      } else if (!isEmpty) {
        newPopulatedRows = [...populatedRows, rowIndex];
      }

      this.setState({
        populatedRows: newPopulatedRows,
        errors: {
          ...this.state.errors,
          [rowIndex]: errors,
        },
      });
    }
    return error;
  };

  isPlayerOnTeam = (email) => {
    const { athletes } = this.props;
    if (!email) {
      return false;
    }
    return (
      athletes &&
      Array.isArray(athletes) &&
      athletes.find(
        (a) => a.email && a.email.toLowerCase() === email.toLowerCase(),
      )
    );
  };

  selectionChanged = () => {
    const athletes = this.gridApi.getSelectedRows();
    if (athletes.length > 0) {
      this.setState({ enableRemove: true });
    } else {
      this.setState({ enableRemove: false });
    }
  };

  init = () => {
    const rowDataItem = {
      first: '',
      last: '',
      gender: '',
      grade: '',
      gradYear: '',
      email: '',
      parentEmail: '',
      parentsPhone: '',
      athletePhone: '',
    };
    const rowData = Array.from({ length: 200 }, () =>
      Object.assign({}, rowDataItem),
    );
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ athletes: rowData });
  };

  removeSelected = () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedData });
  };

  addRows = () => {
    const rowDataItem = {
      first: '',
      last: '',
      gender: '',
      grade: '',
      gradYear: '',
      email: '',
      parentEmail: '',
      parentsPhone: '',
      athletePhone: '',
    };
    const rowData = Array.from({ length: 100 }, () =>
      Object.assign({}, rowDataItem),
    );
    this.gridApi.applyTransaction({ add: rowData });
  };

  addToTeam = () => {
    const { match, sportId, changeView, createProfiles } = this.props;
    const body = {
      school_team_id: match.params.teamId,
      users: [],
    };
    let hasError = false;
    this.gridApi.forEachNode((rowNode, index) => {
      const athleteInfo = Object.assign({}, rowNode.data);
      const isEmpty = Object.values(rowNode.data).every((x) => !x);
      if (this.getErrors(athleteInfo) && !isEmpty) {
        hasError = true;
      }
      if (!isEmpty && !this.getErrors(athleteInfo)) {
        body.users.push({
          given_name: athleteInfo.first,
          family_name: athleteInfo.last,
          gender: athleteInfo.gender.toLowerCase(),
          role_id: 2,
          email: athleteInfo.email ? athleteInfo.email.trim() : '',
          grade: gradesMap[athleteInfo.grade],
          grad_year: athleteInfo.gradYear,
          position: '',
          school_team_id: match.params.teamId,
          phone: athleteInfo.athletePhone
            ? formatPhone(athleteInfo.athletePhone)
            : null,
          parent_email: athleteInfo.parentEmail
            ? athleteInfo.parentEmail.trim()
            : '',
          parent_phone: athleteInfo.parentsPhone
            ? formatPhone(athleteInfo.parentsPhone)
            : null,
        });
      }
    });
    if (hasError) {
      return false;
    }
    createProfiles(body, match.params.schoolId, sportId);
    changeView();
  };

  addToTeamAndInvite = () => {
    const {
      createProfilesAndInvite,
      match,
      sportId,
      profiles,
      userProfileId,
      school,
      team,
      changeView,
    } = this.props;
    const body = {
      schoolTeamId: match.params.teamId,
      users: [],
    };
    let hasError = false;
    this.gridApi.forEachNode((rowNode, index) => {
      const athleteInfo = Object.assign({}, rowNode.data);
      const isEmpty = Object.values(rowNode.data).every((x) => !x);
      if (this.getErrors(athleteInfo) && !isEmpty) {
        hasError = true;
      }
      if (!isEmpty && !this.getErrors(athleteInfo)) {
        body.users.push({
          first: athleteInfo.first,
          last: athleteInfo.last,
          gender: athleteInfo.gender.toLowerCase(),
          roleId: 2,
          email: athleteInfo.email ? athleteInfo.email.trim() : '',
          grade: gradesMap[athleteInfo.grade],
          grad_year: athleteInfo.gradYear,
          position: '',
          schoolTeamId: match.params.teamId,
          phone: athleteInfo.athletePhone
            ? formatPhone(athleteInfo.athletePhone)
            : null,
          inviteType: 'invite',
          teamName: team.name,
          schoolName: school.name,
          coachFullName: `${profiles[userProfileId].first} ${profiles[userProfileId].last}`,
          sportId,
          parent_email: athleteInfo.parentEmail
            ? athleteInfo.parentEmail.trim()
            : '',
          parent_phone: athleteInfo.parentsPhone
            ? formatPhone(athleteInfo.parentsPhone)
            : null,
        });
      }
    });
    if (hasError) {
      return false;
    }
    createProfilesAndInvite(body, match.params.schoolId, sportId);
    changeView();
  };

  render() {
    const { canEdit, changeView, team, match, newTeam } = this.props;
    const { athletes, enableRemove, errors, populatedRows } = this.state;
    const isEmpty = populatedRows.length === 0;
    const doesErrorExist = Object.values(errors).some(
      (item) =>
        item.first ||
        item.last ||
        item.gender ||
        item.grade ||
        item.gradYear ||
        item.email ||
        item.parentEmail ||
        item.parentsPhone ||
        item.athletePhone,
    );
    const enableAddButtons = !isEmpty && !doesErrorExist;
    let enableAddInviteButton = false;

    // invites can only be sent if any athlete have an email or parent email
    this.gridApi &&
      this.gridApi.forEachNode((rowNode, index) => {
        const athleteInfo = Object.assign({}, rowNode.data);
        if (athleteInfo.email || athleteInfo.parentEmail) {
          enableAddInviteButton = true;
        }
      });

    // athlete should have email and grid doesnot have any errors
    enableAddInviteButton = enableAddButtons && enableAddInviteButton;

    return (
      <div
        style={{
          backgroundColor: '#F4F4F4',
          paddingTop: 10,
        }}>
        <div className="GridView_Outer_Top_Sticky_Div">
          <div
            style={{
              display: 'flex',
              margin: 10,
              marginTop: 20,
              flexDirection: 'row',
              width: '100%',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  marginRight: 5,
                }}>
                <button onClick={changeView} className="GridView_Back_Button">
                  <Icon iconName="chevron-left" />
                </button>
              </div>
              <div className="GridView_CardSchoolName">
                {!newTeam
                  ? `Add Athletes > ${team ? team.name : ''} ${
                      match.params.sport
                    }`
                  : `Get Started > ${team ? team.name : ''} ${
                      match.params.sport
                    }`}
              </div>
              <a
                target="_blank"
                href="https://youtu.be/fNeDMznPVlw"
                className="GridView_Blue_Secondary_IconButton"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '12px',
                }}>
                <div style={{ marginRight: 3 }}>
                  <Icon iconName="curved-play" />
                </div>
                <div>
                  <div>Watch Instructions</div>
                </div>
              </a>
            </div>
          </div>
          {!isEmpty && doesErrorExist && (
            <div className="GridView_Error_Msg">
              <Icon iconName="alert" />
              Hey Coach! Before you are able to upload your athlete information,
              make sure all required fields are populated and all errors must be
              addressed. Please review the table below, fix the errors and then
              upload again.
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
              marginBottom: 15,
            }}>
            <button
              disabled={!enableRemove}
              className="GridView_Remove_Button"
              onClick={this.removeSelected}>
              Remove
            </button>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{
            height: 1000,
          }}>
          <AgGridReact
            alwaysShowVerticalScroll
            stopEditingWhenGridLosesFocus
            onCellFocused={this.onCellFocused}
            onPasteEnd={this.onPasteEnd}
            rowSelection="multiple"
            enableRangeSelection
            onGridReady={(params) => {
              this.gridApi = params.api;
            }}
            columnDefs={this.getColumnDefs()}
            columnTypes={{
              editableEmailColumn: { editable: canEdit },
            }}
            modules={AllModules}
            headerHeight={35}
            groupHeaderHeight={35}
            rowHeight={35}
            tooltipShowDelay={0}
            tooltipMouseTrack
            onCellEditingStopped={this.onCellEditingStopped}
            defaultColDef={{
              editable: canEdit,
              flex: 1,
              resizable: true,
              tooltipComponent: 'errorTooltip',
              cellClass: 'GridView_Grid_Cell',
            }}
            context={{ componentParent: this }}
            rowData={athletes}
            suppressRowClickSelection
            onRowSelected={this.selectionChanged}
            animateRows
            suppressScrollOnNewData
            suppressAggFuncInHeader
            frameworkComponents={{
              errorTooltip: ErrorToolTipRenderer,
            }}
          />
        </div>
        <div className="GridView_Bottom_Sticky_Div">
          <div
            style={{
              display: 'flex',
              margin: 0,
              marginLeft: 2,
              justifyContent: 'flex-start',
              width: '100%',
            }}>
            <button
              onClick={this.addRows}
              className="GridView_Secondary_IconButton">
              <div
                style={{
                  marginRight: 3,
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <Icon iconName="plus" />
                <div style={{ marginLeft: 2 }}>Add 100 Rows</div>
              </div>
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              margin: 0,
              justifyContent: 'flex-end',
              marginRight: 10,
            }}>
            <button
              disabled={!enableAddButtons}
              className="GridView_Blue_Secondary_Button"
              onClick={this.addToTeam}>
              Add to Roster
            </button>
            <button
              disabled={!enableAddInviteButton}
              className="GridView_Blue_Secondary_LongButton"
              onClick={this.addToTeamAndInvite}>
              Add to Roster & Send Invites
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewGridView;
