import { combineReducers } from 'redux';
import photos from './media/photo';
import videos from './media/video';
import albums from './media/album';
import profilePhotos from './media/profilePhoto';
import tags from './media/tag';
import favorites from './media/favorite';
import tagRelMediaCollection from './media/tagRelMedia';
import albumRelMediaCollection from './media/albumRelMedia';
import mediaUpdatedFlag from './media/mediaUpdatedFlag';

const reducer = combineReducers({
  photos,
  videos,
  albums,
  profilePhotos,
  tags,
  favorites,
  tagRelMediaCollection,
  albumRelMediaCollection,
  mediaUpdatedFlag,
});

export default reducer;
