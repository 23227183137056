import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import {
  Container,
  Row,
  Col,
  LoadingSpinner,
  CloseLink,
  Line,
} from '../../UxCommon';
import {
  TextControl,
  Button,
  Headline,
  GeneralErrors,
} from '../../UxCommon/Form';
import { Sports, Routes } from '../../../store/actions/ui/routes';
import { parserOnlyNumbers } from '../../../utils/parsers';

class ConfirmEmailPhone extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    formInit: PropTypes.func.isRequired,
    isTelephone: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    onResendClick: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { formInit } = this.props;
    formInit();
  }

  submit = () => {
    const { submit } = this.props;
    submit();
  }

  render() {
    const {
      rrfForm,
      loading,
      loadingMsg,
      uuid,
      sportId,
      isTelephone,
      profile,
      onResendClick,
      match,
    } = this.props;
    return (
      <div className="UxEditProfile_ConfirmEmailPhone verify-your-email">
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
        <CloseLink to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportId ? `/${Sports[sportId]}` : ''}`} />
        <Container>
          <Row className="justify-content-center">
            <Col className="col-auto max-width-100">
              <Form
                model="forms.confirmEmailPhone"
                onSubmit={this.submit}
              >
                <Headline>
                  {
                    isTelephone ? 'Verify Your Phone Number' : 'Verify Your Email Address'
                  }
                </Headline>
                <div className="UxEditProfile_ConfirmEmailPhone_Email">
                  {isTelephone ? (profile.phone || '') : (profile.email || '')}
                </div>
                <TextControl
                  label="Verification Code"
                  placeholder="Verification Code"
                  model="forms.confirmEmailPhone.code"
                  rrfField={rrfForm.code}
                  help={isTelephone ?
                    'Enter the verfication code sent to you phone' :
                    'Enter the verfication code sent to you email'}
                  parser={parserOnlyNumbers}
                />
                <GeneralErrors rrfForm={rrfForm} />
                <div className="d-flex">
                  <Button
                    type="submit"
                    style={{
                      marginRight: '12px',
                    }}
                  >
                    Verify
                  </Button>
                  <Button
                    type="button"
                    onClick={onResendClick}
                  >
                    Resend Code
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <Line style={{ paddingTop: '35px' }} />
        </Container>
      </div>
    );
  }
}

export default ConfirmEmailPhone;
