import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/ExternalProfile/CreateUpdateDialog/CreateUpdateDialog';
import {getCurrentUuid} from "../../UxCommon/Utils";
import {actions} from "react-redux-form";
import {ExternalProfileSportsIcons} from "../../../components/UxProfile/UxProfile.constants";
import {formSubmit} from "../../../store/actions/ui/formUtils";
import {deleteExternalProfile, initExternalProfileForm} from "../../../store/actions/ui/uxProfile/externalProfile";
import {SportIds} from "../../../store/actions/ui/routes";

const getExternalProfile = (uuid, state, ownProps) => {
    if(ownProps.match) {
      if(ownProps.match.params.externalProfileId)
      {
        const { externalProfileId } = ownProps.match.params;
        if (externalProfileId === 'new') return null;

        const userExternalProfile = state.data.user.externalProfile[uuid];
        if(externalProfileId && userExternalProfile )
        {
          return userExternalProfile.find(exPro => exPro.id == externalProfileId);
        }
      }

    }
  return null;
}


const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  const  sportId = (ownProps.match && ownProps.match.params && ownProps.match.params.sport)
                        ? SportIds[ownProps.match.params.sport] :
                        state.ui.app.routes.currentSportId;
  return {
    selectedSportCompanies : ExternalProfileSportsIcons[sportId] || ExternalProfileSportsIcons['default'],
    sportId,
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
      loading: !!state.ui.uxProfile.externalProfile.createUpdateDialog.loadingMsg,
      loadingMsg: state.ui.uxProfile.externalProfile.createUpdateDialog.loadingMsg,
    state,
    rrfForm: state.forms.forms.externalProfile,
    externalProfile: getExternalProfile(currentUuid,state, ownProps ),
  }
}

const mapDispatchToProps = dispatch => ( {
  dispatch
})


const mergeProps = ( stateProps, { dispatch }, ownProps ) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onExternalProfileSubmitForm: (externalProfile= stateProps.externalProfile) => {
      dispatch(formSubmit('forms.externalProfile', { externalProfile, dispatch }));
  },
    onExternalProfileDeleteClick :(externalProfile) => {
      dispatch(deleteExternalProfile(externalProfile.id))
    },
    formInit: (externalProfile) => {
      dispatch(actions.reset('forms.externalProfile'));
      if ( externalProfile )
      {
        dispatch( actions.merge('forms.externalProfile', {
          url: externalProfile.url,
          company:externalProfile.company,
          logo: externalProfile.logo
        }))
      } else {
        dispatch( actions.setInitial('forms.externalProfile') );
      }
      dispatch(initExternalProfileForm());
    },
    onExternalProfileSelected: (option, logo) => {
      dispatch(actions.change('forms.externalProfile.company', option));
      dispatch(actions.change('forms.externalProfile.logo', logo));
    },
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
