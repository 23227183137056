import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

const CombineAwardDialog = ({
  onSaveClick,
  onCancelClick,
  header,
  body,
  saveText,
  cancelText,
}) => (
  <div className="CoachSignOutModal_Backdrop">
    <div className="CoachSignOutModal_CombineAwards">
      <CloseButton size={15} onClick={onCancelClick} />
      <div className="CoachSignOutModal_Header_Combine">
        {header}
      </div>
      <div className="CoachSignOutModal_Body_Combine">
        {body}
      </div>
      <div className="CoachSignOutModal_Footer">
        {
          onSaveClick &&
          <ButtonRect
            className="CoachSignOutModal_Btn primary"
            type="button"
            onClick={onSaveClick}
          >
            {saveText || 'Save'}
          </ButtonRect>
        }
        <ButtonRect
          className="CoachSignOutModal_Btn"
          type="button"
          onClick={onCancelClick}
        >
          {cancelText || 'Cancel'}
        </ButtonRect>
      </div>
    </div>
  </div>
);
CombineAwardDialog.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onSaveClick: PropTypes.func,
  saveText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default CombineAwardDialog;
