import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetFavorites';
import { getCurrentSportId, getCurrentUuid } from '../Utils';
import { getFavorites } from '../../../store/actions/ui/uxProfile';

const mapStateToProps = (state, ownProps) => ({
  uuid: getCurrentUuid(state, ownProps.match),
  sportId: getCurrentSportId(state),
  loading: state.ui.uxProfile.playerProfile.favoritesLoading,
});

const mapDispatchToProps = dispatch => ({
  getFavorites: (uuid, sportId) => {
    dispatch(getFavorites(uuid, sportId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
