import { combineReducers } from 'redux';

import {
  OPEN_ALBUM_SELECT_DIALOG,
  CLOSE_ALBUM_SELECT_DIALOG,
  OPEN_ALBUM_DIALOG,
  DELETE_ALBUM_EVERYTHING,
  DELETE_ALBUM_ONLY,
} from '../../../../actions/ui/uxProfile/photosVideos';
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR,
} from '../../../../actions/ui/async';

const isShowing = (state = false, action) => {
  switch (action.type) {
    case OPEN_ALBUM_SELECT_DIALOG:
      return true;
    case CLOSE_ALBUM_SELECT_DIALOG:
    case OPEN_ALBUM_DIALOG:
      return false;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === DELETE_ALBUM_EVERYTHING) {
        return `Deleting the album ${action.attrs.album.title} and everything in it`;
      }
      if (action.model === DELETE_ALBUM_ONLY) {
        return `Deleting the album ${action.attrs.album.title}, but not everything in it`;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === DELETE_ALBUM_EVERYTHING) ||
      (action.model === DELETE_ALBUM_ONLY)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isShowing,
  loadingMsg,
});

export default reducer;
