import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Tooltip extends PureComponent {
  static propTypes = {
    elem: PropTypes.object,
    children: PropTypes.any.isRequired,
    top: PropTypes.string,
  };

  state = {
    showing: false,
    refSet: false,
  };

  componentDidUpdate() {
    this.onUpdate();
  }

  componentWillUnmount() {
    const { elem } = this.props;
    if (this.state.refSet) {
      elem.removeEventListener('mouseenter', this.mouseEnter);
      elem.removeEventListener('mouseleave', this.mouseLeave);
    }
  }

  onUpdate = () => {
    const { elem } = this.props;
    if (elem && !this.state.refSet) {
      elem.addEventListener('mouseenter', this.mouseEnter);
      elem.addEventListener('mouseleave', this.mouseLeave);
      this.setState({ refSet: true });
    }
  };

  mouseEnter = () => {
    this.hovering = true;
    setTimeout(() => {
      if (this.hovering) {
        this.setState({ showing: true });
      }
    }, 500);
  };

  mouseLeave = () => {
    this.hovering = false;
    this.setState({ showing: false });
  };

  render() {
    const { children, elem, top } = this.props;
    const { showing } = this.state;
    return (
      <div
        className={[
          'UxCommon_Tooltip',
          showing ? 'show' : '',
        ].join(' ')}
        style={{
          right: elem ? elem.scrollWidth + 30 : '100%',
          top: top || '10px',
        }}
      >
        {children}
      </div>
    );
  }
}

export default Tooltip;
