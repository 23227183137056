import queryString from 'query-string';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Redirect, Route, Switch} from 'react-router-dom';
import Banner from '../common/Banner';
import FilterSidebar from '../common/FiltersSidebar';
import LoadingIndicator from '../common/LoadingIndicator';
import NavBarLink from '../common/NavBarLink';
import NavBarCombine from '../common/NavBarCombine';
import {
  editCombineTests,
  expandedCombineDialogPath,
  expandedGenerateReport,
  expandedGenerateReportPath,
  Routes
} from '../../../store/actions/ui/routes';
import {BankLabels, BankSlugs, CombineSortByLabels} from '../+store/combine';
import SidebarLinkFilter from '../common/SidebarLinkFilter';
import SidebarMultiSelectCallbackFilter from '../common/SidebarMultiSelectCallbackFilter';
import {gender, positions} from '../../../components/UxCommon/Form/utils';
import {RosterSortByOptions} from '../+store';
import TwoToneButton from '../common/TwoToneButton';
import MenuBar from './MenuBar';
import Icon from '../../../components/UxCommon/Icon/Icon';
import CombineAwards from './CombineAwards';
import {Banks} from '../../../store/actions/ui/uxPlayerData/tests/actions';
import CombineTable from './CombineTable/CombineTable.container';
import SchoolLogo from '../common/SchoolLogo';
import GenerateReportModal from './GenerateReportModal/GenerateReportModal.container';
import CloseCombineModal from './CloseCombineModal';
import PrintExperiment from './PrintExperiment';
import SidebarSortByFilter from '../common/SidebarSortByFilter';
import SearchControl from '../common/SearchControl';
import {getSortBy} from '../+store/coachWorld/coachWorld.utils';
import {sortAthletes} from '../+store/roster/roster.selectors';
import CombineSidebarEventFilter from '../common/CombineSidebarEventFilter';

import UploadVideoModal from './UploadCombineVideoModal/UploadVideoModal';
import VideoProgressDialog from './UploadCombineVideoModal/VideoProgressDialog';
import VideoDeleteModal from './UploadCombineVideoModal/VideoDeleteModal';
import ExpandedPhotoDialog from '../../../containers/UxProfile/Photos/ExpandedPhotoDialog';
import Video from '../../../store/actions/data/models/Video';
import RefreshPopUp from './RefreshPopUp/RefreshPopUp';
import CombineAgGridComponent from "./CombineAgGrid/CombineAgGrid.component";
import BulkDeleteModal from './BulkAthleteDeleteModal/BulkDeleteModal.component';
import {waitTill} from "./CombineAgGrid/CombineAgGrid.helpers";
import UndoDeleteModal from "./UndoAthleteDeleteModal/UndoDeleteModal.component";

const getSidebarFilters = ({
                             filterSchoolTeamList, sportId, gradYears
                           }) => {
  const linkFilters = [];
  let queryStringFilters = [
    {
      name: 'schoolTeamId',
      label: 'Teams',
      items: filterSchoolTeamList.map(st => ({name: st.team.name, value: st.team.id})),
    },
    {
      name: 'position',
      label: 'Positions',
      items: positions(sportId).map(({value, name}) => ({
        name,
        value,
      })),
    },
    {
      name: 'gender',
      label: 'Genders',
      items: gender().map(({text, value}) => ({name: text, value})),
    },
  ];

  if (gradYears.length) {
    const gradYearFilter = {
      name: 'gradYear',
      label: 'Grad Year',
      items: gradYears.map((value) => ({
        name: value,
        value,
      })),
    };

    queryStringFilters.splice(2, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters,
  };
};

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOSA: function () {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  },
  iOSB: function () {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOSA() || isMobile.iOSB() || isMobile.Opera() || isMobile.Windows());
  }
};

class Combine extends PureComponent {
  static propTypes = {
    getCombine: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    combineId: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    combine: PropTypes.object,
    athletes: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    bank: PropTypes.string,
    schoolStyle: PropTypes.array.isRequired,
    unloadCombine: PropTypes.func,
    updateCombine: PropTypes.func,
    shouldUpdate: PropTypes.bool,
    longLoading: PropTypes.bool,
    gradYears: PropTypes.array.isRequired,
    isCoach: PropTypes.bool.isRequired,
    deleteLastPasteDataCombine: PropTypes.func.isRequired,
    processingAllLaser: PropTypes.bool.isRequired,
  }

  state = {
    closeCombineModalOpen: false,
    showMobileFilters: false,
    isVideoUploadClicked: false,
    showVideoDeleteDialog: false,
    showVideoUploadProgressDialog: false,
    paginatedKey: null,
    paginatedKeyIndex: null,
    athleteId: '',
    athlete: {},
    column: {},
    combine: {},
    value: {},
    deleteVideoId: false,
    renderPrint: false,
    bulkDeleteModal: false,
    bulkDeleteData: {},
    undoDeleteModal: false,
    lastPasteData: [],
    currentAthletes: [],
    isOverFlowClass: false
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate(prevProps) {
    this.shouldUpdate(prevProps);
    const {
      shouldUpdate,
      getCombineAwards,
      getCombineAndPrevCombineTest,
      combineId,
      isCombineOpen,
      updateCombineLoader,
      comparedAwards,
      combine,
      loadingComparedTestResult,
      athletes
    } = this.props;
    if (!this.combineAwardsFetched && combineId) {
      this.combineAwardsFetched = true;
      getCombineAwards([combineId]);
    }
    if (combine && !isCombineOpen && updateCombineLoader === 'closed' && !this.combinePrevFetched) {
      this.combinePrevFetched = true;
      getCombineAndPrevCombineTest(combineId);
    }
    if (updateCombineLoader === 'closed'
      && loadingComparedTestResult === 'compaired'
      && comparedAwards
      && comparedAwards.length > 0) {
      this.createAwardAfterCloseCombine();
    }
  }

  componentWillUnmount() {
    const {unstageNewMedia, stagedMedia, clearAthleteVideoId} = this.props;
    unstageNewMedia(stagedMedia[0]);
    clearAthleteVideoId();
  }

  combineAwardsFetched = false;
  combinePrevFetched = false;

  onCloseCombine = () => {
    const {combine, updateCombine, createAward} = this.props;
    const data = {
      ...combine,
      closed_at: moment().utc().utcOffset(-6).format('YYYY-MM-DD HH:mm:ss'),
    };
    this.setState({
      closeCombineModalOpen: false,
    });
    updateCombine(data, combine);
    // this.createAwardAfterCloseCombine();
  }

  createAwardAfterCloseCombine = () => {
    const {combine, createAward, athletes, comparedAwards, match} = this.props;
    let data = [];
    console.log(comparedAwards, 'comparedAwards ...');

    athletes.map((athlete) => {
      let awards = comparedAwards && comparedAwards
        .filter(x => (x.bestObjectArr.length > 0 && x.bestObjectArr
            .find(ath => ath.athleteId === athlete.userId))
          || (x.mostImprovedObjectArr.length > 0 && x.mostImprovedObjectArr
            .find(ath => ath.athleteId === athlete.userId)));

      awards = awards && awards.map((award) => {
        const bestObject = award.bestObjectArr
          .find(ath => ath.athleteId === athlete.userId);
        const mostImprovedObject = award.mostImprovedObjectArr
          .find(ath => ath.athleteId === athlete.userId);
        return {...award, bestObject, mostImprovedObject};
      });

      const filteredAwards = awards && awards.reduce((currentValue, award) => {
        currentValue.push(award.bestObject, award.mostImprovedObject);
        return currentValue;
      }, []).filter(a => a && a.athleteId === athlete.userId);
      console.log(filteredAwards, 'filteredAwards');
      filteredAwards && filteredAwards.map((award, i) => {
        data.push({
          name: award.awardTitle,
          description: award.awardDesc,
          award_type: 'combine',
          user_id: athlete.userId,
          standardTestObjectId: award.standardTestObjectId
        });
      });
      return null;
    });
    //create the awards.
    createAward(combine.id, data, match.params.schoolId)
  }

  onCloseCombineClick = () => {
    this.setState({
      closeCombineModalOpen: true,
    });
  }

  onPasteEnds = (lastPasteData) => {
    this.setState({
      lastPasteData
    });
  }

  //bulkDeleteModal
  onCancelBulkDelete = (params) => {
    params.api.clearRangeSelection();
    this.setState({
      bulkDeleteModal: false,
    });
  }

  onCancelUndoDelete = (params) => {
    this.setState({
      undoDeleteModal: false,
      lastPasteData: [],
    });
  }

  onBulkDelete = (params) => {
    this.setState({
      bulkDeleteModal: true,
      bulkDeleteData: params,
    });
  }

  onUndoButtonClick = () => {
    this.setState({
      undoDeleteModal: true,
    })
  }

  onUndoBulkDelete = async (dataToDelete) => {
    const {deleteLastPasteDataCombine} = this.props;
    const DELETE_LIMIT = 200;
    if (dataToDelete.length) {
      const dispatchTurn = parseInt(dataToDelete.length / DELETE_LIMIT) + ((dataToDelete.length % DELETE_LIMIT) ? 1 : 0);
      for (let i = 0; i < dispatchTurn; i++) {
        deleteLastPasteDataCombine(dataToDelete.slice(i * DELETE_LIMIT, DELETE_LIMIT + (i * DELETE_LIMIT)));
        await waitTill();
      }
    }
    this.onCancelUndoDelete();
  }

  clearCells = async (startRowIndex, endRowIndex, colIds, params) => {
    const deleteData = [];
    const {deleteBulkTestCombine, combine} = this.props;
    const athletes = [...this.currentAthletes];
    const DELETE_LIMIT = 200;
    for (let i = startRowIndex; i <= endRowIndex; i++) {
      const rowNode = params.api.getRowNode(athletes[i].userId);
      const existingColdIds = colIds.filter(colId => rowNode.data.testResults && rowNode.data.testResults[colId]);
      const testResultIds = existingColdIds.length ? existingColdIds.map(colId => rowNode.data.testResults[colId].id) : null;
      const testResultVideoId = existingColdIds.length ? existingColdIds.map(colId => rowNode.data.testResults[colId].videoId) : null;

      if (testResultIds) {
        deleteData.push({
          athleteId: athletes[i].userId,
          testResultIds: testResultIds,
          standardTestObjectId: colIds,
          combineId: combine.id,
          testId: rowNode.data.test.id,
          videoId: testResultVideoId
        })
      }
    }

    if (deleteData.length) {
      const dispatchTurn = parseInt(deleteData.length / DELETE_LIMIT) + ((deleteData.length % DELETE_LIMIT) ? 1 : 0);
      for (let i = 0; i < dispatchTurn; i++) {
        deleteBulkTestCombine(deleteData.slice(i * DELETE_LIMIT, DELETE_LIMIT + (i * DELETE_LIMIT)))
        await waitTill();
      }
    }
    this.onCancelBulkDelete(params);
  }

  onVideoUploadHandler = (athlete, column, combine, value) => {
    this.setState({athleteId: athlete.userId, athlete, column, combine, value, isVideoUploadClicked: true});
  }

  onVideoDeleteHandler = (athlete, column, combine, value, athleteId) => {
    this.setState({athlete, column, combine, value, showVideoDeleteDialog: true, athleteId});
  }

  onSaveClickModal = () => {
    const {onSaveClick} = this.props;
    onSaveClick();
    this.setState({isVideoUploadClicked: false, showVideoUploadProgressDialog: true})
  }

  onCancelVideoModel = (isDelete = null) => {
    const {unstageNewMedia, stagedMedia, clearAthleteVideoId} = this.props;
    unstageNewMedia(stagedMedia[0]);
    clearAthleteVideoId();
    if (isDelete) {
      this.setState({showVideoDeleteDialog: false})
    } else {
      this.setState({isVideoUploadClicked: false, showVideoUploadProgressDialog: false});
    }
  }

  saveVideoInTestResult = (isDelete = null) => {
    const {athlete, column, combine, value, athleteId} = this.state;
    const {athletes} = this.props;
    const currentAthlete = !!athleteId ? athletes.find((item) => item.userId === athleteId) : athlete;

    const currentCombine = this.props.combine;
    const columns = currentCombine.columns.filter((item) => item.standardTestObjectId === column.standardTestObjectId);
    const {videoList, saveTestResults} = this.props;
    let tempValue = {}, processed = 0;
    let isUploadingVideo = !!isDelete ? false : true;
    if (isDelete) {
      tempValue = {
        ...value,
        videoId: ''
      }
    } else {
      const athleteVideo = videoList[athleteId];
      const length = athleteVideo.length;
      const videoId = athleteVideo[length - 1].id;
      const embedPath = athleteVideo[length - 1].embedPath;
      if (embedPath) {
        processed = 1;
      }
      tempValue = {
        ...value,
        videoId,
        processed,
        isLaser: columns && columns[0].allLaser ? 1 : 0
      }

    }
    saveTestResults(currentAthlete, column, combine, tempValue, isUploadingVideo);
    //close the deleteDialog box
    this.setState({showVideoDeleteDialog: false});
  }

  // setAthleteDetail = (athlete, column, combine, value) => {
  //   this.setState({athleteId: athlete.userId, athlete, column, combine, value});
  // }

  onCancelSignOut = () => {
    this.setState({
      closeCombineModalOpen: false,
    });
  }

  onPrintClick = () => {
    const {renderPrint} = this.state;
    if (renderPrint) {
      const pri = document.getElementById('printable').contentWindow;
      pri.focus();
      pri.print();
    } else {
      setTimeout(() => {
        const pri = document.getElementById('printable').contentWindow;
        pri.focus();
        pri.print();
        this.setState({renderPrint: false});
      }, 100);
      this.setState({renderPrint: true});
    }

  }


  setOverFlowClass = (isFocus) => {
    this.setState({isOverFlowClass: isFocus})
  }

  getTestResult = (athlete, column, isReps = false, isPerf = false) => {
    const {staging, combineId} = this.props;
    const staged = staging[`${athlete.userId}:${combineId}:${column.standardTestObjectId}`];
    if (staged !== undefined) {
      return staged;
    }
    if (athlete.test && athlete.testResults) {
      const result = athlete.testResults[column.standardTestObjectId];
      let videoId = '', videoProcessed = null;
      if (result) {
        videoId = result.videoId;
        videoProcessed = result.videoProcessed;
      }
      if (result && isPerf && result.result) {
        const isLaser = result.isLaser !== undefined ? result.isLaser : result.laser;
        return {result: result.result, isLaser, videoId, videoProcessed};
      }
      if (result && isReps && result.result) {
        return {
          result: result.result,
          reps: result.reps,
          ratedResult: result.ratedResult,
          multiRepMax: result.multiRepMax,
          videoId,
          videoProcessed
        };
      }
      if (result) {
        return {result: result.result, videoId, videoProcessed, isLaser: result.isLaser};
      }
    }
    return null;
  };

  generateReport = () => {
    const {match, history} = this.props;
    history.push(expandedGenerateReport(
      match.params.schoolId,
      match.params.combineId,
      match.params.bank,
    ));
  }

  editCombineTests = () =>{
    const { match, combine, history } = this.props;
    if(!combine.isOpen){
      return false;
    }
    history.push(editCombineTests(
      match.params.schoolId,
      match.params.combineId,
      combine.combineTemplate.id,
    ));
  }

  selectedOption = 'measurables';
  combineFetched = false;

  shouldUpdate = (prevProps = null) => {
    const {
      getCombine,
      combineId,
      shouldUpdate,
      paginatedKeys,
      location,
    } = this.props;
    const {paginatedKey, paginatedKeyIndex} = this.state;
    if (shouldUpdate && !this.combineFetched) {
      this.combineFetched = true;
      getCombine(combineId);
    }
    if (paginatedKeys.length && (!this.state.paginatedKey || !paginatedKeys.includes(paginatedKey))) {
      this.setState({paginatedKey: paginatedKeys[0]});
    }
    if (prevProps && location.search !== prevProps.location.search) {
      this.setState({paginatedKey: paginatedKeys.length ? paginatedKeys[paginatedKeyIndex] : null});
    }
  }

  choseCurrentSchoolStyle = () => {
    const {schoolStyle, combine} = this.props;
    const schoolID = combine.schoolId;
    let obj;
    if (schoolStyle.length > 0 && schoolID) {
      obj = schoolStyle.find(o => o.id === schoolID);
    }
    this.schoolStyleChosen = true;
    return obj;
  }

  redirectToBank = () => {
    const {combine, match} = this.props;
    const bankSlug = BankSlugs[combine.banks[0]];
    return <Redirect
      to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${combine.id}/${bankSlug}?sortBy=${RosterSortByOptions.firstNameAZ}`}/>;
  }

  downloadCombineAsCSV = () => {
    const {
      combine, athletes,
    } = this.props;
    const {columns} = combine;
    let csv = `Athlete,${columns.map((column) => {
      const {useReps} = column;
      if (useReps) {
        return `${column.testCategoryName},${column.testCategoryName} Reps,${column.testCategoryName} Calculated`;
      }
      return column.testCategoryName;
    }).join(',')}\n`;
    let sort = getSortBy(null, this.props);
    const athletesAfterSort = sortAthletes(athletes, sort)
    athletesAfterSort.forEach((athlete) => {
      csv += `${athlete.givenName} ${athlete.familyName},`;
      columns.forEach((column) => {
        const {useReps} = column;
        const isPerf = !useReps;
        const val = this.getTestResult(athlete, column, useReps, isPerf);
        if (val) {
          if (useReps && val.result) {
            csv += `${(val.result || '0')},${val.reps || '1'},${val.multiRepMax || '0'},`;
          } else if (isPerf && val.result) {
            csv += `${(val.result || '0')},`;
          } else {
            csv += `${(val.result || '0')},`;
          }
        } else if (useReps) {
          csv += '0,0,0,';
        } else {
          csv += '0,';
        }
      });
      csv += '\n';
    });
    const fileName = `${combine.name.split(' ').join('_')}_${Date.now()}.csv`;
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
  }

  renderExpandedGenerateReportDialog = (props) => {
    const {combine} = this.props;
    return (
      <GenerateReportModal
        {...props}
        combine={combine}
        athletes={this.props.athletes}
        location={this.props.location}
      />
    );
  };

  pageRefreshed = () => {
    window.location.reload();
  }

  renderTable = ({match, location}) => {
    const {
      bank,
      combine,
      athletes,
      schoolStyle,
      paginated,
      schoolAwardLogo,
      unstageNewMedia,
      stagedMedia,
      clearAthleteVideoId,
      saveTestResults,
      isCoach,
      athleteDataProcessing,
      processingAllLaser,
    } = this.props;


   const isBankExist = combine.banks.find(bk => bank === BankSlugs[bk]);
   if(!isBankExist){
     const bankSlug = BankSlugs[combine.banks[0]];
     return <Redirect
       to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${combine.id}/${bankSlug}?sortBy=${RosterSortByOptions.firstNameAZ}`}/>;
   }

    const {paginatedKey, renderPrint, isOverFlowClass} = this.state;
    const currentAthletes = sortAthletes(
      !paginatedKey || !(paginatedKey in paginated) ? [] : paginated[paginatedKey],
      getSortBy(null, this.props)
    );
    this.currentAthletes = currentAthletes;

    //filtering columns based on bankslug
    let columns = combine.columns.filter(c => BankSlugs[c.testBankCode] === match.params.bank);
    let allColumns = columns;

    if (location && location.search) {
      const parsedQuery = queryString.parse(location.search);
      const filtersJson = parsedQuery.filters;

      if (filtersJson) {
        const filters = JSON.parse(filtersJson);
        filters.forEach((filter) => {
          if (filter.name === 'events') {
            columns = columns.filter(c =>
              filter.selected.includes(c.standardTestObjectId)
            )
            columns = (columns.length != 0 ? columns : allColumns);
          }
        })
      }
    }

    let currentSchoolStyle;
    if (schoolStyle.length > 0) {
      currentSchoolStyle = this.choseCurrentSchoolStyle();
    }
    let athletesAfterSort = [];
    if (renderPrint) {
      let sort = getSortBy(null, this.props);
      athletesAfterSort = sortAthletes(athletes, sort);
    }

    return (
      <React.Fragment>

        <LoadingIndicator className={!columns ? "CoachCombine_loader":"combineAgGrid"} loading={!!athleteDataProcessing || !!processingAllLaser || !columns}
                          loadingMsg={"Processing"} wrapperStyle={!columns ? {top: 131} : {}}/>
        <div
          id="Combine_RosterTableWrapper"
          className={`Combine_RosterTableWrapper AgGridTooltip ${bank === 'awards' ? 'Vc-combine-grantaward' : ''} ${isOverFlowClass ? 'table_overflow' : ''}`}
        >
          {
            bank === Banks.awards ?
              <CombineAwards
                match={match}
                location={location}
                combine={combine}
                bank={bank}
                columns={columns}
                athletes={athletes}
                schoolAwardLogo={schoolAwardLogo}
                currentSchoolStyle={currentSchoolStyle}
              />
              :
              <React.Fragment>
                {
                  isMobile.any() ?
                    <CombineTable
                      key={`CombineTable-${paginatedKey}`}
                      combine={combine}
                      setAthleteDetail={this.setAthleteDetail}
                      bank={bank}
                      columns={columns}
                      location={location}
                      unstageNewMedia={unstageNewMedia}
                      stagedMedia={stagedMedia}
                      // getRefreshPopUp={this.getRefreshPopUp}
                      clearAthleteVideoId={clearAthleteVideoId}
                      onVideoUploadHandler={(athlete, column, combine, value, athleteId) => this.onVideoUploadHandler(athlete, column, combine, value, athleteId)}
                      onVideoDeleteHandler={(athlete, column, combine, value, athleteId) => this.onVideoDeleteHandler(athlete, column, combine, value, athleteId)}
                      athletes={currentAthletes}
                      getTestResult={this.getTestResult}
                      setOverFlowClass={this.setOverFlowClass}
                    />
                    :
                    <CombineAgGridComponent
                      bank={bank}
                      columns={columns}
                      athletes={currentAthletes}
                      combine={combine}
                      location={location}
                      getTestResult={this.getTestResult}
                      onSaveTestResults={saveTestResults}
                      onBulkDelete={this.onBulkDelete}
                      onUndoDelete={this.onUndoDelete}
                      athleteDataProcessing={athleteDataProcessing}
                      unstageNewMedia={unstageNewMedia}
                      stagedMedia={stagedMedia}
                      clearAthleteVideoId={clearAthleteVideoId}
                      onVideoUploadHandler={(athlete, column, combine, value) => this.onVideoUploadHandler(athlete, column, combine, value)}
                      onVideoDeleteHandler={(athlete, column, combine, value) => this.onVideoDeleteHandler(athlete, column, combine, value)}
                      isCoach
                      onPasteEnds={this.onPasteEnds}
                      schoolStyle={schoolStyle}
                    />

                }
                <PrintExperiment
                  combine={combine}
                  bank={bank}
                  columns={columns}
                  athletes={athletesAfterSort}
                  match={match}
                  currentSchoolStyle={currentSchoolStyle}
                  getTestResult={this.getTestResult}
                />
              </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }

  getSortByLabels = () => {

    const allowedLabels = [
      'firstNameAZ',
      'firstNameZA',
      'lastNameAZ',
      'lastNameZA',
      'combineNumberLowHigh',
      'combineNumberHighLow',
    ]

    return Object.keys(CombineSortByLabels)
      .filter(key => allowedLabels.includes(key))
      .reduce((obj, key) => {
        obj[key] = CombineSortByLabels[key];
        return obj;
      }, {});
  }

  currentAthletes = [];


  render() {
    const {
      loading,
      loadingMsg,
      combineId,
      match,
      combine,
      history,
      location,
      schoolStyle,
      schoolTeamsList,
      longLoading,
      paginatedKeys,
      gradYears,
      onDrop,
      stagedMedia,
      getPhotoCardRrfForm,
      getPhotoCardRrfFormModel,
      unstageNewMedia,
      selectedTags,
      videoLoading,
      videoLoadingMsg,
      tagRelMedia,
      allTags,
      deleteMedia,
      showMediaIsNotAccepted,
      rrfForm,
      currentUuid,
      videoList,
      onExternalVideoClick,
      onExternalVideoCancelClick,
      isExternalVideoDialogOpen,
      loadingProgress,
      expandedDialogVideos,
      refreshPopUp,
      athleteDataProcessing,
    } = this.props;

    const {
      closeCombineModalOpen,
      paginatedKey,
      isVideoUploadClicked,
      athleteId,
      showVideoDeleteDialog,
      showVideoUploadProgressDialog,
      bulkDeleteModal,
      bulkDeleteData,
      undoDeleteModal,
      lastPasteData,
    } = this.state;

    let sportId = 0;
    let openDate = '';
    let toDate = '';
    let currentSchoolStyle;
    let showPrintButton = true;

    const photoVideo = expandedDialogVideos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });
    const processed = Object.keys(expandedDialogVideos).length !== 0 && expandedDialogVideos[0].processed;

    if (combine && combine.isOpen && match.params.bank === 'awards') {
      showPrintButton = false;
    }
    if (combine && (combine.id === Number(match.params.combineId))) {
      if (combine.combineTemplate) {
        ({sportId} = combine.combineTemplate);
      }
      if (combine.openDate) {
        openDate = combine.openDate.format('MM/DD/YY');
      }
      if (combine.isOpen) {
        toDate = combine.endDate ? combine.endDate.format('MM/DD/YY') : '';
      } else {
        toDate = combine.closeDate ? combine.closeDate.format('MM/DD/YY') : '';
      }
      if (schoolStyle.length > 0) {
        currentSchoolStyle = this.choseCurrentSchoolStyle();
      }
    }
    let filterSchoolTeamList = [];
    if (combine && combine.lockedTeams) {
      const combineTeamArray = [].concat(combine.lockedTeams.map(team => team.id));

      filterSchoolTeamList = schoolTeamsList.filter(schoolTeam => combineTeamArray.includes(schoolTeam.team.id))
    }
    const {linkFilters, queryStringFilters} = getSidebarFilters({
      filterSchoolTeamList, sportId, gradYears
    });
    const arraySortOptions = Object.values(RosterSortByOptions);

    let eventColumns = null;
    if (combine && combine.columns) {
      eventColumns = combine.columns.filter(c => BankSlugs[c.testBankCode] === match.params.bank);
    }

    return (
      <div className="CoachCombine">
        <Banner match={match}>
          <div className="CoachRoster_BannerChildren combine Combine_landscape">
            <div className="CoachRoster_BannerLeft">
              <SchoolLogo
                className="CoachRoster_BannerTeamLogo"
                schoolStyle={currentSchoolStyle || null}
              />
              <div className="CoachRoster_BannerFullTitle">Combine Results</div>
            </div>
            <div className="CoachCombine_BannerRight">
              {
                combine && (
                  <div className="CoachCombine_Dates">
                    <Icon iconName="calendar" className="CoachCombine_CalendarIcon"/>
                    <div>{`Combine Dates ${openDate} - ${toDate}`}</div>
                  </div>
                )
              }
              {
                combine && (!combine.isOpen) && (
                  <TwoToneButton
                    onClick={this.generateReport}
                    text="Generate Report"
                    iconName="report"
                  />
                )
              }
              {
                combine && combine.isOpen && combine.isCombineCreator && (
                  <TwoToneButton onClick={this.onCloseCombineClick} text="Close Combine" iconName="lock"/>
                )
              }
              {
                !longLoading && showPrintButton && (
                  <TwoToneButton className="print" onClick={this.onPrintClick} text="Print" iconName="print"/>
                )
              }
              {
                !longLoading && (
                  <TwoToneButton className="print" onClick={this.downloadCombineAsCSV} text="Download"
                                 iconName="download"/>
                )
              }
            </div>
          </div>
        </Banner>
        <Route
          path={expandedGenerateReportPath}
          render={this.renderExpandedGenerateReportDialog}
        />
        <Route
          path={expandedCombineDialogPath}
          render={(props) => (
            <ExpandedPhotoDialog photoList={photoVideo} {...props} location={location} isSingleVideo={true}
                                 page={`${Routes.combine}`}/>
          )}
        />
        {
          closeCombineModalOpen && (
            <CloseCombineModal
              onCancel={this.onCancelSignOut}
              onCloseCombine={this.onCloseCombine}
            />
          )
        }
        {
          bulkDeleteModal && (
            <BulkDeleteModal
              onCancel={() => this.onCancelBulkDelete(bulkDeleteData)}
              clearCells={this.clearCells}
              bulkDeleteData={bulkDeleteData}
            />
          )
        }
        {
          undoDeleteModal && (
            <UndoDeleteModal
              onCancel={() => this.onCancelUndoDelete()}
              onDelete={this.onUndoBulkDelete}
              undoDeleteData={lastPasteData}
            />
          )
        }
        {
          showVideoUploadProgressDialog &&
          <VideoProgressDialog
            loading={videoLoading}
            loadingMsg={videoLoadingMsg}
            videos={videoList[athleteId]}
            onCancel={() => this.onCancelVideoModel()}
            saveVideo={() => this.saveVideoInTestResult()}
            loadingProgress={loadingProgress}
          />
        }
        {
          showVideoDeleteDialog && <VideoDeleteModal
            onDeleteVideo={() => this.saveVideoInTestResult("delete")}
            onCancel={() => this.onCancelVideoModel("delete")}
          />
        }
        {
          refreshPopUp && <RefreshPopUp
            msz={'Hey Coach - we detect a connection issue and need you to refresh the page so you can continue to enter data.'}
            buttonMsz={"Refresh"}
            buttonPressed={"Refreshing..."}
            onCancel={this.pageRefreshed}
          />
        }
        {
          isVideoUploadClicked &&
          <UploadVideoModal
            onCancel={() => this.onCancelVideoModel()}
            onDrop={onDrop}
            stagedMedia={stagedMedia}
            getPhotoCardRrfForm={getPhotoCardRrfForm}
            getPhotoCardRrfFormModel={getPhotoCardRrfFormModel}
            unstageNewMedia={unstageNewMedia}
            selectedTags={selectedTags}
            videoLoading={videoLoading}
            videoLoadingMsg={videoLoadingMsg}
            loadingProgress={loadingProgress}
            isExternalVideoDialogOpen={isExternalVideoDialogOpen}
            tagRelMedia={tagRelMedia}
            athleteId={athleteId}
            onExternalVideoClick={onExternalVideoClick}
            onExternalVideoCancelClick={onExternalVideoCancelClick}
            allTags={allTags}
            deleteMedia={deleteMedia}
            showMediaIsNotAccepted={showMediaIsNotAccepted}
            onSaveClick={this.onSaveClickModal}
            rrfForm={rrfForm}
            match={match}/>
        }
        <div className="CoachCombine_BodyWrapper vc-pt-5">
          {/* <NavBar selectedOption={this.selectedOption} combineId={combineId} /> */}
          <NavBarCombine>
            <div className="CoachNavBar_Container">
              <div className="CoachNavBar_Left">
                {
                  combine && combine.banks.map((bank, index) => (
                    <NavBarLink
                      key={index}
                      to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${combineId}/${BankSlugs[bank]}`}
                      match={match}
                      location={location}
                    >
                      {BankLabels[bank]}
                    </NavBarLink>
                  ))
                }
                {/* <NavBarLink
                  to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${combineId}/${Routes.awards}`}
                  match={match}
                  location={location}
                >
                  Grant Awards
                </NavBarLink> */}
              </div>
              <div className="CoachNavBar_Right CoachNavBar_Right_Leaderboard">
                <NavBarLink
                  to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${combineId}`}
                  match={match}
                  location={location}
                >
                  Go to Leaderboard
                </NavBarLink>
              </div>
            </div>

            <div className="CoachNavBar_SearchBox">
              <SearchControl
                placeholder="Search the Combine"
                location={location}
                history={history}
              />

              { combine && combine.combineTemplate &&
                (
                    <TwoToneButton
                      onClick={this.editCombineTests}
                      text="ADD OR REMOVE TEST"
                      iconName="edit"
                      title={`${combine.isOpen ? 'ADD OR REMOVE TEST' : 'Hey Coach - if you want to add or remove a test, first re-open the combine'}`}
                      className={`${combine.isOpen ? 'ADD_OR_REMOVE_TEST' : 'DISABLED_ADD_OR_REMOVE_TEST'}`}
                    />
                )
              }
              {
                (!isMobile.any()) &&
                <TwoToneButton disableButtons={!lastPasteData.length || athleteDataProcessing}
                               disable={!lastPasteData.length || athleteDataProcessing}
                               onClick={this.onUndoButtonClick}
                               text="Undo Paste" iconName="undo" title={"Undo Last Paste"}/>
              }
            </div>
          </NavBarCombine>
          <MenuBar loading={loading}/>

          <div className="CoachCombine_Body">
            <LoadingIndicator className={"CoachCombine_loader"} loading={loading} loadingMsg={loadingMsg}
                              wrapperStyle={{top: 120}}/>

            <button className="CoachWorld_FilterIcon" onClick={() => this.setState({showMobileFilters: true})}>
              <Icon iconName="filter"/>
            </button>
            <FilterSidebar
              combineName="Vc_Combine_FilterSidebar"
              displayNone={!!loading}
              showMobileFilters={v => this.setState({showMobileFilters: v})}
              defaultIsHidden={!this.state.showMobileFilters}
              screenSize={576}
            >
              <SidebarSortByFilter
                usePagination
                label="Group By Last Name"
                filters={paginatedKeys.map((key) => ({
                  name: key,
                  value: key,
                }))}
                paginatedKey={paginatedKey}
                history={history}
                location={location}
                checkboxClassName="FilterCheckbox"
                className="Leaderboard"
                handlePaginationChange={(v, i) => this.setState({
                  paginatedKey: v,
                  paginatedKeyIndex: paginatedKeys.indexOf(v)
                })}
              />
              {
                (match.params.bank && (match.params.bank !== 'awards') &&
                  <SidebarSortByFilter
                    label="Sort By"
                    filters={Object.keys(this.getSortByLabels()).map((column, i) => ({
                      name: CombineSortByLabels[column],
                      value: column,
                    }))}
                    history={history}
                    location={location}
                    page={'Combine'}
                    className="Leaderboard"
                  />
                )
              }
              {
                (eventColumns &&
                  <CombineSidebarEventFilter
                    filterName="events"
                    label="Events"
                    filters={eventColumns.map(column => ({
                      name: column.testCategoryName,
                      value: column.standardTestObjectId,
                    }))}
                    history={history}
                    location={location}
                    page={'Combine'}
                    className="Events"
                  />
                )
              }
              {
                linkFilters.map(({name, label, items}) => (
                  <SidebarLinkFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    match={match}
                  />
                ))
              }
              {
                queryStringFilters.map(({name, label, items}) => (
                  <SidebarMultiSelectCallbackFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    history={history}
                    location={location}
                  />
                ))
              }
            </FilterSidebar>
            {
              combine && (combine.id === Number(match.params.combineId)) && (
                <Switch>
                  <Route path={`/${Routes.school}/:schoolId/${Routes.combine}/:combineId/:bank`}
                         render={this.renderTable}/>
                  <Route path={`/${Routes.school}/:schoolId/${Routes.combine}/:combineId`}
                         render={this.redirectToBank}/>
                </Switch>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Combine;
