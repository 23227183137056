import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/Photos/MultiDeleteBanner/MultiDeleteBanner';
import { multiDeleteSelectAll, closeMultiDelete, executeMultiDelete, openMoveAlbumDialog } from '../../../store/actions/ui/uxProfile/photosVideos';
import { setBottomBanner } from '../../../store/actions/ui/uxProfile';

const mapStateToProps = state => ({
  loadingMsg: state.ui.uxProfile.photosVideos.multiDelete.loadingMsg,
  multiDeleteError: state.ui.uxProfile.photosVideos.multiDelete.error,
  stage: state.ui.uxProfile.photosVideos.multiDelete.stage,
  selected: state.ui.uxProfile.photosVideos.multiDelete.selected,
  isMoveAlbumDialogOpened: !!state.ui.uxProfile.photosVideos.moveAlbumDialog.isShowing,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onSelectAllClick: (photoList) => {
      dispatch(multiDeleteSelectAll(photoList));
    },
    onCancelClick: () => {
      dispatch(closeMultiDelete());
      dispatch(setBottomBanner(null));
    },
    onExecuteClick: () => {
      dispatch(executeMultiDelete());
    },
    onOpenMoveAlbumDialogClick: () => {
      dispatch(openMoveAlbumDialog());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
