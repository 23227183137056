import { connect } from 'react-redux';

import Component from '../../components/UxEditProfile/ChangePass/ChangePass';
import { changePasswordFormInit } from '../../store/actions/ui/uxEditProfile';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { routeChangedAlert } from '../../store/actions/ui/routes';
import { changePasswordFormInputs } from '../../store/actions/ui/uxEditProfile/utils';
import { alertOrNavigate } from '../UxCommon/NativeUtils';

const mapStateToProps = state => ({
  loading: state.ui.uxEditProfile.changePass.loading,
  loadingMsg: state.ui.uxEditProfile.changePass.loadingMsg,
  rrfForm: state.forms.forms.changePassword,
  passwordHelp: state.ui.uxEditProfile.changePass.formPasswordHelp,
  formGeneralError: state.ui.uxEditProfile.changePass.formError,
  uuid: state.data.cognito.uuid,
  sportId: state.ui.app.routes.currentSportId,
  inputs: changePasswordFormInputs,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    submit: () => {
      dispatch(formSubmit('forms.changePassword'));
    },
    formInit: changePasswordFormInit(dispatch),
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
