import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../../../components/UxCommon';

const CombineTitle = ({
  title, onClickNew, onClickTemplates,
}) =>
  (
    <div className="Combine_Title_Row">
      <div className="DashboardCombine_Title_Text">
        {title}
      </div>
      <div>
        {/* {
          onClickTemplates && (
            <ButtonRect
              className="CombineTitle_Button"
              onClick={onClickTemplates}
              type="button"
            >
              Combine Templates
            </ButtonRect>
          )
        } */}
        {
          // onClickNew && (
            <ButtonRect
              className="CombineTitle_Button"
              // onClick={onClickNew}
              onClick={onClickTemplates}
              type="button"
            >
              Run Combine
            </ButtonRect>
          // )
        }
      </div>
    </div>
  );
CombineTitle.propTypes = {
  title: PropTypes.string,
  onClickNew: PropTypes.func,
  onClickTemplates: PropTypes.func,
};

export default CombineTitle;
