import { combineReducers } from 'redux';
import stats from './stats';
import tests from './tests';
import teams from './teams';

const reducer = combineReducers({
  stats,
  tests,
  teams,
});

export default reducer;
