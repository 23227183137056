import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from '../containers/UxCommon/Utils';

export default (match) => {
  const [hasMembership, setHasMembership] = useState(true);

  const profile = useSelector((state) => getProfile(state, match));
  const { canEditProfile } = useSelector((state) => state.ui.app.context);

  useEffect(() => {
    const isCoach = canEditProfile && canEditProfile.isCoach;


    if (isCoach || (profile && !profile.isPaidProfile)) {
      setHasMembership(true);
    } else {
      setHasMembership(
        !!(profile && profile.isPaidProfile && profile.isSubscribed),
      );
    }
  }, [profile, canEditProfile]);

  return hasMembership;
};
