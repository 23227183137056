import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Component from "../../components/UxEditProfile/TwitterHandle/TwitterHandle";
import {getAuthenticatedUserProfile, getProfile} from "../UxCommon/Utils";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import {
  twitterHandleFormInit,
  updateTwitterHandle,
  createTwitterHandle
} from "../../store/actions/ui/uxEditProfile/twitterHandle";

const mapStateToProps = (state, ownProps) =>  {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    profile: isCoach ? getProfile(state, ownProps.match) : getAuthenticatedUserProfile(state),
    loading: !!state.ui.uxEditProfile.twitterHandle.loadingMsg,
    loadingMsg: state.ui.uxEditProfile.twitterHandle.loadingMsg,
    rrfForm: state.forms.forms.twitterHandle,
    formNeedsInit: state.ui.uxEditProfile.twitterHandle.formNeedsInit,
    message: state.ui.uxEditProfile.twitterHandle.message,
    state
  });
}

const mergePorps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    submit: () => {
      const twitterHandleVal = (newStateProps.rrfForm.url.value).replace(/^@/, "");
      // @var hasPrevValue this will check if athlete had previousely a value

      const hasPrevValue =   (stateProps.profile.twitterHandle).length > 1 ? true : false;
      if (twitterHandleVal) {
        dispatch(updateTwitterHandle(twitterHandleVal,hasPrevValue));
      } else {
        dispatch(createTwitterHandle(twitterHandleVal,hasPrevValue));
      }
    },
    formInit: () => {
      dispatch(actions.reset("froms.twitterHandle"));
      dispatch(
        actions.change(
          "forms.twitterHandle.url",
          stateProps.profile.twitterHandle || ""
        )
      );
      dispatch(twitterHandleFormInit());
    },
    changeRoute: pathname => {
      dispatch(routeChangedAlert(pathname));
    }
  };
};

const dispatchStateToProps = dispatch => ({
  dispatch
});

const Container = connect(
  mapStateToProps,
  dispatchStateToProps,
  mergePorps
)(Component);

export default Container;
