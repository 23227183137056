import React from 'react';
import PropTypes from 'prop-types';
import { getAwardLogo } from '../../../../modules/CoachWorld/+store/award';
import RingAwardLogo from '../../../../assets/images/the_ring_logo.png';
import RingAwardLargeLogo from '../../../../assets/images/the_ring_logo.png';
import { getProfile } from '../../../ActivateProfileMembership/ActivateProfileMembership.models';
import { useSelector } from 'react-redux';


const AwardLogo = props => {
  const {
    award,
    schoolAwardLogo,
    imageAlt,
    classes,
    isCoach,
    awardType,isNeedToDisPlayBiggerLogo,
    className,
    landingPageClassName
  } = props;
  const imgClassName = !!className ? className : ''
  const genericAwardLogo = !!landingPageClassName ? landingPageClassName : 'generic_award_logo';
  const uuid = award && award.ownerId;
  const profile = useSelector((state) => getProfile(state, uuid));
  const userSchool = profile && profile.schools;
  let awardSchool;


  athleteAwardType = !!awardType && awardType.value ? awardType.value : '';
  let imgSrc = getAwardLogo( schoolAwardLogo,award, isCoach,athleteAwardType, isNeedToDisPlayBiggerLogo );

  if (!imgSrc && !!award.schoolId && userSchool) {
    awardSchool = userSchool.find((item) => item.school_id === award.schoolId );
    imgSrc = (awardSchool && awardSchool.school) && awardSchool.school.school_award.award_logo;
  } else {
    awardSchool = userSchool && userSchool[0];
  }
  return (
    <div className='gold_fill_bg'>
      { !!imgSrc ?
        <img
          src={ imgSrc }
          className={classes}
          alt={ imageAlt || "award-img"}
        />
        :
        <div className={genericAwardLogo}>
        <img
          src={ isNeedToDisPlayBiggerLogo ? RingAwardLargeLogo  : RingAwardLogo }
          className={`${classes} `}
          alt={ imageAlt || "award-img"}
        />
        <img
          src={ (awardSchool && awardSchool.school) && awardSchool.school.school_award.school_logo }
          className={`award-inner-img ${imgClassName}`}
          alt={ imageAlt || "award-img"}
        />
        </div>
      }
    </div>
  )
}

AwardLogo.propTypes = {
  award: PropTypes.object.isRequired,
  schoolAwardLogo: PropTypes.object.isRequired,
  imageAlt: PropTypes.string,
  classes: PropTypes.string,
  isCoach:PropTypes.bool,
}

export default  AwardLogo;
