import React from 'react';
import PropTypes from 'prop-types';

const GeneralErrorsGroup = ({ rrfForm, style }) => {
  if (!rrfForm.$form) return <div />;
  const { valid } = rrfForm.$form;
  const { errors } = rrfForm.$form;
  return (
    <div
      className={[
        'UxCommon_Form_GeneralErrors',
        valid ? '' : 'show',
      ].join(' ')}
      style={style || {}}
    >
      <i className="icon-alert-icon" />
      <div className="UxCommon_Form_GeneralErrorMsgs">
        {
          errors && Object.values(errors).map((error, index) => (
            error
            ? <span key={index}>{error}</span>
            : ''
          ))
        }
        {
          (!errors || (Object.values(errors).length === 0)) &&
            <span key={0}>Please correct the errors above</span>
        }
      </div>
    </div>
  );
};
GeneralErrorsGroup.propTypes = {
  rrfForm: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default GeneralErrorsGroup;
