import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import ControlErrors from '../ControlErrors';

const FakeCheckbox = ({
                        selected, label, onClick, id, disabled = false
                      }) => {
  function handleClick() {
    onClick(id);
  }

  return (
    <button className={`CoachFakeCheckbox_Container ${disabled ? 'disabled' : ''}`} onClick={handleClick} type="button"
            disabled={disabled}>
      <div className="CoachFakeCheckbox"> {selected && <div className="CoachFakeCheckboxSelected"/>} </div>
      <div>{label}</div>
    </button>
  );
};

FakeCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.any,
  disabled: PropTypes.bool,
};

class SelectControl extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    errors: PropTypes.array,
    help: PropTypes.any,
    name: PropTypes.string,
    myOnChange: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    sortFunc: PropTypes.func,
    disabledOptions: PropTypes.array,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      options, placeholder, label, defaultValue,
    } = props;
    let placeholderValue = { options };
    if ((placeholder && placeholder !== '') || (label && label !== '')) {
      placeholderValue = {
        options: { '': (placeholder || label), ...options },
      };
    }
    const returnState = placeholderValue;
    if (defaultValue !== undefined && state.defaultValue === undefined
      && defaultValue !== state.defaultValue) {
      returnState.defaultValue = defaultValue;
      returnState.selected = defaultValue;
      if (Array.isArray(defaultValue)) {
        returnState.selectedList = defaultValue.map(ele => {
            if(ele == 'WaterPolo'){
                ele = 'Water Polo';
            }
            if(ele == 'CrossCountry'){
                ele = 'Cross Country';
            }
            return ele;
        });
      } else {
        returnState.selectedList = [defaultValue].map(ele => {
          if(ele == 'WaterPolo'){
              ele = 'Water Polo';
          }
          if(ele == 'CrossCountry'){
              ele = 'Cross Country';
          }
          return ele;
      });
      }
    }
    return returnState;
  }

  state = {
    showMenu: false,
    selected: '',
    selectedList: [],
    defaultValue: undefined,
  };

  onClick = () => {
    const { showMenu } = this.state;
    if (showMenu) {
      this.setState({ showMenu: false });
      document.removeEventListener('click', this.onOutsideClick);
    } else {
      this.setState({ showMenu: true });
      document.addEventListener('click', this.onOutsideClick);
    }
  }

  onOutsideClick = (event) => {
    if (!this.menu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.onOutsideClick);
      });
    }
  };

  onOptionClick = (event) => {
    const { myOnChange } = this.props;
    if (myOnChange) {
      myOnChange(event.target.dataset.value);
    }
    this.setState({
      selected: event.target.dataset.value,
      showMenu: false,
    }, () => {
      document.removeEventListener('click', this.onOutsideClick);
    });
  };

  setRef = (elem) => {
    this.menu = elem;
  };

  handleChange = (event) => {
    const { myOnChange } = this.props;
    if (myOnChange) {
      myOnChange(event.target.value);
    }
    this.setState({
      selected: event.target.value,
    });
  };

  handleSelected = (id) => {
    const { selectedList } = this.state;
    const index = selectedList.indexOf(id);
    if (index === -1) {
      selectedList.push(id);
    } else {
      selectedList.splice(index, 1);
    }
    this.setState({ selectedList: [...selectedList] });
  };

  handleMultiChange = (id) => {
    const { myOnChange } = this.props;
    if (id) {
      this.handleSelected(id);
      if (myOnChange) {
        myOnChange(this.state.selectedList);
      }
    }
  };

  render() {
    const {
      label, errors, help, name, style, disabled, multi, selectedSortBy, sortFunc, disabledOptions
    } = this.props;
    const {
      options, showMenu, selected, selectedList,
    } = this.state;
    let selectedLabel;
    if (name === 'sortBy') {
      selectedLabel = multi ?
        selectedList.map(ele => options[ele]).filter(ele => !!ele).join(', ') : options[selectedSortBy];
    } else {
      selectedLabel = multi ?
        selectedList.map(ele => options[ele]).filter(ele => !!ele).join(', ') : options[selected];
    }
    const sortedOptions = Object.entries(options);
    if (sortFunc) {
      sortedOptions.sort(sortFunc);
    } else {
      sortedOptions.sort((a, b) => {
        if (!a[0]) {
          return -1;
        }
        if (!b[0]) {
          return +1;
        }
        return 0;
      });
    }
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <div
        className={[
          'CoachSelectControl2',
          errors && errors.length ? 'error' : '',
          this.props.className].join(' ')}
        style={{ ...style }}
      >
        <select
          id={this.id}
          className="CoachSelectControl2_Btn hidden-lg-up hidden-md-down"
          value={selected}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          name={name}
          disabled={!!disabled}
          multiple={multi}
          // {...inputProps}
        >
          {
            sortedOptions.map(([optionValue, optionLabel], index) => (
              <option key={index} value={optionValue}>{optionLabel}</option>
            ))
          }
        </select>
        <div className="dropdown-up">
          <button
            className={`CoachSelectControl2_Btn ${disabledClass}`}
            onClick={this.onClick}
            type="button"
          >
            <div className="CoachSelectControl2_BtnInner">
              <div className={`CoachSelectControl2_Label ${disabledClass}`}>{label}</div>
              <div className="CoachSelectControl2_Selected">{selectedLabel}</div>
            </div>
            <Icon className={`CoachSelectControl2_Arrow ${disabledClass}`} iconName="down-chevron"/>
          </button>
          {
            !disabled && showMenu && (
              <div className="CoachSelectControl2_Options" ref={this.setRef}>
                {
                  sortedOptions.map(([optionValue, optionLabel], index) => {
                    const isSelected = !!selectedList.find(v => v === optionValue);
                    const isDisabled = disabledOptions ? !!disabledOptions.find(v => v === optionValue) : false;
                    const isPlaceholderField = index === 0 && (!optionValue || !optionLabel);
                    if (multi && !isPlaceholderField) {
                      return (<FakeCheckbox
                        selected={isSelected}
                        disabled={isDisabled}
                        label={optionLabel}
                        onClick={this.handleMultiChange}
                        id={optionValue}
                        key={optionValue}
                      />);
                    }
                    const disabledClass = isDisabled || optionValue === ''  ? 'disabled' : '';
                    return (
                      <button
                        // className={`CoachSelectControl2_Option ${optionValue === '' ? 'disabled' : ''}`}
                        className={`CoachSelectControl2_Option ${disabledClass}`}
                        key={optionValue}
                        data-value={optionValue}
                        onClick={this.onOptionClick}
                        type="button"
                        disabled={isPlaceholderField || disabledClass}
                        // disabled={isPlaceholderField}
                      >
                        {optionLabel}
                      </button>
                    );
                  })
                }
              </div>
            )
          }
        </div>
        {
          (!!help || (!!errors && !!errors.length)) && (
            <div className="CoachSelectControl2_Help">
              {
                !!help && typeof help === 'string' ? (
                  <div className="CoachTextControl_Help">{help}</div>
                ) : (
                  help
                )
              }
              <ControlErrors errors={errors}/>
            </div>
          )
        }
      </div>
    );
  }
}

export default SelectControl;
