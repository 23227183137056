import {createSelector} from 'reselect';
import queryString from 'query-string';

import {BankSlugs, masterFormater, repsMasterFormater} from '../combine';
import {vcScoreColumns, StandardFormat} from '../combine/combine.models';
import {getFilteredSearchedSortedSelectorCreator} from '../roster/roster.selectors';
import {isRealNumber, formatResult, calcDistancePartsFromInches} from '../../../../containers/UxCommon/Utils';

const getCurrentCombine = (state, ownProps) =>
  state.modules.coachWorld.combine.combine[ownProps.match.params.combineId];

export const getLeaderboardRosters = (state) =>
  state.modules.coachWorld.leaderboard.leaderboard ? state.modules.coachWorld.leaderboard.leaderboard.currentCombine.roster : [];

export const getLeaderboardAvg = (columns, data, selectedScoreType) => {
  const testAverage = [];
  columns.map((column, idx) => {
    data.map((athlete, index) => {
      const athleteTest = athlete.testResults[column.standardTestObjectId];
      if (athleteTest !== undefined) {
        const checkHasEntry = testAverage.filter(col => {
          return col.column === column.testCategoryCode;
        });
        let resultDisplay = 0;
        const format = column.standardFormat.code;
        if (athleteTest) {
          switch (selectedScoreType) {
            case "raw": {
              if (column.useReps && isRealNumber(athleteTest.multiRepMax) && column.standardUnitOfMeasureCode !== 'FEET-INCHES') {
                resultDisplay = parseInt(formatResult(athleteTest.multiRepMax, format, false));
              } else {
                const { result} = athleteTest;
                if(result){
                  if(column.standardUnitOfMeasureCode === 'FEET-INCHES'){
                    resultDisplay = result;
                  }else{
                    resultDisplay = masterFormater(format)(result);
                  }
                }
              }
              break;
            }
            case "relative": {
              const {relativeStrength} = athleteTest;
              resultDisplay = isRealNumber(relativeStrength) ? parseInt(relativeStrength) : 0;
              break;
            }
            case "rated": {
              const {ratedResult} = athleteTest;
              if (isRealNumber(ratedResult)) {
                resultDisplay = masterFormater("SCORE")(ratedResult);
              } else {
                resultDisplay = ratedResult;
              }
              break;
            }
            default:
              resultDisplay = 0;
          }
        }
        if (resultDisplay && resultDisplay !== 'N/A') {
          if (!checkHasEntry.length) {
            testAverage.push({
              column: column.testCategoryCode,
              avg: parseFloat(resultDisplay),
              count: 1
            });
          } else {
            testAverage.forEach(col => {
              if (col.column === column.testCategoryCode) {
                col.avg += parseFloat(resultDisplay);
                col.count++;
              }
            });
          }
        }
      }
    })
  });

  testAverage.forEach(col => {
    col.avg /= col.count;
  });

  return testAverage;
}

const getCurrentBank = (state, ownProps) => ownProps.match.params.bank;
const getSchool = (state, ownProps) => state.modules.coachWorld.coachWorld.schoolTeamsLists &&
  state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId] &&
  state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId][0] &&
  state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId][0]
    .team.school;

export const selectCurrentColumns = createSelector(
  getCurrentCombine,
  getCurrentBank,
  getSchool,
  (combine, bank, school) => {
    if (combine) {
      if (bank === BankSlugs.VC_SCORE) {
        return vcScoreColumns;
      } else if (bank === BankSlugs.STRENGTH) {
        if (!combine.columns.find(c => c.standardTestObjectId === 999)) {
          const stdFormat = new StandardFormat();
          stdFormat.code = school && school.isMetricSchool ? 'KG-INTEGER' : 'WEIGHT-POUNDS-INTEGER';
          combine.columns.push({
            standardTestObjectId: 999,
            displayStandardUnitOfMeasureCode: 'POUNDS',
            standardUnitOfMeasureCode: 'POUNDS',
            testCategoryCode: 'TOTAL',
            testCategoryName: 'Total',
            testBankCode: 'STRENGTH',
            standardFormat: stdFormat,
            abbreviation: 'TOTAL',
            best: 'max',
            comparable: true
          });
        }
      }
      return combine.columns.filter(c => BankSlugs[c.testBankCode] === bank);
    }
    return [];
  },
);

const getCurrentQuery = state => state.ui.app.routes.currentQuery;
export const selectSortBy = createSelector(
  getCurrentQuery,
  selectCurrentColumns,
  (currentQuery, columns) => {
    const parsedQuery = queryString.parse(currentQuery);
    const {sortBy} = parsedQuery;

    let stdTestId = Number(sortBy) || 0;
    if (Number.isNaN(stdTestId)) {
      stdTestId = 0;
    }
    if (columns.length) {
      if (stdTestId && columns.find(c => c.standardTestObjectId === stdTestId)) {
        return stdTestId;
      }
      return columns[0].standardTestObjectId;
    }
    return stdTestId;
  },
);

export const getSelectedEvent = createSelector(
  getCurrentQuery,
  selectCurrentColumns,
  (currentQuery, columns) => {
    const parsedQuery = queryString.parse(currentQuery);
    const { selectedEvent } = parsedQuery;
    let stdTestId = Number(selectedEvent) || 0;
    if (Number.isNaN(stdTestId)) {
      stdTestId = 0;
    }
    if (columns.length) {
      if (stdTestId && columns.find(c => c.standardTestObjectId === stdTestId)) {
        return stdTestId;
      }
      return null;
    }
    return stdTestId;
  },
);

const getScoreType = (state) => {
  const {currentQuery} = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const {score} = parsedQuery;
  return score || 'raw';
};

const getAppliedFilter = (state) => {
  const {currentQuery} = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  return !!parsedQuery.filters && parsedQuery.filters !== '[]';
}

const getAppliedBank = (state, ownProps) => {
  const { match } = ownProps;
  return match.params.bank;
}

export const sortStuff = (a, b, getKey, reverse) => {
  const ele1 = getKey(a);
  const ele2 = getKey(b);
  if (!Number.isNaN(ele1) && !Number.isNaN(ele2)) {
    if (reverse) {
      return ele2 - ele1;
    }
    return ele1 - ele2;
  } else if (!Number.isNaN(ele1)) {
    if ( ele1 < 0 )
    {
      return -1;
    }
    return -ele1;
  } else if (!Number.isNaN(ele2)) {
    if ( ele2 < 0 )
    {
      return 1;
    }
    return ele2;
  }
  return 0;
};

export const Sorters = {
  COUNT: (a, b, getKey) => sortStuff(a, b, getKey, true),
  POUNDS: (a, b, getKey) => sortStuff(a, b, getKey, true),
  SECONDS: (a, b, getKey) => sortStuff(a, b, getKey),
  INCHES: (a, b, getKey) => sortStuff(a, b, getKey, true),
  FEET: (a, b, getKey) => sortStuff(a, b, getKey, true),
  min: (a, b, getKey) => sortStuff(a, b, getKey),
  max: (a, b, getKey) => sortStuff(a, b, getKey, true),
};

export const getAthleteTestResult = (sortById, scoreType, useReps, isFeetInches = false) => (athlete) => {
  let value;
  switch (scoreType) {
    case 'raw':
      if (useReps) {
          value = athlete && athlete.testResults && sortById in athlete.testResults
          ? athlete.testResults[sortById].multiRepMax
          : null;
      } else {
        value = athlete && athlete.testResults && sortById in athlete.testResults
          ? athlete.testResults[sortById].result
          : null;
      }
      break;
    case 'relative':
      value = athlete && athlete.testResults && sortById in athlete.testResults
        ? athlete.testResults[sortById].relativeStrength
        : null;
      break;
    case 'rated':
      value = athlete && athlete.testResults && sortById in athlete.testResults
        ? athlete.testResults[sortById].ratedResult
        : null;
      break;
    default:
      value = athlete && athlete.testResults && sortById in athlete.testResults
        ? athlete.testResults[sortById].result
        : null;
  }

  const n = !isRealNumber(value) ? Number.NaN : Number(value);
  return n;
};

export const getCurrentCombineEventRankToSortAthletes = (sortById, scoreType) => (athlete) => {
  let rank;
  switch (scoreType) {
    case 'raw':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].multiRepMaxRank
        : null;
    break;
    case 'rated':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].ratedResultRank
        : null;
    break;
    case 'relative':
      rank = (athlete.testResults && sortById in athlete.testResults)
              ? !!athlete.testResults[sortById].relativeStrengthRank
              ? athlete.testResults[sortById].relativeStrengthRank
              : 5000
              :null
    break;
    default:
      null;
  }
  const n = !isRealNumber(rank) ? Number.NaN : Number(rank);
  return n;
}

export const getCurrentCombineEventRank = (sortById, scoreType) => (athlete) => {
  let rank;
  switch (scoreType) {
    case 'raw':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].multiRepMaxRank
        : null;
    break;
    case 'rated':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].ratedResultRank
        : null;
    break;
    case 'relative':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].relativeStrengthRank
        : null;
    break;
    default:
      null;
   }
   const n = !isRealNumber(rank) ? Number.NaN : Number(rank);
   return n;
}

export const getPreviousCombineEventRank = (sortById, scoreType) => (athlete) => {
  let rank;

  switch (scoreType) {
    case 'raw':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].leaderboardEventComparison
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result.multi_rep_rank_rank
        : null
        : null
        : null;
    break;
    case 'rated':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].leaderboardEventComparison
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result.rated_result_rank
        : null
        : null;
    break;
    case 'relative':
      rank = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].leaderboardEventComparison
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result.relative_strength_rank
        : null
        : null;
    break;
    default:
      null;
  }
  // const n = !isRealNumber(rank) ? Number.NaN : Number(rank);
  return rank;
}

export const getAthleteOldEventResult = (sortById, scoreType, useReps, isFeetInches = false) => (athlete) => {
  let value;
  switch (scoreType) {
    case 'raw':
      if (useReps) {
        // value = athlete.testResults && sortById in athlete.testResults
        //   ? athlete.testResults[sortById].leaderboardEventComparison.test_result.multi_rep_max
        //   : null;
          value = (athlete.testResults && sortById in athlete.testResults)
            ? athlete.testResults[sortById].leaderboardEventComparison
              ? athlete.testResults[sortById].leaderboardEventComparison.test_result
                ? athlete.testResults[sortById].leaderboardEventComparison.test_result.multi_rep_max
                : null
              : null
            : null;
      } else {
        value = ( athlete.testResults && sortById in athlete.testResults )
          ? athlete.testResults[sortById].leaderboardEventComparison && athlete.testResults[sortById].leaderboardEventComparison.test_result
          ? athlete.testResults[sortById].leaderboardEventComparison.test_result.result
          : null
          : null;
      }
      break;
    case 'relative':
      // value = athlete.testResults && sortById in athlete.testResults
      //   ? athlete.testResults[sortById].leaderboardEventComparison.test_result.relative_strength
      //   : null;
      value = ( athlete.testResults && sortById in athlete.testResults )
        ? athlete.testResults[sortById].leaderboardEventComparison
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result.relative_strength
        : null
        : null;
      break;
    case 'rated':
        value = ( athlete.testResults && sortById in athlete.testResults )
          ? athlete.testResults[sortById].leaderboardEventComparison
          ? athlete.testResults[sortById].leaderboardEventComparison.test_result.rated_result
          : null
          : null;
      break;
    default:
      value = athlete.testResults && sortById in athlete.testResults
        ? athlete.testResults[sortById].leaderboardEventComparison.test_result.result
        : null;
  }

  const n = !isRealNumber(value) ? Number.NaN : Number(value);

  return n;
};

export const getPreviousCombineTotalAthletesRank = (prevSortedAthletes, sortById) => {
  let athleteRankArrary = [];
  let lastAthleteTotalResult = 0;
  let currentTotalResultRank = 1;
  let totalResultRank;

  prevSortedAthletes.forEach((athlete, index) => {
    if ( lastAthleteTotalResult === Math.round(athlete.testResults[sortById].result) ) {
      totalResultRank = currentTotalResultRank;
      athleteRankArrary.push({
        athleteId: athlete.userId,
        totalResultRank
      });
    } else {
      totalResultRank = index + 1;
      currentTotalResultRank = index + 1;
      athleteRankArrary.push({
        athleteId: athlete.userId,
        totalResultRank
      });
    }
    lastAthleteTotalResult = Math.round(athlete.testResults[sortById].result);

  });

  return athleteRankArrary;
}

const getPreviousLeaderboardCombine = state => (state.modules.coachWorld.leaderboard.leaderboard
  ? state.modules.coachWorld.leaderboard.leaderboard.previousCombine
  : null);

const getCurrentLeaderboardAthletes = state => (state.modules.coachWorld.leaderboard.leaderboard
  ? state.modules.coachWorld.leaderboard.leaderboard.currentCombine.roster
  : []);

const getPreviousLeaderboardAthletes = state => (state.modules.coachWorld.leaderboard.leaderboard
&& state.modules.coachWorld.leaderboard.leaderboard.previousCombine
  ? state.modules.coachWorld.leaderboard.leaderboard.previousCombine.roster
  : []);

export const selectFilteredLeaderboardAthletes = (
  getFilteredSearchedSortedSelectorCreator(getCurrentLeaderboardAthletes)
);

export const selectFilteredPrevLeaderboardAthletes = (
  getFilteredSearchedSortedSelectorCreator(getPreviousLeaderboardAthletes)
);


const getTestCategories = state => state.modules.coachWorld.combine.standardTestCats.organizedCats;

export const selectLeaderBoard = createSelector(
  getCurrentCombine,
  selectFilteredLeaderboardAthletes,
  selectFilteredPrevLeaderboardAthletes,
  getPreviousLeaderboardCombine,
  selectCurrentColumns,
  selectSortBy,
  getScoreType,
  getTestCategories,
  getAppliedFilter,
  getAppliedBank,
  (
    currentCombine,
    currentAthletes,
    prevAthletes,
    previousLeaderboardCombine,
    columns,
    sortById,
    scoreType,
    testCats,
    isAppliedFilter,
    bank
  ) => {
    if (columns.length && currentAthletes.length && testCats) {
      if (sortById === 999) {

        const sortedAthletes = currentAthletes.slice();
        const sortColumn = columns.find(c => c.standardTestObjectId === sortById);
        const testCategory = testCats[sortColumn.testCategoryCode];
        let sorter;
        if (testCategory && scoreType === 'raw' && testCategory.best) {
          sorter = Sorters[testCategory.best];
        } else {
          sorter = Sorters.max;
        }

        sortedAthletes.sort((a, b) =>
          sorter(a, b, getAthleteTestResult(sortById, scoreType, sortColumn.useReps)));

        let prevSortedAthletes = [];
        if (previousLeaderboardCombine) {
          prevSortedAthletes = prevAthletes.slice();
          prevSortedAthletes.sort((a, b) =>
            sorter(a, b, getAthleteTestResult(sortById, scoreType, sortColumn.useReps)));
        }
        // Todo: calculate ranks and watch out for tied scores
        const previousAthleteTotalRank = getPreviousCombineTotalAthletesRank(prevSortedAthletes, 999);
        let lastAthleteTotalResult = 0;
        let currentTotalResultRank = 1;
        let totalResultRank;
        sortedAthletes.forEach((athlete, index) => {
          const oldIndex = prevSortedAthletes
            .findIndex(oldAthlete =>
              oldAthlete.userId === athlete.userId);
          const previousCombineTotalAthlete = previousAthleteTotalRank.find((element) => element.athleteId === athlete.userId);

          if ( lastAthleteTotalResult === Math.round(athlete.testResults[sortById].result) && Math.round(athlete.testResults[sortById].result) !== 0 && athlete.testResults[sortById].result !== 'N/A') {
            totalResultRank = currentTotalResultRank;
          } else {
            totalResultRank = index + 1;
            currentTotalResultRank = index + 1;
          }
          lastAthleteTotalResult = Math.round(athlete.testResults[sortById].result);
          athlete.improvementClass = '';
          if (oldIndex !== -1) {
            const oldTestResult = getAthleteTestResult(
              sortById,
              scoreType,
              sortColumn.useReps,
            )(prevSortedAthletes[oldIndex]);
            const testResult = getAthleteTestResult(
              sortById,
              scoreType,
              sortColumn.useReps,
            )(athlete);
            if (!Number.isNaN(oldTestResult) && !Number.isNaN(testResult) && oldTestResult !== 0 && testResult !== 0) {
              athlete.rankChange = Math.abs(totalResultRank - previousCombineTotalAthlete.totalResultRank);
              if (totalResultRank - previousCombineTotalAthlete.totalResultRank !== 0) {
                athlete.rankChangeDirection = index - oldIndex < 0 ? 'up' : 'down';
              }
              athlete.filterRank = totalResultRank;
              athlete.rank = totalResultRank;
              athlete.oldValue = oldTestResult;
              athlete.difference = testResult - oldTestResult;
              if (sortColumn && sortColumn.best === 'min'
                && ['raw', 'relative'].includes(scoreType)
                && sortColumn.comparable) {
                if (athlete.difference < 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference > 0) {
                  athlete.improvementClass = 'down';
                }
              } else if (sortColumn && sortColumn.best === 'max'
                && ['raw', 'relative'].includes(scoreType)
                && sortColumn.comparable) {
                if (athlete.difference > 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference < 0) {
                  athlete.improvementClass = 'down';
                }
              } else if (sortColumn && scoreType === 'rated' && sortColumn.comparable) {
                if (athlete.difference > 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference < 0) {
                  athlete.improvementClass = 'down';
                }
              } else {
                athlete.improvementClass = '';
              }
            } else {
              athlete.filterRank = totalResultRank;
              athlete.rank = totalResultRank;
              athlete.oldValue = null;
              athlete.difference = null;
              athlete.rankChange = 0;
              athlete.rankChangeDirection = null;
              athlete.improvementClass = '';

            }
          } else {
            athlete.filterRank = totalResultRank;
            athlete.rank = totalResultRank;
            athlete.rankChange = 0;
            athlete.oldValue = null;
            athlete.difference = null;
            athlete.rankChangeDirection = null;
            athlete.improvementClass = '';
          }
          athlete.best = (testCategory && testCategory.best) || 'max';
        });
        return sortedAthletes;
        //end of the 999 category of tests
      } else {
        const sortedAthletes = currentAthletes.slice();
        const sortColumn = columns.find(c => c.standardTestObjectId === sortById);
        const testCategory = testCats[sortColumn.testCategoryCode];
        let sorter;
        if (testCategory && scoreType === 'raw' && testCategory.best) {
          sorter = Sorters['min'];
        } else {
          sorter = Sorters['min'];
        }
        sortedAthletes.sort((a, b) =>
          sorter(a, b, getCurrentCombineEventRankToSortAthletes(sortById, scoreType, sortColumn.useReps)));

        let prevSortedAthletes = [];
        if (previousLeaderboardCombine) {
          prevSortedAthletes = prevAthletes.slice();
          prevSortedAthletes.sort((a, b) =>
            sorter(a, b, getAthleteTestResult(sortById, scoreType, sortColumn.useReps)));
        }
        // console.log(sortedAthletes, 'sortedAthletes');
        let lastAthleteTotalResult = 0;
        let currentTotalResultRank = 1;
        let totalResultRank;
        // Todo: calculate ranks and watch out for tied scores
        sortedAthletes.forEach((athlete, index) => {
          const currentIndex = index + 1;
          const oldIndex = prevSortedAthletes
            .findIndex(oldAthlete =>
              oldAthlete.userId === athlete.userId);

          const currentCombineEventRank = getCurrentCombineEventRank(
            sortById,
            scoreType,
            sortColumn.useReps,
          )(athlete);

          const PreviousCombineEventRank = getPreviousCombineEventRank(
            sortById,
            scoreType,
            sortColumn.useReps,
          )(athlete);

          let athleteTestResult;
          if (bank === 'strength') {
            if (!!athlete.testResults[sortById]) {
              athleteTestResult = scoreType === 'raw' ? Math.round(athlete.testResults[sortById].multiRepMax) : athlete.testResults[sortById].relativeStrength;
            }
          } else {
            if (!!athlete.testResults[sortById]) {
                athleteTestResult = athlete.testResults[sortById].result;
            }
          }
          if (athlete.userId === 'ce2c42ae-90d4-4ab5-bffc-32662a158df9') {
            console.log(athleteTestResult, 'athleteTestResult athleteTestResult' , typeof athleteTestResult);
          }
          if (!!athlete.testResults[sortById]
              && lastAthleteTotalResult === athleteTestResult
              && athleteTestResult !== 0
              && athleteTestResult !== '0'
              && athleteTestResult !== 'N/A'
              && athleteTestResult !== null) {
            totalResultRank = currentTotalResultRank;
          } else {
            totalResultRank = index + 1;
            currentTotalResultRank = index + 1;
          }
          lastAthleteTotalResult = !!athlete.testResults[sortById] ?  athleteTestResult : 0;

          athlete.improvementClass = '';
          if (PreviousCombineEventRank !== -1) {
            let oldTestResult = null;
            if (sortById === 999) {
              oldTestResult = getAthleteTestResult(
                sortById,
                scoreType,
                sortColumn.useReps,
              )(prevSortedAthletes[oldIndex]);
            } else {
              oldTestResult = getAthleteOldEventResult(
                sortById,
                scoreType,
                sortColumn.useReps,
              )(athlete);
            }

            const testResult = getAthleteTestResult(
              sortById,
              scoreType,
              sortColumn.useReps,
            )(athlete);
            if (!Number.isNaN(oldTestResult) && !Number.isNaN(testResult) && oldTestResult !== 0 && testResult !== 0) {
              const rankChange = (!Number.isNaN(PreviousCombineEventRank) && !Number.isNaN(currentCombineEventRank)) && PreviousCombineEventRank
              ? currentCombineEventRank - PreviousCombineEventRank
              : null;

              if (rankChange && rankChange !== 0) {
                athlete.rankChangeDirection = rankChange < 0 ? 'up' : 'down';
              }
              athlete.filterRank = isAppliedFilter ? totalResultRank : null;
              athlete.rank = currentCombineEventRank;
              athlete.rankChange = Math.abs(rankChange);
              athlete.oldValue = oldTestResult;
              athlete.difference = testResult - oldTestResult;
              if (sortColumn && sortColumn.best === 'min'
                && ['raw', 'relative'].includes(scoreType)
                && sortColumn.comparable) {
                if (athlete.difference < 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference > 0) {
                  athlete.improvementClass = 'down';
                }
              } else if (sortColumn && sortColumn.best === 'max'
                && ['raw', 'relative'].includes(scoreType)
                && sortColumn.comparable) {
                if (athlete.difference > 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference < 0) {
                  athlete.improvementClass = 'down';
                }
              } else if (sortColumn && scoreType === 'rated' && sortColumn.comparable) {
                if (athlete.difference > 0) {
                  athlete.improvementClass = 'up';
                } else if (athlete.difference < 0) {
                  athlete.improvementClass = 'down';
                }
              } else {
                athlete.improvementClass = '';
              }
            } else {
              athlete.filterRank = isAppliedFilter ? totalResultRank : null;
              athlete.rank = currentCombineEventRank;;
              athlete.oldValue = null;
              athlete.difference = null;
              athlete.rankChange = 0;
              athlete.rankChangeDirection = null;
              athlete.improvementClass = '';

            }
          } else {
            athlete.rankChange = 0;
            athlete.oldValue = null;
            athlete.difference = null;
            athlete.rankChangeDirection = null;
            athlete.improvementClass = '';
          }
          athlete.best = (testCategory && testCategory.best) || 'max';
        });
        return sortedAthletes;
      }
    }
    return [];
  },
);

export default 'leaderboard.selectors.js';
