import { combineReducers } from 'redux';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { MERGE_SIGN_IN_FORM_INIT } from '../../../actions/ui/welcome';

import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { cognitoUsernameError } from '../utils/errors';
import { USER_GET_PROFILE_SUC } from '../../../actions/data/user/profile';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case USER_GET_PROFILE_SUC:
      return true;
    case MERGE_SIGN_IN_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case MERGE_SIGN_IN_FORM_INIT:
      return '';
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.mergeLogin') {
        return cognitoUsernameError(action.error, action);
      }
      return state;
    default:
      return state;
  }
};

const msg = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.resetPassword') {
        return 'Your password has been reset!';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
  msg,
});

export default reducer;
