import { combineReducers } from 'redux';

import {
  asyncLoadingMsgReducer,
  asyncLoadingReducer,
} from '../utils';
import { USER_GET_PROFILE_SUC } from '../../../actions/data/user';
import { EP_DELETE_MODEL } from '../../../actions/ui/uxEditProfile';

const loadingMsgMoreCases = {};
loadingMsgMoreCases[USER_GET_PROFILE_SUC] = () => '';

const loadingMoreCases = {};
loadingMoreCases[USER_GET_PROFILE_SUC] = () => false;

const reducer = combineReducers({
  loading: asyncLoadingReducer(EP_DELETE_MODEL, true, loadingMoreCases),
  loadingMsg: asyncLoadingMsgReducer(
    EP_DELETE_MODEL,
    'Loading your account information',
    'Deleting you account',
  ),
});

export default reducer;
