import { connect } from 'react-redux';
import Component from '../../components/App/PrivateRoute/PrivateRoute';

const mapStateToProps = state => ({
  isAuthenticated: !!state.data.cognito.token,
  notSure: state.data.cognito.authenticating,
  authMsg: state.data.cognito.authMsg,
});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
