import {
  UserServiceUrl,
  AppUrl,
  PhotoServiceUrl,
  SportsServiceUrl,
  LeaderboardServiceUrl,
} from './env-urls';

export const sportsTables = {
  sports: 'sports',
  standardTestCategories: 'standardTestCategories',
  standardUnitOfMeasures: 'standardUnitOfMeasures',
  standardTestBanks: 'standardTestBanks',
  standardTestSubbanks: 'standardTestSubbanks',
  standardTestObjects: 'standardTestObjects',
  tests: 'tests',
  testResults: 'testResults',
};

const Urls = {
  app: () => AppUrl,
  getprofileData: (uuid, user, sports, schoolId) =>
    `${SportsServiceUrl}/profile/${uuid}/${user}/${sports}/${schoolId}`,
  getUserTeams: (uuid) => `${SportsServiceUrl}/school/team/user?uuid=${uuid}`,
  userSchoolEnrolled: (uuid) => `${SportsServiceUrl}/school/gibberish/${uuid}`,
  canCoachEdit: (uuid) => `${SportsServiceUrl}/coachEdit/${uuid}`,
  isCoachOfAthlete: (uuid, playerUuid) =>
    `${SportsServiceUrl}/userRoleServer/${uuid}/${playerUuid}`,
  profile: (uuid) =>
    uuid
      ? `${SportsServiceUrl}/profiles/${uuid}`
      : `${SportsServiceUrl}/profiles`,
  updateRosterView: (uuid, cardView) => `${SportsServiceUrl}/rosterView/${uuid}/${cardView}`,
  checkCardView: (uuid) => `${SportsServiceUrl}/checkCardView/${uuid}`,
  updateProfile: (uuid) =>
    `${SportsServiceUrl}/onboardingFromTwo/profiles/${uuid}`,
  updateProfileFinalForm: (uuid) =>
    `${SportsServiceUrl}/onboardingFormThree/profiles/${uuid}`,
  profileDuplicates: (uuid) =>
    `${SportsServiceUrl}/profiles/${uuid}/duplicates`,
  // profileInvited: () => `${UserServiceUrl}/profiles/inviteAccept`,
  profileInvited: () => `${SportsServiceUrl}/profiles/invite-accept`,
  mergeProfiles: () => `${SportsServiceUrl}/profiles/inviteMerge`,
  mergeWithoutInvite: () => `${SportsServiceUrl}/profiles/mergeWithoutInvite`,
  gridMerge: () => `${UserServiceUrl}/profiles/gridMerge`,
  createBulkProfilesData: () => `${SportsServiceUrl}/profileData/bulkadd`,
  createProfileData: () => `${SportsServiceUrl}/profileData`,
  updateProfileEmail: (userId) =>
    `${SportsServiceUrl}/profileData/updateUserEmail/${userId}`,
  createInviteAthlete: () => `${SportsServiceUrl}/profileData/invite`,
  getInviteLink: (uuid, sportId, schoolId) =>
    `${SportsServiceUrl}/profileData/profile/${uuid}/sport/${sportId}/school/${schoolId}/getInviteLink`,
  getInviteLinkWithoutSchool: (uuid, sportId) =>
    `${SportsServiceUrl}/profileData/profile/${uuid}/sport/${sportId}/getInviteLink`,
  getEmailTemplate: (schoolId) =>
    `${SportsServiceUrl}/school/${schoolId}/emailSections`,
  profileByCoach: (uuid) => `${SportsServiceUrl}/profileData/${uuid}`,
  sports: (table, id) =>
    id ? `${SportsServiceUrl}/${table}/${id}` : `${SportsServiceUrl}/${table}`,
  school: (uuid) => `${SportsServiceUrl}/user/schools/${uuid}`,
  latestSchool: (uuid) => `${SportsServiceUrl}/user/schools/latest/${uuid}`,
  enrolledSchool: (uuid) => `${SportsServiceUrl}/user/schools/enrolled/${uuid}`,
  schoolAwardLogo: (schoolId) =>
    `${SportsServiceUrl}/school/award-logo/${schoolId}`,

  getProfileByEmail: (email) => `${UserServiceUrl}/profile/${email}`,
  getDuplicateProfileByEmail: (email) =>
    `${SportsServiceUrl}/${email}/duplicateProfile`,
  getDuplicateProfile: (userId, email) =>
    `${SportsServiceUrl}/${userId}/${email}/duplicateProfile`,
  guestProfileByEmail: (email) => `${SportsServiceUrl}/profile/${email}`,
  privacy: {
    // As of 3rd of May, get request is not being used. privacy values are pulled from profile obj.
    get: (uuid, privacyType) =>
      `${UserServiceUrl}/user/privacycheck/${uuid}/${privacyType}`,
    save: (toggle, privacyType) =>
      `${UserServiceUrl}/user/privacy/${privacyType}/${toggle}`,
    saveCoach: (toggle, privacyType, uuid) =>
      `${SportsServiceUrl}/profileData/privacy/${privacyType}/${uuid}/${toggle}`,
    saveCoachLeaderboardPrivacy: (toggle, combineId) =>
      `${SportsServiceUrl}/leaderboard/privacy/${combineId}/${toggle}`,
  },
  tests: {
    post: () => `${SportsServiceUrl}/tests`,
    put: (id) => `${SportsServiceUrl}/tests/${id}`,
  },
  combineAction: {
    post: () => `${SportsServiceUrl}/combine/bulkTestResultsUpload`,
    delete: () => `${SportsServiceUrl}/combine/bulkTestResultsDelete`,
  },
  testResults: {
    post: () => `${SportsServiceUrl}/testResults`,
    put: (id) => `${SportsServiceUrl}/testResults/${id}`,
    delete: (id) => `${SportsServiceUrl}/testResults/${id}`,
  },
  combineStandardTestCategories: {
    toggleAllLaser: (combineId, standardTestCategoryId) =>
      `${SportsServiceUrl}/combineStandardTestCategories/combine/${combineId}/standardTestCategory/${standardTestCategoryId}`,
    addTestCategory: (combineId) =>
      `${SportsServiceUrl}/combineStandardTestCategories/combine/${combineId}/standardTestCategories/create`,
    deleteTestCategory: (combineId) =>
      `${SportsServiceUrl}/combineStandardTestCategories/combine/${combineId}/standardTestCategories/delete`,
    get: (combineId) =>
      `${SportsServiceUrl}/combineStandardTestCategories/combine/${combineId}/standardTestCategories`,
  },
  awards: {
    get: (uuid, id) => `${SportsServiceUrl}/user/${uuid}/awards/${id}`,
    getAll: (uuid) => `${SportsServiceUrl}/user/${uuid}/awards`,
    post: (uuid, isCoach = false) =>
      isCoach
        ? `${SportsServiceUrl}/playerAward/${uuid}`
        : `${SportsServiceUrl}/user/${uuid}/awards`,
    put: (uuid, id, isCoach = false) =>
      isCoach
        ? `${SportsServiceUrl}/playerAward/${uuid}/${id}`
        : `${SportsServiceUrl}/user/${uuid}/awards/${id}`,
    delete: (uuid, id, isCoach = false) =>
      isCoach
        ? `${SportsServiceUrl}/playerAward/${uuid}/${id}`
        : `${SportsServiceUrl}/user/${uuid}/awards/${id}`,
    endorse: {
      post: (uuid, id) =>
        `${SportsServiceUrl}/user/${uuid}/awards/${id}/endorse`,
      delete: (uuid, id) =>
        `${SportsServiceUrl}/user/${uuid}/awards/${id}/endorse`,
    },
    favorites: {
      post: (userId, awardId, sportId) =>
        `${SportsServiceUrl}/user/${userId}/awards/${awardId}/sport/${sportId}`,
      delete: (userId, awardId, sportId) =>
        `${SportsServiceUrl}/user/${userId}/awards/${awardId}/sport/${sportId}`,
    },
  },
  albums: {
    get: (albumId) => `${PhotoServiceUrl}/albums/${albumId}`,
    getAll: (userId) =>
      `${PhotoServiceUrl}/albums${userId ? `?userId=${userId}` : ''}`,
    post: () => `${PhotoServiceUrl}/albums`,
    put: (albumId) => `${PhotoServiceUrl}/albums/${albumId}`,
    delete: (albumId, uuid = null) =>
      uuid
        ? `${PhotoServiceUrl}/albums/${albumId}/${uuid}`
        : `${PhotoServiceUrl}/albums/${albumId}`,
    photos: {
      post: (albumId) => `${PhotoServiceUrl}/albums/${albumId}/photos`,
      put: (albumId, photoId) =>
        `${PhotoServiceUrl}/albums/${albumId}/photos/${photoId}`,
      delete: (albumId, photoId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/albums/${albumId}/photos/${photoId}/${uuid}`
          : `${PhotoServiceUrl}/albums/${albumId}/photos/${photoId}`,
    },
    videos: {
      post: (albumId, videoId) =>
        `${PhotoServiceUrl}/albums/${albumId}/videos/${videoId}`,
      put: (albumId, videoId) =>
        `${PhotoServiceUrl}/albums/${albumId}/videos/${videoId}`,
      delete: (albumId, videoId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/albums/${albumId}/videos/${videoId}/${uuid}`
          : `${PhotoServiceUrl}/albums/${albumId}/videos/${videoId}`,
    },
  },
  photos: {
    get: (photoId) => `${PhotoServiceUrl}/photos/${photoId}`,
    getAll: (userId) => `${PhotoServiceUrl}/photos?userId=${userId}`,
    post: () => `${PhotoServiceUrl}/photos`,
    put: (photoId) => `${PhotoServiceUrl}/photos/${photoId}`,
    delete: (photoId, uuid = null) =>
      uuid
        ? `${PhotoServiceUrl}/photos/${photoId}/${uuid}`
        : `${PhotoServiceUrl}/photos/${photoId}`,
    tags: {
      post: (photoId) => `${PhotoServiceUrl}/photos/${photoId}/tags`,
      delete: (photoId, tagId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/photos/${photoId}/tags/${tagId}/${uuid}`
          : `${PhotoServiceUrl}/photos/${photoId}/tags/${tagId}`,
    },
    favorites: {
      post: (photoId, sportId) =>
        `${PhotoServiceUrl}/photos/${photoId}/sport/${sportId}`,
      delete: (photoId, sportId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/photos/${photoId}/sport/${sportId}/${uuid}`
          : `${PhotoServiceUrl}/photos/${photoId}/sport/${sportId}`,
    },
    getFavorites: (userId, sportId) =>
      `${PhotoServiceUrl}/photos?userId=${userId}&favorite=true&sport_id=${sportId}`,
  },
  photoUploaded: (photoId) => `${PhotoServiceUrl}/photouploaded/${photoId}`,
  videos: {
    get: (videoId) => `${PhotoServiceUrl}/video/${videoId}`,
    getAll: (userId) => `${PhotoServiceUrl}/videos?userId=${userId}`,
    post: () => `${PhotoServiceUrl}/video`,
    put: (videoId) => `${PhotoServiceUrl}/video/${videoId}`,
    delete: (videoId, uuid = null) =>
      uuid
        ? `${PhotoServiceUrl}/video/${videoId}/${uuid}`
        : `${PhotoServiceUrl}/video/${videoId}`,
    getFavorites: (userId, sportId) =>
      `${PhotoServiceUrl}/videos?userId=${userId}&favorite=true&sport_id=${sportId}`,
    tags: {
      post: (videoId) => `${PhotoServiceUrl}/videos/${videoId}/tags`,
      delete: (videoId, tagId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/videos/${videoId}/tags/${tagId}/${uuid}`
          : `${PhotoServiceUrl}/videos/${videoId}/tags/${tagId}`,
    },
    favorites: {
      post: (videoId, sportId) =>
        `${PhotoServiceUrl}/video/${videoId}/sport/${sportId}`,
      delete: (videoId, sportId, uuid = null) =>
        uuid
          ? `${PhotoServiceUrl}/video/${videoId}/sport/${sportId}/${uuid}`
          : `${PhotoServiceUrl}/video/${videoId}/sport/${sportId}`,
    },
    meta: (url) => encodeURI(`${PhotoServiceUrl}/videometa?url=${url}`),
  },
  tags: {
    get: (tagId) => `${PhotoServiceUrl}/tags/${tagId}`,
    getAll: (userId) => `${PhotoServiceUrl}/tags?userId=${userId}`,
    post: () => `${PhotoServiceUrl}/tags`,
    put: (tagId) => `${PhotoServiceUrl}/tags/${tagId}`,
    delete: (tagId, uuid = null) =>
      uuid
        ? `${PhotoServiceUrl}/tags/${tagId}/${uuid}`
        : `${PhotoServiceUrl}/tags/${tagId}`,
  },
  videoUploaded: (videoId) => `${PhotoServiceUrl}/videouploaded/${videoId}`,
  transcript: {
    get: (athleteId, sportId) =>
      `${PhotoServiceUrl}/athlete/${athleteId}/sport/${sportId}/documents`,
    post: (athleteId, sportId) =>
      `${PhotoServiceUrl}/athlete/${athleteId}/sport/${sportId}/documents`,
    delete: (athleteId, sportId, transcriptId) =>
      `${PhotoServiceUrl}/athlete/${athleteId}/sport/${sportId}/documents/${transcriptId}`,
  },
  schedule: {
    get: (schoolTeamId) =>
      `${PhotoServiceUrl}/schoolTeam/${schoolTeamId}/schedules`,
    post: (schoolTeamId) =>
      `${PhotoServiceUrl}/schoolTeam/${schoolTeamId}/schedules`,
    delete: (schoolTeamId, scheduleId) =>
      `${PhotoServiceUrl}/schoolTeam/${schoolTeamId}/schedules/${scheduleId}`,
    uploaded: (schoolTeamId, scheduleId) =>
      `${PhotoServiceUrl}/schoolTeam/${schoolTeamId}/schedules/${scheduleId}/uploaded`,
  },
  transcriptUploaded: (athleteId, sportId, transcriptId) =>
    `${PhotoServiceUrl}/athlete/${athleteId}/sport/${sportId}/documents/${transcriptId}/uploaded`,
  userSports: (uuid, sportId) =>
    sportId
      ? `${UserServiceUrl}/users/${uuid}/sports/${sportId}`
      : `${UserServiceUrl}/users/${uuid}/sports`,
  personalRecords: (uuid) => `${SportsServiceUrl}/personalRecords/${uuid}`,
  profileData: (uuid, sportId) =>
    `${SportsServiceUrl}/profileData/${uuid}?sportId=${sportId}`,
  userTeamsAndDetails: (uuid, sportId) =>
    `${SportsServiceUrl}/user/all/${uuid}/${sportId}`,
  playerData: (uuid, sportId, categoryId) =>
    `${SportsServiceUrl}/playerData/${uuid}?sportId=${sportId}&categoryId=${categoryId}`,
  playerPersonalRecords: (uuid, sport) =>
    `${SportsServiceUrl}/combine-records/${uuid}/${sport}`,
  playerTestCategories: (uuid) =>
    `${SportsServiceUrl}/playerData/${uuid}/standardTestCategories`,
  testsPerCategory: (uuid, categoryId) =>
    `${SportsServiceUrl}/playerData/${uuid}/category/${categoryId}`,
  seasons: (userId, sportId) =>
    `${SportsServiceUrl}/seasons?userId=${userId}&sportId=${sportId}`,
  season: (seasonId) =>
    `${SportsServiceUrl}/seasons${seasonId ? `/${seasonId}` : ''}`,
  userStats: (seasonId, userId) =>
    `${SportsServiceUrl}/seasons/${seasonId}/userStats?userId=${userId}`,
  userStat: (seasonId, userStatId) =>
    `${SportsServiceUrl}/seasons/${seasonId}/userStats${
      userStatId ? `/${userStatId}` : ''
    }`,
  standardPositions: (sportId) =>
    `${SportsServiceUrl}/sports/${sportId}/standardPositions`,
  standardStatCategories: (sportId) =>
    `${SportsServiceUrl}/sports/${sportId}/standardStatCategories`,
  standardStats: (sportId) =>
    `${SportsServiceUrl}/sports/${sportId}/standardStats`,
  standardStatsFormat: () => `${SportsServiceUrl}/standardStatFormats`,
  standardStatsByCategory: (sportId, statCategoryId) =>
    `${SportsServiceUrl}/sports/${sportId}/standardStatCategories/${statCategoryId}/standardStats`,
  standardStatPositions: (sportId) =>
    `${SportsServiceUrl}/sports/${sportId}/standardStatPositions`,
  playerStats: (uuid, sportId) =>
    `${SportsServiceUrl}/playerStats/${uuid}?sportId=${sportId}`,
  teams: {
    get: (teamId) => `${SportsServiceUrl}/teams/${teamId}`,
    getAll: () => `${SportsServiceUrl}/teams`,
    post: () => `${SportsServiceUrl}/teams`,
    put: (teamId) => `${SportsServiceUrl}/teams/${teamId}`,
    delete: (teamId) => `${SportsServiceUrl}/teams/${teamId}`,
  },
  instagram: {
    get: (name) => `${SportsServiceUrl}/instagram/${name}`,
    put: (name) =>
      name
        ? `${SportsServiceUrl}/instagram/${name}`
        : `${SportsServiceUrl}/instagram`,
    post: (name) =>
      name
        ? `${SportsServiceUrl}/instagram/${name}`
        : `${SportsServiceUrl}/instagram`,
  },
  vanity: {
    get: (name) => `${SportsServiceUrl}/vanity/${name}`,
    put: (name) =>
      name
        ? `${SportsServiceUrl}/vanity/${name}`
        : `${SportsServiceUrl}/vanity`,
    post: (name) =>
      name
        ? `${SportsServiceUrl}/vanity/${name}`
        : `${SportsServiceUrl}/vanity`,
  },
  twitterHandle: {
    get: (name) => `${SportsServiceUrl}/twitterHandle/${name}`,
    put: (name) =>
      name
        ? `${SportsServiceUrl}/twitterHandle/${name}`
        : `${SportsServiceUrl}/twitterHandle`,
    post: (name) =>
      name
        ? `${SportsServiceUrl}/twitterHandle/${name}`
        : `${SportsServiceUrl}/twitterHandle`,
  },
  headCoach: {
    put: (id) => `${SportsServiceUrl}/headCoach/${id}`,
    delete: (id) => `${SportsServiceUrl}/headCoach/${id}`,
    post: () => `${SportsServiceUrl}/headCoach`,
  },
  twitterLink: {
    post: () => `${SportsServiceUrl}/twitterLink`,
    delete: (id) => `${SportsServiceUrl}/twitterLink/${id}`,
  },
  recruitingCoordinator: {
    put: (id) => `${SportsServiceUrl}/recruitingCoordinator/${id}`,
    delete: (id) => `${SportsServiceUrl}/recruitingCoordinator/${id}`,
    post: () => `${SportsServiceUrl}/recruitingCoordinator`,
  },
  schools: {
    getAll: () => `${SportsServiceUrl}/schools`,
    getSchoolTeams: (schoolId, sportId, query) =>
      `${SportsServiceUrl}/school/teams?school_id=${schoolId}&sport_id=${sportId}&${query}`,
    getTeamTypes: () => `${SportsServiceUrl}/teams/types`,
    postCode: () => `${SportsServiceUrl}/school/code`,
    teamSignUp: () => `${SportsServiceUrl}/school/team/user`,
    checkInviteValidation: (userTeamId, token) =>
      `${SportsServiceUrl}/validate/team/${userTeamId}/token/${token}`,
    updateSchoolTeam: () => `${SportsServiceUrl}/school/team`,
    getUsersTeams: (uuid) => `${SportsServiceUrl}/user/teams/${uuid}`,
    getTeamUsers: (sportId, schoolId) =>
      `${SportsServiceUrl}/user/teams?sport_id=${sportId}&school_id=${schoolId}`,
    testCode: (code) => `${SportsServiceUrl}/code/test/${code}`,
    deleteUserFromTeam: (userTeamId) =>
      `${SportsServiceUrl}/school/team/user/${userTeamId}`,
    deleteUserFromProspectTeam: (userId, schoolTeamId) =>
      `${SportsServiceUrl}/school/team/user/${schoolTeamId}/${userId}`,
    addUserToSchoolTeam: (userId) =>
      `${SportsServiceUrl}/school/team/user/${userId}`,
    getSchoolStyle: (schoolId) =>
      `${SportsServiceUrl}/school/style/${schoolId}`,
    getSchoolTeamsList: (schoolId, sportId = 0) =>
      `${SportsServiceUrl}/school/team/list?school_id=${schoolId}&sport_id=${sportId}`,
    updateTeamName: () => `${SportsServiceUrl}/school/team`,
    coachSchool: (uuid) => `${SportsServiceUrl}/coachSchools/${uuid}`,
  },
  combines: {
    get: (combineId) => `${SportsServiceUrl}/combines/${combineId}`,
    getWithPrevCombine: (combineId) =>
      `${SportsServiceUrl}/combines/${combineId}/withPrevCombine`,
    getAll: (schoolUuid) =>
      `${SportsServiceUrl}/combines?schoolUuid=${schoolUuid}`,
    getAllOpen: (schoolUuid) =>
      `${SportsServiceUrl}/combines?schoolUuid=${schoolUuid}&open=true`,
    post: () => `${SportsServiceUrl}/combines`,
    put: (combineId) => `${SportsServiceUrl}/combines/${combineId}`,
    delete: (combineId) => `${SportsServiceUrl}/combines/${combineId}`,
    getLeaderboard: (combineId) =>
      `${LeaderboardServiceUrl}/leaderboard/${combineId}`,
  },
  stripe: {
    subscribe: () => `${SportsServiceUrl}/stripe/subscribe`,
    products: (schoolId) =>
      `${SportsServiceUrl}/stripe/school/${schoolId}/product`,
  },
  combineAwards: {
    getAll: (combineId) => `${SportsServiceUrl}/combine/${combineId}/awards`,
    post: (combineId) => `${SportsServiceUrl}/combine/${combineId}/awards`,
    put: (combineId, awardId) =>
      `${SportsServiceUrl}/combine/${combineId}/awards/${awardId}`,
  },
  combineTemplates: {
    get: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}`,
    getAll: () => `${SportsServiceUrl}/combineTemplates`,
    getAllBySchool: (schoolId) =>
      `${SportsServiceUrl}/combineTemplates/school/${schoolId}`,
    post: () => `${SportsServiceUrl}/combineTemplates`,
    put: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}`,
    delete: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}`,
    getTestCategories: () => `${SportsServiceUrl}/standardTestCategories`,
    getTestBanks: () => `${SportsServiceUrl}/standardTestBanks`,
    getTestSubbanks: () => `${SportsServiceUrl}/standardTestSubbanks`,
    addTestCategory: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}/standardTestCategories/create`,
    deleteTestCategory: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}/standardTestCategories/delete`,
    combines: (combineTemplateId) =>
      `${SportsServiceUrl}/combineTemplates/${combineTemplateId}/combines`,
  },
  combineResults: {
    get: (combineId) => `${SportsServiceUrl}/combineResults/${combineId}`,
    getAthlete: (combineId, athleteId) =>
      `${SportsServiceUrl}/combineResults/${combineId}/athletes/${athleteId}/results`,
    getAllAthleteResults: (combineId) =>
      `${SportsServiceUrl}/combineResults/${combineId}/athletes`,
  },
  reports: {
    getAll: (schoolUuid) =>
      `${SportsServiceUrl}/combineReports?schoolUuid=${schoolUuid}`,
    post: () => `${SportsServiceUrl}/combineReports`,
    get: (reportId) => `${SportsServiceUrl}/combineReportResults/${reportId}`,
    getTeam: (reportId, teamId) =>
      `${SportsServiceUrl}/combineReportResults/${reportId}/teams/${teamId}`,
    getAthlete: (reportId, athleteId) =>
      `${SportsServiceUrl}/combineReportResults/${reportId}/athlete/${athleteId}`,
  },
  collegeContact: () => `${SportsServiceUrl}/collegeContacts`,
  northEastLeaderboard: {
    results: ({
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      orderBy,
      zip,
      radius,
      search,
      sportId,
    }) =>
      `${SportsServiceUrl}/northEastLeaderboard?page=${page}&sortBy=${sortBy}&search=${search}&zip=${zip}&radius=${radius}&sportId=${sportId}&orderBy=${orderBy}&perPage=${perPage}&scoreType=${scoreType}${
        parsedFilters ? `&${parsedFilters}` : ''
      }`,
  },
  allTimeLeaderboard: {
    template: (schoolId, sportId) =>
      `${SportsServiceUrl}/allTimeLeaderboard/${schoolId}/template/${sportId}`,
    sports: (schoolId) =>
      `${SportsServiceUrl}/allTimeLeaderboard/${schoolId}/sports`,
    gradYears: (schoolId) =>
      `${SportsServiceUrl}/allTimeLeaderboard/${schoolId}/gradYears`,
    results: ({
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
      lastFilterRank,
    }) =>
      `${SportsServiceUrl}/allTimeLeaderboard/${schoolId}/results?page=${page}&lastFilterRank=${lastFilterRank}&sortBy=${sortBy}&search=${search}&zip=${zip}&radius=${radius}&sportId=${sportId}&orderBy=${orderBy}&perPage=${perPage}&scoreType=${scoreType}${
        parsedFilters ? `&${parsedFilters}` : ''
      }`,
  },
  districtLeaderboard: {
    template: (schoolId, sportId) =>
      `${SportsServiceUrl}/districtLeaderboard/${schoolId}/template/${sportId}`,
    sports: (schoolId) =>
      `${SportsServiceUrl}/districtLeaderboard/${schoolId}/sports`,
    gradYears: (schoolId) =>
      `${SportsServiceUrl}/districtLeaderboard/${schoolId}/gradYears`,
    results: ({
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
    }) =>
      `${SportsServiceUrl}/districtLeaderboard/${schoolId}/results?page=${page}&sortBy=${sortBy}&search=${search}&zip=${zip}&radius=${radius}&sportId=${sportId}&orderBy=${orderBy}&perPage=${perPage}&scoreType=${scoreType}${
        parsedFilters ? `&${parsedFilters}` : ''
      }`,
  },
  nationalLeaderboard: {
    template: (sportId) =>
      `${SportsServiceUrl}/nationalLeaderboard/template/${sportId}`,
    sports: () => `${SportsServiceUrl}/nationalLeaderboard/sports`,
    gradYears: () => `${SportsServiceUrl}/nationalLeaderboard/gradYears`,
    results: ({
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      lastFilterRank
    }) =>
      `${SportsServiceUrl}/nationalLeaderboard/results?page=${page}&lastFilterRank=${lastFilterRank}&sortBy=${sortBy}&search=${search}&zip=${zip}&radius=${radius}&sportId=${sportId}&orderBy=${orderBy}&perPage=${perPage}&scoreType=${scoreType}${
        parsedFilters ? `&${parsedFilters}` : ''
      }`,
  },
  prospectSheet: {
    personalInfo: (teamId, athleteId) =>
      `${SportsServiceUrl}/schoolTeam/${teamId}/profile/${athleteId}/prospect-sheet/personal-info`,
    personalRecord: (uuid, sport) =>
      `${SportsServiceUrl}/prospectRecords/${uuid}?sport=${sport}`,
  },
  externalProfile: {
    getAll: (uuid) => `${SportsServiceUrl}/user/${uuid}/external-profile`,
    post: (uuid) => `${SportsServiceUrl}/user/${uuid}/external-profile`,
    delete: (uuid, id) =>
      `${SportsServiceUrl}/user/${uuid}/external-profile/${id}`,
    put: (uuid, id) =>
      `${SportsServiceUrl}/user/${uuid}/external-profile/${id}`,
  },
  exists: {
    checkEmail: (email) => `${SportsServiceUrl}/${email}/exists`,
    checkEmailForMerge: (email) =>
      `${SportsServiceUrl}/${email}/exists-for-merge`,
  },
};
export default Urls;
