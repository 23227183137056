import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../UxCommon/Icon/Icon';

class ConfirmMessage extends PureComponent {
  static propTypes = {
    msg: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
  };

  onClose = () => {
    const { onClose, id } = this.props;
    onClose(id);
  }

  render() {
    const {
      msg,
      classNameDialog,
      classNameMsgTxt,
      classNameIcon,
    } = this.props;
    return (
      <div className={["P_TopBanner_SuccessMsg_Msg", classNameDialog].join(" ")}>
        <div className={["P_TopBanner_SuccessMsg_MsgTxt", classNameMsgTxt].join(" ")}>
          {msg}
        </div>
        <button
          className="P_TopBanner_SuccessMsg_CloseBtn"
          onClick={this.onClose}
        >
          <Icon className={["P_TopBanner_SuccessMsg_CloseBtnIcon", classNameIcon].join(" ")} iconName="close" />
        </button>
      </div>
    );
  }
}

export default ConfirmMessage;
