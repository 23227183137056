import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Routes, Sports } from '../../../../../store/actions/ui/routes';
import { getSportIconName } from '../../../../../utils';

class HeaderSportLink extends PureComponent {
  static propTypes = {
    sportId: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  componentDidMount() {}

  render() {
    const {
      sportId,
      className,
      match,
      ...other
    } = this.props;
    const { schoolId } = match.params;
    return (
      <NavLink
        className={['PageHeader_HeaderSportLink', className || ''].join(' ')}
        to={`/${Routes.profile}/${match.params.uuid}/${match.params.page}/${Sports[sportId]}${schoolId ? `/${schoolId}`: ''}`}
        activeClassName="selected"
        {...other}
      >
        <i className={`PageHeader_HeaderSportLink_Icon icon-${getSportIconName(sportId)}`} />
      </NavLink>
    );
  }
}

export default HeaderSportLink;
