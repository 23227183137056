import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import Icon from '../../../../UxCommon/Icon/Icon';
import { TextControl } from '../../../../UxCommon/Form';

const defaultRrfField = {
  errors: {},
  valid: true,
};

class TagItem extends PureComponent {
  static propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    // isInModal: PropTypes.bool.isRequired,
    rrfForm: PropTypes.object,
    tag: PropTypes.object,
    onTagDelete: PropTypes.func,
    isNewTagEnable: PropTypes.bool.isRequired,
    onTagUnselected: PropTypes.func,
    onTagSelected: PropTypes.func,
    selectedTagsFilters: PropTypes.array,
    tagNumber: PropTypes.number,
  };

  componentDidMount() {}

  handleDelete = () => {
    const { onTagDelete, tag } = this.props;
    onTagDelete(tag.id);
  };

  handleSelectTag = () => {
    const {
      onTagUnselected,
      onTagSelected,
      selectedTagsFilters,
      tag,
    } = this.props;
    const selected = (selectedTagsFilters ? selectedTagsFilters.indexOf(tag.id) >= 0 : false);
    if (selected) {
      onTagUnselected(tag.id);
    } else {
      onTagSelected(tag.id);
    }
  };

  render() {
    const {
      isEditMode,
      // isInModal,
      rrfForm,
      isNewTagEnable,
      selectedTagsFilters,
      tag,
      tagNumber,
    } = this.props;
    return (
      <div className="UxCommon_TagButtonWrapper">
        {!isEditMode ? (
          <button
            className={[
            'UxCommon_TagButton',
            selectedTagsFilters.indexOf(tag.id) >= 0 ? 'selected' : '',
          ].join(' ')}
            key={tag.id}
            onClick={this.handleSelectTag}
          >
            <label htmlFor="button" className="UxCommon_TagButton_Btn">
              {tag.label}
            </label>
            <label htmlFor="a" className="UxCommon_TagButton_Number">
              {tagNumber}
            </label>
          </button>
        ) : (
          <div>
            {!isNewTagEnable && (
            <div key={tag.id}>
              <TextControl
                label="Tag Title"
                placeholder="Tag Title"
                model={`forms.tagDialog.newTagName.${tag.id}`}
                rrfField={rrfForm.newTagName[tag.id] || defaultRrfField}
                customIcon="trash"
                onCustomIconClick={this.handleDelete}
                defaultValue={tag && tag.label ? `${tag.label}` : ''}
              />
            </div>
            )}
            {isNewTagEnable && (
              <div>
                <TextControl
                  label="New Tag"
                  placeholder="Enter Name of Tag"
                  model="forms.tagDialog.newTagName.new"
                  rrfField={rrfForm.newTagName.new || defaultRrfField}
                  onChange={this.onTagNameChange}
                  defaultValue=""
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TagItem;
