import { asyncStartAction } from '../../../../store/actions/ui/async';



export const GET_NATIONAL_LEADERBOARD_TEMPLATE = 'nationalLeaderboard.template.get';
export const getNationalLeaderboardTemplate = (sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_NATIONAL_LEADERBOARD_TEMPLATE));
  dispatch({
    type: GET_NATIONAL_LEADERBOARD_TEMPLATE,
    sportId,
    dispatch,
  });
};

export const GET_NATIONAL_LEADERBOARD_SPORTS = 'nationalLeaderboard.sports.get';
export const getNationalLeaderboardSports = () => (dispatch) => {
  dispatch(asyncStartAction(GET_NATIONAL_LEADERBOARD_SPORTS));
  dispatch({
    type: GET_NATIONAL_LEADERBOARD_SPORTS,
    dispatch,
  });
};

export const GET_NATIONAL_LEADERBOARD_GRAD_YEARS = 'nationalLeaderboard.gradYears.get';
export const getNationalLeaderboardGradYears = () => (dispatch) => {
  dispatch(asyncStartAction(GET_NATIONAL_LEADERBOARD_GRAD_YEARS));
  dispatch({
    type: GET_NATIONAL_LEADERBOARD_GRAD_YEARS,
    dispatch,
  });
};


export const GET_NATIONAL_LEADERBOARD_RESULTS = 'nationalLeaderboard.results.get';
export const getNationalLeaderboardResults = ({
  sortBy, perPage, scoreType, parsedFilters, page, loadMore, orderBy, zip, radius,search, sportId , schoolId, lastFilterRank
}) =>
  (dispatch) => {
    dispatch(asyncStartAction(GET_NATIONAL_LEADERBOARD_RESULTS));
    dispatch({
      type: GET_NATIONAL_LEADERBOARD_RESULTS,
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      loadMore,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
      lastFilterRank,
      dispatch,
    });
  };

export const GET_SPORTS = 'nationalLeaderboard.allSports.get';
export const getSports = () => (dispatch) => {
  dispatch(asyncStartAction(GET_SPORTS));
  dispatch({
    type: GET_SPORTS,
    dispatch,
  });
};

export default 'nationalLeaderboard.actions.js';
