import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SidebarNav from './SidebarNav/SidebarNav';
import { Routes } from '../../../store/actions/ui/routes';
import CardContent from './CardContent/CardContent';
import AvatarBg from './AvatarBg/AvatarBg';
import ExternalProfileCard from "./CardContent/ExternalProfileCard/ExternalProfileCard";

class Sidebar extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    playerUuid: PropTypes.string,
    user: PropTypes.object,
    selectedUserSport: PropTypes.object,
    isMenuShown: PropTypes.bool.isRequired,
    onMenuClick: PropTypes.func.isRequired,
    onSearchClick: PropTypes.func.isRequired,
    onSearchLoseFocus: PropTypes.func.isRequired,
    isSearchFocused: PropTypes.bool.isRequired,
    onClearSearchClick: PropTypes.func.isRequired,
    showLogoutDialog: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    onPositionDetail: PropTypes.func,
    playerPositionDetail: PropTypes.string,
    checkIfCanEdit: PropTypes.func,
    canEditProfile: PropTypes.object,
    externalProfile: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array]),
    athleteTeamRosters: PropTypes.array,

  };

  componentDidMount() {
    const { playerUuid, checkIfCanEdit, canEditProfile } = this.props;
    if (!canEditProfile || (canEditProfile && canEditProfile.playerUuid !== playerUuid)) {
      checkIfCanEdit();
    }
  }

  componentDidUpdate() {
    const { playerUuid, checkIfCanEdit, canEditProfile } = this.props;
    if (!canEditProfile || (canEditProfile && canEditProfile.playerUuid !== playerUuid)) {
      checkIfCanEdit();
    }
  }

  componentWillUnmount() {
  }

  getGrade() {
    const { user } = this.props;
    if (user && user.grade) {
      if (user.grade < 9) {
        return `, ${user.grade}th`;
      } else if (user.grade === '9') {
        return ', FR';
      } else if (user.grade === '10') {
        return ', SO';
      } else if (user.grade === '11') {
        return ', JR';
      } else if (user.grade === '12') {
        return ', SR';
      }
    }
    return '';
  }

  render() {
    const {
      match,
      uuid,
      isMenuShown,
      onMenuClick,
      user,
      selectedUserSport,
      onSearchClick,
      isSearchFocused,
      onSearchLoseFocus,
      onClearSearchClick,
      showLogoutDialog,
      onShareClick,
      isMyProfile,
      playerPositionDetail,
      onPositionDetail,
      canEditProfile,
      externalProfile,
      currentSportId,
      athleteTeamRosters,
      updateCalendlyModal,
    } = this.props;
    const isPlayerProfilePage = match.params.page === Routes.player;
    return (
      <div
        className={[
          'UxProfile_Left',
          isPlayerProfilePage ? 'open' : 'closed',
          isMenuShown ? 'menu-shown' : 'menu-shown-hidden',
        ].join(' ')}
      >
        <AvatarBg user={user} selectedUserSport={selectedUserSport} className="UxProfile_Sidebar d-flex">
          <SidebarNav
            uuid={uuid}
            match={match}
            isMenuShown={isMenuShown}
            onMenuClick={onMenuClick}
            onSearchClick={onSearchClick}
            isSearchFocused={isSearchFocused}
            onClearSearchClick={onClearSearchClick}
            onSearchLoseFocus={onSearchLoseFocus}
            onLogoutClick={showLogoutDialog}
            isMyProfile={isMyProfile}
            isCoach={canEditProfile && canEditProfile.isCoach}
            user={user}
            selectedUserSport={selectedUserSport}
            updateCalendlyModal={updateCalendlyModal}
          />
          <div
            className={[
              'UxProfile_S_InfoCol ActiveModalButtonUI',
              isMenuShown ? 'menu-open' : '', isPlayerProfilePage ? '' : 'hidden',
            ].join(' ')}
          >
            <CardContent
              user={user}
              selectedUserSport={selectedUserSport}
              onShareClick={onShareClick}
              isMyProfile={isMyProfile}
              playerPositionDetail={playerPositionDetail}
              onPositionDetail={onPositionDetail}
              match={match}
              athleteTeamRosters={athleteTeamRosters}
            />
          <ExternalProfileCard
            externalProfile ={externalProfile}
            match ={match}
            currentSportId ={currentSportId}
            isMyProfile ={isMyProfile}
          />
          </div>
        </AvatarBg>
      </div>

    );
  }
}

export default Sidebar;
