import { connect } from 'react-redux';
import Component from './PhotoCropDialog';
import { saveNewAthletePhoto } from '../../../+store/roster/roster.photo.actions';


const mapStateToProps = state => ({
  photoDataToCrop: state.modules.coachWorld.roster.photoDataToCrop,
  loading: state.modules.coachWorld.roster.photoCropDataLoading,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    savePhoto: (body, athleteId, coachId) => {
      const msg = 'Uploading photo';
      saveNewAthletePhoto(dispatch)(body, athleteId, msg, coachId);
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
