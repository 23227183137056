import React, {forwardRef, useImperativeHandle} from 'react';

export default forwardRef((props, ref) => {
  const {error} = props;

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return error ? ['DataEntry_Error_Tooltip'] : ['DataEntry_Tooltip'];
      },
    };
  });

  return (
    error ? (
      <div
        className="DataEntry_Error_Tooltip"
      >
        <div className={"Error_Box"}>
          {error}
        </div>
      </div>
    ) : (
      <div className="DataEntry_Tooltip"/>
    )
  )
});
