/* eslint react/no-unused-state: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetProfile extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    getProfile: PropTypes.func.isRequired,
    getProfileData: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  state = {
    loadProfileRequired: false,
    loadDataRequired: false,
    prevUuid: '',
    prevSportId: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {
      loadDataRequired: false,
      loadProfileRequired: false,
    };
    if (props.uuid !== state.prevUuid) {
      newState.loadProfileRequired = true;
      newState.prevUuid = props.uuid;
    }
    if (props.sportId !== state.prevSportId) {
      newState.loadDataRequired = true;
      newState.prevSportId = props.sportId;
    }
    return newState;
  }

  componentDidMount() {
    const {
      getProfile,
      uuid,
      loading,
      sportId,
      getProfileData,
    } = this.props;
    if (!loading) {
      if (uuid) {
        getProfile(uuid);
      }
    }
    if (uuid && sportId) {
      getProfileData(uuid, sportId);
    }
  }

  componentDidUpdate() {
    const {
      getProfile,
      uuid,
      getProfileData,
      sportId,
    } = this.props;
    const { loadDataRequired, loadProfileRequired } = this.state;
    if (loadProfileRequired) {
      getProfile(uuid);
    }
    if (loadDataRequired) {
      getProfileData(uuid, sportId);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetProfile;
