import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Component, { MergeTypes } from "../../components/Welcome/MergeSignInAuth/MergeSignInAuth";
import { formSubmit } from "../../store/actions/ui/formUtils";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import { playerProfileDuplicatesGet, playerProfileGet } from "../../store/actions/ui/uxProfile";

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};


const mapStateToProps = (state, ownProps) => {
  const authUuid = state.data.cognito.uuid;
  return ({
    needsInit: state.ui.welcome.mergeSignIn.formNeedsInit,
    message: state.ui.welcome.mergeSignIn.msg,
    isInvitationValid: state.ui.welcome.checkInvitation.isInvitationValid,
    rrfForm: state.data.cognito.token ?
      state.forms.forms.acceptInvitation : state.forms.forms.mergeLogin,
    formGeneralError: state.ui.welcome.mergeSignIn.formError,
    loggedIn: !!state.data.cognito.token,
    authenticatedProfile: getProfile(state, authUuid),
    mergeProfile: state.ui.welcome.mergeAnotherAccount.mergeProfile,
    authUuid,
    state,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    formInit: (profile) => {
      dispatch(actions.setInitial("forms.mergeLogin"));
        const { mergeType } = ownProps.match.params;
        let email = "";
        if (mergeType === MergeTypes.mergeBoth.toString()) {
          email = ownProps.match.params.newEmail.replaceAll('aAdDoOtT', '.');
        }

        // setting positions and jersey numbers just to skip the validations
        dispatch(actions.merge("forms.mergeLogin", {
          username: email,
          positions: "CB", // it will not be used
          jerseyNumbers: "12345" // it will not be used
        }));
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    updatePosition: (positions) => {
      dispatch(actions.change( "forms.mergeLogin.positions", positions.join(", ")));
    },
    submit: (uuid) => {
      let referrer = "";
      if (ownProps.location && ownProps.location.state) {
        referrer = ownProps.location.state.from;
      }
      dispatch(formSubmit("forms.mergeLogin", {
        from: referrer,
        isMobile: false,
        uuid,
        authUuid: '',
        token: '',
        userTeamId: '',
        dispatch,
        isRegistered: 0,
        editEmail: true
      }));
    },
    getDuplicateProfiles: (uuid) => {
      dispatch(playerProfileDuplicatesGet(uuid));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default Container;
