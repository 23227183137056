import { combineReducers } from 'redux';

import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import {
  EP_SPORT_FORM_INIT,
  EP_SPORT_SHOW_DELETE_DIALOG,
  EP_SPORT_HIDE_DELETE_DIALOG,
  EP_SPORT_DELETE,
  EP_SPORT_SELECT_SPORT,
  EP_SPORT_TOGGLE_ACTIVE_SWITCH,
  SAVE_USER_SPORT,
} from '../../../actions/ui/uxEditProfile';
import {
  USER_SPORT_CREATE_SUC,
  USER_GET_PROFILE_SUC,
  USER_SPORT_UPDATE_SUC,
} from '../../../actions/data/user';
import {
  ASYNC_START,
  ASYNC_FINISH,
  ASYNC_ERROR,
} from '../../../actions/ui/async';
import {
  FORM_SUBMIT_FAILED,
  FORM_SERVER_ERROR,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { EP_PHOTO_SAVE_NEW } from '../../../actions/ui/uxEditProfile/profilePhoto';

const formSuccess = (state = false, action) => {
  switch (action.type) {
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_FAILED:
    case ROUTE_CHANGED:
    case 'rrf/blur':
    case 'rrf/batch':
      return false;
    case FORM_SUBMIT_SUCCESS:
      return action.model === 'forms.userSport';
    default:
      return state;
  }
};

const editSportId = (state = -1, action) => {
  if (action.type === EP_SPORT_SELECT_SPORT) {
    return action.sportId;
  }
  return state;
};

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case USER_SPORT_CREATE_SUC:
    case USER_GET_PROFILE_SUC:
    case USER_SPORT_UPDATE_SUC:
    case EP_SPORT_SELECT_SPORT:
      return true;
    case EP_SPORT_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case USER_SPORT_CREATE_SUC:
    case USER_GET_PROFILE_SUC:
    case USER_SPORT_UPDATE_SUC:
    case EP_SPORT_SELECT_SPORT:
      return true;
    case EP_SPORT_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const isDeleteDialogShown = (state = false, action) => {
  switch (action.type) {
    case EP_SPORT_SHOW_DELETE_DIALOG:
      return true;
    case EP_SPORT_HIDE_DELETE_DIALOG:
    case EP_SPORT_DELETE:
      return false;
    default:
      return state;
  }
};

const loadingMsg = (state = 'Loading your sport information', action) => {
  switch (action.type) {
    case EP_PHOTO_SAVE_NEW:
      return !!action.isMobile && 'Uploading Photo...';
    case ASYNC_START:
      if (action.model === 'forms.userSport') {
        return action.msg;
      }
      if (action.model === SAVE_USER_SPORT) {
        return 'Saving Sports...';
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === 'forms.userSport') {
        return '';
      }
      if (action.model === SAVE_USER_SPORT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case EP_PHOTO_SAVE_NEW:
      return !!action.isMobile;
    case ASYNC_START:
      if (action.model === 'forms.userSport') {
        return true;
      } else if (action.model === SAVE_USER_SPORT) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === 'forms.userSport') {
        return false;
      }
      if (action.model === SAVE_USER_SPORT) {
        return false;
      }
      return state;
    case USER_GET_PROFILE_SUC:
      return false;
    default:
      return state;
  }
};

const loadingUserSports = ( state = false, action ) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === SAVE_USER_SPORT) {
        return true;
      }
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === SAVE_USER_SPORT) {
        return false;
      }
    default:
      return state;
  }
}

const addingNewSport = (state = false, action) => {
  switch (action.type) {
    case EP_SPORT_TOGGLE_ACTIVE_SWITCH:
      return !state;
    case ROUTE_CHANGED:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  isDeleteDialogShown,
  loadingMsg,
  loadingUserSports,
  loading,
  editSportId,
  formSuccess,
  addingNewSport,
  needsInit,
});

export default reducer;
