import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';
import SelectControl from '../FormControls/SelectControl';

class ChangeTeamDialog extends PureComponent {
  static propTypes = {
    onArchiveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    msg: PropTypes.string,
    btnMsg: PropTypes.string,
    teams: PropTypes.array.isRequired,
  };

  state = {
    teamId: ''
  }

  teamSelected = (teamId) => {
    this.setState({ teamId });
  }

  onChangeTeam = () => {
    const { teamId } = this.state;
    const { onArchiveClick } = this.props;
    onArchiveClick(teamId);
  }

  render() {
    const {
      teams,
      onArchiveClick,
      onCancelClick,
      msg,
      btnMsg,
    } = this.props;
    let { title } = this.props;
    const question = (
      <span>
        {msg || 'Are you sure you want to delete this?'}
      </span>
    );

    if (!title) {
      title = 'Delete Combine?';
    }

    let teamOptions = {};
    teams.filter(mt => mt.schoolTeams[0].active ).forEach((innerTeam) => {
      const { id, name } = innerTeam.schoolTeams[0];
      teamOptions = {
        ...teamOptions,
        [id]: name,
      };
    });

    return (
      <div className="ConfirmDialogWrapper">
        <div className="ConfirmDialog">
          <CloseButton size={10} onClick={onCancelClick} />
          <div className="AlbumDialod_DeleteAlbumDialog_Title">
            {title}
          </div>
          <div className="AlbumDialod_DeleteAlbumDialog_Question">
            {question}
          </div>
          <div style={{ width: '100%' }}>
            <SelectControl
              label="Team"
              name="team"
              className="CoachWorld_EditPlayer_Ctrl"
              options={teamOptions}
              myOnChange={this.teamSelected}
            />
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={this.onChangeTeam}
          >
            {btnMsg || 'Delete'}
          </ButtonRect>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default ChangeTeamDialog;
