export const getTeams = (sportTeamsList, sportId) => {
  const teams = {};
  teams[''] = 'Select your Team';
  sportTeamsList
    .filter(s => ((!sportId) || (s.sportId === sportId)))
    .forEach((team) => {
      teams[team.id] = team.name;
    });
  return teams;
};

export const createNewTeamId = newTeam => `new|${newTeam.sportId}|${newTeam.level}|${newTeam.name}`;

export const getTeamTypes = teamTypes => teamTypes.reduce((prev, curr) => (
  { ...prev, [curr.id]: curr.team }
), {});

export default 'utils';
