import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm/CheckoutForm.component";
import { useDispatch, useSelector } from "react-redux";
import { continueOnBoarding } from "../../../store/actions/ui/welcome/StripeCheckout";
import { StripeConfig } from "../../../config/env-urls";
import CheckoutSuccess from "./CheckoutSuccess/CheckoutSuccess.component";

const StripeCheckoutComponent = ({ profile, match }) => {
  const stripePromise = loadStripe(
    StripeConfig.PublishableKey
  );
  const schoolId = match.params && match.params.schoolId ? match.params.schoolId : "f57aaa60-7587-11e9-b3a1-37f6e2ecd8d2";

  const dispatch = useDispatch();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const subscriptionSuccess = useSelector(
    (state) => state.stripe.subscriptionSuccess
  );

  const schoolStyle = useSelector(
    (state) => state.modules.coachWorld.coachWorld.schoolStyle.find(school => school.id === schoolId)
  );

  const product = useSelector((state) => state.stripe.product);


  useEffect(() => {
    setShowSuccessMessage(subscriptionSuccess);
  }, [subscriptionSuccess]);

  const onContinue = () => {
    dispatch(
      continueOnBoarding({
        uuid: profile.uuid
      })
    );
  };
  const appearance = {
    theme: "stripe",

    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "2px",
      paddingRight: "16px",
      paddingBottom: "8px",
      paddingLeft: "16px",
      paddingTop: "8px",
      display: "block",
      fontSize: "18px",
      width: "100%"
    }
  };
  const options = {
   appearance,
  };
  return (
    profile && (
      <div className="container welcome-first">
        {showSuccessMessage ? (
          <CheckoutSuccess onContinue={onContinue} product={product} schoolStyle={schoolStyle} profile={profile} />
        ) : (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm profile={profile} match={match} />
          </Elements>
        )}
      </div>
    )
  );
};

export default StripeCheckoutComponent;
