import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../actions/ui/async';
import {
  FAVORITE_AWARD,
  GET_FAVORITE_AWARDS,
  GET_AWARDS,
  CREATE_AWARD,
  UPDATE_AWARD,
  HIDE_AWARD,
  DELETE_AWARD,
  ENDORSE_AWARD,
  SET_AWARDS_NEEDS_LOAD,
} from '../../../../actions/ui/uxProfile/awards';

const tempFavorites = (state = [], action) => {
  let a;
  switch (action.type) {
    case FAVORITE_AWARD:
      a = state.find(obj => obj.id === action.award.id);
      if (a) {
        return [...state.filter(obj => (obj.id !== a.id)), Object.assign({}, a, {
          semaphore: a.semaphore + 1,
          favorites: action.sportIds,
        })];
      }
      return [...state, {
        id: action.award.id,
        favorites: action.sportIds,
        semaphore: 0,
      }];
    case ASYNC_FINISH:
      if (action.model === FAVORITE_AWARD) {
        a = state.find(obj => obj.id === action.data.id);
        if (a) {
          if (a.semaphore <= 0) {
            return [...state.filter(obj => (obj.id !== a.id))];
          }
          return [...state.filter(obj => (obj.id !== a.id)), Object.assign({}, a, {
            semaphore: a.semaphore - 1,
          })];
        }
      }
      return state;
    default:
      return state;
  }
};

const tempEndorsements = (state = [], action) => {
  let a;
  switch (action.type) {
    case ENDORSE_AWARD:
      a = state.find(obj => obj.id === action.award.id);
      if (a) {
        return [...state.filter(obj => (obj.id !== a.id)), Object.assign({}, a, {
          semaphore: a.semaphore + 1,
          endorsementCount: a.endorsementCount + (1 * ((a.semaphore % 2) === 0 ? -1 : 1)),
        })];
      }
      return [...state, {
        id: action.award.id,
        endorsementCount: action.award.endorsementCount + (action.isEndorsing ? 1 : -1),
        semaphore: 0,
      }];
    case ASYNC_FINISH:
      if (action.model === ENDORSE_AWARD) {
        a = state.find(obj => obj.id === action.data.id);
        if (a) {
          if (a.semaphore <= 0) {
            return [...state.filter(obj => (obj.id !== a.id))];
          }
          return [...state.filter(obj => (obj.id !== a.id)), Object.assign({}, a, {
            semaphore: a.semaphore - 1,
          })];
        }
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = 'Loading awards ...', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_AWARDS) {
        return 'Loading awards ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_AWARDS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingFavoritesMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_FAVORITE_AWARDS) {
        return 'Loading awards ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_FAVORITE_AWARDS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const favoritesNeedUpdate = (state = true, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_FAVORITE_AWARDS) {
        return false;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === GET_FAVORITE_AWARDS) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === GET_FAVORITE_AWARDS) {
        return false;
      }
      if ((action.model === CREATE_AWARD) ||
      (action.model === UPDATE_AWARD) ||
      (action.model === HIDE_AWARD) ||
      (action.model === DELETE_AWARD)) {
        return true;
      }
      return state;
    default:
      return state;
  }
};

const needsToLoadAwards = (state = false, action) => {
  switch (action.type) {
    case SET_AWARDS_NEEDS_LOAD:
      return true;
    case ASYNC_FINISH:
      if (action.model === GET_AWARDS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const reducers = {
  tempFavorites,
  loadingFavoritesMsg,
  loadingMsg,
  favoritesNeedUpdate,
  tempEndorsements,
  needsToLoadAwards,
};

export default reducers;
