import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {RosterViewOptions} from '../../+store';
import EditButton from '../../common/EditButton';
import Icon from '../../common/Icon';
import {GradeConversion} from '../../+store/coachWorld/coachWorld.constants';
import {Routes} from '../../../../store/actions/ui/routes';
import {camelCase} from '../../../../containers/UxCommon/Utils';
import DropDownButton from '../../common/DropDownButton/DropDownButton.component';
import { formatPositionResponse} from '../../../../store/actions/ui/formUtils/validators';
import { capitalizeLetterAfterSpaceHyphen } from '../../../../store/actions/ui/formUtils/validators';
import LockIcon from "../../../../assets/images/roster_lock.png";

const MyLink = ({external, url, children, blankTargetLink}) => (
  external ? (
    <a
      href={url}
      target = { blankTargetLink ? '_blank' : '_self' }
    >
      {children}
    </a>
  ) : (
    <Link
      to={url}
      target = { blankTargetLink ? '_blank' : '_self' }
    >
      {children}
    </Link>
  )
);
MyLink.propTypes = {
  external: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  blankTargetLink: PropTypes.string
};

class AthleteCard extends PureComponent {
  static propTypes = {
    onEditClick: PropTypes.func.isRequired,
    athlete: PropTypes.object.isRequired,
    viewSelection: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    schoolId: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    isMetricUnit: PropTypes.bool.isRequired,
    schoolStyle: PropTypes.oneOfType([
      PropTypes.array, PropTypes.object
    ]),
    isPrivateTeam: PropTypes.number,
    teamId: PropTypes.string
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onEditClick = () => {
    const {athlete, onEditClick} = this.props;
    onEditClick(athlete);
  }

  getAthleteHeight = () => {
    const {isMetricUnit, athlete} = this.props;
    if (isMetricUnit) {
      let height = '';
      height += `${athlete.heightMt || 0}`;
      height += athlete.heightCm ? `.${athlete.heightCm}` : '';
      height += 'm';
      return height;
    }
    //return `${athlete.heightFt || ''}' ${athlete.heightIn || ''}"`;
    return `${athlete.heightFt ? athlete.heightFt : ''}${athlete.heightFt ? '\'': '' } ${athlete.heightIn ? athlete.heightIn : ''}${athlete.heightIn ? '"' : ''}`;
  }

  getAthleteWeight = () => {
    const {isMetricUnit, athlete} = this.props;
    // return isMetricUnit ? `${athlete.weightKg || '-'} Kg` : `${athlete.weight || '-'} lbs`;
    return isMetricUnit
      ? `${athlete.weightKg ? athlete.weightKg : '-'} Kg`
      : `${athlete.weight ? athlete.weight : ''}${athlete.weight ? ' lbs' : ''}`;
  }

  batsThrows = () => {
    const { athlete } = this.props;
    const { bats, throws } = athlete;
    return  ((bats && !!bats.trim()) || (throws && !!throws.trim())) ? `${bats || '-'}/${throws|| '-'}`: '' ;
  }

  shouldUpdate = () => {
  }

  choseCurrentSchoolStyle = () => {
    const {schoolStyle, schoolId} = this.props;
    let obj;
    if (schoolStyle.length > 0 && schoolId) {
      obj = schoolStyle.find(o => o.id === schoolId);
    }
    return obj;
  }

  loadRosterLock = (isCardView = false) =>{
    const { athlete , schoolStyle, match } = this.props;

    const currentSchool = schoolStyle.length && schoolStyle.find(school => school.id === match.params.schoolId);

    if(athlete && currentSchool&& !athlete.isSubscribed && currentSchool.isPaid){
      if( isCardView ){
        return (
          <img
            className="private_profile_lock_icon mx-2"
            style={{ maxWidth: '24px', maxHeight: '24px', position: 'absolute', top: '10px', right: '0' }}
            src={LockIcon}
            data-place="bottom"
            data-background-color="white"
            data-text-color="black"
            alt="Locked Icon"
            data-tip="Athlete Membership required to see full details"
          />
        )
      }
      else {
       return (
         <img
          className="private_profile_lock_icon mx-2"
          style={{ maxWidth: '24px', maxHeight: '24px', position: 'absolute', bottom: '7px', left: '80px', zIndex: '10' }}
          src={LockIcon}
          data-place="bottom"
          data-background-color="white"
          data-text-color="black"
          alt="Locked Icon"
          data-tip="Athlete Membership required to see full details"
        />
       )
      }
    }
  }




  render() {
    const {
      athlete, viewSelection, match, schoolId, teamId, isPrivateTeam
    } = this.props;
    const {sport} = match.params;
    let profileLink;
    let external;
    let blankTargetLink ;
    const athleteProfileImage = athlete && athlete.headshotUrl ? athlete.headshotUrl : this.choseCurrentSchoolStyle().school_logo;


    if ( isPrivateTeam  )
    {
      profileLink = `/${Routes.school}/${schoolId}/${Routes.team}/${teamId}/${Routes.athlete}/${athlete.userId}/${Routes.prospectSheet}/${sport}`;
      blankTargetLink = '_blank';
    } else {
      profileLink = `/${Routes.profile}/${athlete.userId}/${Routes.player}/${sport}/${schoolId}`;
    }

    external = false;

    // all players of demo schools is pointed to demo profile
    const demoSchools = [
      '31760c10-78c1-11e9-8deb-b910ff486cde',
      '31781700-78c1-11e9-a078-018a9c6d9b36',
      '9d6cb910-c0a0-11ec-9d64-0242ac120002',
    ];

    if (demoSchools.find(schId => schId === schoolId) !== undefined) {
      if (match.params.sport === 'football') {
        profileLink = 'https://app.virtualcombine.com/profile/a6d20228-f71b-4bb8-b995-06ae38f2c862/player';
        external = true;
      }
      if (match.params.sport === 'volleyball') {
        profileLink = 'https://app.virtualcombine.com/profile/8aef2f27-d45d-4a08-8843-0b44e83ed05a/player/volleyball';
        external = true;
      }
      if (match.params.sport === 'baseball') {
        profileLink = 'https://app.virtualcombine.com/profile/a6d20228-f71b-4bb8-b995-06ae38f2c862/player/baseball';
        external = true;
      }
      if (match.params.sport === 'softball') {
        if(schoolId === '9d6cb910-c0a0-11ec-9d64-0242ac120002') {
          profileLink = 'https://app.virtualcombine.com/profile/3fdb9cf8-8cd3-4c01-8d76-c6b1e01db854/player/softball';
          external = true;
        } else {
          profileLink = 'https://app.virtualcombine.com/profile/0c04a9b6-dcaf-43f7-b9d1-1814d031242f/player/softball';
          external = true;
        }
      }
      if (match.params.sport === 'basketball') {
        profileLink = 'https://app.virtualcombine.com/profile/b7350548-8bd7-4e96-bc44-76db985714ac/player/basketball';
        external = true;
      }
    }

    if (viewSelection === RosterViewOptions.card) {
      return (
        <div className="CoachAthleteCard">
          {this.loadRosterLock(true)}
          <MyLink
            url={profileLink}
            external={external}
            key={athlete.id}
            blankTargetLink={blankTargetLink}
          >
            {
              athleteProfileImage ? (
                  <div
                    className="CoachAthleteCard_Headshot"
                    style={{
                      backgroundImage: `url(${athleteProfileImage})`,
                    }}
                  />
                ) :
                (
                  <div className="CoachAthleteCard_Headshot">
                    <Icon iconName="photo2" className="CoachAthleteCard_PhotoIcon"/>
                  </div>
                )
            }
            {
              athlete === undefined && (
                <div className="CoachAthleteCard_Loading">Loading ...</div>
              )
            }
            {
              athlete &&
              (
                <React.Fragment>
                  <div className="CoachAthleteCard_Name">
                    {camelCase(athlete.hasOwnProperty('first') ? athlete.first : athlete.givenName)} {
                    athlete.hasOwnProperty('last') ? capitalizeLetterAfterSpaceHyphen(athlete.last) : capitalizeLetterAfterSpaceHyphen(athlete.familyName)}
                  </div>
                  <div className="CoachAthleteCard_Details">
                    <div className="CoachAthleteCard_Detail_Grade">
                      {athlete.gradYear || '-'}
                    </div>
                    <div className={`CoachAthleteCard_Detail ${athlete.height ? 'CoachAthleteCard_Detail_Height' : 'CoachAthleteCard_No_Height'}`}>
                      {this.getAthleteHeight()}
                    </div>
                    <div className={`CoachAthleteCard_Detail ${athlete.weight ? 'CoachAthleteCard_Weight' : 'CoachAthleteCard_No_Weight'}`}>
                      {this.getAthleteWeight()}
                    </div>
                    {(sport === 'baseball' || sport === 'softball') && <div className="CoachAthleteCard_Detail CoachAthleteCard_Detail_BatThrow">
                      {this.batsThrows()}
                    </div>}
                  </div>
                </React.Fragment>
              )
            }
          </MyLink>
          <div className="CoachAthleteCard_LastRow">
            <div className="CoachAthleteCard_JerseyNumber_Position">
              <div className="CoachAthleteCard_JerseyNumber">
                {`${athlete.jerseyNumber && athlete.jerseyNumber.trim() ? `# ${athlete.jerseyNumber.trim()} |` : '# - |'}`}
              </div>
              <div className="CoachAthleteCard_Position">
                {`${athlete.position ? formatPositionResponse(athlete.position) : ''}`}
              </div>
            </div>
            <div className="CoachAthleteCard_EditButton">
              {
                athlete.is_permission_for_edit && (
                  <DropDownButton
                    userId={athlete.userId}
                    teamId={teamId}
                    sport={sport}
                    schoolId={schoolId}
                    onEditClick={this.onEditClick}
                  />
                  // <EditButton iconName="edit" onClick={this.onEditClick} />
                )
              }
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="CoachAthleteListItem">
        {this.loadRosterLock()}
        <MyLink
          url={profileLink}
          external={external}
          key={athlete.id}
        >
          {
            athleteProfileImage ? (
              <div
                className="CoachAthleteListItem_image"
                style={{
                  backgroundImage: `url(${athleteProfileImage})`,
                }}
              />
            ) : (
              <div className="CoachAthleteListItem_image">
                <Icon iconName="photo2" className="CoachAthleteListItem_PhotoIcon"/>
              </div>
            )
          }
          <div className="CoachAthleteListItem_Info">
            {
              athlete && (
                <React.Fragment>
                  <div className="CoachAthleteListItem_Name_Position">
                    <div className="CoachAthleteListItem_Name">
                      {`${athlete.name}`}
                    </div>
                    <div className="CoachAthleteListItem_Line"/>
                    <div className="CoachAthleteListItem_Num_Pos">
                      <div className="CoachAthleteListItem_JerseyNumber">
                        {`${athlete.jerseyNumber && athlete.jerseyNumber.trim() ? `# ${athlete.jerseyNumber}` : ''}`}
                      </div>
                      <div
                        className={`CoachAthleteListItem_Line ${athlete.jerseyNumber && athlete.jerseyNumber.trim() ? '' : 'hideLine'}`}/>
                      <div className="CoachAthleteListItem_Position">
                        {`${athlete.position ? formatPositionResponse(athlete.position) : '' || '-'}`}
                      </div>
                    </div>
                  </div>
                  <div className="CoachAthleteListItem_FlexGrow"/>
                  <div className="CoachAthleteListItem_Details">
                    <div className="CoachAthleteListItem_Detail">
                      {athlete.gradYear || '-'}
                    </div>
                    <div className={`CoachAthleteListItem_Detail ${ athlete.height ? 'CoachAthleteListItem_Height' : 'CoachAthleteListItem_No_Height'}`}>
                      {this.getAthleteHeight()}
                    </div>
                    <div className={`CoachAthleteListItem_Detail ${ athlete.weight ? 'CoachAthleteListItem_Weight' : 'CoachAthleteListItem_No_Weight'}`}>
                      {this.getAthleteWeight()}
                    </div>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </MyLink>
        {
          athlete.is_permission_for_edit && (
            <DropDownButton
              className="Vc_dropdown"
              userId={athlete.userId}
              teamId={teamId}
              sport={sport}
              schoolId={schoolId}
              onEditClick={this.onEditClick}
            />
          )
        }
        {/* <div
          className={['CoachAthleteListItem_EditButton', athlete.is_permission_for_edit ? 'CoachAthleteListItem_canEdit' : '' || ''].join(' ')}
        >
          <EditButton iconName="edit" onClick={this.onEditClick} />
        </div> */}
      </div>
    );
  }
}

export default AthleteCard;
