import { combineEpics } from 'redux-observable';

import reducers from './leaderboard.reducers';
import leaderboardEpics from './leaderboard.epics';

const epics = combineEpics(leaderboardEpics);

export { reducers, epics };
export * from './leaderboard.actions';
export * from './leaderboard.constants';
