import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { LoadingSpinner, ButtonIconOnly } from '../../../UxCommon';
import LetsAddSome from '../LetsAddSome/LetsAddSome';
import NoneYet from '../NoneYet/NoneYet';
import { Routes, awardsDialogTo } from '../../../../store/actions/ui/routes';
import AwardLogo from '../../Awards/AwardLogo/AwardLogo';

class AwardsRow extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    awards: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    schoolAwardLogo: PropTypes.object,
    hasMembership: PropTypes.bool,
  }

  state = {
    currentSlide: 0,
  }

  componentWillUpdate() {
    const { awards } = this.props;
    if (awards.length) {
      this.elem = document.getElementById('FavoriteAwards');
      this.rowWidth = this.elem.parentElement.offsetWidth;
      this.childWidth = this.elem.firstChild.offsetWidth;
      const childrenPerSlide = this.rowWidth / this.childWidth;
      this.numSlides = Math.ceil(awards.length / childrenPerSlide);
    }
  }

  onLeftClick = () => {
    const { currentSlide } = this.state;
    const nextSlide = currentSlide - 1;
    // this.elem.parentElement.style.overflow = 'visible';
    this.elem.style.transform = `translateX(${-this.rowWidth * nextSlide}px)`;
    this.setState({
      currentSlide: nextSlide,
    });
  }

  onRightClick = () => {
    const { currentSlide } = this.state;
    const nextSlide = currentSlide + 1;
    // this.elem.parentElement.style.overflow = 'visible';
    this.elem.style.transform = `translateX(${-this.rowWidth * nextSlide}px)`;
    this.setState({
      currentSlide: nextSlide,
    });
  }

  onTransitionEnd = () => {
    // console.log('transition finished');
    // this.elem.parentElement.style.overflow = 'hidden';
  }

  renderEmptyRow = () => {
    const { isMyProfile, match } = this.props;
    if (isMyProfile) {
      return (
        <LetsAddSome
          msg="Let's add some awards!"
          page={Routes.awards}
          btnMsg="Add Awards"
          match={match}
        />
      );
    }
    return (
      <NoneYet msg="No awards have been added yet" />
    );
  }

  render() {
    const {
      awards,
      loadingMsg,
      loading,
      uuid,
      match,
      schoolAwardLogo,
      hasMembership,
    } = this.props;
    const { currentSlide } = this.state;
    return (
      <div className="AwardsRow" style={{ minHeight: (loading ? '300px' : '0px') }}>
        <LoadingSpinner
          loading={loading}
          loadingMsg={loadingMsg}
          style={{ textTransform: 'none' }}
        />
        {
          awards.length ? (
            <React.Fragment>
              {
                (currentSlide === 0) ? (
                  <div style={{ width: '16px', flexShrink: 0 }} />
                ) : (
                  <ButtonIconOnly
                    iconName="chevron-left"
                    className="AwardsRow_Left"
                    onClick={this.onLeftClick}
                  />
                )
              }
              <div style={{ flexGrow: 1, flexShrink: 0 }} />
              <div className="AwardsRow_AwardsWrapper">
                <div id="FavoriteAwards" className="AwardsRow_Awards" onTransitionEnd={this.onTransitionEnd}>
                  {
                    awards.map(award => (
                      <Link
                        key={award.id}
                        className="AwardsRow_AwardCard"
                        to={ hasMembership ? awardsDialogTo(uuid, award.id, match.params.sport, match.params.schoolId): false }
                      >

                        <AwardLogo
                          classes="AwardsRow_AwardImg"
                          schoolAwardLogo={schoolAwardLogo}
                          award={award}
                          landingPageClassName={'player_landing_page_award'}
                          />
                        <div className="AwardsRow_AwardCardText mt-2">
                          {award.name}<br />

                          {(award.date.isValid() ? award.date.format('MMMM Do YYYY') : moment(award.createdAt).format('MMMM Do YYYY'))}

                        </div>
                      </Link>
                    ))
                  }
                </div>
              </div>
              <div style={{ flexGrow: 1, flexShrink: 0 }} />
              {
                (currentSlide === (this.numSlides - 1)) ? (
                  <div style={{ width: '16px', flexShrink: 0 }} />
                ) : (
                  <ButtonIconOnly
                    iconName="chevron-right"
                    className="AwardsRow_Right"
                    onClick={this.onRightClick}
                  />
                )
              }
            </React.Fragment>
          ) : (
            this.renderEmptyRow()
          )
        }
      </div>
    );
  }
}

export default AwardsRow;
