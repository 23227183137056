import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import { BankSlugs, masterFormater, repsMasterFormater } from '../../+store/combine';
import { TableHeader, Table, TableRow, TableCell, TableBody } from '../../common/Table';
import TableCornerCell from '../../common/Table/TableCornerCell';
import AthleteMedia from '../AthleteMedia/AthleteMedia.component';
import { BankSlugToLabel } from '../../+store';
import Banner from '../../common/Banner';
import SchoolLogo from '../../common/SchoolLogo';
import Icon from '../../common/Icon';
import cssVar from '../../../../components/App/App.utils';
import bgImageUrl from '../../../../assets/images/bannerImage.png';
import vcLogo from '../../../../assets/images/vc-black.png';

const PrintExperiment = (props) => {
  const {
    bank,
    combine,
    athletes,
    match,
    currentSchoolStyle,
    getTestResult,
  } = props;
  const columns = combine.columns.filter(c => BankSlugs[c.testBankCode] === match.params.bank);
  let openDate = '';
  let toDate = '';

  if (combine && (combine.id === Number(match.params.combineId))) {
    if (combine.openDate) {
      openDate = combine.openDate.format('MM/DD/YY');
    }
    if (combine.isOpen) {
      toDate = combine.endDate ? combine.endDate.format('MM/DD/YY') : '';
    } else {
      toDate = combine.closeDate ? combine.closeDate.format('MM/DD/YY') : '';
    }
  }

  const styles = document.getElementsByTagName('style')[0].innerHTML;

  let backGroundImage = bgImageUrl;
  if (currentSchoolStyle && currentSchoolStyle.background_image !== null) {
    backGroundImage = currentSchoolStyle.background_image;
  }

  return (
    <Frame className="PrintExperiment_IFrame" id="printable">
      <style>{styles}</style>
      <style>
        {`* {
            -webkit-print-color-adjust: exact;
            --primary: ${cssVar('primary')};
            --secondary: ${cssVar('secondary')};
          }
          html, body, #root {
            overflow: hidden;
            height: unset;
          }
          @page {
            margin-bottom: 0;
            size: landscape;
          }
          html {
            overflow: auto;
          }
          .CombineTable {
            width: 100%;
          }
          .CoachBanner {
            height: 100px;
            margin-bottom: 10px;
            background-position: center;
            background-size: cover;
            position: relative;
          }
          .CoachBanner_UpperRight {
            top: 0;
            right: 0;
            bottom: unset;
          }
          .CoachRoster_BannerChildren2 {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: -50px;
          }
          .CoachRoster_SchoolName {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .CoachRoster_CombineDataVCLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
          }
          .CombineTableCell {
            height: 50px !important;
            font-size: 12px;
          }
          .CombineEntry_Laser {
            font-size: 12px;
            padding-left: 10px;
            color: var(--secondary);
          }
          .PrintSchoolLogo {
            height:40px;
            width:40px;
          }
          .Print_SchoolRosterName {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            margin-left:5px;
          }
        `}
      </style>
      <div
        className="CoachBanner"
        // style={{ backgroundImage: `url(${backGroundImage})` }}
      >
        <div className="CoachRoster_BannerChildren2">
          <div className="CoachRoster_SchoolName">
            <SchoolLogo
              className="CoachRoster_BannerTeamLogo PrintSchoolLogo"
              schoolStyle={currentSchoolStyle || null}
            />
            <div className="CoachRoster_BannerFullTitle Print_SchoolRosterName">{combine.name} Results</div>
          </div>
          <div className="CoachRoster_CombineDataVCLogo">
            {
              combine && (
                <div className="CoachCombine_Dates">
                  <Icon iconName="calendar" className="CoachCombine_CalendarIcon" />
                  <div>{`Combine Dates ${openDate} - ${toDate}`}</div>
                </div>
              )
            }
            {/* <div className="CoachBanner_VCRoundBgrd" >
              <img className="CoachBanner_VCLogo" src={vcLogo} alt="BACKGRO0ND" />
            </div> */}
          </div>
        </div>
      </div>
      <Table className="CombineTable">
        <TableHeader>
          <TableRow>
            <TableCornerCell style={{ width: '30%' }}>
              {BankSlugToLabel[bank]}
            </TableCornerCell>
            {
              columns.map(column => (
                <TableCell
                  key={column.testCategoryCode}
                  style={{ width: `${70 / columns.length}%` }}
                >
                  {column.testCategoryName}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHeader>
        <TableBody>
          {
            athletes.map((athlete, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '30%' }}>
                  <AthleteMedia isPrintProcess athlete={athlete} />
                </TableCell>
                {
                  columns.map((column) => {
                    const format = column.standardFormat.code;
                    const formatter = column.useReps ?
                      repsMasterFormater(format) : masterFormater(format);
                    let isLaser = false;
                    let formattedValue;
                    if (column.useReps) {
                      const realValue = getTestResult(athlete, column, true);
                      formattedValue = realValue !== null && realValue.result !== null ? formatter(realValue) : '-';
                    } else if (column.useLaser) {
                      const value = getTestResult(athlete, column, false, true);
                      const realValue = (value && value.result) || value;
                      formattedValue = realValue !== null ? formatter(realValue) : '-';
                      isLaser = (value && value.isLaser) || false;
                    } else {
                      const value = getTestResult(athlete, column);
                      const realValue = (value && value.result) || value;
                      formattedValue = realValue !== null ? formatter(realValue) : '-';
                    }
                    return (
                      <TableCell
                        key={column.testCategoryCode}
                        style={{ width: `${70 / columns.length}%` }}
                      >
                        {formattedValue || '-'}
                        {isLaser && <Icon iconName="tick" className="CombineEntry_Laser" />}
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            ))
          }
          <TableRow style={{ height: 86 }} />
        </TableBody>
      </Table>
    </Frame>
  );
};
PrintExperiment.propTypes = {
  bank: PropTypes.any,
  combine: PropTypes.any,
  athletes: PropTypes.any,
  match: PropTypes.any,
  currentSchoolStyle: PropTypes.any,
  getTestResult: PropTypes.func,
};

export default PrintExperiment;
