import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ControlErrors from '../ControlErrors';
import dashrLogoUrl from '../../../../../assets/images/Dashr.png';
import dashrLogoBlueUrl from '../../../../../assets/images/DashrBlue.png';

class CheckboxControl extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    myOnChange: PropTypes.func,
    myOnBlur: PropTypes.func,
    errors: PropTypes.array,
    help: PropTypes.any,
    defaultValue: PropTypes.bool,
    arrangement: PropTypes.string,
    subBankCode: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { checked } = props;
    if (checked !== state.value) {
      return {
        value: checked,
      };
    }
    return null;
  }

  state = {
    value: false,
    defaultValue: false,
    hasFocus: false,
  }

  componentDidMount() {
    this.checkDefault();
  }

  componentDidUpdate() {
    this.checkDefault();
  }

  setDefault = (defaultValue) => {
    const { myOnChange } = this.props;
    let value = defaultValue;
    if (myOnChange) {
      value = myOnChange(value);
    }
    this.setState({
      defaultValue,
      value,
    });
  }

  checkDefault = () => {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
    && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  handleChange = (event) => {
    const { myOnChange } = this.props;
    let value = event.target.checked;
    if (myOnChange) {
      value = myOnChange(value);
    }
    this.setState({ value });
  }

  handleBlur = (event) => {
    const { myOnBlur } = this.props;
    let value = '';
    if (myOnBlur) {
      value = myOnBlur(event.target.checked);
    }
    this.setState({
      value: value || event.target.checked,
      hasFocus: false,
    });
  }

  handleFocus = (event) => {
    const { myOnFocus } = this.props;
    let value = '';
    if (myOnFocus) {
      value = myOnFocus(event.target.checked);
    }
    this.setState({
      value: value || event.target.checked,
      hasFocus: true,
    });
  }

  render() {
    const {
      className, arrangement, label, errors, help, myOnChange, myOnBlur, defaultValue,isDashr,subBankCode, ...inputProps
    } = this.props;
    const { value, hasFocus } = this.state;
    const insides = [
      <label
        key="label"
        className={isDashr === 1 ? "CoachCheckboxControl_Label label_dashr" : 'CoachCheckboxControl_Label'}
        htmlFor={this.id}
        style={{
          paddingLeft: arrangement === 'left' ? '20px' : '0px',
        }}
      >
        {label}{ (!!isDashr && subBankCode !== 'HOCKEYSPECIFICDASHR') && ` - `}{ (!!isDashr && subBankCode !== 'HOCKEYSPECIFICDASHR') && <img className='combine-template-dahsr-logo-label' src={dashrLogoBlueUrl} height={25} width={25}/>}
      </label>,
      <input
        key="input"
        id={this.id}
        className={`CoachCheckboxControl ${(value) ? 'checked' : ''}`}
        type="checkbox"
        checked={value}
        // disabled={isEnableToEdit}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        {...inputProps}
      />
    ];
    if (arrangement === 'left') {
      insides.reverse();
    }
    return (
      <div
        className={`CoachCheckboxControl_Group ${
        className || ''} ${
        hasFocus ? 'focus' : ''} ${
        value ? 'not-empty' : ''} ${
        errors && errors.length ? 'error' : ''}`}
      >
        <div className="CoachCheckboxControl_InputWrapper">
          {insides}
        </div>
        {!!help && help}
        <ControlErrors errors={errors} />
      </div>
    );
  }
}

export default CheckboxControl;
