import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '../../../store/actions/ui/routes';
import { Roles } from '../../../store/actions/data/user/profile/models';

const Zero = ({ profile }) => {
  let redirectTo;
  let msg;
  if (profile && profile.roleId === Roles.coach) {
    redirectTo = `/${Routes.coach}/${Routes.dashboard}`;
    msg = 'Go to Dashboard';
  } else {
    if(profile && profile.step) {
      msg = 'Continue Onboarding' ;
      redirectTo = `/${profile.onboardingUrl}`;
    }
    else {
      msg = 'Go to Profile';
      redirectTo = `/${Routes.profile}`;
    }
  }
  return (
    <div className="welcome-btns">
      {
        profile ?
          <Link className="welcome-btn" to={redirectTo}>{msg}</Link>
          :
          <React.Fragment>
            {/*
            <Link className="welcome-btn" to={`/${Routes.welcome}/${Routes.signup}`}>Join Now</Link>
            */}
            <Link className="welcome-btn" to={`/${Routes.welcome}/${Routes.login}`}>Sign In</Link>
          </React.Fragment>
      }
    </div>
  );
};
Zero.propTypes = {
  profile: PropTypes.object,
};

export default Zero;
