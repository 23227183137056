import { combineReducers } from 'redux';
import { USER_SPORT_CREATE_SUC } from '../../../../store/actions/data/user';
import { DELETE_TRANSCRIPT, GET_TRANSCRIPT, UPLOAD_TRANSCRIPT } from '.';
import { ASYNC_FINISH, ASYNC_START } from '../../../../store/actions/ui/async';
import {GET_ATHLETE_DETAILS,GET_PLAYER_DATA} from './prospectSheet.actions'

const profile = (state = {}, action) => {
    switch (action.type) {
      case ASYNC_FINISH:
        if (action.model === GET_ATHLETE_DETAILS) {
          return action.data.profile
        }
        return state;
      default:
        return state;
    }
};

const personalInfo = (state = {}, action) => {
    switch (action.type) {
      case ASYNC_FINISH:
        if (action.model === GET_ATHLETE_DETAILS) {
          return action.data.personalInfo
        }
        return state;
      case USER_SPORT_CREATE_SUC:
          return { ...state, offers: action.userSport.offers}
      default:
        return state;
    }
};

const schoolTeam = (state = {} , action) =>{
    switch (action.type) {
        case ASYNC_FINISH:
          if (action.model === GET_ATHLETE_DETAILS) {
            return action.data.schoolTeam
          }
          return state;
        default:
          return state;
      }
}

const personalRecords = (state = [] , action) =>{
    switch (action.type) {
        case ASYNC_FINISH:
          if (action.model === GET_PLAYER_DATA) {
            return action.data.playerData
          }
          return state;
        default:
          return state;
      }
}

const loadingTranscript = (state=false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPLOAD_TRANSCRIPT || 
          action.model === DELETE_TRANSCRIPT) {
        return true;
      }
    case ASYNC_FINISH:
      if (action.model === UPLOAD_TRANSCRIPT || 
          action.model === DELETE_TRANSCRIPT) {
        return false;
      }
    default:
      return state;
  }
}

const transcript = (state={}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPLOAD_TRANSCRIPT || action.model === GET_TRANSCRIPT) {
        return action.data.data;
      }
      if(action.model === DELETE_TRANSCRIPT){
        return {};
      }
    default:
      return state;
  }
}


const reducer = combineReducers({
    profile,
    personalInfo,
    schoolTeam,
    personalRecords,
    transcript,
    loadingTranscript
  });
  
  export default reducer;
