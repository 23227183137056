import { combineReducers } from 'redux';
import editSports from './editSports';
import profilePhoto from './profilePhoto';
import account from './account';
import changePass from './changePass';
import deleteAccount from './deleteAccount';
import confirmEmailPhone from './confirmEmailPhone';
import vanityUrl from './vanityUrl';
import twitterHandle from './twitterHandle';

const reducer = combineReducers({
  editSports,
  profilePhoto,
  account,
  changePass,
  deleteAccount,
  confirmEmailPhone,
  vanityUrl,
  twitterHandle
});

export default reducer;
