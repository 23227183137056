import React from 'react';
import PropTypes from 'prop-types';

const LetsAddSome = ({
  msg,
}) => (
  <div className="UxProfile_NoneYet">
    <div className="UxProfile_NoneYet_Msg">
      {msg}
    </div>
  </div>
);
LetsAddSome.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default LetsAddSome;
