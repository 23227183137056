import top100UsaLogo from "../../../../assets/images/top_100_sports.png";
import {SubbankCodes, SubbankTooltip} from "../../+store";
import ReactTooltip from "react-tooltip";
import React from "react";
import dk_logo from "../../../../assets/images/logos/dk_logo.png";
import rapsodo_logo from "../../../../assets/images/logos/rapsodo_logo.png";
import trackman_tm_logo from "../../../../assets/images/logos/trackman_tm_logo.png";
import trackman_logo from "../../../../assets/images/logos/trackman_logo.png";
import hit_trax_logo from "../../../../assets/images/logos/HTEmblem.png";
import hit_trax_logo_white from "../../../../assets/images/logos/HTEmblemWhite.png";
import hit_trax_logo_full_black from "../../../../assets/images/logos/HitTrax-Black.png";
import hit_trax_logo_full_white from "../../../../assets/images/logos/htrx-logo-full-white.png";
import { Routes } from '../../../../store/actions/ui/routes';

const CellLogo =  ( props ) => {
  const { toolTip, logo, renderImage,className,parentClass,dataPlace,dataBackgroundColor,dataTextColor } = props;
  return (
    <div  className={[
      renderImage ? ' Leaderboard_Category_Logo_Parent custom_logo_parent' : ''
    ].join(' ')}>
      {renderImage ?
        <img
          src={logo}
          data-tip={toolTip}
          className={[
         'Leaderboard_Category_Logo', className
        ].join(' ')}
             data-place = {dataPlace} data-background-color={dataBackgroundColor} data-text-color={dataTextColor}
        />
        :  <i  data-place = {dataPlace} data-background-color={dataBackgroundColor} data-text-color={dataTextColor}  data-tip={toolTip}
              className={[
                className
              ].join(' ')} />
      }
      <ReactTooltip />
    </div>
  )
}
export default  React.memo( CellLogo );

const render = (page,renderImage,logo,toolTip,parentClass,className,dataTextColor,dataBackgroundColor,dataPlace) =>{
  switch(page) {
    case Routes.prospectSheet:
      return renderImage ?
      <React.Fragment>
      <img
        src={logo}
        className={className||''}
        alt={'Partner logo'}
        data-tip={toolTip}
        data-text-color={dataTextColor}
        data-background-color={dataBackgroundColor}
        data-place={dataPlace}
      />
      </React.Fragment>
        :
      <React.Fragment>
        <i className={className||''}  style={{fontSize:20}} data-tip={toolTip} data-text-color={dataTextColor}
          data-background-color={dataBackgroundColor} data-place={dataPlace} />
        <ReactTooltip />
      </React.Fragment>
    case Routes.playerProfile:
      return <CellLogo
            renderImage={renderImage}
            logo = {logo}
            toolTip ={toolTip}
            parentClass = {parentClass}
            className = {className ? `${className} ${renderImage ? 'logo_Bottom' : ''}` : ''}
            dataTextColor={dataTextColor}
            dataBackgroundColor={dataBackgroundColor}
            dataPlace={dataPlace}
          />
    default:
          return <CellLogo
            renderImage={renderImage}
            logo = {logo}
            toolTip ={toolTip}
            parentClass = {parentClass}
            className = {className}
            dataTextColor={dataTextColor}
            dataBackgroundColor={dataBackgroundColor}
            dataPlace={dataPlace}
          />
  }
}

export const renderLeaderboardColoumLogo = (subbankCode,
  hasResult = false,
  testCode = null,
  page=null,
  dataTextColor="black",
  dataBackgroundColor="white",
  dataPlace="bottom")=>{
  let className, logo, toolTip, parentClass;
  let renderImage = true;
  switch (subbankCode) {
    case SubbankCodes.BASEBALLHITTINGBLAST:
      className= !hasResult ? "Leaderboard_NoPadding_Subbank_Icon" : '' + ' icon-blast-logo-circle-icon';
      toolTip =SubbankTooltip.BASEBALLHITTINGBLAST;
      renderImage = false;
      break;

    case SubbankCodes.COACHEVAL:
      if (testCode === 'TOP100INDEX'){
        toolTip =SubbankTooltip.Top100SportLogo;
        logo =  top100UsaLogo;
        break;
      } else {
        return '';
      }

    case SubbankCodes.DKSBASEBALLPITCHING:
    case SubbankCodes.DIAMONDKINETICSHITTING:
      toolTip =SubbankTooltip.DK;
      logo =  dk_logo
      className = `${page ? 'diamond-kinetic-logo':''} ${page === Routes.playerDataGraph? 'playerDataGraph_Diamond_Kinetic': ''}`
      break;

    case SubbankCodes.RAPSODOHITTING:
    case SubbankCodes.RAPSODOSOFTBALLPITCHING:
    case SubbankCodes.RAPSODOBASEBALLPITCHING:
      toolTip =SubbankTooltip.RAPSODO;
      logo =  rapsodo_logo
      className = `${page ? 'rapsodo-logo':''} ${page=== Routes.playerProfile ? 'Player_Profile_Rapsodo':''} ${page === Routes.playerDataGraph? 'playerDataGraph_Rapsodo': ''}`
      break;

    case SubbankCodes.TRACKMANHITTING:
    case SubbankCodes.TRACKMANPITCHING:
      toolTip =SubbankTooltip.TRACKMAN;
      className= (page === Routes.playerProfile) ? `icon-trackman-icon trackman_logo`:
                `icon-tm-icon tm-logo ${page ===  Routes.prospectSheet ? 'tm_prospect': ''} ${page === Routes.playerDataGraph ? 'tm_graph' : ''}`;
      renderImage = false;
      break;
    case SubbankCodes.PORPLAYPITCHING:
      toolTip =SubbankTooltip.PORPLAYPITCHING;
      className= (page === Routes.playerProfile) ? `icon-proplayai-icon pro_play_icon`:
                `icon-proplayai-icon pro_play_icon ${page ===  Routes.prospectSheet ? 'pro_play_prospect': ''} ${page === Routes.playerDataGraph ? 'pro_paly_graph_graph' : ''}`;
      renderImage = false;
      break;
    case SubbankCodes.HITTRAX:
      toolTip =SubbankTooltip.HITTRAX;
      logo =
          page === Routes.playerProfile ? hit_trax_logo_full_black
        : page === Routes.playerDataGraph ? hit_trax_logo
        : page === Routes.prospectSheet ? hit_trax_logo_white
        : hit_trax_logo_white;
      className =
          page === Routes.playerProfile ? 'ht_player_profile'
        : page === Routes.playerDataGraph ? 'ht_player_data_graph'
        : page === Routes.prospectSheet ? 'ht_prospect_sheet'
        : page === Routes.combine ? 'ht_combine'
        : 'ht_leaderboard';
      break;
    default:
      return '';

  }

  return render(page,renderImage,logo,toolTip,parentClass,className, dataTextColor,dataBackgroundColor,dataPlace);
}
