import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { LoadingSpinner } from '../../../UxCommon';
import Icon from '../../../UxCommon/Icon/Icon';

const SportIdToIcon = {
  1: 'football',
  2: 'baseball',
  3: 'soccer',
  4: 'baseball',
  5: 'basketball',
  6: 'power-lifting',
  7: 'volleyball',
  8: 'crosscountry',
  9: 'swimming',
  10: 'diving',
  11: 'golf',
  12: 'tennis',
  13: 'athletic-facility',
  14: 'wrestling',
  15: 'lacrosse',
  16: 'trackfield',
  17: 'waterpolo',
  18: 'hockey',
  19: 'cheerleading',
  20: 'rugby',
  21: 'netball',
};

class ProfilePhoto extends PureComponent {
  static propTypes = {
    selectedUserSport: PropTypes.object,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    loadingProgress: PropTypes.object,
  }

  componentDidMount() {
  }

  render() {
    const {
      selectedUserSport,
      loading,
      loadingMsg,
      loadingProgress,
    } = this.props;
    return (
      <div className={`UxEditProfile_ProfilePhoto_Pic ${loading ? '' : 'UxEditProfile_ProfilePhoto_Pic_sm'}`}>
        {
          loading
            ? (
              <LoadingSpinner
                loading
                loadingMsg={loadingMsg}
                loadingProgress={loadingProgress}
                style={{ background: 'none', padding: '20px' }}
                className="UxEditProfile_ProfilePhoto_LoadingSpinner"
              />
            ) : selectedUserSport &&
            (
              selectedUserSport.photoUrl
                ? (
                  <div className="UxProfile_S_ProfilePhotoImg"
                       style={{ backgroundImage: `url('${selectedUserSport.photoUrl}')` }}/>
                )
                :
                <Icon className="UxEditProfile_ProfilePhoto_Icon" iconName={SportIdToIcon[selectedUserSport.sportId]}/>
            )
        }
      </div>
    );
  }
}

export default ProfilePhoto;
