import React from 'react';

import {
  validateRequired,
  validateIsPhoneOrEmail,
} from '../../../store/actions/ui/formUtils/validators';
import { passwordHelpMsg } from '../../../store/reducers/ui/welcome/utils';

export const checkConfirmPassword = (password, confirm) => {
  let error = '';
  if (password !== confirm) {
    error = 'Passwords must match';
  }
  return error ? [error] : [];
};

export const passwordHelp = (value) => {
  const passwordValidationObject = passwordHelpMsg(value);
  return (
    <div>
      <div className="SignInPage_PasswordHelpLabel">
        {passwordValidationObject.msg}
      </div>
      {passwordValidationObject.tests.map((test, index) => (
        <div
          key={index}
          className={test.passing
            ? 'SignInPage_PasswordHelpPassing'
            : 'SignInPage_PasswordHelpFailing'}
        >
          {test.msg}
        </div>
      ))}
    </div>
  );
};

export const checkPassword = (value) => {
  const helps = passwordHelpMsg(value);
  const errors = helps.tests.reduce((prev, test) => {
    if (!test.passing) {
      prev.push(test.msg);
    }
    return prev;
  }, []);
  return errors;
};

export const checkRequired = (value, msg = '') => {
  const error = validateRequired(msg)(value).required;
  return error ? [error] : [];
};

export const checkUsername = (value) => {
  const possibleErrors = [
    validateRequired()(value).required,
    validateIsPhoneOrEmail()(value).phoneOrEmail,
  ];
  const errors = possibleErrors.reduce((prev, curr) => {
    if (curr) {
      prev.push(curr);
    }
    return prev;
  }, []);
  return errors;
};
