import React from 'react';
import PropTypes from 'prop-types';

const StaticInput = ({ label, value }) => (
  <div className="UxCommon_Form_StaticInput">
    <div className="UxCommon_Form_StaticInput_Label">{label}</div>
    <div className="UxCommon_Form_StaticInput_Value">{value}</div>
  </div>
);
StaticInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default StaticInput;
