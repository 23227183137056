import React from "react";


const StripePayButton = ({ product }) => {

  return (
    <button
      id="forms.joinNow.submit"
      className="welcome-btn"
      type="submit">
      Pay ${product.displayPrice}
    </button>
  );
};

export default StripePayButton;
