
import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { GET_TEAM_TYPES, GET_USER_TEAMS, CHECK_CODE } from './codeSignUp.actions';
import { SERVER_ERROR } from '../../../../store/actions/ui/ajaxErrorHandlers';
import Urls from '../../../../config/urls';

const loadingMsg = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_TEAM_TYPES) {
        return Object.assign({}, state, {
          getTeamTypes: 'Loading ...',
        });
      }
      if (action.model === GET_USER_TEAMS) {
        return Object.assign({}, state, {
          getUsersTeams: 'Loading ...',
        });
      }
      if (action.model === CHECK_CODE) {
        return Object.assign({}, state, {
          checkingCode: 'Checking Code ...',
        });
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_TEAM_TYPES) {
        return Object.assign({}, state, {
          getTeamTypes: '',
        });
      }
      if (action.model === GET_USER_TEAMS) {
        return Object.assign({}, state, {
          getUsersTeams: '',
        });
      }
      if (action.model === CHECK_CODE) {
        return Object.assign({}, state, {
          checkingCode: '',
        });
      }
      return state;
    case SERVER_ERROR:
      if (action.error.request.url.includes(Urls.schools.testCode(''))) {
        return Object.assign({}, state, {
          checkingCode: '',
        });
      }
      return state;
    default:
      return state;
  }
};

const teamTypes = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_TEAM_TYPES) {
        return action.data.teamTypes;
      }
      return state;
    default:
      return state;
  }
};

const isCodeValid = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_CODE) {
        return action.data.isValid;
      }
      return state;
    default:
      return state;
  }
};

const notValidMsg = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_CODE) {
        return action.data.isValid ? null : action.data.notValidMsg || 'We don\'t recognize the code you entered, please try again';
      }
      return state;
    case ASYNC_START:
      if (action.model === CHECK_CODE) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const schoolInfo = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_CODE) {
        return action.data.schoolInfo || null;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  teamTypes,
  isCodeValid,
  schoolInfo,
  notValidMsg,
});

export default reducer;
