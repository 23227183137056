export const mapPhysiqueUiToApi = fields => ({
  gender: fields.gender,
  grade: fields.grade,
  height: fields.height,
  weight: fields.weight,
  privacy_weight: fields.gender === 'female',
  privacy_height: fields.gender === 'female',
  grad_year: fields.grad_year,
  zip: fields.zip,
  city: fields.city,
  state: fields.state,
  address: fields.address,
});

export default 'physıquePage.models.js';
