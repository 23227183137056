import { combineReducers } from 'redux';
import {
  AUTH2_CURRENT_USER_REQ,
  AUTH2_CURRENT_USER_SUC,
  AUTH2_CURRENT_USER_AWS_ERR,
  AUTH2_LOGOUT_SUC,
  AUTH2_DEL_ACCOUNT_SUC,
  COGNITO_SIGN_IN_SUC,
  COGNITO_DELETE_SUC,
  COGNITO_RENEW_SUC, AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../actions/data/cognito';
import { FORM_SUBMIT_SUCCESS, FORM_SERVER_ERROR, FORM_SUBMIT_PENDING } from '../../actions/ui/formUtils';
import { SIGN_OUT } from '../../actions/ui/auth';

const authenticating = (state = true, action) => {
  switch (action.type) {
    case AUTH2_CURRENT_USER_REQ:
      return true;
    case FORM_SUBMIT_PENDING:
      if (action.model === 'forms.login') {
        return true;
      }
      return state;
    case AUTH2_CURRENT_USER_SUC:
    case AUTH2_CURRENT_USER_AWS_ERR:
    case COGNITO_SIGN_IN_SUC:
      return false;
    default:
      return state;
  }
};

const authMsg = (state = 'Welcome to Virtual Combine', action) => {
  switch (action.type) {
    case AUTH2_CURRENT_USER_REQ:
      return action.msg;
    case AUTH2_CURRENT_USER_SUC:
    case AUTH2_CURRENT_USER_AWS_ERR:
      return '';
    default:
      return state;
  }
};

// let test = 1;
const token = (state = '', action) => {
  switch (action.type) {
    case COGNITO_SIGN_IN_SUC:
    case COGNITO_RENEW_SUC:
      if (action.cognitoUser.signInUserSession) {
        return action.cognitoUser.signInUserSession.accessToken.jwtToken;
      }
      return state;
    case AUTH2_CURRENT_USER_SUC:
      if (action.awsResponse.signInUserSession) {
        // if (test === 1) {
        //   test += 1;
        //   return testToken;
        // }
        return action.awsResponse.signInUserSession.accessToken.jwtToken;
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
    case COGNITO_DELETE_SUC:
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
};

const uuid = (state = '', action) => {
  switch (action.type) {
    case COGNITO_SIGN_IN_SUC:
    case COGNITO_RENEW_SUC:
      if (action.cognitoUser.signInUserSession) {
        return action.cognitoUser.signInUserSession.accessToken.payload.sub;
      }
      return state;
    case AUTH2_CURRENT_USER_SUC:
      if (action.awsResponse.signInUserSession) {
        return action.awsResponse.signInUserSession.accessToken.payload.sub;
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
    case COGNITO_DELETE_SUC:
    case SIGN_OUT:
      return '';
    default:
      return state;
  }
};

const confirmed = (state = 'unknown', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.joinNow') {
        return 'unconfirmed';
      }
      return state;
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.login') {
        if (action.error.code === 'UserNotConfirmedException') {
          return 'unconfirmed';
        }
      }
      return state;
    case COGNITO_SIGN_IN_SUC:
      return 'confirmed';
    case AUTH2_CURRENT_USER_SUC:
      return 'confirmed';
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return 'unknown';
    default:
      return state;
  }
};

const cognitoUser = (state = null, action) => {
  switch (action.type) {
    case COGNITO_SIGN_IN_SUC:
    case COGNITO_RENEW_SUC:
      return action.cognitoUser;
    case AUTH2_CURRENT_USER_SUC:
      return action.awsResponse;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
    case COGNITO_DELETE_SUC:
    case SIGN_OUT:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({
  authenticating,
  authMsg,
  token,
  uuid,
  confirmed,
  cognitoUser,
});

export default reducer;
