import React from 'react';
import PropTypes from 'prop-types';

import StatsCustomControl from '../StatsCustomControl/StatsCustomControl';
import { parserOnlyNumbers, testDistanceChars } from '../../../../utils/parsers';

class StatsInchesControl extends React.PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    rrfField: PropTypes.object,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
    rrfFormChange: PropTypes.func.isRequired,
  }

  onBlurParse = (v) => {
    const { feetInt, inchesInt, decimalString } = this.calculateParts(v);
    const feet = Math.floor(inchesInt / 12) + feetInt;
    const inches = inchesInt % 12;
    return this.formatFromParts(feet, inches, decimalString);
  }

  getWholeInches = (v, offset = 0, end = -1) => {
    let inchesString;
    if (end > 0) {
      inchesString = parserOnlyNumbers(v.slice(offset, end));
    } else {
      inchesString = parserOnlyNumbers(v.slice(offset));
    }
    if (inchesString.length) {
      return parseInt(inchesString, 10);
    }
    return '';
  }

  getInitialFormattedValue = (defaultValue) => {
    if (!defaultValue) return '';
    const { feetInt, inchesInt, decimalString } = this.calcPartsFromInches(defaultValue);
    return this.formatFromParts(feetInt, inchesInt, decimalString);
  }

  formatFromParts = (feet, inches, decimalString) => {
    if (feet) {
      if (inches) {
        if (decimalString) {
          return `${feet}' ${inches}.${decimalString}"`;
        }
        return `${feet}' ${inches}"`;
      }
      if (decimalString) {
        return `${feet}' 0.${decimalString}"`;
      }
      return `${feet}'`;
    }
    if (inches) {
      if (decimalString) {
        return `0' ${inches}.${decimalString}"`;
      }
      return `0' ${inches}"`;
    }
    if (decimalString) {
      return `0' 0.${decimalString}"`;
    }
    return '';
  }

  parser = (v, cursorPos) => {
    const placesAfterDecimal = 2;
    const ftIndex = v.indexOf("'");
    if (ftIndex >= 0) {
      // Handle yes there is a ' marker
      const ftString = parserOnlyNumbers(v.slice(0, ftIndex));
      let feet = 0;
      if (ftString.length) {
        feet = parseInt(ftString, 10);
      }
      const afterFeet = v.slice(ftIndex + 1);
      let inchIndex = afterFeet.indexOf('"');
      if (inchIndex >= 0) {
        // Handle yes there is also a " marker
        inchIndex += ftIndex + 1;
        let dotIndex = afterFeet.indexOf('.');
        if (dotIndex >= 0) {
          // Handle yes there is a decimal point
          dotIndex += ftIndex + 1;
          const wholeInches = this.getWholeInches(v, ftIndex + 1, dotIndex);
          const endIndex = Math.min(inchIndex, dotIndex + 1 + placesAfterDecimal);
          const parsedText = `${feet}' ${wholeInches || '0'}.${
            parserOnlyNumbers(v.slice(dotIndex + 1, endIndex))}"`;
          const diff = parsedText.length - v.length;
          return {
            parsedText,
            cursorPos: cursorPos + diff,
          };
        }
        // Handle yes ' " but no decimal point
        const wholeInches = this.getWholeInches(v, ftIndex + 1, inchIndex);
        const parsedText = `${feet}' ${wholeInches}"`;
        const diff = parsedText.length - v.length;
        return {
          parsedText,
          cursorPos: cursorPos + diff,
        };
      }

      const dotIndex = v.indexOf('.');
      if (dotIndex >= 0) {
        // Handle yes ' and decimal point but no "
        const wholeInches = this.getWholeInches(v, ftIndex + 1, dotIndex);
        const parsedText = `${feet}' ${
          wholeInches}.${
          parserOnlyNumbers(v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal))}"`;
        return {
          parsedText,
          cursorPos,
        };
      }

      // Handle yes ' but no " or decimal point
      const wholeInches = this.getWholeInches(v, ftIndex + 1);
      if (wholeInches) {
        const parsedText = `${feet}' ${wholeInches}"`;
        return {
          parsedText,
          cursorPos,
        };
      }
      const parsedText = `${feet}' `;
      return {
        parsedText,
        cursorPos: cursorPos + 1,
      };
    }

    const dotIndex = v.indexOf('.');
    if (dotIndex >= 0) {
      // Handle yes decimal point but no '
      const wholeInches = this.getWholeInches(v, 0, dotIndex);
      const parsedText = `0' ${wholeInches || 0}.${
        parserOnlyNumbers(v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal))}"`;
      return {
        parsedText,
        cursorPos: cursorPos + 3,
      };
    }

    const inchIndex = v.indexOf('"');
    if (inchIndex >= 0) {
      // Handle yes " but no ' or decimal point
      const wholeInches = this.getWholeInches(v);
      const parsedText = `0' ${wholeInches}"`;
      return {
        parsedText,
        cursorPos: inchIndex + 4,
      };
    }
    // Handle no decimal point or ' or "
    const wholeInches = this.getWholeInches(v);
    const parsedText = `${wholeInches}`;
    return {
      parsedText,
      cursorPos,
    };
  };

  calculateParts = (v) => {
    const ftIndex = v.indexOf("'");
    let feet = 0;
    if (ftIndex >= 0) {
      const ftString = parserOnlyNumbers(v.slice(0, ftIndex));
      if (ftString.length) {
        feet = parseInt(ftString, 10);
      }
      const inchesString = v.slice(ftIndex + 1);
      let dotIndex = inchesString.indexOf('.');
      if (dotIndex >= 0) {
        dotIndex += ftIndex + 1;
        const wholeInches = this.getWholeInches(v, ftIndex + 1, dotIndex);
        const afterDotString = parserOnlyNumbers(v.slice(dotIndex + 1));
        return {
          feetInt: feet,
          inchesInt: wholeInches || 0,
          decimalString: afterDotString,
        };
      }
      const wholeInches = this.getWholeInches(v, ftIndex + 1);
      return {
        feetInt: feet,
        inchesInt: wholeInches || 0,
        decimalString: '',
      };
    }
    const wholeInches = this.getWholeInches(v);
    return {
      feetInt: 0,
      inchesInt: wholeInches || 0,
      decimalString: '',
    };
  }

  normalizeValue = (v) => {
    const { feetInt, inchesInt, decimalString } = this.calculateParts(v);
    return `${(feetInt * 12) + inchesInt}.${decimalString}`;
  }

  calcPartsFromInches = (inches) => {
    const integerPart = Math.floor(inches);
    const decimalPart = inches - integerPart;
    const decimalString = `${Math.round(100 * decimalPart)}`;
    return {
      feetInt: Math.floor(integerPart / 12),
      inchesInt: integerPart % 12,
      decimalString,
    };
  }

  render() {
    const {
      model,
      defaultValue,
      className,
      rrfField,
      rrfFormChange,
    } = this.props;
    return (
      <StatsCustomControl
        className={`${className} StatsInchesControl`}
        model={model}
        rrfField={rrfField}
        defaultValue={defaultValue}
        parser={this.parser}
        allowedCharacters={testDistanceChars}
        help={<div><div>{`Example: 9' 11.25"${''}`}</div><div>{`(Feet' Inches")${''}`}</div></div>}
        onBlurParse={this.onBlurParse}
        normalizeValue={this.normalizeValue}
        getInitialFormattedValue={this.getInitialFormattedValue}
        rrfFormChange={rrfFormChange}
      />
    );
  }
}

export default StatsInchesControl;
