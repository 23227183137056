export const EXTERNAL_PROFILE_SUC = 'external_profile.success';

export const externalProfileSuccess = ( externalProfile, uuid ) => ({
  type: EXTERNAL_PROFILE_SUC,
  externalProfile,
  uuid
})

export const EXTERNAL_PROFILE_CREATE_SUC = 'external_profile.create.success';

export const externalProfileCreateSuccess = (externalProfile, uuid) => ({
  type: EXTERNAL_PROFILE_CREATE_SUC,
  externalProfile,
  uuid,
});

export const EXTERNAL_PROFILE_UPDATE_SUC = 'external_profile.update.success';

export const externalProfileUpdateSuccess = (externalProfile, uuid) => ({
  type: EXTERNAL_PROFILE_UPDATE_SUC,
  externalProfile,
  uuid,
});


export const EXTERNAL_PROFILE_DEL_SUC = 'external_profile.delete.success';

export const externalProfileDeleteSuccess = (externalProfileId, uuid) => ({
  type: EXTERNAL_PROFILE_DEL_SUC,
  externalProfileId,
  uuid,
});
