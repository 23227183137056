/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import { GradeConversion } from '../../+store/coachWorld/coachWorld.constants';
import { UnitFormats } from '../../+store/combine';
import { camelCase, formatResult } from '../../../../containers/UxCommon/Utils';
import { Routes, SportIds } from '../../../../store/actions/ui/routes';
import { convertLbsToKg } from '../../../CodeSignUp/PhysiquePage/PhysiquePage.validation';
import { customTOFixed } from '../../../../store/actions/data/user/profile/models';
import LockIcon from '../../../../assets/images/Lock_icon.png';
import ReactTooltip from 'react-tooltip';
import { formatPositionResponse } from '../../../../store/actions/ui/formUtils/validators';
import { capitalizeLetterAfterSpaceHyphen } from '../../../../store/actions/ui/formUtils/validators';

const PlayerInfo = ({
  expanded,
  onExpandClick,
  athlete,
  measureableColumns,
  profilePictureHeight,
  schoolStyle,
  enableAthleteLink,
  isAllTimeLeaderboard,
  sport,
  isLoggedIn,
  isCoach,
  match,
}) => {
  // console.log(athlete,'asldknakjdbja')
  const name = `${camelCase(athlete.first)} ${camelCase(athlete.last)}`;
  const profileLink = `/${Routes.profile}/${athlete.userId}/${Routes.player}${
    sport ? `/${sport}` : ''
  }${
    match.params.schoolId && !isAllTimeLeaderboard
      ? `/${match.params.schoolId}`
      : athlete && athlete.school ? `/${athlete.school.schoolId}` : ''
  }`;
  const { headshotUrl, position, combineNumber } = athlete;
  const sportId = SportIds[sport];
  let bats = '',
    throws = '';
  if (sportId === 2 || sportId === 4) {
    const userSportDetails = athlete.userSport
      ? athlete.userSport.find((user_sport) => user_sport.sport_id === sportId)
      : null;
    bats = userSportDetails ? userSportDetails.bat : '';
    throws = userSportDetails ? userSportDetails.throw : '';
  }
  let positionDisplay = '';
  if (position) {
    positionDisplay =
      position.length > 24 ? `${position.slice(0, 24)} ...` : position;
  }
  // const grade = GradeConversion[athlete.grade] || '-';
  let height = '';
  const heightCol =
    measureableColumns &&
    measureableColumns.find(
      (c) =>
        c.testCategoryCode === 'HEIGHT' ||
        c.testCategoryCode === 'METRICHEIGHT',
    );
  if (heightCol) {
    const heightResults = athlete.testResults[heightCol.standardTestObjectId];
    height = heightResults
      ? formatResult(heightResults.result, heightCol.standardFormat.code)
      : '';
  }
  if (!height && (athlete.heightFt || athlete.heightIn)) {
    height = `${Math.round(athlete.heightFt) || '0'}' ${
      Math.round(athlete.heightIn) || '0'
    }"`;
  }
  let weight = '';
  const weightCol =
    measureableColumns &&
    measureableColumns.find(
      (c) =>
        c.testCategoryCode === 'WEIGHT' ||
        c.testCategoryCode === 'METRICWEIGHT',
    );
  if (weightCol) {
    const weightResults = athlete.testResults[weightCol.standardTestObjectId];
    weight = weightResults
      ? formatResult(weightResults.result, weightCol.standardFormat.code)
      : '';
  }
  if (!weight) {
    weight = athlete.weight
      ? athlete.isResultInMetricUnit
        ? `${customTOFixed(convertLbsToKg(athlete.weight))} kg.`
        : `${athlete.weight} lbs`
      : '';
  }
  const isNotCollege =
    athlete.grade && (athlete.grade <= 13 || athlete.grade === 'K');

  let showPrivateIcon = !!(
    schoolStyle &&
    !!schoolStyle.isPaid &&
    !athlete.isSubscribed
  );

  if (athlete.belongToRevShareSchool) {
    showPrivateIcon = !athlete.isSubscribed;
  }

  return (
    <button className="LbPlayerInfo" onClick={onExpandClick}>
      <div
        className="LbPlayerInfo_Image"
        style={{
          backgroundImage: headshotUrl
            ? `url(${headshotUrl})`
            : schoolStyle && schoolStyle.leaderboardLogo
            ? `url(${schoolStyle.leaderboardLogo})`
            : '',
          backgroundColor: '#ffffff',
          height: profilePictureHeight,
        }}>
        {!headshotUrl && (!schoolStyle || !schoolStyle.leaderboardLogo) && (
          <Icon
            iconName="default-photo"
            className="LbPlayerInfo_DefaultPhoto"
          />
        )}
      </div>
      <div className="LbPlayerInfo_Text">
        <div className="LbPlayerInfo_NamePosition">
          {
            <Link className="mb-1" to={profileLink} target="_blank">
              {capitalizeLetterAfterSpaceHyphen(name)}
            </Link>
          }
          {showPrivateIcon && (
            <Link to={profileLink} target="_blank">
              <img
                className="private_profile_lock_icon mx-2"
                style={{ maxWidth: '24px', maxHeight: '24px' }}
                src={LockIcon}
                data-place="bottom"
                data-background-color="white"
                data-text-color="black"
                alt="Locked Icon"
                data-tip="Athlete Membership required to see full details"
              />
              <ReactTooltip />
            </Link>
          )}
          <Icon
            iconName="right-arrow"
            className={`LbPlayerInfo_Arrow ${expanded ? 'expanded' : ''}`}
          />
        </div>
        <div className="LbPlayerInfo_OtherInfo">
          {formatPositionResponse(positionDisplay)}
          {combineNumber && <div className="LbPlayerInfo_Separator">|</div>}
          {combineNumber && <div>#{combineNumber}</div>}
        </div>
        <div className={'LbPlayerInfo_BatThrows'}>{`${bats}/${throws}`}</div>
        {!isNotCollege && athlete.gradyear && (
          <div className="LbPlayerInfo_OtherInfo">
            <div>{athlete.gradyear}</div>
          </div>
        )}
        <div className="LbPlayerInfo_OtherInfo">
          {isNotCollege && <div>{athlete.gradyear}</div>}

          {!!athlete.privacyHeight ? (
            isLoggedIn &&
            isCoach &&
            height &&
            isNotCollege && <div className="LbPlayerInfo_Separator">|</div>
          ) : height ? (
            <div className="LbPlayerInfo_Separator">|</div>
          ) : null}

          {!!athlete.privacyHeight ? (
            isLoggedIn && isCoach && <div>{height}</div>
          ) : (
            <React.Fragment>{height}</React.Fragment>
          )}

          {!!athlete.privacyWeight ? (
            isLoggedIn &&
            isCoach &&
            weight && <div className="LbPlayerInfo_Separator">|</div>
          ) : weight ? (
            <div className="LbPlayerInfo_Separator">|</div>
          ) : null}

          {!!athlete.privacyWeight ? (
            isLoggedIn && isCoach && <div>{weight}</div>
          ) : (
            <div> {weight} </div>
          )}
        </div>
        {athlete.school.name && (
          <div className="LbPlayerInfo_OtherInfo">
            <div>{athlete.school.name}</div>
          </div>
        )}
        {athlete.city && athlete.state && (
          <div className="LbPlayerInfo_OtherInfo">
            <div>
              {athlete.city},{athlete.state},{athlete.zip}
            </div>
          </div>
        )}
        {expanded && (
          <div className="LbPlayerInfo_MoreInfo">
            {measureableColumns.map((column, index) => {
              const unit = column.displayStandardUnitOfMeasureCode;
              const testResult =
                athlete.testResults[column.standardTestObjectId];
              let resultDisplay;
              let measureableColumn = '';
              if (testResult) {
                resultDisplay = UnitFormats[unit](testResult.result);
                measureableColumn = (
                  <div>
                    <div className="ShowTextOnDesktop">
                      {' '}
                      {`${column.testCategoryName}:`}{' '}
                    </div>
                    <div className="ShowTextOnMobile">
                      {' '}
                      {`${column.abbreviation}:`}{' '}
                    </div>
                  </div>
                );
              }
              return measureableColumn ? (
                <div key={index} className="LbPlayerInfo_MoreInfoItem">
                  {isLoggedIn && isCoach
                    ? measureableColumn
                    : column.testCategoryName === 'Height'
                    ? !!!athlete.privacyHeight && measureableColumn
                    : column.testCategoryName === 'Weight'
                    ? !!!athlete.privacyWeight && measureableColumn
                    : measureableColumn}
                  {isLoggedIn && isCoach ? (
                    <div>{resultDisplay}</div>
                  ) : column.testCategoryName === 'Height' ? (
                    !!!athlete.privacyHeight ? (
                      <div>{resultDisplay}</div>
                    ) : (
                      ''
                    )
                  ) : column.testCategoryName === 'Weight' ? (
                    !!!athlete.privacyWeight ? (
                      <div>{resultDisplay}</div>
                    ) : (
                      ''
                    )
                  ) : (
                    <div>{resultDisplay}</div>
                  )}
                </div>
              ) : (
                ''
              );
            })}
          </div>
        )}
      </div>
    </button>
  );
};

PlayerInfo.propTypes = {
  expanded: PropTypes.bool.isRequired,
  athlete: PropTypes.object.isRequired,
  measureableColumns: PropTypes.array.isRequired,
  profilePictureHeight: PropTypes.number,
  onExpandClick: PropTypes.func,
  schoolStyle: PropTypes.object,
};

export default PlayerInfo;
