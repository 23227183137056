
export const getRandomInt = (min, max) => Math.floor(Math.random() * ((max - min) + 1)) + min;

export const emailToUsername = email =>
  email.replace('@', '_at_').replace('.', '_dot_');
export const phoneToUsername = phone =>
  phone.replace('+', 'plus_');

// Cognito phone format is +00000000000
export const formatPhoneForCognito = phone =>
  phone.replace(/[ ()-]/g, '');
