import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../config/urls';

export const apiGetReports = (schoolUuid, token) => (
  ajax.getJSON(
    Urls.reports.getAll(schoolUuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiCreateReport = (combine, secondCombine, token) => ajax.post(
  Urls.reports.post(),
  JSON.stringify({
    combine_id: combine,
    second_combine_id: secondCombine,
  }),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const apiGetReport = (reportId, token) => (
  ajax.getJSON(
    Urls.reports.get(reportId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetTeamReport = (reportId, teamId, token) => (
  ajax.getJSON(
    Urls.reports.getTeam(reportId, teamId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetAthleteReport = (reportId, athleteId, token) => (
  ajax.getJSON(
    Urls.reports.getAthlete(reportId, athleteId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'report.api.js';
