import { combineReducers } from 'redux';
import {
  PLAYER_PROFILE_SHOW_BIO,
  PLAYER_PROFILE_HIDE_BIO,
  PLAYER_PROFILE_GET_DATA_SUC,
  GET_FAVORITES,
  PLAYER_POSITION_SHOW_DETAIL,
  PLAYER_POSITION_HIDE_DETAIL,
  PLAYER_POSITION_DETAIL,
  PP_GET_REQ,
  GET_PRIVACY,
  SAVE_PRIVACY,
  GET_PLAYER_TEAMS_AND_DETAILS, PLAYER_PROFILE_ACTIVATION_MEMBERSHIP_MODAL
} from "../../../actions/ui/uxProfile";
import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../actions/ui/async';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { PD_GET_PERSONAL_RECORDS } from '../../../actions/ui/uxPlayerData';

const isBioShown = (state = false, action) => {
  switch (action.type) {
    case PLAYER_PROFILE_SHOW_BIO:
      return true;
    case PLAYER_PROFILE_HIDE_BIO:
      return false;
    default:
      return state;
  }
};

const isPositionDetailOpen = (state = false, action) => {
  switch (action.type) {
    case PLAYER_POSITION_SHOW_DETAIL:
      return true;
    case PLAYER_POSITION_HIDE_DETAIL:
      return false;
    default:
      return state;
  }
};

const showActivationMembershipModal = (state = false, action) => {
  switch (action.type) {
    case PLAYER_PROFILE_ACTIVATION_MEMBERSHIP_MODAL:
      return action.toggle;
    default:
      return state;
  }
};

const playerPositionDetail = (state = '', action) => {
  switch (action.type) {
    case PLAYER_POSITION_DETAIL:
      return action.positionDetail;
    case PLAYER_POSITION_HIDE_DETAIL:
      return '';
    default:
      return state;
  }
};

const vcRating = (state = {}, action) => {
  const rating = {};
  switch (action.type) {
    case PLAYER_PROFILE_GET_DATA_SUC:
      action.data.forEach((data) => {
        rating[data.sport_id] = {
          rating: data.vc_rating.slice(0, -1),
          strength: parseInt(data.strength, 10),
          performance: parseInt(data.performance, 10),
        };
      });
      return rating;
    default:
      return state;
  }
};

const photosLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_FAVORITES) {
        return action.msg || 'Loading photos & videos';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_FAVORITES) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const favoritesLoading = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_FAVORITES) {
        return {
          uuid: action.attrs.uuid,
          sportId: action.attrs.sportId,
        };
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
    case ROUTE_CHANGED:
      if (action.model === GET_FAVORITES) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PP_GET_REQ) {
        return action.msg || 'Loading profile';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === PP_GET_REQ) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const combineLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PD_GET_PERSONAL_RECORDS) {
        return action.msg || 'Loading...';
      }
      case ASYNC_ERROR:
      case ASYNC_FINISH:
        if (action.model === PD_GET_PERSONAL_RECORDS) {
          return '';
        }
    default:
      return state;
  }
};

const privacyLoading = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_START: {
      if (action.model === SAVE_PRIVACY) {
        state[action.attrs.privacyType] = true;
      }
      return state;
    }
    case ASYNC_ERROR:
    case ASYNC_FINISH: {
      if (action.model === SAVE_PRIVACY) {
        state[action.data.incomingPrivacyType] = false;
      }
      return state;
    }
    default:
      return state;
  }
};

const privacy = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH: {
      if (action.model === GET_PRIVACY) {
        return action.data;
      }
      if (action.model === SAVE_PRIVACY) {
        return action.data.privacy;
      }
      return state;
    }
    default:
      return state;
  }
};

const athleteTeamRostersLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_PLAYER_TEAMS_AND_DETAILS) {
        return action.msg || 'Loading team rosters';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_PLAYER_TEAMS_AND_DETAILS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const athleteTeamRosters = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH: {
      if (action.model === GET_PLAYER_TEAMS_AND_DETAILS) {
        return action.data.teamRosterResults;
      }
      return state;
    }
    default:
      return state;
  }
};

const allAthleteTeams = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH: {
      if (action.model === GET_PLAYER_TEAMS_AND_DETAILS) {
        return action.data.allTeamResults;
      }
      return state;
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  isBioShown,
  vcRating,
  photosLoadingMsg,
  isPositionDetailOpen,
  playerPositionDetail,
  loadingMsg,
  combineLoadingMsg,
  favoritesLoading,
  privacy,
  privacyLoading,
  athleteTeamRosters,
  athleteTeamRostersLoadingMsg,
  allAthleteTeams,
  showActivationMembershipModal,
});

export default reducer;
