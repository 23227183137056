import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../UxCommon/Form';
import { CloseButton, Header } from '../../UxCommon';

const YesNoModal = ({
  show,
  onClose,
  onNo,
  onYes,
  title,
  question,
  noTxt,
  yesTxt,
  defaultNo,
}) => (
  show &&
    <div className="UxProfile_YesNoModal_Backdrop">
      <div className="UxProfile_YesNoModal_Modal">
        <CloseButton onClick={onClose} size={14} />
        <div className="UxProfile_YesNoModal_Header">
          <Header>{title}</Header>
        </div>
        <div className="UxProfile_YesNoModal_Question">
          {question}
        </div>
        <div className="UxProfile_YesNoModal_Btns">
          <Button onClick={onNo} className={defaultNo ? 'delete' : ''}>
            {noTxt}
          </Button>
          <Button onClick={onYes} className={defaultNo ? '' : 'delete'}>
            {yesTxt}
          </Button>
        </div>
      </div>
    </div>
);
YesNoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  noTxt: PropTypes.string.isRequired,
  yesTxt: PropTypes.string.isRequired,
  defaultNo: PropTypes.bool,
};

export default YesNoModal;
