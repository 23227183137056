import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { playerProfileGet } from '../../../../store/actions/ui/uxProfile';
import SelectControl from '../../../../modules/CodeSignUp/SimpleForm/SelectControl';
import Inputcontrol from '../InputControl/InputControl.component';
import { getProfile } from '../../../ActivateProfileMembership/ActivateProfileMembership.models';
import Button from '../../../../modules/CodeSignUp/Common/Button';
import { useParams } from "react-router-dom";
import PhoneControlComponent from "../../../../modules/CodeSignUp/SimpleForm/PhoneControl/PhoneControl.component";
import { checkPhone } from '../../../../modules/CodeSignUp/AccountPage/AccountPage.validation';
import { checkUsername } from '../Validation/AthleteInviteOnboardingValidateion';
import { backToPreviousStep, updateInviteProfileFinalForm } from '../+Store/AthletePhysique/AthletePhysique.action';
import { testVanityUrlAction } from '../../../../../src/store/actions/ui/uxEditProfile/vanityUrl/actions';
import {testTwitterHandleAction} from '../../../../../src/store/actions/ui/uxEditProfile/twitterHandle/actions';
import { getTwitterOrInstagramValidation } from "../+Store/AthletePhysique/AthletePhysique.models";
import useInviteSelector from '../../Invite/hooks/useInviteSelector';


const AthleteAcademicPage = (props) => {
    const { loggedIn } = useInviteSelector();
    const { uuid, sportId, userTeamId, schoolId, teamName } = useParams();
    const dispatch = useDispatch();
    const profile = useSelector((state) => getProfile(state, uuid));
    const profileDetails = profile && profile.profileDetails;

    const schoolStyle = useSelector((state) => {
      if (schoolId) {
        return state.modules.coachWorld.coachWorld.schoolStyle.find(
          (school) => school.id === schoolId,
        );
      }
      return null;
    });

  const schoolName = schoolStyle ? schoolStyle.name : 'Virtual Combine';

    useEffect(() => {
        if (!profile) {
          dispatch(playerProfileGet(uuid));
        }
      }, [profile]);

      const [formData, setFormData] = useState({
        ncca_id: profile && profile.ncaaIdNumber || '',
        twitter: profile && profile.twitter || '',
        instagram: profile ? profile.instagram : '',
        school: profile ? profile.school : '',
        gpa: profile ? profile.coreGpa : '',
        act: profile ? profile.act : '',
        sat: profile ? profile.sat : '',
        class_rank: profile ? profile.classRank : '',
        class_size: profile ? profile.classSize : '',
        school_credit: profile ? profile.schoolCredits : '',
        committed_to: profile && profile.sports[0].commits || '',
        club_team: profileDetails ? profileDetails.club_team : '',
        club_coach_name: profileDetails ? profileDetails.club_coach_name : '',
        club_coach_email: profileDetails ? profileDetails.club_coach_email : '',
        club_coach_mobile_phone_number: profileDetails ? profileDetails.club_coach_mobile_phone_number : '',
        high_school_coach_name: profileDetails ? profileDetails.high_school_coach_name : '',
        high_school_coach_email: profileDetails ? profileDetails.high_school_coach_email : '',
        high_school_coach_phone_number: profileDetails ? profileDetails.high_school_coach_phone_number : '',
        selectedCountry: '',
        sport_Id: sportId
      })

      const [errors, setErrors] = useState({
        club_coach_mobile_phone_number: '',
        club_coach_email: '',
        high_school_coach_email: '',
        high_school_coach_phone_number: ''
      })

      const onHandleChnage = type => (value) => {
        switch (type) {
            case 'ncca_id' : {
                const nccaID = value.replace(/[^0-9]+/g, '');
                setFormData({
                    ...formData,
                    [type]: nccaID
                });
                return nccaID;
            }
            case 'twitter': {
                const twitter = `@${value.replace(/[^a-zA-Z0-9\+_-]+/g, '')}`
                setFormData({
                    ...formData,
                    [type]: twitter ? twitter.substring(0,16) : twitter
                });
                return twitter ? twitter.substring(0,16) : twitter
            }
            case 'instagram': {
                const instagram = `@${value.replace(/[^a-zA-Z0-9\+_-]+/g, '')}`
                setFormData({
                    ...formData,
                    [type]: instagram ? instagram.substring(0,50) : instagram
                });
                return instagram ? instagram.substring(0,50) : instagram;
            }
            case 'school': {
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
            case 'gpa': {
                const gpa = value.replace(/[^\d.]/g, '')
                setFormData({
                    ...formData,
                    [type]: gpa
                });
                return gpa;
            }
            case 'act': {
                const act = value.replace(/[^0-9]+/g, '')
                setFormData({
                    ...formData,
                    [type]: act
                });
                return act;
            }
            case 'sat': {
                const sat = value.replace(/[^0-9]+/g, '')
                setFormData({
                    ...formData,
                    [type]: sat
                });
                return sat;
            }
            case 'class_rank': {
                const classRank = value.replace(/[^0-9]+/g, '')
                setFormData({
                    ...formData,
                    [type]: classRank
                });
                return classRank;
            }
            case 'class_size': {
                const classSize = value.replace(/[^0-9]+/g, '')
                setFormData({
                    ...formData,
                    [type]: classSize
                });
                return classSize;
            }
            case 'school_credit': {
                const schoolCredit = value.replace(/[^0-9]+/g, '')
                setFormData({
                    ...formData,
                    [type]: schoolCredit
                });
                return schoolCredit;
            }
            case 'committed_to': {
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
            case 'club_team': {
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
            case 'club_coach_name': {
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
            case 'club_coach_email': {
                setErrors({
                    ...errors,
                    club_coach_email: checkUsername(value)
                })
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
            case 'high_school_coach_name': {
                setFormData({
                    ...formData,
                    high_school_coach_name: value
                })
                return value;
            }
            case 'high_school_coach_email': {
                setErrors({
                    ...errors,
                    high_school_coach_email: checkUsername(value)
                })
                setFormData({
                    ...formData,
                    [type]: value
                });
                return value;
            }
        }
      }

      const onClubCoachPhoneBlur = (value, data, event, formattedValue) => {
        const parsedPhone = formattedValue ? formattedValue : value;
        setErrors({
            ...errors,
            club_coach_mobile_phone_number:  checkPhone(parsedPhone)
        })
        setFormData({
            ...formData,
            club_coach_mobile_phone_number: parsedPhone,
            selectedCountry: data ? data.countryCode : ''
        })
        return parsedPhone;
      }

      const onHighSchoolCoachPhoneBlur = (value, data, event, formattedValue) => {
        const parsedPhone = formattedValue ? formattedValue : value;

        setErrors({
            ...errors,
            high_school_coach_phone_number:  checkPhone(parsedPhone)
        })
        setFormData({
          ...formData,
          high_school_coach_phone_number: parsedPhone,
          selectedCountry: data ? data.countryCode : ''
        })
        return parsedPhone;
      }

      const onBackClick = () => {
        dispatch(backToPreviousStep(uuid))
      }

      const onSubmitClick = (event) => {
        let success = true;
        Object.values(errors).forEach((errorList) => {
            if (errorList && errorList.length > 0 && errorList[0] !== null && !!errorList[0]) {
              success = false;
            }
          });
          if (success)  {
            dispatch(updateInviteProfileFinalForm(uuid, formData));
          }

        event.preventDefault();

      }
      return(
        <div className="container welcome-first">
            <div className="row">
                <div className="col">
                    <div className="welcome-msg">Welcome to { schoolName }!</div>
                    <div className="welcome-desc">
                        {`You've been invited to join ${teamName.replaceAll('+', ' ')}`}
                    </div>
                </div>
            </div>
            <div className="AccountPage OnboardingStep2Page">
            <form  className="PhysiquePage_Form" onSubmit={onSubmitClick}>

                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="NCCA ID Number"
                    placeholder="NCCA ID Number"
                    name="ncca_id"
                    myOnChange={onHandleChnage('ncca_id')}
                    myOnBlur={onHandleChnage('ncca_id')}
                    defaultValue={(profile && profile.ncaaIdNumber) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Twitter"
                    placeholder="Twitter"
                    name="twitter"
                    myOnChange={onHandleChnage('twitter')}
                    myOnBlur={onHandleChnage('twitter')}
                    defaultValue={profile && `@${profile.twitter.replace(/[^a-zA-Z0-9\+_-]+/g, '')}` || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Instagram"
                    placeholder="Instagram"
                    name="instagram"
                    myOnChange={onHandleChnage('instagram')}
                    myOnBlur={onHandleChnage('instagram')}
                    defaultValue={profile && `@${profile.instagram.replace(/[^a-zA-Z0-9\+_-]+/g, '')}` || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="School"
                    placeholder="School"
                    name="school"
                    myOnChange={onHandleChnage('school')}
                    myOnBlur={onHandleChnage('school')}
                    defaultValue={(profile && profile.school) || ''}

                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="GPA"
                    placeholder="GPA"
                    name="gpa"
                    myOnChange={onHandleChnage('gpa')}
                    myOnBlur={onHandleChnage('gpa')}
                    defaultValue={(profile && profile.coreGpa) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="ACT"
                    placeholder="ACT"
                    name="act"
                    myOnChange={onHandleChnage('act')}
                    myOnBlur={onHandleChnage('act')}
                    defaultValue={(profile && profile.act) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="SAT"
                    placeholder="SAT"
                    name="sat"
                    myOnChange={onHandleChnage('sat')}
                    myOnBlur={onHandleChnage('sat')}
                    defaultValue={(profile && profile.sat) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Class Rank"
                    placeholder="Class Rank"
                    name="class_rank"
                    myOnChange={onHandleChnage('class_rank')}
                    myOnBlur={onHandleChnage('class_rank')}
                    defaultValue={(profile && profile.classRank) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Class Size"
                    placeholder="Class Size"
                    name="class_size"
                    myOnChange={onHandleChnage('class_size')}
                    myOnBlur={onHandleChnage('class_size')}
                    defaultValue={(profile && profile.classSize) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="School Credits"
                    placeholder="School Credits"
                    name="school_credit"
                    myOnChange={onHandleChnage('school_credit')}
                    myOnBlur={onHandleChnage('school_credit')}
                    defaultValue={(profile && profile.schoolCredits) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Committed To"
                    placeholder="Committed To"
                    name="committed_to"
                    myOnChange={onHandleChnage('committed_to')}
                    myOnBlur={onHandleChnage('committed_to')}
                    defaultValue={(profile && profile.sports[0].commits) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Club Team"
                    placeholder="Club Team"
                    name="club_team"
                    myOnChange={onHandleChnage('club_team')}
                    myOnBlur={onHandleChnage('club_team')}
                    defaultValue={(profileDetails && profileDetails.club_team) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Club Coach Name"
                    placeholder="Club Coach Name"
                    name="club_coach_name"
                    myOnChange={onHandleChnage('club_coach_name')}
                    myOnBlur={onHandleChnage('club_coach_name')}
                    defaultValue={(profileDetails && profileDetails.club_coach_name) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="Club Coach Email"
                    placeholder="Club Coach Email"
                    name="club_coach_email"
                    myOnChange={onHandleChnage('club_coach_email')}
                    myOnBlur={onHandleChnage('club_coach_email')}
                    errors={errors.club_coach_email}
                    defaultValue={(profileDetails && profileDetails.club_coach_email) || ''}
                />
                <PhoneControlComponent
                    className="AccountPage_TextControl"
                    placeholder="Club Coach Mobile Number"
                    label="Club Coach Mobile Number"
                    name="club_coach_mobile_phone_number"
                    myOnBlur={onClubCoachPhoneBlur}
                    myOnChange={onClubCoachPhoneBlur}
                    defaultCountry={formData.selectedCountry}
                    defaultValue={(profileDetails && profileDetails.club_coach_mobile_phone_number) || ''}
                    errors= {errors && errors.club_coach_mobile_phone_number}
                    // errors={errors.phone}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="High School Coach Name"
                    placeholder="High School Coach Name"
                    name="high_school_coach_name"
                    myOnChange={onHandleChnage('high_school_coach_name')}
                    myOnBlur={onHandleChnage('high_school_coach_name')}
                    defaultValue={(profileDetails && profileDetails.high_school_coach_name) || ''}
                />
                <Inputcontrol
                    className="AccountPage_TextControl"
                    label="High School Coach Email"
                    placeholder="High School Coach Email"
                    name="high_school_coach_email"
                    myOnChange={onHandleChnage('high_school_coach_email')}
                    myOnBlur={onHandleChnage('high_school_coach_email')}
                    errors={errors.high_school_coach_email}
                    defaultValue={(profileDetails && profileDetails.high_school_coach_email) || ''}
                />
                <PhoneControlComponent
                    className="AccountPage_TextControl"
                    placeholder="High School Coach Mobile Number"
                    label="High School Coach Mobile Number"
                    name="high_school_coach_phone_number"
                    myOnBlur={onHighSchoolCoachPhoneBlur}
                    myOnChange={onHighSchoolCoachPhoneBlur}
                    defaultCountry={formData.selectedCountry}
                    defaultValue={(profileDetails && profileDetails.high_school_coach_phone_number) || ''}
                    errors={errors.high_school_coach_phone_number}
                />
                <div className="row">
                    <Button
                        type="button"
                        className="PhysiquePage_SubmitBtn"
                        onClick={onBackClick}
                        >
                        Back
                    </Button>
                    <Button
                        type="button"
                        className="PhysiquePage_SubmitBtn"
                        onClick={onSubmitClick}
                        >
                        Submit
                    </Button>
                </div>
            </form>
            </div>
        </div>
      )

}


export default AthleteAcademicPage;
