import React from 'react';
import PropTypes from 'prop-types';

const TableCornerCell = ({
  className,
  style,
  children,
  isHeader,
}) => isHeader ? (
  <th
    className={['CoachTableCell', className || ''].join(' ')}
    style={style || {}}
  >
    <div className="CoachTableCornerCell">
      {children}
    </div>
  </th>
) : (
  <td
    className={['CoachTableCell', className || ''].join(' ')}
    style={style || {}}
  >
    <div className="CoachTableCornerCell">
      {children}
    </div>
  </td>
);
TableCornerCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  isHeader: PropTypes.bool,
};

export default TableCornerCell;
