import { ajax } from 'rxjs/ajax';
import Urls from '../../../../config/urls';

export const apiGetNorthEastLeaderboardResults = (token, action) => (
  ajax.getJSON(
    Urls.northEastLeaderboard.results(action),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  )
);

export default 'northEastLeaderboard.api';

