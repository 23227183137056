import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Control } from 'react-redux-form';
import moment from 'moment';

import {
  DatePicker,
  TextControl,
  GeneralErrors,
  CheckboxControl,
  SelectControl,
} from '../../../../../../../UxCommon/Form';
import { ButtonRect, LinkButton } from '../../../../../../../UxCommon';
import HeightEntry from '../../../../../../../UxCommon/Form/HeightEntry/HeightEntry';
import FieldLbsRps from '../../../../../../../UxCommon/Form/FieldLbsRps/FieldLbsRps';
import {parserOnlyNumeric,parserLocation, parserOnlyNumbers, parserMax100Value, defaultHourMinuteParser, parserMax1Value, parserMax5Value , parserOnlyNegativeNumeric, negativeParserMax100Value} from '../../../../../../../../utils/parsers';
import MetricHeightEntry from '../../../../../../../UxCommon/Form/MetricHeightEntry/MetricHeightEntry';
import {
  convertInchesToMetre,
  convertLbsToKg
} from '../../../../../../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation';
import { customTOFixed } from '../../../../../../../../store/actions/data/user/profile/models';

const defaultRrfField = {
  errors: {},
  valid: true,
};

class CreateUpdateForm extends PureComponent {
  static propTypes = {
    stdTestObject: PropTypes.object.isRequired,
    formId: PropTypes.number.isRequired,
    submit: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    getRrfForm: PropTypes.func,
    getRrfFormModel: PropTypes.func.isRequired,
    onDateChanged: PropTypes.func.isRequired,
    onFormFieldChanged: PropTypes.func.isRequired,
    onAddAnotherClick: PropTypes.func.isRequired,
    testResult: PropTypes.object,
    number: PropTypes.number.isRequired,
    mostRecentHeight: PropTypes.string.isRequired,
    mostRecentWeight: PropTypes.string.isRequired,
    isCoach: PropTypes.bool,
    isMetricUnits: PropTypes.bool.isRequired,
  }

  state={
    videoIcon:false,
    videoCount: 1,
    videoLength:0
  }

  onSubmit = () => {
    const { submit, formId } = this.props;
    submit(formId);
  };

  onAddAnotherClick = () => {
    const { onAddAnotherClick, formId } = this.props;
    onAddAnotherClick(formId);
  }

  onDeleteClick = () => {
    const { testResult, onDeleteClick } = this.props;
    onDeleteClick(testResult);
  }

  onDateChanged = (date) => {
    const { onDateChanged, formId } = this.props;
    onDateChanged(formId, date);
  }
  onFormFieldChanged = (field, value) => {
    const { onFormFieldChanged, formId } = this.props;
    onFormFieldChanged(formId, field, value);
  }

  isEditable = () => {
    const { testResult, isCoach } = this.props;
    const isEditable = !testResult || testResult.testIsEditable === 1 || isCoach;
    return isEditable;
  };

  renderResultField() {
    const {
      formId,
      getRrfForm,
      getRrfFormModel,
      stdTestObject,
      testResult,
    } = this.props;
    testResult && testResult.standardStatFormatCode
    const isEditable = this.isEditable();
    const rrfForm = getRrfForm(formId);
    const formModel = getRrfFormModel(formId);

    if ((stdTestObject.cat.unit.code === 'SECONDS') &&
        (testResult && testResult.standardStatFormatCode !== "NEGATIVE-TIME-SEC-HUNDREDTHS"
        || stdTestObject.cat.standardTestFormat[0].code !== "NEGATIVE-TIME-SEC-HUNDREDTHS")) {
      return (
        <div className="row">
          <div style={{ flex: '50%', paddingLeft: '15px' }}>
            <TextControl
              disabled={!isEditable}
              label="Result (sec)"
              placeholder="Enter Result (sec)"
              model={`${formModel}.value`}
              rrfField={rrfForm.value || defaultRrfField}
              defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
              parser={parserOnlyNumeric}
            />
          </div>
          {stdTestObject.cat.isLaser &&
            <div style={{ padding: '15px' }}>
              <CheckboxControl
                disabled={!isEditable}
                label="Laser Time?"
                model={`${formModel}.laserTime`}
                help="A .175 second penalty will be added to non-laser times."
                rrfField={rrfForm.laserTime || defaultRrfField}
                defaultValue={(testResult && testResult.testResultLaserBool) ? `${testResult.testResultLaserBool}` : ''}
              />
            </div>
            }
        </div>
      );
    }  else if ((stdTestObject.cat.unit.code === 'SECONDS') &&
               (testResult && testResult.standardStatFormatCode === "NEGATIVE-TIME-SEC-HUNDREDTHS"
               || stdTestObject.cat.standardTestFormat[0].code === "NEGATIVE-TIME-SEC-HUNDREDTHS")) {
        return (
        <div className="row">
          <div style={{ flex: '50%', paddingLeft: '15px' }}>
            <TextControl
              disabled={!isEditable}
              label="Result (sec)"
              placeholder="Enter Result (sec)"
              model={`${formModel}.value`}
              rrfField={rrfForm.value || defaultRrfField}
              defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
              parser={parserOnlyNegativeNumeric}
            />
          </div>
        </div>
      )
    } else if (stdTestObject.cat.unit.code === 'INCHES') {
      return (
        <TextControl
          disabled={!isEditable}
          label="Result (in)"
          placeholder="Enter Result (in)"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
          parser={parserOnlyNumeric}
        />
      );
    }
    else if (stdTestObject.cat.unit.code === 'FEET-INCHES') {
      let feet = null;
      let inches = null;
      if(testResult){
        const integerPart = Math.floor(testResult.testResultResult);
        const decimalPart = testResult.testResultResult - integerPart;
        const decimalString = Math.round(100 * decimalPart)/100;
        feet = Math.floor(integerPart / 12);
        inches = (integerPart % 12) + decimalString;
      }


      return (
        <div className="UxCommon_Form_Group UxCommon_Form_Group_Flex" style={{flexWrap: 'wrap'}}>
          <div className={[
            'UxCommon_Form_InputGroup',
            'UxCommon_Form_InputGroup_Flex',
          ].join(' ')}
               style={{marginLeft: '2px', flex: '1 0 21%'}}
          >
              <TextControl
                disabled={!isEditable}
                label="Feet"
                placeholder="Enter Feet"
                model={`${formModel}.value`}
                rrfField={rrfForm.value || defaultRrfField}
                defaultValue={feet ? `${feet}` : ''}
                parser={parserOnlyNumeric}
              />
          </div>
          <div style={{marginBottom: '12px'}}> & </div>
          <div style={{marginLeft: '2px', flex: '1 0 21%'}} className={[
            'UxCommon_Form_InputGroup',
            'UxCommon_Form_InputGroup_Flex',
          ].join(' ')}>
              <TextControl
                disabled={!isEditable}
                label="Inch"
                placeholder="Enter Inch"
                model={`${formModel}.reps`}
                rrfField={rrfForm.reps || defaultRrfField}
                defaultValue={inches ? `${inches}` : 0}
                parser={parserOnlyNumeric}
              />
          </div>
        </div>
      );
    }
    else if ( stdTestObject.cat.unit.code === 'POUNDS' || stdTestObject.cat.unit.code === "KG" ) {
      return (
          <FieldLbsRps
            disabled={!isEditable}
            label={stdTestObject.cat.unit.code === 'POUNDS' ? "Raw (Lbs)": "Raw (kg)"}
            modelLbs={`${formModel}.value`}
            modelRps={`${formModel}.reps`}
            rrfFieldLbs={rrfForm.value || defaultRrfField}
            rrfFieldRps={rrfForm.reps || defaultRrfField}
            testResult={testResult}
            onFormFieldChanged={this.onFormFieldChanged}
          />
      );
    } else if ((stdTestObject.cat.unit.code === 'COUNT' || stdTestObject.cat.unit.code === 'COUNT-TENTHS') &&
               (testResult && testResult.standardStatFormatCode !== "NEGATIVE-NUMERIC-HUNDREDTHS"
               || stdTestObject.cat.standardTestFormat[0].code !== "NEGATIVE-NUMERIC-HUNDREDTHS")) {
      return (
        <TextControl
          disabled={!isEditable}
          label="Count"
          placeholder="Enter Count"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
          parser={stdTestObject.cat.unit.code === 'COUNT'
          && testResult &&
          Number.isInteger((testResult.displayValueWithLabel).valueOf())
          ? parserOnlyNumbers : parserOnlyNumeric}
        />
      );
    } else if ((stdTestObject.cat.unit.code === 'COUNT' || stdTestObject.cat.unit.code === 'COUNT-TENTHS') &&
                (testResult && testResult.standardStatFormatCode === "NEGATIVE-NUMERIC-HUNDREDTHS"
               || stdTestObject.cat.standardTestFormat[0].code === "NEGATIVE-NUMERIC-HUNDREDTHS")){
      return (
        <TextControl
          disabled={!isEditable}
          label="Count"
          placeholder="Enter Count"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
        />
    );
    } else if (stdTestObject.cat.unit.code === 'PERCENTAGE' &&
              (testResult && testResult.standardStatFormatCode === "NEGATIVE-PERCENTAGE-HUNDREDTHS"
              || stdTestObject.cat.standardTestFormat[0].code === "NEGATIVE-PERCENTAGE-HUNDREDTHS"))
    {
      return (
        <TextControl
          disabled={!isEditable}
          label="Result"
          placeholder="Enter Result"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
          parser={ negativeParserMax100Value }
        />
      );
    } else if (stdTestObject.cat.unit.code === 'PERCENTAGE' &&
        (testResult && testResult.standardStatFormatCode !== "NEGATIVE-PERCENTAGE-HUNDREDTHS"
        || stdTestObject.cat.standardTestFormat[0].code !== "NEGATIVE-PERCENTAGE-HUNDREDTHS"))
    {
      return (
      <TextControl
        disabled={!isEditable}
        label="Result"
        placeholder="Enter Result"
        model={`${formModel}.value`}
        rrfField={rrfForm.value || defaultRrfField}
        defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
        parser={ stdTestObject.cat.standardTestFormat[0].code === "DECIMAL-PERCENTAGE"  ? parserMax1Value
                : stdTestObject.cat.standardTestFormat[0].code === "TOURNAMENT-STAT-DECIMAL-PERCENTAGE" ? parserMax5Value
                : parserMax100Value }
        />
      );
    } else if (stdTestObject.cat.unit.code === 'HOURS-MINUTES')
    {
      return (
        <TextControl
          disabled={!isEditable}
          label="Result"
          placeholder="Enter Result"
          model={`${formModel}.hourMinute`}
          rrfField={rrfForm.hourMinute || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${ defaultHourMinuteParser( testResult.testResultResult )}` : ''}
          // parser={parserMax100Value}
        />
      );
      // validateHourTime
    }
     else if (stdTestObject.cat.unit.code === 'DEGREES')
    {
      return (
        <TextControl
          disabled={!isEditable}
          label="Result"
          placeholder="Enter Result"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${( testResult.testResultResult )}` : ''}
          // parser={parserMax100Value}
        />
      );
    }
    {
      return (
        <TextControl
          disabled={!isEditable}
          label="Result"
          placeholder="Enter Result"
          model={`${formModel}.value`}
          rrfField={rrfForm.value || defaultRrfField}
          defaultValue={(testResult && testResult.testResultResult) ? `${( testResult.testResultResult )}` : ''}
          // parser={parserMax100Value}
        />
      );
    }
    return (
      <TextControl
        disabled={!isEditable}
        label="Result"
        placeholder="Enter Result"
        model={`${formModel}.value`}
        rrfField={rrfForm.value || defaultRrfField}
        defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
        parser={parserOnlyNumeric}
      />
    );
  }

  render() {
    const {
      formId,
      getRrfForm,
      getRrfFormModel,
      stdTestObject,
      testResult,
      number,
      mostRecentHeight,
      mostRecentWeight,
      isMetricUnits,
      commonSchool,
      isCoach
    } = this.props;
    let isSchoolDropDown = (testResult && testResult.hasCombine) ? testResult.hasCombine : false;
    const isEditable = this.isEditable();
    const rrfForm = getRrfForm(formId);
    const formModel = getRrfFormModel(formId);
    let mrWeight = mostRecentWeight.toString();
    let mrWeightKg = mrWeight;
    if (Number.isNaN(Number(mostRecentWeight))) {
      mrWeight = '';
      mrWeightKg = '';
    }
    let mrHeightM = mostRecentHeight.toString();
    if (Number.isNaN(Number(mostRecentHeight))) {
      mrHeightM = '';
    }

    // Fix Control for new
    const newProps = { value: '' };
    if (!!testResult && rrfForm.new === undefined) {
      newProps.value = !testResult;
    }

    // Fix Control for new
    const testResultProps = { value: '' };
    if (testResult && testResult !== undefined && rrfForm.testResult === undefined) {
      testResultProps.value = testResult;
    }

    // Fix Control for new
    const stdTestObjIdProps = { value: '' };
    if (stdTestObject.id !== undefined && rrfForm.stdTestObjectId === undefined) {
      stdTestObjIdProps.value = stdTestObject.id;
    }

    return (
      <React.Fragment>
        <div
          className="UxPlayerData_TestData_CreateUpdateForm_Title"
          hidden={!isEditable}
        >
          {
            testResult
              ? `Edit ${stdTestObject.cat.name} Data #${number}`
              : `Add ${stdTestObject.cat.name} Data #${number}`
          }
        </div>
        <Form model={formModel} onSubmit={this.onSubmit}>
        {!isCoach && <TextControl
              disabled={!isEditable}
              label="Location/Event"
              placeholder="Enter Location/Event"
              model={`${formModel}.location`}
              rrfField={rrfForm.location || defaultRrfField}
              defaultValue={(testResult && testResult.location) ? testResult.location : ''}
              parser={parserLocation}
          />}
          <DatePicker
            disabled={!isEditable}
            label="Test Date"
            placeholder="Enter Test Date"
            model={`${formModel}.date`}
            rrfField={rrfForm.date || defaultRrfField}
            onDateChange={this.onDateChanged}
            defaultValue={(testResult && testResult.resultDate) ? testResult.resultDate : moment()}
          />
          { commonSchool && ((commonSchool.length >1) ?
            <SelectControl
              label="School"
              placeholder="Select a School"
              showPlaceHolder={true}
              rrfField={rrfForm.school || defaultRrfField}
              model={`${formModel}.school`}
              defaultValue={testResult && testResult.schoolId}
              disabled={isSchoolDropDown}
              options={commonSchool.map(school => ({ value: school.id, text: school.name }))}
            />
            :
            <Control
              type="hidden"
              model={`${formModel}.school`}
              defaultValue={!!commonSchool.length && commonSchool[0].id || " "}
              {...newProps}
            />)
            }
          {this.renderResultField(stdTestObject)}
          {isMetricUnits ? (
            <MetricHeightEntry
              disabled={!isEditable}
              testResult={testResult}
              rrfForm={rrfForm}
              model={`${formModel}.height`}
              modelMt={`${formModel}.height_Mt`}
              modelCm={`${formModel}.height_Cm`}
              rrfField={rrfForm.height || defaultRrfField}
              onFormFieldChanged={this.onFormFieldChanged}
              mostRecentHeight={mrHeightM || ''}
            />
          ) : (
            <HeightEntry
              disabled={!isEditable}
              testResult={testResult}
              rrfForm={rrfForm}
              model={`${formModel}.height`}
              modelFt={`${formModel}.height_ft`}
              modelIn={`${formModel}.height_in`}
              rrfField={rrfForm.height || defaultRrfField}
              onFormFieldChanged={this.onFormFieldChanged}
              mostRecentHeight={mostRecentHeight || ''}
            />
            )}
          {isMetricUnits ? (
            <TextControl
              disabled={!isEditable}
              label="Body Weight (kg)"
              placeholder="Enter Body Weight (kg)"
              model={`${formModel}.weight`}
              rrfField={rrfForm.weight || defaultRrfField}
              defaultValue={(testResult && testResult.test.weight) ? `${testResult.test.weight}` : mrWeight}
              parser={parserOnlyNumeric}
            />
          ) : (
            <TextControl
              disabled={!isEditable}
              label="Body Weight (lbs)"
              placeholder="Enter Body Weight (lbs)"
              model={`${formModel}.weight`}
              rrfField={rrfForm.weight || defaultRrfField}
              defaultValue={(testResult && testResult.test.weight) ? `${testResult.test.weight}` : mrWeight}
              parser={parserOnlyNumeric}
            />
            )}
          <Control
            type="hidden"
            model={`${formModel}.new`}
            defaultValue={!testResult}
            {...newProps}
          />
          <Control
            type="hidden"
            model={`${formModel}.testResult`}
            defaultValue={testResult || ''}
            {...testResultProps}
          />
          <Control
            type="hidden"
            model={`${formModel}.stdTestObjectId`}
            defaultValue={stdTestObject.id}
            {...stdTestObjIdProps}
          />
          <Control
            type="hidden"
            model={`${formModel}.testRange`}
            defaultValue={stdTestObject.cat.standardTestRange}
            {...stdTestObjIdProps}
          />
          <GeneralErrors rrfForm={getRrfForm(formId)} />
          <div
            className="UxPlayerData_TestData_CreateUpdateForm_Btns"
            hidden={!isEditable}
          >
        {
          testResult &&
          <div style={{ flexGrow: 1, textAlign: 'left' }}>
            <LinkButton iconName="delete" onClick={this.onDeleteClick} type="button">
              Delete Data
            </LinkButton>
          </div>
        }
            <ButtonRect
              type="button"
              className="UxPlayerData_TestData_CreateUpdateForm_Btn"
              onClick={this.onAddAnotherClick}
            >
              Add Another
            </ButtonRect>
            <ButtonRect
              className="UxPlayerData_TestData_CreateUpdateForm_Btn primary"
            >
              Save Data
            </ButtonRect>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

export default CreateUpdateForm;
