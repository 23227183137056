import React from 'react';
import logo from '../../../assets/images/vc-black.png';
import Icon from '../Icon/Icon';

const LeaderboardPrivacyPage = ({schoolStyle}) =>
  (
    <div className="UxCommon_ErrorPage PrivatePublicLeaderboard">
      <div className="UxCommon_ErrorPage_Fg">
        <div className="UxCommon_ErrorPage_TxtWrapper">
          <div className="UxCommon_ErrorPage_Title">
            Private Leaderboard
          </div>
          <div className='d-lg-flex'>
            <div className="UxCommon_ErrorPage_Msg col-lg-9">
              <Icon iconName="lock" />
              This leaderboard has been made private by the Coach.
            </div>
            <a className='col-lg-3' href="/profile">
              {
                schoolStyle && schoolStyle.leaderboardLogo ?
                  (<img className="UxCommon_ErrorPage_Logo UxCommon_Org_Logo" src={schoolStyle.leaderboardLogo} alt={schoolStyle.name} />)
                  :
                  (<img className="UxCommon_ErrorPage_Logo" src={logo} alt="logo" />)
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );

export default LeaderboardPrivacyPage;
