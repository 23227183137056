export const SIDEBAR_SHOW_MENU = 'uxProfile.sidebar.showMenu';
export const sidebarShowMenu = () => ({
  type: SIDEBAR_SHOW_MENU,
});

export const ENABLE_CALENDLY_MODAL = 'uxProfile.enableCalendlyModal';
export const enableCalendlyModal = (val) =>({
  type: ENABLE_CALENDLY_MODAL,
  val,
});

export const SIDEBAR_HIDE_MENU = 'uxProfile.sidebar.hideMenu';
export const sidebarHideMenu = () => ({
  type: SIDEBAR_HIDE_MENU,
});

export const SIDEBAR_FOCUS_SEARCH = 'uxProfile.sidebar.focusSearch';
export const sidebarFocusSearch = () => ({
  type: SIDEBAR_FOCUS_SEARCH,
});

export const SIDEBAR_UNFOCUS_SEARCH = 'uxProfile.sidebar.unfocusSearch';
export const sidebarUnfocusSearch = () => ({
  type: SIDEBAR_UNFOCUS_SEARCH,
});

export const SIDEBAR_SHOW_INFO = 'uxProfile.sidebar.showInfo';
export const sidebarShowInfo = () => ({
  type: SIDEBAR_SHOW_INFO,
});
export const SIDEBAR_HIDE_INFO = 'uxProfile.sidebar.hideInfo';
export const sidebarHideInfo = () => ({
  type: SIDEBAR_HIDE_INFO,
});

export const SIDEBAR_SHOW_LOGOUT_DIALOG = 'uxProfile.sidebar.showLogoutDialog';
export const sidebarShowLogoutDialog = () => ({
  type: SIDEBAR_SHOW_LOGOUT_DIALOG,
});
export const SIDEBAR_HIDE_LOGOUT_DIALOG = 'uxProfile.sidebar.hideLogoutDialog';
export const sidebarHideLogoutDialog = () => ({
  type: SIDEBAR_HIDE_LOGOUT_DIALOG,
});

export const SIDEBAR_OPEN_SHARE_DIALOG = 'uxProfile.sidebar.openShareDialog';
export const sidebarOpenShareDialog = uri => ({
  type: SIDEBAR_OPEN_SHARE_DIALOG,
  uri,
});
export const SIDEBAR_CLOSE_SHARE_DIALOG = 'uxProfile.sidebar.closeShareDialog';
export const sidebarCloseShareDialog = () => ({
  type: SIDEBAR_CLOSE_SHARE_DIALOG,
});
