import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../UxCommon/Icon/Icon';
import { sortedGrade } from '../../../UxCommon/Form/utils';
import { parseGPA } from '../../../../utils/parsers';

class AcademicBox extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    this.initState();
  }

  onClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };
  getInfo = user => (
    [
      ['Grade', sortedGrade[user.grade]],
      ['Grad Year', user.gradYear],
      ['ACT', user.act],
      ['SAT', user.sat],
      ['Core GPA', parseGPA(user.coreGpa)],
      ['School Credits', user.schoolCredits],
      ['Class Rank', user.classRank],
      ['Class Size', user.classSize],
    ]
  );

  initState = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { user } = this.props;
    const { open } = this.state;
    return (
      <div className="PlayerCard_AcademicInfo">
        <button
          className="PlayerCard_AcademicInfo_Row PlayerCard_AcademicInfo_Btn"
          onClick={this.onClick}
        >
          <div className="PlayerCard_AcademicInfo_Label">Academic Info</div>
          <Icon
            iconName="down-chevron"
            className={[
              'PlayerCard_AcademicInfo_Arrow',
              open ? 'open' : '',
            ].join(' ')}
          />
        </button>
        {
          user && open && this.getInfo(user)
            .map(([label, value]) => (
              <div key={label} className="PlayerCard_AcademicInfo_Row">
                <div className="PlayerCard_AcademicInfo_Label">
                  {label}
                </div>
                <div className="PlayerCard_AcademicInfo_Value">
                  {value}
                </div>
              </div>
            ))
        }
      </div>
    );
  }
}

export default AcademicBox;
