import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, LinkButton, ButtonIconOnly } from '../../../UxCommon';
import { Errors } from '../../../UxCommon/Form';
import MoveAlbumDialog from '../../../../containers/UxProfile/Photos/MoveAlbumDialog';

class MultiDeleteBanner extends PureComponent {
  static propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onExecuteClick: PropTypes.func.isRequired,
    photoList: PropTypes.array.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    multiDeleteError: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
    selected: PropTypes.array.isRequired,
    isMoveAlbumDialogOpened: PropTypes.bool.isRequired,
    onOpenMoveAlbumDialogClick: PropTypes.func.isRequired,
  }

  componentDidMount() {
  }

  onSelectAllClick = () => {
    const { photoList, onSelectAllClick } = this.props;
    onSelectAllClick(photoList);
  }

  onMoveToAlbumClick = () => {
    const { onOpenMoveAlbumDialogClick } = this.props;
    onOpenMoveAlbumDialogClick();
  }

  render() {
    const {
      onCancelClick,
      onExecuteClick,
      loadingMsg,
      multiDeleteError,
      stage,
      selected,
      isMoveAlbumDialogOpened,
    } = this.props;
    let txt;
    if (stage === 'open') {
      if (multiDeleteError) {
        txt = (
          <Errors
            valid={!multiDeleteError}
            errors={{ error: multiDeleteError }}
            className="Photos_MultiDeleteBanner_Errors"
          />
        );
      } else {
        txt = loadingMsg || 'Select all of the photos/videos you want to delete';
      }
    } else if (stage === 'results') {
      txt = `${selected.length} of your photos / videos have been deleted`;
    } else {
      txt = '';
    }

    return (
      <div className="Photos_MultiDeleteBanner">
        {isMoveAlbumDialogOpened && <MoveAlbumDialog /> }
        <div className="Photos_MultiDeleteBanner_LeftTxt">
          {txt}
        </div>
        {
          stage === 'open' ? (
            !loadingMsg && (
              <React.Fragment>
                <LinkButton
                  className="Photos_MultiDeleteBanner_SelectAllBtn"
                  onClick={this.onSelectAllClick}
                >
                  Select All
                </LinkButton>
                <ButtonRect
                  className="Photos_MultiDeleteBanner_CancelBtn"
                  onClick={onCancelClick}
                >
                  Cancel
                </ButtonRect>
                <ButtonRect
                  className="Photos_MultiDeleteBanner_DeleteBtn"
                  onClick={onExecuteClick}
                >
                  Delete Items
                </ButtonRect>
                <ButtonRect
                  className="Photos_MultiMove2AlbumBanner_Move2AlbumBtn"
                  onClick={this.onMoveToAlbumClick}
                >
                  Move To Album
                </ButtonRect>
              </React.Fragment>
            )
          ) : (
            <ButtonIconOnly
              iconName="close"
              className="Photos_MultiDeleteBanner_XBtn"
              onClick={onCancelClick}
            />
          )
        }
      </div>
    );
  }
}

export default MultiDeleteBanner;
