import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { POST_MESSAGE, POP_MESSAGE, GET_USER_TEAMS, GET_SCHOOL_STYLES, GET_SCHOOL_TEAMS_LIST, SAVE_COACH_PROFILE, SHOW_UPLOAD_PHOTO_DIALOG, HIDE_UPLOAD_PHOTO_DIALOG, UPDATE_SCHOOL_STYLES, PHOTO_DROP_SCHOOL_STYLE, RESET_UPDATING_STYLE_STATUS, GET_COACH_AND_ATHLETE_TEAMS, UPLOAD_PROGRESS_SCHOOL_STYLE } from './coachWorld.actions';
import { getRandomInt } from '../../../../utils';
import { EP_PHOTO_SAVE_NEW_ATHLETE } from '../roster/roster.photo.actions';
import { schoolTeamListId } from '../combine/combine.utils';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_USER_TEAMS) {
        return 'Loading ...';
      } else if (action.model === SAVE_COACH_PROFILE) {
        return 'Saving ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_USER_TEAMS ||
        (action.model && action.model.type === SAVE_COACH_PROFILE)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingSchools = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_SCHOOL_STYLES) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_STYLES) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const isUploadPhotoDialogVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_UPLOAD_PHOTO_DIALOG:
      return true;
    case HIDE_UPLOAD_PHOTO_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (action.model === EP_PHOTO_SAVE_NEW_ATHLETE) {
        return false;
      }
      if (action.model === PHOTO_DROP_SCHOOL_STYLE) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case POST_MESSAGE:
      return [...state, {
        id: getRandomInt(0, 100000),
        message: action.message,
        logLevel: action.logLevel,
      }];
    case POP_MESSAGE:
      if (action.messageId) {
        return state.filter(m => m.id !== action.messageId);
      }
      return state.slice(1);
    default:
      return state;
  }
};

const myTeams = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_USER_TEAMS) {
        return action.data.myTeams || [];
      } else if (action.model === EP_PHOTO_SAVE_NEW_ATHLETE && action.data.isCoach) {
        // Todo: what is this?
        const { headshotUrl } = action.data[0];
        return state.map((team) => {
          team.headshotUrl = headshotUrl;
          return team;
        });
      }
      return state;
    default:
      return state;
  }
};

const canCoachAddImageForAthlete = (state = false, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COACH_AND_ATHLETE_TEAMS) {
        return action.data.result || false;
      }
      return state;
    default:
      return state;
  }
};

const schoolStyle = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_STYLES) {
        return action.data.schoolStyle || [];
      }
      if (action.model === UPDATE_SCHOOL_STYLES) {
        const newStyle = action.data.schoolStyle;
        const i = state.findIndex(s => s.id === newStyle.id);
        state[i] = Object.assign({}, state[i], {
          color1: newStyle.color1,
          color2: newStyle.color2,
          background_image: newStyle.background_image,
          school_logo: newStyle.school_logo,
        });
        return [...state];
      }
      return state;
    default:
      return state;
  }
};

const loadingSchoolTeamsListMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_SCHOOL_TEAMS_LIST) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_TEAMS_LIST) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const shouldUpdateSchoolTeamsList = (state = [], action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_SCHOOL_TEAMS_LIST) {
        const obj = schoolTeamListId(action.attrs.schoolId);
        return [
          ...state.filter(s => (
            (s.key !== obj.key)
          )),
          {
            id: obj.id,
            key: obj.key,
            value: Date.now(),
          },
        ];
      }
      return state;
    case ASYNC_ERROR:
      return state;
    default:
      return state;
  }
};

const schoolTeamsLists = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_TEAMS_LIST) {
        if (!action.data.schoolTeams) return state;
        return Object.assign({}, state, {
          [action.data.schoolId]: action.data.schoolTeams,
        });
      }
      return state;
    default:
      return state;
  }
};

const updateStyle403Error = (state = false, action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === UPDATE_SCHOOL_STYLES) {
        return true;
      }
      return state;
    default:
      return false;
  }
};

const updatingStyleStatus = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPDATE_SCHOOL_STYLES) {
        return 'Updating';
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === UPDATE_SCHOOL_STYLES) {
        return 'Finish Updating';
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === UPDATE_SCHOOL_STYLES) {
        return 'Updating Error';
      }
      return state;
    case RESET_UPDATING_STYLE_STATUS:
      return '';
    default:
      return state;
  }
};

const photoUploadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PHOTO_DROP_SCHOOL_STYLE) {
        return 'Loading ...';
      }
      return state;
    case UPLOAD_PROGRESS_SCHOOL_STYLE:
      if (action.msg) {
        return action.msg;
      }
      if (action.progressEvent && action.progressEvent.loaded && action.progressEvent.total) {
        const { loaded, total } = action.progressEvent;
        const percent = Number((loaded / total) * 100).toFixed(0);
        return `${percent} %`;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === PHOTO_DROP_SCHOOL_STYLE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  messages,
  myTeams,
  schoolStyle,
  loadingSchools,
  schoolTeamsLists,
  shouldUpdateSchoolTeamsList,
  loadingSchoolTeamsListMsg,
  isUploadPhotoDialogVisible,
  updateStyle403Error,
  updatingStyleStatus,
  canCoachAddImageForAthlete,
  photoUploadingMsg,
});

export default reducer;
