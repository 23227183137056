import React from 'react';
import PropTypes from 'prop-types';

import CustomIcon from '../Icon/CustomIcon';

const CustomLinkButton = ({
  iconName,
  children,
  className,
  disable,
  toolTip,
  leftButtonClassName,
  style,
  ...props
}) => (
  <React.Fragment>
  <button disabled={disable} className={['UxCommon_LinkButton', className || ''].join(' ')} {...props}>
    {
      iconName &&
        <div className={["UxCommon_LinkButton_Left", leftButtonClassName || ''].join(' ')}>
          <CustomIcon iconName={iconName} tooltip={toolTip} style={style} />
        </div>
    }
    <div className="UxCommon_LinkButton_Right">
      {children}
    </div>
  </button>
  </React.Fragment>
);
CustomLinkButton.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default CustomLinkButton;
