import { of } from 'rxjs';
import { switchMap , mergeMap} from "rxjs/operators";
import {updateProfile} from './AthletePhysique.api';
import { combineEpics, ofType } from "redux-observable";
import { UPDATE_INVITE_PROFILE, UPDATE_INVITE_PROFILE_FORM_FINAL } from './AthletePhysique.action';
import { asyncFinishAction } from "../../../../../../src/store/actions/ui/async";
import { playerProfileGet } from "../../../../../store/actions/ui/uxProfile";
import { mapPhysiqueProfileDataUiToApi , mapAcademicProfileDataUiToApi } from './AthletePhysique.models';
import { updateInviteProfileFinalFormAPI } from './AthleteAcademic.api';
import { manualCancelApiCallFragment, getTokenFragment } from '../../../../../../src/store/actions/ui/uxProfile/utils';

import { userUpdatePhysique } from '../../../../../../src/store/actions/data/user';

const playerProfileUpdateEpics  = action$ => (
    action$.pipe(
        ofType(UPDATE_INVITE_PROFILE),
        getTokenFragment(),
        switchMap(({action, token}) => {
          return updateProfile(
                action.uuid,
                token,
                mapPhysiqueProfileDataUiToApi(action.profileData, action.teamData),
              ).pipe(manualCancelApiCallFragment(
                action$,
                action,
                'playerProfileUpdate',
              ))
        }),
        switchMap((result) => {
            if (result.success) {
                return of(
                  playerProfileGet(result.action.uuid),
                  asyncFinishAction(result.action.type, UPDATE_INVITE_PROFILE , {}),
                  );
              }
              if (result.actions) return of(...result.actions);
              return of(result.action);
            }),
    )
);


const updateInviteProfileFinalFormEpics = action$ => (
  action$.pipe(
      ofType(UPDATE_INVITE_PROFILE_FORM_FINAL),
      getTokenFragment(),
      switchMap(({action, token}) => {
        return updateInviteProfileFinalFormAPI(
              action.uuid,
              token,
              mapAcademicProfileDataUiToApi(action.profileData),
            ).pipe(manualCancelApiCallFragment(
              action$,
              action,
              'updateInviteProfileFormFinal',
            ))
      }),
      switchMap((result) => {
          if (result.success) {
              return of(
                asyncFinishAction(result.action.type, UPDATE_INVITE_PROFILE_FORM_FINAL , {uuid: result.action.uuid}),
                );
            }
            if (result.actions) return of(...result.actions);
            return of(result.action);
          }),
  )
);

const epics  = combineEpics(playerProfileUpdateEpics, updateInviteProfileFinalFormEpics)

export default epics;
