import React from 'react';
import logo from '../../../assets/images/vc-black.png';
import Icon from '../Icon/Icon';

const PrivacyPage = () =>
  (
    <div className="UxCommon_ErrorPage">
      <div className="UxCommon_ErrorPage_Fg">
        <div className="UxCommon_ErrorPage_TxtWrapper">
          <div className="UxCommon_ErrorPage_Title">
            Private Profile
          </div>
          <div className="UxCommon_ErrorPage_Msg">
            <Icon iconName="lock" />
            This profile is private!
          </div>
          {/* <a href="/profile">
            <img className="UxCommon_ErrorPage_Logo" src={logo} alt="logo" />
          </a> */}
        </div>
      </div>
    </div>
  );

export default PrivacyPage;
