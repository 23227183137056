import { combineEpics } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../../../async';

import { cancelOnRouteChange, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { getRrfChangeValue, ofTypeOfModel } from '../../../formUtils/operators';
import { mapTeamsApiToUiFlat, typeAheadGetTeams } from '../../../../data/sport/teams';
import { teamTypeAheadResults } from '../actions';

const typeAheadTeamsEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  const form = () => state$.value.forms.firstSeason;
  return action$.pipe(
    ofTypeOfModel('rrf/change', 'forms.firstSeason.teamName'),
    getTokenFragment(),
    map(({ action, token }) => ({ token, action, value: getRrfChangeValue(action) })),
    switchMap((actionn) => {
      if (form().team && (actionn.value === form().team.googleName)) {
        return of({
          success: false,
          action: teamTypeAheadResults([]),
        });
      }
      if (!actionn.value) {
        return of({
          success: false,
          action: teamTypeAheadResults([]),
        });
      }
      return of(
        {
          success: false,
          action: asyncStartAction('forms.firstSeason.team', 'teamsTypeAhead'),
        },
        {
          success: true,
          actionn,
        },
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const { actionn } = results;
        return typeAheadGetTeams(actionn.value, actionn.token).pipe(
          map(response => mapTeamsApiToUiFlat(response)),
          map(teams => ({ success: true, teams })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction('forms.firstSeason.team', 'teamsTypeAhead', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          teamTypeAheadResults(results.teams),
          actions.change('forms.firstSeason.team', null),
          asyncFinishAction('forms.firstSeason.team', 'teamsTypeAhead', {}),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const readEpics = combineEpics(typeAheadTeamsEpic);

export const dummy = '';
