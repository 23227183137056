import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';

import { cognitoSignIn, cognitoSignInSuccess } from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateIsPhoneOrEmail,
  validateLength,
  validatePhone,
  validateRequired,
} from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';
import { emailToUsername, formatPhoneForCognito, phoneToUsername } from '../../../../utils/cognito';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { emailNeedsConfirmed, phoneNeedsConfirmed } from '../uxEditProfile/account';
import { getPlayerProfile } from '../../data/user';
import { sportGetSports } from '../../data/sport';
import { checkForkErrors, commonApiCallFragment } from '../uxProfile/utils';
import { Routes } from '../routes';
import { mapUserApiToUi, Roles } from '../../data/user/profile/models';
import { resendEmailPhoneValidation } from '../uxEditProfile/confirmEmailPhone';

const signInFormValidation = {
  'forms.login.username': [
    value => validateLength(1)(value),
    value => validateIsPhoneOrEmail()(value),
    value => validateRequired()(value),
  ],
  'forms.login.password': [
    value => validateRequired()(value),
  ],
};

const signInSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.login;
   console.log(state$, 'state');
  const formUsername = () => form().username.toLowerCase();
  return action$.pipe(
    isPendingIsValid(state$, 'forms.login', 'login'),
    switchMap((action) => {
      let cognitoUsername = formUsername();
      return from(cognitoSignIn(
        cognitoUsername,
        form().password,
      )).pipe(
        map(awsResponse => ({
          success: true,
          awsResponse,
          username: cognitoUsername,
          action,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError((error) => {
          if (error.code === 'UserNotFoundException') {
            if (validatePhone()(formUsername()).phone === '') {
              cognitoUsername = phoneToUsername(formatPhoneForCognito(formUsername()));
            } else {
              cognitoUsername = emailToUsername(formUsername());
            }
            return from(cognitoSignIn(
              cognitoUsername,
              form().password,
            )).pipe(map(awsResponse => ({
              success: true,
              awsResponse,
              username: formUsername(),
              action,
            })));
          }
          throw error;
        }),
        catchError(error => of({
          success: false,
          action: formServerError('forms.login', 'aws', error, {
            username: formUsername(),
            password: form().password,
          }),
        })),
      );
    }),
    switchMap((result) => {
      if (result.success) {
        return forkJoin(
          getPlayerProfile(
            result.awsResponse.signInUserSession.accessToken.payload.sub,
            result.awsResponse.signInUserSession.idToken.jwtToken,
          ).pipe(commonApiCallFragment(action$, result.action, 'getPlayerProfile', Routes.player)),
          sportGetSports()
            .pipe(commonApiCallFragment(action$, result.action, 'getSports', Routes.player)),
        ).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                ...result,
              };
            }
            return forkResults;
          }),
        );
      }
      return of(result);
    }),
    switchMap(({
      success,
      awsResponse,
      action,
      actions,
      username,
      results,
    }) => {
      if (success) {
        const playerProfile = mapUserApiToUi(results[0].response);
        if (playerProfile.roleId === Roles.coach && action.attrs.isMobile) {
          return of(formServerError('forms.login', 'aws', 'Coaches can not login using the mobile app.', {
            username: formUsername(),
            password: form().password,
          }));
        }
        const successActions = [
          cognitoSignInSuccess(awsResponse),
          formSubmitSuccess('forms.login', {
            awsResponse,
            username,
            from: action.attrs.from,
            uuid: awsResponse.signInUserSession.accessToken.payload.sub,
            playerProfile,
            state: state$
          }),
        ];
        if (awsResponse.signInUserSession.idToken.payload.phone_number &&
          !awsResponse.signInUserSession.idToken.payload.phone_number_verified) {
          successActions.push(phoneNeedsConfirmed());
          successActions.push(resendEmailPhoneValidation(true));
        }
        if (awsResponse.signInUserSession.idToken.payload.email &&
          !awsResponse.signInUserSession.idToken.payload.email_verified) {
          successActions.push(emailNeedsConfirmed());
          successActions.push(resendEmailPhoneValidation(false));
        }
        return of(...successActions);
      }
      if (actions) return of(...actions);
      return of(action);
    }),
  );
};

export const signInFormEpic = combineEpics(
  signInSubmitEpic,
  validationOnSubmitEpicFactory(signInFormValidation, 'forms.login', 'login'),
  validationFailedEpicFactory('forms.login', 'login'),
  ...formValidationEpicsFactory(signInFormValidation, 'login'),
  usernameOnChangeEpic('forms.login.username', state => state.forms.login.username),
);

export const SIGN_IN_FORM_INIT = 'SIGN_IN_FORM_INIT';
export const signInFormInit = () => ({
  type: SIGN_IN_FORM_INIT,
});
