/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import Fuse from 'fuse.js';

import { RosterSortByOptions } from './roster.constants';
import {
  getAthletes,
  getCoaches,
  getFilters,
  getLoadingProfiles,
  getProfiles, getSchoolTeams,
  getSearch,
  getSortBy,
} from '../coachWorld/coachWorld.utils';

export const selectProfiles = createSelector(
  getProfiles,
  getLoadingProfiles,
  (profiles, loading) => {
    if (!loading) {
      return profiles;
    }
    return null;
  },
);

export const getProfilesSelectorCreator = (getPeople) =>
  createSelector(getPeople, getProfiles,getSchoolTeams, (athletes, profiles,schoolTeams) => {
    // console.log('roster.selector create selector', athletes, "\n profile ", profiles);
    const athletesWithProfiles = [];
    if (profiles) {
      athletes.forEach((athlete) => {
        const team = schoolTeams.find(e => e.id === athlete.schoolTeamId);
        const profile = profiles[athlete.userId];
        if (profile) {
          const clonedAthlete = { ...athlete };
          clonedAthlete.inviteOrder = clonedAthlete && addInviteStatus(profile.isSubscribed, athlete.invited)
          clonedAthlete.profileId = profile.profileId;
          clonedAthlete.first = profile.first;
          clonedAthlete.last = profile.last;
          clonedAthlete.grade = profile.grade;
          clonedAthlete.gender = profile.gender;
          clonedAthlete.gradYear = profile.gradYear;
          clonedAthlete.jerseyNumber = profile.jerseyNumber;
          clonedAthlete.address = profile.address;
          clonedAthlete.city = profile.city;
          clonedAthlete.state = profile.state;
          clonedAthlete.combineNumber = athlete.combineNumber;
          clonedAthlete.jerseyNumber = athlete.jerseyNumber;
          clonedAthlete.twitter = profile.twitter;
          clonedAthlete.instagram = profile.instagram;
          clonedAthlete.ncaaIdNumber = profile.ncaaIdNumber;
          clonedAthlete.email = profile.email;
          clonedAthlete.invited = athlete.invited;
          clonedAthlete.phone = profile.phone;
          clonedAthlete.parentsPhone = profile.parentsPhone;
          clonedAthlete.parents2Phone = profile.parents2Phone;
          clonedAthlete.parentsName = profile.parentsName;
          clonedAthlete.parent2Name = profile.parent2Name;
          clonedAthlete.parentsEmail = profile.parentsEmail;
          clonedAthlete.parent2Email = profile.parent2Email;
          clonedAthlete.zip = profile.zip;
          clonedAthlete.createdAt = profile.createdAt;
          clonedAthlete.commits = athlete.commits;
          clonedAthlete.school = profile.school;
          clonedAthlete.coreGpa = profile.coreGpa;
          clonedAthlete.act = profile.act;
          clonedAthlete.sat = profile.sat;
          clonedAthlete.classRank = profile.classRank;
          clonedAthlete.classSize = profile.classSize;
          clonedAthlete.schoolCredits = profile.schoolCredits;
          clonedAthlete.heightFt = profile.heightFt;
          clonedAthlete.heightInch = profile.heightInch;
          clonedAthlete.weight = profile.weight;
          clonedAthlete.clubTeam = profile.profileDetails ? profile.profileDetails.club_team : '';
          clonedAthlete.clubCoachName = profile.profileDetails ? profile.profileDetails.club_coach_name : '';
          clonedAthlete.clubCoachEmail = profile.profileDetails ? profile.profileDetails.club_coach_email : '';
          clonedAthlete.highSchoolCoachName = profile.profileDetails ? profile.profileDetails.high_school_coach_name : '';
          clonedAthlete.highSchoolCoachEmail = profile.profileDetails ? profile.profileDetails.high_school_coach_email : '';
          clonedAthlete.clubCoachMobilePhoneNumber = profile.profileDetails ? profile.profileDetails.club_coach_mobile_phone_number : '';
          clonedAthlete.highSchoolCoachPhoneNumber = profile.profileDetails ? profile.profileDetails.high_school_coach_phone_number : '';
          clonedAthlete.teamName = team && team.name;
          clonedAthlete.teamRefId = team && team.teamRefId;
          clonedAthlete.sportId = team && team.sportId;
          clonedAthlete.isPrivateTeam = team && team.isPrivate;
          clonedAthlete.teamOrderBy = team && team.teamRef.orderby;
          athletesWithProfiles.push(clonedAthlete);
        } else {
          athlete.inviteOrder = athlete && addInviteStatus(athlete.isSubscribed, athlete.invited)
          athlete.teamName = team && team.name;
          athlete.teamRefId = team && team.teamRefId;
          athlete.sportId = team && team.sportId;
          athlete.teamOrderBy = team && team.teamRef.orderby;
          athlete.isPrivateTeam = team && team.isPrivate;
          athlete.clubTeam = athlete.profileDetails ? athlete.profileDetails.club_team : '';
          athlete.clubCoachName = athlete.profileDetails ? athlete.profileDetails.club_coach_name : '';
          athlete.clubCoachEmail = athlete.profileDetails ? athlete.profileDetails.club_coach_email : '';
          athlete.highSchoolCoachName = athlete.profileDetails ? athlete.profileDetails.high_school_coach_name : '';
          athlete.highSchoolCoachEmail = athlete.profileDetails ? athlete.profileDetails.high_school_coach_email : '';
          athlete.clubCoachMobilePhoneNumber = athlete.profileDetails ? athlete.profileDetails.club_coach_mobile_phone_number : '';
          athlete.highSchoolCoachPhoneNumber = athlete.profileDetails ? athlete.profileDetails.high_school_coach_phone_number : '';
          athletesWithProfiles.push(athlete);
        }
      });
      return athletesWithProfiles;
    }
    return athletes;
  });

const addInviteStatus = (isSubscribed, invited) => {
  if (isSubscribed && invited === 2) return 5;
  if (isSubscribed && invited === 1) return 4;
  if (isSubscribed && invited === 0) return 3;
  if (!isSubscribed && invited === 1) return 2;
  return 1;
}

const sortTwoStringWithFallback = (
  a,
  b,
  aFb = null,
  bFb = null,
  desc = false,
) => {
  const flip = desc ? -1 : 1;
  const res = a.trim().toLowerCase().localeCompare(b.trim().toLowerCase());
  if (!res && aFb && bFb) {
    return (
      aFb.trim().toLowerCase().localeCompare(bFb.trim().toLowerCase()) * flip
    );
  }
  return res * flip;
};

function combineNumberAthleteSorting(allAthletes, sortBy) {
  athleteWithoutCombineNumber = [];
  athleteNumericCombineNumber = [];
  athleteAlphanumCombineNumber = [];
  const numericRegex = /^[0-9\b]+$/;

  allAthletes.map( athlete => (
    (athlete.combineNumber ?
      (numericRegex.test(athlete.combineNumber) ?
      athleteAlphanumCombineNumber.push(athlete)
        : athleteAlphanumCombineNumber.push(athlete))
      : athleteWithoutCombineNumber.push(athlete))
  ), {});

  athleteAlphanumCombineNumber.sort((a,b) => {
    const valueA =  a.combineNumber ? a.combineNumber.toLowerCase().replaceAll(' ','').replace(/\s/g, ''): '';
    const valueB = b.combineNumber ? b.combineNumber.toLowerCase().replaceAll(' ','').replace(/\s/g, '') : '';
    if (valueA === valueB) {
      if(a.combineNumber.toLowerCase() === valueA) {
        return -1;
      }
      return 1;
    }
    if(sortBy === 'combineNumberLowHigh') {
      if (valueA < valueB) {
        return -1;
      }
      return 1;
    }
    if (valueA < valueB) {
      return 1;
    }
    return -1;
  });
  const sortAlphaNum1 = (a, b) => a.combineNumber.replaceAll(' ','').localeCompare(b.combineNumber.replaceAll(' ',''), 'en', { numeric: true })
  athleteAlphanumCombineNumber.sort(sortAlphaNum1)
  if(sortBy === 'combineNumberHighLow') {
    athleteAlphanumCombineNumber = athleteAlphanumCombineNumber.reverse();
  }
  athleteNumericCombineNumber.sort(function(a,b) {
    if(sortBy === 'combineNumberLowHigh') {
      return a.combineNumber-b.combineNumber;
    }
    return b.combineNumber-a.combineNumber;
  })

  athleteWithoutCombineNumber.sort((a,b) => {
    const valueA = a.familyName;
    const valueB = b.familyName;
    if (valueA === valueB) {
      return 0;
    }
    if(sortBy === 'combineNumberLowHigh') {
      if (valueA < valueB) {
        return -1;
      }
      return 1;
    }
    if (valueA < valueB) {
      return 1;
    }
    return -1;
  });

  if(sortBy === 'combineNumberHighLow') {
    return [ ...athleteWithoutCombineNumber, ...athleteNumericCombineNumber,...athleteAlphanumCombineNumber];
  }
  return [ ...athleteAlphanumCombineNumber, ...athleteNumericCombineNumber,...athleteWithoutCombineNumber];
}

export const sortAthletes = (athletes, sortBy) => {
  const sortedAthletes = athletes.slice();
  let allAthletes = sortedAthletes;
  sortedAthletes.sort((a, b) => {
    let {
      givenName,
      familyName,
      first,
      last,
      name,
      jerseyNumber,
      jersey_number,
      combineNumber,
      isSubscribed,
    } = a;

    const givenNameA =
      givenName || first || (name && name.split(' ')[0]) || 'zzz';
    const familyNameA =
      familyName ||
      last ||
      (name && name.split(' ').slice(1).join(' ')) ||
      'aaa';
    const jerseyA = jerseyNumber || jersey_number;
    const combineNumberA = combineNumber;
    const isSubscribedA = isSubscribed;
    ({
      givenName,
      familyName,
      first,
      last,
      name,
      jerseyNumber,
      jersey_number,
      combineNumber,
      isSubscribed,
    } = b);
    const givenNameB =
      givenName || first || (name && name.split(' ')[0]) || 'zzz';
    const familyNameB =
      familyName ||
      last ||
      (name && name.split(' ').slice(1).join(' ')) ||
      'zzz';
    const jerseyB = jerseyNumber || jersey_number;
    const combineNumberB = combineNumber;
    const isSubscribedB = isSubscribed;
    switch (sortBy) {
      case RosterSortByOptions.firstNameAZ:
        return sortTwoStringWithFallback(
          givenNameA,
          givenNameB,
          familyNameA,
          familyNameB,
        );
      case RosterSortByOptions.firstNameZA:
        return sortTwoStringWithFallback(
          givenNameA,
          givenNameB,
          familyNameA,
          familyNameB,
          true,
        );
      case RosterSortByOptions.lastNameAZ:
        return sortTwoStringWithFallback(
          familyNameA,
          familyNameB,
          givenNameA,
          givenNameB,
        );
      case RosterSortByOptions.lastNameZA:
        return sortTwoStringWithFallback(
          familyNameA,
          familyNameB,
          givenNameA,
          givenNameB,
          true,
        );
      case RosterSortByOptions.numberLowHigh:
        if (jerseyA === jerseyB) {
          return 0;
        }
        if (Number(jerseyA) < Number(jerseyB)) {
          return -1;
        }
        return 1;
      case RosterSortByOptions.numberHighLow:
        if (jerseyA === jerseyB) {
          return 0;
        }
        if (Number(jerseyA) < Number(jerseyB)) {
          return 1;
        }
        return -1;
      case RosterSortByOptions.registered:
        if (isSubscribedA === isSubscribedB) {
          return 0;
        }
        if (Number(isSubscribedA) < Number(isSubscribedB)) {
          return 1;
        }
        return -1;
      case RosterSortByOptions.nonRegistered:
        if (isSubscribedA === isSubscribedB) {
          return 0;
        }
        if (Number(isSubscribedA) < Number(isSubscribedB)) {
          return -1;
        }
        return 1;
      default:
        if (
          a.testResults &&
          a.testResults[sortBy] &&
          b.testResults &&
          b.testResults[sortBy]
        ) {
          // sort from highest to lowest
          return b.testResults[sortBy].result - a.testResults[sortBy].result;
        }
        if (a.orderByTeam && b.orderByTeam) {
          return a.orderByTeam - b.orderByTeam;
        }
        return 0;
    }
  });

  if (sortBy === 'combineNumberLowHigh' || sortBy === 'combineNumberHighLow') {
    return combineNumberAthleteSorting(allAthletes, sortBy);
  }
  return sortedAthletes;
};

export const getFilteredSearchedSelectorCreator = (getPeople) =>
  createSelector(
    getPeople,
    getFilters,
    getSearch,
    (athletes, filters, search) => {
      // Left-hand filters
      let filteredAthletes = athletes.slice();
      if (filters) {
        filters.forEach((filter) => {
          filteredAthletes = filteredAthletes.filter((a) => {
            const positions = [];

            if (filter.name === 'events') {
              return true;
            }

            if (filter.name === 'sportId') {
              if (filter.selected.includes(a.sportId)) {
                return true;
              }
            }

            if (a[filter.name] && filter.name === 'position') {
              a[filter.name]
                .split(',')
                .filter((str) => positions.push(str.trim()));
            } else if (a[filter.name]) {
              positions.push(a[filter.name]);
            }

            return filter.selected.find((f) => positions.includes(f));
          });
        });
      }

      // Search (ignore sort if search present)
      if (search) {
        const options = {
          keys: [
            'name',
            'givenName',
            'familyName',
            'position',
            'combineNumber',
          ],
          shouldSort: true,
          minMatchCharLength: search.trim().length,
          includeMatches: true,
          threshold: 0.0,
        };
        const fuse = new Fuse(filteredAthletes, options);
        return fuse
          .search(search.trim())
          .filter((athArr) => athArr.matches.length > 0)
          .map((ath) => ath.item);
      }
      return filteredAthletes;
    },
  );

export const getFilteredSearchedSortedSelectorCreator = (getPeople) =>
  createSelector(
    getPeople,
    getFilters,
    getSearch,
    getSortBy,
    (athletes, filters, search, sortBy) => {
      // Left-hand filters
      let filteredAthletes = athletes.slice();
      if (filters) {
        filters.forEach((filter) => {
          filteredAthletes = filteredAthletes.filter((a) => {
            const positions = [];

            if (filter.name === 'events') {
              return true;
            }
            if (filter.name === 'sportId') {
              if (filter.selected.includes(a.sportId)) {
                return true;
              }
            }
            if (filter.name === 'gradYear') {
              if (filter.selected.includes(a.gradyear)) {
                return true;
              }
            }
            if (a[filter.name] && filter.name === 'position') {
              a[filter.name]
                .split(',')
                .filter((str) => positions.push(str.trim()));
            } else if (a[filter.name]) {
              positions.push(a[filter.name]);
            }

            if (a['sportId'] && filter.name === 'bats') {
              const user_sport = a.userSport
                ? a.userSport.find((sport) => sport.sport_id === a.sportId)
                : '';
              if (filter.selected.includes(user_sport.bat)) {
                return true;
              }
            }

            if (a['sportId'] && filter.name === 'throws') {
              const user_sport = a.userSport
                ? a.userSport.find((sport) => sport.sport_id === a.sportId)
                : '';
              if (filter.selected.includes(user_sport.throw)) {
                return true;
              }
            }

            return filter.selected.find((f) => positions.includes(f));
          });
        });
      }

      // Search (ignore sort if search present)
      if (search) {
        const options = {
          keys: [
            'name',
            'first',
            'last',
            'position',
            'combineNumber',
            'givenName',
            'familyName',
          ],
          shouldSort: true,
          minMatchCharLength: search.trim().length,
          includeMatches: true,
          threshold: 0.0,
        };
        const fuse = new Fuse(filteredAthletes, options);
        return fuse
          .search(search.trim())
          .filter((athArr) => athArr.matches.length > 0)
          .map((ath) => ath.item);
      }
      // Sort
      return sortAthletes(filteredAthletes, sortBy);
    },
  );

// Arrange athletes by team
export const getByTeamSelectorCreator = (getPeople) =>
  createSelector(getPeople, (athletes) => {
    const teams = {};
    athletes.forEach((athlete) => {
      if (athlete.schoolTeamId in teams) {
        teams[athlete.schoolTeamId].push(athlete);
      } else {
        teams[athlete.schoolTeamId] = [athlete];
      }
    });
    return teams;
  });

export const getAllTeamBySelectorCreator =  (getPeople) =>
  createSelector(getPeople,(athleteWithTeams) =>{
  const allTeams = [];
  athleteWithTeams.forEach((athlete)=>{
    if(!athlete.isPrivateTeam){
      const clonedAthlete = { ...athlete };
      allTeams.push(clonedAthlete);
    }
  });
  return allTeams.sort((a, b) => {
    const teamComp = () => {
      const comp = a.teamOrderBy - b.teamOrderBy;
      if (!comp && a.teamName) {
        // Natural sort if both are within same order because teams' name are alphanumerical
        return a.teamName.localeCompare(b.teamName, undefined, {numeric: true, sensitivity: 'base'})
      }
      return comp;
    }
    return teamComp() !== 0 ? teamComp() : a.name.localeCompare(b.name);
  });
});

const getCurrentCoachUuid = state => state.data.cognito.uuid;
export const getAllTeamProfilesBySelectorCreator = (getPeople) =>
  createSelector(getPeople, getProfiles, getCurrentCoachUuid, (athleteWithTeams, profiles, currentCoachUuid) => {
    const allTeams = {};
    athleteWithTeams.forEach((athlete) => {
      if (!athlete.isPrivateTeam) {
        allTeams[athlete.userId] = athlete;
      }
    });
    if (profiles) {
      allTeams[currentCoachUuid] = profiles[currentCoachUuid];
    }
    return allTeams;
  });

export const selectAthletesWithProfiles = getProfilesSelectorCreator(
  getAthletes,
);
const selectFilterSearchSortAthletes = getFilteredSearchedSortedSelectorCreator(
  selectAthletesWithProfiles,
);
export const selectAthletes = getByTeamSelectorCreator(
  selectFilterSearchSortAthletes,
);

export const selectAllTeamAthletes = getAllTeamBySelectorCreator(
  selectFilterSearchSortAthletes
);

export const selectAllTeamProfiles = getAllTeamProfilesBySelectorCreator(
  selectFilterSearchSortAthletes
);

const selectCoachesWithProfiles = getProfilesSelectorCreator(getCoaches);
const selectFilterSearchSortCoaches = getFilteredSearchedSortedSelectorCreator(
  selectCoachesWithProfiles,
);
export const selectCoaches = getByTeamSelectorCreator(
  selectFilterSearchSortCoaches,
);

const getMyTeams = (state) => state.modules.coachWorld.coachWorld.myTeams;
const getTeam = (state, ownProps) => ownProps.team;

export const selectCanEditRoster = createSelector(
  getMyTeams,
  getTeam,
  (myTeams, team) => {
    const foundTeam = myTeams.find((t) => t.schoolTeamId === team.id);
    return !!foundTeam && foundTeam.userType === 'coach';
  },
);

export default 'assessment.selectors.js';
