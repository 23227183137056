import { connect } from 'react-redux';
import Component from './CombineTable.component';
import { saveTestResults, setExpiringCombineMsg, testCodesForZeroValues, refreshPopUp } from '../../+store/combine';
import { selectIsCoach } from '../../+store';
import { updateAthleteVideoId } from '../../../../store/actions/ui/uxProfile/photosVideos/actions';

const mapStateToProps = state => {
  const videos = state.data.photo.videos;
  Object.keys(videos).forEach(key => {
    if (videos[key] === undefined) {
      delete videos[key];
    }
  });
  return {
  staging: state.modules.coachWorld.combine.combineResultsStaging,
  hasScrolledBelowMenu: state.modules.coachWorld.combine.userHasScrolledBelowMenu,
  myTeams: state.modules.coachWorld.coachWorld.myTeams,
  isCoach: selectIsCoach(state),
  isSavingArray: state.modules.coachWorld.combine.isSavingArray,
  videos: videos,
}};

/*
  userId
  testParams = {
    testId,
    testResultId,
    stdTestObjId,
    result,
    openDate,
    combineId,
  }
*/

const mapDispatchToProps = dispatch => ({
  saveTestResults: (athlete, column, combine, value, isUploadingVideo = false, oldValue = {}) => {
    console.log(oldValue, 'incontainer');
    const testResultId = (athlete.testResults
      && athlete.testResults[column.standardTestObjectId])
      ? athlete.testResults[column.standardTestObjectId].id
      : null;
    let result = value;
    const testObject = {
      testId: athlete.test
        ? athlete.test.id
        : null,
      testResultId,
      stdTestObjId: column.standardTestObjectId,
      result: value ? value : 0,
      oldResultVideo: (oldValue && oldValue.videoId) ? oldValue.videoId : null,
      openDate: combine.openDate.format('YYYY-MM-DD'),
      combineId: combine.id,
      columnRowId: `${athlete.userId}:${column.standardTestObjectId}`,
      videoId : value.videoId,
      processed: value.processed,
    };
    if (value && value.reps) {
      result = Number(value.result) > 0 ? Number(value.result) : null;
      testObject.reps = value.reps ? Number(value.reps) : 1;
      testObject.result = result;
    }
    if (value && typeof value.isLaser !== 'undefined') {
      result = Number(value.result) > 0 ? Number(value.result) : null;
      testObject.isLaser = value.isLaser;
      testObject.result = result;
    }
    if(value){
      result = Number(value.result) > 0 ? Number(value.result) : null;
      testObject.result = result;
    }
    if (testResultId || result || (result == 0 && testCodesForZeroValues.includes(column.testCategoryCode) ) ) {
      dispatch(saveTestResults(athlete.userId, testObject));
    }
  },
  setExpiringCombineMsg: (msg, msgType, seconds) => {
    dispatch(setExpiringCombineMsg(msg, msgType, seconds));
  },
  updateAthleteVideoId:( athleteId )=> {
    dispatch(updateAthleteVideoId(athleteId))
  },
  getRefreshPopUp: () =>{
    dispatch(refreshPopUp())
  }

});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
