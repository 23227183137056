import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ButtonRect, CloseButton } from '../../UxCommon';
import FavoriteSport from './FavoriteSport/FavoriteSport';

class FavoritesDialog extends PureComponent {
  static propTypes = {
    award: PropTypes.object.isRequired,
    mySports: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    favorites: PropTypes.object,
  };

  state = {
    favoriteSports: null,
  }

  componentDidMount() {
    this.initializeState();
    this.scrollElement = document.getElementById('uxProfileRight');
    this.saveScrollTop(this.scrollElement.scrollTop);
  }

  onSaveClick = () => {
    const { award, onSave } = this.props;
    const { favoriteSports } = this.state;
    const sportIds = {};
    Object.values(favoriteSports).forEach((fav) => {
      sportIds[fav.sportId] = fav.favorite;
    });
    onSave(award, sportIds);
  }

  onSportClick = (sportId) => {
    const { favoriteSports } = this.state;
    const wasFavorite = favoriteSports[sportId].favorite;
    this.setState({
      favoriteSports: Object.assign({}, favoriteSports, {
        [sportId]: Object.assign({}, favoriteSports[sportId], {
          favorite: !wasFavorite,
        }),
      }),
    });
  }

  getPaddingTop = () => {
    const { scrollTop } = this.state;
    if (scrollTop > 272) {
      return scrollTop - 154;
    }
    return 118;
  }

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  initializeState = () => {
    const { mySports, favorites } = this.props;
    const sports = {};
    mySports.forEach((sport) => {
      sports[sport.id] = {
        favorite: favorites ? !!favorites[sport.id] : false,
        name: sport.name,
        sportId: sport.id,
      };
    });
    sports[0] = {
      favorite: favorites ? !!favorites[0] : false,
      name: 'All',
      sportId: 0,
    };
    this.setState({
      favoriteSports: sports,
    });
  }

  render() {
    const {
      onCancel,
    } = this.props;
    const { favoriteSports } = this.state;

    let options = null;
    if (favoriteSports) {
      options = Object.values(favoriteSports);
      options.sort((a, b) => {
        if (a.sportId < b.sportId) return -1;
        else if (a.sportId > b.sportId) return 1;
        return 0;
      });
    }

    return (
      <div
        className="Awards_FavoritesDialog_Backdrop"
        style={{ paddingTop: `${this.getPaddingTop()}px` }}
      >
        <div className="Awards_FavoritesDialog">
          <CloseButton
            onClick={onCancel}
            size={10}
            style={{ top: '25px', right: '20px' }}
          />
          <div className="Awards_FavoritesDialog_Title">
            Favorite
          </div>
          <div className="Awards_FavoritesDialog_Sports">
            {
              options && options.map(sport => (
                <FavoriteSport
                  key={sport.sportId}
                  name={sport.name}
                  sportId={sport.sportId}
                  isFavorite={sport.favorite}
                  onClick={this.onSportClick}
                />
              ))
            }
          </div>
          <div className="Awards_FavoritesDialog_BtnsRow">
            <ButtonRect
              style={{ marginLeft: 'auto', width: '100px' }}
              className="Awards_FavoritesDialog_Btn"
              onClick={onCancel}
            >
              Cancel
            </ButtonRect>
            <ButtonRect
              className="Awards_FavoritesDialog_Btn primary"
              onClick={this.onSaveClick}
              style={{ width: '120px' }}
              type="button"
            >
              Save
            </ButtonRect>
          </div>
        </div>
      </div>
    );
  }
}

export default FavoritesDialog;
