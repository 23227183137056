import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, ButtonRect } from '../../../../UxCommon';

class DeleteDialog extends PureComponent {
  static propTypes = {
    onConfirmDeleteClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onHideClick: PropTypes.func.isRequired,
    award: PropTypes.object.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      onConfirmDeleteClick,
      onCancelClick,
      award,
      onHideClick,
    } = this.props;
    return (
      <div className="Awards_DeleteDialog_Backdrop">
        <div className="Awards_DeleteDialog">
          <CloseButton onClick={onCancelClick} size={10} />
          <div style={{ margin: 'auto' }}>
            <div className="Awards_DeleteDialog_Header">
              Delete?
            </div>
            <div
              className="Awards_DeleteDialog_Question"
              dangerouslySetInnerHTML={{
                __html: `Are you sure you want to delete the award, <b>"${award.name}"</b>?<br/><br/>You could hide it instead.`,
              }}
            />
          </div>
          <ButtonRect
            className="Awards_DeleteDialog_Btn primary"
            onClick={onHideClick}
          >
            Hide
          </ButtonRect>
          <ButtonRect
            className="Awards_DeleteDialog_Btn delete"
            onClick={onConfirmDeleteClick}
          >
            Delete
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default DeleteDialog;
