import  React  from 'react';
import PropTypes from 'prop-types';
const ExternalProfileLogo = props => {

  const {
    classes,
    imagePath
  } = props;
  return (
    <img
      src = { imagePath }
      className = { classes }
      alt = { 'img-class' }
    />
  )
}

ExternalProfileLogo.propTypes = {
  classes: PropTypes.string,
  imagePath: PropTypes.string,
}


export default ExternalProfileLogo;
