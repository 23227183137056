import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChechboxControl from '../FormControls/CheckboxControl';
import Collapsable from '../../../../components/UxCommon/Collapsable/Collapsable';
import { setSortBy } from '../../+store/coachWorld/coachWorld.utils';
import { setSport } from '../../+store/coachWorld';

class SidebarSportSelectComponent extends PureComponent {
  static propTypes = {
    sport: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  componentDidMount() {
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  onChange = name => (value) => {
    const { location, history } = this.props;
    setSport(location, history, name);
    return value;
  }

  render() {
    const {
      filters, label, className, sport,
    } = this.props;

    return (
      <div className={`CoachSidebarSortByFilter_Divider ${className || ''}`}>
        <Collapsable
          keepInside
          useFluidContainer
          title={label}
          topRowStyle={{ minHeight: '59px' }}
          className="CoachSidebarSortByFilter"
        >
          <div/>
          <div className={`CoachSidebarSortByFilter_CheckboxContainer ${className || ''}`}>
            {
              filters.map(({ name, value }) => (
                <ChechboxControl
                  key={value}
                  label={name}
                  checked={String(value) === sport}
                  myOnChange={this.onChange(value)}
                />
              ))
            }

          </div>
        </Collapsable>
      </div>
    );
  }
}

export default SidebarSportSelectComponent;
