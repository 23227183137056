import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapsable } from '../../../../UxCommon';
import { TextControl, SelectControl } from '../../../../UxCommon/Form';

class AlbumForm extends PureComponent {
  static propTypes = {
    albums: PropTypes.array.isRequired,
    createMode: PropTypes.bool.isRequired,
    rrfForm: PropTypes.object.isRequired,
    clearNewAlbumName: PropTypes.func.isRequired,
    clearAlbumSelect: PropTypes.func.isRequired,
  }

  componentDidMount() {
  }

  onSelectAlbumChange = (e) => {
    const { clearNewAlbumName, rrfForm } = this.props;
    if ((e.target.value) && (rrfForm.newAlbumName.value)) {
      clearNewAlbumName();
    }
  }

  onNewAlbumNameChange = (e) => {
    const { clearAlbumSelect, rrfForm } = this.props;
    if ((e.target.value) && (rrfForm.selectedAlbum.value)) {
      clearAlbumSelect();
    }
  }

  render() {
    const {
      albums,
      createMode,
      rrfForm,
    } = this.props;
    return (
      <div className="Photos_AlbumDialog_AlbumForm">
        <div className="UxProfile_Photos_AlbumDialog_LeftColTop">
          {
            createMode && (
              <React.Fragment>
                <Collapsable
                  title="Create an album"
                  keepInside
                  topRowStyle={{ minHeight: '59px' }}
                >
                  <div />
                  <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                    <TextControl
                      label="Album Name"
                      placeholder="Album Name"
                      rrfField={rrfForm.newAlbumName}
                      model="forms.albumDialog.newAlbumName"
                      onChange={this.onNewAlbumNameChange}
                    />
                  </div>
                </Collapsable>
                <Collapsable
                  title="or Add to Existing Album"
                  keepInside
                  topRowStyle={{ minHeight: '59px' }}
                >
                  <div />
                  <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                    <SelectControl
                      label="Album"
                      placeholder="Select an Album"
                      rrfField={rrfForm.selectedAlbum}
                      model="forms.albumDialog.selectedAlbum"
                      options={albums.map(album => ({ value: album.id, text: album.title }))}
                      onChange={this.onSelectAlbumChange}
                    />
                  </div>
                </Collapsable>
              </React.Fragment>
            )
          }
          {
            !createMode && (
              <Collapsable
                title="Album Name"
                keepInside
                topRowStyle={{ minHeight: '59px' }}
              >
                <div />
                <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                  <TextControl
                    label="Album Name"
                    placeholder="Album Name"
                    rrfField={rrfForm.albumName}
                    model="forms.albumDialog.albumName"
                  />
                </div>
              </Collapsable>
            )
          }
        </div>
      </div>
    );
  }
}

export default AlbumForm;
