import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContainerFluid, Col, Row, LoadingSpinner } from '../../../UxCommon';
import VcRating from '../../../../containers/UxProfile/VcRating';
// import styles from './PersonalRecords.styles';
import PersonalRecordsTable from './PersonalRecordsTable';
import { YOUTH } from '../../UxProfile.constants';

class PersonalRecords extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    playerData: PropTypes.object,
    currentSportId: PropTypes.number,
    isMyProfile: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.accelerationPersonalRecords = [];
    this.agilityPersonalRecords = [];
    this.explosivePerfPersonalRecords = [];
    this.upperBodyPersonalRecords = [];
    this.legHipPersonalRecords = [];
    this.explosiveStrPersonalRecords = [];
  }

  componentDidMount() {
  }

  componentWillUpdate() {
    this.onUpdate();
  }
  filterPersonalRecordByAthleteSportsIds(personalRecords) {
    return personalRecords.filter(e => this.props.userSportsIds
      .some(sportId => e.stdTestCategory.sportIds.includes(sportId)));
  }
  onUpdate() {
    const { playerData, currentSportId } = this.props;
    if (playerData && currentSportId) {
      if (playerData.performanceClass === YOUTH) {
        this.accelerationPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.accelerationPersonalRecords);
        this.agilityPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.agilityPersonalRecords);
        this.explosivePerfPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.explosivePerfPersonalRecords);
        this.upperBodyPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.upperBodyPersonalRecords);
        this.legHipPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.legHipPersonalRecords);
        this.explosiveStrPersonalRecords =
          this.filterPersonalRecordByAthleteSportsIds(playerData.explosiveStrPersonalRecords);
      } else {
        this.accelerationPersonalRecords = playerData.accelerationPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
        this.agilityPersonalRecords = playerData.agilityPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
        this.explosivePerfPersonalRecords = playerData.explosivePerfPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
        this.upperBodyPersonalRecords = playerData.upperBodyPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
        this.legHipPersonalRecords = playerData.legHipPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
        this.explosiveStrPersonalRecords = playerData.explosiveStrPersonalRecords
          .filter(e => e.stdTestCategory.sportIds.indexOf(currentSportId) > -1);
      }
    } else {
      this.accelerationPersonalRecords = [];
      this.agilityPersonalRecords = [];
      this.explosivePerfPersonalRecords = [];
      this.upperBodyPersonalRecords = [];
      this.legHipPersonalRecords = [];
      this.explosiveStrPersonalRecords = [];
    }
  }

  checkIfAnyRecordExists = playerData => playerData && (
    this.accelerationPersonalRecords.length ||
    this.agilityPersonalRecords.length ||
    this.explosivePerfPersonalRecords.length ||
    this.upperBodyPersonalRecords.length ||
    this.legHipPersonalRecords.length ||
    this.explosiveStrPersonalRecords.length);

  renderEmptyData = () => (
    <Col className="UxPlayerData_PRs_NoDataWrapper col-md-9">
      <div className="UxPlayerData_PRs_NoData">
        {this.props.isMyProfile ? `Let's enter some test results so we can display your personal
      records and generate your VC score!${''}` : 'No test results available to generate VC score!'}
      </div>
    </Col>
  )

  renderSmallSize = (playerData, numTopRows, match) => (
    <ContainerFluid className="UxPlayerData_PRs UxPlayerData_PRs_Small">
      <Row className="UxPlayerData_PRs_Row">
        <LoadingSpinner loading={!playerData} loadingMsg="Loading personal records" />
        <Col className="UxPlayerData_PRs_LastCol col-lg-3 col-12">
          <VcRating match={match} />
        </Col>
        {
          this.checkIfAnyRecordExists(playerData) ?
            <div>
              <PersonalRecordsTable
                numTopRows={numTopRows}
                title1="Speed"
                title2="Upper Body Strength"
                records1={[...this.accelerationPersonalRecords,
                  ...this.agilityPersonalRecords,
                  ...this.explosivePerfPersonalRecords]}
                records2={[...this.upperBodyPersonalRecords,
                  ...this.legHipPersonalRecords,
                  ...this.explosiveStrPersonalRecords]}
              />
            </div>
            :
            this.renderEmptyData()
        }
      </Row>
    </ContainerFluid>
  )

  renderLargeSize = (playerData, numTopRows) => (
    <React.Fragment>
      <Col className="UxPlayerData_PRs_Col col-lg-3 col-4">
        <PersonalRecordsTable
          numTopRows={numTopRows}
          title1="Speed"
          title2="Upper Body Strength"
          records1={this.accelerationPersonalRecords}
          records2={this.upperBodyPersonalRecords}
        />
      </Col>
      <Col className="UxPlayerData_PRs_Col col-lg-3 col-4">
        <PersonalRecordsTable
          numTopRows={numTopRows}
          title1="Agility"
          title2="Leg and Hip Strength"
          records1={this.agilityPersonalRecords}
          records2={this.legHipPersonalRecords}
        />
      </Col>
      <Col className="UxPlayerData_PRs_Col col-lg-3 col-4">
        <PersonalRecordsTable
          numTopRows={numTopRows}
          title1="Explosion"
          title2="Explosive Strength"
          records1={this.explosivePerfPersonalRecords}
          records2={this.explosiveStrPersonalRecords}
        />
      </Col>
    </React.Fragment>
  )

  render() {
    const {
      playerData,
      match,
    } = this.props;
    let numTopRows = 0;
    if (playerData && this.accelerationPersonalRecords.length > numTopRows) {
      numTopRows = this.accelerationPersonalRecords.length;
    }
    if (playerData && this.agilityPersonalRecords.length > numTopRows) {
      numTopRows = this.agilityPersonalRecords.length;
    }
    if (playerData && this.explosivePerfPersonalRecords.length > numTopRows) {
      numTopRows = this.explosivePerfPersonalRecords.length;
    }
    // return this.renderSmallSize(playerData, numTopRows, match);
    return (
      <div style={{ width: '100%' }}>
        <ContainerFluid className="UxPlayerData_PRs UxPlayerData_PRs_Large">
          <Row className="UxPlayerData_PRs_Row">
            <LoadingSpinner loading={!playerData} loadingMsg="Loading personal records" />
            {
              this.checkIfAnyRecordExists(playerData) ?
                this.renderLargeSize(playerData, numTopRows)
                :
                this.renderEmptyData()
            }
            <Col className="UxPlayerData_PRs_LastCol col-lg-3 col-12">
              <VcRating match={match} />
            </Col>
          </Row>
        </ContainerFluid>
        {this.renderSmallSize(playerData, numTopRows, match)}
      </div>
    );
  }
}

export default PersonalRecords;
