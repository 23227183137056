import React, { useState } from 'react';
import { CloseButton, ButtonRect } from '../../../../../../components/UxCommon';
import SelectControl from '../../../../../CodeSignUp/SimpleForm/SelectControl';
import { Sports } from '../../../../../../store/actions/ui/routes';
import LoadingIndicator from '../../../../common/LoadingIndicator';

const SportsSelector = ({ onClickClose, orgAffSports, onValueChange }) => {

    const [loading, setLoading] = useState(false);
    const [sportId, setSportId] = useState(null);

    const onChangeHandler = (value) => {
        setSportId(value);
    }

    const handleContinue = () => {
        setLoading(true);
        onValueChange(sportId);
    }

    return (
        <div className="CoachWorld_EditPlayer_Backdrop">
        <div className="Combine_Edit_Modal">
        <CloseButton onClick={onClickClose} size={10}/>
        <LoadingIndicator
          loading={Object.keys(orgAffSports).length === 0 || loading}
          loadingMsg={'Loading..'}
        />
          <div className="Combine_Edit_Container">
            <div className="CoachWorld_EditPlayer_Header">
              {'Select Sports'}
            </div>
            <div className="Sports_Selector_Sub_Head">{'Hey Coach !'}</div>
            <div className={"Sports_Selector_Ques"}>{'For which sport do you want to run a combine ?'}</div>
            <form className="Sports_Form">
            {
                <SelectControl
                name="Sports"
                classNameRadio="SelectSport_RadioButton"
                useRadio={true}
                options={orgAffSports}
                myOnChange={onChangeHandler}
                myOnBlur={onChangeHandler}
                />
            }
            </form>
            <div className="Combine_Edit_Btns Select_Sports_Buttons">
                <React.Fragment>
                  <ButtonRect
                    className="Photos_ExpandedPhotoDialog_Button"
                    style={{width: '120px'}}
                    type="button"
                    onClick={onClickClose}
                  >
                    Cancel
                  </ButtonRect>
                  <ButtonRect
                    className="Photos_ExpandedPhotoDialog_Button primary"
                    style={{width: '120px'}}
                    type="button"
                    disabled={!sportId}
                    onClick={handleContinue}
                  >
                    Contiune
                  </ButtonRect>
                </React.Fragment>
              </div>
          </div>
        </div>
      </div>
    )

}

export default SportsSelector;