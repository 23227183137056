import React from 'react';
import { Control, Errors } from 'react-redux-form';
import PropTypes from 'prop-types';

// const validateRequired = val => (val ? (!!val.length) : false);

const TextControlGroup = (props) => {
  const {
    label,
    model,
    type,
    validators,
    validateOn,
    placeholder,
    messages,
    help,
    classname,
    ...otherProps
  } = props;
  return (
    <div className={`form-group ${classname ? classname : ''}`}>
      <label htmlFor={model}>
        <div className="form-label">{label}</div>
        <Control
          type={type || 'text'}
          model={model}
          validators={validators}
          validateOn={validateOn || 'blur'}
          id={model}
          placeholder={placeholder || label}
          {...otherProps}
        />
      </label>
      {
        help &&
          <div className="form-help">
            {help}
          </div>
      }
      <Errors
        className="form-error"
        model={model}
        messages={messages}
        show
      />
    </div>
  );
};
TextControlGroup.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  validators: PropTypes.object,
  messages: PropTypes.object,
  parser: PropTypes.func,
  placeholder: PropTypes.string,
  validateOn: PropTypes.string,
  help: PropTypes.any,
};

export default TextControlGroup;
