import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/PlayerData/Stats/ZeroStats/ZeroStats';
import { showFirstSeasonForm, hideFirstSeasonForm } from '../../../store/actions/ui/uxPlayerData';
import { getSelectedSport, getCurrentUuid } from '../../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  return {
    newSeasonFormIsShown: state.ui.uxPlayerData.stats.firstSeasonFormIsShown,
    selectedUserSport,
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    dummy: () => dispatch({}),
    onEnterStatsClick: () => {
      dispatch(showFirstSeasonForm());
    },
    onCancelEnterStats: () => {
      dispatch(hideFirstSeasonForm());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
