import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetStats';
import { getCurrentUuid, getCurrentSportId } from '../Utils';
import { playerStatsGet, playerCombineRecords } from '../../../store/actions/ui/uxPlayerData';

const mapStateToProps = (state, ownProps) => ({
  uuid: getCurrentUuid(state, ownProps.match),
  sportId: getCurrentSportId(state),
});

const mapDispatchToProps = dispatch => ({
  // getStats: (uuid, sportId, msg = '') => {
  //   dispatch(playerStatsGet(uuid, sportId, msg));
  // },
  getTests: (uuid, sport, msg) =>{
    dispatch(playerCombineRecords(uuid, sport, msg))
}
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
