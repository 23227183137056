import moment from 'moment';

const forms = {
  firstSeason: {
    team: null,
    position: null,
    teamName: '',
    seasonStart: moment().subtract(2, 'years'),
    seasonEnd: moment().subtract(1, 'years'),
    perfPosition: null,
  },
};

export default forms;
