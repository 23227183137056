import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextControl from '../SimpleForm/TextControl';

import { checkRequired } from '../AccountPage/AccountPage.validation';
import ControlErrors from '../SimpleForm/ControlErrors';
import SelectControl from '../SimpleForm/SelectControl';
import Button from '../Common/Button';
import {
  checkAndCorrectHeight,
  checkAndCorrectMetricHeight,
  checkWeight, checkYearValidation,
  convertCmToInches,
  convertKgToLbs,
  checkZipcode,
  checkCityState,
  checkAddress, checkAddress2
} from './PhysiquePage.validation';

const grades = {
  1: '12th',
  2: '11th',
  3: '10th',
  4: '9th',
  5: '8th',
  6: '7th',
  7: '6th',
  8: '5th',
  9: '4th',
  10: '3rd',
  11: '2nd',
  12: '1st',
  13: 'K',
  14: '13th',
  15: 'Redshirt Freshman',
  16: 'College Freshman',
  17: 'College Sophomore',
  18: 'College Junior',
  19: 'College Senior',
}

const sortedGrades = {
  1: '12',
  2: '11',
  3: '10',
  4: '9',
  5: '8',
  6: '7',
  7: '6',
  8: '5',
  9: '4',
  10: '3',
  11: '2',
  12: '1',
  13: 'K',
  14: '13',
  15: 'Redshirt Freshman',
  16: 'College Freshman',
  17: 'College Sophomore',
  18: 'College Junior',
  19: 'College Senior',
};

const genders = {
  male: 'Male',
  female: 'Female',
};

class PhysiquePage extends Component {
  static propTypes = {
    savePhysique: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    profile: PropTypes.object,
    match: PropTypes.object.isRequired,
    metricUnits: PropTypes.bool.isRequired,
  }

  state = {
    values: {
      heightFt: '',
      heightIn: '',
      heightM: '',
      heightCm: '',
    },
    errors: {
      height: [''],
    },
  }

  onNextClick = (event) => {
    const {
      savePhysique, uuid, match, metricUnits,
    } = this.props;
    const form = this.formRef.current;
    const { values } = this.state;
    let heightResult;
    if (metricUnits) {
      heightResult = checkAndCorrectMetricHeight(
        values.heightMt,
        values.heightCm,
      );
    } else {
      heightResult = checkAndCorrectHeight(
        values.heightFt,
        values.heightIn,
      );
    }

    const errors = {
      height: heightResult.errors,
      weight: checkWeight(form.weight.value, metricUnits),
      gender: checkRequired(form.gender.value),
      grade: checkRequired(form.grade.value),
      grad_year: checkRequired(form.grad_year.value),
      // zip: checkZipcode(form.zip.value),
      city: checkCityState(form.city.value),
      staress: checkAddress(form.address.value),
      addte: checkCityState(form.state.value),
      address2: checkAddress2(form.address2.value)
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({ errors });
    if (success) {
      savePhysique(uuid, {
        height: this.getCurrentHeight(heightResult),
        weight: this.getCurrentWeight(),
        gender: form.gender.value,
        grade: sortedGrades[form.grade.value],
        grad_year: form.grad_year.value,
        zip: form.zip.value,
        city: form.city.value,
        state: form.state.value,
        address: (form.address.value && form.address2.value ? form.address.value + ', ' + form.address2.value : form.address.value + form.address2.value),
      }, match.params.code);
    }
    event.preventDefault();
  }

  getCurrentHeight = (heightResult) => {
    const { metricUnits } = this.props;
    if (metricUnits) {
      return convertCmToInches((
        heightResult.heightMt * 100) + heightResult.heightCm);
    }
    return (heightResult.heightFt * 12) + heightResult.heightIn;
  }

  getCurrentWeight = () => {
    const form = this.formRef.current;
    const { metricUnits } = this.props;
    if (metricUnits) {
      return Number(convertKgToLbs(form.weight.value));
    }
    return Number(form.weight.value);
  }

  myOnChange = name => (value) => {
    const { metricUnits } = this.props;
    switch (name) {
      case 'heightFt': {
        const heightFt = value.replace(/[^0-9]/g, '');
        this.setState({
          values: Object.assign(this.state.values, {
            heightFt,
          }),
        });
        return heightFt;
      }
      case 'heightMt': {
        const heightMt = value.replace(/[^0-9]/g, '');
        this.setState({
          values: Object.assign(this.state.values, {
            heightMt,
          }),
        });
        return heightMt;
      }
      case 'heightIn': {
        const heightIn = value.replace(/[^0-9.]/g, '');
        this.setState({
          values: Object.assign(this.state.values, {
            heightIn,
          }),
        });
        return heightIn;
      }
      case 'heightCm': {
        const heightCm = value.replace(/[^0-9.]/g, '');
        this.setState({
          values: Object.assign(this.state.values, {
            heightCm,
          }),
        });
        return heightCm;
      }
      case 'weight':
        this.setState({
          errors: Object.assign(this.state.errors, {
            weight: checkWeight(value, metricUnits),
          }),
        });
        return value.replace(/[^0-9.]/g, '');
      case 'grad_year':
        this.setState({
          errors: Object.assign(this.state.errors, {
            grad_year: checkYearValidation(value),
          }),
        });
        return value;
      // case 'zip':
      //     this.setState({
      //       errors: Object.assign(this.state.errors, {
      //         zip: checkZipcode(value),
      //       }),
      //     });
      //     return value;
      case 'city':
          this.setState({
            errors: Object.assign(this.state.errors, {
              city: checkCityState(value),
            }),
          });
          return value;
      case 'state':
          this.setState({
            errors: Object.assign(this.state.errors, {
              state: checkCityState(value),
            }),
          });
          return value;
      case 'address':
          this.setState({
            errors: Object.assign(this.state.errors, {
              address: checkAddress(value),
            }),
          });
          return value;
      case 'address2':
        this.setState({
          errors: Object.assign(this.state.errors, {
            address2: checkAddress2(value),
          }),
        });
        return value;
      default:
          this.setState({
            values: Object.assign(this.state.values, {
              [name]: value,
            }),
          });
    }
    return '';
  }

  myOnBlur = name => (value) => {
    const { values } = this.state;
    const { metricUnits } = this.props;
    switch (name) {
      case 'heightIn': {
        const { heightFt, heightIn, errors } = checkAndCorrectHeight(
          values.heightFt,
          values.heightIn,
        );
        this.setState({
          values: Object.assign(this.state.values, {
            heightIn,
            heightFt,
          }),
          errors: Object.assign(this.state.errors, {
            height: errors,
          }),
        });
        return value;
      }
      case 'heightCm': {
        const { heightMt, heightCm, errors } = checkAndCorrectMetricHeight(
          values.heightMt,
          values.heightCm,
        );
        this.setState({
          values: Object.assign(this.state.values, {
            heightMt,
            heightCm,
          }),
          errors: Object.assign(this.state.errors, {
            height: errors,
          }),
        });
        return value;
      }
      case 'weight':
        this.setState({
          errors: Object.assign(this.state.errors, {
            weight: checkWeight(value, metricUnits),
          }),
        });
        break;
      default:
    }
    return '';
  }

  formRef = React.createRef();

  renderMetricHeight = () => {
    const { profile } = this.props;
    const { values } = this.state;
    return (
      <React.Fragment>
        <TextControl
          className="PhysiquePage_TextControl PhysiquePage_HeightFt"
          placeholder="Height (m.)"
          label="Height (m.)"
          name="heightMt"
          type="number"
          myOnChange={this.myOnChange('heightMt')}
          value={values.heightMt}
          defaultValue={(profile && `${profile.heightFt}`) || ''}
        />
        <TextControl
          className="PhysiquePage_TextControl PhysiquePage_HeightIn"
          placeholder="Height (cm.)"
          label="Height (cm.)"
          type="number"
          name="heightCm"
          myOnChange={this.myOnChange('heightCm')}
          myOnBlur={this.myOnBlur('heightCm')}
          value={values.heightCm}
          defaultValue={(profile && `${profile.heightInch}`) || ''}
        />
      </React.Fragment>
    );
  }

  renderStandardHeight = () => {
    const { profile } = this.props;
    const { values } = this.state;
    return (
      <React.Fragment>
        <TextControl
          className="PhysiquePage_TextControl PhysiquePage_HeightFt"
          placeholder="Height (Ft.)"
          label="Height (Ft.)"
          name="heightFt"
          type="number"
          myOnChange={this.myOnChange('heightFt')}
          value={values.heightFt}
          defaultValue={(profile && `${profile.heightFt}`) || ''}
        />
        <TextControl
          className="PhysiquePage_TextControl PhysiquePage_HeightIn"
          placeholder="Height (In.)"
          label="Height (In.)"
          type="number"
          name="heightIn"
          myOnChange={this.myOnChange('heightIn')}
          myOnBlur={this.myOnBlur('heightIn')}
          value={values.heightIn}
          defaultValue={(profile && `${profile.heightInch}`) || ''}
        />
      </React.Fragment>
    );
  }

  renderHeight = () => {
    const { metricUnits } = this.props;
    return (
      metricUnits ? this.renderMetricHeight() : this.renderStandardHeight()
    );
  }

  renderKgWeight = () => {
    const { profile } = this.props;
    const { errors } = this.state;
    return (<TextControl
      className="PhysiquePage_TextControl"
      placeholder="Weight (Kg.)"
      label="Weight (Kg.)"
      type="number"
      name="myOnChangeweight"
      myOnChange={this.myOnChange('weight')}
      myOnBlur={this.myOnBlur('weight')}
      errors={errors.weight}
      defaultValue={(profile && `${profile.weight}`) || ''}
    />);
  }

  renderStandardWeight = () => {
    const { profile } = this.props;
    const { errors } = this.state;
    return (
      <TextControl
        className="PhysiquePage_TextControl"
        placeholder="Weight"
        label="Weight"
        type="number"
        name="weight"
        myOnChange={this.myOnChange('weight')}
        myOnBlur={this.myOnBlur('weight')}
        errors={errors.weight}
        defaultValue={(profile && `${profile.weight}`) || ''}
      />
    );
  }

  renderWeight = () => {
    const { metricUnits } = this.props;
    return metricUnits ? this.renderKgWeight() : this.renderStandardWeight();
  };

  render() {
    const { profile } = this.props;
    const { errors } = this.state;
    const msg = '';

    return (
      <div className="PhysiquePage">
        {msg && <div className="PhysiquePage_Msg">{msg}</div>}
        <form ref={this.formRef} className="PhysiquePage_Form" onSubmit={this.onNextClick}>
          <div className="PhysiquePage_HeightWrapper">
            {this.renderHeight()}
          </div>
          <ControlErrors errors={errors.height}/>
          {this.renderWeight()}
          <SelectControl
            className="PhysiquePage_TextControl"
            placeholder="Select your gender"
            label="Gender"
            help="Gender is required to help us generate an accurate VC score"
            options={genders}
            name="gender"
            errors={errors.gender}
            defaultValue={(profile && profile.gender) || ''}
          />
          <SelectControl
            className="PhysiquePage_TextControl"
            placeholder="Select your grade"
            label="Grade"
            options={grades}
            name="grade"
            errors={errors.grade}
            defaultValue={(profile && `${profile.grade}`) || ''}
          />
          <TextControl
            className="PhysiquePage_TextControl"
            placeholder="Grad Year"
            label="Grad Year"
            // type="number"
            name="grad_year"
            myOnChange={this.myOnChange('grad_year')}
            myOnBlur={this.myOnBlur('grad_year')}
            errors={errors.grad_year}
            defaultValue={(profile && `${profile.gradYear}`) || ''}
            pattern="^[0-9]{1,2}\.[0-9]{2}$"
          />
          <TextControl
            className="PhysiquePage_TextControl"
            placeholder="Street address or P.O. Box"
            label="Address"
            type="text"
            name="address"
            myOnChange={this.myOnChange('address')}
            myOnBlur={this.myOnBlur('address')}
            errors={errors.address}
            defaultValue={(profile && `${profile.address}`) || ''}
          />
          <TextControl
            className="PhysiquePage_TextControl top_address_margin"
            placeholder="Apt, suite, unit, building, floor, etc."
            type="text"
            name="address2"
            myOnChange={this.myOnChange('address2')}
            myOnBlur={this.myOnBlur('address2')}
            errors={errors.address2}
          />
          <TextControl
            className="PhysiquePage_TextControl"
            placeholder="City"
            label="City"
            type="text"
            name="city"
            myOnChange={this.myOnChange('city')}
            myOnBlur={this.myOnBlur('city')}
            errors={errors.city}
            defaultValue={(profile && `${profile.city}`) || ''}
          />
          <TextControl
            className="PhysiquePage_TextControl"
            placeholder="State"
            label="State"
            type="text"
            name="state"
            myOnChange={this.myOnChange('state')}
            myOnBlur={this.myOnBlur('state')}
            errors={errors.state}
            defaultValue={(profile && `${profile.state}`) || ''}
          />
          <TextControl
            className="PhysiquePage_TextControl"
            placeholder="zipcode"
            label="Zipcode"
            type="text"
            name="zip"
            myOnChange={this.myOnChange('zip')}
            myOnBlur={this.myOnBlur('zip')}
            defaultValue={(profile && `${profile.zip}`) || ''}
            // pattern="^[0-9]$"
          />
          <Button
            type="button"
            onClick={this.onNextClick}
            className="PhysiquePage_SubmitBtn"
          >
            Next
          </Button>
        </form>
      </div>
    );
  }
}

export default PhysiquePage;
