import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/PrivacyControl/PrivacyControl';
import { savePrivacy } from '../../../store/actions/ui/uxProfile';
import { getCurrentUuid, getProfile } from '../Utils';

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  const currentUuid = getCurrentUuid(state, ownProps.match);
  const profile = getProfile(state, ownProps.match);
  return {
    currentUuid,
    uuid: isCoach ? currentUuid : state.data.cognito.uuid,
    loadingPrivacy: state.ui.uxProfile.playerProfile.privacyLoading,
    privacy: profile ? profile[ownProps.privacyType] : null,
  };
};

const mapDispatchToProps = dispatch => ({
  savePrivacy: (toggle, privacyType) => {
    dispatch(savePrivacy(toggle, privacyType));
  }
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
