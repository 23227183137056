import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import HideButton from './HideButton';
import ShowButton from './ShowButton';
import { hideFilters } from '../../../../store/actions/ui/app';


const FilterSidebar = ({
  children, displayNone, className, screenSize, defaultIsHidden, showMobileFilters,combineName,
}) => {
  const [screenWidth, setScreenWidth] = useState(1600);
  const isHidden = useSelector(state => state.ui.app.context.hideFilters);
  const dispatch = useDispatch();
  const defaultScreenSize = screenSize || 200 ;

  const updateWindowDimensions = () => {
    if (window.innerWidth < 992) {
      dispatch(hideFilters(true));
    }
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const onHideClick = () => {
    const isMobile = screenWidth <= defaultScreenSize;
    if (showMobileFilters && isMobile) {
      showMobileFilters(false);
    } else {
      dispatch(hideFilters(true));
    }
  };

  const onShowClick = () => {
    const isMobile = screenWidth <= defaultScreenSize;
    if (showMobileFilters && isMobile) {
      showMobileFilters(true);
    } else {
      dispatch(hideFilters(false));
    }
  };

  const isMobile = screenWidth <= defaultScreenSize;
  const isSidebarHidden = showMobileFilters && isMobile ? defaultIsHidden : isHidden;
  
  return (
    <React.Fragment>
      {
        !isMobile && (
          <ShowButton
            onClick={onShowClick}
            className={`${!isSidebarHidden ? 'hidden' : ''} ${className || ''}`}
          >
            Show
          </ShowButton>
        )
      }
      <div
        className={`CoachFilterSidebar ${combineName || ''} ${className || ''} ${isSidebarHidden ? 'hidden' : 'not_hidden'}`}
        style={{ display: displayNone ? 'none' : 'block' }}
        id="CoachFilterSidebar"
      >
        <div
          className={`CoachFilterSidebar_Content ${className || ''} ${isSidebarHidden ? 'hidden' : ''}`}
        >
          {
            !!isMobile &&
            <HideButton
              onClick={onHideClick}
              className={isSidebarHidden ? 'hidden' : ''}
            >
              Hide
            </HideButton>
          }
          {children}
          <HideButton
            onClick={onHideClick}
            className={isSidebarHidden ? 'hidden' : ''}
          >
            Hide
          </HideButton>
        </div>
      </div>
    </React.Fragment>
  );
};

FilterSidebar.propTypes = {
  children: PropTypes.any,
  displayNone: PropTypes.bool.isRequired,
  className: PropTypes.string,
  defaultIsHidden: PropTypes.bool,
  showMobileFilters: PropTypes.func,
  isLeaderboard: PropTypes.bool,
};

export default memo(FilterSidebar);
