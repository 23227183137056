import { catchError, delay, map, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { cancelOnRouteChange } from './utils';
import {
  getAlbums,
  getPhoto,
  getPhotos,
  mapAlbumDetailsApiToUi,
  mapPhotoApiToUi,
  mapPhotosApiToUi,
  photoGetProfilePhotosSuccess,
} from '../../data/photo';
import { userSportSavePhoto, userSportUpdateSuccess } from '../../data/user';
import { mapUserApiToUi } from '../../data/user/profile/models';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../async';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { createMediaEpicFragment } from '../uxProfile/photosVideos/epics/create';
import { uploadProgressUpdate } from '../uxProfile/photosVideos';
import { Routes } from '../routes';
import { fixRotation } from '../../../../containers/UxCommon/ImageUtils';
import { getTokenFragment } from '../uxProfile/utils';
import { isMobileApp } from '../../../../utils';
import { albumsPolling } from '../uxProfile/photosVideos/epics/read';
import { saveUserSport } from './editSports';

export const EP_GET_OLD_PROFILE_PHOTOS = 'editProfile.profilePhoto.getOld';
export const OLD_PROFILE_PHOTOS = 'oldProfilePhotos';
export const getOldProfilePhotos = dispatch => (uuid, msg) => {
  dispatch(asyncStartAction(OLD_PROFILE_PHOTOS, msg));
  dispatch({ type: EP_GET_OLD_PROFILE_PHOTOS, uuid });
};

export const oldProfilePicsEpic = action$ => (
  action$.pipe(
    ofType(EP_GET_OLD_PROFILE_PHOTOS),
    getTokenFragment(),
    switchMap(({ action, token }) => getPhotos(action.uuid, token)
      .pipe(
        map(response => mapPhotosApiToUi(response)),
        map(profilePhotos => ({
          success: true, profilePhotos, action, token,
        })),
        takeUntil(cancelOnRouteChange(action$)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(OLD_PROFILE_PHOTOS, 'get', error),
        })),
      )),
    switchMap(({ action, token, profilePhotos }) => getAlbums(action.uuid, token)
      .pipe(
        albumsPolling(action$, action, 'getAlbums', Routes.photos, token),
        map(results => ({
          success: true, albums: results, profilePhotos, action, token,
        })),
      )),
    switchMap((results) => {
      if (results.success) {
        const albumRelMediaCollection = [];
        results.albums.results.map(rawAlbum =>
          mapAlbumDetailsApiToUi(rawAlbum.albumDetailsResponse, albumRelMediaCollection));
        if (albumRelMediaCollection && albumRelMediaCollection.length > 0) {
          results.profilePhotos = results.profilePhotos
            .filter(photo => !albumRelMediaCollection
              .some(arm => arm.mediaId === photo.id));
        }
        return of(
          asyncFinishAction(OLD_PROFILE_PHOTOS, 'get'),
          photoGetProfilePhotosSuccess(results.profilePhotos),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);

export const EP_SAVE_PROFILE_PHOTO = 'editProfile.profilePhoto.save';
export const USER_PROFILE_PHOTO = 'user.profilePhoto';
export const updateProfilePhoto = dispatch => (sportId, photoUrl, msg) => {
  dispatch(asyncStartAction(USER_PROFILE_PHOTO, msg));
  dispatch({ type: EP_SAVE_PROFILE_PHOTO, sportId, photoUrl });
};

export const EP_PHOTO_DROP = 'editProfile.profilePhoto.drop';
export const EP_PHOTO_DROP_ONLOAD = 'editProfile.profilePhoto.dropOnLoad';
export const EP_PHOTO_DROP_ERROR = 'editProfile.profilePhoto.dropError';

const loadPhotoToCrop = (dispatch, file) => {
  const image = new Image();
  image.onload = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      dispatch({
        type: EP_PHOTO_DROP_ONLOAD,
        image: {
          data: event.target.result,
          height: image.naturalHeight,
          width: image.naturalWidth,
        },
      });
    };
    reader.readAsDataURL(file);
  };
  image.src = URL.createObjectURL(file);
};

export const onProfilePhotoDrop = dispatch => (accepted, rejected) => {
  if (accepted.length) {
    const file = accepted[0];

    fixRotation(file).then((returnedFile) => {
      dispatch({ type: EP_PHOTO_DROP, file: returnedFile });
      loadPhotoToCrop(dispatch, returnedFile);
    });
  } else {
    dispatch({ type: EP_PHOTO_DROP_ERROR, rejected });
  }
};

export const EP_PHOTO_DISCARD = 'editProfile.profilePhoto.discardNew';
export const discardNewProfilePhoto = dispatch => (photo) => {
  dispatch({ type: EP_PHOTO_DISCARD, photo });
};

export const updateProfilePhotoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const userProfile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    ofType(EP_SAVE_PROFILE_PHOTO),
    getTokenFragment(),
    switchMap(({ action, token }) => userSportSavePhoto(
      uuid(),
      action.sportId,
      action.photoUrl,
      token,
    )
      .pipe(
        map(response => mapUserApiToUi(response, userProfile().schools)),
        map(profile => ({ success: true, profile })),
        takeUntil(cancelOnRouteChange(action$)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(USER_PROFILE_PHOTO, 'save', error),
        })),
      )),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinishAction(USER_PROFILE_PHOTO, 'save'),
          userSportUpdateSuccess(results.profile),
        );
      }
      return of(results.action);
    }),
  );
};

export const EP_PHOTO_SAVE_NEW = 'editProfile.profilePhoto.saveNew';
export const saveNewProfilePhoto = dispatch =>
  (body, caption, sportId, msg, values = {}, isMobile = false) => {
    dispatch(asyncStartAction(EP_PHOTO_SAVE_NEW, msg));
    dispatch({
      type: EP_PHOTO_SAVE_NEW,
      body,
      caption,
      sportId,
      values,
      dispatch,
      isMobile,
    });
  };

export const saveNewProfilePhotoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const userProfile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    ofType(EP_PHOTO_SAVE_NEW),
    getTokenFragment(),
    map(({ action, token }) => ({
      uuid: uuid(),
      token,
      asyncModel: action.type,
      caption: 'Profile photo',
      mediaId: 1,
      file: action.body,
      isAPhoto: true,
      album: null,
      values: action.values,
      dispatch: action.dispatch,
      totalBytes: action.body.size,
      isNew: true,
      outer: action,
    })),
    tap(incoming => incoming.outer.dispatch(uploadProgressUpdate(
      null,
      incoming.outer.body.size,
      '',
      'Uploading photo',
      true,
    ))),
    createMediaEpicFragment(action$),
    tap((results) => {
      if (results.success) {
        results.incoming.outer.dispatch(uploadProgressUpdate(
          null,
          results.incoming.outer.body.size,
          '',
          'Processing photo',
        ));
      }
    }),
    switchMap((results) => {
      if (results.success) {
        const media = mapPhotoApiToUi(results.response);
        let retryCount = 0;
        return getPhoto(results.incoming.token, media.id).pipe(
          map((response) => {
            if (response.resized_photos || response.url) {
              return {
                ...results,
                media: mapPhotoApiToUi(response),
              };
            }
            retryCount += 1;
            const error = {
              tryAgain: true,
            };
            throw error;
          }),
          ajaxErrorHandlerEpicFragment(),
          catchError((error) => {
            if (retryCount > 60) {
              return of({
                success: false,
                action: asyncErrorAction(
                  results.incoming.asyncModel,
                  'pollResized',
                  { name: 'Timeout', desc: 'Timed out while waiting for photo to process' },
                ),
              });
            }
            if (error.tryAgain) throw error;
            return of({
              success: false,
              action: asyncErrorAction(results.incoming.asyncModel, 'pollResized', error),
            });
          }),
          retryWhen(errors => errors.pipe(
            delay(1000),
            tap(val => console.log('Trying again', val)),
          )),
          takeUntil(cancelOnRouteChange(action$, Routes.player, uuid)),
        );
      }
      return results;
    }),
    tap((results) => {
      if (results.success) {
        results.incoming.outer.dispatch(uploadProgressUpdate(
          null,
          results.incoming.outer.body.size,
          '',
          'Saving profile',
        ));
        if (isMobileApp) {
          const { media } = results;
          const profileUrl = media.resizedPhotos && media.resizedPhotos.medium ? media.resizedPhotos.medium : media.url || null;
          const userSport = Object.assign({}, results.incoming.outer.values);
          userSport.photoUrl = profileUrl;
          saveUserSport(userSport, userProfile())(results.incoming.outer.dispatch);
        }
      }
    }),
    switchMap((results) => {
      if (isMobileApp) {
        return of({ type: 'IGNORE' });
      }
      if (results.success) {
        const { media } = results;
        const { outer } = results.incoming;
        return userSportSavePhoto(
          uuid(),
          outer.sportId,
          media.resizedPhotos && media.resizedPhotos.medium
            ? media.resizedPhotos.medium : media.url || null,
          results.incoming.token,
        )
          .pipe(
            map(response => mapUserApiToUi(response, userProfile().schools)),
            map(profile => ({ profile, ...results })),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(EP_PHOTO_SAVE_NEW, 'makeProfile', error),
            })),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (isMobileApp) {
        return of({ type: 'IGNORE' });
      }
      if (results.success) {
        return of(
          asyncFinishAction(EP_PHOTO_SAVE_NEW, 'saveNew'),
          userSportUpdateSuccess(results.profile),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const profilePhotoEpics = combineEpics(
  oldProfilePicsEpic,
  updateProfilePhotoEpic,
  saveNewProfilePhotoEpic,
);
