import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ className, style, children }) => (
  <div
    className={['LeaderboardTableRow', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </div>
);
TableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};

export default TableRow;
