import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Component from "../../components/Welcome/InvitedSignUp/InvitedSignUp";
import { invitedSignUpFormInit, isInviteValid } from "../../store/actions/ui/welcome";
import { formServerError, formSubmit } from "../../store/actions/ui/formUtils";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import { playerProfileDuplicatesSet, playerProfileGet } from "../../store/actions/ui/uxProfile";
import { getCountryCode } from "../../modules/CodeSignUp/+store/common/common.utils";
import { withRouter } from "react-router-dom";
import { logoutWithRedirect } from "../../store/actions/data/cognito";

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};


const checkInviteUserLoggedIn = (state, invitedUserId, uuid) => {
  let flag = false;
  if (invitedUserId && uuid) {
    flag = invitedUserId === uuid;
  }
  return flag;
};


const mapStateToProps = (state, ownProps) => {
  const authUuid = state.data.cognito.uuid;
  return ({
    needsInit: state.ui.welcome.invitedSignUp.formNeedsInit,
    shouldCheckInviteValid: state.ui.welcome.checkInvitation.needsInit,
    isInvitationValid: state.ui.welcome.checkInvitation.isInvitationValid,
    rrfForm: state.forms.forms.invitedSignUp,
    formGeneralError: state.ui.welcome.invitedSignUp.formError,
    loggedIn: !!state.data.cognito.token,
    profile: getProfile(state, ownProps.match.params.uuid),
    authenticatedProfile: getProfile(state, authUuid),
    isInviteUserLoggedIn: checkInviteUserLoggedIn(state, ownProps.match.params.uuid, authUuid),
    countryCode: getCountryCode(state),
    authUuid,
    state,
    duplicateProfiles: state.data.user.duplicateProfiles,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { sportId } = ownProps.match.params;
  const { state, ...newStateProps } = stateProps;
  let schoolStyle = null;
  if (ownProps.match.params.schoolId) {
    schoolStyle = state.modules.coachWorld.coachWorld.schoolStyle.find(school => school.id === ownProps.match.params.schoolId);
  }
  return {
    ...newStateProps,
    ...ownProps,
    schoolStyle,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    checkIfInviteIsValid: (userTeamId, token) => {
      dispatch(isInviteValid(userTeamId, token));
    },
    formInit: (profile) => {
      dispatch(actions.setInitial("forms.invitedSignUp"));
      if (profile) {
        const userSport = profile.sports.find(sport => sport.sportId === Number(sportId));
        const duplicateProfiles = state.data.user.duplicateProfiles;

        // for duplicate emails means user want to create a new account with a new email
        const userName = duplicateProfiles.length === 0 ? profile.email : "";
        console.log(profile,'profileprofile')
        dispatch(actions.merge("forms.invitedSignUp", {
          first: profile.first,
          last: profile.last,
          confirmUsername: userName,
          phone: profile.phone,
          confirmPhone: profile.phone,
          parentName: profile.parentsName,
          parent2Name: profile.parent2Name,
          parentsEmail: profile.parentsEmail,
          confirmParentsEmail: profile.parentsEmail,
          parent2Email: profile.parent2Email,
          confirmParent2Email: profile.parent2Email,
          parentMobile: profile.parentsPhone,
          confirmParentMobile: profile.parentsPhone,
          parent2Mobile: profile.parents2Phone,
          confirmParent2Mobile: profile.parents2Phone,
        }));
      }
      dispatch(invitedSignUpFormInit());
    },
    updateFormError: (userName) => {
      const error = {
        code: "UsernameExistsException",
        value: userName
      };
      dispatch(formServerError("forms.invitedSignUp", "duplicateEmail", error, {
        username: userName
      }));
    },
    updatePosition: (positions) => {
      dispatch(actions.change("forms.invitedSignUp.positions", positions.join(",")));
    },
    updateBats: (bats) => {
      dispatch(actions.change("forms.invitedSignUp.bats", bats));
    },
    updateThrows: (throws) => {
      dispatch(actions.change("forms.invitedSignUp.throws", throws));
    },
    updatePhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.phone", phoneNumber));
    },
    updateConfirmPhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.confirmPhone", phoneNumber));
    },
    updateParentPhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.parentMobile", phoneNumber));
    },
    updateConfirmParentPhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.confirmParentMobile", phoneNumber));
    },
    updateParent2PhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.parent2Mobile", phoneNumber));
    },
    updateConfirmParent2PhoneNumber: (phoneNumber) => {
      dispatch(actions.change("forms.invitedSignUp.confirmParent2Mobile", phoneNumber));
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    forceLogout: (redirectURL) => {
      dispatch(logoutWithRedirect(redirectURL));
    },
    setDuplicateProfiles: (uuid, email, firstName, lastName, inviteUuid) =>{
      dispatch(playerProfileDuplicatesSet(uuid, email, firstName, lastName, inviteUuid))
    },
    submit: (profile, token, userTeamId, sportId, teamName, coachName, schoolId ) => {
      let referrer = "";
      if (ownProps.location && ownProps.location.state) {
        referrer = ownProps.location.state.from;
      }
      dispatch(formSubmit("forms.invitedSignUp", {
        from: referrer,
        isMobile: false,
        profile,
        token,
        dispatch,
        userTeamId,
        sportId,
        schoolId,
        teamName,
        coachName
      }));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default withRouter(Container);
