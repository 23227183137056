import React from 'react';
import PropTypes from 'prop-types';
import { isWindows } from '../../../../containers/UxCommon/Utils';

const TableBody = ({
  className,
  style,
  fixedHeaders,
  children,
}) => (
  <div
    className={[
      'LeaderboardTableBody',
      className || '',
      fixedHeaders ? 'fixedHeaders' : '',
      isWindows() ? 'windowsWidth' : '',
    ].join(' ')}
    style={style || {}}
  >
    {children}
  </div>
);
TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  fixedHeaders: PropTypes.bool,
};

export default TableBody;
