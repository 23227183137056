import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/SideBar/SideBar';
import {
  selectCategoryPhotos,
  selectCategoryVideos,
  selectCategoryAll,
  albumFilterSelected,
  albumFilterUnselected,
  openAlbumSelectDialog,
  toggleFilterList,
  tagFilterSelected,
  tagFilterUnselected,
  openEditTagsDialog,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { getCurrentUuid,getSelectedSport } from '../../UxCommon/Utils';
import { alertOrNavigate } from '../../UxCommon/NativeUtils';
import { makeGetAlbumsState, makeGetTags, makeGetTagRelMedia } from '../../../selectors/Photos';

const getAlbumsState = makeGetAlbumsState();
const getTagsState = makeGetTags();
const getTagsRelMediaState = makeGetTagRelMedia();


const mapStateToProps = (state, ownProps) => ({
  albums: getAlbumsState(state, ownProps),
  tags: getTagsState(state, ownProps),
  tagsRelMedia: getTagsRelMediaState(state, ownProps),
  isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
    || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
  selectedAlbums: state.ui.uxProfile.photosVideos.selectedAlbumFilters,
  selectedCategory: state.ui.uxProfile.photosVideos.selectedCategory,
  isFilterListShown: state.ui.uxProfile.photosVideos.showFilterList,
  selectedTagsFilters: state.ui.uxProfile.photosVideos.selectedTagsFilters,
  state,
  selectedUserSport: getSelectedSport(state, ownProps.match),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onPhotosClick: () => {
      dispatch(selectCategoryPhotos());
    },
    onVideosClick: () => {
      dispatch(selectCategoryVideos());
    },
    onAllClick: () => {
      dispatch(selectCategoryAll());
    },
    onEditAlbumsClick: () => {
      dispatch(openAlbumSelectDialog());
    },
    onEditTagsClick: () => {
      dispatch(openEditTagsDialog());
    },
    onShareClick: () => {
      dispatch({ type: 'noop' });
    },
    onAlbumSelected: (albumId) => {
      dispatch(albumFilterSelected(albumId));
    },
    onAlbumUnselected: (albumId) => {
      dispatch(albumFilterUnselected(albumId));
    },
    onFilterListClick: () => {
      dispatch(toggleFilterList());
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
    onTagSelected: (tagId) => {
      dispatch(tagFilterSelected(tagId));
    },
    onTagUnselected: (tagId) => {
      dispatch(tagFilterUnselected(tagId));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
