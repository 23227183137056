import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Routes } from '../../../../store/actions/ui/routes';

import { Tooltip } from '../../../../store/actions/ui/tooltip';
import NavLink from './NavLink/NavLink';
import NavButton from './NavButton/NavButton';
import { restrictedSchoolIds } from '../../../../../src/components/UxCommon/Form/utils';
import useHasMembership from '../../../../hooks/useHasMembership';
import Icon from "../../../UxCommon/Icon/Icon";
import {ButtonRect, CloseButton} from "../../../UxCommon";
import {Link, matchPath, Redirect} from "react-router-dom";

export const Sidebar = (props) => {
  const onLogoutClick = (e) => {
    const { onLogoutClick, onSearchLoseFocus } = props;
    onSearchLoseFocus(e);
    onLogoutClick();
  };

  const openCalendlyModal = ()=>{
    const { updateCalendlyModal } = props;
    updateCalendlyModal();
  }

  const {
    match,
    uuid,
    onMenuClick,
    isMenuShown,
    isSearchFocused,
    onSearchLoseFocus,
    isMyProfile,
    user,
    isCoach,
    selectedUserSport,
  } = props;

  const hasMembership = useHasMembership(match);

  const coachSelectedSchool = match.params.schoolId
    ? match.params.schoolId
    : '';
  const sportId = selectedUserSport && selectedUserSport.sportId;
  const schoolId = coachSelectedSchool && coachSelectedSchool != 'profile-photo'
    ? coachSelectedSchool
    : user && user.schools.length > 0
    ? user.schools[0].school_id
    : null;
  if (!isMyProfile && user && user.privacy_all) {
    return null;
  }
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const isProfileLandingPage = !!matchPath(location.pathname, {
    path:`/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`,
    exact: true,
  });

  return (
    <div className="UxProfile_S_SidebarNav">
      <button
        type="button"
        className={[
          'UxProfile_S_SidebarNav_TopIcon position-relative',
          isMenuShown ? 'open' : '',
        ].join(' ')}
        onClick={onMenuClick}>
        <div className="UxProfile_S_SidebarNav_IconTopSvg">
          {isMenuShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
              data-tip={isMobile ? '' : Tooltip.closeMenu}>
              <path
                fill="#fff"
                d="M17.681 15.58L11.101 9l6.58-6.58a1.133 1.133 0 0 0 0-1.576L17.156.32a1.133 1.133 0 0 0-1.576 0L9 6.899 2.42.319a1.133 1.133 0 0 0-1.576 0L.32.844a1.133 1.133 0 0 0 0 1.576L6.899 9l-6.58 6.58a1.133 1.133 0 0 0 0 1.576l.525.525c.426.425 1.151.425 1.576 0L9 11.101l6.58 6.58c.425.425 1.15.425 1.576 0l.525-.525c.425-.45.425-1.151 0-1.576z"
              />
            </svg>
          ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                fill="none"
                viewBox="0 0 20 15"
                data-tip={isMobile ? '' : Tooltip.openMenu}>
                <path
                  fill="#fff"
                  d="M1.25 2.5h17.5c.688 0 1.25-.563 1.25-1.25C20 .562 19.437 0 18.75 0H1.25C.562 0 0 .563 0 1.25 0 1.938.563 2.5 1.25 2.5zM18.75 6.25H1.25C.562 6.25 0 6.813 0 7.5c0 .688.563 1.25 1.25 1.25h17.5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25zM18.75 12.5H1.25c-.688 0-1.25.563-1.25 1.25C0 14.438.563 15 1.25 15h17.5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25z"
                />
              </svg>

          )}
          {!isMenuShown && isProfileLandingPage && !isCoach && isMyProfile && (
            <span className="badge badge-danger">1</span>
          )
          }

          <ReactTooltip />
        </div>
      </button>

      <NavLink
        match={match}
        page={Routes.player}
        iconName="player-data"
        txt="Player Profile"
        isMenuShown={isMenuShown}
        isSearchFocused={isSearchFocused}
        onClick={onSearchLoseFocus}
        tooltip={isMobile ? '' : Tooltip.playerProfile}
        sportId={sportId}
        coachSelectedSchool={coachSelectedSchool}
      />
      {isMyProfile && (
        <NavLink
          match={match}
          page={Routes.edit}
          iconName="edit"
          txt="Edit Profile"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          tooltip={isMobile ? '' : Tooltip.athleteEditProfile}
          sportId={sportId}
          coachSelectedSchool={coachSelectedSchool}
          isDisabled={!hasMembership}
        />
      )}
      {(isMyProfile ||
        (user && (!user.privacy_tests || !user.privacy_stats))) && (
        <NavLink
          match={match}
          page={Routes.data}
          iconName="data"
          txt="Player Data"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          nudge={{ left: '-0.5px' }}
          tooltip={isMobile ? '' : Tooltip.playerData}
          sportId={sportId}
          coachSelectedSchool={coachSelectedSchool}
          isDisabled={!hasMembership}
        />
      )}
      {(isMyProfile || (user && !user.privacy_media)) && (
        <NavLink
          match={match}
          page={Routes.photos}
          iconName="photo"
          txt="Photos &amp; Videos"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          nudge={{ left: '-1px', fontSize: '19px' }}
          tooltip={isMobile ? '' : Tooltip.athletePhotVideo}
          sportId={sportId}
          coachSelectedSchool={coachSelectedSchool}
          isDisabled={!hasMembership}
        />
      )}
      {(isMyProfile || (user && !user.privacy_awards)) && (
        <NavLink
          match={match}
          page={Routes.awards}
          iconName="award"
          txt="Awards"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          nudge={{ left: '0px', fontSize: '24px' }}
          wrapperStyle={{ maxWidth: '28px', overflow: 'hidden' }}
          tooltip={isMobile ? '' : Tooltip.athleteAward}
          sportId={sportId}
          coachSelectedSchool={coachSelectedSchool}
          isDisabled={!hasMembership}
        />
      )}

      {/*Due to some urgent priority we have disabled the leaderboard button for Wired Training School
        In future we will have feature of private leaderboard
        */}
      {!restrictedSchoolIds.includes(schoolId) &&
        isMyProfile &&
        user &&
        schoolId && (
          <NavLink
            match={match}
            page={Routes.leaderboard}
            iconName="leaderboard"
            txt="Leaderboard"
            isMenuShown={isMenuShown}
            isSearchFocused={isSearchFocused}
            onClick={onSearchLoseFocus}
            schoolId={schoolId}
            nudge={{ left: '0px', fontSize: '27px' }}
            wrapperStyle={{ maxWidth: '28px', overflow: 'hidden' }}
            tooltip={isMobile ? '' : Tooltip.athleteLeaderboard}
            isDisabled={!hasMembership}
          />
        )}

      {isMyProfile && user && schoolId && (
        <NavLink
          match={match}
          page={Routes.collegeContact}
          iconName="college-directory"
          txt="College Directory"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          schoolId={schoolId}
          nudge={{ left: '0px', fontSize: '27px' }}
          wrapperStyle={{ maxWidth: '28px', overflow: 'hidden' }}
          tooltip={isMobile ? '' : Tooltip.collegeDirectory}
          isDisabled={!hasMembership}
        />
      )}
      {isMyProfile && user && schoolId && (
        <NavLink
          match={match}
          page={Routes.TrainingVideo}
          iconName="play-circle2"
          txt="Training Center"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onSearchLoseFocus}
          schoolId={schoolId}
          nudge={{ left: '0px', fontSize: '27px' }}
          wrapperStyle={{ maxWidth: '28px', overflow: 'hidden' }}
          tooltip={isMobile ? '' : Tooltip.TrainingVideo}
          isDisabled={!hasMembership}
        />
      )}

      {isMyProfile && user && schoolId && !isCoach && (
        <NavLink
          match={match}
          page={Routes.levelUp}
          iconName="plus"
          txt="Level Up"
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={openCalendlyModal}
          schoolId={schoolId}
          nudge={{ left: '0px', fontSize: '27px' }}
          wrapperStyle={{ maxWidth: '28px', overflow: 'hidden' }}
          tooltip={isMobile ? '' : Tooltip.LevelUp}

        />
      )}

      {uuid && (
        <NavButton
          match={match}
          iconName="signout"
          txt="Sign Out"
          className="roster-signout-btn"
          submenu
          isMenuShown={isMenuShown}
          isSearchFocused={isSearchFocused}
          onClick={onLogoutClick}
          nudge={{ left: '4px', top: '-3px', fontSize: '26px' }}
          tooltip={isMobile ? '' : Tooltip.signOut}
        />
      )}
    </div>
  );
};

Sidebar.proptypes = {
  match: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
  isMenuShown: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onSearchLoseFocus: PropTypes.func.isRequired,
  isSearchFocused: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  isMyProfile: PropTypes.bool.isRequired,
  user: PropTypes.object,
  isCoach: PropTypes.bool,
};

export default Sidebar;
