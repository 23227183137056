import { connect } from 'react-redux';
import Component from './ConfirmEmailPhone.component';
import { resendEmailPhoneValidation } from '../../../../store/actions/ui/uxEditProfile';
import { coachVerifyEmailPhone } from '../../+store/account';
import { Routes } from '../../../../store/actions/ui/routes';

const mapStateToProps = (state, ownProps) => {
  const { cognitoUser } = state.data.cognito;
  let email = '';
  let phone = '';
  let attributes;
  if (cognitoUser && cognitoUser.attributes) {
    ({ attributes } = cognitoUser);
  } else if (cognitoUser
  && cognitoUser.signInUserSession
  && cognitoUser.signInUserSession.idToken) {
    attributes = cognitoUser.signInUserSession.idToken.payload;
  }
  if (attributes) {
    ({ email } = attributes);
    phone = attributes.phone_number;
  }
  const isTelephone = ownProps.match.url.indexOf(Routes.confirmPhone) >= 0;
  return {
    isTelephone,
    email,
    phone,
    loadingMsg: state.modules.coachWorld.account.loadingMsg,
    errorMsg: state.modules.coachWorld.account.errorMsg,
    successMsg: state.modules.coachWorld.account.successMsg,
  };
};

const mapDispatchToProps = dispatch => ({
  resendCode: (isTelephone) => {
    dispatch(resendEmailPhoneValidation(isTelephone));
  },
  verify: (isTelephone, code) => {
    dispatch(coachVerifyEmailPhone(isTelephone, code));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
