import { createSelector } from "reselect";
import queryString from "query-string";
import { BankSlugs } from "../combine";
import { StandardFormat, vcScoreColumns } from "../nationalLeaderboard/nationalLeaderboard.models";
import { Sorters } from "../leaderboard/leaderboard.selectors";


export const getCurrentSport = state => state.modules.coachWorld.districtLeaderboard.currentSport;

export const getCurrentTemplate = (state) => {
  return state.modules.coachWorld.districtLeaderboard.template;
};

const getCurrentBank = (state, ownProps) => ownProps.match.params.bank;


export const setSearch = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.search = value;
  history.push({
    search: queryString.stringify(parsedQuery)
  });
};


export const selectCurrentColumns = createSelector(
  getCurrentTemplate,
  getCurrentBank,
  (template, bank) => {
    if (template) {
      if (bank === BankSlugs.VC_SCORE) {
        return vcScoreColumns;
      } else if (bank === BankSlugs.STRENGTH) {
        if (!template.columns.find(c => c.standardTestObjectId === 999)) {
          const stdFormat = new StandardFormat();
          stdFormat.code = "WEIGHT-POUNDS-INTEGER";
          template.columns.push({
            standardTestObjectId: 999,
            displayStandardUnitOfMeasureCode: "POUNDS",
            standardUnitOfMeasureCode: "POUNDS",
            testCategoryCode: "TOTAL",
            testCategoryName: "Total",
            testBankCode: "STRENGTH",
            standardFormat: stdFormat,
            abbreviation: "TOTAL"
          });
        }
      }
      return template.columns.filter(c => BankSlugs[c.testBankCode] === bank);
    }
    return [];
  }
);

const getCurrentQuery = state => state.ui.app.routes.currentQuery;
export const selectSortBy = createSelector(
  getCurrentQuery,
  selectCurrentColumns,
  (currentQuery, columns) => {
    const parsedQuery = queryString.parse(currentQuery);
    const { sortBy } = parsedQuery;
    let stdTestId = Number(sortBy) || 0;
    if (Number.isNaN(stdTestId)) {
      stdTestId = 0;
    }
    if (columns.length) {
      if (stdTestId && columns.find(c => c.standardTestObjectId === stdTestId)) {
        return stdTestId;
      }
      return columns[0].standardTestObjectId;
    }
    return stdTestId;
  }
);

export const currentSport = createSelector(
  getCurrentQuery,
  (currentQuery) => {
    const parsedQuery = queryString.parse(currentQuery);
    const { sport } = parsedQuery;
    return sport;
  }
);

export const getSearch = createSelector(
  getCurrentQuery,
  (currentQuery) => {
    const parsedQuery = queryString.parse(currentQuery);
    const { search } = parsedQuery;
    return search || "";
  }
);

const getDistrictLeaderboardAthletes = state => (
  state.modules.coachWorld.districtLeaderboard.results
    ? state.modules.coachWorld.districtLeaderboard.results.rosters
    : []
);

const getScoreType = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { score } = parsedQuery;
  return score || "raw";
};

const getTestCategories = state => state.modules.coachWorld.combine.standardTestCats.organizedCats;

export const selectDistrictLeaderboard = createSelector(
  getDistrictLeaderboardAthletes,
  selectCurrentColumns,
  selectSortBy,
  getScoreType,
  getTestCategories,
  (
    currentAthletes,
    columns,
    sortById,
    scoreType,
    testCats
  ) => {
    if (columns.length && currentAthletes.length && testCats) {
      const sortedAthletes = currentAthletes.slice();
      const sorter = Sorters["min"];
      //sort by rank
      sortedAthletes.sort((a, b) =>
        sorter(a, b, (athlete) => Number(athlete.rank)));
      return sortedAthletes;
    }
    return [];
  }
);

export default "districtLeaderboard.selectors.js";
