import axios from 'axios';
import Urls from '../../config/urls';

export const getInviteLink = async (userId, sportId, schoolId) => {
  const res = await axios.get(
    schoolId !== undefined ? Urls.getInviteLink(userId, sportId, schoolId) :
      Urls.getInviteLinkWithoutSchool(userId, sportId)
  );
  return res.data.inviteURL;
};
