import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ButtonRect, CloseButton} from '../../../../components/UxCommon';

class PrintFieldSelector extends PureComponent {
  static propTypes = {
    onArchiveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    msg: PropTypes.string,
    btnMsg: PropTypes.string,
    gridApi: PropTypes.object
  };

  state = {
    selectAll: true,
    checked: {},
    targetName: "",
    totalColumn: [],
  }

  componentDidMount(){
    const { gridApi } = this.props;
    const {columnController} = gridApi.cellRendererFactory.gridOptionsWrapper.gridOptions.columnApi;
    const {displayedCenterColumns, displayedLeftColumns} = columnController;
    const leftColumn = displayedLeftColumns.filter(column => column.colId !== "1");
    const totalColumn = [...leftColumn, ...displayedCenterColumns];
    const obj = {}
    totalColumn.map( col => {
      obj[col.colId] = true; 
    });
    this.setState({totalColumn: totalColumn, checked: obj});
  }

  onChangeFields = (totalColumn) => {
    const {onArchiveClick} = this.props;
    const {checked} = this.state;
    const tempChecked = [];
    let filteredColumn;
    for (const key in checked) {
      if (!checked[key]) {
        tempChecked.push(key);
      }
    }
    if (tempChecked.length !== 0) {
      filteredColumn = totalColumn.filter(column => !tempChecked.includes(column.colId))
    }
    onArchiveClick(filteredColumn || totalColumn);
  }

  checkboxHandler = (event) => {
    let changedCheck = {...this.state.checked};
    let isAllChecked = true;
    changedCheck[event.target.name] = !this.state.checked[event.target.name];
    Object.keys(changedCheck).map(colName => {
      if (!changedCheck[colName]) {
        return isAllChecked = false;
      }
    })
    if (isAllChecked) {
      return this.setState({selectAll: true, checked: changedCheck});
    }
    return this.setState({selectAll: false, checked: changedCheck});
  }

  selectAllHandler = () => {
    const {checked} = this.state;
    let changedCheck = {...checked};
    Object.keys(changedCheck).map(colName => changedCheck[colName] = !this.state.selectAll)
    return this.setState({selectAll: !this.state.selectAll, checked: changedCheck});
  }

  render() {
    const {
      onCancelClick,
      msg,
      btnMsg,
    } = this.props;
    let {title} = this.props;
   const {totalColumn, checked} = this.state;
    const question = (
      <span>
        {msg || 'Are you sure you want to print?'}
      </span>
    );

    if (!title) {
      title = 'Delete Combine?';
    }


    return (
      <div className="ConfirmDialogWrapper">
        <div className="ConfirmDialog">
          <CloseButton size={10} onClick={onCancelClick}/>
          <div className="AlbumDialod_DeleteAlbumDialog_Title">
            {title}
          </div>
          <div className="AlbumDialod_DeleteAlbumDialog_Question">
            {question}
          </div>
          <div style={{display: "flex", flexDirection: "column", overflow: "scroll"}}>
            <div style={{display: "flex"}}>
              <input
                style={{marginTop: "1%", marginRight: "2%"}}
                type="checkbox"
                name={"selectAll"}
                checked={this.state.selectAll}
                onChange={this.selectAllHandler}
              />
              <label style={{fontWeight: "bold", fontSize: 16}}>{"Select All"}</label>
            </div>
            { /* checkbox with label is added */
              totalColumn && totalColumn.map(column => <div key={column.colId} style={{display: "flex"}}>
                <input
                  style={{marginTop: "1%", marginRight: "2%"}}
                  type="checkbox"
                  name={`${column.colId}`}
                  checked={checked[column.colId]}
                  onChange={(e) => this.checkboxHandler(e)}
                />
                <label style={{fontSize: 14,width: 150}}>{column.userProvidedColDef.headerName}</label>
              </div>)
            }
          </div>

          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={() => this.onChangeFields(totalColumn)}
          >
            {btnMsg || 'Delete'}
          </ButtonRect>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default PrintFieldSelector;

