import { ajax } from 'rxjs/ajax';
import { School } from '../../../../modules/CoachWorld/+store/roster/roster.models';
import Urls from '../../../../config/urls';

export const getCoachSchools = (uuid, token) => ajax.getJSON(
    Urls.schools.coachSchool(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export class Coach{
  static fromApiResponse(response) {
    const coach = new Coach();
    coach.createdAt = response.created_at;
    coach.id = response.id;
    coach.schoolId = response.school_id;
    coach.updatedAt = response.updated_at;
    coach.userId = response.user_id;
    coach.school = School.fromApi(response.school)
    coach.userType = response.user_type;
    return coach;
  }
} 