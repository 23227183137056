import { connect } from 'react-redux';
import Component from './SignInPage.component';
import { signIn } from '../+store/signInPage/signInPage.actions';

const mapStateToProps = (state) => {
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return {
    profile,
    uuid,
    errorMsg: state.modules.codeSignUp.signInPage.errorMsg,
  };
};

const mapDispatchToProps = dispatch => ({
  signIn: (fields, code) => {
    dispatch(signIn(fields, code));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
