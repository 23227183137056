import React, { useEffect, useState } from 'react';
import { CloseButton, ButtonRect } from '../../../../components/UxCommon';
import SelectControl from '../../../../modules/CodeSignUp/SimpleForm/SelectControl';
import { Sports } from '../../../../store/actions/ui/routes';
import LoadingIndicator from '../../../../modules/CoachWorld/common/LoadingIndicator';
import WelcomeHeader from '../../Common/WelcomeHeader/WelcomeHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSchoolStyles } from '../../../../modules/CoachWorld/+store';

const MergeProfiles = ({ profile, duplicateProfile, match, onNext }) => {
  const [loading, setLoading] = useState(false);
  const { schoolId } = useParams();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(false);
  const schoolStyle = useSelector((state) =>
    state.modules.coachWorld.coachWorld.schoolStyle.find(
      (school) => school.id === schoolId,
    ),
  );

  useEffect(() => {
    dispatch(getSchoolStyles([schoolId]));
  }, [schoolId]);

  const onChangeHandler = (value) => {
    setSelectedOption(value);
  };

  const handleContinue = () => {
    onNext(selectedOption);
    // onValueChange(sportId);
  };

  const onClickClose = () => {};

  return (
    <div className="CoachWorld_EditPlayer_Backdrop OnBoarding-merge-profiles">
      <div className="Combine_Edit_Modal">
        <WelcomeHeader
          match={match}
          loading={false}
          loadingMsg={''}
          schoolStyle={schoolStyle}>
          <CloseButton onClick={onClickClose} size={10} />
          <LoadingIndicator loading={false} loadingMsg={'Loading..'} />
          <div className="Combine_Edit_Container">
            <div className="OnBoarding-merge_Header OnBoarding-merge-profiles-message">
              {`Hi ${profile.first} ${profile.last}`}!
            </div>
            <div className="Sports_Selector_Sub_Head OnBoarding-merge-profiles-message">{`There is another account registered to ${duplicateProfile.first} ${duplicateProfile.last} using ${duplicateProfile.email}`}</div>
            <div
              className={
                'Sports_Selector_Ques OnBoarding-merge-profiles-message'
              }>
              {'Which option is best for you ?'}
            </div>
            <form className="Sports_Form">
              {
                <SelectControl
                  name="Sports"
                  classNameRadio="SelectSport_RadioButton OnBoarding-merge-profiles-options d-flex unsetTextTransform"
                  useRadio={true}
                  options={{
                    1: `${duplicateProfile.first} ${duplicateProfile.last} is the correct athlete. Please merge all data, so I have one account.`,
                    2: `${duplicateProfile.first} ${duplicateProfile.last} is not the correct athlete. I do though, already have an account and need to sign in.`,
                    3: `${duplicateProfile.first} ${duplicateProfile.last} is not the correct athlete. I do not have an account and need to create one.`,
                  }}
                  myOnChange={onChangeHandler}
                  myOnBlur={onChangeHandler}
                  defaultValue={selectedOption}
                />
              }
            </form>
            <div className="Combine_Edit_Btns Select_Sports_Buttons">
              <React.Fragment>
                <ButtonRect
                  className="Photos_ExpandedPhotoDialog_Button primary"
                  style={{ width: '120px' }}
                  type="button"
                  disabled={!selectedOption}
                  onClick={handleContinue}>
                  Next
                </ButtonRect>
              </React.Fragment>
            </div>
          </div>
        </WelcomeHeader>
      </div>
    </div>
  );
};

export default MergeProfiles;
