import { combineReducers } from 'redux';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { SECOND_SIGNIN_FORM_INIT } from '../../../actions/ui/welcome';
import { AUTH2_LOGIN_REQ, AUTH2_LOGIN_SUC } from '../../../actions/data/cognito';

import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_FAILED,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { cognitoUsernameError } from '../utils/errors';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
      return true;
    case SECOND_SIGNIN_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const formError = (state = '', action) => {
  switch (action.type) {
    case AUTH2_LOGIN_REQ:
    case AUTH2_LOGIN_SUC:
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case SECOND_SIGNIN_FORM_INIT:
    case 'rrf/blur':
    case 'rrf/batch':
      return '';
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.secondSignIn') {
        return cognitoUsernameError(action.error, action);
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (action.model === 'forms.secondSignIn') {
        return 'Please correct the errors above';
      }
      return state;
    default:
      return state;
  }
};

const username = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.resetPassword') {
        return action.data.username;
      }
      if (action.model === 'forms.confirmAccount') {
        return action.data.username;
      }
      if (action.model === 'forms.joinNow') {
        return action.data.username;
      }
      return state;
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.login') {
        if (action.error.code === 'UserNotConfirmedException') {
          return action.data.username;
        }
      }
      return state;
    default:
      return state;
  }
};

const password = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.resetPassword') {
        return action.data.password;
      }
      if (action.model === 'forms.joinNow') {
        return action.data.password;
      }
      if (action.model === 'forms.login') {
        return '';
      }
      return state;
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.login') {
        if (action.error.code === 'UserNotConfirmedException') {
          return action.data.password;
        }
      }
      return state;
    default:
      return state;
  }
};

const msg = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.resetPassword') {
        return 'Your password has been reset!';
      }
      if (action.model === 'forms.confirmAccount') {
        return 'Your account has been confirmed!';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
  username,
  password,
  msg,
});

export default reducer;
