import { connect } from 'react-redux';
import Component from '../../components/UxEditProfile/StickyHeader/StickyHeader';
import {getCurrentUuid, getSortedSports, getSelectedSport} from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    uuid: isCoach ? getCurrentUuid(state, ownProps.match) : state.data.cognito.uuid,
    sports: state.data.sport.sports,
    userSports: getSortedSports(state, ownProps.match),
    selectedUserSport: getSelectedSport(state, ownProps.match),
  });
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
