import { StandardFormat } from "../nationalLeaderboard/nationalLeaderboard.models";
import { CachedTest, CachedTestResult, School } from "../leaderboard/leaderboard.models";
import { CombineTestResult, CombineVcScore, vcScoreColumnsObj } from "../combine/combine.models";

export class CombineTemplate {
  static fromApi(response) {
    const combineTemplate = new CombineTemplate();
    combineTemplate.columns = [];
    const banks = {};
    if (response.length) {
      response.forEach((column) => {
        const templateColumn = TemplateColumn.fromApi(column);
        templateColumn.test_cat_id = column.standard_test_category.id;
        banks[templateColumn.testBankCode] = true;
        combineTemplate.columns.push(templateColumn);
      });
    }
    combineTemplate.banks = Object.keys(banks);
    return combineTemplate;
  }
}

export class TemplateColumn {
  static fromApi(response) {
    const templateColumn = new TemplateColumn();
    // eslint-disable-next-line max-len
    templateColumn.standardTestObjectId = response.id;
    // eslint-disable-next-line max-len
    templateColumn.displayStandardUnitOfMeasureCode = response.standard_test_category.display_standard_unit_of_measure.code;
    // eslint-disable-next-line max-len
    templateColumn.standardUnitOfMeasureCode = response.standard_test_category.standard_unit_of_measure.code;
    templateColumn.testCategoryCode = response.standard_test_category.code;
    templateColumn.testCategoryName = response.standard_test_category.name;
    // eslint-disable-next-line max-len
    templateColumn.testBankCode = response.standard_test_subbank.standard_test_bank.code;
    templateColumn.subbankCode = response.standard_test_subbank.code;
    templateColumn.awardable = response.standard_test_category.awardable === 1;
    templateColumn.standardFormat = StandardFormat
      .fromApi(response.standard_test_category.standard_stat_format);
    templateColumn.useLaser = response.standard_test_category.use_laser;
    templateColumn.useReps = response.standard_test_category.use_reps;
    templateColumn.isDashr = response.standard_test_category.is_dashr;
    templateColumn.tooltip = response.standard_test_category.tooltip_example;
    templateColumn.best = response.standard_test_category.best;
    templateColumn.comparable = !!response.standard_test_category.comparable;
    templateColumn.abbreviation = response.standard_test_category.abbreviation_name;
    return templateColumn;
  }
}

export class NationalLeaderboardRosters {
  static fromApi(response, combineStandardTestCategories, sortBy) {
    const rosters = [];
    response.forEach((result) => {

      if (!result.athlete_results) {
        return;
      }

      const cachedAthletes = CachedAthlete.fromApi(
        {
          ...result.athlete_results.results[0],
          atlResult:result.raw,
          sortBy: sortBy && sortBy.id,
          rank: result.athlete_rank ? result.athlete_rank.rank : null,
          filterRank: result.filter_rank
        },
        combineStandardTestCategories
      );
      rosters.push(cachedAthletes);
    });
    return rosters;
  }
}

const standardTestObjectsMap = {};

export class CachedAthlete {
  static fromApi(response, combineStandardTestCategories) {
    const athlete = new CachedAthlete();
    athlete.userId = response.aws_uuid;
    athlete.last = response.family_name;
    athlete.fromCache = response.from_cache;
    athlete.gender = response.gender;
    athlete.first = response.given_name;
    athlete.gradyear = response.grad_year;
    athlete.grade = response.grade;
    athlete.headshotUrl = response.headshot_url;
    athlete.height = response.height;
    athlete.jerseyNumber = response.jersey_number;
    athlete.name = response.name;
    athlete.position = response.position;
    athlete.schoolTeamId = response.school_team_id;
    athlete.teamName = response.team_name;
    athlete.teamReferenceId = response.team_reference_id;
    athlete.isResultInMetricUnit = response.is_result_in_metric_unit;
    athlete.testId = response.test_id;
    athlete.zip = response.athlete_zip || response.school_zip;
    athlete.school = School.fromApi(response);
    athlete.rank = response.rank || null;
    athlete.filterRank = response.filterRank || null;
    athlete.combineNumber = response.combine_no;
    athlete.privacyWeight = response.privacy_weight || 0;
    athlete.privacyHeight = response.privacy_height || 0;
    athlete.userSport = response.user_sports;
    athlete.sportId = response.sport_id;
    athlete.isSubscribed = response.is_subscribed;
    athlete.belongToRevShareSchool = response.belongToRevShareSchool;
    athlete.city = response.city;
    athlete.state = response.state;
    athlete.zip = response.zip;
    if (response.test_results) {
      if (response.test_results.test) {
        athlete.test = CachedTest.fromApi(response.test_results.test);
      } else {
        athlete.test = null;
      }
      if (response.test_results.test_results) {
        athlete.testResults = {};
        // Create model for test result
        response.test_results.test_results.forEach((result) => {
          const testResult = CachedTestResult.fromApi(result);
          // Grab test & cache category from combineStandardTestCategories
          let cat = standardTestObjectsMap[testResult.standardTestObjectId];
          if (!cat && combineStandardTestCategories) {
            cat = combineStandardTestCategories.find((stdCat) => {
              const foundObj = stdCat.id === testResult.standardTestObjectId;
              // const foundObj = stdCat.standard_test_category.standard_test_objects.find(stdObj => (
              //   stdObj.id === testResult.standardTestObjectId
              // ));
              if (foundObj) return stdCat;
              return null;
            });
            if (cat) {
              standardTestObjectsMap[testResult.standardTestObjectId] = cat;
            }
          }
          if (cat) {
            testResult.stdUnitOfMeasureCode = cat.standard_test_category
              .standard_unit_of_measure.code;
            testResult.bankCode = cat
              .standard_test_subbank
              .standard_test_bank.code;
          }

          if(testResult.standardTestObjectId === response.sortBy){
           testResult.result = parseFloat(response.atlResult);
          }
          athlete.testResults[testResult.standardTestObjectId] = testResult;
        });
        const total = Object.values(athlete.testResults)
          .filter(t => (t.stdUnitOfMeasureCode === "POUNDS" || t.stdUnitOfMeasureCode === "KG") && t.bankCode === "STRENGTH")
          .reduce((prev, curr) => prev + curr.multiRepMax, 0);
        athlete.testResults = {
          ...athlete.testResults,
          [999]: {
            result: total,
            relativeStrength: "N/A",
            ratedResult: "N/A"
          }
        };
      } else {
        athlete.testResults = {};
      }

      if (response.test_results.vc_score) {
        athlete.vcScore = CombineVcScore.fromApi(response.test_results.vc_score);
        const testId = athlete.test ? athlete.test.id : 0;
        athlete.testResults[vcScoreColumnsObj.performance
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.performance,
          vcScoreColumnsObj.performance,
          testId
        );
        athlete.testResults[vcScoreColumnsObj.strength
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.strength,
          vcScoreColumnsObj.strength,
          testId
        );
        athlete.testResults[vcScoreColumnsObj.vc
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.vc,
          vcScoreColumnsObj.vc,
          testId
        );
      } else {
        athlete.vcScore = {};
      }
    } else {
      athlete.testResults = [];
      athlete.vcScore = {};
      athlete.test = null;
    }
    athlete.weight = response.weight;
    return athlete;
  }
}
