import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CompareBanner = ({ report }) => {
  let combines = 'Combine Comparison Report';
  let closeDate = '';
  if (report) {
    const closeDate1 = moment(report.combine.closed_at);
    const closeDate2 = moment(report.second_combine.closed_at);
    const compareDate = closeDate1 < closeDate2;
    if (compareDate) {
      combines = `${report.combine.name} & ${report.second_combine.name}`;
      closeDate = ` - ${closeDate1.format('MM.DD.YYYY')}`;
    } else {
      combines = `${report.second_combine.name} & ${report.combine.name}`;
      closeDate = ` - ${closeDate2.format('MM.DD.YYYY')}`;
    }
  }
  return (
    <div className="CombineReportPage_Banner">
      <div className="CombineReportPage_BannerText">{`REPORT${closeDate}`}</div>
      <div className="CoachRoster_BannerFullTitle">{combines}</div>
    </div>
  );
};
CompareBanner.propTypes = {
  report: PropTypes.object,
};

export default CompareBanner;
