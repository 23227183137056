/* eslint react/no-unused-state: 0 */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Routes } from "../../store/actions/ui/routes";
import WelcomeHeader from "./Common/WelcomeHeader/WelcomeHeader";
import Zero from "./Zero/Zero";
import JoinNow from "../../containers/Welcome/JoinNow";
import ConfirmAccount from "../../containers/Welcome/ConfirmAccount";
import SignIn from "../../containers/Welcome/SignIn";
import ForgotPass from "../../containers/Welcome/ForgotPass";
import ResetPass from "../../containers/Welcome/ResetPass";
import SecondSignIn from "../../containers/Welcome/SecondSignIn";
import MergeSignIn from "../../containers/Welcome/MergeSignIn";
import MergeAnotherAccount from "../../containers/Welcome/MergeAnotherAccount";
import MergeAnotherAccountAuth from "../../containers/Welcome/MergeAnotherAccountAuth";
import PrivateRoute from "../../containers/App/PrivateRoute";
import MergeSignInAuth from "../../containers/Welcome/MergeSignInAuth";
import AthletePhysiquePage from "./AthletInviteOnboarding/components/AthletePhysiquePage.component";
import InviteComponent from "./Invite/InviteComponent";
import AthleteAcademicPage from './AthletInviteOnboarding/components/AthleteAcademicOnboarding.component';

class Welcome extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    getProfile: PropTypes.func,
    uuid: PropTypes.any,
    profile: PropTypes.object,
    getSchoolStyles: PropTypes.func.isRequired,
    schoolStyle: PropTypes.any,
  };

  state = {
    renderZero: false,
  }

  componentDidMount() {
    document.getElementsByTagName('html')[0].classList.add('wrap-welcome');
    const { match , getSchoolStyles} = this.props;
    if(match.params.schoolId){
      getSchoolStyles([match.params.schoolId]);
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.uuid !== newProps.uuid) {
      this.props.getProfile();
    }
    if (oldProps.roleId !== newProps.roleId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ renderZero: true });
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('html')[0].classList.remove('wrap-welcome');
  }

  renderZero = props => (this.props.isAuthenticated || this.state.renderZero) && (
    <Zero
      {...props}
      profile={this.props.profile}
      isAuthenticated={this.props.isAuthenticated}
    />
  )

  render() {

    const { profile } = this.props;

    return (
      <div className="welcome">
        <WelcomeHeader
          match={this.props.match}
          schoolStyle={this.props.schoolStyle}
          loading={this.props.loading}
          loadingMsg={this.props.loadingMsg}
          latestSchool={this.props.profile ? this.props.profile.latestSchool : null}
          profile={profile}
        >
          <Route exact path={`/${Routes.welcome}`} render={this.renderZero} />
          <Route exact path={`/${Routes.welcome}/silas`} component={AthletePhysiquePage} />
          <Route exact path={`/${Routes.welcome}/page3`} component={AthleteAcademicPage} />
          <Route exact path={`/${Routes.welcome}/${Routes.signup}`} component={JoinNow} />
          <Route exact path={`/${Routes.welcome}/${Routes.confirmAccount}/:username/:code?`} component={ConfirmAccount} />
          <Route exact path={`/${Routes.welcome}/${Routes.login}`} component={SignIn} />
          <Route exact path={`/${Routes.welcome}/${Routes.pma}/${Routes.mergeLogin}/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId?`} component={MergeSignIn} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeLogin}/:mergeType/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId?`} component={MergeSignIn} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeAnotherAccount}/:mergeType/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId?`} component={MergeAnotherAccount} />
        {/*<Route exact path={`/${Routes.welcome}/${Routes.invited}/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId`} component={InvitedSignUp} />*/}
          <Route exact path={`/${Routes.welcome}/${Routes.invited}/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId?`} component={InviteComponent} />
          <Route path={`/${Routes.welcome}/${Routes.pma}/${Routes.invited}/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId`} component={InviteComponent} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeLoginForgotPassword}/:mergeType/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId?`} component={ForgotPass} />
          <Route exact path={`/${Routes.welcome}/${Routes.secondSignIn}`} component={SecondSignIn} />
          <Route exact path={`/${Routes.welcome}/${Routes.forgot}`} component={ForgotPass} />
          <Route exact path={`/${Routes.welcome}/${Routes.resetPassword}/:username/:code?`} component={ResetPass} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeLoginResetPassword}/:mergeType/:uuid/:token/:teamName/:coachName/:sportId/:userTeamId/:schoolId/:username`} component={ResetPass} />


          {/*Merge profiles routes when athlete update an existing email*/}
          <PrivateRoute exact path={`/${Routes.welcome}/${Routes.mergeLoginAuth}/:mergeType/:newEmail`} component={MergeSignInAuth} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeLoginAuthForgotPassword}/:mergeType/:uuid/:newEmail`} component={ForgotPass} />
          <Route exact path={`/${Routes.welcome}/${Routes.mergeLoginAuthResetPassword}/:mergeType/:uuid/:username`} component={ResetPass} />
          <PrivateRoute exact path={`/${Routes.welcome}/${Routes.mergeAnotherAccountAuth}/:mergeType`} component={MergeAnotherAccountAuth} />








        </WelcomeHeader>
      </div>
    );
  }
}

export default Welcome;
