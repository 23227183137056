import {connect} from 'react-redux';
import Component from './ExistingGridView.component';
import {SportIds} from '../../../../store/actions/ui/routes';
import {checkEMailExists} from '../../../../store/actions/data/cognito/auth';
import {
  confirmMergeProfiles,
  getProfileByEmail,
  getProfiles,
  getSchoolTeamsUsers,
  updateUserSportsInfo,
  updateUserTeamInfo,
  updateMovedAthlete,
  addUserToSchoolTeam,
  updateAthleteInfo, removeUserFromProspectTeam
} from '../../+store/roster/roster.actions';
import {selectAthletes, selectCoaches, selectCanEditRoster} from '../../+store/roster/roster.selectors';
import {
  removeUserFromTeam,
  selectCoachSchoolIds,
  updateAthleteProfileInfo,
  athleteInvite,
  updateAthleteTeamInfo
} from '../../+store';

const mapStateToProps = (state, ownProps) => {
  const {team} = ownProps;
  const allAthletes = selectAthletes(state, ownProps);
  const allCoaches = selectCoaches(state, ownProps);
  const teamName = team ? team.name || team.teamRef.team : '';
  const sportSlug = ownProps.match.params.sport;
  const sportId = sportSlug ? SportIds[sportSlug] : 0;
  const {schoolTeams} = state.modules.coachWorld.roster;
  const {profiles} = state.data.user;
  const schools = profiles
    && state.data.user.profiles[state.data.cognito.uuid]
    && state.data.user.profiles[state.data.cognito.uuid].schools;
  const {schoolId} = ownProps.match.params;
  let coachCode;
  let athleteCode;
  if (schools && !!schools.length) {
    const foundSchool = schools.find(school => school.school_id === schoolId);
    if (foundSchool) {
      coachCode = foundSchool.school.coach_code;
      athleteCode = foundSchool.school.athlete_code;
    }
  }

  const sortByCreatedBy = (arr) => {
    if (!arr || !Array.isArray(arr)) {
      return arr;
    }
    return arr.sort((a, b) => {
      return a.profileId - b.profileId;
    });
  };

  return {

    myTeam: state.modules.coachWorld.coachWorld.myTeams,
    athletes: team ? sortByCreatedBy(allAthletes[team.id]) : [],
    coaches: team ? allCoaches[team.id] : [],
    team,
    teamName,
    schoolTeams,
    sportId,
    coachCode,
    athleteCode,
    allRosterAthlete: state.modules.coachWorld.roster.athletes,
    schoolStyles: state.modules.coachWorld.coachWorld.schoolStyle,
    profilesLoading: state.modules.coachWorld.roster.loadingProfiles,
    viewSelection: state.modules.coachWorld.roster.viewSelection,
    canEdit: selectCanEditRoster(state, ownProps),
    coachSchoolIds: selectCoachSchoolIds(state),
    userProfileId: state.data.cognito.uuid,
    profiles: state.data.user.profiles,
    school: state.modules.coachWorld.coachWorld.schoolTeamsLists &&
      state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId] &&
      state.modules.coachWorld.coachWorld
        .schoolTeamsLists[ownProps.match.params.schoolId][0].team.school,
    currentExistingProfile: state.modules.coachWorld.roster.currentProfile,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, {dispatch}, ownProps) => {
  const {state, ...newStateProps} = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    savePlayer: (values, athleteId, userId, schoolId,total,currentCount) => {
      const schoolValues = {
        position: values.position,
        jersey_number: values.jerseyNumber,
        school_team_id: values.schoolTeamId,
        user_team_id: athleteId,
      };
      if(!values.isProspectTeam){
        schoolValues.teamName = values.teamName;
        schoolValues.user_id = userId;
        schoolValues.prev_school_team_id = values.prevSchoolTeamId;
        dispatch(updateAthleteTeamInfo(
          userId,
          athleteId,
          schoolValues,
          ownProps.match,
          schoolId,
          ownProps.location,
          total,
          currentCount
        ));
      }else{
        dispatch(updateAthleteInfo(
          userId,
          athleteId,
          schoolValues,
          ownProps.match,
          schoolId,
          ownProps.location,
          true
        ));
      }
    },
    addUserToSchoolTeam: (values, userId,athlete,total,currentCount) => {
      const schoolValues = {
        position: values.position,
        schoolTeamId: values.schoolTeamId,
        jerseyNumber: values.jerseyNumber,
        teamName: values.teamName,
        coachComment: values.coachComment,
        invited: values.invited,
        combineNumber: values.combineNumber,
        headshotUrl: values.headshotUrl,
      };
      dispatch(addUserToSchoolTeam(
        userId,
        schoolValues,
        athlete,
        total,
        currentCount,
      ));
    },
    saveUserTeam: (values, userTeamId, userId, schoolId) => {
      const schoolValues = {
        position: values.position,
        jersey_number: values.jerseyNumber,
        school_team_id: values.schoolTeamId,
        combine_no: values.combineNumber,
        user_team_id: userTeamId,
        coach_comment: values.coachComment,
      };

      dispatch(updateUserTeamInfo(
        userId,
        userTeamId,
        schoolValues,
        ownProps.match,
        schoolId,
        ownProps.location,
      ));
    },
    updateUserSport: (values, userId, sportId, userTeamId) => {
      const sportValues = {
        commits: values.commits,
        bat: values.bats,
        throw: values.throws,
      };

      dispatch(updateUserSportsInfo(
        userId,
        sportId,
        sportValues,
        userTeamId,
      ));
    },
    removeUserFromTeam: (userTeamId) => {
      dispatch(removeUserFromTeam(userTeamId));
    },
    removeUserFromProspectTeam: (userId, schoolTeamId) => {
      dispatch(removeUserFromProspectTeam(userId, schoolTeamId));
    },
    getProfiles: (athleteIds) => {
      dispatch(getProfiles(athleteIds));
    },
    updateAthleteProfileInfo: (uuid, profile) => {
      console.log('update-profile',profile)
      dispatch(updateAthleteProfileInfo(uuid, profile));
    },
    invite: athleteInvite(dispatch),
    getProfileIfExists: async (email) => {
      const doesExist = await checkEMailExists(email).then(response => (response));
      if (doesExist) {
        dispatch(getProfileByEmail(email));
      }
      return doesExist;
    },
    confirmMergeProfile: (existingUuid, athleteUuid, sportId, schoolId) => {
      dispatch(confirmMergeProfiles(existingUuid, athleteUuid, sportId, schoolId));
    },
    getMovedAthlete: (teamId , athletes, allAthlete) =>{
      dispatch(updateMovedAthlete(teamId, athletes, allAthlete));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
