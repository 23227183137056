import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextControl from '../../SimpleForm/TextControl';
import SelectControl from '../../SimpleForm/SelectControl';
import { positions,batOptions } from '../../../../components/UxCommon/Form/utils';
import Button from '../../Common/Button';
import MultiSelectControl from '../../SimpleForm/MultiSelectControl';
import { getTeams } from '../utils';

const getPositions = (gender, sportId) => {
  const positionsArray = positions(sportId, gender);
  const positionsObj = {};
  // Todo: fix for wrestling
  positionsObj[''] = 'Select your Position';
  Object.keys(positionsArray).forEach((pstn) => {
    positionsObj[positionsArray[pstn].value] = positionsArray[pstn].name;
  });
  return positionsObj;
};

const makeOnCancel = (index, onCancel) => () => {
  onCancel(index);
};

const AthleteSport = ({
  myTeam,
  index,
  sports,
  onFieldChange,
  gender,
  onCancel,
  errors,
  profileSport,
  sportTeamList,
}) => {

  const [baseballSoftballSport, setBaseballSoftballSport] = useState();

  useEffect(() =>{
    if(myTeam.sportId === "2" || myTeam.sportId === "4"){
      const sport = profileSport.find(sport => Number(sport.sportId) === Number(myTeam.sportId))
      setBaseballSoftballSport(sport);
      if(!myTeam.bats && sport && sport.bats){
        onFieldChange(index, 'bats')(sport.bats);
      }
      if(!myTeam.throws && sport && sport.throws){
        setTimeout(() =>{
          onFieldChange(index, 'throws')(sport.throws);
        },100)
      }
    }
  },[myTeam.sportId])

  if (Array.isArray(myTeam.teamIds) && myTeam.teamIds.length === 0) {
    myTeam.teamIds = '';
  }
  const positionOptions = getPositions(gender, myTeam.sportId);
  const thisOnCancel = makeOnCancel(index, onCancel);
  let teamOptions = {};
  if (sportTeamList.length) {
    teamOptions = { ...getTeams(sportTeamList, Number(myTeam.sportId)) };
  }

  return (
    <div className="CodeSignUp_NewSport">
      <SelectControl
        className="CodeSignUp_SportsPick"
        placeholder="Select a Sport"
        label="Sport"
        help="Which sport do you play?"
        options={sports}
        name="sport"
        errors={errors.sportId}
        defaultValue=""
        myOnChange={onFieldChange(index, 'sportId')}
        value={myTeam.sportId}
      />
      {
        !!myTeam.sportId && (
          <SelectControl
            className="CodeSignUp_TeamPick"
            label="Team"
            help="Select the team(s) do you play"
            options={teamOptions}
            name="team"
            errors={errors.teamIds}
            value={myTeam.teamIds}
            myOnChange={onFieldChange(index, 'teamIds')}
          />
        )
      }
      {
        !!myTeam.sportId && (
          <MultiSelectControl
            className="CodeSignUp_TeamPick"
            placeholder="Positions"
            label="Positions"
            help="Which positions do you play?"
            options={positionOptions}
            name="positions"
            errors={errors.positions}
            defaultValue={myTeam.positions}
            myOnChange={onFieldChange(index, 'positions')}
            multi
          />
        )
      }
      {
        (!!(myTeam.sportId === "2") || !!(myTeam.sportId === "4"))  && (
          <MultiSelectControl
            className="CodeSignUp_TeamPick"
            label="BATS"
            placeholder={"BATS"}
            help="Which way do you bat (Right, Left or Switch)?"
            options={batOptions}
            name="bats"
            errors={errors.bats}
            defaultValue={(myTeam.bats && [myTeam.bats] )|| baseballSoftballSport && [baseballSoftballSport.bats]}
            myOnChange={onFieldChange(index, 'bats')}
          />
        )
      }
      {
        (!!(myTeam.sportId === "2") || !!(myTeam.sportId === "4"))  && (
          <MultiSelectControl
            className="CodeSignUp_TeamPick"
            label="THROWS"
            placeholder={"THROWS"}
            help="Which arm do you throw with (Right, Left or Switch)?"
            options={batOptions}
            name="throws"
            errors={errors.throws}
            defaultValue={(myTeam.throws && [myTeam.throws] )|| baseballSoftballSport && [baseballSoftballSport.throws]}
            myOnChange={onFieldChange(index, 'throws')}
          />
        )
      }
      {
        !!myTeam.sportId && (
          <TextControl
            className="CodeSignUp_JerseyPick"
            placeholder=""
            label="Jersey Number (optional)"
            name="jerseyNumber"
            myOnChange={onFieldChange(index, 'jerseyNumber')}
            errors={errors.jerseyNumber}
          />
        )
      }
      {
        (index > 0) && (
          <Button
            className="CodeSignUp_CancelTeamBtn"
            onClick={thisOnCancel}
            type="button"
          >
            Cancel
          </Button>
        )
      }
    </div>
  );
};
AthleteSport.propTypes = {
  myTeam: PropTypes.object,
  index: PropTypes.number,
  sports: PropTypes.object,
  onFieldChange: PropTypes.func,
  gender: PropTypes.string,
  onCancel: PropTypes.func,
  errors: PropTypes.any,
  sportTeamList: PropTypes.any,
};

export default AthleteSport;
