import {asyncStartAction} from '../../../../store/actions/ui/async';

export const GET_COMBINE_ATHLETE_RESULTS = 'combine.getCombineResultsPerAthlete';
export const getCombineResultsPerAthlete = (combineId, athleteIds, parentModel) => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_ATHLETE_RESULTS));
  dispatch({
    type: GET_COMBINE_ATHLETE_RESULTS,
    combineId,
    athleteIds,
    dispatch,
    parentModel,
  });
};

export const GET_COMBINE_RESULT_ALL_ATHLETES = 'combine.getCombineResultAllAthlete';
export const getCombineResultAllAthletes = (combineId, parentModel) => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_RESULT_ALL_ATHLETES));
  dispatch({
    type: GET_COMBINE_RESULT_ALL_ATHLETES,
    combineId,
    dispatch,
    parentModel,
  });
};

export const GET_COMBINE_ATHLETE_SUCCESS = 'combine.getCombineAthlete.success';
export const getCombineAthleteSuccess = (
  combineId,
  athleteId,
  combineResults,
  parentModel,
  athleteCount,
) => ({
  type: GET_COMBINE_ATHLETE_SUCCESS,
  combineId,
  athleteId,
  combineResults,
  parentModel,
  athleteCount,
});

export const GET_COMBINE_RESULT_ALL_ATHLETE_SUCCESS = 'combine.getCombineAthletes.success';
export const getCombineResultAllAthleteSuccess = (
  combineId,
  combineResults,
  parentModel,
  athleteCount,
) => ({
  type: GET_COMBINE_RESULT_ALL_ATHLETE_SUCCESS,
  combineId,
  combineResults,
  parentModel,
  athleteCount,
});

export const CREATE_COMBINE = 'combine.createCombine';
export const createCombine = data => (dispatch) => {
  dispatch(asyncStartAction(CREATE_COMBINE));
  dispatch({
    type: CREATE_COMBINE,
    data,
  });
};

export const UPDATE_COMBINE = 'combine.updateCombine';
export const updateCombine = (data, combine, isModal = false) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_COMBINE, isModal ? 'modal' : ''));
  dispatch({
    type: UPDATE_COMBINE,
    data,
    combine,
  });
};

export const UPDATE_CREATE_COMBINE_AND_AWARDS = 'combine.updateOrCreateCombineAndAwards';
export const updateOrCreateCombineAndAwards = (data, combine, schoolId, isModal = false) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_CREATE_COMBINE_AND_AWARDS, isModal ? 'modal' : ''));
  dispatch({
    type: UPDATE_CREATE_COMBINE_AND_AWARDS,
    data,
    combine,
    schoolId,
  });
};

export const DELETE_COMBINE = 'combine.deleteCombine';
export const deleteCombine = combineId => (dispatch) => {
  dispatch(asyncStartAction(DELETE_COMBINE));
  dispatch({
    type: DELETE_COMBINE,
    combineId,
  });
};

export const GET_COMBINE = 'combine.getCombine';
export const getCombine = (combineId, combineOnly = false) => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE));
  dispatch({
    type: GET_COMBINE,
    combineId,
    combineOnly,
  });
};

export const SET_ALL_LASER = 'combine.setAllLaser';
export const setAllLaser = (combineId, standardTestCategoryId, standardTestObjectId) => (dispatch) => {
  dispatch(asyncStartAction(SET_ALL_LASER));
  dispatch({
    type: SET_ALL_LASER,
    combineId,
    standardTestCategoryId,
    standardTestObjectId,
  });
};

export const GET_PURE_COMBINE = 'combine.getPureCombine';
export const getPureCombine = combineId => (dispatch) => {
  dispatch(asyncStartAction(GET_PURE_COMBINE));
  dispatch({
    type: GET_PURE_COMBINE,
    combineId,
  });
};

export const GET_COMBINE_TEMPLATES = 'combine.getCombineTemplates';
export const getCombineTemplates = () => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_TEMPLATES));
  dispatch({
    type: GET_COMBINE_TEMPLATES,
  });
};

export const GET_COMBINE_TEMPLATES_BY_SCHOOL = 'combine.getCombineTemplatesBySchool';
export const getCombineTemplatesBySchool = schoolIds => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_TEMPLATES_BY_SCHOOL));
  dispatch({
    type: GET_COMBINE_TEMPLATES_BY_SCHOOL,
    schoolIds,
  });
};

export const GET_COMBINE_STANDARD_TEST_CATEGORIES = 'combine.getCombineStandardTestCategories';
export const getCombineStandardTestCategories = combineId => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_STANDARD_TEST_CATEGORIES));
  dispatch({
    type: GET_COMBINE_STANDARD_TEST_CATEGORIES,
    combineId,
  });
};
// export const FIND_COMBINE_AWARDS = 'combine.findCombineAwards';

export const GET_COMBINE_AWARDS = 'combine.getCombineAwards';
export const getCombineAwards = combineId => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_AWARDS));
  dispatch({
    type: GET_COMBINE_AWARDS,
    combineId,
  });
};

export const GET_COMBINES_AWARDS = 'combine.getCombinesAwards';
export const getCombinesAwards = combineIds => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINES_AWARDS));
  dispatch({
    type: GET_COMBINES_AWARDS,
    combineIds,
  });
};

export const CREATE_COMBINE_AWARD = 'combine.createCombineAward';
export const createCombineAward = (data, combineId, schoolId) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_COMBINE_AWARD));
  dispatch({
    type: CREATE_COMBINE_AWARD,
    data,
    combineId,
    schoolId,
  });
};

export const UPDATE_COMBINE_AWARD = 'combine.updateCombineAward';
export const updateCombineAward = (data, athleteId, awardId) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_COMBINE_AWARD));
  dispatch({
    type: UPDATE_COMBINE_AWARD,
    data,
    athleteId,
    awardId,
  });
};

export const GET_ATHLETE_COUNT = 'combine.getAthleteCount';
export const getAthleteCount = combines => (dispatch) => {
  dispatch({
    type: GET_ATHLETE_COUNT,
    combines,
    dispatch,
  });
};

export const GET_SCHOOL_COMBINES = 'combine.getSchoolCombines';
export const getSchoolCombines = schoolIds => (dispatch) => {
  dispatch(asyncStartAction(GET_SCHOOL_COMBINES));
  dispatch({
    type: GET_SCHOOL_COMBINES,
    schoolIds,
    dispatch,
  });
};

export const GET_COMBINE_PRE_COMBINE_TEST_RES = 'combine.getCombinePreCombineTestRes';
export const getCombinePreCombineTestRes = combineId => (dispatch) => {
  dispatch(asyncStartAction(GET_COMBINE_PRE_COMBINE_TEST_RES));
  dispatch({
    type: GET_COMBINE_PRE_COMBINE_TEST_RES,
    combineId,
    dispatch,
  });
};

export const SHOW_ARCHIVE_DIALOG = 'combine.showArchiveDialog';
export const showArchiveDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_ARCHIVE_DIALOG,
  });
};

export const HIDE_ARCHIVE_DIALOG = 'combine.hideArchiveDialog';
export const hideArchiveDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_ARCHIVE_DIALOG,
  });
};

export const SHOW_SAVE_COMBINE_AWARDS_DIALOG = 'combine.showSaveCombineAwardsDialog';
export const showSaveCombineAwardsDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_SAVE_COMBINE_AWARDS_DIALOG,
  });
};

export const HIDE_SAVE_COMBINE_AWARDS_DIALOG = 'combine.hideSaveCombineAwardsDialog';
export const hideSaveCombineAwardsDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_SAVE_COMBINE_AWARDS_DIALOG,
  });
};


export const SHOW_CREATE_EDIT_DIALOG = 'combine.showCreateEditDialog';
export const showCreateEditDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_CREATE_EDIT_DIALOG,
  });
};

export const HIDE_CREATE_EDIT_DIALOG = 'combine.hideCreateEditDialog';
export const hideCreateEditDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_CREATE_EDIT_DIALOG,
  });
};

export const SHOW_GENERATED_AWARD_DIALOG = 'combine.showGeneratedAwardDialog';
export const showGeneratedAwardDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_GENERATED_AWARD_DIALOG,
  });
};

export const HIDE_GENERATED_AWARD_DIALOG = 'combine.hideGeneratedAwardDialog';
export const hideGeneratedAwardDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_GENERATED_AWARD_DIALOG,
  });
};

export const SHOW_EDIT_DIALOG = 'combine.showEditDialog';
export const showEditDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_EDIT_DIALOG,
  });
};

export const HIDE_EDIT_DIALOG = 'combine.hideEditDialog';
export const hideEditDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_EDIT_DIALOG,
  });
};

export const ARCHIVE_COMBINE = 'combine.archiveCombine';
export const archiveCombine = combineId => (dispatch) => {
  dispatch({
    type: ARCHIVE_COMBINE,
    combineId,
  });
};

// export const UPDATE_COMBINE_RESULTS = 'combine.updateCombineResults';

/*
  testParams = {
    testId,
    testResultId,
    stdTestObjId,
    result,
    openDate, // combine start date
    combineId,
  }
*/
export const SAVE_COMBINE_TEST_RESULT = 'combine.saveTestResults';
export const saveTestResults = (userId, testParams) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_COMBINE_TEST_RESULT, null, testParams.columnRowId));
  dispatch({
    type: SAVE_COMBINE_TEST_RESULT,
    userId,
    testParams,
  });
};

export const SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT = 'combine.saveTestResultsWithoutResult';
export const saveTestResultsWithoutResult = (userId, testParams) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT, null, testParams.columnRowId));
  dispatch({
    type: SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT,
    userId,
    testParams,
  });
};

export const CREATE_UPDATE_BULK_TEST_COMBINE = 'combine.createUpdateBulkTestCombine';
export const createUpdateBulkTestCombine = (testParamsArray, testParamsArrayToDelete) => (dispatch) => {
  console.log(testParamsArrayToDelete, 'testParamsArrayToDelete...', testParamsArray);
  dispatch(asyncStartAction(CREATE_UPDATE_BULK_TEST_COMBINE, "Loading..." ));
  dispatch({
    type: CREATE_UPDATE_BULK_TEST_COMBINE,
    testParamsArray,
    testParamsArrayToDelete
  });
};

export const DELETE_BULK_TEST_COMBINE = 'combine.deleteBulkTestCombine';
export const deleteBulkTestCombine = (testParamsArray) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_BULK_TEST_COMBINE, "Loading..." ));
  dispatch({
    type: DELETE_BULK_TEST_COMBINE,
    testParamsArray,
  });
};

export const DELETE_LAST_PASTE_DATA_COMBINE = 'combine.deleteLastPasteDataCombine';
export const deleteLastPasteDataCombine = (testParamsArray) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_LAST_PASTE_DATA_COMBINE, "Loading..." ));
  dispatch({
    type: DELETE_LAST_PASTE_DATA_COMBINE,
    testParamsArray,
  });
};

export const REFRESH_PAGE = 'combine.pageRefresh';
export const refreshPopUp = () => (dispatch) => {
  dispatch(asyncStartAction(REFRESH_PAGE));
}

export const SET_EXPIRING_MSG = 'combine.expiringMsg.set';
export const setExpiringCombineMsg = (msg, msgType, seconds) => (dispatch) => {
  dispatch(asyncStartAction(SET_EXPIRING_MSG));
  dispatch({
    type: SET_EXPIRING_MSG,
    seconds,
    msg,
    msgType,
  });
};

export const SET_SCROLLED_BELOW_MENU = 'combine.scrolledBelowMenu.set';
export const setScrolledBelowMenu = hasScrolledBelowMenu => ({
  type: SET_SCROLLED_BELOW_MENU,
  hasScrolledBelowMenu,
});

export const UNLOAD_COMBINE = 'combine.unloadCombine';
export const unloadCombine = () => ({
  type: UNLOAD_COMBINE,
});

export const GET_TEST_CATEGORIES = 'combine.getTestCategories';
export const getTestCategories = () => (dispatch) => {
  dispatch(asyncStartAction(GET_TEST_CATEGORIES));
  dispatch({
    type: GET_TEST_CATEGORIES,
  });
};

export default 'combine.actions.js';
