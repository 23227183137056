import {CustomVideoIcon} from "../../../../../components/UxCommon";
import {Link} from "react-router-dom";
import {expandedCombineDialogPathTo} from "../../../../../store/actions/ui/routes";
import React from "react";

export default (props) => {
  const {params, onClickFormButtonIcon, uploadVideo} = props;
  const {
    schoolId,
    userId
  } = params.data;
  const {isCombineOpen, isMobile, bank, combineId, location} = params;
  const {value} = params;
  const videoId = value && value.videoId ? value.videoId  : '' ;
  return <div>
    {
      !(videoId) ?
        (isCombineOpen && <CustomVideoIcon
          multiLine={true}
          iconName={"videocam"}
          iconStyle={{fontSize: 36}}
          onClick={() => onClickFormButtonIcon(false, params)}
          disabled={false}
          tooltip={isMobile ? '' : "Upload Video"}
          className={isMobile ? '' : "showToolTip"}
          place={"right"}
        />)
        :
        <div style={{display: "flex", justifyContent: "center"}}>
          <Link to={expandedCombineDialogPathTo(schoolId, combineId, bank, value.videoId, location.search)}
                className="Leaderboard_Laser">
            <CustomVideoIcon
              multiLine={true}
              iconName="play-circle2"
              iconStyle={{fontSize: 25, color: "red"}}
              onClick={() => uploadVideo(userId)}
              place={"right"}
            />
          </Link>
          {isCombineOpen && <CustomVideoIcon
            iconName="delete-video"
            iconStyle={{fontSize: 25}}
            onClick={() => onClickFormButtonIcon("delete", params)}
            tooltip={isMobile ? '' : "Delete Video"}
            className={isMobile ? '' : "showToolTip"}
            // disabled={(!(params.value && params.value.result))}
            place={"right"}
          />}
        </div>
    }
  </div>

}
