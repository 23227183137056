import React from 'react';
import {ButtonRect, CloseButton} from '../../../../components/UxCommon';

const UndoDeleteModal = ({undoDeleteData, onDelete, onCancel}) => {

  const onUndoDelete = () => {
    onDelete(undoDeleteData);
  }

  return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <CloseButton size={15} onClick={onCancel}/>
      <div className="CloseCombineModal_Header"/>
      <div className="CloseCombineModal_Body">
        <div
          className="CloseCombineModal_Text1">{`Hey Coach - you are about to permanently delete ${undoDeleteData.length} cells of data.`}</div>
        <div className="CloseCombineModal_Text2">
          {"Are you sure ?"}
        </div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn primary"
          type="button"
          onClick={onUndoDelete}
        >
          Delete it Forever
        </ButtonRect>
        <ButtonRect
          className="CloseCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
};

export default UndoDeleteModal;
