import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, Switch, Route } from 'react-router-dom';
import AwardsCombineCard from './AwardCombineCard';
import { Routes } from '../../../../store/actions/ui/routes';
import CombineAwards from './CombineAwards';
import {Roles} from '../../../../store/actions/data/user/profile/models';
import TwoToneButton from '../../../CoachWorld/common/TwoToneButton';
import { ShareModal } from '../../../../components/UxCommon';



class Awards extends PureComponent {
  static propTypes = {
    getCombinesAndAwards: PropTypes.func,
    schoolId: PropTypes.string,
    combines: PropTypes.array,
    awards: PropTypes.object,
    profile: PropTypes.object,
    schoolAwardLogo: PropTypes.object
  }

  state = {
    shareDialogUri: '',
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();

  }

  fetchedCombinesAndAwards = false;
  fetchedAwardOfCombine = false;
  fetchedSchoolAwardLogo = false;

  shouldUpdate = () => {
    const { getCombinesAndAwards, schoolId, profile, schoolAwardLogo, getSchoolAwardLogo } = this.props; 
    if (!this.fetchedCombinesAndAwards && profile ) {
      const schoolIds = profile.schools.map(school => school.school_id);
      this.fetchedCombinesAndAwards = true;
      getCombinesAndAwards(schoolIds);
    }

    if(!schoolAwardLogo[schoolId] &&  !this.fetchedSchoolAwardLogo)
    {
      getSchoolAwardLogo([schoolId]);
      this.fetchedSchoolAwardLogo= true;
    }

  }

  renderList = () => {
    const { awards, combines, schoolId, profile } = this.props;
    const loggedInUserRoute = profile ? (Roles.coach != profile.roleId ? Routes.athlete: Routes.coach)  : Routes.coach;
    return (
      <div className="CoachDashboardLeaderboards_Cards">
        {
          combines && combines.map((combine, index) => {
            const combineAwards = awards && awards[combine.id];
            return (
              <Link
                to={`/${loggedInUserRoute}/${Routes.dashboard}/${schoolId}/${Routes.awards}/${Routes.combine}/${combine.id}`}
                key={index}
                className="CoachDashboardLeaderboards_CardContainer"
              >
                <AwardsCombineCard combine={combine} awards={combineAwards} />
              </Link>
            );
          })
        }
      </div>
    );
  }

  renderCombineAwards = (props) => {
    const { awards, combines, schoolAwardLogo, profile, schoolId ,getCombineAwards, schoolStyle} = this.props;
    // eslint-disable-next-line react/prop-types
    const { combineId } = props.match.params;


    setTimeout( () => {
      if (!profile && !this.fetchedAwardOfCombine)
      {
        getCombineAwards([combineId], schoolId);
      }
      this.fetchedAwardOfCombine = true;
    },1000)


    const awardLogo = schoolAwardLogo[schoolId]
                      && Object.keys(schoolAwardLogo[schoolId]).length
                      ? schoolAwardLogo[schoolId]
                      : null;
    if (combines && awards && awards[combineId]) {
      const combineAwards = awards[combineId];
      const combine = combines.find(c => c.id === Number(combineId));

      return <CombineAwards awards={combineAwards} combine={combine} schoolAwardLogo={awardLogo} schoolStyle={schoolStyle} {...props} />;
    }
    else if (!profile && awards)
    {
      const combineAwards = awards[combineId];
      return <CombineAwards awards={combineAwards} schoolAwardLogo={awardLogo} schoolStyle={schoolStyle} {...props} />;
    }
    return null;
  }

  openSharingModal = () => {
    this.setState({
      shareDialogUri: this.props.location.pathname
    });
  }

  closeShareDialog = () => {
    this.setState({
      shareDialogUri: ""
    });
  }

  render() {
    return (
      <div className="CoachDashboardLeaderboards">
        <div className="CoachDashboard_TeamsHeader">Awards
        <Switch>
          <Route
            path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}/${Routes.combine}/:combineId`}
            component ={ () =>  <TwoToneButton
              onClick = {this.openSharingModal}
              className="share_awards_button"
              text="Share Awards"
              iconName="share" />
            }
          />
          </Switch>
          </div>

        {this.state.shareDialogUri &&
          <ShareModal
            uri={this.state.shareDialogUri}
            onClose={this.closeShareDialog}
            backdropClassName=""
          />
        }

        <Switch>
          <Route
            path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}/${Routes.combine}/:combineId`}
            render={this.renderCombineAwards}
          />
          <Route
            path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}`}
            render={this.renderList}
          />
        </Switch>
      </div>
    );
  }
}

export default Awards;
