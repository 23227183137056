import { connect } from "react-redux";
import queryString from "query-string";
import Component from "./AllTimeLeaderboard.component";
import {
  getFilters,
  getQueryFromFilters,
  getSchoolStyles,
  selectLoadingMsg,
  selectSchoolStyle,
  setSortBy
} from "../+store/coachWorld";
import { BankSlugs, getTestCategories } from "../+store/combine";
import { getSports } from "../+store/nationalLeaderboard";
import {
  getCurrentTemplate, getSearch,
  selectAllTimeLeaderBoard, selectCurrentColumns, selectSortBy,
  currentSport
} from "../+store/allTimeLeaderboard/allTimeLeaderboard.selectors";
import {
  getAllTimeLeaderboardGradYears,
  getAllTimeLeaderboardResults,
  getAllTimeLeaderboardSports,
  getAllTimeLeaderboardTemplate
} from "../+store/allTimeLeaderboard";
import { getRadius, getZipCode } from "../+store/nationalLeaderboard/nationalLeaderboard.selectors";


const getNumResultsShown = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { results } = parsedQuery;
  return results || "100";
};

const setNumResultsShown = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.results = value;
  history.push({
    search: queryString.stringify(parsedQuery)
  });
};

const getScoreType = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { score } = parsedQuery;
  return score || "raw";
};

const setScoreType = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.score = value;
  history.push({
    search: queryString.stringify(parsedQuery)
  });
};

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  return {
    isLoggedIn: !!state.data.cognito.uuid,
    numResultsShown: getNumResultsShown(state),
    loading: !!state.modules.coachWorld.allTimeLeaderboard.loading,
    displayShowMore: state.modules.coachWorld.allTimeLeaderboard.results ? state.modules.coachWorld.allTimeLeaderboard.results.showMore : false,
    loadingMsg,
    template: getCurrentTemplate(state),
    columns: selectCurrentColumns(state, ownProps),
    measureableColumns: selectCurrentColumns(state, {
      match: {
        params: {
          bank: BankSlugs.PHYSIQUE
        }
      }
    }),
    sortByTestId: selectSortBy(state, ownProps),
    zip: getZipCode(state, ownProps),
    videos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    search: getSearch(state, ownProps),
    radius: getRadius(state, ownProps),
    currentSport: currentSport(state, ownProps),
    viewSelection: state.modules.coachWorld.leaderboard.viewSelection,
    selectedScoreType: getScoreType(state),
    results: selectAllTimeLeaderBoard(state, ownProps),
    testCats: state.modules.coachWorld.combine.standardTestCats,
    sports: state.data.sport.sports,
    schoolSports: state.modules.coachWorld.allTimeLeaderboard.sports,
    gradYears: state.modules.coachWorld.allTimeLeaderboard.gradYears,
    selectedFilters: getFilters(state, ownProps),
    parsedFilters: getQueryFromFilters(state, ownProps),
    schoolStyle: selectSchoolStyle(state, ownProps),
    eventAverage: state.modules.coachWorld.allTimeLeaderboard.eventAverage,
  };
};

const mapDispatchToProps = dispatch => ({
  getAllTimeLeaderboardTestRes: (params) => {
    dispatch(getAllTimeLeaderboardResults(params));
  },
  getAllTimeLeaderboardTemplate: (schoolId, sportId) => {
    dispatch(getAllTimeLeaderboardTemplate(schoolId, sportId));
  },
  getTestCategories: () => {
    dispatch(getTestCategories());
  },
  getSports: () => {
    dispatch(getSports());
  },
  getSchoolStyles: (schoolIds) => {
    dispatch(getSchoolStyles(schoolIds));
  },
  getSchoolSports: (schoolId) => {
    dispatch(getAllTimeLeaderboardSports(schoolId));
  },
  getSchoolGradYears: (schoolId) => {
    dispatch(getAllTimeLeaderboardGradYears(schoolId));
  },
  handleTestSortClicked: (location, history, stdTestObjectId) => {
    setSortBy(location, history, stdTestObjectId);
  },
  onResultsChange: (location, history, value) => {
    setNumResultsShown(location, history, value);
  },
  onScoreTypeChange: (location, history, value) => {
    setScoreType(location, history, value);
  }
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default Container;
