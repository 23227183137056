import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../UxCommon/Form';

import {
  Container,
  Row,
  Col,
  LoadingSpinner,
  CloseLink,
  Line,
  ButtonLink,
} from '../../UxCommon';
import Icon from '../../UxCommon/Icon/Icon';
import { Sports, Routes } from '../../../store/actions/ui/routes';

class DeleteAccount extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {}

  delete = () => {
    const { deleteAccount } = this.props;
    deleteAccount('Deleting your account');
  }

  render() {
    const {
      loading,
      loadingMsg,
      uuid,
      sportId,
      match,
    } = this.props;
    let sportSlug = '';
    if (sportId && Sports[sportId]) {
      sportSlug = Sports[sportId];
    }
    return (
      <div className="UxEditProfile_DeleteAccount">
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
        <CloseLink to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportSlug ? `/${sportSlug}` : ''}`} />
        <Container>
          <Row className="justify-content-center">
            <Col className="UxEditProfile_DeleteAccount_Col">
              <div>
                <Icon className="UxEditProfile_DeleteAccount_Alert" iconName="alert" />
              </div>
              <div className="UxEditProfile_DeleteAccount_Warning">You are about to delete your account</div>
              <div className="UxEditProfile_DeleteAccount_Question">This is a permanent action.  Are you sure that you want to completely delete your account and all of your information?</div>
              <div className="UxEditProfile_DeleteAccount_Btns">
                <ButtonLink
                  to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportSlug ? `/${sportSlug}` : ''}`}
                >
                  No, take me back
                </ButtonLink>
                <Button className="delete UxEditProfile_DeleteAccount_Btn" onClick={this.delete}>
                  Yes, delete my account
                </Button>
              </div>
            </Col>
          </Row>
          <Line style={{ paddingTop: '35px' }} />
        </Container>
      </div>
    );
  }
}

export default DeleteAccount;
