import { ajax } from 'rxjs/ajax';
import Urls from '../../../../config/urls';

export const getSchoolAwardLogo = schoolId =>
  ajax.getJSON(
    Urls.schoolAwardLogo(schoolId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
