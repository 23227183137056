import { asyncStartAction } from '../../../../store/actions/ui/async';

export const CREATE_ACCOUNT = 'codeSignUp.createAccount';
export const createAccount = (fields, code) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_ACCOUNT));
  dispatch({
    type: CREATE_ACCOUNT,
    fields,
    code,
  });
};

export const CHECK_EMAIL_EXISTS = 'codeSignUp.checkEmailExists';
export const checkEmailExists = (email) => (dispatch) => {
  dispatch(asyncStartAction(CHECK_EMAIL_EXISTS));
  dispatch({
    type: CHECK_EMAIL_EXISTS,
    email,
  });
};

