import { connect } from 'react-redux';
import Component from './ToggleView.component';
import { changeViewSelection } from '../../+store/leaderboard/leaderboard.actions';

const mapStateToProps = state => ({
  viewSelection: state.modules.coachWorld.leaderboard.viewSelection,
});

const mapDispatchToProps = dispatch => ({
  changeViewSelection: (newSelection) => {
    dispatch(changeViewSelection(newSelection));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
