import { asyncStartAction } from '../../../../store/actions/ui/async';

export const CHANGE_VIEW_SELECTION = 'leaderboard.changeViewSelection';
export const changeViewSelection = viewSelection => ({
  type: CHANGE_VIEW_SELECTION,
  viewSelection,
});

export const GET_LEADERBOARD = 'leaderboard.get';
export const getLeaderboard = (combineId, silent = false) => (dispatch) => {
  dispatch(asyncStartAction(GET_LEADERBOARD, '', { silent }));
  dispatch({
    type: GET_LEADERBOARD,
    combineId,
    dispatch,
  });
};

export default 'leaderboard.actions.js';
