import { combineReducers } from "redux";
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR
} from "../../../actions/ui/async";

import {
  TWITTER_HANDLE_FROM_INIT,
  UPDATE_TWITTER_HANDLE,
  CREATE_TWITTER_HANDLE,
  TEST_TWITTER_HANDLE
} from "../../../actions/ui/uxEditProfile/twitterHandle";
import { TWITTER_HANDLE_SUC } from "../../../actions/data/user/twitterHandle";

const validation = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_FINISH:
      if ([CREATE_TWITTER_HANDLE, UPDATE_TWITTER_HANDLE, TEST_TWITTER_HANDLE].includes(action.model)) {
        return null;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === TEST_TWITTER_HANDLE) {
        return action.error;
      }
      return state;
    default:
      return state;
  }
};

const isAlreadyExistTwitterValidationMessage = (state = null, action) =>  {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.action === "testTwitterHandleUrl") {
        return !!action.data ? action.data.message : '';
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === TEST_TWITTER_HANDLE) {
        return action.error;
      }
      return state;
    default:
      return state;
  }
}

const loadingMsg = (state = "", action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === CREATE_TWITTER_HANDLE ||
        action.model === UPDATE_TWITTER_HANDLE
      ) {
        return "Saving your twitter handle ...";
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        action.model === CREATE_TWITTER_HANDLE ||
        action.model === UPDATE_TWITTER_HANDLE
      ) {
        return "";
      }
      return state;
    default:
      return state;
  }
};

const formNeedsInit = (state = true, action) => {

  switch (action.type) {
    case TWITTER_HANDLE_SUC:
      return true;
    case TWITTER_HANDLE_FROM_INIT:
      return false;
    default:
      return state;
  }
};

const message = (state = '', action) => {
  switch (action.type) {
    case TWITTER_HANDLE_SUC:
      return 'Twitter Handle Saved!';
    case TWITTER_HANDLE_FROM_INIT:
    case TEST_TWITTER_HANDLE:
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  formNeedsInit,
  message,
  validation,
  isAlreadyExistTwitterValidationMessage
});

export default reducer;
