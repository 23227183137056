import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

class GetAllTeams extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    getPlayerTeamsAndDetails: PropTypes.func.isRequired,
    athleteTeamRosters: PropTypes.array,
  }

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  fetched = false;
  sportId = 0;

  shouldInit = () => {
    const {
      uuid,
      sportId,
      getPlayerTeamsAndDetails,
    } = this.props;
    if (uuid && sportId && (!this.fetched || this.sportId !== sportId)) {
      this.fetched = true;
      this.sportId = sportId;
      getPlayerTeamsAndDetails(uuid, sportId);
    }
  }

  render() {
    const {
      children, loading, loadingMsg, athleteTeamRosters,
    } = this.props;
    const imLoading = !athleteTeamRosters || loading;
    return (
      <div
        style={{
          width: '100%',
          minHeight: (imLoading ? '300px' : '0px'),
        }}
      >
        {
          imLoading
          ? <LoadingSpinner loading loadingMsg={loadingMsg} style={{ textTransform: 'none' }} />
          : children
        }
      </div>
    );
  }
}

export default GetAllTeams;
