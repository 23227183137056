import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Header } from '..';
import Icon from '../Icon/Icon';
import { facebookApiId } from '../../../config/_env';
import { copyToClipboard } from '../../../utils';
import SocialLink from './SocialLink/SocialLink';
import Urls from '../../../config/urls';

const initialMsg = 'Copy the link above or click below to share directly.';

class ShareModal extends PureComponent {
  static propTypes = {
    uri: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    backdropClassName: PropTypes.string,
  }

  state = {
    msg: initialMsg,
  }

  onCopyLinkClick = () => {
    copyToClipboard(this.getUrl());
    this.setState({
      msg: 'Copied!',
    });
    setTimeout(() => {
      this.setState({
        msg: initialMsg,
      });
    }, 2000);
  }

  getFacebookLink = () => encodeURI('https://www.facebook.com/dialog/share' +
  `?app_id=${facebookApiId}` +
  '&display=popup' +
  `&href=${this.getUrl()}` +
  `&redirect_uri=${this.getUrl()}`);

  getTwitterLink = () => encodeURI(`https://twitter.com/intent/tweet?text=${this.getUrl()}`);

  getUrl = () => {
    const { uri } = this.props;
    return `${Urls.app()}${uri}`;
  }

  setMsg = (socialMsg) => {
    this.setState({
      msg: socialMsg || initialMsg,
    });
  }

  copyMouseEnter = () => {
    this.copyIn = true;
    setTimeout(() => {
      if (this.copyIn) {
        this.setState({
          msg: 'Copy link.',
        });
      }
    }, 100);
  }

  copyMouseLeave = () => {
    setTimeout(() => {
      const { msg } = this.state;
      if (!this.copyIn && (msg === 'Copy link.')) {
        this.setState({
          msg: initialMsg,
        });
      }
    }, 250);
    this.copyIn = false;
  }

  render() {
    const { uri, onClose, backdropClassName } = this.props;
    const { msg } = this.state;
    return !!uri && (
      <div className={`UxCommon_ShareModal_Backdrop ${backdropClassName}`}>
        <div className="UxCommon_ShareModal_Modal">
          <CloseButton onClick={onClose} size={14} />
          <div className="UxCommon_ShareModal_Header">
            <Header>Share</Header>
          </div>
          <div className="UxCommon_ShareModal_UriRow">
            <div>
              <div className="UxCommon_ShareModal_Uri">
                <div className="UxCommon_ShareModel_UriLabel">Share Link</div>
                {this.getUrl()}
              </div>
            </div>
            <button
              className="UxCommon_ShareModal_CopyBtn"
              onClick={this.onCopyLinkClick}
              onMouseEnter={this.copyMouseEnter}
              onMouseLeave={this.copyMouseLeave}
            >
              <Icon iconName="link" />
            </button>
          </div>
          {
            msg && (
              <div className="UxCommon_ShareModal_Message">
                {msg}
              </div>
            )
          }
          <div className="UxCommon_ShareModal_SocialRow">
            <SocialLink
              link={this.getFacebookLink()}
              iconName="facebook"
              className="facebook"
              msg="Share to Facebook."
              setMsg={this.setMsg}
              currentMsg={msg}
            />
            <SocialLink
              link={this.getTwitterLink()}
              iconName="twitter"
              className="twitter"
              msg="Share to Twitter."
              setMsg={this.setMsg}
              currentMsg={msg}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ShareModal;
