import React from 'react';
import PropTypes from 'prop-types';
import RatingsRow from '../../../../containers/UxProfile/PlayerData/RatingsRow';
import TestsRow from './TestsRow/TestsRow';
import { Line } from '../../../UxCommon';

const TestData = ({ match, selectedTab }) => (
  <React.Fragment>
    {/* <RatingsRow match={match} selectedTab={selectedTab} /> */}
    <Line className="PlayerData_TestData" />
    <TestsRow match={match} />
  </React.Fragment>
);
TestData.propTypes = {
  match: PropTypes.object.isRequired,
  selectedTab: PropTypes.string,
};

export default TestData;
