import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import Urls from '../../../../../../src/config/urls';


export const updateInviteProfileFinalFormAPI = (uuid, token, props = {}) =>
  ajax.put(
    Urls.updateProfileFinalForm(uuid),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
;

export default updateInviteProfileFinalFormAPI;