import { createSelector } from 'reselect';
import { getCurrentSportId } from '../../containers/UxCommon/Utils';

const getTestsStd = state => state.data.sport.testsStd;

export const makeGetPerfStdTests = bankCodeArr => createSelector(
  getTestsStd,
  getCurrentSportId,
  (testsStd, currentSportId) => {
    const objects = [];
    if (testsStd) {
      testsStd.stdTestBanks.filter(bank => bankCodeArr.includes(bank.code) )
        .forEach(bank => bank.subbanks
          .forEach(subbank => subbank.objects
            .forEach((object) => {
              objects.push(object);
            })));
    }

    // return objects.filter(e => e.cat.sportIds.includes(currentSportId));

    // included all tests
    return objects;
  },
);

export const makeGetStdTests = bankCode => createSelector(
  getTestsStd,
  getCurrentSportId,
  (testsStd, currentSportId) => {
    const objects = [];
    if (testsStd) {
      testsStd.stdTestBanks.filter(bank => bank.code === bankCode)
        .forEach(bank => bank.subbanks
          .forEach(subbank => subbank.objects
            .forEach((object) => {
              objects.push(object);
            })));
    }

    // return objects.filter(e => e.cat.sportIds.includes(currentSportId));

    // included all tests
    return objects;
  },
);

export default 'PlayerData';
