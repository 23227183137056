import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
import LeaderboardTable from "../Leaderboard/LeaderboardTable";
import Banner from "./Banner";
import FilterSidebar from "../common/FiltersSidebar";
import SidebarMultiSelectCallbackFilter from "../common/SidebarMultiSelectCallbackFilter";
import {batThrow, positions} from "../../../components/UxCommon/Form/utils";
import {expandedAllTimeLeaderboardDialogPath, Routes} from "../../../store/actions/ui/routes";
import {BankSlugs} from "../+store/combine";
import NationalLeaderboardLoadingIndicator from "../common/NationalLeaderboardLoadingIndicator";
import AllTimeLeaderboardHeader from "./Header";
import {ScoreType} from "../+store/nationalLeaderboard";
import SidebarAthleteSearch from "../common/SidebarAthleteSearch";
import {setSortBy} from "../+store/coachWorld";
import ExpandedPhotoDialog from "../../../containers/UxProfile/Photos/ExpandedPhotoDialog";
import Video from "../../../store/actions/data/models/Video";
import SidebarSportSelectFilter from "../common/SidebarSportSelectFilter/SidebarSportSelectFilter.container";
import {getNextFilterRank} from "../NationalLeaderboard/NationalLeaderboard.component";

const getSidebarFilters = ({sportId, gradYears}) => {
  const linkFilters = [];
  let queryStringFilters = [
    {
      name: "position",
      label: "Positions",
      items: positions(sportId).map(({value, name}) => ({
        name,
        value
      }))
    },
    // {
    //   name: "gender",
    //   label: "Genders",
    //   items: gender().map(({ text, value }) => ({ name: text, value }))
    // }
  ];

  if (sportId === 2 || sportId === 4) {
    const bats = {
      name: 'bats',
      label: 'BATS',
      items: batThrow.map(({name, value}) => ({name, value})),
    };
    const throws = {
      name: 'throws',
      label: 'Throws',
      items: batThrow.map(({name, value}) => ({name, value})),
    };

    queryStringFilters.splice(2, 0, bats, throws);
  }

  if (gradYears.length) {
    const gradYearFilter = {
      name: "gradYear",
      label: "Grad Year",
      items: gradYears.map((value) => ({
        name: value,
        value
      }))
    };

    queryStringFilters.splice(1, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters
  };
};

class AllTimeLeaderboardComponent extends PureComponent {
  static propTypes = {
    getAllTimeLeaderboardTemplate: PropTypes.func.isRequired,
    template: PropTypes.object,
    getAllTimeLeaderboardTestRes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    results: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    columns: PropTypes.array,
    handleTestSortClicked: PropTypes.func.isRequired,
    sortByTestId: PropTypes.number,
    viewSelection: PropTypes.string.isRequired,
    onResultsChange: PropTypes.func.isRequired,
    numResultsShown: PropTypes.string.isRequired,
    onScoreTypeChange: PropTypes.func.isRequired,
    selectedScoreType: PropTypes.string.isRequired,
    measureableColumns: PropTypes.array.isRequired,
    getTestCategories: PropTypes.func.isRequired,
    testCats: PropTypes.object,
    isLoggedIn: PropTypes.bool.isRequired,
    getSports: PropTypes.func.isRequired,
    sports: PropTypes.array.isRequired,
    parsedFilters: PropTypes.string,
    currentSport: PropTypes.string,
    zip: PropTypes.string,
    radius: PropTypes.string,
    search: PropTypes.string,
    displayShowMore: PropTypes.bool.isRequired,
    gradYears: PropTypes.array.isRequired,
    schoolStyle: PropTypes.object,
    getSchoolSports: PropTypes.func.isRequired,
    getSchoolGradYears: PropTypes.func.isRequired,
    schoolSports: PropTypes.array.isRequired,
    schoolGradYears: PropTypes.array.isRequired,
    getSchoolStyles: PropTypes.func.isRequired,
    eventAverage: PropTypes.any.isRequired,
  };

  componentDidMount() {
    const {getSports, match, getSchoolSports, getSchoolGradYears} = this.props;
    const schoolId = match.params.schoolId;
    getSports();
    getSchoolSports(schoolId);
    getSchoolGradYears(schoolId);
    this.sportsFetched = true;
    this.shouldUpdate();
  }

  isFilterChanged = (currentFilter, prevFilter) => {
    if ((!currentFilter || currentFilter && currentFilter.length === 0) && (!prevFilter || prevFilter && prevFilter.length === 0)) return false;
    const currentFilterWithoutEvents = currentFilter && currentFilter.length ? currentFilter.filter(cF => cF.name !== "events") : null;
    const prevFilterWithoutEvents = prevFilter && prevFilter.length ? prevFilter.filter(pF => pF.name !== "events") : null;

    const sortedCurrentFilter = currentFilterWithoutEvents && currentFilterWithoutEvents.sort((a, b) => a.name.localeCompare(b.name)).filter(cF => cF.selected.sort().toString())
    const sortedPrevFilter = prevFilterWithoutEvents && prevFilterWithoutEvents.sort((a, b) => a.name.localeCompare(b.name)).filter(pF => pF.selected.sort().toString())
    return JSON.stringify(sortedCurrentFilter) !== JSON.stringify(sortedPrevFilter);
  };

  componentDidUpdate(prevProps) {
    const {
      currentSport,
      sortByTestId,
      zip,
      radius,
      search,
      selectedScoreType,
      selectedFilters,
      schoolStyle,
      getSports,
      getSchoolSports,
      getSchoolGradYears,
      getSchoolStyles,
      match
    } = this.props;

    if (schoolStyle === undefined && prevProps.schoolStyle !== schoolStyle) {
      this.shouldSchoolFetch = true;
    }

    if (this.shouldSchoolFetch) {
      getSchoolSports(match.params.schoolId);
      getSchoolGradYears(match.params.schoolId);
      getSchoolStyles([match.params.schoolId]);
      this.sportsFetched = true;
      this.shouldTemplateFetch = true;
      this.shouldLeaderBoardFetch = true;
      this.shouldSchoolFetch = false;
    }

    if (this.isFilterChanged(selectedFilters, prevProps.selectedFilters) && (!currentSport || (currentSport && prevProps.currentSport === currentSport))) {
      this.shouldLeaderBoardFetch = true;
    }
    if (currentSport && prevProps.currentSport !== currentSport) {
      this.shouldTemplateFetch = true;
      this.shouldLeaderBoardFetch = true;
    }
    if (sortByTestId && prevProps.sortByTestId !== sortByTestId) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.zip !== zip) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.radius !== radius) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.search !== search) {
      this.shouldLeaderBoardFetch = true;
    }
    if (prevProps.selectedScoreType !== selectedScoreType) {
      this.shouldLeaderBoardFetch = true;
    }
    this.shouldUpdate();
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  onHeaderClick = (stdTestObjectId) => {
    this.shouldLeaderBoardFetch = true;
    const {handleTestSortClicked, location, history} = this.props;
    handleTestSortClicked(location, history, stdTestObjectId);
  };

  onResultsChange = (value) => {
    this.shouldLeaderBoardFetch = true;
    const {onResultsChange, location, history} = this.props;
    onResultsChange(location, history, value);
  };

  onScoreTypeChange = (value) => {
    const {onScoreTypeChange, location, history} = this.props;
    onScoreTypeChange(location, history, value);
  };

  onShowMoreClick = () => {
    this.loadLeaderboard(true);
  };

  getCurrentSportId = () => {
    const sport = this.getCurrentSport();
    return sport ? sport.id : null;
  };

  getSelectedSports = () => {
    const {selectedFilters} = this.props;
    const sportFilter = selectedFilters ? selectedFilters.find(fl => fl.name === "sports") : null;
    if (sportFilter) {
      return sportFilter.selected;
    }

    return [];
  };

  getCurrentSport = () => {
    const {currentSport, sports, schoolSports} = this.props;
    const selectedSport = currentSport ? sports.find(s => s.code === currentSport) : null;
    if (selectedSport) {
      return selectedSport;
    }
    let organizationSports = [];
    if (schoolSports.length && sports && sports.length) {
      organizationSports = schoolSports.map(schoolSport => {
        return sports.find(sport => sport.id === schoolSport.sport_id);
      });
    }
    return organizationSports.length ? organizationSports[0] : null;
  };

  getCurrentSportCode = () => {
    const sport = this.getCurrentSport();
    return sport ? sport.code : null;
  };

  getSortByCatId = () => {
    const {columns, sortByTestId} = this.props;
    const sortByColumn = columns.find(column =>
      column.standardTestObjectId === sortByTestId);
    return sortByColumn.test_cat_id || sortByColumn.standardTestObjectId;
  };

  setRef = (table) => {
    this.table = table;
  };

  getOrderBy = () => {
    const {columns, sortByTestId, selectedScoreType} = this.props;
    if (selectedScoreType && selectedScoreType !== "raw") {
      return "desc";
    }
    const sortByColumn = columns.find(column =>
      column.standardTestObjectId === sortByTestId);
    return sortByColumn.best === "min" ? "asc" : "desc";
  };

  loadLeaderboard = (loadMore = null) => {
    const {
      selectedScoreType,
      getAllTimeLeaderboardTestRes,
      numResultsShown,
      parsedFilters,
      zip,
      radius,
      search,
      match,
      results,
    } = this.props;
    const sortBy = this.getSortByCatId();
    this.shouldLeaderBoardFetch = false;
    const scoreType = selectedScoreType ? ScoreType[selectedScoreType] : null;
    let perPage = Number(numResultsShown);
    let page = 1;
    if (loadMore) {
      this.page++;
      page = this.page;
      perPage = 50;
      if (page === 1) {
        perPage = Number(perPage) + Number(numResultsShown);
        perPage = perPage - (perPage % 50);
        this.page = (perPage / 50);
      }
    } else {
      this.page = 0;
    }
    const orderBy = this.getOrderBy();
    const sportId = this.getCurrentSportId();
    const schoolId = match.params.schoolId;
    const lastFilterRank = results && results.length && page > 1 ? getNextFilterRank(results) : 1;

    getAllTimeLeaderboardTestRes({
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      loadMore,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
      lastFilterRank,
    });
  };

  shouldUpdate = () => {
    const {
      match,
      selectedScoreType,
      onScoreTypeChange,
      location,
      history,
      testCats,
      getTestCategories,
      sortByTestId,
      columns,
      getSports,
      getAllTimeLeaderboardTemplate,
      getSchoolSports,
    } = this.props;

    const schoolId = match.params.schoolId;
    if (!testCats.organizedCats && !this.testCatsFetched) {
      this.testCatsFetched = true;
      getTestCategories();
    }
    const sportId = this.getCurrentSportId();
    if (this.shouldTemplateFetch && sportId) {
      getAllTimeLeaderboardTemplate(schoolId, sportId);
      this.shouldTemplateFetch = false;
    }
    if (!this.sportsFetched) {
      this.sportsFetched = true;
      getSports();
      getSchoolSports(schoolId);
    }
    const {bank} = match.params;
    if (this.currentBank && this.currentBank !== bank) {
      this.shouldLeaderBoardFetch = true;
      this.currentBank = bank;
    }
    if (this.shouldLeaderBoardFetch && sortByTestId && columns.length) {
      this.currentBank = bank;
      this.loadLeaderboard();
    }
    if (((match.params.bank === BankSlugs.PERF)
        && (selectedScoreType === "relative"))
      || ((match.params.bank === BankSlugs.VC_SCORE)
        && (selectedScoreType !== "raw"))) {
      onScoreTypeChange(location, history, "raw");
    }
  };

  showFilterRank = filters => !!(filters && filters.find(fl => fl.name !== "events"));

  isBankPartOfTemplate = () => {
    const {template, match, currentSport} = this.props;
    if ((currentSport === undefined || currentSport === "FOOTBALL") && match.params.bank === BankSlugs.VC_SCORE) {
      return true;
    }
    const currentBank = Object.keys(BankSlugs).find(key => {
      return BankSlugs[key] === match.params.bank;
    });

    return template.banks.includes(currentBank);
  };

  redirectToSportBank = () => {
    const {template, match} = this.props;
    const bankSlug = BankSlugs[template.banks[0]];
    return (<Redirect
      to={`/${Routes.coach}/${match.params.schoolId}/${Routes.allTimeLeaderboard}/${bankSlug}${location.search}`}
    />);
  };


  redirectToBank = () => {
    const {template, match} = this.props;
    const bankSlug = BankSlugs[template.banks[0]];
    return (<Redirect
      to={`/${Routes.coach}/${match.params.schoolId}/${Routes.allTimeLeaderboard}/${bankSlug}`}
    />);
  };

  table = null;
  testCatsFetched = false;
  intervalId = null;
  shouldLeaderBoardFetch = true;
  shouldTemplateFetch = true;
  currentBank = null;
  sportsFetched = false;
  page = 0;
  shouldSchoolFetch = true;

  renderTable = () => {
    const {
      columns,
      sortByTestId,
      results,
      viewSelection,
      loading,
      selectedScoreType,
      measureableColumns,
      isLoggedIn,
      displayShowMore,
      selectedFilters,
      location,
      history,
      match,
      eventAverage
    } = this.props;
    const isLoading = loading && (!results || results.length === 0);
    if (isLoading) return null;
    const eventFilter = selectedFilters ? selectedFilters.find(fl => fl.name === "events") : null;
    const selectedEvents = eventFilter ? eventFilter.selected : null;

    const filteredColumns = selectedEvents && selectedEvents.length ?
      columns.filter(c => selectedEvents.includes(c.standardTestObjectId)) :
      null;

    const selectedTest = filteredColumns && filteredColumns.length && !(filteredColumns.find(fc => fc.standardTestObjectId === sortByTestId)) ?
      setSortBy(location, history, filteredColumns[0].standardTestObjectId) : sortByTestId;

    return (
      <div className="Leaderboard_TableWrapper_Container">
        <div className="Leaderboard_TableWrapper">
          <LeaderboardTable
            data={results}
            columns={filteredColumns && filteredColumns.length ? filteredColumns : columns}
            onHeaderClick={this.onHeaderClick}
            sortByTestId={selectedTest}
            viewSelection={viewSelection}
            loading={isLoading}
            onShowMoreClick={this.onShowMoreClick}
            displayShowMore={displayShowMore}
            selectedScoreType={selectedScoreType}
            measureableColumns={measureableColumns}
            isLoggedIn={isLoggedIn}
            showFilterRank={this.showFilterRank(selectedFilters)}
            rankHeading={"ATR"}
            enableAthleteLink
            isAllTimeLeaderboard
            match={match}
            location={location}
            eventAverage={eventAverage}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      loading,
      loadingMsg,
      match,
      history,
      location,
      numResultsShown,
      selectedScoreType,
      isLoggedIn,
      template,
      sports,
      columns,
      results,
      gradYears,
      videos,
      schoolStyle,
      schoolSports
    } = this.props;
    let sportId = 0;

    if (sports) {
      sportId = this.getCurrentSportId();
    }

    if (template && template.banks && template.banks.length && !this.isBankPartOfTemplate()) {
      return this.redirectToSportBank();
    }

    let filteredSports = sports;


    // will use if we need to remove any sport filter
    if (schoolSports.length && sports && sports.length) {
      filteredSports = schoolSports.map(schoolSport => {
        return sports.find(sport => sport.id === schoolSport.sport_id);
      });
    }
    //const filteredSports = sports ? sports.filter(sport => ['FOOTBALL'].includes(sport.code)) : sports;
    const {linkFilters, queryStringFilters} = getSidebarFilters({
      sportId,
      gradYears
    });

    let bgImage;
    let bgColor;
    if (schoolStyle) {
      if (schoolStyle.leaderboardBackground) {
        bgImage = `url(${schoolStyle.leaderboardBackground})`;
      }
      if (schoolStyle.leaderboardStyle === "dark") {
        bgColor = "black";
      } else {
        bgColor = "white";
      }
    } else {
      bgColor = "black";
    }
    const themeClass = schoolStyle
    && (schoolStyle.leaderboardStyle === "dark") ? "dark" : "light";
    const banks = template && template.banks ? template.banks : [];

    const photoVideos = videos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });

    return (
      <React.Fragment>
        <Switch>
          <Route
            path={expandedAllTimeLeaderboardDialogPath}
            render={(props) => (
              <ExpandedPhotoDialog photoList={photoVideos} {...props} location={location} isSingleVideo={true}
                                   page={`${Routes.allTimeLeaderboard}`}/>
            )}
          />
        </Switch>

        <div className={`LeaderboardPage ${themeClass}-theme AllTimeLeaderboard`}>
          <div
            className="Leaderboard_Background"
            style={{
              backgroundImage: bgImage,
              backgroundColor: bgColor
            }}
          />
          <Banner match={match} location={location} schoolStyle={schoolStyle}
                  customClass={'AllTimeLeaderboardBanner_LogoWrapper'}/>
          <div className="Leaderboard_BodyWrapper">
            <AllTimeLeaderboardHeader
              match={match}
              location={location}
              onResultsChange={this.onResultsChange}
              numResultsShown={numResultsShown}
              onScoreTypeChange={this.onScoreTypeChange}
              selectedScoreType={selectedScoreType}
              isLoggedIn={isLoggedIn}
              sport={this.getCurrentSportCode()}
              banks={banks}
            />
            <SidebarAthleteSearch
              location={location}
              history={history}
              className=""
            />
            <NationalLeaderboardLoadingIndicator
              loading={loading}
              loadingMsg={loadingMsg}
              leaderboard
              wrapperStyle={{top: 100}}
            />`
            <div className="Leaderboard_Body">
              <FilterSidebar
                displayNone={!!loading && (!results || results.length === 0)}
                className="Leaderboard ntlLeaderboard"
              >
                {
                  filteredSports.length && (
                    <SidebarSportSelectFilter
                      label="Sports"
                      filters={filteredSports.map(column => ({
                        name: column.name,
                        value: column.code
                      }))}
                      history={history}
                      location={location}
                      className="Leaderboard"
                      defaultValue={filteredSports[0].code}
                    />
                  )
                }

                {/*{*/}
                {/*  filteredSports.length && (*/}
                {/*    <SidebarMultiSelectCallbackFilter*/}
                {/*      key="Sports"*/}
                {/*      filterName="sports"*/}
                {/*      label={"Sports"}*/}
                {/*      filters={filteredSports.map(column => ({*/}
                {/*        name: column.name,*/}
                {/*        value: column.id*/}
                {/*      }))}*/}
                {/*      history={history}*/}
                {/*      location={location}*/}
                {/*      className="Leaderboard"*/}
                {/*    />*/}
                {/*  )*/}
                {/*}*/}
                <SidebarMultiSelectCallbackFilter
                  key="Events"
                  filterName="events"
                  label={"Events"}
                  filters={columns.map(column => ({
                    name: column.testCategoryName,
                    value: column.standardTestObjectId
                  }))}
                  history={history}
                  location={location}
                  className="Leaderboard"
                />
                {/*Moved to second phase*/}
                {/*<SidebarZipCodeSearch*/}
                {/*  label="Location"*/}
                {/*  location={location}*/}
                {/*  history={history}*/}
                {/*  className="Leaderboard leaderboardLocation"*/}
                {/*/>*/}
                {
                  linkFilters.map(({name, label, items}) => (
                    <SidebarLinkFilter
                      key={name}
                      filterName={name}
                      label={label}
                      filters={items}
                      match={match}
                    />
                  ))
                }
                {
                  queryStringFilters.map(({name, label, items}) => (
                    <SidebarMultiSelectCallbackFilter
                      key={name}
                      filterName={name}
                      label={label}
                      filters={items}
                      history={history}
                      location={location}
                      className="Leaderboard"
                    />
                  ))
                }
              </FilterSidebar>
              {
                template && (
                  <Switch>
                    <Route
                      path={`/${Routes.coach}/:schoolId/${Routes.allTimeLeaderboard}/:bank`}
                      render={this.renderTable}
                    />
                    <Route
                      path={`/${Routes.coach}/:schoolId/${Routes.allTimeLeaderboard}`}
                      render={this.redirectToBank}
                    />
                  </Switch>
                )
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AllTimeLeaderboardComponent;
