import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children, className, style }) => (
  <div className={`container ${className || ''}`} style={style || {}}>
    {children}
  </div>
);
Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Container;
