import React from 'react';
import PropTypes from 'prop-types';
import CustomIcon from '../Icon/CustomIcon';

const CustomVideoIcon = ({
  iconName,
  className,
  tooltip,
  iconStyle,
  multiLine,
  place,
  ...props
}) => (
  <button className={['UxCommon_ButtonIconOnly', className || ''].join(' ')} {...props}>
    <CustomIcon 
    iconName={iconName}
    tooltip={tooltip} 
    style={iconStyle} 
    multiLine={multiLine}
    place={place}
    />
  </button>
);
CustomVideoIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CustomVideoIcon;
