import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../../components/UxCommon';

class ArchiveCombineDialog extends PureComponent {
  static propTypes = {
    onArchiveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    msg: PropTypes.string,
    btnMsg: PropTypes.string,
  };

  render() {
    const {
      onArchiveClick,
      onCancelClick,
      msg,
      isEnableToDelete,
      btnMsg,
      isAuthorizedToDelete,
    } = this.props;
    const isAuthToDelete = isAuthorizedToDelete + "" !== "false"
    const question = ( !isAuthToDelete ? (
          <div className="delete_modal_message">
            <h3>{`${msg}`}</h3>
          </div> )
        : isEnableToDelete ?
          (
            <div className="delete_modal_message">
              <p>{`Hey Coach -`}
              <span>{`${msg}`}</span>
                {`combine template can't be deleted because the template
                 is used in the following Combine(s) :`}
              </p>
            </div>)
          : <span>
            { msg || 'Are you sure you want to delete this?'}
          </span>
    );
    let { title } = this.props;

    if (!title) {
      title = 'Delete Combine?';
    }

    return (
      <div className="Combine_ArchiveDialog">
        <CloseButton size={10} onClick={onCancelClick} />
        <div className="AlbumDialod_DeleteAlbumDialog_Title">
          {title}
        </div>
        <div className="AlbumDialod_DeleteAlbumDialog_Question">
          {question}
        </div>

        { (isAuthToDelete && !isEnableToDelete ) &&
        <ButtonRect
          className="AlbumDialod_DeleteAlbumDialog_Btn primary"
          onClick={onArchiveClick}
        >
          {btnMsg || 'Delete'}
        </ButtonRect>
        }

        { (isEnableToDelete && isAuthToDelete ) &&
        <div className="delete_template_message_scroll" style={{width: "75%"}}>
          <ul style={{height: "90px", overflow: "scroll", border: '1px solid #00000014', padding:'6px 40px','list-style-type': 'square'}}>
            {this.props.combineNames.map( (combineName,i) => (
              <li key={`${combineName}${i}`}>{combineName}</li>
              ))}
          </ul>
          <p>The only way to delete this template is to first delete the associated Combine(s).</p>
          <p className="template_delete_note" >Also, know that deleting Combines is permanent and will cause not only the loss of future comparison data
            (comparing Combines against each other) but also deleted Combines will result in the associated data
            being removed from your player's profiles.
          </p>
          </div>
        }
        <ButtonRect
          className="AlbumDialod_DeleteAlbumDialog_Btn"
          onClick={onCancelClick}
        >
          Cancel
        </ButtonRect>
      </div>
    );
  }
}

export default ArchiveCombineDialog;
