import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../components/UxEditProfile/Account/Account';
import {
  getProfile,
  getAuthenticatedUserProfile,
  getSelectedSport,
} from '../UxCommon/Utils';
import { alertOrNavigate } from '../UxCommon/NativeUtils';
import {
  accountFormInit,
  saveAccountInfo,
  accountInit,
} from '../../store/actions/ui/uxEditProfile';
import { formSubmit } from '../../store/actions/ui/formUtils';
import {
  logout,
  logoutWithRedirect,
} from '../../store/actions/data/cognito/auth';
import { routeChangedAlert } from '../../store/actions/ui/routes';
import { accountFormInputs } from '../../store/actions/ui/uxEditProfile/utils';
import { metricSports } from '../../components/UxProfile/UxProfile.constants';

const smartConverted = (smart) => smart.replace('”', '"').replace('’', "'");

const checkMetricUnits = (state, ownProps) => {
  const selectedSport = getSelectedSport(state, ownProps.match);
  return selectedSport && metricSports.includes(selectedSport.sportId);
};

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const canEdit = canEditProfile && canEditProfile.canEdit;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  const profile = canEdit
    ? getProfile(state, ownProps.match)
    : getAuthenticatedUserProfile(state);
  return {
    profile,
    loading: state.ui.uxEditProfile.account.loading,
    loadingMsg: state.ui.uxEditProfile.account.loadingMsg,
    rrfForm: state.forms.forms.account,
    phoneHelp: state.ui.uxEditProfile.account.formPhoneHelp,
    parentsPhoneHelp: state.ui.uxEditProfile.account.formParentsPhoneHelp,
    formGeneralError: state.ui.uxEditProfile.account.formError,
    formSubmitSuccess: state.ui.uxEditProfile.account.formSuccess,
    formNeedsInit: state.ui.uxEditProfile.account.formNeedsInit,
    inputs: accountFormInputs,
    confirmPhone: state.ui.uxEditProfile.account.phoneNeedsConfirm,
    confirmEmail: state.ui.uxEditProfile.account.emailNeedsConfirm,
    successMessage: state.ui.uxEditProfile.account.successMessage,
    isCoach,
    state,
    isMetricUnits: checkMetricUnits(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    signOut: () => {
      dispatch(logout());
    },
    signOutWithRedirect: (redirectURL) => {
      dispatch(logoutWithRedirect(redirectURL));
    },
    onBirthdateChange: (date) => {
      dispatch(actions.change('forms.account.birthdate', date));
    },
    onFirstNameKeyUp: (evt) => {
      let str = evt.target.value;
      if (str && str.length >= 1) {
        const firstChar = str.charAt(0);
        const remainingStr = str.slice(1);
        str = firstChar.toUpperCase() + remainingStr.toLowerCase();
        dispatch(actions.change('forms.account.first', str));
      }
    },

    onHeightChanged: () => {
      if (stateProps.rrfForm.heightFt && stateProps.rrfForm.heightInch) {
        if (stateProps.rrfForm.heightFt.value) {
          const height = !stateProps.rrfForm.heightInch.value
            ? `${stateProps.rrfForm.heightFt.value}'`
            : `${stateProps.rrfForm.heightFt.value}'${stateProps.rrfForm.heightInch.value}"`;
          dispatch(actions.change('forms.account.height', height));
        }
      }
    },
    onLastNameKeyUp: (evt) => {
      let str = evt.target.value;
      if (str && str.length >= 1) {
        const firstChar = str.charAt(0);
        const remainingStr = str.slice(1);
        str =
          firstChar.toUpperCase() +
          remainingStr.replace(/(\s+\w{1})|(-+\w{1})/g, (letter) =>
            letter.toUpperCase(),
          );
        dispatch(actions.change('forms.account.last', str));
      }
    },
    onPhoneChange: (value, data, event, formattedValue) => {
      dispatch(actions.change('forms.account.phone', formattedValue));
    },

    submit: (isMobile = false, isMetricUnits = false) => {
      dispatch(actions.blur('forms.account.phone'));
      dispatch(actions.blur('forms.account.parentsPhone'));
      if (!!stateProps.rrfForm.height.value && isMobile) {
        const height = smartConverted(stateProps.rrfForm.height.value);
        const heightFt = height.slice(0, height.indexOf("'"));
        dispatch(actions.change('forms.account.heightFt', heightFt));
        if (height.indexOf('"') !== -1) {
          const heightInch = height.slice(
            height.indexOf("'") + 1,
            height.indexOf('"'),
          );
          dispatch(actions.change('forms.account.heightInch', heightInch));
        } else dispatch(actions.change('forms.account.heightInch', ''));
      }
      dispatch(formSubmit('forms.account', { isMobile, isMetricUnits }));
    },
    saveAccount: (values) => {
      let heightInch = '';
      let heightFt = '';
      if (values.height) {
        const height = smartConverted(values.height);
        heightFt = height.slice(0, height.indexOf("'"));
        if (height.indexOf('"') !== -1) {
          heightInch = height.slice(
            height.indexOf("'") + 1,
            height.indexOf('"'),
          );
        }
      }
      const newValues = Object.assign({}, { heightFt, heightInch, ...values });
      saveAccountInfo(newValues)(dispatch);
    },
    formInit: (isMobile = false) => {
      dispatch(actions.reset('forms.account'));
      dispatch(actions.merge('forms.account', stateProps.profile));
      dispatch(accountFormInit());
    },
    init: () => {
      dispatch(accountInit());
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    alertOrNavigate: (navigateTo) =>
      alertOrNavigate(state, dispatch, navigateTo),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
