import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import { Link, Redirect } from "react-router-dom";
import { invited, mergeSignIn, pmaInvited, Routes } from "../../../store/actions/ui/routes";

import TextControlGroup from "../../UxCommon/Form/TextControlGroup/TextControlGroup";
import { parserLower } from "../../../utils/parsers";
import { Roles } from "../../../store/actions/data/user/profile/models";
import { getPositions } from "../InvitedSignUp/InvitedSignUp";
import axios from "axios";
import Urls from "../../../config/urls";
import { MergeTypes } from "../MergeSignIn/MergeSignIn";


class MergeAnotherAccount extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    authenticatedProfile: PropTypes.object,
    rrfForm: PropTypes.object,
    updatePosition: PropTypes.func,
    getProfile: PropTypes.func,
    authUuid: PropTypes.string,
    shouldCheckInviteValid: PropTypes.bool,
    isInvitationValid: PropTypes.object,
    checkIfInviteIsValid: PropTypes.func,
    updateFormError: PropTypes.func.isRequired,
    getProfileByEmail: PropTypes.func.isRequired,
    mergeProfileLoaded: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {
      match,
      getProfile,
      loggedIn,
      authUuid,
      shouldCheckInviteValid,
      checkIfInviteIsValid,
      formInit,
    } = this.props;
    if (shouldCheckInviteValid) {
      checkIfInviteIsValid(match.params.userTeamId, match.params.token);
    } else {
      getProfile(match.params.uuid);
    }
    if (loggedIn && authUuid) {
      getProfile(authUuid);
    }
    formInit();
  }

  componentDidUpdate() {
    const {
      match,
      getProfile,
      loggedIn,
      authUuid,
      authenticatedProfile,
      profile,
      shouldCheckInviteValid,
      checkIfInviteIsValid,
    } = this.props;
    if (shouldCheckInviteValid) {
      checkIfInviteIsValid(match.params.userTeamId, match.params.token);
    } else if (!profile && match.params.uuid) {
      getProfile(match.params.uuid);
    }
    if (loggedIn && (!authenticatedProfile || authenticatedProfile.uuid !== authUuid)) {
      getProfile(authUuid);
    }

    this.shouldInit();
  }

  shouldInit = () => {
    const { needsInit, formInit } = this.props;
    if (needsInit) {
      formInit();
    }
  };

  redirectToMergeProfileModal = ()=>{
    const {match} = this.props;
    if(match.params.schoolId){
      return(
        <Redirect
          to={pmaInvited(
            match.params.uuid,
            match.params.token,
            match.params.teamName,
            match.params.coachName,
            match.params.sportId,
            match.params.userTeamId,
            match.params.schoolId,
          )}
        />
      );
    }
    return(
      <Redirect
      to={invited(
        match.params.uuid,
        match.params.token,
        match.params.teamName,
        match.params.coachName,
        match.params.sportId,
        match.params.userTeamId,
        match.params.schoolId,
      )}
    />
    );
  }

  submit = async () => {
    const { updateFormError, rrfForm , getProfileByEmail} = this.props;
    try {
      await axios.get(`${Urls.exists.checkEmailForMerge(rrfForm.username.value)}`);
      getProfileByEmail(rrfForm.username.value);
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error));
      if (err.status === 404) {
        updateFormError(rrfForm.username.value, "UserNotFoundException");
      }
    }
  };

  render = () => {
    const {
      loggedIn,
      authenticatedProfile,
      formGeneralError,
      isInvitationValid,
      shouldCheckInviteValid,
      mergeProfileLoaded,
      match
    } = this.props;

    // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
    // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
    if (loggedIn && authenticatedProfile && authenticatedProfile.roleId === Roles.coach) {
      return <Redirect to={`/${Routes.coach}/${Routes.dashboard}`} />;
    }


    if (!shouldCheckInviteValid && isInvitationValid && isInvitationValid.isValid === false) {
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">Invitation Invalid</div>
              <div className="welcome-desc">
                This invite link is no longer active.
                Please check with your coach to see if there is a new link available
              </div>
            </div>
          </div>
        </div>
      );
    }

    if(mergeProfileLoaded){
      debugger;
      return this.redirectToMergeProfileModal();
    }

    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">Accept Invite</div>
            <div className="welcome-desc">
              {`Please provide the email in order to merge the data your coach added into your profile.`}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model={"forms.mergeAnotherAccount"}
                  onSubmit={this.submit}>
              {
                !loggedIn &&
                <TextControlGroup
                  label="Email"
                  model="forms.mergeAnotherAccount.username"
                  parser={parserLower}
                />
              }
              {
                this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{formGeneralError}</div>
                </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Submit</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
}

export default MergeAnotherAccount;
