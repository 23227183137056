import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from '../components/ActivateProfileMembership/ActivateProfileMembership.models';

const useHasGuestMembership = (match, uuid) => {
  const [hasMembership, setHasMembership] = useState(false);

  const profile = useSelector((state) => getProfile(state, uuid));


  useEffect(() => {
    if (profile && !profile.isPaidProfile) {
      setHasMembership(true);
    } else {
      setHasMembership(
        !!(profile && profile.isPaidProfile && profile.isSubscribed),
      );
    }
  }, [profile]);

  return hasMembership;
};

export default useHasGuestMembership;
