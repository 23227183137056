import {asyncStartAction} from '../../../../store/actions/ui/async';

export const GET_ATHLETE_DETAILS = 'prospectSheet.athlete.profile.get'
export const getAthleteProfile = (teamId, athleteId) => (dispatch) => {
  dispatch(asyncStartAction(GET_ATHLETE_DETAILS))
  dispatch({
    type: GET_ATHLETE_DETAILS,
    teamId,
    athleteId,
    dispatch,
  });
}

export const GET_PLAYER_DATA = 'prospectSheet.playerData.get'
export const getPlayerData = (uuid, sport,msg="") => (dispatch) => {
  dispatch(asyncStartAction(GET_PLAYER_DATA, msg))
  dispatch({
    type: GET_PLAYER_DATA,
    uuid,
    sport,
    dispatch,
  });
}

export const GET_TRANSCRIPT = 'prospectSheet.get.transcript';
export const getTranscript = ( sportId, athleteId ) => dispatch => {
  dispatch(asyncStartAction(GET_TRANSCRIPT, "Loading..."))
  dispatch({
    type: GET_TRANSCRIPT,
    sportId,
    athleteId,
    dispatch,
  });
}

export const UPLOAD_TRANSCRIPT = 'prospectSheet.upload.transcript';
export const uploadTranscript = ( sportId, athleteId, file ) => dispatch => {
  dispatch(asyncStartAction(UPLOAD_TRANSCRIPT, "Loading..."))
  dispatch({
    type: UPLOAD_TRANSCRIPT,
    sportId,
    athleteId,
    file,
    dispatch,
  });
}

export const DELETE_TRANSCRIPT = 'prospectSheet.delete.transcript';
export const deleteTranscript = (athleteId, sportId, transcriptId) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_TRANSCRIPT, 'Deleting Transcript', { id: transcriptId }));
  dispatch({
    type: DELETE_TRANSCRIPT,
    athleteId, 
    sportId, 
    transcriptId,
  });
};

export default 'prospectSheet.actions.js';
