import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
// const IconNames = [
//   'football',
//   'baseball',
//   'soccer',
//   'basketball',
// ];

const Icon = ({
  className, iconName, style, tooltip,place, tooltiptextColor, tooltipbackgroundColor,onClick,
  multiLine
}) => (
  <React.Fragment>
    <i
      id={`${iconName}-menu-icon`}
      className={[`icon-${iconName}-icon`, className].join(' ')}
      style={style || {}}
      data-tip={tooltip}
      data-place={place}
      data-background-color={tooltipbackgroundColor}
      data-text-color={tooltiptextColor}
      onClick={onClick}
      data-multiline={multiLine || false}     //true to support <br> or <br/> for multiLine
    />
    {tooltip && <ReactTooltip />}
  </React.Fragment>
);
Icon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
};

export default Icon;
