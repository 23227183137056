import { combineReducers } from 'redux';
import {
  PD_STATS_NEW_SEASON,
  PD_STATS_CANCEL_NEW_SEASON,
  PD_STATS_EDIT_TABLE,
  PD_STATS_CANCEL_EDIT_TABLE,
  PD_STATS_SHOW_DEL_SEASON_DIALOG,
  PD_STATS_HIDE_DEL_SEASON_DIALOG,
  PD_STATS_DEL_SEASON,
  PD_GET_STATS,
  SHOW_FIRST_SEASON_FORM,
  HIDE_FIRST_SEASON_FORM,
  HIDE_ADD_SEASON_FORM,
  SHOW_ADD_SEASON_FORM,
  isStatsFormModel,
  PD_CREATE_UPDATE_STATS,
  PD_STATS_REGISTER_SEASONS,
  PD_STATS_REGISTER_POSITION,
} from '../../../actions/ui/uxPlayerData';
import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_FAILED,
  FORM_SERVER_ERROR,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_PENDING,
} from '../../../actions/ui/formUtils';
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR,
} from '../../../actions/ui/async';

const currentSeasons = (state = [], action) => {
  if (action.type === PD_STATS_REGISTER_SEASONS && action.seasons) {
    return action.seasons;
  }
  return state;
};

const currentPosition = (state = [], action) => {
  if (action.type === PD_STATS_REGISTER_POSITION && action.position) {
    return action.position;
  }
  return state;
};

const newSeasons = (state = [], action) => {
  switch (action.type) {
    case PD_STATS_NEW_SEASON:
      return [...state, {
        positionId: action.positionId,
        teamId: action.teamId,
        rowId: action.rowId,
        season: action.season,
      }];
    case PD_STATS_CANCEL_EDIT_TABLE:
      return state.filter(l =>
        ((l.positionId !== action.positionId)
        || (l.teamId !== action.teamId)));
    case PD_STATS_CANCEL_NEW_SEASON:
      return state.filter(l =>
        ((l.positionId !== action.positionId)
        || (l.teamId !== action.teamId)
        || (l.rowId !== action.rowId)));
    case FORM_SUBMIT_SUCCESS:
      if (isStatsFormModel(action.model)) {
        return state.filter(l =>
          ((l.positionId !== action.data.positionId)
          || (l.teamId !== action.data.teamId)));
      }
      return state;
    default:
      return state;
  }
};

const addSeasonFormsShown = (state = [], action) => {
  switch (action.type) {
    case SHOW_ADD_SEASON_FORM:
      return [...state, {
        positionId: action.positionId,
        teamId: action.teamId,
      }];
    case HIDE_ADD_SEASON_FORM:
    case PD_STATS_NEW_SEASON:
      return state.filter(l =>
        ((l.positionId !== action.positionId)
        || (l.teamId !== action.teamId)));
    default:
      return state;
  }
};

const statsTablesEditing = (state = [], action) => {
  switch (action.type) {
    case PD_STATS_NEW_SEASON:
    case PD_STATS_EDIT_TABLE:
      return [...state, {
        positionId: action.positionId,
        teamId: action.teamId,
      }];
    case PD_STATS_CANCEL_EDIT_TABLE:
      return state.filter(l =>
        ((l.positionId !== action.positionId)
        || (l.teamId !== action.teamId)));
    case FORM_SUBMIT_SUCCESS:
      if (isStatsFormModel(action.model)) {
        return state.filter(l =>
          ((l.positionId !== action.data.positionId)
          || (l.teamId !== action.data.teamId)));
      }
      return state;
    default:
      return state;
  }
};

const statsTablesLoadingMsgs = (state = [], action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
      if (isStatsFormModel(action.model)) {
        return [...state, {
          positionId: action.attrs.positionId,
          teamId: action.attrs.teamId,
          msg: 'Saving your stats',
        }];
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (isStatsFormModel(action.model)) {
        return state.filter(l =>
          ((l.positionId !== action.attrs.positionId)
          || (l.teamId !== action.attrs.teamId)));
      }
      return state;
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_SUCCESS:
      if (isStatsFormModel(action.model)) {
        return state.filter(l =>
          ((l.positionId !== action.data.positionId)
          || (l.teamId !== action.data.teamId)));
      }
      return state;
    case ASYNC_START:
      if (action.model === PD_STATS_DEL_SEASON) {
        return [...state, {
          positionId: action.attrs.positionId,
          teamId: action.attrs.teamId,
          msg: `Deleting ${action.attrs.season.name} season stats`,
        }];
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === PD_STATS_DEL_SEASON)
      || (action.model === PD_GET_STATS)
      || (action.model === PD_CREATE_UPDATE_STATS)) {
        return state.filter(l =>
          ((l.positionId !== action.data.positionId)
          || (l.teamId !== action.data.teamId)));
      }
      return state;
    default:
      return state;
  }
};

const statsLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PD_GET_STATS) {
        return 'Loading Stats';
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PD_GET_STATS) {
        return '';
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === PD_GET_STATS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const isDeleteSeasonDialogShown = (state = null, action) => {
  switch (action.type) {
    case PD_STATS_SHOW_DEL_SEASON_DIALOG:
      return {
        teamId: action.teamId,
        positionId: action.positionId,
        season: action.season,
      };
    case PD_STATS_HIDE_DEL_SEASON_DIALOG:
      return null;
    case ASYNC_START:
      if (action.model === PD_STATS_DEL_SEASON) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const firstSeasonFormIsShown = (state = false, action) => {
  switch (action.type) {
    case SHOW_FIRST_SEASON_FORM:
      return true;
    case HIDE_FIRST_SEASON_FORM:
      return false;
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.firstSeason') {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const firstSeasonLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_PENDING:
      if (action.model === 'forms.firstSeason') {
        return 'One moment ...';
      }
      return state;
    case FORM_SUBMIT_FAILED:
    case ASYNC_ERROR:
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.firstSeason') {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  newSeasons,
  currentSeasons,
  currentPosition,
  isDeleteSeasonDialogShown,
  statsTablesLoadingMsgs,
  statsTablesEditing,
  statsLoadingMsg,
  firstSeasonFormIsShown,
  addSeasonFormsShown,
  firstSeasonLoadingMsg,
});

export default reducer;
