import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';
import TextControl from '../FormControls/TextControl/TextControl.component';
import ColorPicker from '../ColorPicker';
import Icon from '../../common/Icon';
import UpdateSchoolImagesDialog from '../../common/UpdateSchoolImagesDialog/UpdateSchoolImagesDialog.container';
import LoadingIndicator from '../LoadingIndicator';

class EditSchoolStyle extends PureComponent {
  static propTypes = {
    schoolStyle: PropTypes.object,
    closeDialog: PropTypes.func.isRequired,
    saveSchoolStyles: PropTypes.func.isRequired,
    showUploadPhotoDialog: PropTypes.func.isRequired,
    hideUploadPhotoDialog: PropTypes.func.isRequired,
    updateStyle403Error: PropTypes.bool.isRequired,
    updatingStyleStatus: PropTypes.string.isRequired,
  }

  state = {
    errors: {},
    form: {},
    choseColor2: false,
    colorOne: '#000000',
    colorTwo: '',
    changeSchoolLogo: false,
    changeBannerImg: false,
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    const { updatingStyleStatus, closeDialog } = this.props;
    if (updatingStyleStatus === 'Finish Updating') {
      closeDialog();
    }
    this.init();
  }

  onValueChange = name => (value) => {
    const errors = {};
    let adjustedValue;
    switch (name) {
      case 'color1':
        if (!value) {
          errors.name = ['This field is required'];
        } else {
          errors.name = [];
        }
        adjustedValue = value;
        break;
      case 'color2':
        if (!value) {
          errors.number = ['This field is required'];
        } else {
          errors.number = [];
        }
        adjustedValue = value;
        break;
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      form: Object.assign({}, this.state.form, errors),
    });
    return adjustedValue;
  }

  onClickSaveSchoolStyle = (e) => {
    const form = this.formRef.current;
    const values = {};
    const { saveSchoolStyles, schoolStyle } = this.props;
    values.name = schoolStyle.name;
    values.color1 = '#000000';
    values.color2 = form.color2.value;
    values.darkmode = schoolStyle.darkmode;
    values.background_image = schoolStyle.background_image;
    values.school_logo = schoolStyle.school_logo;
    values.leaderboard_logo = schoolStyle.leaderboard_logo;
    values.leaderboard_background = schoolStyle.leaderboard_background;
    values.leaderboard_style = schoolStyle.leaderboard_style;
    e.preventDefault();
    saveSchoolStyles(values, schoolStyle.id);
  }

  choseColor = '';

  color2Clicked = () => {
    this.setState({
      choseColor2: !this.state.choseColor2,
      changeSchoolLogo: false,
      changeBannerImg: false,
    });
  }

  init = () => {
    const { schoolStyle } = this.props;
    const { colorTwo } = this.state;
    if (schoolStyle && !colorTwo) {
      this.setState({
        colorTwo: schoolStyle.color2,
      });
    }
  }

  choseColor = (color, colorID) => {
    this.chosenColor = color;
    this.setState({
      choseColor2: false,
    });
    if (colorID === 1) {
      this.setState({
        colorOne: color,
      });
    } else {
      this.setState({
        colorTwo: color,
      });
    }
  }

  closeColorPicker = () => {
    this.setState({
      choseColor2: false,
    });
  }

  openChangeLogoDialog = () => {
    const { showUploadPhotoDialog } = this.props;
    this.setState({
      changeSchoolLogo: true,
      changeBannerImg: false,
      choseColor2: false,
    });
    showUploadPhotoDialog();
  }

  openChangeBannerImgDialog = () => {
    const { showUploadPhotoDialog } = this.props;
    this.setState({
      changeBannerImg: true,
      changeSchoolLogo: false,
      choseColor2: false,
    });
    showUploadPhotoDialog();
  }

  closeChangeLogoDialog = () => {
    const { hideUploadPhotoDialog } = this.props;
    this.setState({
      changeSchoolLogo: false,
    });
    hideUploadPhotoDialog();
  }

  closeChangeBannerImgDialog = () => {
    const { hideUploadPhotoDialog } = this.props;
    this.setState({
      changeBannerImg: false,
    });
    hideUploadPhotoDialog();
  }

  formRef = React.createRef();

  render() {
    const {
      closeDialog,
      schoolStyle,
      updateStyle403Error,
      updatingStyleStatus,
    } = this.props;

    const {
      errors,
      choseColor2,
      colorOne,
      colorTwo,
      changeSchoolLogo,
      changeBannerImg,
    } = this.state;

    if (changeSchoolLogo) {
      return (
        <div className="EditSchoolStyle_Backdrop">
          <div className="EditSchoolStyle_Modal">
            <UpdateSchoolImagesDialog
              description="Current school logo"
              closeDialog={this.closeChangeLogoDialog}
              image={schoolStyle.school_logo}
              schoolStyle={schoolStyle}
              isBackgroundImage={false}
            />
          </div>
        </div>
      );
    }

    if (changeBannerImg) {
      return (
        <div className="EditSchoolStyle_Backdrop">
          <div className="EditSchoolStyle_Modal">
            <UpdateSchoolImagesDialog
              description="Current banner image"
              closeDialog={this.closeChangeBannerImgDialog}
              image={schoolStyle.background_image}
              schoolStyle={schoolStyle}
              isBackgroundImage
            />
          </div>
        </div>
      );
    }

    const bannerStyle = {
      width: '200px',
    };

    let updatingStyle = false;
    if (updatingStyleStatus === 'Updating') {
      updatingStyle = true;
    } else if (updatingStyleStatus === 'Updating Error') {
      updatingStyle = false;
    } else if (updatingStyleStatus === '') {
      updatingStyle = false;
    }

    if (!schoolStyle) return null;
    return (
      <div className="EditSchoolStyle_Backdrop">
        <div className="EditSchoolStyle_Modal">
          <CloseButton size={15} onClick={closeDialog} />
          <div className="EditSchoolStyle_Container">
            <div className="EditSchoolStyle_Header">
              {schoolStyle.name}
            </div>
            <div className="EditSchoolStyle_Body">
              <div className="EditSchoolStyle_Images">
                <div className="EditSchoolStyle_SchoolLogo">
                  <button onClick={this.openChangeLogoDialog} >
                    {
                      schoolStyle.school_logo ?
                        <img src={schoolStyle.school_logo} alt="School Logo" />
                      :
                        <Icon iconName="photo2" className="EditSchoolStyle_ImageIcon" />
                    }
                  </button>
                </div>
                <div className="EditSchoolStyle_BannerImage">
                  <button onClick={this.openChangeBannerImgDialog}>
                    {
                      schoolStyle.background_image ?
                        <img src={schoolStyle.background_image} alt="Banner" style={bannerStyle} />
                      :
                        <Icon iconName="photo2" className="EditSchoolStyle_ImageIcon" />
                    }
                  </button>
                </div>
              </div>
              <div className="EditSchoolStyle_ImagesHelpText">
                Click on the image to change the school logo and banner image
              </div>
              <form ref={this.formRef} onSubmit={this.onClickSaveSchoolStyle} className="EditSchoolStyle_Form" >
                <div className="EditSchoolStyle_EditColor">
                  <div className="EditSchoolStyle_DefaultColor">
                    <div className="EditSchoolStyle_ColorLable">Color 1 (Default)</div>
                    <div className="EditSchoolStyle_Color">{colorOne}</div>
                  </div>
                  <div
                    className="EditSchoolStyle_ColorPickerIcon"
                    style={{ backgroundColor: colorOne }}
                  />
                </div>
                <div className="EditSchoolStyle_EditColor">
                  <TextControl
                    defaultValue={colorTwo}
                    className="EditSchoolStyle_ColorControl"
                    name="color2"
                    label="Color 2"
                    myOnChange={this.onValueChange('color2')}
                    myOnBlur={this.onValueChange('color2')}
                    errors={errors.any}
                    onClick={this.color2Clicked}
                  />
                  <div
                    className="EditSchoolStyle_ColorPickerIcon"
                    style={{ backgroundColor: colorTwo }}
                  />
                  { choseColor2 &&
                    <ColorPicker
                      color={
                        (this.formRef.current.color2 && this.formRef.current.color2.value) ||
                        schoolStyle.color2
                      }
                      choseColor={this.choseColor}
                      onCancel={this.closeColorPicker}
                      colorID={2}
                    />
                  }
                </div>
                <div className="EditSchoolStyle_Btns">
                  <ButtonRect
                    className="EditSchoolStyle_Btn"
                    style={{ marginRight: '20px' }}
                    type="button"
                    onClick={closeDialog}
                  >
                    Cancel
                  </ButtonRect>
                  <ButtonRect
                    className="EditSchoolStyle_Btn"
                    type="button"
                    onClick={this.onClickSaveSchoolStyle}
                  >
                    Save
                  </ButtonRect>
                </div>
              </form>
            </div>
            {
              updatingStyle ?
                <div className="EditSchoolStyle_LoadingUpdating">
                  <LoadingIndicator loading={updatingStyle} loadingMsg={updatingStyleStatus} />
                </div>
              :
                <div>
                  {
                    updateStyle403Error &&
                    <div className="EditSchoolStyle_AccessDenied">
                      <Icon iconName="alert" className="EditSchoolStyle_AccessDeniedIcon" />
                      Access Denied
                    </div>
                  }
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default EditSchoolStyle;
