import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Routes } from '../../../../store/actions/ui/routes';
import { SecondaryIconButton, SecondaryIconLink } from '../../../UxCommon';

class TopBar extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    onShareClick: PropTypes.func.isRequired,
  };

  getRoute = (page) => {
    const { match } = this.props;
    const { schoolId } = match.params;
    return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}${schoolId ? `/${schoolId}`: ''}`;
  };

  renderHoverlay = () => (
    <div className="UxProfile_Photos_TopBar_BtnsHoverlay" />
  )

  render() {
    const {
      isMyProfile,
      onShareClick,
      profile,
      signInToUpdate
    } = this.props;
    return (
      <div className="Data_TopBar">
        <div className="Data_TopBar_BtnLayer">
          <div className="UxProfile_Data_TopBar_Btns">
            <div className="d-flex" style={{ marginRight: 'auto' }}>
              <SecondaryIconLink className={"back-arrow-width"} iconName="back-arrow" to={this.getRoute(Routes.player)}>
                <span>To Profile</span>
                {/* <span className="hidden-md-down"> {isMyProfile ? 'Back to My Profile' : 'Back to Profile'}</span> */}
              </SecondaryIconLink>
              <SecondaryIconButton className="Photos_TopBar_ShareBtn" iconName="share" onClick={onShareClick}>
                Share
              </SecondaryIconButton>
              {
                !isMyProfile && profile && !!profile.registered && !!profile.isSubscribed &&
                <SecondaryIconButton className="Photos_TopBar_signInBtn mobile_button" iconName="login-1"
                                     onClick={signInToUpdate}>
                  Sign In
                </SecondaryIconButton>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
