import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import Urls from '../../../../config/urls';

export const registerCode = (token, props = {}) =>
  ajax.post(
    Urls.schools.postCode(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const checkEmailExistsApi = (email, token) => (
  ajax.getJSON(
    Urls.exists.checkEmail(email),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'accountPage.api.js';
