import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CombineControl from './CombineControl/CombineControl.component';
import CreateEditCombine from './CreateEditCombine/CreateEditCombine.container';
import { Routes } from '../../../../store/actions/ui/routes';
import { Roles } from '../../../../store/actions/data/user/profile/models';
import { Route, Switch } from 'react-router-dom';
import { recordNotFoundMsg } from '../../../../utils'

class Combines extends PureComponent {
  static propTypes = {
    combines: PropTypes.array.isRequired,
    currentSchoolId: PropTypes.string,
    schoolIds: PropTypes.array,
    getSchoolCombines: PropTypes.func,
    athleteCombineResults: PropTypes.array,
    getCombineTemplatesBySchool: PropTypes.func,
    templates: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    isCreateEditDialogVisible: PropTypes.bool,
    showCreateEditDialog: PropTypes.func,
    hideCreateEditDialog: PropTypes.func,
    isEditDialogVisible: PropTypes.bool,
    showEditDialog: PropTypes.func,
    hideEditDialog: PropTypes.func,
    shouldUpdate: PropTypes.bool,
    reports: PropTypes.any,
    getSchoolReports: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updateCombine: PropTypes.func,
    updateOrCreateCombineAndAwards: PropTypes.func
  };

  state = {
    prevSchoolId: 0,
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  combineId = null;
  schoolCombinesFetched = false;
  templatesFetched = false;

  handleClickTemplates = () => {
    const { history, match, orgAffSports,combineTemplate, coachId } = this.props;
    if (Object.keys(orgAffSports).length !== 1){
      history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/${Routes.selectSports}`);
    }
    else{
    const sportId = Object.keys(orgAffSports)[0];
    const isTemplateExists = combineTemplate.reverse().find(template => (template.sportId === Number(sportId)));
    if(!isTemplateExists){
      history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/new?sportId=${sportId}`);
    }
    else{
      history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/${isTemplateExists.id}`);
    }
  }
  };

  shouldUpdate = () => {
    const {
      schoolIds,
      currentSchoolId,
      getSchoolCombines,
      getSchoolReports,
      getCombineTemplatesBySchool,
      templates,
      shouldUpdate,
    } = this.props;
    const { prevSchoolId } = this.state;
    if (prevSchoolId !== currentSchoolId) {
      this.setState({
        prevSchoolId: currentSchoolId,
      });
      this.schoolCombinesFetched = false;
      this.templatesFetched = false;
    }

    if (schoolIds && schoolIds.length && !this.schoolCombinesFetched) {
      this.schoolCombinesFetched = true;
      getSchoolCombines(schoolIds);
      getSchoolReports(schoolIds);
    }

    if (!Object.keys(templates).length && schoolIds && schoolIds.length && !this.templatesFetched) {
      this.templatesFetched = true;
      getCombineTemplatesBySchool(schoolIds);
    }
  };

  showEditDialogInnenr = (combineId) => {
    this.combineId = combineId;
    this.props.showEditDialog(combineId);
  };

  createReportCardInfo = (reportList) => {
    const { combines } = this.props;
    const resultArray = [];
    if (combines && combines.length > 0) {
      reportList.forEach((item) => {
        const tempObject = {};
        const currCombine = combines.find(c => c.id === item.combineId);
        const nextCombine = combines.find(c => c.id === item.secondCombineId);
        if (currCombine && nextCombine) {
          tempObject.name = `${currCombine.name} + ${nextCombine.name}`;
          tempObject.openDate = moment(Math.max.apply(
            null,
            [currCombine.openDate, nextCombine.openDate],
          ));
          tempObject.endDate = moment(Math.max.apply(
            null,
            [currCombine.endDate, nextCombine.endDate],
          ));
          // tempObject.closeDate = moment(Math.max.apply(
          //   null,
          //   [currCombine.closeDate, nextCombine.closeDate],
          // ));
          tempObject.closeDate = moment(Math.max.apply(
            null,
            [
              moment(currCombine.closeDate).isValid() ? currCombine.closeDate : '',
              moment(nextCombine.closeDate).isValid() ? nextCombine.closeDate : '',
            ],
          ));
          tempObject.id = item.combineId;
          tempObject.reportId = item.id;
          tempObject.secondCombineId = item.secondCombineId;
          tempObject.schoolId = currCombine.schoolId;
          tempObject.type = 'report';
          resultArray.push(tempObject);
        }
      });
    }
    return resultArray;
  };

  render() {
    const {
      combines,
      reports,
      athleteCombineResults,
      hideCreateEditDialog,
      showCreateEditDialog,
      isCreateEditDialogVisible,
      hideEditDialog,
      isEditDialogVisible,
      history,
      match,
      profile,
      updateCombine,
      updateOrCreateCombineAndAwards,
    } = this.props;
    const userType = profile ? (profile.roleId) : null;
    const reportList = Object.entries(reports)
      .map(r => r[1])
      .flat();
    const reportsForCombine = this.createReportCardInfo(reportList);
    return (
      <div className="CoachDashboardCombines">
        <Switch>
          <Route
            exact
            path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.reports}`}
            component={ () => < CombineControl
              title="Reports"
              combineList={reportsForCombine}
              athleteCombineResults={athleteCombineResults}
              recordNotFoundMsg={ recordNotFoundMsg.reports }
              />
            }
          />
          <Route
            exact
            path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combines}`}
            component={ () => < CombineControl
              title="Combines"
              onClickNew={showCreateEditDialog}
              onClickUpdate={this.handleClickUpdate}
              onClickTemplates={this.handleClickTemplates}
              combineList={combines}
              athleteCombineResults={athleteCombineResults}
              showEditDialog={this.showEditDialogInnenr}
              updateCombine={updateCombine}
              recordNotFoundMsg={ recordNotFoundMsg.combines }
              updateOrCreateCombineAndAwards = {updateOrCreateCombineAndAwards}
              match = {match}
            />}

          />
        </Switch>
        {
          isCreateEditDialogVisible && (Roles.coach === userType) &&
          <CreateEditCombine
            onClickClose={hideCreateEditDialog}
            history={history}
            match={match}
          />
        }
        {
          isEditDialogVisible && (Roles.coach === userType) &&
          <CreateEditCombine
            combineId={this.combineId}
            onClickClose={hideEditDialog}
            isEditMode
            history={history}
            match={match}
          />
        }
      </div>
    );
  }
}

export default Combines;
