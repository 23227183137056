import {connect} from 'react-redux';
import Component from './HeadCoachModal.component';
import {createHeadCoach, deleteHeadCoach, updateHeadCoach} from "../../+store";

const mapStateToProps = (state, ownProps) => {
  const {headCoach, schoolTeamId} = ownProps;
  return {
    headCoach,
    schoolTeamId
  }
}

const mapDispatchToProps = dispatch => ({
  updateHeadCoach: (id, values) => {
    dispatch(updateHeadCoach(id, {
      name: values.name,
      phone: values.phone,
      email: values.email,
      title: values.title ? values.title : 'Head Coach',
      // team_twitter_link: values.teamTwitterLink,
    }));
  },
  deleteHeadCoach: (id, schoolTeamId) => {
    dispatch(deleteHeadCoach(id, schoolTeamId));
  },
  createHeadCoach: (values) => {
    dispatch(createHeadCoach({
      name: values.name,
      phone: values.phone,
      email: values.email,
      title: values.title ? values.title : 'Head Coach',
      // team_twitter_link: values.teamTwitterLink,
      school_team_id: values.schoolTeamId
    }));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
