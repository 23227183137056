import { asyncStartAction } from '../../async';

export const AWARDS_FORM_INIT = 'awards.formInit';
export const initAwardsForm = () => ({
  type: AWARDS_FORM_INIT,
});

export const CREATE_AWARD = 'awards.create';
export const UPDATE_AWARD = 'awards.update';

export const GET_AWARDS = 'awards.getAll';
export const getAllAwards = uuid => (dispatch) => {
  dispatch(asyncStartAction(GET_AWARDS));
  dispatch({
    type: GET_AWARDS,
    uuid,
  });
};

export const SET_AWARDS_NEEDS_LOAD = 'awards.setNeedsLoad';
export const setAwardsNeedsLoad = () => ({
  type: SET_AWARDS_NEEDS_LOAD,
});

export const DELETE_AWARD = 'awards.delete';
export const deleteAward = awardId => (dispatch) => {
  dispatch(asyncStartAction(DELETE_AWARD));
  dispatch({
    type: DELETE_AWARD,
    awardId,
  });
};

export const HIDE_AWARD = 'awards.hide';
export const hideAward = awardId => (dispatch) => {
  dispatch(asyncStartAction(HIDE_AWARD));
  dispatch({
    type: HIDE_AWARD,
    awardId,
  });
};

export const FAVORITE_AWARD = 'awards.favorite';
export const favoriteAward = (award, sportIds) => (dispatch) => {
  dispatch(asyncStartAction(FAVORITE_AWARD));
  dispatch({
    type: FAVORITE_AWARD,
    award,
    sportIds,
  });
};

export const ENDORSE_AWARD = 'awards.endorse';
export const endorseAward = (award, isEndorsing) => (dispatch) => {
  dispatch(asyncStartAction(ENDORSE_AWARD));
  dispatch({
    type: ENDORSE_AWARD,
    award,
    isEndorsing,
  });
};

export const GET_FAVORITE_AWARDS = 'awards.getFavorites';
export const getFavoriteAwards = (uuid, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_FAVORITE_AWARDS));
  dispatch({
    type: GET_FAVORITE_AWARDS,
    uuid,
    sportId
  });
};

export const AwardTypes = {
  academic: {
    type: 'item',
    name: 'Academic',
    value: 'academic',
  },
  athletic: {
    type: 'item',
    name: 'Athletic',
    value: 'athletic',
  },
  personal: {
    type: 'item',
    name: 'Personal',
    value: 'personal',
  },
};

export const CombineAwardType = {
  combine: {
    type: 'item',
    name: 'Combine',
    value: 'combine',
  },
};

