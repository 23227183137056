import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  type, className, children, ...btnProps
}) => (
  <button type={type} className={`OnboardingButton ${className}`} {...btnProps}>
    {children}
  </button>
);
Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default Button;
