import coachAward from '../../../../assets/images/CoachAward.png';
// import personalAward from '../../../../assets/images/PersonalAward.png';
import PersonalAward from '../../../../assets/images/PersonalAward_1.png';
import AthleticAward from '../../../../assets/images/AtheleticAward_1.png';
import AcademicAward from '../../../../assets/images/AcademicAward_3.png';
import HitRaxLogo from '../../../../assets/images/Hotrax_award_logo.png';
import DiamondKineticsLogo from '../../../../assets/images/DM_award_logo.png';
import RapsodoLogo from '../,,/../../../../assets/images/repsodo_award_logo.png';
import TmLogo from '../../../../assets/images/TM_award_logo.png';
import ProPlayAiAwardLogo from '../../../../assets/images/Pro_play_ai_award_logo.png';
import PersonalAwardBigLogo from '../../../../assets/images/Personal_award_2.png';
import AthleticAwardBigLogo from '../../../../assets/images/Athletic_award_big_logo.png';
import AcademicAwardBigLogo from '../../../../assets/images/Academic_award_2.png';
import BlastMotionAwardLogo from '../../../../assets/images/blast_motion_award_logo.png';
import HitRaxLargeLogo from '../../../../assets/images/Hitrax_large_logo.png';
import TrackManLargeLogo from '../../../../assets/images/Trackman_large_logo.png';
import RasodoLarageLogo from '../../../../assets/images/Rapsodo_large_logo.png';
import BlastLargeLogo from '../../../../assets/images/blast_motion_award_logo.png';
import DiamondLargeLogo from '../../../../assets/images/DK_large_logo.png';
import ProPlayAiLargeLogo from '../../../../assets/images/Pro_paly_ai_large_logo.png';


export const getAwardLogo = ( schoolAwardLogo = {} , award, isCoach = false, athleteAwardType = '', isNeedToDisPlayBiggerLogo = false) => {
  const isSchoolAwardFound =  !!Object.keys(schoolAwardLogo).length;
  let awardType;
  let techPartner;
  if (award) {
    techPartner = award && award.tech_partner ? award.tech_partner : award.techPartner;
    awardType = award && award.awardType ? award.awardType : award.award_type ; 
  }
  const getAthleteAwards = (athleteAwardType) => {
    let award;
    switch(athleteAwardType) {
      case 'academic':
        award = isNeedToDisPlayBiggerLogo ? AcademicAwardBigLogo : AcademicAward
        break;
      case 'athletic':
        award = isNeedToDisPlayBiggerLogo ? AthleticAwardBigLogo : AthleticAward
        break;
      case 'personal':
        award = isNeedToDisPlayBiggerLogo ? PersonalAwardBigLogo : PersonalAward
        break;
      default:
        award = isNeedToDisPlayBiggerLogo ? PersonalAwardBigLogo : PersonalAward
    }
    return award;
  }

  const getTechPartnerLogo = (award) => {
    let awardLogo;
    switch(techPartner) {
      case "VIRTUALCOMBINE":
        awardLogo = coachAward;
        break;
      case "DIAMONDKINETICS":
        awardLogo = !isNeedToDisPlayBiggerLogo ? DiamondLargeLogo : DiamondKineticsLogo;
        break
      case "RAPSODO":
        awardLogo = !isNeedToDisPlayBiggerLogo ? RasodoLarageLogo :RapsodoLogo;
        break
      case "HITTRAX":
        awardLogo = !isNeedToDisPlayBiggerLogo ?  HitRaxLargeLogo : HitRaxLogo;
        break; 
      case "TRACKMAN":
        awardLogo = !isNeedToDisPlayBiggerLogo ? TrackManLargeLogo : TmLogo;
        break
      case "PPAIPITCHING":
        awardLogo = !isNeedToDisPlayBiggerLogo ?  ProPlayAiLargeLogo : ProPlayAiAwardLogo;
        break;
      case "BASEBALLHITTINGBLAST":
        awardLogo = !isNeedToDisPlayBiggerLogo ?  BlastLargeLogo : BlastMotionAwardLogo;
        break;
      default:
        awardLogo = '';
    }
    return awardLogo
  }

  if ( !award || ['personal', 'athletic','academic'].includes(athleteAwardType) )
  {
   return getAthleteAwards(athleteAwardType)
  }
  switch ( awardType ){
    case 'combine':
     return getTechPartnerLogo(award);
    default:
      return getAthleteAwards(award.awardType);
  }

 
}

// const getTechPartnerIcon = (award) => {

// }


