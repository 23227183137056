import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import { validateJersey, validateMaxLength, validateRequired } from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';
import { updateAthleteTeamInfo } from '../../../../modules/CoachWorld/+store/roster';
import { mergeSignIn } from '../../data/user/profile';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const acceptInvitationFormValidation = {
  'forms.acceptInvitation.positions': [
    value => validateRequired()(value),
  ],
  'forms.acceptInvitation.jerseyNumbers': [
    value => validateRequired()(value),
    value => validateMaxLength(10)(value),
    value => validateJersey()(value),
  ],
};

const acceptInvitationSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.acceptInvitation;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.acceptInvitation', 'acceptInvitation'),
    mergeMap((action) => {
      if (action.attrs.authUuid !== action.attrs.uuid) {
        return from(mergeSignIn({
          existing_uuid: action.attrs.authUuid,
          token: action.attrs.token,
          invite_uuid: action.attrs.uuid,
          user_team_id: action.attrs.userTeamId,
        })).pipe(
          map(response => ({
            success: true,
            uuid: action.attrs.authUuid,
            ...action,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.acceptInvitation', 'acceptError', error, {}),
          })),
        );
      }
      return of({
        success: true,
        uuid: action.attrs.authUuid,
        ...action,
      });
    }),
    mergeMap(({
      attrs,
      model,
      success,
      uuid,
      action,
    }) => {
      if (success) {
        attrs.dispatch(updateAthleteTeamInfo(
          attrs.userTeamId,
          uuid,
          {
            position: form().positions,
            jersey_number: form().jerseyNumbers,
            user_team_id: attrs.userTeamId,
            token: null,
            invited: 2,
          }, null, null, attrs.from,
        ));
        return of(formSubmitSuccess(model, {
          from: attrs.from,
          uuid,
        }));
      }
      return of(action);
    }),
  );
};

export const acceptInvitationFormEpic = combineEpics(
  acceptInvitationSubmitEpic,
  validationOnSubmitEpicFactory(acceptInvitationFormValidation, 'forms.acceptInvitation', 'acceptInvitation'),
  validationFailedEpicFactory('forms.acceptInvitation', 'acceptInvitation'),
  ...formValidationEpicsFactory(acceptInvitationFormValidation, 'acceptInvitation'),
);

export const ACCEPT_INVITATION_FORM_INIT = 'ACCEPT_INVITATION_FORM_INIT';
export const acceptInvitationFormInit = () => ({
  type: ACCEPT_INVITATION_FORM_INIT,
});

