import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import FilterSidebar from '../../common/FiltersSidebar';
import LoadingIndicator from '../../common/LoadingIndicator';
import NavBarLink from '../../common/NavBarLink';
import NavBar from '../../common/NavBar';
import { BankSlugs, BankLabels, BankSlugToApi, BankSlugToLabel } from '../../+store/combine';
import SidebarLinkFilter from '../../common/SidebarLinkFilter';
import SidebarMultiSelectCallbackFilter from '../../common/SidebarMultiSelectCallbackFilter';
import { AthleteGrades, AthleteGradesTh, RosterSortByLabels } from '../../+store';
import { positions, gender } from '../../../../components/UxCommon/Form/utils';
import { Routes } from '../../../../store/actions/ui/routes';
import GrayTable from '../GrayTable/GrayTable.component';
import Icon from '../../common/Icon';
// import { toProperCase } from '../../../CodeSignUp/Common/utils/utils';
import SelectControl from '../../common/FormControls/SelectControl';
import PrintExperiment from '../PrintExperiment/PrintExperiment.component';
import Banner from '../../common/Banner';
import CompareBanner from '../Banners/CompareBanner.component';
import { displayCategoryImprovement } from '../Common/utils';
import {formatPositionResponse} from '../../../../store/actions/ui/formUtils/validators';
const resultNumObject = {
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
};

const getPropFromPath = (path, obj) => {
  let sub = obj;
  const parts = path.split('.');
  parts.reverse();
  while (parts.length) {
    const part = parts.pop();
    sub = sub[part];
  }
  return sub;
};

const getSidebarFilters = ({
                             schoolTeamsList, sportId, gradYears
                           }) => {
  const linkFilters = [];
  let queryStringFilters = [
    {
      name: 'teamId',
      label: 'Teams',
      items: schoolTeamsList,
    },
    {
      name: 'position',
      label: 'Positions',
      items: positions(sportId).map(({ value, name }) => ({
        name,
        value,
      })),
    },
    {
      name: 'gender',
      label: 'Genders',
      items: gender().map(({ text, value }) => ({ name: text, value })),
    },
  ];

  if (gradYears.length) {
    const gradYearFilter = {
      name: 'gradYear',
      label: 'Grad Year',
      items: gradYears.map((value) => ({
        name: value,
        value,
      })),
    };

    queryStringFilters.splice(2, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters,
  };
};

class RawComparisonPage extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    reportId: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    athletes: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    schoolStyle: PropTypes.object,
    report: PropTypes.object,
    getReportResults: PropTypes.func.isRequired,
    columns: PropTypes.array,
    banks: PropTypes.array,
    teams: PropTypes.array,
    onSortByChange: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    gradYears: PropTypes.array.isRequired,
  };

  state = {
    numShowElements: 10,
    numCurrentShowElements: 10,
    showMobileFilters: false,
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onPrintClick = () => {
    console.log('Print');
  };

  shouldUpdate = () => {
    const {
      report,
      reportId,
      getReportResults,
    } = this.props;
    if (report && this.reportResultsFetched !== reportId) {
      this.reportResultsFetched = reportId;
      getReportResults(reportId);
    }
  }

  handleUpdateShowElements = (num) => {
    this.setState({ numShowElements: Number(num) });
    this.setState({ numCurrentShowElements: Number(num) });
  };

  handleShowMoreClick = () => {
    const { numShowElements, numCurrentShowElements } = this.state;
    this.setState({ numCurrentShowElements: numCurrentShowElements + numShowElements });
  };

  selectedOption = 'measurables';
  reportResultsFetched = false;

  handleSortByChange = (value) => {
    const { location, history, onSortByChange } = this.props;
    onSortByChange(location, history, value);
  }

  redirectToBank = () => {
    const { banks, match } = this.props;
    const { reportId } = match.params;
    return <Redirect
      to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.compare}/${BankSlugs[banks[0].toUpperCase()]}`}/>;
  }

  renderPrintBanner = () => {
    const { report, schoolStyle } = this.props;
    return (
      <Banner schoolStyle={schoolStyle} hideButtons>
        <div className="CoachRoster_BannerChildren">
          <div className="CoachRoster_BannerLeft">
            <CompareBanner report={report}/>
          </div>
        </div>
      </Banner>
    );
  }

  renderTable = ({ match }) => {
    const {
      columns, athletes, teams, schoolStyle,report
    } = this.props;
    const { numCurrentShowElements } = this.state;
    const { reportId, schoolId, bank } = match.params;
    const bankColumns = columns.filter(c => c.bank === BankSlugToApi[bank]);
    // console.log('asdf', teams);
    // console.log('vcscore', bank);
    const rows = [];
    const widths = ['40%', ...bankColumns.map(() => `${60 / bankColumns.length}%`)];
    teams.forEach((team) => {
      const teamAthletes = athletes.filter(a => a.teamId === team.team_uuid);
      if (teamAthletes.length) {
        rows.push([
          // Team data cell
          <Link
            className="RawComparisonPage_TeamCell"
            to={`/${Routes.school}/${schoolId}/${Routes.report}/${reportId}/${Routes.team}/${team.team_uuid}`}
          >
            <div className="RawComparisonPage_TeamCellLogo"
                 style={{ backgroundImage: `url(${team.schoolStyle.school_logo})` }}/>
            <div className="RawComparisonPage_TeamCellInfoRow">
              <div className="RawComparisonPage_TeamCellInfo">{`${team.name || team.team_reference.team} Report`}</div>
            </div>
          </Link>,
          ...bankColumns.map((bc) => {
            const cat = getPropFromPath(bc.path, team);
            return !!cat && displayCategoryImprovement(
              cat.improvement_percent,
              cat.standard_test_category_best,
              bank === 'vcscore' ? true : cat.standard_test_category_comparable,
              'PERCENTAGE-HUNDREDTHS',
            );
          }),
        ]);
      }
      teamAthletes.forEach((ath) => {
        const athletePositionJersey = [ath.school_team.position, ath.school_team.jersey_number && ath.school_team.jersey_number.trim() ? `#${ath.school_team.jersey_number.trim()}` : null].filter(n => !!n).join(' | ');
        rows.push([
          // Player data cell
          <Link
            className="RawComparisonPage_AthleteCell"
            to={`/${Routes.school}/${schoolId}/${Routes.report}/${reportId}/${Routes.team}/${team.team_uuid}/${Routes.athlete}/${ath.athlete_uuid}`}
          >
            {
              ath.headshot_url ? (
                <div className="RawComparisonPage_AthleteHeadshot"
                     style={{ backgroundImage: `url(${ath.headshot_url})` }}/>
              ) : (
                <Icon className="RawComparisonPage_AthleteCellIcon" iconName="photo2"/>
              )
            }
            <div className="RawComparisonPage_AthleteCellInfoRow d-block">
              <div
                className="RawComparisonPage_AthleteCellName">{ath.school_team.given_name + ' ' + ath.school_team.family_name}</div>
                {/* <div className="RawComparisonPage_AthleteCellInfo">{AthleteGradesTh[ath.school_team.grade]}</div> */}
              <div className="RawComparisonPage_AthleteCellInfo">{ath.gradYear}</div>
              <div
                className="RawComparisonPage_AthleteCellInfo">{formatPositionResponse(athletePositionJersey) || '-'}</div>
            </div>
          </Link>,
          ...bankColumns.map((bc) => {
            const cat = getPropFromPath(bc.path, ath);

            return !!cat && displayCategoryImprovement(
              cat.improvement_percent,
              cat.standard_test_category_best,
              bank === 'vcscore' ? true : cat.standard_test_category_comparable,
              'PERCENTAGE-HUNDREDTHS',
            );
          }),
        ]);
      });
    });
    const data = rows.slice(0, numCurrentShowElements);
    let displayShowMore = false;
    if (rows.length > numCurrentShowElements) {
      displayShowMore = true;
    }
    return (
      <div className="CombineReportPage">
        <GrayTable
          widths={widths}
          headers={[
             { name: BankSlugToLabel[bank]},
            ...bankColumns.map(bc => ( {name: bc.standard_test_category_name, code: bc.standard_test_category_code }) ),
          ]}
          data={data}
          handleShowMore={displayShowMore ? this.handleShowMoreClick : null}
        />
        <PrintExperiment schoolStyle={schoolStyle} report={report}>
          {/* {this.renderPrintBanner()} */}
          <GrayTable
            widths={widths}
            headers={[
              { name: BankSlugToLabel[bank]},
              ...bankColumns.map(bc => ( {name: bc.standard_test_category_name, code: bc.standard_test_category_code })),
            ]}
            data={rows}
            handleShowMore={null}
            print
          />
        </PrintExperiment>
      </div>
    );
  }

  render() {
    const {
      loading,
      loadingMsg,
      reportId,
      match,
      history,
      location,
      report,
      columns,
      banks,
      sortBy,
      gradYears,
    } = this.props;


    const { numShowElements } = this.state;
    let sportId = 0;
    let schoolTeamsList = [];

    if (report) {
      sportId = report.sport.id;
      schoolTeamsList = report
        .teams
        .sort((a, b) => a.team_reference.orderby - b.team_reference.orderby).map(t => ({
          name: t.name || t.team_reference.team,
          value: t.id,
        }));
    }
    const { linkFilters, queryStringFilters } = getSidebarFilters({
      schoolTeamsList, sportId, gradYears
    });
    return (
      <div className="CombineReport_BodyWrapper">
        <NavBar>
          <div className="CoachNavBar_Left">
            <NavBarLink
              key="backButton"
              to={`/${Routes.coach}/${Routes.dashboard}/${Routes.combines}`}
              match={match}
            >
              <Icon iconName="chevron-left"/>
            </NavBarLink>
            {
              banks && banks.map((bank, index) => (
                <NavBarLink
                  key={index}
                  to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.compare}/${BankSlugs[bank.toUpperCase()]}`}
                  location={location}
                  match={match}
                >
                  {BankLabels[bank.toUpperCase()]}
                </NavBarLink>
              ))
            }
            {
              report && (
                <NavBarLink
                  to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.compare}/${BankSlugs.VC_SCORE}`}
                  location={location}
                  match={match}
                >
                  VC Score
                </NavBarLink>
              )
            }
          </div>
          {
            report && (
              <div className="CoachNavBar_Right" style={{ alignItems: 'center' }}>
                <SelectControl
                  name="result"
                  label="Results"
                  options={resultNumObject}
                  myOnChange={this.handleUpdateShowElements}
                  defaultValue={numShowElements}
                  style={{ margin: 0, marginRight: 5, minWidth: 120 }}
                />
                <SelectControl
                  placeholder="Sort By"
                  options={RosterSortByLabels}
                  name="sortBy"
                  myOnChange={this.handleSortByChange}
                  defaultValue=""
                  value={sortBy}
                  style={{ margin: 0, marginRight: 20, minWidth: 150 }}
                />
              </div>
            )
          }
        </NavBar>
        <div className="CombineReport_Body">
          <LoadingIndicator loading={loading} loadingMsg={loadingMsg} wrapperStyle={{ top: 120 }}/>
          <button className="CoachWorld_FilterIcon" onClick={() => this.setState({ showMobileFilters: true })}>
            <Icon iconName="filter"/>
          </button>
          <FilterSidebar
            displayNone={!!loading}
            showMobileFilters={v => this.setState({ showMobileFilters: v })}
            defaultIsHidden={!this.state.showMobileFilters}
            screenSize={576}
          >
            {
              linkFilters.map(({ name, label, items }) => (
                <SidebarLinkFilter
                  key={name}
                  filterName={name}
                  label={label}
                  filters={items}
                  match={match}
                />
              ))
            }
            {
              queryStringFilters.map(({ name, label, items }) => (
                <SidebarMultiSelectCallbackFilter
                  key={name}
                  filterName={name}
                  label={label}
                  filters={items}
                  history={history}
                  location={location}
                />
              ))
            }
          </FilterSidebar>
          {
            report && columns && banks && (
              <Switch>
                <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.compare}/:bank`}
                       render={this.renderTable}/>
                <Route exact path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.compare}`}
                       render={this.redirectToBank}/>
              </Switch>
            )
          }
        </div>
      </div>
    );
  }
}

export default RawComparisonPage;
