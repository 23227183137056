export const parserLower = val => val.toLowerCase();
export const parserJerseyNumbers = val => (!val ? val : val.replace(/[^\w\s-'"]/g, ''));
export const parserOnlyLetters = val => val.replace(/[^a-zA-Z ']+/g, '');
export const parserAlphaLettersWithRmSpecialChar = val => val.replace(/[^a-zA-Z0-9 -_"']+/g, '');
export const parserName = val => val.replace(/[^a-zA-Z ']+/g, '');
export const parseAddress = val => val.replace(/[^a-zA-Z0-9\-.,()"*@:;_&\n ']+/g, '');
export const parserTelephone = val => (!val ? val : val.replace(/[^0-9-()+ ]+/g, ''));
export const parserOnlyNumbers = val => (!val ? val : val.replace(/[^0-9]+/g, ''));
export const parserOnlyNumeric = val => (!val ? val : val.replace(/[^\d.]/g, ''));
export const parserOnlyNegativeNumeric = val => (!val ? val : val.replace(/[^-\d.]/g, ''));
export const parserDate = val => val.replace(/[^0-9/]+/g, '');
export const parserSlug = val => val.replace(/[^a-zA-Z0-9\-_+]+/g, '');
export const parserPassword = val => (!val ? val : val.replace(/\s/g, ''));
export const parserLocation = val => (val && val.length < 40 ? val : val.substr(0,40));

export const parserNumberAndCharcater = val =>  val.replace(/[^\w\s\][,]/gi, '');

export const parserInstagramHandleSlug = val =>   `@${val.replace(/[^a-zA-Z0-9\.+_-]+/g, '')}`;

export const parseGPA = (val) => {
  const num = (!val ? val : val.replace(/[^\d.]/g, ''));
  if (num) {
    const twoDecimalNum = parseFloat(num).toFixed(2);
    return twoDecimalNum.replace(/0$/, '');
  }
  return num;
};

export const parserTwitterHandleSlug = val => `@${val.replace(/[^a-zA-Z0-9\+_-]+/g, '')}`;

export const parserMaxChar = maxChar => val =>
  (val && ((val.length > maxChar) ? val.slice(0, maxChar) : val));
export const testIntegerChars = v => /^[0-9]$/.test(v);
export const testFloatChars = v => /^[0-9.-]$/.test(v);
export const testPositiveFloatChars = v => /^[0-9.]$/.test(v);
export const testTimeChars = v => /^[0-9:.]$/.test(v);
export const testDistanceChars = v => /^[0-9'". ]$/.test(v);


export const parserAboutMeChange = (maxChar, maxChar2) => (val) => {
  if (val) {
    if ((/^@/).test(val)) {
      return `@${val.replace(/[^a-zA-Z0-9_]+/g, '')
        .slice(0, maxChar)}`;
    }
    return val.slice(0, maxChar2);
  }
  return val;
};

export const parserMax100Value = val => {
  if (/[0-9]/.test(val)) {
    return  (val > 100) ? 100 : val
  }
};

export const parserMax1Value = val => {
  if (/[0-9]/.test(val)) {
    return  (val > 1) ? 1 : val
  }
};

export const parserMax5Value = val => {
  if (/[0-9]/.test(val)) {
    return  (val > 5) ? 5 : val
  }
};

export const negativeParserMax100Value = val => {
  return  (val > 100) ? 100
          : (val < -100) ? -100
          : val
};


export const defaultHourMinuteParser =  value => `${Math.floor(value / 60)}:${Math.floor(value % 60) < 10 ? '0' : ''}${Math.floor(value % 60)}`;


export const parseTime = (v) => {
  let results = /^([0-9]+):([0-9]+)(\.[0-9]+)$/.exec(v);
  if (results) {
    const [, min, sec, tens] = results;
    const minutes = Number(min);
    const seconds = Number(sec);
    const tenths = Number(tens);
    return (minutes * 60) + seconds + tenths;
  }
  results = /^([0-9]+):([0-9]+\.?)$/.exec(v);
  if (results) {
    const [, min, sec] = results;
    const minutes = Number(min);
    const seconds = Number(sec);
    return (minutes * 60) + seconds;
  }
  return null;
};

export const parserOnlyYear = val => {
  let finalVal  = (!val ? val : val.replace(/[^0-9]+/g, ''));
  if(finalVal > 4) {
    finalVal =  finalVal.substr(0, 4);
  }
  return finalVal;
}
