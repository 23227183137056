import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Routes, awardsDialogPath, awardsDialogTo } from '../../../store/actions/ui/routes';
import TopBar from './TopBar/TopBar';
import { ButtonRect, LoadingSpinner, FavoritesDialog } from '../../UxCommon';
import CreateUpdateDialog from '../../../containers/UxProfile/Awards/CreateUpdateDialog';
import AwardCard from './AwardCard/AwardCard';
import PageHeader from '../../../containers/UxProfile/PageHeader';

class Awards extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    onHideClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    authorizedUuid: PropTypes.string.isRequired,
    awards: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    onSaveFavorites: PropTypes.func.isRequired,
    isFavorite: PropTypes.func.isRequired,
    mySports: PropTypes.array.isRequired,
    getAwardFavorites: PropTypes.func.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onEndorseAwardClick: PropTypes.func.isRequired,
    getEndorsementCount: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    isCoach: PropTypes.bool,
    schoolAwardLogo: PropTypes.object
  }

  state = {
    favoriteDialogEditing: null,
  }

  componentDidMount() {
  }

  onAddClick = () => {
    const { history, match } = this.props;
    history.push(awardsDialogTo(match.params.uuid, 'new', match.params.sport, match.params.schoolId));
  }

  onFavoriteClick = (award) => {
    const { mySports, onSaveFavorites } = this.props;
    if (mySports.length === 1) {
      onSaveFavorites(award, { [mySports[0].id]: true });
    }
    this.setState({
      favoriteDialogEditing: award,
    });
  }

  onCloseFavoritesDialog = () => {
    this.setState({
      favoriteDialogEditing: null,
    });
  }

  onSaveFavorites = (award, favoriteSports) => {
    const { onSaveFavorites } = this.props;
    this.setState({
      favoriteDialogEditing: null,
    });
    onSaveFavorites(award, favoriteSports);
  }

  getRoute = (page) => {
    const { match } = this.props;
    return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}`;
  };

  render() {
    const {
      match,
      onHideClick,
      isMyProfile,
      awards,
      history,
      isFavorite,
      mySports,
      getAwardFavorites,
      loading,
      loadingMsg,
      onEndorseAwardClick,
      getEndorsementCount,
      isAuthorized,
      authorizedUuid,
      onShareClick,
      isCoach,
      schoolAwardLogo,
      profile
    } = this.props;
    const userSchool = profile.schools;
    const { favoriteDialogEditing } = this.state;
    const combineAwards = awards.filter(a => a.type === 'combine');
    const personalAwards = awards.filter(a => a.type !== 'combine');
    return (
      <div className="Awards">
        <Route path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`} component={PageHeader} />
        <div style={{ position: 'relative' }}>
          <TopBar
            match={match}
            profile={profile}
            onHideClick={onHideClick}
            isMyProfile={isMyProfile}
            history={history}
            onShareClick={onShareClick}
          />
          {
            favoriteDialogEditing && (
              <FavoritesDialog
                award={favoriteDialogEditing}
                favorites={getAwardFavorites(favoriteDialogEditing)}
                mySports={mySports}
                onSave={this.onSaveFavorites}
                onCancel={this.onCloseFavoritesDialog}
              />
            )
          }
          <Route
            path={awardsDialogPath}
            component={CreateUpdateDialog}
          />
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
            style={{ paddingTop: '120px' }}
          />
          {
            (awards.length > 0) ? (
              <div>
                <div className="Awards_AwardsList">
                  {
                    combineAwards.map((award) => {
                      const { count, blocked } = getEndorsementCount(award);
                      return (isMyProfile || !!award.display) && (
                        <AwardCard
                          key={award.id}
                          award={award}
                          match={match}
                          isMyProfile={isMyProfile}
                          isAuthorized={isAuthorized}
                          authorizedUuid={authorizedUuid}
                          onFavoriteClick={this.onFavoriteClick}
                          isFavorite={isFavorite(award)}
                          onEndorseClick={onEndorseAwardClick}
                          endorsementCount={count}
                          endorsementDisabled={blocked}
                          schoolAwardLogo={schoolAwardLogo}
                          userSchool = {userSchool}
                        />
                      );
                    })
                  }
                </div>
                <div className="Awards_Title"> Awards </div>
                <div className="Awards_AwardsList">
                  {
                    personalAwards.map((award) => {
                      const { count, blocked } = getEndorsementCount(award);
                      return (isMyProfile || isCoach || !!award.display) && (
                        <AwardCard
                          key={award.id}
                          award={award}
                          match={match}
                          isMyProfile={isMyProfile || isCoach}
                          isAuthorized={isAuthorized}
                          authorizedUuid={authorizedUuid}
                          onFavoriteClick={this.onFavoriteClick}
                          isFavorite={isFavorite(award)}
                          onEndorseClick={onEndorseAwardClick}
                          endorsementCount={count}
                          endorsementDisabled={blocked}
                          schoolAwardLogo={schoolAwardLogo}
                          userSchool = {userSchool}
                        />
                      );
                    })
                  }
                </div>
              </div>
            ) : (
              <div className="Awards_NoAwards">
                <div className="Awards_NoAwardsMsg">
                  {`No awards have been added to ${(isMyProfile || isCoach) ? 'your' : 'this'} profile`}
                </div>
                {
                  (isMyProfile || isCoach) && (
                    <ButtonRect
                      className="Awards_NoAwardsBtn primary"
                      onClick={this.onAddClick}
                    >
                      Create a Personal Award
                    </ButtonRect>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Awards;
