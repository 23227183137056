import { combineReducers } from 'redux';
import { UPDATE_ATHLETE_VIDEOID } from '../../../actions/ui/uxProfile/photosVideos';

import { CHECK_IF_CAN_EDIT, HIDE_FILTERS } from '../../../actions/ui/app';
import { ASYNC_FINISH, ASYNC_START } from '../../../actions/ui/async';

const canEditProfile = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_IF_CAN_EDIT) {
        return action.data;
      }
      if(action.model === UPDATE_ATHLETE_VIDEOID){
        return null;
        }
      return state;
    case ASYNC_START:
      if (action.model === CHECK_IF_CAN_EDIT) {
        return null;
      }
      return state;
    default:
      return state;
  }
};


const hideFilters = (state = false, action) => {
  switch (action.type) {
    case HIDE_FILTERS:
      return action.hide;
    default:
      return state;
  }
};

const reducer = combineReducers({
  canEditProfile,
  hideFilters,
});

export default reducer;
