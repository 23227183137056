import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import vcLogo from '../../../../assets/images/vc-black.png';
import bgImageUrl from '../../../../assets/images/bannerImage.png';
import { Routes } from '../../../../store/actions/ui/routes';
import EditSchoolStyle from '../EditSchoolStyle';
import { LinkButton } from '../../../../components/UxCommon';
import cssVar from '../../../../components/App/App.utils';

class Banner extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    authenticatedUuid: PropTypes.string,
    schoolStyle: PropTypes.object,
    profile: PropTypes.any,
    coachSchoolIds: PropTypes.any,
    onDashboard: PropTypes.bool,
    onSignOutClick: PropTypes.func,
    hideButtons: PropTypes.bool,
    resetUpdatingStyleStatus: PropTypes.func,
  };

  state = {
    editSchoolStyleOpen: false,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onClickButton = () => { }

  getBackgroundImage = () => {
    const { schoolStyle } = this.props;
    if (schoolStyle === undefined) {
      return bgImageUrl;
    } else if (schoolStyle.background_image !== null) {
      return schoolStyle.background_image;
    }
    return bgImageUrl;
  }

  canEditStyle = false;

  openEditSchoolStyleDialog = () => {
    const { profile } = this.props;
      if (this.canEditStyle && (profile.status === 'profile') && (profile.isCoach)) {
        this.setState({
          editSchoolStyleOpen: true,
        });
      }
  }

  closeEditSchoolStyleDialog = () => {
    const { resetUpdatingStyleStatus } = this.props;
    if (this.canEditStyle) {
      this.setState({
        editSchoolStyleOpen: false,
      });
      if (resetUpdatingStyleStatus) {
        resetUpdatingStyleStatus();
      }
    }
  }

  shouldUpdate = () => {
    const { schoolStyle } = this.props;
    if (schoolStyle) {
      const primary = schoolStyle.color1;
      const secondary = schoolStyle.color2;
      cssVar('primary', primary);
      cssVar('secondary', secondary);
    }
  }

  render() {
    const {
      children,
      authenticatedUuid,
      profile,
      schoolStyle,
      onDashboard,
      coachSchoolIds,
      onSignOutClick,
      hideButtons,
    } = this.props;
    const backgroundImg = this.getBackgroundImage();
    let found = false;
    if (onDashboard) {
      this.canEditStyle = true;
    } else {
      if (coachSchoolIds && coachSchoolIds.length > 0) {
        found = coachSchoolIds.find(s => s === schoolStyle.id);
      }
      if (found) {
        this.canEditStyle = true;
      }
    }

    const { editSchoolStyleOpen } = this.state;
    let dashboardLink;
    let dashboardText;
    let accountLink;
    if ((profile.status === 'profile') && (profile.isCoach)) {
      dashboardLink = `/${Routes.coach}/${Routes.dashboard}`;
      accountLink = `/${Routes.coach}/${Routes.account}`;
      dashboardText = 'Dashboard';
    } else if ((profile.status === 'profile') && (!profile.isCoach)) {
      dashboardLink = `/${Routes.profile}/${authenticatedUuid}`;
      accountLink = `/${Routes.profile}/${authenticatedUuid}/${Routes.edit}`;
      dashboardText = 'Dashboard';
    }
    return (
      <div className="CoachBanner">
        {
          backgroundImg && (
            <div
              className="CoachBanner_Background"
              style={{
                backgroundImage: `url(${backgroundImg})`,
              }}
            />
          )
        }
        {
          editSchoolStyleOpen && (
            <EditSchoolStyle
              schoolStyle={schoolStyle}
              closeDialog={this.closeEditSchoolStyleDialog}
            />
          )
        }
         <div className="CoachBanner_UpperRight">
          <div className="CoachBanner_DashAboutLink" >
            {
              profile.status === 'noauth' && (
                <Link
                  className="CoachBanner_Link"
                  to={`/${Routes.welcome}/${Routes.signup}`}
                >
                  Sign Up
                </Link>
              )
            }
            {
              !hideButtons && (profile.status === 'profile') && (
                <React.Fragment>
                  <Link
                    className="CoachBanner_Link"
                    to={dashboardLink}
                  >
                    {dashboardText}
                  </Link>
                  <div className="CoachBanner_Line" />
                  {
                    onSignOutClick ?
                      <LinkButton
                        onClick={onSignOutClick}
                        className="CoachBanner_Link"
                      >
                        Sign Out
                      </LinkButton>
                      :
                      <Link
                        className="CoachBanner_Link"
                        to={accountLink}
                      >
                        My Account
                      </Link>
                  }
                </React.Fragment>
              )
            }
          </div>
          <div className="CoachBanner_VCRoundBgrd" >
            <img className="CoachBanner_VCLogo" src={vcLogo} alt="BACKGRO0ND" />
          </div>
        </div>
       <div className="dashboard-btn Coach_editbtn">
       <button onClick={this.openEditSchoolStyleDialog} className="CoachBanner_EditBtn" />
       
        {children}
      </div>
       </div>
    );
  }
}

export default Banner;
