import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {playerProfileGet} from '../../../../store/actions/ui/uxProfile';
import DatePicker from '../DatePicker/DatePicker';
import {positions} from '../../../UxCommon/Form/utils';
import SelectControl from '../../../../modules/CodeSignUp/SimpleForm/SelectControl';
import Inputcontrol from '../InputControl/InputControl.component';
import {
  checkAndCorrectHeight,
  checkWeight,
  checkYearValidation,
} from '../../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation';
import {
  checkAddress,
  checkAddress2,
  checkCityState,
  checkGender,
  checkHeightFtValidation,
  checkHeightInchValidation,
  checkZipCode,
  validatePositionRequired,
  validateRequired,
} from '../Validation/AthleteInviteOnboardingValidateion';
import Button from '../../../../modules/CodeSignUp/Common/Button';
import MultiSelectControl from '../../../../modules/CodeSignUp/SimpleForm/MultiSelectControl';
import {getProfile} from '../../../ActivateProfileMembership/ActivateProfileMembership.models';
import {playerProfileUpdateTest} from '../+Store';
import {useParams} from 'react-router-dom';
import useInviteSelector from '../../Invite/hooks/useInviteSelector';


const gradYearOptions = {
  2019: '2019',
  2020: '2020',
  2021: '2021',
  2022: '2022',
  2023: '2023',
  2024: '2024',
  2025: '2025',
  2026: '2026',
  2027: '2027',
  2028: '2028',
  2029: '2029',
  2030: '2030',
  2031: '2031',
  2032: '2032',
  2033: '2033',
  2034: '2034',
  2035: '2035',
  2036: '2036',
  2037: '2037',
  2038: '2038',
  2039: '2039',
  2040: '2040',
}

// const gradYearOptions = {
//   R: 'Right',
//   L: 'Left',
//   S: 'Switch',
// };


const AthletePhysiquePage = () => {
  const {loggedIn} = useInviteSelector();
  const {uuid, sportId, userTeamId, schoolId, teamName} = useParams();
  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfile(state, uuid));
  const schoolStyle = useSelector((state) => {
    if (schoolId) {
      return state.modules.coachWorld.coachWorld.schoolStyle.find(
        (school) => school.id === schoolId,
      );
    }
    return null;
  });
  const currentSportData = profile && profile.sports.filter((item) => item.sportId === Number(sportId))

  const formRef = useRef();

  const schoolName = schoolStyle ? schoolStyle.name : 'Virtual Combine';

  const [formData, setFormData] = useState({
    grad_year: profile && profile.gradYear || '',
    hieght_feet: profile && profile.heightFt || '',
    height_inches: profile && profile.heightInch || '',
    weight: profile && profile.weight || '',
    gender: profile && profile.gender || '',
    address: profile && profile.address || '',
    address2: '',
    city: profile && profile.city || '',
    state: profile && profile.state || '',
    zip: profile && profile.zip || '',
    birthday: '',
  });

  const [teamFormData, setTeamFormData] = useState({
    sportId: sportId,
    teamId: userTeamId,
    positions: currentSportData && currentSportData[0].positionArray || [],
    bats: currentSportData && currentSportData[0].bats || '',
    throws: currentSportData && currentSportData[0].throws || '',
    jersey_number: '',
  });

  const [errors, setErrors] = useState({
    grad_year: '',
    hieght_feet: '',
    height_inches: '',
    weight: '',
    gender: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    positions: '',
    bats: '',
    throws: '',
    birthday: '',
    jersey_number: '',
  });

  useEffect(() => {
    if (!profile) {
      dispatch(playerProfileGet(uuid));
    }
  }, [profile]);

  const genders = {
    male: 'Male',
    female: 'Female',
  };
  const batOptions = {
    R: 'Right',
    L: 'Left',
    S: 'Switch',
  };

  const getPositions = (gender, sportId) => {
    const positionsArray = positions(sportId, gender);
    const positionsObj = {};
    // Todo: fix for wrestling
    positionsObj[''] = 'Select your Position';
    Object.keys(positionsArray).forEach((pstn) => {
      positionsObj[positionsArray[pstn].value] = positionsArray[pstn].name;
    });
    return positionsObj;
  };

  const positionOptions = getPositions(profile && profile.gender, sportId);

  const onHandlePositionChnage = (teamId, fieldName) => (value) => {
    setTeamFormData({
      ...teamFormData,
      [fieldName]: value,
      teamId,
    });
  };

  const setBirthDateDefaultValue = (value) => {
    setFormData({...formData, 'birthday': value});
    return value;
  }

  const onHandleChnage = (type) => (value) => {
    switch (type) {
      case 'grad_year': {
        setErrors({
          ...errors,
          grad_year: checkYearValidation(value),
        });
        setFormData({...formData, [type]: value});
        return value;
      }
      case 'hieght_feet': {
        const heightFt = value.replace(/\D/g, '');
        setFormData({...formData, [type]: heightFt});
        setErrors({
          ...errors,
          hieght_feet: checkHeightFtValidation(value),
        });
        return heightFt;
      }
      case 'height_inches': {
        const heightInch = value.replace(/\D/g, '');
        setFormData({...formData, [type]: heightInch});
        setErrors({
          ...errors,
          height_inches: checkHeightInchValidation(value),
        });
        return heightInch;
      }
      case 'weight': {
        setErrors({
          ...errors,
          weight: checkWeight(value),
        });
        const WeightLbs = value.replace(/\D/g, '');
        setFormData({...formData, [type]: WeightLbs});
        return WeightLbs;
      }
      case 'gender': {
        setErrors({
          ...errors,
          gender: checkGender(value),
        });
        setFormData({...formData, [type]: value});
        return value;
      }
      case 'address': {
        setFormData({...formData, [type]: value});
        setErrors({
          ...errors,
          address: checkAddress(value, true),
        });
        return value;
      }
      case 'address2': {
        setFormData({...formData, [type]: value});
        setErrors({
          ...errors,
          address2: checkAddress2(value, false),
        });
        return value;
      }
      case 'city': {
        setErrors({
          ...errors,
          city: checkCityState(value),
        });
        setFormData({...formData, [type]: value});
        return value;
      }
      case 'state': {
        setErrors({
          ...errors,
          state: checkCityState(value),
        });
        setFormData({...formData, [type]: value});
        return value;
      }
      case 'zip': {
        setErrors({
          ...errors,
          zip: checkZipCode(value),
        });
        setFormData({...formData, [type]: value});
        return value;
      }
      case 'birthday': {
        setFormData({...formData, [type]: value});
        return value
      }
      case 'jersey_number': {
        setTeamFormData({
          ...teamFormData,
          [type]: value,
        });
      }
        return value;
    }
  };

  const onNextClick = (event) => {
    const form = formRef.current;
    let success = true;
    let heightResult = checkAndCorrectHeight(
      formData.hieght_feet,
      formData.height_inches,
    );

    const errors = {
      grad_year: checkYearValidation(form.grad_year.value),
      hieght_feet: checkHeightFtValidation(form.hieght_feet.value),
      height_inches: checkHeightInchValidation(form.height_inches.value),
      weight: checkWeight(form.weight.value),
      gender: checkGender(form.gender.value),
      address: checkAddress(form.address.value, true),
      address2: checkAddress2(form.address2.value, true),
      city: checkCityState(form.city.value),
      state: checkCityState(form.state.value),
      zip: checkZipCode(form.zip.value),
      positions: validatePositionRequired(teamFormData.positions),
      birthday: validateRequired(formData.birthday),
    };

    if (sportId === '2' || sportId === '4') {
      Object.assign(errors, {
        bats: validateRequired(teamFormData.bats),
        throws: validateRequired(teamFormData.throws),
      });
    }

    setErrors(errors);
    const profileData = {
      grad_year: form.grad_year.value,
      height: heightResult.heightFt * 12 + heightResult.heightIn,
      weight: form.weight.value,
      gender: form.gender.value,
      address:
        form.address.value && form.address2.value
          ? form.address.value + ', ' + form.address2.value
          : form.address.value + form.address2.value,
      city: form.city.value,
      state: form.state.value,
      zip: form.zip.value,
      birthday: formData.birthday ? formData.birthday.format('YYYY-MM-DD') : '',
    };

    const teamData = {
      positions: teamFormData.positions,
      bats: teamFormData.bats,
      throws: teamFormData.throws,
      //jersey_number: form.jersey_number.value,
      jersey_number: '',
      sportId: sportId
    };

    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });

    if (success) {
      dispatch(playerProfileUpdateTest(uuid, profileData, teamData));
    }

    event.preventDefault();
  };

  return (
    <div className="container welcome-first">
      <div className="row">
        <div className="col">
          <div className="welcome-msg">Welcome to {schoolName}!</div>
          <div className="welcome-desc">
            {`You've been invited to join ${teamName.replaceAll('+', ' ')}`}
          </div>
        </div>
      </div>
      <div className="AccountPage OnboardingStep1Page">
        <form
          ref={formRef}
          className="PhysiquePage_Form"
          onSubmit={onNextClick}>
          <DatePicker
            onMyChange={onHandleChnage('birthday')}
            onSetDefaultValue={setBirthDateDefaultValue}
            errors={errors.birthday}
            defaultValue={profile ? profile.birthdate : null}
          />

          <MultiSelectControl
            className="CodeSignUp_TeamPick"
            label="HS Grad Year"
            placeholder="HS Grad Year"
            options={gradYearOptions}
            name="grad_year"
            errors={errors.grad_year}
            myOnChange={onHandleChnage('grad_year')}
            myOnBlur={onHandleChnage('grad_year')}
            defaultValue={[formData.grad_year]}
          />

          <Inputcontrol
            className="AccountPage_TextControl"
            label="Height-Feet"
            placeholder="Height-Feet"
            name="hieght_feet"
            myOnChange={onHandleChnage('hieght_feet')}
            myOnBlur={onHandleChnage('hieght_feet')}
            errors={errors.hieght_feet}
            defaultValue={(profile && profile.heightFt) || ''}
          />
          <Inputcontrol
            className="AccountPage_TextControl"
            label="Height-Inches"
            placeholder="Height-inches"
            name="height_inches"
            myOnChange={onHandleChnage('height_inches')}
            myOnBlur={onHandleChnage('height_inches')}
            errors={errors.height_inches}
            defaultValue={(profile && profile.heightInch) || ''}
          />
          <Inputcontrol
            className="AccountPage_TextControl"
            label="Weight(lbs)"
            placeholder="Weight(lbs)"
            name="weight"
            myOnChange={onHandleChnage('weight')}
            myOnBlur={onHandleChnage('weight')}
            errors={errors.weight}
            defaultValue={(profile && profile.weight) || ''}
          />

          <SelectControl
            className="PhysiquePage_TextControl"
            placeholder="Select your gender"
            label="Gender"
            help="Gender is required to help us generate an accurate VC score"
            options={genders}
            name="gender"
            myOnChange={onHandleChnage('gender')}
            myOnBlur={onHandleChnage('gender')}
            errors={errors.gender}
            defaultValue={(profile && profile.gender) || ''}
          />
          <Inputcontrol
            className="PhysiquePage_TextControl"
            placeholder="Street address or P.O. Box"
            label="Address"
            type="text"
            name="address"
            myOnChange={onHandleChnage('address')}
            myOnBlur={onHandleChnage('address')}
            errors={errors.address}
            defaultValue={(profile && profile.address) || ''}
          />
          <Inputcontrol
            className="PhysiquePage_TextControl top_address_margin"
            placeholder="Apt, suite, unit, building, floor, etc."
            type="text"
            name="address2"
            myOnChange={onHandleChnage('address2')}
            myOnBlur={onHandleChnage('address2')}
            errors={errors.address2}
          />
          <Inputcontrol
            className="PhysiquePage_TextControl"
            placeholder="City"
            label="City"
            type="text"
            name="city"
            myOnChange={onHandleChnage('city')}
            myOnBlur={onHandleChnage('city')}
            errors={errors.city}
            defaultValue={(profile && profile.city) || ''}
          />
          <Inputcontrol
            className="PhysiquePage_TextControl"
            placeholder="State"
            label="State"
            type="text"
            name="state"
            myOnChange={onHandleChnage('state')}
            myOnBlur={onHandleChnage('state')}
            errors={errors.state}
            defaultValue={(profile && profile.state) || ''}
          />
          <Inputcontrol
            className="PhysiquePage_TextControl"
            placeholder="zipcode"
            label="Zipcode"
            type="text"
            name="zip"
            myOnChange={onHandleChnage('zip')}
            myOnBlur={onHandleChnage('zip')}
            errors={errors.zip}
            defaultValue={(profile && profile.zip) || ''}
          />
          <MultiSelectControl
            className="CodeSignUp_TeamPick"
            placeholder="Positions"
            label={`What position(s) do you play in ${teamName.replaceAll('+', ' ')}?`}
            options={positionOptions}
            name="positions"
            errors={errors.positions}
            defaultValue={(currentSportData && currentSportData[0].positionArray) || []}
            myOnChange={onHandlePositionChnage(userTeamId, 'positions')}
            multi
          />
          {(sportId === '2' || sportId === '4') && (
            <MultiSelectControl
              className="CodeSignUp_TeamPick"
              label="Which way do you bat (Right, Left or Switch)?"
              placeholder={'BATS'}
              options={batOptions}
              name="bats"
              errors={errors.bats}
              myOnChange={onHandlePositionChnage(userTeamId, 'bats')}
              myOnBlur={onHandlePositionChnage(userTeamId, 'bats')}
              defaultValue={[teamFormData.bats]}
            />
          )}
          {(sportId === '2' || sportId === '4') && (
            <MultiSelectControl
              className="CodeSignUp_TeamPick"
              label="Which arm do you throw with (Right, Left or Switch)?"
              placeholder={'THROWS'}
              options={batOptions}
              name="throws"
              errors={errors.throws}
              myOnChange={onHandlePositionChnage(userTeamId, 'throws')}
              defaultValue={[teamFormData.throws]}
            />
          )}
          {/*<Inputcontrol*/}
          {/*  className="AccountPage_TextControl"*/}
          {/*  label={`What is your ${teamName.replaceAll('+', ' ')} Jersey Number?`}*/}
          {/*  placeholder="jersey number"*/}
          {/*  name="jersey_number"*/}
          {/*  myOnChange={onHandleChnage('jersey_number')}*/}
          {/*  myOnBlur={onHandleChnage('jersey_number')}*/}
          {/*  defaultValue={(currentSportData && currentSportData[0].jerseyNumbers) || ''}*/}
          {/*/>*/}
          <Button
            type="button"
            onClick={onNextClick}
            className="PhysiquePage_SubmitBtn">
            Next
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AthletePhysiquePage;
