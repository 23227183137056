import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import { AthleteGradesTh } from '../../+store';
import CombineAwardRowInner from './CombineAwardRowInner.component';

class CombineAwardPlayer extends PureComponent {
  static propTypes = {
    athlete: PropTypes.object,
    awards: PropTypes.array,
    combine: PropTypes.object,
    createCombineAward: PropTypes.func,
    denyCombineAward: PropTypes.func,
    savedCombineAwards: PropTypes.array,
    loadingAwardButton: PropTypes.bool,
  };

  createCombineAward = (combineId, data) => {
    this.props.createCombineAward(combineId, data);
  }

  render() {
    const {
      athlete,
      combine,
      awards,
      denyCombineAward,
      savedCombineAwards,
      loadingAwardButton,
      schoolAwardLogo,
      isSelectAll,
      handleAwardsCount,
      playersList,
      awardsCount
    } = this.props;
    const bestAwardTitles = awards.map((sc) => {
      if (sc.bestObject && sc.bestObject.athleteId === athlete.userId) {
        return sc.bestObject.awardTitle;
      }
      return null;
    });


    const mostAwardTitles = awards.map((sc) => {
      if (sc.mostImprovedObject && sc.mostImprovedObject.athleteId === athlete.id) {
        return sc.mostImprovedObject.awardTitle;
      }
      return null;
    });

    const awardTitles = [...bestAwardTitles, ...mostAwardTitles];
    const savedBeforeObjects = savedCombineAwards && savedCombineAwards
      .filter(c => (awardTitles.includes(c.name))
        && c.combineId === combine.id.toString());

    const newAwardCount = savedBeforeObjects ?
      (awards.length - savedBeforeObjects.length) : awards.length;

    const filteredAwards = awards.reduce((currentValue, award) => {
      currentValue.push(award.bestObject, award.mostImprovedObject);
      return currentValue;
    }, []).filter(a => a && a.athleteId === athlete.userId);
    // console.log(filteredAwards,'fiiltered awards');

    const athletePositionJersey = [athlete.position, athlete.jerseyNumber && athlete.jerseyNumber.trim() ? `#${athlete.jerseyNumber.trim()}` : null].filter(n => !!n).join(' | ');
    return (
      <div className="CombineAwards_Title_Container">
        <div className="CombineAwards_Title_Row">
          <div className="CombineAwards_Title_Row_Left">
            <div className="AthleteMedia">
              {
                athlete.headshotUrl ? (
                  <div
                    className="AthleteMedia_Headshot"
                    style={{
                      backgroundImage: `url(${athlete.headshotUrl})`,
                    }}
                  />
                ) :
                  (
                    <div className="AthleteMedia_Headshot">
                      <Icon iconName="photo2" className="AthleteMedia_PhotoIcon" />
                    </div>
                  )
              }
            </div>
            <div className="AthleteDetails">
              <div className="CombineAward_Title_Text">
                {`${athlete.givenName} ${athlete.familyName}`}
              </div>
              {
                athlete.gradYear && (
                  <div className="CombineAward_Title_Grade">
                    { athlete.gradYear }
                  </div>
                )
              }
              {
                !!athletePositionJersey && (
                  <div className="CombineAward_Position">
                    {athletePositionJersey}
                  </div>
                )
              }
            </div>
          </div>
          <div className="CombineAwards_Title_Row_Right">
            {
              combine.awardsGeneratedBool === 0 &&
              <div className="CombineAward_NewAwards">
                {`${newAwardCount <= 0 ? 'NO' : newAwardCount} NEW AWARD${newAwardCount > 1 ? 'S' : ''}`}
              </div>
            }
          </div>
        </div>
        {
          filteredAwards.map((award, i) =>
            (
              <CombineAwardRowInner
                key={i}
                award={award}
                athleteId={athlete.userId}
                isAthleteCoachedByUser={athlete.isAthleteCoachedByUser}
                savedCombineAwards={savedCombineAwards}
                createCombineAward={this.createCombineAward}
                combine={combine}
                loadingAwardButton={loadingAwardButton}
                schoolAwardLogo={schoolAwardLogo}
                awardList = {playersList}
                isSelectAll = {isSelectAll}
                awardsCount = {awardsCount}
                handleAwardsCount = {handleAwardsCount}
              />
            ))
        }
      </div>
    );
  }
}


export default CombineAwardPlayer;
