import React, { useState } from 'react';
import ProfileActivationModal from '../ProfileActivationModal/ProfileActivationModal';
import { useDispatch, useSelector } from 'react-redux';
import { playerProfileActivationMembershipModal } from '../../../../../../store/actions/ui/uxProfile';

const ProfileActivationButton = ({ profile }) => {
  const dispatch = useDispatch();
  const openModal = () =>
    dispatch(playerProfileActivationMembershipModal(true));

  if (!profile.parentsEmail) {
    return '';
  }
  return (
    <React.Fragment>
      <button
        id="activateProfileBtn"
        className="ActivateProfileBtn"
        onClick={openModal}>
        Activate This Membership
      </button>
      {/*{!!isOpen && (*/}
      {/*  <ProfileActivationModal profile={profile} onClose={closeModal} />*/}
      {/*)}*/}
    </React.Fragment>
  );
};

export default ProfileActivationButton;
