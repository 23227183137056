import { combineReducers } from 'redux';
import createUpdateDialog from './createUpdateDialog';
import reducers from './awards';

const reducer = combineReducers({
  ...reducers,
  createUpdateDialog,
});

export default reducer;
