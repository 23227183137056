import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { CHANGE_VIEW_SELECTION, GET_LEADERBOARD } from './leaderboard.actions';
import { LeaderboardViewOptions } from './leaderboard.constants';

const leaderboard = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_LEADERBOARD) {
        return action.data || state;
      }
      return state;
    default:
      return state;
  }
};

const viewSelection = (state = LeaderboardViewOptions.outline, action) => {
  switch (action.type) {
    case CHANGE_VIEW_SELECTION:
      return action.viewSelection;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_LEADERBOARD && !action.attrs.silent) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_LEADERBOARD) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  leaderboard,
  viewSelection,
  loadingMsg,
});

export default reducer;
