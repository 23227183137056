import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import SearchControl from '../../common/SearchControl';
import SelectControl from '../../common/FormControls/SelectControl';
import { RosterSortByLabels } from '../../+store';
import ToggleView from '../../common/ToggleView';

class SearchBarNav extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onSortByChange: PropTypes.func.isRequired,
    sortBy: PropTypes.string,
    showFilters: PropTypes.func,
    children: PropTypes.node,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onSortByChange = (value) => {
    const { location, history, onSortByChange } = this.props;
    onSortByChange(location, history, value);
  }

  shouldUpdate = () => {}

  render() {
    const {
      location,
      history,
      sortBy,
      showFilters,
      children,
    } = this.props;
    const findTeamKeyword = location.pathname.indexOf("team");
    return (
      <div className="CoachSearchBarNav_Wrapper">
        <div className="CoachSearchBarNav">
          <SearchControl
            placeholder="Search the Team Roster"
            location={location}
            history={history}
          />
          { children }
          { findTeamKeyword < 0 && <ToggleView history={history} location = {location}/>
          /*<button className="CoachSearchBarNav_Button CoachSelectControl2_Btn" onClick={showFilters}>Filter</button>*/}
          { findTeamKeyword < 0 && <SelectControl
              className="CoachSearchBar_SortBySelect"
              placeholder="Sort By"
              options={RosterSortByLabels}
              name="sortBy"
              myOnChange={this.onSortByChange}
              defaultValue=""
              value={sortBy}
              selectedSortBy={sortBy}
            />
          }
        </div>
      </div>
    );
  }
}

export default SearchBarNav;
