import React, { useState } from 'react';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';
import SelectControl from '../../../../modules/CodeSignUp/SimpleForm/SelectControl';
import LoadingIndicator from '../../../../modules/CoachWorld/common/LoadingIndicator';
import WelcomeHeader from '../../../Welcome/Common/WelcomeHeader/WelcomeHeader';

const MergeAthleteAccount = ({
  profile,
  duplicateProfile,
  match,
  onNext,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  const onChangeHandler = (value) => {
    setSelectedOption(value);
  };

  const handleContinue = () => {
    onNext(selectedOption);
    // onValueChange(sportId);
  };

  const onClickClose = () => {};

  const latestSchoolLogo = profile.latestSchool.school.paid_invite_school_logo;

  return (
    <div className="CoachWorld_EditPlayer_Backdrop OnBoarding-merge-profiles">
      <div className="Combine_Edit_Modal">
        <WelcomeHeader match={match} loading={false} loadingMsg={''} latestSchoolLogo ={ latestSchoolLogo }>
          <CloseButton onClick={onClickClose} size={10} />
          <LoadingIndicator loading={false} loadingMsg={'Loading..'} />
          <div className="Combine_Edit_Container">
            <div className="OnBoarding-merge_Header OnBoarding-merge-profiles-message">
              {`Hi ${profile.first ? profile.first : profile.given_name} ${
                profile.last ? profile.last : profile.family_name
              }`}
              !
            </div>
            <div className="Sports_Selector_Sub_Head OnBoarding-merge-profiles-message">{`There is another account registered to ${duplicateProfile.given_name} ${duplicateProfile.family_name} using ${duplicateProfile.email}`}</div>
            <div
              className={
                'Sports_Selector_Ques OnBoarding-merge-profiles-message'
              }>
              {'Which option is best for you ?'}
            </div>
            <form className="Sports_Form">
              {
                <SelectControl
                  name="Sports"
                  classNameRadio="SelectSport_RadioButton OnBoarding-merge-profiles-options d-flex unsetTextTransform"
                  useRadio={true}
                  options={{
                    1: `${duplicateProfile.given_name} ${duplicateProfile.family_name} is the correct athlete. Please merge all data, so I have one account.`,
                    2: `${duplicateProfile.given_name} ${duplicateProfile.family_name} is not the correct athlete. I do though, already have an account and need to sign in.`,
                  }}
                  myOnChange={onChangeHandler}
                  myOnBlur={onChangeHandler}
                  defaultValue={selectedOption}
                />
              }
            </form>
            <div className="Combine_Edit_Btns Select_Sports_Buttons">
              <React.Fragment>
                <ButtonRect
                  className="Photos_ExpandedPhotoDialog_Button primary"
                  style={{ width: '120px' }}
                  type="button"
                  onClick={onCancel}>
                  Cancel
                </ButtonRect>

                <ButtonRect
                  className="Photos_ExpandedPhotoDialog_Button primary"
                  style={{ width: '120px' }}
                  type="button"
                  disabled={!selectedOption}
                  onClick={handleContinue}>
                  Next
                </ButtonRect>
              </React.Fragment>
            </div>
          </div>
        </WelcomeHeader>
      </div>
    </div>
  );
};

export default MergeAthleteAccount;
