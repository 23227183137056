import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

// import EditProfilePage from '../../containers/EditProfile/EditInfo';
import Account from '../../containers/UxEditProfile/Account';
import GetEditProfile from '../../containers/UxCommon/Loaders/GetEditProfile';
import StickyHeader from '../../containers/UxEditProfile/StickyHeader';
import EditSports from '../../containers/UxEditProfile/EditSports';
import { Routes, Sports } from '../../store/actions/ui/routes';
import UpdateProfilePhotoDialog from '../../containers/UxEditProfile/UpdateProfilePhotoDialog';
import AcademicInfo from '../../containers/UxEditProfile/AcademicInfo';
import MoreActions from '../../containers/UxEditProfile/MoreActions';
import ChangePass from '../../containers/UxEditProfile/ChangePass';
import DeleteAccount from '../../containers/UxEditProfile/DeleteAccount';
import ConfirmEmailPhone from '../../containers/UxEditProfile/ConfirmEmailPhone';
import Personal from '../../containers/UxEditProfile/Personal';
import {
  Container,
  Row,
  Col,
  CloseLink,
  GetWindowSize, SecondaryIconButton,
} from '../UxCommon';
import PageHeader from '../../containers/UxProfile/PageHeader';

class EditProfile extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    currentUuid: PropTypes.string.isRequired,
    profile: PropTypes.object,
    canEdit: PropTypes.bool,
    isCoach: PropTypes.bool,
  };

  state = {
    width: 10000,
    currentForm: 'sports',
  };

  setWindowWidth = (v) => {
    this.setState({ width: v });
  };

  setCurrentForm = (currentForm) => {
    this.setState({ currentForm });
  };

  renderEditProfile = (props) => {

    const { match, history } = props;
    const { isCoach, currentUuid } = this.props;
    const { currentForm } = this.state;
    const {schoolId , sport} = match.params;
    return (
      <React.Fragment>
        <Container style={{ height: '100px' }}>
          <Row>
            <Col>
              <CloseLink to={`/${Routes.profile}/${match.params.uuid || currentUuid}/${Routes.player}/${sport ? sport : ''}${schoolId ? `/${schoolId}`:''}`} />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="col-auto">

              <SecondaryIconButton
                iconName="edit"
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
                className={currentForm === 'sports' ? 'selected' : ''}
                onClick={() => this.setCurrentForm('sports')}
              >
                Sports
              </SecondaryIconButton>

              <SecondaryIconButton
                iconName="edit"
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
                className={currentForm === 'academics' ? 'selected' : ''}
                onClick={() => this.setCurrentForm('academics')}
              >
                Academic Info
              </SecondaryIconButton>

              <SecondaryIconButton
                iconName="edit"
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
                className={currentForm === 'account' ? 'selected' : ''}
                onClick={() => this.setCurrentForm('account')}
              >
                Account Info
              </SecondaryIconButton>

              {!isCoach &&
                <SecondaryIconButton
                  iconName="edit"
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                  }}
                  className={currentForm === 'more' ? 'selected' : ''}
                  onClick={() => this.setCurrentForm('more')}
                >
                  More Actions
                </SecondaryIconButton>
              }
            </Col>
            <Col>
              {currentForm === 'sports' && <EditSports match={match} />}
              {currentForm === 'account' &&
              <React.Fragment>
                <Personal match={match} />
                <Account match={match} history={history} />
              </React.Fragment>
              }
              {currentForm === 'academics' && <AcademicInfo match={match} />}
              {!isCoach && currentForm === 'more' && <MoreActions match={match} />}
            </Col>
          </Row>
        </Container>

      </React.Fragment>
    );
  };

  render() {
    const { match, canEdit,isCoach } = this.props;
    const { width } = this.state;
    if (!canEdit) {
      return <div>404</div>;
    }
    return (
      <React.Fragment>
        <GetWindowSize getWindowX={this.setWindowWidth} />
        <GetEditProfile {...this.props}>
          {/* <EditProfilePage {...this.props} /> */}
          {
            <div className="UxEditProfile">
              {width < 768 ? (
                <Route path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`} component={PageHeader} />) : (
                  <StickyHeader match={match} />)}
              <Switch>
                <Route
                  exact
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/${Routes.confirmPhone}`}
                  component={ConfirmEmailPhone}
                />
                <Route
                  exact
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/${Routes.confirmEmail}`}
                  component={ConfirmEmailPhone}
                />
                <Route
                  exact
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/${Routes.changePassword}`}
                  component={ChangePass}
                />
                <Route
                  exact
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/${Routes.deleteAccount}`}
                  component={DeleteAccount}
                />
                {
                  isCoach ?
                    <Route
                    exact
                    path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport/:schoolId?/${Routes.profilePhoto}`}
                    component={UpdateProfilePhotoDialog} />
                    :
                    <Route
                    exact
                    path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport/${Routes.profilePhoto}`}
                    component={UpdateProfilePhotoDialog}
                />
                }
                <Route
                  exact
                  path={`/${Routes.profile}/:uuid/${Routes.edit}/:sport?/:schoolId?`}
                  render={this.renderEditProfile}
                />
              </Switch>
            </div>
          }
        </GetEditProfile>
      </React.Fragment>
    );
  }
}

export default EditProfile;
