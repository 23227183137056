import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../UxCommon/Icon/Icon';

const PickerButton = ({ selected, txt, onClick }) => (
  <button
    className={[
      'UxProfile_Photos_SideBar_PhotosVidoesPicker_Btn',
      (selected) ? 'selected' : '',
    ].join(' ')}
    onClick={onClick}
  >
    <div style={{ marginRight: 'auto' }}>{txt}</div>
    {
      (selected) && (
        <div>
          <Icon
            className="UxProfile_Photos_SideBar_PhotosVidoesPicker_BtnArrow"
            iconName="arrow-right"
          />
        </div>
      )
    }
  </button>
);
PickerButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  txt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class PhotosVidoesPicker extends PureComponent {
  static propTypes = {
    onPhotosClick: PropTypes.func.isRequired,
    onVideosClick: PropTypes.func.isRequired,
    onAllClick: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      onPhotosClick,
      onVideosClick,
      onAllClick,
      selectedCategory,
    } = this.props;
    return (
      <div className="UxProfile_Photos_SideBar_PhotosVidoesPicker">
        <PickerButton selected={selectedCategory === 'all'} txt="All" onClick={onAllClick} />
        <PickerButton selected={selectedCategory === 'photos'} txt="Photos" onClick={onPhotosClick} />
        <PickerButton selected={selectedCategory === 'videos'} txt="Videos" onClick={onVideosClick} />
      </div>
    );
  }
}

export default PhotosVidoesPicker;
