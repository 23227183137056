export const Logos = {
  'f57aaa60-7587-11e9-b3a1-37f6e2ecd8d2':  'https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/texas-sun-devils-logo.png',
  'f9353190-7587-11e9-99be-1f5ee8e1727c': 'https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/ball_players.jpeg'
}

export const School = {
  'f57aaa60-7587-11e9-b3a1-37f6e2ecd8d2':  'Texas Sun Devils',
  'f9353190-7587-11e9-99be-1f5ee8e1727c': 'Bay Area Ball Players'
}


export const ContactUsSchoolEmails = {
  'f57aaa60-7587-11e9-b3a1-37f6e2ecd8d2':  'TexasSunDevils@virtualcombine.com',
  'f9353190-7587-11e9-99be-1f5ee8e1727c': 'bayareaballplayers@virtualcombine.com'
}
