import { combineReducers } from 'redux';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { INVITED_SIGN_UP_FORM_INIT } from '../../../actions/ui/welcome';

import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { cognitoUsernameError } from '../utils/errors';
import { USER_GET_PROFILE_SUC } from '../../../actions/data/user';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case USER_GET_PROFILE_SUC:
      return true;
    case INVITED_SIGN_UP_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case INVITED_SIGN_UP_FORM_INIT:
      return '';
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.invitedSignUp') {
        return cognitoUsernameError(action.error, action);
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
});

export default reducer;
