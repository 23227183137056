import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, iconName, style }) => (
  <i
    className={[`icon-${iconName}-icon`, 'CoachIcon', className].join(' ')}
    style={style || {}}
  />
);
Icon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;
