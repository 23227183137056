import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import { Link, Redirect } from "react-router-dom";
import { mergeForgotPassword, mergeSignIn, Routes } from "../../../store/actions/ui/routes";

import TextControlGroup from "../../UxCommon/Form/TextControlGroup/TextControlGroup";
import { parserJerseyNumbers, parserLower } from "../../../utils/parsers";
import { Roles } from "../../../store/actions/data/user/profile/models";
import MultiSelectControl from "../../../modules/CodeSignUp/SimpleForm/MultiSelectControl";
import { getPositions } from "../InvitedSignUp/InvitedSignUp";


export const MergeTypes = {
  "mergeBoth": 1,
  "signIn": 2
};

class MergeSignIn extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    authenticatedProfile: PropTypes.object,
    rrfForm: PropTypes.object,
    updatePosition: PropTypes.func,
    getProfile: PropTypes.func,
    authUuid: PropTypes.string,
    shouldCheckInviteValid: PropTypes.bool,
    isInvitationValid: PropTypes.object,
    checkIfInviteIsValid: PropTypes.func,
    mergeProfile: PropTypes.any,
    message: PropTypes.string,
    getDuplicateProfiles: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      match,
      getProfile,
      loggedIn,
      authUuid,
      shouldCheckInviteValid,
      checkIfInviteIsValid,
      getDuplicateProfiles,
      profile,
    } = this.props;
    if (shouldCheckInviteValid) {
      checkIfInviteIsValid(match.params.userTeamId, match.params.token);
    } else {
      getProfile(match.params.uuid);
      getDuplicateProfiles(match.params.uuid);
    }
    if (loggedIn && authUuid) {
      getProfile(authUuid);
    }
    if(profile && !profile.email){
      getProfile(authUuid);
    }
    this.shouldInit();
  }

  componentDidUpdate() {
    const {
      match,
      getProfile,
      loggedIn,
      authUuid,
      authenticatedProfile,
      profile,
      shouldCheckInviteValid,
      checkIfInviteIsValid
    } = this.props;
    if (shouldCheckInviteValid) {
      checkIfInviteIsValid(match.params.userTeamId, match.params.token);
    } else if (!profile && match.params.uuid || !profile.email) {
      getProfile(match.params.uuid);
    }
    if (loggedIn && (!authenticatedProfile || authenticatedProfile.uuid !== authUuid)) {
      getProfile(authUuid);
    }
    this.shouldInit();
  }

  shouldInit = () => {
    const { needsInit, formInit, profile, mergeProfile } = this.props;
    if (needsInit) {
      formInit(mergeProfile ? mergeProfile : profile);
    }
  };

  submit = () => {
    const { submit, match, profile } = this.props;
    submit(match.params.uuid, match.params.token, match.params.userTeamId, profile.registered);
  };

  render = () => {
    const {
      profile,
      loggedIn,
      rrfForm,
      match,
      updatePosition,
      authUuid,
      authenticatedProfile,
      formGeneralError,
      isInvitationValid,
      shouldCheckInviteValid,
      message,
    } = this.props;
    const notMatchingEmails = formGeneralError === "Your email address does not match the invitation.";
    // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
    // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
    if (loggedIn && authenticatedProfile && authenticatedProfile.roleId === Roles.coach) {
      return <Redirect to={`/${Routes.coach}/${Routes.dashboard}`} />;
    }

    const { mergeType } = match.params;

    if (!shouldCheckInviteValid && isInvitationValid && isInvitationValid.isValid === false) {
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">Invitation Invalid</div>
              <div className="welcome-desc">
                This invite link is no longer active.
                Please check with your coach to see if there is a new link available
              </div>
            </div>
          </div>
        </div>
      );
    }

    if ((loggedIn && authUuid !== match.params.uuid && profile && profile.registered)
      || notMatchingEmails) {
      // TODO: add logout and go to profile buttons
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">Email Address does not match invitation</div>
              <div className="welcome-desc">
                {`${notMatchingEmails ? "" : `You are currently logged in as ${authenticatedProfile && authenticatedProfile.email}. `}This email address
                does not match the email address (${profile && profile.email}) specified in the invitation.
                If you would like to use a different account to access Virtual Combine,
                please ask your coach to generate a new invitation link with that account.`}
              </div>
            </div>
          </div>
        </div>
      );
    }
    let possiblePositions = null;
    if (profile) {
      possiblePositions = getPositions(profile.gender, match.params.sportId);
    }
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">Accept Invite</div>
            <div className="welcome-msg" style={{color: "green"}}>{message}</div>
            {loggedIn && <div
              className="welcome-desc">{`You are currently logged in as ${authenticatedProfile && authenticatedProfile.email}.`}</div>}
            <div className="welcome-desc">
              {`Please ${loggedIn ? "enter the data below" : "sign in"} in order to merge the data your coach added into your profile.`}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model={loggedIn ? "forms.acceptInvitation" : "forms.mergeLogin"}
                  onSubmit={this.submit}>
              {
                !loggedIn &&
                <TextControlGroup
                  label="Email"
                  model="forms.mergeLogin.username"
                  parser={parserLower}
                  disabled={mergeType === MergeTypes.mergeBoth.toString()}
                />
              }
              {
                !loggedIn &&
                <TextControlGroup label="Password" type="password" model="forms.mergeLogin.password" />
              }
              {
                possiblePositions && rrfForm.positions && (
                  <MultiSelectControl
                    style={{ fontSize: "1.2rem" }}
                    placeholder="Position(s)"
                    label={`What position(s) do you play in ${match.params.teamName.replace(/\+/g, " ")}?`}
                    options={possiblePositions}
                    name="positions"
                    errors={rrfForm.positions.errors}
                    defaultValue={rrfForm.positions.value ? rrfForm.positions.value.split(", ") : []}
                    myOnChange={updatePosition}
                    multi
                  />
                )
              }
              {/*<TextControlGroup*/}
              {/*  label={`What are your Jersey Number(s) in ${match.params.teamName.replace(/\+/g, " ")}?`}*/}
              {/*  placeholder="Jersey Number(s)"*/}
              {/*  model={loggedIn ? "forms.acceptInvitation.jerseyNumbers" : "forms.mergeLogin.jerseyNumbers"}*/}
              {/*  parser={parserJerseyNumbers}*/}
              {/*/>*/}
              {
                this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{formGeneralError}</div>
                </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Accept Invitation</button>
              </div>
              {
                !loggedIn && (
                  <Link className="welcome-link"
                        to={mergeForgotPassword(
                    MergeTypes.mergeBoth,
                    match.params.uuid,
                    match.params.token,
                    match.params.teamName,
                    match.params.coachName,
                    match.params.sportId,
                    match.params.userTeamId,
                    match.params.schoolId,
                  )}>
                    Forgot Password?
                  </Link>
                )
              }
            </Form>
          </div>
        </div>
      </div>
    );
  };
}

export default MergeSignIn;
