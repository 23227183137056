import { combineReducers } from 'redux';
import { ASYNC_FINISH } from '../../../../store/actions/ui/async';
import { GET_COLLEGE_CONTACTS } from './collegeContact.actions';

const loading = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COLLEGE_CONTACTS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const contacts = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COLLEGE_CONTACTS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loading,
  contacts,
});

export default reducer;
