import { combineReducers } from 'redux';

import {
  OPEN_EXPANDED_DIALOG,
  CLOSE_EXPANDED_DIALOG,
  UPDATE_PHOTO,
  UPDATE_VIDEO,
  FORM_INIT_EXPANDED_DIALOG,
  SHOW_EXPANDED_PHOTO_DELETE_DIALOG,
  HIDE_EXPANDED_PHOTO_DELETE_DIALOG,
  DELETE_PHOTO,
  DELETE_VIDEO,
  EXPANDED_DIALOG_NEXT,
  EXPANDED_DIALOG_PREV,
  ADD_TAG,
  ADD_TAG_TO_PHOTO,
  SHOW_EXPANDED_TAGS_DELETE_DIALOG,
  HIDE_EXPANDED_TAGS_DELETE_DIALOG,
  DELETE_TAG,
  SAVE_TAGS,
  MULTI_DELETE_EXECUTE,
  GET_VIDEO,
  CLEAR_VIDEO_STATE,
} from '../../../../actions/ui/uxProfile/photosVideos';
import { PHOTO_UPDATE_SUC, VIDEO_UPDATE_SUC } from '../../../../actions/data/photo';
import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../actions/ui/async';
import { getNextMediaFromList, getPrevMediaFromList } from '../../../../actions/ui/uxProfile/photosVideos/utils';

const selectedMedia = (state = null, action) => {
  switch (action.type) {
    case OPEN_EXPANDED_DIALOG:
      return action.media;
    case CLOSE_EXPANDED_DIALOG:
      return null;
    case PHOTO_UPDATE_SUC:
      return action.photo;
    case VIDEO_UPDATE_SUC:
      return action.video;
    case ASYNC_FINISH:
      if ((action.model === DELETE_PHOTO) ||
      (action.model === DELETE_VIDEO)) {
        return null;
      }
      return state;
    case EXPANDED_DIALOG_NEXT:
      return getNextMediaFromList(action.photoList, action.media);
    case EXPANDED_DIALOG_PREV:
      return getPrevMediaFromList(action.photoList, action.media);
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if ((action.model === UPDATE_PHOTO) ||
      (action.model === UPDATE_VIDEO) ||
      (action.model === DELETE_PHOTO) ||
      (action.model === DELETE_VIDEO) ||
      (action.model === ADD_TAG) ||
      (action.model === SAVE_TAGS) ||
      (action.model === DELETE_TAG)) {
        return action.msg;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === UPDATE_PHOTO) ||
      (action.model === UPDATE_VIDEO) ||
      (action.model === DELETE_PHOTO) ||
      (action.model === DELETE_VIDEO) ||
      (action.model === ADD_TAG) ||
      (action.model === SAVE_TAGS) ||
      (action.model === ADD_TAG_TO_PHOTO) ||
      (action.model === MULTI_DELETE_EXECUTE)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const needsInit = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if ((action.model === UPDATE_PHOTO) ||
      (action.model === UPDATE_VIDEO)) {
        return true;
      }
      return state;
    case OPEN_EXPANDED_DIALOG:
    case EXPANDED_DIALOG_NEXT:
    case EXPANDED_DIALOG_PREV:
      return true;
    case FORM_INIT_EXPANDED_DIALOG:
      return false;
    default:
      return state;
  }
};

const deleteDialogIsShown = (state = false, action) => {
  switch (action.type) {
    case SHOW_EXPANDED_PHOTO_DELETE_DIALOG:
      return true;
    case HIDE_EXPANDED_PHOTO_DELETE_DIALOG:
      return false;
    default:
      return state;
  }
};

const deleteTagsDialogIsShown = (state = false, action) => {
  switch (action.type) {
    case SHOW_EXPANDED_TAGS_DELETE_DIALOG:
      return true;
    case HIDE_EXPANDED_TAGS_DELETE_DIALOG:
      return false;
    default:
      return state;
  }
};

const videos = ( state = [], action) =>{
  switch(action.type){
    case ASYNC_FINISH:
      if (action.model === GET_VIDEO){
        return ([...state,action.data])
      }
      return state;
    case CLEAR_VIDEO_STATE:
      const tempState = [...state];
      const updatedTempVideo = tempState.filter(video => {
        if(video.processed === 1){
          return video;
        }
      })
      return updatedTempVideo;
    default:
      return state;
  }
}

const reducer = combineReducers({
  selectedMedia,
  loadingMsg,
  needsInit,
  deleteDialogIsShown,
  deleteTagsDialogIsShown,
  videos,
});

export default reducer;
