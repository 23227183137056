import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../../config/urls';

export const createVanityUrl = (name, token, body = {}) => (
  ajax.post(
    // Urls.vanity.post(name),
    Urls.instagram.post(name),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateVanityUrl = (name, token, body = {}) => (
  ajax.put(
    // Urls.vanity.put(name),
    Urls.instagram.put(name),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const testVanityUrl = (name, token)  => (
  ajax.getJSON(
    // Urls.vanity.get(name),
    Urls.instagram.get(name),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'vanity/apiCalls.js';
