import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SIGN_OUT } from '../../../../store/actions/ui/auth';

import { cognitoSignOut } from '../../data/cognito/auth';
import { signOutSuccess } from './actions';

const signOutEpic = action$ => action$.pipe(
  ofType(SIGN_OUT),
  switchMap(action => from(cognitoSignOut()).pipe(
    map(response => ({
      success: true,
      response,
      action,
    })),
    catchError(() => of({
      success: false,
      action: { type: 'Todo: post a message here' },
    })),
  )),
  switchMap((results) => {
    if (results.success) {
      return of(signOutSuccess());
    }
    if (results.actions) return of(...results.actions);
    return of(results.action);
  }),
);

export const authEpics = combineEpics(signOutEpic);

export const dummy = '';
