import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '../Table';
import NumberCell from '../NumberCell';
import PlayerInfo from '../PlayerInfo';
import CellContent from '../CellContent';
import queryString from 'query-string';

class LeaderboardRow extends React.PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    isViewExpanded: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    athlete: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    isSortByColumn: PropTypes.func.isRequired,
    getColumnWidth: PropTypes.func.isRequired,
    selectedScoreType: PropTypes.string.isRequired,
    measureableColumns: PropTypes.array.isRequired,
    isCustomWidth: PropTypes.bool.isRequired,
    schoolStyle: PropTypes.object,
    showFilterRank: PropTypes.bool,
    enableAthleteLink: PropTypes.bool.isRequired,
    isAllTimeLeaderboard: PropTypes.bool,
    sport: PropTypes.string,
  }

  state = {
    screenWidth: 1600,
    expanded: false,
    prevIsViewExpanded: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isViewExpanded !== state.prevIsViewExpanded) {
      return {
        prevIsViewExpanded: props.isViewExpanded,
        expanded: props.isViewExpanded,
      };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  updateDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  }

  render() {
    const {
      index,
      athlete,
      columns,
      isSortByColumn,
      getColumnWidth,
      selectedScoreType,
      measureableColumns,
      isCustomWidth,
      schoolStyle,
      showFilterRank,
      enableAthleteLink,
      isAllTimeLeaderboard,
      sport,
      location,
      match,
      isLoggedIn,
      isCoach
    } = this.props;
    const search = queryString.parse(location.search);
    // console.log('checking filter', search);

    const { expanded, screenWidth } = this.state;
    let height = 120;
    const isMobile = screenWidth < 768;
    const isTablet = screenWidth < 992;
    if (isMobile) {
      height = 100;
    }
    if (expanded) {
      height = isMobile ? 150 : 200;
    }
    const getWidth = (customWidth, defaultWidth, left = null) => {
      if (isMobile) {
        return { height };
      }
      if (left && left.width) {
        return { width: isCustomWidth ? customWidth : defaultWidth, height, left: left.width };
      }
      return { width: isCustomWidth ? customWidth : defaultWidth, height };
    };
    const numberCellWidth = getWidth(isMobile || isTablet ? 50 : 65, '5%');
    const playerInfoWidth = getWidth(isMobile || isTablet ? 210 : 310, '25%', numberCellWidth);
    let rank = athlete.filterRank ? athlete.filterRank
                  : athlete.rank ? athlete.rank
                  : index + 1;
    if(isAllTimeLeaderboard){
      rank = athlete.rank;
    }
    return (
      <TableRow style={{ height }} className={index % 2 === 0 ? 'even' : 'odd'}>
        {
          showFilterRank && (
            <TableCell className="LeaderboardTable_NumberCell" style={numberCellWidth}>
              <NumberCell rank={athlete.filterRank} athlete={athlete}/>
            </TableCell>
          )
        }
        <TableCell className="LeaderboardTable_NumberCell" style={numberCellWidth}>
          <NumberCell rank={rank} athlete={athlete}/>
        </TableCell>
        <TableCell className="LeaderboardTable_PlayerInfoCell" style={playerInfoWidth}>
          <PlayerInfo
            expanded={expanded}
            athlete={athlete}
            schoolStyle={schoolStyle}
            onExpandClick={this.onExpandClick}
            measureableColumns={measureableColumns}
            profilePictureHeight={height}
            enableAthleteLink ={ enableAthleteLink }
            isAllTimeLeaderboard = {isAllTimeLeaderboard}
            sport={sport}
            isLoggedIn = {isLoggedIn}
            isCoach = {isCoach}
            match={match}
          />
        </TableCell>
        {
          columns.map((column, idx) => (
            <TableCell
              key={idx}
              className={isSortByColumn(column) ? 'sortBy' : ''}
              style={{ width: getColumnWidth(idx), height }}
            >
              <CellContent
                athlete={athlete}
                column={column}
                location={location}
                match={match}
                isSortByColumn={isSortByColumn(column)}
                selectedScoreType={selectedScoreType}
              />
            </TableCell>
          ))
        }
      </TableRow>
    );
  }
}

export default LeaderboardRow;
