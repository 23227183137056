import { combineReducers } from 'redux';
import { MOBILE_SELECT_PROFILE, MOBILE_SELECT_SPORT } from '../../../actions/ui/mobile';
import { USER_GET_PROFILE_SUC, USER_SPORT_UPDATE_SUC } from '../../../actions/data/user';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../../actions/data/cognito';
import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_FAILED,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_PENDING,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils/';
import { ASYNC_ERROR, ASYNC_FINISH } from '../../../actions/ui/async';

const authenticating = (state = false, action) => {
  switch (action.type) {
    case FORM_SUBMIT_PENDING:
      return true;
    case FORM_SUBMIT_FAILED:
    case FORM_SUBMIT_SUCCESS:
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_INTENT:
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      return false;
    default:
      return state;
  }
};

const loadingProfile = (state = true, action) => {
  switch (action.type) {
    case USER_GET_PROFILE_SUC:
      return false;
    // case PP_GET_REQ:
    case AUTH2_LOGOUT_SUC:
      return true;
    default:
      return state;
  }
};

const currentSport = (state = null, action) => {
  switch (action.type) {
    case MOBILE_SELECT_SPORT:
      return action.sport;
    case USER_SPORT_UPDATE_SUC:
      if (action.user.sports.length) {
        return action.user.sports.find(s => (!!s.primary));
      }
      return state;
    case USER_GET_PROFILE_SUC:
      if (!state && action.data && action.data.sports && action.data.sports.length) {
        return action.data.sports.find(s => (!!s.primary));
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const currentUuid = (state = '', action) => {
  switch (action.type) {
    case MOBILE_SELECT_PROFILE:
      return action.uuid;
    default:
      return state;
  }
};

const reducer = combineReducers({
  currentSport,
  currentUuid,
  loadingProfile,
  authenticating,
});

export default reducer;
