import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../common/Icon';
import { ButtonRect } from '../../../../components/UxCommon';
import { resetGridEmailProfile } from '../../+store/roster';

export const sortedGrade = {
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  13: '13th',
  12: '12th',
  11: '11th',
  10: '10th',
  9: '9th',
  8: '8th',
  7: '7th',
  6: '6th',
  5: '5th',
  4: '4th',
  3: '3rd',
  2: '2nd',
  1: '1st',
  K: 'K',
};

const ConfirmAthleteModal = ({ onConfirmAthlete, onCancelAthlete }) => {
  const { currentProfile } = useSelector(state => ({
    currentProfile: state.modules.coachWorld.roster.currentProfile,
  }));
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const handleCancel = () => {
    if (onCancelAthlete) {
      onCancelAthlete();
    }
    dispatch(resetGridEmailProfile());
  };

  const handleConfirm = () => {
    if (onConfirmAthlete) {
      onConfirmAthlete();
    }
    dispatch(resetGridEmailProfile());
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  if (!currentProfile) {
    return null;
  }

  const playerInfo = [];
  if (currentProfile.gender) {
    playerInfo.push(currentProfile.gender);
  }
  if (currentProfile.grade && sortedGrade[currentProfile.grade]) {
    playerInfo.push(sortedGrade[currentProfile.grade]);
  }

  return (
    <div className="CoachWorld_ConfirmAthleteModal_Backdrop">
      <div className="CoachWorld_ConfirmAthleteModal_Wrapper" ref={wrapperRef}>
        <div className="CoachWorld_ConfirmAthleteModal_Title">There is another athlete registered with the same email.
          Virtual Combine will merge their data with the data you added to this record.
          We can not revert the merge once you confirm it.
          Are you sure you want to merge the records?
        </div>
        <div className="CoachWorld_ConfirmAthleteModal_ProfileInfoWrapper">
          {
            currentProfile.photoUrl ? (
              <div
                className="CoachWorld_ConfirmAthleteModal_Img"
                style={{
                  backgroundImage: `url(${currentProfile.photoUrl})`,
                }}
              />
              ) : (
                <div className="CoachWorld_ConfirmAthleteModal_Img">
                  <Icon iconName="photo2" className="CoachWorld_ConfirmAthleteModal_Icon" />
                </div>
              )
          }
          <div className="CoachWorld_ConfirmAthleteModal_ProfileInfo">
            <span className="name">{`${currentProfile.first} ${currentProfile.last}`}</span>
            <span>{currentProfile.email}</span>
            { !!playerInfo.length && <span className="info">{playerInfo.join(' | ')}</span> }
          </div>
        </div>
        <div className="CoachWorld_ConfirmAthleteModal_Buttons">
          <ButtonRect
            className="CoachWorld_ConfirmAthleteModal_Button"
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </ButtonRect>
          <ButtonRect
            className="CoachWorld_ConfirmAthleteModal_Button primary"
            type="button"
            onClick={handleConfirm}
          >
            Merge Records
          </ButtonRect>
        </div>
      </div>
    </div>
  );
};

ConfirmAthleteModal.prototype = {
  onConfirmAthlete: PropTypes.func,
  onCancelAthlete: PropTypes.func,
};

export default ConfirmAthleteModal;
