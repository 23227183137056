import { asyncStartAction } from '../../async';

export const PLAYER_PROFILE_SHOW_BIO = 'uxProfile.playerProfile.showBio';
export const playerProfileShowBio = () => ({
  type: PLAYER_PROFILE_SHOW_BIO,
});

export const PLAYER_PROFILE_ACTIVATION_MEMBERSHIP_MODAL = 'uxProfile.activateMembershipModal';
export const playerProfileActivationMembershipModal = (toggle) => ({
  type: PLAYER_PROFILE_ACTIVATION_MEMBERSHIP_MODAL,
  toggle,
});
export const PLAYER_PROFILE_HIDE_BIO = 'uxProfile.playerProfile.hideBio';
export const playerProfileHideBio = () => ({
  type: PLAYER_PROFILE_HIDE_BIO,
});

export const PLAYER_POSITION_SHOW_DETAIL = 'uxProfile.playerProfile.showPositionDetail';
export const playerPositionShowDetail = () => ({
  type: PLAYER_POSITION_SHOW_DETAIL,
});
export const PLAYER_POSITION_HIDE_DETAIL = 'uxProfile.playerProfile.hidePositionDetail';
export const playerPositionHideDetail = () => ({
  type: PLAYER_POSITION_HIDE_DETAIL,
});

export const PLAYER_POSITION_DETAIL = 'uxProfile.playerProfile.playerPositionDetail';
export const playerPositionDetail = posDetail => ({
  type: PLAYER_POSITION_DETAIL,
  positionDetail: posDetail,
});

export const PP_GET_REQ = 'profile.playerProfile.get';
export const playerProfileGet = uuid => (dispatch) => {
  dispatch(asyncStartAction(PP_GET_REQ));
  dispatch({
    type: PP_GET_REQ,
    uuid,
  });
};

export const PLAYER_PROFILE_DUPLICATES = 'profile.playerProfileDuplicates.get';
export const playerProfileDuplicatesGet = uuid => (dispatch) => {
  dispatch(asyncStartAction(PLAYER_PROFILE_DUPLICATES));
  dispatch({
    type: PLAYER_PROFILE_DUPLICATES,
    uuid,
  });
};


export const PLAYER_PROFILE_DUPLICATES_SET = 'profile.playerProfileDuplicates.set';
export const playerProfileDuplicatesSet = (uuid, email, first, last, inviteUuid) => (dispatch) => {
  dispatch({
    type: PLAYER_PROFILE_DUPLICATES_SET,
    data: {
      uuid,
      email,
      first,
      last,
      inviteUuid
    }
  });
};


export const PROFILE_SHOW_CREATE_NEW_ACCOUNT = 'profile.showCreateNewAccount';
export const profileShowCreateNewAccount = (value) => (dispatch) => {
  dispatch({
    type: PROFILE_SHOW_CREATE_NEW_ACCOUNT,
    value
  });
};

export const GET_PROFILE_BY_EMAIL = 'profile.getProfileByEmail';
export const getProfileByEmail = uuid => (dispatch) => {
  dispatch(asyncStartAction(GET_PROFILE_BY_EMAIL));
  dispatch({
    type: GET_PROFILE_BY_EMAIL,
    uuid,
  });
};


export const PROFILE_DATA_GET = 'profile.playerData.get';
export const playerDataGet = (uuid, sportId) => (dispatch) => {
  dispatch(asyncStartAction(PROFILE_DATA_GET));
  dispatch({
    type: PROFILE_DATA_GET,
    uuid,
    sportId,
  });
};

export const GET_FAVORITES = 'playerProfile.photos.getFavorites';
export const getFavorites = (uuid, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_FAVORITES, '', {
    uuid,
    sportId,
  }));
  dispatch({
    type: GET_FAVORITES,
    uuid,
    sportId,
  });
};

export const GET_COACH_SCHOOLS = 'playerProfile.getCoach';
export const getCoachData = (uuid) => (dispatch) =>{
  dispatch({
    type: GET_COACH_SCHOOLS,
    uuid,
  })
}

export const SAVE_PRIVACY = 'playerProfile.savePrivacy';
export const savePrivacy = (toggle, privacyType, combineId) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_PRIVACY, '', {
    toggle,
    privacyType,
    combineId
  }));
  dispatch({
    type: SAVE_PRIVACY,
    toggle,
    privacyType,
    combineId
  });
};

export const GET_PLAYER_TEAMS_AND_DETAILS = 'playerProfile.teams.getAll';
export const getPlayerTeamsAndDetails = (uuid, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_PLAYER_TEAMS_AND_DETAILS, '', {
    uuid,
    sportId,
  }));
  dispatch({
    type: GET_PLAYER_TEAMS_AND_DETAILS,
    uuid,
    sportId,
  });
};

