import { connect } from 'react-redux';
import Component from '../../components/UxEditProfile/EditSports/UpdateProfilePhotoDialog/PhotoCropDialog/PhotoCropDialog';

import {
  saveNewProfilePhoto,
  discardNewProfilePhoto,
} from '../../store/actions/ui/uxEditProfile';

const mapStateToProps = state => ({
  photoDataToCrop: state.ui.uxEditProfile.profilePhoto.photoDataToCrop,
  loading: state.ui.uxEditProfile.profilePhoto.photoCropDataLoading,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    savePhoto: (body) => {
      const origFile = state.ui.uxEditProfile.profilePhoto.photoFileDropped;
      const sportId = state.ui.app.routes.currentSportId;
      const msg = 'Uploading your new profile photo';
      saveNewProfilePhoto(dispatch)(body, origFile.name, sportId, msg);
    },
    onCancel: discardNewProfilePhoto(dispatch),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
