import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CloseButton, LoadingSpinner, ButtonRect } from '../../../UxCommon';
import Album from './Album/Album';
import DeleteAlbumDialog from '../AlbumDialog/DeleteAlbumDialog/DeleteAlbumDialog';

class SelectAlbumDialog extends PureComponent {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    albums: PropTypes.array.isRequired,
    onAlbumClick: PropTypes.func.isRequired,
    onAddAlbumClick: PropTypes.func.isRequired,
    onDeleteAlbumOnlyClick: PropTypes.func.isRequired,
    onDeleteEverythingClick: PropTypes.func.isRequired,
  }

  state = {
    scrollTop: 0,
    albumToDelete: null,
  };

  componentWillMount() {
    this.scrollElement = document.getElementById('uxProfileRight');
    // this.scrollElement.addEventListener('scroll', this.onScroll);
    this.saveScrollTop(this.scrollElement.scrollTop);
    this.setState({
      albumToDelete: null,
    });
  }

  onDeleteAlbumClick = (album) => {
    this.setState({
      albumToDelete: album,
    });
  }

  onCancelDeleteAlbumClick = () => {
    this.setState({
      albumToDelete: null,
    });
  };

  onDeleteAlbumOnlyClick = () => {
    const { onDeleteAlbumOnlyClick } = this.props;
    const { albumToDelete } = this.state;
    if (albumToDelete) {
      onDeleteAlbumOnlyClick(albumToDelete);
      this.setState({
        albumToDelete: null,
      });
    }
  };

  onDeleteEverythingClick = () => {
    const { onDeleteEverythingClick } = this.props;
    const { albumToDelete } = this.state;
    if (albumToDelete) {
      onDeleteEverythingClick(albumToDelete);
      this.setState({
        albumToDelete: null,
      });
    }
  };

  getPaddingTop = () => {
    const { albums } = this.props;
    const { scrollTop } = this.state;
    if (scrollTop > 272) {
      return scrollTop - 236;
    }
    if (albums.length >= 4) {
      return 36;
    }
    return 115;
  }

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  render() {
    const {
      onCloseClick,
      loading,
      loadingMsg,
      albums,
      onAlbumClick,
      onAddAlbumClick,
    } = this.props;
    const { albumToDelete } = this.state;
    return (
      <div
        className="Photos_SelectAlbumDialog_Backdrop"
        style={{ paddingTop: `${this.getPaddingTop()}px` }}
      >
        <div className="Photos_SelectAlbumDialog">
          <CloseButton onClick={onCloseClick} size={10} style={{ top: 22, right: 18 }} />
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
          {
            albumToDelete && (
              <DeleteAlbumDialog
                albumTitle={albumToDelete.title}
                onDeleteEverythingClick={this.onDeleteEverythingClick}
                onDeleteAlbumOnlyClick={this.onDeleteAlbumOnlyClick}
                onCancelClick={this.onCancelDeleteAlbumClick}
              />
            )
          }
          <div className="Photos_SelectAlbumDialog_Title">
            Select an album to edit
          </div>
          <div className="Photos_SelectAlbumDialog_Albums">
            {
              albums.map(album => (
                <Album
                  key={album.id}
                  album={album}
                  onClick={onAlbumClick}
                  onDeleteClick={this.onDeleteAlbumClick}
                />
              ))
            }
          </div>
          <div className="Photos_SelectAlbumDialog_BtnsRow">
            <ButtonRect
              className="Photos_SelectAlbumDialog_Btn primary"
              onClick={onAddAlbumClick}
            >
              Add Album
            </ButtonRect>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectAlbumDialog;
