export const cognitoErrors = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  switch (error.code) {
    case 'UserNotFoundException':
      return 'Your email or password was entered incorrectly.';
    case 'InvalidParameterException':
      if (error.message.indexOf('registered/verified email or phone_number') >= 0) {
        return 'You must confirm your account for password assistance';
      }
      return 'Please enter a valid email or phone number';
    case 'UserNotConfirmedException':
      return '';
    case 'UsernameExistsException':
      return 'Another user with that email address already exists';
    default:
      if (error.message) {
        return 'Your email or password was entered incorrectly.'; // error.message;
      }
      return `Error: ${JSON.stringify(error)}`;
  }
};

export default 'accountPage.errors.js';
