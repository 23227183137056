import { connect } from 'react-redux';

import Component from '../../components/UxEditProfile/DeleteAccount/DeleteAccount';
import { accountDelete } from '../../store/actions/ui/uxEditProfile';

const mapStateToProps = state => ({
  loading: state.ui.uxEditProfile.deleteAccount.loading,
  loadingMsg: state.ui.uxEditProfile.deleteAccount.loadingMsg,
  uuid: state.data.cognito.uuid,
  sportId: state.ui.app.routes.currentSportId,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    deleteAccount: accountDelete(dispatch),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
