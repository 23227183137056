import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../UxCommon/Icon/Icon';

const CloseLink = ({
  to, className, iconClassName, ...props
}) => (
  <Link className={`UxCommon_CloseLink ${className || ''}`} to={to} {...props}>
    <Icon className={`UxCommon_CloseLink_Icon ${iconClassName}`} iconName="close" />
  </Link>
);
CloseLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default CloseLink;
