import React from 'react';
import PropTypes from 'prop-types';
import PerformanceData from '../../../../../containers/UxProfile/PlayerData/PerformanceData';
import StrengthData from '../../../../../containers/UxProfile/PlayerData/StrengthData';
import { ContainerFluid, Row, Col, Collapsable } from '../../../../UxCommon';

const TestData = ({ match }) => (
  <ContainerFluid className="TestDataContainer p0">
    <Row className="TestDataRow">
      <Col className="TestDataCol1 col-12 col-sm-6">
        <Collapsable title="Performance History" className="TestDataCollapsable1">
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              <PerformanceData match={match} />
            }
          </div>
        </Collapsable>
      </Col>
      <Col className="TestDataCol1 col-12 col-sm-6">
        <Collapsable title="Strength History" className="TestDataCollapsable1">
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              <StrengthData match={match} />
            }
          </div>
        </Collapsable>
      </Col>
    </Row>
  </ContainerFluid>
);
TestData.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TestData;
