import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ControlErrors extends PureComponent {
  static propTypes = {
    errors: PropTypes.array,
  }

  componentDidMount() {}

  render() {
    const { errors } = this.props;
    return (!!errors && !!errors.length && (
      <div className="CoachForm_ControlErrors">
        <i className="CoachForm_ControlErrors_Icon icon-alert-icon" />
        {
          errors.map((error, index) => (
            typeof error === 'string' ? (
              error && (
                <div key={index} className="CoachForm_ControlError">
                  {error}
                </div>
              )
            ) : (
              error && (
                <div key={index}>
                  {error}
                </div>
              )
            )
          ))
        }
      </div>
    )) || null;
  }
}

export default ControlErrors;
