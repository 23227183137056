import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';

import { cognitoForgotPassword, cognitoForgotPasswordSuccess } from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateIsPhoneOrEmail,
  validateLength,
  validatePhone,
  validateRequired,
  validatePasswordLength
} from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';

import { emailToUsername, formatPhoneForCognito, phoneToUsername } from '../../../../utils/cognito';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const forgotPassFormValidation = {
  'forms.forgot.username': [
    value => validateLength(1)(value),
    value => validateIsPhoneOrEmail()(value),
    value => validateRequired()(value),
  ],
};

const forgotPassSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.forgot;

  return action$.pipe(
    isPendingIsValid(state$, 'forms.forgot', 'forgot'),
    switchMap((action) => from(cognitoForgotPassword(form().username))
      .pipe(
        map(awsResponse => ({
          success: true,
          awsResponse,
          action,
          username: form().username,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError((error) => {
          if (error.code === 'UserNotFoundException') {
            const { username } = form();
            let cognitoUsername;
            if (validatePhone()(username).phone === '') {
              cognitoUsername = phoneToUsername(formatPhoneForCognito(username));
            } else {
              cognitoUsername = emailToUsername(username);
            }
            return from(cognitoForgotPassword(cognitoUsername))
              .pipe(map(awsResponse => ({
                success: true,
                action,
                awsResponse,
                username: cognitoUsername,
              })));
          }
          throw error;
        }),
        catchError(error => of({
          success: false,
          action: formServerError('forms.forgot', 'aws', error),
        })),
      )),
    switchMap(({
      success,
      awsResponse,
      action,
      username,
    }) => {
      if (success) {
        return of(
          formSubmitSuccess('forms.forgot', {
            awsResponse,
            username,
            toRedirectUrl: action.attrs.toRedirectUrl,
          }),
          cognitoForgotPasswordSuccess(awsResponse),
        );
      }
      return of(action);
    }),
  );
};

export const forgotPassFormEpic = combineEpics(
  forgotPassSubmitEpic,
  usernameOnChangeEpic('forms.forgot.username', state => state.forms.forgot.username),
  validationOnSubmitEpicFactory(forgotPassFormValidation, 'forms.forgot', 'forgot'),
  validationFailedEpicFactory('forms.forgot', 'forgot'),
  ...formValidationEpicsFactory(forgotPassFormValidation, 'forgot'),
);

export const FORGOT_PASS_FORM_INIT = 'FORGOT_FORM_INIT';
export const forgotPassFormInit = () => ({
  type: FORGOT_PASS_FORM_INIT,
});
