import { connect } from 'react-redux';
import Component from './EditPlayerModal.component';
import {updateAthleteInfo, selectLoadingMsg, removeUserFromTeam, selectSchoolStyle} from '../../+store';
import { removeAthletePhoto, photoModeEnabled, photoModeDisabled } from '../../+store/roster/roster.photo.actions';

// const mapStateToProps = (state, ownProps) => ({
 const mapStateToProps = (state, ownProps) => {
   const athletes = state.modules.coachWorld.roster.athletes;
   const athlete = athletes
     .find(a => a.id === ownProps.match.params.athleteId);
   const athleteTeams = athletes.filter( (filter) => filter.userId === athlete.userId)
     .map( (athlete) =>  athlete.schoolTeamId )
   const schoolTeams = state.modules.coachWorld.roster.schoolTeams;
   const schoolTeamsLists = schoolTeams.filter( schoolTeam => schoolTeam.id === athlete.schoolTeamId );

   return {
     athlete:athlete,
     teams: state.modules.coachWorld.roster.schoolTeams,
     myTeam: state.modules.coachWorld.coachWorld.myTeams,
     loading: state.modules.coachWorld.roster.savingProfiles || !!selectLoadingMsg(state, ownProps),
     loadingMsg: state.modules.coachWorld.roster.loadingMsg,
     schoolId: ownProps.match.params.schoolId,
     uuid: state.data.cognito.uuid,
     addingPhotoModeEnabled: state.modules.coachWorld.roster.addingPhotoModeEnabled,
     athleteTeams,
     isPrivateTeam : schoolTeamsLists && schoolTeamsLists[0] &&  schoolTeamsLists[0].isPrivate || false,
     schoolStyle: selectSchoolStyle(state, ownProps) || {},
   }
 };

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    savePlayer: (values, athleteId, userId, schoolId) => {
      let tempUserId = userId;
      if (ownProps.match.url.includes('school')) {
        tempUserId = ownProps.uuid;
      }
      const schoolValues = {
        position: values.position,
        jersey_number: values.jerseyNumber,
        school_team_id: values.schoolTeamId,
        user_team_id: athleteId,
      };
      dispatch(updateAthleteInfo(
        tempUserId,
        athleteId,
        schoolValues,
        ownProps.match,
        schoolId,
        ownProps.location,
      ));
    },
    removePlayerImage: (athleteId) => {
      dispatch(removeAthletePhoto(athleteId));
    },
    enablePhotoMode: () => {
      dispatch(photoModeEnabled());
    },
    disablePhotoMode: () => {
      dispatch(photoModeDisabled());
    },
    removeUserFromTeam: (userTeamId) => {
      dispatch(removeUserFromTeam(userTeamId));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
