import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, ButtonLinkRect, CloseLink, LinkButton } from '../../../../components/UxCommon';
import Icon from '../../common/Icon';
import TextControl from '../FormControls/TextControl/TextControl.component';
import SelectControl from '../FormControls/SelectControl';
import LoadingIndicator from '../LoadingIndicator';
import { positions } from '../../../../components/UxCommon/Form/utils';
import { Routes } from '../../../../store/actions/ui/routes';
import UpdatePhoto from '../UpdatePhotoDialog/UpdatePhotoDialog.container';
import ArchiveCombineDialog from '../../Dashboard/Combines/ArchiveCombineDialog/ArchiveCombineDialog.component';

import { Link } from 'react-router-dom';


class EditPlayerModal extends PureComponent {
  static propTypes = {
    athlete: PropTypes.object,
    teams: PropTypes.array.isRequired,
    savePlayer: PropTypes.func.isRequired,
    match: PropTypes.object,
    loadingMsg: PropTypes.string,
    loading: PropTypes.bool,
    removePlayerImage: PropTypes.func,
    schoolId: PropTypes.string.isRequired,
    enablePhotoMode: PropTypes.func.isRequired,
    disablePhotoMode: PropTypes.func.isRequired,
    addingPhotoModeEnabled: PropTypes.bool.isRequired,
    removeUserFromTeam: PropTypes.func,
    history: PropTypes.object.isRequired,
    isPrivateTeam: PropTypes.bool,
    schoolStyle: PropTypes.any
  }

  state = {
    errors: {},
    form: {},
    positions: [],
    showDeleteDialog: false,
  }

  onAddClick = () => {
    const { history, match } = this.props;
    history.push(`/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}`);
  }

  onValueChange = name => (value) => {
    const errors = {};
    let adjustedValue;
    switch (name) {
      case 'name':
        if (!value) {
          errors.name = ['This field is required'];
        } else {
          errors.name = [];
        }
        adjustedValue = value;
        break;
      case 'number':
        if (!value) {
          errors.number = ['This field is required'];
        } else if (  ! /^[a-zA-Z0-9- '",_]*$/.test(value) ) {
          errors.number = ['Validation failed']
        } else {
          errors.number = [];
        }
        adjustedValue = value;
        break;
      case 'team':
        if (!value) {
          errors.team = ['This field is required'];
        } else {
          errors.team = [];
        }
        adjustedValue = value;
        break;
      case 'position':
        if (!value) {
          errors.position = ['This field is required'];
        } else {
          errors.position = [];

          // form is not tracking the multiple positions, so adding them in state
          this.setState({
            ...this.state,
            positions: value.join(','),
          });
        }
        adjustedValue = value;
        break;
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      form: Object.assign({}, this.state.form, errors),
    });
    return adjustedValue;
  }

  onClickSavePlayer = (e) => {
    const form = this.formRef.current;
    const values = {};
    const { athlete, savePlayer, schoolId ,history , match, isPrivateTeam} = this.props;
    values.position = this.state.positions;
    // values.schoolTeamId = form.team.value;
    values.schoolTeamId = isPrivateTeam ? athlete.schoolTeamId :  form.team.value;
    values.jerseyNumber = form.number.value;
    if (Object.values(this.state.errors).reduce((x, y) => x + y.length, 0) === 0) {
      savePlayer(values, athlete.id, athlete.userId, schoolId);
      history.push({
        pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}`,
        search: history.location.params,
      });

    }
    e.preventDefault();
  }

  handleRemovePhoto = () => {
    this.props.removePlayerImage(this.props.athlete.id);
  }

  handleCloseUpdatePhoto = () => {
    const { disablePhotoMode } = this.props;
    disablePhotoMode();
  }

  handleAddPhoto = () => {
    const { enablePhotoMode } = this.props;
    enablePhotoMode();
  }

  handleDeleteClick = () => (this.setState({ showDeleteDialog: true }))
  handleCloseDeleteDialog = () => (this.setState({ showDeleteDialog: false }))
  handleConfirmDelete = () => {
    const {
      athlete,
      removeUserFromTeam,
      history,
      match,
    } = this.props;
    removeUserFromTeam(athlete.id);

    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}`,
      search: history.location.params,
    });
    this.setState({ showDeleteDialog: false });
  };

  onClickCancel = () => {
    const {
      history,
      match,
    } = this.props;
    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}`,
      search: history.location.params,
    });
    this.setState({ showDeleteDialog: false });
  }

  closeClick = () => {
    const {
      history,
      match,
    } = this.props;
    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}`,
      search: history.location.params,
    });
  }

  formRef = React.createRef();

  render() {
    const {
      athlete, teams, match, loadingMsg, loading, addingPhotoModeEnabled, myTeam,schoolId, athleteTeams,isPrivateTeam,schoolStyle
    } = this.props;
    const { errors, showDeleteDialog } = this.state;
    let headshotUrl = '';
    if ( athlete && athlete.headshotUrl )
    {
      headshotUrl = athlete.headshotUrl;
    } else if (schoolStyle )
    {
      headshotUrl = schoolStyle.school_logo
    }
    let team;
    if (addingPhotoModeEnabled) {
      return (
        <div className="CoachWorld_EditPlayer_Backdrop">
          <div className="CoachWorld_EditPlayer_Modal">
            <UpdatePhoto
              closeDialog={this.handleCloseUpdatePhoto}
              match={match}
              photoUrl={athlete.headshotUrl}
            />
          </div>
        </div>
      );
    }

    let teamOption = {};
    let sportPositions = [];
    let sportPositionObject = {};
    if (athlete && teams) {
      team = teams.find(t => t.id === athlete.schoolTeamId);
      if (myTeam) {

        const mySportTeam = myTeam.filter(mt => mt.schoolTeams[0].active && mt.sportId === team.sportId && mt.schoolId === schoolId);
        mySportTeam.forEach((innerTeam) => {
          const { id, name } = innerTeam.schoolTeams[0];
          teamOption = {
            ...teamOption,
            [id]: name,
          };
        });
      }
      if (team) {
        sportPositions = positions(team.sportId, athlete.gender);
        sportPositions.forEach((sport) => {
          const { value, name } = sport;
          sportPositionObject = {
            ...sportPositionObject,
            [value]: name,
          };
        });
      }
    }
    return (
      <div className="CoachWorld_EditPlayer_Backdrop">
        <div className="CoachWorld_EditPlayer_Modal">
          <LoadingIndicator
            loading={loading}
            loadingMsg={loadingMsg}
            wrapperStyle={{
              position: 'relative',
            }}
            positionStyle={{
              marginTop: 40,
              marginBottom: 40,
            }}
          />
          {
            showDeleteDialog &&
            <ArchiveCombineDialog
              onArchiveClick={this.handleConfirmDelete}
              onCancelClick={this.handleCloseDeleteDialog}
              msg={`Are you sure you want to remove ${athlete.name} from ${team.name}?`}
              title="Remove User?"
              btnMsg="Remove"
            />
          }
          <p className="UxCommon_CloseLink CoachWorld_EditPlayer_CloseLink" onClick={this.closeClick}>
            <Icon className="UxCommon_CloseLink_Icon CoachWorld_EditPlayer_CloseLink_I" iconName="close" />
          </p>

         <div className="CoachWorld_EditPlayer_Container" style={{ display: loading ? 'none' : '' }}>
            <div className="CoachWorld_EditPlayer_Img">
              {
                headshotUrl  ? (
                  <div
                    className="CoachWorld_EditPlayer_PhotoImg"
                    style={{
                        backgroundImage: `url(${headshotUrl})`,
                      }}
                  />
                  ) :
                  (
                    <div className="CoachWorld_EditPlayer_PhotoImg">
                      <Icon iconName="photo2" className="CoachWorld_EditPlayer_PhotoIcon" />
                    </div>
                  )
              }
              <div className="CoachWorld_EditPlayer_PhotoCol_Buttons">
                <ButtonRect
                  className="CoachWorld_Edit_Player_PhotoCol_Button"
                  onClick={this.handleAddPhoto}
                  type="button"
                >
                  {
                      athlete && athlete.headshotUrl
                        ? 'Update Photo'
                        : 'Add a Photo'
                    }
                </ButtonRect>
              </div>
              <LinkButton iconName="trash" onClick={this.handleDeleteClick} type="button">
                Remove User From Team
              </LinkButton>
            </div>
            <div className="CoachWorld_EditPlayer_RS">
              {
                athlete && (
                  <React.Fragment>
                    <div className="CoachWorld_EditPlayer_Header">
                      {athlete.name}
                    </div>
                    <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachWorld_EditPlayer_Form">
                      <TextControl
                        defaultValue={athlete.jerseyNumber || ''}
                        placeholder="Jersey Number"
                        className="CoachWorld_EditPlayer_Ctrl"
                        name="number"
                        label="Player Number"
                        myOnChange={this.onValueChange('number')}
                        myOnBlur={this.onValueChange('number')}
                        errors={errors.number}
                      />
                      <SelectControl
                        name="position"
                        label="Player Position"
                        placeholder="Player Position"
                        className="CoachWorld_EditPlayer_Ctrl"
                        options={sportPositionObject}
                        myOnChange={this.onValueChange('position')}
                        myOnBlur={this.onValueChange('position')}
                        errors={errors.position}
                        defaultValue={athlete.position ? athlete.position.replace(/\s/g, '').split(',') : ''}
                        multi
                      />
                      { !isPrivateTeam &&
                        <SelectControl
                          label="Team"
                          name="team"
                          className="CoachWorld_EditPlayer_Ctrl"
                          options={teamOption}
                          errors={errors.team}
                          myOnChange={this.onValueChange('team')}
                          myOnBlur={this.onValueChange('team')}
                          defaultValue={athlete.schoolTeamId}
                          disabledOptions={athleteTeams}
                        />
                      }
                      <div className="CoachWorld_EditPlayer_Btns">
                        <ButtonRect
                          style={{ marginLeft: 'auto' }}
                          className="Photos_ExpandedPhotoDialog_Button Photos_ExpandedPhotoDialog_Button_Width"
                          type="button"
                          onClick={this.onClickCancel}
                        >
                          Cancel
                        </ButtonRect>
                        <ButtonRect
                          className="Photos_ExpandedPhotoDialog_Button primary"
                          style={{ width: '120px' }}
                          type="button"
                          onClick={this.onClickSavePlayer}
                        >
                          Save
                        </ButtonRect>
                      </div>
                    </form>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPlayerModal;
