import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { actions } from 'react-redux-form';
import { combineEpics, ofType } from 'redux-observable';

import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import {
  mapUserStatUiToApi,
  Season,
  statsGetUserStatsSuccess,
  statsSeasonCreate,
  statsUserStatCreate,
  statsUserStatUpdate,
} from '../../../../data/sport';
import { Routes } from '../../../routes';

import { ASYNC_ERROR, ASYNC_FINISH, asyncErrorAction, asyncFinishAction } from '../../../async';

import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_PENDING,
  formSubmitFailed,
  formSubmitPending,
  formSubmitSuccess,
} from '../../../formUtils';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';

import { validateRequired, validateSeasonDates } from '../../../formUtils/validators';
import { getPlayerStatsEpicFragment } from './read';
import {
  isStatsAddSeasonFormModel,
  isStatsFormModel,
  openNewSeasonRow,
  PD_CREATE_UPDATE_STATS,
  PD_STATS_CANCEL_EDIT_TABLE,
  PD_STATS_CANCEL_NEW_SEASON,
  statsAddSeasonFormDataObject,
  statsAddSeasonFormObject,
  statsSeasonTableFormDataObject,
  statsSeasonTableFormModel,
  statsSeasonTableFormObject,
} from '../actions';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { getRandomInt } from '../../../../../../utils/cognito';
import {
  createTeam,
  mapTeamApiToUi,
  mapTeamsApiToUiFlat,
  teamCreateSucceess,
  typeAheadGetTeams,
} from '../../../../data/sport/teams';

export const createUpdateValidateOnSubmitEpic = action$ => (
  action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_INTENT) && (isStatsFormModel(action.model))) {
        return true;
      }
      return false;
    }),
    switchMap((action) => {
      const errors = [];
      if (errors.length) {
        return of(
          formSubmitFailed(action.model, action.attrs),
          ...errors,
        );
      }
      return of(
        actions.setErrors(
          statsSeasonTableFormModel(action.attrs.teamId, action.attrs.positionId),
          { general: '' },
        ),
        formSubmitPending(action.model, action.attrs),
      );
    }),
  )
);

export const createUpdateSeasonEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_PENDING) && (isStatsFormModel(action.model))) {
        const rrfForm = statsSeasonTableFormObject(
          state$.value,
          action.attrs.teamId,
          action.attrs.positionId,
        );
        return rrfForm.$form.valid;
      }
      return false;
    }),
    getTokenFragment(),
    switchMap(
      ({ action, token }) => {
        const forms = statsSeasonTableFormDataObject(
          state$.value,
          action.attrs.teamId,
          action.attrs.positionId,
        );
        return forkJoin(...Object.values(forms.seasons).filter(s => !!s).map((row) => {
          let apiCall;
          if (row.new) {
            const seasonInfo = action.attrs.newSeasons.find(ns => ns.rowId === row.rowId);
            apiCall = statsSeasonCreate(seasonInfo.season.toApiPost(isCoach() ? uuid() : null), token).pipe(
              switchMap(response => forkJoin(...Object.values(row.stats).map(stat => (
                statsUserStatCreate(
                  response.id,
                  mapUserStatUiToApi(stat, isCoach() ? uuid() : null),
                  token,
                ).pipe(
                  map(statResponse => ({ success: true, statResponse })),
                  takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
                  ajaxErrorHandlerEpicFragment(),
                  catchError(error => of({
                    success: false,
                    action: asyncErrorAction(PD_CREATE_UPDATE_STATS, 'createStat', error),
                  })),
                ))))
                .pipe(
                  checkForkErrors(),
                  map((results) => {
                    if (results.success) {
                      return {
                        success: true,
                        stats: results.results,
                      };
                    }
                    return results;
                  }),
                )),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_STATS, 'createSeason', error, {
                  positionId: action.attrs.positionId,
                  teamId: action.attrs.teamId,
                }),
              })),
            );
          } else {
            apiCall = forkJoin(...Object.values(row.stats).map(stat => (
              statsUserStatUpdate(
                row.season.id,
                stat.id,
                mapUserStatUiToApi(stat, isCoach() ? uuid() : null),
                token,
              ).pipe(
                map(statResponse => ({ success: true, statResponse })),
                takeUntil(cancelOnRouteChange(action$, Routes.data, uuid())),
                ajaxErrorHandlerEpicFragment(),
                catchError(error => of({
                  success: false,
                  action: asyncErrorAction(PD_CREATE_UPDATE_STATS, 'updateStat', error),
                })),
              ))))
              .pipe(
                checkForkErrors(),
                map((results) => {
                  if (results.success) {
                    return {
                      success: true,
                      stats: results.results,
                    };
                  }
                  return results;
                }),
              );
          }
          return apiCall;
        })).pipe(
          checkForkErrors(),
          map((results) => {
            if (results.success) {
              return {
                success: true,
                seasons: results.results,
              };
            }
            return results;
          }),
        );
      },
      ({ action, token }, inner) => ({ ...inner, outer: action, token }),
    ),
    map((results) => {
      if (results.success) {
        return {
          success: true,
          statsStd: state$.value.data.sport.statsStd,
          uuid: uuid(),
          sportId: results.outer.attrs.sportId,
          token: results.token,
          outer: results.outer,
        };
      }
      return {
        success: false,
        actions: results.actions,
      };
    }),
    getPlayerStatsEpicFragment(action$),
    switchMap((results) => {
      if (results.success) {
        return of(
          statsGetUserStatsSuccess(
            results.uuid,
            results.sportId,
            results.userStats,
            results.allTeams,
          ),
          asyncFinishAction(PD_CREATE_UPDATE_STATS, 'createUpdateSeasons', {
            positionId: results.outer.attrs.positionId,
            teamId: results.outer.attrs.teamId,
          }),
          formSubmitSuccess(results.outer.model, results.outer.attrs),
        );
      }
      return of(...results.actions);
    }),
  );
};

export const serverErrorEpic = action$ => action$.pipe(
  filter((action) => {
    if ((action.type === ASYNC_ERROR) && (action.model === PD_CREATE_UPDATE_STATS)) {
      return true;
    }
    return false;
  }),
  map((action) => {
    let msg = 'Oh no!  What happened!';
    if (action.error.name === 'AjaxError') {
      if (action.error.status === 500) {
        msg = "We apologize, we've encountered an unknown server error";
      }
    }
    return actions.setErrors(
      statsSeasonTableFormModel(action.data.teamId, action.data.positionId),
      { serverError: msg },
    );
  }),
);

export const deleteNewSeasonFromFormsEpic = action$ => action$.pipe(
  filter((action) => {
    if ((action.type === ASYNC_FINISH) && (action.model === PD_CREATE_UPDATE_STATS)) {
      return true;
    }
    return false;
  }),
  map(action => (
    actions.change(
      `${statsSeasonTableFormModel(action.data.teamId, action.data.positionId)}.seasons`,
      {},
    )
  )),
);

export const cancelEditTableResetFormsEpic = action$ => action$.pipe(
  ofType(PD_STATS_CANCEL_EDIT_TABLE),
  map(action => (
    actions.change(
      `${statsSeasonTableFormModel(action.teamId, action.positionId)}.seasons`,
      {},
    )
  )),
);

export const cancelAddRowResetFormsEpic = (action$, state$) => action$.pipe(
  ofType(PD_STATS_CANCEL_NEW_SEASON),
  map((action) => {
    const oldForm = statsSeasonTableFormDataObject(
      state$.value,
      action.teamId,
      action.positionId,
    ).seasons;
    const { [`${action.rowId}`]: deleted, ...newForm } = oldForm;
    return actions.change(
      `${statsSeasonTableFormModel(action.teamId, action.positionId)}.seasons`,
      newForm,
    );
  }),
);

export const createUpdateEpics = combineEpics(
  deleteNewSeasonFromFormsEpic,
  serverErrorEpic,
  createUpdateSeasonEpic,
  createUpdateValidateOnSubmitEpic,
  cancelEditTableResetFormsEpic,
  cancelAddRowResetFormsEpic,
);

const firstSeasonFormValidation = {
  'forms.firstSeason.teamName': [
    value => validateRequired()(value),
  ],
  'forms.firstSeason.position': [
    value => validateRequired()(value),
  ],
  'forms.firstSeason.seasonStart': [
    value => validateRequired()(value),
  ],
  'forms.firstSeason.seasonEnd': [
    value => validateRequired()(value),
    (value, form) => validateSeasonDates()(value, form.seasonStart),
  ],
};

const firstSeasonSubmitEpic = (action$, state$) => {
  const firstSeasonForm = () => state$.value.forms.firstSeason;
  const uuid = () => state$.value.ui.app.routes.currentUuid;
  // const teams = (userId, sportId) => state$.value.data.sport.userTeams[userId][sportId];
  const sportId = () => state$.value.ui.app.routes.currentSportId;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.firstSeason', 'firstSeason'),
    filter(action => !!action.attrs),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (firstSeasonForm().team) {
        return of({
          success: true,
          team: firstSeasonForm().team,
          action,
          token,
        });
      }
      return typeAheadGetTeams(firstSeasonForm().teamName, token).pipe(
        map(response => mapTeamsApiToUiFlat(response)),
        map((teams) => {
          const team = teams
            .find(t => ((t.googleName.toLowerCase() === firstSeasonForm().teamName.toLowerCase()) &&
              t.sportId === sportId()));
          return {
            success: true,
            team,
            action,
            token,
          };
        }),
        takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction('forms.firstSeason', 'getMatchingTeams', error),
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const { action, team } = results;
        if (team) {
          return of({
            success: true,
            action,
            team,
            newTeam: false,
          });
        }
        return createTeam(results.token, {
          g_name: firstSeasonForm().teamName,
          sport_id: sportId(),
        }).pipe(
          map(response => mapTeamApiToUi(response)),
          map(newTeam => ({
            action,
            success: true,
            team: newTeam,
            newTeam: true,
          })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction('forms.firstSeason', 'createTeam', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const season = new Season();
        const form = firstSeasonForm();
        // const team = teams(uuid(), sportId()).find(t => t.id === parseInt(form.team, 10));
        season.team = results.team;
        season.userId = uuid();
        season.startDate = form.seasonStart;
        season.stopDate = form.seasonEnd;
        if (form.seasonStart.year() !== form.seasonEnd.year()) {
          season.name = `${form.seasonStart.year()}-${form.seasonEnd.year()} ${results.team.googleName}`;
        } else {
          season.name = `${form.seasonStart.year()} ${results.team.googleName}`;
        }
        const successActions = [];
        if (results.newTeam) {
          successActions.push(teamCreateSucceess(results.team, sportId(), uuid()));
        }
        successActions.push(openNewSeasonRow(
          results.team.id,
          parseInt(form.position, 10),
          getRandomInt(1, 100000),
          season,
        ));
        successActions.push(formSubmitSuccess('forms.firstSeason', results.action.attrs));
        return of(...successActions);
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const firstSeasonFormEpics = combineEpics(
  firstSeasonSubmitEpic,
  validationOnSubmitEpicFactory(firstSeasonFormValidation, 'forms.firstSeason', 'firstSeason'),
  validationFailedEpicFactory('forms.firstSeason', 'firstSeason'),
  ...formValidationEpicsFactory(firstSeasonFormValidation, 'firstSeason'),
);

export const newSeasonValidateOnSubmitEpic = (action$, state$) => (
  action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_INTENT) && (isStatsAddSeasonFormModel(action.model))) {
        return true;
      }
      return false;
    }),
    switchMap((action) => {
      const validation = [
        {
          key: 'teamId',
          validators: [
            value => validateRequired()(value),
          ],
        },
        {
          key: 'positionId',
          validators: [
            value => validateRequired()(value),
          ],
        },
        {
          key: 'seasonStart',
          validators: [
            value => validateRequired()(value),
          ],
        },
        {
          key: 'seasonEnd',
          validators: [
            value => validateRequired()(value),
            (value, form) => validateSeasonDates()(value, form.seasonStart),
          ],
        },
      ];
      const form = statsAddSeasonFormDataObject(
        state$.value,
        action.attrs.teamId,
        action.attrs.positionId,
      );
      const errors = [];
      validation.forEach((field) => {
        const fieldErrors = {};
        field.validators.forEach((validator) => {
          const report = validator(form[field.key], form);
          Object.entries(report).forEach(([key, value]) => {
            if (value) {
              fieldErrors[key] = value;
            }
          });
        });
        if (Object.keys(fieldErrors).length) {
          errors.push(actions.setValidity(`${action.model}.${field.key}`, false));
          errors.push(actions.setErrors(`${action.model}`, fieldErrors));
        }
      });
      if (errors.length) {
        return of(
          formSubmitFailed(action.model, action.attrs),
          ...errors,
        );
      }
      return of(
        actions.setErrors(action.model, {}),
        formSubmitPending(action.model, action.attrs),
      );
    }),
  )
);

const newSeasonSubmitEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const teams = (userId, sportId) => state$.value.data.sport.userTeams[userId][sportId];
  return action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_PENDING) && (isStatsAddSeasonFormModel(action.model))) {
        const rrfForm = statsAddSeasonFormObject(
          state$.value,
          action.attrs.teamId,
          action.attrs.positionId,
        );
        return rrfForm.$form.valid;
      }
      return false;
    }),
    map((action) => {
      const form = statsAddSeasonFormDataObject(
        state$.value,
        action.attrs.teamId,
        action.attrs.positionId,
      );
      const season = new Season();
      const team = teams(uuid(), action.attrs.sportId)
        .find(t => t.id === parseInt(form.teamId, 10));
      season.team = team;
      season.userId = uuid();
      season.startDate = form.seasonStart;
      season.stopDate = form.seasonEnd;
      if (form.seasonStart.year() !== form.seasonEnd.year()) {
        season.name = `${form.seasonStart.year()}-${form.seasonEnd.year()} ${team.googleName}`;
      } else {
        season.name = `${form.seasonStart.year()} ${team.googleName}`;
      }
      return openNewSeasonRow(
        team.id,
        parseInt(form.positionId, 10),
        parseInt(action.attrs.rowId, 10),
        season,
      );
    }),
  );
};

export const newSeasonFormEpics = combineEpics(
  newSeasonSubmitEpic,
  newSeasonValidateOnSubmitEpic,
);
