import { asyncStartAction } from '../../async';

export const CREATE_VANITY_URL = 'vanityUrl.create';
export const createVanityUrl = (name,hasPrevValue) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_VANITY_URL));
  dispatch({
    type: CREATE_VANITY_URL,
    name,
    hasPrevValue
  });
};

export const UPDATE_VANITY_URL = 'vanityUrl.update';
export const updateVanityUrl = (name,hasPrevValue) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_VANITY_URL));
  dispatch({
    type: UPDATE_VANITY_URL,
    name,
    hasPrevValue
  });
};

export const TEST_VANITY_URL = 'vanityUrl.test';
export const testVanityUrlAction = (name, isMobile=false) => (dispatch) => {
  dispatch(asyncStartAction(TEST_VANITY_URL));
  dispatch({
    type: TEST_VANITY_URL,
    name,
    isMobile,
  });
};

export const VANITY_URL_FORM_INIT = 'vanityUrl.formInit';
export const vanityUrlFormInit = () => ({
  type: VANITY_URL_FORM_INIT,
});
