import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from '../../../../store/actions/ui/routes';
import NavBar from '../../common/NavBar';
import NavBarLink from '../../common/NavBarLink';
import LoadingIndicator from '../../common/LoadingIndicator';
import BlueTable from '../BlueTable/BlueTable.component';
import SummaryBar from '../SummaryBar';
import AthleteSummary from './AthleteSummary.component';
import Icon from '../../common/Icon/Icon.component';
import PrintExperiment from '../PrintExperiment/PrintExperiment.component';
import Banner from '../../common/Banner';
import { ReportTabSlugs } from '../../+store/report/report.constants';
import AthleteBanner from '../Banners/AthleteBanner.component';
import { displayCategoryImprovement, checkNA,
  displayCategoryImprovementFilterByCode,
  displayCategoryFilterByCode
} from '../Common/utils';

class Report extends PureComponent {
  static propTypes = {
    athleteReport: PropTypes.object,
    match: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    athleteId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    combineNames: PropTypes.object.isRequired,
    schoolStyle: PropTypes.object,
    teamRepor: PropTypes.object,
    currentAthlete :PropTypes.object,
    report: PropTypes.object,
  };

  redirectToTab = ({ match }) => {
    const {
      reportId, athleteId, schoolId, teamId,
    } = match.params;
    const tabSlug = Object.keys(ReportTabSlugs)[0];
    return <Redirect to={`/${Routes.school}/${schoolId}/${Routes.report}/${reportId}/${Routes.team}/${teamId}/${Routes.athlete}/${athleteId}/${tabSlug}`} />;
  };

  renderAthleteSummary = (props) => {
    const {
      schoolStyle, teamReport, athleteReport, combineNames,
    } = this.props;
    return (
      <React.Fragment>
        <AthleteSummary
          teamReport={teamReport}
          athleteReport={athleteReport}
          combineNames={combineNames}
          {...props}
        />
        <PrintExperiment schoolStyle={schoolStyle}>
          {this.renderPrintBanner()}
          <AthleteSummary
            teamReport={teamReport}
            athleteReport={athleteReport}
            combineNames={combineNames}
            {...props}
          />
        </PrintExperiment>
      </React.Fragment>
    );
  };

  renderPrintBanner = () => {
    const {
      currentAthlete, report, athleteId, schoolStyle,
    } = this.props;
    return (
      <Banner schoolStyle={schoolStyle} hideButtons>
        <div className="CoachRoster_BannerChildren">
          <div className="CoachRoster_BannerLeft">
            <AthleteBanner
              currentAthlete={currentAthlete}
              report={report}
              athleteId={athleteId}
            />
          </div>
        </div>
      </Banner>
    );
  }

  renderAthleteTab= (props) => {
    const { schoolStyle, athleteReport, combineNames } = this.props;
    const { bank } = props.match.params;
    const bankReport = athleteReport[bank];

    const subbanks = (bankReport.subbanks && Object.values(bankReport.subbanks)) || []
      .sort((a, b) => a.standard_test_subbank_order_num - b.standard_test_subbank_order_num);
    const cats = subbanks
      .reduce((prev, sb) => (
        [
          ...prev,
          ...Object.values(sb.categories)
            .sort((a, b) => a.standard_test_subbank_order_num - b.standard_test_subbank_order_num),
        ]
      ), []);
    const topRow = [
      [...subbanks.map(sb => ({
        name: sb.standard_test_subbank_name,
        colSpan: Object.values(sb.categories).length,
      }))],
      [...cats.map(c => ({ name: c.standard_test_category_name, code:c.standard_test_category_code } ) )],
    ];
    const rawSection = {
      name: 'Raw',
      colSpan: cats.length + 1,
      rows: [
        [
          combineNames.mostRecent,
          ...cats.map(c => displayCategoryFilterByCode(
            c.most_recent,
            c.standard_stat_format_code,
            c.standard_test_category_code
          )),
        ],
        [
          combineNames.last,
          ...cats.map(c => displayCategoryFilterByCode(
            c.last,
            c.standard_stat_format_code,
            c.standard_test_category_code
          )),
        ],
        [
          'Improvement',
          ...cats.map(c => (
            displayCategoryImprovementFilterByCode(
              c.improvement,
              c.standard_test_category_best,
              !!c.standard_test_category_comparable,
              c.standard_stat_format_code,
              c.standard_test_category_code
            )
          )),
        ],
        [
          'Team Avg.',
          ...cats.map(c => displayCategoryFilterByCode(
            c.team_avg,
            c.standard_stat_format_code,
            c.standard_test_category_code
          )),
        ],
      ],
    };

    let relativeSection;
    if (bank === 'strength') {
      relativeSection = {
        name: 'Pound for Pound Strength',
        colSpan: cats.length + 1,
        rows: [
          [
            combineNames.mostRecent,
            ...cats.map(c => displayCategoryFilterByCode(
              c.relative_strength_most_recent,
              'PERCENTAGE-INTEGER',
              null,
              checkNA(
                c.relative_strength_last,
                c.relative_strength_most_recent,
                c.relative_strength_improvement_percent,
              ),
            )),
          ],
          [
            combineNames.last,
            ...cats.map(c => displayCategoryFilterByCode(
              c.relative_strength_last,
              'PERCENTAGE-INTEGER',
              null,
              checkNA(
                c.relative_strength_last,
                c.relative_strength_most_recent,
                c.relative_strength_improvement_percent,
              ),
            )),
          ],
          [
            'Improvement',
            ...cats.map(c => (
              displayCategoryImprovement(
                c.relative_strength_improvement_percent,
                c.standard_test_category_best,
                !!c.standard_test_category_comparable,
                'PERCENTAGE-HUNDREDTHS',
                checkNA(
                  c.relative_strength_last,
                  c.relative_strength_most_recent,
                  c.relative_strength_improvement_percent,
                ),
              )
            )),
          ],
          [
            'Team Avg.',
            ...cats.map(c => displayCategoryFilterByCode(
              c.relative_strength_team_avg,
              'PERCENTAGE-HUNDREDTHS',
              null,
              checkNA(
                c.relative_strength_last,
                c.relative_strength_most_recent,
                c.relative_strength_improvement_percent,
              ),
            )),
          ],
        ],
      };
    } else {
      relativeSection = null;
    }

    const scoreSection = {
      name: bank === 'strength' ? 'Strength Score' : 'Performance Score',
      colSpan: cats.length + 1,
      rows: [
        [
          combineNames.mostRecent,
          ...cats.map(c => displayCategoryFilterByCode(
            c.rating_most_recent,
            'SCORE',
            null,
            checkNA(
              c.rating_last,
              c.rating_most_recent,
              c.rating_improvement_percent,
            ),
          )),
        ],
        [
          combineNames.last,
          ...cats.map(c => displayCategoryFilterByCode(
            c.rating_last,
            'SCORE',
            null,
            checkNA(
              c.rating_last,
              c.rating_most_recent,
              c.rating_improvement_percent,
            ),
          )),
        ],
        [
          'Improvement',
          ...cats.map(c => (
            displayCategoryImprovement(
              c.rating_improvement_percent,
              c.standard_test_category_best,
              !!c.standard_test_category_comparable,
              'PERCENTAGE-HUNDREDTHS',
              checkNA(
                c.rating_last,
                c.rating_most_recent,
                c.rating_improvement_percent,
              ),
            )
          )),
        ],
        [
          'Team Avg.',
          ...cats.map(c => displayCategoryFilterByCode(
            c.rating_team_avg,
            'SCORE',
            null,
            checkNA(
              c.rating_last,
              c.rating_most_recent,
              c.rating_improvement_percent,
            ),
          )),
        ],
      ],
    };

    const rankSection = {
      name: 'Ranking',
      colSpan: cats.length + 1,
      rows: [
        [
          'Team Ranking',
          ...cats.map(c => displayCategoryFilterByCode(
            c.team_ranking,
            'NUMERIC-INTEGER',
          )),
        ],
        [
          'Previous Ranking',
          ...cats.map(c => displayCategoryFilterByCode(
            c.previous_ranking,
            'NUMERIC-INTEGER',
          )),
        ],
        [
          'Personal Trend',
          ...cats.map(c => (
            displayCategoryImprovement(
              c.personal_trend,
              'max',
              !!c.standard_test_category_comparable,
              'NUMERIC-INTEGER',
            )
          )),
        ],
      ],
    };

    const sections = [rawSection, relativeSection, scoreSection, rankSection].filter(s => !!s);
    return (
      <React.Fragment>
        <div className="outer-table">
          <BlueTable
            topRow={topRow}
            sections={sections}
          />
          <PrintExperiment schoolStyle={schoolStyle}>
            {this.renderPrintBanner()}
            <BlueTable
              topRow={topRow}
              sections={sections}
            />
          </PrintExperiment>
        </div>
      </React.Fragment>
    );
  };


  render() {
    const {
      athleteReport, loading, loadingMsg, match, reportId, teamId, athleteId,
    } = this.props;
    const tabs = athleteReport ? Object
      .entries(ReportTabSlugs)
      .filter(slug => {
        return slug[1] === ReportTabSlugs.summary ||
        ((athleteReport[slug[0]] ? Object.entries(athleteReport[slug[0]]).length : false ) && slug[0] in athleteReport && !!athleteReport[slug[0]])
      }) : [];

    return (
      <div className="CombineReport_BodyWrapper">
        {
          athleteReport &&
          <NavBar>
            <div className="CoachNavBar_Left">
              <NavBarLink
                key="backButton"
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}`}
                match={match}
                // className="active"
              >
                <Icon iconName="chevron-left" />
              </NavBarLink>
              {
                tabs
                  .map(([slug, label], index) => (
                    <NavBarLink
                      key={index}
                      to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.team}/${teamId}/${Routes.athlete}/${athleteId}/${slug}`}
                      match={match}
                    >
                      {label}
                    </NavBarLink>
                  ))
              }
            </div>
          </NavBar>
        }
        <LoadingIndicator loading={loading} loadingMsg={loadingMsg} />
        <div className="CombineReport_Body">
          <div className="CombineReport_InnerBody">
            <div className="AthleteReportBase">
              { athleteReport && <SummaryBar match={match} report={athleteReport} /> }
              {
                athleteReport &&
                <Switch>
                  <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/${Routes.summary}`} render={this.renderAthleteSummary} />
                  {
                    tabs.map((slug, index) => (
                      <Route key={index} path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/:bank`} render={this.renderAthleteTab} />
                    ))
                  }
                  <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId`} render={this.redirectToTab} />
                </Switch>
              }
              {!athleteReport && <div>There is no such player in this team athleteReport.</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
