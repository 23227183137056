import { asyncStartAction } from '../../../../store/actions/ui/async';



export const GET_DISTRICT_LEADERBOARD_TEMPLATE = 'districtLeaderboard.template.get';
export const getDistrictLeaderboardTemplate = (schoolId, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_DISTRICT_LEADERBOARD_TEMPLATE));
  dispatch({
    type: GET_DISTRICT_LEADERBOARD_TEMPLATE,
    schoolId,
    sportId,
    dispatch,
  });
};

export const GET_DISTRICT_LEADERBOARD_SPORTS = 'districtLeaderboard.sports.get';
export const getDistrictLeaderboardSports = (schoolId) => (dispatch) => {
  dispatch(asyncStartAction(GET_DISTRICT_LEADERBOARD_SPORTS));
  dispatch({
    type: GET_DISTRICT_LEADERBOARD_SPORTS,
    schoolId,
    dispatch,
  });
};

export const GET_DISTRICT_LEADERBOARD_GRAD_YEARS = 'districtLeaderboard.gradYears.get';
export const getDistrictLeaderboardGradYears = (schoolId) => (dispatch) => {
  dispatch(asyncStartAction(GET_DISTRICT_LEADERBOARD_GRAD_YEARS));
  dispatch({
    type: GET_DISTRICT_LEADERBOARD_GRAD_YEARS,
    schoolId,
    dispatch,
  });
};


export const GET_DISTRICT_LEADERBOARD_RESULTS = 'districtLeaderboard.results.get';
export const getDistrictLeaderboardResults = ({
  sortBy, perPage, scoreType, parsedFilters, page, loadMore, orderBy, zip, radius,search, sportId , schoolId
}) =>
  (dispatch) => {
    dispatch(asyncStartAction(GET_DISTRICT_LEADERBOARD_RESULTS));
    dispatch({
      type: GET_DISTRICT_LEADERBOARD_RESULTS,
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      loadMore,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      schoolId,
      dispatch,
    });
  };

export default 'districtLeaderboard.actions.js';
