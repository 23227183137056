import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import { parserOnlyNumbers } from '../../../../utils/parsers';
import { CheckForNewLine } from '../utils';

const FieldLbsRps = (props) => {

    const {   
        modelLbs,    
        modelRps,
        help,
        helpClass,
        rrfFieldLbs,
        rrfFieldRps,
        errors,
        label,
        testResult,
        onFormFieldChanged,
        disabled,
      } = props;

      const onResultChanged = () =>{
        onFormFieldChanged("result", rrfFieldLbs.value.toString());
      }

      const onRepsChanged = () =>{
        onFormFieldChanged("reps", rrfFieldRps.value.toString());
      }

      return (
        <div className="UxCommon_Form_Group UxCommon_Form_Group_Flex" style={{ flexWrap: 'wrap' }}>
          <div
            style={{ marginRight: '2px', flex: '1 0 21%' }}
            className={[
              'UxCommon_Form_InputGroup',
              rrfFieldLbs.valid ? '' : 'error',
              'UxCommon_Form_InputGroup_Flex',
            ].join(' ')}>
            <div className="UxCommon_Form_ErrorMarker" />
            <div>
              <div className="UxCommon_Form_LabelWrapper">
                <label
                  className={[
                    'UxCommon_Form_Label',
                    ((rrfFieldLbs.value && rrfFieldLbs.value.length) ) ? 'show' : '',
                  ].join(' ')}
                  htmlFor={modelLbs}
                >
                  {label}
                </label>
              </div>
              <Control.text
                id={modelLbs}
                label={label}
                placeholder={`Enter ${label}`}
                className={[
                  'UxCommon_Form_Input',
                  ((rrfFieldLbs.value && rrfFieldLbs.value.length) ) ? 'not-empty' : '',
                ].join(' ')}
                model={modelLbs}
                parser={parserOnlyNumbers}
                defaultValue={(testResult && testResult.testResultResult) ? `${testResult.testResultResult}` : ''}
                onKeyDown={CheckForNewLine(modelLbs)}
                onBlur={onResultChanged}
                disabled={disabled}
              />
            </div>
          </div>
          <div style={{marginBottom:'12px'}}>x</div>
          <div
            style={{ marginLeft: '2px', flex: '1 0 21%' }}
            className={[
              'UxCommon_Form_InputGroup',
              rrfFieldRps.valid ? '' : 'error',
              'UxCommon_Form_InputGroup_Flex',
            ].join(' ')}
          >
            <div>
              <div className="UxCommon_Form_LabelWrapper">
                <label
                  className={[
                    'UxCommon_Form_Label',
                    ((rrfFieldRps.value && rrfFieldRps.value.length) ) ? 'show' : '',
                  ].join(' ')}
                 htmlFor={modelRps}
                >
                  Reps
                </label>
              </div>
              <Control.text
                label="(Reps)"
                placeholder="Enter (Reps)"
                className={[
                  'UxCommon_Form_Input',
                  ((rrfFieldRps.value && rrfFieldRps.value.length) ) ? 'not-empty' : '',
                ].join(' ')}
                model={modelRps}
                id={modelRps}
                parser={parserOnlyNumbers}
                defaultValue={(testResult && testResult.testResultReps) ? `${testResult.testResultReps}` : ''}
                onKeyDown={CheckForNewLine(modelRps)}
                onBlur={onRepsChanged}
                disabled={disabled}
              />
            </div>
        </div>
      <div style={{ width: '100%',display: 'flex',justifyContent: 'space-between'}}>
     <div>
     {
        help &&
        <HelpPopper focus={rrfFieldLbs.focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
      }
      <Errors errors={errors || rrfFieldLbs.errors} valid={rrfFieldLbs.valid} />
     </div>
      <div >
      {
        help &&
        <HelpPopper focus={rrfFieldRps.focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
      }
      <Errors errors={errors || rrfFieldRps.errors} valid={rrfFieldRps.valid} />
      </div>
      </div>
     </div>
      );
    };

    FieldLbsRps.propTypes = {
      testResult: PropTypes.any,
      onFormFieldChanged:PropTypes.func.isRequired,
      modelLbs:PropTypes.string.isRequired,
      modelRps:PropTypes.string.isRequired,
      label:PropTypes.string.isRequired,
      rrfFieldLbs:PropTypes.object, 
      rrfFieldRps:PropTypes.object,
      disabled: PropTypes.bool,
    }
  
export default FieldLbsRps;