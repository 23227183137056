import { connect } from 'react-redux';
import Component from './FormExamplePage.component';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
