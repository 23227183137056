import React from 'react';
import PropTypes from 'prop-types';

const RatingLabel = ({ label, value }) => (
  <div className="UxPlayerData_RatingLabel">
    <div className="UxPlayerData_RatingLabel_Label">{label}</div>
    <div className="UxPlayerData_RatingLabel_Value">{value}</div>
  </div>
);
RatingLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default RatingLabel;

