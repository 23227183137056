import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const ButtonWithIcon = ({
  iconName,
  children,
  className,
  ...props
}) => (
  <button className={['UxCommon_ButtonWithIcon', className || ''].join(' ')} {...props}>
    <div className="UxCommon_ButtonWithIcon_Left">
      <Icon iconName={iconName} />
    </div>
    <div className="UxCommon_ButtonWithIcon_Right">
      {children}
    </div>
  </button>
);
ButtonWithIcon.propTypes = {
  children: PropTypes.any,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ButtonWithIcon;
