import { connect } from 'react-redux';
import Component from './EditSchoolStyle.component';
import { updateSchoolStyles, showUploadPhotoDialog, hideUploadPhotoDialog } from '../../+store';


const mapStateToProps = state => ({
  updateStyle403Error: state.modules.coachWorld.coachWorld.updateStyle403Error,
  updatingStyleStatus: state.modules.coachWorld.coachWorld.updatingStyleStatus,
});

const mapDispatchToProps = dispatch => ({
  saveSchoolStyles: (values, schoolID) => {
    dispatch(updateSchoolStyles(schoolID, values));
  },
  showUploadPhotoDialog: () => {
    dispatch(showUploadPhotoDialog());
  },
  hideUploadPhotoDialog: () => {
    dispatch(hideUploadPhotoDialog());
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
