import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import TextControl from '../SimpleForm/TextControl';

import Button from '../Common/Button';
// import { validatePhone } from '../../../store/actions/ui/formUtils/validators';
import {
  checkConfirmPassword,
  checkConfirmPhone,
  checkConfirmUsername,
  checkPasswordEasy,
  checkPhone,
  checkRequired,
  checkUsername,
  checkName,
} from './AccountPage.validation';
import ControlErrors from '../SimpleForm/ControlErrors';
import {Routes} from '../../../store/actions/ui/routes';
import {getRoleId} from '../Common/utils';
import PhoneControlComponent from '../SimpleForm/PhoneControl';

class AccountPage extends PureComponent {
  static propTypes = {
    createAccount: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    errorMsg: PropTypes.string.isRequired,
    schoolInfo: PropTypes.object,
    countryCode: PropTypes.string.isRequired,
    checkEmailExists: PropTypes.func.isRequired,
    duplicateEmail: PropTypes.bool.isRequired,
    emailExistsMessage: PropTypes.string.isRequired,
  }

  state = {
    values: {
      password: '',
      passwordConfirm: '',
    },
    errors: {
      passwordConfirm: [''],
    },
    selectedCountry: '',
    touched: {
      passwordConfirm: false,
      confirmPhone: false,
      confirmUsername: false,
    },
  }

  componentDidMount() {
  }

  onCreateClick = (event) => {
    const {createAccount, match, duplicateEmail} = this.props;
    const form = this.formRef.current;
    const errors = {
      first: checkName(form.first.value),
      last: checkName(form.last.value),
      phone: checkPhone(form.phone.value),
      confirmPhone: checkConfirmPhone(form.phone.value, form.confirmPhone.value),
      username: checkUsername(form.username.value),
      confirmUsername: checkConfirmUsername(form.username.value, form.confirmUsername.value),
      password: checkPasswordEasy(form.password.value),
      confirm: checkConfirmPassword(form.password.value, form.confirm.value),
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({errors});
    if (success && !duplicateEmail) {
      createAccount(
        {
          first: form.first.value,
          last: form.last.value,
          phone: form.phone.value.length > 2 ? form.phone.value : null,
          username: form.username.value,
          password: form.password.value,
          roleId: getRoleId(match.params.code, this.props.schoolInfo),
        },
        match.params.code,
      );
    }
    event.preventDefault();
  }

  onUsernameChange = (value) => {
    const {checkEmailExists} = this.props;
    const usernameConfirmError = checkConfirmUsername(value, this.state.values.confirmUsername);
    const errors = checkUsername(value);
    this.setState({
      values: Object.assign({}, this.state.values, {
        username: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        username: errors,
        confirmUsername: this.state.touched.confirmUsername ? usernameConfirmError : [],
      }),
    });
    if (errors.length === 0) {
      checkEmailExists(value);
    }
    return String(value).toLowerCase();
  }

  onUsernameBlur = value => value

  onUsernameConfirmBlur = (value) => {
    const errors = checkConfirmUsername(value, this.state.values.username);
    this.setState({
      values: Object.assign({}, this.state.values, {
        confirmUsername: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        confirmUsername: errors,
      }),
      touched: Object.assign({}, this.state.touched, {
        confirmUsername: true,
      }),
    });
    return value;
  };

  onUsernameConfirmChange = (value) => {
    const errors = checkConfirmUsername(value, this.state.values.username);
    this.setState({
      values: Object.assign({}, this.state.values, {
        confirmUsername: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        confirmUsername: errors,
      }),
      touched: Object.assign({}, this.state.touched, {
        confirmUsername: true,
      }),
    });
    return value;
  }

  onPasswordChange = (value) => {
    const passwordConfirmError = checkConfirmPassword(value, this.state.values.passwordConfirm);
    const passwordValidationError = checkPasswordEasy(value, 'Please enter a password');
    this.setState({
      values: Object.assign({}, this.state.values, {
        password: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        passwordConfirm: [passwordConfirmError && this.state.errors.confirm],
        password: passwordValidationError,
      }),
    });
    return value.replace(/\s/g, '');
  }

  onPasswordBlur = () => {
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        password: [],
      }),
    });
  }

  onPasswordFocus = (event) => {
    this.onPasswordChange(event.target.value);
  }

  onPasswordConfirmChange = (value) => {
    const errors = checkConfirmPassword(value, this.state.values.password);
    this.setState({
      values: Object.assign({}, this.state.values, {
        confirm: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        confirm: errors,
      }),
      touched: Object.assign({}, this.state.touched, {
        confirm: true,
      }),
    });
    return value.replace(/\s/g, '');
  }

  onNameChange = type => (value) => {
    const errors = checkName(value);
    this.setState({
      values: Object.assign({}, this.state.values, {
        [type]: value,
      }),
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }

  onPhoneBlur = (value, data, event, formattedValue) => {
    const errors = checkPhone(formattedValue);
    this.setState({
      values: Object.assign({}, this.state.values, {
        phone: formattedValue,
      }),
      errors: Object.assign({}, this.state.errors, {
        phone: errors,
      }),
      selectedCountry: data.countryCode
    });
    return formattedValue;
  }

  onConfirmPhoneBlur = (value, data, event, formattedValue) => {
    const errors = checkPhone(formattedValue);
    this.setState({
      values: Object.assign({}, this.state.values, {
        confirmPhone: formattedValue,
      }),
      errors: Object.assign({}, this.state.errors, {
        confirmPhone: errors,
      }),
      touched: Object.assign({}, this.state.touched, {
        confirmPhone: true,
      }),
    });
    return formattedValue;
  }

  formRef = React.createRef();

  render() {
    const {
      errorMsg,
      match,
      countryCode,
      emailExistsMessage,
    } = this.props;

    const {errors} = this.state;
    const msg = '';
    let passwordErrorMessage = [];
    errors.password && errors.password.length === 2 ? passwordErrorMessage.push(errors.password[0])
                                                    : errors.password && errors.password.length === 1 ? passwordErrorMessage.push(errors.password[0])
                                                    : [];
    const passwordClassName = errors.password && errors.password.length > 0 ? "AccountPage_TextControl password_error" : "AccountPage_TextControl";
    const confirmPasswordClassName = errors.confirm && errors.confirm.length > 0 ? "AccountPage_TextControl password_error" : "AccountPage_TextControl";

    // Otherwise show signup form
    return (
      <div className="AccountPage">
        <div className="AccountPage_Heading">{'Let\'s create your account and get started!'}</div>
        {msg && <div className="AccountPage_Msg">{msg}</div>}
        <form ref={this.formRef} onSubmit={this.onCreateClick} className="AccountPage_Form">
          <TextControl
            className="AccountPage_TextControl"
            label="First Name"
            placeholder="First Name"
            name="first"
            myOnChange={this.onNameChange('first')}
            myOnBlur={this.onNameChange('first')}
            errors={errors.first}
          />
          <TextControl
            className="AccountPage_TextControl"
            placeholder="Last Name"
            label="Last Name"
            name="last"
            myOnChange={this.onNameChange('last')}
            myOnBlur={this.onNameChange('last')}
            errors={errors.last}
          />
          <PhoneControlComponent
            className="AccountPage_TextControl"
            placeholder="Mobile Phone"
            label="Mobile Phone"
            name="phone"
            myOnBlur={this.onPhoneBlur}
            myOnChange={this.onPhoneBlur}
            defaultCountry={this.state.selectedCountry}
            errors={errors.phone}
          />
          <PhoneControlComponent
            className="AccountPage_TextControl"
            placeholder="Confirm Mobile Phone"
            label="Confirm Mobile Phone"
            name="confirmPhone"
            myOnBlur={this.onConfirmPhoneBlur}
            myOnChange={this.onConfirmPhoneBlur}
            defaultCountry={this.state.selectedCountry}
            errors={errors.confirmPhone}
            disabled = {true}
          />
          <TextControl
            className="AccountPage_TextControl"
            placeholder="Email"
            label="Email"
            myOnChange={this.onUsernameChange}
            myOnBlur={this.onUsernameBlur}
            errors={errors.username && errors.username.length ? errors.username : [emailExistsMessage]}
            name="username"
          />
          <TextControl
            className="AccountPage_TextControl"
            placeholder="Confirm Email"
            label="Confirm Email"
            myOnBlur={this.onUsernameConfirmBlur}
            myOnChange={this.onUsernameConfirmChange}
            errors={errors.confirmUsername}
            name="confirmUsername"
          />
          <TextControl
            className={passwordClassName}
            placeholder="Password"
            label="Password"
            myOnChange={this.onPasswordChange}
            errors={passwordErrorMessage}
            type="password"
            // onBlur={this.onPasswordBlur}
            // onFocus={this.onPasswordFocus}
            name="password"
          />
          <TextControl
            className={confirmPasswordClassName}
            placeholder="Confirm Password"
            label="Confirm Password"
            myOnChange={this.onPasswordConfirmChange}
            errors={errors.confirm}
            type="password"
            name="confirm"
          />
          <ControlErrors errors={[errorMsg]}/>
          <Button
            className="AccountPage_SubmitBtn"
            type="submit"
            onClick={this.onCreateClick}
          >
            Create Account
          </Button>
          <div className="AccountPage_SignInLinkWrapper">
            <Link className="AccountPage_SignInLink" to={`/code/${match.params.code}/${Routes.login}`}>
              Already have an account? Sign in.
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default AccountPage;
