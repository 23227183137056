import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import Banner from '../Banner';
import SchoolLogo from '../SchoolLogo';
import Icon from '../Icon';
import cssVar from '../../../../components/App/App.utils';
import bgImageUrl from '../../../../assets/images/bannerImage.png';
import {TableHeader, Table, TableRow, TableCell, TableBody} from '../../common/Table';
import EditButton from "../EditButton";
import { batOptions } from '../../../../components/UxCommon/Form/utils';


const PrintRoster = (props) => {

  const [loading, setLoading] = useState(false);

  const {
    propData,
    gridApi,
    currentSchoolStyle,
    totalColumn,
    headCoach,
    recruitingCoordinator,
    twitter
  } = props;

  useEffect(() => {
    setLoading(true);
  }, [])

  const styles = document.getElementsByTagName('style')[0].innerHTML;
  const {athletes, school, team, match} = propData;

  let backGroundImage = bgImageUrl;
  if (currentSchoolStyle && currentSchoolStyle.background_image !== null) {
    backGroundImage = currentSchoolStyle.background_image;
  }

  let fontSize;
  if (totalColumn.length >= 20) {
    fontSize = 8;
  } else if (totalColumn.length >= 16) {
    fontSize = 9;
  } else if (totalColumn.length < 10) {
    fontSize = 13;
  } else if (totalColumn.length <= 15) {
    fontSize = 11;
  }


  let totalAtheletes;
  let selectedAthletes = [];
  const sortedAthletes = [];
  const selectedAndSortedAthletes = [];
    if (totalColumn.length !== 0) {
      selectedAthletes = gridApi.getSelectedRows();
      gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
        sortedAthletes.push(rowNode.data);
    });

      // if user doesn't explicitly select
      if(selectedAthletes){
        sortedAthletes.forEach((data)=>{
          if(selectedAthletes.find(ath => ath.id === data.id)){
            selectedAndSortedAthletes.push(data);
          }
        });
      }

    totalAtheletes = (selectedAndSortedAthletes.length !== 0) ? selectedAndSortedAthletes : sortedAthletes;
  }

  return (
    loading && <Frame className="PrintExperiment_IFrame" id="emptyprintframe">
      <React.Fragment>
        <style>{styles}</style>
        <style>
          {`* {
            -webkit-print-color-adjust: exact;
            --primary: ${cssVar('primary')};
            --secondary: ${cssVar('secondary')};
          }
          html, body, #root {
            overflow: hidden;
            height: unset;
          }
          @page {
            margin-bottom: 0;
          }
          .CoachTable {
            width: 100%;
          }
          .CoachBanner {
            height: 100px;
            margin-bottom: 5px;
            background-position: center;
            background-size: cover;
            position: relative;
          }
          .CoachBanner_UpperRight {
            top: 0;
            right: 0;
            bottom: unset;
          }
          @page { size: landscape; }
          .CoachRoster_BannerChildren2 {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: -50px;
          }
          .CoachRoster_SchoolName {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .CoachTableCell {
              height: 10px !important;
              font-size: ${fontSize}px !important;
          }
          .PrintSchoolLogo {
            height:40px;headCoach
            width:40px;
          }
          .Print_SchoolRosterName {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            margin-left:10px;
          }
          .row {
            display:flex;
          }
          .roster-print {
            display: flex;
            flex-wrap: wrap;
            margin-bottom:5px;
            flex: 2 1 auto;
          }
          .roster-print h1 {
            font-size: 15px;
            padding-top:5px;
            margin-top:5px;
            margin-bottom:0;
          }
          .d-flex {
            display: flex;
          }
          .col-md-4 {
            max-width: 33.33%;
          }
          .col-md-5 {
            flex: 0 0 41.66667%;
            max-width: 35%;
            padding:0 15px;
          }
          p {
            margin: 0 !important;
          }
          .col-md-2 {
            flex: 0 0 16.66667%;
            max-width: 20%;
          }
          .justify-content-between {
            justify-content: space-between;
          }
          .pl {
            padding-left: 15px;
          }
          .border {
            border: 1px solid #e6e8f2;
          }
          .roster-mail-wrap {
            word-break: break-all;
            margin-right:10px;
            margin-left:10px;
          }
          .roster-print p{
            margin:5px;
            font-size: 13px;
          }
          .team-twitter svg {
            margin-left:5px;

          }
          .team-twitter svg path{
            fill:#000;
            margin-top:15px;
          }
          .bold-data {
            font-weight: bold;
          }
          .break-line {
            word-break: break-all;
          }
          .twitter-link {
            color: #007bff;
          }
          `}
        </style>
        <div
          className="CoachBanner"
          // style={{backgroundImage: `url(${backGroundImage})`}}
        >
          <div className="CoachRoster_BannerChildren2">
            <div className="CoachRoster_SchoolName">
              <SchoolLogo
                className="CoachRoster_BannerTeamLogo PrintSchoolLogo"
                defaultClassName="PrintSchoolLogo"
                schoolStyle={currentSchoolStyle || null}
              />
              <div
                className="CoachRoster_BannerFullTitle Print_SchoolRosterName">{(school && team) ? `${school.name} - ${team.name} - ${match.params.sport}` : ''}</div>
            </div>
          </div>
        </div>
        <div className="row roster-print">
          {
            headCoach && (
              <div className="col-md-5 border break-line">
                <div className="row">
                  <h1>{headCoach.title}</h1>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4 break-line">
                    <p>Name</p>
                    <p className="bold-data">{headCoach.name}</p>
                  </div>
                  {
                    headCoach.email && (
                      <div className="col-md-4 roster-mail-wrap">
                        <p>Email</p>
                        <p className="bold-data">{headCoach.email}</p>
                      </div>
                    )
                  }
                  {
                    headCoach.phone && (
                      <div className="col-md-4">
                        <p>Phone Number</p>
                        <p className="bold-data">{headCoach.phone}</p>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            recruitingCoordinator && (
              <div className="col-md-5 border break-line">
                <div className="row">
                  <h1>{recruitingCoordinator.title}</h1>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4 break-line">
                    <p>Name</p>
                    <p className="bold-data">{recruitingCoordinator.name}</p>
                  </div>
                  {
                    recruitingCoordinator.email && (
                      <div className="col-md-4 roster-mail-wrap">
                        <p>Email</p>
                        <p className="bold-data">{recruitingCoordinator.email}</p>
                      </div>
                    )
                  }
                  {
                    recruitingCoordinator.phone && (
                      <div className="col-md-4">
                        <p>Phone Number</p>
                        <p className="bold-data">{recruitingCoordinator.phone}</p>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            twitter && (
              <div className="col-md-2 pl border">
                <div className="row team-twitter">
                  <h1><span>Team</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.196" height="15.196" viewBox="0 0 27.196 27.196">
                      <path id="_013-twitter" data-name="013-twitter" d="M13.6,0A13.6,13.6,0,1,0,27.2,13.6,13.6,13.6,0,0,0,13.6,0Zm6.209,10.6q.009.2.009.4a8.82,8.82,0,0,1-8.88,8.88h0a8.835,8.835,0,0,1-4.784-1.4,6.348,6.348,0,0,0,.744.043,6.263,6.263,0,0,0,3.877-1.336,3.125,3.125,0,0,1-2.916-2.168,3.111,3.111,0,0,0,1.41-.054,3.122,3.122,0,0,1-2.5-3.059c0-.014,0-.027,0-.04a3.1,3.1,0,0,0,1.414.39A3.124,3.124,0,0,1,7.21,8.094a8.861,8.861,0,0,0,6.433,3.261,3.123,3.123,0,0,1,5.319-2.846,6.26,6.26,0,0,0,1.982-.758,3.133,3.133,0,0,1-1.373,1.726,6.225,6.225,0,0,0,1.792-.492A6.343,6.343,0,0,1,19.807,10.6Zm0,0" fill="#38a3fb"/>
                    </svg>
                  </h1>
                </div>
                <div className="row d-flex justify-content-between">
                  <div>
                    <p>Handle</p>
                    <p className="twitter-link"> @{twitter}</p>
                    {/* <a className='ml-2' href={`https://twitter.com/${twitter}`} target='_blank'>
                      <span>{` @${twitter}`}</span>
                    </a> */}
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          totalColumn.length !== 0 &&
          <Table className="PrintTable">
            <TableHeader>
              <TableRow>
                {
                  totalColumn.map(column => <TableCell
                    key={column.colId}>{column.userProvidedColDef.headerName}</TableCell>)
                }
              </TableRow>
            </TableHeader>
            <TableBody>
              {totalAtheletes && totalAtheletes.map((athlete, i) =>
                <TableRow key={`${athletes.userId}${i}`}>
                  {totalColumn.map((column, i) => {
                    if (column.colId === "gender") {
                      if (athlete[`${column.colId}`] === "male") {
                        athlete[`${column.colId}`] = "Male";
                      } else {
                        athlete[`${column.colId}`] = "Female";
                      }
                    }
                    if (column.colId === "invite") {
                      athlete.invited == 2 ? athlete[`${column.colId}`] = "Joined" :
                        (athlete.invited == 1 ? athlete[`${column.colId}`] = 'Invited' : athlete[`${column.colId}`] = 'Not Invited')
                    }
                    return <TableCell key={column.colId}>
                      {(column.colId === 'bats' || column.colId === 'throws') ? batOptions[athlete[`${column.colId}`]] :
                       athlete[`${column.colId}`]}
                      </TableCell>
                  })}
                </TableRow>
              )
              }
            </TableBody>

          </Table>
        }
      </React.Fragment>
    </Frame>
  );
};


export default PrintRoster;
