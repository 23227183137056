import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import {
  Container,
  Row,
  Col,
  LoadingSpinner,
  CloseLink,
  Line,
} from '../../UxCommon';
import {
  TextControl,
  Button,
  Headline,
  PasswordHelp,
  GeneralError,
} from '../../UxCommon/Form';
import { formOnEnterPress } from '../../UxCommon/Form/utils';
import { Sports, Routes } from '../../../store/actions/ui/routes';

class ChangePass extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    passwordHelp: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    formInit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { formInit } = this.props;
    formInit();
  }

  render() {
    const {
      submit,
      rrfForm,
      loading,
      loadingMsg,
      passwordHelp,
      uuid,
      sportId,
      formGeneralError,
      match,
    } = this.props;
    let sportSlug = '';
    if (sportId && Sports[sportId]) {
      sportSlug = Sports[sportId];
    }
    return (
      <div className="UxEditProfile_ChangePass">
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
        <CloseLink to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportSlug ? `/${sportSlug}` : ''}`} />
        <Container>
          <Row className="justify-content-center">
            <Col className="col-auto">
              <Form
                model="forms.changePassword"
                onSubmit={submit}
                onKeyDown={formOnEnterPress('forms.changePassword.submit')}
              >
                <Headline>
                  Change Your Password
                </Headline>
                <TextControl
                  label="Current Password"
                  placeholder="Current Password"
                  model="forms.changePassword.oldPassword"
                  rrfField={rrfForm.oldPassword}
                  type="password"
                />
                <TextControl
                  label="New Password"
                  placeholder="New Password"
                  model="forms.changePassword.newPassword"
                  // help={<PasswordHelp passwordHelp={passwordHelp} />}
                  rrfField={rrfForm.newPassword}
                  type="password"
                />
                <TextControl
                  label="Confirm New Password"
                  placeholder="Confirm New Password"
                  model="forms.changePassword.confirmNewPassword"
                  help="Please type your new password again"
                  rrfField={rrfForm.confirmNewPassword}
                  type="password"
                />
                <GeneralError error={formGeneralError} />
                <div className="d-flex">
                  <Button
                    id="forms.changePassword.submit"
                    type="submit"
                  >
                    Save New Password
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <Line style={{ paddingTop: '35px' }} />
        </Container>
      </div>
    );
  }
}

export default ChangePass;
