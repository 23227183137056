export const BankCodes = {
  PERF: "PERF",
  STRENGTH: "STRENGTH",
  HITTING: "HITTING",
  PITCHING: "PITCHING",
  COACHEVAL: "COACHEVAL"
};

export const schoolStyle = {
  id: '88d49d50-2d2e-11ea-a74d-29ddb6e8545a',
  name: 'National Leaderboard',
  color1: '#D81C10',
  color2: '#3B6EE6',
  darkmode: 0,
  background_image: null,
  school_logo: 'https://photocdn.virtualcombine.com/users/a24a09b4-fb9c-4440-a73e-e044625b67b2/ec41d910-4aba-11e9-bbbb-7b15f7f369cf_large.jpg',
  leaderboardBackground: 'https://d1o9un6w1m3wfl.cloudfront.net/Background-1x.png',
  leaderboardLogo: 'https://photocdn.virtualcombine.com/users/a24a09b4-fb9c-4440-a73e-e044625b67b2/ec41d910-4aba-11e9-bbbb-7b15f7f369cf_large.jpg',
  leaderboardStyle: 'dark',
};

export const ScoreType = {
  raw: 'raw',
  rated: 'score',
  relative: 'pfp_strength',
};

export const radiusOptions = {
  10: '10 miles',
  25: '25 miles',
  50: '50 miles',
  75: '75 miles',
  100: '100 miles',
  200: '200 miles',
  300: '300 miles',
  400: '400 miles',
  500: '500 miles',
  0: 'Any',
};

export const getDefaultRadius = () => Object.keys(radiusOptions)[1];


