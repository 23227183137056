import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { asyncErrorAction, asyncFinishAction } from '../../../async';
import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import {
  endorseAward,
  favoriteAward,
  getAward,
  unendorseAward,
  unfavoriteAward,
  updateAward,
} from '../../../../data/user/awards';
import { awardUpdateSuccess } from '../../../../data/user/awards/actions';
import { ENDORSE_AWARD, FAVORITE_AWARD, HIDE_AWARD, MARK_FAVORITE } from '../actions';
import { mapAwardApiToUi, mapEndorsementApiToUi } from '../../../../data/user/awards/models';

export const hideAwardEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(HIDE_AWARD),
    getTokenFragment(),
    switchMap(({ action, token }) => updateAward(
      uuid(),
      token,
      action.awardId,
      { display: false },
      isCoach(),
    ).pipe(
      map(response => ({ action, success: true, award: mapAwardApiToUi(response) })),
      takeUntil(cancelOnRouteChange(action$, Routes.awards, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(HIDE_AWARD, 'hideAward', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          awardUpdateSuccess(result.award, uuid()),
          asyncFinishAction(HIDE_AWARD, 'hideAward', { id: result.award.id }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

export const favoriteAwardEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(FAVORITE_AWARD),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCalls = [];
      Object.entries(action.sportIds).forEach(([sportId, fav]) => {
        if (sportId !== 0) {
          if (action.award.favorites) {
            if (action.award.favorites[sportId]) {
              if (!fav) {
                // delete
                apiCalls.push(unfavoriteAward(uuid(), action.award.id, sportId, token).pipe(
                  map(response => ({ success: true, response })),
                  takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                  ajaxErrorHandlerEpicFragment(),
                  catchError(error => of({
                    success: false,
                    action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
                  })),
                ));
              }
            } else if (fav) {
              // add
              apiCalls.push(favoriteAward(uuid(), action.award.id, sportId, token).pipe(
                map(response => ({ success: true, response })),
                takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                ajaxErrorHandlerEpicFragment(),
                catchError(error => of({
                  success: false,
                  action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
                })),
              ));
            }
          } else if (fav) {
            // add
            apiCalls.push(favoriteAward(uuid(), action.award.id, sportId, token).pipe(
              map(response => ({ success: true, response })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
              })),
            ));
          }
        }
      });
      if (action.sportIds[0]) {
        if (!action.award.favorites || !action.award.favorites[0]) {
          // set favorite
          apiCalls.push(updateAward(
            uuid(),
            token,
            action.award.id,
            { favorite: true },
          ).pipe(
            map(response => ({ success: true, response })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
            })),
          ));
        }
      } else if (action.award.favorites && action.award.favorites[0]) {
        // unset favoite
        apiCalls.push(updateAward(
          uuid(),
          token,
          action.award.id,
          { favorite: false },
        ).pipe(
          map(response => ({ success: true, response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(action.type, 'favoritePhotoVideo', error),
          })),
        ));
      }
      if (apiCalls.length) {
        return forkJoin(...apiCalls).pipe(
          checkForkErrors(),
          map((results) => {
            if (results.success) {
              return {
                success: true,
                action,
                token,
              };
            }
            return results;
          }),
        );
      }
      return of({ success: true, action, token });
    }),
    switchMap((results) => {
      if (results.success) {
        return getAward(
          uuid(),
          results.token,
          results.action.award.id,
        ).pipe(
          map(response => ({ ...results, award: mapAwardApiToUi(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(MARK_FAVORITE, 'markFavorite', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          awardUpdateSuccess(result.award, uuid()),
          asyncFinishAction(FAVORITE_AWARD, 'favoriteAward', { id: result.award.id }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};


export const endorseAwardEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  return action$.pipe(
    ofType(ENDORSE_AWARD),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const apiCall = action.isEndorsing ? endorseAward : unendorseAward;
      return apiCall(
        uuid(),
        token,
        action.award.id,
      ).pipe(
        map(response => ({
          action,
          success: true,
          award: mapEndorsementApiToUi(
            response,
            uuid(),
            action.award,
            action.isEndorsing,
          ),
        })),
        takeUntil(cancelOnRouteChange(action$, Routes.awards, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(ENDORSE_AWARD, 'endorseAward', error),
        })),
      );
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          awardUpdateSuccess(result.award, result.action.award.ownerId),
          asyncFinishAction(
            ENDORSE_AWARD,
            'endorseAward',
            {
              id: result.award.id,
              isEndorsing: result.action.isEndorsing,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

const updateEpics = combineEpics(
  hideAwardEpic,
  favoriteAwardEpic,
  endorseAwardEpic,
);

export default updateEpics;
