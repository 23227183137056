import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import TextControlGroup from "../../UxCommon/Form/TextControlGroup/TextControlGroup";
import { parserLower } from "../../../utils/parsers";
import {
  mergeAuthResetPassword,
  mergeForgotPassword,
  mergeResetPassword,
  Routes
} from "../../../store/actions/ui/routes";
import { MergeTypes } from "../MergeSignIn/MergeSignIn";

class ForgotPass extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    email: PropTypes.any
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    // this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      this.props.formInit();
    }
  };

  getRedirectURL = () => {
    const { match } = this.props;

    const isMergeLoginForgotPassword = match.url.match(Routes.mergeLoginForgotPassword);
    if (isMergeLoginForgotPassword) {
      return mergeResetPassword(
        MergeTypes.mergeBoth,
        match.params.uuid,
        match.params.token,
        match.params.teamName,
        match.params.coachName,
        match.params.sportId,
        match.params.userTeamId,
        match.params.schoolId,
      );
    }

    const isMergeLoginAuthForgotPassword = match.url.match(Routes.mergeLoginAuthForgotPassword);
    if (isMergeLoginAuthForgotPassword) {
      return mergeAuthResetPassword(
        MergeTypes.mergeBoth,
        match.params.uuid,
      );
    }

    return null;
  };

  submit = () => {
    this.props.submit(this.getRedirectURL());
  };

  formOnKeyDown = (e) => {
    if (e.key === "Enter") document.getElementById("forms.forgot.submit").focus();
  };

  render = () => (
    <div className="container welcome-first">
      <div className="row">
        <div className="col">
          <div className="welcome-msg">Password Assistance</div>
          <div className="welcome-desc">
            Enter the email or phone number associated with your account below
            and we&apos;ll send you a link to reset your password.
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4">
          <Form
            className="welcome-form"
            model="forms.forgot"
            onSubmit={this.submit}
            onKeyDown={this.formOnKeyDown}
          >
            <TextControlGroup
              label="Email"
              model="forms.forgot.username"
              parser={parserLower}
            />
            {
              this.props.formGeneralError &&
              <div className="form-group">
                <div className="form-general-error">{this.props.formGeneralError}</div>
              </div>
            }
            <div className="welcome-btns">
              <button id="forms.forgot.submit" className="welcome-btn" type="submit">Send Link</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;
