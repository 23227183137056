import { combineEpics } from 'redux-observable';
import reducers from './award.reducers';
import awardEpics from './award.epics';


const epics = combineEpics(awardEpics);

export {
  reducers,
  epics,
};

export * from './award.actions';
export * from './award.utils';
