import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { ButtonIconOnly } from '../../../../UxCommon';
import StatsEntry from './StatsEntry/StatsEntry';
import { formatResult } from '../../../../../containers/UxCommon/Utils';

class StatsRow extends PureComponent {
  static propTypes = {
    season: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    stdCats: PropTypes.array.isRequired,
    currentSport: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    onDeleteSeasonClick: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    rrfFormChange: PropTypes.func.isRequired,
  }

  componentDidMount() {}

  isLastTdInCat = (statPositions, statPosIndex, stdCats, catIndex) => {
    if ((statPosIndex === (statPositions.length - 1))
    && (catIndex !== (stdCats.length - 1))) {
      return true;
    }
    return false;
  }

  seasonDisplayDate = (season, currentSport) => {
    if (!currentSport.usesSeasonEndDate) {
      return season.startDate.format('MM/DD/YY');
    }
    if (season.startDate.year() !== season.stopDate.year()) {
      return `${season.startDate.year()} - ${season.stopDate.year()}`;
    }
    return `${season.startDate.year()}`;
  }

  render() {
    const {
      season,
      categories,
      stdCats,
      currentSport,
      editing,
      onDeleteSeasonClick,
      getRrfFormModel,
      rrfFormChange,
      isMyProfile,
    } = this.props;
    const formModel = getRrfFormModel();
    return (
      <tr>
        <td className="PlayerData_StatsTable_NameCell">
          { this.seasonDisplayDate(season, currentSport) }
          {
            editing &&
              <React.Fragment>
                <Control
                  type="hidden"
                  model={`${formModel}.new`}
                  defaultValue={false}
                  value={false}
                />
                <Control
                  type="hidden"
                  model={`${formModel}.season.id`}
                  defaultValue={season.id}
                  value={season.id}
                  readOnly
                />
              </React.Fragment>
          }
        </td>
        {
          stdCats
          .map((cat, catIndex) => cat.statPositions
          .map((statPos, statPosIndex) => {
            const userCategory = categories.find(c => c.category.id === cat.category.id);
            let stat = null;
            if (userCategory) {
              stat = userCategory.stats.find(st => st.stdStatPosition.id === statPos.id);
            }
            if (stat) {
              return (
                <td
                  key={statPos.id}
                  className={
                    this.isLastTdInCat(cat.statPositions, statPosIndex, stdCats, catIndex)
                    ? 'CatBorder'
                    : ''
                  }
                >
                  {
                    editing && !statPos.stdStat.computed ?
                      <React.Fragment>
                        <StatsEntry
                          className="PlayerData_StatsRow_Input"
                          model={`${formModel}.stats.${statPos.id}.results`}
                          stat={stat}
                          rrfFormChange={rrfFormChange}
                        />
                        <Control
                          model={`${formModel}.stats.${statPos.id}.id`}
                          type="hidden"
                          defaultValue={stat.id}
                          value={stat.id}
                          readOnly
                        />
                        <Control
                          model={`${formModel}.stats.${statPos.id}.statPosId`}
                          type="hidden"
                          defaultValue={statPos.id}
                          value={statPos.id}
                          readOnly
                        />
                      </React.Fragment>
                    :
                    formatResult(stat.results, stat.statFormatCode)
                  }
                </td>
              );
            }
            return (
              <td
                key={statPos.id}
                className={
                  this.isLastTdInCat(cat.statPositions, statPosIndex, stdCats, catIndex)
                  ? 'CatBorder'
                  : ''
                }
              >
                {0}
              </td>
            );
          }))
        }
        {
          isMyProfile ?
          <td className="PlayerData_StatsRow_DeleteCell">
            <ButtonIconOnly
              type="button"
              className="PlayerData_StatsRow_DeleteButton"
              iconName="delete"
              onClick={onDeleteSeasonClick}
            />
          </td>
          :
          <td className="PlayerData_StatsRow_DeleteCell" />
        }
      </tr>

    );
  }
}

export default StatsRow;
