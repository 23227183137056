import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import { asyncErrorAction, asyncFinishAction } from '../../../async';
import { cancelOnRouteChange, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { deleteAward } from '../../../../data/user/awards';
import { awardDeleteSuccess } from '../../../../data/user/awards/actions';
import { DELETE_AWARD } from '../actions';

export const deleteAwardEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const currSportId = () => state$.value.ui.app.routes.currentSportId;
  const currSchoolId = () => state$.value.ui.app.routes.currentSchoolId;
  return action$.pipe(
    ofType(DELETE_AWARD),
    getTokenFragment(),
    switchMap(({ action, token }) => deleteAward(uuid(), action.awardId, token, isCoach()).pipe(
      map(() => ({ action, success: true, uuid: uuid() })),
      takeUntil(cancelOnRouteChange(action$, Routes.awards, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(DELETE_AWARD, 'deleteAward', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          awardDeleteSuccess(result.action.awardId, result.uuid),
          asyncFinishAction(DELETE_AWARD, 'deletePhoto', 
          { id: result.action.awardId, 
            uuid: result.uuid,
            sportId: currSportId(),
            schoolId: currSchoolId()
           }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

const deleteEpics = combineEpics(deleteAwardEpic);

export default deleteEpics;
