export const formShowHelpTriage = (state, action, model) => {
  switch (action.type) {
    case 'rrf/focus':
      if (action.model === model) {
        return true;
      }
      return state;
    case 'rrf/blur':
      if (action.model === model) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

export const formShowHelp = (model, initAction) => (state = false, action) => {
  let newState = state;
  switch (action.type) {
    case initAction:
      return false;
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = formShowHelpTriage(newState, a, model);
      });
      return newState;
    default:
      return formShowHelpTriage(state, action, model);
  }
};

export const phoneHelpMsg = (value) => {
  const numbers = value ? value.replace(/[^0-9]+/g, '') : '';
  if (numbers.length > 11) {
    return ['That looks a little long for an American phone number.'];
  }
  return [
    'Like this: +1 (512) 234-5678',
    'Or this: 512-234-5678',
    'Or just like this: 5122345678',
  ];
};

const formPhoneHelpTriage = (state, action, model) => {
  switch (action.type) {
    case 'rrf/change':
      if (action.model === model) {
        return phoneHelpMsg(action.value);
      }
      return state;
    default:
      return state;
  }
};

export const formPhoneHelp = (model, initAction) => (state = phoneHelpMsg(''), action) => {
  let newState = state;
  switch (action.type) {
    case initAction:
      return phoneHelpMsg('');
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = formPhoneHelpTriage(newState, a, model);
      });
      return newState;
    default:
      return formPhoneHelpTriage(state, action, model);
  }
};

export const passwordHelpMsg = (value) => {
  const tests = [
    {
      msg: 'At least one lowercase letter',
      passing: value ? value.toUpperCase() !== value : value,
    },
    {
      msg: 'At least one uppercase letter',
      passing: value ? value.toLowerCase() !== value : value,
    },
    {
      msg: 'At least one number',
      passing: (/[0-9]/.test(value)),
    },
    {
      msg: 'At least one special character',
      passing: (/[-!$%^@#&*()_+|~=`{}[\]:";'<>?,./]/.test(value)),
    },
    {
      msg: 'Minimum length: 8',
      passing: value ? value.length >= 8 : false,
    },
  ];
  const passing = !tests.find(test => !test.passing);
  let msg;
  if (passing) {
    msg = 'Perfect!';
  } else {
    msg = "Let's make sure your password is super secure";
  }
  return { msg, tests };
};

const formPasswordHelpTriage = (state, action, model) => {
  switch (action.type) {
    case 'rrf/change':
      if (action.model === model) {
        return passwordHelpMsg(action.value);
      }
      return state;
    default:
      return state;
  }
};

export const formPasswordHelp = (model, initAction) => (state = passwordHelpMsg(''), action) => {
  let newState = state;
  switch (action.type) {
    case initAction:
      return passwordHelpMsg('');
    case 'rrf/batch':
      action.actions.forEach((a) => {
        newState = formPasswordHelpTriage(newState, a, model);
      });
      return newState;
    default:
      return formPasswordHelpTriage(state, action, model);
  }
};
