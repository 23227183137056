import { connect } from 'react-redux';
import Component from '../../components/UxProfile/BottomBanner/BottomBanner';

const mapStateToProps = state => ({
  bannerComponent: state.ui.uxProfile.banners.bottomBannerComponent,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    dummy: () => {
      dispatch({ type: 'noop' });
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
