import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow, TableBody } from '../../common/Table';
import CombineRow from '../CombineRow';
import { checkCanEditCombineResults } from '../../+store/combine/combine.utils';
import {GridBreakpoints} from "../../../../config/responsive";
import CombineHeader from "./CombineHeader.component";


export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOSA: function () {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  },
  iOSB: function () {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOSA() || isMobile.iOSB() || isMobile.Opera() || isMobile.Windows());
  }
};
class CombineTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    athletes: PropTypes.array.isRequired,
    bank: PropTypes.string.isRequired,
    combine: PropTypes.object.isRequired,
    saveTestResults: PropTypes.func.isRequired,
    staging: PropTypes.object.isRequired,
    myTeams: PropTypes.array.isRequired,
    isCoach: PropTypes.bool.isRequired,
    setExpiringCombineMsg: PropTypes.func.isRequired,
    isSavingArray: PropTypes.array,
    getTestResult: PropTypes.func,
  }

  state = {
    screenWidth: 1600,
    selectedColumn: -1,
  }

  componentDidMount() {
    const {
      myTeams, combine, setExpiringCombineMsg,
    } = this.props;
    if (!checkCanEditCombineResults(myTeams, combine, combine)) {
      setExpiringCombineMsg('Click on a test result to edit it', 'info', 15);
    }
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onSaveTestResult = (athlete, column) => (value, oldValue = {}) => {
    const { combine, saveTestResults } = this.props;
    saveTestResults(athlete, column, combine, value, false, oldValue);
  }

  onEnterPressed = (rowIndex, columnIndex, type='regular') => {
    const maxRowIndex = this.table.children[1].childElementCount - 2;
    const maxColumnIndex = this.table.children[1].children[0].childElementCount - 1;
    const haveChildren = this.table.children[1].children[rowIndex + 1]
      && this.table.children[1].children[rowIndex + 1].children.length > 0;
    const lastRow = rowIndex === maxRowIndex;
    const lastRowColumn = rowIndex === maxRowIndex && columnIndex === maxColumnIndex;
    const isOnlyColumn = maxColumnIndex === 1;
    if (haveChildren) {
      this.table.children[1].children[rowIndex + 1].children[columnIndex].children[0].focus();
    }
    if (lastRowColumn) {
      if (!isOnlyColumn) {
        this.table.children[1].children[rowIndex].children[1].children[0].focus();
      } else if (type === 'regular') {
        this.table.children[1].children[rowIndex].children[1].children[0].children[1].blur();
      } else if (type === 'reps') {
        this.table.children[1].children[rowIndex].children[1].children[0].children[1].children[0].blur();
        this.table.children[1].children[rowIndex].children[1].children[0].children[1].children[1].blur();
      } else if (type === 'laser') {
        this.table.children[1].children[rowIndex].children[0].children[0].click();
      }
    } else if (lastRow) {
      this.table.children[1].children[rowIndex].children[columnIndex + 1].children[0].focus();
    }
  }

  setRef = (input) => {
    this.table = input;
  }

  getTableWidth = () => {
    const { columns } = this.props;
    const { screenWidth } = this.state;
    const isSmallScreen = screenWidth < GridBreakpoints.lg;
    const base = isSmallScreen ? 800 : 1200;
    let extraCols;
    if (isMobile.Android()) {
      extraCols = columns.length > 2 ? columns.length - 4 : -4;
    } else {
      extraCols = columns.length > 2 ? columns.length - 5 : -4;
    }
    const calculatedWidth = base + ((extraCols) * 120);
    if (!isSmallScreen && ((screenWidth - 355) > calculatedWidth || columns.length <= 3)) {
      return (screenWidth - 360);
    }
    return calculatedWidth;
  }

  getCellOneWidthRaw = () => {
    const { screenWidth } = this.state;
    const tableWidth = this.getTableWidth();
    const isSmallScreen = screenWidth < GridBreakpoints.lg;
    const base = isSmallScreen ? 800 : 1200;
    return (20 / ((tableWidth / base) * 100)) * 100;
  }

  getCellOneWidth = () => `${this.getCellOneWidthRaw()}%`

  getOtherCellsWidth = () => {
    const { columns } = this.props;
    return `${(100 - this.getCellOneWidthRaw()) / columns.length}%`;
  }

  updateDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  }

  setSelectedColumn = (selectedColumn) => {
    const { columns } = this.props;
    if ( selectedColumn > columns.length - 1 || selectedColumn < 0) {
      this.setState({ selectedColumn: -1 });
    } else {
      this.setState({ selectedColumn });
    }
  }

  table = null;

  render() {
    const {
      bank,
      columns,
      athletes,
      staging,
      combine,
      myTeams,
      isCoach,
      setExpiringCombineMsg,
      isSavingArray,
      onVideoUploadHandler,
      onVideoDeleteHandler,
      updateAthleteVideoId,
      unstageNewMedia, stagedMedia, clearAthleteVideoId,
      videos,
      deleteVideoId,
      getRefreshPopUp,
      location,
      setOverFlowClass,
    } = this.props;
    const { screenWidth, selectedColumn } = this.state;
    const hasScrolledBelowMenu = true;
    const tableWidth = `${this.getTableWidth()}px`;
    const rightHeaderCellWidth = this.getOtherCellsWidth();
    return (
      <Table
        setReference={this.setRef}
        className="CombineTable smCombineTable"
        style={{ width: tableWidth }}
      >
        <CombineHeader
          hasScrolledBelowMenu={hasScrolledBelowMenu}
          bank={bank}
          columns={columns}
          tableWidth={tableWidth}
          frozenCellWidth={this.getCellOneWidth()}
          rightHeaderCellWidth={rightHeaderCellWidth}
          selectedColumn={selectedColumn}
          combine={combine}
        />
        <TableBody fixedHeaders={hasScrolledBelowMenu}>
          {
            athletes.map((athlete, index) => (
              <CombineRow
                screenWidth={screenWidth}
                tableWidth={tableWidth}
                key={index}
                athlete={athlete}
                columns={columns}
                getTestResult={this.props.getTestResult}
                onSaveTestResult={this.onSaveTestResult}
                getOtherCellsWidth={this.getOtherCellsWidth}
                getCellOneWidth={this.getCellOneWidth}
                updateAthleteVideoId={updateAthleteVideoId}
                staging={staging}
                combine={combine}
                location={location}
                videos={videos}
                bank={bank}
                myTeams={myTeams}
                unstageNewMedia={unstageNewMedia}
                stagedMedia={stagedMedia}
                deleteVideoId={deleteVideoId}
                clearAthleteVideoId={clearAthleteVideoId}
                onVideoUploadHandler={onVideoUploadHandler}
                onVideoDeleteHandler={onVideoDeleteHandler}
                isCoach={isCoach}
                setExpiringCombineMsg={setExpiringCombineMsg}
                onEnterPressed={this.onEnterPressed}
                rowIndex={index}
                getRefreshPopUp={getRefreshPopUp}
                isSavingArray={isSavingArray}
                selectedColumn={selectedColumn}
                setSelectedColumn={this.setSelectedColumn}
                setOverFlowClass={setOverFlowClass}
              />
            ))
          }
          <TableRow style={{ height: 20 }} />
        </TableBody>
      </Table>
    );
  }
}

export default CombineTable;
