import { combineReducers } from "redux";
import {
  AUTH2_CURRENT_USER_ERR,
  AUTH2_CURRENT_USER_REQ,
  AUTH2_CURRENT_USER_SUC,
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
  AUTH2_SAVE_PROFILE_SUC
} from "../../actions/data/cognito";
import {
  GET_BULK_PROFILES_SUC,
  USER_GET_PROFILE_SUC,
  USER_GET_USER_TEAMS_SUC,
  USER_PROFILE_DELETE_SUC,
  USER_PROFILE_UPDATE_SUC,
  USER_SAVE_PROFILE_ERR,
  USER_SAVE_PROFILE_PHOTO_SUC,
  USER_SAVE_PROFILE_REQ,
  USER_SAVE_PROFILE_SUC,
  USER_SPORT_CREATE_SUC,
  USER_SPORT_DELETE_SUC,
  USER_SPORT_UPDATE_SUC,
  USER_UPDATE_PHYSIQUE
} from "../../actions/data/user";
import { PHOTOS_UPLOAD_PHOTO_SUC } from "../../actions/data/photo";
import {
  AWARD_CREATE_SUC,
  AWARD_DEL_SUC,
  AWARD_ENDORSE_SUC,
  AWARD_UNENDORSE_SUC,
  AWARD_UPDATE_SUC,
  AWARDS_GET_FAVS_SUC,
  AWARDS_GET_SUC
} from "../../actions/data/user/awards/actions";
import { VANITY_URL_SAVE_SUC } from "../../actions/data/user/vanity";
import { TWITTER_HANDLE_SUC } from "../../actions/data/user/twitterHandle";
import { SAVE_COACH_PROFILE } from "../../../modules/CoachWorld/+store";
import { ASYNC_FINISH, ASYNC_START } from "../../actions/ui/async";
import { parseGPA } from "../../../utils/parsers";
import {
  PLAYER_PROFILE_DUPLICATES,
  PLAYER_PROFILE_DUPLICATES_SET,
  PROFILE_SHOW_CREATE_NEW_ACCOUNT,
  SAVE_PRIVACY
} from "../../actions/ui/uxProfile/profile";
import {
  EXTERNAL_PROFILE_CREATE_SUC,
  EXTERNAL_PROFILE_DEL_SUC,
  EXTERNAL_PROFILE_SUC,
  EXTERNAL_PROFILE_UPDATE_SUC
} from "../../actions/data/user/externalProfile/actions";
import { CONTINUE_ONBOARDING, STRIPE_CHECKOUT } from "../../actions/ui/welcome/StripeCheckout";
import { FORM_SUBMIT_SUCCESS } from "../../actions/ui/formUtils";
import { UPDATE_INVITE_PROFILE, UPDATE_INVITE_PROFILE_FORM_FINAL , INVITE_BACK_TO_PREVIOUS_STEP } from "../../../components/Welcome/AthletInviteOnboarding/+Store";
import {
  ATHLETE_ACADEMIC_PAGE,
  ATHLETE_COMPLETE_ONBOARDING, ATHLETE_PHYSIQUE_PAGE
} from "../../../components/Welcome/Invite/constants/Invite.constants";

const user = (state = null, action) => {
  switch (action.type) {
    case AUTH2_SAVE_PROFILE_SUC:
      return action.usResponse;
    case PHOTOS_UPLOAD_PHOTO_SUC:
    case USER_SAVE_PROFILE_PHOTO_SUC:
    case USER_SAVE_PROFILE_SUC:
      return action.user;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const cognitoUser = (state = null, action) => {
  switch (action.type) {
    case AUTH2_CURRENT_USER_SUC:
      // case AUTH2_SAVE_PROFILE_SUC:
      return action.awsResponse;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case AUTH2_CURRENT_USER_REQ:
    case USER_SAVE_PROFILE_REQ:
      return true;
    case AUTH2_CURRENT_USER_SUC:
    case AUTH2_CURRENT_USER_ERR:
    case USER_SAVE_PROFILE_SUC:
    case USER_SAVE_PROFILE_ERR:
      return false;
    default:
      return state;
  }
};

const duplicateProfiles = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === PLAYER_PROFILE_DUPLICATES) {
        return [...action.data];
      }
      return state;

    case PLAYER_PROFILE_DUPLICATES_SET:
      return [{...action.data}];

    default:
      return state;
  }
};

const showCreateNewAccount = (state = false, action) => {
  switch (action.type) {
    case PROFILE_SHOW_CREATE_NEW_ACCOUNT:
      return action.value;
    case PLAYER_PROFILE_DUPLICATES_SET:
      return false;

    default:
      return state;
  }
};

const loadingDuplicateProfiles = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PLAYER_PROFILE_DUPLICATES) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PLAYER_PROFILE_DUPLICATES) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const profiles = (state = {}, action) => {
  const stateToInsert = {};
  switch (action.type) {
    case GET_BULK_PROFILES_SUC:
      return Object.assign({}, state, action.profiles);
    case USER_GET_PROFILE_SUC:
      stateToInsert[action.uuid] = {
        ...action.data,
        coreGpa: parseGPA(action.data.coreGpa),
      };
      return Object.assign({}, state, stateToInsert);
    case USER_SPORT_CREATE_SUC:
    case USER_SPORT_UPDATE_SUC:
    case USER_SPORT_DELETE_SUC:
    case USER_PROFILE_UPDATE_SUC:
      stateToInsert[action.user.uuid] = {
        ...action.user,
        coreGpa: parseGPA(action.user.coreGpa),
      };
      return Object.assign({}, state, stateToInsert);
    case USER_PROFILE_DELETE_SUC:
      return Object.keys(state)
        .filter((key) => key !== action.uuid)
        .reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {});
    case VANITY_URL_SAVE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          instagram: action.name,
        }),
      });

    case TWITTER_HANDLE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          twitterHandle: action.name,
        }),
      });
    case USER_GET_USER_TEAMS_SUC:
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          teams: action.teams,
        }),
      });
    case USER_UPDATE_PHYSIQUE:
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          ...action.fields,
        }),
      });
    case PLAYER_PROFILE_DUPLICATES_SET:
      debugger;
      return Object.assign({}, state, {
        [action.data.inviteUuid]: Object.assign({}, state[action.data.inviteUuid], {
          email: action.data.email,
        }),
      });
      case FORM_SUBMIT_SUCCESS:
        if (action.model === 'forms.invitedSignUp') {
          return Object.assign({}, state, {
            [action.data.uuid]: Object.assign({}, state[action.data.uuid], {
              step: ATHLETE_PHYSIQUE_PAGE,
            }),
          });
        }
        return state;
    case ASYNC_FINISH:
      if (action.model === CONTINUE_ONBOARDING) {
        return Object.assign({}, state, {
          [action.data.uuid]: Object.assign({}, state[action.data.uuid], {
            isSubscribed: true,
          }),
        });
      }
      if (action.model === UPDATE_INVITE_PROFILE) {
        return Object.assign({}, state, {
          [action.data.uuid]: Object.assign({}, state[action.data.uuid], {
            step: ATHLETE_ACADEMIC_PAGE,
          }),
        });
      }
      if (action.model === UPDATE_INVITE_PROFILE_FORM_FINAL) {
        return Object.assign({}, state, {
          [action.data.uuid]: Object.assign({}, state[action.data.uuid], {
            step: ATHLETE_COMPLETE_ONBOARDING,
          }),
        });
      }
      if (action.model === INVITE_BACK_TO_PREVIOUS_STEP) {
        return Object.assign({}, state, {
          [action.action.uuid]: Object.assign({}, state[action.action.uuid], {
            step: ATHLETE_PHYSIQUE_PAGE,
          }),
        });
      }
      if (action.model === STRIPE_CHECKOUT) {
        return Object.assign({}, state, {
          [action.data.uuid]: Object.assign({}, state[action.data.uuid], {
            parentName: action.data.parentName,
          }),
        });
      }
      if (action.model.type === SAVE_COACH_PROFILE) {
        stateToInsert[action.model.uuid] = action.data.coach;
        return Object.assign({}, state, stateToInsert);
      }
      if (action.model === SAVE_PRIVACY) {
        return Object.assign({}, state, {
          [action.data.uuid]: Object.assign(
            {},
            state[action.data.uuid],
            action.data.privacy,
          ),
        });
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const awardsSortFunc = (a, b) => {
  if (a.date && b.date) {
    if (a.date.isBefore(b.date)) return 1;
    if (b.date.isBefore(a.date)) return -1;
  }
  if (a.createdAt.isBefore(b.createdAt)) return 1;
  if (b.createdAt.isBefore(a.createdAt)) return -1;
  return 0;
};

const awards = (state = {}, action) => {
  let sortMe;
  let newAward;
  switch (action.type) {
    case AWARDS_GET_SUC:
      sortMe = action.awards;
      sortMe.sort(awardsSortFunc);
      return Object.assign({}, state, {
        [action.uuid]: sortMe,
      });
    case AWARD_CREATE_SUC:
      sortMe = [...(state[action.uuid] || []), action.award];
      sortMe.sort(awardsSortFunc);

      return Object.assign({}, state, {
        [action.uuid]: sortMe,
      });
    case AWARD_UPDATE_SUC:
      sortMe = [
        ...state[action.uuid].filter((award) => award.id !== action.award.id),
        action.award,
      ];
      sortMe.sort(awardsSortFunc);
      return Object.assign({}, state, {
        [action.uuid]: sortMe,
      });
    case AWARD_ENDORSE_SUC:
      newAward = state[action.uuid]
        .find((award) => award.id !== action.award.id)
        .clone();
      newAward.endorsementCount += 1;
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter((award) => award.id !== action.award.id),
          newAward,
        ],
      });
    case AWARD_UNENDORSE_SUC:
      newAward = state[action.uuid]
        .find((award) => award.id !== action.award.id)
        .clone();
      newAward.endorsementCount += 1;
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter((award) => award.id !== action.award.id),
          newAward,
        ],
      });
    case AWARD_DEL_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(
          (award) => award.id !== action.awardId,
        ),
      });
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const awardFavoriteFilter = (award) => {
  let isFav = false;
  if (!award.display) return false;
  const favs = award.favorites;
  if (!favs) return false;
  Object.values(favs).forEach((fav) => {
    if (fav) isFav = true;
  });
  return isFav;
};

const awardBySport = (sortMe, sportId) => {
  const awards = sortMe.filter((award) => {
    let isFav = false;
    const favs = award.favorites;
    Object.keys(favs).forEach((favSportId) => {
      if (!Number(favSportId)) {
        isFav = true;
        return;
      }
      if (Number(favSportId) === Number(sportId)) {
        isFav = true;
      }
    });
    return isFav;
  });

  return awards;
};

const favoriteAwards = (state = {}, action) => {
  let sortMe, sortBySport;
  switch (action.type) {
    case AWARDS_GET_FAVS_SUC:
      sortMe = action.awards.filter(awardFavoriteFilter);
      sortBySport = awardBySport(sortMe, action.sportId);
      sortBySport.sort(awardsSortFunc);
      return Object.assign({}, state, {
        [action.uuid]: sortBySport,
      });
    default:
      return state;
  }
};

const externalProfile = (state = {}, action) => {
  switch (action.type) {
    case EXTERNAL_PROFILE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.externalProfile,
      });

    case EXTERNAL_PROFILE_CREATE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [...(state[action.uuid] || []), action.externalProfile],
      });

    case EXTERNAL_PROFILE_DEL_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(
          (exp) => exp.id !== action.externalProfileId,
        ),
      });
    case EXTERNAL_PROFILE_UPDATE_SUC:
      const currentExternalProfile = [
        ...state[action.uuid].filter(
          (exp) => exp.id !== action.externalProfile.id,
        ),
        action.externalProfile,
      ];
      return Object.assign({}, { [action.uuid]: currentExternalProfile });

    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const reducer = combineReducers({
  user,
  cognitoUser,
  loading,
  profiles,
  awards,
  favoriteAwards,
  externalProfile,
  duplicateProfiles,
  loadingDuplicateProfiles,
  showCreateNewAccount,
});

export default reducer;
