import { catchError, map, mergeMap } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import { forkJoin, from, of } from "rxjs";

import { cognitoSignIn, cognitoSignInSuccess } from "../../data/cognito";

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory
} from "../formUtils/operators";

import {
  validateIsPhoneOrEmail,
  validateJersey,
  validateLength,
  validateMaxLength,
  validatePhone,
  validateRequired
} from "../formUtils/validators";

import { formServerError, formSubmitSuccess } from "../formUtils/actions";
import { emailToUsername, formatPhoneForCognito, phoneToUsername } from "../../../../utils/cognito";
import { ajaxErrorHandlerEpicFragment } from "../ajaxErrorHandlers";
import { emailMergedSuccess, emailNeedsConfirmed, phoneNeedsConfirmed } from "../uxEditProfile/account";
import { getPlayerProfile, mergeSignIn, mergeWithoutInvite } from "../../data/user";
import { sportGetSports } from "../../data/sport";
import { checkForkErrors, commonApiCallFragment } from "../uxProfile/utils";
import { Routes } from "../routes";
import { mapUserApiToUi, Roles } from "../../data/user/profile/models";
import { resendEmailPhoneValidation } from "../uxEditProfile/confirmEmailPhone";
import { updateAthleteTeamInfo } from "../../../../modules/CoachWorld/+store/roster";

const mergeSignInFormValidation = {
  "forms.mergeLogin.username": [
    value => validateLength(1)(value),
    value => validateIsPhoneOrEmail()(value),
    value => validateRequired()(value),
  ],
  'forms.mergeLogin.password': [
    value => validateRequired()(value),
  ],
  'forms.mergeLogin.positions': [
    value => validateRequired()(value),
  ],
  'forms.mergeLogin.jerseyNumbers': [
    // value => validateRequired()(value),
    value => validateMaxLength(10)(value),
    value => validateJersey()(value),
  ],
};

let lastUsername = "";

const mergeSignInSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.mergeLogin;
  const formUsername = () => form().username.toLowerCase();
  return action$.pipe(
    isPendingIsValid(state$, "forms.mergeLogin", "mergeLogin"),
    mergeMap((action) => {
      let cognitoUsername = formUsername();
      return from(cognitoSignIn(
        cognitoUsername,
        form().password
      )).pipe(
        map(awsResponse => ({
          success: true,
          awsResponse,
          username: cognitoUsername,
          action
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError((error) => {
          if (error.code === "UserNotFoundException") {
            if (validatePhone()(formUsername()).phone === "") {
              cognitoUsername = phoneToUsername(formatPhoneForCognito(formUsername()));
            } else {
              cognitoUsername = emailToUsername(formUsername());
            }
            return from(cognitoSignIn(
              cognitoUsername,
              form().password
            )).pipe(map(awsResponse => ({
              success: true,
              awsResponse,
              username: formUsername(),
              action
            })));
          }
          throw error;
        }),
        catchError(error => of({
          success: false,
          action: formServerError("forms.mergeLogin", "aws", error, {
            username: formUsername(),
            password: form().password
          })
        }))
      );
    }),
    mergeMap((result) => {
      if (result.success) {
        if (lastUsername === formUsername()) {
          return of({ type: "IGNORE" });
        }
        lastUsername = formUsername();
        const authUuid = result.awsResponse.signInUserSession.accessToken.payload.sub;
        if (authUuid === result.action.attrs.uuid) {
          return of({
            success: true,
            uuid: authUuid,
            ...result
          });
        }
        if (authUuid !== result.action.attrs.uuid && result.action.attrs.isRegistered) {
          return of({
            success: false,
            loggedIn: true,
            awsResponse: result.awsResponse.signInUserSession.accessToken.payload.sub,
            action: formServerError(
              "forms.mergeLogin", "mergeError",
              "Your email address does not match the invitation.",
              { username: form().username }
            )
          });
        }

        return from(result.action.attrs.editEmail ? mergeWithoutInvite({
          existing_uuid: authUuid,
          invite_uuid: result.action.attrs.uuid,
        }) : mergeSignIn({
          existing_uuid: authUuid,
          token: result.action.attrs.token,
          invite_uuid: result.action.attrs.uuid,
          user_team_id: result.action.attrs.userTeamId,
          position: form().positions,
          jersey_number: form().jerseyNumbers,
          invited: 2
        })).pipe(
          map(response => ({
            success: true,
            uuid: result.awsResponse.signInUserSession.accessToken.payload.sub,
            ...result
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError("forms.mergeLogin", "mergeError", error, {
              username: form().username,
              password: form().password
            })
          }))
        );
      }
      return of(result);
    }),
    mergeMap((result) => {
      if (result.success) {
        return forkJoin(
          getPlayerProfile(result.uuid).pipe(commonApiCallFragment(action$, result.action, "getPlayerProfile", Routes.player)),
          sportGetSports()
            .pipe(commonApiCallFragment(action$, result.action, "getSports", Routes.player))
        ).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                ...result
              };
            }
            return forkResults;
          })
        );
      }
      return of(result);
    }),
    mergeMap(({
                success,
                loggedIn,
                awsResponse,
                action,
                actions,
                username,
                results
              }) => {
      if (success) {
        const playerProfile = mapUserApiToUi(results[0].response);
        if (playerProfile.roleId === Roles.coach && action.attrs.isMobile) {
          return of(formServerError("forms.mergeLogin", "aws", "Coaches can not login using the mobile app.", {
            username: formUsername(),
            password: form().password
          }));
        }

        // when we merge profile by updating the email, updating the team info not necessary
        if(!action.attrs.editEmail) {
          action.attrs.dispatch(updateAthleteTeamInfo(
            action.attrs.userTeamId,
            awsResponse.signInUserSession.accessToken.payload.sub,
            {
              position: form().positions,
              jersey_number: form().jerseyNumbers,
              user_team_id: action.attrs.userTeamId,
              token: null,
              invited: 2,
            }, null, null, null,
          ));

        }


        const successActions = [
          cognitoSignInSuccess(awsResponse),
          formSubmitSuccess("forms.mergeLogin", {
            awsResponse,
            username,
            redirectToEditProfile: action.attrs.editEmail,
            from: action.attrs.from,
            uuid: awsResponse.signInUserSession.accessToken.payload.sub,
            playerProfile
          })
        ];

        if(action.attrs.editEmail){
          successActions.push(emailMergedSuccess());
        }
        if (awsResponse.signInUserSession.idToken.payload.phone_number &&
          !awsResponse.signInUserSession.idToken.payload.phone_number_verified) {
          successActions.push(phoneNeedsConfirmed());
          successActions.push(resendEmailPhoneValidation(true));
        }
        if (awsResponse.signInUserSession.idToken.payload.email &&
          !awsResponse.signInUserSession.idToken.payload.email_verified) {
          successActions.push(emailNeedsConfirmed());
          successActions.push(resendEmailPhoneValidation(false));
        }
        return of(...successActions);
      }
      if (loggedIn) {
        return of(action, cognitoSignInSuccess(awsResponse));
      }
      if (actions) return of(...actions);
      return of(action);
    })
  );
};

export const mergeSignInFormEpic = combineEpics(
  mergeSignInSubmitEpic,
  validationOnSubmitEpicFactory(mergeSignInFormValidation, "forms.mergeLogin", "mergeLogin"),
  validationFailedEpicFactory("forms.mergeLogin", "mergeLogin"),
  ...formValidationEpicsFactory(mergeSignInFormValidation, "mergeLogin"),
  usernameOnChangeEpic("forms.mergeLogin.username", state => state.forms.mergeLogin.username)
);

export const MERGE_SIGN_IN_FORM_INIT = "MERGE_SIGN_IN_FORM_INIT";
export const mergeSignInFormInit = () => ({
  type: MERGE_SIGN_IN_FORM_INIT
});
