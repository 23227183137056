import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/MoveAlbumDialog/MoveAlbumDialog';
import {
  closeMoveAlbumDialog,
  executeMultiChangeAlbum,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { makeGetAlbumsState, getAlbumRelMedia } from '../../../selectors/Photos';

const getAlbumsState = makeGetAlbumsState();
const mapStateToProps = (state, ownProps) => ({
  albums: getAlbumsState(state, ownProps),
  albumRelMedia: getAlbumRelMedia(state, ownProps),
  loading: !!state.ui.uxProfile.photosVideos.moveAlbumDialog.loadingMsg,
  loadingMsg: state.ui.uxProfile.photosVideos.moveAlbumDialog.loadingMsg,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onCloseClick: () => {
      dispatch(closeMoveAlbumDialog());
    },
    onAlbumClick: (album) => {
      const mediaList = [];
      const selectedMedia = state.ui.uxProfile.photosVideos.multiDelete.selected;
      selectedMedia.forEach((media) => {
        const objAlbumRel = newStateProps.albumRelMedia.find(rel => rel.mediaId === media.id);
        if (objAlbumRel && objAlbumRel.albumId !== album.id) {
          media.oldAlbumId = objAlbumRel.albumId;
          media.newAlbumId = album.id;
          mediaList.push(media);
        } else {
          media.oldAlbumId = undefined;
          media.newAlbumId = album.id;
          mediaList.push(media);
        }
      });
      dispatch(executeMultiChangeAlbum(mediaList));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
