import { connect } from 'react-redux';
import Component from './Tutorials.component';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  state,
});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
