import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, ButtonRect } from '../../../../UxCommon';

class DeletePhotoDialog extends PureComponent {
  static propTypes = {
    onConfirmDeleteClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    media: PropTypes.object.isRequired,
  }

  componentDidMount() {
  }

  isAPhoto = photoVideo => photoVideo.constructor.name === 'Photo';

  render() {
    const { onConfirmDeleteClick, onCancelClick, media, canDelete } = this.props;

    return (
      <div className="UxProfile_Photos_DeletePhotoDialog_Backdrop">
        <div className="UxProfile_Photos_DeletePhotoDialog">
          <CloseButton onClick={onCancelClick} size={10} />
          <div style={{ margin: 'auto' }}>
            <div className="UxProfile_Photos_DeletePhotoDialog_Header">
              {canDelete ? 'Delete?' : 
              `You are not Authorized to delete this ${media.isAPhoto ? 'photo' : 'video'}` }
            </div>
            {canDelete && <div className="UxProfile_Photos_DeletePhotoDialog_Question">
              {`Are you sure you want to delete this ${media.isAPhoto ? 'photo' : 'video'}`}
            </div>}
          </div>
          <ButtonRect
            className="UxProfile_Photos_DeletePhotoDialog_Btn primary-delete"
            onClick={canDelete ? onConfirmDeleteClick : onCancelClick}
          >
            {canDelete ? 'Delete' : `Cancel`}
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default DeletePhotoDialog;
