import Media from './Media';

export default class Video extends Media {
  constructor() {
    super(false);
    this.path = '';
    this.fullStreamPaths = {
      '480p': '',
      '720p': '',
      '1080p': '',
    };
    this.thumbPaths = {
      '480p': '',
      '720p': '',
      '1080p': '',
    };
    this.hlsUrl = '';
    this.embedPath = '';
    this.externalThumb = '';
    this.needsIframe = false;
  }

  clone() {
    let video = new Video();
    video = super.clone();
    video.path = this.path;
    video.fullStreamPaths = this.fullStreamPaths;
    video.thumbPaths = this.thumbPaths;
    video.hlsUrl = this.hlsUrl;
    video.embedPath = this.embedPath;
    video.externalThumb = this.externalThumb;
    video.needsIframe = this.needsIframe;
  }

  fromApiResponse(response) {
    super.fromApiResponse(response);
    this.path = response.path;
    this.fullStreamPaths = response.full_stream_paths;
    this.thumbPaths = response.thumb_paths;
    this.hlsUrl = response.hlsUrl;
    this.embedPath = response.embed_path;
    this.externalThumb = response.external_thumb;
    if (this.embedPath) {
      const r = /^https:\/\/www\.hudl\.com\/embed\/video\//;
      if (r.exec(this.embedPath)) {
        this.needsIframe = true;
      }
    }
  }

  getThumb() {
    if (this.thumbPaths && this.thumbPaths['1080p']) {
      return this.thumbPaths['1080p'];
    }
    if (this.externalThumb) {
      return this.externalThumb;
    }
    return '';
  }

  isCoachTutorial() {
    return !!this.isTutorial;
  }
}

