import { combineReducers } from 'redux';

import { formPhoneHelp } from '../utils';
import { ACCOUNT_FORM_INIT, PHONE_NEEDS_CONFIRMED, EMAIL_NEEDS_CONFIRMED, SAVE_ACCOUNT, ACCOUNT_INIT } from '../../../actions/ui/uxEditProfile';
import { USER_GET_PROFILE_SUC, USER_PROFILE_UPDATE_SUC } from '../../../actions/data/user';
import { ROUTE_CHANGED, Routes } from '../../../actions/ui/routes';
import {
  ASYNC_START,
  ASYNC_FINISH,
  ASYNC_ERROR,
} from '../../../actions/ui/async';
import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_FAILED,
  FORM_SERVER_ERROR,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { COGNITO_VERIFY_PHONE_SUC, COGNITO_VERIFY_EMAIL_SUC } from '../../../actions/data/cognito/auth';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_SUC:
      return true;
    case ACCOUNT_INIT:
    case ACCOUNT_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const formSuccess = (state = false, action) => {
  switch (action.type) {
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_FAILED:
    case ROUTE_CHANGED:
    case 'rrf/blur':
    case 'rrf/batch':
      return false;
    case FORM_SUBMIT_SUCCESS:
      return action.model === 'forms.account';
    default:
      return state;
  }
};

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.account') {
        if (typeof action.error === 'string') {
          return action.error;
        }
        if (action.error.message) {
          return action.error.message;
        }
        return `Error: ${JSON.stringify(action.error)}`;
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (action.model === 'forms.account') {
        return 'Please correct the errors above';
      }
      return state;
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case ACCOUNT_FORM_INIT:
    case ACCOUNT_INIT:
    case 'rrf/blur':
    case 'rrf/batch':
      return '';
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
      if (action.model === 'forms.account') {
        return true;
      }
      return state;
    case ASYNC_START:
      if (action.model === SAVE_ACCOUNT) {
        return true;
      }
      return state;
    case FORM_SUBMIT_FAILED:
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.account') {
        return false;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === SAVE_ACCOUNT) {
        return false;
      }
      return state;
    case USER_GET_PROFILE_SUC:
      return false;
    default:
      return state;
  }
};

const loadingMsg = (state = 'Loading your account information', action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
      if (action.model === 'forms.account') {
        return 'Saving your account information';
      }
      return '';
    case ASYNC_START:
      if (action.model === SAVE_ACCOUNT) {
        return 'Saving your account information';
      }
      return state;
    case FORM_SUBMIT_FAILED:
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.account') {
        return '';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === SAVE_ACCOUNT) {
        return '';
      }
      return state;
    case USER_GET_PROFILE_SUC:
      return '';
    default:
      return state;
  }
};

const phoneNeedsConfirm = (state = false, action) => {
  switch (action.type) {
    case PHONE_NEEDS_CONFIRMED:
      return true;
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.confirmEmailPhone' || action.model === 'forms.confirmAccount') {
        return false;
      }
      return state;
    case COGNITO_VERIFY_PHONE_SUC:
      return false;
    default:
      return state;
  }
};

const emailNeedsConfirm = (state = false, action) => {
  switch (action.type) {
    case EMAIL_NEEDS_CONFIRMED:
      return true;
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.confirmEmailPhone' || action.model === 'forms.confirmAccount') {
        return false;
      }
      return state;
    case COGNITO_VERIFY_EMAIL_SUC:
      return false;
    default:
      return state;
  }
};

const successMessage = (state = '', action) => {
  switch (action.type) {
    case COGNITO_VERIFY_PHONE_SUC:
      return 'Phone Number Verified Successfully!';
    case FORM_SERVER_ERROR:
    case FORM_SUBMIT_FAILED:
      return '';
    case ROUTE_CHANGED:
      if (action.pathname === Routes.editAccount) {
        return state;
      }
      return '';
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.account') {
        return 'Account Updated Successfully!';
      } else if (action.model === 'forms.confirmEmailPhone' || action.model === 'forms.confirmAccount') {
        return 'Phone Number Verified Successfully!';
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === SAVE_ACCOUNT) {
        return 'Account Updated Successfully!';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loading,
  loadingMsg,
  formNeedsInit,
  formError,
  formSuccess,
  phoneNeedsConfirm,
  emailNeedsConfirm,
  successMessage,
  formPhoneHelp: formPhoneHelp('forms.account.phone', ACCOUNT_FORM_INIT),
  formParentsPhoneHelp: formPhoneHelp('forms.account.parentsPhone', ACCOUNT_FORM_INIT),
});

export default reducer;
