import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CombineAwardPlayer from './CombineAwardPlayer';
import { ButtonRect } from '../../../../components/UxCommon';
import CombineAwardDialog from './CombineAwardDialog';
class CombineAwardPage extends PureComponent {
  static propTypes = {
    athletes: PropTypes.array.isRequired,
    combine: PropTypes.object.isRequired,
    comparedAwards: PropTypes.array,
    createAward: PropTypes.func,
    denyAward: PropTypes.func,
    updateCombine: PropTypes.func,
    getCombineAwards: PropTypes.func,
    savedCombineAwards: PropTypes.array,
    getCombineAndPrevCombineTest: PropTypes.func,
    hideCombineAwardDialog: PropTypes.func,
    showCombineAwardDialog: PropTypes.func,
    isAwardGeneratedDialogVisible: PropTypes.bool,
    loadingAwardButton: PropTypes.bool,
    isSaveCombineAwardsDialogVisible: PropTypes.bool,
    hideGeneratedAwardDialog: PropTypes.func,
    shouldUpdate: PropTypes.bool,
  }

  state = {
    playersAward: [],
    isSelectAll: true,
    awardsCount: true
  }

  componentDidMount() {
    const {
      getCombineAwards,
      combine,
      getCombineAndPrevCombineTest,
      shouldUpdate,
      schoolIds
    } = this.props;
    if (shouldUpdate && !this.combineAwardsFetched) {
      this.combineAwardsFetched = true;
      getCombineAwards([combine.id]);
    }
    if (shouldUpdate && (!combine.isOpen) && !this.combinePrevFetched) {
      this.combinePrevFetched = true;
      getCombineAndPrevCombineTest(combine.id);
    }
  }

  componentDidUpdate(oldProps) {
    const { combine, getCombineAndPrevCombineTest } = this.props;
    if (oldProps.combine.isOpen !== combine.isOpen && (!combine.isOpen)) {
      getCombineAndPrevCombineTest(combine.id);
    }
  }

  combineAwardsFetched = false;
  combinePrevFetched = false;

  showSaveDialog = () => {
    const { showCombineAwardDialog } = this.props;
    showCombineAwardDialog();
  }

  closeCombine = () => {
    const { combine, updateCombine } = this.props;
    const data = {
      ...combine,
      closed_at: moment().utc().utcOffset(-6)
        .format('YYYY-MM-DD HH:mm:ss'),
    };
    updateCombine(data, combine);
  }

  saveCombine = () => {
    // console.log(this.state.playersAward, 'this is the player awards');
    this.createCombineAward(this.props.combine.id, this.state.playersAward)
    const { combine, updateCombine, hideCombineAwardDialog } = this.props;
    const data = {
      ...combine,
      awards_generated_bool: 1,
      showExecuteSucDialog: true,
    };
    updateCombine(data, combine);
    hideCombineAwardDialog();
  }

  denyCombineAward = (data, athleteId, awardId) => {
    this.props.denyAward(data, athleteId, awardId);
  }

  createCombineAward = (combineId, data) => {
    this.props.createAward(combineId, data);
  }
  //handle total awards
  handleAwardsCount = (count) => {
    if (count == this.state.playersAward.length) {
      this.setState({
        awardsCount: true,
        playersAward: [],
        isSelectAll: true
      })
    }
    else {
      this.setState({
        awardsCount: false,
      })
    }
  }

  //handle change
  handleChange = () => {
      if(!this.state.isSelectAll) {
        this.setState({
          playersAward: [],
          isSelectAll: true,
          awardsCount: true,
        })
      }
      else if(this.state.awardsCount) {
        this.setState({
        awardsCount: false,
        isSelectAll: false,
        })
      }
      else {
        this.setState({
          playersAward: [],
          awardsCount: true,
        })
      }
    }

  playersList = (checked, award) => {
    if(checked) {
      const data = {
        name: award.awardTitle,
        description: award.awardDesc,
        award_type: 'combine',
        user_id: award.athleteId,
      };
      this.setState((state) => ({
        playersAward: [...state.playersAward, data]
    }) )
    }

    else {
      const awards = this.state.playersAward;
      const index = awards.findIndex(a => (a.description === award.awardDesc && a.user_id === award.athleteId));
      awards.splice(index, 1)
      this.setState({
        playersAward: [...awards]
      })
    }
  }

  render() {
    const {
      athletes,
      comparedAwards,
      combine,
      savedCombineAwards,
      isAwardGeneratedDialogVisible,
      hideCombineAwardDialog,
      loadingAwardButton,
      isSaveCombineAwardsDialogVisible,
      hideGeneratedAwardDialog,
      schoolAwardLogo,
    } = this.props;
    console.log(comparedAwards,'comparedAwards silas');
    const { isSelectAll, awardsCount } = this.state;
    return (
    <div style={{ overflow: 'auto', paddingBottom: '130px', height: '100%' }}>
    {
        !combine.isOpen && combine.awardsGeneratedBool === 0 && combine.isCombineCreator &&
        <div className="Vc-select-btn">
        <input type="checkbox" name="selectAll" id="selectAll" checked={awardsCount} onChange={this.handleChange} className={`margin-3 CoachCheckboxControl ${isSelectAll && awardsCount ? 'checked' : ''}`} />
        <label htmlFor=""><b> Select All </b></label>
        </div>
    }
    {
        !combine.isOpen ? comparedAwards && comparedAwards.length > 0 && (
        athletes.map((athlete, i) => {
            let awards = comparedAwards
            .filter(x => (x.bestObjectArr.length > 0 && x.bestObjectArr
                .find(ath => ath.athleteId === athlete.userId))
                || (x.mostImprovedObjectArr.length > 0 && x.mostImprovedObjectArr
                .find(ath => ath.athleteId === athlete.userId)));

            awards = awards.map((award) => {
            const bestObject = award.bestObjectArr
                .find(ath => ath.athleteId === athlete.userId);
            const mostImprovedObject = award.mostImprovedObjectArr
                .find(ath => ath.athleteId === athlete.userId);
            return { ...award, bestObject, mostImprovedObject };
            });

            if (combine.awardsGeneratedBool === 1) {
            const savedCombineAwardNames = savedCombineAwards.map(sc => sc.name);
            const savedCombineAwardsId = savedCombineAwards.map(sc => sc.ownerId);
            awards = awards.filter((award) => {
                const { mostImprovedObject, bestObject } = award;
                const doesInclude = savedCombineAwardNames
                .includes(mostImprovedObject && mostImprovedObject.awardTitle)
                && savedCombineAwardsId.includes(mostImprovedObject.athleteId);
                const doesIncludeBest = savedCombineAwardNames
                .includes(bestObject && bestObject.awardTitle)
                && savedCombineAwardsId.includes(bestObject.athleteId);
                return doesInclude || doesIncludeBest;
            });
            }

            if (awards && awards.length > 0) {
            return (
                <CombineAwardPlayer
                key={i}
                createCombineAward={this.createCombineAward}
                denyCombineAward={this.denyCombineAward}
                combine={combine}
                awards={awards}
                athlete={athlete}
                savedCombineAwards={savedCombineAwards}
                loadingAwardButton={loadingAwardButton}
                schoolAwardLogo={schoolAwardLogo}
                playersList={this.playersList}
                isSelectAll={isSelectAll}
                awardsCount={awardsCount}
                handleAwardsCount={this.handleAwardsCount}
                />
            );
            }
            return null;
        })) : (

            <div className="CombineAwards_CloseDiv VC_Grant_Award">
            {combine && (combine.isCombineCreator === true) ?
                <Fragment>
                <div className="CombineAwards_CloseDiv_Question">
                    Awards can only be issued after a combine has been closed.

                    Are you ready to close this combine and issue awards
                </div>

                <ButtonRect
                    className="CombineAwards_Close_Button primary"
                    onClick={this.closeCombine}
                    type="button"
                    style={{ marginRight: '24px' }}
                >
                    Close Combine
                </ButtonRect>
                </Fragment>
                :
                <div className="CombineAwards_CloseDiv_Question">
                Awards can only be issued after a combine has been closed.
                You do not have permission to close this combine.
                </div>
            }
            </div>
        )
    }
    {
        !combine.isOpen && combine.awardsGeneratedBool === 0 && combine.isCombineCreator &&
        <ButtonRect
        className="Awards_SaveAwardsBtn primary"
        onClick={this.showSaveDialog}
        >
        Grant Awards
        </ButtonRect>
    }
    {
        isSaveCombineAwardsDialogVisible &&
        <div>
        <div>
            <CombineAwardDialog
            onCancelClick={hideCombineAwardDialog}
            header="Grant Awards"
            body="Warning: once you close a combine and grant awards,
            you will not be able to go back and edit your awards without reopening the combine.
            Are you ready to issue awards and mark this combine complete?"
            onSaveClick={this.saveCombine}
            saveText="Grant"
            />
        </div>
        </div>
    }
    {
        isAwardGeneratedDialogVisible &&
        <CombineAwardDialog
        onCancelClick={hideGeneratedAwardDialog}
        header="Execute Awards"
        body={`Congratulations,  you’ve completed the data entry for ${combine.name}.  Please click the Generate Report`}
        cancelText="OK"
        />
    }
    </div>
      
    );
  }
}


export default CombineAwardPage;
