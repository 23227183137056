import { combineReducers } from 'redux';

import {
  ASYNC_START,
  ASYNC_FINISH,
  ASYNC_ERROR,
} from '../../../actions/ui/async';
import {
  OLD_PROFILE_PHOTOS,
  USER_PROFILE_PHOTO,
  EP_PHOTO_DROP_ERROR,
  EP_PHOTO_DROP,
  EP_PHOTO_DROP_ONLOAD,
  EP_PHOTO_DISCARD,
  EP_PHOTO_SAVE_NEW,
} from '../../../actions/ui/uxEditProfile';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { PHOTO_GET_PROFILE_PHOTOS_SUC } from '../../../actions/data/photo';
import { UPLOAD_PROGRESS } from '../../../actions/ui/uxProfile/photosVideos';

const loadingPhotosMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === OLD_PROFILE_PHOTOS) {
        return action.msg;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === OLD_PROFILE_PHOTOS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingPhotos = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === OLD_PROFILE_PHOTOS) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === OLD_PROFILE_PHOTOS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const loadingProfilePhotoMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if ((action.model === USER_PROFILE_PHOTO) ||
        (action.model === EP_PHOTO_SAVE_NEW)) {
        return action.msg;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if ((action.model === USER_PROFILE_PHOTO) ||
        (action.model === EP_PHOTO_SAVE_NEW)) {
        return '';
      }
      return state;
    case UPLOAD_PROGRESS:
      if (action.msg) {
        return action.msg;
      }
      return state;
    default:
      return state;
  }
};

const loadingProfilePhoto = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if ((action.model === USER_PROFILE_PHOTO) ||
      (action.model === EP_PHOTO_SAVE_NEW)) {
        return true;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if ((action.model === USER_PROFILE_PHOTO) ||
      (action.model === EP_PHOTO_SAVE_NEW)) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const photoDropError = (state = false, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ERROR:
      return true;
    case ROUTE_CHANGED:
      return false;
    default:
      return state;
  }
};

const photoFileDropped = (state = null, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP:
      return action.file;
    case EP_PHOTO_DISCARD:
    case EP_PHOTO_SAVE_NEW:
      return null;
    default:
      return state;
  }
};

const photoCropDataLoading = (state = false, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP:
      return true;
    case EP_PHOTO_DROP_ONLOAD:
      return false;
    case EP_PHOTO_DISCARD:
      return false;
    default:
      return state;
  }
};

const photoDataToCrop = (state = null, action) => {
  switch (action.type) {
    case EP_PHOTO_DROP_ONLOAD:
      return action.image;
    case EP_PHOTO_DISCARD:
    case EP_PHOTO_SAVE_NEW:
      return null;
    default:
      return state;
  }
};

const oldPhotosNeedsInit = (state = false, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case ASYNC_FINISH:
      if (action.model === EP_PHOTO_SAVE_NEW) {
        return true;
      }
      return state;
    case PHOTO_GET_PROFILE_PHOTOS_SUC:
      return false;
    default:
      return state;
  }
};

const initialUploadProgress = {
  totalLoaded: 0,
  totalBytes: 0,
  percent: 0,
};
const loadingProgress = (state = initialUploadProgress, action) => {
  switch (action.type) {
    case UPLOAD_PROGRESS:
      if (action.reset) {
        return Object.assign({}, initialUploadProgress);
      }
      if ((action.progressEvent) && (action.progressEvent.lengthComputable)) {
        return Object.assign({}, state, {
          totalBytes: action.totalBytes,
          totalLoaded: action.progressEvent.loaded,
          percent: Math.round((action.progressEvent.loaded / action.totalBytes) * 100),
        });
      }
      return state;
    case ASYNC_START:
      if (action.model === USER_PROFILE_PHOTO) {
        return null;
      }
      return state;
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === USER_PROFILE_PHOTO) {
        return Object.assign({}, initialUploadProgress);
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingPhotos,
  loadingPhotosMsg,
  loadingProfilePhoto,
  loadingProfilePhotoMsg,
  photoFileDropped,
  photoDataToCrop,
  photoDropError,
  oldPhotosNeedsInit,
  photoCropDataLoading,
  loadingProgress,
});

export default reducer;
