import {CloseButton} from "../index";
import React from "react";

const GeneralMessageModal = ({message, toggleModal}) => {
  const handleClick = () => {
    toggleModal(); // Call the callback function from the class component
  };

  return (
    <div className="leaderboard_msgModal_Backdrop">
      <div className="leaderboard_msgModal_Modal">
        <CloseButton onClick={handleClick} size={14}/>
        <div className="leaderboard_msgModal_Msg">
          {message}
        </div>

      </div>
    </div>
  );
}

export default GeneralMessageModal;
