import Base from './Base';

export default class Media extends Base {
  constructor(isAPhoto = true) {
    super();
    this.caption = '';
    this.uploadUrl = '';
    this.uploadComplete = 0;
    this.processed = false;
    this.isAPhoto = isAPhoto;
    this.favorites = null;
    this.isCreatedByCoach = false;
  }

  clone() {
    let media = new Media();
    media = media.super.clone();
    media.caption = this.caption;
    media.uploadUrl = this.uploadUrl;
    media.uploadComplete = this.uploadComplete;
    media.processed = this.processed;
    media.isAPhoto = this.isAPhoto;
    media.favorites = this.favorites ? Object.assign({}, this.favorites) : null;
    media.isCreatedByCoach = this.isCreatedByCoach;
    return media;
  }

  fromApiResponse(response) {
    super.fromApiResponse(response);
    this.caption = response.caption;
    this.uploadUrl = response.upload_url;
    this.uploadComplete = response.upload_complete;
    this.processed = response.processed;
    this.isCreatedByCoach= response.is_createdByCoach || false;
    if (!response.favorite && (!response.sports || !response.sports.length)) {
      this.favorites = null;
    } else {
      this.favorites = {};
      if (response.favorite) {
        this.favorites[0] = true;
      }
      if (response.sports) {
        response.sports.forEach((sport) => {
          this.favorites[sport.sport_id] = true;
        });
      }
    }
  }
}
