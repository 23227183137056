import React from 'react';
import PropTypes from 'prop-types';

const TableFooter = ({ className, style, children }) => (
  <thead
    className={['LeaderboardTableFooter', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </thead>
);
TableFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};

export default TableFooter;
