import { asyncStartAction } from '../../../../store/actions/ui/async';
import { fixRotation } from '../../../../containers/UxCommon/ImageUtils';

export const GET_USER_TEAMS = 'coachWorld.getUserTeams';
export const getUserSchoolTeams = uuid => (dispatch) => {
  dispatch(asyncStartAction(GET_USER_TEAMS));
  dispatch({
    type: GET_USER_TEAMS,
    uuid,
  });
};

export const GET_COACH_AND_ATHLETE_TEAMS = 'coachWorld.getCoachAndAthleteTeams';
export const getCoachAndAthleteTeams = (coachUuid, athleteUuid) => (dispatch) => {
  dispatch(asyncStartAction(GET_COACH_AND_ATHLETE_TEAMS));
  dispatch({
    type: GET_COACH_AND_ATHLETE_TEAMS,
    coachUuid,
    athleteUuid,
  });
};

export const PostMessageLevels = {
  error: 'error',
  info: 'info',
};
export const POST_MESSAGE = 'coachWorld.postMessage';
export const postMessage = (message, logLevel) => ({
  type: POST_MESSAGE,
  message,
  logLevel,
});
export const POP_MESSAGE = 'coachWorld.popMessage';
export const popMessage = messageId => ({
  type: POP_MESSAGE,
  messageId,
});

export const GET_SCHOOL_STYLES = 'coachWorld.getSchoolStyles';
export const getSchoolStyles = schoolIds => (dispatch) => {
  dispatch(asyncStartAction(GET_SCHOOL_STYLES));
  dispatch({
    type: GET_SCHOOL_STYLES,
    schoolIds,
  });
};

export const GET_SCHOOL_TEAMS_LIST = 'coachWorld.getSchoolTeamsList';
export const getSchoolTeamsList = schoolId => (dispatch) => {
  dispatch(asyncStartAction(GET_SCHOOL_TEAMS_LIST, 'school list', {
    schoolId,
  }));
  dispatch({
    type: GET_SCHOOL_TEAMS_LIST,
    schoolId,
  });
};

export const UPDATE_SCHOOL_STYLES = 'coachWorld.updateSchoolStyles';
export const updateSchoolStyles = (schoolID, newSchoolStyle) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_SCHOOL_STYLES));
  dispatch({
    type: UPDATE_SCHOOL_STYLES,
    schoolID,
    newSchoolStyle,
  });
};

export const SAVE_COACH_PROFILE = 'coachWorld.saveCoachProfile';
export const saveCoachProfile = (data, uuid) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_COACH_PROFILE));
  dispatch({
    type: SAVE_COACH_PROFILE,
    data,
    uuid,
  });
};

export const SHOW_UPLOAD_PHOTO_DIALOG = 'combine.showUploadPhotoDialog';
export const showUploadPhotoDialog = () => (dispatch) => {
  dispatch({
    type: SHOW_UPLOAD_PHOTO_DIALOG,
  });
};

export const HIDE_UPLOAD_PHOTO_DIALOG = 'combine.hideUploadPhotoDialog';
export const hideUploadPhotoDialog = () => (dispatch) => {
  dispatch({
    type: HIDE_UPLOAD_PHOTO_DIALOG,
  });
};

export const PHOTO_DROP_SCHOOL_STYLE = 'editSchoolStyle.schoolStyleImages';
export const PHOTO_DROP_ERROR_SCHOOL_STYLE = 'editSchoolStyle.dropError';
export const updateSchoolStyleImages = (dispatch, schoolStyle, isBackground) =>
  async (accepted, rejected) => {
    if (accepted.length) {
      const file = accepted[0];
      const returnedFile = await fixRotation(file);
      dispatch({
        type: PHOTO_DROP_SCHOOL_STYLE,
        file: returnedFile,
        totalBytes: returnedFile.size,
        dispatch,
        mediaId: 'schoolStyle',
        schoolStyle,
        isBackground,
      });
    } else {
      dispatch({ type: PHOTO_DROP_ERROR_SCHOOL_STYLE, rejected });
    }
  };

export const UPLOAD_PROGRESS_SCHOOL_STYLE = 'editSchoolStyle.uploadProgress.update';
export const uploadProgressUpdate = (progressEvent, totalBytes, mediaId, msg, reset) => ({
  type: UPLOAD_PROGRESS_SCHOOL_STYLE,
  progressEvent,
  totalBytes,
  mediaId,
  msg: msg || '',
  reset: reset || false,
});

export const RESET_UPDATING_STYLE_STATUS = 'editSchoolStyle.uploadProgress.reset';
export const resetUpdatingStyleStatus = () => (dispatch) => {
  dispatch({
    type: RESET_UPDATING_STYLE_STATUS,
  });
};

export default 'coachWorld.actions.js';
