/* eslint react/no-unused-state: 0 */
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import { fromEvent } from 'rxjs';

import HelpPopper from '../HelpPopper/HelpPopper';
// import Errors from '../Errors/Errors';

class StatsCustomControl extends React.PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    help: PropTypes.any,
    defaultValue: PropTypes.any,
    parser: PropTypes.func,
    allowedCharacters: PropTypes.func,
    className: PropTypes.string,
    onBlurParse: PropTypes.func,
    getInitialFormattedValue: PropTypes.func,
    rrfFormChange: PropTypes.func.isRequired,
    normalizeValue: PropTypes.func.isRequired,
  }

  static defaultProps = {
    parser: val => val,
    allowedCharacters: () => true,
    help: '',
    className: '',
    getInitialFormattedValue: val => val,
  }

  state = {
    value: '',
    focus: false,
    defaultValue: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.defaultValue !== state.defaultValue) {
      return {
        defaultValue: props.defaultValue,
        value: props.getInitialFormattedValue(props.defaultValue),
      };
    }
    return null;
  }

  componentDidMount() {
    const ele = this.inputRef.current;
    this.subscriptions.push(fromEvent(ele, 'paste').subscribe((result) => {
      this.onPaste(result);
    }));
    this.subscriptions.push(fromEvent(ele, 'keydown').subscribe((result) => {
      this.onInput(result);
    }));
    this.subscriptions.push(fromEvent(ele, 'cut').subscribe((result) => {
      this.onCut(result);
    }));
    this.subscriptions.push(fromEvent(ele, 'focus').subscribe((result) => {
      this.onFocus(result);
    }));
    this.subscriptions.push(fromEvent(ele, 'blur').subscribe((result) => {
      this.onBlur(result);
    }));
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  onFocus = () => {
    this.setState({
      focus: true,
    });
  }

  onBlur = () => {
    const { onBlurParse, parser } = this.props;
    const { value } = this.state;
    let newValue = value;
    if (onBlurParse) {
      const { parsedText } = parser(value, 0);
      newValue = onBlurParse(parsedText);
    }
    this.setState({
      focus: false,
      value: newValue,
    });
  }

  onPaste = (event) => {
    const { allowedCharacters } = this.props;
    event.clipboardData.items[0].getAsString((text) => {
      const filteredText = text.split('')
        .map(letter => (allowedCharacters(letter) ? letter : ''))
        .join('');
      this.insertText(filteredText, this.selectionStart, this.selectionEnd);
    });
    event.preventDefault();
  }

  onCut = (event) => {
    const oldValue = this.state.value;
    const { selectionStart, selectionEnd } = this.inputRef.current;
    this.setState({
      value: oldValue.slice(0, selectionStart) + oldValue.slice(selectionEnd),
    }, this.setCursorPosition(selectionStart));
    event.clipboardData.items.add(oldValue.slice(selectionStart, selectionEnd), 'text/plain');
    event.preventDefault();
  }

  onInput = (result) => {
    const oldValue = this.state.value;
    const { selectionStart, selectionEnd } = this.inputRef.current;
    const {
      allowedCharacters,
      parser,
      model,
      rrfFormChange,
      normalizeValue,
    } = this.props;
    if ((result.key === 'v') && (result.ctrlKey || result.metaKey)) {
      // Save the cursor position for the paste event
      this.selectionStart = selectionStart;
      this.selectionEnd = selectionEnd;
      return;
    }
    if (result.ctrlKey || result.metaKey) {
      return;
    }
    if (result.key === 'Backspace') {
      // Handle backspace
      let newValue;
      let cursorPos;
      if (selectionStart === selectionEnd) {
        newValue = oldValue.slice(0, selectionStart - 1) + oldValue.slice(selectionEnd);
        cursorPos = selectionStart - 1;
      } else {
        newValue = oldValue.slice(0, selectionStart) + oldValue.slice(selectionEnd);
        cursorPos = selectionStart;
      }
      this.setState({
        value: newValue,
      }, this.setCursorPosition(cursorPos));
      rrfFormChange(model, normalizeValue(newValue));
      result.preventDefault();
      return;
    }
    if (result.key.length === 1) {
      if (allowedCharacters(result.key)) {
        // Handle letters accepted by the parser
        this.insertText(result.key, selectionStart, selectionEnd);
        result.preventDefault();
      } else {
        // Handle single letters rejected by the parser
        this.setState({
          value: oldValue,
        }, this.setCursorPosition(selectionStart));
        result.preventDefault();
      }
    }
  }

  setCursorPosition = pos => () => {
    this.inputRef.current.setSelectionRange(pos, pos);
  }

  insertText = (text, selectionStart, selectionEnd) => {
    const {
      parser,
      model,
      rrfFormChange,
      normalizeValue,
    } = this.props;
      const oldValue = this.state.value;
      const unparsedText = oldValue.slice(0, selectionStart)
        + text
        + oldValue.slice(selectionEnd);
      const unparsedCursorPos = selectionStart + text.length;
      const { parsedText, cursorPos } = parser(unparsedText, unparsedCursorPos);
      this.setState({
        value: parsedText,
      }, this.setCursorPosition(cursorPos));
      rrfFormChange(model, normalizeValue(parsedText));
  }

  inputRef = React.createRef();
  subscriptions = [];

  handleChange = () => {}

  render() {
    const {
      help,
      model,
      defaultValue,
      className,
      normalizeValue,
    } = this.props;
    const { value, focus } = this.state;
    return (
      <div className="StatsCustomControl">
        <input ref={this.inputRef} className={className} type="text" value={value} onChange={this.handleChange} />
        <HelpPopper focus={focus} helpClass="bottom">
          {help}
        </HelpPopper>
        <Control
          type="hidden"
          model={model}
          defaultValue={defaultValue}
          value={normalizeValue(value)}
          readOnly
        />
      </div>
    );
  }
}

export default StatsCustomControl;
