import React from 'react';
import PropTypes from 'prop-types';

const HelpPopper = ({
  error,
  focus,
  children,
  helpClass,
  classNameHelpPop,
  classNameHelpPopInner,
}) => (
  <div
    className={[
      classNameHelpPop? classNameHelpPop :'UxCommon_Form_HelpPopper',
      focus ? 'show' : '',
      helpClass || 'right',
      error ? 'error' : '',
    ].join(' ')}
  >
    <div className={classNameHelpPopInner ? classNameHelpPopInner :"UxCommon_Form_HelpPopper_Inner"}>{children}</div>
  </div>
);
HelpPopper.propTypes = {
  children: PropTypes.any,
  focus: PropTypes.bool,
  helpClass: PropTypes.string,
  error: PropTypes.bool,
};

export default HelpPopper;
