import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import linkSvg from "../../../../../../assets/images/play-svg-icon.svg";

import { PhotosCircleButton } from '../../../../../UxCommon';
import { TextAreaControl } from '../../../../../UxCommon/Form';

const defaultRrfField = {
  errors: {},
  valid: true,
};

class StagedPhotoCard extends PureComponent {
  static propTypes = {
    media: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    getRrfForm: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    selectedTags: PropTypes.array,
    showMediaIsNotAccepted: PropTypes.func,
  }

  state = {
    duration: 0,
  }

  componentWillMount() {
    const { showMediaIsNotAccepted } = this.props;
    const videoLimitMsg = this.checkVideoLimits();
    if (videoLimitMsg !== '') {
      showMediaIsNotAccepted(videoLimitMsg);
      this.onDeleteClick();
    }
  }

  componentDidMount() {
    this.element = document.getElementById(this.myid());
    if (this.isSupportedVideoType()) {
      this.element.addEventListener(
        'loadedmetadata',
        this.changeTime,
      );
    }
  }

  componentWillUnmount() {
    if (this.isSupportedVideoType()) {
      this.element.removeEventListener(
        'loadedmetadata',
        this.changeTime,
      );
    }
  }

  onDeleteClick = () => {
    const { media, onDeleteClick } = this.props;
    onDeleteClick(media);
  }

  getImage = (media) => {
    if (media.isAPhoto) {
      return (
        <img
          className="Photos_AlbumDialog_PhotoVideoCard_Img"
          id={this.myid()}
          src={media.file.preview}
          alt="Preview"
        />
      );
    }
    // Media is a video from external source
    if (!media.file && media.externalVideo) {
      return (
        <img
          className="Photos_AlbumDialog_PhotoVideoCard_Img"
          id={this.myid()}
          src={media.externalVideo.thumb}
          alt="Preview"
        />
      );
    }
    // Media is a video
    return (
      ['video/webm', 'video/mp4', 'video/ogg'].indexOf(media.file.type) >= 0 ? (
        <video
          className="Photos_AlbumDialog_PhotoVideoCard_Video"
          id={this.myid()}
          preload="metadata"
        >
          <source src={media.file.preview} />
          <track kind="captions" />
          <div className="Photos_AlbumDialog_PhotoVideoCard_NoPreview">
            <div>Your browser cannot preview this video type.</div>
          </div>
        </video>
      ) : media.file.type === 'video/quicktime' ? (
        <div className="Photos_AlbumDialog_PhotoVideoCard_NoPreview">
          <img src={linkSvg} alt="default mov" style={{width: "75%",margin:"auto"}}/>
        </div>
      ) : (
        <div className="Photos_AlbumDialog_PhotoVideoCard_NoPreview">
          <div>Your browser cannot preview this video type.</div>
        </div>
      )
    );
  };

  checkVideoLimits = () => {
    const { media } = this.props;
    const { duration } = this.state;
    if (!(media && media.file) && media.externalVideo) {
      return '';
    }
    if (this.isSupportedVideoType()) {
      if (duration < 5000) {
        return '';
      }
      return `[${media.file.name}] Video duration must not exceed 5 minutes.`;
    }
    if (media && media.file && media.file.size < 1000000000) {
      return '';
    }
    return `[${media.file.name}] Video file size must not exceed 1GB `;
  }

  changeTime = () => {
    this.element.currentTime = this.element.duration / 2;
    this.setState({
      duration: this.element.currentTime,
    });
  };

  myid = () => `albumMedia_${this.props.media.id}`;

  isSupportedVideoType = () => {
    const { media } = this.props;
    return (
      (!media.isAPhoto) && (media.file) &&
      (['video/webm', 'video/mp4', 'video/ogg'].indexOf(media.file.type) >= 0)
    );
  };

  isUnsupportedVideoType = () => {
    const { media } = this.props;
    return (
      (!media.isAPhoto) && (media.file) &&
      (['video/webm', 'video/mp4', 'video/ogg'].indexOf(media.file.type) < 0)
    );
  }

  renderSelectedTags = () => {
    const { selectedTags } = this.props;
    if (selectedTags.length) {
      return (
        <div className="UxCommon_TagButtons_Container">
          {selectedTags
            .map((selectedTag, index) => (
              <div className="UxCommon_TagButton_Container" key={index}>
                <label htmlFor="button" className="UxCommon_TagButton_Label">
                  {selectedTag.label}
                </label>
              </div>))}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      media,
      getRrfForm,
      getRrfFormModel,
    } = this.props;
    const rrfForm = getRrfForm(media.id);
    const formModel = getRrfFormModel(media.id);
    const isNewProps = {};
    if (rrfForm.isNew === undefined) {
      isNewProps.value = true;
    } else {
      isNewProps.value = rrfForm.isNew.value;
    }
    return (
      <div
        className={[
          'Photos_AlbumDialog_PhotoVideoCard',
          this.isUnsupportedVideoType() ? 'unsupported-video-type' : '',
        ].join(' ')}
      >
        <div className="Photos_AlbumDialog_PhotosVideoCard_ImgWrapper">
          <PhotosCircleButton
            className="Photos_AlbumDialog_PhotosVideoCard_DeleteBtn"
            iconName="trash"
            onClick={this.onDeleteClick}
          />
          {this.getImage(media)}
        </div>
        <div className="Photos_AlbumDialog_PhotoVideoCard_Desc">
          <TextAreaControl
            label="Description"
            placeholder="Add a description"
            model={`${formModel}.caption`}
            rrfField={rrfForm.caption || defaultRrfField}
            defaultValue={media.caption}
          />
          <Control
            type="hidden"
            model={`${formModel}.isNew`}
            defaultValue
            {...isNewProps}
          />
        </div>
        <div className="Photos_AlbumDialog_PhotoVideoCard_Tags">
          {this.renderSelectedTags()}
        </div>
      </div>
    );
  }
}

export default StagedPhotoCard;
