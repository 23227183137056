import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetFavoriteAwards';
import { getCurrentUuid } from '../Utils';
import { getFavoriteAwards } from '../../../store/actions/ui/uxProfile/awards';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return {
    uuid,
    loading: !!state.ui.uxProfile.awards.loadingFavoritesMsg,
    loadingMsg: state.ui.uxProfile.awards.loadingFavoritesMsg,
    needsUpdate: state.ui.uxProfile.awards.favoritesNeedUpdate,
  };
};

const mapDispatchToProps = dispatch => ({
  getFavoriteAwards: (uuid) => {
    dispatch(getFavoriteAwards(uuid));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
