import { combineReducers } from 'redux';
import playerProfile from './playerProfile';
import sidebar from './sidebar';
import photosVideos from './photosVideos';
import banners from './banners';
import awards from './awards';
import externalProfile from './externalProfile';

const reducer = combineReducers({
  playerProfile,
  sidebar,
  photosVideos,
  banners,
  awards,
  externalProfile
});

export default reducer;
