import StripeCheckoutComponent from '../../StripeCheckout/StripeCheckout.component';
import { logoutWithRedirect } from '../../../../store/actions/data/cognito';
import AthletePhysiquePage from '../../AthletInviteOnboarding/components/AthletePhysiquePage.component';
import AthleteAcademicPage from '../../AthletInviteOnboarding/components/AthleteAcademicOnboarding.component';
import InvitedSignUp from '../../../../containers/Welcome/InvitedSignUp';
import MergeProfiles from '../../ValidateInvite/MergingModals/MergeProfiles.component';
import React from 'react';
import useInviteSelector from './useInviteSelector';
import InvitationInvalidComponent from '../components/InvitationInvalid.component';
import { useDispatch } from 'react-redux';
import {
  mergeAnotherAccount,
  mergeSignIn,
  Routes,
} from '../../../../store/actions/ui/routes';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { MergeTypes } from '../../MergeSignIn/MergeSignIn';
import {
  ATHLETE_ACADEMIC_PAGE,
  ATHLETE_COMPLETE_ONBOARDING,
  ATHLETE_PHYSIQUE_PAGE,
} from '../constants/Invite.constants';
import LoadingIndicator from '../../../../modules/CoachWorld/common/LoadingIndicator';
import {
  playerProfileGet,
  profileShowCreateNewAccount,
} from '../../../../store/actions/ui/uxProfile';

const withRenderComponent = ({ match }) => {
  const {
    shouldCheckInviteValid,
    isInvitationValid,
    profile,
    loggedIn,
    isInviteUserLoggedIn,
    authenticatedProfile,
    loadingDuplicateProfiles,
    duplicateProfiles,
    showCreateNewAccount,
    mergeProfile,
  } = useInviteSelector();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    uuid,
    teamName,
    token,
    coachName,
    sportId,
    userTeamId,
    schoolId,
  } = useParams();

  const onNext = (value) => {
    switch (value) {
      case '1':
        return history.push(
          mergeSignIn(
            MergeTypes.mergeBoth,
            match.params.uuid,
            match.params.token,
            match.params.teamName,
            match.params.coachName,
            match.params.sportId,
            match.params.userTeamId,
            match.params.schoolId,
          ),
        );
      case '2':
        return history.push(
          mergeAnotherAccount(
            MergeTypes.signIn,
            match.params.uuid,
            match.params.token,
            match.params.teamName,
            match.params.coachName,
            match.params.sportId,
            match.params.userTeamId,
            match.params.schoolId,
          ),
        );

      case '3':
        dispatch(profileShowCreateNewAccount(true));
        return false;
      default:
        return false;
    }
  };

  if (shouldCheckInviteValid) {
    return (
      <LoadingIndicator
        loading={true}
        loadingMsg={'Please wait...'}
        wrapperStyle={{ top: 120 }}
      />
    );
  }

  const getRedirectUrl = () => {
    if (schoolId) {
      return `/${Routes.welcome}/${Routes.pma}/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
    }
    return `/${Routes.welcome}/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}`;
  };

  const renderRedirect = () => {
    if (!isInvitationValid.isValid) {
      return renderInvitationInvalid();
    }

    return (
      <Redirect
        to={mergeSignIn(
          MergeTypes.mergeBoth,
          match.params.uuid,
          match.params.token,
          match.params.teamName,
          match.params.coachName,
          match.params.sportId,
          match.params.userTeamId,
        )}
      />
    );
  };

  const isInvitationInvalid = () => {
    return (
      !shouldCheckInviteValid &&
      (!isInvitationValid || isInvitationValid.isValid === false)
    );
  };

  const isValidToken = () => {
    return isInvitationValid && isInvitationValid.isValid;
  };

  const renderInvitationInvalid = () => {
    return <InvitationInvalidComponent isInvitationValid={isInvitationValid} profile={profile} />;
  };

  if (shouldCheckInviteValid) {
    return false;
  }

  if (isInvitationInvalid()) {
    return renderInvitationInvalid();
  }

  if (isInvitationValid === null || !profile) {
    return true;
  }
  if (profile.isPaidProfile && !profile.isSubscribed && isValidToken()) {
    return <StripeCheckoutComponent profile={profile} match={match} />;
  }

  if (
    isInvitationValid &&
    isInvitationValid.isValid &&
    loggedIn &&
    !isInviteUserLoggedIn
  ) {
    dispatch(logoutWithRedirect(getRedirectUrl()));
  } else if (loggedIn && authenticatedProfile && !profile.step) {
    return renderRedirect();
  }

  if (isInvitationInvalid()) {
    return renderInvitationInvalid();
  }

  if (!profile || loadingDuplicateProfiles) {
    return false;
  }
  if (duplicateProfiles.length === 0 || showCreateNewAccount) {
    if (profile.step === ATHLETE_PHYSIQUE_PAGE) {
      return <AthletePhysiquePage />;
    }
    if (profile.step === ATHLETE_ACADEMIC_PAGE) {
      return <AthleteAcademicPage />;
    }

    if (profile.step === ATHLETE_COMPLETE_ONBOARDING) {
      if (isInviteUserLoggedIn) {
        dispatch(playerProfileGet(profile.uuid));
        return (
          <Redirect
            to={`/${Routes.profile}/${profile.uuid}/${Routes.player}`}
          />
        );
      } else {
        return <Redirect to={`/${Routes.welcome}/${Routes.login}`} />;
      }
    }

    return <InvitedSignUp />;
  }
  return (
    <MergeProfiles
      match={match}
      profile={profile}
      duplicateProfile={mergeProfile ? mergeProfile : duplicateProfiles[0]}
      onNext={onNext}
    />
  );
};

export default withRenderComponent;
