import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import OldPhoto from './OldPhoto/OldPhoto';

class OldProfilePhotos extends PureComponent {
  static propTypes = {
    profilePhotos: PropTypes.array.isRequired,
    onPhotoClicked: PropTypes.func.isRequired,
  }

  componentDidMount() {}

  render() {
    const { profilePhotos, onPhotoClicked } = this.props;
    if (!profilePhotos.length) {
      return (
        <div className="UPPDialog_OldProfilePhotos">
          <div className="UPPDialog_OldProfilePhotos_NoPhotosMsg">
            {"You don't have any profile photos uploaded yet"}
          </div>
        </div>
      );
    }
    return (
      <div className="UPPDialog_OldProfilePhotos">
        {
          profilePhotos.map((photo, index) => (
            (!!photo.resizedPhotos) && (!!photo.resizedPhotos.medium) &&
              <OldPhoto key={index} srcUrl={photo.resizedPhotos.medium} onClick={onPhotoClicked} />
          ))
        }
      </div>
    );
  }
}

export default OldProfilePhotos;
