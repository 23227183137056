import { connect } from 'react-redux';
import Component from './CombineAwards.component';
import { selectLoadingMsg, selectCoachSchoolIds, getComparedAwards } from '../../+store';
import { getFilters } from '../../+store/coachWorld/coachWorld.utils';
import { getCombine, getSchoolCombines, createCombineAward, getCombinesAwards, updateCombineAward, getCombinePreCombineTestRes, updateCombine, showSaveCombineAwardsDialog, hideSaveCombineAwardsDialog, hideGeneratedAwardDialog } from '../../+store/combine';
import { combinePreCombineUpdateId, checkIfUpdateNeededInFastMode } from '../../+store/combine/combine.utils';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const { combineId, schoolId } = ownProps.match.params;
  return {
    loading: !!loadingMsg,
    loadingMsg,
    loadingAwardButton: !!state.modules.coachWorld.combine.loadingMsgAwardButton,
    selectedFilters: getFilters(state, ownProps),
    schoolTeams: state.modules.coachWorld.roster.schoolTeams,
    schoolIds: selectCoachSchoolIds(state),
    schoolCombines: state.modules.coachWorld.combine.schoolCombines,
    athleteCombineResults: state.modules.coachWorld.combine.athleteCombineResults[combineId],
    combineAwards: state.modules.coachWorld.combine.combineAwards,
    savedCombineAwards: state.modules.coachWorld.combine.savedCombineAwards,
    comparedAwards: getComparedAwards(state, ownProps),
    isSaveCombineAwardsDialogVisible:
      state.modules.coachWorld.combine.isSaveCombineAwardsDialogVisible,
    isAwardGeneratedDialogVisible: state.modules.coachWorld.combine.isAwardGeneratedDialogVisible,
    shouldUpdate: checkIfUpdateNeededInFastMode(
      state.modules.coachWorld.combine.shouldUpdate,
      combinePreCombineUpdateId(ownProps.combine.id),
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  getCombine: (combineId) => {
    dispatch(getCombine(combineId));
  },
  getCombineAndPrevCombineTest: (combineId) => {
    dispatch(getCombinePreCombineTestRes(combineId));
  },
  getSchoolCombines: (schoolIds) => {
    dispatch(getSchoolCombines(schoolIds));
  },
  createAward: (combineId, data) => {
    dispatch(createCombineAward(data, combineId));
  },
  denyAward: (data, athleteId, awardId) => {
    dispatch(updateCombineAward(data, athleteId, awardId));
  },
  getCombineAwards: (combineIds) => {
    dispatch(getCombinesAwards(combineIds));
  },
  updateCombine: (data, combine) => dispatch(updateCombine(data, combine)),
  hideCombineAwardDialog: () => dispatch(hideSaveCombineAwardsDialog()),
  showCombineAwardDialog: () => dispatch(showSaveCombineAwardsDialog()),
  hideGeneratedAwardDialog: () => dispatch(hideGeneratedAwardDialog()),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
