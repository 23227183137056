import { connect } from 'react-redux';
import Component from '../../components/UxEditProfile/UxEditProfile';
import { getCurrentUuid, getSelectedSport, getAuthenticatedUserProfile } from '../UxCommon/Utils';
import { routeChangedAlert } from '../../store/actions/ui/routes';

const mapStateToProps = (state, ownProps) => ({
  authenticating: state.ui.app.mobile.authenticating,
  currentRoute: ownProps.currentRoute || state.ui.app.routes.currentRoute,
  uuid: state.data.cognito.uuid,
  currentUuid: getCurrentUuid(state, ownProps.match),
  selectedUserSport: getSelectedSport(state, ownProps.match),
  profile: getAuthenticatedUserProfile(state),
  canEdit: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
    || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
  isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    dummy: () => {
      dispatch({});
    },
    updateRoute: (path) => {
      dispatch(routeChangedAlert(path));
    },
    dispatch,
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
