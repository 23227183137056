import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import { Link, Redirect } from "react-router-dom";
import { mergeAuthForgotPassword, mergeForgotPassword, Routes } from "../../../store/actions/ui/routes";

import TextControlGroup from "../../UxCommon/Form/TextControlGroup/TextControlGroup";
import { parserLower } from "../../../utils/parsers";
import { getPositions } from "../InvitedSignUp/InvitedSignUp";


export const MergeTypes = {
  "mergeBoth": 1,
  "signIn": 2
};

class MergeSignInAuth extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    authenticatedProfile: PropTypes.object,
    rrfForm: PropTypes.object,
    updatePosition: PropTypes.func,
    getProfile: PropTypes.func,
    authUuid: PropTypes.string,
    mergeProfile: PropTypes.any,
    message: PropTypes.string,
  };

  componentDidMount() {
    const { authUuid, authenticatedProfile, getProfile } = this.props;
    if( !authenticatedProfile ) {
      getProfile(authUuid);
    }
    this.shouldInit();
  }

  componentDidUpdate() {
  }

  shouldInit = () => {
    const { needsInit, formInit, authenticatedProfile, mergeProfile } = this.props;
    if (needsInit) {
      formInit(mergeProfile ? mergeProfile : authenticatedProfile);
    }
  };

  submit = () => {
    const { submit, match, authenticatedProfile } = this.props;
    submit(authenticatedProfile.uuid);
  };

  render = () => {
    const {
      match,
      formGeneralError,
      message,
      authenticatedProfile,
      authUuid
    } = this.props;

    const { mergeType , newEmail} = match.params;

    if(!authenticatedProfile){
      return null;
    }


    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">{message}</div>
            <div className="welcome-msg welcome-text">Please login below</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model={"forms.mergeLogin"}
                  onSubmit={this.submit}>
                <TextControlGroup
                  label="Email"
                  model="forms.mergeLogin.username"
                  parser={parserLower}
                  disabled={mergeType === MergeTypes.mergeBoth.toString()}
                />
                <TextControlGroup label="Password" type="password" model="forms.mergeLogin.password" />
              {
                this.props.formGeneralError &&
                <div className="form-group">
                  <div className="form-general-error">{formGeneralError}</div>
                </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Submit</button>
              </div>
              {

                  <Link className="welcome-link"
                        to={mergeAuthForgotPassword(
                          MergeTypes.mergeBoth,
                          authUuid,
                          newEmail
                        )}>
                    Forgot Password?
                  </Link>

              }
            </Form>
          </div>
        </div>
      </div>
    );
  };
}

export default MergeSignInAuth;
