import { School, SchoolTeamRef } from '../../../CoachWorld/+store/roster/roster.models';

export const mapExistingTeamToApiUserTeams = (teamId, position, jerseyNumber) => ({
  school_team_id: teamId,
  position,
  jersey_number: jerseyNumber,
});

export const mapNewTeamToApiUserTeams = (newTeam, position, jerseyNumber, schoolId) => ({
  sport_id: newTeam.sportId,
  team_reference_id: newTeam.level,
  position,
  jersey_number: jerseyNumber,
  school_id: schoolId,
  name: newTeam.name,
});

export const mapSchoolSportTeamToApiSchoolTeam = (teamSport, schoolTeamId) => ({
  school_team_id: schoolTeamId,
  sport_id: teamSport.sportId,
  team_reference_id: teamSport.levelId,
  name: teamSport.teamName,
});

export const mapUserBaseballSoftballToApiProfileSports = (profile, teamSport) => ({
  profile_id: profile.id,
  aws_uuid: profile.uuid,
  sport_id: teamSport.sportId,
  position: teamSport.positions ? teamSport.positions.join(',') : '',
  jersey_numbers: teamSport.jerseyNumbers ? teamSport.jerseyNumbers.join(', ') : '',
  throw: teamSport.throws ? teamSport.throws.join(', ') : '',
  bat: teamSport.bats ? teamSport.bats.join(', ') : '',
});

export const mapUserSportToApiProfileSports = (profile, teamSport) => ({
  profile_id: profile.id,
  aws_uuid: profile.uuid,
  sport_id: teamSport.sportId,
  position: teamSport.positions ? teamSport.positions.join(',') : '',
  jersey_numbers: teamSport.jerseyNumbers ? teamSport.jerseyNumbers.join(', ') : '',
});

export class SportsPageSchoolTeam {
  static fromApi(response) {
    const team = new SportsPageSchoolTeam();
    team.id = response.id;
    team.isPrivate = response.is_private;
    team.active = response.active;
    team.name = response.name || response.team_reference.team;
    team.schoolId = response.school_id;
    team.sportId = response.sport_id;
    team.school = School.fromApi(response.schools);
    team.teamRef = SchoolTeamRef.fromApi(response.team_reference);
    team.teamRefId = response.team_reference_id;
    return team;
  }
}

export default 'sportsPage.models.js';
