import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, className, style }) => (
  <div className={`row ${className || ''}`} style={style || {}}>
    {children}
  </div>
);
Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Row;
