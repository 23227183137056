import React, { PureComponent } from 'react';
import { Route, Switch, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import Banner from '../common/Banner';
import SearchBarNav from './SearchBarNav';
import FilterSidebar from '../common/FiltersSidebar';
import TeamBox from './TeamBox';
import GridView from './GridView';
import LoadingIndicator from '../common/LoadingIndicator';
import {
  expandedEditPlayerPath,
  Routes,
  SportIds,
  Sports,
  expandedRosterGridPath, expandedRosterReconfigureGridPath,
} from '../../../store/actions/ui/routes';
import EditPlayerModal from '../common/EditPlayerModal/EditPlayerModal.container';
import TwoToneButton from '../common/TwoToneButton';
import SidebarLinkFilter from '../common/SidebarLinkFilter';
import SidebarMultiSelectCallbackFilter from '../common/SidebarMultiSelectCallbackFilter';
import {
  positions,
  gender,
  roles,
  batThrow,
} from '../../../components/UxCommon/Form/utils';
import { AthleteGrades } from '../+store';
import SchoolLogo from '../common/SchoolLogo';
import cssVar from '../../../components/App/App.utils';
import PrintExperiment from './PrintExperiment';
import { ShareModal } from '../../../components/UxCommon';
import { Roles } from '../../../store/actions/data/user/profile/models';
import { getCoachAllSportsData } from '../../../store/actions/ui/formUtils/validators';
import {getFilters, setFilter} from "../+store/coachWorld";
import queryString from "query-string";

const getSidebarFilters = ({ teams, match, sports, gradYears, profile, isGridView, selectedTeamId, isReconfigureEnabled }) => {
  let coachAllSportsIds = [];
  const currentSchoolId = match ? match.params.schoolId : '';
  profile && profile.roleId === 4 && profile.enrolledSchool
    ? profile.enrolledSchool.map((ele) => {
        if (ele.user_school_team.school_id === currentSchoolId) {
          coachAllSportsIds.push(ele.user_school_team.sport_id);
        }
      })
    : null;
  let coachAllSports = getCoachAllSportsData(sports, _.uniq(coachAllSportsIds));
  const linkFilters = [
    {
      name: 'sports',
      label: 'Sports',
      items: coachAllSports.map((coachAllSports) => ({
        name: coachAllSports.name,
        value: coachAllSports.value,
        option: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${
          Sports[coachAllSports.value]
        }`,
        to: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${
          Sports[coachAllSports.value]
        }`,
      })),
    },
  ];

  const queryStringFilters = [
    {
      name: 'position',
      label: 'Positions',
      order:2,
      items: positions(SportIds[match.params.sport]).map(({ value, name }) => ({
        name,
        value,
      })),
    },
    {
      name: 'gender',
      label: 'Genders',
      order:6,
      items: gender().map(({ text, value }) => ({ name: text, value })),
    },
    {
      name: 'userType',
      label: 'Role',
      order:7,
      items: roles().map(({ text, value }) => ({ name: text, value })),
    },
  ];

  let teamFilter;
  if(isGridView && !isReconfigureEnabled){
    teamFilter = {
      name: 'teams',
      label: 'Teams',
      items: teams.map(team => ({
        name: team.name,
        value: team.id,
        option: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}/grid/team/${team.id}`,
        to: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}/grid/team/${team.id}`,
        selected : selectedTeamId === team.id ? true : undefined
      })),
    };
    linkFilters.push(teamFilter);
  }else{
    teamFilter =     {
      name: 'schoolTeamId',
      label: 'Teams',
      order:1,
      items: teams.map(st => {
        if(isReconfigureEnabled && st.isPrivate) return;
        return {name: st.name, value: st.id, selected: selectedTeamId === st.id ? true : isReconfigureEnabled || undefined}
      }),
    };
    teamFilter.items = teamFilter.items.filter(e=>e);
    queryStringFilters.unshift(teamFilter);
  }

  const { sport } = match.params;
  if (sport === 'baseball' || sport === 'softball') {
    const bats = {
      name: 'bats',
      label: 'BATS',
      order:3,
      items: batThrow.map(({ name, value }) => ({ name, value })),
    };
    const throws = {
      name: 'throws',
      label: 'Throws',
      order:4,
      items: batThrow.map(({ name, value }) => ({ name, value })),
    };

    queryStringFilters.splice(2, 0, bats, throws);
  }

  if (gradYears.length) {
    const gradYearFilter = {
      name: 'gradYear',
      label: 'Grad Year',
      order:5,
      items: gradYears.map((value) => ({
        name: value,
        value,
      })),
    };
    sport === 'baseball' || sport === 'softball'
      ? queryStringFilters.splice(4, 0, gradYearFilter)
      : queryStringFilters.splice(2, 0, gradYearFilter);
  }

  return {
    linkFilters,
    queryStringFilters:queryStringFilters.sort((a, b) => a.order - b.order),
  };
};

class Roster extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    getRoster: PropTypes.func.isRequired,
    sportId: PropTypes.number.isRequired,
    schoolId: PropTypes.string.isRequired,
    schoolTeams: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    teamLogo: PropTypes.string,
    teamColor1: PropTypes.string,
    teamColor2: PropTypes.string,
    selectedFilters: PropTypes.array,
    schoolStyle: PropTypes.array.isRequired,
    changeViewSelection: PropTypes.func,
    viewSelection: PropTypes.string,
    allCoaches: PropTypes.object,
    allAthletes: PropTypes.object,
    getSchoolTeamUsers: PropTypes.func,
    getSchedule: PropTypes.func,
    myTeams: PropTypes.array,
    profile: PropTypes.object,
    gradYears: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  state = {
    shareUri: '',
    isGridView: false,
    showMobileFilters: false,
    teamEmptyChecklist: {},
    showMyTeam: false,
    updatedFormValue: {},
    selectedAthlete: {},
    selectCount: 0,
    selectedTeamId: null,
  };

  isRosterViewLoaded = false;
  isScheduleLoaded = false;
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.shouldUpdate();
  }

  componentDidUpdate() {
    const {checkIfCardView, isLoggedIn, getSchedule, schoolTeams} = this.props;
    this.shouldUpdate();
    if (!this.isRosterViewLoaded && isLoggedIn) {
      checkIfCardView();
      this.isRosterViewLoaded = true;
    }
    const coachTeams = this.getCoachTeams().filter(team => !team.isPrivate);
    if(schoolTeams.length && !this.isScheduleLoaded){
        const teamIds = schoolTeams.map(team => team.id);
        getSchedule(teamIds);
        this.isScheduleLoaded = true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setTeamEmptyChecklist = (teamId, val) => {
    this.setState((prevState) => ({
      teamEmptyChecklist: {
        ...prevState.teamEmptyChecklist,
        [teamId]: val,
      },
    }));
  };
  onPrintClick = () => {
    const pri = document.getElementById('printable').contentWindow;
    pri.focus();
    pri.print();
  };

  onShareClick = () => {
    const { match, location } = this.props;
    this.setState({
      shareUri: `${match.url}${location.search}`,
    });
  };

  onCloseShare = () => {
    this.setState({ shareUri: '' });
  };

  updateWindowDimensions() {
    const {viewSelection, dynamicRosterView, changeViewSelection} = this.props;
    if (window.innerWidth < 768 && viewSelection !== 'list') {
      changeViewSelection('list');
    } else {
      if (dynamicRosterView === 'card') {
        changeViewSelection('card');
      }
    }
  }

  didSetCoachFlag = false;
  rosterFetched = false;
  styleUpdated = false;
  sportId = 0;
  schoolStyleChosen = false;

  choseCurrentSchoolStyle = () => {
    const { schoolStyle, schoolId } = this.props;
    let obj;
    if (schoolStyle.length > 0 && schoolId) {
      obj = schoolStyle.find((o) => o.id === schoolId);
    }
    this.schoolStyleChosen = true;
    return obj;
  };

  shouldUpdate = () => {
    const {
      getRoster,
      schoolId,
      sportId,
      schoolStyle,
      loading,
      profile,
      myTeams,
      parsedFilters,
    } = this.props;

    const isCoach = profile && profile.roleId === Roles.coach;
    if (!this.rosterFetched || sportId !== this.sportId) {
      this.rosterFetched = true;
      this.sportId = sportId;
      getRoster(schoolId, sportId, parsedFilters);
      this.setState({ teamEmptyChecklist: {} });
    } else if (
      !this.didSetCoachFlag &&
      isCoach &&
      !loading &&
      myTeams &&
      myTeams.length
    ) {
      this.didSetCoachFlag = true;
      this.setState({
        showMyTeam: myTeams.some((t) => t.schoolId === schoolId),
      });
    }
    if (!this.styleUpdated && schoolStyle) {
      const primary = schoolStyle.color1;
      const secondary = schoolStyle.color2;
      cssVar('primary', primary);
      cssVar('secondary', secondary);
    }
  };

  handleCloseEditPlayerDialog = () => {};

  renderExpandedEditPlayerDialog = (props) => (
    <EditPlayerModal {...props} onClose={this.handleCloseEditPlayerDialog} />
  );

  changeToReconfigureTeam = (teamId) => {
    const {match, history} = this.props;
    const coachTeams = this.getCoachTeams().filter(team => !team.isPrivate);
    const teamIds = coachTeams.map(({id}) => ([id][0]));
    let parsedQuery = {};
    parsedQuery.filters = JSON.stringify([{
      name: 'schoolTeamId',
      selected: teamIds,
    }]);
    history.push({
      pathname: `/${Routes.school}/${match.params.schoolId}/${Routes.roster}/${match.params.sport}/${Routes.reconfigureGrid}/${Routes.team}/${teamId}`,
      search: queryString.stringify(parsedQuery, {skipNull: true}),
    });
  }

  renderGridView = (props) => {
    const {schoolTeams, profilesLoading, loadingMsg, location} = this.props;
    const {teamId} = props.match.params;
    const path = location.pathname.split('/').filter(e => e);
    const isReconfigureEnabled = path.includes('reconfigure-grid');
    const team = schoolTeams.find((t) => t.id === teamId);
    this.setState({isGridView: true, selectedTeamId: teamId});
    if (team) {
      return (
        <div className="CoachRoster_GridWrapper vc-manage-roster">
          {!isReconfigureEnabled && !profilesLoading && !loadingMsg && !team.isPrivate &&
            <div className="d-flex py-3">
              <button className="button reconfig-btn ml-auto"
                      onClick={() => this.changeToReconfigureTeam(teamId)}>Reconfigure Teams
              </button>
            </div>}
          <GridView reconfigureView={isReconfigureEnabled} team={team} {...props} />
        </div>
      );
    }
    return null;
  };

  renderTeams = (teams) => () => {
    const { schoolTeams, loading, location, history, match } = this.props;
    const { teamEmptyChecklist } = this.state;
    this.setState({ isGridView: false });
    const teamEmptyChecklistVal = Object.values(teamEmptyChecklist);
    const noMatchFilter =
      teamEmptyChecklistVal.length && teamEmptyChecklistVal.every((x) => x);
    const isEmpty = !loading && (!teams || teams.length === 0);
    let errorMessage = null;
    if (isEmpty) {
      errorMessage = 'No such team found for this sport on this school.';
    } else if (noMatchFilter) {
      errorMessage =
        'No athlete in any team matching your filter criteria was found. Please try different filter options.';
    }

    return (
      <div className="CoachRoster_TeamsWrapper">
        {!loading && errorMessage && (
          <div className="CoachTeamBox_Header">{errorMessage}</div>
        )}
        {!loading &&
          !isEmpty &&
          teams.map((team) => (
            <TeamBox
              match={match}
              key={team.id}
              team={team}
              location={location}
              history={history}
              setEmptyFlag={this.setTeamEmptyChecklist}
            />
          ))}
      </div>
    );
  };

  getCoachTeams = () => {
    const { schoolTeams, myTeams } = this.props;
    if (schoolTeams && schoolTeams.length && myTeams && myTeams.length) {
      return schoolTeams.filter(
        (team) =>
          !!myTeams.find(
            (t) => t.schoolTeamId === team.id && t.userType === 'coach',
          ),
      );
    }
    return [];
  };

  getTeamsList = (coachTeams) => {
    const { schoolTeams, selectedFilters } = this.props;
    const { showMyTeam } = this.state;
    let schoolTeamIdFilter = null;
    if (selectedFilters) {
      schoolTeamIdFilter = selectedFilters.find(
        (f) => f.name === 'schoolTeamId',
      );
    }
    if (
      schoolTeams &&
      schoolTeams.length &&
      schoolTeamIdFilter &&
      schoolTeamIdFilter.selected &&
      schoolTeamIdFilter.selected.length
    ) {
      return schoolTeams.filter((team) =>
        schoolTeamIdFilter.selected.includes(team.id),
      );
    }
    return showMyTeam ? coachTeams : schoolTeams;
  };

  renderViewToggle = (isMobile) => {
    const { showMyTeam } = this.state;
    return (
      <div className={`ToggleCoachView ${isMobile && 'mobile'}`}>
        <button
          className={`left ${showMyTeam && 'active'}`}
          onClick={() => this.setState({ showMyTeam: true })}>
          Show My Teams
        </button>
        <button
          className={`right  ${!showMyTeam && 'active'}`}
          onClick={() => this.setState({ showMyTeam: false })}>
          Show All Teams
        </button>
      </div>
    );
  };

  render() {
    const {
      schoolTeams,
      loading,
      loadingMsg,
      location,
      history,
      match,
      sports,
      schoolStyle,
      allCoaches,
      allAthletes,
      profile,
      gradYears,
    } = this.props;
    const findTeamKeyword = location.pathname.indexOf('team');
    const path = location.pathname.split('/').filter(e => e);
    const isReconfigureEnabled = path.includes('reconfigure-grid');
    const { shareUri, showMyTeam, showMobileFilters, isGridView, selectedTeamId } = this.state;
    const isCoach = profile && profile.roleId === Roles.coach;
    const coachTeams = this.getCoachTeams();
    const teams = this.getTeamsList(coachTeams);
    const { linkFilters, queryStringFilters } = getSidebarFilters({
      teams: showMyTeam ? coachTeams : schoolTeams,
      match,
      sports,
      gradYears,
      profile,
      isGridView,
      selectedTeamId,
      isReconfigureEnabled,
    });
    let currentSchoolStyle;
    if (schoolStyle.length > 0) {
      currentSchoolStyle = this.choseCurrentSchoolStyle();
    }
    const isExpandedRosterGrid = !!matchPath(location.pathname, {
      path: expandedRosterGridPath,
      exact: true,
    });
    const isExpandedReconfigureRosterGrid = !!matchPath(location.pathname, {
      path: expandedRosterReconfigureGridPath,
      exact: true,
    });
    return (
      <div className="CoachRoster">
        <ShareModal uri={shareUri} onClose={this.onCloseShare} />
        <Banner match={match}>
          <div className="CoachRoster_BannerChildren">
            <div className="CoachRoster_BannerLeft">
              <SchoolLogo
                className="CoachRoster_BannerTeamLogo"
                schoolStyle={currentSchoolStyle || null}
              />
              <div className="CoachRoster_BannerFullTitle roster">
                {currentSchoolStyle ? currentSchoolStyle.name : ''}
              </div>
            </div>
            {!isExpandedRosterGrid && !isExpandedReconfigureRosterGrid && (
              <div className="CoachRoster_BannerPrintButton">
                {!loading && (
                  <div style={{ display: 'flex' }}>
                    {/* <TwoToneButton className="print" onClick={this.onPrintClick} text="Print" iconName="print"/> */}
                    <TwoToneButton
                      className="share"
                      onClick={this.onShareClick}
                      text="Share"
                      iconName="share"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Banner>
        <Route
          path={expandedEditPlayerPath}
          render={this.renderExpandedEditPlayerDialog}
        />
        <div className="CoachRoster_BodyWrapper">
          <SearchBarNav
            location={location}
            history={history}
            showFilters={() => this.setState({ showMobileFilters: true })}>
            {findTeamKeyword < 0 && isCoach && this.renderViewToggle()}
          </SearchBarNav>
          <div className="CoachRoster_Body">
            <LoadingIndicator
              loading={loading}
              loadingMsg={loadingMsg}
              wrapperStyle={{ top: 120 }}
            />
            <FilterSidebar
              displayNone={!!loading}
              className="Roster"
              showMobileFilters={(v) => this.setState({ showMobileFilters: v })}
              defaultIsHidden={!showMobileFilters}>
              {showMobileFilters && isCoach && this.renderViewToggle(true)}
              {linkFilters.map(({ name, label, items }) => (
                <SidebarLinkFilter
                  key={name}
                  filterName={name}
                  label={label}
                  filters={items}
                  match={match}
                  shouldSort = { name === 'teams' ? false: true }
                />
              ))}
              {queryStringFilters.map(({ name, label, items }) => (
                <SidebarMultiSelectCallbackFilter
                  key={name}
                  filterName={name}
                  label={label}
                  filters={items}
                  history={history}
                  location={location}
                  isReconfigureEnabled={isReconfigureEnabled}
                />
              ))}
            </FilterSidebar>
            <Switch>
              <Route
                exact
                path={expandedRosterGridPath}
                render={this.renderGridView}
              />
              <Route
                exact
                path={expandedRosterReconfigureGridPath}
                render={this.renderGridView}
              />
              <Route
                path={`/${Routes.school}/:schoolId/${Routes.roster}/:sport`}
                render={this.renderTeams(teams)}
              />
            </Switch>
          </div>
        </div>
        <PrintExperiment
          match={match}
          currentSchoolStyle={currentSchoolStyle}
          teams={schoolTeams}
          allCoaches={allCoaches}
          allAthletes={allAthletes}
        />
      </div>
    );
  }
}

export default Roster;
