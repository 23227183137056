import {combineEpics, ofType} from 'redux-observable';
import {filter, first, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {forkJoin, of, timer} from 'rxjs';
import moment from 'moment';
import {ASYNC_FINISH, asyncFinishAction} from '../../../../store/actions/ui/async';
import {
  checkForkErrors,
  getTokenFragment,
  getTokenFragmentMergeMap,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import {
  CREATE_COMBINE,
  CREATE_COMBINE_AWARD,
  CREATE_UPDATE_BULK_TEST_COMBINE,
  DELETE_BULK_TEST_COMBINE,
  DELETE_COMBINE,
  DELETE_LAST_PASTE_DATA_COMBINE,
  GET_ATHLETE_COUNT,
  GET_COMBINE,
  GET_COMBINE_ATHLETE_RESULTS,
  GET_COMBINE_AWARDS,
  GET_COMBINE_PRE_COMBINE_TEST_RES,
  GET_COMBINE_RESULT_ALL_ATHLETES,
  GET_COMBINE_TEMPLATES,
  GET_COMBINE_TEMPLATES_BY_SCHOOL,
  GET_COMBINES_AWARDS,
  GET_PURE_COMBINE,
  GET_SCHOOL_COMBINES,
  getAthleteCount,
  getCombineAthleteSuccess,
  getCombineResultAllAthletes,
  getCombineResultAllAthleteSuccess,
  SAVE_COMBINE_TEST_RESULT,
  SET_ALL_LASER,
  SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT,
  SET_EXPIRING_MSG,
  setExpiringCombineMsg,
  UPDATE_COMBINE,
  UPDATE_COMBINE_AWARD,
  UPDATE_CREATE_COMBINE_AND_AWARDS,
  GET_COMBINE_TEMPLATES_BY_COMBINE,
  GET_COMBINE_TEMPLATE_BY_COMBINE,
  GET_COMBINE_STANDARD_TEST_CATEGORIES
} from "./combine.actions";
import {
  apiCreateTest,
  apiCreateTestResult,
  apiDeleteTestResult,
  apiGetCombineResultsAllAthlete,
  apiGetCombineResultsPerAthlete, apiGetCombineTemplateByCombine,
  apiGetCombineTemplates,
  apiGetCombineTemplatesBySchool,
  apiUpdateTestResult, bulkTestCreateOrUpdate,
  bulkTestDelete,
  createCombine,
  createCombineAward,
  deleteCombine,
  getCombine,
  getCombineAwardsByCombineId,
  getCombineResults,
  getCombines, getCombineWithPrevCombine,
  updateCombine,
} from './combine.api';
import { AthleteCombineResults, BulkSuccessData, Combine, CombineTest } from "./combine.models";
import {CombineTemplate} from './combineTemplates.models';
import {SchoolTeamMember} from '../roster/roster.models';
import {mapAwardApiToUi} from '../../../../store/actions/data/user/awards/models';
import { toggleAllLaserApi, updateAward } from "../../../../store/actions/data/user/awards/apiCalls";
import {createCombineTestObject} from '../../../CodeSignUp/Common/utils/utils';
import {FORBIDDEN_ERROR} from '../../../../store/actions/ui/ajaxErrorHandlers';
import {getCombineCompareAwards, getAthleteCombineAwards} from '../coachWorld/coachWorld.selectors'

const getPureCombine = action$ => (
  action$.pipe(
    ofType(GET_PURE_COMBINE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getCombine(action.combineId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getPureCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getPureCombine', {
          combine: Combine.fromApi(result.response),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(
        getCombine(action.combineId, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombine',
          )),
        getCombineResults(action.combineId, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombineResults',
          )),
      )
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {

        const combine = Combine.fromApi(result.results[0].response);
        const athletes = [];
        const {schoolId} = combine;
        Object.entries(result.results[1].response.combine_results.roster)
          .reduce((prev, [userId, apiTeamUsers]) => {
            apiTeamUsers.forEach((apiTeamUser) => {
              prev.push(SchoolTeamMember.fromCombineApi(userId, schoolId, apiTeamUser));
            });
            return prev;
          }, athletes);
        combine.combineTemplate = CombineTemplate.fromApi(result
          .results[1].response.combine_results.combineTemplate);
        const returnActions = [
          asyncFinishAction(result.action.type, 'getCombine', {
            combine,
            athletes,
          }),
        ];
        if (!result.action.combineOnly) {
          // returnActions.push(getProfiles(athleteIds));
          returnActions.push(getCombineResultAllAthletes(
            result.action.combineId,
            result.action.type,
          ));
        }
        return of(...returnActions);
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getAthleteCountEpic = action$ => (
  action$.pipe(
    ofType(GET_ATHLETE_COUNT),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.combines.map(combine => forkJoin(
        getCombine(combine.id, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombine',
          )),
        getCombineResults(combine.id, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombineResults',
          )),
      )
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              const combineResults = Combine.fromApi(forkResults.results[0].response);
              const athleteIds = [];
              const athletes = [];
              const {schoolId} = combineResults;
              Object.entries(forkResults.results[1].response.combine_results.roster)
                .reduce((prev, [userId, apiTeamUsers]) => {
                  athleteIds.push(userId);
                  apiTeamUsers.forEach((apiTeamUser) => {
                    prev.push(SchoolTeamMember.fromCombineApi(userId, schoolId, apiTeamUser));
                  });
                  return prev;
                }, athletes);
              combineResults.combineTemplate = CombineTemplate.fromApi(forkResults
                .results[1].response.combine_results.combineTemplate);
              combineResults.athletes = athletes;
              combineResults.athleteCount = athletes.length;
              // combineResults.athleteCount = athletes.reduce((prev, curr) => {
              //   if (curr.testId) return prev + 1;
              //   return prev;
              // }, 0);
              action.dispatch(asyncFinishAction(action.type, 'getAthleteResults', {
                combineResults,
              }));
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombine', {}));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getSchoolCombinesEpic = action$ => (
  action$.pipe(
    ofType(GET_SCHOOL_COMBINES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.schoolIds.map(schoolId => (
        getCombines(schoolId, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getSchoolCombines',
          ))
      )))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        const combines = result.results.reduce((prev, curr) => {
          const cs = [...prev, ...curr.response._embedded.map(x => Combine.fromApi(x))];
          return cs;
        }, []);
        return of(
          asyncFinishAction(result.action.type, 'getSchoolCombines', {
            combines,
            schoolIds: result.action.schoolIds,
          }),
          getAthleteCount(combines),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const getCombineResultAllAthletesEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_RESULT_ALL_ATHLETES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetCombineResultsAllAthlete(action.combineId, token)
        .pipe(
          tap(response => action.dispatch(getCombineResultAllAthleteSuccess(
            action.combineId,
            response,
            action.parentModel,
            response.length,
          ))),
          manualCancelApiCallFragment(
            action$,
            action,
            'getCombineAthletes',
          ),
        )
        .pipe(map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombineAthletes', {}));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineResultsPerAthleteEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_ATHLETE_RESULTS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.athleteIds.map(athleteId => (
        apiGetCombineResultsPerAthlete(action.combineId, athleteId, token)
          .pipe(
            tap(response => action.dispatch(getCombineAthleteSuccess(
              action.combineId,
              athleteId,
              AthleteCombineResults.fromApi(response),
              action.parentModel,
              action.athleteIds.length,
            ))),
            manualCancelApiCallFragment(
              action$,
              action,
              'getCombineAthlete',
            ),
          )
      )))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombineAthlete', {}));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineTemplates = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_TEMPLATES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetCombineTemplates(token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getCombineTemplates',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombineTemplates', {
          templates: result.response._embedded.map(r => CombineTemplate.fromApi(r)),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineStandardTestCategoriesEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_STANDARD_TEST_CATEGORIES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetCombineTemplateByCombine(action.combineId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          GET_COMBINE_STANDARD_TEST_CATEGORIES,
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, GET_COMBINE_STANDARD_TEST_CATEGORIES, {
          template: {
            sportId: result.response.sportId,
            standardTestCategories: result.response.standardTestCategories,
            combineTemplateId: result.response.combineTemplateId,
          }
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineTemplatesBySchool = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_TEMPLATES_BY_SCHOOL),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.schoolIds.map(schoolId =>
        apiGetCombineTemplatesBySchool(token, schoolId)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombineTemplatesBySchool',
          ), map(
            result => {
              return {
                ...result,
                schoolId
              }
            }
          ))))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,

              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombineTemplatesBySchool', {
          templates: Object.assign({}, ...result.action.schoolIds.map(schoolId =>
            ({[schoolId]: [].concat.apply([], result.results.map(result => (result.schoolId === schoolId) ? result.response._embedded.map(r => CombineTemplate.fromApi(r)) : []))}))
          ),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombineAwardsEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_AWARDS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getCombineAwardsByCombineId(token, action.combineId)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getCombineAwards',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCombineAwards', {
          combineAwards: result.response,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getCombinesAwardsEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINES_AWARDS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.combineIds.map(combineId => (
        getCombineAwardsByCombineId(token, combineId)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombineAthlete',
          ))
      )))
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                ...forkResults,
                action,
              };
            }
            return forkResults;
          }),
        )
    )),
    switchMap((result) => {
      if (result.success) {
        const resArr = result.results.map(x => x.response.map(y => mapAwardApiToUi(y)));
        return of(asyncFinishAction(result.action.type, 'getCombineAwards', {
          combineAwards: resArr.reduce((a, b) => a.concat(b), []),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const createCombineAwardEpic = action$ => (
  action$.pipe(
    ofType(CREATE_COMBINE_AWARD),
    getTokenFragment(),
    switchMap(({action, token}) => (
      createCombineAward(action.data, action.combineId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'createCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const grantedAwards = result.response.response.map((award) => mapAwardApiToUi(award))
        return of(asyncFinishAction(result.action.type, 'createCombine', {
          // combineAward: mapAwardApiToUi(result.response.response),
          combineAward: grantedAwards,
          combineId: result.action.combineId,
          schoolId: result.action.schoolId
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const updateCombineAwardEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_COMBINE_AWARD),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateAward(action.athleteId, token, action.awardId, action.data)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'updateCombine', {
          combineAward: result.response,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const setAllLaserEpic = action$ => (
  action$.pipe(
    ofType(SET_ALL_LASER),
    getTokenFragment(),
    switchMap(({action, token}) => (
      toggleAllLaserApi(action.combineId, action.standardTestCategoryId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          SET_ALL_LASER,
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, SET_ALL_LASER, {
          combineId: result.action.combineId,
          standardTestCategoryId: result.action.standardTestCategoryId,
          standardTestObjectId: result.action.standardTestObjectId,
          allLaser: result.response.all_laser,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const createCombineEpic = action$ => (
  action$.pipe(
    ofType(CREATE_COMBINE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      createCombine(action.data, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'createCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const combine = Combine.fromApi(result.response.response);
        return of(
          asyncFinishAction(result.action.type, 'createCombine', {
            combine,
          }),
          getAthleteCount([combine]),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const updateCombineEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_COMBINE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateCombine(action.data, action.combine.id, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        const combine = Combine.fromApi(result.response.response);
        combine.combineTemplate = result.action.combine.combineTemplate;

        const returnActions = [
          asyncFinishAction(result.action.type, 'updateCombine', {
            combine,
            closeAt: combine.isOpen,
            showExecuteSucDialog: result.action.data.showExecuteSucDialog,
          }),
          getAthleteCount(
            [combine]
          )
        ];
        return of(...returnActions);
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const updateCloseCombineAwardEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_CREATE_COMBINE_AND_AWARDS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(
        getCombineAwardsByCombineId(token, action.combine.id)
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'getCombineAwards',
          )),
        getCombineWithPrevCombine(action.combine.id, token)
          .pipe(manualCancelApiCallFragment(
            action$,
            {
              ...action,
              token,
            },
            'getPureCombine',
          )),
      )
        .pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return of({
                ...forkResults,
                action,
                combineTemplate: CombineTemplate.fromApi(forkResults.results[1].response.currCombine.combine_template),
                prevCombine: forkResults.results[1].response.prevCombine ?
                  Combine.fromApi(forkResults.results[1].response.prevCombine) : null,
                currCombine: Combine.fromApi(forkResults.results[1].response.currCombine),
                success: true,
                token: token
              });
            }
            return forkResults;
          })
        )
    )),
    switchMap((result) => {
      if (result.value.success) {
        return forkJoin(
          result.value.prevCombine ?
            apiGetCombineResultsAllAthlete(
              result.value.prevCombine.id,
              result.value.action.token,
            )
              .pipe(manualCancelApiCallFragment(
                action$,
                {
                  ...result.action,
                  isPrev: true,
                },
                'getCombineAthlete',
              )) : of({
              action: result.value.action,
              success: true,
              result,
            }),
          apiGetCombineResultsAllAthlete(
            result.value.currCombine.id,
            result.value.action.token,
          )
            .pipe(manualCancelApiCallFragment(
              action$,
              {
                ...result.action,
                isPrev: false,
              },
              'getCombineAthlete',
            )),
        ).pipe(
          checkForkErrors(),
          map((forkResults) => {
            if (forkResults.success) {
              return {
                result,
                ...forkResults,
                columns: [...new Set(
                  result.value.currCombine.columns,
                  result.value.prevCombine ? result.value.prevCombine.columns : null,
                )],
                action: result.value.action,
                token: result.value.token
              };
            }
            return {
              forkResults,
              result,
            };
          }),
        )
      }
    }),
    switchMap((result) => {
      if (result.success) {
        const athComb = [];
        result.results.forEach((obj) => {
          // prev combine may not exists
          if (obj.response) {
            obj.response.forEach(x => (
              athComb.push({
                ...AthleteCombineResults.fromApi(x),
                combineId: obj.action.combineId,
                isPrev: obj.action.isPrev,
              })
            ));
          }
        });
        const athletes = result.results[1].response;
        let combineResults = createCombineTestObject(athComb, result.columns.filter(c => c.awardable));
        const awards = getAthleteCombineAwards(athletes, combineResults);
        const combine = result.action.combine
        const data = {
          combine,
          closed_at: moment().utc().utcOffset(-6).format('YYYY-MM-DD HH:mm:ss'),
        }
        return forkJoin(
          updateCombine(data, result.action.combine.id, result.token)
            .pipe(manualCancelApiCallFragment(
              action$,
              result.action,
              'updateCombine',
          )),
          createCombineAward(awards, combine.id, result.token)
          .pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'createCombineAward',
          )),
          ).pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  result,
                  ...forkResults,
                  action: result.action
                }
              }
              return {
                forkResults,
                result,
              };
            }),
            )
      }
      if (result.action) return of(...result.action);
      return of(result.action);
    }),
    switchMap((result) => {
      const combine = Combine.fromApi(result.results[0].response.response);
      combine.combineTemplate = result.action.combine.combineTemplate;

      const returnActions = [
        asyncFinishAction(result.action.type, 'updateCreateCombineAndResults', {
          combine,
          closeAt: combine.isOpen,
          showExecuteSucDialog: result.action.data.showExecuteSucDialog,
          schoolId: result.action.schoolId,
        }),
        getAthleteCount(
          [combine]
        )
      ];
      return of(...returnActions);
    })
  ));


const getPreviousCombineId = (combine, schoolCombines) => {
  const filteredCombines = schoolCombines
    .filter(scombine => combine.combineTemplateId === scombine.combineTemplateId)
    .filter(scombine => combine.closeDate > scombine.closeDate);

  const sortedCombines = filteredCombines.sort((a, b) => new Date(b.date) - new Date(a.date));
  const previousCombine = sortedCombines && sortedCombines[sortedCombines.length - 1];
  return of(previousCombine ? previousCombine.id : -1);
};

export const getCombineAndPrevCombineTestResultsEpic = action$ => (
  action$.pipe(
    ofType(GET_COMBINE_PRE_COMBINE_TEST_RES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getCombineWithPrevCombine(action.combineId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          {
            ...action,
            token,
          },
          'getPureCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of({
          action: result.action,
          combineTemplate: CombineTemplate.fromApi(result.response.currCombine.combine_template),
          prevCombine: result.response.prevCombine ?
            Combine.fromApi(result.response.prevCombine) : null,
          currCombine: Combine.fromApi(result.response.currCombine),
          success: true,
        });
      }
      return of(result.action);
    }),
    switchMap(result => forkJoin(
      result.prevCombine ? apiGetCombineResultsAllAthlete(
        result.prevCombine.id,
        result.action.token,
      )
        .pipe(manualCancelApiCallFragment(
          action$,
          {
            ...result.action,
            isPrev: true,
          },
          'getCombineAthlete',
        )) : of({
        action: result.action,
        success: true,
        result,
      }),
      apiGetCombineResultsAllAthlete(
        result.currCombine.id,
        result.action.token,
      )
        .pipe(manualCancelApiCallFragment(
          action$,
          {
            ...result.action,
            isPrev: false,
          },
          'getCombineAthlete',
        )),
    )
      .pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...result,
              ...forkResults,
              columns: [...new Set(
                result.currCombine.columns,
                result.prevCombine ? result.prevCombine.columns : null,
              )],
              action: result.action,
            };
          }
          return {
            forkResults,
            result,
          };
        }),
      )),
    switchMap((result) => {
      if (result.success) {
        const athComb = [];
        result.results.forEach((obj) => {
          // prev combine may not exists
          if (obj.response) {
            obj.response.forEach(x => (
              athComb.push({
                ...AthleteCombineResults.fromApi(x),
                combineId: obj.action.combineId,
                isPrev: obj.action.isPrev,
              })
            ));
          }
        });
        result.currCombine.combineTemplate = result.combineTemplate;
        return of(asyncFinishAction(result.action.type, 'compareResults', {
          combine: result.currCombine,
          results: createCombineTestObject(athComb, result.columns.filter(c => c.awardable)),
        }));
      }

      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const newlyCreatedTests = {};
const saveCombineTestResultWithoutResultEpic = action$ => (
  action$.pipe(
    ofType(SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => {
      const {
        testId,
        openDate,
        combineId,
      } = action.testParams;
      const userIdCombineId = `${action.userId}${combineId}`;
      const newlyCreatedTest = newlyCreatedTests[userIdCombineId];
      if (!testId && !newlyCreatedTest) {
        // Create test
        newlyCreatedTests[userIdCombineId] = 'wait';
        return apiCreateTest(token, {
          athlete_uuid: action.userId,
          result_date: openDate,
          combine_id: combineId,
        })
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'saveTestResultsWithoutResult',
            {
              token,
              athleteId: action.userId,
              userIdCombineId,
            },
          ));
      }
      if (!testId && newlyCreatedTest === 'wait') {
        return action$.pipe(
          filter((a) => {
            const t = a.type === ASYNC_FINISH
              && a.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT
              && a.data.athleteId === action.userId;
            return t;
          }),
          map(() => ({
            success: true,
            action,
            userIdCombineId,
            token,
            athleteId: action.userId,
            newTestId: newlyCreatedTests[userIdCombineId],
          })),
          first(),
        );
      }
      if (!testId && newlyCreatedTest) {
        return of({
          success: true,
          action,
          token,
          athleteId: action.userId,
          newTestId: newlyCreatedTest,
          userIdCombineId,
        });
      }
      return of({
        success: true,
        action,
        token,
        athleteId: action.userId,
        userIdCombineId,
      });
    }),
    mergeMap((result) => {
      if (result.success) {
        let testId;
        let test;
        const {testParams} = result.action;
        if (result.response) {
          testId = result.response.id;
          newlyCreatedTests[result.userIdCombineId] = testId;
          test = CombineTest.fromApi(result.response);
        } else if (result.newTestId) {
          testId = result.newTestId;
          test = undefined;
        } else {
          ({testId} = testParams);
          test = undefined;
        }
        const returnData = {
          test,
          token: result.token,
          testId,
          athleteId: result.athleteId,
        };
        // Update or Create Test Results if result exists

        const newTestResult = {
          result: !!testParams.result ? testParams.result : null,
          standard_test_object_id: testParams.stdTestObjId,
          test_id: testId,
          video_id: testParams.videoId,
          video_processed: testParams.processed,
        };
        if (testParams.reps) {
          newTestResult.reps = testParams.reps;
        }
        if (typeof testParams.isLaser !== 'undefined') {
          newTestResult.laser_bool = testParams.isLaser;
        }
        // Create testResult
        if (!testParams.testResultId) {
          return apiCreateTestResult(result.token, newTestResult)
            .pipe(manualCancelApiCallFragment(
              action$,
              result.action,
              'saveTestResultsWithoutResult',
              {
                ...returnData,
                job: 'create',
              },
            ));
        }
        // Update testResult
        return apiUpdateTestResult(result.token, testParams.testResultId, newTestResult)
          .pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'saveTestResultsWithoutResult',
            {
              ...returnData,
              job: 'update',
            },
          ));
      }
      return of(result);
    }),
    mergeMap((result) => {
      if (result.success) {
        let newTestResult = {};
        if (result.response) {
          newTestResult = {
            combineId: result.action.testParams.combineId,
            athleteId: result.athleteId,
            testResult: {
              testId: result.testId,
              stdTestObjId: result.response.standard_test_object_id ? result.response.standard_test_object_id : result.action.testParams.stdTestObjId,
              result: result.response.result,
              reps: result.response.reps,
              isLaser: result.response.laser_bool,
              id: result.response.id,
              multiRepMax: result.response.multi_rep_max,
              resultNormalized: result.response.result_normalized,
              videoId: result.response.video_id,
              videoProcessed: result.response.video_processed,
            },
            columnRowId: result.action.testParams.columnRowId,
            test: result.test,
            job: result.job,
            testResultId: result.action.testParams.testResultId,
          };
        }
        return of(
          asyncFinishAction(result.action.type, 'saveTestResultsWithoutResult', newTestResult),
          setExpiringCombineMsg('Saved!', 'success', 3),
        );
      }
      if (result.actions) {
        return of({
          ...result.actions,
          columnRowId: result.action.testParams.columnRowId,
        });
      }
      if (result.action.data.ajaxErrorType === FORBIDDEN_ERROR) {
        return of(
          result.action,
          setExpiringCombineMsg('You are not authorized to edit this athlete\'s results', 'error', 3),
        );
      }
      return of(result.action);
    }),
  )
);

const saveCombineTestResultEpic = action$ => (
  action$.pipe(
    ofType(SAVE_COMBINE_TEST_RESULT),
    getTokenFragmentMergeMap(),
    mergeMap(({action, token}) => {
      const {
        testId,
        openDate,
        combineId,
      } = action.testParams;
      const userIdCombineId = `${action.userId}${combineId}`;
      const newlyCreatedTest = newlyCreatedTests[userIdCombineId];
      if (!testId && !newlyCreatedTest) {
        // Create test
        newlyCreatedTests[userIdCombineId] = 'wait';
        return apiCreateTest(token, {
          athlete_uuid: action.userId,
          result_date: openDate,
          combine_id: combineId,
        })
          .pipe(manualCancelApiCallFragment(
            action$,
            action,
            'saveCombineTestResults',
            {
              token,
              athleteId: action.userId,
              userIdCombineId,
            },
          ));
      }
      if (!testId && newlyCreatedTest === 'wait') {
        return action$.pipe(
          filter((a) => {
            const t = a.type === ASYNC_FINISH
              && a.model === SAVE_COMBINE_TEST_RESULT
              && a.data.athleteId === action.userId;
            return t;
          }),
          map(() => ({
            success: true,
            action,
            userIdCombineId,
            token,
            athleteId: action.userId,
            newTestId: newlyCreatedTests[userIdCombineId],
          })),
          first(),
        );
      }
      if (!testId && newlyCreatedTest) {
        return of({
          success: true,
          action,
          token,
          athleteId: action.userId,
          newTestId: newlyCreatedTest,
          userIdCombineId,
        });
      }
      return of({
        success: true,
        action,
        token,
        athleteId: action.userId,
        userIdCombineId,
      });
    }),
    mergeMap((result) => {
      if (result.success) {
        let testId;
        let test;
        const {testParams} = result.action;
        if (result.response) {
          testId = result.response.id;
          newlyCreatedTests[result.userIdCombineId] = testId;
          test = CombineTest.fromApi(result.response);
        } else if (result.newTestId) {
          testId = result.newTestId;
          test = undefined;
        } else {
          ({testId} = testParams);
          test = undefined;
        }
        const returnData = {
          test,
          token: result.token,
          testId,
          athleteId: result.athleteId,
        };
        // Update or Create Test Results if result exists
        if ( (!testParams.result) || (testParams.result || testParams.result === 0)) {
          const newTestResult = {
            result: !!testParams.result ? testParams.result : null,
            standard_test_object_id: testParams.stdTestObjId,
            test_id: testId,
            video_id: testParams.videoId,
            video_processed: testParams.processed,
          };
          if (testParams.reps) {
            newTestResult.reps = testParams.reps;
          }
          if (typeof testParams.isLaser !== 'undefined') {
            newTestResult.laser_bool = testParams.isLaser;
          }
          // Create testResult
          if (!testParams.testResultId) {
            return apiCreateTestResult(result.token, newTestResult)
              .pipe(manualCancelApiCallFragment(
                action$,
                result.action,
                'saveCombineTestResults',
                {
                  ...returnData,
                  job: 'create',
                },
              ));
          }
          // Update testResult
          if (testParams.testResultId && !((testParams.result  === 0 || testParams.result === null) && !(testParams.oldResultVideo))) {
          return apiUpdateTestResult(result.token, testParams.testResultId, newTestResult)
            .pipe(manualCancelApiCallFragment(
              action$,
              result.action,
              'saveCombineTestResults',
              {
                ...returnData,
                job: 'update',
              },
            ));
          }
          if (testParams.testResultId) {
            return apiDeleteTestResult(result.token, testParams.testResultId)
              .pipe(manualCancelApiCallFragment(
                action$,
                result.action,
                'saveCombineTestResults',
                {
                  ...returnData,
                  job: 'delete',
                },
              ));
            }
          }
        }

        // If there is no test result, remove that test if test id exists

      return of(result);
    }),
    mergeMap((result) => {
      if (result.success) {
        let newTestResult = {};
        if (result.response) {
          newTestResult = {
            combineId: result.action.testParams.combineId,
            athleteId: result.athleteId,
            testResult: {
              testId: result.testId,
              stdTestObjId: result.response.standard_test_object_id ? result.response.standard_test_object_id : result.action.testParams.stdTestObjId,
              result: result.response.result,
              reps: result.response.reps,
              isLaser: result.response.laser_bool,
              id: result.response.id,
              multiRepMax: result.response.multi_rep_max,
              resultNormalized: result.response.result_normalized,
              videoId: result.response.video_id,
              videoProcessed: result.response.video_processed,
            },
            columnRowId: result.action.testParams.columnRowId,
            test: result.test,
            job: result.job,
            testResultId: result.action.testParams.testResultId,
          };
        }
        return of(
          asyncFinishAction(result.action.type, 'saveCombineTestResults', newTestResult),
          setExpiringCombineMsg('Saved!', 'success', 3),
        );
      }
      if (result.actions) {
        return of({
          ...result.actions,
          columnRowId: result.action.testParams.columnRowId,
        });
      }
      if (result.action.data.ajaxErrorType === FORBIDDEN_ERROR) {
        return of(
          result.action,
          setExpiringCombineMsg('You are not authorized to edit this athlete\'s results', 'error', 3),
        );
      }
      return of(result.action);
    }),
  )
);

const deleteCombineEpic = action$ => (
  action$.pipe(
    ofType(DELETE_COMBINE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      deleteCombine(action.combineId, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'deleteCombine', {
          combineId: result.action.combineId,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const expiringMsgEpic = action$ => (
  action$.pipe(
    ofType(SET_EXPIRING_MSG),
    switchMap(action => (
      timer(action.seconds * 1000)
        .pipe(map(() => asyncFinishAction(action.type, 'expiringMsg', {})))
    )),
  )
);

const createUpdateBulkTestsCombineEpic = action$ => (
  action$.pipe(
    ofType(CREATE_UPDATE_BULK_TEST_COMBINE),
    getTokenFragment(),
    mergeMap(({action, token}) => (
      bulkTestCreateOrUpdate(action.testParamsArray, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'createUpdateBulkTestCombine',
        ))
    )),
    switchMap((result) => {
      console.log(result, 'result result sresuls');
      if (result.success) {
        let bulkSuccessData = null;
        if (result.response.response) {
          bulkSuccessData = BulkSuccessData.fromApi(result.response.response);
        }
        let bulkDeleteData =  result.action.testParamsArrayToDelete;
        console.log(bulkSuccessData,'bulkSuccessData..');
        return of(
          asyncFinishAction(result.action.type, 'createUpdateBulkTestCombine', {
            bulkSuccessData,
            bulkDeleteData
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteBulkTestsCombineEpic = action$ => (
  action$.pipe(
    ofType(DELETE_BULK_TEST_COMBINE),
    getTokenFragment(),
    mergeMap(({action, token}) => (
      bulkTestDelete(action.testParamsArray, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteBulkTestCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(result.action.type, 'deleteBulkTestCombine', {
            bulkSuccessData: result.action.testParamsArray,
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const deleteLastPasteDataCombineEpic = action$ => (
  action$.pipe(
    ofType(DELETE_LAST_PASTE_DATA_COMBINE),
    getTokenFragment(),
    mergeMap(({action, token}) => (
      bulkTestDelete(action.testParamsArray, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteLastPasteDataCombine',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(result.action.type, 'deleteLastPasteDataCombine', {
            bulkSuccessData: result.action.testParamsArray,
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(
  getCombineEpic,
  getSchoolCombinesEpic,
  getCombineResultsPerAthleteEpic,
  getCombineResultAllAthletesEpic,
  getCombineTemplates,
  getCombineTemplatesBySchool,
  createCombineEpic,
  updateCombineEpic,
  getPureCombine,
  deleteCombineEpic,
  getAthleteCountEpic,
  saveCombineTestResultEpic,
  expiringMsgEpic,
  createCombineAwardEpic,
  getCombineAwardsEpic,
  getCombinesAwardsEpic,
  updateCombineAwardEpic,
  getCombineAndPrevCombineTestResultsEpic,
  createUpdateBulkTestsCombineEpic,
  deleteBulkTestsCombineEpic,
  deleteLastPasteDataCombineEpic,
  updateCloseCombineAwardEpic,
  setAllLaserEpic,
  saveCombineTestResultWithoutResultEpic,
  getCombineStandardTestCategoriesEpic,
);
