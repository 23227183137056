import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Control } from 'react-redux-form';
import moment from 'moment';
import { CloseButton } from '../../../../UxCommon';
import {
  DatePicker,
  Button,
  Headline,
  StaticInput,
  GeneralErrors,
} from '../../../../UxCommon/Form';

const defaultRrfField = {
  errors: {},
  valid: true,
};

class AddSeasonForm extends PureComponent {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    position: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    newSeasons: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    onSeasonStartChange: PropTypes.func.isRequired,
    onSeasonEndChange: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    getRrfFormObject: PropTypes.func.isRequired,
    currentSport: PropTypes.object.isRequired,
  }

  onMeetDateChange = (date) => {
    const {
      onSeasonStartChange,
      onSeasonEndChange,
    } = this.props;
    const newEndDate = date.clone();
    newEndDate.add(1, 'years');
    onSeasonEndChange(newEndDate);
    onSeasonStartChange(date);
  }

  onCancel = () => {
    const { onCancel, position } = this.props;
    onCancel(position.id);
  }

  submit = () => {
    const { submit, newSeasons } = this.props;
    let rowIndex = 0;
    const checkExists = i => newSeasons.find(row => row.rowId === i);
    while (checkExists(rowIndex)) {
      rowIndex += 1;
    }
    submit(rowIndex);
  }

  renderDatePickerFields() {
    const {
      position,
      onSeasonStartChange,
      onSeasonEndChange,
      getRrfFormModel,
      getRrfFormObject,
      currentSport,
    } = this.props;
    const rrfForm = getRrfFormObject(position.id);
    if (currentSport.usesSeasonEndDate) {
      return (
        <div className="UxPlayerData_AddSeasonForm_Dates">
          <DatePicker
            label="Season Start Date"
            placeholder="Season Start"
            model={`${getRrfFormModel(position.id)}.seasonStart`}
            rrfField={rrfForm.seasonStart || defaultRrfField}
            onDateChange={onSeasonStartChange}
            defaultValue={moment().subtract(2, 'years')}
          />
          <DatePicker
            label="Season End Date"
            placeholder="Season End"
            model={`${getRrfFormModel(position.id)}.seasonEnd`}
            rrfField={rrfForm.seasonEnd || defaultRrfField}
            onDateChange={onSeasonEndChange}
            defaultValue={moment().subtract(1, 'years')}
          />
        </div>
      );
    }
    return (
      <div className="UxPlayerData_AddSeasonForm_Dates">
        <DatePicker
          label="Meet Date"
          placeholder="Meet Date"
          model={`${getRrfFormModel(position.id)}.seasonStart`}
          rrfField={rrfForm.seasonStart || defaultRrfField}
          onDateChange={this.onMeetDateChange}
          defaultValue={moment().subtract(2, 'years')}
        />
        <Control
          type="hidden"
          model={`${getRrfFormModel(position.id)}.seasonEnd`}
          defaultValue={moment().subtract(1, 'years')}
          value={moment().subtract(1, 'years')}
        />
      </div>
    );
  }

  render() {
    const {
      isShown,
      position,
      team,
      getRrfFormModel,
      getRrfFormObject,
      currentSport,
    } = this.props;
    const rrfForm = getRrfFormObject(position.id);
    const { statsSeasonLabel, statsPositionLabel } = currentSport;
    return (
      isShown
        ? (
          <div className="UxPlayerData_AddSeasonForm">
            <CloseButton onClick={this.onCancel} size={14} />
            <Form
              model={getRrfFormModel(position.id)}
              onSubmit={this.submit}
            >
              <Headline>Add a {statsSeasonLabel}</Headline>
              <StaticInput label="Team" value={team.googleName} />
              <StaticInput label={statsPositionLabel} value={position.name} />
              {this.renderDatePickerFields()}
              <Control
                type="hidden"
                model={`${getRrfFormModel(position.id)}.teamId`}
                defaultValue={team.id}
                value={team.id}
              />
              <Control
                type="hidden"
                model={`${getRrfFormModel(position.id)}.positionId`}
                defaultValue={position.id}
                value={position.id}
              />
              <GeneralErrors rrfForm={rrfForm} />
              <div className="UxPlayerData_AddSeasonForm_Btns">
                <Button className="UxPlayerData_AddSeasonForm_Btn" onClick={this.onCancel} type="button">
                  Cancel
                </Button>
                <Button className="UxPlayerData_AddSeasonForm_Btn primary">
                  Next
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div />
        )
    );
  }
}

export default AddSeasonForm;
