import React from 'react';
import PropTypes from "prop-types";
import {ButtonRect, CloseButton} from "../../../../UxCommon";


const DeleteExternalProfileDialog = (props) => {
  const {
    onConfirmDeleteClick,
    onCancelClick,
  } = props;
  return (
    <div className="Awards_DeleteDialog_Backdrop">
      <div className="Awards_DeleteDialog">
        <CloseButton onClick={onCancelClick} size={10} />
        <div style={{ margin: 'auto' }}>
          <div className="Awards_DeleteDialog_Header">
            Delete?
          </div>
          <div
            className="Awards_DeleteDialog_Question"
            dangerouslySetInnerHTML={{
              __html: `Are you sure you want to delete the external profile`,
            }}
          />
        </div>

        <ButtonRect
          className="Awards_DeleteDialog_Btn delete"
          onClick={onConfirmDeleteClick}
        >
          Delete
        </ButtonRect>
      </div>
    </div>
  )
}
DeleteExternalProfileDialog.propeTypes = {
  onConfirmDeleteClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onHideClick: PropTypes.func.isRequired
}


export default DeleteExternalProfileDialog
