import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import Urls from '../../../../config/urls';
import { mapUserSportUiToApi } from './profile/models';

export const USER_SPORT_CREATE_SUC = 'user.sport.createSuccess';
export const userSportCreateSuccess = (user, userSport=null) => ({ type: USER_SPORT_CREATE_SUC, user, userSport });

export const userSportCreate = (profile, userSport, token,isCoach) => ajax.post(
  Urls.userSports(profile.uuid),
  JSON.stringify(mapUserSportUiToApi(profile, userSport,isCoach)),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const USER_SPORT_UPDATE_SUC = 'user.sport.updateSuccess';
export const userSportUpdateSuccess = user => ({ type: USER_SPORT_UPDATE_SUC, user });
export const userSportUpdate = (profile, userSport, token, isCoach=false) => (ajax.put(
  Urls.userSports(profile.uuid, userSport.sportId),
  JSON.stringify(mapUserSportUiToApi(profile, userSport)),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response))
);

export const USER_SPORT_DELETE_SUC = 'user.sport.deleteSuccess';
export const userSportDeleteSuccess = user => ({ type: USER_SPORT_DELETE_SUC, user });

export const userSportDelete = (uuid, sportId, token) => ajax.delete(
  Urls.userSports(uuid, sportId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const userSportSavePhoto = (uuid, sportId, photoUrl, token) => ajax.put(
  Urls.userSports(uuid, sportId),
  JSON.stringify({
    aws_uuid: uuid,
    photo_url: photoUrl,
  }),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));
