
import React , {PureComponent} from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {externalProfileDialogTo} from "../../../store/actions/ui/routes";
import { companyLogoUrl } from "../UxProfile.constants";



class ExternalProfileCard extends PureComponent
{
  static propTypes = {
    match: PropTypes.object.isRequired,
    currentSportExternalProfile: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired
  }

  render() {
    const { currentSportExternalProfile, match, isAuthorized } = this.props;
    const { id, company } = currentSportExternalProfile;
    return (
      <div key ={id} className={['AwardCard', isAuthorized ? 'auth' : ''].join(' ')}>
        { isAuthorized &&
          <Link
            className="AwardCard_OpenLink"
            to = { externalProfileDialogTo(match.params.uuid, id, match.params.sport,match.params.schoolId) }
          />
        }
          <img
            src={ companyLogoUrl(company) }
              className='AwardCard_AwardImg'
            alt={ `${company} award-img`}
          />

      </div>
    )
  }
}
export default ExternalProfileCard;
