import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PhotosCircleButton } from '../../../UxCommon';

class FavoriteSport extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    sportId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool.isRequired,
  };

  onClick = () => {
    const { sportId, onClick } = this.props;
    onClick(sportId);
  }

  render() {
    const {
      name,
      isFavorite,
    } = this.props;
    return (
      <div className="Awards_FavoritesDialog_FavoriteSport">
        <div>{name}</div>
        <PhotosCircleButton
          className={[
            'Awards_FavoritesDialog_FavoriteSport_Btn',
            isFavorite ? 'favorite' : '',
          ].join(' ')}
          iconName="star-outline"
          onClick={this.onClick}
        />
      </div>
    );
  }
}

export default FavoriteSport;
