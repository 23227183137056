import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader, TableRow, TableCell, TableBody } from '../../common/Table';
import TableCornerCell from '../../common/Table/TableCornerCell';
import Icon from '../../common/Icon';
import { changeHeaderName } from '../Common/utils';

class GrayTable extends PureComponent {
  static propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    widths: PropTypes.array,
    handleShowMore: PropTypes.func,
    print: PropTypes.bool,
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  setRef = (table) => {
    this.table = table;
  }

  table = null;

  shouldUpdate = () => {}

  render() {
    const {
      headers, data, widths, handleShowMore, print,
    } = this.props;
    return (
      <Table setReference={this.setRef} className="CombineReportGrayTable">
        <TableHeader fixedHeaders={!print}>
          <TableRow>
            <TableCornerCell style={{ width: widths[0] }}>
              <div style={{ display: 'inline-block' }}>{headers[0].name}</div>
            </TableCornerCell>
            {
              headers.slice(1).map((header, index) => (
                <TableCell key={index} style={{ width: widths[index + 1] }}>
                  {changeHeaderName(header.name, header.code) }
                </TableCell>
              ))
            }
          </TableRow>
        </TableHeader>
        <TableBody fixedHeaders={!print}>
          {
            data.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: widths[0] }}>
                  {row[0]}
                </TableCell>
                {
                  row.slice(1).map((cell, index2) => (
                    <TableCell key={index2} style={{ width: widths[index2 + 1] }}>
                      {cell}
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
          {
            handleShowMore &&
            <TableRow>
              <TableCell className="Leaderboard_ShowMoreTableCell" style={{ height: '55px' }}>
                <button className="Leaderboard_ShowMore" onClick={handleShowMore}>
                  <Icon iconName="right-arrow" className="Leaderboard_ShowMoreIcon" />
                  <div className="Leaderboard_ShowMoreText">Show More</div>
                  <Icon iconName="right-arrow" className="Leaderboard_ShowMoreIcon" />
                </button>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    );
  }
}

export default GrayTable;
