import { connect } from 'react-redux';
import Component from './NewGridView.component';
import { SportIds } from '../../../../store/actions/ui/routes';
import { checkEMailExists } from '../../../../store/actions/data/cognito';
import { selectAthletes, selectCoaches, selectCanEditRoster } from '../../+store/roster/roster.selectors';
import { selectCoachSchoolIds, createAthleteProfile, createAthleteProfileAndInvite, createBulkAthleteProfiles, createBulkAthletesProfileAndInvite } from '../../+store';

const mapStateToProps = (state, ownProps) => {
  const { team } = ownProps;
  const allAthletes = selectAthletes(state, ownProps);
  const allCoaches = selectCoaches(state, ownProps);
  const teamName = team ? team.name || team.teamRef.team : '';
  const sportSlug = ownProps.match.params.sport;
  const sportId = sportSlug ? SportIds[sportSlug] : 0;
  return {
    athletes: team ? allAthletes[team.id] : [],
    coaches: team ? allCoaches[team.id] : [],
    team,
    teamName,
    sportId,
    profilesLoading: state.modules.coachWorld.roster.loadingProfiles,
    viewSelection: state.modules.coachWorld.roster.viewSelection,
    canEdit: selectCanEditRoster(state, ownProps),
    coachSchoolIds: selectCoachSchoolIds(state),
    userProfileId: state.data.cognito.uuid,
    profiles: state.data.user.profiles,
    school: state.modules.coachWorld.coachWorld.schoolTeamsLists &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId] &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId][0]
      .team.school,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    createProfile: (athleteInfo, schoolId, sportId, rowNumber) => {
      dispatch(createAthleteProfile(athleteInfo, schoolId, sportId, rowNumber));
    },
    createProfiles: (profiles, schoolId, sportId) => {
      dispatch(createBulkAthleteProfiles(profiles, schoolId, sportId));
    },
    checkEMailExists: async email => checkEMailExists(email),
    createProfileAndInvite: createAthleteProfileAndInvite(dispatch),
    createProfilesAndInvite: createBulkAthletesProfileAndInvite(dispatch),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
