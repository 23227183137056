import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/Photos/ExpandedPhotoDialog/ExpandedPhotoDialog';
import {
  closeExpandedDialog,
  initExpandedPhotoDialogForm,
  showExpandedPhotoDeleteDialog,
  deletePhoto,
  deleteVideo,
  hideExpandedPhotoDeleteDialog,
  expandedDialogNext,
  expandedDialogPrev,
  addNewTag,
  addTagToPhoto,
  showExpandedTagsDeleteDialog,
  hideExpandedTagsDeleteDialog,
  deleteAllTags,
  markFavorite,
  saveTags,
  addTagToVideo,
  mediaNotFound,
  getVideo,
  clearVideoState,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import {getCurrentUuid, getProfile} from '../../UxCommon/Utils';
import { getNextMediaFromList, getPrevMediaFromList } from '../../../store/actions/ui/uxProfile/photosVideos/utils';
import {
  makeGetAlbumsState,
  makeGetSelectedAlbumState,
  makeGetTags,
  makeGetSelectedTags,
  makeGetMediaList,
} from '../../../selectors/Photos';
import {Routes} from "../../../store/actions/ui/routes";

const getMedia = (state, ownProps) => {
  if (ownProps.match) {
    if (ownProps.match.params.mediaId) {
      return ownProps.photoList
        .find(media => media.id === ownProps.match.params.mediaId);
    }
  }
  const photoList = makeGetMediaList(state);
  const mediaList = photoList(state, ownProps);
  const { selectedMedia } = state.ui.uxProfile.photosVideos.expandedDialog;
  if (selectedMedia) {
    return mediaList.find(m => m.id === selectedMedia.id);
  }
  return selectedMedia;
};

const mapStateToProps = (state, ownProps) => {
  const getAlbumsState = makeGetAlbumsState();
  const getSelectedAlbumState = makeGetSelectedAlbumState();
  const getTagsState = makeGetTags();
  const getSelectedTagState = makeGetSelectedTags();

  const albums = getAlbumsState(state, ownProps);
  const media = getMedia(state, ownProps);
  const tags = getTagsState(state, ownProps);
  let album = null;
  let selectedTags = [];
  let nextMediaId = '';
  let prevMediaId = '';
  album = getSelectedAlbumState(state, ownProps);
  selectedTags = getSelectedTagState(state, ownProps);
  if (media && !ownProps.isMobile) {
    const nextMedia = getNextMediaFromList(ownProps.photoList, media);
    nextMediaId = nextMedia ? nextMedia.id : '';
    const prevMedia = getPrevMediaFromList(ownProps.photoList, media);
    prevMediaId = prevMedia ? prevMedia.id : '';
  }
  return {
    media,
    album,
    albums,
    tags,
    selectedTags,
    isOpen: !!state.ui.uxProfile.photosVideos.expandedDialog.selectedMedia,
    rrfForm: state.forms.forms.expandedPhoto,
    loading: !!state.ui.uxProfile.photosVideos.expandedDialog.loadingMsg,
    loadingMsg: state.ui.uxProfile.photosVideos.expandedDialog.loadingMsg,
    formNeedsInit: state.ui.uxProfile.photosVideos.expandedDialog.needsInit,
    isDeleteDialogShown: state.ui.uxProfile.photosVideos.expandedDialog.deleteDialogIsShown,
    rrfTagForm: state.forms.forms.tagDialog,
    nextMediaId,
    prevMediaId,
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    tagsToBeUpdated: state.ui.uxProfile.photosVideos.tagDialog.updatedTags,
    profile: getProfile(state, ownProps.match),
    state,
    isCoach: state.ui.app.context.canEditProfile ? state.ui.app.context.canEditProfile.isCoach : false
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const _isAPhoto = newStateProps.media && newStateProps.media.isAPhoto;
  return {
    ...newStateProps,
    ...ownProps,
    onFavoriteClick: () => {
      dispatch(markFavorite(
        newStateProps.media,
        newStateProps.media.isAPhoto,
        newStateProps.media.favorites ? { 0: false } : { 0: true },
      ));
    },
    onDeleteClick: () => {
      dispatch(showExpandedPhotoDeleteDialog());
    },
    onDeleteAllTagsClick: () => {
      dispatch(showExpandedTagsDeleteDialog());
    },
    onCancelDeleteClick: () => {
      dispatch(hideExpandedPhotoDeleteDialog());
    },
    onConfirmDeleteClick: () => {
      if (_isAPhoto) {
        dispatch(deletePhoto(newStateProps.media.id));
      } else {
        dispatch(deleteVideo(newStateProps.media.id));
      }
    },
    onCancelDeleteAllTagsClick: () => {
      dispatch(hideExpandedTagsDeleteDialog());
    },
    onConfirmDeleteAllTagsClick: () => {
      dispatch(deleteAllTags(newStateProps.selectedTags, newStateProps.media));
    },
    onSaveClick: () => {
      newStateProps.media.albumIds = newStateProps.album.id ? [newStateProps.album.id] : [];
      dispatch(formSubmit('forms.expandedPhoto', { media: newStateProps.media }));
    },
    mediaNotFound: () => {
      dispatch(mediaNotFound());
    },
    onCloseClick: () => {
      dispatch(closeExpandedDialog());
    },
    onAlbumSelect: (albumId) => {
      dispatch(actions.change('forms.expandedPhoto.album', albumId || Routes.noAlbum));
    },
    formInit: () => {
      dispatch(actions.reset('forms.expandedPhoto'));
      dispatch(actions.merge('forms.expandedPhoto', {
        caption: newStateProps.media.caption,
        album: newStateProps.album ? newStateProps.album.id : '',
      }));
      dispatch(initExpandedPhotoDialogForm());
    },
    onNextClick: () => {
      dispatch(expandedDialogNext(newStateProps.media, ownProps.photoList));
    },
    onPrevClick: () => {
      dispatch(expandedDialogPrev(newStateProps.media, ownProps.photoList));
    },
    onTagNameChange: () => {
      dispatch(actions.change('forms.Sidebar.tagName', ''));
    },
    onAddTagClick: () => {
      const newValue = state.forms.tagDialog.newTagName.new;
      if (newValue && newValue !== '') {
        const existingTag = newStateProps.tags.find(tag => tag.label === newValue);
        if (existingTag) {
          dispatch(actions.change('forms.tagDialog.selectedTag', existingTag.id));
          if (_isAPhoto) {
            dispatch(addTagToPhoto(newStateProps.media.id, newStateProps.tags));
          } else {
            dispatch(addTagToVideo(newStateProps.media.id, newStateProps.tags));
          }
        } else {
          dispatch(addNewTag(newStateProps.media));
        }
      }
    },
    onDeleteTagClick: (tagId) => {
      dispatch(deleteAllTags(newStateProps.tags.find(a => a.id === tagId), newStateProps.media));
    },
    onAddTagToPhotoClick: () => {
      if (state.forms.tagDialog.selectedTag !== '') {
        if (_isAPhoto) {
          dispatch(addTagToPhoto(newStateProps.media.id, newStateProps.tags));
        } else {
          dispatch(addTagToVideo(newStateProps.media.id, newStateProps.tags));
        }
      }
    },
    onSaveTagsClick: () => {
      if (newStateProps.tagsToBeUpdated && newStateProps.tagsToBeUpdated.length > 0) {
        dispatch(saveTags(newStateProps.tagsToBeUpdated));
      }
    },
    getVideo : (videoId) =>{
      dispatch(getVideo(videoId))
    },
    clearVideoState : () =>{
      dispatch(clearVideoState())
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
