import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row } from '../../../UxCommon';
import VcRating from '../../../../containers/UxProfile/VcRating';
import Bio from '../../../../containers/UxProfile/Bio';
import { privacyType } from '../../../../utils';
import PrivacyControl from '../../../../containers/UxCommon/PrivacyControl/PrivacyControl';

class BioRow extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    user: PropTypes.object,
    isMyProfile: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    collapsed: false,
    maxHeight: 10000,
    width: 10000,
  };

  componentDidMount() {
    this.onMount();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onMount = () => {
    if (this.myRef.current) {
      this.setState({
        maxHeight: this.myRef.current.scrollHeight * 2,
      });
    }
  };

  onCollapseClick = () => {
    if (this.state.collapsed) {
      this.setState({
        collapsed: false,
      });
    } else {
      this.setState({
        collapsed: true,
      });
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { match, user, isMyProfile } = this.props;
    const { collapsed, maxHeight, width } = this.state;
    return (
      <div ref={this.myRef} className="UxProfile_BioRowWrapper">
        <Container className="UxProfile_BioRow">
          <div className="UxProfile_BioRow_Header">
            <button className="UxProfile_PP_BioRow_Button" onClick={this.onCollapseClick}>
              {
                collapsed ?
                  <div className="UxCommon_Collapsable_Plus" />
                  :
                  <div className="UxCommon_Collapsable_Minus" />
              }
            </button>
            <div className="UxProfile_BioRow_Txt">
              {`Player's Profile${''}`}
            </div>
          </div>
          <Row className="UxProfile_BioRow_TopRow">
            <div
              className="UxProfile_BioRow_LeftCol col-lg-8 col"
              style={{
                marginTop: collapsed ? -maxHeight : 0,
                marginBottom: collapsed ? '100px' : 0,
              }}
            >
              <PrivacyControl
                match={match}
                privacyType={privacyType.ALL}
                className="BioRow_PrivacyControl"
                loadingStyle={{ left: -35 }}
                buttonStyle={{ right: 8 }}
              />
              <Bio match={match} />
            </div>
            <div
              className="UxProfile_BioRow_RightCol col-lg-4 col"
              style={{ marginTop: collapsed ? -maxHeight : 0 }}
            >
              <PrivacyControl
                match={match}
                privacyType={privacyType.TESTS}
                className="BioRow_PrivacyControl"
                loadingStyle={{ right: -35 }}
                buttonStyle={{ right: width > 768 ? -35 : 8 }}
              />
              {
                (isMyProfile || (user && !user.privacy_tests)) &&
                <VcRating match={match} />
              }
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BioRow;
