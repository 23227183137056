import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import { Container, Row, Col, LoadingSpinner, Line } from '../../UxCommon';
import {
  TextControl,
  Button,
  Headline,
  CheckboxControl,
  GeneralError,
} from '../../UxCommon/Form';
import {formOnEnterPress, grades, gradeYears, gradYears} from '../../UxCommon/Form/utils';
import { parserOnlyNumbers, parserOnlyNumeric, parserOnlyYear } from '../../../utils/parsers';
import SelectControl from '../../UxCommon/Form/SelectControl/SelectControl';
import PrivacyControl from '../../../containers/UxCommon/PrivacyControl/PrivacyControl';
import { privacyType } from '../../../utils';

class AcademicInfo extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    formInit: PropTypes.func.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    isMetricUnits: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { formInit, profile } = this.props;
    if (profile) {
      formInit();
    }
  }

  componentDidUpdate() {
    const { profile, formNeedsInit, formInit } = this.props;
    if (profile && formNeedsInit) {
      formInit();
    }
  }

  submit = () => {
    const { isMetricUnits } = this.props;
    this.props.submit(false, isMetricUnits);
  }

  render() {

    const {
      loading,
      loadingMsg,
      rrfForm,
      formGeneralError,
      match,
    } = this.props;
    return (
      <div className="UxEditProfile_AcademicInfo">
        <LoadingSpinner
          loading={loading}
          loadingMsg={loadingMsg}
          style={{
            position: 'relative',
            height: 200,
            zIndex: 'unset',
          }}
        />
        <Container>
          {
            !loading && (
              <Row className="justify-content-center">
                <Col className="col-auto UxEditProfile_Acccount_Col">
                  <Form
                    model="forms.account"
                    onSubmit={this.submit}
                    onKeyDown={formOnEnterPress('forms.account.submit')}
                  >
                    <Headline>
                      <div style={{ width: '80%' }}>Academic Info</div>
                      <PrivacyControl match={match} privacyType={privacyType.ACADEMIC}/>
                    </Headline>
                    <Row>
                      <Col>
                        <TextControl
                          label="School"
                          placeholder="School"
                          model="forms.account.school"
                          rrfField={rrfForm.school}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="NCAA ID"
                          placeholder="NCAA ID"
                          model="forms.account.ncaaIdNumber"
                          rrfField={rrfForm.ncaaIdNumber}
                          parser={parserOnlyNumbers}
                        />
                        {/*<CheckboxControl*/}
                        {/*  label="NCAA ID"*/}
                        {/*  model="forms.account.ncaaRegistered"*/}
                        {/*  rrfField={rrfForm.ncaaRegistered}*/}
                        {/*  help="NCAA Registration status"*/}
                        {/*/>*/}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectControl
                          label="Grade"
                          placeholder="Grade"
                          model="forms.account.grade"
                          rrfField={rrfForm.grade}
                          options={grades()}
                          help="Grade level as of Oct 1 in the current school year"
                        />

                      </Col>
                      <Col>

                        <SelectControl
                          label='HS Grad Year'
                          placeholder="HS Grad Year"
                          model="forms.account.gradYear"
                          rrfField={rrfForm.gradYear}
                          options={gradeYears()}
                          required = {true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          label="ACT"
                          placeholder="ACT"
                          model="forms.account.act"
                          rrfField={rrfForm.act}
                          parser={parserOnlyNumbers}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="SAT"
                          placeholder="SAT"
                          model="forms.account.sat"
                          rrfField={rrfForm.sat}
                          parser={parserOnlyNumbers}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          label="GPA"
                          placeholder="GPA"
                          model="forms.account.coreGpa"
                          rrfField={rrfForm.coreGpa}
                          parser={parserOnlyNumeric}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="School Credits"
                          placeholder="School Credits"
                          model="forms.account.schoolCredits"
                          rrfField={rrfForm.schoolCredits}
                          parser={parserOnlyNumbers}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextControl
                          label="Class Rank"
                          placeholder="Class Rank"
                          model="forms.account.classRank"
                          rrfField={rrfForm.classRank}
                          parser={parserOnlyNumbers}
                        />
                      </Col>
                      <Col>
                        <TextControl
                          label="Class Size"
                          placeholder="Class Size"
                          model="forms.account.classSize"
                          rrfField={rrfForm.classSize}
                          parser={parserOnlyNumbers}
                        />
                      </Col>
                    </Row>
                    <GeneralError error={formGeneralError}/>
                    <div className="d-flex justify-content-between">
                      <div className="UxEditProfile_VanityUrl_BtnWrapper">
                        <Button
                          id="forms.account.submit"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            )
          }
          <Line style={{ paddingTop: '50px' }}/>
        </Container>
      </div>
    );
  }
}

export default AcademicInfo;

