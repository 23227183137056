import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

import Urls, {sportsTables} from '../../../../config/urls';
import {deleteSchedule} from "./roster.actions";

export const getSchoolTeams = (schoolId, sportId, token, query = null) => (
  ajax.getJSON(
    Urls.schools.getSchoolTeams(schoolId, sportId, query),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getSchoolTeamSchedule = (schoolTeamId, token) => (
    ajax.getJSON(
        Urls.schedule.get(schoolTeamId),
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    )
);

export const updateHeadCoachApi = (token, id, props = {}) => (
  ajax.put(
    Urls.headCoach.put(id),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const createHeadCoachApi = (token, props = {}) => (
  ajax.post(
    Urls.headCoach.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);


export const deleteHeadCoachApi = (token, id) => (
  ajax.delete(
    Urls.headCoach.delete(id),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateTwitterLinkApi = (token, props = {}) => (
  ajax.post(
    Urls.twitterLink.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const deleteTwitterLinkApi = (token, id) => (
  ajax.delete(
    Urls.twitterLink.delete(id),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const deleteScheduleApi = (token, schoolTeamId, scheduleId) => (
    ajax.delete(
        Urls.schedule.delete(schoolTeamId, scheduleId),
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    ).pipe(map(response => response.response))
);

export const updateRecruitingCoordinatorApi = (token, id, props = {}) => (
  ajax.put(
    Urls.recruitingCoordinator.put(id),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const createRecruitingCoordinatorApi = (token, props = {}) => (
  ajax.post(
    Urls.recruitingCoordinator.post(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);


export const deleteRecruitingCoordinatorApi = (token, id) => (
  ajax.delete(
    Urls.recruitingCoordinator.delete(id),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateUserTeamInfo = (token, props = {}) => (
  ajax.put(
    Urls.schools.teamSignUp(),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateUserSportInfo = (token, userId, sportId, props = {}) => (
  ajax.put(
    Urls.userSports(userId, sportId),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },``
  ).pipe(map(response => response.response))
);

export const addUserToSchoolTeam = (userId, props = {}, token) => (
  ajax.post(
    Urls.schools.addUserToSchoolTeam(userId),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const createSchedule = (token, props) =>
  ajax.post(
    Urls.schedule.post(props.schoolTeamId),
    { ext: props.ext },
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const uploadScheduleToS3 = (uploadUrl, body, progressSubscriber) => ajax({
  url: uploadUrl,
  method: 'put',
  body: body,
  headers: {
    'Content-Type': body.type || 'application/octet-stream',
  },
  progressSubscriber,
})
  .pipe(map(response => response.response));

export const flagScheduleUploaded = (schoolTeamId, scheduleId, token) =>
  ajax.put(
    Urls.schedule.uploaded(schoolTeamId, scheduleId),
    '{}',
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
    .pipe(map(response => response.response));

export const removeUserFromTeam = (schoolTeamId, token) => (ajax.delete(
    Urls.schools.deleteUserFromTeam(schoolTeamId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const removeUserFromProspectTeam = (userId, schoolTeamId, token) => (ajax.delete(
    Urls.schools.deleteUserFromProspectTeam(userId, schoolTeamId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateSportProfile = (uuid, token, userSport) => ajax.put(
  Urls.profile(uuid),
  JSON.stringify(userSport),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const createBulkProfiles = (token, profilesData) => ajax.post(
  Urls.createBulkProfilesData(),
  JSON.stringify(profilesData),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const createProfile = (token, profileData) => ajax.post(
  Urls.createProfileData(),
  JSON.stringify(profileData),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const inviteAthlete = (token, profileData) => ajax.post(
  Urls.createInviteAthlete(),
  JSON.stringify(profileData),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const updatesTeamName = (token, values) => (
  ajax.put(
    Urls.schools.updateTeamName(),
    JSON.stringify(values),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updatesRosterView = (token, uuid, cardView) => (
  ajax.put(
    Urls.updateRosterView(uuid,cardView),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const checkCardView = (token, uuid) => {
  return ajax.getJSON(
    Urls.checkCardView(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
}

export default 'codeSignUp.api.js';

