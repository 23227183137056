import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ButtonIconOnly from '../ButtonIconOnly/ButtonIconOnly';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {YesNoModal} from '../index';
import {getCurrentUuid, getLeaderboardData} from "../../../containers/UxCommon/Utils";
import {useDispatch, useSelector} from "react-redux";
import { savePrivacy } from '../../../store/actions/ui/uxProfile';
import {getProfile} from "../../ActivateProfileMembership/ActivateProfileMembership.models";
import {checkIfCanEdit} from "../../../store/actions/ui/app";
import TooltipIcon from "../../../modules/CoachWorld/common/TooltipIcon";

const LeaderboardPrivacyControl = ({combine, privacyType, className,isCoach}) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const currentUuid = useSelector((state) => getCurrentUuid(state));
  const leaderboard = useSelector((state) => getLeaderboardData(state, currentUuid, combine.id));

  const loadingPrivacy = useSelector((state) => state.ui.uxProfile.playerProfile.privacyLoading);
  const privacy = leaderboard ? !!leaderboard.leaderboard_privacy : combine.leaderboardPrivacy;

  if (leaderboard) {
    combine.leaderboardPrivacy = !!leaderboard.leaderboard_privacy;
  }

  useEffect((state)=>{
    dispatch(checkIfCanEdit(currentUuid));
  },[]);

  const onSave = () => {
    saveLeaderboardPrivacy();
    setModal(false);
  }

  const onOpen = (e) => {
    e.preventDefault();
    setModal(true);
  }

  const onClose = () => {
    setModal(false);

  }

  const saveLeaderboardPrivacy = () => {
    const toggle = privacy ? 'off' : 'on';
    dispatch(savePrivacy(toggle, privacyType, combine.id));
  }


  if (privacy == null) {
    return <LoadingSpinner loading loadingMsg="" style={{}}/>;
  }

  let icon = privacy ? 'custom-lock' : 'globe';
  const text = privacy ? 'public' : 'private';
  if (loadingPrivacy[privacyType]) {
    icon = 'loading';
  }


  return (
    <React.Fragment>
      <button
        className={`UxCommon_Privacy CombineCard_NoStyle_Btn ${className || ''}`}
        onClick={isCoach ? onOpen : null}
        style={(!isCoach) ? {opacity:'0.5',cursor:'not-allowed'}:{}}
      >
        <TooltipIcon
          iconName={icon}
          tooltip={`This leaderboard is ${!privacy ? 'public' : 'private'}`}
        />
      </button>

      <YesNoModal
        show={modal}
        onClose={onClose}
        onNo={onClose}
        onYes={onSave}
        title={`Make ${text}`}
        question={`Are you sure you want to make this ${text}?`}
        noTxt="No"
        yesTxt="Yes"
        defaultNo
      />
    </React.Fragment>
  );
}


export default LeaderboardPrivacyControl;
