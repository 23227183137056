import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import Component from './CombineTemplates.component';
import { getCombineTemplatesBySchool, toggleDeleteModal,getSchoolCombines,getCombineTemplateCombines } from '../../+store/combine';
import { selectCoachSchoolIds , selectFilteredCombinesTemplate ,selectFilteredRosters} from '../../+store';
import { checkIfUpdateNeeded , dashboardCombinesUpdateId} from '../../+store/combine/combine.utils';
const mapStateToProps = (state,ownProps) => {
  const { uuid } = state.data.cognito;
  const schoolIds = selectCoachSchoolIds(state);
  const routeMatches = state.ui.app.routes.currentRoute.match(/dashboard\/(.*?)\/combine/);
  return {
    profile: state.data.user.profiles[uuid],
    sports: state.data.sport.sports,
    currentSchoolId: routeMatches && routeMatches[1] ? routeMatches[1] : 0,
    combineTemplates: selectFilteredCombinesTemplate(state,ownProps),
    combines: state.modules.coachWorld.combine.schoolCombines,
    teams: selectFilteredRosters(state, ownProps),
    schoolIds,
    orgAffSports: state.data.sport.sportsOrgAff,
    sports: state.data.sport.sports,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
    shouldUpdate: checkIfUpdateNeeded(
      state.modules.coachWorld.combine.shouldUpdate,
      dashboardCombinesUpdateId(schoolIds),
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  getSchoolCombines: (schoolIds) => {
    dispatch(getSchoolCombines(schoolIds));
  },
  getCombineTemplatesBySchool: (schoolIds) => {
    dispatch(getCombineTemplatesBySchool(schoolIds));
  },
  toggleDeleteModal: () => {
    dispatch(toggleDeleteModal());
  },
  getCombineTemplateCombines: (combineTemplateId) => dispatch(getCombineTemplateCombines(combineTemplateId)),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
