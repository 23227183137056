import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/AwardsRow/withMembershipAwardsRow';
import {
  getCurrentUuid,
  getCurrentUuidSchoolStyle,
  getSelectedSport
} from '../UxCommon/Utils';

const getFavorites = (currUuid, currSport, state) => {
  if (!currSport) return [];
  let favorites = state.data.user.favoriteAwards[currUuid] || [];
  // filter out hidden
  favorites = favorites.filter(a => !!a.display);
  // filter by sport
  favorites = favorites.filter((favorite) => {
    if (favorite.favorites) {
      return favorite.favorites[0] || favorite.favorites[currSport.sportId];
    }
    return false;
  });
  // limit to 10
  return favorites.slice(0, 10);
};

const mapStateToProps = (state, ownProps) => {
  const currUuid = getCurrentUuid(state, ownProps.match);
  const currSport = getSelectedSport(state, ownProps.match);
  const awards = getFavorites(currUuid, currSport, state);
  const currentUuidSchoolStyle =  getCurrentUuidSchoolStyle(state, ownProps.match);
  const schoolId = currentUuidSchoolStyle && currentUuidSchoolStyle.id;
  const schoolAwardLogo =  state.data.awardLogo.schoolAwardLogo
    && state.data.awardLogo.schoolAwardLogo[schoolId];
  return {
    uuid: currUuid,
    isMyProfile: currUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    awards,
    loadingMsg: state.ui.uxProfile.awards.loadingFavoritesMsg,
    loading: !!state.ui.uxProfile.awards.loadingFavoritesMsg,
    schoolAwardLogo
  };
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
