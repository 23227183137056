import { connect } from 'react-redux';
import Component from '../../components/UxEditProfile/EditSports/UpdateProfilePhotoDialog/UpdateProfilePhotoDialog';

import {getCurrentUuid, getSelectedSport} from '../UxCommon/Utils';
import {
  updateProfilePhoto,
  onProfilePhotoDrop,
} from '../../store/actions/ui/uxEditProfile';
import { routeChangedAlert } from '../../store/actions/ui/routes';

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    isCoach,
    uuid: isCoach ? getCurrentUuid(state, ownProps.match) : state.data.cognito.uuid,
    selectedUserSport: getSelectedSport(state, ownProps.match),
    oldProfilePhotos: state.data.photo.profilePhotos,
    loadingPhoto: state.ui.uxEditProfile.profilePhoto.loadingProfilePhoto,
    loadingPhotoMsg: state.ui.uxEditProfile.profilePhoto.loadingProfilePhotoMsg,
    loadingPhotoProgress: state.ui.uxEditProfile.profilePhoto.loadingProgress,
    dropError: state.ui.uxEditProfile.profilePhoto.photoDropError,
    photoDataToCrop: state.ui.uxEditProfile.profilePhoto.photoDataToCrop,
  });
}

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onOldProfilePhotoSelected: updateProfilePhoto(dispatch),
    onDrop: onProfilePhotoDrop(dispatch),
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
