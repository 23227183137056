import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TwoTextButton extends PureComponent {
  static propTypes = {
    leftText: PropTypes.string,
    rightText: PropTypes.string,
    onLeftClick: PropTypes.func,
    onRightClick: PropTypes.func,
  }
  state = { leftSelected: true };
  render() {
    const {
      onLeftClick,
      onRightClick,
      leftText,
      rightText
    } = this.props;
    return (
      <div className={`TwoTextButton TwoTextButtonMobile`}>
        <button
          onClick={() => { this.setState({ leftSelected: true }); onLeftClick();}}
          className={`TwoTextButton TwoTextButtonMobile`}
        >
          <div className={this.state.leftSelected ?  "TwoTextButton_Left_Selected" : "TwoTextButton_Left"}>
            {leftText}
          </div>
        </button>
        <button
          onClick={() => { this.setState({ leftSelected: false }); onRightClick();}}
          className={`TwoTextButton TwoTextButtonMobile`}
        >
          <div className={!this.state.leftSelected ?  "TwoTextButton_Left_Selected" : "TwoTextButton_Left"}>
            <div>{rightText}</div>
          </div>
        </button>
      </div>
    );
  }
}

export default TwoTextButton;

