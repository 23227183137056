import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChechboxControl from '../FormControls/CheckboxControl';
import Collapsable from '../../../../components/UxCommon/Collapsable/Collapsable';

class SidebarLinkFilter extends PureComponent {
  static propTypes = {
    filterName: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    shouldSort: PropTypes.any
  }

  sortFilter = (currentIndexObject,nextIndexObject) => {
    if(currentIndexObject.name < nextIndexObject.name) {
      return -1;
    }
    if(currentIndexObject.name > nextIndexObject.name) {
      return 1;
    }
    return 0;
  }

  render() {
    const { filters, filterName, match, shouldSort } = this.props;
    const sortedFilter = shouldSort === false ? filters : filters.sort( this.sortFilter );

    return (
      <div className="CoachSidebarLinkFilter_Divider">
        <Collapsable
          keepInside
          refId = {filterName}
          useFluidContainer
          title={filterName}
          topRowStyle={{ minHeight: '59px' }}
          collapsed={!filters || filters.length === 0}
          className="CoachSidebarLinkFilter"
        >
          <div />
          <div className="CoachSidebarLinkFilter_CheckboxContainer">
            {
              sortedFilter.map(({ name, to,option, selected }, i) => (
                <Link
                  key={`${filterName}${name}${i}`}
                  to={to}
                  className="CoachSidebarLinkFilter_Link"
                >
                  <ChechboxControl
                    label={name}
                    checked={match.url === option || selected}
                    className="filters"
                  />
                </Link>
              ))
            }
          </div>
        </Collapsable>
      </div>
    );
  }
}

export default SidebarLinkFilter;
