import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChechboxControl from '../FormControls/CheckboxControl';
import Collapsable from '../../../../components/UxCommon/Collapsable/Collapsable';
import { setSortBy } from '../../+store/coachWorld/coachWorld.utils';

class SidebarSortByFilter extends PureComponent {
  static propTypes = {
    sortBy: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
    usePagination: PropTypes.bool,
    handlePaginationChange: PropTypes.func,
    paginatedKey: PropTypes.string,
    checkboxClassName: PropTypes.string,
  }

  componentDidMount() {
    this.id = `${Math.floor(Math.random() * 100000)}`;
    const { sortBy,location,history,page } = this.props;
    if(page === 'Combine' && sortBy){
      setSortBy(location, history, sortBy);
    }
  }

  onChange = name => (value) => {
    const { location, history, usePagination, handlePaginationChange } = this.props;
    if (usePagination) {
      handlePaginationChange(name);
    } else {
      setSortBy(location, history, name); 
    }
    return value;
  }

  render() {
    const {
      filters,
      label,
      className,
      sortBy,
      usePagination,
      paginatedKey,
      checkboxClassName,
    } = this.props;

    return (
      <div className={`CoachSidebarSortByFilter_Divider ${className || ''}`}>
        <Collapsable
          keepInside
          useFluidContainer
          title={label}
          topRowStyle={{ minHeight: '59px' }}
          className="CoachSidebarSortByFilter"
        >
          <div />
          <div className={`CoachSidebarSortByFilter_CheckboxContainer ${className || ''}`}>
            {
              filters.map(({ name, value }) => (
                <ChechboxControl
                  className={checkboxClassName || ''}
                  key={value}
                  label={name}
                  checked={String(value) === (usePagination ? paginatedKey : sortBy)}
                  myOnChange={this.onChange(value)}
                />
              ))
            }
          </div>
        </Collapsable>
      </div>
    );
  }
}

export default SidebarSortByFilter;
