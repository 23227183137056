import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import Icon from '../common/Icon';

class SchoolSidebar extends PureComponent {
  static propTypes = {
    school: PropTypes.object,
    loading: PropTypes.bool,
  }

  state = {
    isHidden: false,
  }

  updateWindowDimensions = () => {
    let hiddenDefault = false;
    if (window.innerWidth < 992) {
      hiddenDefault = true;
    }
    this.setState({
      isHidden: hiddenDefault,
    });
  }

  render() {
    const { school, loading } = this.props;
    if (!school) {
      return (
        <LoadingIndicator loading loadingMsg="Loading ..." />
      );
    }
    const { isHidden } = this.state;
    return (
      <div
        className={`CoachDashSidebar ${isHidden ? 'hidden' : ''}`}
        style={{ display: loading ? 'none' : 'block' }}
      >
        <div className="CoachDashSidebar_Content">
          <div className="CoachDashSidebar_LeadIn visitor">
            <div className="CoachDashSidebar_LeadInCrcl">
              {
                (school && school.schoolLogo !== '') ?
                  <img src={school.schoolLogo} alt="coach" />
                  :
                  <Icon iconName="photo2" className="AthleteMedia_PhotoIcon" />
              }
            </div>
          </div>
          <div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  Address
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {school.address || '-'}
                </div>
              </div>
            </div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  City
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {school.city || '-'}
                </div>
              </div>
            </div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  State
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {school.state || '-'}
                </div>
              </div>
            </div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  Zip
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {school.zip || '-'}
                </div>
              </div>
            </div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  Population
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {school.population || '-'}
                </div>
              </div>
            </div>
            <div className="CoachDashSidebar_DetailBox">
              <div className="CoachDashSidebar_Detail">
                <div className="CoachDashSidebar_DetailName">
                  Grades
                </div>
                <div className="CoachDashSidebar_DetailValue">
                  {`${school.lowestGrade} - ${school.highestGrade}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolSidebar;
