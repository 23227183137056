import { connect } from 'react-redux';
import queryString from 'query-string';

import Component from './SidebarAthleteSearch.component';

const mapStateToProps = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { search } = parsedQuery;
  return {
    defaultValue: search || '',
  };
};

const Container = connect(mapStateToProps)(Component);

export default Container;
