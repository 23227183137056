import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../../components/UxProfile/PlayerData/Stats/StatsTable/StatsTable';
import {
  showAddSeasonForm,
  editStatsTable,
  cancelEditStatsTable,
  statsSeasonTableFormModel,
  statsSeasonTableFormObject,
} from '../../../store/actions/ui/uxPlayerData';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import { getCurrentSport, getCurrentUuid } from '../../UxCommon/Utils';
import { playerStatsRegisterSeasons, playerStatsRegisterPosition } from '../../../store/actions/ui/uxPlayerData/stats/actions';

const mapStateToProps = (state, ownProps) => {
  const { position, team, sportId } = ownProps;
  const newSeasons = state.ui.uxPlayerData.stats.newSeasons
    .filter(ns => ((ns.positionId === position.id) && (ns.teamId === team.id)));
  const editing = !!state.ui.uxPlayerData.stats.statsTablesEditing
    .find(te => ((te.positionId === position.id) && (te.teamId === team.id)));
  const loadingMsg = state.ui.uxPlayerData.stats.statsTablesLoadingMsgs
    .find(tl => ((tl.positionId === position.id) && (tl.teamId === team.id)));
  const addSeasonFormIsShown = !!state.ui.uxPlayerData.stats.addSeasonFormsShown
    .find(tl => ((tl.positionId === position.id) && (tl.teamId === team.id)));
  const currentUuid = getCurrentUuid(state, ownProps.match);
  return {
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    newSeasons,
    editing,
    loading: !!loadingMsg,
    loadingMsg: loadingMsg ? loadingMsg.msg : '',
    addSeasonFormIsShown,
    stdCats: state.data.sport.statsStd[sportId].tables[position.id].categories,
    state,
    currentSport: getCurrentSport(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onAddSeasonClick: () => {
      const { position, team } = ownProps;
      dispatch(showAddSeasonForm(team.id, position.id));
    },
    onEditClick: () => {
      const { position, team } = ownProps;
      dispatch(editStatsTable(team.id, position.id));
    },
    onCancelEditClick: () => {
      const { position, team } = ownProps;
      dispatch(cancelEditStatsTable(team.id, position.id));
      dispatch(actions.resetErrors(statsSeasonTableFormModel(team.id, position.id)));
    },
    saveData: () => {
      const { position, team, sportId } = ownProps;
      dispatch(formSubmit(
        statsSeasonTableFormModel(team.id, position.id),
        {
          teamId: team.id,
          positionId: position.id,
          sportId,
          newSeasons: stateProps.newSeasons,
        },
      ));
    },
    getRrfFormModel: () => {
      const { position, team } = ownProps;
      return statsSeasonTableFormModel(team.id, position.id);
    },
    getRrfFormObject: () => {
      const { position, team } = ownProps;
      return statsSeasonTableFormObject(state, team.id, position.id);
    },
    playerStatsRegisterSeasons: (seasons) => {
      dispatch(playerStatsRegisterSeasons(seasons));
    },
    playerStatsRegisterPosition: (position) => {
      dispatch(playerStatsRegisterPosition(position));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
