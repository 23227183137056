import { catchError, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { actions } from 'react-redux-form';
import { combineEpics,ofType } from 'redux-observable';

import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_PENDING,
  FORM_SUBMIT_SUCCESS,
  formSubmitFailed,
  formSubmitPending,
  formSubmitSuccess,
} from '../../../formUtils';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import {
  isPerfTestsCreateUpdateFormModel,
  isPerfTestsValueCreateUpdateFormModel,
  isStrengthTestsCreateUpdateFormModel,
  isStrengthTestsValueCreateUpdateFormModel,
  PD_CREATE_UPDATE_TESTS,
  PD_UPDATE_VIDEO_ID,
  playerTestsGet,
  showCreateUpdateDialog,
  playerPersonalRecords,
  deleteTestResult
} from '../actions';

import {
  mapTestResultUiToApi,
  mapTestUiToApi,
  testsTestCreate,
  testsTestResultsCreate,
  testsTestResultsUpdate,
  testsTestUpdate,
} from '../../../../data/sport';

import { parseTime  } from '../../../../../../../src/utils/parsers'
import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import {
  validateRequired,
  validateRequiredNonZeroNumber,
  validateHourTime,
  validateRequiredDegree,
  validateMinimum, validateRequiredNonZeroNumberAndMinimum
} from '../../../formUtils/validators';
import { Routes, Sports } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

export const createUpdateBarFormValidation = [
  {
    key: 'date',
    validators: [
      value => validateRequired()(value),
    ],
  },
  {
    key: 'hourMinute',
    validators: [
      value => validateRequired()(value),
      value => validateHourTime()(value),
    ],
  },
  {
    key: 'value',
    validators: [
      value => validateRequired()(value),
      (value, form) => validateRequiredNonZeroNumberAndMinimum(form)(value),
    ],
  },
  {
    key: 'school',
    validators: [
      value => validateRequired()(value),
    ],
  },
  {
    key: 'height',
    validators: [
      value => validateRequired()(value),
      value => validateRequiredNonZeroNumber()(value),
    ],
  },
  {
    key: 'weight',
    validators: [
      value => validateRequired()(value),
      value => validateRequiredNonZeroNumber()(value),
    ],
  },
  {
    key: 'location',
    validators: [
      value => validateRequired()(value),
    ],
  },
];

export const createUpdateBarOnChangeValidationEpic = (action$, state$) => {
  const forms = () => state$.value.forms;

  return action$.pipe(
    filter((action) => {
      if ((action.type === 'rrf/change')
        && ((isPerfTestsValueCreateUpdateFormModel(action.model))
          || (isStrengthTestsValueCreateUpdateFormModel(action.model)))) {
        return true;
      }
      return false;
    }),
    debounceTime(500),
    map((action) => {
      let tempForm = null;
      const formNames = action.model.split('.');
      let key = formNames[formNames.length - 1];
      formNames.splice(-1, 1).splice(0, 1);
      formNames.forEach((item) => {
        tempForm = !tempForm ? forms()[item] : tempForm[item];
      });
      let { value } = action;
      if (key === 'height_ft') {
        key = 'height';
        action.model = 'forms.dynamic.tests.perf.0.height';
        value = (value === '' || value === '0' ? tempForm.height_in.value : value);
      } else if (key === 'height_in') {
        key = 'height';
        action.model = 'forms.dynamic.tests.perf.0.height';
        value = (value === '' || value === '0' ? tempForm.height_ft.value : value);
      } else if (key === 'height_Mt') {
        key = 'height';
        action.model = 'forms.dynamic.tests.perf.0.height';
        value = (value === '' || value === '0' ? tempForm.height_Mt.value : value);
      } else if (key === 'height_Cm') {
        key = 'height';
        action.model = 'forms.dynamic.tests.perf.0.height';
        value = (value === '' || value === '0' ? tempForm.height_Cm.value : value);
      }
      else if ( key === 'hourMinute' )
      {
        key = 'hourMinute'
        action.model = 'forms.dynamic.tests.perf.0.hourMinute';
        value = (value === '' || value === '0' ? tempForm.hourMinute.value : value);
      }
      if(key === 'reps'){
        key= 'value';
      }
      const validationObject = createUpdateBarFormValidation.find(x => x.key === key);

      let msg = null;
      if (validationObject) {
        const result = validationObject.validators
          .map(validator => validator(value, tempForm));

        msg = Object.values(result).map(x => x.required).join(' ');
      }
      return actions.setErrors(
        action.model,
        (msg && msg.trim() !== '' ? { required: msg } : {}),
      );
    }),
  );
};

export const createUpdateBarFormValidationEpic = action$ => (
  action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_INTENT)
        && ((isPerfTestsCreateUpdateFormModel(action.model))
          || (isStrengthTestsCreateUpdateFormModel(action.model)))) {
        return true;
      }
      return false;
    }),
    switchMap((action) => {
      const { form } = action.attrs;
      const errors = [];
      const validation = createUpdateBarFormValidation;
      let filterValidate  = [...validation];
      if ( form.hourMinute )
      {
        filterValidate = validation.filter(  ( { key }, field ) => key === 'value' ? false : true )
      } else {
        filterValidate = validation.filter(  ( { key }, field ) => key === 'hourMinute' ? false : true )
      }
      filterValidate.forEach((field) => {
        const fieldErrors = {};
        field.validators.forEach((validator) => {
          if (field.key in  form) {
            const report = validator(form[field.key], form);
            Object.entries(report).forEach(([key, value]) => {
              if (value) {
                fieldErrors[key] = value;
              }
            });
          }
        });
        if (Object.keys(fieldErrors).length) {
          errors.push(actions.setValidity(`${action.model}.${field.key}`, false));
          errors.push(actions.setErrors(`${action.model}.${field.key}`, fieldErrors));
        }
      });
      if (errors.length) {
        return of(
          formSubmitFailed(action.model, action.attrs),
          ...errors,
        );
      }
      return of(
        actions.setErrors(action.model, {}),
        ...(createUpdateBarFormValidation.map(validator => actions.setErrors(`${action.model}.${validator.key}`, {}))),
        formSubmitPending(action.model, action.attrs),
      );
    }),
  )
);

const createUpdateBarFormSubmitEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_PENDING)
        && ((isPerfTestsCreateUpdateFormModel(action.model))
          || (isStrengthTestsCreateUpdateFormModel(action.model)))) {
        return true;
      }
      return false;
    }),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const { form } = action.attrs;
      if (form.new) {
        return testsTestCreate(mapTestUiToApi(form, uuid()), token).pipe(
          map(testResponse => ({
            token,
            success: true,
            testResponse,
            outer: action,
          })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'createTest', error),
          })),
        );
      }
      return testsTestUpdate(mapTestUiToApi(form), form.testResult.test.id, token).pipe(
        map(testResponse => ({
          token,
          success: true,
          testResponse,
          outer: action,
        })),
        takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'updateTest', error),
        })),
      );
    }),
    switchMap(
      (results) => {
        if (results.success) {
          const { form, stdTestObjectWeight, stdTestObjectHeight } = results.outer.attrs;
//updated code will go here
          let formValue = form.value;
            if (form.hourMinute) {
              formValue = parseTime(form.hourMinute)
            }
//end

          const apiCalls = [];
          if (form.testResult && form.testResult.testResultId) {
            apiCalls.push(testsTestResultsUpdate(mapTestResultUiToApi(
              form.stdTestObjectId,
              results.testResponse.id,
              '',
              formValue,
              form.reps,
              form.laserTime ? '1' : '0',
              form.location
            ), form.testResult.testResultId, results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'updateTestResultValue', error),
              })),
            ));
          } else {
            apiCalls.push(testsTestResultsCreate(mapTestResultUiToApi(
              form.stdTestObjectId,
              results.testResponse.id,
              '',
              formValue,
              form.reps,
              form.laserTime ? '1' : '0',
              form.location
            ), results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'createTestResultValue', error),
              })),
            ));
          }

          if (form.testResult && form.testResult.test.weightResults) {
            apiCalls.push(testsTestResultsUpdate(mapTestResultUiToApi(
              stdTestObjectWeight.id,
              results.testResponse.id,
              '',
              form.weight,
            ), form.testResult.test.weightResults.testResultId, results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'updateTestResultWeight', error),
              })),
            ));
          } else {
            apiCalls.push(testsTestResultsCreate(mapTestResultUiToApi(
              stdTestObjectWeight.id,
              results.testResponse.id,
              '',
              form.weight,
            ), results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'createTestResultWeight', error),
              })),
            ));
          }
          if (form.testResult && form.testResult.test.heightResults) {
            apiCalls.push(testsTestResultsUpdate(mapTestResultUiToApi(
              stdTestObjectHeight.id,
              results.testResponse.id,
              '',
              form.height,
            ), form.testResult.test.heightResults.testResultId, results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'updateTestResultHeight', error),
              })),
            ));
          } else {
            apiCalls.push(testsTestResultsCreate(mapTestResultUiToApi(
              stdTestObjectHeight.id,
              results.testResponse.id,
              '',
              form.height,
            ), results.token).pipe(
              map(testResultResponse => ({ success: true, testResultResponse })),
              takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(PD_CREATE_UPDATE_TESTS, 'createTestResultHeight', error),
              })),
            ));
          }
          return forkJoin(...apiCalls).pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  success: true,
                  responses: forkResults.results,
                };
              }
              return forkResults;
            }),
          );
        }
        return of(results);
      },
      (outer, inner) => ({ outer: outer.outer, ...inner }),
    ),
    switchMap((results) => {
      if (results.success) {
        const sport = Sports[results.outer.attrs.sportId];
        return of(
          playerTestsGet(uuid(), results.outer.attrs.sportId, 'Loading your test results', results.outer.attrs.categoryId),
          playerPersonalRecords(uuid(), sport),
          formSubmitSuccess(results.outer.model, { ...results.outer.attrs }),
        );
      }
      if (results.actions) {
        return of(...results.actions);
      }
      return of(results.action);
    }),
  );
};

const addVideoIdEpic =  (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(PD_UPDATE_VIDEO_ID),
    getTokenFragment(),
    switchMap(({ action, token }) =>
    {
      return testsTestResultsUpdate({
        video_id: action.videoId,
        standard_test_object_id : action.testResult.stdTestObjectId,
        test_id: action.testResult.testId,
        video_processed: action.videoProcessed
      }, action.testResult.testResultId, token).pipe(
      map(response => ({ success: true, outer: {action,response} })),
      takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(PD_UPDATE_VIDEO_ID, 'updateTestResultValue', error),
      })),
    )}),
    switchMap((results) => {
      const action = results.outer.action;
      if(action.testResult.testResultResult === null && !action.videoId) {
        return of(deleteTestResult(action.testResult,
          null,
          action.bank,
          action.sportId,
          action.categoryId)
        );
      }
      if (results.success) {
        return of(
          playerTestsGet(uuid(), results.outer.action.sportId, 'Loading your test results', results.outer.action.categoryId),
          asyncFinishAction(PD_UPDATE_VIDEO_ID, 'updateTestResultValue'),
        );
      }
      return of(results.action);
    }),
  )
}

const addAnotherEpic = action$ => (
  action$.pipe(
    filter((action) => {
      if ((action.type === FORM_SUBMIT_SUCCESS)
        && ((isPerfTestsCreateUpdateFormModel(action.model))
          || (isStrengthTestsCreateUpdateFormModel(action.model)))
        && (action.data.addAnother)) {
        return true;
      }
      return false;
    }),
    map(action => showCreateUpdateDialog(action.data.bank, null)),
  )
);

export const createUpdateEpics = combineEpics(
  createUpdateBarFormValidationEpic,
  createUpdateBarFormSubmitEpic,
  createUpdateBarOnChangeValidationEpic,
  addAnotherEpic,
  addVideoIdEpic,
);
