import React from 'react';
import PropTypes from 'prop-types';
import GraySummaryTable from '../GraySummaryTable';
import { displayCategoryImprovement, displayCategory,
  displayCategoryFilterByCode,
   displayCategoryImprovementFilterByCode 
  } from '../Common/utils';
import { VC_SCORE_SUBBANKS } from '../../+store/report/report.constants';

const TeamSummary = ({ reportSummary, combineNames }) => {
  const subbanks = reportSummary.strength.subbanks || [];
  const perfSB = reportSummary.perf.subbanks || [];
  const physiqueSB = reportSummary.physique.subbanks || [];
  const strengthNotApplicable = Array.isArray(reportSummary.strength);
  const perfNotApplicable = Array.isArray(reportSummary.perf);

  // if combine does not contain these tests, we still need to show them
  const fakeStrengthSubbanks = [
    {
      standard_test_subbank_code: 'UPPERBODY',
      standard_test_subbank_name: 'Upper Body Strength',
      most_recent: 0,
      last: 0,
      NA: true,
      improvement_percent: 0,
      improvement: 0,
      id: 1,
    },
    {
      standard_test_subbank_code: 'LEGHIP',
      standard_test_subbank_name: 'Leg and Hip Strength',
      most_recent: 0,
      last: 0,
      NA: true,
      improvement_percent: 0,
      improvement: 0,
      id: 2,
    },
    {
      standard_test_subbank_code: 'EXPLOSIVESTR',
      standard_test_subbank_name: 'Explosive Strength',
      most_recent: 0,
      improvement_percent: 0,
      improvement: 0,
      NA: true,
      last: 0,
      id: 3,
    },
  ];

  // if combine does not contain these tests, we still need to show them
  const fakePerfSubbanks = [
    {
      standard_test_subbank_code: 'ACCELERATION',
      standard_test_subbank_name: 'Speed',
      most_recent: 0,
      last: 0,
      improvement_percent: 0,
      NA: true,
      improvement: 0,
      id: 1,
    },
    {
      standard_test_subbank_code: 'AGILITY',
      standard_test_subbank_name: 'Agility',
      most_recent: 0,
      last: 0,
      improvement_percent: 0,
      NA: true,
      improvement: 0,
      id: 2,
    },
    {
      standard_test_subbank_code: 'EXPLOSIVEPERF',
      standard_test_subbank_name: 'Explosive Performance',
      most_recent: 0,
      improvement_percent: 0,
      improvement: 0,
      last: 0,
      NA: true,
      id: 3,
    },
  ];
  let strengthSubbanks = Object.values(subbanks);
  strengthSubbanks = fakeStrengthSubbanks.map((fakeSubBank) => {
    const realSubBank = strengthSubbanks
      .find(rBank => rBank.standard_test_subbank_code === fakeSubBank.standard_test_subbank_code);
    if (realSubBank) {
      return realSubBank;
    }
    return fakeSubBank;
  }).sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id);

  let perfSubbanks = Object.values(perfSB);
  perfSubbanks = fakePerfSubbanks.map((fakeSubBank) => {
    const realSubBank = perfSubbanks
      .find(rBank => rBank.standard_test_subbank_code === fakeSubBank.standard_test_subbank_code);
    if (realSubBank) {
      return realSubBank;
    }
    return fakeSubBank;
  }).sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id)
    .filter(obj => VC_SCORE_SUBBANKS.includes(obj.standard_test_subbank_code));

  const physiqueCats = Object.values(physiqueSB)
    .sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id)
    .reduce((prev, sb) => (
      [...prev, ...Object.values(sb.categories)]
    ), [])
    .sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id);
  return (
    <div className="CombineReportPage">
      <GraySummaryTable
        headers={['Vc Scores', 'VC Score', 'Strength Score', 'Performance Score']}
        data={[
          [
            combineNames.mostRecent,
            displayCategory(reportSummary.vc_score.most_recent, 'SCORE'),
            displayCategory(reportSummary.strength.most_recent, 'SCORE', strengthNotApplicable),
            displayCategory(reportSummary.perf.most_recent, 'SCORE', perfNotApplicable),
          ],
          [
            combineNames.last,
            displayCategory(reportSummary.vc_score.last, 'SCORE'),
            displayCategory(reportSummary.strength.last, 'SCORE', strengthNotApplicable),
            displayCategory(reportSummary.perf.last, 'SCORE', perfNotApplicable),
          ],
          [
            'Improvement',
            displayCategoryImprovement(
              reportSummary.vc_score.improvement_percent,
              'max',
              true,
              'PERCENTAGE-HUNDREDTHS',
            ),
            displayCategoryImprovement(
              reportSummary.strength.improvement_percent,
              'max',
              true,
              'PERCENTAGE-HUNDREDTHS',
              strengthNotApplicable,
            ),
            displayCategoryImprovement(
              reportSummary.perf.improvement_percent,
              'max',
              true,
              'PERCENTAGE-HUNDREDTHS',
              perfNotApplicable,
            ),
          ],
        ]}
      />
      <GraySummaryTable
        headers={['Strength scores', ...strengthSubbanks.map(sb => sb.standard_test_subbank_name)]}
        data={[
          [
            combineNames.mostRecent,
            ...strengthSubbanks.map(sb => displayCategory(sb.most_recent, 'SCORE', sb.NA)),
          ],
          [
            combineNames.last,
            ...strengthSubbanks.map(sb => displayCategory(sb.last, 'SCORE', sb.NA)),
          ],
          [
            'Improvement',
            ...strengthSubbanks.map(sb => (
              displayCategoryImprovement(
                sb.improvement_percent,
                'max',
                true,
                'PERCENTAGE-HUNDREDTHS',
                sb.NA,
              )
            )),
          ],
        ]}
      />
      <GraySummaryTable
        headers={['Performance scores', ...perfSubbanks.map(sb => sb.standard_test_subbank_name)]}
        data={[
          [
            combineNames.mostRecent,
            ...perfSubbanks.map(sb => displayCategory(sb.most_recent, 'SCORE', sb.NA)),
          ],
          [
            combineNames.last,
            ...perfSubbanks.map(sb => displayCategory(sb.last, 'SCORE', sb.NA)),
          ],
          [
            'Improvement',
            ...perfSubbanks.map(sb => (
              displayCategoryImprovement(
                sb.improvement_percent,
                'max',
                true,
                'PERCENTAGE-HUNDREDTHS',
                sb.NA,
              )
            )),
          ],
        ]}
      />
      <GraySummaryTable
        headers={['Measurables', ...physiqueCats.map(sb => sb.standard_test_category_name)]}
        data={[
          [
            combineNames.mostRecent,
            ...physiqueCats.map(c => displayCategoryFilterByCode(c.most_recent, c.standard_stat_format_code,c.standard_test_category_code)),
          ],
          [
            combineNames.last,
            ...physiqueCats.map(c => displayCategoryFilterByCode(c.last, c.standard_stat_format_code,c.standard_test_category_code)),
          ],
          [
            'Change',
            ...physiqueCats.map(c => (
              displayCategoryImprovementFilterByCode(
                c.improvement,
                c.standard_test_category_best,
                !!c.standard_test_category_comparable,
                c.standard_stat_format_code.replace('INTEGER', 'HUNDREDTHS'),
                c.standard_test_category_code
              )
            )),
          ],
        ]}
      />
    </div>);
};

TeamSummary.propTypes = {
  reportSummary: PropTypes.object.isRequired,
  combineNames: PropTypes.object.isRequired,
};

export default TeamSummary;
