import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonIconOnly } from '../../../../UxCommon';

class Album extends PureComponent {
  static propTypes = {
    album: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func,
  };

  componentDidMount() {
  }

  onClick = () => {
    const { album, onClick } = this.props;
    onClick(album);
  };

  onDeleteClick = () => {
    const { album, onDeleteClick } = this.props;
    onDeleteClick(album);
  }

  render() {
    const { album, onDeleteClick } = this.props;
    return (
      <div className="Photos_SelectAlbumDialog_Album">
        <button
          className="Photos_SelectAlbumDialog_Album_Select"
          onClick={this.onClick}
        >
          {album.title}
        </button>
        {
          onDeleteClick && <ButtonIconOnly
            className="Photos_SelectAlbumDialog_Album_Delete"
            iconName="trash"
            onClick={this.onDeleteClick}
          />
        }
      </div>
    );
  }
}

export default Album;
