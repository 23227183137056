import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CloseLink from "../../../UxCommon/CloseLink/CloseLink";
import {Routes} from "../../../../store/actions/ui/routes";
import LoadingSpinner from "../../../UxCommon/LoadingSpinner/LoadingSpinner";
import ExternalProfileLogo from "../ExternalProfileLogo/ExternalProfileLogo";
import ExternalProfileForm from "./ExternalProfileForm/ExternalProfileForm";
import { companyLogoUrl } from "../../UxProfile.constants";
import questionMarkLogo from '../../../../assets/images/question.png';

class CreateUpdateDialog extends PureComponent
{
  static propTypes = {
    formInit: PropTypes.func.isRequired,
    externalProfile: PropTypes.object,
    onExternalProfileDeleteClick: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    rrfForm: PropTypes.object.isRequired,
    selectedSportCompanies: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),

  }


  state = {
    scrollTop: 0,
    shareDialogUri: '',
    selectedCompanyImageUrl: '',
    selectedCompanyLogo:questionMarkLogo
  };

  componentDidMount() {
      const { formInit , externalProfile } = this.props;
      formInit(externalProfile);
      if (externalProfile) {
        this.setState({
          selectedCompanyLogo: companyLogoUrl(externalProfile.company)
        })
      }
      this.scrollElement = document.getElementById('uxProfileRight');
      this.saveScrollTop(this.scrollElement.scrollTop);
  }

  getPaddingTop = () => {
    const { scrollTop } = this.state;
    if (scrollTop > 272) {
      return scrollTop - 154;
    }
    return 118;
  }


  onExternalProfileLinkChange = (com) =>  {
    const  { selectedSportCompanies, onExternalProfileSelected,  } = this.props;
   const selectedCompany = selectedSportCompanies.find(sportCom => sportCom.value === com);
      this.setState({
        selectedCompanyLogo: companyLogoUrl(selectedCompany.name)
      })
    onExternalProfileSelected(com, selectedCompany.url);
  }

  onSubmitForm = () => {
    const { externalProfile, onExternalProfileSubmitForm } = this.props;
    onExternalProfileSubmitForm(externalProfile);
  };

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  render() {
    const {
      match,
      history,
      loading,
      loadingMsg,
      isMyProfile,
      rrfForm,
      selectedSportCompanies,
      externalProfile,
      onExternalProfileDeleteClick
    } = this.props;

    const {  selectedCompanyLogo } = this.state;


    return (
      <div
        className="Awards_CreateUpdateDialog_BackDrop"
        style={{ paddingTop: `${this.getPaddingTop()}px` }}
      >
        <div
          className={[
            'Awards_CreateUpdateDialog',
            loading ? 'loading' : '',
          ].join(' ')}
        >


          <CloseLink
            to={`/${Routes.profile}/${match.params.uuid}/${Routes.externalProfile}${match.params.sport ? `/${match.params.sport}` : ''}${match.params.schoolId ? `/${match.params.schoolId}`: ''}`}

            className="Awards_CreateUpdateDialog_CloseLink"
          />
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
            style={{ backgroundColor: 'rgba(256, 256, 256, 0.7)', zIndex: 100 }}
          />

          <div className="row rowMargin  vc-external-popup" style={{ width: '100%' }}>
            <div className="Awards_CreateUpdateDialog_ImgSide col-sm-12 col-md-7">
              <div className="img-wrapper">
                <ExternalProfileLogo
                  classes ="Awards_CreateUpdateDialog_Img"
                  isCoach={true}
                  imagePath ={selectedCompanyLogo}
                />
              </div>
            </div>

            <div className="Awards_CreateUpdateDialog_Details col-md-5 col-sm-12">
              {
                isMyProfile && (
                  <ExternalProfileForm
                    rrfForm={rrfForm}
                    externalProfileSportIcons ={selectedSportCompanies}
                    multipleObject={true}
                    companyLogo={selectedCompanyLogo}
                    onDeleteClick={onExternalProfileDeleteClick}
                    onSubmitForm={this.onSubmitForm}
                    onExternalProfileSelected={this.onExternalProfileLinkChange}
                    match={match}
                    externalProfile={externalProfile}
                    history={history}
                  />
                )
              }

            </div>

          </div>

        </div>
      </div>
    );

  }
}

export default CreateUpdateDialog;
