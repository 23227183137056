import React from 'react';
import PropTypes from 'prop-types';

const TableCell = ({
  className,
  style,
  colSpan,
  children,
  isHeader,
}) => isHeader ? (
  <th
    colSpan={colSpan || ' '}
    className={['CoachTableCell', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </th>
) : (
  <td
    colSpan={colSpan || ' '}
    className={['CoachTableCell', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </td>
);

TableCell.propTypes = {
  className: PropTypes.string,
  colSpan: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object,
  isHeader: PropTypes.bool,
};

export default TableCell;
