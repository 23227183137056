import React from 'react';
import PropTypes from 'prop-types';

const GeneralError = ({ error }) => (
  error &&
    <div className="UxCommon_Form_GeneralError">
      <i className="icon-alert-icon" />
      <div className="UxCommon_Form_GeneralError_Msg">
        {error}
      </div>
    </div>
);
GeneralError.propTypes = {
  error: PropTypes.string,
};

export default GeneralError;
