import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class BottomBanner extends PureComponent {
  static propTypes = {
    bannerComponent: PropTypes.any,
  }

  componentDidMount() {
  }

  render() {
    const { bannerComponent } = this.props;
    if (!bannerComponent) {
      return null;
    }
    return (
      <div className="UxProfile_BottomBanner">
        {bannerComponent}
      </div>
    );
  }
}

export default BottomBanner;
