import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader, TableRow, TableCell, TableBody } from '../../common/Table';
import TableCornerCell from '../../common/Table/TableCornerCell';


class GraySummaryTable extends PureComponent {
  static propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
  }

  getFirstColWidth = () => '33.333%'

  getColWidth = numCols => `${(100 - 33.333) / numCols}%`

  setRef = (table) => {
    this.table = table;
  }

  table = null;
  render() {
    // const { headers, data, combineNames } = this.props;
    const { headers, data } = this.props;
    return (
      <React.Fragment>
        <Table setReference={this.setRef} className="CombineReportGraySummaryTable">
          <TableHeader>
            <TableRow>
              <TableCornerCell>
                <div style={{ display: 'inline-block' }}>{headers[0]}</div>
              </TableCornerCell>
              {
                headers.slice(1).map((header, index) => (
                  <TableCell key={index}>
                    {header}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHeader>
          <TableBody fixedHeaders={false} >
            {
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="CoachReportSummaryFirstCol"
                    style={{ width: this.getFirstColWidth() }}
                  >
                    {row[0]}
                  </TableCell>
                  {
                    row.slice(1).map((cell, index2, arr) => (
                      <TableCell
                        className="CoachReportSummaryCol"
                        style={{ width: this.getColWidth(arr.length) }}
                        key={index2}
                      >
                        {cell}
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <br />
        <hr />
        <br />
      </React.Fragment>
    );
  }
}

export default GraySummaryTable;
