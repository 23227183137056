import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-redux-form';
import { SecondaryIconButton } from '../../../../UxCommon';

class CombineAwardDetails extends PureComponent {
  static propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    // rrfForm: PropTypes.object.isRequired,
    // onHideShowToggleClick: PropTypes.func.isRequired,
    // match: PropTypes.object.isRequired,
    award: PropTypes.object,
    onShareClick: PropTypes.func.isRequired,
  }

  componentDidMount() {}

  render() {
    const {
      award,
      // rrfForm,
      // onHideShowToggleClick,
      onShareClick,
      onSubmitForm,
      // match,
    } = this.props;
    if (!award) return null;
    return (
      <div className="Awards_CombineAwardDetails">
        <div className="Awards_AwardDetailsForm_TopBtnsRow">
          {/* {
            rrfForm.display.value ? (
              <SecondaryIconButton type="button" iconName="hide" onClick={onHideShowToggleClick}>
                Hide
              </SecondaryIconButton>
            ) :
              (
                <SecondaryIconButton type="button" iconName="hide" onClick={onHideShowToggleClick}>
                  Show
                </SecondaryIconButton>
              )
          } */}
          <SecondaryIconButton
            className="Photos_PhotoInfoForm_ShareBtn"
            iconName="share"
            onClick={onShareClick}
            type="button"
          >
            Share
          </SecondaryIconButton>
        </div>
        <Form
          className="Awards_CombineAwardDetailsForm"
          model="forms.awards"
          onSubmit={onSubmitForm}
        >
          <div className="Awards_AwardDetails_Name">
            {award.name}
          </div>
          <div className="Awards_AwardDetails_Date">
            {`Awarded ${(award.date.isValid() ? award.date.format('MMMM Do YYYY') : moment(award.createdAt).format('MMMM Do YYYY'))}`}
          </div>
          {
            award.url && (
              <a className="Awards_AwardDetails_Url" href={award.url} target="_target">
                {award.url}
              </a>
            )
          }
          {
            award.desc && (
              <div className="Awards_AwardDetails_Description">
                {award.desc}
              </div>
            )
          }
          {/* <div style={{ display: 'flex', marginTop: 'auto' }}>
            <ButtonLinkRect
              style={{ marginLeft: 'auto', width: '100px' }}
              className="Awards_AwardsDetailsForm_Button"
              to={`/${Routes.profile}/${match.params.uuid}/${Routes.awards}`}
            >
              Cancel
            </ButtonLinkRect>
            <ButtonRect
              className="Awards_AwardsDetailsForm_Button primary"
              style={{ width: '120px' }}
              type="submit"
            >
              Save
            </ButtonRect>
          </div> */}
        </Form>
      </div>
    );
  }
}

export default CombineAwardDetails;
