import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CloseButton, LoadingSpinner } from '../../../../../../UxCommon';
import CreateUpdateForm from './CreateUpdateForm/CreateUpdateForm';
import DeleteForm from './DeleteForm/DeleteForm';

class EditTestResultModal extends PureComponent {
  static propTypes = {
    stdTestObject: PropTypes.object.isRequired,
    formId: PropTypes.number.isRequired,
    submit: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    getRrfForm: PropTypes.func,
    onDateChanged: PropTypes.func.isRequired,
    onFormFieldChanged: PropTypes.func.isRequired,
    onAddAnotherClick: PropTypes.func.isRequired,
    testResult: PropTypes.object,
    mostRecentHeight: PropTypes.string.isRequired,
    mostRecentWeight: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    onCancelDeleteClick: PropTypes.func.isRequired,
    onConfirmDeleteClick: PropTypes.func.isRequired,
    deleteDialogIsShown: PropTypes.bool.isRequired,
    getCreateUpdateFormModel: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    savingMsg: PropTypes.string.isRequired,
    isCoach: PropTypes.bool,
    isMetricUnits: PropTypes.bool.isRequired,
    updateGraphIdxOnDelete: PropTypes.func,
    goToLastGraph: PropTypes.func,
  }

  onSubmit = () => {
    const {
      submit,
      formId,
      stdTestObject,
      goToLastGraph,
    } = this.props;
    submit(formId, stdTestObject.cat.id);
    goToLastGraph();
  }

  onDeleteClick = () => {
    const {
      testResult,
      onDeleteClick,
      number,
      updateGraphIdxOnDelete,
    } = this.props;
    onDeleteClick(testResult);
    updateGraphIdxOnDelete(number);
  }

  onConfirmDeleteClick = () => {
    const {
      testResult,
      onConfirmDeleteClick,
      stdTestObject,
      number,
    } = this.props;
    onConfirmDeleteClick(
      testResult,
      `Deleting ${stdTestObject.cat.name} Data #${number}`,
      stdTestObject.cat.id,
    );
  }

  render() {
    const {
      formId,
      getRrfForm,
      onCancelClick,
      stdTestObject,
      onAddAnotherClick,
      testResult,
      number,
      onDateChanged,
      onFormFieldChanged,
      onCancelDeleteClick,
      deleteDialogIsShown,
      getCreateUpdateFormModel,
      saving,
      savingMsg,
      mostRecentHeight,
      mostRecentWeight,
      isCoach,
      isMetricUnits,
      commonSchool,
    } = this.props;
    return (
      <div className="UxPlayerData_TestData_EditTestResultModalWrapper">
        <div className="UxPlayerData_TestData_EditTestResultModal"
             style={{ height: deleteDialogIsShown ? '426px' : 'auto' }}>
          <LoadingSpinner loading={saving} loadingMsg={savingMsg} style={{ zIndex: 100 }}/>
        <div>
          <CloseButton size={14} onClick={onCancelClick}/>
        </div>
          {
            deleteDialogIsShown ?
              <DeleteForm onDelete={this.onConfirmDeleteClick} onCancel={onCancelDeleteClick}/>
              :
              <CreateUpdateForm
                isCoach={isCoach}
                stdTestObject={stdTestObject}
                formId={formId}
                submit={this.onSubmit}
                onCancelClick={onCancelClick}
                onDeleteClick={this.onDeleteClick}
                getRrfForm={getRrfForm}
                commonSchool={commonSchool}
                getRrfFormModel={getCreateUpdateFormModel}
                onDateChanged={onDateChanged}
                onFormFieldChanged={onFormFieldChanged}
                onAddAnotherClick={onAddAnotherClick}
                testResult={testResult}
                number={number}
                mostRecentHeight={mostRecentHeight}
                mostRecentWeight={mostRecentWeight}
                isMetricUnits={isMetricUnits}
              />
          }
        </div>
      </div>
    );
  }
}

export default EditTestResultModal;
