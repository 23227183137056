import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PHONE_NEEDS_CONFIRMED, EMAIL_NEEDS_CONFIRMED, EMAIL_SUCCESS_MERGED } from '../../../store/actions/ui/uxEditProfile/account';
import { Routes } from '../../../store/actions/ui/routes';
import ConfirmMessage from './ConfirmMsg/ConfirmMsg';
import ErrorMsg from './ErrorMsg/ErrorMsg';
import { NO_INTERNET, CLIENT_ERROR, SERVER_ERROR, UNKNOWN_COMMS_ERROR } from '../../../store/actions/ui/ajaxErrorHandlers';
import { USER_SPORT_UPDATE_SUC } from '../../../store/actions/data/user/userSport';
import { USER_PROFILE_UPDATE_SUC } from '../../../store/actions/data/user/profile';
import { ASYNC_FINISH, ASYNC_ERROR } from '../../../store/actions/ui/async';
import { FAVORITE_AWARD, UPDATE_AWARD, HIDE_AWARD, DELETE_AWARD, CREATE_AWARD } from '../../../store/actions/ui/uxProfile/awards';
import SuccessMsg from './SuccessMsg/SuccessMsg';
import { CREATE_ALBUM, UPDATE_ALBUM, STAGE_MEDIA_PROBLEM, MULTI_CHANGE_ALBUM_EXECUTE } from '../../../store/actions/ui/uxProfile/photosVideos';
import {
  CREATE_EXTERNAL_PROFILE,
  DELETE_EXTERNAL_PROFILE,
  UPDATE_EXTERNAL_PROFILE
} from "../../../store/actions/ui/uxProfile/externalProfile";

class TopBanner extends PureComponent {
  static propTypes = {
    bannerComponent: PropTypes.any,
    bannerMsgs: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    onMsgClose: PropTypes.func.isRequired,
  }

  renderBannerMsg = (msg) => {
    const { match, onMsgClose } = this.props;
    const { sport, schoolId } = match.params;
    if (msg.type === PHONE_NEEDS_CONFIRMED) {
      return (
        <ConfirmMessage
          key={msg.id}
          id={msg.id}
          msg="Please confirm your phone number to prevent being locked out of your account"
          btnMsg="Confirm Phone"
          to={`/${Routes.profile}/${match.params.uuid}/${Routes.edit}/${Routes.confirmPhone}`}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === EMAIL_NEEDS_CONFIRMED) {
      return (
        <ConfirmMessage
          key={msg.id}
          id={msg.id}
          msg="Please confirm your email to prevent being locked out of your account"
          btnMsg="Confirm Email"
          to={`/${Routes.profile}/${match.params.uuid}/${Routes.edit}/${Routes.confirmEmail}`}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === NO_INTERNET) {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg="Please check you internet connection and reload this page"
          btnMsg="Reload"
          onClick={null}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === CLIENT_ERROR) {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg={`Error ${msg.error.status}: Sorry for the problems - this error has been reported, please contact info@virtualcombine.com for more information.`}
          btnMsg="Reload"
          onClick={null}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === SERVER_ERROR) {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg={`Error ${msg.error.status} Sorry for the problems - this error has been reported, please contact info@virtualcombine.com for more information.`}
          btnMsg="Reload"
          onClick={null}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === UNKNOWN_COMMS_ERROR) {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg="Uh oh ... What happened!  We apologize for the inconvenience.  This error has been reported, please contact info@virtualcombine.com for more information."
          btnMsg="Reload"
          onClick={null}
          onClose={onMsgClose}
        />
      );
    } else if (msg.type === STAGE_MEDIA_PROBLEM) {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg={msg.msgDesc}
          btnMsg="Reload"
          onClick={null}
          onClose={onMsgClose}
        />
      );
    }
    else if (msg.type === EMAIL_SUCCESS_MERGED) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your profiles have been successfully merged! Explore the many ways you can build a stand-out athlete profile."
          btnMsg="Ok"
          onClose={onMsgClose}
        />
      );
    }
    else if (msg.type === USER_SPORT_UPDATE_SUC || msg.type === USER_PROFILE_UPDATE_SUC || msg.model === 'update.invite.profile.form.final') {
      return (
        <ConfirmMessage
          key={msg.id}
          id={msg.id}
          msg="Your profile was successfully updated."
          btnMsg="Ok"
          // to={`/${Routes.profile}/${match.params.uuid}/${Routes.edit}/${sport}${schoolId? `/${schoolId}`: ''}`}
          onClose={onMsgClose}
        />
      );
    }
    else if (((msg.type === ASYNC_FINISH) && (msg.model === FAVORITE_AWARD)) ||
    ((msg.type === ASYNC_FINISH) && (msg.model === UPDATE_AWARD)) ||
    ((msg.type === ASYNC_FINISH) && (msg.model === CREATE_AWARD)) ||
    ((msg.type === ASYNC_FINISH) && (msg.model === HIDE_AWARD))) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your award has been saved"
          onClose={onMsgClose}
        />
      );
    } else if ((msg.type === ASYNC_FINISH) && (msg.model === DELETE_AWARD)) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your award has been deleted"
          onClose={onMsgClose}
        />
      );
    }
    else if ((msg.type === ASYNC_FINISH) && (msg.model === CREATE_EXTERNAL_PROFILE))   {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your external profile has been saved"
          onClose={onMsgClose}
        />
      );
    }
    else if  ((msg.type === ASYNC_FINISH) && (msg.model === UPDATE_EXTERNAL_PROFILE))  {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your external profile has been updated"
          onClose={onMsgClose}
        />
      );
    }
    else if ((msg.type === ASYNC_FINISH) && (msg.model === DELETE_EXTERNAL_PROFILE)) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your external profile has been deleted"
          onClose={onMsgClose}
        />
      );
    }

    else if ((msg.type === ASYNC_FINISH) && (msg.model === MULTI_CHANGE_ALBUM_EXECUTE)) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg={msg.data.successMessage}
          onClose={onMsgClose}
        />
      );
    } else if ((msg.type === ASYNC_ERROR) &&
    (msg.model === CREATE_ALBUM || msg.model === UPDATE_ALBUM)) {
      return (
        <SuccessMsg
          key={msg.id}
          id={msg.id}
          msg="Your media is still processing.  To check if they’ve finished please periodically refresh this page."
          onClose={onMsgClose}
        />
      );
    } else if (msg && msg.msgDesc && msg.msgType === 'error') {
      return (
        <ErrorMsg
          key={msg.id}
          id={msg.id}
          msg={msg.msgDesc}
          btnMsg={msg.btnMsg ? msg.btnMsg : null}
          onClick={msg.onClick ? msg.onClick : null}
          onClose={onMsgClose}
        />
      );
    }
    return null;
  }

  render() {
    const { bannerComponent, bannerMsgs } = this.props;
    if (bannerComponent) {
      return (
        <div className="UxProfile_TopBanner">
          {bannerComponent}
        </div>
      );
    }
    if (bannerMsgs.length) {
      const errorMsgs = bannerMsgs.filter(msg => msg.msgType === 'error');
      const warnMsgs = bannerMsgs.filter(msg => msg.msgType === 'warn');
      const successMsgs = bannerMsgs.filter(msg => msg.msgType === 'success');
      return (
        <React.Fragment>
          {
            !!errorMsgs.length && (
              <div className="UxProfile_TopBanner_ErrorMsgs">
                {
                  errorMsgs.map(msg => this.renderBannerMsg(msg))
                }
              </div>
            )
          }
          {
            !!warnMsgs.length && (
              <div className="UxProfile_TopBanner_WarnMsgs">
                {
                  warnMsgs.map(msg => this.renderBannerMsg(msg))
                }
              </div>
            )
          }
          {
            !!successMsgs.length && (
              <div className="UxProfile_TopBanner_SuccessMsgs">
                {
                  successMsgs.map(msg => this.renderBannerMsg(msg))
                }
              </div>
            )
          }
        </React.Fragment>
      );
    }
    return null;
  }
}

export default TopBanner;
