import React, { useRef, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Routes} from "../../../../store/actions/ui/routes";

const useOutsideAlerter = (ref) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        ref.current.classList.remove('show');
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const DropDownButton = ({className, teamId, schoolId, userId, sport, onEditClick}) => {
  
  const [showDropDown, setDropDown] = useState(false);
  
  const showDropdown = () => {
    setDropDown(!showDropDown);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (

    <div className={['dropdown', className || ''].join(' ')}>

      <ul className="dropbtn icons btn-right showLeft"
          onClick={() => showDropdown()}>
        {/* showing 3 dots with li */}
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <div ref={wrapperRef} id={`myDropdown_${userId}`} onClick={() => showDropdown()} 
        className={`dropdown-content ${showDropDown? 'show' : ''}`}>
        <a href="#" onClick={() => onEditClick()}> Edit </a>
        <Link
          target="_blank"
          to={`/${Routes.school}/${schoolId}/${Routes.team}/${teamId}/${Routes.athlete}/${userId}/${Routes.prospectSheet}/${sport}`}> Prospect
          Sheet </Link>

      </div>
    </div>
  )
}

DropDownButton.propTypes = {
  className: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired
}


export default DropDownButton;
