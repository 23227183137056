import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import {
  epics as accountPageEpics,
  reducers as accountPageReducers,
} from './accountPage';
import {
  epics as signInPageEpics,
  reducers as signInPageReducers,
} from './signInPage';
import {
  epics as physiquePageEpics,
  reducers as physiquePageReducers,
} from './physiquePage';
import {
  epics as sportsPageEpics,
  reducers as sportsPageReducers,
} from './sportsPage';
import {
  epics as codeSignUpEpics_,
  reducers as codeSignUpReducers,
} from './codeSignUp';

export const codeSignUpEpics = combineEpics(
  accountPageEpics,
  signInPageEpics,
  physiquePageEpics,
  codeSignUpEpics_,
  sportsPageEpics,
);

export default combineReducers({
  accountPage: accountPageReducers,
  signInPage: signInPageReducers,
  physiquePage: physiquePageReducers,
  codeSignUp: codeSignUpReducers,
  sportsPage: sportsPageReducers,
});

export * from './accountPage/accountPage.actions';
export * from './signInPage/signInPage.actions';
