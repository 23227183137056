import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { GET_REPORT, GET_REPORTS, CREATE_REPORT, GET_ATHLETE_REPORT, GET_TEAM_REPORT } from './report.actions';
import { CANCEL_API_REQUEST } from '../../../../store/actions/ui/uxProfile/utils';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        [GET_REPORTS, GET_REPORT, CREATE_REPORT, GET_ATHLETE_REPORT, GET_TEAM_REPORT]
          .includes(action.model)) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        [GET_REPORTS, GET_REPORT, CREATE_REPORT, GET_ATHLETE_REPORT, GET_TEAM_REPORT]
          .includes(action.model)) {
        return '';
      }
      return state;
    case CANCEL_API_REQUEST:
      return '';
    default:
      return state;
  }
};

const athleteTeamReport = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ATHLETE_REPORT || action.model === GET_TEAM_REPORT) {
        return Object.assign({}, state, action.data.report);
      }
      return state;
    default:
      return state;
  }
};

const report = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          [action.data.reportId]: action.data.report,
        });
      }
      return state;
    default:
      return state;
  }
};

const athletes = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          [action.data.reportId]: action.data.athletes,
        });
      }
      return state;
    default:
      return state;
  }
};

const columns = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          [action.data.reportId]: action.data.columns,
        });
      }
      return state;
    default:
      return state;
  }
};

const banks = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          [action.data.reportId]: action.data.banks,
        });
      }
      return state;
    default:
      return state;
  }
};

const teams = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return Object.assign({}, state, {
          [action.data.reportId]: action.data.teams,
        });
      }
      return state;
    default:
      return state;
  }
};

const teamReports = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORT) {
        return [...action.data.teams];
      }
      return state;
    default:
      return state;
  }
};

const reports = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_REPORTS) {
        return Object.assign({}, state, action.data.reports);
      }
      if (action.model === CREATE_REPORT) {
        return Object.assign({}, state, {
          [action.data.schoolId]: [
            ...(state[action.data.schoolId] || []),
            action.data.report,
          ],
        });
      }
      return state;
    default:
      return state;
  }
};

const reportsError = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === CREATE_REPORT) {
        return action.error.response.error.message;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  report,
  athleteTeamReport,
  reports,
  teamReports,
  athletes,
  columns,
  banks,
  teams,
  reportsError,
});

export default reducer;
