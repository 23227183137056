import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({
  className,
  style,
  children,
  fixedHeaders,
}) => (
  <div
    className={[
      'LeaderboardTableHeader',
      className || '',
      fixedHeaders ? 'fixedHeaders' : '',
    ].join(' ')}
    style={style || {}}
  >
    {children}
  </div>
);
TableHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  fixedHeaders: PropTypes.bool,
};

export default TableHeader;
