import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import {
  epics as prospectSheetEpic,
  reducers as prospectSheet
} from './prospectSheet';

export const prospectSheetEpics = combineEpics(
  prospectSheetEpic,
);

export default combineReducers({
  prospectSheet,
});


export * from './prospectSheet/prospectSheet.actions';
