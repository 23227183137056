import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RosterViewOptions } from '../../+store';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import { rosterView } from '../../+store/coachWorld';

class ToggleView extends PureComponent {
    static propTypes = {
      viewSelection: PropTypes.string.isRequired,
      changeViewSelection: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.shouldUpdate();
    }

    componentDidUpdate() {
      const { viewSelection, changeViewSelection } = this.props;
      if (window.innerWidth < 768 && viewSelection === 'card') {
        changeViewSelection('list');
      }
      this.shouldUpdate();
    }

    shouldUpdate = () => {}

    switchToCardView = () => {
      const {changeViewSelection, updateCardView, isLoggedIn, history, location} = this.props;
      changeViewSelection('card');
      if(isLoggedIn){
        updateCardView('card')
      }
      rosterView(location, history, RosterViewOptions.card);
    }
    switchToListView = () => {
      const {changeViewSelection, updateCardView, isLoggedIn, history, location} = this.props;
      changeViewSelection('list');
      if (isLoggedIn) {
        updateCardView('list')
      }
      rosterView(location, history, RosterViewOptions.list);
    }

    render() {
      const { viewSelection} = this.props;
      if (viewSelection === RosterViewOptions.card) {
        return (
          <div className="ToggleView">
            <button className="ToggleView_Card ToggleView_Card_Active">
              <Icon iconName="card-view" />
            </button>
            <button className="ToggleView_List" onClick={this.switchToListView}>
              <Icon iconName="list-view2" />
            </button>
          </div>
        );
      }
      return (
        <div className="ToggleView">
          <button className="ToggleView_Card" onClick={this.switchToCardView}>
            <Icon iconName="card-view" />
          </button>
          <button className="ToggleView_List ToggleView_List_Active">
            <Icon iconName="list-view2" />
          </button>
        </div>
      );
    }
}

export default ToggleView;
