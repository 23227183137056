import { combineReducers } from 'redux';
import pathToRegexp from 'path-to-regexp';
import {
  REDIRECT,
  REDIRECT_URL,
  ROUTE_CHANGED,
  Routes,
  SportIds,
  Sports,
  UPDATE_SPORT_ID,
  UPDATE_SCHOOL_ID,
} from '../../../actions/ui/routes';
import { FORM_SERVER_ERROR, FORM_SUBMIT_SUCCESS } from '../../../actions/ui/formUtils';
import { AUTH2_LOGOUT_SUC, AUTH2_LOGOUT_SUC_WITH_REDIRECT } from '../../../actions/data/cognito';
import { ASYNC_FINISH } from '../../../actions/ui/async';
import { SIGN_OUT } from '../../../actions/ui/auth';
import { validateEmail, validatePhone } from '../../../actions/ui/formUtils/validators';
import { CREATE_AWARD, DELETE_AWARD, UPDATE_AWARD } from '../../../actions/ui/uxProfile/awards';
import {DELETE_PHOTO, DELETE_VIDEO, UPDATE_PHOTO, UPDATE_VIDEO} from '../../../actions/ui/uxProfile/photosVideos';
import { isMobileApp } from '../../../../utils';
import { EP_DELETE_ACCOUNT } from '../../../actions/ui/uxEditProfile/deleteAccount';
import { UPDATE_CURRENT_UUID } from '../../../actions/ui/app';
import { SAVE_SPORT } from '../../../../modules/CodeSignUp/+store/sportsPage/sportsPage.actions';
import {UPDATE_ATHLETE_INFO, UPDATE_ATHLETE_TEAM_INFO} from '../../../../modules/CoachWorld/+store';
import { Roles } from '../../../actions/data/user/profile/models';
import { CREATE_REPORT } from '../../../../modules/CoachWorld/+store/report';
import {
  CREATE_COMBINE,
  CREATE_COMBINE_TEMPLATE,
  DELETE_COMBINE_TEMPLATE,
  UPDATE_COMBINE_TEMPLATE,
  CREATE_COMBINE_AWARD,
  UPDATE_CREATE_COMBINE_AND_AWARDS, UPDATE_COMBINE_TEST,
} from '../../../../modules/CoachWorld/+store/combine';
import {
  CREATE_EXTERNAL_PROFILE,
  DELETE_EXTERNAL_PROFILE,
  UPDATE_EXTERNAL_PROFILE
} from "../../../actions/ui/uxProfile/externalProfile";

const encodeEmailToUrl = (email, page) => encodeURI(`/${Routes.welcome}/${page}/${email}`).replace('.', 'aAdDoOtT');
const encodePhoneToUrl = (phone, page) => encodeURI(`/${Routes.welcome}/${page}/${phone.replace(/[ ()-]/g, '')}`);
const encodeUsernameToUrl = (username, page, toRedirectUrl = null) => {
  if (username && validatePhone()(username).phone === '') {
    return encodePhoneToUrl(username, page);
  } else if (username && validateEmail()(username).email === '') {
    if(toRedirectUrl){
      return encodeURI(`${toRedirectUrl}/${username}`.replace('.', 'aAdDoOtT'));
    }
    return encodeEmailToUrl(username, page);
  }
  return `/${Routes.welcome}/${Routes.login}`;
};

// SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
// See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
const redirectTo = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.forgot') {
        return encodeUsernameToUrl(action.data.username, Routes.resetPassword, action.data.toRedirectUrl);
      }
      if (action.model === 'forms.resetPassword') {
        if(action.data.toRedirectUrl){
          return action.data.toRedirectUrl;
        }
        return `/${Routes.welcome}/${Routes.secondSignIn}`;
      }
      if (action.model === 'forms.joinNow') {
        return `/${Routes.welcome}/${Routes.secondSignIn}`;
        // return encodeUsernameToUrl(action.data.username, Routes.confirmAccount);
      }
      if (action.model === 'forms.login') {
        const toRedirectUrl = action.data.state.value.ui.app.routes.toRedirectUrl;
        const sports = action.data.playerProfile.sports.find(sport => sport.defaultSports === 1);
        if (toRedirectUrl) {
          return toRedirectUrl;
        }
        if (action.data.from) {
          return action.data.from.pathname;
        }
        if (action.data.playerProfile && action.data.playerProfile.roleId === Roles.coach) {
          return `/${Routes.coach}/${Routes.dashboard}`;
        }
        return `/${Routes.profile}/${action.data.uuid}/${Routes.player}/${Sports[sports.sportId]}`;
      }
      if (action.model === 'forms.secondSignIn') {
        const uuid = action.data.awsResponse.signInUserSession.idToken.payload.sub;
        return `/${Routes.profile}/${uuid}/${Routes.player}`;
      }
      if (action.model === 'forms.confirmAccount') {
        return `/${Routes.welcome}/${Routes.secondSignIn}`;
      }
      if (action.model === 'forms.changePassword') {
        return `/${Routes.profile}/${action.data.uuid}/${Routes.edit}/${Sports[action.data.sportId]}`;
      }
      //need to update the redirect here
      if(action.model === 'forms.mergeLogin') {
        if(action.data.redirectToEditProfile){
          return `/${Routes.profile}/${action.data.uuid}/edit`;
        }
        return `/${Routes.profile}/${action.data.uuid}/${Routes.player}`;
      }
      return state;
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.login') {
        if (action.error.code === 'UserNotConfirmedException') {
          return encodeUsernameToUrl(action.data.username, Routes.confirmAccount);
        }
      }
      return state;
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
      return action.redirectURL;
    case AUTH2_LOGOUT_SUC:
    case EP_DELETE_ACCOUNT:
      return `/${Routes.welcome}`;
    case SIGN_OUT:
      return `/${Routes.welcome}`;
    case ASYNC_FINISH:
      if (action.model === 'forms.userSport') {
        if ((action.action === 'delete') || (action.action === 'create')) {
          const { profile, schoolId } = action.data;
          const sports = profile.sports;
          const sportId = sports[sports.length - 1].sportId
          return `/${Routes.profile}/${action.data.uuid}/${Routes.edit}${sportId? `/${Sports[sportId]}`:''}${schoolId? `/${schoolId}`: ''}`;
        }
      }

      if (action.model === 'forms.confirmEmailPhone') {
        return `/${Routes.profile}/${action.data.uuid}/${Routes.edit}`;
      }
      if ((action.model === CREATE_AWARD) ||
        (action.model === DELETE_AWARD) ||
        (action.model === UPDATE_AWARD)) {
        const { sportId, schoolId } = action.data;
        return `/${Routes.profile}/${action.data.uuid}/${Routes.awards}${sportId? `/${Sports[sportId]}`: ''}${schoolId? `/${schoolId}`:''}`;
      }
      if ((action.model === DELETE_PHOTO) || (action.model === DELETE_VIDEO)) {
        if (isMobileApp) {
          return Routes.photos;
        }
        const { sportId, schoolId }= action.data;
        return `/${Routes.profile}/${action.data.uuid}/${Routes.photos}${sportId? `/${Sports[sportId]}`: ''}${schoolId? `/${schoolId}`:''}`;
      }
      // if (action.model === CREATE_ACCOUNT) {
      //   return `/${Routes.code}/${action.data.code}/${Routes.mySports}`;
      // }
      if (action.model === UPDATE_ATHLETE_TEAM_INFO && !action.data.newTeamName) {
        if (action.action.location && action.action.location.params) {
          return `/${Routes.school}/${action.action.match.params.schoolId}/${Routes.roster}/${action.action.match.params.sport}${action.action.location.params}`;
        }
        if (action.action.match && action.action.match.params) {
          return `/${Routes.school}/${action.action.match.params.schoolId}/${Routes.roster}/${action.action.match.params.sport}`;
        }
        return `/${Routes.profile}/${action.data.userId}/${Routes.player}`;
      }

      if (action.model === UPDATE_ATHLETE_INFO){
        //return `/${Routes.school}/${action.data.schoolId}/${Routes.roster}/${action.action.match.params.sport}`;
      }
      // if (action.model === SAVE_PHYSIQUE) {
      //   return `/${Routes.code}/${action.data.code}/${Routes.mySports}`;
      // }
      // VCA-834
      // if (action.model === SIGN_IN) {
      //   return `/${Routes.code}/${action.data.code}/${Routes.mySports}`;
      // }
      if (action.model === SAVE_SPORT) {
        if (action.data.roleId === Roles.coach) {
          return `/${Routes.coach}/${Routes.dashboard}/${Routes.rosters}`;
        } else if (action.data.roleId === Roles.athlete) {
          return `/${Routes.profile}/${action.data.uuid}/${Routes.player}`;
        }
      }
      if (action.model === CREATE_REPORT) {
        return `/${Routes.school}/${action.data.schoolId}/${Routes.report}/${action.data.report.id}`;
      }
      if(action.model === CREATE_COMBINE){
        return `/${Routes.school}/${action.data.combine.schoolId}/${Routes.combine}/${action.data.combine.id}`
      }
      if ((action.model === CREATE_COMBINE_TEMPLATE)
        || (action.model === UPDATE_COMBINE_TEMPLATE)
        || (action.model === DELETE_COMBINE_TEMPLATE)) {
        if (action.data.redirectToCombine) {
          return `/${Routes.coach}/${Routes.dashboard}/${action.data.schoolId}/${Routes.combines}`;
        }
        if ((action.data.combineId && action.data.redirectToCombineDataEntry)) {
          return `/${Routes.school}/${action.data.schoolId}/${Routes.combine}/${action.data.combineId}`;
        }
        return `/${Routes.coach}/${Routes.dashboard}/${action.data.schoolId}/${Routes.combineTemplate}`;
      }

      if(action.model === UPDATE_COMBINE_TEST){
        return `/${Routes.school}/${action.data.schoolId}/${Routes.combine}/${action.data.combineId}`;
      }
      if ((action.model === CREATE_EXTERNAL_PROFILE)
        || ( action.model === DELETE_EXTERNAL_PROFILE)
        || ( action.model === UPDATE_EXTERNAL_PROFILE)
        )
      {
        return `/${Routes.profile}/${action.data.uuid}/${Routes.externalProfile}/${Sports[action.data.sportId]}${action.data.schoolId ? `/${action.data.schoolId}`: ''}`;
      }
      if(action.model === CREATE_COMBINE_AWARD){
        return `/${Routes.coach}/${Routes.dashboard}/${action.data.schoolId}/${Routes.awards}/${Routes.combine}/${action.data.combineId}`;
      }
      if(action.model === UPDATE_CREATE_COMBINE_AND_AWARDS){
        return `/${Routes.coach}/${Routes.dashboard}/${action.data.schoolId}/${Routes.awards}/${Routes.combine}/${action.data.combine.id}`;
      }

      if(action.model === UPDATE_VIDEO || action.model === UPDATE_PHOTO){
        return `/${Routes.profile}/${action.data.uuid}/${Routes.photos}/${Sports[action.data.sport]}`;
      }

      return state;
    case REDIRECT:
      return '';
    default:
      return state;
  }
};

const toRedirectUrl = (state = '', action) => {
  switch (action.type) {
    case REDIRECT_URL:
      return action.url.url;
    default:
      return state;
  }
};

const currentRoute = (state = '', action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
      // return action.pathname;
    default:
      return state;
  }
};

const currentQuery = (state = '', action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
      return action.query || '';
    default:
      return state;
  }
};

const getSportFromUrl = (pathname) => {
  let sportId,params;
  const re = pathToRegexp(`/${Routes.profile}/:uuid/:page/:sport?`);
  params = re.exec(pathname);
  if(!params){
    const regex =pathToRegexp(`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`);
    params = regex.exec(pathname);
  }
  if (params) {
    if (Object.values(Sports).indexOf(params[3]) >= 0) {
      sportId = SportIds[params[3]];
      return sportId;
    }
  }
  return 0;
};

const currentSportId = (state = 0, action) => {
  let sportId;
  switch (action.type) {
    case ROUTE_CHANGED:
      sportId = getSportFromUrl(action.pathname);
      if (sportId) {
        return sportId;
      }
      return state;
    case UPDATE_SPORT_ID:
      return action.sportId;
    default:
      return state;
  }
};

const currentSchoolId = ( state="", action) =>{
  switch(action.type){
    case UPDATE_SCHOOL_ID:
      return action.schoolId;
    default:
      return state;
  }
}

const currentUuid = (state = '', action) => {
  switch (action.type) {
    case UPDATE_CURRENT_UUID:
      return action.uuid || '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  redirectTo,
  currentRoute,
  currentQuery,
  currentSportId,
  currentSchoolId,
  currentUuid,
  toRedirectUrl,
});

export default reducer;
