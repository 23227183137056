import {ASYNC_FINISH} from '../../../../store/actions/ui/async';
import {
  GET_TEST_CATEGORIES,
  GET_COMBINE_TEMPLATES,
  GET_COMBINE_TEMPLATES_BY_SCHOOL, GET_COMBINE_TEMPLATES_BY_COMBINE, GET_COMBINE_STANDARD_TEST_CATEGORIES,
} from './combine.actions';
import {
  CREATE_COMBINE_TEMPLATE,
  DELETE_COMBINE_TEMPLATE,
  UPDATE_COMBINE_TEMPLATE,
  TOGGLE_DELETE_MODAL,
  CLEAR_DELETE_MODAL, GET_COMBINE_TEMPLATE_COMBINES, UPDATE_COMBINE_TEST,
} from './combineTemplates.actions';
// import { CombineTemplate } from './combineTemplates.models';


const standardTestCats = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_TEST_CATEGORIES) {
        return action.data || {};
      }
      return state;
    default:
      return state;
  }
};
``

const combineTemplates = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if ([GET_COMBINE_TEMPLATES, GET_COMBINE_TEMPLATES_BY_SCHOOL].includes(action.model)) {
        return action.data.templates || state;
      } else if (action.model === CREATE_COMBINE_TEMPLATE) {
        for (const [key, value] of Object.entries(state)) {
          if (key === action.data.schoolId) {
            return {...state, [action.data.schoolId]: [...value, action.data.combineTemplate]}
          }
        }
        return {...state};
      } else if (action.model === UPDATE_COMBINE_TEMPLATE) {
        for (const [key, value] of Object.entries(state)) {
          if (key === action.data.schoolId) {
            return {
              ...state,
              [action.data.schoolId]: [...value.filter(x => x.id !== action.data.combineTemplate.id), action.data.combineTemplate]
            }
          }
        }
        return {...state};
      } else if (action.model === DELETE_COMBINE_TEMPLATE) {
        for (const [key, value] of Object.entries(state)) {
          if (key === action.data.schoolId) {
            return {...state, [action.data.schoolId]: [...value.filter(x => x.id !== action.data.combineTemplateId)]}
          }
        }
        // return {...state};
        // return [...state.filter(x => x.id !== action.data.combineTemplateId)];
      }
      return state;
    default:
      return state;
  }
};

const combineTemplate = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_STANDARD_TEST_CATEGORIES) {
        return action.data.template || state;
      }
      if(action.model === UPDATE_COMBINE_TEST){
        return {};
      }
      return {...state};
    default:
      return state;
  }
}

const openDeleteModal = (state = false, action) => {
  if (action.type === TOGGLE_DELETE_MODAL) {
    return !state;
  } else if (action.type === CLEAR_DELETE_MODAL) {
    return false;
  }
  return state;
};

const combineTemplateCombines = (state = [], action) => {
  if (action.type === ASYNC_FINISH) {
    if (action.model === GET_COMBINE_TEMPLATE_COMBINES) {
      return action.data || [];
    }
  }
  return state;
}

export default {
  standardTestCats,
  combineTemplates,
  combineTemplateCombines,
  openDeleteModal,
  combineTemplate,
};
