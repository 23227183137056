import { Routes } from '../../../../store/actions/ui/routes';
import { Roles } from '../../../../store/actions/data/user/profile/models';
import { vcScoreColumns } from '../../../CoachWorld/+store/combine/combine.models';

// SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
// See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F

export const getRole = (code, schoolInfo) => {
  switch (code.toUpperCase()) {
    case schoolInfo.admin_code:
      return 'admin';
    case schoolInfo.coach_code:
      return 'coach';
    case schoolInfo.athlete_code:
      return 'athlete';
    default:
      return 'participant';
  }
};

export const redirectCheck = (uuid, profile, match, schoolInfo) => {
  const missingFields = [];
  const { code } = match.params;
  if (uuid && profile) {
    if (schoolInfo && getRole(code.toUpperCase(), schoolInfo) === 'coach') {
      if (match.params.page !== Routes.mySports) {
        return `/${Routes.code}/${code}/${Routes.mySports}`;
      }
      return '';
    }
    // if profile loading show loading
    // Todo: check users profile for necessary fields
    if (!(profile.heightFt || profile.heightInch)) missingFields.push('height');
    if (!profile.weight) missingFields.push('weight');
    if (!profile.gender) missingFields.push('gender');
    if (!profile.grade) missingFields.push('grade');
    if (!missingFields.length) {
      if (match.params.page !== Routes.mySports) {
        return `/${Routes.code}/${code}/${Routes.mySports}`;
      }
      return '';
    } else if (match.params.page !== Routes.physique) {
      return `/${Routes.code}/${code}/${Routes.physique}`;
    }
  } else if ((match.params.page !== Routes.signup)
    && (match.params.page !== Routes.login)) {
    return `/${Routes.code}/${code}/${Routes.signup}`;
  }
  return '';
};

export const getRoleId = (code, schoolInfo) => {
  switch (code.toLowerCase()) {
    case schoolInfo.admin_code.toLowerCase():
      return Roles.parent;
    case schoolInfo.coach_code.toLowerCase():
      return Roles.coach;
    case schoolInfo.athlete_code.toLowerCase():
      return Roles.athlete;
    default:
      return Roles.guest;
  }
};

export const toProperCase = s => s.replace(/\w[^ \t\n-]*/g, txt => (
  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
));

const getDifference = (oldVal, newVal, type, best) => {
  if (best && best === 'min') {
    return oldVal - newVal;
  } else if (best && best === 'max') {
    return newVal - oldVal;
  } else {
    return -1;
  }
};

const getOldValAndNewVal = (item, testResId, tempObject, column, attr) => {
  let oldVal = null;
  let newVal = null;
  let difference = null;
  if (item.isPrev) {
    if (item.testResults[testResId]) {
      oldVal = item.testResults[testResId][attr] || null;
    }
    newVal = (tempObject && tempObject.testResults[testResId][attr]) || null;
  } else if (tempObject && tempObject.testResults[testResId]) {
    oldVal = tempObject.testResults[testResId][attr] || null;
  } else {
    newVal = (item.testResults[testResId] && item.testResults[testResId][attr]) || null;
  }
  if ((oldVal !== null)
    && (newVal !== null)
    && !Number.isNaN(Number(oldVal))
    && !Number.isNaN(Number(newVal))) {
    difference = getDifference(
      oldVal,
      newVal,
      column.standardUnitOfMeasureCode,
      column.best,
    );
  }
  return { oldVal, newVal, difference};
};

export const createCombineTestObject = (
  athleteCombineResults,
  columns,
) => [...columns, ...vcScoreColumns].map((column) => {
  const testResId = column.standardTestObjectId;
  const resultAthleteCombineResults = [];
  athleteCombineResults.forEach((item) => {
    if (!resultAthleteCombineResults.find(r => r.athleteId === item.athleteId)) {
      const tempObject = athleteCombineResults
        .find(athleteRes => athleteRes.isPrev === !item.isPrev
          && athleteRes.testResults[column.standardTestObjectId]
          && athleteRes.athleteId === item.athleteId);
      const { oldVal, newVal, difference } = getOldValAndNewVal(
        item,
        testResId,
        tempObject,
        column,
        'result',
      );
      const relative = getOldValAndNewVal(
        item,
        testResId,
        tempObject,
        column,
        'relativeStrength',
      );
      const rated = getOldValAndNewVal(
        item,
        testResId,
        tempObject,
        column,
        'ratedResult',
      );
      if (oldVal || newVal) {
        resultAthleteCombineResults.push({
          athleteId: item.athleteId,
          oldValue: oldVal,
          oldRelativeStrength: relative.oldVal,
          oldRatedResult: rated.oldVal,
          newValue: newVal,
          newRelativeStrength: relative.newVal,
          newRatedResult: rated.newVal,
          difference,
          diffRelativeStrength: relative.difference,
          diffRatedResult: rated.difference,
          calculateForMin: (column.best && column.best === 'min') || false,
        });
      }
    }
  });
  return {
    isVcTest: column.isVcTest || false,
    stdTestObjectId: column.standardTestObjectId,
    testCategoryName: column.testCategoryName,
    standardUnitOfMeasureCode: column.standardUnitOfMeasureCode,
    standardFormatCode: column.standardFormat.code,
    athletesResults: resultAthleteCombineResults,
  };
}).filter(item => item);

// const createAwards = (results, columns) => {
//   let mostImproved = {};
//   let best = {};
//   const bestResArr = [];
//   const mostImprovedResArr = [];
//   columns.forEach((column) => {
//     const clmBasedResults = results
//       .filter(result => result.stdTestObjectId === column.standardTestObjectId);
//     const mostImprovedArr = clmBasedResults.filter(clm => clm.oldValue);
//     const bestArr = clmBasedResults;

//     mostImproved = column.standardUnitOfMeasureCode === 'SECONDS' ?
//       mostImprovedArr
//         .reduce((min, shot) => (min && min.difference < shot.difference ? min : shot), null) :
//       mostImprovedArr
//         .reduce((max, shot) => (max && max.difference > shot.difference ? max : shot), null);

//     best = column.standardUnitOfMeasureCode === 'SECONDS' ?
//       bestArr
//         .reduce((min, shot) => (min && min.newValue < shot.newValue ? min : shot), null) :
//       bestArr
//         .reduce((max, shot) => (max && max.newValue > shot.newValue ? max : shot), null);
//     if (best) {
//       best.awardTitle = `Best in ${best.testCategoryName}`;
//       bestResArr.push(best);
//     }
//     if (mostImproved) {
//       mostImproved.awardTitle = `Most Improved in ${mostImproved.testCategoryName}`;
//       mostImprovedResArr.push(mostImproved);
//     }
//   });
//   return [...bestResArr, ...mostImprovedResArr];
// };
export const getAlternativeRoleOpt = (role) => {
  switch (role) {
    case 'athlete':
      return 'Coach';
    case 'coach':
      return 'Athlete';
    case 'admin':
      return 'Coach/Athlete'
    default:
      return 'Athlete';
  }
}
export default 'codeSignUp/Common/utils.js';
