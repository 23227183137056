import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import coachAward from '../../../../../assets/images/CoachAward.png';
import { ButtonRect } from '../../../../../components/UxCommon';
import { awardsDialogTo } from '../../../../../store/actions/ui/routes';
import { getAwardLogo } from '../../../+store/award';
import RingAwardLogo from '../../../../../assets/images/ring.png';


class AwardRow extends PureComponent {
  static propTypes = {
    award: PropTypes.object,
    openShareDialog: PropTypes.func,
  }

  copySocialLink = () => {
    const { openShareDialog, award ,sportId,schoolId} = this.props;
    const uri = awardsDialogTo(award.owner_id, award.id,sportId,schoolId);
    openShareDialog(uri);
  }

  render() {
    const { award , schoolAwardLogo, schoolStyle } = this.props;
    let combineSchoolLogo;
    if (!!award) {
    combineSchoolLogo =  schoolStyle.find((item) => item.id === award.combine.school_uuid);
    }
    let imgSrc = getAwardLogo({},award, true);
    if (!imgSrc) {
      imgSrc = combineSchoolLogo.schoolLogo.award_logo;
    }

    // if (! award.is_vc_award &&  schoolAwardLogo)
    // {
    //   awardImgSrc = schoolAwardLogo.combine_award_logo
    // }
    return (
      <div className="AwardRow">
        <div className="Combine_Title_Row_Awards_Image">
          { !!imgSrc ?
            <img height={85} width={85} src={imgSrc} alt="award-img" />
            :
            <div className='generic_test_award_logo'>
              <img height={85} width={85} src={RingAwardLogo} alt="award-img" />
              <img className={'school_award_logo'} height={60} width={60} src={combineSchoolLogo && combineSchoolLogo.school_logo} alt="award-img" />
            </div>
          }
        </div>
        <div className="Combine_Title_Row_Awards_Title">
          {award.name}
        </div>
        <div className="Combine_Title_Row_Awards_Title_Detail">
          {award.description.replace( /\s-\s?/i , '  |  ')}
        </div>
        <div className="Combine_Title_Row_Awards_Date">
          {`Awarded ${moment(award.created_at).format('MM/DD/YY')}`}
        </div>
        <ButtonRect className="AwardRow_Button" onClick={this.copySocialLink}>Social Link</ButtonRect>
      </div>
    );
  }
}

export default AwardRow;
