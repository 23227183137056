/* eslint-dis able quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import AthleteCard from '../AthleteCard';
import CoachCard from '../CoachCard';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import CollapsableButtom from '../../common/CollapsableButton';
import TwoTextButton from '../../common/TwoTextButton';
import {RosterViewOptions} from '../../+store';
import {expandedEditPlayer, expandedRosterGrid} from '../../../../store/actions/ui/routes';
import TwoToneButton from "../../common/TwoToneButton";
import {isMobile} from "../../Combine/Combine.component";
import ConfirmDialog from "../../common/ConfirmDialog";
import ErrorDialog from "../../common/ErrorDialog";
import LoadingIndicator from "../../common/LoadingIndicator";

class TeamBox extends PureComponent {
  static propTypes = {
    athletes: PropTypes.array.isRequired,
    coaches: PropTypes.array.isRequired,
    teamName: PropTypes.string.isRequired,
    profilesLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
    viewSelection: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    canEdit: PropTypes.bool.isRequired,
    updateTeamName: PropTypes.func.isRequired,
    coachSchoolIds: PropTypes.any.isRequired,
    userProfileId: PropTypes.string,
    setEmptyFlag: PropTypes.func,
    saveSchedule: PropTypes.func,
    deleteSchedule: PropTypes.func,
    schedule: PropTypes.any,
    loadingSchedule: PropTypes.bool.isRequired,
  }

  state = {
    collapsed: false,
    isEditing: false,
    editValue: this.props.teamName,
    coachCollapsed: true,
    athleteCollapsed: false,
    showGrid: false,
    errorMsg: '',
    showConfirmDialog: false,
    showErrorDialog: false,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onCollapseClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onCoachCollapseClick = () => {
    this.setState({
      athleteCollapsed: true,
      coachCollapsed: false,
    });
  }

  onAthleteCollapseClick = () => {
    this.setState({
      athleteCollapsed: false,
      coachCollapsed: true,
    });
  }

  onShowGrid = () => {
    this.setState({ showGrid: !this.state.showGrid });
  }

  onEditAthleteClick = (athlete) => {
    const { history, team } = this.props;
    history.push({
      pathname: expandedEditPlayer(team.schoolId, athlete.id, team.sportId),
      params: history.location.search,
      search: history.location.search,
    });
  }

  onInputChange = (e) => {
    this.setState({
      editValue: e.target.value,
    });
  }

  onInputFocus = () => {
    this.setState({
      isEditing: true,
    });
  }

  onInputBlur = () => {
    const { updateTeamName, team, teamName } = this.props;
    const { editValue } = this.state;
    this.setState({
      isEditing: false,
    });
    if (editValue !== teamName) {
      const values = {};
      values.school_team_id = team.id;
      values.name = editValue;
      values.team_reference_id = team.teamRefId;
      values.sport_id = team.sportId;
      updateTeamName(values);
    }
  }

  onButtonFocus = () => {
    if (this.canEditTeamName) {
      this.setState({
        isEditing: true,
      }, () => {
        this.entryInput.focus();
      });
    }
  }

  onRosterGridClick = () => {
    const {
      history,
      team,
    } = this.props;
    history.push({
      pathname: expandedRosterGrid(team.schoolId, team.sportId, team.id),
      params: history.location.search,
      search: history.location.search,
    });
  }

  setRef = (input) => {
    this.entryInput = input;
  }

  openFile = () => {
    this.fileInputEl.click();
  }

  setRefs = (ref) => {
    this.fileInputEl = ref;
  };

  fileHandler = (event) => {
    const { saveSchedule, team } = this.props;
    const fileData = event.target.files[0];
    const document = ['application/pdf','pdf' , 'doc' ,'docx', 'xls', 'xlsx'] ;
    const allowedExtension = document.find( doc => fileData.name.split('.').pop() === doc );
    if( fileData ){
      if(!allowedExtension){
        this.setState({ showErrorDialog: true });
        this.setState({ errorMsg: 'Please select PDF, Word Doc and Excel Files' });
      }
      else if( fileData.size > (50 * 1000000) ){
        this.setState({ errorMsg: 'Please select the file less than 50 MB' });
      }
      else{
        this.setState({ errorMsg:''});
        saveSchedule( team.id, fileData);
        this.fileInputEl.value = '';
      }
    }

  }


  canEditTeamName = false;

  shouldUpdate = () => {
    const { coaches, athletes, profilesLoading, setEmptyFlag, team } = this.props;

    if (!profilesLoading) {
      setEmptyFlag(team.id, !coaches.length && !athletes.length);
    }
  }

  openFile = () => {
    this.fileInputEl.click();
  }

  downloadFile = async() => {
    const { schedule } = this.props;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = schedule.url;
    document.body.appendChild(link);
    link.click();
    this.setState({ downloadLoading: '' });
    link.parentNode.removeChild(link);
  }

  onClickDelete = () =>{
    this.setState({ showConfirmDialog : true });
  }

  onClickCancel = () =>{
    this.setState({ showConfirmDialog : false });
  }

  onDeleteSchedule  = () =>{
    const { schedule, deleteSchedule , team } = this.props;
    deleteSchedule(team.id, schedule.id);
    this.setState({ showConfirmDialog : false });
  }

  renderSchedule = () =>{
    const { schedule , canEdit }  = this.props;
    if( schedule && schedule.id ){
      return (
        <React.Fragment>
          <TwoToneButton
            iconName={"download"}
            text={"Schedule"}
            onClick={this.downloadFile}
            className={"team_schedule team_schedule_tone_button"}
          />
          { !!canEdit && (
            <Icon
              iconName='delete'
              className='delete_schedule_button'
              onClick={this.onClickDelete}
              tooltip = {isMobile.any() ? '' : 'Delete Schedule' }
            />
            )
          }

        </React.Fragment>
      )
    }else if (canEdit) {
      return (
        <React.Fragment>
          <TwoToneButton
            iconName={"upload"}
            text={"Schedule"}
            onClick={this.openFile}
            className={"team_schedule team_schedule_tone_button"}
            data-tip={isMobile.any() ? '' : "Upload PDF, Word Doc and Excel Files"}
            data-place={"bottom"}
            data-background-color="white"
            data-text-color="black"
          />
          <input
            name='pdf_uploader'
            style={{display: 'none'}}
            className='pdfInputUploader'
            type='file'
            ref={this.setRefs}
            onChange={this.fileHandler}
            accept=".pdf, .doc, .docx, .xls, .xlsx"
          />
        </React.Fragment>
      )
    }
  }


  render() {
    const {
      athletes,
      coaches,
      teamName,
      profilesLoading,
      match,
      viewSelection,
      history,
      canEdit,
      coachSchoolIds,
      team,
      userProfileId,
      location,
      schoolStyle,
      schedule,
      loadingSchedule
    } = this.props;
    const {
      collapsed,
      isEditing,
      editValue,
      coachCollapsed,
      athleteCollapsed,
      showGrid,
      showConfirmDialog,
      showErrorDialog
    } = this.state;

    let found = false;
    if (coachSchoolIds && coachSchoolIds.length > 0) {
      found = coachSchoolIds.find(s => s === team.schoolId);
    }
    if (found) {
      this.canEditTeamName = true;
    }

    let isCardView = true;
    if (viewSelection === RosterViewOptions.list) {
      isCardView = false;
    }
    if (!coaches.length && !athletes.length && !profilesLoading) {
      return null;
    }
    const isMetricUnit = team.school ? team.school.isMetricSchool : false;

    return (
    <div className={`CoachTeamBox ${collapsed ? 'collapsed' : ''}`}>
      <LoadingIndicator
          className = "schedule_loader"
          loading={loadingSchedule}
          loadingMsg={'Please wait...'}
          wrapperStyle={{ top: 120 }}
      />
      {
          showConfirmDialog &&
          <ConfirmDialog
              title= "Delete Schedule?"
              msg= { "Are you sure you want to delete schedule?"}
              onArchiveClick= { this.onDeleteSchedule }
              onCancelClick = { this.onClickCancel }
              wrapperClass = 'TeamBoxDialog'
          />
      }
      {
        showErrorDialog &&
        <ErrorDialog
          msg= { "CHOOSE ANOTHER FILE FORMAT Please upload either a PDF, Word, or Excel file."}
          onCancelClick = { () => { this.setState({ showErrorDialog : false }) } }
        />
      }
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="CoachTeamBox_Header">
            {
                isEditing ?
                  <form onSubmit={this.onInputBlur}>
                    <input
                      ref={this.setRef}
                      className="CoachTeamBox_TeamNameInput"
                      value={editValue || ''}
                      onFocus={this.onInputFocus}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                    />
                    <button type="submit" style={{ display: 'none' }} />
                  </form>
                  :
                  <button
                    className="CoachTeamBox_TeamName"
                    onFocus={this.onButtonFocus}
                  >
                    {teamName}
                  </button>
              }
            <CollapsableButtom onClick={this.onCollapseClick} collapsed={collapsed} />
          </div>
          {
            !collapsed && (
              <div className='CoachTeamBox_Buttons' style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="CoachTeamBox_RadioButton CoachTeamBox_RadioButtonMobile" style={{ justifyContent: 'flex-start' }}>
                  <TwoTextButton
                    leftText="Athletes"
                    rightText="Coaches"
                    onLeftClick={this.onAthleteCollapseClick}
                    onRightClick={this.onCoachCollapseClick}
                  />
                  { this.renderSchedule() }
                </div>
                <div
                  style={{
                   display: 'flex', margin: 0, marginLeft: 2, justifyContent: 'flex-end', width: '100%',
                  }}
                >
                  <button disabled={!canEdit} onClick={this.onRosterGridClick} className="CoachTeamBox_IconButton">
                    <div style={{ marginRight: 3, display: 'flex', flexDirection: 'row' }}>
                      <Icon iconName="edit" />
                      <div style={{ marginLeft: 2 }}>Manage Roster</div>
                    </div>
                  </button>
                </div>
              </div>
            )
          }
        </div>
        {
          !collapsed && (
            <div className="CoachTeamBox_Body">
              {/* <div
                className="CoachTeamBox_Header InsideHeader"
                style={{ marginBottom: coachCollapsed ? 0 : 30 }}
              >
                Coaches
                <CollapsableButtom onClick={this.onCoachCollapseClick} collapsed={coachCollapsed} />
              </div> */}
              <div>
                {
                  !coachCollapsed && (
                    <div className={`CoachTeamBox_List ${isCardView ? 'card' : 'list'}`}>
                      {
                        coaches.map(coach => (
                          <CoachCard
                            key={coach.id}
                            coach={coach}
                          />
                        ))
                      }
                    </div>
                  )
                }
              </div>
              {/* {
                athletes.length ?
                  <div
                    className="CoachTeamBox_Header InsideHeader"
                    style={{ marginBottom: athleteCollapsed ? 0 : 30 }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                      <div>Athletes</div>
                      <div>
                        <button className="GridView_EditButton" onClick={this.onRosterGridClick}>
                          <Icon iconName="edit" />
                        </button>
                      </div>
                    </div>
                    <CollapsableButtom
                      onClick={this.onAthleteCollapseClick}
                      collapsed={athleteCollapsed}
                    />
                  </div>
                  :
                  <div />
              } */}
              <div>
                <div className={`CoachTeamBox_List ${isCardView ? 'card' : 'list'}`}>
                  {
                    !athleteCollapsed && athletes.map(athlete => (
                      <AthleteCard
                        history={history}
                        match={match}
                        key={athlete.id}
                        teamId={team.id}
                        isPrivateTeam = {team.isPrivate}
                        athlete={athlete}
                        isMetricUnit={isMetricUnit}
                        onEditClick={this.onEditAthleteClick}
                        schoolId={team.schoolId}
                        canEdit={canEdit || userProfileId === athlete.userId}
                        schoolStyle = {schoolStyle}
                      />
                    ))
                  }
                </div>
              </div>
              {
                !coaches.length && !athletes.length && profilesLoading && (
                  <div className="CoachTeamBox_NoResults">
                    Loading profiles ...
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

export default TeamBox;
