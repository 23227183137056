import { combineReducers } from 'redux';

import {
  SIDEBAR_SHOW_TAG_DIALOG,
  SIDEBAR_HIDE_TAG_DIALOG,
  ADD_TAG,
  MULTI_DELETE_EXECUTE,
  SAVE_TAGS,
  ADD_TAG_TO_PHOTO,
  DELETE_TAG,
} from '../../../../actions/ui/uxProfile/photosVideos';
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR,
} from '../../../../actions/ui/async';
import { MULTI_UPDATE_TAGS_SUC } from '../../../../actions/data/photo';

const isShowing = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_SHOW_TAG_DIALOG:
      return true;
    case SIDEBAR_HIDE_TAG_DIALOG:
      return false;
    default:
      return state;
  }
};

const updatedTags = (state = [], action) => {
  switch (action.type) {
    case 'rrf/change':
      if (
        action.model.indexOf('forms.tagDialog.newTagName.') > -1 &&
        action.value !== '' &&
        action.currentValue
      ) {
        const spltArr = action.model.split('.');
        if (spltArr && spltArr.length > 0 && spltArr[spltArr.length - 1] !== 'new') {
          return [
            ...state.filter(tag => tag.id !== spltArr[spltArr.length - 1]),
            {
              id: spltArr[spltArr.length - 1],
              val: action.value,
            },
          ];
        }
      }
      return state;
    case MULTI_UPDATE_TAGS_SUC:
      return [];
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === ADD_TAG ||
        action.model === SAVE_TAGS ||
        (action.model === DELETE_TAG)
      ) {
        return action.msg;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (
        action.model === ADD_TAG ||
        action.model === MULTI_DELETE_EXECUTE ||
        action.model === SAVE_TAGS ||
        action.model === ADD_TAG_TO_PHOTO ||
        (action.model === DELETE_TAG)
      ) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isShowing,
  loadingMsg,
  updatedTags,
});

export default reducer;
