import { } from 'redux';
import {ASYNC_ERROR, ASYNC_FINISH, ASYNC_START} from "../../../../actions/ui/async";
import {GET_EXTERNAL_PROFILE} from "../../../../actions/ui/uxProfile/externalProfile";

const loadingMsg = (state = 'Loading external profile ...', action) => {

switch(action.type) {

  case ASYNC_START:
    if ( action.model === GET_EXTERNAL_PROFILE){
      return 'Loading external profile ...'
    }
    return state;
  case ASYNC_ERROR:
  case ASYNC_FINISH:
    if (action.model === GET_EXTERNAL_PROFILE)
    {
      return '';
    }
    return state;
  default:
    return state;
}
}


const reducers = {
  loadingMsg
}
export default reducers;
