import { dispatch } from 'rxjs/internal/observable/pairs';
import { asyncStartAction } from '../../async';

export const PD_GET_DATA = 'playerData.getData.TestResult';
export const PD_GET_TESTS = 'playerData.getTests';
export const playerTestsGet = (uuid, sportId, msg, categoryId = null) => (dispatch) => {
  dispatch({
    type: PD_GET_TESTS,
    sportId,
    uuid,
    categoryId,
    msg,
  });
  dispatch(asyncStartAction(PD_GET_TESTS, msg, { sportId }));
};

export const PD_GET_PERSONAL_RECORDS = 'playerProfile.getPersonalRecords';
export const playerPersonalRecords = (uuid,sport,msg) => (dispatch) =>{
  dispatch(asyncStartAction(PD_GET_PERSONAL_RECORDS, msg));
  dispatch({
    type: PD_GET_PERSONAL_RECORDS,
    sport,
    uuid,
  })
}

export const PD_GET_CAT_STRENGTH_TESTS = 'playerData.getCategoryStrTests';
export const playerCatStrTestsGet = (uuid, categoryId, msg = null, bank = null) => (dispatch) => {
  dispatch({
    type: PD_GET_CAT_STRENGTH_TESTS,
    uuid,
    categoryId,
    msg,
    bank,
  });
  dispatch(asyncStartAction(PD_GET_CAT_STRENGTH_TESTS, msg, { bank }));
};

export const PD_GET_CAT_PERF_TESTS = 'playerData.getCategoryPerfTests';
export const playerCatPerfTestsGet = (uuid, categoryId, msg = null, bank = null) => (dispatch) => {
  dispatch({
    type: PD_GET_CAT_PERF_TESTS,
    uuid,
    categoryId,
    msg,
    bank,
  });
};

export const Banks = {
  perf: 'performance',
  strength: 'strength',
  awards: 'awards',
};

export const TR_SHOW_CRUD_DIALOG = 'testResults.CRUDDialog.show';
export const showCRUDDialog = bank => ({
  type: TR_SHOW_CRUD_DIALOG,
  bank,
});

export const TR_HIDE_CRUD_DIALOG = 'testResults.CRUDDialog.hide';
export const hideCRUDDialog = bank => ({
  type: TR_HIDE_CRUD_DIALOG,
  bank,
});

export const TR_SHOW_CREATE_UPDATE_DIALOG = 'testResults.createUpdateDialog.show';
export const showCreateUpdateDialog = (bank, testResult, testResultIdx = null) => ({
  type: TR_SHOW_CREATE_UPDATE_DIALOG,
  bank,
  testResult,
  testResultIdx,
});

export const TR_SHOW_CREATE_UPDATE_VIDEO_DIALOG = 'testResults.createUpdateVideoDialog.show';
export const showCreateUpdateVideoDialog = (bank, testResult, testResultIdx = null) => ({
  type: TR_SHOW_CREATE_UPDATE_VIDEO_DIALOG,
  bank,
  testResult,
  testResultIdx,
});

export const TR_HIDE_CREATE_UPDATE_DIALOG = 'testResults.createUpdateDialog.hide';
export const hideCreateUpdateDialog = bank => ({
  type: TR_HIDE_CREATE_UPDATE_DIALOG,
  bank,
});
export const TR_SHOW_DELETE_DIALOG = 'testResults.deleteDialog.show';
export const showDeleteTestResultDialog = (bank, testResult = null, testResultIdx = null) => ({
  type: TR_SHOW_DELETE_DIALOG,
  bank,
  testResult,
  testResultIdx,
});
export const TR_HIDE_DELETE_DIALOG = 'testResults.deleteDialog.hide';
export const hideDeleteTestResultDialog = bank => ({
  type: TR_HIDE_DELETE_DIALOG,
  bank,
});

export const perfTestsCreateUpdateFormModel = newFormId =>
  `forms.dynamic.tests.perf.${newFormId}`;
export const perfTestsCreateUpdateFormObject = (state, newFormId) => {
  if (state.forms.forms.dynamic.tests) {
    if (state.forms.forms.dynamic.tests.perf) {
      if (state.forms.forms.dynamic.tests.perf[newFormId]) {
        return state.forms.forms.dynamic.tests.perf[newFormId];
      }
    }
  }
  return {};
};
export const perfTestsCreateUpdateFormDataObject = (state, newFormId) => {
 return state.forms.dynamic.tests.perf[newFormId]};
export const isPerfTestsCreateUpdateFormModel = (model) => {
  const re = /^forms[.]dynamic[.]tests[.]perf[.](\d+)$/;
  return re.test(model);
};
export const isPerfTestsValueCreateUpdateFormModel = (model) => {
  const re = /^forms[.]dynamic[.]tests[.]perf[.](\d+)[.]/;
  return re.test(model);
};

export const strengthTestsCreateUpdateFormModel = newFormId =>
  `forms.dynamic.tests.strength.${newFormId}`;
export const strengthTestsCreateUpdateFormObject = (state, newFormId) => {
  if (state.forms.forms.dynamic.tests) {
    if (state.forms.forms.dynamic.tests.strength) {
      if (state.forms.forms.dynamic.tests.strength[newFormId]) {
        return state.forms.forms.dynamic.tests.strength[newFormId];
      }
    }
  }
  return {};
};
export const strengthTestsCreateUpdateFormDataObject = (state, newFormId) =>
  state.forms.dynamic.tests.strength[newFormId];
export const isStrengthTestsCreateUpdateFormModel = (model) => {
  const re = /^forms[.]dynamic[.]tests[.]strength[.](\d+)$/;
  return re.test(model);
};
export const isStrengthTestsValueCreateUpdateFormModel = (model) => {
  const re = /^forms[.]dynamic[.]tests[.]strength[.](\d+)[.]/;
  return re.test(model);
};

export const PD_CREATE_UPDATE_TESTS = 'playerData.tests.createUpdate';
export const PD_UPDATE_VIDEO_ID = 'playerData.tests.updateVideoId';
export const upadateVideoId = (testResult, videoId,sportId, categoryId,videoProcessed, bank) => (dispatch) => {
  dispatch({
    type: PD_UPDATE_VIDEO_ID,
    testResult,
    videoId,
    sportId,
    categoryId,
    videoProcessed,
    bank
  })
}

export const PD_DELETE_TEST_RESULT = 'playerData.testResult.delete';
export const deleteTestResult = (testResult, msg, bank, sportId, categoryId = null) => (dispatch) => {
  dispatch({
    type: PD_DELETE_TEST_RESULT,
    testResult,
    bank,
    sportId,
    categoryId,
  });
  dispatch(asyncStartAction(PD_DELETE_TEST_RESULT, msg, { bank }));
};
