import React from 'react';
import PropTypes from 'prop-types';

const TableCell = ({
  className,
  style,
  children,
  id,
  onClick = null,
  colSpan,
}) => {
  function handleClick() {
    if (onClick && id) {
      onClick(id);
    }
  }
  return (
    <div
      className={['LeaderboardTableCell', className || ''].join(' ')}
      style={style || {}}
      colSpan={colSpan || 1}
    >
      {onClick ? <button className="LeaderboardTableCellButton" onClick={handleClick}>{children}</button> : children}
    </div>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.any,
  colSpan: PropTypes.number,
};

export default TableCell;
