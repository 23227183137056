
import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { positions } from '../../../../../components/UxCommon/Form/utils';
import RosterSelectControl from '../../../common/FormControls/SelectControl/RosterSelectControl';

const PositionCellComponentEditor = forwardRef((props, ref) => {
  const [pos, setPos] = useState(props.value);
  const {data, sportId} = props;

  useImperativeHandle(ref, () => {
      return {
          getValue() {
              return pos;
          },

          isPopup() {
              return true;
          },

          isCancelBeforeStart() {
            return false;
        },
      };
  });

  let sportPositionObject={};
  const sportPositions = positions(sportId, data.gender);
  sportPositions.forEach((sport) => {
    const { value, name } = sport;
    sportPositionObject = {
    ...sportPositionObject,
    [value]: name,
    };
  });

  const onValueChange =() => (value) =>{
    if(value.length <= 1 && (value[0] === ""|| value[0] === null || value[0] === undefined)){
      value.push(props.value)
    }
    if(value.length > 1 && value[0] === "" ){
      value.splice(0,1);
    }
    setPos(value.join(","));
  }

  return (
      <RosterSelectControl
        name="positions"
        label="Player Position"
        placeholder="Player Position"
        className="CoachWorld_RosterPosition_Ctrl"
        options={sportPositionObject}
        myOnChange={onValueChange()}
        defaultValue={data.position ? data.position.replace(/\s/g, '').split(',') : ''}
        multi={true}
      /> 
  );
});

export default PositionCellComponentEditor;
