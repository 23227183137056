import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import HelpPopper from '../../../../components/UxCommon/Form/HelpPopper/HelpPopper';
import LoadingIndicatorCard
  from '../../Dashboard/Combines/CombineControl/LoadingIndicatorCard/LoadingIndicatorCard.component';
import { isIOS, isAndroid } from '../../../../containers/UxCommon/Utils';
import { CustomVideoIcon } from '../../../../components/UxCommon';
import { fromEvent } from 'rxjs';
import { Link } from 'react-router-dom';
import { expandedCombineDialogPathTo } from '../../../../store/actions/ui/routes';
class CombineRepsEntry extends PureComponent {
  static propTypes = {
    value: PropTypes.any,
    onSave: PropTypes.func.isRequired,
    formatToDisplay: PropTypes.func.isRequired,
    formatToEdit: PropTypes.func.isRequired,
    help: PropTypes.func.isRequired,
    allowedCharTest: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    validateReps: PropTypes.func.isRequired,
    canNotEditReason: PropTypes.string.isRequired,
    isCoach: PropTypes.bool.isRequired,
    setExpiringCombineMsg: PropTypes.func.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    onEnterPressed: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    isAthleteCoachedByUser: PropTypes.bool,
    isCombineOpen : PropTypes.bool,
    setIsSelected: PropTypes.func.isRequired,
  };

  defaultInputAttrs = {
    type: "number",
    step: "0.01",
    min: "0",
    lang:"en"
  };

  state = {
    isEditing: false,
    editResult: '',
    editReps: '',
    hasFocus: false,
    error: '',
    customInputAttrs: this.defaultInputAttrs,
    willSave: false,
    currentTimeCount: 0,
  };

  componentDidMount = () => {
    document.addEventListener(this.isTouchDevice() ? 'touchstart' : 'mousedown', this.handleClickOutside);
    let eventName = 'click';
    if (this.isTouchDevice()) {
      eventName = 'touchstart';
    }
    this.windowClickSubscription = fromEvent(window, eventName)
      .subscribe((e) => {
        const { willSave } = this.state;
        if (e.target !== this.formRef.current && willSave) {
          const { onSave, validate, value, setOverFlowClassToTable} = this.props;
          const { editResult, editReps} = this.state;
          const editRepsDef = editReps || 1;
          const videoId = (!!value && value.videoId) || '';
          this.setState({
            isEditing: false,
            hasFocus: false,
            willSave: false,
            customInputAttrs: this.defaultInputAttrs,
          });
          setOverFlowClassToTable(false);
          if (!validate(editResult)) {
            onSave({result: editResult, reps: editRepsDef,videoId}, {result: value && value.result || value, videoId: value && value.videoId || null});
          }
        }
      });
  };

  componentWillUnmount() {
    if (this.windowClickSubscription) {
      this.windowClickSubscription.unsubscribe();
    }
    document.removeEventListener(this.isTouchDevice() ? 'touchstart' : 'mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { setIsSelected } = this.props;
    if (this.formRef && this.formRef.current && !this.formRef.current.contains(event.target)) {
        setIsSelected(false);
    }
  }

  onEditClick = () => {
  };

  onEditSubmit = (e) => {
    const {
      onEnterPressed,
      rowIndex,
      columnIndex,
      setIsSelected,
      setOverFlowClassToTable,
    } = this.props;
    this.setState({
      isEditing: false,
    });
    setOverFlowClassToTable(false);
    setIsSelected(false);
    onEnterPressed(rowIndex, columnIndex, 'reps');
    e.preventDefault();
  };

  onKeyDownMobile = type => (e) => {
    if ((isAndroid || isIOS) && e.key === 'Enter') {
      if (type === 'value' ) {
        this.repRef.focus();
        this.repRef.select();
      } else if (type === 'rep') {
        this.buttonRef.click();
      }
      e.preventDefault();
      return '';
    }
    this.onEditKeyDown(e);
  };

  onEditKeyDown = (e) => {
    const {
      allowedCharTest,
      setIsSelected,
      setOverFlowClassToTable,
    } = this.props;
    if (e.keyCode === 27) {
      this.setState({
        isEditing: false,
      });
      setIsSelected(false);
      setOverFlowClassToTable(false)
    }
    if (e.metaKey
      || ['Backspace', 'Delete',
        'ArrowLeft', 'ArrowRight',
        'Shift', 'Control',
        'Alt', 'Tab', 'Enter'].includes(e.key)) {
      return;
    }
    if (allowedCharTest) {
      if (!allowedCharTest(e.key)) {
        e.preventDefault();
      }
    }
  };

  onResultChange = (e) => {
    const {validate} = this.props;
    const val = e.target.value.replace(',', '.');
    const error = validate(val);
    this.setState({
      editResult: val,
      error,
    });
  };

  onRepsChange = (e) => {
    const {validateReps} = this.props;
    const error = validateReps(e.target.value);
    this.setState({
      editReps: e.target.value,
      error,
    });
  };

  onInputFocus = () => {
    this.setState({
      hasFocus: true,
      customInputAttrs: { type: 'text' },
    });
  };

  onRepsBlur = (e) => {
    const {
      onSave, rowIndex, columnIndex, validate, setIsSelected, setOverFlowClassToTable
    } = this.props;
    const {editResult, editReps} = this.state;
    const editRepsDef = editReps || 1;
    if (!e.relatedTarget || e.relatedTarget.id !== `${rowIndex}-${columnIndex}-result`) {
      if (!editReps) {
        this.setState({
          editReps: 1,
        });
      }
      this.setState({
        isEditing: false,
        hasFocus: false,
      });
      setOverFlowClassToTable(false);
      setIsSelected(false);
      if (!validate(editResult)) {
        onSave({result: editResult, reps: editRepsDef}, {result: value && value.result || value, videoId: value && value.videoId || null});
      }
    }
  };

  onResultBlur = (e) => {
    const {
      onSave, rowIndex, columnIndex, validate, setIsSelected, setOverFlowClassToTable,
    } = this.props;
    const {editResult, editReps} = this.state;
    if (!e.relatedTarget || e.relatedTarget.id !== `${rowIndex}-${columnIndex}-reps`) {
      this.setState({
        isEditing: false,
        hasFocus: false,
        customInputAttrs: this.defaultInputAttrs,
      });
      setOverFlowClassToTable(false)
      setIsSelected(false);
      if (!validate(editResult)) {
        onSave({result: editResult, reps: editReps},{result: value && value.result || value, videoId: value && value.videoId || null});
      }
    }
  };

  onButtonFocus = () => {
    const {
      canNotEditReason,
      isCoach,
      setExpiringCombineMsg,
      value,
      formatToEdit,
      isAthleteCoachedByUser,
      isCombineOpen,
      setIsSelected,
      setOverFlowClassToTable,
    } = this.props;
    if(!isCombineOpen  || !isAthleteCoachedByUser) {
      return false;
    }
    if (!canNotEditReason) {
      let editResult = null;
      let editReps = null;
      if (value && value.result) {
        editResult = value.result;
        editReps = value.reps;
      } else {
        editReps = 1;
      }
      this.setState({
        editResult: formatToEdit(editResult),
        editReps: formatToEdit(editReps),
        isEditing: true,
        willSave: true,
      }, () => {
        if (this.entryInput) {
          this.entryInput.focus();
          this.entryInput.select();
        }
      });
      setOverFlowClassToTable(true);
      setIsSelected(true);
      if (this.isTouchDevice()) {
        window.dispatchEvent(new Event('touchstart'));
      } else {
        window.dispatchEvent(new Event('click'));
      }
      // window.dispatchEvent(new Event('click'));
    } else if (isCoach) {
      setExpiringCombineMsg(canNotEditReason, 'error', 3);
    }
  };

  setRef = (input) => {
    this.entryInput = input;
  };

  setButtonRef = input => {
    this.buttonRef = input;
  };

  setRepRef = input => {
    this.repRef = input;
  };

  onClickFormButtonIcon = (isDelete =null) =>{
    const {
      unstageNewMedia,
      stagedMedia,
      updateAthleteVideoId,
      athleteId,
      onVideoUploadHandler,
      onVideoDeleteHandler,
      athlete, column, combine,value
     } =  this.props;
    stagedMedia.length>0 && unstageNewMedia(stagedMedia[0]);
    updateAthleteVideoId(athleteId);
    return isDelete ? onVideoDeleteHandler(athlete, column, combine,value) : onVideoUploadHandler(athlete, column, combine,value);
  }

  windowClickSubscription = null;

  formClick = (e) => {
    e.stopPropagation();
  };

  renderVideoData = (realValue) =>{
    const {isCombineOpen,isMobile,value,bank,combineId, schoolId,location,updateAthleteVideoId,athleteId}= this.props;
    const videoId =  value && value.videoId ? value.videoId : null;
    return <div>
    {
     !(videoId) ?
    (isCombineOpen && <CustomVideoIcon
     multiLine={true}
     iconName={"videocam"}
     iconStyle={{fontSize: 36}}
     onClick={() => this.onClickFormButtonIcon()}
     tooltip={isMobile ? '': "Upload Video"}
     className={isMobile ? '' : "showToolTip"}
     place={"right"}
     />  )
     :
     <div style={{display:"flex"}}>
      <Link to={expandedCombineDialogPathTo(schoolId,combineId,bank,value.videoId,location.search)}
        className="Leaderboard_Laser">
        <CustomVideoIcon
        multiLine={true}
        iconName="play-circle2"
        iconStyle={{fontSize: 25, color: "red"}}
        onClick={()=> updateAthleteVideoId(athleteId)}
        disabled={realValue ? false : true}
        place={"right"}
        />
      </Link>
      {isCombineOpen && <CustomVideoIcon
          iconName="delete-video"
          iconStyle={{fontSize: 25}}
          onClick={() => this.onClickFormButtonIcon("delete")}
          tooltip={isMobile ? '' :  "Delete Video"}
          className={isMobile ? '' : "showToolTip"}
          disabled={realValue ? false : true}
          place={"right"}
        />}
    </div>
     }
     </div>
  }

  isTouchDevice = () => ('ontouchstart' in window);
  formRef = React.createRef();

  render() {
    const {
      value,
      formatToDisplay,
      help,
      rowIndex,
      columnIndex,
      isSaving,
      isMobile,
      withinEstimated,
      getRefreshPopUp,
    } = this.props;
    const {
      isEditing,
      editResult,
      editReps,
      hasFocus,
      error,
    } = this.state;
    const realValue = value;
    if(Number(withinEstimated) === 5){
      getRefreshPopUp();
    }
    if (isSaving) {
      setTimeout(()=>{
        const currentTime = new Date().getTime();
        const isOnline = navigator.onLine;
        if(!isOnline){
          getRefreshPopUp();
        }
          if((isSaving && Number(withinEstimated) !==1 &&(currentTime >= Number(withinEstimated)+5000))){
            getRefreshPopUp();
          }
          this.setState({currentTimeCount: currentTime})
      },1000);
      return (
        <form className="CombineEntry_Form" onSubmit={this.onEditSubmit}>
          <LoadingIndicatorCard loading loadingMsg="Please Wait..."/>
        </form>
      );
    }
    return (
      isEditing ? (
        <form ref={this.formRef} className="CombineEntry_Form" onSubmit={this.onEditSubmit}
        onTouchStart={this.formClick} onClick={this.formClick} >
          {
            error && (
              <HelpPopper error focus={hasFocus} helpClass="bottom">
                {error}
              </HelpPopper>
            )
          }
          {
            !error && help && (
              <HelpPopper focus={hasFocus} helpClass="bottom">
                {help({result: editResult, reps: editReps})}
              </HelpPopper>
            )
          }
          <div>
            <input
              id={`${rowIndex}-${columnIndex}-result`}
              type="number"
              step="0.01"
              min="0"
              lang="en"
              className="CombineEntry_Input half"
              value={editResult || ''}
              ref={this.setRef}
              onChange={this.onResultChange}
              onKeyDown={this.onKeyDownMobile('value')}
              onFocus={this.onInputFocus}
            />
            x
            <input
              id={`${rowIndex}-${columnIndex}-reps`}
              type="number"
              min="1"
              lang="en"
              className="CombineEntry_Input half"
              ref={this.setRepRef}
              value={editReps}
              onChange={this.onRepsChange}
              onKeyDown={this.onKeyDownMobile('rep')}
            />
          </div>
          <button ref={this.setButtonRef} style={{display: 'none'}}/>

        </form>
      ) : (
        <React.Fragment>
        <button
          className="CombineEntry_Btn"
          onClick={this.onButtonFocus}
          onFocus={this.onButtonFocus}
        >
          {realValue !== null && realValue.result !== null ? formatToDisplay(realValue) : '-'}
        </button>
         { this.renderVideoData(realValue)}
         </React.Fragment>
      )
    );
  }
}

export default CombineRepsEntry;
