import { asyncStartAction } from '../../async';

export const EXTERNAL_PROFILE_FORM_INIT = 'external_profile.formInit';


export const initExternalProfileForm = () => ({
  type: EXTERNAL_PROFILE_FORM_INIT
});


export const CREATE_EXTERNAL_PROFILE = 'external_profile.create';

export const UPDATE_EXTERNAL_PROFILE = 'external_profile.update';

export const DELETE_EXTERNAL_PROFILE = 'external_profile.delete';
export const deleteExternalProfile = externalProfileId => (dispatch) => {

  dispatch(asyncStartAction( DELETE_EXTERNAL_PROFILE ) );
  dispatch({
    type: DELETE_EXTERNAL_PROFILE,
    externalProfileId,
  });
};

export const EDIT_EXTERNAL_PROFILE = 'external_profile.edit';


export const GET_EXTERNAL_PROFILE = 'external_profile.getAll';

export const getAllExternalProfile = uuid => (dispatch) => {
  dispatch(asyncStartAction(GET_EXTERNAL_PROFILE));
  dispatch({
    type: GET_EXTERNAL_PROFILE,
    uuid,
  });
};

export const HIDE_EXTERNAL_PROFILE = 'external_profile.hide';
export const hideExternalProfile = externalProfileId => (dispatch) => {
  dispatch(asyncStartAction(HIDE_EXTERNAL_PROFILE));
  dispatch({
    type: HIDE_EXTERNAL_PROFILE,
    externalProfileId,
  });
};


