import {connect} from 'react-redux';
import Component from './Roster.component';
import {
  getRoster,
  selectLoadingMsg,
  changeViewSelection,
  getSchoolTeamsUsers,
  updateRosterAthlete,
  checkIfCardView, getSchoolTeamSchedule,
} from '../+store';
import {SportIds} from '../../../store/actions/ui/routes';
import {getFilters, getQueryFromFilters} from '../+store/coachWorld/coachWorld.utils';
import {
  getProfilesSelectorCreator,
  selectAthletes,
  selectAthletesWithProfiles,
  selectCoaches
} from '../+store/roster/roster.selectors';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const sportSlug = ownProps.match.params.sport;
  const sportId = sportSlug ? SportIds[sportSlug] : 0;
  const allAthletes = selectAthletes(state, ownProps);
  const allCoaches = selectCoaches(state, ownProps);
  const {profiles} = state.data.user;
  const profile = profiles && state.data.user.profiles[state.data.cognito.uuid];

  const {schoolTeams} = state.modules.coachWorld.roster;
  const schoolTeamsOrdered = schoolTeams.filter(team => team.active).sort((a, b) => {
    const comp = a.teamRef.orderby - b.teamRef.orderby;
    if (!comp) {
      // Natural sort if both are within same order because teams' name are alphanumerical
      return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
    }
    return comp;
  });
  const rosters = selectAthletesWithProfiles(state, ownProps);

  const gradYears = [...new Set(rosters
    .filter(ath => !!ath.gradYear)
    .map(ath => ath.gradYear)
  )].sort((a, b) => b - a);

  return {
    isLoggedIn: !!state.data.cognito.uuid,
    profile,
    allAthletes,
    allCoaches,
    authenticatedUuid: state.data.cognito.uuid,
    loading: !!loadingMsg,
    loadingMsg,
    athletes: state.modules.coachWorld.roster.athletes,
    schoolTeams: schoolTeamsOrdered,
    admins: state.modules.coachWorld.roster.admins,
    coaches: state.modules.coachWorld.roster.coaches,
    schoolId: ownProps.match.params.schoolId,
    userAthletes: state.data.user.profiles,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
    sportId,
    selectedFilters: getFilters(state, ownProps),
    myTeams: state.modules.coachWorld.coachWorld.myTeams,
    sports: state.data.sport.sports,
    viewSelection: state.modules.coachWorld.roster.viewSelection,
    dynamicRosterView: state.modules.coachWorld.roster.dynamicRosterView,
    parsedFilters: getQueryFromFilters(state, ownProps),
    gradYears,
    isReconfigureEnabled: state.modules.coachWorld.roster.isReconfigureEnabled,
    profilesLoading: state.modules.coachWorld.roster.loadingProfiles,
  };
};

const mapDispatchToProps = dispatch => ({
  getRoster: (schoolId, sportId, parsedFilters) => {
    dispatch(getRoster(schoolId, sportId, parsedFilters));
  },
  checkIfCardView:()=>{
    dispatch(checkIfCardView());
  },
  changeViewSelection: (newSelection) => {
    dispatch(changeViewSelection(newSelection));
  },
  getSchoolTeamUsers: (sportId, schoolId) => {
    dispatch(getSchoolTeamsUsers(sportId, schoolId));
  },
  getUpdateRosterAthlete: (athlete, updatedFormValue, athletes) => {
    dispatch(updateRosterAthlete(athlete, updatedFormValue, athletes));
  },
  getSchedule: ( schoolTeamIds ) => {
    dispatch( getSchoolTeamSchedule(schoolTeamIds) );
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
