import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/UxCommon/Icon/Icon';

class LoadingIndicator extends PureComponent {
  static propTypes = {
    loadingMsg: PropTypes.string,
    loading: PropTypes.bool,
    positionStyle: PropTypes.object,
    wrapperStyle: PropTypes.object,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => {}

  render() {
    const {
      loading, loadingMsg, positionStyle, wrapperStyle,className
    } = this.props;
    if (!loading) return null;
    return (
      <div className={["CoachLoadingIndicator", className || ''].join(' ')} style={wrapperStyle || {}}>
        <div className="CoachLoadingIndicator_Inner" style={positionStyle || {}}>
          <div className="CoachLoadingIndicator_InnerCircle">
            <Icon iconName="loading" className="CoachLoadingIndicator_Icon" />
          </div>
          <div className="CoachLoadingIndicator_Msg">{loadingMsg}</div>
        </div>
      </div>
    );
  }
}

export default LoadingIndicator;
