import { toProperCase } from '../../../CodeSignUp/Common/utils/utils';

export class AthleteResult {
  static fromApi(response) {
    const athlete = { ...response };

    if (response.oldValue) {
      athlete.oldValue = parseFloat(athlete.oldValue);
    }
    if (response.newValue) {
      athlete.newValue = parseFloat(athlete.newValue);
    }
    if (response.difference) {
      athlete.difference = parseFloat(athlete.difference);
    }
    return athlete;
  }
}

export class UserTeam {
  static fromApi(response) {
    const userTeam = new UserTeam();
    userTeam.headshotUrl = response.headshot_url;
    userTeam.id = response.id;
    userTeam.jerseyNumber = response.jersey_number;
    userTeam.schoolTeamId = response.school_team_id;
    userTeam.userId = response.user_id;
    userTeam.userType = response.user_type;
    userTeam.schoolTeams = [];
    if (response.school_team) {
      response.school_team.forEach((st) => {
        userTeam.schoolTeams.push({
          id: st.id,
          isPrivate: st.is_private,
          name: st.name,
          schoolId: st.school_id,
          sportId: st.sport_id,
          teamRefId: st.team_reference_id,
          active: st.active,
        });
      });
      userTeam.sportId = response.school_team[0].sport_id;
      userTeam.schoolId = response.school_team[0].school_id;
    }
    return userTeam;
  }
}

export class SchoolStyle {
  static fromApi(response) {
    // console.log(response, 'response response response');
    const schoolStyle = new SchoolStyle();
    schoolStyle.id = response.id;
    // schoolStyle.name = toProperCase(response.name);
    schoolStyle.name = response.name;
    schoolStyle.color1 = response.color1;
    schoolStyle.color2 = response.color2;
    schoolStyle.darkmode = response.darkmode;
    schoolStyle.background_image = response.background_image;
    schoolStyle.school_logo = response.school_logo;
    schoolStyle.allTimeLeaderboardLogo = response.all_time_leaderboard_logo
      ? response.all_time_leaderboard_logo
      : response.school_logo;
    schoolStyle.leaderboardBackground = response.leaderboard_background;
    schoolStyle.leaderboardLogo = response.leaderboard_logo;
    schoolStyle.leaderboardStyle = response.leaderboard_style;
    schoolStyle.isPaid = response.is_paid;
    schoolStyle.isSMSEnabled = response.enable_sms;
    schoolStyle.paidInviteSchoolLogo = response.paid_invite_school_logo;
    schoolStyle.districtSocialMediaLogo = response.district_social_media_logo;
    schoolStyle.districtLeaderboardName = response.district_leaderboard_name;
    schoolStyle.schoolLogo = response.school_award;
    schoolStyle.isActive = response.enable_nl;
    return schoolStyle;
  }
}

export default 'coachWorld.models.js';
