import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LoadingImg extends PureComponent {
  static propTypes = {
    srcUrl: PropTypes.string.isRequired,
    className: PropTypes.string,
    cover: PropTypes.bool,
    onLoadCallback: PropTypes.func,
    imgHeight: PropTypes.number,
    imgWidth: PropTypes.number,
  }

  state = {
    status: 'loading',
    shape: 'landscape',
  };

  componentDidMount() {
    const { srcUrl } = this.props;
    this.img = new Image();
    this.img.onload = this.onLoad;
    this.img.onerror = this.onError;
    this.img.src = srcUrl;
  }

  componentDidUpdate() {
    const { srcUrl } = this.props;
    if ((this.img) && (srcUrl !== this.img.src)) {
      this.img.onload = null;
      this.img.onerror = null;
      this.img = new Image();
      this.img.onload = this.onLoad;
      this.img.onerror = this.onError;
      this.img.src = srcUrl;
      this.setLoading();
    }
  }

  componentWillUnmount() {
    this.img.onload = null;
    this.img.onerror = null;
  }

  onLoad = () => {
    const { onLoadCallback } = this.props;
    if (onLoadCallback) {
      onLoadCallback({
        height: this.img.height,
        width: this.img.width,
      });
    }
    let shape;
    if (this.img.height > this.img.width) {
      shape = 'portrait';
    } else if (this.img.width > this.img.height) {
      shape = 'landscape';
    } else {
      shape = 'square';
    }
    this.setState({
      status: 'good',
      shape,
    });
  }

  onError = () => {
    this.setState({
      status: 'bad',
    });
  }

  setLoading = () => {
    this.setState({
      status: 'loading',
    });
  };

  render() {
    const {
      srcUrl,
      className,
      cover,
      onLoadCallback,
      imgHeight,
      imgWidth,
      ...otherProps
    } = this.props;
    const { status, shape } = this.state;
    if (status === 'bad') {
      return null;
    }
    let width;
    let height;
    if (shape === 'portrait') {
      if (cover) {
        width = '100%';
        height = 'auto';
      } else {
        width = 'auto';
        height = '100%';
      }
    }
    if (shape !== 'portrait') {
      if (cover) {
        width = 'auto';
        height = '100%';
      } else {
        width = '100%';
        height = 'auto';
      }
    }
    if (imgHeight && imgHeight) {
      width = `${imgWidth}px`;
      height = `${imgHeight}px`;
    }
    return (
      <div className={['UxCommon_LoadingImg', className].join(' ')} {...otherProps}>
        {
          status === 'loading' ?
            <div className="UxCommon_LoadingImg_Loading">
              <div>Loading...</div>
            </div>
          :
            <img
              className="UxCommon_LoadingImg_Img"
              src={srcUrl}
              alt="loading"
              style={{
                width,
                height,
              }}
            />
        }
      </div>
    );
  }
}

export default LoadingImg;
