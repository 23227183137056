import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import Icon from '../../../../components/UxCommon/Icon/Icon';

const validDateFormat = 'MM/DD/YYYY';
class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    // const { selectedDate, compareDate } = this.props;
    let { selectedDate } = this.props;
    const { compareDate } = this.props;
    if (selectedDate && !moment(selectedDate, [validDateFormat], true).isValid()) {
      selectedDate = compareDate || moment().format(validDateFormat);
    }
    let currentMonth;
    if (!selectedDate && compareDate) {
      currentMonth = dateFns.format(dateFns.startOfMonth(new Date(compareDate)));
    } else {
      currentMonth = selectedDate || new Date();
    }
    this.state = {
      currentMonth,
      selectedDate: selectedDate || new Date(),
      actualSelectedDate: selectedDate,
    };
  }
  onDateClick = day => () => {
    this.setState({
      selectedDate: day,
    });
    this.props.onDateChanged(day);
  };

  getButton = (classNameToBe, day, clonedDay, formattedDate) => (
    <button
      className={classNameToBe}
      key={day}
      onLoad={this.onDateClick(clonedDay)}
      onClick={this.onDateClick(clonedDay)}
      type="button"
    >
      {formattedDate}
    </button>
  );

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
  };

  renderHeader() {
    const dateFormat = 'MMMM YYYY';

    return (
      <div className="header">
        <div className="col col-month">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-arrows">
          <button className="icon" onClick={this.prevMonth} type="button">
            <Icon iconName="chevron-left" />
          </button>
          <span />
          <button className="icon" onClick={this.nextMonth} type="button">
            <Icon iconName="chevron-right" />
          </button>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = 'dddd';
    const days = [];

    const startDate = dateFns.startOfWeek(this.state.currentMonth);
    const daysArray = [...Array(7).keys()];
    daysArray.forEach((index) => {
      const day = dateFns.format(dateFns.addDays(startDate, index), dateFormat);
      days.push(<div className="col col-day" key={index}>{day.charAt(0)}</div>);
    });

    return <div className="days">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate, actualSelectedDate } = this.state;
    const { compareDate, compareType } = this.props;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = 'D';
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';
    const daysArray = [...Array(7).keys()];
    const firstDaysOfWeeks = [];
    while (day <= endDate) {
      firstDaysOfWeeks.push(day);
      day = dateFns.addDays(day, 7);
    }
    day = startDate;
    firstDaysOfWeeks.forEach(() => {
      daysArray.forEach(() => {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        let classNameToBe = 'coll cell ';
        classNameToBe += !dateFns.isSameMonth(day, monthStart) ? 'other-month ' : ' ';
        if (compareDate && compareType === 'isAfter') {
          classNameToBe += dateFns.isAfter(compareDate, dateFns.parse(cloneDay)) ? ' disabled ' : '';
          classNameToBe += dateFns.isSameDay(day, compareDate) || dateFns.isSameDay(day, actualSelectedDate) ? 'selected ' : '';
          classNameToBe += dateFns.isAfter(actualSelectedDate, compareDate) && dateFns.isWithinRange(day, compareDate, actualSelectedDate) ? 'date-range ' : '';
        } else {
          classNameToBe += dateFns.isSameDay(day, selectedDate) ? 'selected ' : '';
        }
        days.push(this.getButton(classNameToBe, day, dateFns.parse(cloneDay), formattedDate));
        day = dateFns.addDays(day, 1);
      });
      rows.push(<div className="rowCalendar" key={day}>{days}</div>);
      days = [];
    });
    return <div className="calendarBody">{rows}</div>;
  }

  render() {
    return (
      <div className={`CombineCalendar ${this.props.className}`}>
        <div className="CombineCalendarContainer" />
        <div className="simpleCalendar">
          {this.renderHeader()}
          {this.renderDays()}
          {this.renderCells()}
        </div>
      </div>
    );
  }
}
Calendar.propTypes = {
  onDateChanged: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  className: PropTypes.string,
  compareDate: PropTypes.string,
  compareType: PropTypes.string,
};
export default Calendar;
