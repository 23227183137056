import { combineEpics } from 'redux-observable';
import { epics as photosVideosEpics } from './photosVideos';
import { epics as awardsEpics } from './awards';
import { epics as profileEpics } from './profile';

import { epics as externalProfileEpics  } from './externalProfile';

export const uxProfileEpics = combineEpics(
  photosVideosEpics,
  awardsEpics,
  profileEpics,
  externalProfileEpics
);

export * from './profile';
export * from './sidebar';
export * from './banners';
