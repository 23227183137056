import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/UxCommon/Icon/Icon';

const AthleteRowMedia = (props) => {
  const { athlete } = props;
  return (
    <div className="AthleteMedia">
      {
        athlete.headshotUrl ? (
          <div
            className="AthleteMedia_Headshot"
            style={{
              backgroundImage: `url(${athlete.headshotUrl})`,
            }}
          />
        ) :
          (
            <div className="AthleteMedia_Headshot">
              <Icon iconName="photo2" className="AthleteMedia_PhotoIcon" />
            </div>
          )
      }
    </div>
  );
};
AthleteRowMedia.propTypes = {
  athlete: PropTypes.object,
};

export default AthleteRowMedia;
