import { connect } from 'react-redux';
import Component from './CombineContainer.components';
// import { getCombine } from '../../../+store/combine';
import { selectFilteredCombineAthletes } from '../../../+store/combine/combine.selectors';

const mapStateToProps = (state, ownProps) => {
  let athleteList;
  if (ownProps.athletes) {
    athleteList = ownProps.athletes;
  } else {
    athleteList = selectFilteredCombineAthletes(state, ownProps);
  }
  const athletes = athleteList;
  return {
    athletes,
  };
};

const mapDispatchToProps = dispatch => ({
  getCombine: (combineId) => {
    // dispatch(getCombine(combineId));
    dispatch({ type: 'noop', combineId });
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
