import { combineEpics } from 'redux-observable';
import { joinFormEpic } from './joinNow';
import { signInFormEpic } from './signIn';
import { mergeSignInFormEpic } from './mergeSignIn';
import { forgotPassFormEpic } from './forgotPass';
import { resetPassFormEpic } from './resetPass';
import { confirmAccountFormEpic } from './confirmAccount';
import { secondSignInFormEpic } from './secondSignIn';
import { invitedSignUpFormEpic } from './invitedSignUp';
import { acceptInvitationFormEpic } from './acceptInvitation';
import { checkInvitationEpic } from './checkInvitation';
import { mergeAnotherAccountFormEpic } from './mergeAnotherAccount';
import { stripeCheckoutEpics } from "./StripeCheckout";

export const welcomeEpics = combineEpics(
  joinFormEpic,
  signInFormEpic,
  forgotPassFormEpic,
  resetPassFormEpic,
  confirmAccountFormEpic,
  secondSignInFormEpic,
  invitedSignUpFormEpic,
  mergeSignInFormEpic,
  acceptInvitationFormEpic,
  checkInvitationEpic,
  mergeAnotherAccountFormEpic,
  stripeCheckoutEpics,
);

export * from './welcome';
export * from './confirmAccount';
export * from './forgotPass';
export * from './joinNow';
export * from './resetPass';
export * from './secondSignIn';
export * from './signIn';
export * from './invitedSignUp';
export * from './mergeSignIn';
export * from './acceptInvitation';
export * from './checkInvitation';
export * from './mergeAnotherAccount';

