import { connect } from "react-redux";
import Component from "./Banner.component";
import { selectCoachOrAthleteSchoolIds, selectIsCoachOrAthlete } from "../../+store";

const mapStateToProps = (state, ownProps) => ({
  isCoachOrAthlete: selectIsCoachOrAthlete(state),
  authenticatedUuid: state.data.cognito.uuid,
  coachSchoolIds: selectCoachOrAthleteSchoolIds(state, ownProps),
  customClass : ownProps.customClass
});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
