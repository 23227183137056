import React from 'react';

const ChecklistComponent = ({ checklists }) => {
  return (
    <ul>
      {checklists.map((checklist, index) => {
        return (
          <li key={index}>
            <img
              src="https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/noun-check-3612574-FFFFFF.png"
              height={20}
              width={20}
            />
            {checklist}
          </li>
        );
      })}
    </ul>
  );
};

export default ChecklistComponent;
