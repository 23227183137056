import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../Common/Button';
import { getRole, getRoleId } from '../Common/utils';
import CoachSport from './CoachSport';
import AthleteSport from './AthleteSport';
import { createNewTeamId } from './utils';

const MyTeam = () => ({
  sportId: 0,
  teamIds: [],
  positions: [],
  bats: '',
  throws: '',
  jerseyNumber: '',
  notPristine: [],
});

const addToPristine = (notPristine, field) => {
  if (notPristine.includes(field)) {
    return notPristine;
  }
  return [...notPristine, field];
};

const checkPristine = (notPristine, field) => !notPristine.includes(field);

const getSports = (sports) => {
  const sportsOptions = {};
  const sortedSports = sports.sort((a, b) => {
    if (a.code > b.code) {
      return 1;
    } else if (b.code > a.code) {
      return -1;
    }
    return 0;
  });
  sortedSports.forEach((sport) => {
    sportsOptions[`${sport.id}`] = sport.name;
  });
  return sportsOptions;
};

class SportsPage extends PureComponent {
  static propTypes = {
    teamTypes: PropTypes.array,
    saveSports: PropTypes.func,
    match: PropTypes.object,
    schoolInfo: PropTypes.object,
    profile: PropTypes.object,
    getSportTeam: PropTypes.func,
    sportTeamList: PropTypes.array,
    sports: PropTypes.array,
    checkCode: PropTypes.func,
  };

  state = {
    myTeams: [],
    newTeams: [],
    errors: [],
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onClickSaveTeam = () => {
    const { myTeams, newTeams } = this.state;
    const {
      saveSports, match, schoolInfo, profile,
    } = this.props;
    const roleId = getRoleId(match.params.code, schoolInfo);
    const errors = this.validateStuff(true);
    const valid = this.isValid(errors);
    this.setState({
      errors,
    });
    const teams = myTeams.filter(s => s);
    // Athletes can only choose a single team so teamIds is a string
    // Here I'm converting it back to an array because that's what the data layer expects
    // + but you forgot to check if user select the team or not
    teams.forEach((team) => {
      if (!Array.isArray(team.teamIds) && team.teamIds !== '') {
        team.teamIds = [team.teamIds];
      }
    });
    if (valid) {
      saveSports({
        profile,
        roleId,
        teams,
        newTeams,
        code: match.params.code,
        schoolId: schoolInfo.id,
      });
    }
  }

  onFieldChange = (index, fieldName) => (value) => {
    const { myTeams } = this.state;
    const notPristine = addToPristine(myTeams[index].notPristine, fieldName);
    this.setState({
      myTeams: [
        ...myTeams.slice(0, index),
        Object.assign({}, myTeams[index], {
          [fieldName]: value,
          notPristine,
        }),
        ...myTeams.slice(index + 1),
      ],
    }, () => {
      const errors = this.validateStuff();
      this.setState({
        errors,
        valid: this.isValid(errors),
      });
    });
  }

  onCancelSport = (index) => {
    const { myTeams } = this.state;
    this.setState({
      myTeams: [
        ...myTeams.slice(0, index),
        null,
        ...myTeams.slice(index + 1),
      ],
    });
  }

  onAddSport = () => {
    const { myTeams } = this.state;
    this.setState({
      myTeams: [...myTeams, MyTeam()],
    });
  }

  getRole = () => {
    const { schoolInfo, match } = this.props;
    const role = schoolInfo && getRole(match.params.code, schoolInfo);
    return role;
  }

  isValid = (errors) => {
    let valid = true;
    errors.forEach((team) => {
      if (Object.values(team).length) {
        valid = false;
      }
    });
    return valid;
  }

  validateStuff = (checkAll = false) => {
    const { myTeams } = this.state;
    const errors = [];
    const role = this.getRole();
    myTeams.forEach((team, index) => {
      if (!team) return;
      errors.push({});
      if (!checkPristine(team.notPristine, 'sportId') || checkAll) {
        if (!team.sportId) {
          errors[index].sportId = ['This field is required'];
        }
      }
      if (!checkPristine(team.notPristine, 'teamIds') || checkAll) {
        if (!team.teamIds.length) {
          errors[index].teamIds = ['This field is required'];
        }
      }
      if (role === 'athlete') {
        if (!checkPristine(team.notPristine, 'positions') || checkAll) {
          if (!team.positions.length) {
            errors[index].positions = ['This field is required'];
          }
        }
       if(team.sportId === "2" || team.sportId === "4"){ 
         if (!checkPristine(team.notPristine, 'bats') || checkAll) {
          if (!team.bats.trim()) {
            errors[index].bats = ['This field is required'];
          }
        }
        if (!checkPristine(team.notPristine, 'throws') || checkAll) {
          if (!team.throws.trim()) {
            errors[index].throws = ['This field is required'];
          }
        }
      }
        if (!checkPristine(team.notPristine, 'jerseyNumber') || checkAll) {
          // if (Number.isNaN(Number(team.jerseyNumber))) {
          if ( ! /^[a-zA-Z0-9- '",_]*$/.test(team.jerseyNumber) ) {
            errors[index].jerseyNumber = ['Validation failed'];
          }
        }
      }
    });
    return errors;
  }

  createTeam = (sportId, level, name, index) => {
    const { newTeams, myTeams } = this.state;
    const { teamIds } = myTeams[index];
    const newTeamId = createNewTeamId({
      sportId, level, name,
    });
    this.setState({
      newTeams: [...newTeams, {
        sportId,
        level,
        name,
        id: newTeamId,
      }],
    }, () => {
      this.onFieldChange(index, 'teamIds')([...teamIds, newTeamId]);
    });
  }

  teamsFetched = false;

  shouldUpdate() {
    const {
      schoolInfo,
      getSportTeam,
      checkCode,
      match,
    } = this.props;
    const { myTeams } = this.state;
    if (!schoolInfo) {
      checkCode(match.params.code);
    }
    if (schoolInfo && !this.teamsFetched) {
      this.teamsFetched = true;
      getSportTeam(schoolInfo.id);
    }
    if (!myTeams.length) {
      this.setState({
        myTeams: [MyTeam()],
      });
    }
  }

  render() {
    const {
      sportTeamList, sports, profile, teamTypes,
    } = this.props;
    const {
      myTeams, newTeams, errors, valid,
    } = this.state;
    const gender = profile ? (profile.gender || 'male') : 'male';
    const role = this.getRole();
    const sportOptions = getSports(sports);
    const filterSportTeamList = sportTeamList.filter(sp => sp.active);
    return (
      <div className="CodeSignUp_SportsPage">
        {
          !!profile && role === 'athlete' && myTeams.map((myTeam, index) => (
            myTeam && (
              <AthleteSport
                key={index}
                myTeam={myTeam}
                index={index}
                sports={sportOptions}
                onFieldChange={this.onFieldChange}
                gender={gender}
                profileSport={profile.sports}
                onCancel={this.onCancelSport}
                errors={errors[index] || {}}
                sportTeamList={filterSportTeamList}
                // sportTeamList={sportTeamList}
              />
            )
          ))
        }
        {
          !!profile && (role === 'coach' || role === 'admin') && myTeams.map((myTeam, index) => (
            myTeam && (
              <CoachSport
                key={index}
                myTeam={myTeam}
                index={index}
                onFieldChange={this.onFieldChange}
                gender={gender}
                sports={sportOptions}
                teamTypes={teamTypes}
                createTeam={this.createTeam}
                onCancel={this.onCancelSport}
                errors={errors[index] || {}}
                sportTeamList={filterSportTeamList}
                // sportTeamList={sportTeamList}
                newTeams={newTeams}
              />
            )
          ))
        }
        {
          (myTeams.length > 1 || valid) && (
            <React.Fragment>
              <Button
                type="button"
                onClick={this.onClickSaveTeam}
                className="Code_Sports_Button Code_Sport_Save"
              >
                {'Save & Go to Profile'}
              </Button>
              <Button
                type="button"
                onClick={this.onAddSport}
                className="Code_Sports_Button Code_Sport_Save"
              >
                {'Add Another Sport'}
              </Button>
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default SportsPage;
