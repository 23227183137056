import {connect} from 'react-redux';
import Component from './TeamTwitterLinkModal.component';
import {deleteTwitterLink, updateTwitterLink} from "../../+store";

const mapStateToProps = (state, ownProps) => {
  const {headCoach, schoolTeamId} = ownProps;
  return {
    headCoach,
    schoolTeamId
  }
}

const mapDispatchToProps = dispatch => ({
  updateTwitterLink: (values) => {
    dispatch(updateTwitterLink({
      twitter: values.teamTwitterLink,
      school_team_id: values.schoolTeamId
    }));
  },
  deleteTwitterLink: (schoolTeamId) => {
    dispatch(deleteTwitterLink(schoolTeamId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
