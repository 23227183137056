import { ajax } from "rxjs/ajax";
import Urls from "../../../../config/urls";

export const apiGetDistrictLeaderboardResults = (token, action) => (
  ajax.getJSON(
    Urls.districtLeaderboard.results(action),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);


export const apiGetDistrictLeaderboardTemplate = (token, schoolId, sportId) => (
  ajax.getJSON(
    Urls.districtLeaderboard.template(schoolId, sportId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);

export const apiGetDistrictLeaderboardSports = (schoolId) => (
  ajax.getJSON(
    Urls.districtLeaderboard.sports(schoolId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);

export const apiGetDistrictLeaderboardGradYears = (schoolId) => (
  ajax.getJSON(
    Urls.districtLeaderboard.gradYears(schoolId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);

export default "districtLeaderboard.api";

