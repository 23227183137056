import { combineReducers } from 'redux';

import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { CONFIRM_ACCOUNT_FORM_INIT } from '../../../actions/ui/welcome';

import {
  AUTH2_RESEND_CODE_REQ,
  AUTH2_RESEND_CODE_SUC,
  AUTH2_RESEND_CODE_ERR,
} from '../../../actions/data/cognito';

import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_FAILED,
  FORM_SERVER_ERROR,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case AUTH2_RESEND_CODE_SUC:
      return true;
    case CONFIRM_ACCOUNT_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const displayError = (action) => {
  if (typeof action.error === 'string') {
    return action.error;
  }
  if (action.error.message) {
    return action.error.message;
  }
  return `Error: ${JSON.stringify(action.error)}`;
};

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case AUTH2_RESEND_CODE_REQ:
    case AUTH2_RESEND_CODE_SUC:
    case CONFIRM_ACCOUNT_FORM_INIT:
    case 'rrf/change':
      return '';
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.confirmAccount') {
        return displayError(action);
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (action.model === 'forms.confirmAccount') {
        return "Looks like something's missing";
      }
      return state;
    case AUTH2_RESEND_CODE_ERR:
      return displayError(action);
    default:
      return state;
  }
};

const username = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_SUCCESS:
      if (action.model === 'forms.joinNow') {
        return action.data.username;
        // return action.data.awsResponse.userSub;
      }
      return state;
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.login') {
        if (action.error.code === 'UserNotConfirmedException') {
          return action.data.username;
        }
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
  username,
});

export default reducer;
