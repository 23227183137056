import React from 'react';

const InviteLinkComponent = ({ inviteLink }) => {
  return (
    <a href={inviteLink}>
      <img src="https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/PMA/Register_Now2.png"  alt={'Register Now'}/>
    </a>
  );
};

export default InviteLinkComponent;
