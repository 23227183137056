import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './Caption.scss';

export const Caption = ({ profile, media }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => setShowMore(!showMore);

  const { caption, createdAt } = media;
  const uploadDate = () => {
    let uploadDateText = null;
    if (createdAt) {
      const now = moment();
      const date = moment(`${createdAt}+00:00`);
      const diffDays = now.diff(date, 'day');
      const diffHours = now.diff(date, 'hour');
      const diffMinutes = now.diff(date, 'minute');
      if (diffDays >= 7) {
        uploadDateText =
          diffDays === 7 ? '1 week ago' : date.format('MMMM D, YYYY');
      } else if (diffDays >= 1) {
        uploadDateText = `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
      } else if (diffHours >= 1) {
        uploadDateText = `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
      } else if (diffMinutes >= 1) {
        uploadDateText = `${diffMinutes} minute${
          diffMinutes !== 1 ? 's' : ''
        } ago`;
      } else {
        uploadDateText = 'Few moments ago';
      }
    }
    return <div className="Photos_ExpandedPhotoDialog_UploadedAt">{uploadDateText}</div>;
  };

  const maxLength = 40;
  const shouldEllipsis =
    caption.length > maxLength || caption.split('\n').length > 2;
  let updatedCaption = caption;

  if (!showMore) {
    updatedCaption = caption.slice(0, maxLength);
    const captionArr = updatedCaption.split('\n');
    updatedCaption =
      captionArr.length > 2
        ? captionArr.slice(0, 1).join('\n')
        : updatedCaption;
    updatedCaption = `${updatedCaption}${
      updatedCaption.length !== caption.length ? '...' : ''
    }`;
  }

  const captionText = (
    <p className="Photos_ExpandedPhotoDialog_CaptionText">
      <strong style={{ marginRight: 10 }}>{profile ? `${profile.first} ${profile.last}\t` : ''}</strong> {updatedCaption}
      {!!shouldEllipsis && <span className="Photos_ExpandedPhotoDialog_UploadedAt">{showMore ? '  less' : '  more'}</span>}
    </p>
  );

  if (shouldEllipsis) {
    return (
      <button className="Photos_ExpandedPhotoDialog_Caption" onClick={toggleMore}>
        {captionText}
        {uploadDate()}
      </button>
    );
  }

  return (
    <div className="Photos_ExpandedPhotoDialog_Caption">
      {captionText}
      {uploadDate()}
    </div>
  );
};

Caption.propTypes = {
  media: PropTypes.object,
  profile: PropTypes.object,
};

export default Caption;
