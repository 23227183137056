import { ofType } from 'redux-observable';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { cancelOnRouteChange, getTokenFragment } from '../../../uxProfile/utils';
import {
  statsDeleteSeasonSuccess,
  statsGetUserStatsSuccess,
  statsSeasonDelete,
} from '../../../../data/sport';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

import { ASYNC_FINISH, asyncErrorAction, asyncFinishAction } from '../../../async';

import { getPlayerStatsEpicFragment } from './read';
import {
  PD_STATS_DEL_SEASON,
  statsSeasonTableFormDataObject,
  statsSeasonTableFormModel,
} from '../actions';

export const deleteSeasonEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(PD_STATS_DEL_SEASON),
    getTokenFragment(),
    switchMap(({ action, token }) => statsSeasonDelete(
      action.season.id,
      token,
    ).pipe(
      map(response => ({
        success: true,
        response,
        outer: action,
        token,
      })),
      takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(
          PD_STATS_DEL_SEASON,
          'deleteSeason',
          error,
          { positionId: action.positionId },
        ),
      })),
    )),
    map((results) => {
      if (results.success) {
        return {
          statsStd: state$.value.data.sport.statsStd,
          uuid: uuid(),
          sportId: results.outer.season.team.sportId,
          ...results,
        };
      }
      return results;
    }),
    getPlayerStatsEpicFragment(action$),
    switchMap((results) => {
      if (results.success) {
        const { outer } = results;
        return of(
          asyncFinishAction(PD_STATS_DEL_SEASON, 'deleteSeason', {
            teamId: outer.teamId,
            positionId: outer.positionId,
            season: outer.season,
          }),
          statsDeleteSeasonSuccess(outer.teamId, outer.positionId, outer.season.id),
          statsGetUserStatsSuccess(
            results.uuid,
            results.sportId,
            results.userStats,
            results.allTeams,
          ),
        );
      }
      if (results.action) return of(results.action);
      if (results.actions) return of(...results.actions);
      return of(results);
    }),
  );
};

export const deleteSeasonFromFormsEpic = (action$, state$) => action$.pipe(
  filter((action) => {
    if ((action.type === ASYNC_FINISH) && (action.model === PD_STATS_DEL_SEASON)) {
      return true;
    }
    return false;
  }),
  map((action) => {
    const oldPos = statsSeasonTableFormDataObject(
      state$.value,
      action.data.teamId,
      action.data.positionId,
    );
    let oldForm;
    if (oldPos.seasons) {
      oldForm = oldPos.seasons;
    } else {
      return { type: 'noop' };
    }
    if (oldForm) {
      const { [`${action.data.season.id}`]: deleted, ...newForm } = oldForm;
      return actions.change(
        `${statsSeasonTableFormModel(action.data.teamId, action.data.positionId)}.seasons`,
        newForm,
      );
    }
    return { type: 'noop' };
  }),
);
