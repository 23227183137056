import React, { Component } from 'react';

export default class ErrorToolTipRenderer extends Component {
  getReactContainerClasses() {
    const { error } = this.props;
    if (error) return ['GridView_Error_Tooltip'];
    return ['GridView_Tooltip'];
  }

  render() {
    const { data } = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex);
    const { error } = this.props;
    if (!error) {
      return (
        <div
          className="GridView_Tooltip"
        />
        );
      }
    return (
      <div
        className="GridView_Error_Tooltip"
      >
        <p>
          <span>{error}</span>
        </p>
      </div>
    );
  }
}
