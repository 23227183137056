import React from 'react';
import PropTypes from 'prop-types';
import ButtonIconOnly from '../ButtonIconOnly/ButtonIconOnly';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { YesNoModal } from '../index';

class PrivacyControl extends React.Component {
  static propTypes = {
    uuid: PropTypes.string,
    currentUuid: PropTypes.string,
    privacyType: PropTypes.string,
    privacy: PropTypes.bool,
    savePrivacy: PropTypes.func,
    className: PropTypes.string,
    loadingPrivacy: PropTypes.object,
    loadingStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
  };

  state = {
    openModal: false,
  }

  onSave = () => {
    this.savePrivacy();
    this.closeModal();
  }

  onOpen = (e) => {
    e.preventDefault();
    this.openModal();
  }

  onClose = () => {
    this.closeModal();
  }

  openModal = () => {
    this.setState({ ...this.state, openModal: true });
  }

  closeModal = () => {
    this.setState({ ...this.state, openModal: false });
  }

  savePrivacy = () => {
    const {
      savePrivacy,
      privacy,
      privacyType,
    } = this.props;
    const toggle = privacy ? 'off' : 'on';
    savePrivacy(toggle, privacyType);
  }

  render() {
    const {
      privacy,
      className,
      currentUuid,
      uuid,
      loadingPrivacy,
      privacyType,
      loadingStyle,
      buttonStyle,
    } = this.props;

    const { openModal } = this.state;
    if (uuid !== currentUuid) {
      return null;
    }
    if (privacy == null) {
      return <LoadingSpinner loading loadingMsg="" style={loadingStyle || {}} />;
    }
    let icon = privacy ? 'lock' : 'globe';
    const text = privacy ? 'public' : 'private';
    if (loadingPrivacy[privacyType]) {
      icon = 'loading';
    }
    return (
      <div>
        <ButtonIconOnly
          className={`UxCommon_Privacy ${className||''}`}
          iconName={icon}
          onClick={this.onOpen}
          style={buttonStyle || {}}
        />
        <YesNoModal
          show={openModal}
          onClose={this.onClose}
          onNo={this.onClose}
          onYes={this.onSave}
          title={`Make ${text}`}
          question={`Are you sure you want to make this ${text}?`}
          noTxt="No"
          yesTxt="Yes"
          defaultNo
        />
      </div>
    );
  }
}

export default PrivacyControl;
