import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import {
  TextControl,
  TextAreaControl,
  DatePicker,
  Errors,
  CustomSelectControl,
} from '../../../../UxCommon/Form';
import { SecondaryIconButton, LinkButton, ButtonRect, ButtonLinkRect } from '../../../../UxCommon';
import { parserMaxChar } from '../../../../../utils/parsers';
import { Routes } from '../../../../../store/actions/ui/routes';
import { AwardTypes, CombineAwardType } from '../../../../../store/actions/ui/uxProfile/awards';
import DeleteDialog from '../DeleteDialog/DeleteDialog';

class AwardDetailsForm extends PureComponent {
  static propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onAwardDateChange: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onHideShowToggleClick: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    onAwardTypeSelected: PropTypes.func.isRequired,
    award: PropTypes.object,
    onHideImmediatelyClick: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    onShareClick: PropTypes.func.isRequired,
  }

  state = {
    deleteDialogIsShown: false,
  }

  componentDidMount() { }

  onDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: true,
    });
  }

  onCancelDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: false,
    });
  }

  onConfirmDeleteClick = () => {
    const { onDeleteClick, award } = this.props;
    this.setState({
      deleteDialogIsShown: false,
    });
    onDeleteClick(award);
  }

  onHideImmediatelyClick = () => {
    const { onHideImmediatelyClick } = this.props;
    this.setState({
      deleteDialogIsShown: false,
    });
    onHideImmediatelyClick();
  }

  onCancelClick = () => {
    const { history, match } = this.props;
    const { sport, schoolId } = match.params;
    history.push(`/${Routes.profile}/${match.params.uuid}/${Routes.awards}${sport ? `/${sport}`:''}${schoolId ? `/${schoolId}`:''}`);
  }

  render() {
    const {
      onSubmitForm,
      rrfForm,
      onAwardDateChange,
      match,
      onHideShowToggleClick,
      onAwardTypeSelected,
      award,
      onShareClick,
    } = this.props;
    const { deleteDialogIsShown } = this.state;
    const { sport, schoolId } =match.params;
    const isCombineType = rrfForm && rrfForm.type.value === 'combine';
    return (
      <div className="Awards_AwardDetailsForm">
        {
          deleteDialogIsShown && (
            <DeleteDialog
              onConfirmDeleteClick={this.onConfirmDeleteClick}
              onCancelClick={this.onCancelDeleteClick}
              onHideClick={this.onHideImmediatelyClick}
              award={award}
            />
          )
        }
        <div className="Awards_AwardDetailsForm_TopBtnsRow">
          {
            rrfForm.display.value ? (
              <SecondaryIconButton type="button" iconName="hide" onClick={onHideShowToggleClick}>
                Hide
              </SecondaryIconButton>
            ) :
              (
                <SecondaryIconButton type="button" iconName="hide" onClick={onHideShowToggleClick}>
                  Show
                </SecondaryIconButton>
              )
          }
          { award &&
            <SecondaryIconButton
              className="Photos_PhotoInfoForm_ShareBtn"
              iconName="share"
              onClick={onShareClick}
              type="button"
            >
              Share
            </SecondaryIconButton>
          }
        </div>
        <Form
          model="forms.awards"
          onSubmit={onSubmitForm}
        >
          <TextControl
            label="Name"
            placeholder="Enter Award Name"
            model="forms.awards.name"
            rrfField={rrfForm.name}
            maxLength={80}
          />
          <CustomSelectControl
            label="Type of Award"
            placeholder="Type of Award"
            model="forms.awards.type"
            rrfField={rrfForm.type}
            onOptionSelected={onAwardTypeSelected}
            options={isCombineType ? CombineAwardType : AwardTypes}
          />
          <DatePicker
            label="Award Date"
            placeholder="Enter Award Date"
            model="forms.awards.date"
            rrfField={rrfForm.date}
            onDateChange={onAwardDateChange}
          />
          <TextControl
            label="Website"
            placeholder="Enter Website link"
            model="forms.awards.url"
            rrfField={rrfForm.url}
          />
          <TextAreaControl
            label="Description"
            placeholder="Add Award Description"
            model="forms.awards.desc"
            rrfField={rrfForm.desc}
            maxLength={400}
            showCounter
            parser={parserMaxChar(400)}
          />
          <Errors
            className="Awards_AwardsDetailsForm_Errors"
            valid={rrfForm.$form.valid}
            errors={rrfForm.$form.errors}
          />
          <div className="Awards_AwardsDetailsForm_ButtonsRow">
            {
              award ? (
                <LinkButton
                  className="Awards_AwardsDetailsForm_Button"
                  iconName="trash"
                  onClick={this.onDeleteClick}
                  type="button"
                  style={{ width: '100px', marginRight: '24px' }}
                >
                  Delete
                </LinkButton>
              )
                :
                (
                  <LinkButton
                    className="Awards_AwardsDetailsForm_Button"
                    iconName="trash"
                    onClick={this.onCancelClick}
                    type="button"
                    style={{ width: '100px', marginRight: '24px' }}
                  >
                    Delete
                  </LinkButton>
                )
            }
            <ButtonLinkRect
              style={{ marginLeft: 'auto', width: '100px' }}
              className="Awards_AwardsDetailsForm_Button"
              to={`/${Routes.profile}/${match.params.uuid}/${Routes.awards}${sport ? `/${sport}` : ''}${schoolId? `/${schoolId}`: ''}`}
            >
              Cancel
            </ButtonLinkRect>
            <ButtonRect
              className="Awards_AwardsDetailsForm_Button primary"
              style={{ width: '120px' }}
              type="submit"
            >
              Save
            </ButtonRect>
          </div>
        </Form>
      </div>
    );
  }
}

export default AwardDetailsForm;
