import React from "react";
import PropTypes from "prop-types";
import {repsMasterFormater, masterFormater, feetAndInchesMasterFormater} from "../../+store/combine";
import {isRealNumber, formatResult, calcDistancePartsFromInches} from "../../../../containers/UxCommon/Utils";
import LaserImg from "../../../../assets/images/Laser_v1.png";
import top100UsaLogo from "../../../../assets/images/top_100_sports.png";
import ReactTooltip from "react-tooltip";
import {SubbankCodes, SubbankTooltip} from "../../+store";
import Icon from "../../../../components/UxCommon/Icon/Icon";
import {Link} from "react-router-dom";
import {
  expandedLeaderboardDialogPathTo,
  expandedNationalLeaderboardDialogPathTo, expandedNorthEastLeaderboardDialogPathTo,
  expandedAllTimeLeaderboardDialogPathTo,
  Routes, expandedDistrictLeaderboardDialogPathTo
} from "../../../../store/actions/ui/routes";
import {renderLeaderboardColoumLogo} from "./CellLogo";
import dashrLogoUrl from "../../../../assets/images/Dashr.png";


const CellContent = ({
                       athlete, column, isSortByColumn, selectedScoreType, location, match
                     }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const testResult = athlete.testResults[column.standardTestObjectId];
  const format = column.standardFormat.code;
  const videoId = testResult && testResult.videoId;
  const processed = !!(testResult && testResult.videoProcessed);
  const {schoolId, combineId, bank} = match.params;
  const {url} = match;
  const result = testResult && testResult.result;
  let resultDisplay;
  let resultLittleDisplay;
  let oldValue;
  let plusMinus;
  let difference;
  if (testResult) {
    switch (selectedScoreType) {
      case "raw": {
        if (column.useReps) {
          const {result, reps} = testResult;
          if (column.standardUnitOfMeasureCode === "FEET-INCHES") {
            resultDisplay = result ? feetAndInchesMasterFormater(format)(result) : '-';
          } else {
            resultDisplay = repsMasterFormater(format)({
              result,
              reps
            });
          }
        } else {
          const {result} = testResult;
          resultDisplay = masterFormater(format)(result);
        }
        if (isSortByColumn) {
          if (column.useReps && isRealNumber(testResult.multiRepMax) && column.standardUnitOfMeasureCode !== "FEET-INCHES") {
            resultLittleDisplay = formatResult(testResult.multiRepMax, format, false);
          } else {
            if (testResult.result) {
              if (column.useReps && column.standardUnitOfMeasureCode === "FEET-INCHES" && testResult.result) {
                resultLittleDisplay = formatResult(testResult.result, format, true);
              } else {
                resultLittleDisplay = formatResult(testResult.result, format, false);
              }
            } else {
              resultLittleDisplay = '';
            }
          }
          if (isRealNumber(athlete.oldValue)) {
            if(column.standardUnitOfMeasureCode === "FEET-INCHES"){
              oldValue = formatResult(athlete.oldValue, format, true);
            }else{
              oldValue = formatResult(athlete.oldValue, format, false);
            }
          } else {
            oldValue = athlete.oldValue || "-";
          }
        }
        break;
      }
      case "relative": {
        const {relativeStrength} = testResult;
        if (isRealNumber(relativeStrength)) {
          resultDisplay = masterFormater("PERCENTAGE-INTEGER")(relativeStrength);
        } else {
          resultDisplay = relativeStrength || "-";
        }
        if (isSortByColumn) {
          if (isRealNumber(relativeStrength)) {
            resultLittleDisplay = formatResult(relativeStrength, "PERCENTAGE-INTEGER", false);
          } else {
            resultLittleDisplay = relativeStrength || "-";
          }
          if (isRealNumber(athlete.oldValue)) {
            oldValue = formatResult(athlete.oldValue, "PERCENTAGE-INTEGER", false);
          } else {
            oldValue = athlete.oldValue || "-";
          }
        }
        break;
      }
      case "rated": {
        const {ratedResult} = testResult;
        if (isRealNumber(ratedResult)) {
          resultDisplay = masterFormater("SCORE")(ratedResult);
        } else {
          resultDisplay = ratedResult;
        }
        if (isSortByColumn) {
          if (isRealNumber(ratedResult)) {
            resultLittleDisplay = formatResult(ratedResult, "SCORE", false);
          } else {
            resultLittleDisplay = ratedResult || "-";
          }
          if (isRealNumber(athlete.oldValue)) {
            oldValue = formatResult(athlete.oldValue, "SCORE", false);
          } else {
            oldValue = athlete.oldValue || "-";
          }
        }
        break;
      }
      default:
        resultDisplay = "*";
    }
    if (isSortByColumn) {
      if (isRealNumber(athlete.difference)) {
        if (athlete.difference === 0) {
          plusMinus = "";
          difference = "";
        } else {
          plusMinus = athlete.difference < 0 ? "-" : "+";
          if (column.standardUnitOfMeasureCode === "FEET-INCHES") {
            const {feetInt, inchesInt, decimalString} = calcDistancePartsFromInches(Math.abs(athlete.difference));
            difference = `${feetInt}' ${inchesInt}.${decimalString}"`;
          }else{
            difference = formatResult(Math.abs(athlete.difference), format, false);
          }
        }
      } else {
        difference = "";
      }
    }
  } else {
    resultDisplay = "-";
  }

  const renderCategoryLogo = (subbankCode, hasResult = false, testCode = null) => {
    switch (subbankCode) {
      case SubbankCodes.BASEBALLHITTINGBLAST:
        return (
          <div>
            <i data-place="bottom" data-background-color="white" data-text-color="black"
               data-tip={SubbankTooltip.BASEBALLHITTINGBLAST}
               className={`Leaderboard_Subbank_Icon ${!hasResult ? "Leaderboard_NoPadding_Subbank_Icon" : ""} icon-blast-logo-circle-icon `}/>
            <ReactTooltip/>
          </div>
        );
      case SubbankCodes.COACHEVAL:
        if (testCode === "TOP100INDEX") {
          return (
            <div className="Top_100_Sport_Logo_Parent custom_logo_parent">
              <img src={top100UsaLogo} data-tip={SubbankTooltip.Top100SportLogo} data-place="bottom"
                   data-background-color="white" data-text-color="black" className="Top_100_Sport_Logo"/>
              <ReactTooltip/>
            </div>
          );
        }
      default:
        return "";

    }
  };

  let videoDialogPath = expandedLeaderboardDialogPathTo(schoolId, combineId, bank, videoId, location.search);
  if (url.includes(Routes.nationalLeaderboard)) {
    videoDialogPath = expandedNationalLeaderboardDialogPathTo(bank, videoId, location.search);
  }
  if (url.includes(Routes.northEastLeaderboard)) {
    videoDialogPath = expandedNorthEastLeaderboardDialogPathTo(bank, videoId, location.search);
  }

  if (url.includes(Routes.allTimeLeaderboard)) {
    videoDialogPath = expandedAllTimeLeaderboardDialogPathTo(bank, videoId, location.search, schoolId);
  }

  if (url.includes(Routes.districtLeaderboard)) {
    videoDialogPath = expandedDistrictLeaderboardDialogPathTo(bank, videoId, location.search, schoolId);
  }
  const resultToDisplay = (resultDisplay === 0 || result === 0 || result === null) ? '-' : resultDisplay;
  const diffToDisplay = (resultLittleDisplay === 0 || resultLittleDisplay === '0') ? '-' : resultLittleDisplay
  return (
    <div className="LeaderboardTable_CellValue">
      {
        isSortByColumn ? (
            <div style={{flexGrow: 1}}>
              <div className="LeaderboardTable_CellInner1">
                {resultToDisplay}
                {
                  !(/^-$/.test(resultDisplay)) && renderLeaderboardColoumLogo(column.subbankCode, testResult ? true : false, column.testCategoryCode)
                }
              </div>
              {
                !!column.isDashr &&
                <div className="leaderboardTable_dahsr_logo_label">
                  <img data-place="bottom" data-background-color="white" data-text-color="black"
                       className="leaderboard-label-dahsr-logo-label" src={dashrLogoUrl} alt="Dashr Laser Verified"
                       data-tip="Dashr Laser Verified" height={17} width={34}/>
                  <ReactTooltip/>
                </div>
              }
              {testResult && testResult.laserBool === 1 && !(!!column.isDashr) && (
                <div className="Leaderboard_Laser">
                  <img data-place="bottom" data-background-color="white" data-text-color="black" src={LaserImg}
                       alt="Laser Verified" data-tip="Laser Verified" width="22px"/>
                  <ReactTooltip/>
                </div>
              )
              }
              {
                oldValue !== "-" && oldValue != null && oldValue !== '0.00' && resultLittleDisplay &&
                (
                  <div className="LeaderboardTable_CellInner2">
                    <div className="LeaderboardTable_CellInner2a">
                      {`${oldValue} to ${diffToDisplay}`}
                    </div>
                    <div className={`LeaderboardTable_CellInner2b ${athlete.improvementClass}`}>
                      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                      {!!difference && !athlete.improvementClass && <span> {"("}</span>}
                      <span>{plusMinus}</span>
                      <span>{difference}</span>
                      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                      {!!difference && !athlete.improvementClass && <span>{")"}</span>}
                    </div>
                  </div>
                )
              }
              {
                (!oldValue || oldValue === "-") && diffToDisplay !== '-' && selectedScoreType === "raw" && !!column.useReps && column.standardUnitOfMeasureCode !== 'FEET-INCHES' && (
                  <div className="LeaderboardTable_CellInner2">
                    <div className="LeaderboardTable_CellInner2a">
                      {diffToDisplay}
                    </div>
                  </div>
                )
              }
              {
                videoId && processed && (
                  <Link to={videoDialogPath}
                        className="Leaderboard_Laser">
                    <Icon
                      iconName="play-circle2"
                      className="leaderboard_video_icon"
                      style={{color: "red", fontSize: 25, position: "relative"}}
                      place={"bottom"}
                      tooltip={isMobile ? "" : "Play video"}
                      tooltiptextColor={isMobile ? "transparent" : "black"}
                      tooltipbackgroundColor={isMobile ? "transparent" : "white"}
                    />

                  </Link>

                )
              }
            </div>
          ) :
          <div>
            <div className="LeaderboardTable_CellInner1">
              {resultToDisplay}

              {/* {   renderLeaderboardColoumLogo(column.subbankCode, testResult ? true : false, column.testCategoryCode)} */}
            </div>
            {
              !!column.isDashr &&
              <div className="leaderboardTable_dahsr_logo_label">
                <img data-place="bottom" data-background-color="white" data-text-color="black"
                     className="leaderboard-label-dahsr-logo-label" src={dashrLogoUrl} alt="Dashr Laser Verified"
                     data-tip="Dashr Laser Verified" height={17} width={34}/>
                <ReactTooltip/>
              </div>
            }
            {
              testResult && testResult.laserBool == 1 && !(!!column.isDashr) && (
                <div className="Leaderboard_Laser">
                  <img data-place="bottom" data-background-color="white" data-text-color="black" src={LaserImg}
                       alt="Laser Verified" data-tip="Laser Verified" width="22px"/>
                  <ReactTooltip/>
                </div>
              )
            }
            {
              videoId && processed && (
                <Link
                  to={videoDialogPath}
                  className="Leaderboard_Laser">
                  <Icon
                    iconName="play-circle2"
                    className="leaderboard_video_icon"
                    style={{color: "red", fontSize: 25, position: "relative"}}
                    place={"bottom"}
                    tooltip={isMobile ? "" : "Play video"}
                    tooltiptextColor={isMobile ? "transparent" : "black"}
                    tooltipbackgroundColor={isMobile ? "transparent" : "white"}
                  />
                </Link>
              )
            }
          </div>
      }
    </div>
  );
};
CellContent.propTypes = {
  athlete: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  isSortByColumn: PropTypes.bool.isRequired,
  selectedScoreType: PropTypes.string.isRequired
};

export default CellContent;
