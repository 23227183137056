export const Tooltip = {
    openMenu : "Open Menu",
    closeMenu : "Close Menu",
    athleteLeaderboard: "Leaderboard",
    collegeDirectory : "College Directory",
    playerProfile: "Player Profile",
    athleteEditProfile: "Edit Profile",
    playerData :"Player Data",
    athletePhotVideo: "Photos & Videos",
    athleteAward : "Awards",
    athleteJoinTeam : "Join Team",
    signOut : "Sign Out",
    TrainingVideo: 'Training Center',
    LevelUp:"Level Up"
  };
