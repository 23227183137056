import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../UxCommon/Icon/Icon';

class HeaderMenuButton extends PureComponent {
  static propTypes = {
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    nudge: PropTypes.object,
    className: PropTypes.string,
  };

  componentDidMount() {}

  render() {
    const {
      iconName,
      nudge,
      className,
      ...other
    } = this.props;
    return (
      <button
        className={['PageHeader_HeaderMenuButton', className || ''].join(' ')}
        {...other}
      >
        <Icon
          iconName={iconName}
          className="PageHeader_HeaderMenuButton_Icon"
          style={nudge || {}}
        />
      </button>
    );
  }
}

export default HeaderMenuButton;
