import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

const OpenCombineModal = ({ onCancel, onOpenCombine, combine }) => (
  <div className="OpenCombineModal_Backdrop">
    <div className="OpenCombineModal">
      <CloseButton size={15} onClick={onCancel} />
      <div className="OpenCombineModal_Header" />
      <div className="OpenCombineModal_Body">
        <div className="OpenCombineModal_Text1">Are you sure you want to open { combine ? `  ${combine.name}  ` : ' this '} combine?</div>
        <div className="OpenCombineModal_Text2">
          Once the combine is open, you will be able to add or edit Athlete&apos;s test results.
        </div>
      </div>
      <div className="OpenCombineModal_Footer">
        <ButtonRect
          className="OpenCombineModal_Btn primary"
          type="button"
          onClick={onOpenCombine}
        >
          Open Combine
        </ButtonRect>
        <ButtonRect
          className="OpenCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
);
OpenCombineModal.propTypes = {
  onOpenCombine: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  combine: PropTypes.object,
};


export default OpenCombineModal;
