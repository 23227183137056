import { combineEpics } from 'redux-observable';

import { readEpics } from './read';
import { createEpics } from './create';

export const teamsEpics = combineEpics(
  createEpics,
  readEpics,
);

export const dummy3 = {};
