import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ButtonRect, SplitButton } from '../../../../../../UxCommon';
import Icon from '../../../../../../UxCommon/Icon/Icon';

class TestObjectBtn extends PureComponent {
  static propTypes = {
    onEditClick: PropTypes.func.isRequired,
    onSelectClick: PropTypes.func.isRequired,
    object: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    userCanEdit: PropTypes.bool.isRequired,
  }

  onSelectClick = () => {
    const { object, onSelectClick } = this.props;
    onSelectClick(object);
  }

  onEditClick = () => {
    const { object, onEditClick } = this.props;
    onEditClick(object);
  }

  render() {
    const { object, selected, userCanEdit, testResults } = this.props;
    const filterData = testResults.find((testResult) => testResult.stdTestObjectId === object.id)
    return (
      userCanEdit ? (
        <SplitButton
          className={[
            'UxPlayerData_TestResultsData_CatBtn',
            'dark',
            selected ? 'selected' : '',
          ].join(' ')}
          iconName="lead-pencil"
          dataIconName= {!!filterData ? "tick": "plus"}
          onClickLeft={this.onEditClick}
          onClickRight={this.onSelectClick}
        >
          <div>{object.cat.name}</div>
        </SplitButton>
      ) : (
        <ButtonRect
          className={[
            'UxPlayerData_TestResultsData_CatBtn',
            'dark rect',
            selected ? 'selected' : '',
            'UxPlayerData_NoPadding'
          ].join(' ')}
          onClick={this.onSelectClick}
        >
          {object.cat.name}
          <Icon iconName={!!filterData ? "tick": "plus"} style={{ marginLeft: 3 }}/>
        </ButtonRect>
      )
    );
  }
}

export default TestObjectBtn;
