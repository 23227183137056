import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import { GET_AWARDS, GET_FAVORITE_AWARDS } from '../actions';
import { getAwards } from '../../../../data/user/awards';
import { mapAwardsApiToUi } from '../../../../data/user/awards/models';
import {
  awardGetFavoritesSuccess,
  awardsGetAllSuccess,
} from '../../../../data/user/awards/actions';

import { asyncErrorAction, asyncFinishAction } from '../../../async';
import {
  cancelOnRouteChange,
  commonApiCallFragment,
  getTokenFragment,
} from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

const getAwardsEpic = action$ => (
  action$.pipe(
    ofType(GET_AWARDS),
    getTokenFragment(),
    switchMap(({ action, token }) => getAwards(action.uuid, token).pipe(commonApiCallFragment(action$, action, 'getAwards', Routes.awards))),
    switchMap((result) => {
      if (result.success) {
        const awards = mapAwardsApiToUi(result.response);
        return of(
          awardsGetAllSuccess(awards, result.action.uuid),
          asyncFinishAction(result.action.type, 'getAwards', {}),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const getFavoriteAwardsEpic = action$ => (
  action$.pipe(
    ofType(GET_FAVORITE_AWARDS),
    getTokenFragment(),
    switchMap(({ action, token }) => getAwards(action.uuid, token).pipe(
      map(response => ({ action, success: true, awards: mapAwardsApiToUi(response) })),
      takeUntil(cancelOnRouteChange(action$, Routes.player, () => action.uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(action.type, 'getFavoriteAwards', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          awardGetFavoritesSuccess(result.awards, result.action.uuid, result.action.sportId),
          asyncFinishAction(result.action.type, 'getFavoriteAwards', {}),
        );
      }
      return of(result.action);
    }),
  )
);

const readEpics = combineEpics(
  getAwardsEpic,
  getFavoriteAwardsEpic,
);

export default readEpics;
