import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';

const SecondaryIconLink = ({
  iconName,
  children,
  className,
  to,
  ...props
}) => (
  <Link className={['UxCommon_SecondaryIconLink', className || ''].join(' ')} to={to} {...props}>
    <div className="UxCommon_SecondaryIconLink_Left">
      <Icon iconName={iconName} />
    </div>
    <div className="UxCommon_SecondaryIconLink_Right">
      {children}
    </div>
  </Link>
);
SecondaryIconLink.propTypes = {
  children: PropTypes.any,
  iconName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SecondaryIconLink;
