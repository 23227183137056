import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import { ButtonIconOnly } from '../../../UxCommon';


const PhoneControl = (props) => {
  const {
    label,
    model,
    type,
    help,
    helpClass,
    rrfField,
    errors,
    customIcon,
    onCustomIconClick,
    disabled,
    onPhoneChange,
    defaultCountry,
    ...otherProps
  } = props;
  if (rrfField.value == null && otherProps.defaultValue !== undefined) {
    otherProps.value = otherProps.defaultValue;
  }
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.valid ? '' : 'error',
          type === 'textarea' ? 'UxCommon_Form_TextareaGroup' : '',
          disabled ? 'disabled' : '',
        ].join(' ')}
      >
        <div className="UxCommon_Form_ErrorMarker" />
        <div className="UxCommon_Form_LabelWrapper phoneLabel">
          <label
            className={[
              'UxCommon_Form_Label',
              rrfField.value && rrfField.value.length ? 'show' : '',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        <Control
          model={model}
          component={PhoneInput}
          disabled={disabled}
          {...otherProps}
          mapProps={{
              onChange: () => onPhoneChange,
              country: defaultCountry,
              inputClass: 'UxCommon_Form_Input phoneInput',
              onlyCountries: ['us', 'ca', 'za'],
              preserveOrder: ['onlyCountries'],
              inputProps: { ...otherProps },
              priority: {ca: 1, us: 0 },
            }}
        />
        {onCustomIconClick && <ButtonIconOnly
          className="trashClass"
          iconName={customIcon}
          onClick={onCustomIconClick}
        />}
      </div>
      {
        help &&
        <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
      }
      <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
    </div>
  );
};
PhoneControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  help: PropTypes.any,
  rrfField: PropTypes.object.isRequired,
  helpClass: PropTypes.string,
  onCustomIconClick: PropTypes.func,
  errors: PropTypes.object,
  customIcon: PropTypes.string,
  disabled: PropTypes.bool,
  onPhoneChange: PropTypes.func.isRequired,
};

export default PhoneControl;
