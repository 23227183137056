import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR, ASYNC_PROGRESS } from '../../../../store/actions/ui/async';
import { CANCEL_API_REQUEST } from '../../../../store/actions/ui/uxProfile/utils';
import { AWARDS_GET_COMBINES, AWARDS_GET_COMBINES_AWARDS,AWARDS_GET_COMBINE_AWARDS } from './award.actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === AWARDS_GET_COMBINES) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === AWARDS_GET_COMBINES) {
        return '';
      }
      return state;
    case CANCEL_API_REQUEST:
      return '';
    default:
      return state;
  }
};


const loadingAwards = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === AWARDS_GET_COMBINES_AWARDS) {
        return true;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === AWARDS_GET_COMBINES_AWARDS) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const combines = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === AWARDS_GET_COMBINES) {
        return Object.assign({}, ...action.data.schoolCombine.map( schoolCombine => (
          {[schoolCombine.schoolId]:schoolCombine.combines})
        ))
      }
      return state;
    default:
      return state;
  }
};

const awards = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_PROGRESS:
      if (action.model === AWARDS_GET_COMBINES_AWARDS) {
        return  Object.assign({}, ...action.data.schoolCombineIds.map(schoolCombine => {
          return {[schoolCombine.schoolId]: Object.assign({}, state[action.data.schoolId], {
            [action.data.combineId]: action.data.awards,})
        }})
        );
      }
      if (action.model === AWARDS_GET_COMBINE_AWARDS) {
        return Object.assign({}, {
          [action.data.schoolId]: Object.assign({}, state[action.data.schoolId], {
            [action.data.combineId]: action.data.awards,
          }),
        });
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  loadingAwards,
  combines,
  awards,
});

export default reducer;
