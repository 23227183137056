import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PositionWidgetBtn from './PositionWidgetBtn';
import { getPositionWidgetLabel } from '../../../UxCommon/Form/utils';

class PositionWidget extends PureComponent {
  static propTypes = {
    positions: PropTypes.array.isRequired,
    rrfField: PropTypes.object.isRequired,
    onPositionSelect: PropTypes.func.isRequired,
    sportId: PropTypes.number.isRequired,
  }

  onBtnClick = (value) => {
    const { onPositionSelect } = this.props;
    let newSelected;
    const selected = this.getCurrentValue();
    if (selected.includes(value)) {
      newSelected = selected.filter(c => c !== value);
    } else {
      newSelected = [...selected, value];
    }
    onPositionSelect(newSelected);
  }

  getCurrentValue = () => {
    const { rrfField } = this.props;
    if (rrfField.$form.value) {
      const fieldValues = rrfField.$form.value.map((el) => el.trim());
      return fieldValues;
    }
    return [];
  }

  render() {
    const { positions, sportId } = this.props;
    if (!positions.length) return null;
    const label = getPositionWidgetLabel(sportId);
    return (
      <div className="EditSports_PositionWidget">
        <div className="EditSports_PositionWidget_Heading">
          {label}
        </div>
        <div className="EditSports_PositionWidget_Btns">
          {
            positions.map(position => (
              <PositionWidgetBtn
                key={position.value}
                name={position.name}
                value={position.value}
                selected={this.getCurrentValue().includes(position.value)}
                onClick={this.onBtnClick}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

export default PositionWidget;
