import pathToRegexp from 'path-to-regexp';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { ROUTE_CHANGED, Routes } from '../routes';
import { currentToken } from '../../data/cognito/auth';
import { ajaxErrorCustomHandlerFragment, ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { asyncErrorAction } from '../async';

export const cancelOnRouteChange = (action$, route, uuid) => action$.pipe(
  ofType(ROUTE_CHANGED),
  filter((action) => {
    if (action.pathname) {
      const re = pathToRegexp(`/${Routes.profile}/:uuid/:page/:sport?`);
      const params = re.exec(action.pathname);
      if (params && uuid() !== params[1]) {
        return true;
      }
      if (route && params && params[2] !== route) {
        return true;
      }
    }
    return false;
  }),
);

export const dummy = '';

export const checkForkErrors = () => source =>
  source.pipe(map((results) => {
    const failed = [];
    const succeeded = [];
    results.forEach((result) => {
      if (!result.success) {
        if (result.actions) {
          failed.push(...result.actions);
        } else {
          failed.push(result.action);
        }
      } else {
        succeeded.push(result);
      }
    });
    if (failed.length) {
      return {
        success: false,
        actions: failed,
        succeeded,
      };
    }
    return {
      success: true,
      results,
    };
  }));

export const getTokenFragment = () => source => (
  /* eslint-disable-next-line */
  source.pipe(switchMap((action) => {
    return from(currentToken()).pipe(map(({ token, uuid }) => ({ action, uuid, token })));
  }))
);

export const getTokenFragmentMap = mergeMap((action) => {
  return from(currentToken()).pipe(map(({ token, uuid }) => ({ action, uuid, token })));
});

export const getTokenFragmentMergeMap = () => source => (
  /* eslint-disable-next-line */
  source.pipe(mergeMap((action) => {
    return from(currentToken()).pipe(map(({ token, uuid }) => ({ action, uuid, token })));
  }))
);

export const commonApiCallFragment = (
  action$,
  action,
  errorLabel,
  page,
) => source => source.pipe(
  map(response => ({ action, success: true, response })),
  takeUntil(cancelOnRouteChange(action$, page, () => action.uuid)),
  ajaxErrorHandlerEpicFragment(action),
  catchError(error => of({
    success: false,
    action: asyncErrorAction(action.type, errorLabel, error),
    originalAction: action,
  })),
);

export const CANCEL_API_REQUEST = 'apiRequest.cancel';
export const cancelApiCalls = () => ({
  type: CANCEL_API_REQUEST,
});

export const cancelOnAction = (action$, cancelable) => (
  action$.pipe(filter(a => cancelable && a.type === CANCEL_API_REQUEST))
);

export const manualCancelApiCallFragment = (
  action$,
  action,
  errorLabel,
  incoming = {},
  cancelable = true,
) => source => source.pipe(
  map(response => ({
    ...incoming, action, success: true, response,
  })),
  takeUntil(cancelOnAction(action$, cancelable)),
  ajaxErrorCustomHandlerFragment(action),
  catchError(error => of({
    success: false,
    action: asyncErrorAction(action.type, errorLabel, error, { action }),
  })),
);
