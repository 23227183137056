import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { Link, Redirect } from 'react-router-dom';
import { mergeSignIn, Routes } from '../../../store/actions/ui/routes';
import axios from 'axios';
import {
  parserLower,
  parserName,
  parserPassword,
} from '../../../utils/parsers';

import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';

import { Roles } from '../../../store/actions/data/user/profile/models';
import { positions } from '../../UxCommon/Form/utils';
import PhoneControlComponent from '../../../modules/CodeSignUp/SimpleForm/PhoneControl';
import Urls from '../../../config/urls';
import { MergeTypes } from '../MergeSignIn/MergeSignIn';
import LoadingSpinner from '../../Common/LoadingSpinner/LoadingSpinner';
import SignupForm from "./components/SignupForm.component";

export const getPositions = (gender, sportId) => {
  const positionsArray = positions(sportId, gender);
  const positionsObj = {};
  // Todo: fix for wrestling
  Object.keys(positionsArray).forEach((pstn) => {
    positionsObj[positionsArray[pstn].value] = positionsArray[pstn].name;
  });
  return positionsObj;
};

const invitationCompleted = 'Oops! Expired Link';
const invitationCompletedDesc = ` You have clicked on a link that is no longer valid. Please head back to your inbox and click on the most recent email invitation. If you get stuck,`;
let contactEmail = `mailto:support@virtualcombine.com`;

const invitationInvalid = 'Invitation Invalid';
const invitationInvalidDesc = ` This invite link is no longer active.\n
                Please contact your coach to see if there is a new link available.`;

class InvitedSignUp extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    authenticatedProfile: PropTypes.object,
    getProfile: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    updatePosition: PropTypes.func.isRequired,
    authUuid: PropTypes.string,
    shouldCheckInviteValid: PropTypes.bool,
    isInvitationValid: PropTypes.object,
    checkIfInviteIsValid: PropTypes.func,
    countryCode: PropTypes.string.isRequired,
    updatePhoneNumber: PropTypes.func.isRequired,
    updateParentPhoneNumber: PropTypes.func.isRequired,
    updateConfirmPhoneNumber: PropTypes.func.isRequired,
    updateConfirmParentPhoneNumber: PropTypes.func.isRequired,
    updateParent2PhoneNumber: PropTypes.func.isRequired,
    updateConfirmParent2PhoneNumber: PropTypes.func.isRequired,
    duplicateProfiles: PropTypes.array.isRequired,
    updateFormError: PropTypes.func.isRequired,
    setDuplicateProfiles: PropTypes.func.isRequired,
    schoolStyle: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldSignUp: false,
      batThrowAllow: true,
      selectedCountry: '',
      shouldRedirectToMergingPopup: false,
      showLoader: false,
    };
  }

  componentDidMount() {
    const {
      match,
      getProfile,
      loggedIn,
      authUuid,
      shouldCheckInviteValid,
      checkIfInviteIsValid,
      isInvitationValid
    } = this.props;
    if (shouldCheckInviteValid) {
      const { userTeamId, token } = match.params;
      checkIfInviteIsValid(userTeamId, token);
    } else {
      getProfile(match.params.uuid);
    }
    if (loggedIn && authUuid) {
      getProfile(authUuid);
    }
  }

  componentDidUpdate() {
    const {
      getProfile,
      loggedIn,
      authUuid,
      authenticatedProfile,
      profile,
      match,
      shouldCheckInviteValid,
      checkIfInviteIsValid,
      updateBats,
      updateThrows
    } = this.props;

    const { batThrowAllow } = this.state;
    if (shouldCheckInviteValid) {
      const { token } = match.params;
      checkIfInviteIsValid(match.params.userTeamId, token);
    } else if (!profile && match.params.uuid) {
      getProfile(match.params.uuid);
    }
    if (
      loggedIn &&
      (!authenticatedProfile || authenticatedProfile.uuid !== authUuid)
    ) {
      getProfile(authUuid);
    }
    this.shouldInit();
    const sport =
      profile &&
      profile.sports.find((sport) => sport.sportId === match.params.sportId);
    if (sport && batThrowAllow) {
      if (sport.bats) {
        updateBats(sport.bats);
      }
      if (sport.throws) {
        setTimeout(() => {
          updateThrows(sport.throws);
        }, 100);
      }
      this.setState({ batThrowAllow: false });
    }
  }

  shouldInit = () => {
    const { profile, needsInit } = this.props;
    if (needsInit) {
      this.props.formInit(profile);
    }
  };

  submit = async () => {
    const { rrfForm, submit, profile, match, setDuplicateProfiles } = this.props;
    const { teamName, sportId, schoolId , token, userTeamId , coachName} = match.params
    try {
      this.setState({showLoader: true});
      const response = await axios.get(`${Urls.getDuplicateProfileByEmail(rrfForm.confirmUsername.value)}`);
      const duplicateProfile = response.data;
      await axios.put(`${Urls.updateProfileEmail(profile.uuid)}`,{
        email: duplicateProfile.email
      });
      setDuplicateProfiles(duplicateProfile.aws_uuid, duplicateProfile.email, duplicateProfile.given_name, duplicateProfile.family_name, profile.uuid)
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error));
      if (err.status === 404) {
        submit(profile, token, userTeamId, sportId, teamName, coachName, schoolId);
      }
    }finally {
      this.setState({showLoader: false});
    }

  };

  createAccountHandler = () => {
    this.setState({ shouldSignUp: true });
  };

  onPhoneChange = (isConfirm = false) => (
    value,
    data,
    event,
    formattedValue,
  ) => {
    const { updatePhoneNumber, updateConfirmPhoneNumber } = this.props;
    const parsedPhone = formattedValue ? formattedValue : value;
    if (isConfirm) {
      updateConfirmPhoneNumber(parsedPhone);
    } else {
      data && this.setState({
        selectedCountry: data.countryCode
      })
      updatePhoneNumber(parsedPhone);
    }
    return parsedPhone;
  };

  onParentPhoneChange = (isConfirm = false) => (
    value,
    data,
    event,
    formattedValue,
  ) => {
    const { updateParentPhoneNumber, updateConfirmParentPhoneNumber } = this.props;
    const parsedPhone = formattedValue ? formattedValue : value;
    if (isConfirm) {
      updateConfirmParentPhoneNumber(parsedPhone);
    } else {
      data && this.setState({
        selectedCountry: data.countryCode
      })
      updateParentPhoneNumber(parsedPhone);
    }
    return parsedPhone;
  };


  onParent2PhoneChange = (isConfirm = false) => (
    value,
    data,
    event,
    formattedValue,
  ) => {
    const { updateParent2PhoneNumber, updateConfirmParent2PhoneNumber } = this.props;
    const parsedPhone = formattedValue ? formattedValue : value;
    if (isConfirm) {
      updateConfirmParent2PhoneNumber(parsedPhone);
    } else {
      data && this.setState({
        selectedCountry: data.countryCode
      })
      updateParent2PhoneNumber(parsedPhone);
    }
    return parsedPhone;
  };

  renderRedirect = () => {
    const { authenticatedProfile, match, isInvitationValid } = this.props;
    let redirectTo, msg;
    if (!isInvitationValid.isValid) {
      if (authenticatedProfile.roleId === Roles.athlete) {
        redirectTo = `/${Routes.profile}`;
        msg = 'Go to Profile';
      } else if (authenticatedProfile.roleId === Roles.coach) {
        redirectTo = `/${Routes.coach}/${Routes.dashboard}`;
        msg = 'Go to Dashboard';
      }
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">
                {!isInvitationValid.isTokenExist
                  ? invitationCompleted
                  : invitationInvalid}
              </div>
              <div className="welcome-desc">
                {!isInvitationValid.isTokenExist
                  ? invitationCompletedDesc
                  : invitationInvalidDesc}

                {
                  !isInvitationValid.isTokenExist &&
                  <a href={contactEmail}> Email us.</a>
                }
                <div className="welcome-btns">
                  <Link className="welcome-btn" to={redirectTo}>
                    {msg}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Redirect
        to={mergeSignIn(
          MergeTypes.mergeBoth,
          match.params.uuid,
          match.params.token,
          match.params.teamName,
          match.params.coachName,
          match.params.sportId,
          match.params.userTeamId
        )}
      />
    );
  };

  getRedirectUrl = () => {
    const { params } = this.props.match;
    const { uuid, teamName, token, coachName, sportId, userTeamId, schoolId } = params;
    if(schoolId) {
      return `/${Routes.welcome}/${Routes.pma}/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}`;
    }
    return `/welcome/invited/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}`;
  };

  loadSignupForm = () => {
    const { countryCode, rrfForm, profile, formGeneralError } = this.props;
    const {selectedCountry} = this.state;

    return (
      <SignupForm
        countryCode={countryCode}
        rrfForm={rrfForm}
        profile={profile}
        submit={this.submit}
        formOnKeyDown={this.formOnKeyDown}
        onPhoneChange={this.onPhoneChange}
        onParentPhoneChange={this.onParentPhoneChange}
        onParent2PhoneChange={this.onParent2PhoneChange}
        selectedCountry={selectedCountry}
        formGeneralError={formGeneralError}
        handlePwFocus={this.handlePwFocus}
      />
    )
  };

  loadDefaultView = () => {

    return (
      <div className="col-lg-8 justify-content-center">
        <div className="welcome-btns">
          <button className="welcome-btn" onClick={this.createAccountHandler}>
            Create Your VC Account
          </button>
        </div>
      </div>
    );
  };

  loadView = () => {
    const { shouldSignUp } = this.state;
    const { profile , duplicateProfiles} = this.props;
    // if user is already subscribed then show signup form directly
    if (shouldSignUp || duplicateProfiles.length > 0 || (profile.isPaidProfile && profile.isSubscribed)) {
      return this.loadSignupForm();
    }

    return this.loadDefaultView();
  };

  render = () => {
    const {
      match,
      authenticatedProfile,
      loggedIn,
      isInvitationValid,
      shouldCheckInviteValid,
      forceLogout,
      isInviteUserLoggedIn,
      profile,
      schoolStyle,
    } = this.props;

    const { showLoader } = this.state;
    if (
      isInvitationValid &&
      isInvitationValid.isValid &&
      loggedIn &&
      !isInviteUserLoggedIn
    ) {
      forceLogout(this.getRedirectUrl());
    } else if (loggedIn && authenticatedProfile && !profile.step) {
      return this.renderRedirect();
    }


    if (
      !shouldCheckInviteValid &&
      (!isInvitationValid || isInvitationValid.isValid === false)
    ) {
      return (
        <div className="container welcome-first">
          <div className="row">
            <div className="col">
              <div className="welcome-msg">
                {!isInvitationValid ? invitationCompleted : invitationInvalid}
              </div>
              <div className="welcome-desc">
                {!isInvitationValid
                  ? invitationCompletedDesc
                  : invitationInvalidDesc}

                { !isInvitationValid &&
                  <a href={contactEmail}> Email us.</a>
                }
                <div className="welcome-btns">
                  <Link
                    className="welcome-btn"
                    to={`/${Routes.welcome}/${Routes.login}`}>
                    {' '}
                    Sign In{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if(profile.isPaidProfile && profile.isSubscribed && profile.registered){
      return (
        <Redirect
          to={mergeSignIn(
            MergeTypes.signIn,
            match.params.uuid,
            match.params.token,
            match.params.teamName,
            match.params.coachName,
            match.params.sportId,
            match.params.userTeamId,
          )}
        />
      );
    }

    const schoolName = schoolStyle ? schoolStyle.name: 'Virtual Combine';

    return (
      <div className="container welcome-first">
        <LoadingSpinner
          loading={showLoader}
          loadingMsg={"Please wait ..."}
          wrapperStyle={{ top: 300 }}
        />
        <div className="row">
          <div className="col">
            <div className="welcome-msg">Welcome to {schoolName}!</div>
            <div className="welcome-desc">
              {`You've been invited to join ${match.params.teamName.replace(
                /\+/g,
                ' ',
              )}!`}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">{this.loadView()}</div>
      </div>
    );
  }
}

export default InvitedSignUp;
