import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '../../common/Table';
import AthleteMedia from '../AthleteMedia/AthleteMedia.component';
import {
  AllowedCharacters,
  CombineValidation,
  ParseEntries,
  masterFormater,
  repsMasterFormater,
  editMasterFormater,
} from '../../+store/combine';
import CombineEntry from '../CombineEntry/CombineEntry.component';
import CombineRepsEntry from '../CombineRepsEntry/CombineRepsEntry.component';
import { checkCanEditCombineResults } from '../../+store/combine/combine.utils';
import CombineLaserEntry from '../CombineLaserEntry/CombineLaserEntry.component';
import { CustomVideoIcon } from '../../../../components/UxCommon';


const CombineRow = React.memo(({
  combine,
  athlete,
  getTestResult,
  onSaveTestResult,
  myTeams,
  isCoach,
  setExpiringCombineMsg,
  rowIndex,
  onEnterPressed,
  isSavingArray,
  columns,
  getOtherCellsWidth,
  getCellOneWidth,
  tableWidth,
  bank,
  screenWidth,
  videos,
  setSelectedColumn,
  selectedColumn,
  onVideoUploadHandler,
  onVideoDeleteHandler,
  updateAthleteVideoId,
  unstageNewMedia, stagedMedia, clearAthleteVideoId,
  deleteVideoId,
  location,
  getRefreshPopUp,
  setOverFlowClass
}) => {
    const [isSelected, setIsSelected] = useState(false);
    const getTooltip = column => () => column.tooltip;
    const setSelectedRowColumn = columnIndex => (v) => {
      setSelectedColumn(v ? columnIndex : -1);
      setIsSelected(v);
    };

    const setOverFlowClassToTable = (isFocus) => {
      setOverFlowClass(isFocus);
    }
    
    const renderCombine = (column, index) => {
      const unit = column.displayStandardUnitOfMeasureCode;
      const format = column.standardFormat.code;
      const isSavingObj = isSavingArray.find(s => s.columnRowId === `${athlete.userId}:${column.standardTestObjectId}`);
      const schoolId = combine.schoolId;
      const combineId = combine.id;
      const athleteId = athlete.userId;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      if (column.useReps) {
        return (
          <CombineRepsEntry
            canNotEditReason={checkCanEditCombineResults(myTeams, athlete, combine)}
            value={getTestResult(athlete, column, true)}
            onSave={onSaveTestResult(athlete, column)}
            formatToDisplay={repsMasterFormater(format)}
            formatToEdit={editMasterFormater(format)}
            help={getTooltip(column)}
            allowedCharTest={AllowedCharacters(format)[unit]}
            validate={CombineValidation(format)[unit]}
            validateReps={CombineValidation(format).REPS}
            isCoach={isCoach}
            setExpiringCombineMsg={setExpiringCombineMsg}
            columnIndex={index + 1}
            rowIndex={rowIndex}
            schoolId={schoolId}
            combineId={combineId}
            bank={bank}
            athlete={athlete}
            column={column} 
            combine ={combine}
            location={location}
            isMobile={isMobile}
            videos={videos}
            unstageNewMedia={unstageNewMedia}
            stagedMedia={stagedMedia}
            clearAthleteVideoId={clearAthleteVideoId}
            athleteId={athleteId}
            deleteVideoId={deleteVideoId}
            updateAthleteVideoId={ updateAthleteVideoId}
            onVideoUploadHandler={onVideoUploadHandler}
            onVideoDeleteHandler={onVideoDeleteHandler}
            testBankCode ={column.testBankCode}
            standardTestObjectId={column.standardTestObjectId}
            onEnterPressed={onEnterPressed}
            getRefreshPopUp={getRefreshPopUp}
            withinEstimated={isSavingObj && isSavingObj.isEstimatedTime}
            isSaving={isSavingObj && isSavingObj.isSaving}
            isAthleteCoachedByUser={athlete.isAthleteCoachedByUser}
            isCombineOpen={combine.isOpen}
            setIsSelected={setSelectedRowColumn(index)}
            setOverFlowClassToTable={setOverFlowClassToTable}
          />
        );
      } else if (column.useLaser) {
        return (
          <CombineLaserEntry
            canNotEditReason={checkCanEditCombineResults(myTeams, athlete, combine)}
            value={getTestResult(athlete, column, false, true)}
            onSave={onSaveTestResult(athlete, column)}
            formatToDisplay={masterFormater(format)}
            formatToEdit={editMasterFormater(format)}
            help={getTooltip(column)}
            allowedCharTest={AllowedCharacters(format)[unit]}
            validate={CombineValidation(format)[unit]}
            parse={ParseEntries(format)[unit]}
            isCoach={isCoach}
            isMobile={isMobile}
            onVideoUploadHandler={onVideoUploadHandler}
            onVideoDeleteHandler={onVideoDeleteHandler}
            testBankCode ={column.testBankCode}
            setExpiringCombineMsg={setExpiringCombineMsg}
            columnIndex={index + 1}
            rowIndex={rowIndex}
            schoolId={schoolId}
            combineId={combineId}
            bank={bank}
            athlete={athlete}
            column={column} 
            combine ={combine}
            location={location}
            videos={videos}
            deleteVideoId={deleteVideoId}
            standardTestObjectId={column.standardTestObjectId}
            unstageNewMedia={unstageNewMedia}
            stagedMedia={stagedMedia}
            clearAthleteVideoId={clearAthleteVideoId}
            athleteId={athleteId}
            updateAthleteVideoId={ updateAthleteVideoId}
            onEnterPressed={onEnterPressed}
            getRefreshPopUp={getRefreshPopUp}
            withinEstimated={isSavingObj && isSavingObj.isEstimatedTime}
            isSaving={isSavingObj && isSavingObj.isSaving}
            isAthleteCoachedByUser={athlete.isAthleteCoachedByUser}
            isCombineOpen={combine.isOpen}
            setIsSelected={setSelectedRowColumn(index)}
            setOverFlowClassToTable={setOverFlowClassToTable}
          />
        );
      }
      return (
        <CombineEntry
          canNotEditReason={checkCanEditCombineResults(myTeams, athlete, combine)}
          value={getTestResult(athlete, column)}
          onSave={onSaveTestResult(athlete, column)}
          formatToDisplay={masterFormater(format)}
          formatToEdit={editMasterFormater(format)}
          help={getTooltip(column)}
          onVideoUploadHandler={onVideoUploadHandler}
          onVideoDeleteHandler={onVideoDeleteHandler}
          testBankCode ={column.testBankCode}
          allowedCharTest={AllowedCharacters(format)[unit] || null}
          validate={CombineValidation(format)[unit]}
          parse={ParseEntries(format)[unit]}
          isCoach={isCoach}
          schoolId={schoolId}
          isMobile={isMobile}
          deleteVideoId={deleteVideoId}
          combineId={combineId}
          bank={bank}
          athlete={athlete}
          column={column} 
          combine ={combine}
          location={location}
          videos={videos}
          unstageNewMedia={unstageNewMedia}
          stagedMedia={stagedMedia}
          clearAthleteVideoId={clearAthleteVideoId}
          athleteId={athleteId}
          standardTestObjectId={column.standardTestObjectId}
          updateAthleteVideoId={ updateAthleteVideoId}
          setExpiringCombineMsg={setExpiringCombineMsg}
          columnIndex={index + 1}
          rowIndex={rowIndex}
          onEnterPressed={onEnterPressed}
          getRefreshPopUp={getRefreshPopUp}
          withinEstimated={isSavingObj && isSavingObj.isEstimatedTime}
          isSaving={isSavingObj && isSavingObj.isSaving}
          column={column}
          isAthleteCoachedByUser={athlete.isAthleteCoachedByUser}
          isCombineOpen={combine.isOpen}
          setIsSelected={setSelectedRowColumn(index)}
          setOverFlowClassToTable={setOverFlowClassToTable}
        />
      );
    }

    return (
      <TableRow className={isSelected ? 'selected' : ''} style={{ width: tableWidth }}>
        <TableCell isHeader style={{ width: getCellOneWidth() }} className={rowIndex % 2 === 1 ? 'even' : 'odd'}>
          <AthleteMedia athlete={athlete} />
        </TableCell>
        {
          columns.map((column, index) => (
            <TableCell
              colSpan={index + 1}
              key={column.testCategoryCode}
              style={{ width: getOtherCellsWidth() }}
              className={[selectedColumn === index ? 'selectedColumn' : '', "TableColumnCell", columns.length < 2 ? 'combineOneCell' : ''].join(" ")}
            >
              {
                renderCombine(column, index)
              }
            </TableCell>
          ))
        }
      </TableRow>
    );
});

CombineRow.propTypes = {
  myTeams: PropTypes.array.isRequired,
  combine: PropTypes.object.isRequired,
  athlete: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  getTestResult: PropTypes.func.isRequired,
  onSaveTestResult: PropTypes.func.isRequired,
  getOtherCellsWidth: PropTypes.func.isRequired,
  getCellOneWidth: PropTypes.func.isRequired,
  isCoach: PropTypes.bool.isRequired,
  setExpiringCombineMsg: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onEnterPressed: PropTypes.func.isRequired,
  isSavingArray: PropTypes.array,
  screenWidth: PropTypes.number,
  tableWidth: PropTypes.any,
  setSelectedColumn: PropTypes.func,
  selectedColumn: PropTypes.number,
};

export default CombineRow;
