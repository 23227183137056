import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ExistingGridView from '../ExistingGridView';
import NewGridView from '../NewGridView';
import {useDispatch} from "react-redux";
import {getProfiles} from "../../+store";
import {useHistory} from "react-router-dom";
import ReconfigureTeamGridView from "../ExistingGridView/ReconfigureTeamGridView.container";

const GridView = ({reconfigureView,
                    match,
                    team,
                    athletes,
                    profilesLoading
                  }) => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [newView, setNewView] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(true);

  useEffect(() => {
    if (athletes && athletes.length > 0) {
      const athleteIds = athletes.map(athlete => athlete.userId)
      dispatch(getProfiles(athleteIds));
    } else {
      setNewView(true);
    }
  }, [team])

  const cssHasLoaded = () => {
    // test if the theme has loaded by looking for the effect of a known style,
    // in this case we know that the theme applies padding to cells
    const themeEl = document.createElement('div');
    document.body.appendChild(themeEl);

    try {
      themeEl.className = document.querySelector("[class^='ag-theme']").className;

      const cellEl = document.createElement('div');

      cellEl.className = 'ag-cell';
      themeEl.appendChild(cellEl);

      const computedStyle = window.getComputedStyle(cellEl);

      return parseFloat(computedStyle.paddingLeft) > 0;
    } catch (err) {
      return false;
    } finally {
      document.body.removeChild(themeEl);
    }
  }

  const initialise = () => {
    if (cssHasLoaded()) {
      setCssLoaded(true);
    } else {
      setTimeout(initialise, 100);
    }
  }

  if (profilesLoading) {
    return (
      <div className="CoachTeamBox_NoResults">
        Loading profiles ...
      </div>
    );
  }

  if (!cssLoaded) {
    return (
      <div className="CoachTeamBox_NoResults">
        Preparing grid ...
      </div>
    );
  }

  return (
    <div style={{width: '100%', height: '900px'}}>
      {
        newView && !reconfigureView
          ?
          <NewGridView
            match={match}
            history={history}
            team={team}
            location={location}
            changeView={() => setNewView(false)}
            newTeam={!athletes || athletes.length === 0}
          />
          :
          (reconfigureView ?
              <ReconfigureTeamGridView
                match={match}
                history={history}
                reconfigureView={reconfigureView}
                team={team}
                location={location}
                changeView={() => setNewView(true)}
              />
              :
              <ExistingGridView
                match={match}
                history={history}
                team={team}
                location={location}
                changeView={() => setNewView(true)}
              />
          )
      }
    </div>
  );

}

GridView.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  athletes: PropTypes.array.isRequired,
  getProfiles: PropTypes.func.isRequired,
  profilesLoading: PropTypes.bool.isRequired,
}

export default GridView;
