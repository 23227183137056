import Media from './Media';

export default class Photo extends Media {
  constructor() {
    super();
    this.key = '';
    this.resizedPhotos = {
      large: '',
      medium: '',
      small: '',
      thumb: '',
    };
    this.url = '';
    this.explicitTags = null;
  }

  clone() {
    let photo = new Photo();
    photo = super.clone();
    photo.resizedPhotos = this.resizedPhotos;
    photo.key = this.key;
    photo.explicitTags = this.explicitTags;
    photo.url = this.url;
    return photo;
  }

  fromApiResponse(response) {
    super.fromApiResponse(response);
    this.resizedPhotos = response.resized_photos;
    this.key = response.key;
    this.url = response.url;
    try {
      this.explicitTags = JSON.parse(response.explicit_tags);
    } catch (e) {
      this.explicitTags = null;
    }
  }
}

