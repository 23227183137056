import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/PlayerProfile';
import {getSelectedSport, getSortedSports, getCurrentUuid, getProfile, getCurrentSportId} from '../UxCommon/Utils';
import { sidebarOpenShareDialog } from '../../store/actions/ui/uxProfile';
import { profileShareUri } from '../../store/actions/ui/routes';
import {getPlayerData} from '../../modules/ProspectSheet/+store/prospectSheet';
import { playerPersonalRecords } from '../../store/actions/ui/uxPlayerData';
import { getFavoriteAwards } from '../../store/actions/ui/uxProfile/awards';
import { playerStatsGet } from '../../store/actions/ui/uxPlayerData';
import { Sports } from '../../store/actions/ui/routes';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  const currSportId = selectedUserSport && selectedUserSport.sportId ;
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return {
  canEditProfile: state.ui.app.context.canEditProfile,
  isCoach,
  user: getProfile(state, ownProps.match),
  currUuid: uuid,
  userSports: getSortedSports(state, ownProps.match),
  selectedUserSport,
  combineRecords:  state.data.sport.combineRecords,
  favorites: state.data.photo.favorites[getCurrentUuid(state, ownProps.match)] || [],
  sports: state.data.sport.sports,
  isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
    || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
  state,
  favoritesAwards : state.data.user.favoriteAwards[uuid] || [],
  userStats: currSportId && state.data.sport.userStats[uuid] &&
              state.data.sport.userStats[uuid][currSportId] &&
              state.data.sport.userStats[uuid][currSportId].teams || [],
    externalProfile : state.data.user.externalProfile[uuid] || [],
    currentSportId: getCurrentSportId(state),
    athleteTeamRosters: state.ui.uxProfile.playerProfile.athleteTeamRosters,
    isLoggedIn: !!state.data.cognito.uuid,
}
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const uuid = getCurrentUuid(state, ownProps.match);
  return {
    ...newStateProps,
    ...ownProps,
    onShareClick: () => {
      dispatch(sidebarOpenShareDialog(profileShareUri(
        uuid,
        newStateProps.selectedUserSport.sportId,
      )));
    },
    getTests: (uuid, sport, msg) =>{
      dispatch(playerPersonalRecords(uuid, sport, msg))
    },
    getFavoriteAwards: (uuid,sportId) => {
      dispatch(getFavoriteAwards(uuid,sportId));
    },
    getStats: (uuid, sportId, msg = '') => {
      dispatch(playerStatsGet(uuid, sportId, msg));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
