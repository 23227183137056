import React from 'react';
import PropTypes from 'prop-types';

/* eslint react/no-unused-state: 0 */

class GetWindowSize extends React.PureComponent {
  static propTypes = {
    getWindowX: PropTypes.func,
    getWindowY: PropTypes.func,
    updateOthers: PropTypes.func,
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const { getWindowX, getWindowY, updateOthers } = this.props;
    const y = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const x = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (getWindowX) {
      getWindowX(x);
    }
    if (getWindowY) {
      getWindowY(y);
    }
    if (updateOthers) {
      updateOthers();
    }
  }

  render() {
    return <div />;
  }
}

export default GetWindowSize;
