import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/PhotoFilterDialog/PhotoFilterDialog';
import {
  selectCategoryPhotos,
  selectCategoryVideos,
  selectCategoryAll,
  albumFilterSelected,
  albumFilterUnselected,
  tagFilterSelected,
  tagFilterUnselected,
  hideFilterDialog,
  clearAllPhotoFilters,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { getCurrentUuid } from '../../UxCommon/Utils';
import { makeGetAlbumsState, makeGetTags, makeGetTagRelMedia } from '../../../selectors/Photos';

const getAlbumsState = makeGetAlbumsState();
const getTagsState = makeGetTags();
const getTagsRelMediaState = makeGetTagRelMedia();

const mapStateToProps = (state, ownProps) => ({
  albums: getAlbumsState(state, ownProps),
  tags: getTagsState(state, ownProps),
  tagsRelMedia: getTagsRelMediaState(state, ownProps),
  isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
    || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
  selectedAlbums: state.ui.uxProfile.photosVideos.selectedAlbumFilters,
  selectedCategory: state.ui.uxProfile.photosVideos.selectedCategory,
  selectedTagsFilters: state.ui.uxProfile.photosVideos.selectedTagsFilters,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onPhotosClick: () => {
      dispatch(selectCategoryPhotos());
    },
    onVideosClick: () => {
      dispatch(selectCategoryVideos());
    },
    onAllClick: () => {
      dispatch(selectCategoryAll());
    },
    onAlbumSelected: (albumId) => {
      dispatch(albumFilterSelected(albumId));
    },
    onAlbumUnselected: (albumId) => {
      dispatch(albumFilterUnselected(albumId));
    },
    onTagSelected: (tagId) => {
      dispatch(tagFilterSelected(tagId));
    },
    onTagUnselected: (tagId) => {
      dispatch(tagFilterUnselected(tagId));
    },
    onCloseClick: () => {
      dispatch(hideFilterDialog());
    },
    onClearAllClick: () => {
      dispatch(clearAllPhotoFilters());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
