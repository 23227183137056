import { connect } from 'react-redux';
import Component from './Profile.component';
import { saveCoachProfile, selectCoachProfilePhotoUrl, hideUploadPhotoDialog, showUploadPhotoDialog } from '../../+store';

const mapStateToProps = state => ({
  coachProfile: state.data.user.profiles[state.data.cognito.uuid],
  profilePhotoURL: selectCoachProfilePhotoUrl(state),
  isUploadPhotoDialogVisible: state.modules.coachWorld.coachWorld.isUploadPhotoDialogVisible,
});

const mapDispatchToProps = dispatch => ({
  saveCoach: (values, uuid) => {
    dispatch(saveCoachProfile(values, uuid));
  },
  hideUploadPhotoDialog: () => dispatch(hideUploadPhotoDialog()),
  showUploadPhotoDialog: () => dispatch(showUploadPhotoDialog()),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
