import { validateRequired } from '../../../store/actions/ui/formUtils/validators';

export const checkRequired = (value, msg = '') => {
  const error = validateRequired(msg)(value).required;
  return error ? [error] : [];
};

export const checkAndCorrectHeight = (valueFt, valueIn) => {
  let heightFt = Number(valueFt);
  let heightIn = Number(valueIn);
  let errors = [];
  if ((Number.isNaN(heightFt) || Number.isNaN(heightIn))) {
    errors.push('Please enter a valid height');
  } else {
    errors = [];
    heightFt = Math.floor(heightFt + (heightIn / 12));
    heightIn %= 12;
  }
  const totalHeight = (heightFt * 12) + heightIn;
  if ((totalHeight < 12)
    || (totalHeight > 120)) {
    errors.push('Please enter a valid height');
  }
  return {
    heightFt,
    heightIn,
    errors,
  };
};


export const convertCmToFt = valueMt => valueMt * 0.0328084;

export const convertCmToInches = valueMt => valueMt * 0.393701;

export const convertInchesToCm = valueMt => valueMt * 2.54;

export const convertInchesToMetre = valueIn => valueIn * 0.0254;

export const checkAndCorrectMetricHeight = (valueMt, valueCm) => {
  let heightMt = Number(valueMt);
  let heightCm = Number(valueCm);
  let errors = [];
  if ((Number.isNaN(heightMt) || Number.isNaN(heightCm))) {
    errors.push('Please enter a valid height');
  } else {
    errors = [];
    heightMt = Math.floor(heightMt + (heightCm / 100));
    heightCm %= 100;
  }
  const totalHeightCm = (heightMt * 100) + heightCm;
  const totalHeightFt = convertCmToInches(totalHeightCm);
  if ((totalHeightFt < 12)
    || (totalHeightFt > 120)) {
    errors.push('Please enter a valid height');
  }
  return {
    heightMt,
    heightCm,
    errors,
  };
};

export const checkStandardWeight = (value) => {
  let error = validateRequired()(value).required;
  if (!error) {
    const weight = Number(value);
    if (Number.isNaN(weight)) {
      error = 'Please enter a valid weight';
    }
    if ((weight < 50) || (weight > 500)) {
      error = 'Please enter a valid weight';
    }
  }
  return error ? [error] : [];
};

export const convertKgToLbs = value => value * 2.20462;

export const convertLbsToKg = value => value * 0.453592;

export const checkKgWeight = (value) => {
  let error = validateRequired()(value).required;
  if (!error) {
    let weight = Number(value);
    if (Number.isNaN(weight)) {
      error = 'Please enter a valid weight';
    }
    weight = convertKgToLbs(weight);
    if ((weight < 50) || (weight > 500)) {
      error = 'Please enter a valid weight';
    }
  }
  return error ? [error] : [];
};

export const checkWeight = (value, metricUnits = false) =>
  (
    metricUnits
      ? checkKgWeight(value)
      : checkStandardWeight(value)
  );

export const checkYearValidation = (value) => {
  let error = validateRequired()(value).required;
  if ( error) {
    return  [error];
  }
  let errorFound = false;
  // let grad_year= Number(value);
  // if (Number.isNaN(grad_year)) {
  //   errorFound = true;
  // }
  if (!value || !(/^[0-9]{4}[0-9a-zA-Z- '",_]*/.test(value))) {
    errorFound = true;
    error = 'Please enter a valid Grad Year';
  }

  return errorFound  ? [error] : [];
}

export const checkZipcode = (value) => {
  let error = '';
  let isError = false;
  let newValue = Number(value);
  if(Number.isNaN(newValue)) {
    isError = true;
    error = 'This field can only contain numbers'
  }
  if(value.length > 5) {
    isError = true;
    error = 'Please enter a valid zipcode';
  }
  return isError ? [error] : [];
}

export const checkCityState = (value) => {
  let error = '';
  let isError = false;
  if(!(/^[a-zA-Z\s]*$/i).test(value)) {
    isError = true;
    error = 'This field can only contain letters';
  }
  return isError ? [error] : [];
}

export const checkAddress = (value) => {
  let error = '';
  let isError = false;
  if(!(/^[a-zA-Z0-9\s,.():;*@"'_&-]*$/i).test(value)) {
    isError = true;
    error = 'This field can only contain letters with space and some special characters';
  }
  return isError ? [error] : [];
}

export const checkAddress2 = (value) => {
  let error = '';
  let isError = false;
  if(!(/^[a-zA-Z0-9\s,.():;*@"'_&-]*$/i).test(value)) {
    isError = true;
    error = 'This field can only contain letters with space and some special characters';
  }
  return isError ? [error] : [];
}
