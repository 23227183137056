import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Routes } from "../../../../store/actions/ui/routes";
import LeaderboardCard from "./LeaderboardCard.component";
import {
  BEST_IN_US_SCHOOLS,
  DISTRICT_LEADERBOARD_COACHES,
  SPECTS_ALLTIME_SCHOOLS,
  TEXAS_PREMIER_SCHOOL
} from "../../+store";
import {privacyType} from "../../../../utils";
import PrivacyControl
  from "../../../../components/UxCommon/LeaderboardPrivacyControl/LeaderboardPrivacyControl";
import ButtonIconOnly from "../../../../components/UxCommon/ButtonIconOnly/ButtonIconOnly";
import {CloseButton, Header} from "../../../../components/UxCommon";
import {Button} from "../../../../components/UxCommon/Form";
import Icon from "../../../../components/UxCommon/Icon/Icon";
import TooltipIcon from "../../common/TooltipIcon";
import GeneralMessageModal from "../../../../components/UxCommon/GeneralMessageModal/GeneralMessageModal";

class Leaderboards extends PureComponent {
  static propTypes = {
    schoolIds: PropTypes.array,
    getSchoolCombines: PropTypes.func,
    combines: PropTypes.array.isRequired,
    athleteCombineResults: PropTypes.array,
    sports: PropTypes.array.isRequired,
    coachSports: PropTypes.object,
    isCoach:PropTypes.bool,
    coachProfile: PropTypes.any,
    schoolStyles: PropTypes.array.isRequired,
  };

  state = {
    openModal: false,
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => {
    const {
      schoolIds,
      getSchoolCombines,
      match
    } = this.props;
    if (!this.combinesFetched && schoolIds && schoolIds.length) {
      const schoolIdsArr = schoolIds.filter(schoolId => schoolId === match.params.schoolId);
      getSchoolCombines(schoolIdsArr);
      this.combinesFetched = true;
    }
  };

  isEmptyObj = (obj) => (!obj || Object.keys(obj).length === 0);

  shouldShowNL = () => {
    const { coachSports } = this.props;
    const NLSports = ["Football", "Baseball"];
    if (this.isEmptyObj(coachSports)) {
      return false;
    }
    return !!(NLSports.filter(sport => Object.values(coachSports).includes(sport)).length);
  };

  getCurrentSchool = () => {
    const { match , schoolStyles } = this.props;
    const { schoolId } = match.params;

    if ( schoolStyles.length ) {
     return schoolStyles.find(school => school.id === schoolId);
    }
    return null;
  }

  render() {
    const {
      combines,
      athleteCombineResults,
      match,
      isCoach,
      coachProfile,
      schoolStyles
    } = this.props;

    const { openModal } = this.state;

    const currentSchool = this.getCurrentSchool();

    const currentSchoolId = match.params.schoolId;
    return (

      <div className="LeaderBoardWrapper">
          <div className="CustomNavbar">
            <a className="CoachNavBarLink  active">Combines</a>
            {
              !!(currentSchool && currentSchool.isActive) &&
              ( <Link className="CoachNavBarLink" target="_blank"
                  to={`/${Routes.coach}/${currentSchoolId}/${Routes.allTimeLeaderboard}?`}>All Time</Link>
              )
            }
            {/*{*/}
            {/*  ( coachProfile && DISTRICT_LEADERBOARD_COACHES.includes(coachProfile.uuid) )*/}
            {/*  && (*/}
            {/*    <Link className="CoachNavBarLink" target="_blank"*/}
            {/*          to={`/${Routes.coach}/${currentSchoolId}/${Routes.districtLeaderboard}?`}>District</Link>*/}
            {/*  )*/}
            {/*}*/}
          </div>
        <div className="CoachDashboardLeaderboards">
          <div className="CoachDashboard_TeamsHeader">Combine Leaderboards</div>
          <div className="CoachDashboardLeaderboards_Cards PrivatePublic_Card">
            {
              combines.map((combine, index) => {
                const openDate = combine.openDate ? combine.openDate.format('MM.DD.YY') : '';
                let toDate = '';
                if (combine.isOpen) {
                  toDate = combine.endDate ? combine.endDate.format('MM.DD.YY') : '';
                } else {
                  toDate = combine.closeDate ? combine.closeDate.format('MM.DD.YY') : '';
                }
                const renderLink = <Link
                  to={`/${Routes.school}/${combine.schoolId}/${Routes.leaderboard}/${combine.id}`}
                  key={index}
                  className="CoachDashboardLeaderboards_CardContainer"
                >
                  <LeaderboardCard combine={combine} athleteCombineResults={athleteCombineResults}/>
                </Link>;
                return (<React.Fragment key={index}>
                  {isCoach ?
                    <div className="CoachDashboardLeaderboards_CardContainer">
                      <div className="CoachDashboardLeaderboards_privacy_date_wrapper">
                        <PrivacyControl
                          combine={combine}
                          privacyType={privacyType.LEADERBOARD}
                          isCoach={isCoach}
                          className="CoachDashboardLeaderboards_PrivacyControl"
                        />
                        <div className="CoachDashboardLeaderboards_CardDates">
                          {`${openDate} - ${toDate}`}
                        </div>
                      </div>
                      {renderLink}
                    </div> :
                    <React.Fragment>
                      {combine.leaderboardPrivacy ?
                        <div onClick={() => this.toggleModal()}
                             className="CoachDashboardLeaderboards_Athlete_view CoachDashboardLeaderboards_CardContainer">
                          <div className="CoachDashboardLeaderboards_privacy_date_wrapper">
                            <div className="UXComman_lock_icon_wrapper">
                              <TooltipIcon
                                className={`UxCommon_Privacy`}
                                iconName={'lock'}
                                tooltip={`This leaderboard is private`}
                                style={{opacity: '0.5', cursor: 'not-allowed'}}
                              />
                            </div>
                            <div className="CoachDashboardLeaderboards_CardDates">
                              {`${openDate} - ${toDate}`}
                            </div>
                          </div>
                          <LeaderboardCard combine={combine} athleteCombineResults={athleteCombineResults}/>
                        </div> :
                        <React.Fragment>
                          <div
                            className="CoachDashboardLeaderboards_Athlete_view CoachDashboardLeaderboards_CardContainer CoachDashboardLeaderboards_date">
                            <div className="CoachDashboardLeaderboards_privacy_date_wrapper">
                              <div className="CoachDashboardLeaderboards_CardDates">
                                {`${openDate} - ${toDate}`}
                              </div>
                            </div>
                            {renderLink}
                          </div>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>);
              })
            }
            {openModal &&
              <GeneralMessageModal message={'This leaderboard has been made private by the Coach'} toggleModal={this.toggleModal} />
            }
          </div>

        </div>
      </div>
    );
  }
}

// link should be : {`/${Routes.school}/${combine.schoolId}/${Routes.leaderboard}/${combine.id}`}

export default Leaderboards;
