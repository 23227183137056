import React, {useEffect, useRef, useState} from 'react';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {GridBreakpoints} from "../../../../config/responsive";
import {
  AllowedCharacters,
  CombineValidation,
  createUpdateBulkTestCombine,
  editMasterFormater,
  feetAndInchesMasterFormater,
  masterFormater,
  ParseEntries,
  refreshPopUp,
  repsMasterFormater,
  testCodesForZeroValues,
  validateMinimum
} from "../../+store/combine";
import {useDispatch, useSelector} from "react-redux";
import CellEditor from './Editors/CellEditor.component';
import CustomHeader from './CustomHeader/CustomHeader';
import ErrorTooltip from './ErrorTooltip/ErrorTooltip.component';
import RenderVideo from './RenderVideo/RenderVideo.component';
import AthleteInfo from './AthleteInfo/AthleteInfo.component';
import Icon from "../../../../components/UxCommon/Icon/Icon";
import {updateAthleteVideoId} from "../../../../store/actions/ui/uxProfile/photosVideos";
import LoadingIndicatorCard from "../../Dashboard/Combines/CombineControl/LoadingIndicatorCard";
import {checkCanEditCombineResults} from "../../+store/combine/combine.utils";
import {
  customComparator,
  getCellErrorMessage,
  getCellNewValue,
  getCellOldValue,
  getResult,
  isErrorOnCell,
  isPasteOperation,
  setCellValue,
  useCurrentDims,
  waitTill
} from "./CombineAgGrid.helpers";

const CombineAgGridComponent = ({
                                  bank,
                                  columns,
                                  athletes,
                                  combine,
                                  getTestResult,
                                  onSaveTestResults,
                                  onVideoUploadHandler,
                                  onVideoDeleteHandler,
                                  location,
                                  onBulkDelete,
                                  isCoach,
                                  onUndoDelete,
                                  onPasteEnds,
                                  schoolStyle
                                }) => {

  const {myTeams} = useSelector((state) => ({
    myTeams: state.modules.coachWorld.coachWorld.myTeams,
  }));

  const isCombineOpen = combine.isOpen

  const dispatch = useDispatch();

  const uploadVideo = (athleteId) => {
    dispatch(updateAthleteVideoId(athleteId))
  }

  const getRefreshPopUp = () => {
    dispatch(refreshPopUp())
  }

  const onClickFormButtonIcon = (isDelete = null, params) => {
    const athlete = params.data;
    const {
      column, combine, value
    } = params;
    const {userId} = params.data;
    // stagedMedia.length > 0 && unstageNewMedia(stagedMedia[0]);// todo edward will verify
    uploadVideo(userId);
    return isDelete ? onVideoDeleteHandler(athlete, column, combine, value) : onVideoUploadHandler(athlete, column, combine, value);
  }

  const [screenWidth, screenHeight] = useCurrentDims();
  const [columnDefs, setColumnDefs] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [currentTimeCount, setCurrentTimeCount] = useState(0);
  const [scroll, setScroll] = useState(false);
  const columnsLength = columns.length;
  const athletesLength = athletes.length;
  const [testParamsData, setTestParamsData] = useState([]);
  const [toDeleteTestParamsData, setToDeleteTestParamsData] = useState([]);
  const gridRef = useRef(null);


  const getFixedColWidth = (tableWidth) => {
    const isSmallScreen = screenWidth < GridBreakpoints.lg;
    return (isSmallScreen ? 0.39 : 0.2) * tableWidth;
  };

  useEffect(() => {
    document.getElementById('CoachWorld').addEventListener('scroll', () => {
      const coachWorld = document.getElementById('CoachWorld')
      if (coachWorld.scrollTop > 325) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
    return () => {
      document.getElementById('CoachWorld').removeEventListener('scroll', () => {
        setScroll(false);
      });
    }
  }, []);


  const onPasteEnd = async (params) => {
    const bulkPasteData = [...testParamsData];
    const bulkPasteDataToDelete = [...toDeleteTestParamsData];
    setTestParamsData([]);
    const PASTE_LIMIT = 200;
    if (bulkPasteData.length) {
      const dispatchTurn = parseInt(bulkPasteData.length / PASTE_LIMIT) + ((bulkPasteData.length % PASTE_LIMIT) ? 1 : 0);
      for (let i = 0; i < dispatchTurn; i++) {
        dispatch(createUpdateBulkTestCombine(bulkPasteData.slice(i * PASTE_LIMIT, PASTE_LIMIT + (i * PASTE_LIMIT)), bulkPasteDataToDelete.slice(i * PASTE_LIMIT, PASTE_LIMIT + (i * PASTE_LIMIT))))
        await waitTill();
      }
      setToDeleteTestParamsData([]);
    }
    onPasteEnds([...bulkPasteData]);
  }

  const createBulkPasteData = (athlete, column, combine, value) => {
    const testResult = (athlete.testResults
      && athlete.testResults[column.standardTestObjectId])
      ? athlete.testResults[column.standardTestObjectId]
      : null;
    const testResultId = testResult && testResult.id ? testResult.id : null;
    // console.log(testResult, 'athlete athlete', testResultId);
    let result = value;
    const testObject = {
      userId: athlete.userId,
      testId: athlete.test
        ? athlete.test.id
        : null,
      testResultId,
      stdTestObjId: column.standardTestObjectId,
      result: value,
      openDate: combine.openDate.format('YYYY-MM-DD'),
      combineId: combine.id,
      columnRowId: `${athlete.userId}:${column.standardTestObjectId}`,
      videoId: testResult && !!testResult.videoId ? testResult.videoId : null,
      processed: testResult && !!testResult.processed ? testResult.processed : 0,
    };
    if (value && value.reps) {
      result = Number(value.result) > 0 ? Number(value.result) : null;
      testObject.reps = value.reps ? Number(value.reps) : 1;
      testObject.result = result;
    }
    if (value && typeof value.isLaser !== 'undefined') {
      result = Number(value.result) > 0 ? Number(value.result) : null;
      testObject.isLaser = value.isLaser;
      testObject.result = result;
    }
    // if (value) {
    //   result = Number(value.result) > 0 ? Number(value.result) : null;
    //   testObject.result = result;
    // }
    if (value) {
      if (column.standardFormat.code === "NEGATIVE-DEGREE-HUNDREDTH"
        || column.standardFormat.code === "NEGATIVE-INCHES-HUNDREDTH"
        || column.standardFormat.code === "NEGATIVE-NUMERIC-HUNDREDTHS"
        || column.standardFormat.code === "NEGATIVE-PERCENTAGE-HUNDREDTHS"
        || column.standardFormat.code === "NEGATIVE-TIME-SEC-HUNDREDTHS") {

        result = (Number(value.result) < 0 || Number(value.result) > 0) ? Number(value.result) : null;
      } else {
        result = (Number(value.result) > 0) ? Number(value.result) : null;
      }
      testObject.result = result;
    }

    if (testResultId || result || (result === 0 && testCodesForZeroValues.includes(column.testCategoryCode))) {
      setTestParamsData(prevState => [...prevState, testObject]);
    }
    if (testResultId !== null && result === null && (testObject.videoId === '' || testObject.videoId === null)) {
      setToDeleteTestParamsData(prevState => [...prevState, testObject]);
    }
  }

  useEffect(() => {
    const tableWidth = getTableWidth();
    const fixedColWidth = getFixedColWidth(tableWidth) || 250;

    const defaultColumns = [{
      headerName: 'Athlete',
      colId: 'name',
      minWidth: fixedColWidth,
      width: fixedColWidth,
      maxWidth: fixedColWidth,
      resizable: false,
      sortable: false,
      editable: false,
      pinned: 'left',
      lockPosition: true,
      suppressSizeToFit: true,
      cellRendererParams: {schoolStyle},
      cellRenderer: "athleteInfo",
      filter: "agTextColumnFilter",
      headerComponentParams: {showLaser: false}
    }];

    const combineColumns = columns.map(c => ({
      headerName: c.testCategoryName,
      colId: c.testCategoryCode,
      isAllLaserChecked: c.allLaser,
      editable: (params) => isCoach && combine.isOpen && (params.data.isAthleteCoachedByUser || checkCanEditCombineResults(myTeams, params.data, combine)),
      minWidth: 180,
      width: 180,
      tooltipComponent: 'errorTooltip',
      headerComponentParams: {
        showLaser: c.useLaser,
        standardTestCategoryId: c.standardTestCategoryId,
        combineId: combine.id,
        isLaserChecked: c.allLaser,
        standardTestObjectId: c.standardTestObjectId,
        isCombineOpen: combine.isOpen,
      },
      // maxWidth: otherColWidth,
      sortable: false,
      cellStyle: params => {
        let error = {
          backgroundColor: 'transparent'
        };
        if (isErrorOnCell(params)) {
          error = {
            backgroundColor: '#ff9999',
            fontWeight: 'bold'
          }
        }
        return {
          textAlign: "center",
          overflow: "visible",
          ...error,
        }
      },
      cellClass: (params) => {
        let error = '';
        if (isErrorOnCell(params)) {
          error = 'cell-error'
        }
        return error;
      },
      cellEditor: 'cellEditor',
      cellRenderer: 'cellRender',
      cellRendererParams: {
        standardTestObjectId: c.standardTestObjectId,
        bank,
        isCombineOpen: combine.isOpen,
        isMobile: false,//todo dynamic
        combineId: combine.id,
        location,
        column: c,
        combine,
      },
      tooltipValueGetter: params => ({value: params.value}),
      tooltipComponentParams: params => {
        return {
          error: isErrorOnCell(params) ? getCellErrorMessage(params) : '',
        }
      },
      cellEditorParams: {
        help: c.tooltip,
        allowedCharTest: AllowedCharacters(c.standardFormat.code)[c.displayStandardUnitOfMeasureCode],
        formatToEdit: editMasterFormater(c.standardFormat.code),
        parse: ParseEntries(c.standardFormat.code)[c.displayStandardUnitOfMeasureCode],
        validate: CombineValidation(c.standardFormat.code)[c.displayStandardUnitOfMeasureCode],
        validateBiggerThan: validateMinimum(c.standardTestRange),
        validateReps: c.useReps ? CombineValidation(c.standardFormat.code).REPS : null,
        isLaserField: !!c.useLaser,
        isRepField: !!c.useReps,
        isCombineOpen: combine.isOpen,
        isLaserChecked: c.allLaser,
      },
      valueGetter: (params) => {
        return (isErrorOnCell(params)) ? getCellNewValue(params) : getTestResult(params.data, c, c.useReps, c.useLaser);
      },
      valueFormatter: (params) => {
        let {value} = params;
        // use the old value if we got validation error, as new value cannot be formatted
        if (isErrorOnCell(params)) {
          value = getCellOldValue(params);
        }
        const format = c.standardFormat.code;
        if (c.useReps) {
          if (c.standardUnitOfMeasureCode === "FEET-INCHES") {
            return (value && value.result !== null && value.result) ? feetAndInchesMasterFormater(format)(value.result) : '-';
          } else {
            return value !== null && value.result !== null ? repsMasterFormater(format)(value) : '-';
          }
        }
        value = (value && value.result) || value;
        if (c.useLaser) {
          return value !== null && value.result !== null ? masterFormater(format)(value) : '-';
        }
        return (value && value.result !== null) ? masterFormater(format)(Number(value)) : '-';
      },
      valueSetter: (params) => {
        const {parse, validate, validateReps, validateBiggerThan} = params.colDef.cellEditorParams;
        const setValue = setCellValue(params);
        setValue(null, null, null);
        const newValue = getResult(params, c.useReps, c.standardUnitOfMeasureCode === "FEET-INCHES");

        let error = '';

        // needs to validate the bulk paste only, manual entry already comes validated
        if (isPasteOperation(params)) {
          const toValidate = newValue.result ? newValue.result : newValue;
          if (c.useReps) {
            error = validateReps(toValidate);
          } else {
            error = validate(toValidate);
            if (!error) {
              error = validateBiggerThan(toValidate);
            }

          }
        }


        if (newValue && newValue.result && error !== '') {
          setValue(newValue, error, params.oldValue);
          return true;
        }

        const didResultChange = (!params.oldValue && newValue) || (params.oldValue && Number(newValue.result) !== Number(params.oldValue.result));

        if (c.useReps) {
          const didRepChange = (!params.oldValue && newValue) || (params.oldValue && Number(newValue.reps) !== Number(params.oldValue.reps));
          if (didRepChange || didResultChange) {
            isPasteOperation(params) ? createBulkPasteData(params.data, c, combine, newValue) : onSaveTestResults(params.data, c, combine, newValue, false, params.oldValue);
            return true;
          }
          return false;
        }
        if (c.useLaser) {
          const didLaserChange = (!params.oldValue && newValue) || (params.oldValue && Boolean(newValue.isLaser) !== Boolean(params.oldValue.isLaser));
          if (didLaserChange || didResultChange) {
            isPasteOperation(params) ? createBulkPasteData(params.data, c, combine, {
              ...params.newValue,
              result: parse(params.newValue.result || params.newValue),
              isLaser: newValue.isLaser
            }) : onSaveTestResults(params.data, c, combine, {
              ...params.newValue,
              result: parse(params.newValue.result || params.newValue)
            }, false, params.oldValue)
            return true;
          }
          return false;
        }
        if (params.oldValue && Number(newValue.result) === Number(params.oldValue.result)) {
          return false;
        }

        isPasteOperation(params) ? createBulkPasteData(params.data, c, combine, {
          ...params.newValue,
          result: parse(newValue.result)
        }) : onSaveTestResults(params.data, c, combine, {
          ...params.newValue,
          result: parse(newValue.result)
        }, false, params.oldValue);

        return true;
      },
      equals: customComparator,
    }));

    setColumnDefs([...defaultColumns, ...combineColumns]);


  }, [columnsLength, athletesLength, screenWidth, isCombineOpen, bank, columns])

  const CellRender = params => {
    const {isSavingArray} = useSelector((state) => ({
      isSavingArray: state.modules.coachWorld.combine.isSavingArray
    }));
    const isSavingObj = isSavingArray.find(s => s.columnRowId === `${params.data.userId}:${params.standardTestObjectId}`);
    const withinEstimated = isSavingObj && isSavingObj.isEstimatedTime;
    if (Number(withinEstimated) === 5) {
      getRefreshPopUp();
    }

    if (isSavingObj && isSavingObj.isSaving) {
      setTimeout(() => {
        const currentTime = new Date().getTime();
        const isOnline = navigator.onLine;
        if (!isOnline) {
          getRefreshPopUp();
        }
        if ((isSavingObj && isSavingObj.isSaving && Number(withinEstimated) !== 1 && (currentTime >= Number(withinEstimated) + 5000))) {
          getRefreshPopUp();
        }
        setCurrentTimeCount(currentTime)
      }, 1000);
      return (
        <LoadingIndicatorCard loading loadingMsg="Please Wait..."/>
      );
    }
    return (
      (
        <React.Fragment>
          <button
            className="CombineGridEntry_Btn"
          >
            {params.valueFormatted}
            {(params.value && params.value.isLaser) ? (
              <Icon iconName="tick" className="ag-laser-cell-icon"/>
            ) : ''}
          </button>
          {
            <RenderVideo params={params} onClickFormButtonIcon={onClickFormButtonIcon} uploadVideo={uploadVideo}/>
          }
        </React.Fragment>
      )
    )
  }

  const getTableWidth = () => {
    const isSmallScreen = screenWidth < GridBreakpoints.lg;
    return screenWidth - (isSmallScreen ? 10 : 20);
  }

  const getTableHeight = () => {
    return screenHeight;
  }

  useEffect(() => {
    const isClickedOutside = (event) => {
      const bulkDeleteModal = document.getElementsByClassName('CloseCombineModal_Backdrop')[0];
      const agGridHeader = document.getElementsByClassName('ag-header')[0];
      return !!(agGridHeader.contains(event.target) || ((gridRef.current
        && !gridRef.current.contains(event.target)) && !(bulkDeleteModal && bulkDeleteModal.contains(event.target))));

    }

    const handleClickOutside = (event) => {
      if (isClickedOutside(event)) {
        gridApi.clearRangeSelection();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [gridApi, gridRef])

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    params.columnApi.autoSizeAllColumns();
  }

  return (
    <div ref={gridRef} className={`ag-theme-alpine combine-ag-grid ${scroll ? 'ag-grid-scroll' : ''}`}
         style={{height: getTableHeight(), width: '100%'}}>
      {
        athletesLength && columnDefs ? (
          <AgGridReact
            rowHeight={90}
            modules={AllModules}
            defaultColDef={{
              filter: false,
              resizable: false,
              sortable: false,
              editable: true,
              wrapText: true,
              tooltipComponent: 'errorTooltip'
            }}
            onPasteEnd={onPasteEnd}
            frameworkComponents={{
              cellEditor: CellEditor,
              cellRender: CellRender,
              athleteInfo: AthleteInfo,
              agColumnHeader: CustomHeader,
              errorTooltip: ErrorTooltip,
            }}
            animateRows
            onGridReady={onGridReady}
            rowData={athletes}
            enableCellTextSelection
            tooltipShowDelay={0}
            enterMovesDown
            applyColumnDefOrder={true}
            enterMovesDownAfterEdit
            suppressKeyboardEvent={(params) => {
              const {isLaserField, isRepField} = params.colDef.cellEditorParams;
              const classNames = params.event.target.className.split(' ');
              if (isRepField && classNames.includes('reps-result') && ['Enter', 'Tab'].includes(params.event.key)) {
                return true;
              }
              if (isLaserField && classNames.includes('laser') && ['Tab'].includes(params.event.key)) {
                return true;
              }

              if (!params.editing && (params.event.key === 'Delete' || params.event.key === 'Backspace')) {
                onBulkDelete(params);
                return true;
              }
              return false;
            }
            }
            headerHeight={55}
            rowSelection="multiple"
            stopEditingWhenGridLosesFocus
            immutableData
            enableRangeSelection
            getRowNodeId={(data) => data.userId}
            onCellClicked={(event => {
              const stopEditingFor = ['play-circle2-menu-icon', 'delete-video-menu-icon', 'videocam-menu-icon']
              if (event.event.target.id && stopEditingFor.includes(event.event.target.id)) {
                event.api.stopEditing();
              }
            })}
            onCellKeyDown={(event => {
              if (event.event.key === 'Enter') {
                const classNames = event.event.target.className.split(' ');
                const currentCell = event.api.getFocusedCell();
                if (!classNames.includes('reps-result') && event.rowIndex === currentCell.rowIndex && currentCell.rowIndex === athletesLength - 1) {
                  return event.api.tabToNextCell();
                }
                event.api.startEditingCell({
                  rowIndex: currentCell.rowIndex,
                  colKey: currentCell.column
                })
              }

            })}
          >
            {
              columnDefs.map(column => {
                return (
                  <AgGridColumn
                    {...column}
                    key={column.colId}
                  />
                )
              })
            }
          </AgGridReact>
        ) : null
      }
    </div>
  )
};

export default CombineAgGridComponent;
