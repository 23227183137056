import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import StatsCustomControl from '../StatsCustomControl/StatsCustomControl';
import { testTimeChars, parserOnlyNumbers } from '../../../../utils/parsers';

class StatsTimeControl extends React.PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    rrfField: PropTypes.object,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
    rrfFormChange: PropTypes.func.isRequired,
    isInteger: PropTypes.bool,
  }

  onBlurParse = (v) => {
    const { isInteger } = this.props;
    momentDurationFormatSetup(moment);
    const m = moment.duration(this.calcSeconds(v), 'seconds');
    if (isInteger) {
      return m.format('*m:ss');
    }
    return m.format('*m:ss.SS');
  }

  getInitialFormattedValue = (defaultValue) => {
    const { isInteger } = this.props;
    if (!defaultValue) return '';
    momentDurationFormatSetup(moment);
    const m = moment.duration(defaultValue, 'seconds');
    if (isInteger) {
      return m.format('*m:ss');
    }
    return m.format('*m:ss.SS');
  }

  calcSeconds = (v) => {
    if (!v) return 0;
    const colonIndex = v.indexOf(':');
    let minutes = 0;
    if (colonIndex >= 0) {
      minutes = parseInt(v.slice(0, colonIndex), 10);
    }
    const seconds = parseFloat(v.slice(colonIndex + 1));
    return (minutes * 60) + seconds;
  }

  parser = (v, cursorPos) => {
    const colonIndex = v.indexOf(':');
    const placesAfterDecimal = 2;
    let parsedCursorPos = cursorPos;

    if (colonIndex >= 0) {
      const preParseMinutesString = v.slice(0, colonIndex);
      const minutesString = parserOnlyNumbers(preParseMinutesString);
      let minutes = parseInt(minutesString, 10);
      if ((minutes < 10) && (cursorPos > colonIndex)) {
        minutes = `0${minutes}`;
      } else {
        minutes = `${minutes}`;
      }
      parsedCursorPos -= (preParseMinutesString.length - minutes.length);

      let dotIndex = v.slice(colonIndex + 1).indexOf('.');
      if (dotIndex >= 0) {
        dotIndex += colonIndex + 1;
        const afterColon = parserOnlyNumbers(v.slice(colonIndex + 1, dotIndex));
        let wholeSeconds = 0;
        if (afterColon) {
          wholeSeconds = parseInt(afterColon, 10);
        }
        if ((wholeSeconds < 10) && ((cursorPos < colonIndex) || (cursorPos > dotIndex))) {
          wholeSeconds = `0${wholeSeconds}`;
          if (cursorPos > dotIndex) {
            parsedCursorPos += 1;
          }
        }
        const seconds = `${wholeSeconds}.${parserOnlyNumbers(v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal))}`;
        return {
          parsedText: `${minutes}:${seconds}`,
          cursorPos: parsedCursorPos,
        };
      }
      const wholeSeconds = parserOnlyNumbers(v.slice(colonIndex));
      return {
        parsedText: `${minutes}:${wholeSeconds}`,
        cursorPos: parsedCursorPos,
      };
    }
    const dotIndex = v.indexOf('.');
    if (dotIndex >= 0) {
      const afterColon = parserOnlyNumbers(v.slice(0, dotIndex));
      const wholeSeconds = parseInt(afterColon, 10);
      const seconds = `${wholeSeconds}.${parserOnlyNumbers(v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal))}`;
      if ((wholeSeconds < 10) && (cursorPos > dotIndex)) {
        return {
          parsedText: `00:0${seconds}`,
          cursorPos: cursorPos + 4,
        };
      }
      return {
        parsedText: `00:${seconds}`,
        cursorPos: cursorPos + 3,
      };
    }
    const wholeSeconds = parserOnlyNumbers(v);
    return {
      parsedText: `${wholeSeconds}`,
      cursorPos,
    };
  };

  normalizeValue = (v) => {
    const { isInteger } = this.props;
    if (isInteger) {
      return this.calcSeconds(v).toFixed(0);
    }
    return this.calcSeconds(v).toFixed(2);
  }

  render() {
    const {
      model,
      defaultValue,
      className,
      rrfField,
      rrfFormChange,
      isInteger,
    } = this.props;
    let help = '10:22.44';
    if (isInteger) {
      help = '10:22';
    }
    return (
      <StatsCustomControl
        className={`${className} StatsTimeControl`}
        model={model}
        rrfField={rrfField}
        defaultValue={defaultValue}
        parser={this.parser}
        allowedCharacters={testTimeChars}
        help={<div><div>Example: {help}</div><div>(Minutes:Seconds)</div></div>}
        onBlurParse={this.onBlurParse}
        normalizeValue={this.normalizeValue}
        getInitialFormattedValue={this.getInitialFormattedValue}
        rrfFormChange={rrfFormChange}
      />
    );
  }
}

export default StatsTimeControl;
