import {ASYNC_ERROR, ASYNC_FINISH, ASYNC_START} from '../../../../store/actions/ui/async';
import {
  CREATE_COMBINE,
  CREATE_COMBINE_AWARD,
  DELETE_COMBINE,
  FIND_COMBINE_AWARDS,
  GET_ATHLETE_COUNT,
  GET_COMBINE,
  GET_COMBINE_ATHLETE_RESULTS,
  GET_COMBINE_ATHLETE_SUCCESS,
  GET_COMBINE_ATHLETES_RESULT_SUCCESS,
  GET_COMBINE_PRE_COMBINE_TEST_RES,
  GET_COMBINE_RESULT_ALL_ATHLETE_SUCCESS,
  GET_COMBINE_TEMPLATES,
  GET_COMBINES_AWARDS,
  GET_PURE_COMBINE,
  GET_SCHOOL_COMBINES,
  GET_TEST_CATEGORIES,
  HIDE_ARCHIVE_DIALOG,
  HIDE_CREATE_EDIT_DIALOG,
  HIDE_EDIT_DIALOG,
  HIDE_GENERATED_AWARD_DIALOG,
  HIDE_SAVE_COMBINE_AWARDS_DIALOG,
  SAVE_COMBINE_TEST_RESULT,
  SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT,
  REFRESH_PAGE,
  SET_EXPIRING_MSG,
  SET_SCROLLED_BELOW_MENU,
  SHOW_ARCHIVE_DIALOG,
  SHOW_CREATE_EDIT_DIALOG,
  SHOW_EDIT_DIALOG,
  SHOW_GENERATED_AWARD_DIALOG,
  SHOW_SAVE_COMBINE_AWARDS_DIALOG,
  UNLOAD_COMBINE,
  UPDATE_COMBINE,
  UPDATE_COMBINE_AWARD,
  UPDATE_COMBINE_RESULTS,
  CREATE_UPDATE_BULK_TEST_COMBINE,
  DELETE_BULK_TEST_COMBINE,
  DELETE_LAST_PASTE_DATA_COMBINE,
  UPDATE_CREATE_COMBINE_AND_AWARDS,
  SET_ALL_LASER
} from "./combine.actions";
import {FORBIDDEN_ERROR} from '../../../../store/actions/ui/ajaxErrorHandlers';
import {combinePreCombineUpdateId, combineUpdateId, dashboardCombinesUpdateId} from './combine.utils';
import {
  CREATE_COMBINE_TEMPLATE,
  DELETE_COMBINE_TEMPLATE,
  UPDATE_COMBINE_TEMPLATE,
  UPDATE_COMBINE_TEST
} from './combineTemplates.actions';
import {AthleteCombineResults} from './combine.models';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if(action.model === UPDATE_COMBINE_TEST){
        return 'Updating...'
      }
      if (action.model === GET_COMBINE ||
        action.model === GET_TEST_CATEGORIES) {
        return 'Loading ...';
      } else if (action.model === UPDATE_COMBINE_RESULTS ||
        action.model === UPDATE_COMBINE_TEMPLATE ||
        (action.model === UPDATE_COMBINE
          || action.model === UPDATE_CREATE_COMBINE_AND_AWARDS
          && action.msg === '')) {
        return 'Updating ...';
      } else if (action.model === DELETE_COMBINE_TEMPLATE) {
        return 'Deleting ...';
      } else if (action.model === CREATE_COMBINE_TEMPLATE) {
        return 'Adding ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ([
        GET_COMBINE,
        UPDATE_COMBINE_RESULTS,
        UPDATE_COMBINE,
        UPDATE_COMBINE_TEST,
        GET_COMBINE_TEMPLATES,
        GET_TEST_CATEGORIES,
        UPDATE_COMBINE_TEMPLATE,
        CREATE_COMBINE_TEMPLATE,
        DELETE_COMBINE_TEMPLATE,
        UPDATE_CREATE_COMBINE_AND_AWARDS,
      ].includes(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const updateCombineLoader = ( state = '', action ) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === UPDATE_COMBINE ) {
        return 'loading...';
      }
    case ASYNC_FINISH:
      if (action.model === UPDATE_COMBINE && !action.data.closeAt ) {
        return 'closed';
      }
      else if (action.model === CREATE_COMBINE_AWARD) {
        return 'award_granated...'
      }
    default:
      return state;
}
}

const loadingCombinesMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_SCHOOL_COMBINES) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ([
        GET_SCHOOL_COMBINES,
      ].includes(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingComparedCombines = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        return 'Comparing results with previous combine ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingComparedTestResult = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        return 'Comparing results with previous combine ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        return 'compaired';
      }
      else if (action.model === CREATE_COMBINE_AWARD) {
        return 'award_granated...'
      }
      return state;
    default:
      return state;
  }
};

const loadingCombineResults = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === GET_COMBINE_ATHLETE_RESULTS) {
        return 'Loading ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_ATHLETE_RESULTS) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingMsgModal = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START: {
      let msg = '';
      if (action.model === CREATE_COMBINE) {
        msg = 'Creating ...';
      } else if (action.model === UPDATE_COMBINE) {
        msg = 'Updating ...';
      } else if (action.model === GET_PURE_COMBINE) {
        msg = 'Loading ...';
      } else if (action.model === DELETE_COMBINE) {
        msg = 'Archiving ...';
      }
      return msg;
    }
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ([
        CREATE_COMBINE,
        UPDATE_COMBINE,
        GET_PURE_COMBINE,
        DELETE_COMBINE,
      ].includes(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const loadingMsgAwardButton = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START: {
      let msg = '';
      if (action.model === CREATE_COMBINE_AWARD) {
        msg = 'Granting ...';
      }
      return msg;
    }
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ([
        CREATE_COMBINE_AWARD,
      ].includes(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const schoolCombines = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_COMBINES) {
        return action.data.combines || state;
      } else if (action.model === CREATE_COMBINE) {
        return [action.data.combine, ...state];
      } else if (action.model === UPDATE_COMBINE ) {
        return [action.data.combine, ...state.filter(x => x.id !== action.data.combine.id)];
      } else if (action.model === UPDATE_CREATE_COMBINE_AND_AWARDS) {
        return [action.data.combine, ...state.filter(x => x.id !== action.data.combine.id)];
      } else if (action.model === DELETE_COMBINE) {
        return [...state.filter(x => x.id !== action.data.combineId)];
      }
      return state;
    default:
      return state;
  }
};

const shouldUpdate = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_SCHOOL_COMBINES) {
        const obj = dashboardCombinesUpdateId(action.data.schoolIds);
        return [
          ...state.filter(s => (
            (s.key !== obj.key)
          )),
          {
            id: obj.id,
            key: obj.key,
            value: Date.now(),
          },
        ];
      }
      if (action.model === GET_COMBINE) {
        const obj = combineUpdateId(action.data.combine.id);
        return [
          ...state.filter(s => (
            (s.key !== obj.key)
          )),
          {
            id: obj.id,
            key: obj.key,
            value: Date.now(),
          },
        ];
      }
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        const obj = combinePreCombineUpdateId(action.data.combine.id);
        return [
          ...state.filter(s => (
            (s.key !== obj.key)
          )),
          {
            id: obj.id,
            key: obj.key,
            value: Date.now(),
          },
        ];
      }
      return state;
    default:
      return state;
  }
};

const athleteCount = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_ATHLETE_COUNT) {
        if (action.data.combineResults) {
          return [
            action.data.combineResults,
            ...state.filter(x => x.id !== action.data.combineResults.id),
          ];
        }
      }
      return state;
    default:
      return state;
  }
};

const combine = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPDATE_COMBINE && !action.data.combine) return state;
      if ([
        GET_COMBINE,
        GET_COMBINE_PRE_COMBINE_TEST_RES,
        UPDATE_COMBINE,
        UPDATE_CREATE_COMBINE_AND_AWARDS,
      ].includes(action.model) && action.data.combine.combineTemplateId) {
        return Object.assign({}, state, {
          [action.data.combine.id]: action.data.combine,
        });
      }
      if (action.model === SET_ALL_LASER) {
        const oldCombine = state[action.data.combineId];
        const updatedCombine = Object.assign({}, oldCombine, {
          columns: oldCombine.columns.map((column) => {
            if (
              column.standardTestCategoryId === action.data.standardTestCategoryId
            ) {
              return {
                ...column,
                allLaser: !column.allLaser,
              };
            }
            return column;
          }),
        });

        return Object.assign({}, state, {
          [action.data.combineId]: updatedCombine,
        });
      }
      return state;
    default:
      return state;
  }
};

const comparedTestResults = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_PRE_COMBINE_TEST_RES) {
        if (!action.data) return state;
        return Object.assign({}, state, {
          [action.data.combine.id]: action.data.results,
        });
      }
      return state;
    default:
      return state;
  }
};

const combineAwards = (state = [], action) => {
  switch (action.type) {
    case FIND_COMBINE_AWARDS:
      return action.data || null;
    default:
      return state;
  }
};

const savedCombineAwards = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COMBINES_AWARDS) {
        return action.data.combineAwards;
      } else if (action.model === CREATE_COMBINE_AWARD) {
        console.log(action.data , 'action awards');
        return action.data.combineAward;
      } else if (action.model === UPDATE_COMBINE_AWARD) {
        return [
          ...state.filter(c => c.id !== action.data.combineAward.id),
          action.data.combineAward,
        ];
      }
      return state;
    case UNLOAD_COMBINE:
      return null;
    default:
      return state;
  }
};

const combineToEdit = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_PURE_COMBINE) {
        return action.data.combine || state;
      } else if (action.model === UPDATE_COMBINE) {
        return null;
      }
      return state;
    default:
      return state;
  }
};


const combineTemplates = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_COMBINE_TEMPLATES) {
        return action.data.templates || state;
      }
      return state;
    default:
      return state;
  }
};

const getExistingAttr = (obj, attr) => {
  if (!obj) return null;
  return obj[attr];
};


const athleteCombineResultProcessing = (count = 0, action) => {
  switch (action.type) {
    case ASYNC_START :
      if (action.model === CREATE_UPDATE_BULK_TEST_COMBINE ||
        action.model === DELETE_BULK_TEST_COMBINE || action.model === DELETE_LAST_PASTE_DATA_COMBINE) {
        count++;
        return count;
      }
      return count;
    case ASYNC_FINISH :
      if (action.model === CREATE_UPDATE_BULK_TEST_COMBINE ||
        action.model === DELETE_BULK_TEST_COMBINE || action.model === DELETE_LAST_PASTE_DATA_COMBINE) {
        if (count === 0) {
          return false
        } else if (count > 0) {
          count--;
        }
        return count;
      }
      return count;
    default:
      return count;
  }
}

const athleteCombineResults = (state = {}, action) => {
  switch (action.type) {
    case GET_COMBINE_ATHLETE_SUCCESS:
      return Object.assign({}, state, {
        [action.combineId]: Object.assign({}, state[action.combineId], {
          [action.athleteId]: action.combineResults,
        }),
      });
    case GET_COMBINE_RESULT_ALL_ATHLETE_SUCCESS:
      let obj = {};
      action.combineResults.forEach((test) => {
        obj = {...obj, [test.athlete_uuid]: AthleteCombineResults.fromApi(test)};
      });
      return Object.assign({}, state, {
        [action.combineId]: Object.assign({}, state[action.combineId], {...obj}),
      });
    case ASYNC_FINISH:
      if (action.model === DELETE_LAST_PASTE_DATA_COMBINE) {
        let newState = {...state};
        action.data.bulkSuccessData.forEach(response => {
          const {userId, combineId, stdTestObjId} = response;
          const existingAthlete = newState[combineId] ? newState[combineId][userId] : null;
          const testResults = getExistingAttr(existingAthlete, 'testResults');
          delete testResults[stdTestObjId];
          Object.assign({}, newState, {
            [combineId]: Object.assign({}, state[combineId], {
              [userId]: Object.assign(
                {},
                existingAthlete,
                Object.assign({}, {
                  test: getExistingAttr(existingAthlete, 'test'),
                  testResults: Object.assign({}, testResults),
                }),
              ),
            }),
          });

        });

        return newState;
      }
      if(action.model === UPDATE_COMBINE_TEST){
        return {};
      }
      if (action.model === DELETE_BULK_TEST_COMBINE) {
        let newState = {...state};
        action.data.bulkSuccessData.forEach(response => {
          const {athleteId, combineId} = response;
          const existingAthlete = newState[combineId] ? newState[combineId][athleteId] : null;
          response.testResultIds.forEach(testResultId => {
            const testResults = getExistingAttr(existingAthlete, 'testResults');
            const stdTestObjId = Object.keys(testResults)
              .find(r => testResults[r].id === testResultId);

            const findStdObjIndex = response.standardTestObjectId
            .findIndex(item => item === Number(stdTestObjId))

            if (response.videoId[findStdObjIndex] === null || response.videoId[findStdObjIndex] === undefined || response.videoId[findStdObjIndex] === ''){
              delete testResults[stdTestObjId];
            } else {
              testResults[stdTestObjId].result = null
              testResults[stdTestObjId].multiRepMax = null
            }

            Object.assign({}, newState, {
              [combineId]: Object.assign({}, state[combineId], {
                [athleteId]: Object.assign(
                  {},
                  existingAthlete,
                  Object.assign({}, {
                    test: getExistingAttr(existingAthlete, 'test'),
                    testResults: Object.assign({}, testResults),
                  }),
                ),
              }),
            });
          })
        });

        return newState;
      }
      if (action.model === CREATE_UPDATE_BULK_TEST_COMBINE) {
        let newState = {...state};
        const bulkDeleteData = action.data.bulkDeleteData;

        bulkDeleteData.forEach(response => {
          const {userId, combineId} = response;
          const existingAthlete = newState[combineId] ? newState[combineId][userId] : null;
          const testResults = getExistingAttr(existingAthlete, 'testResults');
          delete testResults[response.stdTestObjId];

        });

        action.data.bulkSuccessData.forEach(response => {
          const {athleteId, combineId} = response;
          const existingAthlete = newState[combineId] ? newState[combineId][athleteId] : null;

          newState = Object.assign({}, newState, {
            [combineId]: Object.assign({}, newState[combineId], {
              [athleteId]: Object.assign(
                {},
                existingAthlete,
                {
                  test: response.test || getExistingAttr(existingAthlete, 'test'),
                  testResults: Object.assign({}, getExistingAttr(existingAthlete, 'testResults'), {
                    [response.testResult.standard_test_object_id]: Object.assign(
                      {},
                      getExistingAttr(existingAthlete, 'testResults')
                        ? existingAthlete.testResults[response.testResult.standard_test_object_id]
                        : null,
                      response.testResult,
                    ),
                  }),
                },
              ),
            }),
          });
        })
        return newState;
      }
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        const {athleteId, combineId} = action.data;
        const existingAthlete = state[combineId] ? state[combineId][athleteId] : null;
        if (action.data.job === 'delete') {
          const testResults = getExistingAttr(existingAthlete, 'testResults');
          const stdTestObjId = Object.keys(testResults)
            .find(r => testResults[r].id === action.data.testResultId);
          delete testResults[stdTestObjId];
          return Object.assign({}, state, {
            [combineId]: Object.assign({}, state[combineId], {
              [athleteId]: Object.assign(
                {},
                existingAthlete,
                Object.assign({}, {
                  test: action.data.test || getExistingAttr(existingAthlete, 'test'),
                  testResults: Object.assign({}, testResults),
                }),
              ),
            }),
          });
        }
        return Object.assign({}, state, {
          [combineId]: Object.assign({}, state[combineId], {
            [athleteId]: Object.assign(
              {},
              existingAthlete,
              {
                test: action.data.test || getExistingAttr(existingAthlete, 'test'),
                testResults: Object.assign({}, getExistingAttr(existingAthlete, 'testResults'), {
                  [action.data.testResult.stdTestObjId]: Object.assign(
                    {},
                    getExistingAttr(existingAthlete, 'testResults')
                      ? existingAthlete.testResults[action.data.testResult.stdTestObjId]
                      : null,
                    action.data.testResult,
                  ),
                }),
              },
            ),
          }),
        });
      }

      if(action.model === SET_ALL_LASER){
        const combineAthleteResult = state[action.data.combineId];
        let affected = false;
        Object.keys(combineAthleteResult).forEach(athleteId=>{
         const testResult = combineAthleteResult[athleteId].testResults[action.data.standardTestObjectId];
         if(testResult){
           testResult.isLaser = !!action.data.allLaser === true ? 1 : 0;
           affected = true;
         }
        })
        if(affected){
          return Object.assign({}, state, {
            [action.data.combineId]: Object.assign({}, state[action.data.combineId], {...combineAthleteResult}),
          });
        }
        return state;
      }
      return state;
    case UNLOAD_COMBINE:
      return {};
    default:
      return state;
  }
};

const combineResultsStaging = (state = {}, action) => {
  switch (action.type) {
    case SAVE_COMBINE_TEST_RESULT:
      if (action.testParams.reps) {
        const {reps, result} = action.testParams;
        return Object.assign({}, state, {
          [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {reps, result},
        });
      }
      if (action.testParams.isLaser !== undefined) {
        const {isLaser, result} = action.testParams;
        return Object.assign({}, state, {
          [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {isLaser, result},
        });
      }
      return Object.assign({}, state, {
        [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {result: action.testParams.result},
      });
    case SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT:
      if (action.testParams.reps) {
        const {reps, result} = action.testParams;
        return Object.assign({}, state, {
          [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {reps, result},
        });
      }
      if (action.testParams.isLaser !== undefined) {
        const {isLaser, result} = action.testParams;
        return Object.assign({}, state, {
          [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {isLaser, result},
        });
      }
      return Object.assign({}, state, {
        [`${action.userId}:${action.testParams.combineId}:${action.testParams.stdTestObjId}`]: {result: action.testParams.result},
      });
    case ASYNC_ERROR:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        const data = action.data.action;
        return Object.assign({}, state, {
          [`${data.userId}:${data.testParams.combineId}:${data.testParams.stdTestObjId}`]: undefined,
        });
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        const {data} = action;
        return Object.assign({}, state, {
          [`${data.athleteId}:${data.combineId}:${data.testResult.stdTestObjId}`]: undefined,
        });
      }
      return state;
    default:
      return state;
  }
};

const savingMsg = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        return {
          msg: 'Saving ...',
          msgType: 'info',
        };
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        if (action.data.ajaxErrorType === FORBIDDEN_ERROR) {
          return null;
        }
        return {
          msg: 'There was an error saving your combine results',
          msgType: 'error',
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const isSavingArray = (state = [], action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        return [
          {columnRowId: action.attrs, isSaving: true, isEstimatedTime: new Date().getTime()},
          ...state.filter(x => x.columnRowId !== action.attrs),
        ];
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        return [
          {columnRowId: action.columnRowId, isSaving: false, isEstimatedTime: 5},
          // ...state.filter(x => x.columnRowId !== action.columnRowId),
        ];
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === SAVE_COMBINE_TEST_RESULT || action.model === SAVE_COMBINE_TEST_RESULT_WITHOUT_RESULT) {
        return [
          {columnRowId: action.data.columnRowId, isSaving: false, isEstimatedTime: 1},
          ...state.filter(x => x.columnRowId !== action.data.columnRowId),
        ];
      }
      return state;
    default:
      return state;
  }
};

const refreshPopUp = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === REFRESH_PAGE) {
        return true;
      }
    default:
      return state;
  }
}


const expiringMsg = (state = null, action) => {
  switch (action.type) {
    case SET_EXPIRING_MSG:
      return {
        msg: action.msg,
        msgType: action.msgType,
      };
    case ASYNC_FINISH:
      if (action.model === SET_EXPIRING_MSG) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const userHasScrolledBelowMenu = (state = false, action) => {
  switch (action.type) {
    case SET_SCROLLED_BELOW_MENU:
      return action.hasScrolledBelowMenu;
    default:
      return state;
  }
};

const isArchiveDialogVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_ARCHIVE_DIALOG:
      return true;
    case HIDE_ARCHIVE_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (action.model === DELETE_COMBINE) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const isSaveCombineAwardsDialogVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_SAVE_COMBINE_AWARDS_DIALOG:
      return true;
    case HIDE_SAVE_COMBINE_AWARDS_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (action.model === UPDATE_COMBINE) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const isAwardGeneratedDialogVisible = (state = false, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === UPDATE_COMBINE && action.data.showExecuteSucDialog === 1) {
        return true;
      }
      return state;
    case SHOW_GENERATED_AWARD_DIALOG:
      return true;
    case HIDE_GENERATED_AWARD_DIALOG:
      return false;
    default:
      return state;
  }
};

const isCreateEditDialogVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_CREATE_EDIT_DIALOG:
      return true;
    case HIDE_CREATE_EDIT_DIALOG:
      return false;
    case ASYNC_FINISH:
      if ([
        CREATE_COMBINE,
        UPDATE_COMBINE,
        DELETE_COMBINE,
      ].includes(action.model)) {
        return false;
      }
      if ([
        UPDATE_COMBINE_TEMPLATE,
        CREATE_COMBINE_TEMPLATE,
      ].includes(action.model) && action.data && action.data.redirectToCombine) {
        return true;
      }
      return state;
    default:
      return state;
  }
};

const isEditDialogVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_EDIT_DIALOG:
      return true;
    case HIDE_EDIT_DIALOG:
      return false;
    case ASYNC_FINISH:
      if ([
        UPDATE_COMBINE,
        DELETE_COMBINE,
      ].includes(action.model)) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const combineTemplateId = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if ([
        UPDATE_COMBINE_TEMPLATE,
        CREATE_COMBINE_TEMPLATE,
      ].includes(action.model) && action.data && action.data.redirectToCombine) {
        return action.data.combineTemplate.id;
      }
      return state;
    case HIDE_CREATE_EDIT_DIALOG:
      return null;
    default:
      return state;
  }
};

const processingAllLaser = (state = false, action) => {
  switch (action.type) {
    case ASYNC_START:
      if(action.model === SET_ALL_LASER){
        return true;
      }
      return state;
    case ASYNC_FINISH:
      if(action.model === SET_ALL_LASER){
        return false;
      }
      return state;
    default:
      return state;
  }
};

export default {
  loadingMsg,
  schoolCombines,
  combine,
  updateCombineLoader,
  athleteCombineResultProcessing,
  comparedTestResults,
  athleteCombineResults,
  isArchiveDialogVisible,
  combineTemplates,
  combineToEdit,
  loadingMsgModal,
  athleteCount,
  isCreateEditDialogVisible,
  isEditDialogVisible,
  combineResultsStaging,
  savingMsg,
  expiringMsg,
  userHasScrolledBelowMenu,
  combineAwards,
  savedCombineAwards,
  isSaveCombineAwardsDialogVisible,
  loadingMsgAwardButton,
  isAwardGeneratedDialogVisible,
  shouldUpdate,
  loadingComparedCombines,
  loadingCombineResults,
  combineTemplateId,
  loadingCombinesMsg,
  isSavingArray,
  refreshPopUp,
  loadingComparedTestResult,
  processingAllLaser,
};
