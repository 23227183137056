import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTokenFragment,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { NationalLeaderboardRosters } from './nationalLeaderboard.models';
import { Routes } from '../../../../store/actions/ui/routes';
import {
  GET_NATIONAL_LEADERBOARD_GRAD_YEARS,
  GET_NATIONAL_LEADERBOARD_RESULTS,
  GET_NATIONAL_LEADERBOARD_SPORTS,
  GET_NATIONAL_LEADERBOARD_TEMPLATE,
} from './nationalLeaderboard.actions';
import {
  apiGetNationalLeaderboardGradYears,
  apiGetNationalLeaderboardResults,
  apiGetNationalLeaderboardSports,
  apiGetNationalLeaderboardTemplate,
} from './nationalLeaderboard.api';
import { CombineTemplate } from './nationalLeaderboard.models';
import { GET_SPORTS } from '../nationalLeaderboard';
import {
  mapSportsApiToUi,
  sportGetSports,
  sportsGetSuccess,
} from '../../../../store/actions/data/sport';

const getNationalLeaderboardTemplateEpic = (action$) =>
  action$.pipe(
    ofType(GET_NATIONAL_LEADERBOARD_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      apiGetNationalLeaderboardTemplate(token, action.sportId).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_NATIONAL_LEADERBOARD_TEMPLATE,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const template = CombineTemplate.fromApi(result.response);
        const categories = result.response;
        return of(
          asyncFinishAction(
            result.action.type,
            GET_NATIONAL_LEADERBOARD_TEMPLATE,
            {
              template,
              categories,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getNationalLeaderboardSportsEpic = (action$) =>
  action$.pipe(
    ofType(GET_NATIONAL_LEADERBOARD_SPORTS),
    getTokenFragment(),
    switchMap(({ action }) =>
      apiGetNationalLeaderboardSports().pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_NATIONAL_LEADERBOARD_SPORTS,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(
            result.action.type,
            GET_NATIONAL_LEADERBOARD_SPORTS,
            {
              sports: result.response,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getNationalLeaderboardGradYearsEpic = (action$) =>
  action$.pipe(
    ofType(GET_NATIONAL_LEADERBOARD_GRAD_YEARS),
    getTokenFragment(),
    switchMap(({ action }) =>
      apiGetNationalLeaderboardGradYears().pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_NATIONAL_LEADERBOARD_GRAD_YEARS,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(
            result.action.type,
            GET_NATIONAL_LEADERBOARD_GRAD_YEARS,
            {
              gradYears: result.response,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getNationalLeaderboardResultsEpics = (action$, state$) =>
  action$.pipe(
    ofType(GET_NATIONAL_LEADERBOARD_RESULTS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      apiGetNationalLeaderboardResults(token, action).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_NATIONAL_LEADERBOARD_RESULTS,
          Routes.nationalLeaderboard,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const { loadMore } = result.action;
        const {
          categories,
        } = state$.value.modules.coachWorld.nationalLeaderboard;
        let newRosters = NationalLeaderboardRosters.fromApi(
          result.response[0].result.data,
          categories,
        );
        if (loadMore) {
          const {
            rosters,
          } = state$.value.modules.coachWorld.nationalLeaderboard.results;
          if (result.action.page > 1) {
            newRosters = [...rosters, ...newRosters];
          }
        }

        const showMore =
          result.response[0].result.to < result.response[0].result.total;

        return of(
          asyncFinishAction(
            result.action.type,
            GET_NATIONAL_LEADERBOARD_RESULTS,
            {
              rosters: newRosters,
              showMore,
              eventAverage: result.response.eventAverage,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getSports = (action$) =>
  action$.pipe(
    ofType(GET_SPORTS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      sportGetSports(token).pipe(
        manualCancelApiCallFragment(action$, action, 'getSports'),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const sports = mapSportsApiToUi(result.response);
        return of(sportsGetSuccess(sports));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

export default combineEpics(
  getNationalLeaderboardResultsEpics,
  getNationalLeaderboardTemplateEpic,
  getNationalLeaderboardSportsEpic,
  getNationalLeaderboardGradYearsEpic,
  getSports,
);
