import {connect} from 'react-redux';
import Component from './RecruitingCoordinatorModal.component';
import {createRecruitingCoordinator, deleteRecruitingCoordinator, updateRecruitingCoordinator} from "../../+store";

const mapStateToProps = (state, ownProps) => {
  const {recruitingCoordinator, schoolTeamId} = ownProps;
  return {
    recruitingCoordinator,
    schoolTeamId
  }
}

const mapDispatchToProps = dispatch => ({
  updateRecruitingCoordinator: (id, values) => {
    dispatch(updateRecruitingCoordinator(id, {
      name: values.name,
      phone: values.phone,
      email: values.email,
      title: values.title ? values.title : 'Recruiting Coordinator'
    }));
  },
  deleteRecruitingCoordinator: (id, schoolTeamId) => {
    dispatch(deleteRecruitingCoordinator(id, schoolTeamId));
  },
  createRecruitingCoordinator: (values) => {
    dispatch(createRecruitingCoordinator({
      name: values.name,
      phone: values.phone,
      email: values.email,
      school_team_id: values.schoolTeamId,
      title: values.title ? values.title : 'Recruiting Coordinator'
    }));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
