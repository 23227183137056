import { createSelector } from 'reselect';

const codeSignUpLoading = state => state.modules.codeSignUp.codeSignUp.loadingMsg;
const accountPageLoading = state => state.modules.codeSignUp.accountPage.loadingMsg;
const physiquePageLoading = state => state.modules.codeSignUp.physiquePage.loadingMsg;
const profileLoading = state => state.ui.uxProfile.playerProfile.loadingMsg;
const signInPageLoading = state => state.modules.codeSignUp.signInPage.loadingMsg;
const sportsPageLoading = state => state.modules.codeSignUp.sportsPage.loadingMsg;

export const selectLoadingMsg = createSelector(
  codeSignUpLoading,
  accountPageLoading,
  physiquePageLoading,
  profileLoading,
  signInPageLoading,
  sportsPageLoading,
  (codeSignUp, accountPage, physiquePage, profile, signInPage, sportsPage) => (
    profile
    || Object.values(codeSignUp).reduce((curr, prev) => curr || prev, '')
    || accountPage
    || signInPage
    || physiquePage
    || sportsPage
  ),
);

export default 'assessment.selectors.js';
