import React, { PureComponent } from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';

class TextAreaControl extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    model: PropTypes.string.isRequired,
    type: PropTypes.string,
    help: PropTypes.any,
    rrfField: PropTypes.object.isRequired,
    helpClass: PropTypes.string,
    errors: PropTypes.object,
    maxLength: PropTypes.number,
    showCounter: PropTypes.bool,
    errorClass:PropTypes.string
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight + 29}px`;
    e.target.parentElement.style.height = 'auto';
    e.target.parentElement.style.height = `${e.target.scrollHeight}px`;
  };

  render() {
    const {
      label,
      model,
      type,
      help,
      helpClass,
      rrfField,
      errors,
      maxLength,
      showCounter,
      errorClass,
      ...otherProps
    } = this.props;
    const shouldShowCounter = showCounter && maxLength;
    return (
      <div className={[
        'UxCommon_Form_Group',
        errorClass ? errorClass : ''
      ].join(' ')}
      >
        <div
          id={`${model}_InputGroup`}
          className={[
            'UxCommon_Form_InputGroup',
            rrfField.valid ? '' : 'error',
            type === 'textarea' ? 'UxCommon_Form_TextareaGroup' : '',
          ].join(' ')}
          style={{
            height: '71px',
          }}
        >
          <div className="UxCommon_Form_ErrorMarker" />
          <div className="UxCommon_Form_LabelWrapper">
            <label
              className={[
                'UxCommon_Form_Label',
                rrfField.value && rrfField.value.length ? 'show' : '',
              ].join(' ')}
              htmlFor={model}
            >
              {label}
            </label>
          </div>
          <Control.textarea
            className={[
              'UxCommon_Form_Input UxCommon_Form_Textarea',
              rrfField.value && rrfField.value.length ? 'not-empty' : '',
            ].join(' ')}
            type={type || 'text'}
            model={model}
            id={model}
            onKeyDown={this.onKeyDown}
            onCut={this.onKeyDown}
            onPaste={this.onKeyDown}
            onDrop={this.onKeyDown}
            {...otherProps}
            style={{
              height: '71px',
            }}
          />
          {
            shouldShowCounter &&
            <div className="UxCommon_Form_Textarea_Counter">
              {`${rrfField.value.length} / ${maxLength}`}
            </div>
          }
        </div>
        {
          help &&
            <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
              {help}
            </HelpPopper>
        }
        <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
      </div>
    );
  }
}

export default TextAreaControl;
