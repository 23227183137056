import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cssVar from '../../App/App.utils';

class VcRating extends PureComponent {
  static propTypes = {
    vcRating: PropTypes.object,
    small: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.keysUsed = [];
  }

  getUnusedKeyValue = (key) => {
    const maxTries = 1000;
    let value = Math.abs(key);
    while (value < maxTries) {
      if (this.keysUsed.includes(value)) {
        value += 1;
      } else {
        return value;
      }
    }
    return value;
  }

  getUniquePathElementKey = (key) => {
    if (this.keysUsed.includes(key)) {
      const newKey = this.getUnusedKeyValue(key);
      this.keysUsed.push(newKey);
      return newKey;
    }
    this.keysUsed.push(key);
    return key;
  }

  getCoordinatesForPercent = (percent) => {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }

  getVcRating = () => {
    if (this.isSmall()) {
      return 'UxProfile_VcRating_Small';
    }
    return 'UxProfile_VcRating_Default';
  }

  getScoreTitle = () => 'VC Score'

  getSmallHidden = () => {
    if (this.isSmall()) {
      return 'UxProfile_VcRating_Hidden';
    }
    return '';
  }

  getCircles = () => {
    if (this.isSmall()) {
      return 'UxProfile_VcRating_Circles_Small';
    }
    return 'UxProfile_VcRating_Circles_Default';
  }

  getCenter2 = () => {
    if (this.isSmall()) {
      return 'UxProfile_VcRating_Center2_Small';
    }
    return 'UxProfile_VcRating_Center2_Default';
  }

  getCenter3 = () => {
    if (this.isSmall()) {
      return 'UxProfile_VcRating_Center3_Small';
    }
    return 'UxProfile_VcRating_Center3_Default';
  }

  isSmall = () => {
    const { small } = this.props;
    return (small && small === true);
  }

  createPieSlicePath = (percent, color, cumulativePercent) => {
    // destructuring assignment sets the two variables at once
    const [startX, startY] = this.getCoordinatesForPercent(cumulativePercent);

    // each slice starts where the last slice ended, so keep a cumulative percent
    const newCumulativePercent = cumulativePercent + percent;

    const [endX, endY] = this.getCoordinatesForPercent(newCumulativePercent);

    // if the slice is more than 50%, take the large arc (the long way around)
    const largeArcFlag = percent > 0.5 ? 1 : 0;

    // create an array and join it just for code readability
    const pathData = [
      `M ${startX} ${startY}`, // Move
      `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
      'L 0 0', // Line
    ].join(' ');

    const key = this.getUniquePathElementKey(newCumulativePercent);

    // create a <path> and append it to the <svg> element
    const pathEl = (
      <path key={key} xmlns="http://www.w3.org/2000/svg" fill={color} d={pathData} />
    );

    return [pathEl, newCumulativePercent];
  }

  render() {
    const { vcRating } = this.props;
    let strength = 0;
    let performance = 0;
    let rating = 'N/A';
    const primaryColor = cssVar('primary') || '#190165';
    const secondarColor = cssVar('secondary') || '#ec0616';
    if (vcRating) {
      if (vcRating.rating && (vcRating.rating !== 'N/A')) {
        ({ rating } = vcRating);
      }
      if (vcRating.strength && (vcRating.strength !== 'N/A')) {
        ({ strength } = vcRating);
      }
      if (vcRating.performance && (vcRating.performance !== 'N/A')) {
        ({ performance } = vcRating);
      }
    }
    if (rating > 9999) {
      rating = 9999;
    }
    let strengthPercent = strength / 1000.0;
    if (strengthPercent > 1.0) {
      strengthPercent = 1.0;
    }
    const strengthSlices = [
      { percent: strengthPercent, color: primaryColor },
      { percent: 1 - strengthPercent, color: '#f7f7f7' },
    ];
    let cumulativePercent = 0;

    const strengthPaths = [];
    strengthSlices.forEach((slice) => {
      const [path, newPercent] = this.createPieSlicePath(
        slice.percent,
        slice.color,
        cumulativePercent,
      );
      cumulativePercent = newPercent;
      strengthPaths.push(path);
    });

    let performancePercent = performance / 1000.0;
    if (performancePercent > 1.0) {
      performancePercent = 1.0;
    }
    const performanceSlices = [
      { percent: performancePercent, color: secondarColor },
      { percent: 1 - performancePercent, color: '#f7f7f7' },
    ];
    cumulativePercent = 0;

    const performancePaths = [];
    performanceSlices.forEach((slice) => {
      const [path, newPercent] = this.createPieSlicePath(
        slice.percent,
        slice.color,
        cumulativePercent,
      );
      cumulativePercent = newPercent;
      performancePaths.push(path);
    });

    return (
      <div className={`UxProfile_VcRating ${this.getVcRating()}`}>
        <div className={`UxProfile_VcRating_Circles ${this.getCircles()}`}>
          <div className="UxProfile_VcRating_StrengthCircle">
            <svg viewBox="-1 -1 2 2" style={{ transform: 'rotate(-90deg)' }}>
              {strengthPaths}
              <circle cx="0" cy="0" r=".92761905" fill="#ffffff" />
            </svg>
          </div>
          <div className="UxProfile_VcRating_PerformanceCircle">
            <svg viewBox="-1 -1 2 2" style={{ transform: 'rotate(-90deg)' }}>
              {performancePaths}
              <circle cx="0" cy="0" r=".91314286" fill="#ffffff" />
            </svg>
          </div>
          <div className="UxProfile_VcRating_Center d-flex justify-content-center flex-column align-items-center">
            <div className="UxProfile_VcRating_Center1" />
            <div className={`UxProfile_VcRating_Center2 ${this.getCenter2()}`}>
              {rating}
            </div>
            <div className={`UxProfile_VcRating_Center3 ${this.getCenter3()}`}>
              {this.getScoreTitle()}
            </div>
          </div>
        </div>
        {/* should be able to apply optional hidden here? But apparently not... */}
        <div className="UxProfile_VcRating_Points d-flex justify-content-center">
          <div className="UxProfile_VcRating_Point d-flex align-items-center">
            <div className={`UxProfile_VcRating_PerformancePoints ${this.getSmallHidden()}`}>
              {performance}
            </div>
            <div className={`${this.getSmallHidden()}`}>
              Performance
            </div>
          </div>
          <div className="UxProfile_VcRating_Point d-flex align-items-center">
            <div className={`UxProfile_VcRating_StrengthPoints ${this.getSmallHidden()}`}>
              {strength}
            </div>
            <div className={`${this.getSmallHidden()}`}>
              Strength
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VcRating;

/*
  Creds for the svg craziness:
  https://hackernoon.com/a-simple-pie-chart-in-svg-dbdd653b6936
*/
