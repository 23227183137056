import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/PlayerData/Stats/NewSeasonForm/NewSeasonForm';
import {
  getProfile,
  getUserTeams,
  getStandardStats,
  getCurrentSportId, getCurrentSport,
} from '../../UxCommon/Utils';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import { positions } from '../../../components/UxCommon/Form/utils';

const mapStateToProps = (state, ownProps) => {
  const statsStd = getStandardStats(state, ownProps.match);
  let positionOptions;
  if (statsStd.positions) {
    positionOptions = statsStd.positions;
  }
  const currentSportId = getCurrentSportId(state);
  // only allow user to select weight classes of the correct gender for wrestling
  if (currentSportId === 14) {
    const user = getProfile(state, ownProps.match);
    const positionNames = positions(currentSportId, user.gender)
      .map(e => e.name);
    positionOptions = statsStd.positions.filter(e => positionNames.indexOf(e.name) > -1);
  }

  return {
    rrfForm: state.forms.forms.firstSeason,
    positions: positionOptions,
    allTeams: getUserTeams(state, ownProps.match) || [],
    teamTypeAheadResults: state.ui.uxPlayerData.teams.teamTypeAheadResults
      .filter(team => team.sportId === currentSportId),
    loading: !!state.ui.uxPlayerData.stats.firstSeasonLoadingMsg,
    loadingMsg: state.ui.uxPlayerData.stats.firstSeasonLoadingMsg,
    currentSport: getCurrentSport(state),
    enteredTeamName: state.forms.forms.firstSeason.teamName.value,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onSeasonStartChange: (date) => {
      dispatch(actions.change('forms.firstSeason.seasonStart', date));
    },
    onSeasonEndChange: (date) => {
      dispatch(actions.change('forms.firstSeason.seasonEnd', date));
    },
    copyModelValue: (model, value) => {
      dispatch(actions.change(model, value));
    },
    formInit: () => {
      dispatch(actions.setInitial('forms.firstSeason'));
      dispatch(actions.reset('forms.firstSeason'));
    },
    submit: () => {
      dispatch(formSubmit('forms.firstSeason'));
    },
    onTeamSearchResultClick: (result) => {
      dispatch(actions.change('forms.firstSeason.team', result.team));
      dispatch(actions.change('forms.firstSeason.teamName', result.team.googleName));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
