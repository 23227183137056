import React from "react";
import PropTypes from "prop-types";
import NavBarLink from "../../common/NavBarLink";
import { Routes } from "../../../../store/actions/ui/routes";
import { TabLabels } from "../../+store";
import ToggleView from "../../Leaderboard/ToggleView";
import SelectControl from "../../common/FormControls/SelectControl";
import NavBar from "../../common/NavBar";
import { BankSlugs } from "../../+store/combine";
import { BankCodes } from "../../+store/allTimeLeaderboard";
import { Redirect } from "react-router-dom";


export const scoreTypeOptions = {
  [BankSlugs.STRENGTH]: {
    raw: 'Raw',
    relative: 'PFP Strength',
    rated: 'Score',
  },
  [BankSlugs.PERF]: {
    raw: 'Raw',
    rated: 'Score',
  },
  [BankSlugs.HITTING]: {
    raw: 'Raw',
    rated: 'Score',
  },
  [BankSlugs.PITCHING]: {
    raw: 'Raw',
    rated: 'Score',
  },
  [BankSlugs.COACHEVAL]: {
    raw: 'Raw',
    rated: 'Score',
  },
  [BankSlugs.STATS]: {
    raw: 'Raw',
    rated: 'Score',
  },
  [BankSlugs.VC_SCORE]: {
    raw: 'Score',
  },
};

const resultNumObject = {
  5: "5",
  10: "10",
  20: "20",
  50: "50",
  100: "100"
};
const notLoggedInResults = {
  5: "5",
  10: "10",
  20: "20",
  50: "50",
  100: "100"
};

const AllTimeLeaderboardHeader = ({
                                    match, onResultsChange, numResultsShown,
                                    onScoreTypeChange, selectedScoreType, location, isLoggedIn, sport, banks
                                  }) => {
  let scoreOptions = {};
  const { schoolId } = match.params;

  if ([BankSlugs.PHYSIQUE].indexOf(match.params.bank) !== -1) {
    return (<Redirect
      to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.PERF}${location.search}`}
    />);
  }
  if (match.params.bank) {
    scoreOptions = scoreTypeOptions[match.params.bank] || {};
  }
  return (
    <div className="LeaderboardHeader" style={{ marginBottom: 15 }}>
      <div className="LeaderboardHeader_ControlsWrapper">
        <div className="LeaderboardHeader_Tabs AllTimeLeaderboardHeader_Tabs">
          <NavBar className="Leaderboard">
            {
              banks.includes(BankCodes.PERF) && (
                <div className="LeaderboardNavBarLinkWrapper">
                  <NavBarLink
                    to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.PERF}`}
                    match={match}
                    location={location}
                    className="Leaderboard Performance"
                  >
                    {TabLabels.PERF}
                  </NavBarLink>
                </div>
              )
            }
            {banks.includes(BankCodes.HITTING) &&
              <div className="LeaderboardNavBarLinkWrapper">
                <NavBarLink
                  to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.HITTING}`}
                  match={match}
                  location={location}
                  className="Leaderboard Hitting"
                >
                  {TabLabels.HITTING}
                </NavBarLink>
              </div>
            }
            {banks.includes(BankCodes.PITCHING) &&
              <div className="LeaderboardNavBarLinkWrapper">
                <NavBarLink
                  to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.PITCHING}`}
                  match={match}
                  location={location}
                  className="Leaderboard Pitching"
                >
                  {TabLabels.PITCHING}
                </NavBarLink>
              </div>
            }

            {
              banks.includes(BankCodes.COACHEVAL) &&
              <div className="LeaderboardNavBarLinkWrapper">
                <NavBarLink
                  to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.COACHEVAL}`}
                  match={match}
                  location={location}
                  className="Leaderboard Pitching Leaderboard-nav"
                >
                  {TabLabels.COACHEVAL}
                </NavBarLink>
              </div>
            }

            {
              banks.includes(BankCodes.STRENGTH) && (
                <div className="LeaderboardNavBarLinkWrapper">
                  <NavBarLink
                    to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.STRENGTH}`}
                    match={match}
                    location={location}
                    className="Leaderboard Strength"
                  >
                    {TabLabels.STRENGTH}
                  </NavBarLink>
                </div>
              )
            }

            {
              banks && banks.length && sport && sport === "FOOTBALL" && (
                <div className="LeaderboardNavBarLinkWrapper">
                  <NavBarLink
                    to={`/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${BankSlugs.VC_SCORE}`}
                    match={match}
                    location={location}
                    className="Leaderboard VC_Score"
                  >
                    {TabLabels.VC}
                  </NavBarLink>
                </div>
              )
            }
          </NavBar>
        </div>
        <div className="LeaderboardHeader_Other">
          <div className="LeaderboardHeader_ToggleView">
            <ToggleView />
          </div>
          <SelectControl
            name="raw"
            options={scoreOptions}
            defaultValue={selectedScoreType}
            myOnChange={onScoreTypeChange}
          />
          <SelectControl
            name="result"
            label="Results"
            options={isLoggedIn ? resultNumObject : notLoggedInResults}
            defaultValue={isLoggedIn ? numResultsShown : numResultsShown}
            myOnChange={onResultsChange}
          />
        </div>
      </div>
      {/*<div className="LeaderboardHeader_TextWrapper">*/}
      {/*  <div className="LeaderboardHeader_Text"> Leaderboard</div>*/}
      {/*</div>*/}
    </div>
  );
};
AllTimeLeaderboardHeader.propTypes = {
  match: PropTypes.object.isRequired,
  onResultsChange: PropTypes.func.isRequired,
  numResultsShown: PropTypes.string.isRequired,
  onScoreTypeChange: PropTypes.func.isRequired,
  selectedScoreType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  sport: PropTypes.string,
  banks: PropTypes.array
};


export default AllTimeLeaderboardHeader;
