import { connect } from 'react-redux';
import Component from './CoachAccount.component';
import { selectLoadingMsg } from '../+store';

const mapStateToProps = (state) => {
  const loadingMsg = selectLoadingMsg(state);
  const loading = !!loadingMsg;
  let phoneNeedsConfirmed = false;
  let emailNeedsConfirmed = false;
  const { cognitoUser } = state.data.cognito;
  let attributes;
  if (cognitoUser && cognitoUser.attributes) {
    ({ attributes } = cognitoUser);
  } else if (cognitoUser
  && cognitoUser.signInUserSession
  && cognitoUser.signInUserSession.idToken) {
    attributes = cognitoUser.signInUserSession.idToken.payload;
  }
  if (attributes) {
    emailNeedsConfirmed = !!attributes.email
    && !attributes.email_verified;
    phoneNeedsConfirmed = !!attributes.phone_number
    && !attributes.phone_number_verified;
  }
  return {
    loading,
    loadingMsg,
    schoolStyles: state.modules.coachWorld.coachWorld.schoolStyle,
    emailNeedsConfirmed,
    phoneNeedsConfirmed,
  };
};

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
