import {combineEpics, ofType} from "redux-observable";
import {DELETE_EXTERNAL_PROFILE } from "../actions";
import { cancelOnRouteChange, getTokenFragment} from "../../utils";
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import {deleteExternalProfileApi} from "../../../../data/user/externalProfile";
import {Routes} from "../../../routes";
import {ajaxErrorHandlerEpicFragment} from "../../../ajaxErrorHandlers";
import {asyncErrorAction, asyncFinishAction} from "../../../async";
import {externalProfileDeleteSuccess} from "../../../../data/user/externalProfile/actions";
import {DELETE_AWARD} from "../../awards";
import {deleteAward} from "../../../../data/user/awards";




export const deleteExternalProfileEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const schoolId = () => state$.value.ui.app.routes.currentSchoolId;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(DELETE_EXTERNAL_PROFILE),
    getTokenFragment(),
    switchMap(({ action, token }) =>
    {
      console.log({action})
      return deleteExternalProfileApi(uuid(), action.externalProfileId, token)
        .pipe(
          map(() => ({ action, success: true, uuid: uuid() })),
          takeUntil(cancelOnRouteChange(action$, Routes.externalProfile, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(DELETE_AWARD, 'deleteAward', error),
          })),
        )
    }
      ),
    switchMap((result) => {
      if (result.success) {
        return of(
          externalProfileDeleteSuccess(result.action.externalProfileId, result.uuid),
          asyncFinishAction(DELETE_EXTERNAL_PROFILE, 'deleteExternalProfile', { id: result.action.externalProfileId, uuid: result.uuid , sportId:state$.value.ui.app.routes.currentSportId, schoolId: schoolId()}),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};

const deleteEpics = combineEpics(deleteExternalProfileEpic);
export default deleteEpics;
