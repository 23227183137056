import { combineReducers } from 'redux';
import {
  PLAYER_DATA_CATEGORY_TEST_RESULT_SUC, PlayerData,
  SPORT_GET_SPORTS_SUC,
  ORG_AFF_SPORTS,
  STATS_GET_STD_SUC,
  STATS_GET_USER_STATS_SUC,
  TESTS_GET_PLAYER_DATA_SUC,
  TESTS_GET_STD_SUC,
} from '../../actions/data/sport';
import { EP_GET_REQ } from '../../actions/ui/uxEditProfile';
import { USER_PROFILE_DELETE_SUC } from '../../actions/data/user';
import { TEAM_CREATE_SUC } from '../../actions/data/sport/teams';
import { GET_PROFILE_DATA_SUC } from '../../actions/data/sport/playerData';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT
} from '../../actions/data/cognito';
import { GET_PLAYER_DATA } from '../../../modules/ProspectSheet/+store/prospectSheet';
import { ASYNC_FINISH } from '../../actions/ui/async';
import {PD_GET_PERSONAL_RECORDS} from '../../actions/ui/uxPlayerData/tests/actions'
import { Sports } from '../../actions/ui/routes';
import {SAVE_PRIVACY} from "../../actions/ui/uxProfile";

const loadingSports = (state = false, action) => {
  switch (action.type) {
    case EP_GET_REQ:
      return true;
    case SPORT_GET_SPORTS_SUC:
      return false;
    default:
      return state;
  }
};

const sports = (state = [], action) => {
  switch (action.type) {
    case SPORT_GET_SPORTS_SUC:
      return action.sports || [];
    default:
      return state;
  }
};

const profileData = (state = {}, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          [action.sportId]: action.data[action.sportId],
          performanceClass : action.data.performanceClass
        }),
      },
    );
    case USER_PROFILE_DELETE_SUC:
      return Object.keys(state)
        .filter(key => key !== action.uuid)
        .reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {});
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const statsStd = (state = null, action) => {
  switch (action.type) {
    case STATS_GET_STD_SUC:
      return Object.assign({}, state, action.std);
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const sportsOrgAff = (state={}, action) => {
  switch (action.type) {
    case ORG_AFF_SPORTS:
        const { schoolStyle, teams, sports } = action;
        let orgAffSports={};
        if (schoolStyle.length && teams.length && sports.length) {
          teams.forEach(team => team.schoolTeams.forEach((schoolTeam) => {
            orgAffSports[`${schoolTeam.sportId}`] = Sports[schoolTeam.sportId].charAt(0).toUpperCase() + Sports[schoolTeam.sportId].slice(1);
          }));
        }
        return orgAffSports;
    default:
      return state;
  }
}

const statsFormatStandart = (state = null, action) => {
  switch (action.type) {
    case STATS_GET_STD_SUC:
      return action.stdFormats;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const testsStd = (state = null, action) => {
  switch (action.type) {
    case TESTS_GET_STD_SUC:
      return Object.assign({}, state, action.std);
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return null;
    default:
      return state;
  }
};

const sportIdStats = (state, action) => {
  const {
    uuid,
    sportId,
    stats,
  } = action;
  const stateToInsert = {};
  stateToInsert[sportId] = stats[uuid][sportId];
  return Object.assign({}, state, stateToInsert);
};

const userStats = (state = {}, action) => {
  const stateToInsert = {};
  let teams;
  switch (action.type) {
    case STATS_GET_USER_STATS_SUC:
      stateToInsert[action.uuid] = sportIdStats(state[action.uuid], action);
      return Object.assign({}, state, action.stats);
    case USER_PROFILE_DELETE_SUC:
      ({ [`${action.uuid}`]: deleted, ...newState } = state);
      return newState;
    case TEAM_CREATE_SUC:
      if ((state[action.uuid]) && (state[action.uuid][action.sportId])) {
        ({ teams } = state[action.uuid][action.sportId]);
      } else {
        teams = [];
      }
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          [action.sportId]: Object.assign({}, state[action.uuid][action.sportId], {
            teams: [
              ...teams,
              action.team,
            ],
          }),
        }),
      });
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const userTeams = (state = {}, action) => {
  let teams;
  switch (action.type) {
    case STATS_GET_USER_STATS_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.allTeams,
      });
    case TEAM_CREATE_SUC:
      if ((state[action.uuid]) && (state[action.uuid][action.sportId])) {
        teams = state[action.uuid][action.sportId];
      } else {
        teams = [];
      }
      return Object.assign({}, state, {
        [action.uuid]: Object.assign({}, state[action.uuid], {
          [action.sportId]: [
            ...teams,
            action.team,
          ],
        }),
      });
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const athletePersonalRecords = (state=[], action) =>{
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === GET_PLAYER_DATA){
        return [...action.data.playerData];
      }
    default:
      return state;
  }
}

const combineRecords = (state=[], action) =>{
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === PD_GET_PERSONAL_RECORDS){
        return [...action.data.playerData];
      }
      // if (action.model === SAVE_PRIVACY) {
      //   return Object.assign({}, {
      //     [action.data.uuid]: Object.assign(
      //       [],
      //       state[action.data.uuid],
      //       action.data.combine,
      //     ),
      //   });
      // }
    default:
      return state;
  }
}

const playerData = (state = {}, action) => {
  let newState = null;
  switch (action.type) {
    case TESTS_GET_PLAYER_DATA_SUC: {
      const prevData = state[action.uuid];
      const prevPlayerData = prevData instanceof PlayerData ? prevData.clone() : null;
      const nextPlayerData = action.playerData;
      let prevTestResults = [];

      // merged the previous test results with the latest
      if (prevPlayerData) {
        prevTestResults = prevPlayerData.testResults;

        // remove the previous test results of given category
        if (action.categoryId) {
          prevTestResults = prevTestResults
            .filter(testResult => testResult.stdTestCategory.id !== action.categoryId);
        }
        nextPlayerData.testResults = [...nextPlayerData.testResults, ...prevTestResults];
      }

      return Object.assign({}, state, {
        [action.uuid]: nextPlayerData,
      });
    }
    case USER_PROFILE_DELETE_SUC:
      ({ [`${action.uuid}`]: deleted, ...newState } = state);
      return newState;
    case PLAYER_DATA_CATEGORY_TEST_RESULT_SUC:
      const prevData = state[action.uuid];
      const prevPlayerData = prevData instanceof PlayerData ? prevData.clone() : null;
      if (prevPlayerData) {
        prevPlayerData.addNewTestResults(action.testResults.test_results, action.categoryId);
        return Object.assign({}, state, {
          [action.uuid]: prevPlayerData,
        });
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingSports,
  sports,
  sportsOrgAff,
  profileData,
  athletePersonalRecords,
  combineRecords,
  playerData,
  statsStd,
  userStats,
  userTeams,
  testsStd,
  statsFormatStandart,
});

export default reducer;
