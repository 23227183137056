import {combineEpics, ofType} from 'redux-observable';
import {switchMap, map, combineAll, delay} from 'rxjs/operators';
import {of, concat} from 'rxjs';

import {asyncFinishAction} from '../../../../store/actions/ui/async';
import {
  manualCancelApiCallFragment,
  getTokenFragment,
  checkForkErrors
} from '../../../../store/actions/ui/uxProfile/utils';
import {playerProfileGet} from '../../../../store/actions/ui/uxProfile';
import {SAVE_SPORT, GET_SCHOOL_SPORT_TEAMS_LIST} from './sportsPage.actions';
import {
  mapUserSportToApiProfileSports,
  mapUserBaseballSoftballToApiProfileSports,
  SportsPageSchoolTeam,
  mapExistingTeamToApiUserTeams,
  mapNewTeamToApiUserTeams
} from './sportsPage.models';
import {
  postSportTeam,
  addSportToProfile,
  updateSportProfile,
  getSchoolSportTeamsList,
  getUsersTeams
} from './sportsPage.api';
import {profileUpdate} from '../../../../store/actions/data/user';

const saveSportsEpic = action$ => action$.pipe(
  ofType(SAVE_SPORT),
  getTokenFragment(),
  switchMap(({action, token}) => {
    /*
      action: {
        profile,
        roleId,
        teams: myTeams.filter(s => s),
        newTeams,
        code: match.params.code,
        schoolId: schoolInfo.id,
      }
      team: {
        sportId: 0,
        teamIds: [],
        positionId: [],
        newTeams: [],
      }
      newTeam: {
        sportId,
        level,
        name,
      }
    */
    const apiCalls = [];
    action.teams.forEach((team) => {
      team.teamIds.forEach((teamId) => {
        ((team.positions && team.positions.length ? team.positions : null) || [undefined]).forEach((position) => {
          let props;
          if (teamId.startsWith('new')) {
            const newTeam = action.newTeams.find(n => n.id === teamId);
            props = mapNewTeamToApiUserTeams(newTeam, position, team.jerseyNumber, action.schoolId);
          } else {
            props = mapExistingTeamToApiUserTeams(teamId, position, team.jerseyNumber);
          }
          apiCalls.push(postSportTeam(
            action.profile.uuid,
            token,
            props,
          ).pipe(manualCancelApiCallFragment(
            action$,
            action,
            'codeSignUpSaveSport',
          )));
        });
      });
    });

    // Update profile role id if role level has increased
    if ((!action.profile.roleId) || (action.roleId > action.profile.roleId)) {
      apiCalls.push(profileUpdate(
        action.profile.uuid,
        {role_id: action.roleId},
        token,
      ).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'codeSignUpSaveSport',
      )));
    }

    const uniqueSportsSet = {};
    action.teams.forEach((team) => {
      uniqueSportsSet[team.sportId] = team.sportId;
    });
    const uniqueSports = Object.values(uniqueSportsSet);
    uniqueSports.forEach((sportId) => {
      const teams = action.teams.filter(t => t.sportId === sportId);
      const positions = teams.reduce((prev, curr) => (
        [...prev, ...curr.positions]
      ), []);
      const jerseyNumbers = teams.reduce((prev, curr) => (
        [...prev, curr.jerseyNumber]
      ), []);
      const existingUserSport = action.profile.sports.find(s => (
        s.sportId === Number(sportId)
      ));
      let throws, bats;
      if(sportId === "2" || sportId === "4"){
        throws = teams.reduce((prev, curr) => (
          [...prev, curr.throws]
          ), []);
          bats = teams.reduce((prev, curr) => (
            [...prev, curr.bats]
            ), []);
      }
      let teamSports = {
        sportId,
        positions,
        jerseyNumbers,
        throws,
        bats
      } 
      if (existingUserSport) {
        apiCalls.push(updateSportProfile(
          action.profile.uuid,
          sportId,
          token,
          (sportId === "2" || sportId === "4") ?
          mapUserBaseballSoftballToApiProfileSports(action.profile, teamSports) :
          mapUserSportToApiProfileSports(action.profile, teamSports)
        ).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'codeSignUpSaveSport',
        )));
      } else {
        apiCalls.push(addSportToProfile(
          action.profile.uuid,
          token,
          (sportId === "2" || sportId === "4") ?
          mapUserBaseballSoftballToApiProfileSports(action.profile, teamSports) :
          mapUserSportToApiProfileSports(action.profile, teamSports)
        ).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'codeSignUpSaveSport',
        )));
      }
    });
    return concat(...apiCalls.map(apiCall => apiCall.pipe(delay(1000), map(a => of(a))))).pipe(
      combineAll(),
      checkForkErrors(),
      map((forkResults) => {
        if (forkResults.success) {
          return {
            ...forkResults,
            action,
            token,
          };
        }
        return forkResults;
      }),
    );
  }),
  switchMap((result) => {
    if (result.success) {
      return getUsersTeams(
        result.action.profile.uuid,
        result.token,
      ).pipe(manualCancelApiCallFragment(
        action$,
        result.action,
        'codeSignUpSaveSport',
        {
          teamAndProfileResults: result.results,
          token: result.token,
        },
      ));
    }
    return of(result);
  }),
  switchMap((result) => {
    if (result.success) {
      if (result.action.isMobile) {
        result.action.dispatch(playerProfileGet(result.action.profile.uuid));
      }
      const profileResponse = result.teamAndProfileResults[result.teamAndProfileResults.length - 1];
      return of(asyncFinishAction(result.action.type, 'codeSignUpSaveSport', {
        uuid: result.action.profile.uuid,
        roleId: profileResponse.response.role_id,
      }));
    }
    if (result.actions) return of(...result.actions);
    return of(result.action);
  }),
);

const getSchoolTeamsListEpic = action$ => (
  action$.pipe(
    ofType(GET_SCHOOL_SPORT_TEAMS_LIST),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getSchoolSportTeamsList(action.schoolId, 0, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getSchoolSportTeamsList',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getSchoolSportTeamsList', {
          schoolId: result.action.schoolId,
          sportId: result.action.sportId,
          schoolTeams: result.response.map(r => SportsPageSchoolTeam.fromApi(r)),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


export default combineEpics(
  saveSportsEpic,
  getSchoolTeamsListEpic,
);
