import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/PlayerData/TestData/RatingsRow/RatingsRow';

import { getPlayerData } from '../../UxCommon/Utils';
import { isMobileApp } from '../../../utils';
import {YOUTH} from '../../../components/UxProfile/UxProfile.constants';
const mapStateToProps = (state, ownProps) => {
  const playerData = getPlayerData(state, ownProps.match);
  const sportId = isMobileApp ? state.ui.app.mobile.currentSport.sportId :
    state.ui.app.routes.currentSportId;
  let vcScore = null;
  let strength = null;
  let performance = null;
  // if (playerData) {
  //   ({ vcScore } = playerData);
  //   strength = playerData.strengthSignature;
  //   performance = playerData.performanceSignature;
  // }
let sportData;
  if (playerData && playerData.vcRatings) {
    if(playerData.performanceClass === YOUTH) {
      sportData = playerData.vcRatings.find(vc => vc ? Object.keys(vc).length > 0:false);
    } else {
      if(playerData.vcRatings[sportId]){
      sportData = playerData.vcRatings[sportId];
      }
    }
    // const sportData = playerData.vcRatings[sportId];
    if (sportData) {
      ({ vcScore } = sportData);
      strength = sportData.strengthSignature;
      performance = sportData.performanceSignature;
    }
  }
  return {
    vcScore,
    strength,
    performance,
  };
};

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;