import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Component from "../../components/Welcome/MergeAnotherAccountAuth/MergeAnotherAccountAuth";
import { isInviteValid, mergeAnotherAccountFormInit } from "../../store/actions/ui/welcome";
import { formServerError, formSubmit } from "../../store/actions/ui/formUtils";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import { playerProfileGet } from "../../store/actions/ui/uxProfile";

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};


const mapStateToProps = (state, ownProps) => {
  const authUuid = state.data.cognito.uuid;
  return ({
    needsInit: state.ui.welcome.mergeAnotherAccount.formNeedsInit,
    rrfForm: state.forms.forms.mergeAnotherAccount,
    formGeneralError: state.ui.welcome.mergeAnotherAccount.formError,
    loggedIn: !!state.data.cognito.token,
    profile: getProfile(state, ownProps.match.params.uuid),
    mergeProfileLoaded: state.ui.welcome.mergeAnotherAccount.profileLoaded,
    authenticatedProfile: getProfile(state, authUuid),
    authUuid,
    state
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const { loggedIn, authUuid } = newStateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    checkIfInviteIsValid: (userTeamId, token) => {
      dispatch(isInviteValid(userTeamId, token));
    },
    updateFormError: (userName, exceptionType) => {
      const error = {
        code: exceptionType,
        message: "You cannot provide the email which receive the invite , please provide the different email",
        value: userName
      };
      dispatch(formServerError("forms.mergeAnotherAccount", "duplicateEmail", error, {
        username: userName
      }));
    },
    formInit: () => {
      dispatch(actions.setInitial("forms.mergeAnotherAccount",{}));
      dispatch(mergeAnotherAccountFormInit());
      dispatch(actions.merge("forms.mergeAnotherAccount", {
        username: ''
      }));
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    submit: (uuid, token, userTeamId, isRegistered) => {
      let referrer = "";
      if (ownProps.location && ownProps.location.state) {
        referrer = ownProps.location.state.from;
      }
      dispatch(formSubmit(loggedIn ? "forms.acceptInvitation" : "forms.mergeLogin", {
        from: referrer,
        isMobile: false,
        uuid,
        authUuid,
        token,
        userTeamId,
        dispatch,
        isRegistered
      }));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default Container;
