import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { ButtonRect } from '../../../../components/UxCommon';

const ColorPicker = ({
  color, colorID, choseColor, onCancel,
}) => {
  const [colorPreview, setColorPreview] = useState(color);

  const handleChangeComplete = (selectedColor) => {
    if (selectedColor.hex !== colorPreview) {
      setColorPreview(selectedColor.hex);
    }
  };

  const onChooseColorClick = () => {
    choseColor(colorPreview, colorID);
  };

  return (
    <div className="ColorPicker">
      <ChromePicker color={colorPreview} onChangeComplete={handleChangeComplete} disableAlpha />
      <div className="ColorPicker_Buttons">
        <ButtonRect type="button" onClick={onChooseColorClick}>
          Choose Color
        </ButtonRect>
        <ButtonRect type="button" onClick={onCancel}>
          Cancel
        </ButtonRect>
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.any.isRequired,
  colorID: PropTypes.number.isRequired,
  choseColor: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ColorPicker;
