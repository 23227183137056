import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import TeamTypeAheadTextControl from './../TeamTypeAheadTextControl/TeamTypeAheadTextControl';
import SearchResult from './SearchResult/SearchResult';
// import Errors from '../../../../../UxCommon/Form/Errors/Errors';

class TeamTypeAhead extends PureComponent {
  static propTypes = {
    searchResults: PropTypes.array.isRequired,
    onSearchResultClick: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    allTeams: PropTypes.array.isRequired,
    // errors: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    hasFocus: false,
    resultsMouseIn: false,
  };

  componentDidUpdate = () => {}

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
  }

  onBlur = () => {
    this.setState({
      hasFocus: false,
    });
  }

  onResultsMouseIn = () => {
    this.setState({
      resultsMouseIn: true,
    });
  }

  onResultsMouseOut = (e) => {
    if (e.target.parentNode === this.myRef.current) {
      this.setState({
        resultsMouseIn: false,
      });
    }
  }

  resetMouseIn = () => {
    this.setState({
      resultsMouseIn: false,
    });
  };

  render() {
    const {
      searchResults,
      onSearchResultClick,
      rrfForm,
      allTeams,
      // errors,
    } = this.props;
    const { hasFocus, resultsMouseIn } = this.state;
    let resultsToShow;
    if (searchResults.length) {
      resultsToShow = searchResults;
    } else if ((rrfForm.team.value) &&
      (rrfForm.team.value.googleName === rrfForm.teamName.value)) {
      resultsToShow = [];
    } else {
      resultsToShow = allTeams;
    }

    // Fix Control for team
    const teamProps = {};
    if (rrfForm.team === undefined) {
      teamProps.value = '';
    } else if (rrfForm.team.value === null) {
      teamProps.value = '';
    }

    return (
      <div className="TeamTypeAhead">
        <TeamTypeAheadTextControl
          label="Team"
          placeholder="Team"
          model="forms.firstSeason.teamName"
          rrfField={rrfForm.team}
          rrfRelatedField={rrfForm.teamName}
          autoComplete="off"
          autofill="off"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <Control
          type="hidden"
          model="forms.firstSeason.team"
          defaultValue={null}
          {...teamProps}
        />
        {
          ((hasFocus || resultsMouseIn) && !!resultsToShow.length) && (
            <div
              ref={this.myRef}
              className="TeamTypeAhead_SearchResults"
              onMouseEnter={this.onResultsMouseIn}
              onMouseLeave={this.onResultsMouseOut}
            >
              {
                resultsToShow.map(result => (
                  <SearchResult key={result.id} result={result} onClick={onSearchResultClick} />
                ))
              }
            </div>
          )
        }
      </div>
    );
  }
}

export default TeamTypeAhead;
