export const ASYNC_START = 'async.start';
export const asyncStartAction = (model, msg, attrs) => ({
  type: ASYNC_START,
  model,
  msg,
  attrs,
});
export const asyncStart = dispatch => (model, msg) => {
  dispatch(asyncStartAction(model, msg));
};
export const ASYNC_FINISH = 'async.finish';
export const asyncFinishAction = (model, action, data) => ({
  type: ASYNC_FINISH,
  model,
  action,
  data,
});
export const asyncFinish = dispatch => (model, action, data) => {
  dispatch(asyncFinishAction(model, action, data));
};
export const ASYNC_PROGRESS = 'async.progress';
export const asyncProgressAction = (model, action, data) => ({
  type: ASYNC_PROGRESS,
  model,
  action,
  data,
});
export const asyncProgress = dispatch => (model, action, data) => {
  dispatch(asyncProgressAction(model, action, data));
};
export const ASYNC_ERROR = 'async.error';
export const asyncErrorAction = (model, desc, error, data) => ({
  type: ASYNC_ERROR,
  model,
  desc,
  error,
  data,
});
export const asyncError = dispatch => (model, desc, error, data) => {
  dispatch(asyncErrorAction(model, desc, error, data));
};
