import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextControl from '../FormControls/TextControl';

class SearchControl extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onChange = (value) => {
    const { location, history, onChange } = this.props;
    onChange(location, history, value);
  }

  shouldUpdate = () => {}

  render() {
    const {
      placeholder,
      value,
      autofocus,
    } = this.props;
    return (
      <div className="CoachSearchControl">
        <TextControl
          className="SearchControl_Field"
          placeholder={placeholder || ''}
          name="search"
          myOnChange={this.onChange}
          value={value}
          focus={true}
          autofocus={autofocus}
        />
      </div>
    );
  }
}

export default SearchControl;
