import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonIconOnly } from '../..';
import Icon from '../../Icon/Icon';

class SettingsWidget extends PureComponent {
  static propTypes = {
    onOpenQuality: PropTypes.func.isRequired,
    onOpenSpeed: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.wasOpen = false;
    this.mouseIn = false;
  }

  state = {
    isOpen: false,
  };

  componentDidUpdate() {
  }

  onMouseEnter = () => {
    this.mouseIn = true;
  }

  onMouseLeave = () => {
    this.mouseIn = false;
  }

  onSetLevel = (level) => {
    const { onSetLevel } = this.props;
    onSetLevel(level);
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { onOpenSpeed, onOpenQuality } = this.props;
    return (
          <div className="VideoPlayer_SettingsWidget">
            <button className="VideoPlayer_ButtonWithIcon" onClick={onOpenSpeed}>
              <div className="VideoPlayer_ButtonWithIcon_Left">
                Playback Speed
              </div>
              <div className="VideoPlayer_ButtonWithIcon_Right">
                <Icon iconName="chevron-right"/>
              </div>
            </button>
            <button className="VideoPlayer_ButtonWithIcon" onClick={onOpenQuality}>
              <div className="VideoPlayer_ButtonWithIcon_Left">
                Quality
              </div>
              <div className="VideoPlayer_ButtonWithIcon_Right">
                <Icon iconName="chevron-right" />
              </div>
            </button>
        </div>
    );
  }
}

export default SettingsWidget;
