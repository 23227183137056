import { combineReducers } from 'redux';
import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../../actions/ui/async';
import { OPEN_EXTERNAL_VIDEO_DIALOG, CLOSE_EXTERNAL_VIDEO_DIALOG, CHECK_VIDEO_URL } from '../../../../actions/ui/uxProfile/photosVideos/actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CHECK_VIDEO_URL) {
        return 'Checking URL ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === CHECK_VIDEO_URL) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const isDialogOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_EXTERNAL_VIDEO_DIALOG:
      return true;
    case CLOSE_EXTERNAL_VIDEO_DIALOG:
      return false;
    case ASYNC_FINISH:
      if (action.model === CHECK_VIDEO_URL) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  isDialogOpen,
});

export default reducer;
