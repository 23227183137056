import {combineEpics, ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';
import {of, forkJoin} from 'rxjs';

import {asyncFinishAction} from '../../../../store/actions/ui/async';
import {
  manualCancelApiCallFragment,
  getTokenFragment,
  checkForkErrors
} from '../../../../store/actions/ui/uxProfile/utils';

import {
  GET_USER_TEAMS,
  GET_SCHOOL_STYLES,
  GET_SCHOOL_TEAMS_LIST,
  UPDATE_SCHOOL_STYLES,
  SAVE_COACH_PROFILE,
  GET_COACH_AND_ATHLETE_TEAMS
} from './coachWorld.actions';
import {
  getUsersTeams,
  getSchoolStyle,
  apiGetSchoolTeamsList,
  saveCoachProfile,
  updateSchoolStyle
} from './coachWorld.api';
import {UserTeam, SchoolStyle} from './coachWorld.models';
import {SchoolTeam} from '../roster/roster.models';
import {mapUserApiToUi} from '../../../../store/actions/data/user/profile/models';

const getUsersTeamsEpic = action$ => (
  action$.pipe(
    ofType(GET_USER_TEAMS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getUsersTeams(action.uuid, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getUsersTeams',
        {},
        false,
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getUsersTeams', {
          myTeams: result.response.map(r => UserTeam.fromApi(r)),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getAthleteAndCoachTeamsEpic = action$ => (
  action$.pipe(
    ofType(GET_COACH_AND_ATHLETE_TEAMS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(
        action.coachUuid ? getUsersTeams(action.coachUuid, token).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getCombine',
        )) : of({success: false, action}),
        action.athleteUuid ? getUsersTeams(action.athleteUuid, token).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getCombineResults',
        )) : of({success: false, action}),
      ).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        const coachResponse = result.results[0].response;
        const athleteResponse = result.results[1].response;
        let coachTeamIds = [];
        let athleteTeamIds = [];
        if (coachResponse && coachResponse.length > 0) {
          coachTeamIds = coachResponse.map(c => c.user_type === 'coach' && c.school_team_id);
        }
        if (athleteResponse && athleteResponse.length > 0 && coachResponse) {
          athleteTeamIds = coachResponse.map(c => c.school_team_id);
        }
        const res = coachTeamIds.some(cti => athleteTeamIds.includes(cti));
        return of(asyncFinishAction(result.action.type, 'getCombine', {result: res}));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getSchoolTeamsListEpic = action$ => (
  action$.pipe(
    ofType(GET_SCHOOL_TEAMS_LIST),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetSchoolTeamsList(action.schoolId, 0, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getUsersTeams',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getUsersTeams', {
          schoolId: result.action.schoolId,
          schoolTeams: result.response.map(r => SchoolTeam.fromApi(r)),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getSchoolStylesEpic = action$ => (
  action$.pipe(
    ofType(GET_SCHOOL_STYLES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      forkJoin(...action.schoolIds.map(schoolId => (
        getSchoolStyle(schoolId, token).pipe(manualCancelApiCallFragment(
          action$,
          action,
          'getSchoolStyles',
        ))
      ))).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getSchoolStyles', {
          schoolStyle: result.results.map(r => SchoolStyle.fromApi(r.response)),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const updateSchoolStyleEpic = action$ => (
  action$.pipe(
    ofType(UPDATE_SCHOOL_STYLES),
    getTokenFragment(),
    switchMap(({action, token}) => (
      updateSchoolStyle(action.schoolID, token, action.newSchoolStyle)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'updateSchoolStyles',
        ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'updateSchoolStyles', {
          schoolStyle: result.response,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const saveCoachProfileEpic = action$ => (
  action$.pipe(
    ofType(SAVE_COACH_PROFILE),
    getTokenFragment(),
    switchMap(({action, token}) => (
      saveCoachProfile(action.uuid, action.data, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'saveCoachProfile',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action, 'saveCoachProfile', {
          coach: mapUserApiToUi(result.response),
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(
  getUsersTeamsEpic,
  getSchoolStylesEpic,
  getSchoolTeamsListEpic,
  updateSchoolStyleEpic,
  saveCoachProfileEpic,
  getAthleteAndCoachTeamsEpic,
);

