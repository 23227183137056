import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';
import moment from 'moment';

import Urls, {sportsTables} from '../../../../config/urls';
import {feetAndInchesMasterFormater, masterFormater} from '../../../../modules/CoachWorld/+store/combine';
import {convertInchesToMetre} from '../../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation';
import {customTOFixed} from '../user/profile/models';

export const testsGetStdUnits = token => ajax.getJSON(
  Urls.sports(sportsTables.standardUnitOfMeasures),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetStdTestCategories = token => ajax.getJSON(
  Urls.sports(sportsTables.standardTestCategories),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const playerGetTestCategories = (uuid, token) => ajax.getJSON(
  Urls.playerTestCategories(uuid),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const apiGetPlayerPersonalRecord = (token, uuid, sport) => ajax.getJSON(
  Urls.playerPersonalRecords(uuid, sport),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
)

export const testsGetStdTestBanks = token => ajax.getJSON(
  Urls.sports(sportsTables.standardTestBanks),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetStdTestSubbanks = token => ajax.getJSON(
  Urls.sports(sportsTables.standardTestSubbanks),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetStdTestObjects = token => ajax.getJSON(
  Urls.sports(sportsTables.standardTestObjects),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetUserTests = (uuid, token) => ajax.getJSON(
  `${Urls.sports(sportsTables.tests)}?${queryString.stringify({athleteUuid: uuid})}`,
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetUserTestResults = (uuid, token) => ajax.getJSON(
  `${Urls.sports(sportsTables.testResults)}?${queryString.stringify({athleteUuid: uuid})}`,
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);
export const testsGetPlayerData = (uuid, sportId, token, categoryId = null) => ajax.getJSON(
  Urls.playerData(uuid, sportId, categoryId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);


export const testsGetPerCategory = (uuid, categoryId, token) => ajax.getJSON(
  Urls.testsPerCategory(uuid, categoryId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const sortByCategoryOrderAsc = ( a, b ) => {
  if ( a.standard_test_category_order_num < b.standard_test_category_order_num ){
    return -1;
  }
  if ( a.standard_test_category_order_num > b.standard_test_category_order_num ){
    return 1;
  }
  return 0;
}


export const testsTestCreate = (test, token) =>
  ajax.post(
    Urls.sports(sportsTables.tests),
    test,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const testsTestUpdate = (test, id, token) =>
  ajax.put(
    Urls.sports(sportsTables.tests, id),
    test,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
export const testsTestDelete = (id, token) =>
  ajax.delete(
    Urls.sports(sportsTables.tests, id),
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const testsTestResultsCreate = (testResult, token) =>
  ajax.post(
    Urls.sports(sportsTables.testResults),
    testResult,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const testsTestResultsUpdate = (testResult, id, token) =>
  ajax.put(
    Urls.sports(sportsTables.testResults, id),
    testResult,
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const testsTestResultsDelete = (id, token) =>
  ajax.delete(
    Urls.sports(sportsTables.testResults, id),
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

class StandardUnitOfMeasure {
  constructor() {
    this.id = 0;
    this.code = '';
    this.desc = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.desc = response.description;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}

export const mapStandardUnitApiToUi = response => (
  response._embedded.map((rawUnit) => {
    const unit = new StandardUnitOfMeasure();
    unit.fromApiResponse(rawUnit);
    return unit;
  })
);

class StandardTestBank {
  constructor() {
    this.id = 0;
    this.code = '';
    this.desc = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.subbanks = [];
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.desc = response.description;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  addSubBank(subbank) {
    this.subbanks.push(subbank);
  }
}

export const mapStandardTestBankApiToUi = response => (
  response._embedded.map((raw) => {
    const bank = new StandardTestBank();
    bank.fromApiResponse(raw);
    return bank;
  })
);

class StandardTestSubbank {
  constructor() {
    this.id = 0;
    this.code = '';
    this.desc = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.bank = null;
    this.objects = [];
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.desc = response.description;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  setBank(bank) {
    this.bank = bank;
  }

  addObject(object) {
    this.objects.push(object);
  }
}

export const mapStandardTestSubbankApiToUi = (response, banks) => (
  response.map((raw) => {
    const subbank = new StandardTestSubbank();
    subbank.fromApiResponse(raw);
    const testbank = banks.find(b => b.id === raw.standard_test_bank_id);
    subbank.setBank(testbank);
    testbank.addSubBank(subbank);
    return subbank;
  })
);

class StandardTestCategory {
  constructor() {
    this.id = 0;
    this.unit = null;
    this.standardTestFormat = null;
    this.code = '';
    this.desc = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.best = '';
    this.sportIds = [];
    this.isLaser = false;
    this.isDashr = false;
    this.templateName = '';
    this.standardTestRange = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.desc = response.description;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
    this.best = response.best;
    this.isLaser = response.use_laser || false;
    this.isDashr = response.is_dashr;
    this.standardTestFormat = response._embedded.standardStatFormat;
    this.standardTestRange = response._embedded.standardTestRange.length ? response._embedded.standardTestRange[0] : null;
    //   this.sportIds = response._embedded.sportStandardTestCategories
    this.sportIds = response._embedded.playerDataSportStandardTestCategories
      .map(cat => cat.sport_id);
  }

  setUnit(unit) {
    this.unit = unit;
  }
}

export const mapStandardTestCategoryApiToUi = (response, units) => {
  return response._embedded.map((raw) => {
    const cat = new StandardTestCategory();
    cat.fromApiResponse(raw);
    cat.setUnit(units.find(unit => unit.id === raw.standard_unit_of_measure_id));
    return cat;
  })
};

class StandardTestObject {
  constructor() {
    this.id = 0;
    this.subbank = null;
    this.cat = null;
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  setSubbank(subbank) {
    this.subbank = subbank;
  }

  setCategory(cat) {
    this.cat = cat;
  }
}

export const mapStandardTestObjectApiToUi = (response, subbanks, cats) => (
  response._embedded.map((raw) => {
    const obj = new StandardTestObject();
    obj.fromApiResponse(raw);
    const subbank = subbanks.find(sb => sb.id === raw.standard_test_subbank_id);
    obj.setSubbank(subbank);
    subbank.addObject(obj);
    obj.setCategory(cats.find(cat => cat.id === raw.standard_test_category_id));
    return obj;
  })
);

export class Test {
  constructor() {
    this.id = 0;
    this.athleteUuid = '';
    this.verifiedById = '';
    this.comment = '';
    this.name = '';
    this.resultDate = null;
    this.createdAt = '';
    this.updatedAt = '';
    this.results = [];
    this.weight = 0;
    this.height = 0;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.athleteUuid = response.athlete_uuid;
    this.verifiedById = response.verified_by_id;
    this.comment = response.comment;
    this.name = response.name;
    this.resultDate = moment(response.test_result_date);
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }

  addResult(result) {
    this.results.push(result);
  }
}

export const mapTestApiToUi = response => (
  response._embedded.map((raw) => {
    const test = new Test();
    test.fromApiResponse(raw);
    return test;
  })
);

class TestResult {
  constructor() {
    this.id = 0;
    this.object = null;
    this.test = null;
    this.comment = '';
    this.result = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.videoId = '';
    this.processed = 0;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.comment = response.comment;
    this.result = response.result;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
    this.videoId = response.video_id;
    this.processed = response.video_processed;
  }

  setObject(object) {
    this.object = object;
  }

  setTest(test) {
    this.test = test;
  }
}

export const mapTestResultApiToUi = (response, tests, objects) => (
  response.map((raw) => {
    const result = new TestResult();
    result.fromApiResponse(raw);
    const test = tests.find(t => t.id === raw.test_id);
    result.setTest(test);
    const obj = objects.find(o => o.id === raw.standard_test_object_id);
    result.setObject(obj);
    return result;
  })
);

class VCRating {
  constructor() {
    this.sportId = '';
    this.sportName = '';
    this.vcRating = 0;
    this.vcScore = {
      performanceRating: 0,
      strengthRating: 0,
    };
    this.strengthSignature = {
      upperBodyStrengthRating: 0,
      legHipStrengthRating: 0,
      explosiveStrengthRating: 0,
    };
    this.performanceSignature = {
      speedRating: 0,
      agilityRating: 0,
      explosionRating: 0,
    };
  }

  fromApiResponse(response) {
    this.sportId = response.sport_id;
    this.sportName = response.sport_name;
    this.vcRating = response.vc_rating;
    this.vcScore.strengthRating = response.vc_score.strength_rating;
    this.vcScore.performanceRating = response.vc_score.performance_rating;
    this.strengthSignature.upperBodyStrengthRating =
      response.strength_signature.upper_body_strength_rating;
    this.strengthSignature.legHipStrengthRating =
      response.strength_signature.leg_hip_strength_rating;
    this.strengthSignature.explosiveStrengthRating =
      response.strength_signature.explosive_strength_rating;
    this.performanceSignature.speedRating =
      response.performance_signature.speed_rating;
    this.performanceSignature.agilityRating =
      response.performance_signature.agility_rating;
    this.performanceSignature.explosionRating =
      response.performance_signature.explosion_rating;
  }
}

export class PlayerDataTestResult {
  constructor() {
    this.stdTestObject = null;
    this.stdTestObjectId = 0;
    this.test = null;
    this.testId = 0;
    this.testResult = null;
    this.testResultId = 0;
    this.stdTestCategory = null;
    this.stdTestCategoryId = 0;
    this.stdTestCategoryName = '';
    this.stdTestCategoryCode = '';
    this.stdTestCategoryBest = '';
    this.stdTestCategoryOrderNum = '';
    this.resultDate = null; // to be removed
    this.testResultDate = null;
    this.testName = '';
    this.testIsEditable = 1;
    this.testResultResult = 0;
    this.testResultResultNormalized = 0;
    this.testResultLaserBool = 0;
    this.testResultReps = null;
    this.standardStatFormatCode = ''
    this.stdUnitOfMeasureCode = '';
    this.stdUnitOfMeasure = null;
    this.stdTestBanksCode = '';
    this.stdTestSubbanksCode = '';
    this.testResultRatedResult = 0;
    this.testResultRelativeResult = 0;
    this.testIsReps = 0;
    this.displayLabel = '';
    this.displayValueFloat = '';
    this.displayValueWithLabel = '';
    this.testIsScorable = 0;
    this.videoId = null;
    this.processed = 0;
    this.schoolName = "";
    this.isDashr = "";
    this.location = '';
  }

  fromApiResponse(response) {
    const multiRepsMax = response.test_result_multi_rep_max;
    const testResult = response.test_result_result;
    const testReps = response.test_result_reps;
    const standardUnitOfMeasuresCode= response.standard_unit_of_measures_code;
    this.testIsScorable = response.isScorable_test;
    this.stdTestObjectId = response.standard_test_object_id;
    this.testId = response.test_id;
    this.testResultId = response.test_result_id;
    this.isDashr = response.is_dashr;
    this.location = response.location;
    this.stdTestCategoryId = response.standard_test_category_id;
    this.stdTestCategoryName = response.standard_test_category_name;
    this.stdTestCategoryCode = response.standard_test_category_code;
    this.stdTestCategoryBest = response.standard_test_category_best;
    this.stdTestCategoryOrderNum = response.standard_test_category_order_num;
    this.resultDate = moment(response.test_result_date);
    this.testResultDate = moment(response.test_result_date);
    this.testName = response.test_name;
    this.testIsEditable = response.test_is_editable;
    this.testResultResult = response.test_result_result;
    this.testResultResultNormalized = response.test_result_result_normalized;
    this.testResultLaserBool = response.test_result_laser_bool;
    this.testResultReps = response.test_result_reps;
    this.standardStatFormatCode = response.standard_stat_format_code;
    this.stdUnitOfMeasureCode = response.standard_unit_of_measures_code;
    this.stdTestBanksCode = response.standard_test_banks_code;
    this.stdTestSubbanksCode = response.standard_test_subbanks_code;
    this.testResultRatedResult = response.test_result_rated_result;
    this.testResultRelativeResult = response.test_result_relative_strength;
    this.testVerifiedAt = response.test_verified_at;
    this.testVerifiedByUuid = response.test_verified_by_uuid;
    this.testIsReps = response.test_is_reps;
    this.testResultMultiRepMax = multiRepsMax;
    this.videoId = response.video_id;
    this.processed = response.video_processed;
    this.schoolName = response.school_name || '';
    this.schoolId = response.school_uuid || '';
    this.hasCombine = response.has_combine;
    this.displayLabel = response.display_label;
    this.displayValueFloat = response.display_value_float;

    if (standardUnitOfMeasuresCode === "FEET-INCHES") {
      this.displayValueWithLabel = feetAndInchesMasterFormater(this.standardStatFormatCode)(testResult);
    } else {
      this.displayValueWithLabel = masterFormater(this.standardStatFormatCode)(multiRepsMax);
    }
    this.schoolName = response.hasOwnProperty('school_name') ? response.school_name : "";
  }


  unitFormatInProfile = {
    COUNT: c => `${c}`,
    POUNDS: lbs => `${this.limitDecimals(lbs)} lbs.`,
    SECONDS: s => `${this.limitDecimals(s)}s`,
    INCHES: i => `${Math.floor(i / 12)}-${this.limitDecimals(i % 12)}`,
    FEET: i => `${Math.floor(i / 12)}-${this.limitDecimals(i % 12)}`,
    METERS: m => `${m} m`,
    'SPEED-MPH': m => `${m} mph`,
    'SPEED-RPM': m => `${m} rpm`,
    PERCENTAGE: p => `${p}%`,
    MINUTES: m => `${Math.floor(m / 60)}:${this.limitDecimals(m % 60)}`,
    'INTEGER-INCHES': i => `${i}"`,
    'QUARTER-INCHES': i => `${i}"`,
  };


  limitDecimals = (val) => {
    if (String(val).includes('.')) {
      return Number(val).toFixed(2);
    }
    return val;
  };

  setStdTestObject(obj) {
    this.stdTestObject = obj;
  }

  setStdTestCategory(cat) {
    this.stdTestCategory = cat;
  }

  setStdUnitOfMeasure(unit) {
    this.stdUnitOfMeasure = unit;
  }

  setTest(test) {
    this.test = test;
  }
}

export class PlayerData {
  constructor() {
    this.athleteUuid = '';
    this.performanceClass = '';
    // Deprecated - vcRating and vcScore to be removed
    // this.vcRating = '';
    // this.vcScore = {
    //   performanceRating: 0,
    //   strengthRating: 0,
    // };
    this.mostRecentWeight = 0;
    this.mostRecentHeight = 0;
    this.mostRecentHeightMt = 0;
    // Deprecated - strengthSignature to be removed
    // this.strengthSignature = {
    //   upperBodyStrengthRating: 0,
    //   legHipStrengthRating: 0,
    //   explosiveStrengthRating: 0,
    // };
    // Deprecated - performanceSignature to be removed
    // this.performanceSignature = {
    //   speedRating: 0,
    //   agilityRating: 0,
    //   explosionRating: 0,
    // };
    this.personalRecords = [];
    this.testResults = [];
    this.accelerationPersonalRecords = [];
    this.agilityPersonalRecords = [];
    this.explosivePerfPersonalRecords = [];
    this.explosiveStrPersonalRecords = [];
    this.upperBodyPersonalRecords = [];
    this.legHipPersonalRecords = [];
    // per sport vc ratings
    this.vcRatings = [];
    this.stdTestObjects = [];
    this.stdUnits = [];
    this.rawPlayerTestCategories = [];
  }

  clone() {
    const playerData = new PlayerData();
    playerData.athleteUuid = this.athleteUuid;
    playerData.performanceClass = this.performanceClass;
    playerData.mostRecentWeight = this.mostRecentWeight;
    playerData.mostRecentHeight = this.mostRecentHeight;
    playerData.mostRecentHeightMt = this.mostRecentHeightMt;
    playerData.personalRecords = this.personalRecords;
    playerData.testResults = this.testResults;
    playerData.accelerationPersonalRecords = this.accelerationPersonalRecords;
    playerData.agilityPersonalRecords = this.agilityPersonalRecords;
    playerData.explosivePerfPersonalRecords = this.explosivePerfPersonalRecords;
    playerData.explosiveStrPersonalRecords = this.explosiveStrPersonalRecords;
    playerData.upperBodyPersonalRecords = this.upperBodyPersonalRecords;
    playerData.legHipPersonalRecords = this.legHipPersonalRecords;
    playerData.vcRatings = this.vcRatings;
    playerData.stdTestObjects = this.stdTestObjects;
    playerData.stdUnits = this.stdUnits;
    playerData.rawPlayerTestCategories = this.rawPlayerTestCategories;
    return playerData;
  }

  addNewTestResults(testResults, categoryId) {
    // as new test loaded of given category, old loaded test of same category need to be removed
    const prevTestResults = this.testResults
      .filter(testResult => testResult.stdTestCategory.id !== categoryId);
    this.testResults = testResults.map((rawTestResult) => {
      const result = new PlayerDataTestResult();
      result.fromApiResponse(rawTestResult);
      const obj = this.stdTestObjects.find(o => o.id === result.stdTestObjectId);
      result.setStdTestObject(obj);
      result.setStdTestCategory(obj.cat);
      const unit = this.stdUnits.find(u => u.code === result.stdUnitOfMeasureCode);
      result.setStdUnitOfMeasure(unit);
      return result;
    });

    const tests = {};
    this.testResults.forEach((result) => {
      let test = tests[result.testId];
      if (!test) {
        test = new Test();
        const weightResults = this.testResults
          .find(r => ((r.testId === result.testId)
            && (r.stdTestCategoryCode === 'WEIGHT' || r.stdTestCategoryCode === 'METRICWEIGHT')));
        const heightResults = this.testResults
          .find(r => ((r.testId === result.testId)
            && (r.stdTestCategoryCode === 'HEIGHT' || r.stdTestCategoryCode === 'METRICHEIGHT')));
        test.id = result.testId;
        if (weightResults) {
          test.weightResults = weightResults;
          test.weight = weightResults.testResultResult;
        }
        if (heightResults) {
          test.heightResults = heightResults;
          test.height = heightResults.testResultResult;
          const height = Number(test.height); // stored in inches
          const heightFt = Number.isNaN(height) ? null : Math.floor(height / 12);
          const heightInch = Number.isNaN(height) ? null : Math.round(height - (heightFt * 12));
          test.heightFt = heightFt ? heightFt.toString() : '';
          test.heightInch = heightInch ? heightInch.toString() : '';
          let heightMt = Number.isNaN(height) ? null : Math.floor(height);
          let heightCm = Number.isNaN(height) ? null :
            customTOFixed(Math.abs((height * 100) - (heightMt * 100)));
          if (heightCm >= 100) {
            heightMt += 1;
            heightCm -= 100;
          }
          test.heightMt = heightMt;
          test.heightCm = heightCm;
        }
        tests[result.testId] = test;
      }
      result.setTest(test);
    });

    this.testResults = [...this.testResults, ...prevTestResults];
  }

  fromApiResponse(response, stdTestObjects, stdUnits) {
    this.athleteUuid = response.player_data.athlete_uuid;
    this.performanceClass = response.player_data.athlete_performance_class;
    this.stdTestObjects = stdTestObjects;
    this.stdUnits = stdUnits;
    // this.vcRating = response.player_data.vc_rating;
    // this.vcScore.strengthRating = response.player_data.vc_score.strength_rating;
    // this.vcScore.performanceRating = response.player_data.vc_score.performance_rating;
    this.mostRecentWeight = response.player_data.most_recent_weight;
    this.mostRecentHeight = response.player_data.most_recent_height;
    this.mostRecentHeightMt = convertInchesToMetre(response.player_data.most_recent_height);
    // this.strengthSignature.upperBodyStrengthRating =
    //   response.player_data.strength_signature.upper_body_strength_rating;
    // this.strengthSignature.legHipStrengthRating =
    //   response.player_data.strength_signature.leg_hip_strength_rating;
    // this.strengthSignature.explosiveStrengthRating =
    //   response.player_data.strength_signature.explosive_strength_rating;
    // this.performanceSignature.speedRating =
    //   response.player_data.performance_signature.speed_rating;
    // this.performanceSignature.agilityRating =
    //   response.player_data.performance_signature.agility_rating;
    // this.performanceSignature.explosionRating =
    //   response.player_data.performance_signature.explosion_rating;
    response.player_data.vc_ratings.forEach((rawRating) => {
      const sportId = rawRating.sport_id;
      const vcr = new VCRating();
      vcr.fromApiResponse(rawRating);
      this.vcRatings[sportId] = vcr;
    });

    this.personalRecords = response.player_data.personal_records.map((rawPr) => {
      const pr = new PlayerDataTestResult();
      pr.fromApiResponse(rawPr);
      const obj = stdTestObjects.find(o => o.id === pr.stdTestObjectId);
      pr.setStdTestObject(obj);
      pr.setStdTestCategory(obj.cat);
      const unit = stdUnits.find(u => u.code === pr.stdUnitOfMeasureCode);
      pr.setStdUnitOfMeasure(unit);
      return pr;
    });
    this.testResults = response.player_data.test_results.map((rawTestResult) => {
      const result = new PlayerDataTestResult();
      result.fromApiResponse(rawTestResult);
      const obj = stdTestObjects.find(o => o.id === result.stdTestObjectId);
      result.setStdTestObject(obj);
      result.setStdTestCategory(obj.cat);
      const unit = stdUnits.find(u => u.code === result.stdUnitOfMeasureCode);
      result.setStdUnitOfMeasure(unit);
      return result;
    });
    this.accelerationPersonalRecords = this.personalRecords.filter(r => (r.stdTestSubbanksCode === 'ACCELERATION')).sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    this.agilityPersonalRecords = this.personalRecords.filter(r => r.stdTestSubbanksCode === 'AGILITY').sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    this.explosivePerfPersonalRecords = this.personalRecords.filter(r => r.stdTestSubbanksCode === 'EXPLOSIVEPERF').sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    this.explosiveStrPersonalRecords = this.personalRecords.filter(r => r.stdTestSubbanksCode === 'EXPLOSIVESTR').sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    this.upperBodyPersonalRecords = this.personalRecords.filter(r => r.stdTestSubbanksCode === 'UPPERBODY').sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    this.legHipPersonalRecords = this.personalRecords.filter(r => r.stdTestSubbanksCode === 'LEGHIP').sort((a, b) => a.stdTestCategoryOrderNum - b.stdTestCategoryOrderNum);
    const tests = {};
    this.testResults.forEach((result) => {
      let test = tests[result.testId];
      if (!test) {
        test = new Test();
        const weightResults = this.testResults
          .find(r => ((r.testId === result.testId)
            && (r.stdTestCategoryCode === 'WEIGHT' || r.stdTestCategoryCode === 'METRICWEIGHT')));
        const heightResults = this.testResults
          .find(r => ((r.testId === result.testId)
            && (r.stdTestCategoryCode === 'HEIGHT' || r.stdTestCategoryCode === 'METRICHEIGHT')));
        test.id = result.testId;
        if (weightResults) {
          test.weightResults = weightResults;
          test.weight = weightResults.testResultResult;
        }
        if (heightResults) {
          test.heightResults = heightResults;
          test.height = heightResults.testResultResult;
          const height = Number(test.height); // stored in inches
          const heightFt = Number.isNaN(height) ? null : Math.floor(height / 12);
          const heightInch = Number.isNaN(height) ? null : Math.round(height - (heightFt * 12));
          test.heightFt = heightFt ? heightFt.toString() : '';
          test.heightInch = heightInch ? heightInch.toString() : '';
          let heightMt = Number.isNaN(height) ? null : Math.floor(height);
          let heightCm = Number.isNaN(height) ? null :
            customTOFixed(Math.abs((height * 100) - (heightMt * 100)));
          if (heightCm >= 100) {
            heightMt += 1;
            heightCm -= 100;
          }
          test.heightMt = heightMt;
          test.heightCm = heightCm;
        }
        tests[result.testId] = test;
      }
      result.setTest(test);
    });
  }
}

export const mapPlayerDataApiToUi = (
  response,
  stdTestObjects,
  stdUnits,
  rawPlayerTestCategories,
) => {
  const playerData = new PlayerData();
  playerData.fromApiResponse(response, stdTestObjects, stdUnits);
  playerData.rawPlayerTestCategories = rawPlayerTestCategories;
  return playerData;
};

export const mapTestUiToApi = (form, uuid) => ({
  name: '',
  comment: '',
  result_date: moment(form.date).format('YYYY-MM-DD'),
  athlete_uuid: uuid,
  school_uuid: form.school.trim()
});

export const mapTestResultUiToApi = (stdTestObjectId, testId, comment, result, reps, laserTime, location) => ({
  standard_test_object_id: stdTestObjectId,
  test_id: testId,
  comment,
  laser_bool: laserTime,
  result,
  reps,
  location
});

export const TESTS_GET_STD_SUC = 'sport.tests.getStdSuccess';
export const testsGetStandardSuccess = std => ({
  type: TESTS_GET_STD_SUC,
  std,
});

export const TESTS_GET_PLAYER_DATA_SUC = 'sport.tests.getPlayerDataSuccess';
export const testsGetPlayerDataSuccess = (uuid, playerData, categoryId) => ({
  type: TESTS_GET_PLAYER_DATA_SUC,
  uuid,
  playerData,
  categoryId,
});

export const PLAYER_DATA_CATEGORY_TEST_RESULT_SUC = 'sport.tests.getPlayerDataCategoryTestResultSuccess';
export const getPlayerDataCategoryTestResultSuccess = (uuid, categoryId, testResults) => ({
  type: PLAYER_DATA_CATEGORY_TEST_RESULT_SUC,
  uuid,
  categoryId,
  testResults,
});
