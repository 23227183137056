import React, {PureComponent, Fragment} from 'react';
import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import moment from 'moment';
import LoadingIndicator from '../../../common/LoadingIndicator';
import Calendar from '../../../common/Calendar';
import {CloseButton, ButtonRect, LinkButton} from '../../../../../components/UxCommon';
import TextControl from '../../../common/FormControls/TextControl';
import SelectControl from '../../../common/FormControls/SelectControl';
import ArchiveCombineDialog from '../ArchiveCombineDialog/ArchiveCombineDialog.component';
import { Routes, Sports } from '../../../../../store/actions/ui/routes';

const dateFormat = 'MM/DD/YYYY';
const invalidDateMessage = `Please enter a valid date in ${dateFormat} format  (${moment().format(dateFormat)})`;
const endDateValidationMessage = 'Date should be equal or greater than start date.';
const openDateValidationMessage = 'Date should be equal or smaller than end date.';
const requiredValidationMessage = 'This field is required.';

class CreateEditCombine extends PureComponent {
  static propTypes = {
    combineId: PropTypes.any,
    combine: PropTypes.object,
    onClickClose: PropTypes.func,
    loadingMsg: PropTypes.string,
    loading: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isArchiveDialogVisible: PropTypes.bool,
    showArchiveDialog: PropTypes.func,
    hideArchiveDialog: PropTypes.func,
    getCombine: PropTypes.func,
    templates: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    createCombine: PropTypes.func,
    updateCombine: PropTypes.func,
    schoolOptions: PropTypes.array,
    deleteCombine: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    combineTemplateId: PropTypes.number,
    teams: PropTypes.array,
    combineTemplates: PropTypes.array,
  };

  state = {
    errors: {},
    showStartDateCalendar: false,
    startDate: moment().format(dateFormat),
    endDate: moment(moment().format(dateFormat)).clone().add(9, 'days').format(dateFormat),
    showEndDateCalendar: false,
    selectedTeams: '',
    selectedTemplate: null,
    startDateValidated: false,
    endDateValidated: false,
    selectedSchool: null,
    combineName:'',
    defaultData:{},
    fetchCombine: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.shouldUpdate();

    const { combineId,currentTemplate,combineTemplateId, combine } = this.props;   
     if (!combineId) {
       const sport = Sports[currentTemplate.sportId];
       this.setState({
        combineName: `${sport.toUpperCase()} COMBINE ${this.state.startDate}`, 
        defaultData : {
         name: ``,
         combineTemplateId: combineTemplateId || '',
         schoolId : '',
         isOpen : true,
       }});
     }
     else if(combine && combine.id === combineId) {
       this.setState({
         defaultData : {...combine},
         startDate : combine.openDate.format(dateFormat),
         endDate: combine.endDate.format(dateFormat)
       })
     }

  }

  componentDidUpdate() {
    this.shouldUpdate();

    const { combine, combineId } = this.props;
    if( combine && !this.state.fetchCombine){
      if (combine.id === combineId) {
        this.setState({
          defaultData: {...combine}, fetchCombine : true,
          startDate : combine.openDate.format(dateFormat),
          endDate: combine.endDate.format(dateFormat)
        }) ;
    }
  }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onValueChange = name => (value) => {
    const {history, match} = this.props;
    const errors = {};
    let adjustedValue;
    let dateWithFormat;
    switch (name) {
      case 'template':
        if (!value) {
          errors.template = [requiredValidationMessage];
        } else {
          if (value === 'createNew') {
            history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/new`);
          }
          errors.template = [];
        }
        adjustedValue = value;
        this.updateSelectedTemplate(value);
        break;
      case 'teams':
        if (!value.length) {
          this.setState({selectedTeams: ''});
          errors.teams = [requiredValidationMessage];
        } else {
          this.setState({selectedTeams: value});
          errors.teams = [];
        }
        adjustedValue = value;
        break;
      case 'name':
        if (!value) {
          errors.name = [requiredValidationMessage];
        } else {
          errors.name = [];
        }
        this.setState({combineName: value});
        adjustedValue = value;
        break;
      case 'openDate':
        dateWithFormat = value.replace(/[^0-9/]/g, '').replace(/(\..*)\./g, '$1');
        errors.openDate = [];
        this.setState({startDate: dateWithFormat});
        let isDatePresent = this.state.combineName.match(/\d{2}([\/.-])\d{2}\1\d{4}/g);
        if(isDatePresent){
          this.setState({
            combineName: this.state.combineName.replace(isDatePresent[0], dateWithFormat)
          })
        }
        adjustedValue = dateWithFormat;
        break;
      case 'endDate':
        errors.endDate = [];
        dateWithFormat = value.replace(/[^0-9/]/g, '').replace(/(\..*)\./g, '$1');
        this.setState({endDate: dateWithFormat});
        adjustedValue = dateWithFormat;
        break;
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
    });
    return adjustedValue;
  }


  onStartDateChanged = (day) => {
    const dateString = dateFns.format(day, dateFormat);
    const endDateAfterChangeStartDate = moment(dateString).clone().add(9, 'days').format(dateFormat);
    this.setState({
      showStartDateCalendar: false,
      startDate: dateString,
      endDate: endDateAfterChangeStartDate
    }, () => {
      this.onValueChange('openDate')(dateString);
    });
  }

  onEndDateChanged = (day) => {
    const dateString = dateFns.format(day, dateFormat);
    this.setState({
      showEndDateCalendar: false,
      endDate: dateString,
    }, () => {
      this.onValueChange('endDate')(dateString);
    });
  }
  onBlurHandle = name => (value) => {
    switch (name) {
      case 'openDate':
        this.setState({
          endDateValidated: false,
        }, () => {
          this.checkOpenDateValidation(value);
        });
        break;
      case 'endDate':
        this.setState({
          startDateValidated: false,
        }, () => {
          this.checkEndDateValidation(value);
        });
        break;
      default:
        return value;
    }
  }


  getTeamDataSource = (teams) => {
    let res = {};
    teams.forEach((userTeam) => {
      userTeam.schoolTeams.forEach((schoolTeam) => {
        res = {
          ...res,
          [schoolTeam.id]: schoolTeam.name,
        };
      });
    });
    return res;
  }

  setStartDateCalendarRef = (node) => {
    this.startDateCalendarRef = node;
  }

  setEndDateCalendarRef = (node) => {
    this.endDateCalendarRef = node;
  }

  checkOpenDateValidation = (value) => {
    const errors = {};
    if (value && !this.isDateValid(value)) {
      errors.openDate = [invalidDateMessage];
    } else if (moment(value) > moment(this.state.endDate)) {
      errors.openDate = [openDateValidationMessage];
    } else {
      errors.openDate = [];
    }

    if (!this.state.endDateValidated && this.state.endDate) {
      this.checkEndDateValidation(this.state.endDate);
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      startDateValidated: true,
    });
  }


  checkEndDateValidation = (value) => {
    const errors = {};
    if (value && !this.isDateValid(value)) {
      errors.endDate = [invalidDateMessage];
    } else if (moment(value) < moment(this.state.startDate)) {
      errors.endDate = [endDateValidationMessage];
    } else {
      errors.endDate = [];
    }
    if (!this.state.startDateValidated && this.state.startDate) {
      this.checkOpenDateValidation(this.state.startDate);
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
      endDateValidated: true,
    });
  }

  isDateValid = value => moment(value, [dateFormat], true).isValid();

  sortTemplates = ([valueA, labelA], [valueB, labelB]) => {
    const {templates,match} = this.props;
    let currentSchoolId = match.params.schoolId;
    schoolCombineTemplates = templates[currentSchoolId];
    combineTemplates = this.coachSportsTemplates(schoolCombineTemplates)
    if (valueA === '') return -1;
    if (valueB === '') return 1;
    if (!valueA) return -1;
    if (!valueB) return 1;
    if (combineTemplates) {

      const elementA = combineTemplates.find(template => template.id === Number(valueA));
      const elementB = combineTemplates.find(template => template.id === Number(valueB));
      if (elementA.orderNum !== elementB.orderNum) {
        return elementA.orderNum - elementB.orderNum > 0 ? 1 : -1;
      }
      return elementA.createdAt.isBefore(elementB.createdAt) ? 1 : -1;
    }
  }

  updateSelectedTemplate = (id) => {
    const combineTemplates = this.getSelectedSchoolCombineTemplate();
    const selectedTemplate = combineTemplates.find(template =>
      template.id === parseInt(id, 10));
    this.setState({selectedTemplate});
  }

  getLockedTeams = () => {
    const {combine, isEditMode} = this.props;
    if (combine && combine.lockedTeams.length > 0 && isEditMode) {
      return combine.lockedTeams.map(team => team.id);
    }
    return [];
  }

  getDropdownTeams = () => {
    const {teams, combine, isEditMode, match} = this.props;
    const currentSchoolId = match.params.schoolId;
    if (isEditMode) {
      const selectedTemplate = combine && combine.template;
      if (selectedTemplate) {
        let sportTeams = teams.filter(team => team.sportId === selectedTemplate.sport_id  && !team.schoolTeams[0].isPrivate);
        if (currentSchoolId) {
          sportTeams = sportTeams.filter(sTeam => sTeam.schoolId === currentSchoolId);
        }
        return this.getTeamDataSource(sportTeams);
      }
      return {};

    } else {
      const selectedTemplate = this.findSelectedTemplate(currentSchoolId);
      if (selectedTemplate) {
        let sportTeams = teams.filter(team => team.sportId === selectedTemplate.sportId && !team.schoolTeams[0].isPrivate);
        if (currentSchoolId) {
          sportTeams = sportTeams.filter(sTeam => sTeam.schoolId === currentSchoolId);
        }
        return this.getTeamDataSource(sportTeams);
      }
      return {};
  }
  }

  combineFetched = false;
  shouldUpdate = () => {
    const {combineId, getCombine} = this.props;
    if (!this.combineFetched && combineId) {
      getCombine(combineId);
      this.combineFetched = true;
    }
  }

  isDataValid = (form) => {
    const {combine} = this.props;
    const errors = {};
    let hasErros = false;
    if (!form.template.value) {
      errors.template = [requiredValidationMessage];
      hasErros = true;
    }
    if (!this.state.selectedTeams && !combine) {
      errors.teams = [requiredValidationMessage];
      hasErros = true;
    }
    if (!form.name.value) {
      errors.name = [requiredValidationMessage];
      hasErros = true;
    }
    if (!form.openDate.value) {
      errors.openDate = [requiredValidationMessage];
      hasErros = true;
    } else if (!this.isDateValid(form.openDate.value)) {
      errors.openDate = [invalidDateMessage];
      hasErros = true;
    }
    if (!form.endDate.value) {
      errors.endDate = [requiredValidationMessage];
      hasErros = true;
    } else if (!this.isDateValid(form.endDate.value)) {
      errors.endDate = [invalidDateMessage];
      hasErros = true;
    } else if (this.isDateValid(form.openDate.value) &&
      (moment(form.endDate.value) < moment(form.openDate.value))) {
      errors.endDate = [endDateValidationMessage];
      hasErros = true;
    } else {
      errors.endDate = [];
    }
    if (hasErros) {
      this.setState({
        errors: Object.assign({}, this.state.errors, errors),
      });
      return false;
    }


    return true;
  }

  handleSaveClick = () => {
    const {
      createCombine,
      updateCombine,
      isEditMode,
      combine,
      schoolOptions,
      match,
    } = this.props;
    const form = this.formRef.current;
    if (!this.isDataValid(form)) {
      return;
    }
    const data = {
      combine_template_id: form.template.value,
      teams: this.state.selectedTeams,
      name: form.name.value,
      // open_date: moment().utc().utcOffset(-6).format('YYYY-MM-DD HH:mm:ss'),
      // closed_at: moment(form.closeDate.value, 'MM.DD.YYYY HH:mm:ss').endOf('day').format(),
      open_date: moment(form.openDate.value).format('YYYY-MM-DD'),
      end_date: moment(`${form.endDate.value} ${moment().utc().utcOffset(-6).format('HH:mm:ss')}`).format(),
      school_uuid: match.params.schoolId,
      order_num: 0,
      description: '',
    };
    if (Object.values(this.state.errors).reduce((x, y) => x + y.length, 0) === 0) {
      if (isEditMode) {
        updateCombine(data, combine);
      } else {
        createCombine(data);
      }
    }
  }

  startDateCalendarClicked = () => {
    this.setState({showStartDateCalendar: !this.state.showStartDateCalendar});
  }

  endDateCalendarClicked = () => {
    this.setState({showEndDateCalendar: !this.state.showEndDateCalendar});
  }

  handleDeleteClick = () => {
    const {deleteCombine, combineId} = this.props;
    deleteCombine(combineId);
  }
  handleArchiveClick = () => {
    const {showArchiveDialog} = this.props;
    showArchiveDialog();
  }
  formRef = React.createRef();

  handleClickOutside = (event) => {
    if (this.state.showStartDateCalendar && !this.startDateCalendarRef.contains(event.target)) {
      this.setState({showStartDateCalendar: false});
    } else if (this.state.showEndDateCalendar && !this.endDateCalendarRef.contains(event.target)) {
      this.setState({showEndDateCalendar: false});
    }
  }

  getSportsIds = () => {
    const { combine, isEditMode, match,teams,schools} = this.props;
    currentSchoolId = isEditMode ? (combine ? combine.schoolId : null) : match.params.schoolId;
    const sportIds =  teams.map((team) => ((team.schoolId === currentSchoolId) ? team.sportId : ""))
    return sportIds.filter(sportId => sportId != "")
  }

  coachSportsTemplates = (combineTemplates) => {
    const coachSportsIds = this.getSportsIds();
    const coachSportsCombineTemplates = combineTemplates.map( (template) => ((coachSportsIds.includes(template.sportId))  ? template : {} ))
    let coachSportsCombineTemplatesArray = coachSportsCombineTemplates.filter(value => Object.keys(value).length !== 0);
    return coachSportsCombineTemplatesArray
  }

  dropDownTemplate = (schools) => {
    const {templates, combine, isEditMode, match} = this.props;

    if (isEditMode) {
      schoolCombineTemplates = combine.template;
      let res = {};
      if (schoolCombineTemplates) {
        res = {
          ...res,
          [schoolCombineTemplates.id]: schoolCombineTemplates.name,
        };
        return res;
      }
      return res;

    } else {

      let currentSchoolId = isEditMode ? (combine ? combine.schoolId : null) : match.params.schoolId
      let schoolCombineTemplates = [];
      if (currentSchoolId) {
      combineTemplates = templates[currentSchoolId];
      schoolCombineTemplates = this.coachSportsTemplates(combineTemplates)
      }
      let res = {};
      if (schoolCombineTemplates) {
        schoolCombineTemplates.forEach((template) => {
          res = {
            ...res,
            [template.id]: template.name,
          };
        });
        return res;
      }
      return res;

    }
  }

  getSelectedSchoolCombineTemplate = () => {
    const {selectedSchool} = this.state;
    const {templates, combine, isEditMode, match} = this.props;
    let currentSchoolId = null;
    if (selectedSchool) {
      currentSchoolId = isEditMode ? (combine ? combine.schoolId : null) : selectedSchool;
    } else {
      currentSchoolId = isEditMode ? (combine ? combine.schoolId : null) : match.params.schoolId;
    }
    schoolCombineTemplates = templates[currentSchoolId];
    return combineTemplates = this.coachSportsTemplates(schoolCombineTemplates)
  }

  findSelectedTemplate = (currentSchoolId) => {
    // const {selectedTemplate} = this.state;
    const {combine, combineTemplateId, templates, isEditMode } = this.props;
    schoolCombineTemplates = templates[currentSchoolId];
    combineTemplates = this.coachSportsTemplates(schoolCombineTemplates)
    // if (selectedTemplate) {
    //   return selectedTemplate;
    // } else 
    if (combineTemplates && (combine || combineTemplateId)) {
      return combineTemplates.find(template =>
        template.id === parseInt((isEditMode && combine) ?
        combine.combineTemplateId : combineTemplateId, 10));
    }
    return null;
  }

  render() {
    const {
      combine,
      loading,
      loadingMsg,
      isEditMode,
      onClickClose,
      isArchiveDialogVisible,
      hideArchiveDialog,
      combineId,
      templates,
      schoolOptions,
      combineTemplateId,
      schools,
    } = this.props;
    const {
      errors, showStartDateCalendar, startDate, endDate, showEndDateCalendar,
      combineName, defaultData
    } = this.state;
    let lockedTeams = this.getLockedTeams();
    const dropDownTeams = this.getDropdownTeams( lockedTeams );


    // let defaultData = combine;
    // if (!combineId) {
    //   defaultData = {
    //     name: '',
    //     combineTemplateId: combineTemplateId || '',
    //     schoolId: '',
    //     isOpen: true,
    //   };
    // }
    // let defaultOpenDate = moment().format(dateFormat);
    // // let defaultEndDate = moment().add(90, 'days').format('MM/DD/YY');
    // let defaultEndDate = '';
    // if (combine && combine.id === combineId) {
    //   if (combine.openDate) {
    //     defaultOpenDate = combine.openDate.format(dateFormat);
    //   }
    //   if (combine.endDate) {
    //     defaultEndDate = combine.endDate.format(dateFormat);
    //   }
    // }

    const disabledOptions = (isEditMode && combine && combine.isOpen) ? lockedTeams : null;

    if (isArchiveDialogVisible) {
      return (
        <div className="CoachWorld_EditPlayer_Backdrop">
          <div className="Combine_Edit_Modal">
            <ArchiveCombineDialog
              onArchiveClick={this.handleDeleteClick}
              onCancelClick={hideArchiveDialog}
              msg="Are you sure you want to delete your combine?"
            />
            <LoadingIndicator
              loading={loading}
              loadingMsg={loadingMsg}
              positionStyle={{margin: 'auto'}}
            />
          </div>
        </div>
      );
    }

    if (isEditMode && (!combine || combine.id !== combineId)) {
      return (
        <div className="CoachWorld_EditPlayer_Backdrop">
          <div className="Combine_Edit_Modal">
            <LoadingIndicator
              loading
              loadingMsg="Loading ..."
              positionStyle={{margin: 'auto'}}
            />;
          </div>
        </div>
      );
    }

    return (
      <div className="CoachWorld_EditPlayer_Backdrop">
        <div className="Combine_Edit_Modal">
          <CloseButton onClick={onClickClose} size={10}/>
          <LoadingIndicator
            loading={loading}
            loadingMsg={loadingMsg}
            positionStyle={{margin: 'auto'}}
          />
          <div className="Combine_Edit_Container">
            <div className="CoachWorld_EditPlayer_Header">
              {isEditMode ? 'Edit Combine' : 'Create Combine'}
            </div>
            <form ref={this.formRef} onSubmit={this.handleSaveClick}>
              <SelectControl
                name="template"
                label="Combine Template"
                noPlaceholder
                errors={errors.template}
                options={this.dropDownTemplate(schools)}
                sortFunc={this.sortTemplates}
                myOnChange={this.onValueChange('template')}
                myOnBlur={this.onValueChange('template')}
                defaultValue={defaultData.combineTemplateId}
                disabled={isEditMode}
                style={{ zIndex: 4, display:"none" }}
              />
              <SelectControl
                name="teams"
                label="Teams"
                errors={errors.teams}
                options={this.getDropdownTeams()}
                myOnChange={this.onValueChange('teams')}
                myOnBlur={this.onValueChange('teams')}
                disabled={isEditMode && combine && !combine.isOpen}
                defaultValue={lockedTeams}
                disabledOptions={disabledOptions}
                multi
                style={{zIndex: 3}}
              />
              <TextControl
                defaultValue={defaultData.name}
                label="Combine Name"
                placeholder="Combine Name"
                name="name"
                value={combineName}
                myOnChange={this.onValueChange('name')}
                myOnBlur={this.onValueChange('name')}
                errors={errors.name}
                disabled={!defaultData.isOpen}
              />
              <div className="Combine_Edit_Date_Ctrl_Container">
                <div
                  ref={this.setStartDateCalendarRef}
                  style={{
                    position: 'relative',
                    flexGrow: 1,
                    width: '50%',
                  }}
                >
                  <TextControl
                    // defaultValue={defaultOpenDate}
                    className="CoachWorld_EditPlayer_Ctrl left"
                    name="openDate"
                    label={`Start ( ${dateFormat} )`}
                    placeholder={`Start ( ${dateFormat} )`}
                    myOnChange={this.onValueChange('openDate')}
                    errors={errors.openDate}
                    iconName="calendar"
                    onClick={this.startDateCalendarClicked}
                    value={startDate}
                    autoComplete="off"
                    disabled={!defaultData.isOpen}
                  />
                  {showStartDateCalendar &&
                  <Calendar
                    onDateChanged={this.onStartDateChanged}
                    selectedDate={startDate}
                  />}
                </div>
                <div
                  ref={this.setEndDateCalendarRef}
                  style={{
                    position: 'relative',
                    flexGrow: 1,
                    width: '50%',
                  }}
                >
                  <TextControl
                    // defaultValue={defaultEndDate}
                    className="CoachWorld_EditPlayer_Ctrl"
                    name="endDate"
                    label={`End ( ${dateFormat} )`}
                    placeholder={`End ( ${dateFormat} )`}
                    myOnChange={this.onValueChange('endDate')}
                    onFocus={this.onFocus}
                    errors={errors.endDate}
                    iconName="calendar"
                    onClick={this.endDateCalendarClicked}
                    value={endDate}
                    autoComplete="off"
                    disabled={!defaultData.isOpen}
                  />
                  {showEndDateCalendar &&
                  <Calendar
                    onDateChanged={this.onEndDateChanged}
                    selectedDate={endDate}
                    compareDate={startDate}
                    compareType="isAfter"
                  />}
                </div>
              </div>
              <div className="Combine_Edit_Btns">
                <div className="Combine_Edit_Archive">
                  {combine && combine.isCombineCreator && isEditMode &&
                  <LinkButton type="button" onClick={this.handleArchiveClick} iconName="archive">
                    Delete Combine
                  </LinkButton>
                  }
                </div>
                {defaultData.isOpen &&
                <Fragment>
                  <ButtonRect
                    className="Photos_ExpandedPhotoDialog_Button"
                    style={{width: '120px'}}
                    type="button"
                    onClick={onClickClose}
                  >
                    Cancel
                  </ButtonRect>
                  <ButtonRect
                    className="Photos_ExpandedPhotoDialog_Button primary"
                    style={{width: '120px'}}
                    type="button"
                    onClick={this.handleSaveClick}
                  >
                    Save
                  </ButtonRect>
                </Fragment>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditCombine;
