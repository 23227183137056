import React, { PureComponent } from 'react';
import PropTypes, { element } from 'prop-types';
import { Form, Control } from 'react-redux-form';

import {
  Container,
  Row,
  Col,
  LoadingSpinner,
  CloseLink,
  Line,
} from '../../UxCommon';
import {
  TextControl,
  TextAreaControl,
  CheckboxControl,
  Button,
  Headline,
  GeneralError,
  SelectControl,
} from '../../UxCommon/Form';
import { formOnEnterPress, positions, softballBaseballIdsArray,batsThrows } from '../../UxCommon/Form/utils';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import SportPicker from './SportPicker/SportPicker';
import ProfilePhotoCol from './ProfilePhotoCol/ProfilePhotoCol';
import { Sports, Routes } from '../../../store/actions/ui/routes';
import { parserJerseyNumbers, parserMaxChar, parserAboutMeChange } from '../../../utils/parsers';
import PositionWidget from './PositionWidget/PositionWidget';

class EditSports extends PureComponent {
  static propTypes = {
    selectedUserSport: PropTypes.object,
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    sports: PropTypes.array.isRequired,
    profile: PropTypes.object,
    showDeleteDialog: PropTypes.func.isRequired,
    hideDeleteDialog: PropTypes.func.isRequired,
    deleteUserSport: PropTypes.func.isRequired,
    isDeleteDialogShown: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    onSportPick: PropTypes.func.isRequired,
    userSports: PropTypes.array.isRequired,
    onUpdateProfilePhotoClicked: PropTypes.func.isRequired,
    onRemoveProfilePhotoClicked: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    onPositionSelect: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    const {
      formNeedsInit,
      formInit,
      selectedUserSport,
      match,
      userSports,
      changeFieldValue,
    } = this.props;
    if (formNeedsInit) {
      if (match.params.sport === Routes.addSport) {
        formInit(null);
        if (userSports.some(sport => sport.primary)) {
          changeFieldValue('primary', false);
        }
      } else {
        formInit(selectedUserSport);
      }
    }
  };

  selectedSportTeams = (selectedSport) => {
    const { allUserTeams } = this.props;
    let selectedSportTeams = [];
    allUserTeams.forEach( element => {
      selectedSportTeams.push(element.sportId === selectedSport.id && element.name);
    })
    return selectedSportTeams;
  }

  render() {
    const {
      selectedUserSport,
      submit,
      sports,
      rrfForm,
      showDeleteDialog,
      deleteUserSport,
      hideDeleteDialog,
      isDeleteDialogShown,
      loading,
      loadingMsg,
      onSportPick,
      userSports,
      profile,
      onUpdateProfilePhotoClicked,
      onRemoveProfilePhotoClicked,
      uuid,
      formGeneralError,
      match,
      onPositionSelect,
      allUserTeams,
      isCoach,
    } = this.props;
    let selectedSport = null;
    if (selectedUserSport) {
      selectedSport = sports.find(s => s.id === selectedUserSport.sportId);
    }
    let addingSport = false;
    if ((match.params.sport === Routes.addSport) || (!selectedSport)) {
      addingSport = true;
    }
    let profileGender = '';
    if (profile) {
      profileGender = profile.gender;
    }
    if (addingSport) {
      if (rrfForm.sportId.value) {
        selectedSport = sports.find(s => s.id === Number(rrfForm.sportId.value));
      } else {
        selectedSport = null;
      }
    }

    let isSportDelete = true;
    let selectedSportTeams = [];
    if(allUserTeams && selectedSport) {
      allUserTeams.forEach( (element) =>  {
        if(element.sportId === selectedSport.id) {
          isSportDelete = false;
          selectedSportTeams.push(element.name);
        }
      });
    }

    return (
      <div className="UxEditProfile_EditSports">
        <LoadingSpinner
          loading={loading}
          loadingMsg={loadingMsg}
          style={{
            position: 'relative',
            height: 200,
            zIndex: 'unset',
          }}
        />
        {
          !isDeleteDialogShown && !loading && (userSports.length === 0) && (
            <React.Fragment>
              <div className="UxEditProfile_EditSports_Welcome">
                Welcome to Virtual Combine!
              </div>
              <div className="UxEditProfile_EditSports_WelcomeMsg">
                {`Let's get started!  Please take a second to add the sports you play and fill in your profile info.${''}`}
              </div>
            </React.Fragment>
          )
        }
        <Container>
          {
            isDeleteDialogShown &&
            <DeleteDialog
              show={isDeleteDialogShown}
              userSport={selectedUserSport}
              sport={selectedSport}
              hideDialog={hideDeleteDialog}
              deleteSport={deleteUserSport}
              isSportDelete={isSportDelete}
              selectedSportTeams={selectedSportTeams}
              isCoach={isCoach}
            />
          }
          {
            !isDeleteDialogShown && !loading && (
              <Form
                model="forms.userSport"
                onSubmit={submit}
                onKeyDown={formOnEnterPress('forms.userSport.submit')}
              >
                <Row className="justify-content-center">
                  {
                    !addingSport && (
                      <Col className="col-auto">
                        <ProfilePhotoCol
                          uuid={uuid}
                          selectedUserSport={selectedUserSport}
                          onUpdateClicked={onUpdateProfilePhotoClicked}
                          onRemoveClicked={onRemoveProfilePhotoClicked}
                          match={match}
                          isCoach={isCoach}
                        />
                      </Col>
                    )
                  }
                  <Col className="col-auto UxEditProfile_Acccount_Col">
                    <Headline>
                      {
                        addingSport
                          ? 'Add a Sport'
                          : selectedSport.name
                      }
                    </Headline>
                    {
                      addingSport &&
                      <SportPicker
                        sports={sports.filter(sport =>
                          !userSports.find(userSport =>
                            sport.id === userSport.sportId))}
                        pickedSportId={rrfForm.sportId.value}
                        onSportPick={onSportPick}
                        rrfField={rrfForm.sportId}
                      />
                    }
                    <div>
                      <div className="FormIn">
                        <TextAreaControl
                          label="Bio"
                          placeholder="Bio"
                          model="forms.userSport.bio"
                          help="A well-written paragraph that draws people into your story"
                          rrfField={rrfForm.bio}
                          type="textarea"
                        />
                        {
                          (selectedSport && ! (softballBaseballIdsArray.includes( selectedSport.id )) &&
                              <TextAreaControl
                                label="Offer(s)"
                                placeholder="Offer(s)"
                                model="forms.userSport.offers"
                                rrfField={rrfForm.offers}
                                type="textarea"
                                help="Offers should be separated by a comma"
                              />
                          )
                        }
                        <TextControl
                          label="Committed to"
                          placeholder="Committed to"
                          model="forms.userSport.commits"
                          rrfField={rrfForm.commits}
                        />
                        {
                          selectedSport && 
                          (selectedSport.id === 2 || selectedSport.id === 4) && (
                            <SelectControl
                              label={selectedSport.bats || 'BAT(s)'}
                              placeholder="BAT(s)"
                              model="forms.userSport.bats"
                              rrfField={rrfForm.bats}
                              options={batsThrows('BATS')}
                            />
                          )
                        }
                       {
                          selectedSport && 
                          (selectedSport.id === 2 || selectedSport.id === 4) && (
                            <SelectControl
                              label={selectedSport.throws || 'THROW(s)'}
                              placeholder="THROW(s)"
                              model="forms.userSport.throws"
                              rrfField={rrfForm.throws}
                              options={batsThrows('THROWS')}
                            />
                          )
                        }
                        {
                          selectedSport && selectedSport.hasJerseyNumber && (
                            <TextControl
                              label={selectedSport.jerseyNumberLabel || 'Jersey Number(s)'}
                              placeholder="Jersey Number(s)"
                              model="forms.userSport.jerseyNumbers"
                              rrfField={rrfForm.jerseyNumbers}
                              parser={parserJerseyNumbers}
                            />
                          )
                        }
                        <PositionWidget
                          positions={rrfForm.sportId.value ?
                            positions(rrfForm.sportId.value, profileGender) : []}
                          model="forms.userSport.positionArray"
                          rrfField={rrfForm.positionArray}
                          onPositionSelect={onPositionSelect}
                          sportId={rrfForm.sportId.value}
                        />
                        {
                          ((addingSport) || (selectedUserSport && !selectedUserSport.primary)) &&
                          <CheckboxControl
                            label="Make this my primary sport"
                            model="forms.userSport.primary"
                            help="Your primary sport is the first profile visitors to your page will see"
                            rrfField={rrfForm.primary}
                          />
                        }
                        <GeneralError error={formGeneralError} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="UxEditProfile_EditSports_ButtonGroup">
                  <Button
                    id="forms.userSport.submit"
                    type="submit"
                    className="UxEditProfile_MoreActions_Button"
                  >
                    Save Changes
                  </Button>
                  {
                    !addingSport && (userSports.length > 1) &&
                    <Button
                      type="button"
                      className="delete"
                      onClick={showDeleteDialog}
                    >
                      Delete Sport
                    </Button>
                  }
                </div>
              </Form>
            )
          }
          <Line style={{ paddingTop: '35px' }} />
        </Container>
      </div>
    );
  }
}

export default EditSports;
