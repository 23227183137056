/* eslint-disable react/jsx-curly-brace-presence */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '../../../store/actions/ui/routes';
import LoadingIndicator from '../common/LoadingIndicator';
import Banner from '../common/Banner';
// import teamLogoUrlAlt from '../../../assets/images/allen_logo.png'; TODO: Make teamLogo required!
import NavBar from '../common/NavBar';
import NavBarLink from '../common/NavBarLink';
import Rosters from './Rosters';
import Combines from './Combines';
import Leaderboards from './Leaderboards';
import Awards from './Awards';
import Tutorials from './Tutorials';
import SchoolLogo from '../common/SchoolLogo';
import CombineTemplates from './CombineTemplates';
import FilterSidebar from '../common/FiltersSidebar';
import SidebarLinkFilter from '../common/SidebarLinkFilter';
import SidebarMultiSelectCallbackFilter from '../common/SidebarMultiSelectCallbackFilter';
import AddTeamModal from './AddTeamModal/AddTeamModal.component';

import { Roles } from '../../../store/actions/data/user/profile/models';
import CollegeContact from '../CollegeContact';
import Icon from '../../../components/UxCommon/Icon/Icon';
import TwoToneButton from '../common/TwoToneButton';
import { ShareModal } from '../../../components/UxCommon';
import { match } from 'assert';
import { restrictedSchoolIds } from '../../../../src/components/UxCommon/Form/utils';
import { getCoachAllSportsData } from '../../../store/actions/ui/formUtils/validators';
import CombineTemplateComponent from "./CombineTemplate/CombineTemplate.component";

const getSidebarFilters = ({
  match,
  sports,
  schools,
  locationSeach,
  loggedInUserRoute,
  orgSportsAff,
  profile,
}) => {
  let coachAllSportsIds = [];
  if(match && schools){
  schools.map((value) => {
    if(match.params.schoolId === value.id){
      const currentSchoolId = value.id;
      profile && profile.roleId === 4 && profile.enrolledSchool
    ? profile.enrolledSchool.map((ele) => {
        if(ele.user_school_team.school_id === currentSchoolId){
        coachAllSportsIds.push(ele.user_school_team.sport_id);
        }
      })
    : null;
    }
  })
  }
  const matchTab = match.params.tab || 'awards';
  const linkFilters = [
    {
      name: 'Organizations',
      label: 'Schools',
      items: schools.map((school) => ({
        name: school.name,
        value: school.id,
        option: `/${loggedInUserRoute}/${Routes.dashboard}/${school.id}/${matchTab}`,
        to: `/${loggedInUserRoute}/${Routes.dashboard}/${school.id}/${matchTab}${locationSeach}`,
      })),
    },
  ];
  let queryStringFilters = [];
  if (
    match.params.tab === Routes.rosters ||
    match.params.tab === Routes.leaderboard ||
    match.params.tab === Routes.reports ||
    (match.params.tab === Routes.awards && match.isExact) ||
    // ||  match.params.tab === Routes.combineTemplate
    match.params.tab === Routes.combines
  ) {
    queryStringFilters = [
      {
        name: 'sportId',
        label: 'Sports',
        items: getCoachAllSportsData(sports, _.uniq(coachAllSportsIds)),
      },
    ];
  }

  // if(match.params.tab === Routes.combines){
  //   queryStringFilters = [
  //     {
  //       name: 'sportId',
  //       label: 'Sports',
  //       items: getCoachAllSportsData(sports, coachAllSportsIds),
  //     },
  //   ];
  // }

  return {
    linkFilters,
    queryStringFilters,
  };
};

class Dashboard extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    schoolStyles: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    coachCode: PropTypes.string,
    athleteCode: PropTypes.string,
    cancelApiCalls: PropTypes.func,
    profile: PropTypes.object,
    combineAward: PropTypes.object,
  };

  state = {
    isCoachModalVisible: false,
    clipboardMessage: '',
    showMobileFilters: false,
    shareDialogUri: '',
    linkFilters: [],
    queryStringFilters: [],
    tempRosterTeams: [],
    schoolId: '',
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
    const {
      rosterTeams,
      schoolStyles,
      sports,
      getOrgAffSports,
      match,
      rosterTeamLoading,
    } = this.props;
    const { tempRosterTeams, schoolId } = this.state;
    if (rosterTeams.length && schoolStyles.length && sports.length) {
      if (
        !rosterTeamLoading &&
        ((tempRosterTeams.length !== rosterTeams.length &&
          match.params.schoolId !== schoolId) ||
          match.params.schoolId !== schoolId)
      ) {
        getOrgAffSports(rosterTeams, schoolStyles, sports);
        this.setState({
          tempRosterTeams: rosterTeams,
          schoolId: match.params.schoolId,
        });
      }
    }
  }

  shouldUpdate = () => {};

  // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
  // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
  redirectToRosters = () => (
    <Redirect to={`/${Routes.coach}/${Routes.dashboard}/${Routes.rosters}`} />
  );

  redirectToSchool = (props) => {
    const { schoolStyles } = this.props;
    if (schoolStyles.length) {
      const firstSchoolId = schoolStyles[0].id;
      const { tab, schoolId } = props.match.params;
      let goToSchool;
      if (schoolId) {
        goToSchool = schoolId;
      } else {
        goToSchool = firstSchoolId;
      }
      if (tab) {
        return (
          <Redirect
            to={`/${Routes.coach}/${Routes.dashboard}/${goToSchool}/${tab}`}
          />
        );
      }
      return (
        <Redirect
          to={`/${Routes.coach}/${Routes.dashboard}/${goToSchool}/${Routes.rosters}`}
        />
      );
    }
    return null;
  };

  copyToClipboard = (containerid) => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand('copy');
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().addRange(range);
      document.execCommand('copy');
    }
  };

  handleCoachCopy2Clipboard = () => {
    this.copyToClipboard('coachCodeText');
    this.setState({
      clipboardMessage: 'Coach code has been copied to clipboard!',
    });
  };

  handleAthleteCopy2Clipboard = () => {
    this.copyToClipboard('athleteCodeText');
    this.setState({
      clipboardMessage: 'Athlete code has been copied to clipboard!',
    });
  };

  handleCloseAddTeamModal = () => {
    this.setState({ isCoachModalVisible: false });
  };

  handleOpenAddTeamModal = () => {
    this.setState({ isCoachModalVisible: true });
  };

  openSharingModal = () => {
    this.setState({
      shareDialogUri: this.props.location.pathname,
    });
  };

  closeShareDialog = () => {
    this.setState({
      shareDialogUri: '',
    });
  };

  render() {
    const {
      match,
      loading,
      loadingMsg,
      schoolStyles,
      sports,
      history,
      location,
      coachCode,
      athleteCode,
      cancelApiCalls,
      profile,
      combineAward,
      orgSportsAff,
    } = this.props;
    const userType = profile ? profile.roleId : null;
    const loggedInUserRoute = profile
      ? Roles.coach !== userType
        ? Routes.athlete
        : Routes.coach
      : Routes.coach;
    const locationSeach = history.location.search;
    const schools = schoolStyles;
    const { isCoachModalVisible, clipboardMessage } = this.state;
    const { linkFilters, queryStringFilters } = getSidebarFilters({
      match,
      sports,
      schools,
      locationSeach,
      loggedInUserRoute,
      orgSportsAff,
      profile,
    });
    const schoolId = match.params.schoolId;
    // const restictedSchoolIdForLeaderboard = ['efb99b20-7587-11e9-9de9-e14bf5680e4c','f8c4d180-7587-11e9-be81-710d9c79d557'];
    const isShowLeaderboard = restrictedSchoolIds.includes(schoolId)
      ? profile && profile.roleId === 4
      : profile
      ? true
      : false;
    const currentStyle = schoolStyles.find(
      (s) => s.id === match.params.schoolId,
    );
    return (
      <div className="CoachDashboard">
        <Banner match={match} onDashboard>
          <div className="CoachRoster_BannerChildren">
            <div className="CoachRoster_BannerLeft">
              <SchoolLogo
                className="CoachRoster_BannerTeamLogo"
                schoolStyle={currentStyle}
              />
              <div className="CoachRoster_BannerFullTitle">Dashboard</div>
            </div>
            {/* {combineAward && Object.keys(combineAward).length  && (
              <div className="CoachCombine_BannerRight">
                <Switch>
                  <Route
                    path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}/${Routes.combine}/:combineId`}
                    component ={ () =>  <TwoToneButton
                      onClick = {this.openSharingModal}
                      className="print"
                      text="Share"
                      iconName="share" />
                    }
                  />
                </Switch>
              </div>
            )} */}
          </div>
        </Banner>

        {this.state.shareDialogUri && (
          <ShareModal
            uri={this.state.shareDialogUri}
            onClose={this.closeShareDialog}
            backdropClassName=""
          />
        )}
        {isCoachModalVisible && (
          <AddTeamModal
            coachCode={coachCode}
            athleteCode={athleteCode}
            onCancel={this.handleCloseAddTeamModal}
            handleCoachCopy2Clipboard={this.handleCoachCopy2Clipboard}
            handleAthleteCopy2Clipboard={this.handleAthleteCopy2Clipboard}
            teamName={currentStyle.name}
            message={clipboardMessage}
          />
        )}
        <div className="CoachDashboard_BodyWrapper">
          <NavBar>
            <div className="CoachNavBar_Left">
              {profile && (
                <NavBarLink
                  to={`/${loggedInUserRoute}/${Routes.dashboard}/${match.params.schoolId}/${Routes.rosters}`}
                  match={match}
                  onClick={cancelApiCalls}>
                  Rosters
                </NavBarLink>
              )}
              {profile && Roles.coach === userType && (
                <React.Fragment>
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combines}`}
                    match={match}
                    onClick={cancelApiCalls}>
                    Combines
                  </NavBarLink>
                </React.Fragment>
              )}
              {isShowLeaderboard && (
                <NavBarLink
                  to={`/${loggedInUserRoute}/${Routes.dashboard}/${match.params.schoolId}/${Routes.leaderboard}`}
                  match={match}
                  onClick={cancelApiCalls}>
                  Leaderboards
                </NavBarLink>
              )}
              {profile && Roles.coach === userType && (
                <React.Fragment>
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.reports}`}
                    match={match}
                    onClick={cancelApiCalls}>
                    Reports
                  </NavBarLink>
                </React.Fragment>
              )}
              <NavBarLink
                to={`/${loggedInUserRoute}/${Routes.dashboard}/${match.params.schoolId}/${Routes.awards}`}
                match={match}
                onClick={cancelApiCalls}>
                Share Awards
              </NavBarLink>
              {profile && (
                <React.Fragment>
                  <NavBarLink
                    to={`/${loggedInUserRoute}/${Routes.dashboard}/${match.params.schoolId}/${Routes.collegeContact}`}
                    match={match}
                    onClick={cancelApiCalls}>
                    College Directory
                  </NavBarLink>
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.tutorials}`}
                    match={match}
                    className="CoachNavBar_MediumScreen">
                    Tutorials
                  </NavBarLink>
                  <NavBarLink
                    to=""
                    match={match}
                    className="CoachNavBar_MediumScreen"
                    onClick={this.handleOpenAddTeamModal}>
                    Codes
                  </NavBarLink>
                  <NavBarLink
                    to={`/${Routes.code}/${coachCode}/my-sports`}
                    match={match}
                    className="CoachNavBar_MediumScreen"
                    state={{ codeConfirmed: true }}>
                    Add Team
                  </NavBarLink>
                </React.Fragment>
              )}
              {/*disable as of now, using it as a link*/}
              {/*<NavBarLink*/}
              {/*  to={`/${Routes.coach}/${Routes.nationalLeaderboard}`}*/}
              {/*  match={match}*/}
              {/*  onClick={cancelApiCalls}*/}
              {/*>*/}
              {/*  National Leaderboard*/}
              {/*</NavBarLink>*/}
              <NavBarLink
                to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.tutorials}`}
                match={match}
                className="CoachNavBar_MediumScreen">
                Tutorials
              </NavBarLink>
              <NavBarLink
                to=""
                match={match}
                className="CoachNavBar_MediumScreen"
                onClick={this.handleOpenAddTeamModal}>
                Codes
              </NavBarLink>
              <NavBarLink
                to={`/${Routes.code}/${coachCode}/my-sports`}
                match={match}
                className="CoachNavBar_MediumScreen"
                state={{ codeConfirmed: true }}>
                Add Team
              </NavBarLink>
            </div>
            {profile && Roles.coach === userType && (
              <div className="CoachNavBar_Right">
                <a
                  className="CoachNavBarLink"
                  href={`https://www.youtube.com/channel/UCjTKetuwXG3kQ4Vytc6KS5A/playlists`}
                  target="_blank">
                  Tutorials
                </a>
                <NavBarLink
                  to=""
                  match={match}
                  onClick={this.handleOpenAddTeamModal}>
                  Codes
                </NavBarLink>
                <NavBarLink
                  to={`/${Routes.code}/${coachCode}/my-sports`}
                  match={match}
                  state={{ codeConfirmed: true }}>
                  Add Team
                </NavBarLink>
              </div>
            )}
          </NavBar>
          <div className="CoachDashboard_Body">
            <LoadingIndicator
              loading={loading}
              loadingMsg={loadingMsg}
              wrapperStyle={{ top: 100, zIndex: 9 }}
            />
            {!(match.params.tab === Routes.combineTemplate || match.params.tab === Routes.editCombineTemplate) && (
              <FilterSidebar
                displayNone={!!loading}
                showMobileFilters={(v) =>
                  this.setState({ showMobileFilters: v })
                }
                defaultIsHidden={!this.state.showMobileFilters}>
                {linkFilters.map(({ name, label, items }) => (
                  <SidebarLinkFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    match={match}
                  />
                ))}
                {queryStringFilters.map(({ name, label, items }) => (
                  <SidebarMultiSelectCallbackFilter
                    key={name}
                    filterName={name}
                    label={label}
                    filters={items}
                    history={history}
                    location={location}
                    match={match}
                  />
                 ))}
              </FilterSidebar>
            )}
            <div
              className={`CoachDashboard_BodyRight vc-dashboard-review-award ${
                match.params.tab === Routes.combineTemplate || match.params.tab === Routes.editCombineTemplate
                  ? 'vc-dash-combine-template'
                  : ''
              }`}>
              <Switch>
                <Route
                  path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.rosters}`}
                  component={Rosters}
                />
                <Route
                  path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/(${Routes.combines}|${Routes.reports})`}
                  component={Combines}
                />

                {/*<Route path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.reports}`} component={Combines} />*/}
                <Route
                  path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.leaderboard}`}
                  component={Leaderboards}
                />
                <Route
                  path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.awards}`}
                  component={Awards}
                />
                <Route
                  path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/${Routes.collegeContact}`}
                  component={CollegeContact}
                />
                <Route
                  path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.tutorials}`}
                  component={Tutorials}
                />
                <Route
                    path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combineTemplate}/:templateId/:combineId?`}
                    component={CombineTemplates}
                />
                <Route
                  path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.editCombineTemplate}/:templateId/:combineId`}
                  component={CombineTemplateComponent}
                />
                <Route
                  path={`/${Routes.coach}/${Routes.dashboard}/(${Routes.rosters}|${Routes.combines}|${Routes.leaderboard}|${Routes.combineTemplate}|${Routes.awards}|${Routes.photos})`}
                  render={this.redirectToSchool}
                />
                <Route
                  path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId?/:tab?`}
                  render={this.redirectToSchool}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
