import React from "react";
import AwardsRow from "./AwardsRow";
import useHasMembership from "../../../../hooks/useHasMembership";

const withMembershipAwardsRow = (props) => {
  const hasMembership = useHasMembership(props.match);

  return <AwardsRow {...props} hasMembership={hasMembership} />;
};
export default withMembershipAwardsRow;
