import { combineEpics } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';
import { commonApiCallFragment, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import {
  createTwitterHandleUrl,
  updateTwitterHandleUrl,
} from '../../../../data/user/twitterHandle/apiCalls';

import { CREATE_TWITTER_HANDLE, UPDATE_TWITTER_HANDLE } from '../actions';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import { twitterHandleSaveSuccess } from '../../../../data/user/twitterHandle';

const createUpdateTwitterHandleUrlEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    filter(action =>
      action.type === CREATE_TWITTER_HANDLE ||
      action.type === UPDATE_TWITTER_HANDLE),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (!(action.hasPrevValue) && !(action.name)) {
        return of({
          success: false,
          actions: [
            actions.setErrors('forms.twitterHandle.url', {
              validation: 'You forget to type your Twitter Handle',
            }),
            asyncErrorAction(action.type, 'createTwitterHandleUrl'),
          ],
        });
      }
      const apiCall =
        action.type === CREATE_TWITTER_HANDLE
          ? createTwitterHandleUrl
          : updateTwitterHandleUrl;
      const body = isCoach() ? { athlete_uuid: uuid() } : {};
      return apiCall(action.name, token, body)
        .pipe(commonApiCallFragment(action$, action, 'twitterHandle', Routes.edit));
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          twitterHandleSaveSuccess(uuid(), results.action.name),
          asyncFinishAction(results.action.type, 'createTwitterHandleUrl', {
            name: results.action.name,
          }),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );
};

export const createUpdateEpics = combineEpics(createUpdateTwitterHandleUrlEpic);
export default 'twitterHandle/create.js';
