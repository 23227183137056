import { asyncStartAction } from '../../../../store/actions/ui/async';

export const GET_TEAM_TYPES = 'codeSignUp.getTeamTypes';
export const getTeamTypes = () => (dispatch) => {
  dispatch(asyncStartAction(GET_TEAM_TYPES));
  dispatch({
    type: GET_TEAM_TYPES,
  });
};

export const CHECK_CODE = 'codeSignUp.checkCode';
export const checkSignUpCode = (code, isMobile=false) => (dispatch) => {
  dispatch(asyncStartAction(CHECK_CODE));
  dispatch({
    type: CHECK_CODE,
    code,
    isMobile,
  });
};

// export const GET_SCHOOL_TEAMS = 'codeSignUp.getUserTeams';
// export const getUsersTeams = uuid => (dispatch) => {
//   dispatch(asyncStartAction(GET_USER_TEAMS));
//   dispatch({
//     type: GET_USER_TEAMS,
//     uuid,
//   });
// };
