import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import pathToRegexp from 'path-to-regexp';
import { Route } from 'react-router-dom';
import { Routes, expandedPhotoDialogPath } from '../../../store/actions/ui/routes';
import { Row, Col, ContainerFluid } from '../../UxCommon';
import TopBar from './TopBar/TopBar';
import SideBar from '../../../containers/UxProfile/Photos/Sidebar';
import PhotoVideoList from './PhotoVideoList/PhotoVideoList';
import AlbumDialog from '../../../containers/UxProfile/Photos/AlbumDialog';
import PageHeader from '../../../containers/UxProfile/PageHeader';
import GetPhotosVidoes from '../../../containers/UxCommon/Loaders/GetPhotosVidoes';
import PhotoFilterDialog from '../../../containers/UxProfile/Photos/PhotoFilterDialog';
import ExpandedPhotoDialog from '../../../containers/UxProfile/Photos/ExpandedPhotoDialog';

class Photos extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    photoList: PropTypes.array.isRequired,
    albumDialogIsShowing: PropTypes.bool.isRequired,
    selectAlbumDialogIsShowing: PropTypes.bool.isRequired,
    expandedDialogIsOpen: PropTypes.bool.isRequired,
    multiDeleteIsOpen: PropTypes.bool.isRequired,
    onMultiDeleteCancelClick: PropTypes.func.isRequired,
    tagDialogIsShowing: PropTypes.bool.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    mySports: PropTypes.array.isRequired,
    onSaveFavorites: PropTypes.func.isRequired,
    getPhotoVideoFavorites: PropTypes.func.isRequired,
    photosLoading: PropTypes.bool.isRequired,
    onShowFilterDialogClick: PropTypes.func.isRequired,
    isPhotoFilterDialogShown: PropTypes.bool.isRequired,
    isFilterApplied: PropTypes.bool.isRequired,
    onClearAllFiltersClick: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    getCoachAndAthleteTeams: PropTypes.func,
    uuid: PropTypes.string,
    isCoach: PropTypes.bool,
    user: PropTypes.object,
  }

  state = {
    screenHeight: 400,
  }

  componentDidMount() {
    this.setScreenHeight();
    const { uuid, getCoachAndAthleteTeams, match } = this.props;
    if (uuid && uuid !== match.params.uuid) {
      getCoachAndAthleteTeams(uuid, match.params.uuid);
    }
  }

  setScreenHeight = () => {
    const screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const headerHeight = document.querySelector('.PageHeader').offsetHeight;
    this.setState({
      screenHeight: screenHeight - headerHeight,
    });
  }

  getRoute = (page) => {
    const { match } = this.props;
    return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}`;
  };

  getClassNameForSmallDevices = () => {
    const re = pathToRegexp(expandedPhotoDialogPath);
    const params = re.exec(window.location.pathname);
    if (params) {
      return 'arrange-screen';
    }
    return '';
  }

  render() {
    const {
      match,
      history,
      photoList,
      selectedCategory,
      onAddClick,
      albumDialogIsShowing,
      selectAlbumDialogIsShowing,
      expandedDialogIsOpen,
      onDeleteClick,
      multiDeleteIsOpen,
      onMultiDeleteCancelClick,
      tagDialogIsShowing,
      isMyProfile,
      mySports,
      onSaveFavorites,
      getPhotoVideoFavorites,
      photosLoading,
      onShowFilterDialogClick,
      isPhotoFilterDialogShown,
      isFilterApplied,
      onClearAllFiltersClick,
      onShareClick,
      isCoach,
      user,
    } = this.props;
    const { screenHeight } = this.state;
    return (
      <div className="UxProfile_PhotosWrapper">
        <Route path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`} component={PageHeader} />
        { isPhotoFilterDialogShown && <PhotoFilterDialog /> }
        <TopBar
          match={match}
          history={history}
          user={user}
          selectedCategory={selectedCategory}
          onAddClick={onAddClick}
          onDeleteClick={onDeleteClick}
          photoList={photoList}
          multiDeleteIsOpen={multiDeleteIsOpen}
          onMultiDeleteCancelClick={onMultiDeleteCancelClick}
          isMyProfile={isMyProfile}
          photosLoading={photosLoading}
          onShowFilterDialogClick={onShowFilterDialogClick}
          onShareClick={onShareClick}
          isCoach={isCoach}
        />
        { albumDialogIsShowing && <AlbumDialog match={match} /> }
        {
          !!(photoList && photoList.length) && (
            <Route
              path={expandedPhotoDialogPath}
              render={props => <ExpandedPhotoDialog photoList={photoList} {...props} />}
            />
          )
        }
        <div className="UxProfile_Photos" style={{ minHeight: `${screenHeight * 0.6}px` }}>
          <GetPhotosVidoes match={match}>
            <ContainerFluid>
              <Row>
                <Col
                  className={[
                    'hidden-md-down',
                    'col-auto',
                    this.getClassNameForSmallDevices(),
                  ].join(' ')}
                  style={{ padding: 0 }}
                >
                  <SideBar match={match} />
                </Col>
                <Col>
                  <PhotoVideoList
                    match={match}
                    photosVideos={photoList}
                    onAddPhotoVideoClick={onAddClick}
                    photoList={photoList}
                    selectAlbumDialogIsShowing={selectAlbumDialogIsShowing}
                    expandedDialogIsOpen={expandedDialogIsOpen}
                    tagDialogIsShowing={tagDialogIsShowing}
                    isMyProfile={isMyProfile}
                    mySports={mySports}
                    onSaveFavorites={onSaveFavorites}
                    getPhotoVideoFavorites={getPhotoVideoFavorites}
                    isFilterApplied={isFilterApplied}
                    onClearAllFiltersClick={onClearAllFiltersClick}
                  />
                </Col>
              </Row>
            </ContainerFluid>
          </GetPhotosVidoes>
        </div>
      </div>
    );
  }
}

export default Photos;
