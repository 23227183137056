import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/AlbumDialog/ExternalVideoDialog/ExternalVideoDialog';
import { checkVideoUrl, clearStagedMedia, createAlbum } from '../../../store/actions/ui/uxProfile/photosVideos';

const mapStateToProps = state => ({
  loading: !!state.ui.uxProfile.photosVideos.externalVideo.loadingMsg,
  loadingMsg: state.ui.uxProfile.photosVideos.externalVideo.loadingMsg,
  rrfForm: state.forms.forms.externalVideo,
  stagedMedia: state.ui.uxProfile.photosVideos.albumDialog.stagedMedia,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onAdd: () => {
      dispatch(checkVideoUrl());
    },
    clearStagedMedia: () => {
      dispatch(clearStagedMedia());
    },
    createAlbum: () => {
      dispatch(createAlbum(dispatch));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
