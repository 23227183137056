import React from 'react';

const SectionPricingComponent = ({ content }) => {
  return (
    <div className="my-4">
      <b
        dangerouslySetInnerHTML={{
          __html: content,
        }}></b>
    </div>
  );
};

export default SectionPricingComponent;
