import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

const LinkButton = ({
  iconName,
  children,
  className,
  disable,
  toolTip,
  ...props
}) => (
  <button disabled={disable} className={['UxCommon_LinkButton', className || ''].join(' ')} {...props}>
    {
      iconName &&
        <div className="UxCommon_LinkButton_Left">
          <Icon iconName={iconName} tooltip={toolTip} />
        </div>
    }
    <div className="UxCommon_LinkButton_Right">
      {children}
    </div>
  </button>
);
LinkButton.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default LinkButton;
