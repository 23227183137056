import { combineEpics, ofType } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { commonApiCallFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';

import { testTwitterHandleUrl } from '../../../../data/user/twitterHandle/apiCalls';
import { TEST_TWITTER_HANDLE, testTwitterHandleAction } from '../actions';

import { getRrfChangeValue, ofTypeOfModel } from '../../../formUtils/operators';
import { asyncErrorAction, asyncFinishAction } from '../../../async';

const typeAndCheckEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const profile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    ofTypeOfModel('rrf/change', 'forms.twitterHandle.url'),
    map(action => getRrfChangeValue(action)),
    filter(name => !!name.replace(/^@/, '')),
    filter(name => profile().twitterHandle !== name),
    map(name => testTwitterHandleAction(name)),
  );
};

export const testTwitterHandleEpic = action$ =>
  action$.pipe(
    ofType(TEST_TWITTER_HANDLE),
    switchMap(action =>
      testTwitterHandleUrl((action.name).replace(/^@/, '')).pipe(commonApiCallFragment(action$, action, 'twitterHandle', Routes.edit))),
    switchMap((results) => {
      if (
        !results.success &&
        results.action.error &&
        results.action.error.response === 'Twitter Handle Not In Use'
      ) {
        if (results.originalAction.isMobile) {
          return of(asyncFinishAction(results.action.type, 'testTwitterHandleUrl'));
        }
        return of(
          asyncFinishAction(results.action.type, 'testTwitterHandleUrl'),
          actions.setErrors('forms.twitterHandle.url', {
            validation: '',
          }),
        );
      }
      if (results.success) {
        if (results.action.isMobile) {
          return of(asyncErrorAction(results.action.type, 'testTwitterHandleUrl', 'Already being used by another athlete.'));
        }
        return of(
          asyncFinishAction(results.action.type, 'testTwitterHandleUrl',  { message: 'Already being used by another athlete.'} ),
          actions.setErrors('forms.twitterHandle.url', {
            validation: 'Already being used by another athlete.',
          }),
        );
      }

      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );

export const readEpics = combineEpics(typeAndCheckEpic, testTwitterHandleEpic);

export default 'twitterHandle/create.js';
