import { asyncStartAction } from '../../../../store/actions/ui/async';

export const SIGN_IN = 'codeSignUp.signIn';
export const signIn = (fields, code) => (dispatch) => {
  dispatch(asyncStartAction(SIGN_IN));
  dispatch({
    type: SIGN_IN,
    fields,
    code,
  });
};

