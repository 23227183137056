import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Control } from 'react-redux-form';

import ErrorsGroup from '../Errors/Errors';

const SimpleSelect = ({
  model,
  rrfField,
  options,
  placeholder,
  label,
  ...otherProps
}) => (
  <div className="UxCommon_Form_Group">
    <div
      className={[
        'UxCommon_Form_InputGroup',
        rrfField.valid ? '' : 'error',
      ].join(' ')}
    >
      <div className="UxCommon_Form_ErrorMarker" />
      <div className="UxCommon_Form_LabelWrapper">
        <label
          className={[
            'UxCommon_Form_Label',
            rrfField.value && rrfField.value.length ? 'show' : '',
          ].join(' ')}
          htmlFor={model}
        >
          {label}
        </label>
      </div>
      <div className={[
          'UxCommon_Form_Input',
          rrfField.value && rrfField.value.length ? 'not-empty' : '',
        ].join(' ')}
      >
        <select id={model} className="UxCommon_Form_SelectSelect" value={rrfField.value} {...otherProps}>
          <option value="" disabled hidden>{placeholder || label}</option>
          {
            options.map((option, index) => (
              <option
                key={index}
                value={option.value}
              >
                {option.text}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  </div>
);
SimpleSelect.propTypes = {
  model: PropTypes.string.isRequired,
  rrfField: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

class TestsDatePicker extends PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    rrfField: PropTypes.object,
    defaultValue: PropTypes.any,
  }

  onMonthChange = (e) => {
    const { onChange, rrfField } = this.props;
    const month = rrfField.value;
    const newMonth = month.clone();
    onChange(newMonth.month(e.target.value));
  }

  onYearChange = (e) => {
    const { onChange, rrfField } = this.props;
    const month = rrfField.value;
    const newMonth = month.clone();
    onChange(newMonth.year(e.target.value));
  }

  onDayChange = (e) => {
    const { onChange, rrfField } = this.props;
    const month = rrfField.value;
    let newMonth;
    if (month) {
      newMonth = month.clone();
    } else {
      newMonth = moment();
    }
    onChange(newMonth.date(e.target.value));
  }

  getDays = (month) => {
    const range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);
    return range(1, month.daysInMonth() + 1);
  }

  getMonths = () => {
    const months = [];
    for (let i = 0; i < 12; i += 1) {
      months.push(moment().month(i));
    }
    return months;
  }

  getYears = () => {
    const years = [];
    const today = moment();
    for (let i = 0; i < 20; i += 1) {
      years.push(moment().year(today.year() - i));
    }
    return years;
  }

  render() {
    const { model, rrfField, defaultValue } = this.props;
    const month = rrfField.value || defaultValue || null;
    return (
      <div className="UxCommon_TestsDatePicker">
        <div className="UxCommong_TestsDatePicker_Controls">
          <SimpleSelect
            label="Month"
            placeholder="Month"
            model={`${model}.month`}
            rrfField={{ value: month ? month.month() : '', errors: {}, valid: true }}
            options={this.getMonths().map(m => ({ value: m.month(), text: m.format('MMMM') }))}
            onChange={this.onMonthChange}
          />
          <SimpleSelect
            label="Day"
            placeholder="Day"
            model={`${model}.day`}
            rrfField={{ value: month ? month.date() : '', errors: {}, valid: true }}
            options={this.getDays(month).map(m => ({ value: m, text: m }))}
            onChange={this.onDayChange}
          />
          <SimpleSelect
            label="Year"
            placeholder="Year"
            model={`${model}.year`}
            rrfField={{ value: month ? month.year() : '', errors: {}, valid: true }}
            options={this.getYears().map(m => ({ value: m.year(), text: m.format('YYYY') }))}
            onChange={this.onYearChange}
          />
        </div>
        <Control
          type="hidden"
          model={model}
          defaultValue={month}
        />
        <ErrorsGroup valid={rrfField.valid} errors={rrfField.errors} />
      </div>
    );
  }
}

export default TestsDatePicker;
