import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { Link, Redirect } from 'react-router-dom';
import { Routes } from '../../../store/actions/ui/routes';
import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';

class SecondSignIn extends PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    autoSubmit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
    const { username, password, autoSubmit } = this.props;
    if (username && password) {
      autoSubmit(username, password);
    }
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      this.props.formInit();
    }
  };

  submit = (form) => {
    this.props.submit(this.props.username, form.password);
  }

  render() {
    const { username } = this.props;
    // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
    // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
    if (!username) {
      return <Redirect to={`/${Routes.welcome}/${Routes.login}`} />;
    }
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div style={{ fontSize: '1.6rem', textAlign: 'center', marginBottom: '.5rem' }}>{this.props.username}</div>
            <div className="welcome-msg">{this.props.msg}</div>
            <div className="welcome-desc">Please enter your password one more time and we&#39;ll get started.</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <Form className="welcome-form" model="forms.secondSignIn" onSubmit={this.submit}>
              <TextControlGroup label="Password" type="password" model="forms.secondSignIn.password" />
              {
                this.props.formGeneralError &&
                  <div className="form-group">
                    <div className="form-general-error">{this.props.formGeneralError}</div>
                  </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="submit">Sign In</button>
              </div>
            </Form>
            <div className="d-flex justify-content-center">
              <Link className="welcome-link" to={`/${Routes.welcome}/${Routes.forgot}`}>
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SecondSignIn;
