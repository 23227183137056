import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/Icon';
import TextControl from '../../../common/FormControls/TextControl';
import SelectControl from '../../../common/FormControls/SelectControl';
import { ButtonRect } from '../../../../../components/UxCommon';
import { validateRequired } from '../../../../../store/actions/ui/formUtils/validators';
import ArchiveCombineDialog from '../../Combines/ArchiveCombineDialog/ArchiveCombineDialog.component';
import LoadingIndicator from '../../../common/LoadingIndicator';
import CombineLister from './CategoryLister/CategoryLister.component';
import ControlErrors from '../../../common/FormControls/ControlErrors';
import TooltipIcon from '../../../common/TooltipIcon/TooltipIcon.component';
import { authorizedToDelete } from '../../../../../utils'
import { Sports } from '../../../../../store/actions/ui/routes';
import queryString from 'query-string';


export const checkRequired = (value, msg = '') => {
  const error = validateRequired(msg)(value).required;
  return error ? [error] : [];
};

const CatButton = ({ cat, onClick }) => (
  <button onClick={onClick(cat)} className="CombineTemplateWrapper_CategoryBadge" >
    <div className="CombineTemplate_BadgeText">
      <div className="CombineTemplate_BadgeTextName">{cat.name}</div>
      <div className="CombineTemplate_BadgeTextDesc">{cat.description}</div>
    </div>
    <Icon iconName="close" />
  </button>
);

CatButton.propTypes = {
  cat: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

class EditCreateCombineTemplate extends PureComponent {
  static propTypes = {
    getTestCategories: PropTypes.func.isRequired,
    standardTestCats: PropTypes.array.isRequired,
    sports: PropTypes.object.isRequired,
    standardTestCatOptions: PropTypes.object,
    combineTemplate: PropTypes.object,
    createCombineTemplate: PropTypes.func.isRequired,
    updateCombineTemplate: PropTypes.func.isRequired,
    openDeleteModal: PropTypes.bool.isRequired,
    toggleDeleteModal: PropTypes.func.isRequired,
    deleteCombineTemplate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    onNameChange: PropTypes.func.isRequired,
    organizedSubbanks: PropTypes.object,
    sportedCats: PropTypes.array,
    clearDeleteModal: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    sportsCode : PropTypes.object.isRequired
  };

  state = {
    errors: {},
    values: {
      name: '',
      sport: '',
      cat: '',
      desc: '',
    },
    addedCats: [],
    required: [],
    optional: [],
    combineCount:0,
    isEnableToDelete: false,
    waitForStateUpdate:false,
    sportUpdated: false,
  };

  static getDerivedStateFromProps(props, currentState) {
    if (props.combineTemplate
    && currentState.combineTemplateId !== props.combineTemplate.id
    && props.standardTestCats.length) {
      const currentCats = [];
      props.combineTemplate.stdTestCategories.forEach((cat) => {
        const stdCat = props.standardTestCats.find(c => c.id === cat.standardTestCategoryId);
        if (stdCat) {
          currentCats.push(stdCat);
        }
      });
      // props.onNameChange(props.combineTemplate.name);
      const required = props.sportedCats.filter(c =>
        !!c.adultRateable
        && c.sportId === props.combineTemplate.sportId);
      const optional = props.standardTestCats.filter(c => !required.find(r => r.code === c.code));
      return {
        isEnableToDelete: props.combineTemplate.combineCount > 0 ? true : false,
        combineTemplateId: props.combineTemplate.id,
        errors: {},
        values: {
          name: props.combineTemplate.name,
          sport:  props.combineTemplate.sportId || props.sportId,
          desc: props.combineTemplate.description,
          cat: '',
        },
        addedCats: currentCats,
        required,
        optional,
        waitForStateUpdate: true,
      };
    }
    if (props.match.params.templateId === 'new'
    && props.standardTestCats.length
    && !currentState.addedCats.length) {
      let heightCode = 'HEIGHT';
      let weightCode = 'WEIGHT';
      const sportCode = props.sportsCode[currentState.values.sport];
      if(sportCode ===  'INTLRUGBY' || sportCode  =='INTLNETBALL' ) {
        heightCode = 'METRICHEIGHT';
        weightCode = 'METRICWEIGHT';
      }
      const height = props.standardTestCats.find(c => c.code === heightCode);
      const weight = props.standardTestCats.find(c => c.code === weightCode);
      const parsedQuery = queryString.parse(props.location.search);
      const { sportId } = parsedQuery;
      return {
        addedCats: [height, weight],
        optional: props.standardTestCats,
        waitForStateUpdate: true,
        values: {
          name: '',
          sport: Number(sportId),
          desc: '',
          cat: '',
        },
      };
    }
    return null;
  }

  componentDidMount() {
    const { clearDeleteModal } = this.props;
    this.shouldUpdate();
    clearDeleteModal();
  }

  componentDidUpdate() {
    const { sportUpdated,values } = this.state;
    if(!sportUpdated && values.sport){
      this.setRequiredAndOptional(values.sport);
      this.setSportHeightAndWeight(values.sport);
      this.setState({sportUpdated: true})
    }
    this.shouldUpdate();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.handleSubmit(false, true);
  };

  onSubmitAndRedirect = (event) => {
    event.preventDefault();
    this.handleSubmit(true, false);
  };

  onValueChange = name => (value) => {
    // const { onNameChange } = this.props;
    const { errors, values } = this.state;
    const nextState = {
      errors: { ...errors },
      values: { ...values },
    };
    switch (name) {
      // case 'name':
      //   nextState.values.name = value;
      //   nextState.errors.name = checkRequired(value);
      //   onNameChange(value);
      //   break;
      // case 'sport':
      //   nextState.values.sport = Number(value);
      //   nextState.errors.sport = checkRequired(value);
      //   if (!Number.isNaN(nextState.values.sport)) {
      //     this.setRequiredAndOptional(nextState.values.sport);
      //     this.setSportHeightAndWeight(nextState.values.sport);
      //   }
      //   break;
      // case 'desc':
      //   nextState.values.desc = value;
      //   break;
      default:
        nextState.values[name] = Number(value);
        nextState.errors[name] = [];
        break;
    }
    this.setState(nextState);
  };
  setSportHeightAndWeight = (sportId) => {
      const {addedCats} = this.state;
      const {standardTestCats,sportsCode} =  this.props;
      let filterAddedCats = [];
      const cloneAddedCats = Object.assign([], addedCats);
     if(sportsCode[sportId]) {
        cloneAddedCats.forEach(function (values, index) {
          if(sportsCode[sportId]  === 'INTLRUGBY' || sportsCode[sportId] =='INTLNETBALL') {
            if(values.code == 'HEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICHEIGHT'));
            }
            if(values.code == 'WEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICWEIGHT'));
            }
          } else {
            if(values.code == 'METRICHEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'HEIGHT'));
            }
            if(values.code == 'METRICWEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'WEIGHT'));
            }
          }
          filterAddedCats.push(cloneAddedCats[index]);
        });
      }
         this.setState( {
          addedCats : filterAddedCats.filter(item => item != null),
        });
  }
  onRemoveCat = cat => () => {
    const { addedCats } = this.state;
    this.setState({
      addedCats: addedCats.filter(c => c !== cat),
    });
  };

  onCategoryPicked = key => (value) => {
    const { standardTestCats } = this.props;
    const { addedCats, values } = this.state;
    const cat = standardTestCats.find(c => c.code === key);
    if (value) {
      this.setState({
        addedCats: [...addedCats, cat],
        values: Object.assign({}, values, {
          [key]: value,
        }),
      });
    } else {
      this.setState({
        addedCats: addedCats.filter(c => c.id !== cat.id),
        values: Object.assign({}, values, {
          [key]: value,
        }),
      });
    }
  }

  setRequiredAndOptional = (sportId) => {
    const { standardTestCats, sportedCats } = this.props;
    const required = sportedCats.filter(c => !!c.adultRateable && c.sportId === sportId);
    const optional = standardTestCats.filter(c => !required.find(r => r.code === c.code))
    this.setState({
      required,
      optional,
    });
  }

  handleSubmit = (redirectToCombine = false, redirectToCombineDataEntry = false) => {
    const {
      createCombineTemplate,
      updateCombineTemplate,
      combineTemplate,
      match,
    } = this.props;
    const schoolId = this.props.match.params.schoolId;
    const { combineId } = match.params;
    const { addedCats } = this.state;
    const form = this.formRef.current;
    const errors = {
      // name: checkRequired(form.name.value),
      sport: combineTemplate ? [] : checkRequired(form.sport.value),
      cat: addedCats.length ? null : ['Please select at least one test'],
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({ errors });
    if (success) {
      const formStdTestCats = addedCats.map(c => c.id);
      const date = new Date().toJSON().slice(0,10);
      const formData = {
        name: `${Sports[form.sport.value]} TEMPLATE ${date}`,
        description: "DESCRIPTION",
        school_uuid: schoolId,
      };
      if (!combineTemplate) {
        formData.sport_id = form.sport.value;
      }

      if (combineTemplate) {
        const curStdTestCategories = combineTemplate.stdTestCategories
          .map(c => c.standardTestCategoryId);
        const newCats = formStdTestCats.filter(el => !curStdTestCategories.includes(el));
        const removedCats = curStdTestCategories.filter(el => !formStdTestCats.includes(el));
        updateCombineTemplate(
          formData,
          combineTemplate.id,
          newCats,
          removedCats,
          match.params.schoolId,
          redirectToCombine,
          combineId,
          redirectToCombineDataEntry
        );
      } else {
        createCombineTemplate(formData, formStdTestCats, match.params.schoolId, redirectToCombine);
      }
    }
  };

  formRef = React.createRef();

  addCatToTemplate = key => () => {
    const { standardTestCats } = this.props;
    const { addedCats, values } = this.state;
    const cat = standardTestCats.find(c => c.id === values[key]);
    if (cat) {
      this.setState({
        addedCats: [...addedCats, cat],
        values: Object.assign({}, values, {
          [key]: '',
        }),
      });
    }
  };

  testCatsFetched = false;
  templateFetched = 0;
  catSelectors = 0;
  schoolCombinesFetched = false;

  shouldUpdate = () => {
    const {
       getTestCategories,
      } = this.props;
      const schoolIds = this.props.match.params.schoolId;
      let shouldUpdate = false;
    if (!this.testCatsFetched) {
      this.testCatsFetched = true;
      getTestCategories();
    }
  };

  handleDeleteClick = () => {
    const { deleteCombineTemplate, combineTemplate, match } = this.props;
    deleteCombineTemplate(combineTemplate.id, match.params.schoolId);
  };

  renderDeleteModal = () => {
    const {combineTemplate,user,combineTemplatesCombines } = this.props
    const isAuthorizedToDelete = combineTemplate && combineTemplate.createdBy === user.uuid;
    const isEnableToDelete = combineTemplate  && !!combineTemplate.combineCount;
    const msg = ( !isAuthorizedToDelete ? authorizedToDelete.combineTemplate
                :
                   isEnableToDelete ? combineTemplate.name
                   :'Are you sure want to delete this combine template?'
                );
        let combineNames ;
       if ( combineTemplate ) {
          combineNames= combineTemplatesCombines[combineTemplate.id]
                 && combineTemplatesCombines[combineTemplate.id].combines
                 && combineTemplatesCombines[combineTemplate.id].combines.map(temp => temp.name);
       }


    return <div className="CoachWorld_EditPlayer_Backdrop">
      <div className="Combine_Edit_Modal">
        {
          !(this.props.loading) &&
          <ArchiveCombineDialog
            onArchiveClick={this.handleDeleteClick}
            onCancelClick={this.props.toggleDeleteModal}
            title="Delete Combine Template?"
            msg={ msg }
            isEnableToDelete={isEnableToDelete}
            combineNames={combineNames}
            isAuthorizedToDelete={isAuthorizedToDelete}
          />
        }
        <LoadingIndicator
          loading={this.props.loading}
          loadingMsg={this.props.loadingMsg}
          positionStyle={{ margin: 'auto' }}
        />
      </div>
    </div>
  }

  render() {
    const {
      sports, standardTestCatOptions, openDeleteModal, combineTemplate, loading,
      standardTestCats, organizedSubbanks,combines,sportId , match
    } = this.props;
    const {
      errors, values, addedCats, required, optional, waitForStateUpdate
    } = this.state;
    const cloneStandardTestCatOptions = Object.assign({}, standardTestCatOptions);

    const { combineId } = match.params;

    if (addedCats.length && Object.keys(standardTestCatOptions).length) {
      addedCats.forEach(c => delete cloneStandardTestCatOptions[c.id]);
    }

    this.catSelectors = 0;
    return (
      waitForStateUpdate && <React.Fragment>
        {
          openDeleteModal && this.renderDeleteModal()
        }
        <form
          ref={this.formRef}
          onSubmit={this.onSubmit}
          className="CombineTemplate_Form"
          style={{ display: loading ? 'none' : '' }}
        >
          <div className="CombineTemplate_Cols">
            <div className="CombineTemplate_LeftCol">
              <div className="CombineTemplate_MetaData">
                {
                  <SelectControl
                    label="Sport"
                    options={sports}
                    name="sport"
                    style={{ zIndex: 5, display: "none" }}
                    defaultValue={values.sport}
                  />
                  // !combineTemplate ? (
                    // <SelectControl
                    //   placeholder="Select a Sport"
                    //   label="Sport"
                    //   options={sports}
                    //   name="sport"
                    //   errors={errors.sport}
                    //   myOnChange={this.onValueChange('sport')}
                    //   value={values.sport}
                    //   style={{ zIndex: 5 , display: "none"}}
                    //   defaultValue={values.sport}
                    // />
                  // ) : (
                  //   <div className="CombineTemplate_SportStatic">
                  //     <div className="CombineTemplate_SportLabel">Sport</div>
                  //     <div>{sports[values.sport]}</div>
                  //   </div>
                  // )
                }
                {/* <TextControl
                  placeholder="Template Name"
                  label="Template Name"
                  name="name"
                  myOnChange={this.onValueChange('name')}
                  myOnBlur={this.onValueChange('name')}
                  errors={errors.name}
                  value={values.name}
                />
                <TextControl
                  placeholder="Description"
                  label="Description"
                  name="desc"
                  myOnChange={this.onValueChange('desc')}
                  myOnBlur={this.onValueChange('desc')}
                  errors={errors.desc}
                  value={values.desc}
                /> */}
              </div>
              {/* <div style={{ marginBottom: 35 }} /> */}
              {/* {this.renderBankSelector(values.sport)} */}
              <CombineLister
                title="Speed, Agility, Performance, & Strength Tests"
                help={
                  <React.Fragment>
                    These tests are industry standard to measure your<br />
                    athlete's overall athleticism and strength
                  </React.Fragment>
                }
                missingMsg="Please select a sport"
                subbanks={Object.values(organizedSubbanks)}
                cats={required}
                filters={{ adultRateable: 1, sportId: values.sport || 1 }}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
              <div style={{ marginBottom: 35 }} />
              <CombineLister
                title="Sport Specific/Optional Tests"
                help="Add as many of the tests below as you'd like"
                subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode !== 'PHYSIQUE')}
                cats={optional}
                filters={{}}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
              <div style={{ marginBottom: 35 }} />
              <CombineLister
                title="Measureables"
                help={
                  <React.Fragment>
                    {"Add as many of the measurables below as you'd like"}<br />
                    Height and weight are required to generate a VC Score
                  </React.Fragment>
                }
                subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode === 'PHYSIQUE')}
                cats={standardTestCats}
                filters={{ adultRateable: 0 }}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
            </div>
            <div className="CombineTemplateWrapper_CategoryContainer">
              <div className="CombineTemplateWrapper_CategoryBadges">
                {
                  addedCats.map(cat => (
                    <CatButton key={cat.id} onClick={this.onRemoveCat} cat={cat} />
                  ))
                }
              </div>
              <ControlErrors errors={errors.cat} />
            </div>
          </div>
          <div className="CombineTemplate_Btns">
            {
              combineId ?
                (
                  <ButtonRect
                    type="submit"
                    onClick={this.onSubmit}
                    className="secondary"
                    style={{width: '45%', minWidth: 200}}
                  >
                    Save Combine Template
                  </ButtonRect>)
                : (
                  <ButtonRect
                    type="submit"
                    onClick={this.onSubmitAndRedirect}
                    className="primary"
                    style={{width: '45%', minWidth: 200}}
                  >
                    Save and Run Combine
                  </ButtonRect>
                )
            }


          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default EditCreateCombineTemplate;
