import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

class GetPhotosVideos extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getMedia: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    needsToLoadPhotos: PropTypes.bool.isRequired,
    needsToLoadVideos: PropTypes.bool.isRequired,
    getProfile: PropTypes.func.isRequired,
    needsToLoadProfile: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const {
      getMedia,
      uuid,
      getProfile,
      needsToLoadProfile,
    } = this.props;
    getMedia(uuid);
    if (needsToLoadProfile) {
      getProfile(uuid);
    }
  }

  componentDidUpdate() {
    const {
      needsToLoadPhotos,
      needsToLoadVideos,
      uuid,
      getMedia,
      getProfile,
      needsToLoadProfile,
    } = this.props;
    if (needsToLoadPhotos || needsToLoadVideos) {
      getMedia(uuid);
    }
    if (needsToLoadProfile) {
      getProfile(uuid);
    }
  }
  render() {
    const { children, loading, loadingMsg } = this.props;
    return (
      <React.Fragment>
        {
          loading
          ? <LoadingSpinner loading loadingMsg={loadingMsg} />
          : children
        }
      </React.Fragment>
    );
  }
}

export default GetPhotosVideos;
