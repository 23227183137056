import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import {
    epics as physiquePageEpics
} from './AthletePhysique';

export const InviteSignUpEpics = combineEpics(
    physiquePageEpics,
  );


  export * from './AthletePhysique/AthletePhysique.action';