import { asyncStartAction } from '../../../../store/actions/ui/async';

export const SAVE_PHYSIQUE = 'codeSignUp.savePhysique';
export const savePhysique = (uuid, fields, code) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_PHYSIQUE));
  dispatch({
    type: SAVE_PHYSIQUE,
    uuid,
    fields,
    code,
  });
};
