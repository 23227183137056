import { combineEpics } from 'redux-observable';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { validateRequired, validateUrl } from '../../../formUtils/validators';
import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';
import { CREATE_AWARD, UPDATE_AWARD } from '../actions';
import { createAward, updateAward } from '../../../../data/user/awards/apiCalls';
import { mapAwardApiToUi, mapAwardFormToApi } from '../../../../data/user/awards/models';
import { awardCreateSuccess, awardUpdateSuccess } from '../../../../data/user/awards/actions';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../../../async';
import { cancelOnRouteChange, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

const awardsFormValidation = {
  'forms.awards.name': [
    value => validateRequired()(value),
  ],
  'forms.awards.date': [
    value => validateRequired()(value),
  ],
  'forms.awards.url': [
    value => validateUrl()(value),
  ],
};

const submitAwardFormSuccess = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.awards', 'awards'),
  switchMap((action) => {
    if (action.attrs.award) {
      return of(
        asyncStartAction(UPDATE_AWARD),
        actions.setErrors('forms.awards', { general: '' }),
        { type: UPDATE_AWARD, award: action.attrs.award, dispatch: action.attrs.dispatch },
      );
    }
    return of(
      asyncStartAction(CREATE_AWARD),
      actions.setErrors('forms.awards', { general: '' }),
      { type: CREATE_AWARD, award: null, dispatch: action.attrs.dispatch },
    );
  }),
);

export const createUpdateAwardEpic = (action$, state$) => {
  const form = () => state$.value.forms.awards;
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const currSportId = () => state$.value.ui.app.routes.currentSportId;
  const currSchoolId = () => state$.value.ui.app.routes.currentSchoolId;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    filter(action => ((action.type === UPDATE_AWARD) || (action.type === CREATE_AWARD))),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const formValues = {...form()};
      if (action.type === CREATE_AWARD) {
        const isCoachFlag = isCoach();
        formValues.coachId = isCoachFlag ? state$.value.data.cognito.uuid : null;
        return createAward(uuid(), token, mapAwardFormToApi(formValues), isCoachFlag).pipe(
          map(response => ({ action, success: true, award: mapAwardApiToUi(response), uuid: uuid() })),
          takeUntil(cancelOnRouteChange(action$, Routes.awards, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            actions: [
              asyncErrorAction(action.type, 'createAward', error),
              actions.setErrors('forms.awards', {
                general: 'There was a problem saving your award',
              }),
            ],
          })),
        );
      }
      return updateAward(
        uuid(),
        token,
        action.award.id,
        mapAwardFormToApi(formValues),
        isCoach(),
      ).pipe(
        map(response => ({ action, success: true, award: mapAwardApiToUi({...response, id: action.award.id}), uuid: uuid() })),
        takeUntil(cancelOnRouteChange(action$, Routes.awards, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          actions: [
            asyncErrorAction(action.type, 'updateAward', error),
            actions.setErrors('forms.awards', {
              general: 'There was a problem saving your award',
            }),
          ],
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        let successAction;
        if (results.action.type === CREATE_AWARD) {
          successAction = awardCreateSuccess(
            results.award,
            results.uuid,
          );
        } else {
          successAction = awardUpdateSuccess(
            results.award,
            results.uuid,
          );
        }
        return of(
          successAction,
          asyncFinishAction(results.action.type, 'createUpdateAward', 
          { 
            uuid: uuid(),
            sportId: currSportId(),
            schoolId: currSchoolId()
          }),
        );
      }
      if (results.actions) {
        return of(...results.actions);
      }
      return of(results.action);
    }),
  );
};

const createEpics = combineEpics(
  submitAwardFormSuccess,
  validationOnSubmitEpicFactory(awardsFormValidation, 'forms.awards', 'awards'),
  validationFailedEpicFactory('forms.awards', 'awards'),
  ...formValidationEpicsFactory(awardsFormValidation, 'awards'),
  createUpdateAwardEpic,
);

export default createEpics;
