import moment from 'moment';
import {formatPhone} from '../../../../utils';

export const coach = (response) =>{
    return {
        createdAt : moment(response.created_at) || '',
        coachEmail: response.email || '',
        coachId: response.id || '',
        coachName: response.name || '',
        coachPhone: response.phone || '',
        schoolTeamId: response.school_team_id || '',
        // teamTwitterLink: response.team_twitter_link || '',
        updatedAt: moment(response.updated_at) || '',
    }
}

export const personalInfo =(response) => {
    const profile = response.profile;
    const headCoach = response.schoolTeam.head_coach && coach(response.schoolTeam.head_coach)
    const currentSportId = response.schoolTeam.sport_id;
    const sportData = profile.user_sports.filter(userSports => userSports.sport_id === currentSportId)
    const sportIds = profile.user_sports.map(userSports => userSports.sport_id)
    const [{commits,offers}] = sportData;
    const rc = response.schoolTeam.recruiting_coordinator;
    const {email, phone,twitter,instagram,class_rank_num,class_rank_of,core_gpa,parent_email,parent_name,parent_phone,sat,act,ncaa_id_number} = profile;

    return  {
        playerEmail: email || '',
        playerPhone: phone && formatPhone(phone).split(" ").splice(1,2).join(".").replaceAll("-",".").replace(/[\(\)']+/g,'') || '',
        twitter: !!twitter && `@${twitter.replace(/^@*/g,'')}` || '',
        instagram: !!instagram && `@${instagram.replace(/^@*/g,'')}` || '',
        teamTwitterLink: response.schoolTeam && !!response.schoolTeam.twitter && `@${response.schoolTeam.twitter.replace(/^@*/g,'')}` || '' ,
        gpa: core_gpa || "",
        'sat/act': (sat !== null || act !== null) ? `${sat? sat: "-"}/${act? act: "-"}`: "",
        classRank: class_rank_num ? `${class_rank_num}/${class_rank_of? class_rank_of : '-'}`  : "" ,
        parentName: parent_name || "",
        parentEmail: parent_email || "",
        parentPhone: parent_phone && formatPhone(parent_phone).split(" ").splice(1,2).join(".").replaceAll("-",".").replace(/[\(\)']+/g,'') || "",
        headCoachName: headCoach && headCoach.coachName || "",
        headCoachEmail: headCoach && headCoach.coachEmail || "",
        headCoachPhone: (headCoach && headCoach.coachPhone) ? formatPhone(headCoach.coachPhone).split(" ").splice(1,2).join(".").replaceAll("-",".").replace(/[\(\)']+/g,'') : "",
        rcName: rc && rc.name || "",
        rcEmail: rc && rc.email || "",
        rcPhone: (rc && rc.phone ) ? formatPhone(rc.phone).split(" ").splice(1,2).join(".").replaceAll("-",".").replace(/[\(\)']+/g,'') : "",
        ncaaId: ncaa_id_number || "",
        sportsId: sportIds || [],
        committed: commits || "",
        offers: offers || "",
    }
}

export const athleteSchoolTeamInfo = (response) =>{
    const schoolDetail = response.schools;
    return {
        schoolName: schoolDetail.name || '',
        schoolLogo : schoolDetail.school_logo || '',
        sportId : response.sport_id,
        coachComment : response.user_team.coach_comment || '',
        schoolId: response.school_id || '',
        userTeam: team(response.user_team)
    }
}


const team = (response) =>{
    return{
        jerseyNumber: response.jersey_number || "",
        position: response.position || "",
        schoolTeamId: response.school_team_id || "",
        coachComment : response.coach_comment || '',
        userTeamId: response.id,
        invited: response.invited,
    }
}
