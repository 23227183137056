import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import ReactTooltip from 'react-tooltip';

const TwoToneButton = ({
                         iconName,
                         text,
                         onClick,
                         disable,
                         className,
                         ...props
                       }) => {
  const buttonClassNameLeft = text === 'Share Awards'
                              ? `TwoToneButton_Left ${className}_left ${props.disableButtons ? 'leftButtonDisabled' : ''}`
                              : `TwoToneButton_Left ${props.disableButtons ? 'leftButtonDisabled' : ''}`;

  const buttonClassNameRight = text === 'Share Awards'
                                ? `TwoToneButton_Right ${className}_right  ${props.disableButtons ? 'leftButtonDisabled' : ''}`
                                : `TwoToneButton_Right ${props.disableButtons ? 'leftButtonDisabled' : ''}`;

  return (
  <button disabled={disable ? true : false} onClick={onClick} className={`TwoToneButton ${className}`} {...props}>
    <div className={buttonClassNameLeft}>
      <Icon iconName={iconName}/>
    </div>
    <div className={buttonClassNameRight}>
      <div>{text}</div>
    </div>
    <ReactTooltip/>
  </button>
)};
TwoToneButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TwoToneButton;
