import { combineReducers } from 'redux';

import {
  formErrorReducer,
  formLoadingMsgReducer,
  formLoadingReducer,
  formPasswordHelp,
  cognitoChangePasswordError,
} from '../utils';
import { USER_GET_PROFILE_SUC } from '../../../actions/data/user';
import { EP_CHANGE_PW_FORM_INIT } from '../../../actions/ui/uxEditProfile';

const loadingMsgMoreCases = {};
loadingMsgMoreCases[USER_GET_PROFILE_SUC] = () => '';

const loadingMoreCases = {};
loadingMoreCases[USER_GET_PROFILE_SUC] = () => false;

const reducer = combineReducers({
  formError: formErrorReducer('forms.changePassword', EP_CHANGE_PW_FORM_INIT, cognitoChangePasswordError),
  loading: formLoadingReducer('forms.changePassword', true, loadingMoreCases),
  loadingMsg: formLoadingMsgReducer(
    'forms.changePassword',
    'Loading your account information',
    'Saving your new password',
    loadingMsgMoreCases,
  ),
  formPasswordHelp: formPasswordHelp('forms.changePassword.newPassword', EP_CHANGE_PW_FORM_INIT),
});

export default reducer;

