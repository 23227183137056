import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';

import { cognitoSignIn, cognitoSignInSuccess } from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import { validateRequired } from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const secondSignInValidation = {
  'forms.secondSignIn.password': [
    value => validateRequired()(value),
  ],
};

const secondSignInSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.secondSignIn;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.secondSignIn', 'secondSignIn'),
    switchMap(action => from(cognitoSignIn(
      action.attrs.username,
      form().password,
    )).pipe(
      map(awsResponse => ({
        success: true,
        awsResponse,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: formServerError('forms.secondSignIn', 'aws', error),
      })),
    )),
    switchMap(({
      success,
      awsResponse,
      action,
    }) => {
      if (success) {
        return of(
          formSubmitSuccess('forms.secondSignIn', {
            awsResponse,
          }),
          cognitoSignInSuccess(awsResponse),
        );
      }
      return of(action);
    }),
  );
};

export const secondSignInFormEpic = combineEpics(
  secondSignInSubmitEpic,
  validationOnSubmitEpicFactory(secondSignInValidation, 'forms.secondSignIn', 'secondSignIn'),
  validationFailedEpicFactory('forms.secondSignIn', 'secondSignIn'),
  ...formValidationEpicsFactory(secondSignInValidation, 'secondSignIn'),
);

export const SECOND_SIGNIN_FORM_INIT = 'SECOND_SIGNIN_FORM_INIT';
export const secondSignInFormInit = () => ({
  type: SECOND_SIGNIN_FORM_INIT,
});
