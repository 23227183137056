import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../components/UxCommon';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import CoachProfileForm from './CoachProfileForm.component';
import UpdatePhoto from '../../common/UpdatePhotoDialog/UpdatePhotoDialog.container';

class Profile extends PureComponent {
  static propTypes = {
    coachProfile: PropTypes.object,
    match: PropTypes.object,
    profilePhotoURL: PropTypes.string,
    isUploadPhotoDialogVisible: PropTypes.bool,
    saveCoach: PropTypes.func,
    showUploadPhotoDialog: PropTypes.func,
    hideUploadPhotoDialog: PropTypes.func,
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleCloseUpdatePhoto = () => {
    this.props.hideUploadPhotoDialog();
  }

  handleAddPhoto = () => {
    this.props.showUploadPhotoDialog();
  }

  formRef = React.createRef();

  render() {
    const {
      coachProfile,
      match, saveCoach,
      profilePhotoURL,
      isUploadPhotoDialogVisible,
    } = this.props;

    return (
      <div className="CoachProfile">
        {
          coachProfile &&
          (
            <div className="CoachAccount_Body">
              <div className="CoachAccount_LeadIn">
                <div className="CoachAccount_LeadInCrcl">
                  {
                    (profilePhotoURL && profilePhotoURL !== '') ?
                      <img src={profilePhotoURL} alt="coach" />
                      :
                      <Icon iconName="photo2" className="AthleteMedia_PhotoIcon" />
                  }
                </div>
                <div className="CoachAccount_LeadInBottom">
                  <div className="CoachAccount_LeadInTitle" >
                    <ButtonRect onClick={this.handleAddPhoto} >
                      Upload
                    </ButtonRect>
                  </div>
                </div>
              </div>
              <CoachProfileForm coachProfile={coachProfile} saveCoach={saveCoach} />
              {
                isUploadPhotoDialogVisible &&
                (
                  <div className="CoachWorld_EditPlayer_Backdrop">
                    <div className="CoachWorld_EditPlayer_Modal">
                      <UpdatePhoto
                        closeDialog={this.handleCloseUpdatePhoto}
                        match={match}
                        photoUrl={profilePhotoURL}
                        coachId={coachProfile.uuid}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

export default Profile;
