export const ReportTabSlugs = {
  summary: 'Score Card',
  strength: 'Strength Report',
  perf: 'Performance Report',
  hitting: 'Hitting Report',
  pitching: 'Pitching Report',
  stats: 'stats report'
  // physique: 'Physique Report',
  // awards: 'Awards',
};

export const VC_SCORE_SUBBANKS = ['ACCELERATION', 'AGILITY', 'EXPLOSIVEPERF'];

export default 'report.constants.js';
