import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ButtonRect} from "../../../../components/UxCommon";
import TextControl from '../../common/FormControls/TextControl';
import {
  checkName,
  checkPhone,
} from "../../../CodeSignUp/AccountPage/AccountPage.validation";
import {validateRequired} from "../../../../store/actions/ui/formUtils/validators";
import {formatPhone} from "../../../../utils";
import {checkUsername} from "../RecruitingCoordinatorModal/RecruitingModal.validation";


class HeadCoachModalComponent extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    schoolTeamId: PropTypes.string.isRequired,
    headCoach: PropTypes.any,
    updateHeadCoach: PropTypes.func.isRequired,
    deleteHeadCoach: PropTypes.func.isRequired,
    createHeadCoach: PropTypes.func.isRequired,
  }

  state = {
    errors: {},
  }

  onClickDelete = (e) => {
    const {deleteHeadCoach, headCoach, onCancelClick} = this.props;
    deleteHeadCoach(headCoach.id, headCoach.schoolTeamId);
    onCancelClick();
    e.preventDefault()
  }

  onPhoneBlur = type => (value) => {
    const phone = formatPhone(value);
    const errors = checkPhone(phone);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    if(errors.length){
      return value;
    }
    return phone;
  }

  onNameBlur = type => (value) => {
    const errors = checkName(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }

  onEmailBlur = type => (value) => {
    const errors = checkUsername(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        [type]: errors,
      }),
    });
    return value;
  }

  onClickSave = (e) => {
    const form = this.formRef.current;
    const phone = formatPhone(form.phone.value);
    const errors = {
      name: checkName(form.name.value),
      email: checkUsername(form.email.value),
      phone: checkPhone(phone),
    };

    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({errors});

    if (success) {
      const values = {};
      const {updateHeadCoach, headCoach, createHeadCoach, schoolTeamId, onCancelClick} = this.props;
      values.name = form.name.value;
      values.phone = form.phone.value;
      values.email = form.email.value;
      values.title = form.title.value;
      if (headCoach) {
        updateHeadCoach(headCoach.id, values);
      } else {
        values.schoolTeamId = schoolTeamId;
        createHeadCoach(values);
      }
      onCancelClick();
    }
    e.preventDefault();
  }

  formRef = React.createRef();

  render() {
    const {onCancelClick, headCoach} = this.props;
    const {errors} = this.state;

    return (
      <div className="ConfirmDialogWrapper">
        <div className="ConfirmDialog Roster-ConfirmDialog">
          <div className="AlbumDialod_DeleteAlbumDialog_Title headCoachTitle">
            {
              headCoach ? 'Edit Coach Information' : 'Add Coach Information'
            }
          </div>
          <div className="HeadCoachForm" style={{width: '100%'}}>
            <form ref={this.formRef} onSubmit={this.onClickSavePlayer} className="CoachAccount_Form">
              <TextControl
                className="CoachAccount_Ctrl HeadCoachCtrl"
                name="title"
                label="Title"
                placeholder="Title"
                defaultValue={headCoach ? headCoach.title : 'Head Coach'}
                errors={errors.title}
              />
              <TextControl
                className="CoachAccount_Ctrl HeadCoachCtrl"
                name="name"
                label="Name"
                placeholder="Name"
                myOnChange={this.onNameBlur('name')}
                myOnBlur={this.onNameBlur('name')}
                defaultValue={headCoach ? headCoach.name : ''}
                errors={errors.name}
              />
              <TextControl
                className="CoachAccount_Ctrl HeadCoachCtrl"
                name="email"
                label="Email Id"
                placeholder="Email"
                myOnChange={this.onEmailBlur('email')}
                myOnBlur={this.onEmailBlur('email')}
                defaultValue={headCoach ? headCoach.email : ''}
                errors={errors.email}
              />
              <TextControl
                className="CoachAccount_Ctrl HeadCoachCtrl"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                myOnChange={this.onPhoneBlur('phone')}
                myOnBlur={this.onPhoneBlur('phone')}
                defaultValue={headCoach ? headCoach.phone : ''}
                errors={errors.phone}
              />
            </form>
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={this.onClickSave}
          >
            Save
          </ButtonRect>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Cancel
          </ButtonRect>
          {
            headCoach && (
              <ButtonRect
                className="AlbumDialod_DeleteAlbumDialog_Btn"
                onClick={this.onClickDelete}
              >
                Delete
              </ButtonRect>
            )
          }
        </div>
      </div>
    )
  }
}

export default HeadCoachModalComponent;
