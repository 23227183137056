import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../components/UxEditProfile/Academic/AcademicInfo';
import { getAuthenticatedUserProfile, getProfile, getSelectedSport } from '../UxCommon/Utils';
import { accountFormInit } from '../../store/actions/ui/uxEditProfile';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { academicsFormInputs } from '../../store/actions/ui/uxEditProfile/utils';
import { metricSports } from '../../components/UxProfile/UxProfile.constants';

const checkMetricUnits = (state, ownProps) => {
  const selectedSport = getSelectedSport(state, ownProps.match);
  return selectedSport && metricSports.includes(selectedSport.sportId);
};

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const canEdit = canEditProfile && canEditProfile.canEdit;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  const profile = canEdit ? getProfile(state, ownProps.match) : getAuthenticatedUserProfile(state);
  return ({
    profile,
    isCoach,
    loading: state.ui.uxEditProfile.account.loading,
    loadingMsg: state.ui.uxEditProfile.account.loadingMsg,
    rrfForm: state.forms.forms.account,
    formGeneralError: state.ui.uxEditProfile.account.formError,
    formSubmitSuccess: state.ui.uxEditProfile.account.formSuccess,
    formNeedsInit: state.ui.uxEditProfile.account.formNeedsInit,
    inputs: academicsFormInputs,
    isMetricUnits: checkMetricUnits(state, ownProps),
  });
}

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    submit: (isMobile = false, isMetricUnits = false) => {
      dispatch(formSubmit('forms.account', { isMobile, isMetricUnits,emailRequired:false }));
    },
    formInit: () => {
      dispatch(actions.reset('forms.account'));
      dispatch(actions.merge('forms.account', stateProps.profile));
      dispatch(accountFormInit());
    },
    onNCAARegisteredChange: () => {
      dispatch(actions.toggle('forms.account.ncaaRegistered'));
    },
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
