import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PhotosVidoesPicker from '../SideBar/PhotosVideosPicker/PhotosVideosPicker';
import Albums from '../SideBar/Albums/Albums';
import { CloseButton, ButtonRect } from '../../../UxCommon';
import Tags from '../SideBar/Tags/Tags';

class PhotoFilterDialog extends PureComponent {
  static propTypes = {
    onPhotosClick: PropTypes.func.isRequired,
    onVideosClick: PropTypes.func.isRequired,
    onAllClick: PropTypes.func.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    albums: PropTypes.array.isRequired,
    onAlbumSelected: PropTypes.func.isRequired,
    onAlbumUnselected: PropTypes.func.isRequired,
    selectedAlbums: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    onTagSelected: PropTypes.func.isRequired,
    onTagUnselected: PropTypes.func.isRequired,
    selectedTagsFilters: PropTypes.array.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    onClearAllClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    tagsRelMedia: PropTypes.array,
  }

  noop = () => {}

  render() {
    const {
      onPhotosClick,
      onVideosClick,
      onAllClick,
      selectedCategory,
      albums,
      selectedAlbums,
      onAlbumSelected,
      onAlbumUnselected,
      isMyProfile,
      tags,
      tagsRelMedia,
      selectedTagsFilters,
      onTagSelected,
      onTagUnselected,
      onClearAllClick,
      onCloseClick,
    } = this.props;
    return (
      <div className="Photos_PhotoFilterDialog">
        <CloseButton size={10} onClick={onCloseClick} />
        <div className="Photos_PhotoFilterDialog_Title">
          {'Filter Photos & Videos'}
        </div>
        <PhotosVidoesPicker
          onPhotosClick={onPhotosClick}
          onVideosClick={onVideosClick}
          onAllClick={onAllClick}
          selectedCategory={selectedCategory}
        />
        <Albums
          onEditClick={this.noop}
          albums={albums}
          onAlbumSelected={onAlbumSelected}
          onAlbumUnselected={onAlbumUnselected}
          selectedAlbums={selectedAlbums}
          isMyProfile={isMyProfile}
        />
        <Tags
          onEditClick={this.noop}
          tags={tags}
          onTagSelected={onTagSelected}
          onTagUnselected={onTagUnselected}
          selectedTagsFilters={selectedTagsFilters}
          tagsRelMedia={tagsRelMedia}
        />
        <div className="Photos_PhotoFilterDialog_BtnsRow">
          <ButtonRect
            className="Photos_PhotoFilterDialog_Btn"
            onClick={onClearAllClick}
          >
            Clear All
          </ButtonRect>
          <ButtonRect
            className="Photos_PhotoFilterDialog_Btn primary"
            onClick={onCloseClick}
          >
            Close
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default PhotoFilterDialog;
