import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls, { sportsTables }  from '../../../../config/urls';

export const apiGetCombineTemplate = (templateId, token) => (
  ajax.getJSON(
    Urls.combineTemplates.get(templateId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiDeleteCombineTemplate = (templateId, token) => ajax.delete(
  Urls.combineTemplates.delete(templateId),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
);

export const apiCreateCombineTemplate = (data, token) => (
  ajax.post(
    Urls.combineTemplates.post(),
    JSON.stringify(data),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const apiUpdateCombineTemplate = (data, combineTemplateId, token) => (
  ajax.put(
    Urls.combineTemplates.put(combineTemplateId),
    JSON.stringify(data),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);



export const apiAddCombineTemplateStdTest = (combineTemplateId, stdTestCatId, token) => (
  ajax.post(
    Urls.combineTemplates.addTestCategory(combineTemplateId),
    stdTestCatId,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const apiDeleteCombineTemplateStdTest = (combineTemplateId, stdTestCatId, token) => (
  ajax.post(
    Urls.combineTemplates.deleteTestCategory(combineTemplateId),
    stdTestCatId,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);


export const apiAddCombineStdTest = (combineId, stdTestCatId, token) => (
  ajax.post(
    Urls.combineStandardTestCategories.addTestCategory(combineId),
    stdTestCatId,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const apiDeleteCombineStdTest = (combineId, stdTestCatId, token) => (
  ajax.post(
    Urls.combineStandardTestCategories.deleteTestCategory(combineId),
    stdTestCatId,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);



export const apiCombineTemplateDetails = (combineTemplateId, token) => (
  ajax.get(
    Urls.combineTemplates.combines(combineTemplateId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
  )
)
