import React from 'react';
import PropTypes from 'prop-types';

const Col = ({ children, style, className }) => (
  <div className={`col ${className || ''}`} style={style || {}}>
    {children}
  </div>
);
Col.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Col;
