/* eslint-disable react/jsx-curly-brace-presence */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Routes, Sports } from '../../../store/actions/ui/routes';
import LoadingIndicator from '../common/LoadingIndicator';
import Banner from '../common/Banner';
import SchoolSidebar from './SchoolSidebar.component';
import SchoolLogo from '../common/SchoolLogo';

class School extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    schoolsStyle: PropTypes.array.isRequired,
    school: PropTypes.object,
    getSchool: PropTypes.func.isRequired,
    teamList: PropTypes.array,
  }

  componentDidMount() {
    this.shouldUpdate();
    this.props.getSchool(this.props.match.params.schoolId);
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => { }

  // SAVE THIS AND NEXT LINE FOR FUTURE SEARCHES
  // See this https://github.com/Virtual-Combine-LLC/react-ui/wiki/Routing-Challenges%3F
  redirectToRosters = () => (
    <Redirect to={`/${Routes.coach}/${Routes.dashboard}/${Routes.rosters}`} />
  );

  titleCase = (title) => {
    const str = title.split(' ');
    return str.map(word => word[0].toUpperCase() + word.substr(1).toLowerCase()).join(' ');
  }

  render() {
    const {
      schoolsStyle,
      school,
      teamList,
      match,
    } = this.props;
    if (!school) {
      return (<LoadingIndicator
        loading
      />);
    }
    const sports = [];
    let schoolName;
    teamList.forEach((item) => {
      const sportName = this.titleCase(Sports[item.team.sportId]);
      if (!sports.find(s => s === sportName)) {
        sports.push(sportName);
      }
      schoolName = this.titleCase(item.team.school.name);
    });
    const { schoolId } = match.params;
    return (
      <div className="CoachDashboard">
        <Banner match={match}>
          <div className="CoachRoster_BannerChildren">
            <div className="CoachRoster_BannerLeft">
              <SchoolLogo className="CoachRoster_BannerTeamLogo" schoolStyles={schoolsStyle} />
              <div className="CoachRoster_BannerFullTitle">{school.name}</div>
            </div>
          </div>
        </Banner>
        <div className="CoachDashboard_BodyWrapper">
          <div className="CoachDashboard_Body visitor">
            <SchoolSidebar school={school} />
            <div className="CoachDashboard_BodyRight">
              <div className="CoachDashboardRosters">
                <div className="CoachDashboard_TeamsHeader">Sports</div>
                <div className="CoachDashboardRosters_Cards">
                  {
                    sports.map((sportSlug, index) => (
                      <Link
                        to={`/${Routes.school}/${schoolId}/${Routes.roster}/${sportSlug.toLowerCase()}`}
                        key={index}
                        className="CoachDashboardRosters_Card"
                      >
                        <div className="CoachDashboardRosters_CardSchoolName">
                          {schoolName}
                        </div>
                        <div className="CoachDashboardRosters_CardSportName">
                          {sportSlug}
                        </div>
                        <div className="CoachDashboardRosters_CardLink">
                          Go to Roster Page
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default School;
