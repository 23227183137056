import { combineEpics } from 'redux-observable';
import reducers from './coachWorld.reducers';
import coachWorldEpics from './coachWorld.epics';
import photoEpics from './coachWorldPhoto.epics';

const epics = combineEpics(coachWorldEpics, photoEpics);

export { reducers, epics };
export * from './coachWorld.actions.js';
export * from './coachWorld.selectors.js';
export * from './coachWorld.utils.js';
