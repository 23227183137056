import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {combineEpics} from 'redux-observable';
import {from, of} from 'rxjs';

import {profileCreate, profileCreateSuccess} from '../../data/user';
import {mapUserApiToUi, mapUserUiToApi} from '../../data/user/profile/models';

import {cognitoSignup, cognitoSignUpSuccess} from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateConfirmPassword,
  validateConfirmUsername,
  validateEmail,
  validateLength,
  validateName,
  validateRequired,
} from '../formUtils/validators';

import {formServerError, formSubmitSuccess} from '../formUtils/actions';
import {ajaxErrorHandlerEpicFragment} from '../ajaxErrorHandlers';
import {checkEmailExistsApi} from "../../../../modules/CodeSignUp/+store/accountPage/accountPage.api";
import {manualCancelApiCallFragment} from "../uxProfile/utils";

export const JOIN_NOW_FORM_INIT = 'JOIN_NOW_FORM_INIT';
export const joinNowFormInit = () => ({
  type: JOIN_NOW_FORM_INIT,
});

const joinFormValidation = {
  'forms.joinNow.first': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateName()(value),
  ],
  'forms.joinNow.last': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateName()(value),
  ],
  'forms.joinNow.username': [
    value => validateLength(1)(value),
    value => validateEmail()(value),
    value => validateRequired()(value),
  ],
  'forms.joinNow.confirmUsername': [
    (value, form) => validateConfirmUsername()(form.username, value),
  ],
  'forms.joinNow.password': [
    value => validateLength(6)(value),
    value => validateRequired()(value),
  ],
  'forms.joinNow.confirmPassword': [
    (value, form) => validateConfirmPassword()(form.password, value),
  ],
};

const joinFormSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.joinNow;
  const username = () => form().username.toLowerCase();
  return action$.pipe(
    isPendingIsValid(state$, 'forms.joinNow', 'joinNow'),
    filter(action => !!action.attrs),
    switchMap(action => (
      checkEmailExistsApi(username())
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteBulkTestCombine',
        ))
    )),
    switchMap(action => {
      if(action.success){
        return of({
          success: false,
          action: formServerError('forms.joinNow', 'aws', 'Another user with that email address already exists', {username: form().username})
        })
      }
      return from(cognitoSignup({
        username: username(),
        password: form().password,
      }, action.action.data.action.attrs.isMobile)).pipe(
        map(awsResponse => ({
          success: true,
          awsResponse,
          outer: action.action.data.action,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: formServerError('forms.joinNow', 'aws', error, {username: form().username}),
        })),
      )
    }),
    switchMap((results) => {
      if (results.success) {
        return profileCreate(mapUserUiToApi(
          results.awsResponse.userSub,
          form(),
        )).pipe(
          map(usResponse => ({
            success: true,
            awsResponse: results.awsResponse,
            usResponse,
            outer: results.outer,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.joinNow', 'us', error),
          })),
        );
      }
      return of(results);
    }),
    switchMap(({
                 success,
                 awsResponse,
                 usResponse,
                 action,
               }) => {
      if (success) {
        return of(
          formSubmitSuccess('forms.joinNow', {
            awsResponse,
            usResponse,
            email: form().email,
            phone: form().phone,
            password: form().password,
            username: username(),
          }),
          cognitoSignUpSuccess(awsResponse),
          profileCreateSuccess(mapUserApiToUi(usResponse)),
        );
      }
      return of(action);
    }),
  );
};

export const joinFormEpic = combineEpics(
  joinFormSubmitEpic,
  usernameOnChangeEpic('forms.joinNow.username', state => state.forms.joinNow.username),
  validationOnSubmitEpicFactory(joinFormValidation, 'forms.joinNow', 'joinNow'),
  validationFailedEpicFactory('forms.joinNow', 'joinNow'),
  ...formValidationEpicsFactory(joinFormValidation, 'joinNow'),
);
