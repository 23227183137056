import { asyncStartAction } from '../../../../store/actions/ui/async';

export const VERIFY_EMAIL_PHONE = 'coachAccount.verifyEmailPhone';
export const coachVerifyEmailPhone = (isTelephone, code) => (dispatch) => {
  dispatch(asyncStartAction(VERIFY_EMAIL_PHONE));
  dispatch({
    type: VERIFY_EMAIL_PHONE,
    isTelephone,
    code,
  });
};
