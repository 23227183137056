import moment from 'moment';

const sidebarSearchForm = {
  search: '',
};

const expandedPhotoForm = {
  caption: '',
  album: '',
};

const albumDialogForm = {
  newAlbumName: '',
  selectedAlbum: '',
  newTagName: '',
  albumName: '',
};

const tagDialogForm = {
  newTagName: '',
  updatedTagname: '',
  selectedTag: '',
};

const awardsForm = {
  name: '',
  type: '',
  date: moment(),
  url: '',
  desc: '',
  display: true,
};

const externalVideoForm = {
  url: '',
};


const externalProfileForm = {
  url: '',
  company: '',
  logo: ''
};

const forms = {
  sidebarSearch: sidebarSearchForm,
  expandedPhoto: expandedPhotoForm,
  albumDialog: albumDialogForm,
  tagDialog: tagDialogForm,
  awards: awardsForm,
  externalVideo: externalVideoForm,
  externalProfile:externalProfileForm
};

export default forms;
