import moment from 'moment';

export const mapAwardFormToApi = formValues => ({
  name: formValues.name,
  url: formValues.url,
  description: formValues.desc,
  date: formValues.date.format('YYYY-MM-DD'),
  display: !!formValues.display,
  award_type: formValues.type,
  coach_id: formValues.coachId,
});

class Award {
  constructor(response) {
    if (response) {
      this.id = response.id;
      this.name = response.name;
      this.url = response.url;
      this.techPartner = response.tech_partner ? response.tech_partner : '';
      this.desc = response.description && response.description.replace(/\s-\s?/i , '  |  ');
      if (response.display === undefined) {
        this.display = true;
      } else {
        this.display = response.display;
      }
      this.type = response.award_type;
      this.ownerId = response.owner_id;
      if (!response.favorite && (!response.sports || !response.sports.length)) {
        this.favorites = null;
      } else {
        this.favorites = {};
        if (response.favorite) {
          this.favorites[0] = true;
        }
        if (response.sports) {
          response.sports.forEach((sport) => {
            this.favorites[sport.sport_id] = true;
          });
        }
      }
      this.date = response.award_type === 'combine' && !response.date ? moment(response.created_at) : moment(response.date);
      this.createdAt = moment(response.created_at);
      this.updatedAt = moment(response.updated_at);
      this.endorsementCount = response.endorsement_count ? response.endorsement_count.count : 0;
      this.endorsedBy = response.endorsed_by && response.endorsed_by.map(e => e.endorsed_by);
      this.coachId = response.coach_id;
      this.awardType = response.award_type;
      this.combineId = response.combine_id;
      this.isVcAward = response.is_vc_award;
      this.schoolId = !!response.combine ? response.combine.school_uuid : '';
    } else {
      this.id = 0;
      this.name = '';
      this.url = '';
      this.desc = '';
      this.date = moment();
      this.display = true;
      this.type = '';
      this.ownerId = '';
      this.favorites = null;
      this.createdAt = moment();
      this.endorsementCount = 0;
      this.endorsedBy = [];
      this.updatedAt = moment();
      this.isVcAward= false;
    }
  }

  clone() {
    const award = new Award();
    award.id = this.id;
    award.name = this.name;
    award.url = this.url;
    award.desc = this.desc;
    award.date = this.date;
    award.display = this.display;
    award.type = this.type;
    award.ownerId = this.ownerId;
    award.favorites = this.favorites ? Object.assign({}, this.favorites) : null;
    award.createdAt = this.createdAt;
    award.updatedAt = this.updatedAt;
    award.endorsementCount = this.endorsementCount;
    award.endorsedBy = [...this.endorsedBy];
    award.techPartner = this.techPartner
    award.schoolId = this.schoolId
    return award;
  }
}

export const mapAwardApiToUi = response => (
  new Award(response)
);

export const mapAwardsApiToUi = response => (
  response.map(mapAwardApiToUi)
);

export const mapEndorsementApiToUi = (response, authorizedUuid, award, isEndorsing) => {
  const newAward = award.clone();
  if (isEndorsing) {
    newAward.endorsedBy.push(authorizedUuid);
    newAward.endorsementCount += 1;
    return newAward;
  }
  newAward.endorsedBy = newAward.endorsedBy.filter(i => i !== authorizedUuid);
  newAward.endorsementCount -= 1;
  return newAward;
};
