import { combineReducers } from 'redux';
import reducers from './photosVideos';
import expandedDialog from './expandedDialog';
import albumDialog from './albumDialog';
import selectAlbumDialog from './selectAlbumDialog';
import multiDelete from './multiDelete';
import tagDialog from './tagDialog';
import externalVideo from './externalVideo';
import moveAlbumDialog from './moveAlbumDialog';

const reducer = combineReducers({
  ...reducers,
  expandedDialog,
  albumDialog,
  selectAlbumDialog,
  moveAlbumDialog,
  multiDelete,
  tagDialog,
  externalVideo,
});

export default reducer;
