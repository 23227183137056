import { connect } from 'react-redux';
import Component from './UpdateSchoolImagesDialog.component';
import { updateSchoolStyleImages } from '../../+store/coachWorld/coachWorld.actions';

const mapStateToProps = state => ({
  isDialogOpen: state.modules.coachWorld.coachWorld.isUploadPhotoDialogVisible,
  loadingMsg: state.modules.coachWorld.coachWorld.photoUploadingMsg,
});

const mapDispatchToProps = dispatch => ({
  updateImage: (schoolStyle, isBackgroundImage) => updateSchoolStyleImages(
    dispatch,
    schoolStyle,
    isBackgroundImage,
  ),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
