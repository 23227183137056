import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  activateProfileMembershipURL,
  SportIds,
} from '../../../../../../../store/actions/ui/routes';
import { useSelector } from 'react-redux';
import { getSchoolStyles } from '../../../../../../../modules/CoachWorld/+store';

const formatEmail = (email) => {
  const emailArr = email.split('@');
  const longerText = '***********************************************';
  const firstPart =
    emailArr[0].substring(0, 3) +
    longerText.substring(0, emailArr[0].length - 3);
  const secondPart =
    emailArr[1].substring(0, 3) +
    longerText.substring(0, emailArr[1].length - 3);
  return `${firstPart}@${secondPart}`;
};

const ProfileActivationModalForm = ({ profile, onClose }) => {
  const inputRef = useRef(null);
  const [validation, setValidationMessage] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const { sport, schoolId, uuid } = useParams();
  let activatingSchool = null;

  if(!schoolId){
    const enrolledSchool = profile && profile.enrolledSchool;
    activatingSchool = enrolledSchool.find(schools => {
      if(schools.user_school_team.school.is_paid != 0 && schools.user_school_team.school.enable_nl === 1){
        return schools.user_school_team.school.name;
      }
    })
  }

  const history = useHistory();
  const schoolStyle = useSelector((state) =>
    state.modules.coachWorld.coachWorld.schoolStyle.find(
      (school) => school.id === schoolId,
    ),
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputRef.current.value === profile.parentsEmail) {
      schoolId ?
      history.push(
        activateProfileMembershipURL(uuid, SportIds[sport], schoolId),
      )
      :
      history.push(
        activateProfileMembershipURL(uuid, SportIds[sport], activatingSchool.user_school_team.school_id),
      )
      ;
      onClose();
    } else {
      setValidationMessage('Please provide the associated parent email address!');
    }
  };

  if(!profile){
    return '';
  }

  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: 15,
          textTransform: 'none !important',
        }}>{`You have been invited to Activate your membership with ${
        schoolId && schoolStyle ? schoolStyle.name : (activatingSchool ? activatingSchool.user_school_team.school.name : '')
      }.
              If you already have a membership with another organization, you can merge all of your data in one profile.
              Please enter your email below.`}</div>

      <div style={{ marginBottom: 5 }}>
        {`Corresponding Parent Membership Email`}
        <sup style={{ color: 'rgb(237, 70, 47)' }}>*</sup>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex' }}
        className={'ProfileActivationForm'}>
        <input
          ref={inputRef}
          id="activationEmail"
          placeholder={formatEmail(profile.parentsEmail)}
          style={{ width: '100%', fontFamily: 'sans-serif' }}
        />
        <button
          className="UxCommon_ButtonRect CoachSignOutModal_Btn primary"
          type="button"
          onClick={handleSubmit}>
          Submit
        </button>
      </form>
      <span
        style={{
          color: 'rgb(237, 70, 47)',
        }}>
        {validation}
      </span>
    </React.Fragment>
  );
};

export default ProfileActivationModalForm;
