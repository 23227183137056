import { connect } from 'react-redux';
import Component from '../../components/UxProfile/TopBanner/TopBanner';
import { closeTopBannerMsg } from '../../store/actions/ui/uxProfile';

const mapStateToProps = state => ({
  bannerComponent: state.ui.uxProfile.banners.topBannerComponent,
  bannerMsgs: state.ui.uxProfile.banners.topBannerMsgs,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onMsgClose: (id) => {
      dispatch(closeTopBannerMsg(id));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
