import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

const CloseCombineModal = ({ onCloseCombine, onCancel, combine }) => (
  <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <CloseButton size={15} onClick={onCancel} />
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">Are you sure you want to close { combine ? `  ${combine.name}  ` : ' this '} combine?</div>
        <div className="CloseCombineModal_Text2">
          {"Once the combine is closed, you will no longer be able to add or edit Athlete's test results."}
        </div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn primary"
          type="button"
          onClick={onCloseCombine}
        >
          Close Combine
        </ButtonRect>
        <ButtonRect
          className="CloseCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
);
CloseCombineModal.propTypes = {
  onCloseCombine: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  combine: PropTypes.object,
};

export default CloseCombineModal;
