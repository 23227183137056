import { combineEpics } from 'redux-observable';

import { validateRequired, validateUrl  } from '../../../formUtils/validators';


import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {asyncErrorAction, asyncFinishAction, asyncStartAction} from "../../../async";
import {CREATE_EXTERNAL_PROFILE, UPDATE_EXTERNAL_PROFILE} from "../actions";
import {actions} from "react-redux-form";
import {getTokenFragment} from "../../utils";

import {createExternalProfile, updateExternalProfile} from '../../../../data/user/externalProfile';
import {cancelOnRouteChange} from "../../utils";

import { Routes } from '../../../routes';

import {ajaxErrorHandlerEpicFragment} from "../../../ajaxErrorHandlers";
import {of} from "rxjs";
import {
  externalProfileCreateSuccess,
  externalProfileSuccess,
  externalProfileUpdateSuccess
} from "../../../../data/user/externalProfile/actions";
import {formSubmitFailed, formSubmitPending} from "../../../formUtils";
// import { actions } from 'react-redux-form';

const externalProfileValidation = {
  'forms.externalProfile.company': [
    value => validateRequired()(value)
  ],
  'forms.externalProfile.url':[
    value => validateRequired()(value),
    value => validateUrl()(value),
  ]

};



const submitExternalProfileFormSuccess = ( action$, state$ )=> action$.pipe(
  isPendingIsValid(state$, 'forms.externalProfile', 'externalProfile'),
  switchMap( ( action ) =>  {
    if (action.attrs)
    {
      if (action.attrs.externalProfile) {
        return of(
          asyncStartAction(UPDATE_EXTERNAL_PROFILE),
          actions.setErrors('forms.externalProfile', { general: '' }),
          { type: UPDATE_EXTERNAL_PROFILE, externalProfile: action.attrs.externalProfile, dispatch: action.attrs.dispatch },
        );
      }

      return of (
        asyncStartAction( CREATE_EXTERNAL_PROFILE ),
        actions.setErrors('forms.externalProfile', { general: '' }),
        { type:  CREATE_EXTERNAL_PROFILE, externalProfile: null,dispatch:action.attrs.dispatch },
      );
    }

  }),
);


const createExternalProfileEpic = ( action$, state$ ) => {
  const form = () => state$.value.forms.externalProfile;

  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };

  const canEditProfile = () =>   state$.value.ui.app.context.canEditProfile;

  //get current user id

  const uuid = () => {
    const canEditObj = canEditProfile();
    return  isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  }

  return action$.pipe(
    //filter operator -> emit only the source which satisfy the condition
    filter(  action  => ( (action.type === CREATE_EXTERNAL_PROFILE || action.type ===UPDATE_EXTERNAL_PROFILE ))),
    getTokenFragment(),

    switchMap( ( { action, token }) => {
      const formValues = {...form() };
      formValues.sportId =  state$.value.ui.app.routes.currentSportId;
      if ( action.type === CREATE_EXTERNAL_PROFILE )
      {

        return createExternalProfile(
          uuid(),
          token,
          ( {
            company: formValues.company,
            url: formValues.url,
            sport_id: formValues.sportId,
          })
        )
          .pipe(
            map( response => ( {
                action,
                success: true,
                externalProfile: response,
                uuid: uuid()
              })
            ),
            takeUntil( cancelOnRouteChange( action$, Routes.externalProfile, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError( error => of ({
              success:false,
              actions: [
                asyncErrorAction(action.type, 'createExternalProfile', error),
                actions.setErrors('forms.externalProfile', {
                  general: 'There was a problem saving your profile',
                }),
              ]
            }) )


          );
      }

      return updateExternalProfile(
        uuid(), token, action.externalProfile.id,
        ( {
          company: formValues.company,
          url: formValues.url,
          sport_id: formValues.sportId,
        })
      ).pipe(
        map( response => ( { action, success: true , externalProfile:response , uuid: uuid() })),
        takeUntil( cancelOnRouteChange( action$, Routes.externalProfile, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError( error => of ({
          success:false,
          actions: [
            asyncErrorAction(action.type, 'updateExternalProfile', error),
            actions.setErrors('forms.externalProfile', {
              general: 'There was a problem saving your profile',
            }),
          ]
        }) )
      )

    } ), //end of switch map

    switchMap( (results) => {
      if (results.success )
      {
        let successAction;

        if (results.action.type === CREATE_EXTERNAL_PROFILE) {
          successAction = externalProfileCreateSuccess(
            results.externalProfile,
            results.uuid,
          );
        } else {
          successAction  = externalProfileUpdateSuccess(
            results.externalProfile,
            results.uuid,
          );
        }

        return of(
          successAction,
          asyncFinishAction(results.action.type, 'createUpdateExternalProfile', { uuid: uuid(), sportId:state$.value.ui.app.routes.currentSportId, schoolId: state$.value.ui.app.routes.currentSchoolId }),
        );

      }
      //if duplicate profile
      if (!results.status)
      {
        if(results.action.error && results.action.error.status && results.action.error.status === 406)
        {

          return of(
            actions.setErrors('forms.externalProfile',  { general: 'Url is already taken!' }),
          );

        }

      }

      if (results.actions) {
        return of(...results.actions);
      }
      return of(results.action);
    })

  )

}

const createEpics = combineEpics(
  submitExternalProfileFormSuccess,
  validationOnSubmitEpicFactory( externalProfileValidation, 'forms.externalProfile', 'externalProfile'),
  validationFailedEpicFactory('forms.externalProfile', 'externalProfile'),
  ...formValidationEpicsFactory(externalProfileValidation, 'externalProfile'),
  createExternalProfileEpic
  )

export default createEpics;
