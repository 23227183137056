import React from "react";
import {Form} from "react-redux-form";
import {PaymentRequestButtonElement} from "@stripe/react-stripe-js";

const CheckoutOptionsComponent = ({setShowCreditCard, setShowPaymentOptions, paymentRequest}) => {
  return (
    <div className="container welcome-first PaymentOptions">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Form
            className="welcome-form"
            model="forms.joinNow"
          >
            <h1> Select a Payment Method</h1>
            <div className="d-flex flex-column button-wrapper">
              <PaymentRequestButtonElement options={{paymentRequest}}/>

              <div className="row justify-content-center">
                <span className="font-weight-bold line-partition">Or</span>
              </div>
              <button
                onClick={() => {
                  setShowCreditCard(true)
                  setShowPaymentOptions(false)
                }}
                id="forms.joinNow.submit"
                className="welcome-btn"
                type="submit">
                Credit Card
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CheckoutOptionsComponent;
