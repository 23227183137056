import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SportLink from '../../Common/SportLink/SportLink';
import { Routes } from '../../../../store/actions/ui/routes';

class SportNav extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    userSports: PropTypes.array.isRequired,
    selectedUserSport: PropTypes.object,
    match: PropTypes.object.isRequired,
    showAddSport: PropTypes.bool.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      uuid,
      userSports,
      selectedUserSport,
      match,
      showAddSport,
    } = this.props;
    const { schoolId } = match.params;
    return (
      <div className="UxProfile_PP_SportNav">
        {
          userSports.map(userSport => (
            <SportLink
              key={userSport.sportId}
              uuid={match.params.uuid || uuid}
              sportId={userSport.sportId}
              selected={!!selectedUserSport && (selectedUserSport.sportId === userSport.sportId)}
              currentPage={match.params.page}
              schoolId = {match.params.schoolId}
            />
          ))
        }
        {
          (showAddSport) &&
            <Link
              to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Routes.addSport}${schoolId ? `/${schoolId}` : ''}`}
              className={[
                'UxProfile_PP_SportNav_AddSport',
                match.params.sport === Routes.addSport ? 'selected' : '',
              ].join(' ')}
            >
              <div>
                <svg viewBox="0 0 24 24">
                  <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </svg>
              </div>
              {/* <div>
                Add a Sport
              </div> */}
            </Link>
        }
      </div>
    );
  }
}

export default SportNav;
