import { connect } from 'react-redux';
import Component from './Dashboard.component';
import { selectFilteredRosters, selectLoadingMsg } from '../+store';
import { cancelApiCalls } from '../../../store/actions/ui/uxProfile/utils';
import { getOrgAffSports } from '../../../store/actions/data/sport';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const loading = !!loadingMsg;
  const { profiles } = state.data.user;
  const schools = profiles
    && state.data.user.profiles[state.data.cognito.uuid]
    && state.data.user.profiles[state.data.cognito.uuid].schools;
  const profile =  profiles && state.data.user.profiles[state.data.cognito.uuid];
  const { schoolId } = ownProps.match.params;

  const combineUrl = new RegExp('^/coach/dashboard/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/awards/combine/([0-9]+)');
  const isCombineAwardUrlFound = combineUrl.exec(ownProps.location.pathname);
  let combineId;
  if ( isCombineAwardUrlFound ) {
   combineId = parseInt(isCombineAwardUrlFound[2])
  }
  let coachCode;
  let athleteCode;
  let combineAward;
  const schoolAwards = state.modules.coachWorld.award.awards[schoolId]  ;
 if ( schoolAwards && combineId ) {
   combineAward =  schoolAwards[combineId];
 }


  if (schools && !!schools.length) {
    const foundSchool = schools.find(school => school.school_id === schoolId);
    if (foundSchool) {
      coachCode = foundSchool.school.coach_code;
      athleteCode = foundSchool.school.athlete_code;
    }
  }
  return {
    loading,
    loadingMsg,
    coachCode,
    athleteCode,
    teams: state.modules.coachWorld.coachWorld.myTeams,
    rosterTeams: selectFilteredRosters(state, ownProps),
    sports: state.data.sport.sports,
    schoolStyles: state.modules.coachWorld.coachWorld.schoolStyle,
    profile,
    combineAward,
    orgSportsAff: state.data.sport.sportsOrgAff || {},
    rosterTeamLoading: state.modules.coachWorld.coachWorld.loadingMsg,
  };
};

const mapDispatchToProps = dispatch => ({
  cancelApiCalls: () => {
    dispatch(cancelApiCalls());
  },
  getOrgAffSports: (team, schoolStyle, sports) => {
    dispatch(getOrgAffSports(team, schoolStyle, sports));
  }
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
