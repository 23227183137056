import top100UsaLogo from "../../../../assets/images/top_100_sports.png";
import {SubbankCodes, SubbankTooltip} from "../../+store";
import ReactTooltip from "react-tooltip";
import React from "react";
import dk_logo from "../../../../assets/images/logos/dk_logo.png";
import rapsodo_logo from "../../../../assets/images/logos/rapsodo_logo.png";
import hit_trax_logo from "../../../../assets/images/logos/hit_trax_logo.png";
import trackman_tm_logo from "../../../../assets/images/logos/trackman_tm_logo.png";

const CombineHeaderLogo =  ( props ) => {
  const { toolTip, logo, renderImage,className } = props;
 return (
   <div className={`iconWrap ${className && className.includes('combine-tm-logo') ? 'trackManIconWrap': ''}`}>
     {renderImage ?
       <img  src={logo} data-tip={toolTip} data-class={"ToolTip_Index"} data-place={"left"} className={[
        'Top_100_Sport_Logo_Combine', className
       ].join(' ')}/>
     :  <i style ={{fontSize: "15px"}} data-effect="solid" data-tip={toolTip}
          data-place={"left"} data-class={"ToolTip_Index"}
           className={[
             className
           ].join(' ')} />
     }
     <ReactTooltip />
   </div>
 )
}

export default  React.memo(CombineHeaderLogo);



export const renderCategoryLogo = (subbankCode, testCode = null) => {
  let className, logo, toolTip;
  let renderImage = true;
  switch (subbankCode) {
    case SubbankCodes.BASEBALLHITTINGBLAST:
      className = "icon-blast-logo-circle-icon blast-color";
      toolTip =SubbankTooltip.BASEBALLHITTINGBLAST;
      renderImage = false;
      break;
    case SubbankCodes.COACHEVAL:
      if (testCode === 'TOP100INDEX'){
        toolTip =SubbankTooltip.Top100SportLogo;
        logo =  top100UsaLogo
        break;
      } else {
        return '';
      }
    case SubbankCodes.DKSBASEBALLPITCHING:
    case SubbankCodes.DIAMONDKINETICSHITTING:
      toolTip =SubbankTooltip.DK;
      logo =  dk_logo
      break;
    case SubbankCodes.RAPSODOHITTING:
    case SubbankCodes.RAPSODOSOFTBALLPITCHING:
    case SubbankCodes.RAPSODOBASEBALLPITCHING:
      toolTip =SubbankTooltip.RAPSODO;
      logo =  rapsodo_logo
      break;
    case SubbankCodes.TRACKMANHITTING:
    case SubbankCodes.TRACKMANPITCHING:
      toolTip =SubbankTooltip.TRACKMAN;
      className = "icon-tm-icon combine-tm-logo";
      renderImage = false;
      break;
    case SubbankCodes.PORPLAYPITCHING:
      className = "icon-proplayai-icon combine-pro-play-logo";
      renderImage = false;
      break;
    case SubbankCodes.HITTRAX:
      toolTip =SubbankTooltip.HITTRAX;
      className = "ht_combine";
      logo =  hit_trax_logo
      break;
    default:
      return '';

  }
  return (
    <CombineHeaderLogo
      renderImage={renderImage}
      className = {className}
      logo = {logo}
      toolTip ={toolTip}
    />
  );
}
