import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Routes } from '../../../../store/actions/ui/routes';
import logo from '../../../../assets/images/logo.png';
import LoadingSpinner from '../../../Common/LoadingSpinner/LoadingSpinner';
import NavLink from '../NavLink/NavLink';
import { MarketingUrl } from './../../../../config/env-urls';
import { Logos } from '../../../../store/actions/ui/schoolLogo';

class WelcomeHeader extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    schoolStyle: PropTypes.any,
    latestSchoolLogo: PropTypes.any,
    latestSchool: PropTypes.any,
    profile: PropTypes.any,
  };

  isStepZero = () => this.props.match.params.page === undefined;
  isStepFirst = () => this.props.match.params.page === Routes.signup;
  isStepConfirmAccount = () =>
    this.props.match.params.page === Routes.confirmAccount;
  isStepLogin = () => this.props.match.params.page === Routes.login;
  isStepForgot = () => this.props.match.params.page === Routes.forgot;
  isStepMergeLogin = () => this.props.match.params.page === Routes.mergeLogin;
  isStepResetPassword = () =>
    this.props.match.params.page === Routes.resetPassword;
  isStepSecondSignIn = () =>
    this.props.match.params.page === Routes.secondSignIn;
  isStepPMA = () => {
    const { match } = this.props;
    return match.params.page === Routes.pma;
  };

  isStepInvited = () => {
    const { match } = this.props;
    return match.params.page === Routes.invited;
  };

  isStepMergeAuth = () => {
    const { match } = this.props;
    return (
      match.params.page === Routes.mergeLoginAuth ||
      match.params.page === Routes.mergeLoginAuthForgotPassword ||
      match.params.page === Routes.mergeLoginAuthResetPassword ||
      match.params.page === Routes.mergeAnotherAccountAuth
    );
  };

  logoClass = () => {
    const { schoolStyle, latestSchoolLogo, latestSchool } = this.props;

    if (this.isStepInvited) {
      return '';
    }

    if (this.isStepMergeAuth() || latestSchoolLogo || this.isStepPMA()) {
      return 'small';
    }

    switch (this.props.match.params.page) {
      case Routes.signup:
        return 'small';
      case Routes.login:
      case Routes.forgot:
      case Routes.resetPassword:
        return 'medium';
      default:
        return '';
    }
  };

  renderLogo = () => {
    const { schoolStyle, latestSchoolLogo, latestSchool, match } = this.props;
    let latestLogo = '';
    let schoolStyleLogo = '';
    if (schoolStyle) {
      schoolStyleLogo = schoolStyle.paidInviteSchoolLogo;
    }
    if (this.isStepMergeAuth() && latestSchool) {
      latestLogo = latestSchool.school.paid_invite_school_logo;
    }

    if (latestSchoolLogo) {
      latestLogo = latestSchoolLogo;
    }

    if (match.params.schoolId && match.params.schoolId !== 'undefined') {
      return (
        <img
          className={['fast-pitch-logo-img', this.logoClass()].join(' ')}
          src={schoolStyleLogo}
          alt={schoolStyle ? schoolStyle.name : ''}
        />
      );
    }

    if (latestLogo) {
      return (
        <img
          className={['fast-pitch-logo-img', this.logoClass()].join(' ')}
          src={latestLogo}
          alt="Virtual Combine Home"
        />
      );
    }

    return (
      <img
        className={['welcome-logo-img', this.logoClass()].join(' ')}
        src={logo}
        alt="Virtual Combine Home"
      />
    );
  };

  render = () => {
    const { match } = this.props;

    return (
      <React.Fragment>
        <LoadingSpinner
          loading={this.props.loading}
          loadingMsg={this.props.loadingMsg}
        />
        <div className="welcome-bg-pic" />
        <div
          className={[
            'welcome-bg-tint',
            this.isStepZero() ? 'step-zero' : '',
          ].join(' ')}
        />
        <div className="welcome-fg">
          {(this.isStepLogin() || this.isStepFirst()) && (
            <NavLink
              to={`/${Routes.welcome}`}
              icon="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
              text="Go Back"
            />
          )}
          {this.isStepForgot() && (
            <NavLink
              to={`/${Routes.welcome}/${Routes.login}`}
              icon="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
              text="Go Back"
            />
          )}
          {this.isStepConfirmAccount() && (
            <NavLink
              to={`/${Routes.welcome}/${Routes.login}`}
              icon="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
              text="Go Back"
            />
          )}
          {this.isStepSecondSignIn() && (
            <NavLink
              to={`/${Routes.welcome}/${Routes.login}`}
              icon="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
              text="Go Back"
            />
          )}
          {this.isStepResetPassword() && (
            <NavLink
              to={`/${Routes.welcome}/${Routes.forgot}`}
              icon="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
              text="Go Back"
            />
          )}
          <div className="d-flex flex-column" style={{ height: '100%' }}>
            <div
              className={[
                'welcome-logo-top',
                this.isStepZero() && !match.params.schoolId ? 'step-zero' : '',
              ].join(' ')}
            />
            {!this.isStepLogin() && (
              <div className="welcome-logo">
                <a href={MarketingUrl} title="Virtual Combine Home">
                  {this.renderLogo()}
                </a>
              </div>
            )}
            <div
              className={[
                'welcome-logo-bottom',
                this.isStepZero() ? 'step-zero' : '',
              ].join(' ')}
            />
            {!this.isStepLogin() && (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <hr
                      className={[
                        'welcome-logo-hr',
                        this.isStepZero() ? 'step-zero' : '',
                      ].join(' ')}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default WelcomeHeader;
