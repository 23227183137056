import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { actions } from 'react-redux-form';

import {
  cognitoVerifyEmail,
  cognitoVerifyEmailResend,
  cognitoVerifyEmailSuccess,
  cognitoVerifyPhone,
  cognitoVerifyPhoneResend,
  cognitoVerifyPhoneSuccess,
} from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import { validateRequired } from '../formUtils/validators';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../async';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const formValidation = {
  'forms.confirmEmailPhone.code': [
    value => validateRequired()(value),
  ],
};

export const RESEND_EMAIL_VALIDATION = 'confirmEmailPhone.email.resend';
export const RESEND_PHONE_VALIDATION = 'confirmEmailPhone.phone.resend';
export const resendEmailPhoneValidation = isTelephone => (dispatch) => {
  const action = isTelephone ? RESEND_PHONE_VALIDATION : RESEND_EMAIL_VALIDATION;
  dispatch(asyncStartAction(action, 'Resending verification code'));
  dispatch({ type: action });
};

const confirmEmailPhoneSubmitAsyncStartEpic = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.confirmEmailPhone', 'confirmEmailPhone'),
  filter(action => !!action.attrs),
  map(action => asyncStartAction(
    'forms.confirmEmailPhone',
    '',
    { isTelephone: action.attrs.isTelephone },
  )),
);

const confirmEmailPhoneSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.confirmEmailPhone;
  const uuid = () => state$.value.data.cognito.uuid;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.confirmEmailPhone', 'confirmEmailPhone'),
    filter(action => !!action.attrs),
    switchMap((action) => {
      let verify;
      if (action.attrs.isTelephone) {
        verify = cognitoVerifyPhone;
      } else {
        verify = cognitoVerifyEmail;
      }
      return from(verify(form().code)).pipe(
        map(response => ({
          success: true,
          response,
          action,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          actions: [
            asyncErrorAction('forms.confirmEmailPhone', `verify${action.attrs.isTelephone ? 'Phone' : 'Email'}`, error),
            actions.setErrors('forms.confirmEmailPhone', {
              general: `Uh oh, there was an error verifying your ${action.attrs.isTelephone ? 'Phone' : 'Email'}`,
            }),
          ],
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const successAction = results.action.attrs.isTelephone ?
          cognitoVerifyPhoneSuccess :
          cognitoVerifyEmailSuccess;
        return of(
          asyncFinishAction('forms.confirmEmailPhone', 'verify', { uuid: uuid() }),
          successAction(results.response),
        );
      }
      if (results.actions) {
        return of(...results.actions);
      }
      return of(results.action);
    }),
  );
};

const confirmEmailPhoneResendEpic = action$ => action$.pipe(
  filter(action =>
    ((action.type === RESEND_EMAIL_VALIDATION) || (action.type === RESEND_PHONE_VALIDATION))),
  switchMap((action) => {
    let resend;
    if (action.type === RESEND_EMAIL_VALIDATION) {
      resend = cognitoVerifyEmailResend;
    } else {
      resend = cognitoVerifyPhoneResend;
    }
    return from(resend()).pipe(
      map(response => ({
        success: true,
        response,
        action,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        actions: [
          asyncErrorAction(action.type, '', error),
          actions.setErrors('forms.confirmEmailPhone', {
            general: 'Uh oh, there was an error resending your verification code',
          }),
        ],
      })),
    );
  }),
  switchMap((results) => {
    if (results.success) {
      return of(asyncFinishAction(results.action.type, 'resend'));
    }
    if (results.actions) {
      return of(...results.actions);
    }
    return of(results.action);
  }),
);


export const confirmEmailPhoneEpics = combineEpics(
  confirmEmailPhoneSubmitEpic,
  confirmEmailPhoneSubmitAsyncStartEpic,
  validationOnSubmitEpicFactory(formValidation, 'forms.confirmEmailPhone', 'confirmEmailPhone'),
  validationFailedEpicFactory('forms.confirmEmailPhone', 'confirmEmailPhone'),
  ...formValidationEpicsFactory(formValidation, 'confirmEmailPhone'),
  confirmEmailPhoneResendEpic,
);

export const dummy = {};
