import React, { PureComponent } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import PropTypes from 'prop-types';
import { setSearch } from '../../+store/nationalLeaderboard/nationalLeaderboard.selectors';
import SearchControl from '../SearchControl/SearchControl.component';

const onSearch$ = new Subject().pipe(debounceTime(1500));


class SidebarAthleteSearchComponent extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultValue: PropTypes.any,
  }

  state = {
    search: ''
  }

  componentDidMount() {
    this.id = `${Math.floor(Math.random() * 100000)}`;
    this.subscription = onSearch$.subscribe(debounced => {
      const { location, history } = this.props;
      setSearch(location, history, debounced)
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSearchChange = (location, history, value) => {
    if (value.length === 0) {
      setSearch(location, history, value)
    } else {
      onSearch$.next(value);
    }
    this.setState({ search: value });
  }


  errors = [];

  render() {
    const {
      className, location, history, defaultValue
    } = this.props;

    const { search } = this.state;

    const value = search === '' ? defaultValue : search;

    return (
      <div className={`CoachSidebarAthleteSearch ${className || ''}`}>
        <SearchControl value={value} onChange={this.onSearchChange} location={location} history={history}
                       placeholder={'Search Athlete'} autofocus={true}/>
      </div>
    )
      ;
  }
}

export default SidebarAthleteSearchComponent;
