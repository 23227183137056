import moment from 'moment';

export class StandardTestCategory {
  constructor() {
    this.best = '';
    this.code = '';
    this.comparable = 0;
    this.createdByUuid = 'admin';
    this.description = 'Start';
    this.displayStandardUnitOfMeasureId = 0;
    this.id = 0;
    this.name = '';
    this.orderNum = 0;
    this.rateable = 0;
    this.standardUnitnitOfmeasureId = 3;
    this.isActiveTest = true;
    this.isDashr = 0;
    this.templateCatName='';
  }

  static fromApi(response) {
    const testCat = new StandardTestCategory();
    testCat.best = response.best;
    testCat.code = response.code;
    testCat.comparable = response.comparable;
    testCat.createdByUuid = response.created_by_uuid;
    testCat.description = response.description;
    testCat.displayStandardUnitOfMeasureId = response.display_standard_unit_of_measure_id;
    testCat.id = response.id;
    testCat.name = response.name;
    testCat.orderNum = response.order_num;
    testCat.adultRateable = response.adult_rateable;
    testCat.youthRateable = response.youth_rateable;
    testCat.standardUnitnitOfmeasureId = response.standard_unit_of_measure_id;
    testCat.isDashr = response.is_dashr;
    testCat.templateCatName = response.template_name;
    const sports = [];
    response._embedded.sportStandardTestCategories.forEach((sportObj) => {
      sports.push(sportObj.sport_id);
    });
    testCat.sportIds = sports;
    testCat.isActiveTest = response.deleted_at ? false : true;
    return testCat;
  }

  cloneWithSport(sportId, bankCode, subbankCode) {
    const testCat = new StandardTestCategory();
    testCat.best = this.best;
    testCat.code = this.code;
    testCat.comparable = this.comparable;
    testCat.createdByUuid = this.created_by_uuid;
    testCat.description = this.description;
    testCat.displayStandardUnitOfMeasureId = this.display_standard_unit_of_measure_id;
    testCat.id = this.id;
    testCat.name = this.name;
    testCat.orderNum = this.order_num;
    testCat.rateable = this.rateable;
    testCat.adultRateable = this.adultRateable;
    testCat.youthRateable = this.youthRateable;
    testCat.standardUnitnitOfmeasureId = this.standard_unit_of_measure_id;
    testCat.sportId = sportId;
    testCat.bankCode = bankCode;
    testCat.subbankCode = subbankCode;
    testCat.isActiveTest = this.isActiveTest
    testCat.isDashr = this.isDashr
    testCat.templateCatName = this.templateCatName;
    return testCat;
  }
}

export class StandardTestBank {
  constructor() {
    this.code = '';
    this.description = '';
    this.id = 0;
    this.name = '';
    this.orderNum = 0;
  }

  static fromApi(response) {
    const bank = new StandardTestBank();
    bank.code = response.code;
    bank.description = response.description;
    bank.id = response.id;
    bank.name = response.name;
    bank.orderNum = response.order_num;
    const subbanks = [];
    response._embedded.standardTestSubbanks.forEach((subbankObj) => {
      subbanks.push(subbankObj.id);
    });
    bank.subbanks = subbanks;
    return bank;
  }
}

export class StandardTestSubbank {
  constructor() {
    this.code = '';
    this.description = '';
    this.id = 0;
    this.name = '';
    this.orderNum = 0;
    this.standardTestBankId = 0;
    this.standardTestObjects = [];
  }

  static fromApi(response) {
    const subbank = new StandardTestSubbank();
    subbank.code = response.code;
    subbank.description = response.description;
    subbank.id = response.id;
    subbank.name = response.name;
    subbank.orderNum = response.order_num;
    subbank.standardTestBankId = response.standard_test_bank_id;
    const testObjs = [];
    response.standard_test_objects.forEach((testObj) => {
      testObjs.push({
        id: testObj.id,
        catId: testObj.standard_test_category_id,
        subbankId: testObj.standard_test_subbank_id,
      });
    });
    subbank.standardTestObjects = testObjs;
    return subbank;
  }
}

export class CombineTemplateStdTest {
  static fromApi(response) {
    const combineTemplateStdTest = new CombineTemplateStdTest();
    combineTemplateStdTest.id = response.id;
    combineTemplateStdTest.standardTestCategoryId = response.standard_test_category_id;
    return combineTemplateStdTest;
  }
}

export class CombineTemplate {
  static fromApi(response) {
    const combineTemplate = new CombineTemplate();
    combineTemplate.combineCount = response.combines_count;
    combineTemplate.description = response.description;
    combineTemplate.name = response.name;
    combineTemplate.orderNum = response.order_num;
    combineTemplate.id = response.id;
    combineTemplate.sportId = response.sport_id;
    combineTemplate.schoolId = response.school_uuid;
    combineTemplate.createdBy = response.created_by_uuid;
    combineTemplate.createdAt = moment(response.created_at, 'YYYY-MM-DD h:m');
    combineTemplate.updatedAt = moment(response.updated_at, 'YYYY-MM-DD h:m');
    combineTemplate.stdTestCategories = [];
    if (response._embedded && response._embedded.combineTemplateStandardTestCategories) {
      response._embedded.combineTemplateStandardTestCategories.forEach((test) => {
        const combineTemplateStdTest = CombineTemplateStdTest.fromApi(test);
        combineTemplate.stdTestCategories.push(combineTemplateStdTest);
      });
    }
    return combineTemplate;
  }
}

export class CombineTemplateDetail {
  static fromApi(response) {
    const combineTemplateDetail = new CombineTemplateDetail();
    combineTemplateDetail.name = response.name;
    combineTemplateDetail.id = response.id;
    combineTemplateDetail.combines = [];
    if ( response.combines ) {
      response.combines.forEach ( (combine) => {
        combineTemplateDetail.combines.push({
          id: combine.id,
          name:combine.name
        });
      })
    }
    return combineTemplateDetail;
  }
}



export default 'combineTemplates.models.js';
