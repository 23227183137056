import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ButtonRect, CloseButton} from '../../../../components/UxCommon';

class ConfirmDialog extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    msg: PropTypes.string,
  };

  render() {
    const {
      onCancelClick,
      msg,
    } = this.props;

    return (
      <div className="ErrorDialogWrapper">
        <div className="ConfirmDialog">
          <CloseButton size={10} onClick={onCancelClick}/>
          <div className="AlbumDialod_DeleteAlbumDialog_Question">
            {msg}
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn"
            onClick={onCancelClick}
          >
            Ok
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default ConfirmDialog;
