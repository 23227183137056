import React from 'react';
import PropTypes from 'prop-types';

import { BarGraph, ButtonWithIcon, LoadingSpinner, Tabs, LinkButton } from '../../../../../UxCommon';
import TextObjectBtn from './TestObjectBtn/TestObjectBtn';
import TestLabel from './TestLabel/TestLabel';
import EditTestResultModal from './EditTestResultModal/EditTestResultModal';
import EditMenu from './EditMenu/EditMenu';
import Icon from '../../../../../UxCommon/Icon/Icon';
import UploadVideoModal from '../../../../../../modules/CoachWorld/Combine/UploadCombineVideoModal/UploadVideoModal';
import VideoProgressDialog from '../../../../../../modules/CoachWorld/Combine/UploadCombineVideoModal/VideoProgressDialog';
import VideoDeleteModal from '../../../../../../modules/CoachWorld/Combine/UploadCombineVideoModal/VideoDeleteModal';
import {sortByCategoryOrderAsc} from "../../../../../../store/actions/data/sport";

class TestResultsData extends React.PureComponent {
  static propTypes = {
    testResults: PropTypes.array,
    getCreateUpdateForm: PropTypes.func.isRequired,
    onCreateUpdateFormDateChanged: PropTypes.func.isRequired,
    onCreateUpdateFormFieldChanged: PropTypes.func.isRequired,
    saveDataBar: PropTypes.func.isRequired,
    createUpdateDialogIsShown: PropTypes.bool.isRequired,
    showCreateUpdateDialog: PropTypes.func.isRequired,
    hideCreateUpdateDialog: PropTypes.func.isRequired,
    onConfirmDeleteClick: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    savingMsg: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    showDeleteDialog: PropTypes.func.isRequired,
    hideDeleteDialog: PropTypes.func.isRequired,
    currentTestResult: PropTypes.object,
    deleteDialogIsShown: PropTypes.bool.isRequired,
    tabs: PropTypes.array.isRequired,
    getCreateUpdateFormModel: PropTypes.func.isRequired,
    userCanEdit: PropTypes.bool.isRequired,
    stdTestObjects: PropTypes.array.isRequired,
    mostRecentHeight: PropTypes.string.isRequired,
    mostRecentWeight: PropTypes.string.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    currentSportId: PropTypes.number,
    loadCategoryTestResults: PropTypes.func.isRequired,
    currentUuid: PropTypes.string.isRequired,
    playerDataLoaded: PropTypes.bool.isRequired,
    isCoach: PropTypes.bool,
    isMetricUnits: PropTypes.bool.isRequired,
  };


  static getDerivedStateFromProps(props, state) {
    const {
      stdTestObjects, currentSportId, testResults, rawPlayerTestCategories,
    } = props;

    const { currentStdTestObject, startingGraphIdx } = state;

    let newState = { ...state };

    // we will be showing only those optional objects that actually athlete was part of
    let filteredTestObjects = stdTestObjects
      .filter( (obj)=>
        rawPlayerTestCategories.find( (cat) => {return cat.id === obj.cat.id }) !== undefined
        || obj.cat.sportIds.includes(currentSportId)
        ).sort((a, b) => a.cat.orderNum - b.cat.orderNum);

      let testCode = "40YD";
      if(currentSportId === 2)
        testCode = "60YD";
      else if(currentSportId === 7)
        testCode = "10YD";
        else if(currentSportId === 4)
          testCode = "20YD";

      if(filteredTestObjects && filteredTestObjects.length){
        filteredTestObjects = filteredTestObjects.sort((a,b) =>{
          if(a.cat.code === testCode) return -1;
          else return 1;
        })
      }
    if (filteredTestObjects.length && !currentStdTestObject) {
      let objSet = false;
      for (let i = 0; i < filteredTestObjects.length; i += 1) {
        const object = filteredTestObjects[i];
        const objectTestLength = testResults.filter(r => r.stdTestObjectId === object.id).length;
        if (objectTestLength) {
          const startingIdx = objectTestLength % 2 ?
            objectTestLength - 1 : objectTestLength - 2;
          newState = { ...newState, currentStdTestObject: object, startingGraphIdx: startingIdx };
          objSet = true;
          break;
        }
      }
      if (!objSet) {
        const testsLength = testResults
          .filter(r => r.stdTestObjectId === filteredTestObjects[0].id).length;
        let startingIdx = null;
        if (testsLength) {
          startingIdx = testsLength % 2 ? testsLength - 1 : testsLength - 2;
        }
        newState = {
          ...newState,
          currentStdTestObject: filteredTestObjects[0],
          startingGraphIdx: startingIdx,
        };
      }
    } else if (currentStdTestObject && startingGraphIdx === null) {
      const testsLength = testResults
        .filter(r => r.stdTestObjectId === currentStdTestObject.id).length;
      if (testsLength) {
        newState = {
          ...newState,
          startingGraphIdx: testsLength % 2 ? testsLength - 1 : testsLength - 2,
        };
      }
    }

    return { ...newState, filteredTestObjects };
  }

  state = {
    currentTabId: 'raw',
    currentStdTestObject: null,
    currentBarIndex: 0,
    inEditMode: false,
    filteredTestObjects: [],
    startingGraphIdx: null,
    showVideoUploadDialog:false,
    showVideoUploadProgressDialog:false,
    showVideoDeleteDialog:false,
    videoLoading:false,
    videoLength:0,
    testResultsLength:0,
    commonSchool: [],
    getCoachLength: true,
    screenSize: 0,
    windowWidth: null
  };

  componentDidMount() {
    this.props.clearStagedMedia();
    this.props.clearLoadingProgress();
    const {
      getMedia,
      currentUuid,
      getProfile,
      needsToLoadProfile,
    } = this.props;
    getMedia(currentUuid);
    if (needsToLoadProfile) {
      getProfile(currentUuid);
    }
    if (window.location.href.indexOf('#stats') > 0) {
      const myElement = document.getElementById('divStats');
      if (myElement) {
        const topPos = myElement.offsetTop;
        document.getElementById('uxProfileRight').scrollTop = topPos + 132;
      }
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // Remove event listener when the component unmounts to avoid memory leaks
    window.removeEventListener('resize', this.handleResize);
  
  }


  handleResize = () => {
    // Update the state with the new window width
    this.setState({
      windowWidth: window.innerWidth,
    });
    this.initBarGraph();
  }
  componentDidUpdate = (prevProps,prevState) => {
    const { currentUuid, loadCategoryTestResults, playerDataLoaded,currentSportId } = this.props;
    const {
      needsToLoadPhotos,
      needsToLoadVideos,
      getMedia,
      getProfile,
      needsToLoadProfile,
      testResults,
      testTestResultVideo,
      getData,
      coachSchools,
      athleteSchools,
      isCoach,
    } = this.props;
    if(isCoach && !!coachSchools.length && this.state.getCoachLength
      && athleteSchools){
      const school = coachSchools.filter(coachSchool =>{
        for(let school of athleteSchools){
          if(coachSchool["id"] === school["school_id"]){
            return coachSchool;
          }
        }
      });
      this.setState({commonSchool: school, getCoachLength: false});
    }

    if (needsToLoadPhotos || needsToLoadVideos) {
      getMedia(currentUuid);
    }
    if (needsToLoadProfile) {
      getProfile(currentUuid);
    }
    if(prevProps.currentSportId !== currentSportId ||
      (prevProps.testResults && prevProps.testResults.length !== testResults.length && !getData)){
      this.setState({currentStdTestObject: null})
    }
    if(prevProps.testResults.length !== prevState.testResultsLength){
      testTestResultVideo(testResults);
      this.setState({testResultsLength: prevProps.testResults.length})
    }
    this.state.filteredTestObjects.forEach((object) => {
      if (this.shouldLoadTestResults(object.cat.id) && playerDataLoaded) {
        loadCategoryTestResults(currentUuid, object.cat.id);
        this.catResultLoaded = [...this.catResultLoaded, object.cat.id];
      }
    });
  }

  onPositionChange = (e) => {
    if (e.target.value) {
      const { testResults } = this.props;
      const { filteredTestObjects } = this.state;
      const selectedObject = filteredTestObjects
        .find(obj => obj.id === parseInt(e.target.value, 10));
      const testsLength = testResults
        .filter(r => r.stdTestObjectId === selectedObject.id).length;
      let startingGraphIdx = null;
      if (testsLength) {
        startingGraphIdx = testsLength % 2 ? testsLength - 1 : testsLength - 2;
      }
      this.setState({ currentStdTestObject: selectedObject, startingGraphIdx });
    }
  };

  onTestObjectSelectBtnClick = (object) => {
    const { currentUuid, loadCategoryTestResults, testResults } = this.props;
    const testsLength = testResults
      .filter(r => r.stdTestObjectId === object.id).length;
    let startingGraphIdx = null;
    if (testsLength) {
      startingGraphIdx = testsLength % 2 ? testsLength - 1 : testsLength - 2;
    }
    this.setState({ currentStdTestObject: object, startingGraphIdx });
    if (this.shouldLoadTestResults(object.cat.id)) {
      loadCategoryTestResults(currentUuid, object.cat.id);
      this.catResultLoaded = [...this.catResultLoaded, object.cat.id];
    }
  };

  onTestObjectEditBtnClick = (object) => {
    const { testResults } = this.props;
    const testsLength = testResults
      .filter(r => r.stdTestObjectId === object.id).length;
    let startingGraphIdx = null;
    if (testsLength) {
      startingGraphIdx = testsLength % 2 ? testsLength - 1 : testsLength - 2;
    }
    this.setState({ currentStdTestObject: object, startingGraphIdx, inEditMode: true });
  };

  onAddTestResultsDataClick = () => {
    const { showCreateUpdateDialog } = this.props;
    showCreateUpdateDialog(null);
  };

  onDeleteDataClick = () => {
    const { showDeleteDialog } = this.props;
    showDeleteDialog();
  };

  onCancelDeleteDataClick = () => {
    const { hideDeleteDialog } = this.props;
    hideDeleteDialog();
  };

  setTab = (tabId) => {
    // console.log('new tab', tabId);
    this.setState({ currentTabId: tabId });
  };

  shouldLoadTestResults = categoryId => !this.catResultLoaded.find(catId => catId === categoryId);

  cancelEditTestResultModal = () => {
    const {
      hideCreateUpdateDialog,
      hideDeleteDialog,
      deleteDialogIsShown,
    } = this.props;
    hideCreateUpdateDialog();
    if (deleteDialogIsShown) {
      hideDeleteDialog();
    }
  };

  saveDataBarAndAddAnother = (formId) => {
    const { saveDataBar } = this.props;
    saveDataBar(formId, true);
  };

  onVideoClickHandler = () =>{
    this.props.clearStagedMedia();
    this.props.clearLoadingProgress();
    this.setState({showVideoUploadDialog: true})
  }

  deleteVideoTestResult = () =>{
    this.props.clearStagedMedia();
    this.props.clearLoadingProgress();
    this.setState({showVideoDeleteDialog: true})
  }

  onCancelDeleteVideoTestResult = () =>{
    this.setState({showVideoDeleteDialog: false})
  }

  saveDataBar = (formId, categoryId) => {
    const { saveDataBar, isMetricUnits} = this.props;
    saveDataBar(formId, false, categoryId, isMetricUnits);
  };

  editTestResult = (testResult, index) => {
    const { showCreateUpdateDialog } = this.props;
    this.setState({
      currentBarIndex: index,
    });
    showCreateUpdateDialog(testResult);
  };

  editVideoTestResult = (testResult, index) =>{
    const { showCreateUpdateVideoDialog } = this.props;
    this.setState({
      currentBarIndex: index,
    });
    showCreateUpdateVideoDialog(testResult);
  }

  exitEditMode = () => {
    this.setState({ inEditMode: false });
  };

  initBarGraph = () => {
    const {
      testResults,
      userCanEdit,
      isMyProfile,
      videoData,
      currentUuid,
      profile,
    } = this.props;
    const {
      currentStdTestObject,
      currentTabId,
      inEditMode,
      startingGraphIdx,
      commonSchool,
      windowWidth
    } = this.state;
    const yAxis = {
      start: 0,
      end: 100,
      interval: 10,
    };
    const privacyWeight = profile && profile.privacy_weight;
    const privacyHeight = profile && profile.privacy_height;
    let bars = [];
    let maxResult = 0;
    let isVerifiedAndLaser = false;
    let totalBars = 0;

    if (testResults && currentStdTestObject) {
      let results = testResults
        .filter(r => r.stdTestObjectId === currentStdTestObject.id);
      totalBars = results.length;
      if (window.innerWidth <= 1200 && startingGraphIdx !== null) {
        results = results.slice(startingGraphIdx, startingGraphIdx + 2);

      }
      if (results.length) {
        let testIsScorable = results[0].testIsScorable;
        let showBarResult = true;
        results.forEach((r, index) => {
          let val;
          let valueWithLabel = '';
          switch (currentTabId) {
            case 'raw':
              val = Number(r.testResultMultiRepMax);
              valueWithLabel = !!r.testResultResult ? r.displayValueWithLabel : "--";
              showBarResult = true;
              break;
            case 'rating':
              if (testIsScorable) {
                val = Number(r.testResultRatedResult);
                valueWithLabel = !!val ? String(r.testResultRatedResult) : "--";
              }
              showBarResult = testIsScorable;
              break;
            case 'relative':
              if (testIsScorable) {
                val = Number(r.testResultRelativeResult);
                valueWithLabel = !!val ? String(r.testResultRelativeResult) : "--";
              }
              showBarResult = testIsScorable;
              break;
            default:
              val = 0.0;
          }
          if (Number.isNaN(val)) {
            val = 0.0;
          }
          if (val > maxResult) {
            maxResult = val;
            isVerifiedAndLaser = r.testResultLaserBool != 0
              && (r.testVerifiedByUuid !== null || r.testIsEditable === 0);
          }
          let currentVideo=[];
          if(videoData[currentUuid] && videoData[currentUuid].length!==0 ){
          currentVideo = videoData[currentUuid].filter(video => video.id === r.videoId);
          }

          if (showBarResult) {
            bars.push({
              value: val,
              isVerified: r.testVerifiedByUuid !== null || r.testIsEditable === 0,
              isLaser: r.testResultLaserBool != 0,
              videoId:r.videoId || '',
              result: r,
              processed: (currentVideo.length!==0 && currentVideo[0].processed) || 0,
              label: (
                <TestLabel
                  testResult={r}
                  editing={inEditMode}
                  currentTabId={currentTabId}
                  onClick={this.editTestResult}
                  onVideoClick={this.editVideoTestResult}
                  value={val}
                  videoId= {r.videoId || ''}
                  index={index}
                  onDeleteClick={this.deleteVideoTestResult}
                  videoClick = {this.onVideoClickHandler}
                  displayValueWithLabel={valueWithLabel}
                  privacyWeight = {privacyWeight}
                  isMyProfile = {isMyProfile}
                  privacyHeight = {privacyHeight}
                  commonSchool={commonSchool}
                />
              ),
            });
          } else {
            bars = [{
              value: key => (<div key={key} className="UxPlayerData_TestResultsData_AddDataBtnWrapper">
                <div className="UxPlayerData_TestResultsData_AddDataBtn_Resp">
                  <h3 className="NA_TEXT"> N/A </h3>
          </div>
              </div>
              )
            }]
            return;
          }
        });
      }
      else if (currentStdTestObject) {
        bars.push({
          isVerified: false,
          value: key => (
            <div className="UxPlayerData_TestResultsData_AddDataBtnWrapper" key={key}>
              {
                userCanEdit ? (
                  <ButtonWithIcon
                    iconName="plus"
                    onClick={this.onAddTestResultsDataClick}
                    className="UxPlayerData_TestResultsData_AddDataBtn_Resp"
                  >
                    {`Add ${currentStdTestObject.cat.name} Data`}
                  </ButtonWithIcon>
                  ) :
                  (
                    <div className="UxPlayerData_TestResultsData_NothingYet">Nothing Yet</div>
                  )
              }
            </div>
          ),
          label: isMyProfile ? (
            <div className="UxPlayerData_TestResultsData_NoDataLabel">
              <i className="UxPlayerData_TestResultsData_NoDataLabelIcon icon-alert-icon" />
              No Test Data Available
            </div>
          ) :
            (
              <div style={{ height: '43px' }} />
            ),
        });
      }
    }

    if (maxResult) {
      if (maxResult < 1) {
        yAxis.end = Math.round((maxResult) * 1000) / 1000;
      } else if (maxResult > 100) {
        yAxis.end = (Math.round(maxResult / 10) * 10);
      } else {
        yAxis.end = Math.ceil(maxResult);
      }
      yAxis.interval = yAxis.end / 10;
      if (isVerifiedAndLaser) {
        yAxis.end += yAxis.interval;
      }
    }
    return {
      yAxis,
      bars,
      totalBars,
    };
  }

  catResultLoaded = [];

  nextGraph = () => {
    const { startingGraphIdx } = this.state;
    this.setState({ startingGraphIdx: startingGraphIdx + 2 });
  };

  previousGraph = () => {
    const { startingGraphIdx } = this.state;
    const prevIndex = startingGraphIdx - 2;
    this.setState({ startingGraphIdx: prevIndex < 0 ? 0 : prevIndex });
  };

  updateGraphIdxOnDelete = testsLength => (number) => {
    if (testsLength === number) {
      this.previousGraph();
    }
  };

  goToLastGraph = testsLength => () => {
    if (testsLength) {
      this.setState({ startingGraphIdx: testsLength % 2 ? testsLength - 1 : testsLength - 2 });
    }
  }

  onSaveClickModal= async() =>{
    const {onSaveClick } = this.props;
    onSaveClick();
    this.setState({showVideoUploadDialog: false})
    this.setState({showVideoUploadProgressDialog: true})
  }

  onCancelVideoModel = () =>{
    this.setState({showVideoUploadDialog: false})
  }

  videoDialogHandler = ()=>{
    this.setState({showVideoUploadProgressDialog: false})
  }

  saveVideoInTestResult = (type="") =>{
    const {
      currentTestResult,
      videoData, currentUuid,testResults,saveVideoTestResult
    } = this.props;
    const { currentStdTestObject } = this.state;
    let videoId='',processed = 0;
    if(type === "delete"){
      videoId= '';
    }
    else{
      const videos = videoData[currentUuid];
      videoId = `${videos[videos.length - 1].id}`;
      const embedPath = videos[videos.length - 1].embedPath;
      if(embedPath){
        processed = 1;
      }
    }
    let results = testResults.filter(r => r.stdTestObjectId === currentStdTestObject.id);
    saveVideoTestResult(currentTestResult,videoId,currentStdTestObject.cat.id,processed);
    this.goToLastGraph(results.length);
    if(type === "delete"){
      this.setState({showVideoDeleteDialog: false})
    }
  }

  onConfirmDeleteClick = () => {
    const {
      testResult,currentTestResult,
      onConfirmDeleteClick,
    } = this.props;
    const {currentBarIndex, currentStdTestObject} =this.state;
    let results = testResults.filter(r => r.stdTestObjectId === currentStdTestObject.id);
    const number= currentTestResult ? (currentBarIndex + 1) : (results.length + 1);
    onConfirmDeleteClick(
      testResult,
      `Deleting ${currentStdTestObject.cat.name} Data #${number}`,
      currentStdTestObject.cat.id,
    );
  }

  render() {
    const {
      getCreateUpdateForm,
      getCreateUpdateFormModel,
      onCreateUpdateFormDateChanged,
      onCreateUpdateFormFieldChanged,
      createUpdateDialogIsShown,
      onConfirmDeleteClick,
      currentTestResult,
      deleteDialogIsShown,
      tabs,
      userCanEdit,
      testResults,
      loading,
      loadingMsg,
      saving,
      savingMsg,
      mostRecentHeight,
      mostRecentWeight,
      isCoach,
      isMetricUnits,
      onDrop,
      stagedMedia,
      getPhotoCardRrfForm,
      getPhotoCardRrfFormModel,
      unstageNewMedia,
      selectedTags,
      videoLoading,
      videoLoadingMsg,
      tagRelMedia,
      allTags,
      deleteMedia,
      showMediaIsNotAccepted,
      rrfForm,
      match,
      currentUuid,
      onExternalVideoClick,
      onExternalVideoCancelClick,
      isExternalVideoDialogOpen,
      loadingProgress,
      videoData,
      currentSportId,
    } = this.props;
    const {
      currentBarIndex,
      currentStdTestObject,
      currentTabId,
      inEditMode,
      filteredTestObjects,
      startingGraphIdx,
      showVideoUploadDialog,
      showVideoUploadProgressDialog,
      showVideoDeleteDialog,
      commonSchool,
      windowWidth
    } = this.state;
    const barGraphData = this.initBarGraph();
    let results = [];
    if (testResults && currentStdTestObject) {
      results = testResults
        .filter(r => r.stdTestObjectId === currentStdTestObject.id);
    }

    const selectedVal = filteredTestObjects.find(item => !!(currentStdTestObject &&
      (currentStdTestObject.id === item.id)));
    return (
      <div className="UxPlayerData_TestResultsData">
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} style={{ left: '-100px', right: '-100px' }} />
        {
          showVideoUploadProgressDialog &&
          <VideoProgressDialog
            loading={videoLoading}
            loadingMsg={videoLoadingMsg}
            videos={ videoData[currentUuid]}
            onCancel={this.videoDialogHandler}
            saveVideo = {this.saveVideoInTestResult}
            loadingProgress={loadingProgress}
            isUploadingFromPlayerDataPage={true}
          />
        }
        {
          showVideoDeleteDialog && <VideoDeleteModal
          onDeleteVideo={()=> this.saveVideoInTestResult("delete")}
          onCancel={this.onCancelDeleteVideoTestResult}
          />

        }
        {
         showVideoUploadDialog && <UploadVideoModal
            onCancel={this.onCancelVideoModel}
            onDrop={onDrop}
            stagedMedia={stagedMedia}
            getPhotoCardRrfForm={getPhotoCardRrfForm}
            getPhotoCardRrfFormModel={getPhotoCardRrfFormModel}
            unstageNewMedia={unstageNewMedia}
            selectedTags={selectedTags}
            videoLoading={videoLoading}
            videoLoadingMsg={videoLoadingMsg}
            loadingProgress={loadingProgress}
            isExternalVideoDialogOpen={isExternalVideoDialogOpen}
            tagRelMedia={tagRelMedia}
            athleteId={currentUuid}
            onExternalVideoClick={onExternalVideoClick}
            onExternalVideoCancelClick={onExternalVideoCancelClick}
            allTags={allTags}
            deleteMedia={deleteMedia}
            showMediaIsNotAccepted={showMediaIsNotAccepted}
            onSaveClick={this.onSaveClickModal}
            rrfForm={rrfForm}
            match={match}
          />
        }
        {
          createUpdateDialogIsShown &&
          <EditTestResultModal
            isCoach={isCoach}
            stdTestObject={currentStdTestObject}
            formId={currentTestResult ? currentTestResult.testResultId : 0}
            getRrfForm={getCreateUpdateForm}
            onDateChanged={onCreateUpdateFormDateChanged}
            onFormFieldChanged={onCreateUpdateFormFieldChanged}
            onCancelClick={this.cancelEditTestResultModal}
            onAddAnotherClick={this.saveDataBarAndAddAnother}
            submit={this.saveDataBar}
            commonSchool={commonSchool}
            testResult={currentTestResult}
            mostRecentHeight={mostRecentHeight}
            mostRecentWeight={mostRecentWeight}
            number={currentTestResult ? (currentBarIndex + 1) : (results.length + 1)}
            onDeleteClick={this.onDeleteDataClick}
            deleteDialogIsShown={deleteDialogIsShown}
            onCancelDeleteClick={this.onCancelDeleteDataClick}
            onConfirmDeleteClick={onConfirmDeleteClick}
            getCreateUpdateFormModel={getCreateUpdateFormModel}
            saving={saving}
            savingMsg={savingMsg}
            isMetricUnits={isMetricUnits}
            updateGraphIdxOnDelete={this.updateGraphIdxOnDelete(results.length)}
            goToLastGraph={this.goToLastGraph(results.length)}
          />
        }
        <Tabs
          className="UxPlayerData_TestResultsData_Tabs"
          tabs={tabs}
          setTab={this.setTab}
          currentTabId={currentTabId}
        />
        <div className="SelectBoxPositionPerf">
          <div className={`UxCommon_Form_InputGroup ${!userCanEdit && 'fullWidth'}`}>
            <div className="UxCommon_Form_Input">
              <select
                id="selectPositionPerf"
                className="UxCommon_Form_SelectSelect form-control"
                onChange={this.onPositionChange}
                value={selectedVal && selectedVal.id}
              >
                {
                  filteredTestObjects.map((option, index) => (
                    <option
                      key={index}
                      value={option.id}
                    >
                      {option.cat.name}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
          {
            userCanEdit && (
              <button
                onClick={() => this.setState({ inEditMode: !inEditMode })}
                className={`UxPlayerData_EditButton ${inEditMode && 'active'}`}
              >
                <Icon iconName="lead-pencil" />
              </button>
            )
          }
        </div>
        {
          <BarGraph
          className="UxPlayerData_TestResultsData_BarGraph"
          data={barGraphData}
          isVerified={results && results.length > 0 &&
          (!!results.find(test => test.testVerifiedByUuid !== null || test.testIsEditable === 0))}
          startingGraphIdx={startingGraphIdx}
          onPreviousGraph={this.previousGraph}
          currentUuid={currentUuid}
          onNextGraph={this.nextGraph}
          match={match}
          windowWidth={windowWidth}
          
        />

        }
       
        <div className={`UxPlayerData_TestResultsData_CatBtns ${inEditMode && results.length > 0 ? '' : 'category'}`}>
          {
            inEditMode && results.length > 0
              ? (
                <EditMenu
                  stdTestObject={currentStdTestObject}
                  onAddAnotherClick={this.onAddTestResultsDataClick}
                  onImDoneClick={this.exitEditMode}
                />
              ) : (
                filteredTestObjects.map(object => {
                  return <TextObjectBtn
                    key={object.id}
                    onEditClick={this.onTestObjectEditBtnClick}
                    onSelectClick={this.onTestObjectSelectBtnClick}
                    object={object}
                    testResults={testResults}
                    currentSportId={currentSportId}
                    selected={!!(currentStdTestObject && (currentStdTestObject.id === object.id))}
                    userCanEdit={userCanEdit}
                  />
                })
              )
          }
        </div>



      </div>
    );
  }
}

export default TestResultsData;
