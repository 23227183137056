import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '../../../store/actions/ui/routes';
import PageHeader from '../../../containers/UxProfile/PageHeader';
import { Button } from '../../UxCommon/Form';
import TextControl from '../../../modules/CodeSignUp/SimpleForm/TextControl';
import checkRequired from './JoinTeam.validation';


export default class JoinTeamComponent extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    errors: {
      code: [''],
    },
  };

  onSubmit = (e) => {
    e.preventDefault();
    const form = this.formRef.current;
    const errors = {
      code: checkRequired(form.code.value, 'Code is required'),
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({ errors });
    if (success) {
      const code = form.code.value;
      this.redirectTo(this.generateUrl(code));
    }
  };

  generateUrl = code => `/code/${code}/my-sports`;

  redirectTo = (url) => {
    const { history } = this.props;
    history.push(url);
  };

  formRef = React.createRef();

  render() {
    const { errors } = this.state;
    return (
      <div className="JoinTeam">
        <Route path={`/${Routes.profile}/:uuid/:page`} component={PageHeader}/>
        <form ref={this.formRef} className="" onSubmit={this.onSubmit}>
          <div className="Content">
            <TextControl
              className="Enter_Code_Text_Control"
              placeholder="Enter code"
              label="Enter code"
              type="text"
              name="code"
              errors={errors.code}
            />
            <Button
              type="submit"
              className="UxCommon_Form_Button UxEditProfile_TwitterHandle_Btn"
            >
              Submit
            </Button>
          </div>
        </form>

      </div>
    );
  }
}
