import { filter, map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { actions } from 'react-redux-form';

import { validateRequired, validateUrl } from '../../../formUtils/validators';
import {
  formValidationEpicsFactory,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';

const externalVideoFormValidation = {
  'forms.externalVideo.url': [
    value => validateRequired()(value),
    value => validateUrl()(value),
  ],
};

const resetGeneralErrorEpic = (action$) => {
  const r = /^forms\.externalVideo\..+$/;
  return action$.pipe(
    filter((action) => {
      if (action.type === 'rrf/change') {
        return r.test(action.model);
      }
      if ((action.type === 'rrf/batch')
      && (action.actions.find(a => a.type === 'rrf/change'))) {
        return r.test(action.model);
      }
      return false;
    }),
    map(action => actions.setErrors(action.model, { general: '' })),
  );
};

const externalVideoEpics = combineEpics(
  validationOnSubmitEpicFactory(externalVideoFormValidation, 'forms.externalVideo', 'externalVideo'),
  validationFailedEpicFactory('forms.externalVideo', 'externalVideo'),
  ...formValidationEpicsFactory(externalVideoFormValidation, 'externalVideo'),
  resetGeneralErrorEpic,
);

export default externalVideoEpics;
