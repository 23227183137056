import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/ForgotPass/ForgotPass';
import { forgotPassFormInit } from '../../store/actions/ui/welcome';
import { formSubmit } from '../../store/actions/ui/formUtils';

const mapStateToProps = state => ({
  needsInit: state.ui.welcome.forgotPass.formNeedsInit,
  formGeneralError: state.ui.welcome.forgotPass.formError,
  rrfForm: state.forms.forms.forgot,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    formInit: () => {
      dispatch(actions.setInitial('forms.forgot'));
      dispatch(actions.reset('forms.forgot'));
      dispatch(forgotPassFormInit());
    },
    submit: (toRedirectUrl) => {
      dispatch(formSubmit('forms.forgot',{ toRedirectUrl }));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
