import { connect } from 'react-redux';

import Component from '../../components/UxEditProfile/ConfirmEmailPhone/ConfirmEmailPhone';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { Routes } from '../../store/actions/ui/routes';
import { getProfile } from '../UxCommon/Utils';
import { resendEmailPhoneValidation } from '../../store/actions/ui/uxEditProfile/confirmEmailPhone';
import { alertOrNavigate } from '../UxCommon/NativeUtils';
import { isMobileApp } from '../../utils';

const checkIsTelephone = (state, ownProps) => {
  if (ownProps.match &&
    ownProps.match.url &&
    (ownProps.match.url.indexOf(Routes.confirmPhone) >= 0)) {
    return true;
  }
  return false;
};

const mapStateToProps = (state, ownProps) => ({
  loading: !!state.ui.uxEditProfile.confirmEmailPhone.loadingMsg,
  loadingMsg: state.ui.uxEditProfile.confirmEmailPhone.loadingMsg,
  rrfForm: state.forms.forms.confirmEmailPhone,
  uuid: state.data.cognito.uuid,
  sportId: state.ui.app.routes.currentSportId,
  isTelephone: checkIsTelephone(state, ownProps) || isMobileApp,
  profile: getProfile(state, ownProps.match) || {},
  confirmed: !state.ui.uxEditProfile.account.phoneNeedsConfirm,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    submit: () => {
      dispatch(formSubmit(
        'forms.confirmEmailPhone',
        { isTelephone: newStateProps.isTelephone },
      ));
    },
    onResendClick: () => {
      dispatch(resendEmailPhoneValidation(newStateProps.isTelephone));
    },
    formInit: () => {},
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
