import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from '../../../../store/actions/ui/routes';
import { ReportTabSlugs } from '../../+store/report/report.constants';
import LoadingIndicator from '../../common/LoadingIndicator';
import NavBar from '../../common/NavBar';
import NavBarLink from '../../common/NavBarLink';
import TeamSummary from './TeamSummary.component';
import SummaryBar from '../SummaryBar';
import BlueTable from '../BlueTable/BlueTable.component';
import Icon from '../../common/Icon/Icon.component';
import TeamBanner from '../Banners/TeamBanner.component';
import Banner from '../../common/Banner';
import PrintExperiment from '../PrintExperiment/PrintExperiment.component';
import {  checkNA,
  displayCategory,
  displayCategoryImprovement,
  displayCategoryFilterByCode,
  displayCategoryImprovementFilterByCode
} from '../Common/utils';

class Report extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    combineNames: PropTypes.object.isRequired,
    bank: PropTypes.string,
    teamReport: PropTypes.object,
  };

  redirectToTab = ({ match }) => {
    const { reportId, schoolId, teamId } = match.params;
    const tabSlug = Object.keys(ReportTabSlugs)[0];
    return <Redirect
      to={`/${Routes.school}/${schoolId}/${Routes.report}/${reportId}/${Routes.team}/${teamId}/${tabSlug}`}/>;
  };

  renderPrintBanner = () => {
    const {
      teamId, report, schoolStyle,
    } = this.props;
    return (
      <Banner schoolStyle={schoolStyle} hideButtons>
        <div className="CoachRoster_BannerChildren">
          <div className="CoachRoster_BannerLeft">
            <TeamBanner
              report={report}
              teamId={teamId}
            />
          </div>
        </div>
      </Banner>
    );
  }

  renderTeamSummary = (props) => {
    const { schoolStyle, teamReport, combineNames } = this.props;
    return (
      <React.Fragment>
        <TeamSummary
          reportSummary={teamReport}
          combineNames={combineNames}
          {...props}
        />
        <PrintExperiment schoolStyle={schoolStyle}>
          {this.renderPrintBanner()}
          <TeamSummary
            reportSummary={teamReport}
            combineNames={combineNames}
            {...props}
          />
        </PrintExperiment>
      </React.Fragment>
    );
  }

  renderTeamTab = (props) => {
    const { schoolStyle, teamReport, combineNames } = this.props;
    const { bank } = props.match.params;
    const bankReport = teamReport[bank];

    const subbanks = (bankReport.subbanks && Object.values(bankReport.subbanks)) || []
      .sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id);
    const cats = subbanks
      .reduce((prev, sb) => (
        [
          ...prev,
          ...Object.values(sb.categories)
            .sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id),
        ]
      ), []);

    const topRow = [
      [...subbanks.map(sb => ({
        name: sb.standard_test_subbank_name,
        colSpan: Object.values(sb.categories).length,
      }))],
      [...cats.map(c => ( { name : c.standard_test_category_name, code:c.standard_test_category_code} ) )],
    ];

    const rawSection = {
      name: 'Raw',
      colSpan: cats.length + 1,
      rows: [
        [
          combineNames.mostRecent,
          ...cats.map(c => displayCategoryFilterByCode(
            c.most_recent,
            c.standard_stat_format_code,
            c.standard_test_category_code,
            undefined,
            false,
          )),
        ],
        [
          combineNames.last,
          ...cats.map(c => displayCategoryFilterByCode(
            c.last,
            c.standard_stat_format_code,
            c.standard_test_category_code,
            undefined,
            false,
          )),
        ],
        [
          'Improvement',
          ...cats.map(c => (
            displayCategoryImprovementFilterByCode(
              c.improvement,
              c.standard_test_category_best,
              !!c.standard_test_category_comparable,
              c.standard_stat_format_code,
              c.standard_test_category_code,
              undefined,
              false,
            ))),
        ],
      ],
    };

    let relativeSection;
    if (bank === 'strength') {
      relativeSection = {
        name: 'Pound for Pound Strength',
        colSpan: cats.length + 1,
        rows: [
          [
            combineNames.mostRecent,
            ...cats.map(c => displayCategory(
              c.relative_strength_most_recent,
              'PERCENTAGE-INTEGER',
              checkNA(
                c.relative_strength_last,
                c.relative_strength_most_recent,
                c.relative_strength_improvement,
              ),
              false,
            )),
          ],
          [
            combineNames.last,
            ...cats.map(c => displayCategory(
              c.relative_strength_last,
              'PERCENTAGE-INTEGER',
              checkNA(
                c.relative_strength_last,
                c.relative_strength_most_recent,
                c.relative_strength_improvement,
              ),
              false,
            )),
          ],
          [
            'Improvement',
            ...cats.map(c => (
              displayCategoryImprovement(
                c.relative_strength_improvement,
                c.standard_test_category_best,
                !!c.standard_test_category_comparable,
                'PERCENTAGE-INTEGER',
                checkNA(
                  c.relative_strength_last,
                  c.relative_strength_most_recent,
                  c.relative_strength_improvement,
                ),
                false,
              )
            )),
          ],
        ],
      };
    } else {
      relativeSection = null;
    }

    const scoreSection = {
      name: bank === 'strength' ? 'Strength Score' : 'Performance Score',
      colSpan: cats.length + 1,
      rows: [
        [
          combineNames.mostRecent,
          ...cats.map(c => displayCategory(
            c.rating_most_recent,
            'SCORE',
            checkNA(
              c.rating_last,
              c.rating_most_recent,
              c.rating_improvement,
            ),
          )),
        ],
        [
          combineNames.last,
          ...cats.map(c => displayCategory(
            c.rating_last,
            'SCORE',
            checkNA(
              c.rating_last,
              c.rating_most_recent,
              c.rating_improvement,
            ),
          )),
        ],
        [
          'Improvement',
          ...cats.map(c => (
            displayCategoryImprovement(
              c.rating_improvement,
              c.standard_test_category_best,
              !!c.standard_test_category_comparable,
              'COUNT',
              checkNA(
                c.rating_last,
                c.rating_most_recent,
                c.rating_improvement,
              ),
            )
          )),
        ],
      ],
    };

    const sections = [rawSection, relativeSection, scoreSection].filter(s => !!s);
    return (
      <React.Fragment>
        <div className="outer-table">
          <BlueTable
            topRow={topRow}
            sections={sections}
          />
          <PrintExperiment schoolStyle={schoolStyle}>
            {this.renderPrintBanner()}
            <BlueTable
              topRow={topRow}
              sections={sections}
            />
          </PrintExperiment>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      teamReport, loading, loadingMsg, match, reportId, teamId,
    } = this.props;
    const tabs = teamReport ? Object
      .entries(ReportTabSlugs)
      .filter(slug => slug[1] === ReportTabSlugs.summary ||
        ((teamReport[slug[0]] ? Object.entries(teamReport[slug[0]]).length : false )&& slug[0] in teamReport && !!teamReport[slug[0]])) : [];
    return (
      <div className="CombineReport_BodyWrapper">
        {
          teamReport &&
          <NavBar>
            <div className="CoachNavBar_Left">
              <NavBarLink
                key="backButton"
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}`}
                match={match}
                // className="active"
              >
                <Icon iconName="chevron-left"/>
              </NavBarLink>
              {
                tabs
                  .map(([slug, label], index) => (
                    <NavBarLink
                      key={index}
                      to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.team}/${teamId}/${slug}`}
                      match={match}
                    >
                      {label}
                    </NavBarLink>
                  ))
              }
            </div>
          </NavBar>
        }
        <LoadingIndicator loading={loading} loadingMsg={loadingMsg}/>
        <div className="CombineReport_Body">
          <div className="CombineReport_InnerBody">
            <div className="TeamReportBase">
              {teamReport && <SummaryBar match={match} report={teamReport}/>}
              {teamReport &&
              <Switch>
                <Route
                  path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.summary}`}
                  render={this.renderTeamSummary}/>
                {
                  tabs.map((slug, index) => (
                    <Route key={index}
                           path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/:bank`}
                           render={this.renderTeamTab}/>
                  ))
                }
                <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/`}
                       render={this.redirectToTab}/>
              </Switch>
              }
              {!teamReport && <div>There is no such team in this combines teamReport.</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
