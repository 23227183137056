import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { actions } from 'react-redux-form';

import { invitedResetPassword, profileUpdate } from "../../data/user";
import { cognitoSignIn, cognitoSignInSuccess } from '../../data/cognito';
import {
  formValidationEpicsFactory,
  isPendingIsValid,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateConfirmPassword,
  validateConfirmPhone,
  validateEmail,
  validateJersey,
  validateLength,
  validateMaxLength,
  validateName,
  validatePhone,
  validateRequired,
  validateSAPhone,
  validatePassword,
  validatePasswordLength, validateConfirmUsername, validateParentsEmail, validateParent2Email, validateConfirmPhone2
} from "../formUtils/validators";

import { formServerError, formSubmit, formSubmitSuccess } from '../formUtils/actions';
import { emailToUsername } from '../../../../utils/cognito';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { emailNeedsConfirmed } from '../uxEditProfile/account';


import { resendEmailPhoneValidation } from '../uxEditProfile/confirmEmailPhone';
import { updateAthleteTeamInfo } from '../../../../modules/CoachWorld/+store/roster';
import {checkYearValidation, checkZipcode, checkAddress, checkCityState, checkAddress2} from "../../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation";
import { mapInvitedSignUpUiToApi, mapUserUiToApi } from "../../data/user/profile/models";

const invitedSignUpValidation = {
  'forms.invitedSignUp.first': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateName()(value),
  ],
  'forms.invitedSignUp.last': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateName()(value),
  ],
  'forms.invitedSignUp.confirmUsername': [
    value => validateLength(1)(value),
    value => validateEmail()(value),
    value => validateRequired()(value),
  ],
  'forms.invitedSignUp.phone': [
    (value) => {
      let countryCode = '';
      let phoneNumber = value;
      if (value && value.indexOf('+1') !== -1) {
        countryCode = '+1';
        phoneNumber = value.substr(2).trim();
      } else if (value && value.indexOf('+27') !== -1) {
        countryCode = '+27';
        phoneNumber = value.substr(3).trim();
      }
      switch (countryCode) {
        case '+27':
          return validateSAPhone()(phoneNumber);
        default:
          return validatePhone()(phoneNumber);
      }
    },
  ],
  'forms.invitedSignUp.confirmPhone': [
    (value, form) => validateConfirmPhone()(form.phone, value),
  ],
  'forms.invitedSignUp.parentName': [
    value => validateLength(1)(value),
    value => validateRequired()(value),
    value => validateName()(value),
  ],
  'forms.invitedSignUp.parentsEmail': [
    value => validateLength(1)(value),
    value => validateEmail()(value),
    value => validateRequired()(value),
  ],
  'forms.invitedSignUp.confirmParentsEmail': [
    (value, form) => validateParentsEmail()(form.parentsEmail, value),
  ],
  'forms.invitedSignUp.confirmParent2Email': [
    (value, form) => validateParent2Email()(form.parent2Email, value),
  ],
  'forms.invitedSignUp.parentMobile': [
    value => validateRequired()(value),
    (value) => {
      let countryCode = '';
      let phoneNumber = value;
      if (value && value.indexOf('+1') !== -1) {
        countryCode = '+1';
        phoneNumber = value.substr(2).trim();
      } else if (value && value.indexOf('+27') !== -1) {
        countryCode = '+27';
        phoneNumber = value.substr(3).trim();
      }
      switch (countryCode) {
        case '+27':
          return validateSAPhone()(phoneNumber);
        default:
          return validatePhone()(phoneNumber);
      }
    },
  ],
  'forms.invitedSignUp.confirmParentMobile': [
    value => validateRequired()(value),
    (value, form) => validateConfirmPhone()(form.parentMobile, value),
  ],
  'forms.invitedSignUp.confirmParent2Mobile': [
    (value, form) => validateConfirmPhone2()(form.parent2Mobile, value),
  ],
  'forms.invitedSignUp.password': [
    value => validatePassword(6)(value),
  ],
  'forms.invitedSignUp.confirmPassword': [
    (value, form) => validateConfirmPassword()(form.password, value),
  ],
};

const invitedSignUpSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.invitedSignUp;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.invitedSignUp', 'invitedSignUp'),
    switchMap(action => invitedResetPassword({
      aws_uuid: action.attrs.profile.uuid,
      email: form().confirmUsername,
      parent_email:form().parentsEmail,
      token: action.attrs.token,
      pass: form().password,
      user_team_id: action.attrs && action.attrs.userTeamId,
      sportId: action.attrs && action.attrs.sportId,
      teamName: action.attrs && action.attrs.teamName,
      schoolId: action.attrs && action.attrs.schoolId,
      coachName: action.attrs && action.attrs.coachName,
    }).pipe(
      map(response => ({
        success: true,
        response,
        action,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: formServerError('forms.invitedSignUp', 'invitedResetPassword', error, {
          username: form().confirmUsername,
          password: form().password,
        }),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        let username = form().confirmUsername;
        return from(cognitoSignIn(
          username,
          form().password,
        )).pipe(
          map(awsResponse => ({
            success: true,
            cognitoUser: awsResponse,
            username,
            action: result.action,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError((error) => {
            if (error.code === 'UserNotFoundException') {
              username = result.action.attrs.profile.email;
              const cognitoUsername = emailToUsername(username);
              return from(cognitoSignIn(
                cognitoUsername,
                form().password,
              )).pipe(map(awsResponse => ({
                success: true,
                cognitoUser: awsResponse,
                username: cognitoUsername,
                action: result.action,
              })));
            }
            throw error;
          }),
          catchError(error => of({
            success: false,
            action: formServerError('forms.invitedSignUp', 'aws', error, {
              username,
              password: form().password,
            }),
          })),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success ) {
        const uuid = result.action.attrs.profile.uuid;
        return profileUpdate(
          uuid,
          mapInvitedSignUpUiToApi(
            uuid,
            {
              first: form().first,
              last: form().last,
              email: form().confirmUsername,
              parentsEmail: form().parentsEmail,
              phone: form().phone,
              parentName: form().parentName,
              parentsPhone: form().parentMobile,
              parent2Name: form().parent2Name,
              parent2Email: form().parent2Email,
              parents2Phone: form().parent2Mobile,
            },
            false,
          ),
          result.cognitoUser.signInUserSession.accessToken.jwtToken,
          false,
        ).pipe(
          map(() => ({
            success: true,
            uuid,
            cognitoUser: result.cognitoUser,
            username: result.username,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: formServerError('forms.account', 'usUpdate', error),
          })),
        );
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const successActions = [
          formSubmitSuccess('forms.invitedSignUp', {
            uuid: result.uuid,
          }),
          cognitoSignInSuccess(result.cognitoUser),
        ];
        return of(...successActions);
      }
      return of(result.action);
    }),
  );
};

export const invitedSignUpFormEpic = combineEpics(
  invitedSignUpSubmitEpic,
  validationOnSubmitEpicFactory(invitedSignUpValidation, 'forms.invitedSignUp', 'invitedSignUp'),
  validationFailedEpicFactory('forms.invitedSignUp', 'invitedSignUp'),
  ...formValidationEpicsFactory(invitedSignUpValidation, 'invitedSignUp'),
  usernameOnChangeEpic('forms.invitedSignUp.confirmUsername', state => state.forms.invitedSignUp.confirmUsername),
);

export const INVITED_SIGN_UP_FORM_INIT = 'INVITED_SIGN_UP_FORM_INIT';
export const invitedSignUpFormInit = () => ({
  type: INVITED_SIGN_UP_FORM_INIT,
});
