import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class AwardDetails extends PureComponent {
  static propTypes = {
    award: PropTypes.object,
  }

  componentDidMount() {}

  render() {
    const { award } = this.props;
    if (!award) return null;
    return (
      <div className="Awards_AwardDetails">
        <div className="Awards_AwardDetails_Name">
          {award.name}
        </div>
        <div className="Awards_AwardDetails_Date">
          {`Awarded ${(award.date.isValid() ? award.date.format('MMMM Do YYYY') : moment(award.createdAt).format('MMMM Do YYYY'))}`}
        </div>
        {
          award.url && (
            <a className="Awards_AwardDetails_Url" href={award.url} target="_target">
              {award.url}
            </a>
          )
        }
        {
          award.desc && (
            <div className="Awards_AwardDetails_Description">
              {award.desc}
            </div>
          )
        }
      </div>
    );
  }
}

export default AwardDetails;
