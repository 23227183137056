import { connect } from 'react-redux';
import Component from '../../components/UxCommon/MainMenuButton/MainMenuButton';
import {enableCalendlyModal, sidebarShowMenu} from '../../store/actions/ui/uxProfile';

const mapStateToProps = state => ({
  isMenuShown: state.ui.uxProfile.sidebar.isMenuShown,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onMenuClick: () => {
      dispatch(sidebarShowMenu());
    },
    updateCalendlyModal: () => {
      dispatch(enableCalendlyModal(true));
    }

  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
