import { asyncStartAction } from '../../../../store/actions/ui/async';


export const GET_NORTH_EAST_LEADERBOARD_RESULTS = 'northEastLeaderboard.results.get';
export const getNorthEastLeaderboardResults = ({
  sortBy, perPage, scoreType, parsedFilters, page, loadMore, orderBy, zip, radius,search, sportId
}) =>
  (dispatch) => {
    dispatch(asyncStartAction(GET_NORTH_EAST_LEADERBOARD_RESULTS));
    dispatch({
      type: GET_NORTH_EAST_LEADERBOARD_RESULTS,
      sortBy,
      perPage,
      scoreType,
      parsedFilters,
      page,
      loadMore,
      orderBy,
      zip,
      radius,
      search,
      sportId,
      dispatch,
    });
  };

export default 'northEastLeaderboard.actions.js';
