import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { commonApiCallFragment, getTokenFragment } from '../../utils';
import { SAVE_PRIVACY } from '../actions';
import { savePrivacyData } from '../../../../data/user/profile';
import { asyncFinishAction } from '../../../async';
import { Routes } from '../../../routes';
import { privacyTypeArr } from '../../../../../../utils';

const createPrivacyEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = (returnCognitoUuid = false) => {
    const canEditObj = canEditProfile();
    if (isCoach()) {
      return canEditObj.playerUuid;
    }
    return returnCognitoUuid ? state$.value.data.cognito.uuid : null;
  };
  return (
    action$.pipe(
      ofType(SAVE_PRIVACY),
      getTokenFragment(),
      switchMap(({ action, token }) => (
        savePrivacyData(action.toggle, action.privacyType, token, uuid(),action.combineId)
          .pipe(commonApiCallFragment(action$, action, 'savePrivacyData', Routes.player))
      )),
      switchMap((results) => {
        if (results.success) {
          const { response } = results;
          const privacyObj = {};
          privacyTypeArr.forEach((privacy) => {
            privacyObj[privacy] = response[privacy] === 1;
          });
          return of(asyncFinishAction(SAVE_PRIVACY, 'savePrivacyData', {
            privacy: privacyObj,
            incomingPrivacyType: results.action.privacyType,
            uuid: uuid(true),
            combine:response
          }));
        }
        if (results.actions) return of(...results.actions);
        return of(results.action);
      }),
    )
  );
};

export default combineEpics(createPrivacyEpic);
