import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { PhotosCircleButton, LoadingImg, LoadingSpinner } from '../../../../../UxCommon';
import { TextAreaControl } from '../../../../../UxCommon/Form';
import VideoView from '../../../PhotoVideoList/PhotoCard/VideoView/VideoView';
import DeletePhotoDialog from '../../../PhotoVideoList/DeletePhotoDialog/DeletePhotoDialog';

const defaultRrfField = {
  errors: {},
  valid: true,
};

class AlbumPhotoCard extends PureComponent {
  static propTypes = {
    media: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    getRrfForm: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    stageAlbumMedia: PropTypes.func.isRequired,
    alreadyStaged: PropTypes.bool.isRequired,
    selectedTags: PropTypes.array,
    allTags: PropTypes.array,
    tagRelMedia: PropTypes.array,
  }

  state = {
    deleteDialogIsShown: false,
  };

  onCaptionChange = () => {
    const { stageAlbumMedia, media, alreadyStaged } = this.props;
    if (!alreadyStaged) {
      stageAlbumMedia(media);
    }
  }

  onDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: true,
    });
  }

  onConfirmDeleteClick = () => {
    const { media, onDeleteClick } = this.props;
    onDeleteClick(media);
    this.setState({
      deleteDialogIsShown: false,
    });
  }

  onCancelDeleteClick = () => {
    this.setState({
      deleteDialogIsShown: false,
    });
  }

  getImage = () => {
    const { media } = this.props;
    if (media.isAPhoto) {
      return (
        media.resizedPhotos ? (
          <LoadingImg
            className="Photos_PhotoCard_Img"
            srcUrl={media.resizedPhotos.small}
          />
        ) : (
          <LoadingSpinner loading loadingMsg="Processing Photo" style={{ zIndex: 1 }} />
        )
      );
    }
    // Media is a video
    return (
      <VideoView video={media} />
    );
  };

  renderSelectedTags = () => {
    const {
      selectedTags, media, allTags, tagRelMedia,
    } = this.props;
    const commonTagRelList = tagRelMedia.filter(rel => media.id === rel.mediaId);
    const ownedTags = allTags.filter(tag => commonTagRelList.some(c => c.tagId === tag.id));
    if (selectedTags.length || ownedTags.length) {
      return (
        <div className="UxCommon_TagButtons_Container">
          { Array.from(new Set([...ownedTags, ...selectedTags]))
            .map((selectedTag, index) => (
              <div className="UxCommon_TagButton_Container" key={index}>
                <label htmlFor="button" className="UxCommon_TagButton_Label">
                  {selectedTag.label}
                </label>
              </div>))}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      media,
      getRrfForm,
      getRrfFormModel,
    } = this.props;
    const { deleteDialogIsShown } = this.state;
    const rrfForm = getRrfForm(media.id);
    const formModel = getRrfFormModel(media.id);
    return (
      <div
        className="Photos_AlbumDialog_PhotoVideoCard"
      >
        <div className="Photos_AlbumDialog_PhotosVideoCard_ImgWrapper">
          <PhotosCircleButton
            className="Photos_AlbumDialog_PhotosVideoCard_DeleteBtn"
            iconName="trash"
            onClick={this.onDeleteClick}
          />
          {this.getImage(media)}
        </div>
        <div className="Photos_AlbumDialog_PhotoVideoCard_Desc">
          <TextAreaControl
            label="Description"
            placeholder="Add a description"
            model={`${formModel}.caption`}
            rrfField={rrfForm.caption || defaultRrfField}
            defaultValue={media.caption}
            onChange={this.onCaptionChange}
          />
          <Control
            type="hidden"
            model={`${formModel}.isNew`}
            defaultValue={false}
            value={false}
          />
          {
            deleteDialogIsShown && (
              <DeletePhotoDialog
                media={media}
                onConfirmDeleteClick={this.onConfirmDeleteClick}
                onCancelClick={this.onCancelDeleteClick}
              />
            )
          }
        </div>
        <div className="Photos_AlbumDialog_PhotoVideoCard_Tags">
          {this.renderSelectedTags()}
        </div>
      </div>
    );
  }
}

export default AlbumPhotoCard;
