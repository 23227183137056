import { CombineVcScore, vcScoreColumnsObj, CombineTestResult } from '../combine/combine.models';

export class CachedTest {
  static fromApi(response) {
    const test = new CachedTest();
    test.athleteUuid = response.athlete_uuid;
    test.closeDate = response.close_date;
    test.combineId = response.combine_id;
    test.comment = response.comment;
    test.createdAt = response.created_at;
    test.createdBy = response.created_by_uuid;
    test.id = response.id;
    test.isEditable = response.is_editable;
    test.name = response.name;
    test.resultDate = response.result_date;
    test.updatedAt = response.updated_at;
    test.verifiedAt = response.verified_at;
    test.verifiedBy = response.verified_by_uuid;
    return test;
  }
}

export class CachedTestResult {
  static fromApi(response) {
    const testResult = new CachedTestResult();
    testResult.id = response.id;
    testResult.laserBool = response.laser_bool;
    testResult.multiRepMax = response.multi_rep_max;
    testResult.ratedResult = response.rated_result;
    testResult.relativeStrength = response.relative_strength;
    testResult.reps = response.reps;
    testResult.result = response.result;
    testResult.resultNormalized = response.result_normalized;
    testResult.standardTestObjectId = response.standard_test_object_id;
    testResult.testId = response.test_id;
    testResult.videoId = response.video_id || "";
    testResult.videoProcessed = response.video_processed;
    testResult.multiRepMaxRank = response.multi_rep_rank_rank;
    testResult.ratedResultRank = response.rated_result_rank;
    testResult.relativeStrengthRank = response.relative_strength_rank;
    testResult.leaderboardEventComparison = response.leaderboard_event_comparison;
    return testResult;
  }
}

const standardTestObjectsMap = {};

export class School {
  static fromApi(response) {
    if (!response) return null;
    const school = new School();
    school.name = response.school_name;
    school.city = response.school_city;
    school.state = response.school_state;
    school.schoolId = response.school_id;
    return school;
  }
}

export class CachedAthlete {
  static fromApi(response, combineStandardTestCategories) {
    const athlete = new CachedAthlete();
    athlete.userId = response.aws_uuid;
    athlete.last = response.family_name;
    athlete.fromCache = response.from_cache;
    athlete.gender = response.gender;
    athlete.first = response.given_name;
    athlete.gradyear = response.grad_year;
    athlete.grade = response.grade;
    athlete.headshotUrl = response.headshot_url;
    athlete.height = response.height;
    athlete.jerseyNumber = response.jersey_number;
    athlete.name = response.name;
    athlete.position = response.position;
    athlete.schoolTeamId = response.school_team_id;
    athlete.teamName = response.team_name;
    athlete.teamReferenceId = response.team_reference_id;
    athlete.isResultInMetricUnit = response.is_result_in_metric_unit;
    athlete.testId = response.test_id;
    athlete.zip = response.athlete_zip || response.school_zip;
    athlete.school = School.fromApi(response);
    athlete.rank = response.rank || null;
    athlete.filterRank = response.filterRank || null;
    athlete.combineNumber = response.combine_no;
    athlete.privacyWeight = response.privacy_weight || 0;
    athlete.privacyHeight = response.privacy_height || 0;
    athlete.userSport = response.user_sports;
    athlete.sportId = response.sport_id ;
    athlete.isSubscribed = !!response.is_subscribed;
    if (response.test_results) {
      if (response.test_results.test) {
        athlete.test = CachedTest.fromApi(response.test_results.test);
      } else {
        athlete.test = null;
      }
      if (response.test_results.test_results) {
        athlete.testResults = {};
        // Create model for test result
        response.test_results.test_results.forEach((result) => {
          const testResult = CachedTestResult.fromApi(result);
          // Grab test & cache category from combineStandardTestCategories
          let cat = standardTestObjectsMap[testResult.standardTestObjectId];
          if (!cat && combineStandardTestCategories) {
            cat = combineStandardTestCategories.find((stdCat) => {
              const foundObj = stdCat.standard_test_category.standard_test_objects.find(stdObj => (
                stdObj.id === testResult.standardTestObjectId
              ));
              if (foundObj) return stdCat;
              return null;
            });
            if (cat) {
              standardTestObjectsMap[testResult.standardTestObjectId] = cat;
            }
          }
          if (cat) {
            testResult.stdUnitOfMeasureCode = cat.standard_test_category
              .standard_unit_of_measure.code;
            testResult.bankCode = cat.standard_test_category
              .standard_test_objects[0]
              .standard_test_subbank
              .standard_test_bank.code;
          }
          athlete.testResults[testResult.standardTestObjectId] = testResult;
        });
        const total = Object.values(athlete.testResults)
          .filter(t => (t.stdUnitOfMeasureCode === 'POUNDS' || t.stdUnitOfMeasureCode === 'KG') && t.bankCode === 'STRENGTH')
          .reduce((prev, curr) => prev + curr.multiRepMax, 0);
        athlete.testResults = {
          ...athlete.testResults,
          [999]: {
            result: total,
            relativeStrength: 'N/A',
            ratedResult: 'N/A',
          },
        };
      } else {
        athlete.testResults = {};
      }

      if (response.test_results.vc_score) {
        athlete.vcScore = CombineVcScore.fromApi(response.test_results.vc_score);
        const testId = athlete.test ? athlete.test.id : 0;
        athlete.testResults[vcScoreColumnsObj.performance
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.performance,
          vcScoreColumnsObj.performance,
          testId,
        );
        athlete.testResults[vcScoreColumnsObj.strength
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.strength,
          vcScoreColumnsObj.strength,
          testId,
        );
        athlete.testResults[vcScoreColumnsObj.vc
          .standardTestObjectId] = CombineTestResult.fromVcScore(
          athlete.vcScore.vc,
          vcScoreColumnsObj.vc,
          testId,
        );
      } else {
        athlete.vcScore = {};
      }
    } else {
      athlete.testResults = [];
      athlete.vcScore = {};
      athlete.test = null;
    }
    athlete.weight = response.weight;
    return athlete;
  }
}

export class CachedCombine {
  static fromApi(response) {
    if (!response) return null;
    const combine = new CachedCombine();
    combine.awardsGenerated = response.awards_generated_bool;
    combine.closedAt = response.closed_at;
    combine.combineTemplateId = response.combine_template_id;
    combine.createdAt = response.created_at;
    combine.createdBy = response.created_by_uuid;
    combine.deletedAt = response.deleted_at;
    combine.description = response.description;
    combine.id = response.id;
    combine.isOpen = response.is_open;
    combine.name = response.name;
    combine.openDate = response.open_date;
    combine.orderNum = response.order_num;
    combine.sportId = response.sport_id;
    combine.schoolUuid = response.school_uuid;
    combine.updatedAt = response.updated_at;
    if (response.result && response.result.roster) {
      combine.roster = Object.values(response.result.roster)
        .map(athlete => CachedAthlete.fromApi({...athlete[0], sport_id :response.sport_id}, response.combine_standard_test_categories,response.sport_id));
      // .map(athlete => CachedAthlete.fromApi(athlete[0], response.combineStandardTestCategories));

    } else {
      combine.roster = [];
    }
    return combine;
  }
}

export default 'leaderboard.models';
