import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TextControl from '../SimpleForm/TextControl';

import Button from '../Common/Button';
// import { validatePhone } from '../../../store/actions/ui/formUtils/validators';
import { checkRequired } from './SignInPage.validation';
// import { formatPhone } from '../../../utils';
import ControlErrors from '../SimpleForm/ControlErrors';
import { Routes } from '../../../store/actions/ui/routes';
import { validatePhone } from '../../../store/actions/ui/formUtils/validators';
import { formatPhone } from '../../../utils';

class SignInPage extends PureComponent {
  static propTypes = {
    signIn: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    errorMsg: PropTypes.string.isRequired,
  }

  state = {
    errors: {
      passwordConfirm: [''],
    },
  }

  componentDidMount() {
  }

  onSignInClick = (event) => {
    const { signIn, match } = this.props;
    const form = this.formRef.current;
    const errors = {
      username: checkRequired(form.username.value),
      password: checkRequired(form.password.value),
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({ errors });
    event.preventDefault();
    if (success) {
      signIn(
        {
          username: form.username.value,
          password: form.password.value,
        },
        match.params.code,
      );
    }
  }

  onUsernameChange = (value) => {
    const errors = checkRequired(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        username: errors,
      }),
    });
    if (!value) return value;
    if (validatePhone()(value).phone === '') {
      return formatPhone(value);
    }
    return value;
  }

  onUsernameBlur = value =>
    // if (!value) return value;
    // if (validatePhone()(value).phone === '') {
    //   return formatPhone(value);
    // }
    value


  onPasswordChange = (value) => {
    const errors = checkRequired(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        password: errors,
      }),
    });
    return value;
  }

  onPasswordBlur = (value) => {
    const errors = checkRequired(value);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        password: errors,
      }),
    });
    return value;
  }

  formRef = React.createRef();

  render() {
    const {
      errorMsg,
      match,
    } = this.props;
    const { errors } = this.state;
    const msg = '';

    // Otherwise show signup form
    return (
      <div className="SignInPage">
        <div className="SignInPage_Heading">Welcome back!</div>
        {msg && <div className="SignInPage_Msg">{msg}</div>}
        <form ref={this.formRef} onSubmit={this.onSignInClick} className="SignInPage_Form">
          <TextControl
            className="SignInPage_TextControl"
            placeholder="Email"
            label="Email"
            myOnChange={this.onUsernameChange}
            myOnBlur={this.onUsernameBlur}
            errors={errors.username}
            name="username"
          />
          <TextControl
            className="SignInPage_TextControl"
            placeholder="Password"
            label="Password"
            myOnChange={this.onPasswordChange}
            errors={errors.password}
            type="password"
            onBlur={this.onPasswordBlur}
            name="password"
          />
          <ControlErrors errors={[errorMsg]} />
          <Button
            className="SignUpPage_SubmitBtn"
            type="submit"
          >
            Sign In
          </Button>
          <div className="SignUpPage_SignInLinkWrapper">
            <Link className="SignUpPage_SignInLink" to={`/code/${match.params.code}/${Routes.signup}`}>
              Or create an account.
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default SignInPage;
