import React, { useEffect, useState } from 'react';
import ProfileActivationModalForm from './ProfileActivationModalForm/ProfileActivationModalForm';
import './ProfileActivationModal.scss';
import { getSchoolStyles } from '../../../../../../modules/CoachWorld/+store';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { playerProfileActivationMembershipModal } from '../../../../../../store/actions/ui/uxProfile';

const ProfileActivationModal = ({ profile }) => {
  const { schoolId } = useParams();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(playerProfileActivationMembershipModal(false));
  };

  useEffect(() => {
    dispatch(getSchoolStyles([schoolId]));
  }, [schoolId]);

  return (
    <div className="CoachSignOutModal_Backdrop ProfileActivationModal">
      <div className="CoachSignOutModal">
        <div className="CoachSignOutModal_Header ProfileActivationModalHeader">
          {'Congratulations'}
          <button
            className="UxCommon_CloseButton ProfileActivationButton"
            style={{
              top: 15,
              right: 15,
              padding: 11.25,
              height: 45,
              width: 45,
            }}
            onClick={onClose}>
            <i
              id="close-menu-icon"
              className="icon-close-icon UxCommon_CloseButton_Icon"
              data-multiline="false"
              style={{ fontSize: 15 }}></i>
          </button>
        </div>
        <div
          className="CoachSignOutModal_Body ProfileActivationModal_Body"
          style={{ width: '100%', maxWidth: 600, display: 'block' }}>
          <ProfileActivationModalForm profile={profile} onClose={onClose}/>
        </div>
        <div className="CoachSignOutModal_Footer"></div>
      </div>
    </div>
  );
};

export default ProfileActivationModal;
