import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { profileDelete, profileDeleteSuccess } from '../../data/user';
import { cognitoDeleteUser, cognitoDeleteUserSuccess } from '../../data/cognito';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../async';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { getTokenFragment } from '../uxProfile/utils';

export const EP_DELETE_MODEL = 'account';
export const EP_DELETE_ACCOUNT = 'account.delete';
export const accountDelete = dispatch => (msg) => {
  dispatch(asyncStartAction(EP_DELETE_MODEL, msg));
  dispatch({ type: EP_DELETE_ACCOUNT });
};

export const accountDeleteEpic = (action$, state$) => {
  const uuid = () => state$.value.data.cognito.uuid;
  const cognitoUser = () => state$.value.data.cognito.cognitoUser;
  let retAction;
  return action$.pipe(
    ofType(EP_DELETE_ACCOUNT),
    getTokenFragment(),
    switchMap(({ action, token }) => profileDelete(uuid(), token).pipe(
      map(response => ({ success: true, response, outer: action })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        retAction: asyncErrorAction(EP_DELETE_MODEL, 'delete', error),
      })),
    )),
    switchMap((results) => {
      if (results.success) {
        return from(cognitoDeleteUser(cognitoUser())).pipe(
          map(response => ({ success: true, response })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            retAction: asyncErrorAction(EP_DELETE_MODEL, 'delete', error),
          })),
        );
      }
      return of(retAction);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          asyncFinishAction(EP_DELETE_MODEL, 'delete'),
          profileDeleteSuccess(uuid()),
          cognitoDeleteUserSuccess(uuid()),
        );
      }
      return of(retAction);
    }),
  );
};
