import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../../config/urls';

export const typeAheadGetTeams = (search, token) =>
  ajax.getJSON(
    `${Urls.teams.getAll()}?g_name=${search}`,
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export const createTeam = (token, team) =>
  ajax.post(
    Urls.teams.post(),
    JSON.stringify(team),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

