import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import SchoolLogo from '../../common/SchoolLogo';
import cssVar from '../../../../components/App/App.utils';
import coachAward from '../../../../assets/images/CoachAward.png';

const AwardPrintExperiment = (props) => {
  const {
    combine,
    athletes,
    currentSchoolStyle,
    comparedAwards,
    savedCombineAwards
  } = props;
  
  const styles = document.getElementsByTagName('style')[0].innerHTML;
  return (
    <Frame className="PrintExperiment_IFrame" id="printable">
      <style>{styles}</style>
      <style>
        {`* {
            -webkit-print-color-adjust: exact;
            --primary: ${cssVar('primary')};
            --secondary: ${cssVar('secondary')};
          }
          html, body, #root {
            overflow: hidden;
            height: unset;
          }
          @page {
            margin-bottom: 0;
            size: landscape;
          }
          html {
            overflow: auto;
          }
          .CombineTable {
            width: 100%;
          }
          .CoachBanner {
            height: 100px;
            margin-bottom: 10px;
            background-position: center;
            background-size: cover;
            position: relative;
          }
          .CoachBanner_UpperRight {
            top: 0;
            right: 0;
            bottom: unset;
          }
          .CoachRoster_BannerChildren2 {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            // padding: 0px 20px 20px 20px;
            margin-top: -50px;
          }
          .CoachRoster_SchoolName {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .CoachRoster_CombineDataVCLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
          }
          .CombineTableCell {
            height: 50px !important;
            font-size: 12px;
          }
          .CombineEntry_Laser {
            font-size: 12px;
            padding-left: 10px;
            color: var(--secondary);
          }
          .PrintSchoolLogo {
            height:40px;
            width:40px;
          }
          .Print_SchoolRosterName {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            margin-left:5px;
          }
          .vc-award-title{
           margin-left:5%;
           width:250px;
          }
          .vc-d-flex{
            display:flex;       
          }
          .vc-print-name{
              width:20%;
          }
          .justify-space-between {
             justify-content:flex-start;
             align-items:center;
          }
          .AthleteDetail_Container {
            margin-top:15px;
          }
          .AwardDetail_Container {
            margin-top:10px;
            justify-content:space-between;
          }
          .vc-print-year{
            width:10%;
          }
        `}
      </style>
      <div className="CoachBanner">
        <div className="CoachRoster_BannerChildren2">
          <div className="CoachRoster_SchoolName">
            <SchoolLogo
              className="CoachRoster_BannerTeamLogo PrintSchoolLogo"
              schoolStyle={currentSchoolStyle || null}
            />
            <div className="CoachRoster_BannerFullTitle Print_SchoolRosterName">{combine.name} Results</div>
          </div>
        </div>
      </div>
      {
        !combine.isOpen && (comparedAwards && comparedAwards.length > 0) && (
          athletes.map((athlete) => {
            let awards = comparedAwards
              .filter(x => (x.bestObjectArr.length > 0 && x.bestObjectArr
                  .find(ath => ath.athleteId === athlete.userId))
                || (x.mostImprovedObjectArr.length > 0 && x.mostImprovedObjectArr
                  .find(ath => ath.athleteId === athlete.userId)));

            awards = awards.map((award) => {
              const bestObject = award.bestObjectArr
                .find(ath => ath.athleteId === athlete.userId);
              const mostImprovedObject = award.mostImprovedObjectArr
                .find(ath => ath.athleteId === athlete.userId);
              return { ...award, bestObject, mostImprovedObject };
            });

            if (combine.awardsGeneratedBool === 1) {
              const savedCombineAwardNames = savedCombineAwards.map(sc => sc.name);
              const savedCombineAwardsId = savedCombineAwards.map(sc => sc.ownerId);
              awards = awards.filter((award) => {
                const { mostImprovedObject, bestObject } = award;
                const doesInclude = savedCombineAwardNames
                  .includes(mostImprovedObject && mostImprovedObject.awardTitle)
                  && savedCombineAwardsId.includes(mostImprovedObject.athleteId);
                const doesIncludeBest = savedCombineAwardNames
                  .includes(bestObject && bestObject.awardTitle)
                  && savedCombineAwardsId.includes(bestObject.athleteId);
                return doesInclude || doesIncludeBest;
              });
            }

            if(awards && awards.length > 0 ) {
              const athletePositionJersey = [athlete.position, athlete.jerseyNumber && athlete.jerseyNumber.trim() ? `#${athlete.jerseyNumber.trim()}` : null].filter(n => !!n).join(' | ');
            
              const filteredAwards = awards.reduce((currentValue, award) => {
                currentValue.push(award.bestObject, award.mostImprovedObject);
                return currentValue;
              }, []).filter(a => a && a.athleteId === athlete.userId);
              return (
                <div className="AthleteAwards_Container">
                  <div className="AthleteDetail_Container vc-d-flex justify-space-between">
                    <div className="vc-print-name">
                      {`${athlete.givenName} ${athlete.familyName}`}
                    </div>
                    {
                      athlete.gradYear && (
                        <div className="vc-print-year">
                          { athlete.gradYear }
                        </div>
                      )
                    }
                    {
                      !!athletePositionJersey && (
                        <div>
                          {athletePositionJersey}
                        </div>
                      )
                    }
                  </div>
                  {
                    filteredAwards.map((filteredAward) => {
                      let awardImgSrc = coachAward;
                      const savedBeforeObject = savedCombineAwards && savedCombineAwards
                      .find(c => (c.name === filteredAward.awardTitle)
                        && c.combineId === combine.id.toString()
                        && c.ownerId === filteredAward.athleteId);

                      if (combine.awardsGeneratedBool === 1 && !savedBeforeObject) {
                        return null;
                      }

                      return (
                        <div className="AwardDetail_Container vc-d-flex">
                          <div className="vc-d-flex">
                            <div>
                              <img height={20} width={20} src={awardImgSrc} alt="award-img" />
                            </div>
                            <div className="vc-award-title">
                              {filteredAward.awardTitle}
                            </div>
                          </div>
                          <div>
                            {filteredAward.awardDesc}
                          </div>
                          <div>
                            {`Awarded ${combine.closeDate.format('MM/DD/YY')}`}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          })
        )
      }
    </Frame>
  );
};
AwardPrintExperiment.propTypes = {
  bank: PropTypes.any,
  combine: PropTypes.any,
  athletes: PropTypes.any,
  match: PropTypes.any,
  currentSchoolStyle: PropTypes.any,
  getTestResult: PropTypes.func,
};

export default AwardPrintExperiment;
