import React, { useEffect, useState } from 'react';
import PhotosRow from './PhotosRow';
import useHasMembership from '../../../../hooks/useHasMembership';
import videos from '../../../../modules/CoachWorld/Dashboard/Tutorials/Tutorials.constant';
import Video from '../../../../store/actions/data/models/Video';

const withMembershipPhotosRow = (props) => {
  const hasMembership = useHasMembership(props.match);
  const { favorites } = props;
  const [photoVideos, setPhotoVideos] = useState(
    getDummyVideosForNonMembershipUsers(),
  );

  const [shouldReloadImages, setShouldReloadImages] = useState(false);

  useEffect(() => {
    if (favorites.length || hasMembership) {
      setShouldReloadImages(true);
      setPhotoVideos([...favorites]);
    }
  }, [favorites.length, hasMembership]);

  return (
    <PhotosRow
      {...props}
      hasMembership={hasMembership}
      favorites={photoVideos}
      shouldReloadImages = {shouldReloadImages}
    />
  );
};

const getDummyVideosForNonMembershipUsers = () => {
  return videos
    .filter((vid) => !vid.isAPhoto)
    .slice(0, 1)
    .map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });
};

export default withMembershipPhotosRow;
