import React, { PureComponent } from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import Icon from '../../../UxCommon/Icon/Icon';
import CustomOption from './CustomOption/CustomOption';

class CustomSelectControl extends PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutSide = this.handleClickOutSide.bind(this);
    this.state = {
      optionsAreShown: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutSide);
  }

  static propTypes = {
    label: PropTypes.string,
    model: PropTypes.string.isRequired,
    help: PropTypes.any,
    rrfField: PropTypes.object.isRequired,
    helpClass: PropTypes.string,
    errors: PropTypes.object,
    placeholder: PropTypes.string,
    // options: PropTypes.object.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]).isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    multipleObject: PropTypes.bool
  };
  static defaultProps = {
    placeholder: '',
    label: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.defaultOpen && !state.initialized) {
      return { optionsAreShown: true };
    }
    return {};
  }


  onShowOptionsClick = () => {
    this.setState({
      optionsAreShown: true,
      // eslint-disable-next-line
      initialized: false,
    });
  }

  onOptionClick = (option) => {
    const { onOptionSelected } = this.props;
    onOptionSelected(option.value);
    this.setState({
      optionsAreShown: false,
    });
  }

  handleClickOutSide(event)
  {
    if (this.wrapperRef
      && !this.wrapperRef.current.contains(event.target)
      && this.state.optionsAreShown
    ) {
      this.setState({ optionsAreShown: false})
    }
  }


  render() {
    const {
      label,
      model,
      help,
      helpClass,
      rrfField,
      errors,
      placeholder,
      options,
      multipleObject
    } = this.props;
    const rrfFieldValue = rrfField.value !== 'no-album' ? rrfField.value : undefined;
    let findFromOption = [];
    if  ( options && Array.isArray(options) ){
      findFromOption =  options.find( (opt) => opt.value === rrfFieldValue );
    }

    const { optionsAreShown } = this.state;
    return (
      <div className="Form_CustomSelectControl redfd" ref ={this.wrapperRef}>
        <div
          className={[
            'Form_CustomSelectControl_Label',
            rrfFieldValue ? 'not-empty' : '',
          ].join(' ')}
        >
          {label}
        </div>
        <button type="button" className="Form_CustomSelectControl_Display" onClick={this.onShowOptionsClick}>
          <div
            className={[
              'Form_CustomSelectControl_DisplayText',
              rrfFieldValue ? 'not-empty' : '',
            ].join(' ')}
          >
            {
              (!!multipleObject ? ( (
                    rrfFieldValue
                    && findFromOption
                    && findFromOption.name
                  )

                  || placeholder )
              : ( (rrfFieldValue && options[rrfFieldValue].name) || placeholder ) )
            }

          </div>
          <div className="Form_CustomSelectControl_DisplayArrow">
            <Icon className="Form_CustomSelectControl_DisplayArrowIcon" iconName="down-chevron" />
          </div>
        </button>
        {
          optionsAreShown && (
            <div className="Form_CustomSelectControl_Options">
              {
                Object.values(options).map(option => (
                  <CustomOption key={option.value} option={option} onClick={this.onOptionClick} />
                ))
              }
            </div>
          )
        }
        {
          help &&
          <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
            {help}
          </HelpPopper>
        }
        <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
        <Control
          type="hidden"
          model={model}
        />
      </div>
    );
  }
}

export default CustomSelectControl;
