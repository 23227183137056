import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/PlayerData/TestData/TestsRow/TestResultsData/TestResultsData';
import { getCurrentSportId, getCurrentUuid, getPlayerData, getSortedSports } from '../../UxCommon/Utils';
import {
  Banks,
  deleteTestResult,
  hideCreateUpdateDialog,
  hideDeleteTestResultDialog,
  perfTestsCreateUpdateFormDataObject,
  perfTestsCreateUpdateFormModel,
  perfTestsCreateUpdateFormObject,
  showCreateUpdateDialog,
  showCreateUpdateVideoDialog,
  showDeleteTestResultDialog,
} from '../../../store/actions/ui/uxPlayerData';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import { makeGetPerfStdTests } from '../../../selectors/PlayerData';
import { playerCatPerfTestsGet,upadateVideoId } from '../../../store/actions/ui/uxPlayerData/tests';
import { PlayerData } from '../../../store/actions/data/sport';
import { metricSports } from '../../../components/UxProfile/UxProfile.constants';
import {
  onPhotoDrop,
  closeAlbumDialog,
  albumMediaFormModel,
  albumMediaFormObject,
  clearStagedMedia,
  clearLoadingProgress,
  stageAlbumMedia,
  unstageNewMedia,
  deletePhoto,
  stageNewTag,
  unstageNewTag,
} from '../../../store/actions/ui/uxProfile/photosVideos';

import { makeGetAlbumsState, makeGetTags, makeGetMediaList, makeGetTagRelMedia } from '../../../selectors/Photos';
import { uploadVideo,openExternalVideoDialog,closeExternalVideoDialog,loadMedia } from '../../../store/actions/ui/uxProfile/photosVideos/actions';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';
import {updateTestTestResult} from '../../../store/actions/data/photo/actions'

const isMetricUnits = (state) => {
  const currentSportId = getCurrentSportId(state);
  return metricSports.includes(currentSportId);
};

const tabs = [{ id: 'raw', label: 'Raw' }];

const getAlbumTags = (photoList, tagRel) => {
  const resultArr = [];
  const filteredTagRel = tagRel.filter(rel => photoList.some(p => rel.mediaId === p.id));

  const allMediaIds = filteredTagRel.map(item => item.mediaId);
  const allTagIds = filteredTagRel.map(item => item.tagId);
  const uniqueTagIds = [...new Set(allTagIds)];
  const uniqueMediaIds = [...new Set(allMediaIds)];


  uniqueTagIds.forEach((uTagId) => {
    const tempObj = filteredTagRel.filter(fTag => fTag.tagId === uTagId);
    if (tempObj && tempObj.length === uniqueMediaIds.length) {
      resultArr.push(tempObj[0].tagId);
    }
  });
  return resultArr;
};

const mapStateToProps = (state, ownProps) => {
  const userSports = getSortedSports(state, ownProps.match);
  const playerData = getPlayerData(state, ownProps.match);
  const currentUuid = getCurrentUuid(state, ownProps.match);
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;

  const photoList = makeGetMediaList(state);
  const getTagsState = makeGetTags(state);
  const getTagRelMedia = makeGetTagRelMedia(state);
  const getAlbumsState = makeGetAlbumsState();
  const albumTags = getAlbumTags(photoList(state, ownProps), getTagRelMedia(state, ownProps));
  const allTags = getTagsState(state, ownProps);
  let selectedTags = albumTags;
  selectedTags = allTags.filter(tag => selectedTags &&
    selectedTags.some(selectedTag => selectedTag === tag.id));
  const tags = state.ui.uxProfile.photosVideos.albumDialog.stagedTag;
  if (tags && tags.length > 0) {
    selectedTags = [...selectedTags, ...tags];
  }
  const profile = state.data.user.profiles[currentUuid];
  return {
    title: 'Performance History',
    type: Banks.perf,
    currentUuid,
    isCoach,
    profile,
    userCanEdit: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    testResults: playerData ? playerData.testResults : [],
    rawPlayerTestCategories: playerData ? playerData.rawPlayerTestCategories : [],
    playerDataLoaded: playerData instanceof PlayerData,
    mostRecentHeight: playerData ? playerData.mostRecentHeight.toString() : '',
    mostRecentWeight: playerData ? playerData.mostRecentWeight.toString() : '',
    testsStd: state.data.sport.testsStd,
    createUpdateDialogIsShown: state.ui.uxPlayerData.tests.isCreateUpdateDialogShown[Banks.perf],
    savingMsg: state.ui.uxPlayerData.tests.perfSavingMsg,
    saving: !!state.ui.uxPlayerData.tests.perfSavingMsg,
    loadingMsg: state.ui.uxPlayerData.tests.perfLoadingMsg,
    loading: !!state.ui.uxPlayerData.tests.perfLoadingMsg,
    testsLoading: state.ui.uxPlayerData.tests.loading,
    deleteDialogIsShown: state.ui.uxPlayerData.tests.deleteDialogIsShown[Banks.perf],
    currentTestResult: state.ui.uxPlayerData.tests.perfTestResultBeingEdited,
    getData :  state.ui.uxPlayerData.tests.getData,
    tabs,
    stdTestObjects: makeGetPerfStdTests(['PERF', 'HITTING', 'PITCHING', 'COACHEVAL','STATS'])(state, ownProps),
    currentSportId: getCurrentSportId(state),
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    state,
    userSportsIds: userSports ? userSports.map(us => us.sportId) : null,
    performanceClass: playerData ? playerData.performanceClass : '',
    isMetricUnits: isMetricUnits(state),
    coachSchools: state.data.coach.coachSchool || [],
    athleteSchools: profile && profile.schools,

    albums: getAlbumsState(state, ownProps),
    stagedMedia: state.ui.uxProfile.photosVideos.albumDialog.stagedMedia,
    photoList: photoList(state, ownProps),
    rrfForm: state.forms.forms.albumDialog,
    selectedTags,
    allTags,
    tagRelMedia: getTagRelMedia(state, ownProps),
    isExternalVideoDialogOpen: state.ui.uxProfile.photosVideos.externalVideo.isDialogOpen,
    videoLoading: !!state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    videoLoadingMsg: state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    loadingProgress: state.ui.uxProfile.photosVideos.albumDialog.loadingProgress,
    needsToLoadVideos: state.ui.uxProfile.photosVideos.needsToLoadVideos,
    needsToLoadPhotos: state.ui.uxProfile.photosVideos.needsToLoadPhotos,
    needsToLoadProfile: !profile,
    videoData: state.data.photo.videos,
    mediaLoaded: state.ui.uxProfile.photosVideos.albumDialog.loadingProgress &&
                state.ui.uxProfile.photosVideos.albumDialog.loadingProgress.media,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const sportId = newStateProps.currentSportId;
  const photoList = makeGetMediaList(state);
  const getTagRelMedia = makeGetTagRelMedia(state);

  return {
    ...newStateProps,
    ...ownProps,
    getCreateUpdateForm: formId => perfTestsCreateUpdateFormObject(state, formId),
    getCreateUpdateFormModel: formId => perfTestsCreateUpdateFormModel(formId),
    onCreateUpdateFormDateChanged: (formId, date) => {
      dispatch(actions.change(`${perfTestsCreateUpdateFormModel(formId)}.date`, date));
    },
    onCreateUpdateFormFieldChanged: (formId, field, value) => {
      dispatch(actions.change(`${perfTestsCreateUpdateFormModel(formId)}.${field}`, value));
    },
    saveDataBar: (formId, addAnother = false, categoryId = null, isMetricUnits = false) => {
      let heightCode = 'HEIGHT';
      let weightCode = 'WEIGHT';
      if (isMetricUnits) {
        heightCode = 'METRICHEIGHT';
        weightCode = 'METRICWEIGHT';
      }
      const stdTestObjectWeight = stateProps.testsStd.stdTestObjects
        .find(object => object.cat.code === weightCode);
      const stdTestObjectHeight = stateProps.testsStd.stdTestObjects
        .find(object => object.cat.code === heightCode);
      dispatch(formSubmit(
        perfTestsCreateUpdateFormModel(formId),
        {
          form: perfTestsCreateUpdateFormDataObject(state, formId),
          stdTestObjectWeight,
          stdTestObjectHeight,
          addAnother,
          bank: Banks.perf,
          sportId,
          categoryId,
        },
      ));
      // reset the laserTime
      if (formId === 0) {
        dispatch(actions.change(`${perfTestsCreateUpdateFormModel(formId)}.laserTime`, false));
      }
    },
    saveVideoTestResult : (testResult, videoId, categoryId,videoProcessed) =>{
      dispatch(upadateVideoId(testResult, videoId,sportId,categoryId,videoProcessed, Banks.perf))
    },
    showCreateUpdateDialog: (testResult) => {
      dispatch(showCreateUpdateDialog(Banks.perf, testResult));
    },
    showCreateUpdateVideoDialog: (testResult) => {
      dispatch(showCreateUpdateVideoDialog(Banks.perf, testResult));
    },
    hideCreateUpdateDialog: () => {
      dispatch(hideCreateUpdateDialog(Banks.perf));
    },
    onConfirmDeleteClick: (testResult, msg, categoryId = null) => {
      dispatch(deleteTestResult(testResult, msg, Banks.perf, sportId, categoryId));
    },
    showDeleteDialog: () => {
      dispatch(showDeleteTestResultDialog(Banks.perf));
    },
    hideDeleteDialog: () => {
      dispatch(hideDeleteTestResultDialog(Banks.perf));
    },
    loadCategoryTestResults: (uuid, categoryId) => {
      dispatch(playerCatPerfTestsGet(uuid, categoryId, 'Loading Performance Test Results', 'PERF'));
    },

    //video upload in performance data
    getMedia: (uuid) => {
      if (uuid) {
        dispatch(loadMedia(uuid));
      }
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    testTestResultVideo:(testResult)=> {
      dispatch(updateTestTestResult(testResult))
    },
    onSaveClick: () => {
      const album = newStateProps.albums.find(a => a.id === state.forms.albumDialog.selectedAlbum);
      dispatch(formSubmit('forms.albumDialog', { album, dispatch }));
    },
    onDrop: onPhotoDrop(dispatch),
    onCloseClick: () => {
      dispatch(closeAlbumDialog());
    },
    getPhotoCardRrfForm: mediaId => albumMediaFormObject(state, mediaId),
    getPhotoCardRrfFormModel: albumMediaFormModel,
    clearStagedMedia: () =>{
      dispatch(clearStagedMedia());
    },
    clearLoadingProgress: () =>{
      dispatch(clearLoadingProgress());
    },
    showMediaIsNotAccepted: (videoLimitMsg) => {
      dispatch(stageMediaProblem(videoLimitMsg));
    },
    clearNewAlbumName: () => {
      dispatch(actions.change('forms.albumDialog.newAlbumName', ''));
    },
    clearAlbumSelect: () => {
      dispatch(actions.change('forms.albumDialog.selectedAlbum', ''));
    },
    stageAlbumMedia: (media) => {
      dispatch(stageAlbumMedia(media, media.isAPhoto));
    },
    unstageNewMedia: (media) => {
      dispatch(unstageNewMedia(media));
    },
    deleteMedia: (media) => {
      if (media.isAPhoto) {
        dispatch(deletePhoto(media.id));
      } else {
        dispatch(deleteVideo(media.id));
      }
    },
    onAddTagClick: () => {
      // dispatch for staged media and photoList
      const newValue = state.forms.tagDialog.newTagName.new;
      if (newValue !== '') {
        const existingTag = newStateProps.tags.find(tag => tag.label === newValue);
        if (existingTag) {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList));
        } else {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList, newValue));
        }
        dispatch(actions.change('forms.tagDialog.newTagName.new', ''));
      }
    },
    onDeleteTagClick: (tagId) => {
      dispatch(unstageNewTag(newStateProps.tags.filter(a => a.id === tagId), newStateProps.media));
    },
    uploadVideo: (video, athleteId=null) => {
      dispatch(uploadVideo(video,athleteId));
    },
    onExternalVideoCancelClick: () => {
      dispatch(closeExternalVideoDialog());
    },
    onExternalVideoClick: () => {
      dispatch(openExternalVideoDialog());
    },

  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
