import { combineEpics } from 'redux-observable';
import { editSportsFormEpics } from './editSports';
import { profilePhotoEpics } from './profilePhoto';
import { accountEpics } from './account';
import { changePassEpics } from './changePass';
import { accountDeleteEpic } from './deleteAccount';
import { confirmEmailPhoneEpics } from './confirmEmailPhone';
import { epics as vanityEpics } from './vanityUrl';
import { epics as twitterHandleEpics } from './twitterHandle';

export const uxEditProfileEpics = combineEpics(
  editSportsFormEpics,
  profilePhotoEpics,
  accountEpics,
  changePassEpics,
  accountDeleteEpic,
  confirmEmailPhoneEpics,
  vanityEpics,
  twitterHandleEpics,
);
export * from './editSports';
export * from './profilePhoto';
export * from './account';
export * from './changePass';
export * from './deleteAccount';
export * from './confirmEmailPhone';
