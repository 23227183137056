import { connect } from 'react-redux';
import Component from './CombineAwards.component';
import { getLeaderboard } from '../../../+store/leaderboard';

const mapStateToProps = (state, ownProps) => {
  const { combineId,schoolId } = ownProps.match.params;
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  let roster;
  if (state.modules.coachWorld.leaderboard.leaderboard) {
    ({ roster } = state.modules.coachWorld.leaderboard.leaderboard.currentCombine);
  }
  return {
    combineId,
    roster,
    profile,
    schoolId
  };
};

const mapDispatchToProps = dispatch => ({
  getLeaderboard: (combineId) => {
    dispatch(getLeaderboard(combineId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
