import React, { PureComponent } from 'react';
import TextControl from '../common/FormControls/TextControl';
import SelectControl from '../common/FormControls/SelectControl';
import CheckboxControl from '../common/FormControls/CheckboxControl/CheckboxControl.component';
import { ButtonRect } from '../../../components/UxCommon';
// import PropTypes from 'prop-types';

const Grades = {
  '': 'Select your grade',
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  13: '13th',
  12: '12th',
  11: '11th',
  10: '10th',
  9: '9th',
  8: '8th',
  7: '7th',
  6: '6th',
};

class FormExamplePage extends PureComponent {
  static propTypes = {}

  state = {
    errors: {},
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onValueChange = name => (value) => {
    const errors = {};
    let adjustedValue;
    switch (name) {
      case 'first':
        if (!value) {
          errors.first = ['This field is required'];
        } else {
          errors.first = [];
        }
        adjustedValue = value;
        break;
      case 'last':
      default:
        adjustedValue = value;
    }
    this.setState({
      errors: Object.assign({}, this.state.errors, errors),
    });
    return adjustedValue;
  }

  onSubmit = (e) => {
    const form = this.formRef.current;
    const values = {
      first: form.first.value,
      last: form.last.value,
      grade: form.grade.value,
      ncaa: form.ncaa.value,
    };
    console.log('values', values);
    e.preventDefault();
  }

  shouldUpdate = () => {}

  formRef = React.createRef();

  render() {
    const { errors } = this.state;
    return (
      <div className="CoachFormExamplePage">
        <form ref={this.formRef} onSubmit={this.onSubmit} className="CoachFormExamplePage_Form">
          <div className="CoachFormExamplePage_Title">
            Example Form
          </div>
          <TextControl
            className="CoachFormExamplePage_Field"
            placeholder="First Name"
            label="First Name"
            name="first"
            myOnChange={this.onValueChange('first')}
            myOnBlur={this.onValueChange('first')}
            errors={errors.first}
            defaultValue="Aaron"
          />
          <TextControl
            className="CoachFormExamplePage_Field"
            placeholder="Last Name"
            label="Last Name"
            name="last"
            myOnChange={this.onValueChange('last')}
            myOnBlur={this.onValueChange('last')}
            errors={errors.last}
            defaultValue=""
          />
          <SelectControl
            className="CoachFormExamplePage_Field"
            placeholder="Grade"
            label="Grade"
            help="Grade is required to help us generate an accurate VC score"
            options={Grades}
            name="grade"
            errors={errors.grade}
            defaultValue=""
          />
          <CheckboxControl
            className="CoachFormExamplePage_Field"
            label="NCAA ID"
            name="ncaa"
            myOnChange={this.onValueChange('ncaa')}
            myOnBlur={this.onValueChange('ncaa')}
            errors={errors.ncaa}
            defaultValue={false}
            arrangement="left"
          />
          <div className="CoachFormExamplePage_Btns">
            <ButtonRect className="CoachFormExamplePage_Btn primary">Save</ButtonRect>
          </div>
        </form>
      </div>
    );
  }
}

export default FormExamplePage;
