import React from 'react';
import PropTypes from 'prop-types';
import ControlErrors from '../ControlErrors';
import Icon from '../../../CoachWorld/common/Icon';

const FakeCheckbox = ({
  selected, label, onClick, id,
}) => {
  function handleClick() {
    onClick(id);
  }
  return (
    <button className="CodeSignUpMultCheckbox_Container" onClick={handleClick} type="button">
      <div className="CodeSignUpMultCheckbox_Lbl">{ label }</div>
      <div className="CoachFakeCheckbox"> { selected && <div className="CodeSignUpMultCheckbox_Selected" /> } </div>
    </button>
  );
};

FakeCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.any,
};

class MultiSelectControl2 extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    errors: PropTypes.array,
    help: PropTypes.any,
    name: PropTypes.string,
    myOnChange: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    placeholder: PropTypes.string,
    customOnClick: PropTypes.func,
    customLabel: PropTypes.string,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      options, placeholder, label, defaultValue,
    } = props;
    let placeholderValue = { options };
    if ((placeholder && placeholder !== '') || (label && label !== '')) {
      placeholderValue = {
        options: { '': (placeholder || label), ...options },
      };
    }
    const returnState = placeholderValue;
    if (defaultValue !== undefined && defaultValue !== state.defaultValue) {
      returnState.defaultValue = defaultValue;
      returnState.selected = defaultValue;
      returnState.selectedList = defaultValue;
    }
    return returnState;
  }

  state = {
    showMenu: false,
    selected: '',
    selectedList: [],
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick);
  }

  onClick = () => {
    const { showMenu } = this.state;
    if (showMenu) {
      this.setState({ showMenu: false });
      document.removeEventListener('click', this.onOutsideClick);
    } else {
      this.setState({ showMenu: true });
      document.addEventListener('click', this.onOutsideClick);
    }
  }

  onOutsideClick = (event) => {
    if (!this.menu || !this.menu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.onOutsideClick);
      });
    }
  };

  onOptionClick = (event) => {
    const { myOnChange } = this.props;
    if (myOnChange) {
      myOnChange(event.target.dataset.value);
    }
    this.setState({
      selected: event.target.dataset.value,
      showMenu: false,
    }, () => {
      document.removeEventListener('click', this.onOutsideClick);
    });
  };

  setRef = (elem) => {
    this.menu = elem;
  };

  handleChange = (event) => {
    const { myOnChange } = this.props;
    if (myOnChange) {
      myOnChange(event.target.value);
    }
    this.setState({
      selected: event.target.value,
    });
  };

  handleMultiChange = (id) => {
    const { myOnChange } = this.props;
    if (id) {
      const { selectedList } = this.state;
      const index = selectedList.indexOf(id);
      let newSelectedList;
      if (index === -1) {
        newSelectedList = [...selectedList, id];
      } else {
        newSelectedList = [
          ...selectedList.slice(0, index),
          ...selectedList.slice(index + 1),
        ];
      }
      myOnChange(newSelectedList);
      this.setState({ selectedList: newSelectedList });
    }
  };

  render() {
    const {
      label, errors, help, name, style, disabled, multi, placeholder, customOnClick, customLabel,
    } = this.props;
    const {
      options, showMenu, selected, selectedList,
    } = this.state;
    const selectedLabel = multi ?
      selectedList.map(ele => options[ele]).filter(ele => !!ele).join(', ') : options[selected];
    const sortedOptions = Object.entries(options);
    sortedOptions.sort((a, b) => {
      if (!a[0]) {
        return -1;
      }
      if (!b[0]) {
        return +1;
      }
      return 0;
    });
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <div
        className={[
          'CodeSignUpMulti2',
          errors && errors.length ? 'error' : '',
          this.props.className].join(' ')}
        style={{ ...style }}
      >
        <select
          id={this.id}
          className="CodeSignUpMulti2_Btn hidden"
          value={!!multi ? selected : selected.toString()}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          name={name}
          disabled={!!disabled}
          multiple={!!multi}
        // {...inputProps}
        >
          {
            sortedOptions.map(([optionValue, optionLabel], index) => (
              <option key={index} value={optionValue}>{optionLabel}</option>
            ))
          }
        </select>
        <div className="CodeSignUpMulti2_Styled">
          <div className={`CodeSignUpMulti2_Label ${disabledClass}`}>{label}</div>
          <button
            className={`CodeSignUpMulti2_Btn ${disabledClass}`}
            onClick={this.onClick}
            type="button"
          >
            <div className="CodeSignUpMulti2_BtnInner">
              <div className="CodeSignUpMulti2_Selected">{selectedLabel || placeholder}</div>
            </div>
            <Icon className={`CodeSignUpMulti2_Arrow ${disabledClass}`} iconName="down-chevron" />
          </button>
          {
            !disabled && showMenu && (
              <div className="CodeSignUpMulti2_Options" ref={this.setRef}>
                {
                  sortedOptions.map(([optionValue, optionLabel], index) => {
                    const isSelected = !!selectedList.find(v => v === optionValue);
                    const isPlaceholderField = index === 0 && (!optionValue || !optionLabel);
                    if (multi && !isPlaceholderField) {
                      return (<FakeCheckbox
                        selected={isSelected}
                        label={optionLabel}
                        onClick={this.handleMultiChange}
                        id={optionValue}
                        key={optionValue}
                      />);
                    }
                    return (
                      <button
                        className={`CodeSignUpMulti2_Option ${optionValue === '' ? 'disabled' : ''}`}
                        key={optionValue}
                        data-value={optionValue}
                        onClick={this.onOptionClick}
                        type="button"
                        disabled={isPlaceholderField}
                      >
                        { optionLabel }
                      </button>
                    );
                  })
                }
                {
                  customOnClick && (
                    <button
                      className="CodeSignUpMulti2_Option"
                      onClick={customOnClick}
                      type="button"
                    >
                      { customLabel }
                    </button>
                  )
                }
              </div>
            )
          }
        </div>
        {
          (!!help || (!!errors && !!errors.length)) && (
            <div className="CodeSignUpMulti2_Help">
              {
                !!help && typeof help === 'string' ? (
                  <div className="CoachTextControl_Help">{help}</div>
                ) : (
                    help
                  )
              }
              <ControlErrors errors={errors} />
            </div>
          )
        }
      </div>
    );
  }
}

export default MultiSelectControl2;
