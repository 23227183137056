import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/PhotoVideoList/PhotoCard/PhotoCard';
import {
  showDeleteDialog,
  hideDeleteDialog,
  deletePhoto,
  deleteVideo,
  openExpandedDialog,
  multiDeleteSelect,
  multiDeleteUnselect,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { getCurrentUuid } from '../../UxCommon/Utils';
import { expandedPhotoDialogTo } from '../../../store/actions/ui/routes';
import { sidebarOpenShareDialog } from '../../../store/actions/ui/uxProfile';

const isDeleteDialogShown = (state, ownProps) => {
  const ids = state.ui.uxProfile.photosVideos.deleteDialogsShown;
  return ids.indexOf(ownProps.media.id) >= 0;
};

const isLoading = (state, ownProps) => {
  const areLoading = state.ui.uxProfile.photosVideos.mediaCardsLoading;
  const loadingObj = areLoading.find(obj => (obj.id === ownProps.media.id));
  if (loadingObj) {
    return {
      loading: true,
      msg: loadingObj.msg,
    };
  }
  return null;
};

const isFavorite = (state, ownProps) => {
  const favorites = state.ui.uxProfile.photosVideos.tempFavorites;
  const mediaId = ownProps.media.id;
  const tempFavoriteObj = favorites.find(f => f.id === mediaId);
  if (tempFavoriteObj) {
    return tempFavoriteObj.favorite;
  }
  return !!ownProps.media.favorites;
};

const isMultiDeleteSelected = (state, ownProps) => {
  const { selected } = state.ui.uxProfile.photosVideos.multiDelete;
  const mediaId = ownProps.media.id;
  const selectedMedia = selected.find(m => m.id === mediaId);
  return !!selectedMedia;
};

const mapStateToProps = (state, ownProps) => {
  const loading = isLoading(state, ownProps);

  return {
    loading: !!loading,
    loadingMsg: loading ? loading.msg : '',
    isDeleteDialogShown: isDeleteDialogShown(state, ownProps),
    isFavorite: isFavorite(state, ownProps),
    isCoach: state.ui.app.context.canEditProfile ? state.ui.app.context.canEditProfile.isCoach : false,
    multiDeleteIsOpen: state.ui.uxProfile.photosVideos.multiDelete.stage === 'open',
    multiDeleteIsSelected: isMultiDeleteSelected(state, ownProps),
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onDeleteClick: () => {
      dispatch(showDeleteDialog(ownProps.media.id));
    },
    onCancelDeleteClick: () => {
      dispatch(hideDeleteDialog(ownProps.media.id));
    },
    onConfirmDeleteClick: () => {
      if (ownProps.media.isAPhoto) {
        dispatch(deletePhoto(ownProps.media.id));
      } else {
        dispatch(deleteVideo(ownProps.media.id));
      }
    },
    onClick: () => {
      dispatch(openExpandedDialog(ownProps.media));
    },
    onMultiDeleteClick: (media) => {
      dispatch(multiDeleteSelect(media));
    },
    onMultiDeleteUnclick: (media) => {
      dispatch(multiDeleteUnselect(media));
    },
    onShareClick: () => {
      const { match, media } = ownProps;
      const uri = expandedPhotoDialogTo(match.params.uuid, media.isAPhoto, media.id,match.params.sport,match.params.schoolId);
      dispatch(sidebarOpenShareDialog(uri));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
