import React from 'react';
import PropTypes from 'prop-types';
import NavBarLink from '../../common/NavBarLink';
import { Routes } from '../../../../store/actions/ui/routes';
import { TabLabels } from '../../+store';
import ToggleView from '../../Leaderboard/ToggleView';
import SelectControl from '../../common/FormControls/SelectControl';
import NavBar from '../../common/NavBar';
import { BankCodes, BankSlugs } from '../../+store/combine';
import { Redirect } from 'react-router-dom';
import { scoreTypeOptions } from '../../Leaderboard/Header/Header.component';

const resultNumObject = {
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
};
const notLoggedInResults = {
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
};

const NorthEastLeaderboardHeader = ({
                                     match, onResultsChange, numResultsShown,
                                     onScoreTypeChange, selectedScoreType, location, isLoggedIn, sport, banks,
                                   }) => {
  let scoreOptions = {};
  if (sport === 'BASEBALL' && [BankSlugs.PERF, BankSlugs.HITTING, BankSlugs.PITCHING].indexOf(match.params.bank) === -1) {
    return (<Redirect
      to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.PERF}${location.search}`}
    />);
  }
  if (sport === 'FOOTBALL' && [BankSlugs.HITTING, BankSlugs.PITCHING].indexOf(match.params.bank) !== -1) {
    return (<Redirect
      to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.PERF}${location.search}`}
    />);
  }
  if (match.params.bank) {
    scoreOptions = scoreTypeOptions[match.params.bank] || {};
  }
  return (
    <div className="LeaderboardHeader" style={{marginBottom:15}}>
      <div className="LeaderboardHeader_ControlsWrapper">
        <div className="LeaderboardHeader_Tabs">
          <NavBar className="Leaderboard">
            {
              sport && sport === 'FOOTBALL' && (
                <div className="LeaderboardNavBarLinkWrapper">
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.STRENGTH}`}
                    match={match}
                    location={location}
                    className="Leaderboard Strength"
                  >
                    {TabLabels.STRENGTH}
                  </NavBarLink>
                </div>
              )
            }
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.PERF}`}
                match={match}
                location={location}
                className="Leaderboard Performance"
              >
                {TabLabels.PERF}
              </NavBarLink>
            </div>
            {banks.includes(BankCodes.HITTING) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.HITTING}`}
                match={match}
                location={location}
                className="Leaderboard Hitting"
              >
                {TabLabels.HITTING}
              </NavBarLink>
            </div>
            }
            {banks.includes(BankCodes.PITCHING) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.PITCHING}`}
                match={match}
                location={location}
                className="Leaderboard Pitching"
              >
                {TabLabels.PITCHING}
              </NavBarLink>
            </div>
            }
            {
              sport && sport === 'FOOTBALL' && (
                <div className="LeaderboardNavBarLinkWrapper">
                  <NavBarLink
                    to={`/${Routes.coach}/${Routes.northEastLeaderboard}/${BankSlugs.VC_SCORE}`}
                    match={match}
                    location={location}
                    className="Leaderboard VC_Score"
                  >
                    {TabLabels.VC}
                  </NavBarLink>
                </div>
              )
            }
          </NavBar>
        </div>
        <div className="LeaderboardHeader_Other">
          <div className="LeaderboardHeader_ToggleView">
            <ToggleView/>
          </div>
          <SelectControl
            name="raw"
            options={scoreOptions}
            defaultValue={selectedScoreType}
            myOnChange={onScoreTypeChange}
          />
          <SelectControl
            name="result"
            label="Results"
            options={isLoggedIn ? resultNumObject : notLoggedInResults}
            defaultValue={isLoggedIn ? numResultsShown : numResultsShown}
            myOnChange={onResultsChange}
          />
        </div>
      </div>
      {/*<div className="LeaderboardHeader_TextWrapper">*/}
      {/*  <div className="LeaderboardHeader_Text"> Leaderboard</div>*/}
      {/*</div>*/}
    </div>
  );
};
NorthEastLeaderboardHeader.propTypes = {
  match: PropTypes.object.isRequired,
  onResultsChange: PropTypes.func.isRequired,
  numResultsShown: PropTypes.string.isRequired,
  onScoreTypeChange: PropTypes.func.isRequired,
  selectedScoreType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  sport: PropTypes.string,
  banks: PropTypes.array,
};


export default NorthEastLeaderboardHeader;
