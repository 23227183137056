import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import logo from '../../../assets/images/vc-black.png';

class Error404Page extends PureComponent {
  static propTypes = {
    error: PropTypes.any,
    info: PropTypes.any,
  };

  render() {
    const {
      error,
      info,
    } = this.props;
    console.log('ErrorPage', error, info);
    return (
      <div className="UxCommon_ErrorPage">
        <div className="UxCommon_ErrorPage_Fg">
          <div className="UxCommon_ErrorPage_TxtWrapper">
            <div className="UxCommon_ErrorPage_Title">Page Not Found!</div>
            <div className="UxCommon_ErrorPage_Msg">
              {`I'm afraid that this page doesn't exist.  You could try another one, or click below.${''}`}
            </div>
            <a href="/profile">
              <img className="UxCommon_ErrorPage_Logo" src={logo} alt="logo" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Error404Page;
