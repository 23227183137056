import queryString from 'query-string';
import _ from 'lodash';
import {
  convertInchesToCm,
  convertInchesToMetre,
  convertLbsToKg
} from '../../../CodeSignUp/PhysiquePage/PhysiquePage.validation';
import {customTOFixed} from '../../../../store/actions/data/user/profile/models';
export const getAthletes = state => state.modules.coachWorld.roster.athletes;
export const getCoaches = state => state.modules.coachWorld.roster.coaches;
export const getProfiles = state => state.data.user.profiles;
export const getSchoolTeams = state => state.modules.coachWorld.roster.schoolTeams;
export const getLoadingProfiles = state => state.modules.coachWorld.roster.loadingProfiles;

const validateFilters = (filters) => {
  let valid = true;
  if (Array.isArray(filters)) {
    filters.forEach((filter) => {
      if (typeof filter === 'object') {
        if (filter.name && filter.selected) {
          if ((typeof filter.name === 'string') && Array.isArray(filter.selected)) {
            filter.selected.forEach((s) => {
              if (!['string', 'number'].includes(typeof s)) {
                valid = false;
              }
            });
          } else {
            valid = false;
          }
        } else {
          valid = false;
        }
      } else {
        valid = false;
      }
    });
  } else {
    valid = false;
  }
  return valid;
};

export const getFilters = (state, ownProps) => {
  const { location } = ownProps;
  if (location.search) {
    const parsedQuery = queryString.parse(location.search);
    const filtersJson = parsedQuery.filters;
    if (filtersJson) {
      try {
        const filters = JSON.parse(filtersJson);
        if (validateFilters(filters)) {
          return filters;
        }
        console.log('Invalid filters in query string');
      } catch (err) {
        console.log('Invalid filters in query string:', err);
      }
    }
  }
  return null;
};

export const getQueryFromFilters = (state, ownProps) => {
  const filters = getFilters(state, ownProps);
  if (filters) {
    const parsedQuery = filters.map((filter) => {
      const { name } = filter;
      let query = '';
      filter.selected.forEach((val) => {
        query += '&';
        query += `${name}[]=${val}`;
      });
      return query;
    });
    return parsedQuery.join('&');
  }
  return null;
};

export const setFilter = (location, history, filterName, name, value, currentSelectedId=null ,page='') => {
  const allFilters = getFilters(null, { location });
  let filter;
  let otherFilters;
  if (allFilters) {
    filter = allFilters.find(f => f.name === filterName);
    otherFilters = allFilters.filter(f => f.name !== filterName);
  }
  if (!filter) {
    filter = {
      name: filterName,
      selected: [],
    };
  }
  if (!otherFilters) {
    otherFilters = [];
  }

  let selected;
  if (Array.isArray(name)) {
    const diff = _.difference(filter.selected, name);
    selected = value ? [...diff, ...name] : diff;
  } else {
    if (value) {
      selected = [...filter.selected.filter(k => k !== name), name];
    } else {
      selected = [...filter.selected.filter(k => k !== name)];
    }
  }

  let updatedFilters;
  if (selected.length) {
    updatedFilters = [
      ...(otherFilters),
      {
        name: filterName,
        selected,
      },
    ];
  } else {
    updatedFilters = [...otherFilters];
  }

  let parsedQuery;
  if (location.search) {
    parsedQuery = queryString.parse(location.search);
  } else {
    parsedQuery = {};
  }
  parsedQuery.filters = JSON.stringify(updatedFilters);
  if(filterName === "events" && page !== 'Combine'){
    parsedQuery.sortBy = currentSelectedId && currentSelectedId[currentSelectedId.length-1];
  }

  if (page === 'reconfigureTeams' && updatedFilters.find(e => e.name === 'schoolTeamId') === undefined) {
    updatedFilters = [
      ...(updatedFilters),
      {
        name: 'schoolTeamId',
        selected:[],
      },
    ];
    parsedQuery.filters = JSON.stringify(updatedFilters);
  }

  history.push({
    ...location,
    search: queryString.stringify(parsedQuery,{skipNull: true}),
  });
};

export const setSortBy = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.sortBy = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

export const rosterView = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.rosterView = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
}

export const setEventAndSortBy = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.sortBy = value;
  parsedQuery.selectedEvent = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

export const setSport = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.sport = value;
  delete parsedQuery.filters;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

export const getSearch = (state, ownProps) => {
  const { location } = ownProps;
  if (location.search) {
    const parsedQuery = queryString.parse(location.search);
    return parsedQuery.search || null;
  }
  return null;
};

export const getSortBy = (state, ownProps) => {
  const { location } = ownProps;
  if (location.search) {
    const parsedQuery = queryString.parse(location.search);
    return parsedQuery.sortBy || null;
  }
  return null;
};

export const getCombineNames = (state, reportId) => {
  const combineNames = {
    last: 'Previous Combine',
    mostRecent: 'Most Recent Combine',
  };
  if (state.modules.coachWorld.report.report[reportId]) {
    combineNames.mostRecent = state.modules.coachWorld.report.report[reportId].combine.name;
    combineNames.last = state.modules.coachWorld.report.report[reportId].second_combine.name;
  }
  return combineNames;
};

export const isMetricUnits = (state, ownProps) => {
  const { profiles } = state.data.user;
  const schools = profiles
    && state.data.user.profiles[state.data.cognito.uuid]
    && state.data.user.profiles[state.data.cognito.uuid].schools;
  const { schoolId } = ownProps.match.params;
  if (schools && !!schools.length) {
    const foundSchool = schools.find(school => school.school_id === schoolId);
    const metricSchoolCodes = ['OSGR', 'OSGT'];
    return foundSchool && metricSchoolCodes.includes(foundSchool.school.athlete_code);
  }
  return false;
};


export const testResultConversion = (code, result)=> {
  switch(code) {
      case 'INTLBROADJUMP':
          return customTOFixed(convertInchesToMetre(result),2);
      case 'INTLVERTICALJUMP':
          return customTOFixed(convertInchesToCm(result), 2);
      case 'METRICHEIGHT':
          return customTOFixed(convertInchesToMetre(result), 2);
      case 'METRICWEIGHT':
          return customTOFixed(convertLbsToKg(result));
      default:
          return result;
  }
}
