import { combineReducers } from 'redux';

import { TEAM_TYPEAHEAD_RESULTS, FIRST_SEASON_CREATE_TEAM } from '../../../actions/ui/uxPlayerData';
import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../actions/ui/async';

const firstSeasonLoadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === FIRST_SEASON_CREATE_TEAM) {
        return 'Saving your team ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === FIRST_SEASON_CREATE_TEAM) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const teamTypeAheadResults = (state = [], action) => {
  switch (action.type) {
    case TEAM_TYPEAHEAD_RESULTS:
      return action.teams;
    default:
      return state;
  }
};

const reducer = combineReducers({
  firstSeasonLoadingMsg,
  teamTypeAheadResults,
});

export default reducer;
