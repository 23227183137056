import { asyncStartAction } from '../../../../store/actions/ui/async';
import { fixRotation } from '../../../../containers/UxCommon/ImageUtils';

export const EP_SAVE_ATHLETE_PHOTO = 'editAthlete.athletePhoto.save';
export const USER_ATHLETE_PHOTO = 'user.athletePhoto';
export const updateAthletePhoto = dispatch => (sportId, photoUrl, msg) => {
  dispatch(asyncStartAction(USER_ATHLETE_PHOTO, msg));
  dispatch({ type: EP_SAVE_ATHLETE_PHOTO, sportId, photoUrl });
};

export const EP_PHOTO_DROP_ATHLETE = 'editAthlete.athletePhoto.drop';
export const EP_PHOTO_DROP_ONLOAD_ATHLETE = 'editAthlete.athletePhoto.dropOnLoad';
export const EP_PHOTO_DROP_ERROR_ATHLETE = 'editAthlete.athletePhoto.dropError';

const loadPhotoToCrop = (dispatch, file) => {
  const image = new Image();
  image.onload = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      dispatch({
        type: EP_PHOTO_DROP_ONLOAD_ATHLETE,
        image: {
          data: event.target.result,
          height: image.naturalHeight,
          width: image.naturalWidth,
        },
      });
    };
    reader.readAsDataURL(file);
  };
  image.src = URL.createObjectURL(file);
};

export const onAthletePhotoDrop = dispatch => (accepted, rejected) => {
  if (accepted.length) {
    const file = accepted[0];

    fixRotation(file).then((returnedFile) => {
      dispatch({ type: EP_PHOTO_DROP_ATHLETE, file: returnedFile });
      loadPhotoToCrop(dispatch, returnedFile);
    });
  } else {
    dispatch({ type: EP_PHOTO_DROP_ERROR_ATHLETE, rejected });
  }
};

export const EP_PHOTO_DISCARD_ATHLETE = 'editAthlete.athletePhoto.discardNew';
export const discardNewAthletePhoto = dispatch => (photo) => {
  dispatch({ type: EP_PHOTO_DISCARD_ATHLETE, photo });
};

export const EP_PHOTO_SAVE_NEW_ATHLETE = 'editAthlete.athletePhoto.saveNew';
export const saveNewAthletePhoto = dispatch => (body, athleteId, msg, coachId) => {
  dispatch(asyncStartAction(EP_PHOTO_SAVE_NEW_ATHLETE, msg));
  dispatch({
    type: EP_PHOTO_SAVE_NEW_ATHLETE,
    body,
    athleteId,
    dispatch,
    coachId,
  });
};

export const EP_PHOTO_REMOVE_ATHLETE = 'editAthlete.athletePhoto.remove';
export const removeAthletePhoto = athleteId => ({
  type: EP_PHOTO_REMOVE_ATHLETE,
  athleteId,
});

export const UPLOAD_PROGRESS_ATHLETE = 'editAthlete.uploadProgress.update';
export const uploadProgressUpdate = (progressEvent, totalBytes, mediaId, msg, reset) => ({
  type: UPLOAD_PROGRESS_ATHLETE,
  progressEvent,
  totalBytes,
  mediaId,
  msg: msg || '',
  reset: reset || false,
});

export const PHOTO_MODE_ENABLED = 'editAthlete.athletePhoto.photoModeEnabled';
export const photoModeEnabled = () => ({
  type: PHOTO_MODE_ENABLED,
});
export const PHOTO_MODE_DISABLED = 'editAthlete.athletePhoto.photoModeDisabled';
export const photoModeDisabled = () => ({
  type: PHOTO_MODE_DISABLED,
});

// export const EP_GET_OLD_ATHLETE_PHOTOS = 'editAthlete.athletePhoto.getOld';
// export const OLD_ATHLETE_PHOTOS = 'oldAthletePhotos';
// export const getOldAthletePhotos = dispatch => (uuid, msg) => {
//   dispatch(asyncStartAction(OLD_ATHLETE_PHOTOS, msg));
//   dispatch({ type: EP_GET_OLD_ATHLETE_PHOTOS, uuid });
// };

// export const oldAthletePicsEpic = action$ => (
//   action$.pipe(
//     ofType(EP_GET_OLD_ATHLETE_PHOTOS),
//     getTokenFragment(),
//     switchMap(({ action, token }) => getPhotos(action.uuid, token)
//       .pipe(
//         map(response => mapPhotosApiToUi(response)),
//         map(athletePhotos => ({
//           success: true, athletePhotos, action, token,
//         })),
//         takeUntil(cancelOnRouteChange(action$)),
//         ajaxErrorHandlerEpicFragment(),
//         catchError(error => of({
//           success: false,
//           action: asyncErrorAction(OLD_ATHLETE_PHOTOS, 'get', error),
//         })),
//       )),
//     switchMap(({ action, token, athletePhotos }) => getAlbums(action.uuid, token)
//       .pipe(
//         albumsPolling(action$, action, 'getAlbums', Routes.photos, token),
//         map(results => ({
//           success: true, albums: results, athletePhotos, action, token,
//         })),
//       )),
//     switchMap((results) => {
//       if (results.success) {
//         const albumRelMediaCollection = [];
//         results.albums.results.map(rawAlbum =>
//           mapAlbumDetailsApiToUi(rawAlbum.albumDetailsResponse, albumRelMediaCollection));
//         if (albumRelMediaCollection && albumRelMediaCollection.length > 0) {
//           results.athletePhotos = results.athletePhotos
//             .filter(photo => !albumRelMediaCollection
//               .some(arm => arm.mediaId === photo.id));
//         }
//         return of(
//           asyncFinishAction(OLD_ATHLETE_PHOTOS, 'get'),
//           photoGetAthletePhotosSuccess(results.athletePhotos),
//         );
//       }
//       if (results.actions) return of(...results.actions);
//       return of(results.action);
//     }),
//   )
// );
