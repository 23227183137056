import React from 'react';
import { ButtonRect } from '../../../../components/UxCommon';

const VideoDeleteModal = (props) => {

const { 
   onCancel, onDeleteVideo
} =props;

return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">Do you want to delete Video ?</div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn CloseCombineModal_Color"
          type="button"
          onClick={onDeleteVideo}
        >
          Yes
        </ButtonRect>
        <ButtonRect
          className="CloseCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          No
        </ButtonRect>
      </div>
    </div>
  </div>
};

export default VideoDeleteModal;
