import { combineReducers } from 'redux';
import { ASYNC_FINISH, ASYNC_START } from '../../../../store/actions/ui/async';
import {
  GET_NATIONAL_LEADERBOARD_GRAD_YEARS,
  GET_NATIONAL_LEADERBOARD_RESULTS,
  GET_NATIONAL_LEADERBOARD_SPORTS,
  GET_NATIONAL_LEADERBOARD_TEMPLATE,
} from './nationalLeaderboard.actions';

const loading = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (
        action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE ||
        action.model === GET_NATIONAL_LEADERBOARD_RESULTS
      ) {
        return 'loading';
      }
      return state;
    case ASYNC_FINISH:
      if (
        action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE ||
        action.model === GET_NATIONAL_LEADERBOARD_RESULTS
      ) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const template = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE) {
        return Object.assign({}, state, action.data.template);
      }
      return state;
    default:
      return state;
  }
};

const categories = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_TEMPLATE) {
        return action.data.categories;
      }
      return state;
    default:
      return state;
  }
};

const sports = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_SPORTS) {
        return action.data.sports;
      }
      return state;
    default:
      return state;
  }
};

const gradYears = (state = [], action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_GRAD_YEARS) {
        return action.data.gradYears;
      }
      return state;
    default:
      return state;
  }
};

const results = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_RESULTS) {
        return action.data;
      }
      return state;
    default:
      return state;
  }
};

const eventAverage = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GET_NATIONAL_LEADERBOARD_RESULTS) {
        return action.data.eventAverage;
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  results,
  loading,
  template,
  categories,
  sports,
  gradYears,
  eventAverage,
});

export default reducer;
