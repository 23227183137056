export default class TagRelMedia {
  constructor() {
    this.tagId = '';
    this.mediaId = '';
    this.isAPhoto = true;
  }

  fromApiResponse(mediaId, tagId, isAPhoto) {
    this.tagId = tagId;
    this.mediaId = mediaId;
    this.isAPhoto = isAPhoto;
  }
}
