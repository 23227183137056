import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { Routes, expandedPhotoDialogPath } from '../../../../store/actions/ui/routes';
import { SecondaryIconButton, SecondaryIconLink } from '../../../UxCommon';
import MultiDeleteBanner from '../../../../containers/UxProfile/Photos/MultiDeleteBanner';

class TopBar extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    photoList: PropTypes.array.isRequired,
    multiDeleteIsOpen: PropTypes.bool.isRequired,
    onMultiDeleteCancelClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    photosLoading: PropTypes.bool.isRequired,
    onShowFilterDialogClick: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    isCoach: PropTypes.bool,
    user: PropTypes.object,
  };

  onDeleteClick = () => {
    const { onDeleteClick, photoList } = this.props;
    onDeleteClick(<MultiDeleteBanner photoList={photoList} />);
  };

  getTitle = () => {
    const { selectedCategory } = this.props;
    switch (selectedCategory) {
      case 'all':
        return 'All Photos and Videos';
      case 'photos':
        return 'All Photos';
      case 'videos':
        return 'All Videos';
      default:
        return 'All Photos and Videos';
    }
  };

  getRoute = (page) => {
    const { match } = this.props;
    const { sport, schoolId } = match.params;
    return `/${Routes.profile}/${match.params.uuid}/${page}${sport ? `/${sport}` : ''}${schoolId ? `/${schoolId}`: ''}`;
  };

  renderHoverlay = () => (
    <div className="UxProfile_Photos_TopBar_BtnsHoverlay" />
  )

  signInToUpdate = () => {
    const {history} = this.props;
    history.push({
      pathname: `/${Routes.welcome}/${Routes.login}`,
    });
  }

  render() {
    const {
      onAddClick,
      multiDeleteIsOpen,
      onMultiDeleteCancelClick,
      isMyProfile,
      photosLoading,
      onShowFilterDialogClick,
      onShareClick,
      isCoach,
      user
    } = this.props;
    return (
      <div className="Photos_Topbar">
        <div className="Photos_TopBar_Title">{this.getTitle()}</div>
        <div className="Photos_TopBar_Btns" style={{ display: photosLoading ? 'none' : 'flex' }}>
          <Route
            path={expandedPhotoDialogPath}
            render={this.renderHoverlay}
          />
          <div className="Photos_TopBar_LeftBtns">
            <div className="d-flex">
              <SecondaryIconLink className="Photos_TopBar_ProfileBtn"iconName="back-arrow" to={this.getRoute(Routes.player)}>
                <span className="hidden-md-down">Back to My Profile</span>
                <span className="hidden-lg-up">To Profile</span>
              </SecondaryIconLink>
              <SecondaryIconButton className="Photos_TopBar_ShareBtn" iconName="share" onClick={onShareClick}>
                Share
              </SecondaryIconButton>
            </div>

          </div>
          <div className="Photos_TopBar_RightBtns mobile_button">
            <SecondaryIconButton
              className="Photos_TopBar_FilterBtn"
              iconName="filter"
              onClick={onShowFilterDialogClick}
            >
              Filter
            </SecondaryIconButton>
            {
              isMyProfile && (
                multiDeleteIsOpen ? (
                  <SecondaryIconButton className="Photos_TopBar_CancelBtn" iconName="times" onClick={onMultiDeleteCancelClick}>
                    Cancel
                  </SecondaryIconButton>
                ) : (
                  <SecondaryIconButton className="Photos_TopBar_DeleteBtn" iconName="edit" onClick={this.onDeleteClick}>
                    Select
                  </SecondaryIconButton>
                )
              )
            }
            {
              (isMyProfile || isCoach) && (
                <SecondaryIconButton className="Photos_TopBar_AddBtn" iconName="plus" onClick={onAddClick}>
                  Add
                </SecondaryIconButton>
              )
            }
            {
              !isMyProfile && user && !!user.registered && !!user.isSubscribed && (
                <SecondaryIconButton
                  className="Photos_TopBar_signInBtn" iconName="login-1" onClick={this.signInToUpdate}>
                  Sign In<span> to Update</span>
                </SecondaryIconButton>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
