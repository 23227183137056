import moment from 'moment';
import {emailOrPhone, formatPhone} from '../../../../../utils';
import {
  convertCmToInches,
  convertInchesToCm,
  convertInchesToMetre,
  convertKgToLbs,
  convertLbsToKg,
} from '../../../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation';
import { parseGPA } from '../../../../../utils/parsers';

export const Roles = {
  guest: 1,
  athlete: 2,
  parent: 3,
  coach: 4,
  admin: 5,
};

export const RoleType = {
  1: 'guest',
  2: 'athlete',
  3: 'parent',
  4: 'coach',
  5: 'admin',
};

export const mapUserSportUiToApi = (user, sport, isCoach) => {
  // added positionArray field so that the change to
  // multiselect position field will not break the mobile
  // app, which is using a text field for "position"
  let positionValue = null;
  if (sport.positionArray) {
    positionValue = sport.positionArray.join();
  } else if (sport.position) {
    positionValue = sport.position;
  }
  if (sport.sportId === 4 || sport.sportId === 2) {
    return {
      profile_id: user.id,
      aws_uuid: user.uuid,
      sport_id: sport.sportId,
      about_me: sport.aboutMe,
      commits: sport.commits || null,
      offers: sport.offers || null,
      position: positionValue || null,
      jersey_numbers: sport.jerseyNumbers || null,
      bio: sport.bio,
      bat: sport.bats,
      throw: sport.throws,
      primary_sport_bool: sport.primary,
      photo_url: sport.photoUrl,
      created_by: isCoach ? 'coach' : 'athlete',
    };
  } else {
    return {
      profile_id: user.id,
      aws_uuid: user.uuid,
      sport_id: sport.sportId,
      about_me: sport.aboutMe,
      commits: sport.commits || null,
      offers: sport.offers || null,
      position: positionValue || null,
      jersey_numbers: sport.jerseyNumbers || null,
      bio: sport.bio,
      primary_sport_bool: sport.primary,
      photo_url: sport.photoUrl,
      created_by: isCoach ? 'coach' : 'athlete',
    };
  }
};

export const mapUserSportApiToUi = (response) => {
  let positionArray = [];
  if (response.position) {
    positionArray = response.position.split(',');
  }
  return {
    id: response.id,
    sportId: response.sport_id,
    aboutMe: response.about_me || '',
    bio: response.bio || '',
    commits: response.commits || '',
    offers: response.offers || '',
    bats: response.bat || '',
    throws: response.throw || '',
    position: response.position || '',
    defaultSports: response.primary_sport_bool,
    positionArray,
    jerseyNumber: response.jersey_number || '',
    jerseyNumbers: response.jersey_numbers || '',
    primary: !!response.primary_sport_bool,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    photoUrl: response.photo_url,
    createdBy: response.created_by,
  };
};

const getUserHeight = (user, isMetricUnits) => {
  let height = 0;
  if (isMetricUnits) {
    if (user.heightMt) {
      height += Number(user.heightMt * 100);
    }
    if (user.heightCm) {
      height += Number(user.heightCm);
    }
    height = convertCmToInches(height);
  } else {
    if (user.heightFt) {
      height += Number(user.heightFt * 12);
    }
    if (user.heightInch) {
      height += Number(user.heightInch);
    }
  }

  return height;
};

export const mapInvitedSignUpUiToApi = (
  uuid,
  user,
  isMetricUnits,
  bothEmailAndPhone,
) => {
  let birthday;

  let email;
  let phone;
  if (user.username) {
    if (bothEmailAndPhone) {
      email = user.username;
      phone = user.phone || null;
    } else {
      const isEmailOrUsername = emailOrPhone(user.username);
      if (isEmailOrUsername === 'email') {
        email = user.username;
        phone = null;
      } else if (isEmailOrUsername === 'phone') {
        phone = user.username;
        email = null;
      } else {
        email = null;
        phone = null;
      }
    }
  } else {
    email = user.email || null;
    phone = user.phone || null;
  }

  return {
    aws_uuid: uuid,
    email,
    given_name: user.first,
    family_name: user.last,
    parent_email: user.parentsEmail ? user.parentsEmail.trim() : null,
    parent2_email: user.parent2Email ? user.parent2Email.trim() : null,
    phone,
    parent_phone: user.parentsPhone || null,
    parent_name: user.parentName || null,
    parent2_phone: user.parents2Phone || null,
    parent2_name: user.parent2Name || null,
    role_id: user.roleId,
  };
};

export const mapUserUiToApi = (
  uuid,
  user,
  isMetricUnits,
  bothEmailAndPhone,
) => {
  let birthday;
  if (user.birthdate) {
    if (typeof user.birthdate === 'string') {
      birthday = moment(user.birthdate).format('YYYY-MM-DD');
    } else {
      birthday = user.birthdate.format('YYYY-MM-DD');
    }
  } else {
    birthday = null;
  }
  let email;
  let phone;
  if (user.username) {
    if (bothEmailAndPhone) {
      email = user.username;
      phone = user.phone || null;
    } else {
      const isEmailOrUsername = emailOrPhone(user.username);
      if (isEmailOrUsername === 'email') {
        email = user.username;
        phone = null;
      } else if (isEmailOrUsername === 'phone') {
        phone = user.username;
        email = null;
      } else {
        email = null;
        phone = null;
      }
    }
  } else {
    email = user.email || null;
    phone = user.phone || null;
  }
  // convert boolean value to string 1 or 0
  let ncaaRegistered;
  if (user.ncaaRegistered) {
    ncaaRegistered = '1';
  } else {
    ncaaRegistered = '0';
  }
  const height = getUserHeight(user, isMetricUnits);
  const weight = isMetricUnits ? convertKgToLbs(user.weightKg) : user.weight;

  return {
    aws_uuid: uuid,
    email,
    given_name: user.first,
    family_name: user.last,
    birthday,
    gender: user.gender || null,
    height: height || null,
    weight: weight || null,
    parent_email: user.parentsEmail ? user.parentsEmail.trim() : null,
    parent2_email: user.parent2Email ? user.parent2Email.trim() : null,
    phone,
    parent_phone: user.parentsPhone || null,
    parent2_phone: user.parents2Phone || null,
    parent_name: user.parentsName || null,
    parent2_name: user.parent2Name || null,
    parent_first_name: user.parentFirstName || null,
    parent_last_name: user.parentLastName || null,
    role_id: user.roleId,
    grad_year: (user.gradYear && user.gradYear) || null,
    instagram: user.instagram || null,
    combine_no: user.combineNumber || null,
    jersey_number: user.jerseyNumber || null,
    ncaa_id_number: user.ncaaIdNumber || null,
    address: user.address || null,
    city: user.city || null,
    state: user.state || null,
    position: user.position || null,
    twitter: user.twitter || null,
    photo_url: user.profilePhoto || null,
    act: user.act || null,
    sat: user.sat || null,
    core_gpa: user.coreGpa || null,
    class_rank_num: user.classRank || null,
    class_rank_of: user.classSize || null,
    school_credits: user.schoolCredits || null,
    school: user.school || null,
    ncaa_registered: ncaaRegistered,
    grade: user.grade,
    zip: user.zip,
    profileDetails: user.profileDetails || {},
    team_name: user.teamName,
    isTeamNameChanged: user.isTeamNameChanged || false,
  };
};

export const mapUserAccountInfoUiToApi = (uuid, user, profile) => {
  let birthday;
  if (user.birthdate) {
    if (typeof user.birthdate === 'string') {
      birthday = moment(user.birthdate).format('YYYY-MM-DD');
    } else {
      birthday = user.birthdate.format('YYYY-MM-DD');
    }
  } else {
    birthday = null;
  }
  let email;
  let phone;
  if (user.username) {
    const isEmailOrUsername = emailOrPhone(user.username);
    if (isEmailOrUsername === 'email') {
      email = user.username;
      phone = null;
    } else if (isEmailOrUsername === 'phone') {
      phone = user.username;
      email = null;
    } else {
      email = null;
      phone = null;
    }
  } else {
    email = user.email || null;
    phone = user.phone || null;
  }
  // convert boolean value to string 1 or 0
  let ncaaRegistered;
  if (profile.ncaaRegistered) {
    ncaaRegistered = '1';
  } else {
    ncaaRegistered = '0';
  }
  let height = 0; // stored in inches
  if (user.heightFt) {
    height += Number(user.heightFt * 12);
  }
  if (user.heightInch) {
    height += Number(user.heightInch);
  }
  return {
    aws_uuid: uuid,
    email,
    given_name: user.first,
    family_name: user.last,
    birthday,
    gender: user.gender || null,
    height: height || null,
    weight: user.weight || null,
    parent_email: user.parentsEmail || null,
    phone,
    parent_phone: user.parentsPhone || null,
    parent_name: user.parentsName || null,
    role_id: profile.roleId,
    grad_year: (profile.gradYear && profile.gradYear.trim()) || null,
    jersey_number: profile.jerseyNumber || null,
    combine_no: profile.combineNumber || null,
    twitter: profile.twitter || null,
    instagram: profile.instagram || null,
    ncca_id_number: profile.nccaIdNumber || null,
    address: profile.address || null,
    city: profile.city || null,
    state: profile.state || null,
    position: profile.position || null,
    photo_url: profile.profilePhoto || null,
    act: profile.act || null,
    sat: profile.sat || null,
    core_gpa: profile.coreGpa || null,
    class_rank_num: profile.classRank || null,
    class_rank_of: profile.classSize || null,
    school_credits: profile.schoolCredits || null,
    school: profile.school || null,
    ncaa_registered: ncaaRegistered,
    grade: profile.grade,
    zip: profile.zip,
  };
};

export const customTOFixed = (num, fixed = 1) => {
  const rounded = Number(num.toFixed(fixed));
  return rounded === Math.floor(rounded) ? Math.floor(rounded) : rounded;
};

export const mapDuplicateProfileToUi = (usResponse) => {
  if (!usResponse) return null;

  return {
    first: usResponse.given_name,
    last: usResponse.family_name,
    userId: usResponse.aws_uuid,
    email: usResponse.email,
  };
};

export const mapScheduleApiToUi = (response) => {
  return {
    id : response.id,
    ownerId: response.owner_id,
    key: response.key,
    uploadedBy: response.uploaded_by,
    uploadUrl: response.upload_url,
    uploadComplete: response.upload_complete,
    schoolTeamId: response.school_team_id,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    url: response.url,
    ext: response.ext || '',
  }
}

export const mapUserApiToUi = (
  usResponse,
  schoolResponse = null,
  sports = null,
  latestSchool = null,
  enrolledSchool = null,
  teamName = null,
  schoolTeamId = null,
  isTeamNameChanged = false,
) => {
  if (!usResponse) {
    return null;
  }
  const height = Number(usResponse.height); // stored in inches
  const heightFt = Number.isNaN(height) ? null : Math.floor(height / 12);
  const heightInch = Number.isNaN(height)
    ? null
    : customTOFixed(Math.abs(height - heightFt * 12));
  let heightMt = Number.isNaN(height)
    ? null
    : Math.floor(convertInchesToMetre(height));
  let heightCm = Number.isNaN(height)
    ? null
    : customTOFixed(Math.abs(convertInchesToCm(height) - heightMt * 100));
  if (heightCm >= 100) {
    heightMt += 1;
    heightCm -= 100;
  }
  const weight = Number(usResponse.weight);
  const weightKg = Number.isNaN(weight)
    ? null
    : customTOFixed(convertLbsToKg(weight));
  return {
    id: usResponse.id,
    profileId: usResponse.profileId,
    uuid: usResponse.aws_uuid,
    step: usResponse.step,
    onboardingUrl: usResponse.onboarding_url,
    isPaidProfile: usResponse.is_paid_profile,
    isSubscribed: usResponse.is_subscribed,
    parentName: usResponse.parent_name,
    email: usResponse.email || '',
    first: usResponse.given_name || '',
    last: usResponse.family_name || '',
    name: usResponse.given_name + ' ' + usResponse.family_name || '',
    birthdate: usResponse.birthday ? moment(usResponse.birthday) : null,
    gender: usResponse.gender || '',
    heightInch: heightInch ? heightInch.toString() : '',
    heightFt: heightFt ? heightFt.toString() : '',
    weight: usResponse.weight ? usResponse.weight.toString() : '',
    weightKg: weightKg ? weightKg.toString() : '',
    parentsEmail: usResponse.parent_email || '',
    parent2Email: usResponse.parent2_email || '',
    phone: usResponse.phone || '',
    country: usResponse.phone ? '' : 'us',
    parentsPhone: usResponse.parent_phone && formatPhone(usResponse.parent_phone) || '',
    parents2Phone: usResponse.parent2_phone && formatPhone(usResponse.parent2_phone) || '',
    parentsName: usResponse.parent_name || '',
    parent2Name: usResponse.parent2_name || '',
    roleId: usResponse.role_id,
    gradYear: usResponse.grad_year ? usResponse.grad_year.toString() : '',
    grade: usResponse.grade || '',
    zip: usResponse.zip || '',
    profilePhoto: usResponse.photo_url || '',
    school: usResponse.school || '',
    combineNumber: usResponse.combine_no
      ? usResponse.combine_no.toString()
      : '',
    jerseyNumber: usResponse.jersey_number
      ? usResponse.jersey_number.toString()
      : '',
    twitter: usResponse.twitter || '',
    instagram: usResponse.instagram || '',
    ncaaIdNumber: usResponse.ncaa_id_number
      ? usResponse.ncaa_id_number.toString()
      : '',
    address: usResponse.address || '',
    city: usResponse.city || '',
    state: usResponse.state || '',
    position: usResponse.position || '',
    act: usResponse.act ? usResponse.act.toString() : '',
    sat: usResponse.sat ? usResponse.sat.toString() : '',
    coreGpa: usResponse.core_gpa
      ? parseGPA(usResponse.core_gpa.toString())
      : '',
    classRank: usResponse.class_rank_num
      ? usResponse.class_rank_num.toString()
      : '',
    classSize: usResponse.class_rank_of
      ? usResponse.class_rank_of.toString()
      : '',
    schoolCredits: usResponse.school_credits
      ? usResponse.school_credits.toString()
      : '',
    ncaaRegistered: !!usResponse.ncaa_registered,
    sports: usResponse._embedded
      ? usResponse._embedded.userSports.map((response) =>
          mapUserSportApiToUi(response),
        )
      : sports || [],
    coachCareerCoaching: usResponse.coach_career_coaching,
    coachCareerPlaying: usResponse.coach_career_playing,
    coachCollege: usResponse.coach_college,
    coachHighSchool: usResponse.coach_high_school,
    coachHobbies: usResponse.coach_hobbies,
    coachKids: usResponse.coach_kids,
    coachPosition: usResponse.coach_position,
    coachSpouse: usResponse.coach_spouse,
    privacy_academic: !!usResponse.privacy_academic,
    privacy_all: !!usResponse.privacy_all,
    privacy_awards: !!usResponse.privacy_awards,
    privacy_endorsements: !!usResponse.privacy_endorsements,
    privacy_media: !!usResponse.privacy_media,
    privacy_combine_records: !!usResponse.privacy_combine_records,
    privacy_bio: !!usResponse.privacy_bio,
    privacy_network: !!usResponse.privacy_network,
    privacy_stats: !!usResponse.privacy_stats,
    privacy_tests: !!usResponse.privacy_tests,
    privacy_weight: !!usResponse.privacy_weight,
    privacy_height: !!usResponse.privacy_height,
    schools: schoolResponse || [],
    latestSchool,
    enrolledSchool,
    vanityUrl: usResponse.vanityUrl || '',
    twitterHandle: usResponse.twitter || '',
    heightMt: heightMt ? heightMt.toString() : '',
    heightCm: heightCm ? heightCm.toString() : '',
    hourMinute: '0:00',
    invited: usResponse.invited,
    registered: usResponse.registered,
    createdAt: moment(usResponse.created_at),
    userSport: usResponse.user_sports
      ? usResponse.user_sports.map((response) => mapUserSportApiToUi(response))
      : [],
    profileDetails: usResponse.profile_details || {},
    teamName: teamName || null,
    schoolTeamId: schoolTeamId || null,
    isTeamNameChanged: isTeamNameChanged,
    // coachTitle: usResponse.coach_title,
    // coachSecondaryPhone: usResponse.coach_secondary_phone,
    // coachSecondaryEmail: usResponse.coach_secondary_email,
    // coachOrganizationWebsite: usResponse.organization_website
  };
};

export const mapProfileDataApiToUi = (response) => {
  const profileData = {};
  response.profile_data.forEach((data) => {
    profileData[data.sport.id] = {
      height: data.height,
      weight: data.weight,
      stats: data.season_stats,
      vcRating: {
        strength: data.strength_rating,
        performance: data.performance_rating,
        rating: data.vc_rating,
      },
    };
    profileData.performanceClass = data.athlete_performance_class;
  });
  return profileData;
};
