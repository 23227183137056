import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const PhotosCircleButton = ({
  iconName,
  className,
  isFavorite,
  ...props
}) => (
  <button className={[isFavorite ? 'UxCommon_PhotosCircleButtonF' : 'UxCommon_PhotosCircleButton', className || ''].join(' ')} {...props}>
    <Icon iconName={iconName} />
  </button>
);
PhotosCircleButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default PhotosCircleButton;
