import { asyncStartAction } from '../../../../store/actions/ui/async';

export const AWARDS_GET_COMBINES = 'coachAwards.getCombines';
export const awardsGetCombines = (schoolIds) => (dispatch) => {
  dispatch(asyncStartAction(AWARDS_GET_COMBINES));
  dispatch({
    type: AWARDS_GET_COMBINES,
    schoolIds
  });
};

export const AWARDS_GET_COMBINES_AWARDS = 'coachAwards.getCombinesAwards';
export const awardsGetCombinesAwards = (schoolCombine,schoolCombineIds, combineIds) => (dispatch) => {
  dispatch(asyncStartAction(AWARDS_GET_COMBINES_AWARDS));
  dispatch({
    type: AWARDS_GET_COMBINES_AWARDS,
    schoolCombine,
    schoolCombineIds,
    combineIds,
    dispatch,
  });
};

export const AWARDS_GET_COMBINE_AWARDS = 'coachAwards.getCombineAwards';
export const awardsGetCombineAwards = (combineIds,schoolId) => (dispatch) => {
  dispatch(asyncStartAction(AWARDS_GET_COMBINE_AWARDS));
  dispatch({
    type: AWARDS_GET_COMBINE_AWARDS,
    combineIds,
    schoolId,
    dispatch,
  });
};
