import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { VERIFY_EMAIL_PHONE } from './account.actions';
import { RESEND_EMAIL_VALIDATION, RESEND_PHONE_VALIDATION } from '../../../../store/actions/ui/uxEditProfile';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === VERIFY_EMAIL_PHONE) {
        return 'Loading ...';
      }
      if (action.model === RESEND_EMAIL_VALIDATION) {
        return 'Resending ...';
      }
      if (action.model === RESEND_PHONE_VALIDATION) {
        return 'Resending ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ([
        VERIFY_EMAIL_PHONE,
        RESEND_EMAIL_VALIDATION,
        RESEND_PHONE_VALIDATION,
      ].includes(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === VERIFY_EMAIL_PHONE) {
        return action.error.message || 'We encountered an error, please try again';
      }
      return state;
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.model === VERIFY_EMAIL_PHONE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const successMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === VERIFY_EMAIL_PHONE) {
        return action.data.successMsg;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_START:
      if (action.model === VERIFY_EMAIL_PHONE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  errorMsg,
  successMsg,
});

export default reducer;
