import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/SignIn/SignIn';
import { signInFormInit } from '../../store/actions/ui/welcome';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { routeChangedAlert } from '../../store/actions/ui/routes';

const getProfile = (state) => {
  let profile = null;
  if (state.data.cognito.uuid) {
    profile = state.data.user.profiles[state.data.cognito.uuid];
  }
  return profile || null;
};


const mapStateToProps = state => ({
  needsInit: state.ui.welcome.signIn.formNeedsInit,
  rrfForm: state.forms.forms.login,
  formGeneralError: state.ui.welcome.signIn.formError,
  loggedIn: !!state.data.cognito.token,
  profile: getProfile(state),
  state,
  toRedirectUrl: state.ui.app.routes.toRedirectUrl,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    formInit: () => {
      dispatch(actions.setInitial('forms.login'));
      dispatch(actions.reset('forms.login'));
      dispatch(signInFormInit());
    },
    submit: (isMobile=false) => {
      let referrer = '';
      if (ownProps.location && ownProps.location.state) {
        referrer = ownProps.location.state.from;
      }
      dispatch(formSubmit('forms.login', { from: referrer, isMobile }));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
