import React, {useEffect} from "react";
import {Routes} from "../../../../store/actions/ui/routes";
import pathToRegexp from "path-to-regexp";
import {Link, Route, Switch} from "react-router-dom";
import Icon from "../../common/Icon";
import EditCreateCombineTemplate from "./EditCreateCombineTemplate/EditCreateCombineTemplate.component";
import {useDispatch, useSelector} from "react-redux";
import {getCombineStandardTestCategories} from "../../+store/combine";
import LoadingIndicator from "../../common/LoadingIndicator";

const CombineTemplateComponent = ({
                                      history, loading, loadingMsg, match,
                                  }) => {
    const combineTemplate = useSelector(state => state.modules.coachWorld.combine.combineTemplate);
    const dispatch = useDispatch();

    const {combineId, schoolId} = match.params;

    useEffect(() => {
        dispatch(getCombineStandardTestCategories(combineId));
    }, []);

    const renderForm = (props) => {
        return (<React.Fragment>
                <div className="CombineTemplateForm_TitleRow">
                    <div className="CombineTemplate_Title_Text">
                        <Link
                            to={`/${Routes.school}/${schoolId}/${Routes.combine}/${combineId}`}
                            className="CombineTemplate_Title_TextLink"
                        >
                            <Icon iconName="chevron-left" style={{marginRight: '15px'}}/>
                            {'Select Combine Events'}
                        </Link>
                    </div>
                </div>
                <div className="CombineCard_Card_Container">
                    <EditCreateCombineTemplate
                        {...props}
                        sportId={combineTemplate.sportId}
                        history={history}
                    />
                </div>
            </React.Fragment>);
    }

    if (!combineTemplate.standardTestCategories || loading) {
        return (<div className="CombineTemplateWrapper" style={{background: params ? 'white' : ''}}>
                <LoadingIndicator
                    loading={loading}
                    loadingMsg={loadingMsg}
                    positionStyle={{margin: 'auto'}}
                />
            </div>)
    }

    const re = pathToRegexp(`/${Routes.coach}/${Routes.dashboard}/:school/${Routes.editCombineTemplate}/:templateId/:combineId`);
    const params = re.exec(window.location.pathname);
    return (<div className="CombineTemplateWrapper" style={{background: params ? 'white' : ''}}>
            <LoadingIndicator
                loading={loading}
                loadingMsg={loadingMsg}
                positionStyle={{margin: 'auto'}}
            />
            <Switch>
                <Route
                    path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.editCombineTemplate}/:templateId/:combineId`}
                    render={renderForm}/>
            </Switch>
        </div>);

}

export default CombineTemplateComponent;
