import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavBarLink = ({
  to,
  match,
  children,
  className,
  location,
  onClick,
  state
}) => {
  let search;
  let hash;
  if (location) {
    ({ search, hash } = location);
  }
  return (
    <Link
      to={{
        search,
        hash,
        pathname: to,
        state
      }}
      className={`CoachNavBarLink ${className || ''} ${to === match.url ? 'active' : ''}`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
NavBarLink.propTypes = {
  to: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  location: PropTypes.object,
  onClick: PropTypes.func,
  state:PropTypes.object,
};

export default NavBarLink;

