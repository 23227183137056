import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import loadingGif from '../../../../../../assets/images/ajax-loader.gif';

class LoadingIndicatorCard extends PureComponent {
  static propTypes = {
    loadingMsg: PropTypes.string,
    loading: PropTypes.bool,
    positionStyle: PropTypes.object,
  }

  state = {
    step: 0,
  }

  componentDidMount() {
    this.shouldUpdate();
    this.interval = setInterval(() => {
      const { step } = this.state;
      this.setState({
        step: step < 4 ? step + 1 : -1,
      });
    }, 200);
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldUpdate = () => {}

  render() {
    const { loading, loadingMsg, positionStyle } = this.props;
    const { step } = this.state;
    if (!loading) return null;
    return (
      <div className="CoachLoadingIndicatorC">
        <div className="CoachLoadingIndicatorC_Inner" style={positionStyle || { margin: 'auto' }}>
          <div className="CoachLoadingIndicatorC_InnerCircle">
            {/* <img src={loadingGif} alt="Loading ..." /> */}
            <div className={`CoachLoadingIndicatorC_One one ${step > 0 && 'show'}`} />
            <div className={`CoachLoadingIndicatorC_One two ${step > 1 && 'show'}`} />
            <div className={`CoachLoadingIndicatorC_One three ${step > 2 && 'show'}`} />
          </div>
          <div className="CoachLoadingIndicatorC_Msg">{loadingMsg}</div>
        </div>
      </div>
    );
  }
}

export default LoadingIndicatorCard;
