import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, LoadingSpinner, Line } from '../../UxCommon';
import {
  Headline,
} from '../../UxCommon/Form';

import VanityUrl from '../../../containers/UxEditProfile/VanityUrl';
import TwitterHandle from '../../../containers/UxEditProfile/TwitterHandle';

class Personal extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
  }
  render() {
    const {
      loading,
      loadingMsg,
      profile,
      match,
    } = this.props;

    const isLoading = !profile || loading;
    return (
      <div className="UxEditProfile_VanityUrl">
        <LoadingSpinner
          loading={isLoading}
          loadingMsg={loadingMsg}
          style={{
            position: 'relative',
            height: 200,
            zIndex: 'unset',
          }}
        />
        <Container>
          {
            !isLoading && (
              <Row className="justify-content-center">
                <Col className="col-auto UxEditProfile_VanityUrl_Col">
                  <Headline>Personal</Headline>
                  <TwitterHandle match={match}/>
                  <VanityUrl match={match}/>
                </Col>
              </Row>
            )
          }
          <Line style={{ paddingTop: '30px' }} />
        </Container>
      </div>
    );
  }
}

export default Personal;
