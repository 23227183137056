import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getTokenFragmentMergeMap, manualCancelApiCallFragment } from '../uxProfile/utils';
import { asyncFinishAction, asyncStartAction } from '../async';
import { checkIfInviteIsValid } from '../../data/user/profile';

export const CHECK_IF_INVITE_IS_VALID = 'CHECK_IF_INVITE_IS_VALID';
export const isInviteValid = (userTeamId,token) => (dispatch) => {
  dispatch(asyncStartAction(CHECK_IF_INVITE_IS_VALID));
  dispatch({ type: CHECK_IF_INVITE_IS_VALID, userTeamId, token });
};

const checkIfInviteIsValidEpic = action$ => (
  action$.pipe(
    ofType(CHECK_IF_INVITE_IS_VALID),
    getTokenFragmentMergeMap(),
    mergeMap(({ action }) => (
      checkIfInviteIsValid(action.userTeamId, action.token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'checkIfInviteIsValid',
      ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getProfileByEmail', {
          isValid: result.response[0].is_token_valid,
          isTokenExist: !!result.response[0],
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export const checkInvitationEpic = combineEpics(checkIfInviteIsValidEpic);
