import { combineEpics } from 'redux-observable';

import reducers from './districtLeaderboard.reducers';
import districtLeaderboardEpics from './districtLeaderboard.epics';

const epics = combineEpics(districtLeaderboardEpics);

export { reducers, epics };
export * from './districtLeaderboard.actions';

export * from './districtLeaderboard.constants';
