
import {
  SET_VIDEOS_NEEDS_LOAD,
  SET_PHOTOS_NEEDS_LOAD,
  LOAD_MEDIA,
  UPLOAD_PHOTO,
  UPLOAD_VIDEO,
  CATEGORY_SELECT_ALL,
  CATEGORY_SELECT_PHOTOS,
  CATEGORY_SELECT_VIDEOS,
  SHOW_DELETE_DIALOG,
  HIDE_DELETE_DIALOG,
  DELETE_PHOTO,
  DELETE_VIDEO,
  ALBUM_FILTER_SELECTED,
  ALBUM_FILTER_UNSELECTED,
  MARK_FAVORITE,
  MULTI_DELETE_EXECUTE,
  TOGGLE_FILTER_LIST,
  TAG_FILTER_SELECTED,
  TAG_FILTER_UNSELECTED,
  HIDE_PHOTO_FILTER_DIALOG,
  SHOW_PHOTO_FILTER_DIALOG,
  CLEAR_ALL_PHOTO_FILTERS,
  UPDATE_ATHLETE_VIDEOID,
  CLEAR_ATHLETE_VIDEOID,
} from '../../../../actions/ui/uxProfile/photosVideos';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../actions/ui/async';
import { MARK_PHOTO_FAVORITE_SUC } from '../../../../actions/data/photo';
import { MARK_VIDEO_FAVORITE_SUC } from '../../../../actions/data/photo/actions';

const needsToLoadVideos = (state = false, action) => {
  switch (action.type) {
    case SET_VIDEOS_NEEDS_LOAD:
      return true;
    case ASYNC_FINISH:
      if (action.model === LOAD_MEDIA) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const isCoachUploadVideoId = (state='', action) =>{
  switch(action.type){
    case ASYNC_FINISH:
      if(action.model === UPDATE_ATHLETE_VIDEOID){
        return action.data;
      }
      if(action.model === CLEAR_ATHLETE_VIDEOID){
        return state;
      }
    default:
      return state;
  }
}

const needsToLoadPhotos = (state = false, action) => {
  switch (action.type) {
    case SET_PHOTOS_NEEDS_LOAD:
      return true;
    case ASYNC_FINISH:
      if (action.model === LOAD_MEDIA) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

const initialLoading = {
  videos: true,
  photos: true,
  loading: true,
  msg: '',
};
const loadingMsg = (loadingPhotos, loadingVideos) => {
  if (loadingPhotos && loadingVideos) {
    return 'Loading photos and videos';
  } else if (loadingPhotos) {
    return 'Loading photos';
  } else if (loadingVideos) {
    return 'Loading videos';
  }
  return '';
};
const loading = (state = initialLoading, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === LOAD_MEDIA) {
        return {
          videos: true,
          photos: true,
          loading: true,
          msg: loadingMsg(true, true),
        };
      }
      if (action.model === UPLOAD_PHOTO) {
        return {
          videos: state.videos,
          photos: true,
          loading: true,
          msg: loadingMsg(true, state.videos),
        };
      }
      if (action.model === UPLOAD_VIDEO) {
        return {
          videos: true,
          photos: state.photos,
          loading: true,
          msg: loadingMsg(state.photos, true),
        };
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === LOAD_MEDIA) {
        return {
          videos: false,
          photos: false,
          loading: false,
          msg: loadingMsg(false, false),
        };
      }
      if (action.model === UPLOAD_PHOTO) {
        return {
          videos: state.videos,
          photos: false,
          loading: state.videos,
          msg: loadingMsg(false, state.videos),
        };
      }
      if (action.model === UPLOAD_VIDEO) {
        return {
          videos: false,
          photos: state.photos,
          loading: state.photos,
          msg: loadingMsg(state.photos, false),
        };
      }
      return state;
    default:
      return state;
  }
};

const selectedCategory = (state = 'all', action) => {
  switch (action.type) {
    case CATEGORY_SELECT_ALL:
    case CLEAR_ALL_PHOTO_FILTERS:
      return 'all';
    case CATEGORY_SELECT_PHOTOS:
      return 'photos';
    case CATEGORY_SELECT_VIDEOS:
      return 'videos';
    default:
      return state;
  }
};

const deleteDialogsShown = (state = [], action) => {
  switch (action.type) {
    case SHOW_DELETE_DIALOG:
      return [...state, action.id];
    case HIDE_DELETE_DIALOG:
      return state.filter(id => (id !== action.id));
    default:
      return state;
  }
};

const mediaCardsLoading = (state = [], action) => {
  switch (action.type) {
    case ASYNC_START:
      if ((action.model === DELETE_PHOTO) ||
      (action.model === DELETE_VIDEO)) {
        return [...state, {
          id: action.attrs.id,
          msg: action.msg,
        }];
      }
      if (action.model === MULTI_DELETE_EXECUTE) {
        return [...state, ...action.attrs.ids.map(id => ({
          id,
          msg: action.msg,
        }))];
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === DELETE_PHOTO) ||
      (action.model === DELETE_VIDEO)) {
        return state.filter(obj => (obj.id !== action.data.id));
      }
      return state;
    default:
      return state;
  }
};

const selectedAlbumFilters = (state = [], action) => {
  switch (action.type) {
    case ALBUM_FILTER_SELECTED:
      return [...state, action.albumId];
    case ALBUM_FILTER_UNSELECTED:
      return state.filter(albumId => albumId !== action.albumId);
    case CLEAR_ALL_PHOTO_FILTERS:
      return [];
    default:
      return state;
  }
};

const showFilterList = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_FILTER_LIST:
      return !state;
    default:
      return state;
  }
};

const tempFavorites = (state = [], action) => {
  switch (action.type) {
    case MARK_FAVORITE:
      return [...state.filter(obj => (obj.id !== action.media.id)), {
        id: action.media.id,
        favorite: Object.values(action.sportIds).reduce((acc, curr) => acc || curr),
        favorites: action.sportIds,
      }];
    case MARK_PHOTO_FAVORITE_SUC:
      return state.filter(obj => (obj.id !== action.photo.id));
    case MARK_VIDEO_FAVORITE_SUC:
      return state.filter(obj => (obj.id !== action.video.id));
    default:
      return state;
  }
};

const selectedTagsFilters = (state = [], action) => {
  switch (action.type) {
    case TAG_FILTER_SELECTED:
      return [...state, action.tagId];
    case TAG_FILTER_UNSELECTED:
      return state.filter(tagId => tagId !== action.tagId);
    case CLEAR_ALL_PHOTO_FILTERS:
      return [];
    default:
      return state;
  }
};

const isPhotoFilterDialogShown = (state = false, action) => {
  switch (action.type) {
    case SHOW_PHOTO_FILTER_DIALOG:
      return true;
    case HIDE_PHOTO_FILTER_DIALOG:
      return false;
    default:
      return state;
  }
};

const reducers = {
  needsToLoadVideos,
  needsToLoadPhotos,
  isCoachUploadVideoId,
  loading,
  selectedCategory,
  deleteDialogsShown,
  mediaCardsLoading,
  selectedAlbumFilters,
  tempFavorites,
  showFilterList,
  selectedTagsFilters,
  isPhotoFilterDialogShown,
};

export default reducers;
