import {combineReducers} from 'redux';
import {combineEpics} from 'redux-observable';

import {epics as coachWorldSharedEpics, reducers as coachWorld,} from './coachWorld';
import {epics as rosterEpics, reducers as roster,} from './roster';
import {epics as schoolCombineEpics, reducers as combine,} from './combine';
import {epics as reportEpics, reducers as report,} from './report';
import {epics as leaderboardEpics, reducers as leaderboard,} from './leaderboard';
import {epics as accountEpics, reducers as account,} from './account';
import {epics as awardEpics, reducers as award,} from './award';
import {epics as collegeContactEpics, reducers as collegeContact,} from './collegeContact';
import {epics as nationalLeaderboardEpics, reducers as nationalLeaderboard,} from './nationalLeaderboard';
import {epics as northEastLeaderboardEpics, reducers as northEastLeaderboard,} from './northEastLeaderboard';
import {epics as allTimeLeaderboardEpics, reducers as allTimeLeaderboard,} from './allTimeLeaderboard';
import {epics as districtLeaderboardEpics, reducers as districtLeaderboard,} from './districtLeaderboard';

export const coachWorldEpics = combineEpics(
  coachWorldSharedEpics,
  rosterEpics,
  schoolCombineEpics,
  reportEpics,
  leaderboardEpics,
  accountEpics,
  awardEpics,
  nationalLeaderboardEpics,
  collegeContactEpics,
  northEastLeaderboardEpics,
  allTimeLeaderboardEpics,
  districtLeaderboardEpics,
);

export default combineReducers({
  coachWorld,
  roster,
  combine,
  report,
  leaderboard,
  account,
  award,
  nationalLeaderboard,
  collegeContact,
  northEastLeaderboard,
  allTimeLeaderboard,
  districtLeaderboard,
});

export * from './coachWorld/coachWorld.actions';
export * from './coachWorld/coachWorld.selectors';
export * from './roster/roster.actions';
export * from './roster/roster.constants';
export * from './leaderboard/leaderboard.constants';
