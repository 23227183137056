import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetProfile';
import { playerProfileGet, playerDataGet } from '../../../store/actions/ui/uxProfile/profile';
import { getCurrentSportId, getCurrentUuid } from '../Utils';

const mapStateToProps = (state, ownProps) => ({
  uuid: getCurrentUuid(state, ownProps),
  sportId: getCurrentSportId(state),
  loading: !!state.ui.uxProfile.playerProfile.loadingMsg,
  loadingMsg: state.ui.uxProfile.playerProfile.loadingMsg,
});

const mapDispatchToProps = dispatch => ({
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  getProfileData: (uuid, sportId) => {
    dispatch(playerDataGet(uuid, sportId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
