import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';

const SelectControl = (props) => {
  const {
    label,
    model,
    type,
    help,
    rrfField,
    placeholder,
    options,
    errors,
    helpClass,
    disabled,
    showPlaceHolder=false,
    ...otherProps
  } = props;
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.valid ? '' : 'error',
        ].join(' ')}
      >
        <div className="UxCommon_Form_ErrorMarker" />
        <div className="UxCommon_Form_LabelWrapper">
          <label
            className={[
              'UxCommon_Form_Label',
              rrfField.value && rrfField.value.length ? 'show' : '',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        <div className={[
            'UxCommon_Form_Input SelectInput',
            rrfField.value && rrfField.value.length ? 'not-empty' : '',
          ].join(' ')}
        >
          <Control.select
            className="UxCommon_Form_SelectSelect"
            model={model}
            id={model}
            disabled={disabled}
            value={rrfField.value === null ? '' : rrfField.value}
            {...otherProps}
          >
           {showPlaceHolder ? <option value="">{placeholder || label}</option>
           : <option value="" disabled hidden>{placeholder || label}</option>}
            {
              options.map((option, index) => (
                <option
                  key={index}
                  value={option.value}
                >
                  {option.text}
                </option>
              ))
            }
          </Control.select>
        </div>
      </div>
      {
        help &&
          <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
            {help}
          </HelpPopper>
      }
      <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
    </div>
  );
};
SelectControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  help: PropTypes.any,
  options: PropTypes.array.isRequired,
  rrfField: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default SelectControl;
