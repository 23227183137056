import React from 'react';
import PropTypes from 'prop-types';

import StatsCustomControl from '../StatsCustomControl/StatsCustomControl';
import { testPositiveFloatChars } from '../../../../utils/parsers';

class StatsHundrethsControl extends React.PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    rrfField: PropTypes.object,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
    placesAfterDecimal: PropTypes.number.isRequired,
    help: PropTypes.any.isRequired,
    rrfFormChange: PropTypes.func.isRequired,
    onReturnKeyPress: PropTypes.func.isRequired,
    stdStatCode: PropTypes.string
  }

  getInitialFormattedValue = (defaultValue) => {
    if (!defaultValue) return '';
    return `${defaultValue}`;
  }

  parser = (v, cursorPos) => {
    const { placesAfterDecimal, stdStatCode } = this.props;
    const dotIndex = v.indexOf('.');
    const maxPlacesBeforeDecimal = 4;
    
    if (placesAfterDecimal && dotIndex >= 0) {
      if(stdStatCode === "IP" || stdStatCode == "" )
      {
        return {
          parsedText: `${v.slice(0, dotIndex).replace(/[^0-9]+/g, '').slice(0, maxPlacesBeforeDecimal)
          }.${v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal).replace(/[^0-2]+/g, '')}`,
          cursorPos,
        };
      } else {
        return {
          parsedText: `${v.slice(0, dotIndex).replace(/[^0-9]+/g, '').slice(0, maxPlacesBeforeDecimal)
          }.${v.slice(dotIndex + 1, dotIndex + 1 + placesAfterDecimal).replace(/[^0-9]+/g, '')}`,
          cursorPos,
        };
      }
      
    } 
    return {
      parsedText: v.replace(/[^0-9]+/g, '').slice(0, maxPlacesBeforeDecimal),
      cursorPos,
    };
  };

  normalizeValue = v => v;

  render() {
    const {
      model,
      defaultValue,
      className,
      rrfField,
      help,
      rrfFormChange,
      onReturnKeyPress,
    } = this.props;
    return (
      <StatsCustomControl
        className={className}
        model={model}
        rrfField={rrfField}
        defaultValue={defaultValue}
        parser={this.parser}
        allowedCharacters={testPositiveFloatChars}
        help={help}
        normalizeValue={this.normalizeValue}
        getInitialFormattedValue={this.getInitialFormattedValue}
        rrfFormChange={rrfFormChange}
        onReturnKeyPress={onReturnKeyPress}
      />
    );
  }
}

export default StatsHundrethsControl;
