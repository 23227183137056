import React, { PureComponent, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../UxCommon';
import { useParams } from 'react-router-dom';

const Collapsable = ({
  title,
  children,
  className,
  style,
  useFluidContainer,
  useTightTop,
  keepInside,
  topRowStyle,
  refId,
}) => {
  const myRef = useRef();
  const wrapperRef = useRef();
  const { tab } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [maxHeight, setMaxHeight] = useState(10000);

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    onUpdate();
  });

  useEffect(() => {
    if (myRef.current) {
      myRef.current.id == ('sportId' || 'sports') ? setCollapsed(true) : '';
      wrapperRef.current.style.overflow = 'hidden';
      myRef.current.style.transition = 'none';
    }
  }, [tab]);

  const onCollapseClick = () => {
    if (collapsed) {
      setCollapsed(false);
      const cssSignals = [
        'webkitTransitionEnd',
        'transitionend',
        'msTransitionEnd',
        'oTransitionEnd',
      ];
      const transition = () => {
        wrapperRef.current.style.overflow = 'visible';
        cssSignals.forEach((signal) => {
          wrapperRef.current.removeEventListener(signal, transition);
        });
      };
      cssSignals.forEach((signal) => {
        wrapperRef.current.addEventListener(signal, transition);
      });
    } else {
      setCollapsed(true);
      wrapperRef.current.style.overflow = 'hidden';
    }
  };

  const onUpdate = () => {
    if (myRef.current) {
      myRef.current.id = refId;
      setMaxHeight(myRef.current.scrollHeight);
    }
  };

  const onMount = () => {
    if (myRef.current) {
      myRef.current.id = refId;
      if (
        (myRef.current.id == 'sportId' &&
          (tab == 'rosters' || tab == 'combines')) ||
        myRef.current.id == 'sports'
      ) {
        wrapperRef.current.style.overflow = 'hidden';
        setCollapsed(true);
        myRef.current.style.transition = 'none';
      } else {
        setMaxHeight(myRef.current.scrollHeight);
      }
    }
  };

  return (
    <div
      className={[
        useFluidContainer ? 'container-fluid' : 'container',
        'UxCommon_Collapsable',
        useTightTop ? 'UxCommon_Collapsable_TightTop' : '',
        keepInside ? 'UxCommon_Collapsable_KeepInside' : '',
        className || '',
      ].join(' ')}
      style={style || {}}>
      <Row className="UxCommon_Collapsable_TopRow" style={topRowStyle || {}}>
        <Col className="UxCommon_Collapsable_LeftCol">
          <button
            className="UxCommon_PP_Collapsable_Button"
            onClick={onCollapseClick}>
            {collapsed ? (
              <div className="UxCommon_Collapsable_Plus" />
            ) : (
              <div className="UxCommon_Collapsable_Minus" />
            )}
          </button>
          <div className="UxCommon_Collapsable_Txt">{title}</div>
        </Col>
        {!collapsed && !Array.isArray(children) && (
          <div className="col-md-12 col-lg-auto UxCommon_Collapsable_LeftCol">
            {children && children[2]}
            {Array.isArray(children) ? children[0] : children}
          </div>
        )}
        {!collapsed && children.length > 1 && (
          <div className="UxCommon_Collapsable_LeftCol">
            {children && children[2]}
            {Array.isArray(children) ? children[0] : children}
          </div>
        )}
      </Row>
      {Array.isArray(children) && children.length > 1 && (
        <Row>
          <div
            ref={wrapperRef}
            className="col UxCommon_PP_Collapsable_ContentWrapper">
            <div
              ref={myRef}
              className="UxCommon_PP_Collapsable_Content"
              style={{
                marginTop: collapsed ? -maxHeight : 0,
                transition: collapsed ? 'none' : 'margin-top ease-out .2s',
              }}>
              {children[1]}
            </div>
          </div>
        </Row>
      )}
    </div>
  );
};

Collapsable.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  useFluidContainer: PropTypes.bool,
  useTightTop: PropTypes.bool,
  keepInside: PropTypes.bool,
  topRowStyle: PropTypes.object,
  refId: PropTypes.string,
};

export default Collapsable;
