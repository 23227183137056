import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { actions } from 'react-redux-form';

import {
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_PENDING,
  formSubmitFailed,
  formSubmitPending,
} from './actions';
import {validatePhone, validateRequired} from './validators';
import { formatPhone } from '../../../../utils';

// import { validatePhone } from './validators';
// import { formatPhone } from '../../../../utils';

export const getRrfChangeValue = (action) => {
  if (action.type === 'rrf/change') {
    return action.value;
  }
  if (action.type === 'rrf/batch') {
    const act = action.actions.find(a => a.type === action.type);
    return act.value;
  }
  return '';
};

export const ofTypeOfModel = (type, model) => source =>
  source.pipe(filter((action) => {
    if ((action.type === type) && (action.model.indexOf(model) === 0)) {
      return true;
    }
    if (action.type === 'rrf/batch') {
      const act = action.actions.find(a => a.type === type);
      if (act && (act.model.indexOf(model) === 0)) {
        return true;
      }
    }
    return false;
  }));

export const ofSubmitIntent = model => source =>
  source.pipe(filter((action) => {
    if ((action.type === FORM_SUBMIT_INTENT)
    && (action.model === model)) {
      return true;
    }
    if ((action.type === 'rrf/addIntent')
    && (action.model === model)
    && (action.intent.type === 'submit')) {
      return true;
    }
    return false;
  }));

export const isPendingIsModel = model => source =>
  source.pipe(
    ofType(FORM_SUBMIT_PENDING),
    filter(action => (action.model === model)),
  );

export const isFormValid = form => source => source.pipe(filter(() => form().$form.valid));
export const isFormNotValid = form => source => source.pipe(filter(() => (!form().$form.valid)));

export const ofSubmitRrf = model => source =>
  source.pipe(filter((action) => {
    if ((action.type === 'rrf/addIntent')
    && (action.model === model)
    && (action.intent.type === 'submit')) {
      return true;
    }
    return false;
  }));

export const ofTypeOfModelOrSubmit = (type, model) => source =>
  source.pipe(filter((action) => {
    if ((action.type === 'rrf/addIntent') && (action.intent.type === 'submit')) {
      return true;
    }
    if ((action.type === type) && (action.model.indexOf(model) === 0)) {
      return true;
    }
    if (action.type === 'rrf/batch') {
      const act = action.actions.find(a => a.type === type);
      if (act && (act.model.indexOf(model) === 0)) {
        return true;
      }
    }
    return false;
  }));


export const dummy = '';

export const formValidationEpicsFactory = (validationDef, formName) =>
  Object.keys(validationDef)
    .map(key => ((action$, state$) => action$.pipe(
      filter(() => (key !== 'forms.joinNow.password')),
      ofTypeOfModel('rrf/change', key),
      debounceTime(500),
      map(action => actions.setErrors(
        key,
        Object.assign({}, ...validationDef[key]
          .map(validator => validator(action.value, state$.value.forms[formName]))),
      )),
    )));

export const validationOnSubmitEpicFactory = (validationDef, modelName, formName) =>
  (action$, state$) => action$.pipe(
    ofSubmitIntent(modelName),
    switchMap(action => of(
      ...Object.keys(validationDef).map(key => actions.setErrors(
          key,
          Object.assign({}, ...validationDef[key]
            .map((validator) => {
              const name = key.replace(`${modelName}.`, '');
                if (key == 'forms.account.email' &&
                  (action.attrs
                    && action.attrs.emailRequired != undefined)
                ){
                  return { required: '' }
                  }

            return validator(state$.value.forms[formName][name], state$.value.forms[formName]);
            })),
        )
      ),
      formSubmitPending(modelName, action.attrs),
    )),
  );

export const validationFailedEpicFactory = (modelName, formName) =>
  (action$, state$) => action$.pipe(
    ofType(FORM_SUBMIT_PENDING),
    filter(action => (action.model === modelName)),
    filter(() => !state$.value.forms.forms[formName].$form.valid),
    map(action => formSubmitFailed(modelName, action.attrs)),
  );

export const isPendingIsValid = (state$, modelName, formName) => source =>
  source.pipe(
    ofType(FORM_SUBMIT_PENDING),
    filter(action => (action.model === modelName)),
    filter(() => state$.value.forms.forms[formName].$form.valid),
  );

/*
  getUsername is a function that takes state as a parameter and returns username
*/
const prevUsername = {};
export const usernameOnChangeEpic = (model, getUsername) => (action$, state$) => (
  action$.pipe(
    filter((action) => {
      const user = getUsername(state$.value);
      if (user === prevUsername[model]) {
        return false;
      }
      prevUsername[model] = user;
      if (action.type === 'rrf/change') {
        return (
          (action.model === model)
          && user
          && (validatePhone()(user).phone.length === 0)
        );
      }
      if ((action.type === 'rrf/change')
      && (action.actions.find(a => a.type === 'rrf/blur'))) {
        return (
          (action.model === model)
          && user
          && (validatePhone()(user).phone.length === 0)
        );
      }
      return false;
    }),
    map(() => actions.change(model, formatPhone(getUsername(state$.value)))),
    // map(() => actions.change(model, getUsername(state$.value))),
  )
);
