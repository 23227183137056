import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import combinesReducers from './combine.reducers';
import combinesEpics from './combine.epics';
import templateEpics from './combineTemplates.epics';
import templatesReducers from './combineTemplates.reducers';

const epics = combineEpics(combinesEpics, templateEpics);
const reducers = combineReducers({
  ...combinesReducers,
  ...templatesReducers,
});

export {
  reducers,
  epics,
};

export * from './combine.actions';
export * from './combineTemplates.actions';
export * from './combine.constants';
