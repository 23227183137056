import { asyncErrorAction, asyncStartAction,asyncFinishAction } from '../../async';
import { fixRotation } from '../../../../../containers/UxCommon/ImageUtils';

export const TOGGLE_FILTER_LIST = 'TOGGLE_FILTER_LIST';
export const toggleFilterList = () => ({
  type: TOGGLE_FILTER_LIST,
});

export const SET_PHOTOS_NEEDS_LOAD = 'photosVideos.photos.setNeedsLoad';
export const setPhotosNeedsLoad = () => ({
  type: SET_PHOTOS_NEEDS_LOAD,
});

export const SET_VIDEOS_NEEDS_LOAD = 'photosVideos.videos.setNeedsLoad';
export const setVideosNeedsLoad = () => ({
  type: SET_VIDEOS_NEEDS_LOAD,
});

export const LOAD_MEDIA = 'photosVideos.media.load';
export const loadMedia = uuid => (dispatch) => {
  dispatch(asyncStartAction(LOAD_MEDIA));
  dispatch({
    type: LOAD_MEDIA,
    uuid,
  });
};

export const UPDATE_ATHLETE_VIDEOID = 'photovideos.updateAthlete.videoId';
export const updateAthleteVideoId = athleteId => (dispatch) =>{
  dispatch(asyncFinishAction(UPDATE_ATHLETE_VIDEOID,"UPDATE_ATHLETE_VIDEOID",athleteId));
}

export const CLEAR_ATHLETE_VIDEOID = 'photovideos.videoId.clear';
export const clearAthleteVideoId = () => (dispatch) =>{
  dispatch(asyncFinishAction(CLEAR_ATHLETE_VIDEOID,"CLEAR_ATHLETE_VIDEOID"));
}

export const GET_VIDEO = 'photoVideos.media.video';
export const getVideo = videoId => (dispatch) =>{
  dispatch(asyncStartAction(GET_VIDEO));
  dispatch({
    type : GET_VIDEO,
    videoId,
  });
};

export const CLEAR_VIDEO_STATE = 'photoVideos.clear.video';
export const clearVideoState = () => ({
  type: CLEAR_VIDEO_STATE,
});

export const LOAD_MEDIA_CANCELLED = 'photosVideos.media.load.cancelled';
export const loadMediaCancelled = () => (dispatch) => {
  dispatch({
    type: LOAD_MEDIA_CANCELLED,
  });
};

export const MEDIA_NOT_FOUND = 'photosVideos.media.load.cancelled';
export const mediaNotFound = () => (dispatch) => {
  dispatch({
    type: MEDIA_NOT_FOUND,
    msg: 'Photo or video was removed or does not exist.',
  });
};

export const UPLOAD_PHOTO = 'photosVideos.photo.upload';
export const uploadPhoto = file => (dispatch) => {
  dispatch(asyncStartAction(UPLOAD_PHOTO));
  dispatch({
    type: UPLOAD_PHOTO,
    file,
  });
};

export const UPLOAD_VIDEO = 'photosVideos.video.upload';
export const uploadVideo = file => (dispatch) => {
  dispatch(asyncStartAction(UPLOAD_VIDEO));
  dispatch({
    type: UPLOAD_VIDEO,
    file,
  });
};

export const STAGE_NEW_MEDIA = 'photosVideos.newMedia.stage';
const stageNewMedia = (file, isAPhoto) => ({
  type: STAGE_NEW_MEDIA,
  media: {
    file,
    isAPhoto,
  },
});
export const UNSTAGE_NEW_MEDIA = 'photosVideos.newMedia.unstage';
export const unstageNewMedia = media => ({
  type: UNSTAGE_NEW_MEDIA,
  media,
});
export const STAGE_ALBUM_MEDIA = 'photosVideos.albumMedia.stage';
export const stageAlbumMedia = (media, isAPhoto) => ({
  type: STAGE_ALBUM_MEDIA,
  media,
  isAPhoto,
});
export const CLEAR_STAGED_MEDIA = 'photosVideos.media.clearStaged';
export const clearStagedMedia = () => ({
  type: CLEAR_STAGED_MEDIA,
});

export const STAGE_NEW_TAG = 'photosVideos.newTag.stage';
export const stageNewTag = (stagedMedia, photoList, newTag) => ({
  type: STAGE_NEW_TAG,
  stagedMedia,
  photoList,
  newTag,
});
export const UNSTAGE_NEW_TAG = 'photosVideos.newTag.unstage';
export const unstageNewTag = tag => ({
  type: UNSTAGE_NEW_TAG,
  tag,
});
export const CLEAR_STAGED_TAG = 'photosVideos.tag.clearStaged';
export const clearStagedTag = () => ({
  type: CLEAR_STAGED_TAG,
});

export const onPhotoDrop = dispatch => (accepted, rejected) => {
  if (accepted.length) {
    accepted.forEach((file) => {
      if (/^image\/.*$/.test(file.type)) {
        fixRotation(file).then((fileResult) => {
          dispatch(stageNewMedia(fileResult, true));
        });
      } else {
        dispatch(stageNewMedia(file, false));
      }
    });
  } else {
    // Todo: report error on rejected
    console.log('rejected', rejected);
  }
};

export const CREATE_ALBUM = 'photosVideos.album.create';
export const UPDATE_ALBUM = 'photosVideos.album.update';
export const createAlbum = () => (dispatch) => {
  dispatch(asyncStartAction(CREATE_ALBUM));
  dispatch({
    type: CREATE_ALBUM,
    dispatch,
  });
};

export const UPLOAD_PROGRESS = 'photosVideos.uploadProgress.update';
export const uploadProgressUpdate = (progressEvent, totalBytes, mediaId, msg, reset) => ({
  type: UPLOAD_PROGRESS,
  progressEvent,
  totalBytes,
  mediaId,
  msg: msg || '',
  reset: reset || false,
});

export const CLEAR_LOADING_PROGRESS = 'photosVideos.loadingProgress.clear';
export const clearLoadingProgress = () => ({
    type: CLEAR_LOADING_PROGRESS
});

export const CREATE_TAG = 'photosVideos.tag.create';
export const createTags = tags => (dispatch) => {
  dispatch(asyncStartAction(CREATE_TAG, 'Creating tags'));
  dispatch({
    type: CREATE_TAG,
    tags,
  });
};

export const albumMediaFormModel = mediaId =>
  `forms.dynamic.album.media.${mediaId}`;
export const albumMediaFormObject = (state, mediaId) => {
  if (state.forms.forms.dynamic.album) {
    if (state.forms.forms.dynamic.album.media) {
      if (state.forms.forms.dynamic.album.media[mediaId]) {
        return state.forms.forms.dynamic.album.media[mediaId];
      }
    }
  }
  return {};
};
export const albumMediaFormObjects = (state) => {
  if (state.forms.forms.dynamic.album) {
    if (state.forms.forms.dynamic.album.media) {
      return state.forms.forms.dynamic.album.media;
    }
  }
  return {};
};
export const albumMediaFormDataObject = (state, mediaId) => {
  if (state.forms.dynamic.album) {
    if (state.forms.dynamic.album.media) {
      if (state.forms.dynamic.album.media[mediaId]) {
        return state.forms.dynamic.album.media[mediaId];
      }
    }
  }
  return {};
};

export const CATEGORY_SELECT_ALL = 'photosVideos.selectCategory.all';
export const CATEGORY_SELECT_PHOTOS = 'photosVideos.selectCategory.photos';
export const CATEGORY_SELECT_VIDEOS = 'photosVideos.selectCategory.videos';
export const selectCategoryAll = () => ({ type: CATEGORY_SELECT_ALL });
export const selectCategoryPhotos = () => ({ type: CATEGORY_SELECT_PHOTOS });
export const selectCategoryVideos = () => ({ type: CATEGORY_SELECT_VIDEOS });

export const SHOW_DELETE_DIALOG = 'photosVideos.deleteDialog.show';
export const showDeleteDialog = id => ({
  type: SHOW_DELETE_DIALOG,
  id,
});
export const HIDE_DELETE_DIALOG = 'photosVideos.deleteDialog.hide';
export const hideDeleteDialog = id => ({
  type: HIDE_DELETE_DIALOG,
  id,
});

export const OPEN_EXPANDED_DIALOG = 'photosVideos.expandedDialog.open';
export const openExpandedDialog = media => ({
  type: OPEN_EXPANDED_DIALOG,
  media,
});

export const EXPANDED_DIALOG_NEXT = 'photosVideos.expandedDialog.next';
export const expandedDialogNext = (media, photoList) => ({
  type: EXPANDED_DIALOG_NEXT,
  media,
  photoList,
});
export const EXPANDED_DIALOG_PREV = 'photosVideos.expandedDialog.prev';
export const expandedDialogPrev = (media, photoList) => ({
  type: EXPANDED_DIALOG_PREV,
  media,
  photoList,
});


export const CLOSE_EXPANDED_DIALOG = 'photosVideos.expandedDialog.close';
export const closeExpandedDialog = () => ({
  type: CLOSE_EXPANDED_DIALOG,
});

export const UPDATE_PHOTO = 'photosVideos.photo.update';
export const UPDATE_VIDEO = 'photosVideos.video.update';

export const FORM_INIT_EXPANDED_DIALOG = 'photosVideos.expandedPhotoDialog.init';
export const initExpandedPhotoDialogForm = () => ({
  type: FORM_INIT_EXPANDED_DIALOG,
});

export const SHOW_EXPANDED_PHOTO_DELETE_DIALOG = 'photosVideos.expandedPhotoDeleteDialog.show';
export const showExpandedPhotoDeleteDialog = () => ({
  type: SHOW_EXPANDED_PHOTO_DELETE_DIALOG,
});
export const HIDE_EXPANDED_PHOTO_DELETE_DIALOG = 'photosVideos.expandedPhotoDeleteDialog.hide';
export const hideExpandedPhotoDeleteDialog = () => ({
  type: HIDE_EXPANDED_PHOTO_DELETE_DIALOG,
});

export const SHOW_EXPANDED_TAGS_DELETE_DIALOG = 'photosVideos.expandedTagsDeleteDialog.show';
export const showExpandedTagsDeleteDialog = () => ({
  type: SHOW_EXPANDED_TAGS_DELETE_DIALOG,
});
export const HIDE_EXPANDED_TAGS_DELETE_DIALOG = 'photosVideos.expandedTagsDeleteDialog.hide';
export const hideExpandedTagsDeleteDialog = () => ({
  type: HIDE_EXPANDED_TAGS_DELETE_DIALOG,
});

export const DELETE_PHOTO = 'photosVideos.photo.delete';
export const deletePhoto = photoId => (dispatch) => {
  dispatch(asyncStartAction(DELETE_PHOTO, 'Deleting photo', { id: photoId }));
  dispatch(hideDeleteDialog(photoId));
  dispatch(hideExpandedPhotoDeleteDialog());
  dispatch({
    type: DELETE_PHOTO,
    photoId,
  });
};

export const DELETE_VIDEO = 'photosVideos.video.delete';
export const deleteVideo = videoId => (dispatch) => {
  dispatch(asyncStartAction(DELETE_VIDEO, 'Deleting video', { id: videoId }));
  dispatch(hideDeleteDialog(videoId));
  dispatch(hideExpandedPhotoDeleteDialog());
  dispatch({
    type: DELETE_VIDEO,
    videoId,
  });
};

export const OPEN_TAGS_DIALOG = 'photosVideos.tagsDialog.open';
export const openTagsDialog = () => ({
  type: OPEN_TAGS_DIALOG,
});

export const CLOSE_TAGS_DIALOG = 'photosVideos.tagsDialog.close';
export const closeTagsDialog = () => ({
  type: CLOSE_TAGS_DIALOG,
});

export const OPEN_ALBUM_DIALOG = 'photosVideos.albumDialog.open';
export const openAlbumDialog = album => ({
  type: OPEN_ALBUM_DIALOG,
  album,
});
export const CLOSE_ALBUM_DIALOG = 'photosVideos.albumDialog.close';
export const closeAlbumDialog = () => ({
  type: CLOSE_ALBUM_DIALOG,
});

export const INIT_ALBUM_DIALOG = 'photosVideos.albumDialog.init';
export const initAlbumDialog = albumTags => ({
  type: INIT_ALBUM_DIALOG,
  albumTags,
});

export const ALBUM_FILTER_SELECTED = 'photosVideos.albumFilter.selected';
export const albumFilterSelected = albumId => ({
  type: ALBUM_FILTER_SELECTED,
  albumId,
});
export const ALBUM_FILTER_UNSELECTED = 'photosVideos.albumFilter.unselected';
export const albumFilterUnselected = albumId => ({
  type: ALBUM_FILTER_UNSELECTED,
  albumId,
});

export const OPEN_ALBUM_SELECT_DIALOG = 'photosVideos.albumSelectDialog.open';
export const openAlbumSelectDialog = () => ({
  type: OPEN_ALBUM_SELECT_DIALOG,
});
export const CLOSE_ALBUM_SELECT_DIALOG = 'photosVideos.albumSelectDialog.close';
export const closeAlbumSelectDialog = () => ({
  type: CLOSE_ALBUM_SELECT_DIALOG,
});

export const OPEN_ALBUM_MOVE_DIALOG = 'photosVideos.albumMoveDialog.open';
export const openMoveAlbumDialog = () => ({
  type: OPEN_ALBUM_MOVE_DIALOG,
});
export const CLOSE_ALBUM_MOVE_DIALOG = 'photosVideos.albumMoveDialog.close';
export const closeMoveAlbumDialog = () => ({
  type: CLOSE_ALBUM_MOVE_DIALOG,
});

export const STAGE_MEDIA_PROBLEM = 'photosVideos.stagedMedia.problem';
export const stageMediaProblem = msg => ({
  type: STAGE_MEDIA_PROBLEM,
  msg,
});

export const MARK_FAVORITE = 'photosVideos.markFavorite';
export const markFavorite = (media, isAPhoto, sportIds) => (dispatch) => {
  dispatch(asyncStartAction(MARK_FAVORITE, 'Marking favorite', { id: media.id }));
  dispatch({
    type: MARK_FAVORITE,
    media,
    isAPhoto,
    sportIds,
  });
};

export const OPEN_MULTI_DELETE = 'photosVideos.multiDelete.open';
export const openMultiDelete = () => ({
  type: OPEN_MULTI_DELETE,
});
export const CLOSE_MULTI_DELETE = 'photosVideos.multiDelete.close';
export const closeMultiDelete = () => ({
  type: CLOSE_MULTI_DELETE,
});
export const MULTI_DELETE_SELECT = 'photosVideos.multiDelete.select';
export const multiDeleteSelect = media => ({
  type: MULTI_DELETE_SELECT,
  media,
});
export const MULTI_DELETE_UNSELECT = 'photosVideos.multiDelete.unselect';
export const multiDeleteUnselect = media => ({
  type: MULTI_DELETE_UNSELECT,
  media,
});
export const MULTI_DELETE_SELECT_ALL = 'photosVideos.multiDelete.selectAll';
export const multiDeleteSelectAll = media => ({
  type: MULTI_DELETE_SELECT_ALL,
  media,
});

export const MULTI_DELETE_EXECUTE = 'photosVideos.multiDelete.execute';
export const executeMultiDelete = () => (dispatch, getState) => {
  const selectedMedia = getState().ui.uxProfile.photosVideos.multiDelete.selected;
  if (selectedMedia.length) {
    dispatch(asyncStartAction(
      MULTI_DELETE_EXECUTE,
      'Deleting photos',
      { ids: selectedMedia.map(m => m.id) },
    ));
    dispatch({
      type: MULTI_DELETE_EXECUTE,
      selectedMedia,
    });
  } else {
    dispatch(asyncErrorAction(MULTI_DELETE_EXECUTE, 'noSelectedMedia', { code: 0 }));
  }
};
export const MULTI_CHANGE_ALBUM_EXECUTE = 'photosVideos.multiChangeAlbum.execute';
export const executeMultiChangeAlbum = mediaList => (dispatch) => {
  if (mediaList.length) {
    dispatch(asyncStartAction(
      MULTI_CHANGE_ALBUM_EXECUTE,
      'Adding media to album',
      { ids: mediaList.map(m => m.id) },
    ));
    dispatch({
      type: MULTI_CHANGE_ALBUM_EXECUTE,
      mediaList,
    });
  } else {
    dispatch(asyncErrorAction(MULTI_CHANGE_ALBUM_EXECUTE, 'noSelectedMedia', { code: 0 }));
  }
};
export const MULTI_CHANGE_ALBUM_SUCCESS = 'photosVideos.multiChangeAlbum.success';
export const completeMultiAlbumChangeSuccessfully = (mediaList, uuid, albumRelMedia) => ({
  type: MULTI_CHANGE_ALBUM_SUCCESS,
  mediaList,
  uuid,
  albumRelMedia,
});
export const SIDEBAR_SHOW_TAG_DIALOG = 'uxProfile.sidebar.showTagDialog';
export const openEditTagsDialog = () => ({
  type: SIDEBAR_SHOW_TAG_DIALOG,
});
export const SIDEBAR_HIDE_TAG_DIALOG = 'uxProfile.sidebar.hideTagDialog';
export const closeEditTagsDialog = () => ({
  type: SIDEBAR_HIDE_TAG_DIALOG,
});

export const TAG_FILTER_SELECTED = 'photosVideos.tagFilter.selected';
export const tagFilterSelected = tagId => ({
  type: TAG_FILTER_SELECTED,
  tagId,
});
export const TAG_FILTER_UNSELECTED = 'photosVideos.tagFilter.unselected';
export const tagFilterUnselected = tagId => ({
  type: TAG_FILTER_UNSELECTED,
  tagId,
});

export const ADD_TAG = 'photosVideos.tagFilter.addTag';
export const addNewTag = media => (dispatch) => {
  dispatch(asyncStartAction(ADD_TAG, 'Creating new tag'));
  let photoId;
  let videoId;
  if (media) {
    if (media.isAPhoto) {
      photoId = media.id;
    } else {
      videoId = media.id;
    }
  }
  dispatch({
    type: ADD_TAG,
    photoId,
    videoId,
  });
};
export const ADD_TAG_TO_PHOTO = 'photosVideos.tagFilter.addTagToPhoto';
export const addTagToPhoto = (photoId, tags) => (dispatch) => {
  dispatch(asyncStartAction(ADD_TAG, 'Tag is adding to photo'));
  dispatch({
    type: ADD_TAG_TO_PHOTO,
    photoId,
    tags,
  });
};
export const addTagToVideo = (videoId, tags) => (dispatch) => {
  dispatch(asyncStartAction(ADD_TAG, 'Tag is adding to photo'));
  dispatch({
    type: ADD_TAG_TO_PHOTO,
    videoId,
    tags,
  });
};
export const SAVE_TAGS = 'photosVideos.tagFilter.saveTag';
export const saveTags = tags => (dispatch) => {
  dispatch(asyncStartAction(SAVE_TAGS, 'Saving tags'));
  dispatch({
    type: SAVE_TAGS,
    tags,
  });
};

export const DELETE_TAG = 'photosVideos.tagFilter.deleteAllTags';
export const deleteAllTags = (selectedTag, media) => (dispatch) => {
  let msg;
  if (media) {
    msg = `Deleting ${selectedTag.label} from photo`;
  } else {
    msg = `Deleting ${selectedTag.label}`;
  }
  dispatch(asyncStartAction(DELETE_TAG, msg));
  dispatch(hideExpandedTagsDeleteDialog());
  dispatch({
    type: DELETE_TAG,
    selectedTag,
    media,
  });
};

export const DELETE_ALBUM_EVERYTHING = 'photosVideos.album.deleteEverything';
export const deleteAlbumEverything = (album, albumRelMedia) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_ALBUM_EVERYTHING, 'Deleting album and everything in it', { album }));
  dispatch({
    type: DELETE_ALBUM_EVERYTHING,
    album,
    albumRelMedia,
  });
};

export const DELETE_ALBUM_ONLY = 'photosVideos.album.deleteAlbumOnly';
export const deleteAlbumOnly = album => (dispatch) => {
  dispatch(asyncStartAction(DELETE_ALBUM_ONLY, 'Deleting album, not photos and videos', { album }));
  dispatch({
    type: DELETE_ALBUM_ONLY,
    album,
  });
};

export const CHECK_VIDEO_URL = 'photosVideos.externalVideo.checkUrl';
export const checkVideoUrl = () => (dispatch) => {
  dispatch(asyncStartAction(CHECK_VIDEO_URL, 'Checking URL'));
  dispatch({
    type: CHECK_VIDEO_URL,
  });
};

export const OPEN_EXTERNAL_VIDEO_DIALOG = 'photosVideos.externalVideo.openDialog';
export const openExternalVideoDialog = () => ({
  type: OPEN_EXTERNAL_VIDEO_DIALOG,
});

export const CLOSE_EXTERNAL_VIDEO_DIALOG = 'photosVideos.externalVideo.closeDialog';
export const closeExternalVideoDialog = () => ({
  type: CLOSE_EXTERNAL_VIDEO_DIALOG,
});

export const SHOW_PHOTO_FILTER_DIALOG = 'photosVideos.filterDialog.show';
export const showFilterDialog = () => ({
  type: SHOW_PHOTO_FILTER_DIALOG,
});
export const HIDE_PHOTO_FILTER_DIALOG = 'photosVideos.filterDialog.hide';
export const hideFilterDialog = () => ({
  type: HIDE_PHOTO_FILTER_DIALOG,
});

export const CLEAR_ALL_PHOTO_FILTERS = 'photosVideos.filters.clear';
export const clearAllPhotoFilters = () => ({
  type: CLEAR_ALL_PHOTO_FILTERS,
});
