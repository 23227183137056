import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AlbumButton extends PureComponent {
  static propTypes = {
    album: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  }

  componentDidMount() {
  }

  onClick = () => {
    const { onClick, album } = this.props;
    onClick(album.id);
  }

  render() {
    const { album, selected } = this.props;
    return (
      <button
        className={[
          'UxProfile_Photos_SideBar_AlbumButton',
          selected ? 'selected' : '',
        ].join(' ')}
        onClick={this.onClick}
      >
        <div className="UxProfile_Photos_SideBar_AlbumButton_Text">
          {album.title}
        </div>
      </button>
    );
  }
}

export default AlbumButton;
