import { combineReducers } from 'redux';

import { ASYNC_START, ASYNC_ERROR, ASYNC_FINISH } from '../../../actions/ui/async';
import { RESEND_EMAIL_VALIDATION, RESEND_PHONE_VALIDATION } from '../../../actions/ui/uxEditProfile/confirmEmailPhone';

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === 'forms.confirmEmailPhone') {
        return `Verifying your ${action.attrs.isTelephone ? 'phone number' : 'email'}`;
      }
      if ((action.model === RESEND_EMAIL_VALIDATION) ||
        (action.model === RESEND_PHONE_VALIDATION)) {
        return action.msg;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === 'forms.confirmEmailPhone') {
        return '';
      }
      if ((action.model === RESEND_EMAIL_VALIDATION) ||
        (action.model === RESEND_PHONE_VALIDATION)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  formNeedsInit,
});

export default reducer;
