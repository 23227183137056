import { combineReducers } from 'redux';
import { formPhoneHelp, formShowHelp } from './utils';
import { ROUTE_CHANGED } from '../../../actions/ui/routes';
import { JOIN_NOW_FORM_INIT } from '../../../actions/ui/welcome';
import {
  FORM_SERVER_ERROR,
  FORM_SUBMIT_FAILED,
  FORM_SUBMIT_INTENT,
  FORM_SUBMIT_SUCCESS,
} from '../../../actions/ui/formUtils';
import { cognitoUsernameError } from '../utils/errors';


const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
      return true;
    case JOIN_NOW_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.joinNow') {
        return cognitoUsernameError(action.error, action);
      }
      return state;
    case FORM_SUBMIT_FAILED:
      if (action.model === 'forms.joinNow') {
        return 'Please correct the errors above';
      }
      return state;
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case JOIN_NOW_FORM_INIT:
    case 'rrf/blur':
    case 'rrf/batch':
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
  formPhoneHelp: formPhoneHelp('forms.joinNow.phone', JOIN_NOW_FORM_INIT),
  // formPasswordHelp: formPasswordHelp('forms.joinNow.password', JOIN_NOW_FORM_INIT),
  formShowPhoneHelp: formShowHelp('forms.joinNow.phone', JOIN_NOW_FORM_INIT),
  // formShowPasswordHelp: formShowHelp('forms.joinNow.password', JOIN_NOW_FORM_INIT),
  formShowParentsNameHelp: formShowHelp('forms.joinNow.parentsName', JOIN_NOW_FORM_INIT),
  formShowParentsEmailHelp: formShowHelp('forms.joinNow.parentsEmail', JOIN_NOW_FORM_INIT),
});

export default reducer;
