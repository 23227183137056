import React from 'react';
import Icon from "../../../common/Icon";
import {formatPositionResponse} from '../../../../../store/actions/ui/formUtils/validators';

export default (props) => {
  const athlete = props.data;
  const isPrintProcess = false;
  const style = {};
  const schoolStyle = props.schoolStyle;
  return (
    <div className="AthleteMedia" style={style || {}}>
      {
        athlete.headshotUrl ? (
            !isPrintProcess && <div
              className="AthleteMedia_Headshot"
              style={{
                backgroundImage: `url(${athlete.headshotUrl})`,
              }}
            />
          ) :
          schoolStyle[0] && schoolStyle[0].leaderboardLogo ? (
            !isPrintProcess && <div
              className="AthleteMedia_Headshot"
              style={{
                backgroundImage: `url(${schoolStyle[0].leaderboardLogo})`,
              }}
            />
          ) :
          (
            !isPrintProcess &&
            <div className="AthleteMedia_Headshot">
              <Icon iconName="photo2" className="AthleteMedia_PhotoIcon"/>
            </div>
          )
      }
      <div className="AthleteMedia_MediaBody">
        <div
          className="AthleteMedia_MediaName"
        >{`${athlete.givenName || ''} ${athlete.familyName || ''}`}
        </div>
        <div className="AthleteMedia_MediaInfo">
          <div className="AthleteMedia_Grade">
            {athlete.gradYear}
          </div>
          <div className="AthleteMedia_Position">
            {athlete.combineNumber ? (
              `${athlete.position ? formatPositionResponse(athlete.position) : '-'} | #${athlete.combineNumber}`
            ) : (
              `${athlete.position ? formatPositionResponse(athlete.position) : '-'}`
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
