import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextControl from '../../SimpleForm/TextControl';
import SelectControl from '../../SimpleForm/SelectControl';
import Button from '../../Common/Button';
import MultiSelectControl from '../../SimpleForm/MultiSelectControl';
import { getTeams, createNewTeamId, getTeamTypes } from '../utils';

class CoachSport extends PureComponent {
  static propTypes = {
    sports: PropTypes.object,
    onFieldChange: PropTypes.func,
    teamTypes: PropTypes.array,
    errors: PropTypes.object,
    sportTeamList: PropTypes.array,
    newTeams: PropTypes.array,
    index: PropTypes.number,
    createTeam: PropTypes.func,
    myTeam: PropTypes.object,
    onCancel: PropTypes.func,
  };

  state = {
    creatingNewTeam: false,
    values: {},
  };

  onNewTeamFieldChange = name => (value) => {
    const { values } = this.state;
    this.setState({
      values: Object.assign({}, values, {
        [name]: value,
      }),
    });
  }

  onCreateNewTeamClick = () => {
    this.setState({
      creatingNewTeam: true,
    });
  }

  onCancelCreateNewClick = () => {
    this.setState({
      creatingNewTeam: false,
    });
  }

  onAddTeam = () => {
    const { createTeam, myTeam, index } = this.props;
    const { values } = this.state;
    if (!myTeam.sportId || !values.level || !values.name) {
      return;
    }
    createTeam(myTeam.sportId, values.level, values.name, index);
    this.setState({
      creatingNewTeam: false,
    });
  }

  onCancel = () => {
    const { onCancel, index } = this.props;
    onCancel(index);
  }

  render() {
    const {
      myTeam,
      index,
      sports,
      onFieldChange,
      teamTypes,
      errors,
      sportTeamList,
      newTeams,
    } = this.props;
    const { creatingNewTeam, values } = this.state;
    const newTeamOptions = newTeams.reduce((prev, curr) => {
      return { ...prev, [createNewTeamId(curr)]: `${curr.name}` };
    }, {});
    let teamOptions = {};
    if (sportTeamList.length) {
      teamOptions = { ...getTeams(sportTeamList, Number(myTeam.sportId)), ...newTeamOptions };
    }
    const teamTypeOptions = getTeamTypes(teamTypes);
    return (
      <div className="CodeSignUp_NewSport">
        <SelectControl
          className="CodeSignUp_SportsPick"
          placeholder="Select a Sport"
          label="Sport"
          help="Which sport do you coach?"
          options={sports}
          name="sport_control"
          errors={['']}
          defaultValue=""
          myOnChange={onFieldChange(index, 'sportId')}
          value={myTeam.sportId}
        />
        {
          !!myTeam.sportId && (
            <React.Fragment>
              {
                creatingNewTeam ? (
                  <React.Fragment>
                    <div className="CodeSignUp_Title">
                      Add a New Team to Your School
                    </div>
                    <SelectControl
                      className="CodeSignUp_LevelPick"
                      placeholder="Level"
                      label="Level"
                      help="Choose a level"
                      options={teamTypeOptions}
                      name="sport_control"
                      errors={['']}
                      defaultValue=""
                      myOnChange={this.onNewTeamFieldChange('level')}
                      value={values.level || ''}
                    />
                    <TextControl
                      className="CodeSignUp_NamePick"
                      placeholder="Team Name"
                      label="Team Name"
                      name="name"
                      myOnChange={this.onNewTeamFieldChange('name')}
                      value={values.name || ''}
                    />
                    <Button
                      className="CodeSignUp_CreateTeamBtn"
                      onClick={this.onAddTeam}
                      type="button"
                    >
                      Add Team
                    </Button>
                    <Button
                      className="CodeSignUp_CreateTeamBtn"
                      onClick={this.onCancelCreateNewClick}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <MultiSelectControl
                        className="CodeSignUp_TeamPick"
                        placeholder="Team"
                        label="Team"
                        help="Select the team(s) do you coach"
                        options={teamOptions}
                        name="team_control"
                        errors={errors.teamIds}
                        defaultValue={myTeam.teamIds}
                        myOnChange={onFieldChange(index, 'teamIds')}
                        multi
                        customOnClick={this.onCreateNewTeamClick}
                        customLabel="Add a new team"
                      />
                      {/* <Button
                      className="CodeSignUp_CreateTeamBtn"
                      onClick={this.onCreateNewTeamClick}
                      type="button"
                    >
                      Add a New Team to Your School
                    </Button> */}
                    </React.Fragment>
                  )
              }
            </React.Fragment>
          )
        }
        {
          (index > 0) && (
            <Button
              className="CodeSignUp_CancelTeamBtn"
              onClick={this.onCancel}
              type="button"
            >
              Cancel
            </Button>
          )
        }
      </div>
    );
  }
}

export default CoachSport;
