import { connect } from 'react-redux';
import Component from '../../components/UxProfile/VcRating/VcRating';
import { getVcScore } from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => ({
  vcRating: getVcScore(state, ownProps),
});

const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
