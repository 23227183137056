import { combineEpics, ofType } from 'redux-observable';
import { switchMap, map, tap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';

import { asyncFinishAction, asyncProgressAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment, getTokenFragment, checkForkErrors } from '../../../../store/actions/ui/uxProfile/utils';

import { AWARDS_GET_COMBINES, awardsGetCombineAwards,awardsGetCombinesAwards, AWARDS_GET_COMBINES_AWARDS,AWARDS_GET_COMBINE_AWARDS } from './award.actions';
import { getCombines, getCombineAwardsByCombineId } from '../combine/combine.api';
import { Combine } from '../combine/combine.models';
import { getProfiles } from '../roster';

const awardsGetCombinesEpic = action$ => (
  action$.pipe(
    ofType(AWARDS_GET_COMBINES),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      forkJoin(...action.schoolIds.map(schoolIds =>
        getCombines(schoolIds, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'awardsGetCombines',
      ))
    ))
    .pipe(checkForkErrors(),
      map(result =>{
        if(result.success){
          return {
            ...result,
            action
          }
        }
      }))
    )),
    switchMap((result) => {
      if (result.success) {
        const schoolCombine = result.results.map((results, i) =>{
          return {
            schoolId : results.action.schoolIds[i],
            combines: results.response._embedded.map(x => Combine.fromApi(x))    
            }
        }) 
        let combineIds = [];
        const schoolCombineIds = [...schoolCombine.map(schoolCombine => {
          const ids = schoolCombine.combines.map(c => c.id);
          combineIds.push(ids)
         return {
         schoolId:schoolCombine.schoolId,
         combineIds:ids
         }
       }
       )];
        const combineId = [].concat.apply([],combineIds);
        return of(
          asyncFinishAction(result.action.type, 'awardsGetCombines', {
            schoolCombine         
          }),
          awardsGetCombinesAwards(schoolCombine, schoolCombineIds,combineId),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const awardsGetCombinesAwardsEpic = action$ => (
  action$.pipe(
    ofType(AWARDS_GET_COMBINES_AWARDS),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      return forkJoin(...action.combineIds.map(id =>
        getCombineAwardsByCombineId(token,id).pipe(
          manualCancelApiCallFragment(
            action$,
            action,
            'awardsGetCombinesAwards',
          ),
          tap((result) => {
            const schoolId = [];
            const tempIds = action.schoolCombine.map(schoolCombine => {
             let tempSchoolId = schoolCombine.combines.find(c => c.id ===id);
             if(tempSchoolId){
               schoolId.push(tempSchoolId);
             }
             return schoolCombine;
            })
            action.dispatch(asyncProgressAction(
              action.type,
              'awardsGetCombinesAwards',
              {
                schoolId: schoolId[0].schoolId,
                schoolCombineIds: action.schoolCombineIds,
                combineId:id,
                awards: result.response.reduce((prev, curr) => {
                  if (prev[curr.owner_id]) {
                    return Object.assign({}, prev, {
                      [curr.owner_id]: [...prev[curr.owner_id], curr],
                    });
                  }
                  return { ...prev, [curr.owner_id]: [curr] };
                }, {}),
              },
            ));
          }),
        ))).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      )
      }),
    switchMap((result) => {
      if (result.success) {
        const awards = result.results.reduce((prev, curr) => (
          [...prev, ...curr.response]
        ), []);
        const ownerIds = {};
  
        awards.forEach((award) => {
          ownerIds[award.owner_id] = true;
        });
        return of(
          asyncFinishAction(result.action.type, 'getCombinesAndAwards'),
          getProfiles(Object.keys(ownerIds)),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const awardsGetCombineAwardsEpic = action$ => (
  action$.pipe(
    ofType(AWARDS_GET_COMBINE_AWARDS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      forkJoin(...action.combineIds.map(id =>
        getCombineAwardsByCombineId(token, id).pipe(
          manualCancelApiCallFragment(
            action$,
            action,
            'awardsGetCombineAwards',
          ),
          tap((result) => {
            action.dispatch(asyncProgressAction(
              action.type,
              'awardsGetCombineAwards',
              {
                schoolId: action.schoolId,
                combineId: id,
                awards: result.response.reduce((prev, curr) => {
                  if (prev[curr.owner_id]) {
                    return Object.assign({}, prev, {
                      [curr.owner_id]: [...prev[curr.owner_id], curr],
                    });
                  }
                  return { ...prev, [curr.owner_id]: [curr] };
                }, {}),
              },
            ));
          }),
        ))).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        const awards = result.results.reduce((prev, curr) => (
          [...prev, ...curr.response]
        ), []);
        const ownerIds = {};
        awards.forEach((award) => {
          ownerIds[award.owner_id] = true;
        });
        return of(
          asyncFinishAction(result.action.type, 'getCombinesAndAwards'),
          getProfiles(Object.keys(ownerIds)),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(
  awardsGetCombinesEpic,
  awardsGetCombinesAwardsEpic,
  awardsGetCombineAwardsEpic
);
