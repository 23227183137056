import { combineReducers } from 'redux';

import {
  MULTI_CHANGE_ALBUM_EXECUTE,
  OPEN_ALBUM_MOVE_DIALOG,
  CLOSE_ALBUM_MOVE_DIALOG,
} from '../../../../actions/ui/uxProfile/photosVideos';
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR,
} from '../../../../actions/ui/async';

const isShowing = (state = false, action) => {
  switch (action.type) {
    case OPEN_ALBUM_MOVE_DIALOG:
      return true;
    case CLOSE_ALBUM_MOVE_DIALOG:
      return false;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === MULTI_CHANGE_ALBUM_EXECUTE) {
        return action.msg;
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === MULTI_CHANGE_ALBUM_EXECUTE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isShowing,
  loadingMsg,
});

export default reducer;
