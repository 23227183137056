import React from 'react';
import { isRealNumber, formatResult } from '../../../../containers/UxCommon/Utils';
import Icon from '../../common/Icon';
import {testResultConversion } from '../../+store/coachWorld/coachWorld.utils.js';

export const displayCategoryImprovement = (
  improvementPercent,
  best,
  comparable,
  formatCode,
  na = false,
  rounding = true,
) => {
  if (na) return 'N/A';
  let resultDisplay;
  let direction;
  let color;
  if (improvementPercent === Infinity) return '-';
  if (isRealNumber(improvementPercent)) {
    resultDisplay = formatResult(Math.abs(improvementPercent), formatCode, undefined, rounding);
    if (improvementPercent > 0) {
      direction = 'up';
    } else if (improvementPercent < 0) {
      direction = 'down';
    } else {
      direction = '';
    }
    if (comparable && direction === 'up') {
      color = 'green';
    } else if (comparable && direction === 'down') {
      color = 'red';
    } else {
      color = '';
    }
    return (
      <div className="RawComparisonPage_ArrowWrapper">
        {resultDisplay}
        {direction && (
          <Icon className={`RawComparisonPage_Arrow ${color}`} iconName={`${direction}-arrow`}/>
        )}
      </div>
    );
  }
  resultDisplay = '-';
  return resultDisplay;
};

export const displayCategory = (val, formatCode, na = false, rounding = true) => {
  if (na) return 'N/A';
  if (isRealNumber(val)) {
    return formatResult(val, formatCode, undefined, rounding);
  }
  return '-';
};

export const checkNA = (last, mostRecent, improvement) => (
  last === 0
  && mostRecent === 0
  && improvement === 0
);

export const getSubBanks = (fakeSubBanks, subBanks) => {
  return fakeSubBanks.map((fakeSubBank) => {
    const realSubBank = subBanks
      .find(rBank => rBank.standard_test_subbank_code === fakeSubBank.standard_test_subbank_code);
    if (realSubBank) {
      return realSubBank;
    }
    return fakeSubBank;
  }).sort((a, b) => a.standard_test_subbank_id - b.standard_test_subbank_id);
};


export const displayCategoryImprovementFilterByCode = (
  improvementPercent,
  best,
  comparable,
  formatCode,
  categoryCode = null,
  na = false,
  rounding = true,
) => {
  if (na) return 'N/A';
  let resultDisplay;
  let direction;
  let color;
  if (improvementPercent === Infinity) return '-';
  if (isRealNumber(improvementPercent)) {
    resultDisplay = formatResult(Math.abs( testResultConversion(categoryCode,improvementPercent)), formatCode, undefined, rounding);
    if (improvementPercent > 0) {
      direction = 'up';
    } else if (improvementPercent < 0) {
      direction = 'down';
    } else {
      direction = '';
    }
    if (comparable && direction === 'up') {
      color = 'green';
    } else if (comparable && direction === 'down') {
      color = 'red';
    } else {
      color = '';
    }
    return (
      <div className="RawComparisonPage_ArrowWrapper">
        {resultDisplay}
        {direction && (
          <Icon className={`RawComparisonPage_Arrow ${color}`} iconName={`${direction}-arrow`}/>
        )}
      </div>
    );
  }
  resultDisplay = '-';
  return resultDisplay;
};

export const displayCategoryFilterByCode =(val,formatCode,categoryCode = null, na = false, rounding = true) => {
  if(na) return 'N/A';
  if(isRealNumber(val))
  {
    return formatResult(testResultConversion(categoryCode,val), formatCode, undefined,rounding);
  }
  return '-';
}

export const changeHeaderName = (header, code) => {
  if (!code || code === 'undefined') {
    return header;
  }
  switch (code) {
    case 'MACHINEBENCH':
      return 'DB Bench Press';
    case 'MACHINESQUAT':
        return 'Safety Bar Squat';
    case 'ATTACKANGLE':
    case 'DKNEGATIVEATTACKANGLE':
      return 'Attack Angle (AVG)';
    case 'CONNECTIONANGLE':
        return 'Connection Angle at Impact (AVG)';
    case 'RSHLAUNCHANGLE':
    case 'TMHLAUNCHANGLEAVG':
          return 'Launch Angle (AVG)';
    default:
      return header;
  }
}


export default '';
