export const UserServiceUrl = 'https://service-user.virtualcombine.com';
export const SportsServiceUrl = 'https://service-sport.virtualcombine.com';
export const PhotoServiceUrl = 'https://service-photo.virtualcombine.com';
export const AppUrl = 'https://app.virtualcombine.com';
export const LeaderboardServiceUrl = 'https://service-sport-leaderboard.virtualcombine.com';
export const StripeConfig = {
  PublishableKey: 'pk_live_GtWv0MFe3FQDBQ80YwZiPsQ2001o50yYC1'
}
export const AwsAmplyfyConfig = {
  region: 'us-west-2',
  userPoolId: 'us-west-2_cNNZaZMXL',
  userPoolWebClientId: 'c3806mrqovim7anhl80qcdvnt',
  mandatorySignIn: false,
};
