import React from 'react';
import { renderCategoryLogo } from '../../CombineTable/CombineHeaderLogo';
import dashrRunningLogoUrl from '../.././../../../assets/images/DashrRunningBlueMen.png';
import { useDispatch } from 'react-redux';
import { setAllLaser } from '../../../+store/combine';

export default (props) => {
  const {
    column,
    displayName,
    showLaser,
    standardTestCategoryId,
    combineId,
    isLaserChecked,
    standardTestObjectId,
    isCombineOpen
  } = props;
  const dispatch = useDispatch();

  const toggleAllLaser = () => {
    dispatch(
      setAllLaser(combineId, standardTestCategoryId, standardTestObjectId),
    );
  };

  return (
    <div style={{textAlign: "center"}} className="CombineTable_ColumnHeader iconTextWrap d-block text-center p-3">
      {renderCategoryLogo(column.subbankCode, column.testCategoryCode)}
      {!!column.isDashr && (
        <div className="CombineTable_Column_Header_Logo_Label">
          <img
            src={dashrRunningLogoUrl}
            className="Combine_Column_Header_Logo"
            alt={''}
          />
        </div>
      )}
      <div className="CombineTable_ColumnHeaderText">{displayName}</div>
      {!!showLaser && (
        <div className="mt-1 d-flex">
           <input
            type="checkbox"
            onClick={toggleAllLaser}
            checked={isLaserChecked}
            disabled={!isCombineOpen}
          />
          <span  className="pl-2" style={{ color: 'black' }}>All Laser? </span>

        </div>
      )}
    </div>
  );
};
