import { connect } from 'react-redux';
import Component from './Rosters.component';
import { selectLoadingMsg, selectFilteredRosters } from '../../+store';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const loading = !!loadingMsg;
  return {
    loading,
    teams: selectFilteredRosters(state, ownProps),
    sports: state.data.sport.sports,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
  };
};

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
