import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import TextControlGroup from '../../UxCommon/Form/TextControlGroup/TextControlGroup';
import { parserOnlyNumbers } from '../../../utils/parsers';
import { emailOrPhone, formatPhone } from '../../../utils';

class ConfirmAccount extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    formGeneralError: PropTypes.string.isRequired,
    resendConfirmCode: PropTypes.func.isRequired,
    needsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    autoConfirm: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.shouldInit();
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  shouldInit = () => {
    if (this.props.needsInit) {
      if (this.props.match.params.code) {
        this.props.autoConfirm(this.props.match.params.code);
      }
      this.props.formInit();
    }
  };

  submit = () => {
    this.props.submit();
  }

  resendConfirmCode = () => {
    const { resendConfirmCode } = this.props;
    resendConfirmCode();
  }

  render() {
    const { match } = this.props;
    let username = match.params.username.replace('aAdDoOtT', '.');
    if (username) {
      const usernameType = emailOrPhone(username);
      if (usernameType === 'phone') {
        username = formatPhone(username);
      }
    }
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">One more step!</div>
            <div className="welcome-desc">
              {`In order to confirm your identity we sent a confirmation code to ${username}.`}
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <Form className="welcome-form" model="forms.confirmAccount" onSubmit={this.submit}>
              <TextControlGroup
                label="Please enter your confirmation code below."
                placeholder="Confirmation Code"
                parser={parserOnlyNumbers}
                model="forms.confirmAccount.code"
              />
              {
                this.props.formGeneralError &&
                  <div className="form-group">
                    <div className="form-general-error">{this.props.formGeneralError}</div>
                  </div>
              }
              <div className="welcome-btns">
                <button className="welcome-btn" type="button" onClick={this.resendConfirmCode}>Resend Code</button>
                <button className="welcome-btn" type="submit">Confirm</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmAccount;
