import { reloadDataSeconds } from '../../../../utils';
import { ShouldUpdate } from './combine.constants';

export const fromVoiceDictation = (text) => {
  const result = {
    shouldSubmit: false,
    shouldExit: false,
    shouldGoNext: false,
    value: text,
  };

  const numbers = {
    'zero': 0,
    'one': 1,
    'two': 2,
    'three': 3,
    'four': 4,
    'five': 5,
    'six': 6,
    'seven': 7,
    'eight': 8,
    'nine': 9,
  };

  const words = text.trim().split(/\s+/);
  // Assumes there are two words
  // first is the value
  if (words.length === 2) {
    let value = words[0].toLowerCase();
    value = value in numbers ? numbers[value] : value;
    if (['remove', 'clear', 'delete'].includes(value)) {
      value = '';
    }
    result.value = value;
    const command = words[1].toLowerCase();
    // second is the command
    if (['enter', 'next'].includes(command)) {
      result.shouldGoNext = true;
    } else if (['cancel', 'undo'].includes(command)) {
      result.shouldExit = true;
    } else if (['done', 'exit', 'save', 'close'].includes(command)) {
      result.shouldSubmit = true;
    }
  }

  return result;
};


export const fromWordsToNumbers = (text) => {
    const decimals = ['dot', 'point'];
    const numbers = {
      zero: 0,
      first: 1,
      one: 1,
      second: 2,
      two: 2,
      third: 3,
      thirteenth: 13,
      thirteen: 13,
      three: 3,
      fourth: 4,
      fourteenth: 14,
      fourteen: 14,
      four: 4,
      fifteenth: 15,
      fifteen: 15,
      fifth: 5,
      five: 5,
      sixth: 6,
      sixteenth: 16,
      sixteen: 16,
      six: 6,
      seventeenth: 17,
      seventeen: 17,
      seventh: 7,
      seven: 7,
      eighteenth: 18,
      eighteen: 18,
      eighth: 8,
      eight: 8,
      nineteenth: 19,
      nineteen: 19,
      ninth: 9,
      nine: 9,
      tenth: 10,
      ten: 10,
      eleventh: 11,
      eleven: 11,
      twelfth: 12,
      twelve: 12,
    };

    multiplesOfTen = {
      twenty: 20,
      twentieth: 20,
      thirty: 30,
      thirtieth: 30,
      forty: 40,
      fortieth: 40,
      fifty: 50,
      fiftieth: 50,
      sixty: 60,
      sixtieth: 60,
      seventy: 70,
      seventieth: 70,
      eighty: 80,
      eightieth: 80,
      ninety: 90,
      ninetieth: 90,
    };

    const numberSize = {
      hundred: 100,
      hundredth: 100,
      thousand: 1000,
    };

}


export const checkCanEditCombineResults = (myTeams, athlete /* ,combine */) => {
  const foundTeam = myTeams.find(t => t.schoolTeams[0].schoolId === athlete.schoolId);
  if (!foundTeam || foundTeam.userType !== 'coach') {
    return 'You must be an admin or coach at this school to edit combine results';
  }
  return '';
};

export const checkIfUpdateNeeded = (updateList, id) => {
  if (!id) return false;
  const lastUpdated = updateList.find(u => u.key === id.key && u.id === id.id);
  if (lastUpdated) {
    const difference = (Date.now() - lastUpdated.value) / 1000;
    if (difference > reloadDataSeconds) {
      return true;
    }
    return false;
  }
  return true;
};

export const checkIfUpdateNeededInFastMode = (updateList, id) => {
  if (!id) return false;
  const lastUpdated = updateList.find(u => u.key === id.key && u.id === id.id);
  if (lastUpdated) {
    const difference = (Date.now() - lastUpdated.value) / 100;
    if (difference > reloadDataSeconds) {
      return true;
    }
    return false;
  }
  return true;
};

export const dashboardCombinesUpdateId = (schoolIds) => {
  if (schoolIds) {
    return {
      id: schoolIds.join('|'),
      key: ShouldUpdate.DashboardCombine,
    };
  }
  return '';
};

export const combineUpdateId = (combineId) => {
  if (combineId) {
    return {
      id: parseInt(combineId, 10),
      key: ShouldUpdate.CombinePerf,
    };
  }
  return '';
};

export const combinePreCombineUpdateId = (combineId) => {
  if (combineId) {
    return {
      id: combineId,
      key: ShouldUpdate.CombineAwards,
    };
  }
  return '';
};

export const schoolTeamListId = (id) => {
  if (id) {
    return {
      id,
      key: ShouldUpdate.SchoolTeamList,
    };
  }
  return '';
};

export const getPaginationLimit = (word1, word2) => {
  const shortestLen = Math.min(word1.length, word2.length);
  let startPagination = '';
  let endPagination = '';
  for (let j = 0; j < shortestLen; j++) {
    startPagination += word1[j];
    endPagination += word2[j];
    if (word1[j] !== word2[j]) {
      break;
    }
  }
  return [startPagination, endPagination];
}
export const paginateCombineBy = (combine, paginateBy, numOfGroups=5, minNumToGroup=150) => {
  if (!combine.length) return {};
  if (combine.length <= minNumToGroup) {
    numOfGroups = 1;
  }
  const paginatedCombine = {};
  const sortedCombine = combine.sort((a, b) => {
    if (a[paginateBy] === b[paginateBy]) return 0;
    if (a[paginateBy] > b[paginateBy]) return 1;
    return -1;
  });
  const perPagination = Math.floor(sortedCombine.length/numOfGroups);
  let nextFirst = '';
  for (let i = 0; i < numOfGroups; i++) {
    const isLast = i === numOfGroups - 1;
    const startIdx = i * perPagination;
    const endIdx = isLast ? sortedCombine.length : (i+1) * perPagination;
    const paginated = sortedCombine.slice(startIdx, endIdx);
    const firstElementInPagination = paginated.find( rec  => rec[paginateBy] );
    const lastElementInPagination = [...paginated].reverse().find( rec  => rec[paginateBy] );

    const firstInPaginated = firstElementInPagination[paginateBy].toLowerCase();
    const lastInPaginated = lastElementInPagination[paginateBy].toLowerCase();
    let [startPagination, endPagination] = getPaginationLimit(firstInPaginated, lastInPaginated);
    startPagination = startPagination.length > nextFirst.length ? startPagination : nextFirst;
    if (!isLast) {
      const afterLast = sortedCombine[endIdx][paginateBy].toLowerCase();
      const [afterStartPagination, afterEndPagination] = getPaginationLimit(lastInPaginated, afterLast);
      endPagination = endPagination.length > afterStartPagination.length ? endPagination : afterStartPagination;
      nextFirst = afterEndPagination;
    }

    const paginatedKey = startPagination === endPagination ? startPagination : `${startPagination} - ${endPagination}`;
    if (paginatedKey in paginatedCombine) {
      paginatedCombine[paginatedKey] = [...paginatedCombine[paginatedKey], ...paginated];
    } else {
      paginatedCombine[paginatedKey] = paginated;
    }
  };

  return paginatedCombine;
};

export default 'combine.utils.js';

