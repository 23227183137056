import { connect } from 'react-redux';
import Component from './School.component';
import { getSchoolTeamsList } from '../+store';

const mapStateToProps = (state, ownProps) => ({
  authenticatedUuid: state.data.cognito.uuid,
  schoolTeams: state.modules.coachWorld.roster.schoolTeams,
  admins: state.modules.coachWorld.roster.admins,
  coaches: state.modules.coachWorld.roster.coaches,
  schoolId: ownProps.match.params.schoolId,
  userAthletes: state.data.user.profiles,
  teamList: state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId],
  schoolsStyle: state.modules.coachWorld.coachWorld.schoolStyle,
  school: state.modules.coachWorld.coachWorld.schoolTeamsLists &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId] &&
    state.modules.coachWorld.coachWorld.schoolTeamsLists[ownProps.match.params.schoolId][0]
      .team.school,
});

const mapDispatchToProps = dispatch => ({
  getSchool: (schoolId) => {
    dispatch(getSchoolTeamsList(schoolId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
