import { ajax } from 'rxjs/ajax';

import Urls from '../../../../config/urls';

export const getTeamTypes = () =>
  ajax.getJSON(
    Urls.schools.getTeamTypes(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );

export const getUsersTeams = (uuid, token) =>
  ajax.getJSON(
    Urls.schools.getUsersTeams(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

// export const getSchoolTeams = schoolId =>
//   ajax.getJSON(
//     Urls.schools.getTeams(schoolId),
//     {
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//     },
//   );

export const checkCode = code =>
  ajax.getJSON(
    Urls.schools.testCode(code),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );

export default 'codeSignUp.api.js';

