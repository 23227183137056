import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LeaderboardViewOptions } from '../../+store';
import Icon from '../../../../components/UxCommon/Icon/Icon';

class ToggleView extends PureComponent {
    static propTypes = {
      viewSelection: PropTypes.string.isRequired,
      changeViewSelection: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.shouldUpdate();
    }

    componentDidUpdate() {
      this.shouldUpdate();
    }

    shouldUpdate = () => {}

    switchToDetailedView = () => {
      const { changeViewSelection } = this.props;
      changeViewSelection('detailed');
    }
    switchToOutlineView = () => {
      const { changeViewSelection } = this.props;
      changeViewSelection('outline');
    }

    render() {
      const { viewSelection } = this.props;
      if (viewSelection === LeaderboardViewOptions.detailed) {
        return (
          <div className="Leaderboard-ToggleView">
            <button className="Leaderboard-ToggleView_Detailed Leaderboard-ToggleView_Detailed_Active">
              <Icon iconName="leaderboard_detailed" />
            </button>
            <button className="Leaderboard-ToggleView_Outline" onClick={this.switchToOutlineView}>
              <Icon iconName="leaderboard_outline" />
            </button>
          </div>
        );
      }
      return (
        <div className="ToggleView">
          <button className="Leaderboard-ToggleView_Detailed" onClick={this.switchToDetailedView}>
            <Icon iconName="leaderboard_detailed" />
          </button>
          <button className="Leaderboard-ToggleView_Outline Leaderboard-ToggleView_Outline_Active">
            <Icon iconName="leaderboard_outline" />
          </button>
        </div>
      );
    }
}

export default ToggleView;
