import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Header } from '../../../UxCommon';
import { Button } from '../../../UxCommon/Form';
import {authorizedToDeleteSport} from '../../../../utils/messages'

class DeleteDialog extends PureComponent {
  static propTypes = {
    userSport: PropTypes.object.isRequired,
    sport: PropTypes.object.isRequired,
    hideDialog: PropTypes.func.isRequired,
    deleteSport: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  }

  delete = () => {
    const { deleteSport, userSport, sport } = this.props;
    deleteSport(userSport, `Deleting ${sport.name}`);
  }

  render() {
    const {
      sport,
      hideDialog,
      show,
      isSportDelete,
      selectedSportTeams,
      userSport,
      isCoach,
    } = this.props;

    let message = isCoach && !isSportDelete
                  ? authorizedToDeleteSport.coachDeleteSportMessage
                  : !isCoach && !isSportDelete  ? authorizedToDeleteSport.athleteDeleteSportMessage
                  : !isCoach && userSport.createdBy === 'coach' ? authorizedToDeleteSport.athleteDeleteSportMessage
                  : `Are you sure you want complete delete ${sport && sport.name} from your profile?  `
                    + `Your ${sport && sport.name} "Tag Line" and "Bio" will be deleted as well`

    return (
      <div className={['UxEP_EditSports_DeleteDialog', show ? 'show' : ''].join(' ')}>
        <Container className="UxEP_EditSports_DeleteDialog_Container">
          <Row>
            <Col>
              <Header style={{ marginBottom: '40px' }}>
                {`Delete ${sport && sport.name}`}
              </Header>
              <div style={{ marginBottom: '40px', paddingLeft: '44px' }}>
                {message}
              </div>
              <div className="d-flex justify-content-around">
                <Button onClick={hideDialog}>
                  Cancel
                </Button>
                { isCoach && isSportDelete ?
                    <Button className="delete" onClick={this.delete}>
                      Delete Sport
                    </Button>
                  : (!isCoach &&  userSport.createdBy !== 'coach' && isSportDelete ) &&
                    <Button className="delete" onClick={this.delete}>
                      Delete Sport
                    </Button>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DeleteDialog;
