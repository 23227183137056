import { emailOrPhone } from '../../../../utils';

export const cognitoUsernameError = (error, action) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error.code === 'UserNotFoundException') {
    return 'Your email or password was entered incorrectly.';
  }
  if (error.code === 'InvalidParameterException') {
    if (error.message.indexOf('registered/verified email or phone_number') >= 0) {
      return 'You must confirm your account for password assistance';
    }
    return 'Please enter a valid email or phone number';
  }
  if (error.code === 'UserNotConfirmedException') {
    return '';
  }
  if (error.code === 'UsernameExistsException') {
    const s = emailOrPhone(action.data.username) === 'email' ? 'email address' : 'mobile phone number';
    return `Another user with that ${s} already exists`;
  }
  if(error.code === 'InvitedEmailException'){
    return error.message;
  }
  if(error.code === 'NotAuthorizedException'){
    return error.message;
  }
  if (error.message) {
    return 'Your email or password was entered incorrectly.'; // error.message;
  }
  return `Error: ${JSON.stringify(error)}`;
};

export const cognitoChangePasswordError = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error.code === 'InvalidParameterException') {
    const re = /^.*Value at '(.*)' failed to satisfy constraint: (.*)$/g;
    const groups = re.exec(error.message);
    if (groups[1] === 'previousPassword') {
      return 'The current password you entered is invalid';
    }
    return `${groups[1]} ${groups[2]}`;
  }
  if (error.code === 'NotAuthorizedException') {
    return 'The current password you entered is incorrect';
  }
  if (error.message) {
    return 'Your email or password was entered incorrectly.'; // error.message;
  }
  return `Error: ${JSON.stringify(error)}`;
};

