import React from "react";
import {SubbankCodes, SubbankTooltip} from "../../../../+store";
import dk_logo from "../../../../../../assets/images/logos/dk_logo.png";
import rapsodo_logo from "../../../../../../assets/images/logos/rapsodo_logo.png";
import trackman_tm_logo from "../../../../../../assets/images/logos/trackman_tm_logo.png";
import dashr_logo from '../../../../../../assets/images/DashrBlue.png'
import hit_trax_logo from "../../../../../../assets/images/logos/hit_trax_logo.png";



const CategoryLogo =  ( props ) => {
  const { toolTip, logo, renderImage,className } = props;
  return (
    <div className="iconWrap">
      {renderImage ?
        <img  src={logo} data-tip={toolTip} className={[
          'Top_100_Sport_Logo_Combine', className
        ].join(' ')}/>
        :  <i style = {{ fontSize: '20px'}} data-effect="solid" data-tip={toolTip}
              className={[
                className
              ].join(' ')} />
      }
    </div>
  )
}

export default  React.memo(CategoryLogo);



export const renderSubbankCategoryLogo = (subbankCode) => {
  let className, logo, toolTip;
  let renderImage = true;
  switch (subbankCode) {
    case SubbankCodes.BASEBALLHITTINGBLAST:
      className = "icon-blast-logo-circle-icon";
      renderImage = false;
      break;
    case SubbankCodes.DKSBASEBALLPITCHING:
    case SubbankCodes.DIAMONDKINETICSHITTING:
      logo =  dk_logo
      break;
    case SubbankCodes.RAPSODOHITTING:
    case SubbankCodes.RAPSODOSOFTBALLPITCHING:
    case SubbankCodes.RAPSODOBASEBALLPITCHING:
      logo =  rapsodo_logo
      break;
    case SubbankCodes.HOCKEYSPECIFICDASHR:
      className = 'combine-template-category-dashr-logo-label'
      logo =  dashr_logo
      break;
    case SubbankCodes.TRACKMANHITTING:
    case SubbankCodes.TRACKMANPITCHING:
      className = "icon-tm-icon combine-template-tm-logo";
      renderImage = false;
      break;
    case SubbankCodes.PORPLAYPITCHING:
      className = "icon-proplayai-icon combine-template-pro-play-logo";
      renderImage = false;
      break;
    case SubbankCodes.HITTRAX:
      logo =  hit_trax_logo
      break;
    default:
      return '';

  }
  return (
    <CategoryLogo
      renderImage={renderImage}
      className = {className}
      logo = {logo}
      toolTip ={toolTip}
    />
  );
}
