import { GET_FAVORITES_SUC } from '../../../actions/data/photo';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT
} from '../../../actions/data/cognito';

const favorites = (state = {}, action) => {
  switch (action.type) {
    case GET_FAVORITES_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.favorites,
      });
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default favorites;
