import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { asyncErrorAction } from './async';

export const NO_INTERNET = 'ajaxError.noInternet';
export const CLIENT_ERROR = 'ajaxError.clientError';
export const SERVER_ERROR = 'ajaxError.serverError';
export const FORBIDDEN_ERROR = 'ajaxError.403Error';
export const UNKNOWN_COMMS_ERROR = 'ajaxError.unknownCommsError';

export const ajaxErrorHandlerEpicFragment = (action=null) => source =>
  source.pipe(catchError((error) => {
    // console.log('new error', error);
    // console.log(error.name, error.status);
    if (error.name === 'AjaxError') {
      console.log(error);
      if (error.status === 0) {
        if (navigator.onLine) {
          return of({ success: false, action: { type: UNKNOWN_COMMS_ERROR, error }, originalAction: action });
        }
        return ({ success: false, action: { type: NO_INTERNET, error }, originalAction: action });
      }
      if (error.status === 403) {
        return of({ success: false, actions: [{ type: FORBIDDEN_ERROR, error }], originalAction: action });
      }
      if ((error.status >= 400) && (error.status < 500)) {
        return of({ success: false, action: { type: CLIENT_ERROR, error }, originalAction: action });
      }
      if (error.status >= 500) {
        return of({ success: false, action: { type: SERVER_ERROR, error }, originalAction: action });
      }
    }
    // console.log('new error', error);
    throw error;
  }));

export const ajaxErrorCustomHandlerFragment = action => source =>
  source.pipe(catchError((error) => {
    // console.log('new error', error);
    // console.log(error.name, error.status);
    if (error.name === 'AjaxError') {
      if (error.status === 0) {
        if (navigator.onLine) {
          return of({
            success: false,
            action: asyncErrorAction(
              action.type,
              'Ajax error',
              error,
              {
                action,
                ajaxErrorType: UNKNOWN_COMMS_ERROR,
              },
            ),
          });
        }
        return of({
          success: false,
          action: asyncErrorAction(
            action.type,
            'Ajax error',
            error,
            {
              action,
              ajaxErrorType: NO_INTERNET,
            },
          ),
        });
      }
      if (error.status === 403) {
        return of({
          success: false,
          action: asyncErrorAction(
            action.type,
            'Ajax error',
            error,
            {
              action,
              ajaxErrorType: FORBIDDEN_ERROR,
            },
          ),
        });
      }
      if ((error.status >= 400) && (error.status < 500)) {
        return of({
          success: false,
          action: asyncErrorAction(
            action.type,
            'Ajax error',
            error,
            {
              action,
              ajaxErrorType: CLIENT_ERROR,
            },
          ),
        });
      }
      if (error.status >= 500) {
        return of({
          success: false,
          action: asyncErrorAction(
            action.type,
            'Ajax error',
            error,
            {
              action,
              ajaxErrorType: SERVER_ERROR,
            },
          ),
        });
      }
    }
    // console.log('new error', error);
    throw error;
  }));
