import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetPlayerData';
import { getSelectedSport, getCurrentUuid ,getPlayerData } from '../Utils';
import { playerStatsGet, playerTestsGet } from '../../../store/actions/ui/uxPlayerData';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';
import {Sports} from '../../../store/actions/ui/routes';

const getSportId = (state, match) => {
  const userSport = getSelectedSport(state, match);
  if (userSport) {
    return userSport.sportId;
  }
  return null;
};

const getAthletePerformanceClass = (state,match) => {
  const playerData = getPlayerData(state,match);
  if(playerData) {
    return playerData.performanceClass;
  }
  return '';
}

const mapStateToProps = (state, ownProps) => {
  const sportId = getSportId(state, ownProps.match);
  return {
  uuid: getCurrentUuid(state, ownProps.match),
  sportId: sportId,
  sport : Sports[sportId],
  performanceClass: getAthletePerformanceClass(state, ownProps.match),
}};

const mapDispatchToProps = dispatch => ({
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  getStats: (uuid, sportId, msg = '') => {
    dispatch(playerStatsGet(uuid, sportId, msg));
  },
  getTests: (uuid, sportId, msg) => {
    dispatch(playerTestsGet(uuid, sportId, msg));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;