import avatarBaseballBatter from '../assets/images/Avatars-0.4/Avatar-Baseball-Batter.jpg';
import avatarBasketballMens from '../assets/images/Avatars-0.4/Avatar-Basketball-Mens.jpg';
import avatarBasketballWomens from '../assets/images/Avatars-0.4/Avatar-Basketball-Womens.jpg';
import avatarCheerleadingMens from '../assets/images/Avatars-0.4/Avatar-Cheerleading-Mens.jpg';
import avatarCheerleadingWomens from '../assets/images/Avatars-0.4/Avatar-Cheerleading-Womens.jpg';
import avatarCrossCountryMens from '../assets/images/Avatars-0.4/Avatar-Cross-Country-Mens.jpg';
import avatarCrossCountryWomens from '../assets/images/Avatars-0.4/Avatar-Cross-Country-Womens.jpg';
import avatarDivingMens from '../assets/images/Avatars-0.4/Avatar-Diving-Mens.jpg';
import avatarDivingWomens from '../assets/images/Avatars-0.4/Avatar-Diving-Womens.jpg';
import avatarFitnessMens from '../assets/images/Avatars-0.4/Avatar-Fitness-Mens.jpg';
import avatarFitnessWomens from '../assets/images/Avatars-0.4/Avatar-Fitness-Womens.jpg';
import avatarFootballMens from '../assets/images/Avatars-0.4/Avatar-Football-Men.jpg';
import avatarFootballWomens from '../assets/images/Avatars-0.4/Avatar-Football-Womens.jpg';
import avatarGolfMens from '../assets/images/Avatars-0.4/Avatar-Golf-Mens.jpg';
import avatarGolfWomens from '../assets/images/Avatars-0.4/Avatar-Golf-Womens.jpg';
import avatarHockeyMens from '../assets/images/Avatars-0.4/Avatar-Hockey-Mens.jpg';
import avatarHockeyWomens from '../assets/images/Avatars-0.4/Avatar-Hockey-Womens.jpg';
import avatarLacrosseMens from '../assets/images/Avatars-0.4/Avatar-Lacrosse-Mens.jpg';
import avatarLacrosseWomens from '../assets/images/Avatars-0.4/Avatar-Lacrosse-Womens.jpg';
import avatarSoccerMens from '../assets/images/Avatars-0.4/Avatar-Soccer-Mens.jpg';
import avatarSoccerWomens from '../assets/images/Avatars-0.4/Avatar-Soccer-Womens.jpg';
import avatarSoftballMens from '../assets/images/Avatars-0.4/Avatar-Softball-Batter-Mens.jpg';
import avatarSoftballWomensBatter from '../assets/images/Avatars-0.4/Avatar-Softball-Batter-Womens.jpg';
import avatarSoftballWomensPitcher from '../assets/images/Avatars-0.4/Avatar-Softball-Pitcher-Womens.jpg';
import avatarSwimmingMens from '../assets/images/Avatars-0.4/Avatar-Swim-Mens.jpg';
import avatarSwimmingWomens from '../assets/images/Avatars-0.4/Avatar-Swim-Womens.jpg';
import avatarTennisMens from '../assets/images/Avatars-0.4/Avatar-Tennis-Mens.jpg';
import avatarTennisWomens from '../assets/images/Avatars-0.4/Avatar-Tennis-Womens.jpg';
import avatarTrackMens from '../assets/images/Avatars-0.4/Avatar-Track-Mens.jpg';
import avatarVolleyballMens from '../assets/images/Avatars-0.4/Avatar-Volleyball-Mens.jpg';
import avatarVolleyballWomens from '../assets/images/Avatars-0.4/Avatar-Volleyball-Womens.jpg';
import avatarWakeboarder from '../assets/images/Avatars-0.4/Avatar-Wakeboarder.jpg';
import avatarWaterPoloMens from '../assets/images/Avatars-0.4/Avatar-Water-Polo-Mens.jpg';
import avatarWaterPoloWomens from '../assets/images/Avatars-0.4/Avatar-Water-Polo-Womens.jpg';
import avatarWrestlingMens from '../assets/images/Avatars-0.4/Avatar-Wrestling-Mens.jpg';
import avatarWrestlingWomens from '../assets/images/Avatars-0.4/Avatar-Wrestling-Womens.jpg';
import avatarRugbyMen from '../assets/images/Avatars-0.4/Avatar-Rugby-Men.jpg';
import avatarNetballWomen from '../assets/images/Avatars-0.4/Avatar-Netball-Women.jpg';
import avatarNetballMen from '../assets/images/Avatars-0.4/Avatar-Netball-Men.jpg';
import avatarRugbyWomen from '../assets/images/Avatars-0.4/Avatar-Rugby-Women.jpg';
import { metricSports } from '../components/UxProfile/UxProfile.constants';

export const getGrade = (user) => {
  if (user && user.grade) {
    if (user.grade < 9) {
      return `, ${user.grade}th`;
    } else if (user.grade === '9') {
      return ', FR';
    } else if (user.grade === '10') {
      return ', SO';
    } else if (user.grade === '11') {
      return ', JR';
    } else if (user.grade === '12') {
      return ', SR';
    }
  }
  return '';
};

const getPlayerHeight = (user, selectedUserSport) => {
  let height = '';
  if (user && user.heightFt) {
    if (user.heightInch) {
      height = `${user.heightFt}'${user.heightInch}"`;
    } else {
      height = `${user.heightFt}'00"`;
    }
  } else if (user && user.heightInch) {
    height = `0'${user.heightInch}"`;
  }

  if (selectedUserSport && metricSports.includes(selectedUserSport.sportId)) {
    const cmHeight = user.heightCm ? Math.floor(user.heightCm) : null;
    if (user && user.heightMt) {
      if (cmHeight) {
        height = `${user.heightMt}.${cmHeight}m`;
      } else {
        height = `${user.heightMt}m`;
      }
    } else if (user && cmHeight) {
      height = `0.${cmHeight}m`;
    }
  }

  return height;
};

const getPlayerWeight = (user, selectedUserSport) => {
  let weight = '';
  if (user && user.weight) {
    weight = `${user.weight} lbs`;
  }
  if (selectedUserSport && metricSports.includes(selectedUserSport.sportId)) {
    if (user && user.weightKg) {
      weight = `${user.weightKg} Kg`;
    }
  }
  return weight;
};

export const createPlayerProfileData = (user, selectedUserSport,isMyProfile = false) => {
  let academic = '';
  if (user && user.school) {
    // academic += `${user.school}${getGrade(user)}`;
    academic += `${user.school} `;
    // academic +=  user.gradYear  ? `- ${user.gradYear}` : '';
  }
  if ( user  && user.gradYear )
  {
    academic +=  academic  ? '-' : '';

    academic +=  user.gradYear  ? `${user.gradYear}` : '';
  }
  const height = getPlayerHeight(user, selectedUserSport);
  let physique = '';

  if (isMyProfile || (user && !user.privacy_height)) {
    physique = height;
  }

  if (isMyProfile || (user && !user.privacy_weight)) {
    if (user && user.weight && height.length > 0) {
      physique += '  |  ';
    }
    if (user && user.weight) {
      physique += getPlayerWeight(user, selectedUserSport);
    }
  }

  if(selectedUserSport && selectedUserSport.sportId){
    const { sportId, bats, throws } = selectedUserSport;
    if((sportId === 2 || sportId === 4) && ((bats && !!bats.trim()) || (throws && !!throws.trim())))
      physique += ' | ' + `${bats.trim() ||'-'}/${throws.trim() ||'-'}`;
  }

  let jerseyNumbers = '';
  if (selectedUserSport && selectedUserSport.jerseyNumbers) {
    const jerseyList = selectedUserSport.jerseyNumbers.replace(/^\s+|\s+$/g, '')
      .split(/\s*,\s*/);
    jerseyList.forEach((element) => {
      if (jerseyNumbers === '') {
        jerseyNumbers += `#${element}`;
      } else {
        jerseyNumbers += `, #${element}`;
      }
    });
  }
  return [
    {
      label: 'academic',
      info: academic,
    },
    {
      label: 'physique',
      info: physique,
    },
  ];
};

export const getAthleteDefaultImage= (user, match) => {
  if (user && user.schools)
  {
    const userSchools = user.schools;
    const coachSelectedSchoolId = match.params.schoolId;
    if ( userSchools.length )
    {
      const schoolDetail =  userSchools.find((userSchool) => (userSchool.school.id === coachSelectedSchoolId));
      const lastSchool = schoolDetail ? schoolDetail : userSchools[0];
      return lastSchool.school.school_logo
    }

  }
  return '';

}

export const getSportsAvatars = (sportId, gender) => {
  switch (sportId) {
    case 2:
      return gender === 'female' ? avatarSoftballWomensBatter : avatarBaseballBatter;
    case 3:
      return gender === 'female' ? avatarSoccerWomens : avatarSoccerMens;
    case 4:
      // if (gender === 'female') {
      //   return getRandomInt(0, 1) ? avatarSoftballWomensBatter : avatarSoftballWomensPitcher;
      // }
      return gender === 'female' ? avatarSoftballWomensPitcher : avatarSoftballMens;
    case 5:
      return gender === 'female' ? avatarBasketballWomens : avatarBasketballMens;
    case 6:
      return gender === 'female' ? avatarFitnessWomens : avatarFitnessMens;
    case 7:
      return gender === 'female' ? avatarVolleyballWomens : avatarVolleyballMens;
    case 8:
      return gender === 'female' ? avatarCrossCountryWomens : avatarCrossCountryMens;
    case 9:
      return gender === 'female' ? avatarSwimmingWomens : avatarSwimmingMens;
    case 10:
      return gender === 'female' ? avatarDivingWomens : avatarDivingMens;
    case 11:
      return gender === 'female' ? avatarGolfWomens : avatarGolfMens;
    case 12:
      return gender === 'female' ? avatarTennisWomens : avatarTennisMens;
    case 13:
      return gender === 'female' ? avatarFitnessWomens : avatarFitnessMens;
    case 14:
      return gender === 'female' ? avatarWrestlingWomens : avatarWrestlingMens;
    case 15:
      return gender === 'female' ? avatarLacrosseWomens : avatarLacrosseMens;
    case 16:
      return avatarTrackMens;
    case 17:
      return gender === 'female' ? avatarWaterPoloWomens : avatarWaterPoloMens;
    case 18:
      return gender === 'female' ? avatarHockeyWomens : avatarHockeyMens;
    case 19:
      return gender === 'female' ? avatarCheerleadingWomens : avatarCheerleadingMens;
    case 20:
      return gender === 'female' ? avatarRugbyWomen : avatarRugbyMen;
    case 21:
      return gender === 'female' ? avatarNetballWomen : avatarNetballMen;
    default:
      return gender === 'female' ? avatarFootballWomens : avatarFootballMens;
  }
};
export const getSportIconName = (sportId) => {
  switch (sportId) {
    case 1:
      return 'football-icon';
    case 2:
      return 'baseball-icon';
    case 3:
      return 'soccer-icon';
    case 4:
      return 'softball-icon';
    case 5:
      return 'basketball-icon';
    case 6:
      return 'power-lifting-icon';
    case 7:
      return 'volleyball-icon';
    case 8:
      return 'crosscountry-icon';
    case 9:
      return 'swimming-icon';
    case 10:
      return 'diving-icon';
    case 11:
      return 'golf-icon';
    case 12:
      return 'tennis-icon';
    case 13:
      return 'athletic-facility-icon';
    case 14:
      return 'wrestling-icon';
    case 15:
      return 'lacrosse-icon';
    case 16:
      return 'trackfield-icon';
    case 17:
      return 'waterpolo-icon';
    case 18:
      return 'hockey-icon';
    case 19:
      return 'cheerleading-icon';
    case 20:
      return 'rugby-icon';
    case 21:
      return 'netball-icon';
    default:
      return 'star-filled-icon';
  }
};

export const isK13Student = grade => [
  'k', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
].includes(grade);

