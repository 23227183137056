import { asyncStartAction } from '../../async';

export const CREATE_TWITTER_HANDLE = 'twitterHandle.create';
export const createTwitterHandle = (name, hasPrevValue) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_TWITTER_HANDLE));
  dispatch({
    type: CREATE_TWITTER_HANDLE,
    name,
    hasPrevValue,
  });
};

export const UPDATE_TWITTER_HANDLE = 'twitterHandle.update';
export const updateTwitterHandle = (name, hasPrevValue) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_TWITTER_HANDLE));
  dispatch({
    type: UPDATE_TWITTER_HANDLE,
    name,
    hasPrevValue,
  });
};

export const TEST_TWITTER_HANDLE = 'twitterHandle.test';
export const testTwitterHandleAction = (name, isMobile = false) => (dispatch) => {
  dispatch(asyncStartAction(TEST_TWITTER_HANDLE));
  dispatch({
    type: TEST_TWITTER_HANDLE,
    name,
    isMobile,
  });
};

export const TWITTER_HANDLE_FROM_INIT = 'twitterHandle.formInit';
export const twitterHandleFormInit = () => ({
  type: TWITTER_HANDLE_FROM_INIT,
});
