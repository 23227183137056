import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import {
  PhotosIconButton,
  Line,
  ButtonRect,
  LinkButton,
  ButtonLinkRect,
  SecondaryIconButton,
} from '../../../../UxCommon';
import {
  TextAreaControl,
  Errors,
  CustomSelectControl,
} from '../../../../UxCommon/Form';
import { Routes } from '../../../../../store/actions/ui/routes';

class PhotoInfoForm extends PureComponent {
  static propTypes = {
    media: PropTypes.object,
    match: PropTypes.object.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    albums: PropTypes.array.isRequired,
    isAlbumPicking: PropTypes.bool.isRequired,
    isCaptionEditing: PropTypes.bool.isRequired,
    onEditCaptionClick: PropTypes.func.isRequired,
    onChangeAlbumClick: PropTypes.func.isRequired,
    onChooseTagsClick: PropTypes.func.isRequired,
    album: PropTypes.object,
    selectedTags: PropTypes.array,
    isMyProfile: PropTypes.bool.isRequired,
    onShareClick: PropTypes.func.isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    isSmallScreen: PropTypes.bool,
    onSmallScreenCloseClick: PropTypes.func,
  };

  state = {
    selectedAlbumText: '',
  };

  componentDidMount() {}

  handleOptionClick = (value) => {
    const { albums } = this.props;
    const album = albums.find(a => a.id === value);
    this.setState({
      selectedAlbumText: album ? album.title : 'No Album',
    });
    const { onOptionSelected } = this.props;
    onOptionSelected(value);
  }

  renderAlbumRow = () => {
    const {
      albums,
      isAlbumPicking,
      onChangeAlbumClick,
      album,
      isMyProfile,
      rrfForm,
    } = this.props;
    const { selectedAlbumText } = this.state;
    let text = 'No Album';
    if (selectedAlbumText && selectedAlbumText !== '') {
      text = selectedAlbumText;
    } else if (album && album.title) {
      text = album.title;
    }
    const options = {
      '': { value: '', name: 'No Album' },
      ...albums.reduce((opts, a) => {
        opts[a.id] = { value: a.id, name: a.title };
        return opts;
      }, {}),
    };
    return (
      <React.Fragment>
        {isAlbumPicking ? (
          <CustomSelectControl
            options={options}
            rrfField={rrfForm.album}
            model="forms.expandedPhoto.album"
            onOptionSelected={this.handleOptionClick}
            defaultOpen
          />
        ) : (
          <React.Fragment>
            <div className="Photos_PhotoInfoForm_HeaderTxt">{text}</div>
            {isMyProfile && (
              <PhotosIconButton
                iconName="down-chevron"
                className="Photos_PhotoInfoForm_ChooseIcon"
                onClick={onChangeAlbumClick}
                type="button"
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  renderSelectedTags = () => {
    const { selectedTags } = this.props;
    if (selectedTags.length) {
      return (
        <div className="UxCommon_TagButtons_Container">
          {selectedTags
            .map((selectedTag, index) => (
              <div className="UxCommon_TagButton_Container" key={index}>
                <label htmlFor="button" className="UxCommon_TagButton_Label">
                  {selectedTag.label}
                </label>
              </div>))}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      match,
      onSaveClick,
      media,
      rrfForm,
      onDeleteClick,
      isCaptionEditing,
      onEditCaptionClick,
      onChooseTagsClick,
      isMyProfile,
      onShareClick,
      isSmallScreen,
      onSmallScreenCloseClick,
    } = this.props;
  const { schoolId, sport } = match.params;
    return (
      <div className="Photos_PhotoInfoForm_Wrapper">
        <Form
          model="forms.expandedPhoto"
          onSubmit={onSaveClick}
          className="Photos_PhotoInfoForm"
        >
          {
            !isSmallScreen && (
              <div className="Photos_PhotoInfoForm_ShareRow">
                <SecondaryIconButton
                  className="Photos_PhotoInfoForm_ShareBtn"
                  iconName="share"
                  onClick={onShareClick}
                  type="button"
                >
                  Share
                </SecondaryIconButton>
              </div>
            )
          }
          <div className="Photos_PhotoInfoForm_Row caption">
            {isCaptionEditing ? (
              <TextAreaControl
                label="Caption"
                placeholder="Add a caption"
                model="forms.expandedPhoto.caption"
                rrfField={rrfForm.caption}
              />
            ) : (
              <div className="Photos_PhotoInfoForm_Caption">
                {(media && media.caption) ||
                (isMyProfile ? 'Add a caption' : 'No caption')}
              </div>
            )}
            {isMyProfile &&
              !isCaptionEditing && (
                <PhotosIconButton
                  className="Photos_PhotoInfoForm_EditCaptionBtn"
                  iconName="edit"
                  onClick={onEditCaptionClick}
                  type="button"
                />
              )}
          </div>
          <Line style={{ margin: 0 }} />
          <div className="Photos_PhotoInfoForm_Row">
            {this.renderAlbumRow()}
          </div>
          { /* <Line style={{ margin: 0 }} />
          <div className="Photos_PhotoInfoForm_Row">
            <div className="Photos_PhotoInfoForm_HeaderTxt">Likes</div>
          </div> */ }
          <Line style={{ margin: 0 }} />
          <div className="Photos_PhotoInfoForm_Row">
            <div className="Photos_PhotoInfoForm_HeaderTxt">Tags</div>
            {
              isMyProfile && (
                <PhotosIconButton
                  className="Photos_PhotoInfoForm_EditCaptionBtn"
                  iconName="edit"
                  onClick={onChooseTagsClick}
                  type="button"
                />
              )
            }
          </div>
          <div> {this.renderSelectedTags()}</div>
          <Errors valid={rrfForm.$form.valid} errors={rrfForm.$form.errors} />
        </Form>
        {isMyProfile && (
          <div className="Photos_ExpandedPhotoDialog_ButtonsRow">
            <LinkButton iconName="trash" onClick={onDeleteClick} type="button">
              Delete
            </LinkButton>
            {
              isSmallScreen ? (
                <ButtonRect
                  type="button"
                  style={{ marginLeft: 'auto' }}
                  className="Photos_ExpandedPhotoDialog_Button Photos_ExpandedPhotoDialog_Button_Width"
                  onClick={onSmallScreenCloseClick}
                >
                  Cancel
                </ButtonRect>
              ) : (
                <ButtonLinkRect
                  style={{ marginLeft: 'auto' }}
                  className="Photos_ExpandedPhotoDialog_Button Photos_ExpandedPhotoDialog_Button_Width"
                  to={`/${Routes.profile}/${match.params.uuid}/${Routes.photos}${sport ? `/${sport}`: ''}${schoolId ? `/${schoolId}`: ''}`}
                >
                  Cancel
                </ButtonLinkRect>
              )
            }
            <ButtonRect
              className={`Photos_ExpandedPhotoDialog_Button Photos_ExpandedPhotoDialog_Button_Width ${isSmallScreen ? '' : 'primary'}`}
              onClick={onSaveClick}
              type="button"
            >
              Save
            </ButtonRect>
          </div>
        )}
      </div>
    );
  }
}

export default PhotoInfoForm;
