import { combineReducers } from 'redux';
import routes from './routes';
import mobile from './mobile';
import context from './context';

const reducer = combineReducers({
  routes,
  mobile,
  context,
});

export default reducer;
