import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { combineAndSortMedia } from '../../store/actions/ui/uxProfile/photosVideos/utils';
import { isMobileApp } from '../../utils';
import { YOUTH } from './../../components/UxProfile/UxProfile.constants';

const filterTwitterHandle = (value) => {
  let aboutMeStr = value;
  if (aboutMeStr && (!aboutMeStr.startsWith('@'))) {
    aboutMeStr = `@${aboutMeStr}`;
  }
  return aboutMeStr || '@';
};

export const getAuthenticatedUserProfile = (state) => {
  const profile = state.data.user.profiles[state.data.cognito.uuid];
  if (profile) {
    profile.twitterHandle = filterTwitterHandle(profile.twitterHandle);
  }
  return profile || null;
};

export const getCurrentSportId = state =>
  (isMobileApp && state.ui.app.mobile.currentSport
    ? state.ui.app.mobile.currentSport.sportId
    : state.ui.app.routes.currentSportId);

export const getCurrentSport = (state) => {
  let sportId = 1;
  if (getCurrentSportId(state)) {
    sportId = getCurrentSportId(state);
  }
  const sport = state.data.sport.sports.find(s => s.id === sportId);
  return sport || {};
};

export const getCurrentUuid = (state, match = null) => (
  match
    ? state.ui.app.routes.currentUuid
    : (state.ui.app.mobile.currentUuid || state.data.cognito.uuid)
);

export const getCurrentUuidSchoolStyle = (state, match) => {
  const uuid = getCurrentUuid(state, match);
  const profile = state.data.user.profiles[uuid];
  const currentSchoolId = state.ui.app.routes.currentSchoolId;
  if(currentSchoolId && profile && profile.schools.length !== 0){
    const schoolDetail = profile.schools.find(school => school.school_id === currentSchoolId);
    return schoolDetail.school;
  }
  return profile && profile.schools.length !== 0 ? profile.schools[0].school : null;
};

export const getSelectedSport = (state, match = null) => {
  let userSport = null;
  const uuid = getCurrentUuid(state, match);
  if (match) {
    const profile = state.data.user.profiles[uuid];
    if (profile) {
      const sportId = state.ui.app.routes.currentSportId;
      if (sportId) {
        userSport = profile.sports.find(s => s.sportId === sportId);
      } else {
        userSport = profile.sports.find(s => (!!s.primary));
      }
    }
  } else {
    // On mobile
    const profile = state.data.user.profiles[uuid];
    if (profile) {
      userSport = state.ui.app.mobile.currentSport;
      if (!userSport) {
        userSport = profile.sports.find(s => (!!s.primary));
      }
    }
  }
  return userSport;
};

export const getProfile = (state, match = null) => {
  const uuid = getCurrentUuid(state, match);
  const profile = state.data.user.profiles[uuid];
  if (profile && profile.twitterHandle) {
    profile.twitterHandle = filterTwitterHandle(profile.twitterHandle);
  }
  return profile;
};

export const getLeaderboardData = (state, currentUuid, combineId) => {
  if (state.data.sport.combineRecords[currentUuid]) {
    const data = state.data.sport.combineRecords[currentUuid];
    if (data.id === combineId) {
      return state.data.sport.combineRecords[currentUuid]
    }
  }
}

export const getProfileData = (state, match) => {
  const uuid = getCurrentUuid(state, match);
  const profileData = state.data.sport.profileData[uuid];
  return profileData || null;
};

const sortSports = (a, b) => a.sportId - b.sportId;

export const getSortedSports = (state, match) => {
  const profile = getProfile(state, match);
  if (profile && profile.sports) {
    const sports = profile.sports.slice();
    sports.sort(sortSports);
    return sports;
  }
  return [];
};

export const getSeasonStats = (state, match = null) => {
  const currUuid = getCurrentUuid(state, match);
  const selectedUserSport = getSelectedSport(state, match);
  if (currUuid && selectedUserSport) {
    const userStats = state.data.sport.userStats[currUuid];
    if (userStats) {
      return userStats[selectedUserSport.sportId];
    }
  }
  return null;
};

export const getUserTeams = (state, match = null) => {
  const currUuid = getCurrentUuid(state, match);
  const selectedUserSport = getSelectedSport(state, match);
  if (currUuid && selectedUserSport) {
    const userTeams = state.data.sport.userTeams[currUuid];
    if (userTeams) {
      return userTeams[selectedUserSport.sportId];
    }
  }
  return null;
};

export const getStandardStats = (state, match = null) => {
  const selectedUserSport = getSelectedSport(state, match);
  if (selectedUserSport) {
    return state.data.sport.statsStd[selectedUserSport.sportId];
  }
  return null;
};

export const getPlayerData = (state, match = null) => {
  const currUuid = getCurrentUuid(state, match);
  if (currUuid) {
    return state.data.sport.playerData[currUuid];
  }
  return null;
};

export const getVcScore = (state, ownProps = null) => {
  // NOTE: this component must load VC Rating from profileData or playerData, depending upon
  // whether the user is on the player profile screen or on the player data screen.
  // Therefore, it is looking for profileData, but if that is not available, it will
  // load from playerData - RM
  const sportId = isMobileApp
  && state.ui.app.mobile.currentSport
    ? state.ui.app.mobile.currentSport.sportId
    : state.ui.app.routes.currentSportId;
  const profileData = getProfileData(state, ownProps.match);
  let sportData;
  if (profileData) {
    sportData = (profileData.performanceClass === YOUTH) ? profileData[Object.keys(profileData)[0]] : profileData[sportId];

    if (sportData) {
      return sportData.vcRating;
    }
  } else {
    const playerData = getPlayerData(state, ownProps.match);
    //   const sportData = playerData[sportId];
    // if (playerData && playerData.vcRating && playerData.vcScore.strengthRating
    //   && playerData.vcScore.performanceRating) {
    //   return {
    //     strength: playerData.vcScore.strengthRating,
    //     performance: playerData.vcScore.performanceRating,
    //     rating: playerData.vcRating,
    //   };
    // }

    if (playerData && playerData.vcRatings) {
      // const sportData = playerData.vcRatings[sportId];
      let sportData;
      // sportData = (playerData.performanceClass === 'YOUTH') ? playerData.vcRatings.find(vc => vc ? Object.keys(vc).length > 0:false) :  playerData.vcRatings[sportId];
      if (playerData.performanceClass === YOUTH) {
        sportData = playerData.vcRatings.find(vc => vc ? Object.keys(vc).length > 0 : false);
      } else {
        sportData = playerData.vcRatings[sportId];
      }
      if (sportData) {
        return {
          strength: sportData.vcScore.strengthRating,
          performance: sportData.vcScore.performanceRating,
          rating: sportData.vcRating,
          performanceSignature: sportData.performanceSignature,
          strengthSignature: sportData.strengthSignature,
        };
      }
    }
  }
  return null;
};

export const getVcRating = (state, ownProps = null) => {
  const playerData = getPlayerData(state, ownProps.match);
  const sportId = isMobileApp
  && state.ui.app.mobile.currentSport
    ? state.ui.app.mobile.currentSport.sportId
    : state.ui.app.routes.currentSportId;
  if (playerData && playerData.vcRatings) {
    const sportData = playerData.vcRatings[sportId];
    if (sportData) {
      return {
        vcScore: {
          strength: sportData.vcScore.strengthRating,
          performance: sportData.vcScore.performanceRating,
          rating: sportData.vcRating,
        },
        performanceSignature: sportData.performanceSignature,
        strengthSignature: sportData.strengthSignature,
      };
    }
  }
  return null;
};

// True if form and original data are equal to each other, false ow
export const isSame = (formData, actualData, labels) => {
  if (!formData || !labels) {
    return true;
  }
  const formPropNames = Object.getOwnPropertyNames(formData);
  if (!actualData) { // no actual data, empty form in case of adding, change password, etc
    for (let i = 0; i < labels.length; i += 1) {
      const propName = labels[i];
      if (formPropNames.includes(propName) && formData[propName].value !== null && formData[propName].value !== '') {
        return false;
      }
    }
  } else {
    const dataPropNames = Object.getOwnPropertyNames(actualData);
    // check if form's data are equal to its original data
    for (let i = 0; i < labels.length; i += 1) {
      const propName = labels[i];
      if (dataPropNames.includes(propName) &&
        formPropNames.includes(propName) &&
        formData[propName].value !== actualData[propName]) {
        return false;
      }
    }
  }
  return true;
};

// True if there is unsaved changes, false otherwise
export const checkForUnsavedChanges = (state) => {
  // Disable Alert for now until fixed
  // console.log('***** THE STATE: ', state);
  return false;

  // let form;
  // let originalData;
  // let labels;
  //
  // switch (state.ui.app.routes.currentRoute) {
  //   case Routes.editAccount:
  //     labels = accountFormInputs;
  //     originalData = getAuthenticatedUserProfile(state);
  //     form = state.forms.forms.account;
  //     break;
  //   case Routes.editAcademic:
  //     labels = academicsFormInputs;
  //     originalData = getAuthenticatedUserProfile(state);
  //     form = state.forms.forms.account;
  //     break;
  //   case Routes.changePassword:
  //     labels = changePasswordFormInputs;
  //     originalData = null;
  //     form = state.forms.forms.changePassword;
  //     break;
  //   case Routes.editSports:
  //     labels = editSportFormInputs;
  //     originalData = getSortedSports(state)
  //       .find(el => el.sportId === state.ui.uxEditProfile.editSports.editSportId)
  //       || {
  //         aboutMe: '',
  //         bio: '',
  //         commits: '',
  //         offers: '',
  //         position: '',
  //         jerseyNumbers: '',
  //         primary: true,
  //       };
  //     form = state.forms.forms.userSport;
  //     break;
  //   default:
  //     originalData = null;
  //     form = null;
  //     break;
  // }
  // return !isSame(form, originalData, labels);
};

export const sortByUpdatedAt = (a, b) => {
  if (moment(a.updatedAt)
    .isAfter(moment(b.updatedAt))) {
    return -1;
  } else if (a.updatedAt === b.updatedAt) {
    return 0;
  }
  return 1;
};

export const sortByOpenDate = (a, b) => {
  if (moment(a.openDate)
    .isBefore(moment(b.openDate))) {
    return -1;
  } else if (a.openDate === b.openDate) {
    return 0;
  }
  return 1;
};

export const sortByNewestCreated = (a, b) => {
  if (moment(a.createdAt)
    .isAfter(moment(b.createdAt))) {
    return -1;
  } else if (a.createdAt === b.createdAt) {
    return 0;
  }
  return 1;
};

export const getAlbums = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  let albums = state.data.photo.albums[uuid] || [];
  albums = albums.slice();
  albums.sort(sortByUpdatedAt);
  return albums;
};

// TODO: Aaron please check
const getTags = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  let tags = state.data.photo.tags[uuid] || [];
  tags = tags.slice();
  tags.sort(sortByUpdatedAt);
  return tags;
};

export const getPhotosList = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  const photos = state.data.photo.photos[uuid] || [];
  const videos = state.data.photo.videos[uuid] || [];
  const combined = combineAndSortMedia(photos, videos);
  const form = state.forms.albumDialog;
  const album = state.ui.uxProfile.photosVideos.albumDialog.albumToEdit;
  if (album) {
    return combined.filter(media => media.getAlbumId() === album.id);
  }
  const albumId = form.selectedAlbum;
  if (albumId) {
    return combined.filter(media => media.getAlbumId() === albumId);
  }
  return [];
};

export const getAlbumTags = (state, ownProps) => {
  let tempTags = [];
  const tags = getTags(state, ownProps);
  const photoList = getPhotosList(state, ownProps);
  const allTagIds = photoList.map(item => item.tagIds);
  const intersectionTagIds = allTagIds && allTagIds.length > 0 &&
    allTagIds.reduce((a, b) => a.filter(c => b.includes(c)));

  if (intersectionTagIds && intersectionTagIds.length > 0) {
    tempTags = tags.filter(tag => intersectionTagIds.indexOf(tag.id) > -1);
  } else {
    tempTags = [];
  }
  return tempTags;
};

const lengthDashQuarterinch = 'LENGTH-DASH-QUARTERINCH';
const lengthInchesHalfinch = 'LENGTH-INCHES-HALFINCH';
const lengthInchesInteger = 'LENGTH-INCHES-INTEGER';
const lengthInchesQuarterinch = 'LENGTH-INCHES-QUARTERINCH';
const lengthInchesTenths = 'LENGTH-INCHES-TENTHS';
const lengthmetersHundredths = 'LENGTH-METERS-HUNDREDTHS';
const lengthMetersInteger = 'LENGTH-METERS-INTEGER';
const lengthMetersTenths = 'LENGTH-METERS-TENTHS';
const lengthhQuarterinch = 'LENGTH-QUARTERINCH';
const numericHundredths = 'NUMERIC-HUNDREDTHS';
const numericInteger = 'NUMERIC-INTEGER';
const numericTenths = 'NUMERIC-TENTHS';
const numericThousandths = 'NUMERIC-THOUSANDTHS';
const percentageHundredths = 'PERCENTAGE-HUNDREDTHS';
const percentageInteger = 'PERCENTAGE-INTEGER';
const percentageTenths = 'PERCENTAGE-TENTHS';
const percentageIntegerHundredths = 'PERCENTAGE-INTEGER-HUNDREDTHS';
const decimalPercentage = 'DECIMAL-PERCENTAGE';
const statDecimalPercentage = 'TOURNAMENT-STAT-DECIMAL-PERCENTAGE';
const speedMphInteger = 'SPEED-MPH-INTEGER';
const speedMphTenths = 'SPEED-MPH-TENTHS';
const speedMphHundredths = 'SPEED-MPH-HUNDREDTHS';
const speedMpsHundredths = 'SPEED-MPS-HUNDREDTHS';
const timeHundredths = 'TIME-HUNDREDTHS';
const timeInteger = 'TIME-INTEGER';
const timeSecHundredths = 'TIME-SEC-HUNDREDTHS';
const negativeTimeSecHundredths = 'NEGATIVE-TIME-SEC-HUNDREDTHS';
const timeSecInteger = 'TIME-SEC-INTEGER';
const timeSecTenths = 'TIME-SEC-TENTHS';
const timeTenths = 'TIME-TENTHS';
const weightPoundsHundredths = 'WEIGHT-POUNDS-HUNDREDTHS';
const weightPoundsInteger = 'WEIGHT-POUNDS-INTEGER';
const weightPoundsTenths = 'WEIGHT-POUNDS-TENTHS';
const scoreFormat = 'SCORE';
const speedRpmInteger = 'SPEED-RPM-INTEGER';
const speedRpmTenth = 'SPEED-TENTH';
const speedRpmHundredth = 'SPEED-RPM-HUNDREDTH';
const degreesInteger = 'DEGREES-INTEGER';
const degreesTenths = 'DEGREES-TENTHS';
const degreesHundredths = 'DEGREES-HUNDREDTHS';
const lengthFeetHundredths = 'LENGTH-FEET-HUNDREDTHS';
const lengthFeetInteger = 'LENGTH-FEET-INTEGER';
const timeHoursMintues = 'TIME-HOURS-MINUTES';
const cmInteger = 'CM-INTEGER';
const cmTenths = 'CM-TENTHS';
const cmHundredths = 'CM-HUNDREDTHS';
const kgInteger = 'KG-INTEGER';
const kgTenths = 'KG-TENTHS';
const kgHundredths = 'KG-HUNDREDTHS';
const mlKgMinTenths = 'MLKGMIN-TENTHS';
const yardInterger = 'YARD-INTEGER';
const yardTenths = 'YARD-TENTHS';
const yardHundredths = 'YARD-HUNDREDTHS';
const wattInteger = 'WATT-INTEGER';
const wattTenths = 'WATT-TENTHS';
const wattHundredths = 'WATT-HUNDREDTHS';
const negativeInchesHundredth  = 'NEGATIVE-INCHES-HUNDREDTH';
const negativeDegreeHundredth = 'NEGATIVE-DEGREE-HUNDREDTH';
const gForceInteger =  'G-FORCE-INTEGER';
const gForceTenth = 'G-FORCE-TENTH';
const gForceHundredth = 'G-FORCE-HUNDREDTH';
const kgMSecInteger =  'KG-M-SEC-INTEGER'
const kgMSecTenth =  'KG-M-SEC-TENTH'
const kgMSecHundredth =  'KG-M-SEC-HUNDREDTH'
const msInteger = 'MS-INTEGER';
const msTenth ='MS-TENTH';
const msHundredth = 'MS-HUNDREDTH';
const negativeNumericHundredth = 'NEGATIVE-NUMERIC-HUNDREDTHS';
const negativePercentageHundredth = 'NEGATIVE-PERCENTAGE-HUNDREDTHS';

export const calcDistancePartsFromInches = (inches) => {
  const integerPart = Math.floor(inches);
  const decimalPart = inches - integerPart;
  const decimalString = `${Math.round(100 * decimalPart)}`;
  return {
    feetInt: Math.floor(integerPart / 12),
    inchesInt: integerPart % 12,
    decimalString,
  };
};

const formatLengthFromParts = (feet, inches, decimalString) => {
  if (feet) {
    if (inches) {
      if (decimalString) {
        return `${feet}' ${inches}.${decimalString}"`;
      }
      return `${feet}' ${inches}"`;
    }
    if (decimalString) {
      return `${feet}' 0.${decimalString}"`;
    }
    return `${feet}'`;
  }
  if (inches) {
    if (decimalString) {
      return `0' ${inches}.${decimalString}"`;
    }
    return `0' ${inches}"`;
  }
  if (decimalString) {
    return `0' 0.${decimalString}"`;
  }
  return '';
};


const formatLength = (value) => {
  const { feetInt, inchesInt, decimalString } = calcDistancePartsFromInches(value);
  return formatLengthFromParts(feetInt, inchesInt, decimalString);
};

const formatLengthDashQuarterinch = (value) => {
  const { feetInt, inchesInt, decimalString } = calcDistancePartsFromInches(value);
  let stringInches = String(inchesInt);
  while (stringInches.length < 2) {
    stringInches = `0${stringInches}`;
  }
  return `${feetInt}-${stringInches}.${decimalString}`;
};

export const pad3 = (number) => {
  if (number <= 999) {
    return `00${Math.round(number)}`.slice(-3);
  }
  return number;
};


export const formatResult = (value, format, units = true, rounding = true) => {
  momentDurationFormatSetup(moment);
  if (format === lengthhQuarterinch) {
    return units ? formatLength(value) : value;
  } else if (format === lengthDashQuarterinch) {
    return formatLengthDashQuarterinch(value);
  }
  // Remove trailing zeros from integers
  // if (format !== timeHundredths && Number.isInteger(value)) {
  //   return value;
  // }
  let retVal;
  if (value || (value === 0 && format)) {
    switch (format) {
      case lengthInchesHalfinch:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}"` : retVal;
      case lengthInchesInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal}"` : retVal;
      case lengthInchesQuarterinch:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}"` : retVal;
      case lengthInchesTenths:
      case negativeInchesHundredth:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}"` : retVal;
      case negativeNumericHundredth:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}` : retVal;
      case numericInteger:
        return rounding ? `${value.toFixed(0)}` : value;
      case numericTenths:
        return rounding ? `${value.toFixed(1)}` : value;
      case numericHundredths:
        return rounding ? `${value.toFixed(2)}` : value;
      case numericThousandths:
        return rounding ? `${value.toFixed(3)}` : value;
      case timeInteger:
        return moment.duration(value, 'second')
          .format('*m:ss');
      case timeTenths:
        return moment.duration(value, 'second')
          .format('*m:ss.S');
      case timeHundredths:
        return moment.duration(value, 'second')
          .format('*m:ss.SS');
      case lengthMetersInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal}m` : retVal;
      case lengthMetersTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}m` : retVal;
      case lengthmetersHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}m` : retVal;
      case weightPoundsInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} lbs` : retVal;
      case weightPoundsTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} lbs` : retVal;
      case weightPoundsHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} lbs` : retVal;
      case speedMphInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} mph` : retVal;
      case speedMphTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} mph` : retVal;
      case speedMphHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} mph` : retVal;
      case speedMpsHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} m/s` : retVal;
      case percentageInteger:
        retVal = rounding ? parseInt(value, 10) : value;
        return units ? `${retVal}%` : retVal;
      case percentageIntegerHundredths:
        retVal = rounding ? parseInt(value, 10) : value;
        return units ? `${retVal}%` : retVal;
      case percentageTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}%` : retVal;
      case percentageHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}%` : retVal;
      case negativePercentageHundredth:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}%` : retVal;
      case decimalPercentage:
        if(value < 1) {
         retVal = rounding ? value.toFixed(3).slice(1) : value;
         return units ? `${retVal}` : retVal;
        } else if( value === 1 ){
          retVal = rounding ? value : value;
          return units ? `${retVal}.000` : retVal;
        }
        else {
          retVal = rounding ? value : value;
          return units ? `${retVal}` : retVal;
        }
      case statDecimalPercentage:
      if(value < 5) {
        retVal = rounding ? value.toFixed(3) : value;
        return units ? `${retVal}` : retVal;
       } else if( value === 5 ){
         retVal = rounding ? value : value;
         return units ? `${retVal}.000` : retVal;
       }
       else {
         retVal = rounding ? value : value;
         return units ? `${retVal}` : retVal;
       }
      case timeSecInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal}s` : retVal;
      case timeSecTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}s` : retVal;
      case timeSecHundredths:
        retVal = rounding ? Number(value).toFixed(2) : value;
        return units ? `${retVal}s` : retVal;
      case negativeTimeSecHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}s` : retVal;
      case scoreFormat:
        return pad3(value);
      case speedRpmInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} rpm` : retVal;
      case speedRpmTenth:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} rpm` : retVal;
      case speedRpmHundredth:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} rpm` : retVal;
      case degreesInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal}°` : retVal;
      case degreesTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}°` : retVal;
        case degreesHundredths:
        case negativeDegreeHundredth:
          retVal = rounding ? value.toFixed(2) : value;
          return units ? `${retVal}°` : retVal;
      case lengthFeetHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} ft.` : retVal;
      case lengthFeetInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} ft.` : retVal;
      case timeHoursMintues:
        retVal = `${Math.floor(value / 60)}:${Math.floor(value % 60) < 10 ? '0' : ''}${Math.floor(value % 60)}`;
        return `${retVal}`;
      case cmInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal}cm` : retVal;
      case cmTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal}cm` : retVal;
      case cmHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal}cm` : retVal;
      case kgInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} kg` : retVal;
      case kgTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} kg` : retVal;
      case kgHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} kg` : retVal;
      case mlKgMinTenths:
        return rounding ? `${value.toFixed(1)}` : value;
      case yardInterger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} yds` : retVal;
      case yardTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} yds` : retVal;
      case yardHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} yds` : retVal;
      case wattInteger:
        retVal = rounding ? value.toFixed(0) : value;
        return units ? `${retVal} w` : retVal;
      case wattTenths:
        retVal = rounding ? value.toFixed(1) : value;
        return units ? `${retVal} w` : retVal;
      case wattHundredths:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} w` : retVal;
        case gForceInteger:
          retVal = rounding ? value.toFixed(0) : value;
          return units ? `${retVal} g` : retVal;
        case gForceTenth:
          retVal = rounding ? value.toFixed(1) : value;
          return units ? `${retVal} g` : retVal;
      case gForceHundredth:
        retVal = rounding ? value.toFixed(2) : value;
        return units ? `${retVal} g` : retVal;
        case kgMSecInteger:
          retVal = rounding ? value.toFixed(0) : value;
          return units ? `${retVal} kg*m/sec` : retVal;
        case kgMSecTenth:
          retVal = rounding ? value.toFixed(1) : value;
          return units ? `${retVal} kg*m/sec` : retVal;
        case kgMSecHundredth:
          retVal = rounding ? value.toFixed(2) : value;
          return units ? `${retVal} kg*m/sec` : retVal;
        case msInteger:
          retVal = rounding ? value.toFixed(0) : value;
          return units ? `${retVal} ms` : retVal;
        case msTenth:
          retVal = rounding ? value.toFixed(1) : value;
          return units ? `${retVal} ms` : retVal;
        case msHundredth:
          retVal = rounding ? value.toFixed(2) : value;
          return units ? `${retVal} ms` : retVal;
      default:
        return value;
    }
  }
  return 0;
};

export const sortPositions = (a, b) => a.orderNum - b.orderNum;

export const sortStdCats = (a, b) => a.orderNum - b.orderNum;

export const sortStatPositions = (a, b) => a.stdStat.orderNum - b.stdPosition.orderNum;

const getOS = () => {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (
    (iosPlatforms.indexOf(platform) !== -1 || platform === 'MacIntel')
    && !window.MSStream) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const isIOS = () => getOS() === 'iOS';
export const isWindows = () => getOS() === 'Windows';
export const isAndroid = () => getOS() === 'Android';

export const isRealNumber = num => num !== '' && num != null && !Number.isNaN(Number(num));

export const camelCase = (string = null) => (string ? string.charAt(0)
  .toUpperCase() + string.substring(1)
  .toLowerCase() : '');
