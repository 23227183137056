import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import HelpPopper from "../../../../../components/UxCommon/Form/HelpPopper/HelpPopper";
import {getCellNewValue, getCellOldValue, isErrorOnCell} from "../CombineAgGrid.helpers";
import {validateBiggerThan} from "../../../+store/combine";

export default forwardRef((props, ref) => {
    const {isLaserField, isRepField, formatToEdit, allowedCharTest, validate, validateReps, help, isLaserChecked, parse, column, validateBiggerThan} = props
    const isFeetInches = column && column.standardUnitOfMeasureCode === 'FEET-INCHES';
    const inputRef = useRef();
    const repInputRef = useRef();
    const laserInputRef = useRef();
    const [value, setValue] = useState('');
    const [isLaser, setIsLaser] = useState(isLaserChecked);
    const initialRep = isFeetInches ? 0 : 1;
    const [rep, setRep] = useState(initialRep);
    const [error, setError] = useState(null);
    const [inputAttrs, setInputAttrs] = useState({
      type: "number",
      step: "0.01",
      min: "0",
      lang: "en"
    })


    useEffect(() => {
      // focus on the input
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 20);
    }, []);

    const inputHandler = (e) => {
      const val = e.target.value.replace(',', '.');
      setError(validate(val));
      setError(validateBiggerThan(val));
      setValue(val);
    }

    const onEditKeyDown = (e) => {
      if (e.metaKey
        || ['Backspace', 'Delete',
          'ArrowLeft', 'ArrowRight',
          'Shift', 'Control',
          'Alt'].includes(e.key)) {
        return;
      }
      if (allowedCharTest) {
        if (!allowedCharTest(e.key)) {
          e.preventDefault();
        }
      }
      if (isRepField && ['Enter', 'Tab'].includes(e.key)) {
        repInputRef.current.select();
        repInputRef.current.focus();
        setTimeout(()=>{
          repInputRef.current.focus();
        },100)
      }
      if (isLaserField && ['Tab'].includes(e.key)) {
        laserInputRef.current.focus();
        laserInputRef.current.select();
      }
    }

    const onLaserChange = (e) => {
      e.target.focus();
      setIsLaser(e.target.checked);
    };

    const onRepChange = (e) => {
      const val = e.target.value.replace(',', '.');
      if(!isFeetInches){
        setError(validateReps(val));
      }
      setRep(val);
    }

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          if (isLaserField) {
            return {result: value, isLaser};
          }
          if (isRepField) {
            return {result: value, reps: rep};
          }

          return {result: value}

        },
        afterGuiAttached: () => {
         // debugger;
          const value = isErrorOnCell(props) ? getCellOldValue(props) : props.value;
          if(isFeetInches && value){
            const inches = value.result;
            const integerPart = Math.floor(inches);
            const decimalPart = inches - integerPart;
            const decimalString = Math.round(100 * decimalPart)/100;
             setValue(Math.floor(integerPart / 12));
             setRep((integerPart % 12) + decimalString)
          }else{
            setValue(value == null || value === '' ? '' : formatToEdit(value.result));
            if (isLaserField && value) {
              setIsLaser(!!value.isLaser);
            } else if (isRepField && value) {
              const reps = !!value.result ? value.reps : isFeetInches ? 0 : 1;
              setRep(props.formatToEdit(reps));
            }
          }
        },

        isCancelAfterEnd: () => {
          return error;
        }
      }
    })

    if (isRepField) {
      return (
        <form className="CombineEntry_Form" style={{position: 'static'}}>
          {
            error && (
              <HelpPopper error focus={true} helpClass="bottom">
                {error}
              </HelpPopper>
            )
          }
          {
            !error && help && (
              <HelpPopper focus={true} helpClass="bottom">
                {help}
              </HelpPopper>
            )
          }
          <div>
            <input
              key="results"
              {...inputAttrs}
              className="combine-text-input half reps-result"
              ref={inputRef}
              type={"number"}
              onChange={inputHandler}
              onFocus={() => setInputAttrs()}
              value={value}
              onKeyDown={onEditKeyDown}
            />
            {isFeetInches ? '&' : 'X'}
            <input
              key="reps"
              {...inputAttrs}
              className="combine-text-input half reps"
              ref={repInputRef}
              onChange={onRepChange}
              type={"number"}
              onFocus={() => setInputAttrs()}
              value={rep}
              onKeyDown={onEditKeyDown}
            />
          </div>
        </form>
      )
    }

    if (isLaserField) {
      return (
        <form className="CombineEntry_Form" style={{position: 'static'}}>
          {
            error && (
              <HelpPopper error focus={true} helpClass="bottom">
                {error}
              </HelpPopper>
            )
          }
          {
            !error && help && (
              <HelpPopper focus={true} helpClass="bottom">
                {help}
              </HelpPopper>
            )
          }
          <div className="grid-editing-cell">
            <input
              {...inputAttrs}
              className="combine-text-input laser"
              ref={inputRef}
              onChange={inputHandler}
              type={"number"}
              onFocus={() => setInputAttrs()}
              value={value}
              onKeyDown={onEditKeyDown}
            />
            <div className="editing-laser-container">
              <input
                checked={isLaser}
                onChange={onLaserChange}
                ref={laserInputRef}
                type="checkbox"
                className="laser-checkbox"
              />
              Laser?
            </div>
          </div>
        </form>
      );
    }

    return (
      <form className="CombineEntry_Form" style={{position: 'static'}}>
        {
          error && (
            <HelpPopper error focus={true} helpClass="bottom">
              {error}
            </HelpPopper>
          )
        }
        {
          !error && help && (
            <HelpPopper focus={true} helpClass="bottom">
              {help}
            </HelpPopper>
          )
        }
        <input
          {...inputAttrs}
          className="combine-text-input"
          ref={inputRef}
          value={value}
          onFocus={() => setInputAttrs({type: "text"})}
          onChange={inputHandler}
          onKeyDown={onEditKeyDown}
        />
      </form>
    )
  }
)
