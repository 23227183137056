import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment, getTokenFragment } from '../../../../store/actions/ui/uxProfile/utils';
import { SAVE_PHYSIQUE } from './physiquePage.actions';
import { mapPhysiqueUiToApi } from './physiquePage.models';
import { putPhysique } from './physiquePage.api';
import { userUpdatePhysique } from '../../../../store/actions/data/user';


const savePhysiqueEpic = action$ => (
  action$.pipe(
    ofType(SAVE_PHYSIQUE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      putPhysique(
        action.uuid,
        token,
        mapPhysiqueUiToApi(action.fields),
      ).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'codeSignUpSavePhysique',
      ))
    )),
    switchMap((result) => {
      debugger;
      if (result.success) {
        return of(
          asyncFinishAction(result.action.type, 'codeSignUpSavePhysique', {
            ...result.action.fields,
            code: result.action.code,
          }),
          userUpdatePhysique(result.action.uuid, {
            heightFt: Math.floor(result.action.fields.height / 12),
            heightInch: result.action.fields.height % 12,
            weight: result.action.fields.weight,
            gender: result.action.fields.gender,
            grade: result.action.fields.grade,
            grad_year: result.action.fields.grad_year,
            zip: result.action.fields.zip,
            city: result.action.fields.city,
            state: result.action.fields.state,
            address: result.action.fields.address,
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const epics = combineEpics(savePhysiqueEpic);

export default epics;
