import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../actions/ui/async';
import { VANITY_URL_FORM_INIT, UPDATE_VANITY_URL, CREATE_VANITY_URL, TEST_VANITY_URL } from '../../../actions/ui/uxEditProfile/vanityUrl';
import { VANITY_URL_SAVE_SUC } from '../../../actions/data/user/vanity';

const validation = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_FINISH:
      if ([CREATE_VANITY_URL, UPDATE_VANITY_URL, TEST_VANITY_URL].includes(action.model)) {
        return null;
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === TEST_VANITY_URL) {
        return action.error;
      }
      return state;
    default:
      return state;
  }
};

const isAlreadyExistInstagramValidationMessage = (state = null, action) => {
  switch (action.type) {
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.action === "testVanityUrl") {
        return  !!action.data ? action.data.message : '';
      }
      return state;
    case ASYNC_ERROR:
      if (action.model === TEST_VANITY_URL) {
        return action.error;
      }
      return state;
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if ((action.model === CREATE_VANITY_URL)
      || (action.model === UPDATE_VANITY_URL)) {
        return 'Saving your url ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ((action.model === CREATE_VANITY_URL)
      || (action.model === UPDATE_VANITY_URL)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case VANITY_URL_SAVE_SUC:
      return true;
    case VANITY_URL_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const message = (state = '', action) => {
  switch (action.type) {
    case VANITY_URL_SAVE_SUC:
      return 'Personal URL Saved!';
    case VANITY_URL_FORM_INIT:
    case TEST_VANITY_URL:
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  formNeedsInit,
  message,
  validation,
  isAlreadyExistInstagramValidationMessage
});

export default reducer;
