import { combineReducers } from 'redux';

import {
  OPEN_MULTI_DELETE,
  CLOSE_MULTI_DELETE,
  MULTI_DELETE_SELECT,
  MULTI_DELETE_SELECT_ALL,
  MULTI_DELETE_UNSELECT,
  MULTI_DELETE_EXECUTE,
  ALBUM_FILTER_SELECTED,
  ALBUM_FILTER_UNSELECTED,
} from '../../../../actions/ui/uxProfile/photosVideos';
import {
  ASYNC_FINISH,
  ASYNC_START,
  ASYNC_ERROR,
} from '../../../../actions/ui/async';

const stage = (state = false, action) => {
  switch (action.type) {
    case OPEN_MULTI_DELETE:
      return 'open';
    case CLOSE_MULTI_DELETE:
      return 'closed';
    case ASYNC_FINISH:
      if (action.model === MULTI_DELETE_EXECUTE) {
        return 'results';
      }
      return state;
    default:
      return state;
  }
};

const selected = (state = [], action) => {
  switch (action.type) {
    case MULTI_DELETE_SELECT:
      return [...state, action.media];
    case MULTI_DELETE_SELECT_ALL:
      return [...state, ...action.media];
    case MULTI_DELETE_UNSELECT:
      return state.filter(m => m.id !== action.media.id);
    case CLOSE_MULTI_DELETE:
    case OPEN_MULTI_DELETE:
    case ALBUM_FILTER_SELECTED:
    case ALBUM_FILTER_UNSELECTED:
      return [];
    default:
      return state;
  }
};

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === MULTI_DELETE_EXECUTE) {
        return 'Deleting photos/videos ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === MULTI_DELETE_EXECUTE) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const error = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === MULTI_DELETE_EXECUTE) {
        return 'Please select a photo/video to delete';
      }
      return state;
    case ASYNC_START:
      if (action.model === MULTI_DELETE_EXECUTE) {
        return '';
      }
      return state;
    case MULTI_DELETE_SELECT:
    case MULTI_DELETE_SELECT_ALL:
    case CLOSE_MULTI_DELETE:
    case ALBUM_FILTER_SELECTED:
    case ALBUM_FILTER_UNSELECTED:
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  selected,
  loadingMsg,
  error,
  stage,
});

export default reducer;
