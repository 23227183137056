import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

const SignOutModal = ({ onSignOut, onCancel }) => (
  <div className="CoachSignOutModal_Backdrop">
    <div className="CoachSignOutModal">
      <CloseButton size={15} onClick={onCancel} />
      <div className="CoachSignOutModal_Header" />
      <div className="CoachSignOutModal_Body">
        Are you sure you want to sign out?
      </div>
      <div className="CoachSignOutModal_Footer">
        <ButtonRect
          className="CoachSignOutModal_Btn primary"
          type="button"
          onClick={onSignOut}
        >
          Sign Out
        </ButtonRect>
        <ButtonRect
          className="CoachSignOutModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
);
SignOutModal.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SignOutModal;
