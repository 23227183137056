import { connect } from "react-redux";
import Component from "../../components/Welcome/Welcome";
import { getCurrentUser } from "../../store/actions/data/cognito/auth";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import { playerProfileGet } from "../../store/actions/ui/uxProfile";
import { Roles } from "../../store/actions/data/user/profile/models";
import { getSchoolStyles } from "../../modules/CoachWorld/+store";

const isGuest = (state) =>
  !(
    state.data.user.cognitoUser && state.data.user.cognitoUser.signInUserSession
  );

const getGuestProfile = (state) =>
  state.data.user.profiles
    ? state.data.user.profiles[Object.keys(state.data.user.profiles)[0]]
    : null;

const getProfile = (state) => {
  let profile = null;
  if (state.data.cognito.uuid) {
    profile = state.data.user.profiles[state.data.cognito.uuid];
  } else if (isGuest) {
    profile = getGuestProfile(state);
  }
  return profile || null;
};

const mapStateToProps = (state) => ({
  loading: state.ui.welcome.welcome.loading,
  loadingMsg: state.ui.welcome.welcome.loadingMsg,
  authenticating: state.data.cognito.authenticating,
  loggedIn: !!state.data.cognito.token,
  username: state.data.user.username,
  currentUser: state.data.user.user,
  currentRoute: state.ui.app.routes.currentRoute,
  uuid: state.data.cognito && state.data.cognito.uuid,
  profile: getProfile(state),
  state
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  let schoolStyle = null;
  if (ownProps.match.params.schoolId) {
    schoolStyle = state.modules.coachWorld.coachWorld.schoolStyle.find(school => school.id === ownProps.match.params.schoolId);
  }
  return {
    ...newStateProps,
    ...ownProps,
    checkLogin: getCurrentUser(dispatch),
    schoolStyle,
    isAuthenticated: () =>
      state.data.user.cognitoUser &&
      state.data.user.cognitoUser.signInUserSession,
    getProfile: () => {
      dispatch(playerProfileGet(state.data.cognito.uuid));
    },
    getSchoolStyles: (schoolIds) => {
      dispatch(getSchoolStyles(schoolIds));
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    getRoleId: () => {
      const profile =
        state.data.user.profiles &&
        state.data.user.profiles[state.data.cognito.uuid];
      if (profile) {
        return profile.roleId;
      }
      return Roles.athlete;
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default Container;
