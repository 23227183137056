import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import PageHeader from '../../../containers/UxProfile/PageHeader';
import Stats from '../../../containers/UxProfile/PlayerData/Stats';
import {
  Collapsable,
  Line,
  ContainerFluid,
  Col,
  Row,
  Tabs,
  LoadingSpinner, SecondaryIconButton,
} from '../../UxCommon';
import PersonalRecords from '../../../containers/UxProfile/PlayerData/PersonalRecords';
import TestData from './TestData/TestData';
import { Routes, Sports } from '../../../store/actions/ui/routes';
import TopBar from './TopBar/TopBar';
import CombineRecords from '../PlayerProfile/CombineRecords/CombineRecords';
import NoneYet from '../PlayerProfile/NoneYet/NoneYet';
import { bank } from '../PlayerProfile/ProfileStats/ProfileStats';

class PlayerData extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    selectedUserSport: PropTypes.object,
    sports: PropTypes.array.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    onShareClick: PropTypes.func.isRequired,
    profile: PropTypes.object,
    getProfile: PropTypes.func,
    uuid: PropTypes.string,
  };

  state = {
    currentTabId: 'vcscore',
    screenHeight: 400,
    selectedBank: '',
    playerData: [],
    filterShowRecord: [],
    personalRecords: [],
    imageWidth: 0,
    imageHeight: 0,
    orgLogo: '',
  };

  componentDidMount() {
    this.setScreenHeight();
    if (!this.props.profile) {
      this.props.getProfile(this.props.uuid);
    }
  }

  componentDidUpdate() {
    const { playerData, currentSportId } = this.props;
    this.updateSchool();
    if (playerData && playerData !== this.state.playerData) {
      const personalRecords = playerData.personalRecords;
      const showRecord = [
        ...new Set(personalRecords.map((record) => record.stdTestBanksCode)),
      ];
      const filteredRecord =
        showRecord &&
        showRecord.filter(
          (s) =>
            s === bank.PERF ||
            s === bank.STRENGTH ||
            s === bank.PITCHING ||
            (s === bank.HITTING) | (s === bank.STATS),
        );
      if (filteredRecord && filteredRecord.length !== 0) {
        filteredRecord.sort(
          (record1, record2) => (record1 > record2) - (record1 < record2),
        );
        if (
          filteredRecord.length > 1 &&
          filteredRecord[0] === bank.HITTING &&
          filteredRecord[1] === bank.PERF
        ) {
          let tempRecord = filteredRecord[0];
          filteredRecord[0] = filteredRecord[1];
          filteredRecord[1] = tempRecord;
        }
      }
      const sportsFiltered =
        filteredRecord &&
        this.filterSportsRecord(filteredRecord, currentSportId);
      if (sportsFiltered) {
        if (sportsFiltered.length !== this.state.filterShowRecord.length) {
          this.setState({ selectedBank: `${filteredRecord[0]}` });
        }
        this.setState({
          filterShowRecord: sportsFiltered,
          playerData: playerData,
          personalRecords: personalRecords,
        });
      }
    }
  }

  filterSportsRecord = (filteredRecord, currentSportId) => {
    switch (currentSportId) {
      case 2:
      case 4:
        return filteredRecord;
      default:
        return filteredRecord.filter(
          (record) =>
            record === bank.PERF || record === bank.STRENGTH || bank.STATS,
        );
    }
  };
  setScreenHeight = () => {
    const screenHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
    const headerHeight = document.querySelector('.PageHeader').offsetHeight;
    this.setState({
      screenHeight: screenHeight - headerHeight,
    });
  };

  getRoute = (page) => {
    const { match } = this.props;
    return `/${Routes.profile}/${match.params.uuid}/${page}${
      match.params.sport ? `/${match.params.sport}` : ''
    }`;
  };

  setTab = (tabId) => {
    this.setState({ currentTabId: tabId });
  };

  bankHandler = (bank) => {
    this.setState({ selectedBank: `${bank}` });
  };

  renderBankData = (banks, i) => {
    const value = bank[banks];
    if (value === bank.PERF) {
      return 'PERFORMANCE';
    }
    return value;
  };

  renderPersonalRecord = () => {
    const {
      loading,
      currentUuid,
      videoData,
      currentSportId,
      match,
    } = this.props;
    const { selectedBank, filterShowRecord, personalRecords } = this.state;

    return (
      <div className="PlayerData_PersonalRecords_Container">
        <div className={loading ? 'PlayerData_Loader' : ''}>
          <LoadingSpinner
            loading={loading}
            loadingMsg={"Loading Personal Record's"}
          />
        </div>
        <div className="PlayerData_PersonalRecords_Header">
          {filterShowRecord &&
            filterShowRecord.map((banks, i) => {
              return (
                <div
                  key={`${banks}${i}`}
                  className={[
                    'PlayerData_PersonalRecords',
                    selectedBank === `${banks}` ? 'active' : '',
                  ].join(' ')}
                  onClick={() => this.bankHandler(banks)}>
                  {this.renderBankData(banks, i)}
                </div>
              );
            })}
        </div>
        <div className="PlayerData_PersonalRecords_Data_Value">
          {filterShowRecord && filterShowRecord.length !== 0 && (
            <CombineRecords
              isCombineRecords={true} // to show the vrified icon
              videoData={videoData}
              currentUuid={currentUuid}
              classNameCombine="PlayerData_CombineRecord"
              banks={selectedBank}
              match={match}
              sportId={currentSportId}
              personalRecords={personalRecords}
            />
          )}
        </div>
        {filterShowRecord && filterShowRecord.length === 0 && (
          <div className={'UxPlayerData_PRs_NoData'}>
            <NoneYet msg="No personal record have been entered yet" />
          </div>
        )}
      </div>
    );
  };

  updateSchool = () => {
    const { imageWidth } = this.state;
    if (!imageWidth) {
      const { profile, match } = this.props;
      const coachSelectedSchoolId = match.params.schoolId;
      const userSchools =
        profile &&
        profile.schools.find(
          (userSchool) => userSchool.school.id === coachSelectedSchoolId,
        );
      const schoolDetail = userSchools
        ? userSchools
        : profile && profile.schools[0];
      const school = schoolDetail && schoolDetail.school;
      if (school && school.school_logo) {
        let img = new Image();
        img.src = school.school_logo;
        this.setState({
          imageWidth: img.width,
          imageHeight: img.height,
          orgLogo: school.school_logo,
        });
      }
    }
  };

  setImage = () => {
    const { imageHeight, imageWidth, orgLogo } = this.state;
    let className = '';
    if (imageWidth > imageHeight) {
      className = 'Organization_Logo_Width';
    } else {
      className = 'Organization_Logo';
    }

    return (
      <img
        className={className}
        id={'schoolLogoImg'}
        src={orgLogo}
        alt={'Organization Logo'}
      />
    );
  };

  signInToUpdate = () => {
    const {history} = this.props;
    history.push({
      pathname: `/${Routes.welcome}/${Routes.login}`,
    });
  }

  render() {
    const {
      match,
      selectedUserSport,
      sports,
      isMyProfile,
      onShareClick,
      profile,
      currentSportId,
    } = this.props;
    const { currentTabId, screenHeight, imageStyle } = this.state;
    const tabs = [
      { id: 'vcscore', label: 'VC Score' },
      { id: 'perfsign', label: 'Performance Sig.' },
      { id: 'strsign', label: 'Strength Sig.' },
    ];
    let selectedSportHasStats = false;
    if (selectedUserSport) {
      const selectedSport = sports.filter(
        (sport) => sport.id === selectedUserSport.sportId,
      );
      if (selectedSport[0] && selectedSport[0].hasStats === 1) {
        selectedSportHasStats = true;
      }
    }
    const { schoolId } = match.params;
    return (
      <div>
        <Route
          path={`/${Routes.profile}/:uuid/:page/:sportId?/:schoolId?`}
          component={PageHeader}
        />
        <div className="UxProfile_PlayerData">
          <div className="UxProfile_topBar">
            <TopBar
              match={match}
              profile={profile}
              signInToUpdate={this.signInToUpdate}
              isMyProfile={isMyProfile}
              onShareClick={onShareClick}
            />
            <div className="UxProfile_signIn_wrapper">
              {
                !isMyProfile && profile && !!profile.registered && !!profile.isSubscribed &&
                <SecondaryIconButton className="Photos_TopBar_signInBtn desktop_button" iconName="login-1"
                                     onClick={this.signInToUpdate}>
                  Sign In To Update
                </SecondaryIconButton>
              }
              <Link
                className="UxProfile_VcLogo PlayerData_Logo"
                to={`/${Routes.profile}/${match.params.uuid}/${Routes.player}/${
                  Sports[currentSportId]
                }${schoolId ? `/${schoolId}` : ''}`}>
                {this.setImage()}
              </Link>
            </div>
          </div>
          <ContainerFluid style={{ minHeight: `${screenHeight * 0.6}px` }}>
            <Row>
              <Col>
                {(isMyProfile || (profile && !profile.privacy_tests)) && (
                  <React.Fragment>
                    <Collapsable
                      title="Personal Records"
                      useFluidContainer
                      className="UxPlayerData_Collapsable"
                      useTightTop>
                      <div />
                      {this.renderPersonalRecord()}
                    </Collapsable>
                    <TestData match={match} selectedTab={currentTabId} />
                  </React.Fragment>
                )}
                <div style={{ marginBottom: '30px' }} />
                {selectedSportHasStats &&
                  (isMyProfile || (profile && !profile.privacy_stats)) && (
                    <div id="divStats">
                      <Line />
                      <Collapsable
                        title="Stats"
                        useFluidContainer
                        className="UxPlayerData_Collapsable">
                        <div />
                        <Stats match={match} />
                      </Collapsable>
                    </div>
                  )}
                <div style={{ marginBottom: '120px' }} />
              </Col>
            </Row>
          </ContainerFluid>
        </div>
      </div>
    );
  }
}

export default PlayerData;
