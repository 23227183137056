import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col, CloseButton } from '../../../../components/UxCommon';
import { Button } from '../../../../components/UxCommon/Form';
import Dropzone from '../../../../components/UxCommon/Dropzone/Dropzone';
import AthletePhoto from '../AthletePhoto/AthletePhoto';
import PhotoCropDialog from './PhotoCropDialog/PhotoCropDialog.container';

class UpdatePhoto extends PureComponent {
  static propTypes = {
    closeDialog: PropTypes.func,
    photoUrl: PropTypes.string,
    loadingPhoto: PropTypes.bool.isRequired,
    loadingPhotoMsg: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    dropError: PropTypes.bool.isRequired,
    photoDataToCrop: PropTypes.object,
    loadingPhotoProgress: PropTypes.object,
    match: PropTypes.object.isRequired,
    coachId: PropTypes.string,
  }

  state = {
    hideCrop: false,
  };

  componentDidMount() { }

  onFromComputerClick = () => {
    this.dropZoneRef.open();
  };

  onCancel = () => {
    this.setState({ hideCrop: true });
  }

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  render() {
    const {
      photoUrl,
      loadingPhoto,
      loadingPhotoMsg,
      onDrop,
      dropError,
      photoDataToCrop,
      loadingPhotoProgress,
      match,
      coachId,
    } = this.props;
    return (
      <div className="UxEditProfile_UpdateProfilePhoto">
        {
          <CloseButton
            style={{ right: '35px', top: '43px' }}
            size={10}
            onClick={this.props.closeDialog}
          />
        }
        {
          photoDataToCrop && this.state.hideCrop === false &&
          <div className="UxEP_UpdateProfilePhoto_CropDialogBackDrop">
            <PhotoCropDialog
              onCancel={this.onCancel}
              athleteId={match.params.athleteId}
              coachId={coachId}
              className="UxEP_UpdateProfilePhoto_CropDialog"
            />
          </div>
        }
        <Container style={{ width: '653px' }}>
          <Dropzone
            disableClick
            accept="image/*"
            ref={this.saveDropZoneRef}
            onDrop={onDrop}
            multiple={false}
          >
            {
              dropError &&
              <div className="UxEP_UpdateProfilePhoto_DropError">
                Wrong file format. File must be an image.
              </div>
            }
            <Row>
              <Col className="UxEP_UpdateProfilePhoto_PhotoCol">
                <div className="UxEP_UpdateProfilePhoto_H1">
                  Your Current Profile Photo
                </div>
                <AthletePhoto
                  photoUrl={photoUrl}
                  loading={loadingPhoto}
                  loadingMsg={loadingPhotoMsg}
                  loadingProgress={loadingPhotoProgress}
                />
                <div style={{ marginBottom: '10px' }} />
                <div className="UxEP_UpdateProfilePhoto_P">
                  Drag an image here!
                </div>
                <div className="UxEP_UpdateProfilePhoto_H2">
                  Or
                </div>
                <div className="UxEP_UpdateProfilePhoto_P">
                  Choose an image from
                </div>
                <div style={{ marginBottom: '20px' }} />
                <Button onClick={this.onFromComputerClick}>Your Computer</Button>
              </Col>
            </Row>
          </Dropzone>
        </Container>
      </div>
    );
  }
}

export default UpdatePhoto;
