import React, { PureComponent } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';
import { Routes } from '../../../../store/actions/ui/routes';
import EditCreateCombineTemplate from './EditCreateCombineTemplate';
import ButtonRect from '../../../../components/UxCommon/ButtonRect/ButtonRect';
import CombineTemplateCard from './CombineTemplateCard';
import LoadingIndicator from '../../common/LoadingIndicator';
import SportsSelector from './EditCreateCombineTemplate/SportsSelector/SportsSelector.component';

class CombineTemplates extends PureComponent {
  static propTypes = {
    combineTemplates: PropTypes.array,
    getCombineTemplatesBySchool: PropTypes.func.isRequired,
    getCombineTemplateCombines: PropTypes.func.isRequired,
    currentSchoolId: PropTypes.string.isRequired,
    toggleDeleteModal: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string,
    match: PropTypes.object.isRequired,
  };

  state = {
    templateName: '',
    prevSchoolId: '',
    sportId: null,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onNameChange = (value) => {
    this.setState({ templateName: value });
  }
  schoolCombinesFetched = false;
  templateCombinesFetched = false;
  shouldUpdate = () => {
    const { getCombineTemplatesBySchool, currentSchoolId, match,schoolIds,getSchoolCombines, shouldUpdate } = this.props;
    const { prevSchoolId } = this.state;
    if (prevSchoolId !== currentSchoolId) {
      this.setState({
        prevSchoolId: currentSchoolId,
      });
      this.combineTemplatesFetched = false;
    }

    if (shouldUpdate && schoolIds && schoolIds.length && !this.schoolCombinesFetched) {
      this.schoolCombinesFetched = true;
      getSchoolCombines(schoolIds);
    }


    if (schoolIds && schoolIds.length && !this.combineTemplatesFetched) {
      this.combineTemplatesFetched = true;
      getCombineTemplatesBySchool(schoolIds);
    }
  };

  combineTemplatesFetched = false;

  redirectToAddNewTemplate = () => {
    const { history, match } = this.props;
    history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/new`);
  };

  handleTitleClick = (e) => {
    let onClick = this.redirectToAddNewTemplate;
    const re = pathToRegexp(`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combineTemplate}/:templateId`);
    const params = re.exec(window.location.pathname);
    const isNewTemplate = params && params[1] === 'new';
    if (params && !isNewTemplate) {
      onClick = this.props.toggleDeleteModal;
    }
    e.preventDefault();
    onClick();
  };

  getSportsIds = () => {
    const {teams} = this.props;
    return teams.map((team) => { return team.sportId});
  }

  coachSportsTemplates = () => {
    const { combineTemplates } = this.props;
    const coachSportsIds = this.getSportsIds();
    const coachSportsCombineTemplates = combineTemplates.map( (template) => ((coachSportsIds.includes(template.sportId))  ? template : {} ))
    let coachSportsCombineTemplatesArray = coachSportsCombineTemplates.filter(value => Object.keys(value).length !== 0);
    let sortedCoachSportsCombineTemplatesArray = coachSportsCombineTemplatesArray.sort( (a,b) => { return -(a.id - b.id) });
    return sortedCoachSportsCombineTemplatesArray
  }

  renderList = () => {
    const { profile,match } = this.props;
    let combineTemplates = [];
    combineTemplates = profile && this.coachSportsTemplates();
    this.templateCombinesFetched = false;
    return (
      <React.Fragment>
        <LoadingIndicator
          loading={this.props.loading}
          loadingMsg={this.props.loadingMsg}
          positionStyle={{ margin: 'auto' }}
        />
        <div className="CombineTemplate_Title_Row">
          <div className="CombineTemplate_Title_Text">
            <Link
              to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combines}`}
              className="CombineTemplate_Title_TextLink"
            >
              <Icon iconName="chevron-left" style={{ marginRight: '15px' }} />
              Edit Combine Templates
            </Link>
          </div>
          <ButtonRect
            className="CombineTemplate_Title_Button"
            onClick={this.handleTitleClick}
          >
            New Combine Template
          </ButtonRect>
        </div>
        <div className="CombineCard_Card_Container">
          {
            combineTemplates && !!combineTemplates.length > 0 && (
              combineTemplates.map(template => (
                <CombineTemplateCard
                  key={template.id}
                  template={template}
                  match={match}
                />
              ))
            )
          }
        </div>
      </React.Fragment>
    );
  };

  redirectToCombine = () => {
    const { history, match } = this.props;
    history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combines}`);
  }

  renderForm = (props) => {
    const {
      match,
      history,
      getCombineTemplateCombines,
      teams,
      sports,
      schoolStyle,
      orgAffSports,
    } = this.props;
    const { sportId } = this.state;
    const re = pathToRegexp(`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combineTemplate}/:templateId`);
    const params = re.exec(window.location.pathname);
    const isNewTemplate = params && params[2] === 'new';
    const isSportSelected = params && params[2] === Routes.selectSports;
    if (!isSportSelected && !isNewTemplate && !this.templateCombinesFetched) {
      getCombineTemplateCombines(params[2]);
      this.templateCombinesFetched = true;
    }
    const onValueChange = (value) => {
      const { combineTemplates, history, match } = this.props;
      const sportsExistTemplate = combineTemplates.reverse().find((template) => Number(template.sportId) === Number(value));
      this.setState({ sportId: value })
      if(!sportsExistTemplate){
        history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/new?sportId=${value}`);
      }else{
        history.push(`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/${sportsExistTemplate.id}`)
      }

    }

    // let titleText;
    // if (isNewTemplate) {
    //   titleText = 'Create Combine Template';
    // } else {
    //   titleText = `Edit Combine Template${`${` - ${templateName}` || ''}`}`;
    // }
    return (
      isSportSelected ?
        <SportsSelector
          onClickClose={this.redirectToCombine}
          orgAffSports={orgAffSports}
          onValueChange={onValueChange}
        />
        :
        <React.Fragment>
          <div className="CombineTemplateForm_TitleRow">
            <div className="CombineTemplate_Title_Text">
              <Link
                to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combines}`}
                className="CombineTemplate_Title_TextLink"
              >
                <Icon iconName="chevron-left" style={{ marginRight: '15px' }} />
                {'Select Combine Events'}
              </Link>
            </div>
            {/* {
            !isNewTemplate && (
              <ButtonRect
                className={`CombineTemplate_Title_Button ${!isNewTemplate ? 'delete' : ''}`}
                onClick={this.handleTitleClick}
              >
                Delete
              </ButtonRect>
            )
          } */}
          </div>
          <div className="CombineCard_Card_Container">
            <EditCreateCombineTemplate
              {...props}
              sportId={sportId}
              onNameChange={this.onNameChange}
              history={history}
            />
          </div>
        </React.Fragment>
    );
  }

  render() {
    const re = pathToRegexp(`/${Routes.coach}/${Routes.dashboard}/:school/${Routes.combineTemplate}/:templateId`);
    const params = re.exec(window.location.pathname);
    return (
      <div className="CombineTemplateWrapper" style={{ background: params ? 'white' : '' }}>
        <Switch>
          <Route path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combineTemplate}/:templateId`} render={this.renderForm} />
          <Route path={`/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.combineTemplate}`} render={this.renderList} />
        </Switch>
      </div>
    );
  }
}

export default CombineTemplates;
