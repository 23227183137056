import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditButton from '../../../common/EditButton';
import { Routes } from '../../../../../store/actions/ui/routes';

class CombineTemplateCard extends PureComponent {
  static propTypes = {
    template: PropTypes.object,
    match: PropTypes.object,
  };

  render() {
    const { template, match } = this.props;
    const countObject = template.stdTestCategories && template.stdTestCategories.length;
    const createdAt = template.createdAt ? template.createdAt.format('MM.DD.YY') : '';

    return (
      <div className="CombineCard_Container">
        <div className="CombineCard_Header">
          <div className="CombineCard_Season">
            { createdAt }
          </div>
          <Link
            to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/${template.id}`}
          >
            <div className="CombineCard_Edit">
              <EditButton iconName="edit" />
            </div>
          </Link>
        </div>
        <Link
          className="CombineCard_Body_Footer"
          to={`/${Routes.coach}/${Routes.dashboard}/${match.params.schoolId}/${Routes.combineTemplate}/${template.id}`}
        >
          <div className="CombineCard_Body">
            {template.name}
          </div>
          <div className="CombineCard_Footer">
            <div className="CombineCard_AthletesCount">
              <div className="CombineCard_AthletesCount_Text"> {`${countObject} Categories`} </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default CombineTemplateCard;
