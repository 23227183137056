import { combineReducers } from 'redux';
import createUpdateDialog from './createUpdateDialog';
import reducers from './externalProfile';

const reducer = combineReducers(
  {
    ...reducers,
    createUpdateDialog,
  }
)

export default reducer;


