import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonRect, ButtonLinkRect, CloseLink } from '../../../../components/UxCommon';
import { Routes } from '../../../../store/actions/ui/routes';
import CombineContainer from './CombineContainer';
import LoadingIndicator from '../../common/LoadingIndicator';
import { sortByOpenDate } from '../../../../containers/UxCommon/Utils';

class GenerateReportModal extends PureComponent {
  static propTypes = {
    schoolId: PropTypes.string,
    getSchoolCombines: PropTypes.func,
    match: PropTypes.object,
    combine: PropTypes.object,
    athleteCountsList: PropTypes.array,
    allCombines: PropTypes.array,
    location: PropTypes.object.isRequired,
    athletes: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    getReports: PropTypes.func.isRequired,
    reports: PropTypes.array.isRequired,
    generateReport: PropTypes.func.isRequired,
    reportsError: PropTypes.string,
  }

  state = {
    selectedCombine: null,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onSelectCombine = (combine) => {
    this.setState({ selectedCombine: combine });
  }

  onClickGenerateReport = () => {
    const { combine, generateReport, schoolId } = this.props;
    const { selectedCombine } = this.state;
    if (selectedCombine) {
      generateReport(schoolId, combine.id, selectedCombine.id);
    }
  }

  combinesFetched = false;

  shouldUpdate = () => {
    const {
      schoolId,
      getSchoolCombines,
      getReports,
    } = this.props;
    if (!this.combinesFetched && schoolId) {
      getSchoolCombines(schoolId);
      getReports(schoolId);
      this.combinesFetched = true;
    }
  }

  canGenerateReport = (currentCombine, previousCombine) => (moment(currentCombine.openDate)
    .isSame(moment(previousCombine.openDate))
    ? moment(currentCombine.closeDate).isAfter(moment(previousCombine.closeDate))
    : moment(currentCombine.openDate).isAfter(moment(previousCombine.openDate)));

  dummy = () => {
  };

  render() {
    const {
      match,
      athleteCountsList,
      allCombines,
      location,
      combine,
      athletes,
      loading,
      loadingMsg,
      reports,
      reportsError,
    } = this.props;
    const { selectedCombine } = this.state;

    let combineList = [];
    if (combine && allCombines && allCombines.length) {
      combineList = allCombines.filter((c) => {
        if (c.id === combine.id) {
          return false;
        }
        if (reports.find(r => (r.combineId === combine.id && r.secondCombineId === c.id))) {
          return false;
        }
        if (c.sportId !== combine.sportId) {
          return false;
        }
        if (c.schoolId !== combine.schoolId) {
          return false;
        }
        return this.canGenerateReport(combine, c);
      });
      combineList.sort(sortByOpenDate);
    }
    return (
      <div className="Combine_GenerateReport_Backdrop">
        <div className="Combine_GenerateReport_Modal">
          <CloseLink
            to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${match.params.combineId}/${match.params.bank}`}
            iconClassName="Combine_GenerateReport_CloseLink_I"
            className="Combine_GenerateReport_CloseLink"
          />
          <LoadingIndicator
            loading={loading}
            loadingMsg={loadingMsg}
            wrapperStyle={{
              position: 'relative',
            }}
            positionStyle={{
              marginTop: 40,
              marginBottom: 40,
            }}
          />
          <div
            className={`Combine_GenerateReport_Container ${selectedCombine ? '' : 'noneSelected'}`}
            style={{ display: loading ? 'none' : '' }}
          >
            <div className="Combine_GenerateReport_Header">Generate Report</div>
            <div className="Combine_GenerateReport_Description">Compare data between 2 combines to generate a report.
            </div>
            {
              reportsError !== '' &&
              <div className="Combine_GenerateReport_Description error">
                {reportsError}
              </div>
            }
            <div className="Combine_GenerateReport_Line"/>
            <div className="Combine_GenerateReport_CurrentCombine">
              <CombineContainer
                combine={combine}
                athleteCountsList={athleteCountsList}
                athletes={athletes}
                location={location}
                onSelectCombine={this.dummy}
                match={match}
              />
            </div>
            <div className="Combine_GenerateReport_Separator">
              <div className="Combine_GenerateReport_Line"/>
              <div className="Combine_GenerateReport_Text">Select a combine below to generate a report</div>
              <div className="Combine_GenerateReport_Line"/>
            </div>
            <div className="Combine_GenerateReport_CombineList">
              {
                combine
                && combineList
                && combineList.map(combineObj =>
                  (
                    <CombineContainer
                      key={combineObj.id}
                      combine={combineObj}
                      athleteCountsList={athleteCountsList}
                      selectedCombine={selectedCombine}
                      onSelectCombine={this.onSelectCombine}
                      getSchoolCombines={this.getSchoolCombines}
                      location={location}
                      match={match}
                    />
                  ))
              }
            </div>
            <div className="Combine_GenerateReport_Buttons">
              <ButtonLinkRect
                className="Combine_GenerateReport_CancelButton"
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.combine}/${match.params.combineId}/${match.params.bank}`}
              >
                Cancel
              </ButtonLinkRect>
              <ButtonRect
                className="Combine_GenerateReport_SaveButton"
                type="button"
                onClick={this.onClickGenerateReport}
              >
                Generate Report
              </ButtonRect>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GenerateReportModal;
