import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import logo from '../../../assets/images/icon-time.png';
import reload from '../../../assets/images/icon-reload.png';

class ErrorPage extends PureComponent {
  static propTypes = {
    error: PropTypes.any,
    info: PropTypes.any,
  };

  render() {
    const {
      error,
      info,
    } = this.props;
    console.log('ErrorPage', error, info);
    return (
      <div className="UxCommon_ErrorPage">
        <div className="UxCommon_ErrorPage_Fg">
          <div className="UxCommon_ErrorPage_TxtWrapper">
            <a onClick={() => window.location.reload()}><img className="UxCommon_ErrorPage_Logo" src={logo} alt="logo" /></a>
            <div className="UxCommon_ErrorPage_Msg">
              {'Our site requires a fast internet connection for a quality experience'}
            </div>
            <div className="UxCommon_ErrorPage_Reload">
              <a onClick={() => window.location.reload()}><img className="UxCommon_ErrorPage_ReloadIcon" src={reload} alt="Reload" /> Please try
                reloading this page
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
