import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChechboxControl from '../FormControls/CheckboxControl';
import Collapsable from '../../../../components/UxCommon/Collapsable/Collapsable';
import { getFilters, setFilter } from '../../+store/coachWorld';

const SidebarMultiSelectCallbackFilter = ({
  filterName,
  label,
  filters,
  location,
  history,
  className,
  defaultValues,
  match,
  isReconfigureEnabled,
}) => {
  const [defaultSelected] = useState(defaultValues);

  useEffect(() => {
    if (defaultSelected && defaultSelected.length) {
      setFilter(location, history, filterName, defaultSelected, true, null);
    }
  }, []);

  const onChange = (name, currentValues) => (value) => {
    const currentId = (currentValues && [...currentValues]) || [];
    const page = isReconfigureEnabled ? 'reconfigureTeams' : '';
    if (value) {
      currentId.push(name);
    } else {
      const indexOfId = currentId.indexOf(name);
      if (indexOfId > -1) {
        currentId.splice(indexOfId, 1);
      }
    }
    setFilter(location, history, filterName, name, value, currentId, page);

    return value;
  };

  const allFilters = getFilters(null, { location });
  let selectedValues = [],
    allValue = [],
    filter,
    currentValues;
  filters &&
    filters.map((filter) => {
      const { value } = filter;
      allValue.push(value);
    });

  if (allFilters) {
    filter = allFilters.find((f) => f.name === filterName);
    if (filter) {
      selectedValues = filter.selected;
    }
  }

  if (allValue && filter)
    currentValues = allValue.filter((id) => selectedValues.includes(id));

  const currentLocation = location.pathname;
  const endPartOfUrl = currentLocation.slice(
    currentLocation.lastIndexOf('/'),
    currentLocation.length,
  ).length
    ? currentLocation.slice(
        currentLocation.lastIndexOf('/'),
        currentLocation.length,
      )
    : '';

  let tab = match && match.params.tab ? match.params.tab : '';

  return (
    <React.Fragment>
      {endPartOfUrl != '/leaderboard' &&
      endPartOfUrl != '/reports' &&
      endPartOfUrl != '/awards' ? (
        <div
          className={`CoachSidebarMultiSelectCallbackFilter_Divider ${
            className || ''
          }`}>
          <Collapsable
            keepInside
            refId={filterName}
            tab={tab}
            useFluidContainer
            title={label}
            topRowStyle={{ minHeight: '59px' }}
            className="CoachSidebarMultiSelectCallbackFilter">
            <div />
            <div
              className={`CoachSidebarMultiSelectCallbackFilter_CheckboxContainer ${
                className || ''
              }`}>
              {filters.map(({ name, value }) => (
                <ChechboxControl
                  key={value}
                  label={name}
                  checked={selectedValues.includes(value)}
                  myOnChange={onChange(value, currentValues)}
                  className="filters"
                />
              ))}
            </div>
          </Collapsable>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

SidebarMultiSelectCallbackFilter.propTypes = {
  filterName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  defaultValues: PropTypes.array,
};

export default SidebarMultiSelectCallbackFilter;
