export const GradeConversion = {
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  13: '13th',
  12: '12th',
  11: '11th',
  10: '10th',
  9: '9th',
  8: '8th',
  7: '7th',
  6: '6th',
  5: '5th',
  4: '4th',
  3: '3rd',
  2: '2nd',
  1: '1st',
  'K': 'K',
};

export const def = {};
