export const WelcomeSteps = {
  zero: 'zero',
  first: 'first',
  last: 'last',
  email: 'email',
  password: 'password',
  phone: 'phone',
  birthdate: 'birthdate',
  graduate: 'graduate',
  confirmAccount: 'confirmAccount',
  login: 'login',
  forgot: 'forgot',
  resetPassword: 'resetPassword',
  toProfile: 'toProfile',
  toConfirmAccount: 'toConfirmAccount',
  toResetPassword: 'toResetPassword',
  toLogin: 'toLogin',
  toSecondSignIn: 'toSecondSignIn',
};
export const Redirects = {
  autoLogin: 'autoLogin',
};

export const SET_WELCOME_STEP = 'SET_WELCOME_STEP';
export const setWelcomeStep = step => ({
  type: SET_WELCOME_STEP,
  step,
});

export const WELCOME_CLEAR_ERRORS = 'WELCOME_CLEAR_ERRORS';
export const clearGeneralFormError = () => ({
  type: WELCOME_CLEAR_ERRORS,
});

