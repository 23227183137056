import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, CustomLinkButton } from '../../../../../../UxCommon';
import {Routes} from "../../../../../../../store/actions/ui/routes";

class TestLabel extends PureComponent {
  static propTypes = {
    testResult: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    displayValueWithLabel: PropTypes.string.isRequired,
    isMetricUnits: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    elem: null,
  }

  componentDidMount() {
    this.onMount();
  }

  onMount() {
    this.setState({ elem: this.myRef.current });
  }

  onClick = () => {
    const { onClick, testResult, index } = this.props;
    onClick(testResult, index);
  }

  onVideoClickHandler = () => {
    const { onVideoClick, videoClick, testResult, index } = this.props;
    onVideoClick(testResult, index);
    videoClick();
  }

  onDeleteClickHandler = () => {
    const { onDeleteClick, onVideoClick, testResult, index } = this.props;
    onVideoClick(testResult, index);
    onDeleteClick();
  }

  formatHeight = (heightInInches) => {
    if (!heightInInches && !Routes.playerDataGraph) return 'N/A';
    const ft = Math.floor(heightInInches / 12);
    const inch = parseFloat(heightInInches % 12).toFixed(2);
    return `${(ft !== 0) ? `${ft}'`  : ''}${(inch > 0.00) ? `${inch}"` : ''}`;
  }

  formatMetricHeight = (test) => {
    if (!test.height && !Routes.playerDataGraph) return 'N/A';
    return `${(test.heightMt !== 0) ? `${test.heightMt}'`  : ''}${(test.heightCm > 0.00) ? `${test.heightCm}m"` : ''}`;
  }

  formatWeight = weightInLbs => (weightInLbs ? `${weightInLbs} lbs` : Routes.playerDataGraph ? '' : 'N/A');

  formatMetricWeight = weightInKg => (weightInKg ? `${weightInKg} kg` : Routes.playerDataGraph ? '' : 'N/A');

  sendRef = () => this.myRef;

  isValidCoach = () => {
    const { testResult, commonSchool } = this.props;
    if (testResult && !testResult.schoolId) {
      return true;
    } else {
      const verifiedCoach = (testResult && testResult.schoolId) && commonSchool.find((element) => element && element.id === testResult.schoolId);
      const isValidCoach = !!(verifiedCoach && verifiedCoach.id);
      return isValidCoach;
    }
  }

  render() {
    const {
      testResult,
      editing,
      displayValueWithLabel,
      isMetricUnits,
      currentTabId,
      videoId,
      onDeleteCancel,
      isMyProfile,
      privacyWeight,
      privacyHeight,
      commonSchool,
    } = this.props;
    isValidCoach = this.isValidCoach();
    let resultDate = '';
    if (testResult.resultDate) {
      resultDate = testResult.resultDate.format('M/D/YY');
    }
    const { testResultResult, displayLabel, testResultReps, stdUnitOfMeasureCode } = testResult;
    const formattedHeight = isMetricUnits ?
      this.formatMetricHeight(testResult.test) :
      this.formatHeight(testResult.test.height);
    const formattedWeight = isMetricUnits ?
      this.formatMetricWeight(testResult.test.weight) :
      this.formatWeight(testResult.test.weight);

    let label2 = '';
    if (isMyProfile) {
      label2 = `${formattedHeight} ${(formattedHeight && formattedWeight) ? '/' : ''} ${formattedWeight}`
    } else if (!isMyProfile && !privacyHeight && !privacyWeight) {
      label2 = `${formattedHeight} ${(formattedHeight && formattedWeight) ? '/' : ''} ${formattedWeight}`
    } else {
      label2 = (!isMyProfile && !privacyHeight) ? `${formattedHeight}` : (!isMyProfile && !privacyWeight) && `${formattedWeight}`
    }

    return (
      <React.Fragment>
        <div ref={this.myRef} className="UxPlayerData_TestLabel">
          <div className="UxPlayerData_TestLabel_Label">{label2}</div>
          {
            (editing && isValidCoach) ?
              <React.Fragment>
                <Tooltip elem={this.state.elem} top="50px">
                  <div style={{ width: '120px' }}>Click to Edit</div>
                </Tooltip>
                {
                  ((stdUnitOfMeasureCode === "POUNDS" || stdUnitOfMeasureCode === "KG")) && (!!testResultResult) &&
                  <div className="UxPlayerData_TestLabel_Label">{`${testResultResult} ${displayLabel} X ${testResultReps}`}</div>}
                {(displayValueWithLabel !== "N/A" && currentTabId === "relative") ?
                  <button className="UxPlayerData_TestLabel_ValueBtn"
                    onClick={this.onClick}>{`${displayValueWithLabel} %`}</button>
                  :
                  <button className="UxPlayerData_TestLabel_ValueBtn"
                    onClick={this.onClick}>{displayValueWithLabel}</button>}
              </React.Fragment>
              :
              <React.Fragment>
                {((stdUnitOfMeasureCode === "POUNDS" || stdUnitOfMeasureCode === "KG")) && (testResultResult) &&
                  <div className="UxPlayerData_TestLabel_Label">{`${testResultResult} ${displayLabel} X ${testResultReps}`}</div>}
                {(displayValueWithLabel !== "N/A" && currentTabId === "relative") ?
                  <div className="UxPlayerData_TestLabel_Value">{`${displayValueWithLabel} %`}</div>
                  :
                  <div className="UxPlayerData_TestLabel_Value">{displayValueWithLabel}</div>}
              </React.Fragment>
          }
        </div>
        {
          <div className={"Combine_Record_Stamp"} style={{ fontSize: 11 }}>
            {
              (testResult.schoolName ? testResult.schoolName 
              : testResult.location ? testResult.location
              : 'UNVERIFIED')
            }
          </div>

        }
        <div className="UxPlayerData_TestLabel_Label PlayerData_Date">{resultDate}</div>
        {isMyProfile &&
          <div className={"UxPlayerData_VideoIcon"}>
            {
              !videoId ?
                <CustomLinkButton
                  iconName="videocam"
                  onClick={this.onVideoClickHandler}
                  type="button"
                  toolTip={"Add Video"}
                  style={{ display: "flex" }}
                  className={"UxPlayerData_VideoButton customVideoButton"}
                  leftButtonClassName={"leftButtonIcon"}
                />
                :
                <CustomLinkButton
                  iconName="delete-video"
                  onClick={this.onDeleteClickHandler}
                  type="button"
                  toolTip={"Delete Video"}
                  style={{ display: "flex" }}
                  className={"UxPlayerData_VideoButton"}
                  leftButtonClassName={"leftButtonIcon"}
                  style={{ fontSize: 30, color: "black" }}
                />
            }
          </div>
        }
      </React.Fragment>
    );
  }
}

export default TestLabel;

