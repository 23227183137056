import {useEffect, useState} from "react";

export const getCellId = (params) => `${params.colDef.colId}${params.data.userId}`;


export const getCellNewValue = (params) => {
  const cellId = getCellId(params);
  return params.colDef[cellId].newValue;
}

export const getCellOldValue = (params) => {
  const cellId = getCellId(params);
  return params.colDef[cellId].oldValue;
}

export const isErrorOnCell = (params) => {
  const cellId = getCellId(params);
  return params.colDef[cellId] && params.colDef[cellId].error;
}

export const shouldHighlight = (params, pasteIndex) => {
  const cellId = getCellId(params);
  console.log(params.colDef[cellId]);
  if (params.colDef[cellId]) {
    console.log('currentPasteIndex', pasteIndex);
    console.log('cells', params.colDef[cellId].pasteIndex);
  }
  return params.colDef[cellId] && params.colDef[cellId].highlight && (params.colDef[cellId].pasteIndex === pasteIndex || params.colDef[cellId].pasteIndex === pasteIndex - 1);
}

export const setCellValue = (params) => (newValue, error, oldValue) => {
  const cellId = getCellId(params);
  params.colDef[cellId] = {
    newValue,
    error,
    oldValue,
  }
}

export const markCellHighlighted = (params, pasteIndex) => {
  const cellId = getCellId(params);
  params.colDef[cellId] = {
    ...params.colDef[cellId],
    highlight: true,
    pasteIndex
  }
}

export const getCellErrorMessage = (params) => {
  const cellId = getCellId(params);
  return params.colDef[cellId].error;
}

export const isPasteOperation = (params) => (params.newValue && params.newValue.result === undefined);

export const getResult = (params, isReps, isFeetInches) => {
  if (isPasteOperation(params)) {
    if (isReps) {
      let splitChar = params.newValue.indexOf('x') > -1 ? 'x' : 'X';
      if (isFeetInches) {
        splitChar = '.'
      }
      const valueWithReps = params.newValue.split(splitChar);

      if (isFeetInches) {
        return {
          result: valueWithReps[0],
          reps: valueWithReps[2] ? parseFloat(`${valueWithReps[1]}.${valueWithReps[2]}`) : valueWithReps[1] ? valueWithReps[1] : '0',
        };
      } else {
        return {
          result: valueWithReps[0],
          reps: valueWithReps[1] ? valueWithReps[1] : 1,
        };
      }

    }

    let isLaser = false;

    if (params.newValue) {
      isLaser = params.colDef.isAllLaserChecked;
    }

    if (params.oldValue) {
      isLaser = params.oldValue.isLaser
    }

    return {
      result: params.newValue,
      isLaser,
    }
  }

  return params.newValue;

}


export const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

export const useCurrentDims = () => {
  const screenWidth = getWidth();

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWidth(getWidth());
        setHeight(getHeight());
      }, 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const [width, setWidth] = useState(screenWidth)
  const [height, setHeight] = useState(getHeight());

  return [width, height];
}

export const customComparator = (value1, value2) => {

  if (!value1 && !value2) {
    return true
  }

  if (!value1 && value2) {
    return false
  }

  if (value1 && value2 && value1.result !== value2.result) {
    return false;
  }

  if (value1 && value2 && (value1.isLaser !== undefined || value2.isLaser !== undefined) && Boolean(value1.isLaser) !== Boolean(value2.isLaser)) {
    return false;
  }

  if (value1 && value2 && (value1.reps !== undefined || value2.reps !== undefined) && value1.reps !== value2.reps) {
    return false;
  }

  if (value1 && value2 && ((!value1.videoId && value2.videoId) || (value1.videoId && !value2.videoId))) {
    return false;
  }

  if (value1 && !value2) {
    return false;
  }

  return true;

}

export const waitTill = (ms = 50) => {
  return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    }
  )
}

