import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicatorCard from '../../Combines/CombineControl/LoadingIndicatorCard/LoadingIndicatorCard.component';

class AwardCard extends PureComponent {
  static propTypes = {
    combine: PropTypes.object.isRequired,
    awards: PropTypes.any,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  shouldUpdate = () => {}

  render() {
    const { combine, awards } = this.props;
    let toData ='';
    let awardCount = awards && Object.values(awards).length !== 0 ? `${Object.values(awards).length} Awards` : `Active Combine` ;
    const openDate = combine.openDate ? combine.openDate.format('MM.DD.YY') : '';
    if (combine.isOpen) {
      toDate = combine.endDate ? combine.endDate.format('MM.DD.YY') : '';
    } else {
      toDate = combine.closeDate ? combine.closeDate.format('MM.DD.YY') : '';
    }
    return (
      <div className="CoachDashboardLeaderboards_Card">
        <div className="CoachDashboardLeaderboards_CardDates">
          {`${openDate} - ${toDate}`}
        </div>
        <div className="CoachDashboardLeaderboards_CardName">
          {`${combine.name}`}
        </div>
        <div className="CoachDashboardLeaderboards_Athlete_Link">
          {
            typeof awards !== 'undefined'
              ? <div className="CoachDashboardLeaderboards_CardLink"> {awardCount} </div>
              : <LoadingIndicatorCard loading loadingMsg="" />
          }
        </div>
      </div>
    );
  }
}

export default AwardCard;
