import React, {PureComponent} from 'react';
import linkSvg from "../../assets/images/link.svg";
import {getSportIconName} from '../../utils';
import cirSvg from "../../assets/images/cir.svg"
import { LoadingSpinner, SecondaryIconLink } from '../../components/UxCommon';
import { Routes, SportIds, expandedProspectDialogPath, expandedProspectDialogPathTo } from '../../store/actions/ui/routes';
import {Link, Route, Switch} from 'react-router-dom';
import Icon from '../../components/UxCommon/Icon/Icon';
import cssVar from '../../components/App/App.utils';
import HelpPopper from '../../components/UxCommon/Form/HelpPopper/HelpPopper';
import Urls from '../../config/urls';
import {renderLeaderboardColoumLogo} from '../CoachWorld/Leaderboard/CellContent/CellLogo';
import { batsThrows, softballBaseballIdsArray } from '../../components/UxCommon/Form/utils';
import ReactTooltip from 'react-tooltip';
import dashrLogoUrl from '../../assets/images/Dashr.png';
import ExternalProfileCard from "../../components/UxProfile/PlayerCard/CardContent/ExternalProfileCard/ExternalProfileCard";
import TwoToneButton from "../CoachWorld/common/TwoToneButton";
import ExpandedPhotoDialog from '../../containers/UxProfile/Photos/ExpandedPhotoDialog';
import Video from '../../store/actions/data/models/Video';
import LoadingIndicator from '../CoachWorld/common/LoadingIndicator';
import { isMobile } from '../CoachWorld/Combine/Combine.component';
import { checkComment } from '../CodeSignUp/AccountPage/AccountPage.validation';
import ConfirmDialog from '../CoachWorld/common/ConfirmDialog';
import { calculateElementHeight } from "./+store/prospectSheet/prospectSheet.constants";
import {SubbankCodes} from "../CoachWorld/+store";


export const bank = {
  PERF: "PERF",
  HITTING: "HITTING",
  PITCHING: "PITCHING",
  STRENGTH: "STRENGTH",
};

const OFFER = 'offer';
const COMMENT = 'comment';

export const copyStringToClipboard =(text) => {
  let el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const personalInfoKeys = {
  "classRank": "Class Rank",
  "committed": "Committed",
  "gpa": "GPA",
  "headCoachEmail": "Head Coach Email",
  "headCoachName": "Head Coach Name",
  "headCoachPhone": "Head Coach Phone",
  "instagram": "Instagram",
  "ncaaId": "Ncaa Id#",
  "parentEmail": "Parent Email",
  "parentName": "Parent Name",
  "parentPhone": "Parent Phone",
  "playerEmail": "Player Email",
  "playerPhone": "Player Phone",
  "rcEmail": "RC Email",
  "rcName": "RC Name",
  "rcPhone": "RC Phone",
  "sat/act": "SAT/ACT",
  "sportsId": "Other Sports",
  "teamTwitterLink": "Team Twitter",
  "twitter": "Twitter",
  "offers": "Offers",
}

const NO_COMMENT = 'No Comments...';

class ProspectSheetComponent extends PureComponent {
  static propTypes = {};

  state = {
    filterShowRecord: [],
    personalRecords: [],
    showPrintDialog: false,
    shareCopied: false,
    selectedFile: null,
    errorMsg: '',
    visibleOfferButton: false,
    visibleCommentButton: false,
    sports: [],
    currentAthlete: [],
    downloadLoading: '',
    errorCoachComment: '',
    closeDialogToggle: false,
    offers: '',
  }

  componentDidMount() {
    const { getAthleteProfile, match, playerData,getSchoolStyles,getExternalProfile, getRoster, getTranscript } = this.props;
    const { teamId, athleteId, sport, schoolId } = match.params;
    this.disableAutoZoom();
    getAthleteProfile(teamId, athleteId);
    getTranscript(SportIds[sport], athleteId);
    playerData(athleteId, sport);
    getExternalProfile(athleteId);
    getRoster(schoolId, SportIds[sport]);
    getSchoolStyles([schoolId]);
  }

  disableAutoZoom = () =>{
    const meta = document.createElement('meta');
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  componentDidUpdate(prevProps) {
    const { personalRecords, schoolStyle, profile, currentSportId , personalInfo} = this.props;
    const { shareCopied , offers} = this.state;
    if(shareCopied){
      setTimeout(() => this.setState({shareCopied:false}), 1000);
    }
    if( profile && Object.keys(profile).length !== 0 && prevProps.profile !== profile ){
      const { userSport } = profile;
      const sports = userSport.filter(userSports => userSports.sportId === currentSportId);
      this.setState({ sports })
    }
    if (personalRecords.length !== 0 && personalRecords.length !== this.state.personalRecords.length) {
      const showRecord = [...new Set(personalRecords.map(record => record.stdTestBanksCode))];
      const filteredRecord = showRecord && showRecord.filter(s => s === bank.PERF || s === bank.STRENGTH || s === bank.PITCHING || s === bank.HITTING);
      if (filteredRecord && filteredRecord.length !== 0) {
        filteredRecord.sort((record1, record2) => (record1 > record2) - (record1 < record2))
        if (filteredRecord.length > 1 && filteredRecord[0] === bank.HITTING && filteredRecord[1] === bank.PERF) {
          let tempRecord = filteredRecord[0];
          filteredRecord[0] = filteredRecord[1];
          filteredRecord[1] = tempRecord;
        }
      }
      filteredRecord && this.setState({filterShowRecord: filteredRecord, personalRecords: personalRecords})
    }
    if (schoolStyle ) {
      const primary = schoolStyle.color1;
      const secondary = schoolStyle.color2;
      cssVar('primary', primary);
      cssVar('secondary', secondary);
    }

    const sortOffers = personalInfo["offers"] && personalInfo["offers"].split(",");
    const sortedOffers = sortOffers && sortOffers.map( offer => offer.trim().replace(/\s+/g, " ").toUpperCase()).sort().join(',');
    if(personalInfo['offers'] !== prevProps.personalInfo["offers"]){
      this.setState({offers: sortedOffers})
    }
  }

  renderBankData = (banks, i) => {
    const value = bank[banks];
    if (value === bank.PERF) {
      return 'PERFORMANCE'
    }
    return value;
  }

  onShareClose = () =>{
    this.setState({shareCopied:false})
  }

  onShareHandler = () =>{
    const { url } = this.props.match;
    const shareableLink = `${Urls.app()}${url}`;
    copyStringToClipboard(shareableLink);
    this.setState({shareCopied:true})
  }

  renderPlayerData = (record, i) => {

    const date = new Date(record.resultDate);
    const { match } = this.props;
    const { athleteId, schoolId, teamId, sport } = match.params;

    return (<td key={`${record.stdTestCategoryName} ${i}`}>
      <div className="details-card">
        <h4 style={{display:"flex"}}>
        {renderLeaderboardColoumLogo(record.stdTestSubbanksCode, !!record, record.stdTestCategoryCode,Routes.prospectSheet,"black","white","top")}
        <ReactTooltip/>
        {!!(record.isDashr) &&
          <div className="prospect_record_dashr_label">
            <img className='prospect_record_dashr_logo' src={dashrLogoUrl}
              height={20} width={20} alt="img"
              data-tip="Dashr Laser Verified" data-place={"top"}
              data-background-color="white" data-text-color="black"
            />
            <ReactTooltip />
          </div>
        }
        <div className="dash text-uppercase">
          {`${record.stdTestCategoryName.trim()}`}
        </div>
        </h4>
        <h4 className="add">
          {
            record.schoolName ? record.schoolName + ' '  + `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
            : record.location ? record.location + ' ' + `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
            :'UNVERIFIED'+ ' ' + `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
          }
        </h4>
        <h4 className="gpa">
          {`${record.displayValueWithLabel}`}
          { record.schoolName && 
            <Icon
              iconName= {"ok-circled"}
              tooltip={"Verified Data"}
              place={"right"}
              className={"Bank-Container ml-1"}
              style={{color:"#007bff", fontSize:22}}
              tooltiptextColor={"black"}
              tooltipbackgroundColor={"white"}
            />
          }
        </h4>
          {
            record.videoId && (
            (record.processed) ?
             <div className='videoIconProspect'>
             <Link
              to={expandedProspectDialogPathTo(schoolId, teamId, athleteId, sport, record.videoId)}
              className="PlayerData_StatsTable_CellWithComment PlayerData_VideoIcon">
                <Icon
                iconName="play-circle2"
                style={{color:"red", fontSize:30}}
                className='play-video-icon-prospect'
                />
                <span
                className="PlayerData_StatsTable_CellComment right coach-verified"
                >
                {"Play video"}
              </span>
              </Link>
              </div>
              :
              <div className='videoIconProspect'>
                  <Icon
                  iconName="play-circle2"
                  style={{color:"red", fontSize:30}}
                  className='play-video-icon-prospect'
                  />
                  <span
                  className="PlayerData_StatsTable_CellComment right coach-verified"
                  >
                  { "Processing... Please Wait."}
                  </span>
              </div>
              )
          }
      </div>
    </td>)
  }

  renderData = (banks, personalRecords) => {
    let count=0;
    switch (banks) {
      case bank.PERF:
        return personalRecords && personalRecords.map((record, index) => {
          if((record.stdTestBanksCode === bank.PERF) && count < 10) {
            count++;
            return this.renderPlayerData(record, index)
          }
        });
      case bank.STRENGTH:
        return personalRecords && personalRecords.map((record, index) => {
          if((record.stdTestBanksCode === bank.STRENGTH) && count<10){
            count++;
            return this.renderPlayerData(record, index)
          }
          }
        );
      case bank.PITCHING:
        return personalRecords && personalRecords.sort((res1, res2) => res1.stdTestCategoryOrderNum - res2.stdTestCategoryOrderNum).map((record, index) =>{
          if(record.stdTestBanksCode === bank.PITCHING && count<10){
            count++;
            return this.renderPlayerData(record, index)
          }
        });
      case bank.HITTING:
        return personalRecords && personalRecords.sort((res1, res2) => res1.stdTestCategoryOrderNum - res2.stdTestCategoryOrderNum).map((record, index) =>{
          if(record.stdTestBanksCode === bank.HITTING && count< 10) {
            count++;
            return this.renderPlayerData(record, index)
          }
        });
      default:
        return <div className="PlayerData_PersonalRecords_Header">Unknown Data</div>
    }
  }

  batsThrows = (throws, bats) =>{
    const {sport} =this.props.match.params;
    if((sport === 'softball' || sport === 'baseball') && ((throws && !!throws.trim()) || (bats && !!bats.trim()))){
      return `${bats.trim() || '-'}/${throws.trim() || '-'}`
    }
    return ''
  }

  getTwitterLink = handle => encodeURI(`https://twitter.com/${handle.replace('@', '')}`);
  getInstagramLink = handle => encodeURI(`https://www.instagram.com/${handle.replace('@', '')}`)

  getGpa = (val) => {
    const num = (!val ? val : `${val}`.replace(/[^\d.]/g, ''));
    if (num) {
      const twoDecimalNum = parseFloat(num).toFixed(2);
      return twoDecimalNum.replace(/0$/, '');
    }
    return num;
  }

  setRefs = (ref) => {
    this.fileInputEl = ref;
  };

  setOfferRef = (ref) => {
    this.offerInputEl = ref;
  }

  setCommentRef = (ref) => {
    this.commentInputEl = ref;
  }

  editHandler = ( field ) => {
    switch(field){
      case OFFER:
        this.offerInputEl.removeAttribute('readOnly');
        this.offerInputEl.selectionStart = this.offerInputEl.value.length;
        this.offerInputEl.focus();
        this.offerInputEl.classList.add("editableOffer_On");
        this.setState({ visibleOfferButton: true});
        break;
      case COMMENT:
        this.commentInputEl.removeAttribute('readOnly');
        this.commentInputEl.selectionStart = this.commentInputEl.value.length;
        this.commentInputEl.focus();
        this.commentInputEl.classList.add("editableComment_On");
        this.setState({ visibleCommentButton: true});
        break;
    }
  }

  openFile = () => {
    this.fileInputEl.click();
  }

  downloadFile = async() => {
    const { transcript } = this.props;
    this.setState({ downloadLoading: "Downloading... Please wait..." });
    const res = await fetch(transcript.url);

    if( res.status === 200 ){
      const fileBlob = await res.blob();
      const url =  URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Transcript.${transcript.ext || 'txt'}`);
      document.body.appendChild(link);
      link.click();
      this.setState({ downloadLoading: '' });
      link.parentNode.removeChild(link);
    }else{
      this.setState({ downloadLoading: '' });
      console.log("response error ", res);
    }

  }

  onCloseHandler = () => {
    const { deleteTranscript, match, uuid, transcript } = this.props;
    const { athleteId, sport } = match.params;
    this.fileInputEl.value = null;
    deleteTranscript( athleteId, SportIds[sport], transcript.id );
    this.setState({ closeDialogToggle: false });
  }

  onCloseDiloagHandler = () => {
     this.setState({ closeDialogToggle: true });
  }

  onCancelHandler = () => {
    this.setState({ closeDialogToggle: false });
  }

  onActionHandler = (field, action) => {
    const { schoolTeam, personalInfo, saveUserTeam, match, profile, currentAthlete, saveUserSport } = this.props;
    const { userTeam, sportId } = schoolTeam;
    const { athleteId, teamId } = match.params;
    const [{ jerseyNumbers, position }] = this.state.sports;
    switch(field){
      case OFFER:
        if(action === 'save'){
          if( this.offerInputEl.value !== personalInfo.offers){
            const userSport = {
              offers: this.offerInputEl.value,
              sportId,
              commits: personalInfo.committed,
              jerseyNumbers,
              position,
            }
            saveUserSport(userSport, profile);
          }
        }
        if(action === 'cancel'){
          this.offerInputEl.value = personalInfo.offers;
        }
        this.offerInputEl.setAttribute('readOnly', 'true');
        this.offerInputEl.classList.remove("editableOffer_On");
        this.setState({ visibleOfferButton: false });
        break;

      case COMMENT:
        const error = checkComment(this.commentInputEl.value);
        if(action === 'save'){
          if( this.commentInputEl.value !== ( currentAthlete.coachComment || NO_COMMENT )){
            if(error){
              this.setState({ errorCoachComment: error[0] })
            }
            else{
              this.setState({ errorCoachComment: '' });
              const value = {
                user_team_id: userTeam.userTeamId,
                coach_comment: this.commentInputEl.value,
              }
              saveUserTeam( value, userTeam.userTeamId, athleteId, teamId)
            }
            }
        }
        if(!error){
          this.commentInputEl.setAttribute('readOnly', 'true');
          this.commentInputEl.classList.remove("editableComment_On");
          this.setState({ visibleCommentButton: false});
        }
        if(action === 'cancel'){
          this.setState({ errorCoachComment: '' });
          this.commentInputEl.value = currentAthlete.coachComment || NO_COMMENT;
          this.commentInputEl.setAttribute('readOnly', 'true');
          this.commentInputEl.classList.remove("editableComment_On");
          this.setState({ visibleCommentButton: false});
        }
        break;
    }
  }

  fileHandler = (event) => {
    const { saveTranscript, match } = this.props;
    const { athleteId, sport } = match.params;
    const fileData = event.target.files[0];
    const document = ['pdf' , 'doc' , 'txt', 'docx'] ;
    const uploadableExt = document.find( doc => fileData.name.split('.')[1] === doc );

    if( fileData ){
      if(!uploadableExt){
        this.setState({ errorMsg: 'Please select PDF, Word Doc and Text Files' });
      }
      else if( fileData.size > (50 * 1000000) ){
        this.setState({ errorMsg: 'Please select the file less than 50 MB' });
      }
      else{
        this.setState({ errorMsg:''});
        saveTranscript( SportIds[sport], athleteId, fileData);
      }
    }

  }

  render() {
    const {
      profile,
      personalInfo,
      schoolTeam,
      personalRecords,
      currentSportId,
      match,
      externalProfiles,
      videos,
      location,
      loadingProspect,
      currentAthlete,
      transcript,
      uuid,
    } = this.props;

    const {
      filterShowRecord,
      shareCopied,
      errorMsg,
      visibleOfferButton,
      visibleCommentButton,
      sports,
      downloadLoading,
      errorCoachComment,
      closeDialogToggle,
      offers,
     } = this.state;
    const {schoolId, sport} = match.params;
    const profileLength = Object.keys(profile).length;
    const dashboardUrl =  (uuid && uuid.length) && (currentAthlete && currentAthlete.is_permission_for_edit ? `/${Routes.coach}/dashboard/${schoolId}/rosters` : '');

    if (profileLength === 0) {
      return <div className='prospectSheet'>
        <LoadingSpinner loadingMsg={"Please Wait"} loading={(profileLength === 0)}/>
      </div>
    }
    const {athleteId} = match.params;
    const {first, last, gradYear, heightFt, heightInch, weight, city, state, userSport} = profile;
    const {schoolName, schoolLogo, userTeam} = schoolTeam;
    const height = `${heightFt ? heightFt + "’" : ""}${heightInch ? heightInch + "”" : ""}`;
    let jerseyNumbers, position, photoUrl, throws, bats ;
    if(sports.length !== 0){
      [{ jerseyNumbers, position, photoUrl, throws, bats }] = sports;
    }


    const photoVideos = videos.map((tutorial) => {
      const video = new Video();
      Object.keys(tutorial).forEach((key) => {
        video[key] = tutorial[key];
      });
      return video;
    });

    return (
      <div className='prospectSheet'>
          <Switch>
          <Route
            path={expandedProspectDialogPath}
            render={(props) => (
              <ExpandedPhotoDialog photoList={photoVideos} location={location} {...props} isSingleVideo={true} page={`${Routes.prospectSheet}`} />
            )}
          />
        </Switch>
        <header>
          <nav className="navbar navbar-light">
            <div className="prospect-header">
              <img src={schoolLogo} width={100} height={100} alt="Org Logo"/>
              <div className="left-navbar">
              {dashboardUrl && <SecondaryIconLink iconName="back-arrow" to={dashboardUrl} className={"back-button-prospect-sheet"}>Dashboard</SecondaryIconLink>}
              <div className= "shareLinkContainer" onClick={this.onShareHandler}>
              <img className="left-navbar-img" src={linkSvg} alt="link"/>
              {shareCopied && <HelpPopper focus={shareCopied} helpClass="bottom"
              classNameHelpPop={"shareLinkPop"} classNameHelpPopInner={"shareLinkInnerPop"}>
                {"Link Copied"}
              </HelpPopper>}
              </div>
              <button onClick={() => print()} className="prospect-print">
              {/* <button onClick={() => {
                const elem = document.getElementById('vcOffer');
                const textAreaLines = calculateElementHeight(elem);
                this.offerInputEl.rows =  textAreaLines< 4 ? 4 : textAreaLines ;
                print()
              }} className="prospect-print"> */}
                <div style={{marginRight: 3}}>
                  <Icon iconName="print"/>
                </div>
              </button>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section className="profie">
            <div className="container-fluid">
                <div className='loading_popup_prospect'>
                  <LoadingIndicator
                  className='prospect_loader'
                  loading={loadingProspect || !!downloadLoading}
                  loadingMsg={downloadLoading || "Processing"}
                  />
                </div>
                {
                  closeDialogToggle &&
                  <ConfirmDialog
                    title= "Delete Transcript?"
                    msg= { "Are you sure you want to delete transcript?"}
                    onArchiveClick= { this.onCloseHandler}
                    onCancelClick = {this.onCancelHandler}
                  />
                }
              <div className="row profile-media-row">
                <div className="col-lg-4 col-md-4 col-sm-12 bordered">
                  <div className="row">
                    {photoUrl && <div className="col-6 profie-picture">
                      <img src={photoUrl} alt="profie picture" className="img-responsive img-fluid"/>
                    </div>}
                    <div className={`col-6 profile-name ${photoUrl ? '' : 'profile-no-image'}`}>
                      <h1 className="text-white first-name">{`${first}`}</h1>
                      <h1 className="text-white last-name">{`${last}`}</h1>
                      {gradYear && <div className="year-button">
                        <a href="#" className="text-uppercase text-white btn-dark">{`Class of ${gradYear}`}</a>
                      </div>}
                    </div>
                  </div>
                  <div className="row profile-address">
                    <div className="col profile-address-col">
                      <h3
                        style={{textTransform: "capitalize",lineHeight:1.35, fontWeight: 700}}>{`${schoolName.toLowerCase()} ${(schoolName && (city || state)) ? "| " : ""} ${city ? city : ""} ${(city && state) ? ", " : ""} ${state ? state : ""}`}</h3>
                      <h3>{`${height ? height : ""} ${height && weight ? "| " : ""} ${weight ? weight + ' lbs' : ""}` + this.batsThrows(throws, bats) + `${jerseyNumbers ? " | #" + jerseyNumbers : ""}`}</h3>
                      <h3>{` ${position ? position.replace(/^,/, '').split(",").join(" | ") : ""}`}</h3>
                    </div>
                  </div>
                  <div>
                    {
                    transcript.url
                      ?
                      <div className='d-flex align-items-center'>
                          <TwoToneButton
                            iconName={"download"}
                            text={"Transcript"}
                            onClick={this.downloadFile}
                            className={"print transcript_prospect"}
                          />
                       {
                        currentAthlete.is_permission_for_edit &&
                        <Icon
                          iconName='delete'
                          className='trash_icon_prospect transcript_prospect'
                          onClick={this.onCloseDiloagHandler}
                          />
                        }
                      </div>
                      :
                      currentAthlete.is_permission_for_edit && <TwoToneButton
                        iconName={"upload"}
                        text={"Transcript"}
                        onClick={this.openFile}
                        className={"print transcript_prospect"}
                        data-tip={isMobile.any() ? '' : "Upload PDF, Word Doc and Text Files"}
                        data-place={"bottom"}
                        data-background-color="white"
                        data-text-color="black"
                      />
                    }
                     <div className='errorFileUpload_prospect'>{errorMsg}</div>
                  </div>
                  <div className='row-bottom-profile'>

                    <input
                        name='pdf_uploader'
                        style={{display: 'none'}}
                        className='pdfInputUploader'
                        type='file'
                        ref={this.setRefs}
                        onChange={this.fileHandler}
                        accept=".pdf, .doc, .txt, .docx"
                      />
                    {/* <div className={"Video_Button_Row"}>
                      <Link to={`/${Routes.profile}/${athleteId}/${Routes.photos}`}>
                        <button className={"Prospect_Video_Button"}> Videos </button>
                      </Link>
                      <Link to={`/${Routes.profile}/${athleteId}/${Routes.player}/${sport}/${schoolId}`}>
                        <button className={"Prospect_Video_Button left"}> Profile </button>
                      </Link>
                    </div> */}
                      <div className='externalProfile_propsect'>
                          {
                          externalProfiles.length !== 0 &&
                             <ExternalProfileCard
                              externalProfile ={externalProfiles}
                              match ={match}
                              currentSportId ={currentSportId}
                          />
                          }
                      </div>
                  </div>
              <div className="d-flex">
                <div className="mb-3">
                    <Link to={`/${Routes.profile}/${athleteId}/${Routes.photos}`}>
                      <button className={"Prospect_Video_Button"}> Videos </button>
                    </Link>
                </div>
                <div className="col-md-4 mb-3">
                    <Link to={`/${Routes.profile}/${athleteId}/${Routes.player}/${sport}/${schoolId}`}>
                      <button className={"Prospect_Video_Button left"}> Profile </button>
                    </Link>
                </div>
             </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 personal-info-wrapper">
                  <div className="row">
                    <div className="col-12 personal-info">
                      <h3 className="text-uppercase">
                        <span>Personal Info</span>
                      </h3>
                    </div>
                  </div>
                  <div className="row information-details">
                    <div className="col-6">
                      <table className="table table-borderless custom-table-one">
                        <tbody>
                        {
                          Object.keys(personalInfo).map((info, i) => {
                            if (i < 11) {
                              if(info === 'twitter' || info === 'teamTwitterLink'){
                                return <tr key={`${info}${i}`}>
                                <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                <th scope="row">
                                  <a href={this.getTwitterLink(personalInfo[info])} target="_blank">{personalInfo[info]}</a>
                                </th>
                              </tr>
                              }
                              if(info === 'instagram'){
                                return <tr key={`${info}${i}`}>
                                <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                <th scope="row">
                                  <a href={this.getInstagramLink(personalInfo[info])} target="_blank">{personalInfo[info]}</a>
                                </th>
                              </tr>
                              }
                              if(info === 'gpa'){
                                return <tr key={`${info}${i}`}>
                                  <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                  <th scope="row">
                                    {this.getGpa(personalInfo[info])}
                                  </th>
                                </tr>
                              }
                              if(info === 'playerEmail' || info === 'parentEmail'){
                                return <tr key={`${info}${i}`}>
                                  <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                  <th scope="row">
                                    <a href={"mailto:"+ personalInfo[info]}>
                                      {personalInfo[info]}
                                    </a>
                                  </th>
                                </tr>
                              }
                              if(info === 'playerPhone' || info === 'parentPhone'){
                                return <tr key={`${info}${i}`}>
                                  <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                  <th scope="row">
                                    <a href={"tel:"+ personalInfo[info]}>
                                      {personalInfo[info]}
                                    </a>
                                  </th>
                                </tr>
                              }
                              return <tr key={`${info}${i}`}>
                                <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                <th scope="row">{personalInfo[info]}</th>
                              </tr>
                            }

                          })
                        }
                        </tbody>
                      </table>
                    </div>

                    <div className="col-6">
                      <table className="table table-borderless custom-table-one">
                        <tbody>
                        {
                          Object.keys(personalInfo).map((info, i) => {
                            if (info === 'sportsId') {
                              return <tr key={`${info}${i}`}>
                                <th scope="row"> {'OTHER SPORTS'}</th>
                                <th scope="row">
                                  <div className="sports-accessories">
                                    {personalInfo[info].length !== 0 &&
                                    personalInfo[info].map(sportId => {
                                      return (sportId !== currentSportId &&
                                        <i key={`${sportId}`}
                                           className={`Prospect_Sports_Icon icon-${getSportIconName(sportId)}`}/>
                                      )
                                    })
                                    }
                                  </div>
                                </th>
                              </tr>
                            }
                            if (info === 'offers') {
                              if(!(softballBaseballIdsArray.includes(currentSportId)))
                              {
                                return (
                                  <tr class="custom-inline-block" key={`${info}${i}`}>
                                    <th className="text-uppercase">{personalInfoKeys[info]}
                                    {
                                    currentAthlete.is_permission_for_edit &&
                                    <div class="tooltip-offer">
                                     <div class="UxCommon_Form_HelpPopper show right "><div class="UxCommon_Form_HelpPopper_Inner">Offers should be separated by a comma</div></div>
                                       <Icon
                                      onClick={this.editHandler.bind(this, OFFER)}
                                      iconName='lead-pencil'
                                      className='offer-edit-pencil editIcon'
                                    />
                                    <th class="mobile-offer" scope="row">Offers should be separated by a comma</th>
                                    </div>
                                    }
                                    </th>
                                  </tr>
                                );
                              } else {
                                return false;
                              }
                            }
                            if (i >= 11) {
                              if(info === 'rcEmail' || info === 'headCoachEmail'){
                                return <tr key={`${info}${i}`}>
                                  <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                  <th scope="row">
                                    <a href={"mailto:"+ personalInfo[info]}>
                                      {personalInfo[info]}
                                    </a>
                                  </th>
                                </tr>
                              }

                              if(info === 'rcPhone' || info === 'headCoachPhone'){
                                return <tr key={`${info}${i}`}>
                                  <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                  <th scope="row">
                                    <a href={"tel:"+ personalInfo[info]}>
                                      {personalInfo[info]}
                                    </a>
                                  </th>
                                </tr>
                              }
                              return <tr key={`${info}${i}`}>
                                <th scope="row" className="text-uppercase">{personalInfoKeys[info]}</th>
                                <th className='monicamanisha' scope="row">{personalInfo[info]}</th>
                              </tr>
                            }

                          })
                        }
                        </tbody>
                      </table>
                      {/* <div className="Vc_Offer_para">{personalInfo["offers"]}</div> */}
                      <textarea
                        ref={this.setOfferRef}
                        id="vcOffer"
                        className='Vc_Offer_para'
                        rows="2"
                        cols="100"
                        value={offers}
                        onChange={(e)=>this.setState({offers: e.target.value})}
                        readOnly
                        maxLength={300}
                      />
                      {
                        visibleOfferButton && (
                          <div className='offer-button-container'>
                              <button className='Save_Offers' onClick={this.onActionHandler.bind(this,OFFER, 'save')}>Save</button>
                              <button className='Cancel_Offers' onClick={this.onActionHandler.bind(this, OFFER, 'cancel')}>Cancel</button>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {
                    offers &&
                    <div class="row vc-print-view">
                      <h3 class="text-uppercase"><span></span></h3>
                      <div class="custom-inline-block d-flex">Offers</div>
                      <div id="vcOffer" class="Vc_Offer_para editableOffer_On mt-2">
                      <p> {offers} </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container-fluid">
              {
                filterShowRecord && filterShowRecord.map((banks, i) => {
                  return <div key={`${banks}${i}`} className="row personalRecord">
                    <div className="col-md-12">
                      <table className="table custom-table-two">
                        <thead>
                        <tr style={{padding: "0.55rem"}}>
                          <th colSpan="5" style={{display:"flex"}}>
                            <h3 className="text-uppercase">
                              {this.renderBankData(banks, i)}
                            </h3>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          {
                            this.renderData(banks, personalRecords)
                          }
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                })
              }

              {<div className="row coach-comments">
                <div className="col-md-12">
                  <h4 className="text-uppercase">Coach Comments
                  {
                    currentAthlete.is_permission_for_edit &&
                    <Icon
                    onClick={this.editHandler.bind(this, COMMENT)}
                    iconName='lead-pencil'
                    className='comment-edit-pencil editIcon'
                    />
                  }
                  </h4>
                  <textarea
                    ref={this.setCommentRef}
                    id="vcComment"
                    className='vc_comment_para'
                    rows="2"
                    cols="33"
                    defaultValue={userTeam.coachComment || NO_COMMENT}
                    readOnly
                    maxLength="1001"
                  />
                  { errorCoachComment &&
                      <div style= {{fontSize: "1rem", color: "red", fontWeight: "400", marginBottom:8 }}>
                        {errorCoachComment}
                      </div>
                  }
                  {
                    visibleCommentButton && (
                      <div className='comment-button-container'>
                          <button className='Save_Offers' onClick={this.onActionHandler.bind(this, COMMENT, 'save')}>Save</button>
                          <button className='Cancel_Offers' onClick={this.onActionHandler.bind(this, COMMENT, 'cancel')}>Cancel</button>
                      </div>
                    )
                  }
                  {/* <p>
                    {coachComment ? coachComment : "No Comments..."}
                  </p> */}
                </div>
              </div>}
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default ProspectSheetComponent;

