import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/Photos/AlbumDialog/AlbumDialog';
import {
  onPhotoDrop,
  closeAlbumDialog,
  albumMediaFormModel,
  albumMediaFormObject,
  clearStagedMedia,
  stageAlbumMedia,
  unstageNewMedia,
  deletePhoto,
  stageNewTag,
  addTagToPhoto,
  initAlbumDialog,
  unstageNewTag,
  saveTags,
} from '../../../store/actions/ui/uxProfile/photosVideos';

import { makeGetAlbumsState, makeGetTags, makeGetMediaList, makeGetTagRelMedia } from '../../../selectors/Photos';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import { deleteVideo } from '../../../store/actions/data/photo';
import { deleteAlbumEverything, deleteAlbumOnly, closeExternalVideoDialog, openExternalVideoDialog, stageMediaProblem } from '../../../store/actions/ui/uxProfile/photosVideos/actions';

const getAlbumTags = (photoList, tagRel) => {
  const resultArr = [];
  const filteredTagRel = tagRel.filter(rel => photoList.some(p => rel.mediaId === p.id));

  const allMediaIds = filteredTagRel.map(item => item.mediaId);
  const allTagIds = filteredTagRel.map(item => item.tagId);
  const uniqueTagIds = [...new Set(allTagIds)];
  const uniqueMediaIds = [...new Set(allMediaIds)];


  uniqueTagIds.forEach((uTagId) => {
    const tempObj = filteredTagRel.filter(fTag => fTag.tagId === uTagId);
    if (tempObj && tempObj.length === uniqueMediaIds.length) {
      resultArr.push(tempObj[0].tagId);
    }
  });
  return resultArr;
};

const mapStateToProps = (state, ownProps) => {
  const photoList = makeGetMediaList(state);
  const getTagsState = makeGetTags(state);
  const getTagRelMedia = makeGetTagRelMedia(state);

  const albumTags = getAlbumTags(photoList(state, ownProps), getTagRelMedia(state, ownProps));
  const allTags = getTagsState(state, ownProps);
  let selectedTags = albumTags;
  selectedTags = allTags.filter(tag => selectedTags &&
    selectedTags.some(selectedTag => selectedTag === tag.id));
  const tags = state.ui.uxProfile.photosVideos.albumDialog.stagedTag;
  if (tags && tags.length > 0) {
    selectedTags = [...selectedTags, ...tags];
  }
  const getAlbumsState = makeGetAlbumsState();
  return {
    albums: getAlbumsState(state, ownProps),
    stagedMedia: state.ui.uxProfile.photosVideos.albumDialog.stagedMedia,
    createMode: !state.ui.uxProfile.photosVideos.albumDialog.albumToEdit,
    albumToEdit: state.ui.uxProfile.photosVideos.albumDialog.albumToEdit,
    deleteAlbumDialogIsShowing: false,
    photoList: photoList(state, ownProps),
    selectedCategory: state.ui.uxProfile.photosVideos.selectedCategory,
    loading: !!state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    loadingMsg: state.ui.uxProfile.photosVideos.albumDialog.loadingMsg,
    loadingProgress: state.ui.uxProfile.photosVideos.albumDialog.loadingProgress,
    rrfForm: state.forms.forms.albumDialog,
    isDeleteTagDialogShown: state.ui.uxProfile.photosVideos.expandedDialog.deleteTagsDialogIsShown,
    rrfTagForm: state.forms.forms.tagDialog,
    tags,
    selectedTags,
    state,
    allTags,
    tagRelMedia: getTagRelMedia(state, ownProps),
    isExternalVideoDialogOpen: state.ui.uxProfile.photosVideos.externalVideo.isDialogOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const photoList = makeGetMediaList(state);
  const getTagRelMedia = makeGetTagRelMedia(state);
  return {
    ...newStateProps,
    ...ownProps,
    onDeleteEverythingClick: (album) => {
      dispatch(deleteAlbumEverything(album));
    },
    onDeleteAlbumOnlyClick: (album) => {
      dispatch(deleteAlbumOnly(album));
    },
    onSaveClick: () => {
      const album = newStateProps.albums.find(a => a.id === state.forms.albumDialog.selectedAlbum);
      dispatch(formSubmit('forms.albumDialog', { album, dispatch }));
    },
    onDrop: onPhotoDrop(dispatch),
    onCloseClick: () => {
      dispatch(closeAlbumDialog());
    },
    getPhotoCardRrfForm: mediaId => albumMediaFormObject(state, mediaId),
    getPhotoCardRrfFormModel: albumMediaFormModel,
    formInit: (album) => {
      dispatch(actions.reset('forms.albumDialog'));
      dispatch(clearStagedMedia());
      if (album) {
        dispatch(actions.merge('forms.albumDialog', {
          newAlbumName: '',
          selectedAlbum: album.id,
          newTagName: '',
          albumName: album.title,
        }));
        dispatch(initAlbumDialog(getAlbumTags(
          photoList(state, ownProps),
          getTagRelMedia(state, ownProps),
        )));
      } else {
        dispatch(actions.setInitial('forms.albumDialog'));
      }
    },
    showMediaIsNotAccepted: (videoLimitMsg) => {
      dispatch(stageMediaProblem(videoLimitMsg));
    },
    clearNewAlbumName: () => {
      dispatch(actions.change('forms.albumDialog.newAlbumName', ''));
    },
    clearAlbumSelect: () => {
      dispatch(actions.change('forms.albumDialog.selectedAlbum', ''));
    },
    stageAlbumMedia: (media) => {
      dispatch(stageAlbumMedia(media, media.isAPhoto));
    },
    unstageNewMedia: (media) => {
      dispatch(unstageNewMedia(media));
    },
    deleteMedia: (media) => {
      if (media.isAPhoto) {
        dispatch(deletePhoto(media.id));
      } else {
        dispatch(deleteVideo(media.id));
      }
    },
    onAddTagClick: () => {
      // dispatch for staged media and photoList
      const newValue = state.forms.tagDialog.newTagName.new;
      if (newValue !== '') {
        const existingTag = newStateProps.tags.find(tag => tag.label === newValue);
        if (existingTag) {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList));
        } else {
          dispatch(stageNewTag(newStateProps.stagedMedia, newStateProps.photoList, newValue));
        }
        dispatch(actions.change('forms.tagDialog.newTagName.new', ''));
      }
    },
    onDeleteTagClick: (tagId) => {
      dispatch(unstageNewTag(newStateProps.tags.filter(a => a.id === tagId), newStateProps.media));
    },
    onAddTagToPhotoClick: () => {
      if (state.forms.tagDialog.selectedTag !== '') {
        dispatch(addTagToPhoto(newStateProps.media.id, newStateProps.tags));
        dispatch(actions.change('forms.tagDialog.selectedTag', ''));
      }
    },
    onSaveTagsClick: () => {
      dispatch(saveTags(newStateProps.tagsToBeUpdated));
    },
    onExternalVideoCancelClick: () => {
      dispatch(closeExternalVideoDialog());
    },
    onExternalVideoClick: () => {
      dispatch(openExternalVideoDialog());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
