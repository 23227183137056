import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { CloseButton } from '../../../../components/UxCommon';
import { Button } from '../../../../components/UxCommon/Form';
import Dropzone from '../../../../components/UxCommon/Dropzone/Dropzone';
import LoadingIndicator from '../LoadingIndicator';

class UpdateSchoolImagesDialog extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    image: PropTypes.any,
    closeDialog: PropTypes.func.isRequired,
    updateImage: PropTypes.func.isRequired,
    schoolStyle: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    isBackgroundImage: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onFromComputerClick = () => {
    this.dropZoneRef.open();
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  shouldUpdate = () => {
    const { isDialogOpen, closeDialog } = this.props;
    if (!isDialogOpen) {
      closeDialog();
    }
  }

  render() {
    const {
      description,
      closeDialog,
      image,
      updateImage,
      schoolStyle,
      loadingMsg,
      isBackgroundImage,
    } = this.props;

    return (
      <div className="UpdateSchoolImages">
        <CloseButton size={10} onClick={closeDialog} />
        <Dropzone
          disableClick
          accept="image/*"
          ref={this.saveDropZoneRef}
          onDrop={updateImage(schoolStyle, isBackgroundImage)}
          multiple={false}
        >
          <div className="UpdateSchoolImages_Container">
            <div className="UpdateSchoolImages_Header">{description}</div>
            {
              image ?
                <div className="UpdateSchoolImages_Image">
                  {
                    loadingMsg ?
                      <div className="LoadingIndicator">
                        <LoadingIndicator loading={!!loadingMsg} loadingMsg={loadingMsg} />
                      </div>
                    :
                      <img src={image} alt="" />
                  }
                </div>
              :
                <Icon iconName="photo2" className="UpdateSchoolImages_ImageIcon" />
            }
            <div className="UpdateSchoolImages_Text">Drag an image here!</div>
            <div className="UpdateSchoolImages_Text">Or</div>
            <div className="UpdateSchoolImages_Text">Choose an image from</div>
            <Button onClick={this.onFromComputerClick}>Your Computer</Button>
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default UpdateSchoolImagesDialog;
