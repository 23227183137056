import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/ProfileStats/GameStats';
import {
  getSelectedSport,
  getCurrentUuid,
  getSeasonStats, getCurrentSport
} from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const currUuid = getCurrentUuid(state, ownProps.match);
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  let stdTables = {};
  if (selectedUserSport
    && state.data.sport.statsStd
    && state.data.sport.statsStd[selectedUserSport.sportId]) {
    stdTables = state.data.sport.statsStd[selectedUserSport.sportId].tables;
  }
  return {
    isMyProfile: currUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    currUuid,
    userStats: getSeasonStats(state, ownProps.match),
    selectedUserSport,
    loading: !!state.ui.uxPlayerData.stats.statsLoadingMsg,
    loadingMsg: state.ui.uxPlayerData.stats.statsLoadingMsg,
    stdTables,
    state,
    currentSport: getCurrentSport(state),
  };
};

const Container = connect(
  mapStateToProps,
  null
)(Component);

export default Container;
