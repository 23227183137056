import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/Photos/TagDialog/TagDialog';
import {
  closeEditTagsDialog,
  addNewTag,
  saveTags,
  deleteAllTags,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { makeGetTags } from '../../../selectors/Photos';

const getTagsState = makeGetTags();
const mapStateToProps = (state, ownProps) => ({
  tags: getTagsState(state, ownProps),
  loading: !!state.ui.uxProfile.photosVideos.tagDialog.loadingMsg,
  loadingMsg: state.ui.uxProfile.photosVideos.tagDialog.loadingMsg,
  rrfForm: state.forms.forms.tagDialog,
  tagsToBeUpdated: state.ui.uxProfile.photosVideos.tagDialog.updatedTags,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onCloseClick: () => {
      dispatch(closeEditTagsDialog());
    },
    onAddTagClick: () => {
      if (state.forms.tagDialog.newTagName.new !== '') {
        dispatch(addNewTag());
      }
    },
    onSaveTagsClick: () => {
      if (newStateProps.tagsToBeUpdated && newStateProps.tagsToBeUpdated.length > 0) {
        dispatch(saveTags(newStateProps.tagsToBeUpdated));
      }
      if (state.forms.tagDialog.newTagName.new !== '') {
        dispatch(addNewTag());
      }
    },
    onDeleteTagClick: (tagId) => {
      dispatch(deleteAllTags(newStateProps.tags.find(a => a.id === tagId), newStateProps.media));
    },
    formInit: () => {
      dispatch(actions.setInitial('forms.tagDialog'));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
