import {
  convertInchesToCm,
  convertInchesToMetre,
  convertLbsToKg,
} from '../../../CodeSignUp/PhysiquePage/PhysiquePage.validation';
import { customTOFixed } from '../../../../store/actions/data/user/profile/models';
import moment from 'moment';

export const getUserProfile = (profiles, profile) => {
  if (profile.email) {
    return profiles.users.find((u) =>
      u.email && profile.email
        ? u.email.toLowerCase() === profile.email.toLowerCase()
        : false,
    );
  }

  return profiles.users.find((u) =>
    u.parent_email && profile.parent_email
      ? u.parent_email.toLowerCase() === profile.parent_email.toLowerCase()
      : false,
  );
};

export class School {
  static fromApi(response) {
    const school = new School();
    school.address = response.address;
    school.schoolLogo = response.school_logo;
    school.city = response.city;
    school.highestGrade = response.highest_grade;
    school.id = response.id;
    school.location = response.location;
    school.lowestGrade = response.lowest_grade;
    school.name = response.name;
    school.population = response.population;
    school.state = response.state;
    school.zip = response.zip;
    school.isMetricSchool = response.is_metric_school;
    school.isPaid = !!response.is_paid;
    school.isSMSEnabled = !!response.enable_sms;
    return school;
  }
}

export class SchoolTeamRef {
  static fromApi(response) {
    const team = new SchoolTeamRef();
    team.id = response.id;
    team.team = response.team;
    team.type = response.type;
    team.orderby = response.orderby;
    return team;
  }
}

export class HeadCoach {
  static fromApi(response) {
    const headCoach = new HeadCoach();
    headCoach.id = response.id;
    headCoach.name = response.name;
    headCoach.email = response.email;
    headCoach.phone = response.phone;
    headCoach.schoolTeamId = response.school_team_id;
    // headCoach.teamTwitterLink = response.team_twitter_link;
    headCoach.title = response.title;
    return headCoach;
  }
}

export class RecruitingCoordinator {
  static fromApi(response) {
    const recruitingCoordinator = new RecruitingCoordinator();
    recruitingCoordinator.id = response.id;
    recruitingCoordinator.name = response.name;
    recruitingCoordinator.email = response.email;
    recruitingCoordinator.phone = response.phone;
    recruitingCoordinator.schoolTeamId = response.school_team_id;
    recruitingCoordinator.title = response.title;
    return recruitingCoordinator;
  }
}

export class TwitterLink {
  static fromApi(response) {
    const twitterLink = new TwitterLink();
    twitterLink.twitter = response.twitter;
    twitterLink.schoolTeamId = response.id;
    return twitterLink;
  }
}

const MemberUserTypes = {
  coach: 'coach',
  admin: 'admin',
  athlete: 'athlete',
};

//  cutomTOFixedInRoster Lenght after decimal point in roster page
const cutomTOFixedInRoster = (num, fixed = 1) =>
  num == Math.floor(num) ? num : num.toFixed(fixed);

export class SchoolTeamMember {
  static fromApi(response) {
    const member = new SchoolTeamMember();
    const height = Number(response.height); // stored in inches
    member.headshotUrl = response.headshot_url;
    member.id = response.id;
    member.coachComment = response.coach_comment ? response.coach_comment : '';
    member.jerseyNumber = response.jersey_number;
    member.position = response.position;
    member.schoolTeamId = response.school_team_id;
    member.userId = response.user_id;
    member.userType = response.user_type;
    member.familyName = response.family_name;
    member.givenName = response.given_name;
    member.gender = response.gender;
    member.gradYear = response.grad_year;
    member.height = response.height;
    member.heightFt = Math.floor(response.height / 12);
    member.heightIn = cutomTOFixedInRoster(response.height % 12);
    member.heightMt = Number.isNaN(height)
      ? null
      : Math.floor(convertInchesToMetre(height));
    member.heightCm = Number.isNaN(height)
      ? null
      : Math.floor(
          customTOFixed(
            Math.abs(convertInchesToCm(height) - member.heightMt * 100),
          ),
        );
    if (member.heightCm >= 100) {
      member.heightMt += 1;
      member.heightCm -= 100;
    }
    member.weightKg = Number.isNaN(response.weight)
      ? null
      : customTOFixed(convertLbsToKg(response.weight));
    member.profileId = response.profileId;
    member.email = response.email;
    member.parentsPhone = response.parent_phone;
    member.parents2Phone = response.parent2_phone;
    member.parentsName = response.parent_name;
    member.parent2Name = response.parent2_name;
    member.parentsEmail = response.parent_email;
    member.parent2Email = response.parent2_email;
    member.phone = response.phone;
    member.zip = response.zip;
    member.school = response.school;
    member.coreGpa = response.core_gpa;
    member.act = response.act;
    member.sat = response.sat;
    member.classRank = response.class_rank_num;
    member.classSize = response.class_rank_of;
    member.schoolCredits = response.school_credits;
    member.weight = response.weight;
    member.name = response.name;
    member.grade = response.grade;
    member.coachCareerCoaching = response.coach_career_coaching;
    member.coachCareerPlaying = response.coach_career_playing;
    member.coachCollege = response.coach_college;
    member.coachHighSchool = response.coach_high_school;
    member.coachHobbies = response.coach_hobbies;
    member.coachKids = response.coach_kids;
    member.coachPosition = response.coach_position;
    member.coachSpouse = response.coach_spouse;
    member.is_permission_for_edit = response.is_permission_for_edit
      ? response.is_permission_for_edit
      : false;
    member.createdAt = moment(response.created_at);
    member.invited = response.invited;
    member.isSubscribed = response.is_subscribed;
    member.address = response.address;
    member.city = response.city;
    member.state = response.state;
    member.combineNumber = response.combine_no;
    member.jerseyNumber = response.jersey_number;
    member.ncaaIdNumber = response.ncaa_id_number;
    member.twitter = response.twitter;
    member.instagram = response.instagram;
    member.bats = response.bat;
    member.throws = response.throw;
    member.commits = response.commits || null;
    member.birthdate = response.birthday;
    if (response.birthday && response.birthday.date) {
      member.birthdate = new moment(
        response.birthday.date,
        'YYYY-MM-DD',
      ).format('MM/DD/YY');
    }
    member.profileDetails = response.profileDetails || {
      club_team: '',
      club_coach_name: '',
      club_coach_email: '',
      high_school_coach_name: '',
      high_school_coach_email: '',
      club_coach_mobile_phone_number: '',
      high_school_coach_phone_number: '',
    };
    // member.coachSecondaryEmail = response.coach_secondary_email;
    // member.coachSecondaryPhone = response.coach_secondary_phone;
    // member.coachTitle = response.coach_title;
    // member.coachOrganizationWebsite = response.organization_website;
    return member;
  }

  static fromSchoolTeamApi(userId, schoolId, response,newTeamName,currentTeam){
      const member = new SchoolTeamMember();
      member.id = response.id;
      member.userId = userId;
      member.schoolTeamId = response.school_team_id;
      member.userType = 'athlete';
      member.position = response.position;
      member.jerseyNumber = response.jersey_number;
      member.headshotUrl = response.headshot_url;
      member.invited = response.invited;
      member.combineNumber = response.combine_no;
      member.oldUserTeamId = response.old_user_team_id;
      member.isPrivate = response.is_private;
      member.newTeamName = newTeamName || ''
      member.teamOrderBy = currentTeam && currentTeam.teamRef.orderby;

      return member;
  }

  static fromCombineApi(userId, schoolId, response) {
    const member = new SchoolTeamMember();
    member.headshotUrl = response.headshot_url;
    member.id = response.id;
    member.schoolId = schoolId;
    member.jerseyNumber = response.jersey_number;
    member.position = response.position;
    member.schoolTeamId = response.school_team_id;
    member.userId = userId;
    member.userType = 'athlete';
    member.testId = response.test_id;
    member.familyName = response.family_name;
    member.givenName = response.given_name;
    member.grade = response.grade;
    member.gradYear = response.gradYear;
    member.height = response.height;
    member.heightFt = Math.floor(response.height / 12);
    member.heightIn = response.height % 12;
    member.weight = response.weight;
    member.gender = response.gender || '';
    member.isAthleteCoachedByUser = response.is_athlete_coached_by_user;
    member.combineNumber = response.combine_no;
    member.invited = response.invited;
    member.name = response.name || member.givenName + ' ' + member.familyName;
    member.isPrivate = response.is_private;
    member.oldUserTeamId = response.old_user_team_id;
    return member;
  }

  static fromRosterTeamApi(userId, schoolId, response) {
    const member = new SchoolTeamMember();
    member.jerseyNumber = response.jersey_number;
    member.position = response.position;
    member.schoolTeamId = response.school_team_id;
    member.schoolId = schoolId;
    member.id = response.id;
    member.userType = 'athlete';
    member.userId = userId;
    member.combineNumber = response.combine_no;
    member.invited = response.invited;
    member.isPrivate = response.is_private;
    member.oldUserTeamId = response.old_user_team_id;
    return member;
  }

  static fromRosterApi(schoolId, response) {
    const member = new SchoolTeamMember();
    member.headshotUrl = response.headshot_url;
    member.id = response.id;
    member.schoolId = schoolId;
    member.jerseyNumber = response.jersey_number;
    member.position = response.position;
    member.schoolTeamId = response.school_team_id;
    member.userId = response.user_id;
    member.userType = response.user_type;
    member.combineNumber = response.combine_no;
    member.invited = response.invited;
    member.coachComment = response.coach_comment;
    return member;
  }

  static fromUserSportApi(userId, sportId, response, userTeamId) {
    const member = new SchoolTeamMember();
    const currentSport = response._embedded.userSports.find(
      (us) => us.sport_id === sportId,
    );
    member.commits = currentSport.commits;
    member.bats = currentSport.bat;
    member.throws = currentSport.throw;
    member.userId = userId;
    member.userTeamId = userTeamId;
    return member;
  }

  static fromObject(response) {
    const member = new SchoolTeamMember();
    const height = Number(response.height);
    member.coachComment = response.coachComment;
    member.headshotUrl = response.headshotUrl;
    member.id = response.id;
    member.jerseyNumber = response.jerseyNumber;
    member.position = response.position;
    member.schoolTeamId = response.schoolTeamId;
    member.userId = response.userId;
    member.userType = response.userType;
    member.familyName = response.familyName;
    member.givenName = response.givenName;
    member.birthdate = response.birthdate;
    member.email = response.email;
    member.phone = response.phone;
    member.parentsPhone = response.parentsPhone;
    member.parents2Phone = response.parents2Phone;
    member.parentsName = response.parentsName;
    member.parent2Name = response.parent2Name;
    member.parentsEmail = response.parentsEmail;
    member.parent2Email = response.parent2Email;
    member.address = response.address;
    member.city = response.city;
    member.state = response.state;
    member.zip = response.zip;
    member.school = response.school;
    member.coreGpa = response.coreGpa;
    member.act = response.act;
    member.sat = response.sat;
    member.classRank = response.classRank;
    member.classSize = response.classSize;
    member.schoolCredits = response.schoolCredits;
    member.ncaaIdNumber = response.ncaaIdNumber;
    member.twitter = response.twitter;
    member.instagram = response.instagram;
    member.gender = response.gender;
    member.gradYear = response.gradYear;
    member.height = response.height;
    member.heightFt = Math.floor(response.height / 12);
    member.heightIn = response.height % 12;
    member.weight = response.weight;
    member.name = response.givenName + ' ' + response.familyName;
    member.grade = response.grade;
    member.combineNumber = response.combineNumber;
    member.coachCareerCoaching = response.coachCareerCoaching;
    member.coachCareerPlaying = response.coachCareerPlaying;
    member.coachCollege = response.coachCollege;
    member.coachHighSchool = response.coachHighSchool;
    member.coachHobbies = response.coachHobbies;
    member.coachKids = response.coachKids;
    member.coachPosition = response.coachPosition;
    member.coachSpouse = response.coachSpouse;
    member.invited = response.invited;
    member.commits = response.commits || null;
    member.createdAt = response.createdAt;
    member.is_permission_for_edit = response.is_permission_for_edit
      ? response.is_permission_for_edit
      : false;
    member.heightMt = Number.isNaN(height)
      ? null
      : Math.floor(convertInchesToMetre(height));
    member.heightCm = Number.isNaN(height)
      ? null
      : Math.floor(
          customTOFixed(
            Math.abs(convertInchesToCm(height) - member.heightMt * 100),
          ),
        );
    if (member.heightCm >= 100) {
      member.heightMt += 1;
      member.heightCm -= 100;
    }
    member.weightKg = Number.isNaN(response.weight)
      ? null
      : customTOFixed(convertLbsToKg(response.weight));
    member.bats = response.bats;
    member.throws = response.throws;
    member.isSubscribed = response.isSubscribed;
    member.teamOrderBy = response.teamOrderBy;
    member.teamName = response.teamName;
    return member;
  }

  clone() {
    const member = new SchoolTeamMember();
    member.headshotUrl = this.headshotUrl;
    member.id = this.id;
    member.isSubscribed = this.isSubscribed;
    member.isPaidProfile = this.isPaidProfile;
    member.jerseyNumber = this.jerseyNumber;
    member.schoolId = this.schoolId;
    member.position = this.position;
    member.schoolTeamId = this.schoolTeamId;
    member.userId = this.userId;
    member.userType = this.userType;
    member.familyName = this.familyName;
    member.givenName = this.givenName;
    member.gradYear = this.gradYear;
    member.height = this.height;
    member.heightFt = this.heightFt;
    member.heightIn = this.heightIn;
    member.weight = this.weight;
    member.name = this.name;
    member.grade = this.grade;
    member.gender = this.gender;
    member.coachCareerCoaching = this.coachCareerCoaching;
    member.coachCareerPlaying = this.coachCareerPlaying;
    member.coachCollege = this.coachCollege;
    member.coachHighSchool = this.coachHighSchool;
    member.coachHobbies = this.coachHobbies;
    member.coachKids = this.coachKids;
    member.coachPosition = this.coachPosition;
    member.coachSpouse = this.coachSpouse;
    member.is_permission_for_edit = this.is_permission_for_edit;
    member.isAthleteCoachedByUser = this.isAthleteCoachedByUser;
    member.combineNumber = this.combineNumber;
    member.commits = this.commits || null;
    member.bats = this.bats;
    member.throws = this.throws;
    member.address = this.address;
    member.city = this.city;
    member.coachComment = this.coachComment;
    member.createdAt = this.createdAt;
    member.email = this.email;
    member.heightCm = this.heightCm;
    member.heightMt = this.heightMt;
    member.instagram = this.instagram;
    member.invited = this.invited;
    member.ncaaIdNumber = this.ncaaIdNumber;
    member.state = this.state;
    member.twitter = this.twitter;
    member.weightKg = this.weightKg;

    member.coachComment = this.coach_comment || '';
    member.invited = this.invited;
    return member;
  }
}

export class SchoolTeam {
  static fromApi(response) {
    const team = new SchoolTeam();
    team.id = response.id;
    team.name = response.name || response.team_reference.team;
    team.schoolId = response.school_id;
    team.active = response.active || false;
    team.sportId = response.sport_id;
    team.school = School.fromApi(response.schools);
    team.teamRef = SchoolTeamRef.fromApi(response.team_reference);
    team.teamRefId = response.team_reference_id;
    team.headCoach = response.head_coach
      ? HeadCoach.fromApi(response.head_coach)
      : null;
    team.isPrivate = response.is_private;
    team.twitter = response.twitter ? response.twitter : null;
    team.recruitingCoordinator = response.recruiting_coordinator
      ? RecruitingCoordinator.fromApi(response.recruiting_coordinator)
      : null;
    const athletes = [];
    const coaches = [];
    const admins = [];
    if (response.members) {
      response.members.forEach((apiMember) => {
        const member = SchoolTeamMember.fromApi(apiMember);
        switch (member.userType) {
          case MemberUserTypes.coach:
            coaches.push(member);
            break;
          case MemberUserTypes.athlete:
            athletes.push(member);
            break;
          case MemberUserTypes.admin:
            admins.push(member);
            break;
          default:
        }
      });
    }
    return {
      team,
      athletes,
      coaches,
      admins,
    };
  }
}

export const mapUserUiToSportsApi = (user) => {
  const email = user.email || '';
  // convert boolean value to string 1 or 0
  return {
    email,
    given_name: user.first,
    family_name: user.last,
    phone: user.phone || null,
    gender: user.gender || null,
    role_id: user.roleId,
    school_team_id: user.schoolTeamId || null,
    grade: user.grade,
    position: user.position || '',
    grad_year: user.grad_year,
    parent_email: user.parent_email || null,
    parent_phone: user.parent_phone || null,
  };
};

export const mapUserUiToSportsInviteApi = (user, sportId) => {
  const email = user.email || '';
  // convert boolean value to string 1 or 0
  return {
    email,
    parent_email: user.parent_email || '',
    aws_uuid: user.uuid,
    given_name: user.first,
    family_name: user.familyName,
    coach_full_name: user.coachFullName,
    school_team_id: user.schoolTeamId,
    school_name: user.schoolName,
    team_name: user.teamName,
    invite_type: user.inviteType,
    sport_id: sportId,
  };
};
