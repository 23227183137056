export default class Tag {
  constructor() {
    this.id = '';
    this.ownerId = '';
    this.createdAt = '';
    this.updatedAt = '';
    this.label = '';
    this.media = [];
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.ownerId = response.owner_id;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
    this.label = response.label;
  }

  addMedia(media) {
    this.media.push(media);
  }
}
