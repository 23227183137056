import { connect, useSelector } from 'react-redux';
import Component from './CoachWorld.component';
import { playerProfileGet } from '../../store/actions/ui/uxProfile';
import { getUserSchoolTeams, getSchoolStyles, selectMySchoolIds, getSchoolTeamsList, selectSchoolStyle } from './+store';

const mapStateToProps = (state, ownProps) => {
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
 
  return {
    authenticating: state.data.cognito.authenticating,
    uuid,
    profile,
    schoolIds: selectMySchoolIds(state, ownProps),
    shouldUpdateTeamsList: state.modules.coachWorld.coachWorld.shouldUpdateSchoolTeamsList,
    schoolStyle: selectSchoolStyle(state, ownProps),
    hideFilters: state.ui.app.context.hideFilters,
  };
};

const mapDispatchToProps = dispatch => ({
  getUserProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  getUserSchoolTeams: (uuid) => {
    dispatch(getUserSchoolTeams(uuid));
  },
  getSchoolStyles: (schoolIds) => {
    dispatch(getSchoolStyles(schoolIds));
  },
  getSchoolTeamsList: (schoolId) => {
    dispatch(getSchoolTeamsList(schoolId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
