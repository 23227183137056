import { combineEpics } from "redux-observable";
import createEpics from "./create";
import readEpics from "./read";
import deleteEpics from "./delete";


const epics = combineEpics(
  createEpics,
  readEpics,
  deleteEpics
)

export default epics;
