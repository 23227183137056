import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import PhotosCircleButton from '../../../UxCommon/PhotosCircleButton/PhotosCircleButton';
import { awardsDialogTo } from '../../../../store/actions/ui/routes';

import personalAwardHidden from '../../../../assets/images/PersonalAwardHidden.png';
import Icon from '../../../UxCommon/Icon/Icon';
import EndorseIcon from '../EndorseIcon/EndorseIcon';
import AwardLogo from '../AwardLogo/AwardLogo';

class AwardCard extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    award: PropTypes.object.isRequired,
    onFavoriteClick: PropTypes.func.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    onEndorseClick: PropTypes.func.isRequired,
    endorsementCount: PropTypes.number.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    authorizedUuid: PropTypes.string.isRequired,
    endorsementDisabled: PropTypes.bool.isRequired,
    disableFavorite: PropTypes.bool.isRequired,
    schoolAwardLogo:PropTypes.object,
    awardType:PropTypes.string
  }


  onFavoriteClick = () => {
    const { award, onFavoriteClick } = this.props;
    onFavoriteClick(award);
  }

  onEndorseClick = () => {
    const {
      award,
      onEndorseClick,
      isAuthorized,
      authorizedUuid,
      endorsementDisabled,
    } = this.props;
    if (isAuthorized && !endorsementDisabled) {
      onEndorseClick(award, !award.endorsedBy.includes(authorizedUuid));
    }
  }



  render() {
    const {
      match,
      isMyProfile,
      award,
      isFavorite,
      endorsementCount,
      isAuthorized,
      disableFavorite,
      schoolAwardLogo,
      awardType,
      userSchool
    } = this.props;
    const { sport, schoolId } = match.params;
    return (
      <div className={['AwardCard', isAuthorized ? 'auth' : ''].join(' ')}>
        <Link
          className="AwardCard_OpenLink"
          to={awardsDialogTo(match.params.uuid, award.id, sport, schoolId)}
        />
        {
          isMyProfile && !disableFavorite && (
            <PhotosCircleButton
              className={[
                'AwardCard_FavoritBtn',
                isFavorite ? 'favorite' : '',
              ].join(' ')}
              iconName="star-outline"
              onClick={this.onFavoriteClick}
            />
          )
        }
        <div className="AwardCard_AwardImgWrapper">
          {
            award.display ? (
              <AwardLogo
                award = {award}
                schoolAwardLogo={schoolAwardLogo}
                classes  = "AwardCard_AwardImg"
                uuid ={match.params.uuid}
                awardType={awardType}
                userSchool={userSchool}
                />
            ) : (
              <React.Fragment>
                <img src={personalAwardHidden} className="AwardCard_AwardImg" alt="award-img" />
                <div className="AwardCard_AwardHiddenBadgeWrapper">
                  <div className="AwardCard_AwardHiddenBadge">
                    <Icon iconName="hide" className="AwardCard_AwardHiddenBadgeIcon" />
                    <div className="AwardCard_AwardHiddenBadgeText">Hidden</div>
                  </div>
                </div>
              </React.Fragment>
            )
          }
        </div>
        <div className="AwardCard_Txt">
          {award.name}<br />{(award.date.isValid() ? award.date.format('MMMM Do YYYY') : moment(award.createdAt).format('MMMM Do YYYY'))}
        </div>
      </div>
    );
  }
}

export default AwardCard;
