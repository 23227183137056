import loadImage from 'blueimp-load-image';

const getBlobWeb = (img, cb) => {
  fetch(img.src)
    .then(res => res.blob())
    .then(cb);
};

const getBlobMobile = fileType => (img, cb) => {
  img.toBlob(cb, fileType);
};

const fixRotationOfFile = file => new Promise((resolve) => {
  loadImage(file.preview, (img) => {
    const getBlob = img.src ? getBlobWeb : getBlobMobile(file.type);
    getBlob(img, (blob) => {
      const tempFile = new File([blob], file.name, { type: file.type });
      tempFile.preview = window.URL.createObjectURL(blob);
      resolve(tempFile);
    });
  }, {
    orientation: true,
    canvas: false,
  });
});

export const fixRotation = file => new Promise(resolve =>
  fixRotationOfFile(file)
    .then((resultFile) => {
      resolve(resultFile);
    }));

export default { fixRotation };
