import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { combineForms } from 'react-redux-form';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { Amplify } from 'aws-amplify';
import ui from './reducers/ui';
import stripe from './reducers/stripe/stripe';
import data from './reducers/data';
import welcomeForms from './reducers/ui/welcome/forms';
import uxProfileForms from './reducers/ui/uxProfile/forms';
import uxEditProfileForms from './reducers/ui/uxEditProfile/forms';
import uxPlayerDataForms from './reducers/ui/uxPlayerData/forms';
import awsConfig from '../config/amplify';

import { welcomeEpics } from './actions/ui/welcome';
import { uxProfileEpics } from './actions/ui/uxProfile';
import { uxEditProfileEpics } from './actions/ui/uxEditProfile';
import { uxPlayerDataEpics } from './actions/ui/uxPlayerData';
import { appEpics } from './actions/ui/app';
import codeSignUp, { codeSignUpEpics } from '../modules/CodeSignUp/+store';
import coachWorld, { coachWorldEpics } from '../modules/CoachWorld/+store';
import prospectSheet, { prospectSheetEpics } from  '../modules/ProspectSheet/+store' ;
import { AUTH2_LOGOUT_SUC } from './actions/data/cognito';
import schoolAwardLogoEpics  from './../store/actions/data/awardLogo/epics';
import {InviteSignUpEpics} from '../components/Welcome/AthletInviteOnboarding/+Store';

Amplify.configure(awsConfig);

const epicMiddleware = createEpicMiddleware();

const appReducer = combineReducers({
  ui,
  data,
  stripe,
  forms: combineForms({
    ...welcomeForms,
    ...uxProfileForms,
    ...uxEditProfileForms,
    ...uxPlayerDataForms,
    dynamic: {},
  }, 'forms'),
  modules: combineReducers({
    codeSignUp,
    coachWorld,
    prospectSheet,
  }),
});


// reset state on logout to fix the cache issue
const rootReducer = (state, action) => {
  if (action.type === AUTH2_LOGOUT_SUC) {
    return appReducer({
      ...state,
      modules: {},
    }, action);
  }
  return appReducer(state, action);
};


// console.log(__DEV__, __GITBRANCH__);

let composeEnhancers = compose;
// eslint-disable-next-line no-undef
if (__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

let middleware = [
  epicMiddleware,
  thunkMiddleware, // lets us create async actions
];
if (__DEV__) {
  // const loggerMiddleware = createLogger();
  middleware = [...middleware,
    // loggerMiddleware, // neat middleware that logs actions
  ];
}
const storeConfig = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );
  epicMiddleware.run(combineEpics(
    welcomeEpics,
    uxProfileEpics,
    uxEditProfileEpics,
    uxPlayerDataEpics,
    appEpics,
    schoolAwardLogoEpics,
    codeSignUpEpics,
    coachWorldEpics,
    prospectSheetEpics,
    InviteSignUpEpics,
  ));
  return store;
};

export default storeConfig;
