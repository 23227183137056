import { combineEpics, ofType } from 'redux-observable';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';
import { of, from } from 'rxjs';

import { asyncFinishAction, asyncErrorAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment, cancelOnAction } from '../../../../store/actions/ui/uxProfile/utils';
import { cognitoSignIn, cognitoSignInSuccess } from '../../../../store/actions/data/cognito';
import { registerCode } from '../accountPage/accountPage.api';
import { SIGN_IN } from './signInPage.actions';
import { makeCognitoUsername } from '../common/common.utils';
import { ajaxErrorHandlerEpicFragment } from '../../../../store/actions/ui/ajaxErrorHandlers';

const signInEpic = action$ => (
  action$.pipe(
    ofType(SIGN_IN),
    switchMap(action => (
      from(cognitoSignIn(
        action.fields.username,
        action.fields.password,
      )).pipe(
        map(response => ({
          action, success: true, response,
        })),
        takeUntil(cancelOnAction(action$)),
        catchError((error) => {
          if (error.code === 'UserNotFoundException') {
            const username = makeCognitoUsername(action.fields.username);
            return from(cognitoSignIn(
              username,
              action.fields.password,
            )).pipe(manualCancelApiCallFragment(
              action$,
              action,
              'codeSignUpSignIn',
            ));
          }
          throw error;
        }),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(action.type, 'codeSignUpSignIn', error, { action }),
        })),
      )
    )),
    switchMap((result) => {
      if (result.success) {
        const token = result.response.signInUserSession.accessToken.jwtToken;
        return registerCode(token, {
          code: result.action.code,
        }).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'codeSignInRegisterCode',
          {
            cognitoSignInResponse: result.response,
          },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          cognitoSignInSuccess(result.cognitoSignInResponse),
          asyncFinishAction(result.action.type, 'codeSignUpSignIn', {
            awsResponse: result.cognitoSignInResponse,
            code: result.action.code,
            username: result.action.fields.username,
            password: result.action.fields.password,
            schoolInfo: result.response,
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const epics = combineEpics(signInEpic);

export default epics;
