
export const YOUTH = 'YOUTH';
export const metricSports = [20, 21];


export const logoFolder = '../../assets/images/CompanyLogos';

let s3Url = 'https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/external-profile-company-logos';
let transparent3Url = 'https://mktg-prod-cdn.s3.us-west-2.amazonaws.com/external-profile-company-logos/transparent';


export const companyLogoUrl = (company, transparent=false) =>  {
  const url = !!transparent ? transparent3Url   : s3Url;
  return `${url}/${logosUrl[company]}`;
};


// export const transparentCompanyLogos = ['247 Sports', 'Athletes Go Live','BeRecruited','OnDeck Softball','Rivals','SportsForce', 'SportsRecruits','FieldLevel' , 'Gamechanger', 'ScoutU', 'College Sports Advocate', 'Rise Softball', 'Softball Factory'];

export const transparentCompanyLogos = ['247 Sports', 'Athletes Go Live','OnDeck Softball','Rivals','FieldLevel' , 'Game Changer', 'ScoutU', 'Rise Softball', 'Softball Factory', '2D', 'Perfect Game', 'Baseball Factory', 'Max Preps', 'MaxPreps', 'Sports Recruits','College Sports Advocate', 'QB Hit List', 'YouTube Channel'];


const logosUrl = {
    'Perfect Game': 'perfect_game.png',
    'Perfect Game_white': 'perfect_game_white.png',
    'PBR': 'pbr.png',
    'Future Stars': 'future_stars_series.png',
    '2D': '2_d.png',
    'HUDL': 'hudl.png',
    'Baseball Factory': 'baseball_factory.png',
    'Game Changer': 'gamechanger.png',
    'MaxPreps': 'maxpreps.png',
    'Max Preps': 'maxpreps.png',
    'FieldLevel': 'field_level.png',
    'NCSA': 'NCSA__Logo.png',
    'Sports Recruits':'sport_recruit.png',
    'SportsForce': 'sport_force.png',
    '247 Sports': '24_7_sport.png',
    'Rivals': 'rival.png',
    'ESPN': 'espn.png',
    'OnDeck Softball': 'on_deck_softball.png',
    'Softball Factory': 'Softballfactory.png',
    'Athletes Go Live': 'athletes_go_live.png',
    'BeRecruited': 'berecruited.png',
    'College Sports Advocate': 'college_sports_advocate.png',
    'ScoutU': 'ScoutU.png',
    'National Scouting Report': 'nsr.png',
    'Rise Softball': 'risesoftball.png',
    'Play in School': 'playinschool.png',
    'Five Tool' : 'Five-Tool.png',
    'YouTube Channel' : 'YoutubeFullColorLogo.png',
    'QB Hit List' : 'QB LIST.png',
    'AthleticNET' : 'AthleticNET_test.png',
    'Mile Split' : 'Mile Split.png',
    'CONNECTLAX' : 'CONNECTLAX.png',
    'On3': 'On3.png',
    'Press Sports': 'Press_Sports.png',
    'Showtime Sports' : 'Showtime_Sports.png',
    'Prospect Select' : 'prospect_sheet_external_profile_light_blue_logo.png',
    'Dynamic Baseball': 'BaseballNW__Logo.png',
    'Baseball Northwest' : 'NorthWest__Logo.png',
    'Realplay' : 'RealPlay__Logo.png',

}

export const ExternalProfileSportsIcons = {
  2: [ //baseball
    {
      'type': 'item',
      'value': 'Perfect Game',
      'name': 'Perfect Game',
      'url': companyLogoUrl('Perfect Game')
    },
    {
      'type': 'item',
      'value': 'PBR',
      'name': 'PBR',
      'url': companyLogoUrl('PBR')
    },
    {
      'type': 'item',
      'value': 'Prospect Select',
      'name': 'Prospect Select',
      'url': companyLogoUrl('Prospect Select')
    },
    {
      'type': 'item',
      'value': 'Five Tool',
      'name': 'Five Tool',
      'url': companyLogoUrl('Five Tool')
    },
    {
      'type': 'item',
      'value': 'Future Stars',
      'name': 'Future Stars',
      'url': companyLogoUrl('Future Stars')
    },
    {
      'type': 'item',
      'value': '2D',
      'name': '2D',
      'url': companyLogoUrl('2D')
    },
    {
      'type': 'item',
      'value': 'Baseball Factory',
      'name': 'Baseball Factory',
      'url': companyLogoUrl('Baseball Factory')
    },
    {
      'type': 'item',
      'value': 'HUDL',
      'name': 'HUDL',
      'url': companyLogoUrl('HUDL')
    },
    {
      'type': 'item',
      'value': 'Game Changer',
      'name': 'Game Changer',
      'url': companyLogoUrl('Game Changer')
    },
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    } ,
    {
      'type': 'item',
      'value': 'FieldLevel',
      'name': 'FieldLevel',
      'url': companyLogoUrl('FieldLevel')
    },
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    },
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    },
    {
      'type': 'item',
      'value': 'National Scouting Report',
      'name': 'National Scouting Report',
      'url': companyLogoUrl('National Scouting Report')
    },
    {
      'type': 'item',
      'value': 'Play in School',
      'name': 'Play in School',
      'url':  companyLogoUrl('Play in School')
    },
    {
      'type': 'item',
      'value': 'SportsForce',
      'name': 'SportsForce',
      'url': companyLogoUrl('SportsForce')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
    {
      'type': 'item',
      'value': 'Showtime Sports',
      'name': 'Showtime Sports',
      'url': companyLogoUrl('Showtime Sports')
    },
    {
      'type': 'item',
      'value': 'Dynamic Baseball',
      'name': 'Dynamic Baseball',
      'url': companyLogoUrl('Dynamic Baseball')
    },
    {
      'type': 'item',
      'value': 'Baseball Northwest',
      'name': 'Baseball Northwest',
      'url': companyLogoUrl('Baseball Northwest')
    },
    {
      'type': 'item',
      'value': 'Realplay',
      'name': 'Realplay',
      'url': companyLogoUrl('Realplay')
    },
  ],
  1: [  //football
    {
      'type': 'item',
      'value': '247 Sports',
      'name': '247 Sports',
      'url': companyLogoUrl('247 Sports')
    } ,
    {
      'type': 'item',
      'value': 'Rivals',
      'name': 'Rivals',
      'url': companyLogoUrl('Rivals')
    } ,
    {
      'type': 'item',
      'value': 'ESPN',
      'name': 'ESPN',
      'url': companyLogoUrl('ESPN')
    } ,
    {
      'type': 'item',
      'value': 'HUDL',
      'name': 'HUDL',
      'url': companyLogoUrl('HUDL')
    } ,
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    } ,
    {
      'type': 'item',
      'value': 'FieldLevel',
      'name': 'FieldLevel',
      'url': companyLogoUrl('FieldLevel')
    } ,
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    } ,
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    } ,
    {
      'type': 'item',
      'value': 'National Scouting Report',
      'name': 'National Scouting Report',
      'url': companyLogoUrl('National Scouting Report')
    },   {
      'type': 'item',
      'value': 'QB Hit List',
      'name': 'QB Hit List',
      'url': companyLogoUrl('QB Hit List')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'AthleticNET',
      'name': 'AthleticNET',
      'url': companyLogoUrl('AthleticNET')
    },
    {
      'type': 'item',
      'value': 'Mile Split',
      'name': 'Mile Split',
      'url': companyLogoUrl('Mile Split')
    },
    {
      'type': 'item',
      'value': 'On3',
      'name': 'On3',
      'url': companyLogoUrl('On3')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ],
  4: [ //softball
    {
      'type': 'item',
      'value': 'OnDeck Softball',
      'name': 'OnDeck Softball',
      'url': companyLogoUrl('OnDeck Softball')
    },
    {
      'type': 'item',
      'value': 'Softball Factory',
      'name': 'Softball Factory',
      'url': companyLogoUrl('Softball Factory')
    },
    {
      'type': 'item',
      'value': 'Athletes Go Live',
      'name': 'Athletes Go Live',
      'url': companyLogoUrl('Athletes Go Live')
    },
    {
      'type': 'item',
      'value': 'BeRecruited',
      'name': 'BeRecruited',
      'url': companyLogoUrl('BeRecruited')
    },
    {
      'type': 'item',
      'value': 'College Sports Advocate',
      'name': 'College Sports Advocate',
      'url': companyLogoUrl('College Sports Advocate')
    },
    {
      'type': 'item',
      'value': 'ScoutU',
      'name': 'ScoutU',
      'url': companyLogoUrl('ScoutU')
    },
    {
      'type': 'item',
      'value': 'National Scouting Report',
      'name': 'National Scouting Report',
      'url': companyLogoUrl('National Scouting Report')
    },
    {
      'type': 'item',
      'value': 'Rise Softball',
      'name': 'Rise Softball',
      'url': companyLogoUrl('Rise Softball')
    },
    {
      'type': 'item',
      'value': 'FieldLevel',
      'name': 'FieldLevel',
      'url': companyLogoUrl('FieldLevel')
    },
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    },
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    },
    {
      'type': 'item',
      'value': 'Perfect Game',
      'name': 'Perfect Game',
      'url': companyLogoUrl('Perfect Game')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
    {
      'type': 'item',
      'value': 'Showtime Sports',
      'name': 'Showtime Sports',
      'url': companyLogoUrl('Showtime Sports')
    },
    {
      'type': 'item',
      'value': 'Game Changer',
      'name': 'Game Changer',
      'url': companyLogoUrl('Game Changer')
    },
  ],
  5: [ //basketball
    {
      'type': 'item',
      'value': '247 Sports',
      'name': '247 Sports',
      'url': companyLogoUrl('247 Sports')
    } ,
    {
      'type': 'item',
      'value': 'Rivals',
      'name': 'Rivals',
      'url': companyLogoUrl('Rivals')
    } ,
    {
      'type': 'item',
      'value': 'ESPN',
      'name': 'ESPN',
      'url': companyLogoUrl('ESPN')
    },
    {
      'type': 'item',
      'value': 'HUDL',
      'name': 'HUDL',
      'url': companyLogoUrl('HUDL')
    } ,
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    },
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    } ,
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    } ,
    {
      'type': 'item',
      'value': 'National Scouting Report',
      'name': 'National Scouting Report',
      'url': companyLogoUrl('National Scouting Report')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'On3',
      'name': 'On3',
      'url': companyLogoUrl('On3')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ],
  16: [ //trackfield
    {
      'type': 'item',
      'value': 'AthleticNET',
      'name': 'AthleticNET',
      'url': companyLogoUrl('AthleticNET')
    },
    {
      'type': 'item',
      'value': 'Mile Split',
      'name': 'Mile Split',
      'url': companyLogoUrl('Mile Split')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ],
  15: [ //lacrosse
    {
      'type': 'item',
      'value': 'CONNECTLAX',
      'name': 'CONNECTLAX',
      'url': companyLogoUrl('CONNECTLAX')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    },
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ],
  7: [ //volleyball
    {
      'type': 'item',
      'value': 'NCSA',
      'name': 'NCSA',
      'url': companyLogoUrl('NCSA')
    },
    {
      'type': 'item',
      'value': 'Sports Recruits',
      'name': 'Sports Recruits',
      'url': companyLogoUrl('Sports Recruits')
    },
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'HUDL',
      'name': 'HUDL',
      'url': companyLogoUrl('HUDL')
    },
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ],
  default:[
    {
      'type': 'item',
      'value': 'MaxPreps',
      'name': 'MaxPreps',
      'url': companyLogoUrl('MaxPreps')
    } ,
    {
      'type': 'item',
      'value': 'HUDL',
      'name': 'HUDL',
      'url': companyLogoUrl('HUDL')
    } ,
    {
      'type': 'item',
      'value': 'YouTube Channel',
      'name': 'YouTube Channel',
      'url': companyLogoUrl('YouTube Channel')
    },
    {
      'type': 'item',
      'value': 'Press Sports',
      'name': 'Press Sports',
      'url': companyLogoUrl('Press Sports')
    },
  ]

}



// ,1: 'football',,2: 'baseball',,3: 'soccer',,4: 'softball',,5: 'basketball',,6: 'wakeboarding',,7: 'volleyball',,8: 'crosscountry',,9: 'swimming',,10: 'diving',,11: 'golf',,12: 'tennis',,13: 'athletictraining',,14: 'wrestling',,15: 'lacrosse',,16: 'trackfield',,17: 'waterpolo',,18: 'hockey',,19: 'cheerleading',,20: 'intlrugby',,21: 'intlnetball',
