import React from "react";
import Icon from "../../../../../components/UxCommon/Icon/Icon";
import PropTypes from "prop-types";

const InviteButtonComponentRenderer = ({
                                         api,
                                         data,
                                         context,
                                         school,
                                         ...props
                                       }) => {



  const invite = () => {
    const { getErrors, sendInvitationByCommonMethod } = context.componentParent;
    data.invited = 1;
    const athleteInfo = Object.assign({}, data);
    if (!getErrors(athleteInfo)) {
      sendInvitationByCommonMethod(athleteInfo);
    }
  };

  const isSubscribed = school && school.isPaid && !!data.isSubscribed;
  const onBoardingCompleted = data.invited === 2;
  let inviteText = !data.invited || data.invited === 0 ? "Invite" : "Resend Invite"
  if(data.invitationSend){
    inviteText = 'Invite Sent';
  }


  if (!data.email && !data.parentsEmail) {
    return <span className="GridView_Invite_Button_Wrapper"/>;
  }

  const renderSubscriptionHtml = () =>{
    return  (
      <div className="GridView_Invite_Icon subscribed">
        <Icon iconName="ok-circled" />
        {data.invitationSend ?
          <div className="GridView_Invite_Text" style={{color: "black"}}>
            {inviteText}
          </div>
          :
          <button
            onClick={invite}
            className="GridView_Invite_Button"
          >
            {inviteText}
          </button>
        }
      </div>
    )
  }
  return (
    <span className="GridView_Invite_Button_Wrapper">
      {
        (onBoardingCompleted) ? (
            <div className="GridView_Invite_Icon Onboarded">
              <Icon iconName="ok-circled" />
              { !!data.invitationSend && (
                <div className="GridView_Invite_Text" style={{color: "black" }}>
                  {inviteText}
                </div>
              )
              }
            </div>
          )
          : ( isSubscribed ? (
            renderSubscriptionHtml()
          ) : (
            <React.Fragment>
              {data.invitationSend ?
                <div className="GridView_Invite_Text">
                  {inviteText}
                </div>
                :
                <button
                  onClick={invite}
                  className="GridView_Invite_Button"
                >
                  {inviteText}
                </button>
              }
            </React.Fragment>

          ))
      }
    </span>
  );
};
export default InviteButtonComponentRenderer;


InviteButtonComponentRenderer.propTypes = {
  api: PropTypes.any,
  data: PropTypes.any,
  context: PropTypes.any
};
