import {combineReducers} from 'redux';

import {ASYNC_FINISH, ASYNC_START, ASYNC_ERROR} from '../../../../store/actions/ui/async';
import {cognitoErrors} from './accountPage.errors';
import {CHECK_EMAIL_EXISTS, CREATE_ACCOUNT} from './accountPage.actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === CREATE_ACCOUNT) {
        return 'Creating account ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === CREATE_ACCOUNT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === CREATE_ACCOUNT) {
        return cognitoErrors(action.error);
      }
      return state;
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.model === CREATE_ACCOUNT) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const duplicateEmail = (state = true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_EMAIL_EXISTS) {
        return action.data.exists;
      }
      return state;
    default:
      return state;
  }
}

const emailExistsMessage = (state = '', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === CHECK_EMAIL_EXISTS) {
        return action.data.exists ? 'Another user with that email address already exists' : ''
      }
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  loadingMsg,
  errorMsg,
  duplicateEmail,
  emailExistsMessage,
});

export default reducer;
