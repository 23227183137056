import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../../../../UxCommon';

const DeleteForm = ({ onDelete, onCancel }) => (
  <div className="UxPlayerData_TestData_DeleteDialog">
    <div className="UxPlayerData_TestData_DeleteDialog_Top">
      <div className="UxPlayerData_TestData_DeleteDialog_Title">
        Delete Data?
      </div>
      <div className="UxPlayerData_TestData_DeleteDialog_Question">
        {`Are you sure you want to delete your data?  You won't get it back if you do.${''}`}
      </div>
    </div>
    <div className="UxPlayerData_TestData_DeleteDialog_Btns">
      <ButtonRect onClick={onCancel}>
        Cancel
      </ButtonRect>
      <ButtonRect onClick={onDelete} className="primary">
        Delete Data
      </ButtonRect>
    </div>
  </div>
);
DeleteForm.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteForm;
