import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/Icon';
import SelectControl from '../../../common/FormControls/SelectControl';
import {ButtonRect} from '../../../../../components/UxCommon';
import {validateRequired} from '../../../../../store/actions/ui/formUtils/validators';
import CombineLister from './CategoryLister/CategoryLister.component';
import ControlErrors from '../../../common/FormControls/ControlErrors';
import {Sports} from '../../../../../store/actions/ui/routes';
import queryString from 'query-string';


export const checkRequired = (value, msg = '') => {
  const error = validateRequired(msg)(value).required;
  return error ? [error] : [];
};

const CatButton = ({ cat, onClick }) => (
  <button onClick={onClick(cat)} className="CombineTemplateWrapper_CategoryBadge" >
    <div className="CombineTemplate_BadgeText">
      <div className="CombineTemplate_BadgeTextName">{cat.name}</div>
      <div className="CombineTemplate_BadgeTextDesc">{cat.description}</div>
    </div>
    <Icon iconName="close" />
  </button>
);

CatButton.propTypes = {
  cat: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

class EditCreateCombineTemplate extends PureComponent {
  static propTypes = {
    getTestCategories: PropTypes.func.isRequired,
    standardTestCats: PropTypes.array.isRequired,
    sports: PropTypes.object.isRequired,
    standardTestCatOptions: PropTypes.object,
    combineTemplate: PropTypes.object,
    createCombineTemplate: PropTypes.func.isRequired,
    updateCombineTemplate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    organizedSubbanks: PropTypes.object,
    sportedCats: PropTypes.array,
    match: PropTypes.object.isRequired,
    sportsCode : PropTypes.object.isRequired
  };

  state = {
    errors: {},
    values: {
      name: '',
      sport: '',
      cat: '',
      desc: '',
    },
    addedCats: [],
    required: [],
    optional: [],
    combineCount:0,
    waitForStateUpdate:false,
    sportUpdated: false,
  };

  static getDerivedStateFromProps(props, currentState) {
    debugger;
    if (props.combineTemplate
    && props.standardTestCats.length) {
      const currentCats = [];
      props.combineTemplate.standardTestCategories.forEach((cat) => {
        const stdCat = props.standardTestCats.find(c => c.id === cat.standard_test_category_id);
        if (stdCat) {
          currentCats.push(stdCat);
        }
      });

      const required = props.sportedCats.filter(c =>
        !!c.adultRateable
        && c.sportId === props.combineTemplate.sportId);
      const optional = props.standardTestCats.filter(c => !required.find(r => r.code === c.code));
      return {
        combineTemplateId: props.combineTemplate.combineTemplateId,
        errors: {},
        values: {
          sport:  props.combineTemplate.sportId || props.sportId,
          cat: '',
        },
        addedCats: currentCats,
        required,
        optional,
        waitForStateUpdate: true,
      };
    }
    if (props.match.params.templateId === 'new'
    && props.standardTestCats.length
    && !currentState.addedCats.length) {
      let heightCode = 'HEIGHT';
      let weightCode = 'WEIGHT';
      const sportCode = props.sportsCode[currentState.values.sport];
      if(sportCode ===  'INTLRUGBY' || sportCode  =='INTLNETBALL' ) {
        heightCode = 'METRICHEIGHT';
        weightCode = 'METRICWEIGHT';
      }
      const height = props.standardTestCats.find(c => c.code === heightCode);
      const weight = props.standardTestCats.find(c => c.code === weightCode);
      const parsedQuery = queryString.parse(props.location.search);
      const { sportId } = parsedQuery;
      return {
        addedCats: [height, weight],
        optional: props.standardTestCats,
        waitForStateUpdate: true,
        values: {
          sport: Number(sportId),
          cat: '',
        },
      };
    }
    return null;
  }

  componentDidMount() {
    debugger;
    this.shouldUpdate();
  }

  componentDidUpdate() {
    debugger;
    const { sportUpdated,values, addedCats } = this.state;
    if(!sportUpdated && values.sport){
      this.setRequiredAndOptional(values.sport);
      this.setSportHeightAndWeight(values.sport);
      this.setState({sportUpdated: true})

    }
    this.shouldUpdate();
  }

  onSubmit = (event) => {
    debugger;
    event.preventDefault();
    this.handleSubmit(false, true);
  };

  onSubmitAndRedirect = (event) => {
    debugger;
    event.preventDefault();
    this.handleSubmit(true, false);
  };

  onValueChange = name => (value) => {
    debugger;
    const { errors, values } = this.state;
    const nextState = {
      errors: { ...errors },
      values: { ...values },
    };
    switch (name) {
      default:
        nextState.values[name] = Number(value);
        nextState.errors[name] = [];
        break;
    }
    this.setState(nextState);
  };
  setSportHeightAndWeight = (sportId) => {
    debugger;
      const {addedCats} = this.state;
      const {standardTestCats,sportsCode} =  this.props;
      let filterAddedCats = [];
      const cloneAddedCats = Object.assign([], addedCats);
     if(sportsCode[sportId]) {
        cloneAddedCats.forEach(function (values, index) {
          if(sportsCode[sportId]  === 'INTLRUGBY' || sportsCode[sportId] =='INTLNETBALL') {
            if(values.code == 'HEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICHEIGHT'));
            }
            if(values.code == 'WEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'METRICWEIGHT'));
            }
          } else {
            if(values.code == 'METRICHEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'HEIGHT'));
            }
            if(values.code == 'METRICWEIGHT') {
              delete cloneAddedCats[index];
              filterAddedCats.push(standardTestCats.find(ar => ar.code === 'WEIGHT'));
            }
          }
          filterAddedCats.push(cloneAddedCats[index]);
        });
      }
         this.setState( {
          addedCats : filterAddedCats.filter(item => item != null),
        });
  }
  onRemoveCat = cat => () => {
    debugger;
    const { addedCats } = this.state;
    this.setState({
      addedCats: addedCats.filter(c => c !== cat),
    });
  };

  onCategoryPicked = key => (value) => {
    debugger;
    const { standardTestCats } = this.props;
    const { addedCats, values } = this.state;
    const cat = standardTestCats.find(c => c.code === key);
    if (value) {
      this.setState({
        addedCats: [...addedCats, cat],
        values: Object.assign({}, values, {
          [key]: value,
        }),
      });
    } else {
      this.setState({
        addedCats: addedCats.filter(c => c.id !== cat.id),
        values: Object.assign({}, values, {
          [key]: value,
        }),
      });
    }
  }

  setRequiredAndOptional = (sportId) => {
    debugger;
    const { standardTestCats, sportedCats } = this.props;
    const required = sportedCats.filter(c => !!c.adultRateable && c.sportId === sportId);
    const optional = standardTestCats.filter(c => !required.find(r => r.code === c.code))
    this.setState({
      required,
      optional,
    });
  }

  handleSubmit = (redirectToCombine = false, redirectToCombineDataEntry = false) => {
    debugger;
    const {
      createCombineTemplate,
      updateCombineTemplate,
      combineTemplate,
      match,
    } = this.props;
    const schoolId = this.props.match.params.schoolId;
    const { combineId } = match.params;
    const { addedCats } = this.state;
    const form = this.formRef.current;
    const errors = {
      sport: combineTemplate ? [] : checkRequired(form.sport.value),
      cat: addedCats.length ? null : ['Please select at least one test'],
    };
    let success = true;
    Object.values(errors).forEach((errorList) => {
      if (errorList && errorList.length) {
        success = false;
      }
    });
    this.setState({ errors });
    if (success) {
      const formStdTestCats = addedCats.map(c => c.id);
      const date = new Date().toJSON().slice(0,10);
      const formData = {
        name: `${Sports[form.sport.value]} TEMPLATE ${date}`,
        description: "DESCRIPTION",
        school_uuid: schoolId,
      };
      if (!combineTemplate) {
        formData.sport_id = form.sport.value;
      }

      if (combineTemplate) {
        const curStdTestCategories = combineTemplate.standardTestCategories
          .map(c => c.standard_test_category_id);
        const newCats = formStdTestCats.filter(el => !curStdTestCategories.includes(el));
        const removedCats = curStdTestCategories.filter(el => !formStdTestCats.includes(el));
        updateCombineTemplate(
          formData,
          combineTemplate.combineTemplateId,
          newCats,
          removedCats,
          match.params.schoolId,
          redirectToCombine,
          combineId,
          redirectToCombineDataEntry
        );
      } else {
        createCombineTemplate(formData, formStdTestCats, match.params.schoolId, redirectToCombine);
      }
    }
  };

  formRef = React.createRef();


  testCatsFetched = false;
  catSelectors = 0;

  shouldUpdate = () => {
    debugger;
    const {
       getTestCategories,
      } = this.props;
    if (!this.testCatsFetched) {
      this.testCatsFetched = true;
      getTestCategories();
    }
  };

  render() {
    const {
      sports, standardTestCatOptions, loading,
      standardTestCats, organizedSubbanks, match
    } = this.props;
    const {
      errors, values, addedCats, required, optional, waitForStateUpdate
    } = this.state;
    const cloneStandardTestCatOptions = Object.assign({}, standardTestCatOptions);

    const { combineId } = match.params;

    if (addedCats.length && Object.keys(standardTestCatOptions).length) {
      addedCats.forEach(c => delete cloneStandardTestCatOptions[c.id]);
    }

    this.catSelectors = 0;
    return (
     <React.Fragment>
        <form
          ref={this.formRef}
          onSubmit={this.onSubmit}
          className="CombineTemplate_Form"
          style={{ display: loading ? 'none' : '' }}
        >
          <div className="CombineTemplate_Cols">
            <div className="CombineTemplate_LeftCol">
              <div className="CombineTemplate_MetaData">
                {
                  <SelectControl
                    label="Sport"
                    options={sports}
                    name="sport"
                    style={{ zIndex: 5, display: "none" }}
                    defaultValue={values.sport}
                  />
                }
              </div>
              <CombineLister
                title="Speed, Agility, Performance, & Strength Tests"
                help={
                  <React.Fragment>
                    These tests are industry standard to measure your<br />
                    athlete's overall athleticism and strength
                  </React.Fragment>
                }
                missingMsg="Please select a sport"
                subbanks={Object.values(organizedSubbanks)}
                cats={required}
                filters={{ adultRateable: 1, sportId: values.sport || 1 }}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
              <div style={{ marginBottom: 35 }} />
              <CombineLister
                title="Sport Specific/Optional Tests"
                help="Add as many of the tests below as you'd like"
                subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode !== 'PHYSIQUE')}
                cats={optional}
                filters={{}}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
              <div style={{ marginBottom: 35 }} />
              <CombineLister
                title="Measureables"
                help={
                  <React.Fragment>
                    {"Add as many of the measurables below as you'd like"}<br />
                    Height and weight are required to generate a VC Score
                  </React.Fragment>
                }
                subbanks={Object.values(organizedSubbanks).filter(s => s.bankCode === 'PHYSIQUE')}
                cats={standardTestCats}
                filters={{ adultRateable: 0 }}
                selectedCats={addedCats}
                onChange={this.onCategoryPicked}
              />
            </div>
            <div className="CombineTemplateWrapper_CategoryContainer">
              <div className="CombineTemplateWrapper_CategoryBadges">
                {
                  addedCats.map(cat => (
                    <CatButton key={cat.id} onClick={this.onRemoveCat} cat={cat} />
                  ))
                }
              </div>
              <ControlErrors errors={errors.cat} />
            </div>
          </div>
          <div className="CombineTemplate_Btns">
            {
              combineId ?
                (
                  <ButtonRect
                    type="submit"
                    onClick={this.onSubmit}
                    className="secondary"
                    style={{width: '45%', minWidth: 200}}
                  >
                    Save Combine Template
                  </ButtonRect>)
                : (
                  <ButtonRect
                    type="submit"
                    onClick={this.onSubmitAndRedirect}
                    className="primary"
                    style={{width: '45%', minWidth: 200}}
                  >
                    Save and Run Combine
                  </ButtonRect>
                )
            }


          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default EditCreateCombineTemplate;
