import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Control } from 'react-redux-form';

import {
  ContainerFluid,
  Row,
  Col,
  CloseButton,
  LoadingSpinner,
} from '../../../../UxCommon';
import {
  DatePicker,
  SelectControl,
  Button,
  Headline,
  GeneralErrors,
} from '../../../../UxCommon/Form';
import TeamTypeAhead from './TeamTypeAhead/TeamTypeAhead';

class NewSeasonForm extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onSeasonStartChange: PropTypes.func.isRequired,
    onSeasonEndChange: PropTypes.func.isRequired,
    allTeams: PropTypes.array.isRequired,
    positions: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    teamTypeAheadResults: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    onTeamSearchResultClick: PropTypes.func.isRequired,
    formInit: PropTypes.func.isRequired,
    currentSport: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { formInit } = this.props;
    formInit();
  }

  onMeetDateChange = (date) => {
    const {
      onSeasonStartChange,
      onSeasonEndChange,
    } = this.props;
    const newEndDate = date.clone();
    newEndDate.add(1, 'years');
    onSeasonEndChange(newEndDate);
    onSeasonStartChange(date);
  }

  mapTeamsToSearchResults = teams => teams.map(team => ({
    team,
    text: team.googleName,
    id: team.id,
  }));

  submit = (form) => {
    this.props.submit(form);
  }

  renderDatePickerFields() {
    const {
      rrfForm,
      onSeasonStartChange,
      onSeasonEndChange,
      currentSport,
    } = this.props;
    if (currentSport.usesSeasonEndDate) {
      return (
        <Row>
          <Col>
            <DatePicker
              label="Season Start Date"
              placeholder="Season Start Date"
              model="forms.firstSeason.seasonStart"
              rrfField={rrfForm.seasonStart}
              onDateChange={onSeasonStartChange}
            />
          </Col>
          <Col>
            <DatePicker
              label="Season End Date"
              placeholder="Season End Date"
              model="forms.firstSeason.seasonEnd"
              rrfField={rrfForm.seasonEnd}
              onDateChange={onSeasonEndChange}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <DatePicker
            label="Meet Date"
            placeholder="Meet Date"
            model="forms.firstSeason.seasonStart"
            rrfField={rrfForm.seasonStart}
            onDateChange={this.onMeetDateChange}
          />
        </Col>
        <Col>
          <Control
            type="hidden"
            model="forms.firstSeason.seasonEnd"
          />
        </Col>
      </Row>
    );
  }

  render() {
    const {
      onCancel,
      rrfForm,
      allTeams,
      positions,
      teamTypeAheadResults,
      loading,
      loadingMsg,
      onTeamSearchResultClick,
      currentSport,
    } = this.props;
    const positionOptions = positions.filter( (pos) => pos.code !== "LS");
    const { statsSeasonLabel, statsPositionLabel } = currentSport;
    const headline = `Enter ${statsSeasonLabel} Stats`;
    return (
      <ContainerFluid className="UxPlayerData_NewSeasonForm">
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
        <CloseButton onClick={onCancel} size={14} />
        <Row>
          <Col>
            <Headline>{headline}</Headline>
          </Col>
        </Row>
        <Form
          model="forms.firstSeason"
          onSubmit={this.submit}
        >
          <Row>
            <Col className="col-auto">
              <TeamTypeAhead
                searchResults={this.mapTeamsToSearchResults(teamTypeAheadResults)}
                onSearchResultClick={onTeamSearchResultClick}
                rrfForm={rrfForm}
                allTeams={this.mapTeamsToSearchResults(allTeams)}
              />
            </Col>
            {/* <Col className="col-auto" style={{ paddingTop: '7.5px', paddingBottom: '7.5px' }}>
              <ButtonLink
                to={`/${Routes.profile}/${match.params.uuid}/${Routes.team}`}
              >
                Manage My Teams
              </ButtonLink>
            </Col> */}
          </Row>
          <Row>
            <Col className="col-auto">
              <SelectControl
                label={statsPositionLabel}
                placeholder={statsPositionLabel}
                model="forms.firstSeason.position"
                rrfField={rrfForm.position}
                options={positionOptions.map(p => ({ value: p.id, text: p.name }))}
              />
            </Col>
          </Row>
          {this.renderDatePickerFields()}
          {/* <GeneralError error={formGeneralError} /> */}
          <GeneralErrors rrfForm={rrfForm} />
          <Row className="UxPlayerData_NewSeasonForm_Btns">
            <Col className="col-auto">
              <Button className="UxPlayerData_NewSeasonForm_Btn" onClick={onCancel} type="button">
                Cancel
              </Button>
            </Col>
            <Col className="col-auto">
              <Button className="UxPlayerData_NewSeasonForm_Btn primary">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </ContainerFluid>
    );
  }
}

export default NewSeasonForm;
