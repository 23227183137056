import React, { PureComponent } from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import { parserOnlyNumeric } from '../../../../utils/parsers';
import { convertStoredInches, convertToInches } from '../utils';

class HeightEntry extends PureComponent {
  static propTypes = {
    testResult: PropTypes.any,
    model: PropTypes.string.isRequired,
    rrfForm: PropTypes.object.isRequired,
    modelFt: PropTypes.string.isRequired,
    modelIn: PropTypes.string.isRequired,
    onFormFieldChanged: PropTypes.func.isRequired,
    rrfField: PropTypes.object.isRequired,
    help: PropTypes.any,
    helpClass: PropTypes.string,
    errors: PropTypes.object,
    mostRecentHeight: PropTypes.string.isRequired,
  }

  componentDidUpdate() {
    if (this.props.rrfForm.height) {
      const heightNum = Number(this.props.rrfForm.height.value);
      const vals = convertStoredInches(heightNum);
      if (this.props.rrfForm.height_ft && this.props.rrfForm.height_in) {
        const ftNum = Number(this.props.rrfForm.height_ft.value);
        if (ftNum !== vals.feet
          && !this.props.rrfForm.height_ft.focus
          && !this.props.rrfForm.height_in.focus) {
          this.props.onFormFieldChanged('height_ft', vals.feet.toString());
        }

        const inchesNum = Number(this.props.rrfForm.height_in.value);
        if (inchesNum !== vals.inches
          && !this.props.rrfForm.height_ft.focus
          && !this.props.rrfForm.height_in.focus) {
          this.props.onFormFieldChanged('height_in', vals.inches.toString());
        }
      }
    }
  }
  onHeightChanged = () => {
    const { onFormFieldChanged } = this.props;
    const currentFt = this.props.rrfForm.height_ft.value ?
      this.props.rrfForm.height_ft.value : 0;
    const currentIn = this.props.rrfForm.height_in.value ? this.props.rrfForm.height_in.value : 0;
    const newHeight = convertToInches(currentFt, currentIn);
    onFormFieldChanged('height', newHeight.inches.toString());
  };

  render() {
    const {
      testResult,
      model,
      modelFt,
      modelIn,
      rrfField,
      help,
      helpClass,
      errors,
      mostRecentHeight,
    } = this.props;
    let mrHeight = mostRecentHeight;
    if (Number.isNaN(Number(mostRecentHeight))) {
      mrHeight = '';
    }

    const ftProps = {};
    const inProps = {};
    if (rrfField.value === undefined) {
      ftProps.value = '';
      inProps.value = '';
    }

    // Note: react-redux-form Control elements need a value set when
    // defaultValue is untruthy (0, '', null etc)
    const totalDefaulValue = (testResult && testResult.test.height) ? `${testResult.test.height}` : mrHeight;
    const totalProps = {};
    if (!totalDefaulValue && rrfField.value === undefined) {
      totalProps.value = '';
    } else {
      totalProps.value = totalDefaulValue || rrfField.value;
    }

    return (
      <div className="UxCommon_Form_Group UxCommon_Form_Group_Flex" style={{ flexWrap: 'wrap' }}>
        <div
          style={{ marginRight: '5px', flex: '1 0 21%' }}
          className={[
            'UxCommon_Form_InputGroup',
            rrfField.valid ? '' : 'error',
            'UxCommon_Form_InputGroup_Flex',
          ].join(' ')}
        >
          <div className="UxCommon_Form_ErrorMarker" />
          <div>
            <div className="UxCommon_Form_LabelWrapper">
              <label
                className={[
                  'UxCommon_Form_Label',
                  ((rrfField.value && rrfField.value.length) || mrHeight) ? 'show' : '',
                ].join(' ')}
                htmlFor={modelFt}
              >
                Height (ft)
              </label>
            </div>
            <Control.text
              label="Height (ft)"
              placeholder="Enter Height (ft)"
              className={[
                'UxCommon_Form_Input',
                ((rrfField.value && rrfField.value.length) || mrHeight) ? 'not-empty' : '',
              ].join(' ')}
              model={modelFt}
              id={modelFt}
              parser={parserOnlyNumeric}
              onBlur={this.onHeightChanged}
              defaultValue=""
              {...ftProps}
            />
          </div>
        </div>
        <div
          style={{ flex: '1 0 21%' }}
          className={[
            'UxCommon_Form_InputGroup',
            'UxCommon_Form_InputGroup_Flex',
          ].join(' ')}
        >
          <div>
            <div className="UxCommon_Form_LabelWrapper">
              <label
                className={[
                  'UxCommon_Form_Label',
                  ((rrfField.value && rrfField.value.length) || mrHeight) ? 'show' : '',
                ].join(' ')}
                htmlFor={modelIn}
              >
                Height (in)
              </label>
            </div>
            <Control.text
              label="Height (in)"
              placeholder="Enter Height (in)"
              className={[
                'UxCommon_Form_Input',
                ((rrfField.value && rrfField.value.length) || mrHeight) ? 'not-empty' : '',
              ].join(' ')}
              model={modelIn}
              id={modelIn}
              parser={parserOnlyNumeric}
              onBlur={this.onHeightChanged}
              defaultValue="0"
              {...inProps}
            />
          </div>
        </div>
        <Control
          type="hidden"
          model={model}
          id={model}
          // mapProps={{
          //   value: ({ viewValue }) => viewValue,
          // }}
          defaultValue={(testResult && testResult.test.height) ? `${testResult.test.height}` : mrHeight}
          {...totalProps}
        />
        {
          help &&
          <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
            {help}
          </HelpPopper>
        }
        <div>
          <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
        </div>

      </div>
    );
  }
}

export default HeightEntry;
