import { connect } from 'react-redux';
import Component from './Awards.component';
import { awardsGetCombines,awardsGetCombineAwards } from '../../+store/award';
import { getSchoolAwardLogo } from '../../../../store/actions/data/awardLogo';
import { selectFilteredCombinesForAwards } from '../../+store';

const mapStateToProps = (state, ownProps) => {
  const { schoolId } = ownProps.match.params;
  const profile =   state.data.user && state.data.user.profiles[state.data.cognito.uuid];
  const schoolAwardLogo = state.data.awardLogo.schoolAwardLogo;
  return {
    schoolId,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
    combines: selectFilteredCombinesForAwards(state,ownProps),
    awards: state.modules.coachWorld.award.awards[schoolId],
    profile,
    schoolAwardLogo
  };
};

const mapDispatchToProps = dispatch => ({
  getCombinesAndAwards: (schoolIds) => {
    dispatch(awardsGetCombines(schoolIds));
  },
  getCombineAwards : (combineId, schoolId) => {
    dispatch(awardsGetCombineAwards(combineId, schoolId));
  },
  getSchoolAwardLogo: schoolId => {
    dispatch(getSchoolAwardLogo(schoolId))
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
