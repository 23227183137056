import React from 'react';
import PropTypes from 'prop-types';

const Line = ({ style, className }) => (
  <div className={`UxCommon_Line ${className || ''}`} style={style || {}} />
);
Line.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Line;
