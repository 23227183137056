import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetFavoriteAwards extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getFavoriteAwards: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    needsUpdate: PropTypes.bool.isRequired,
  };

  // /* eslint-disable */
  // state = {
  //   loadRequired: false,
  //   prevUuid: '',
  // };
  // /* eslint-enable */

  // static getDerivedStateFromProps(props, state) {
  //   const derivedState = {
  //     loadRequired: false,
  //   };
  //   if (props.uuid !== state.prevUuid) {
  //     derivedState.loadRequired = true;
  //     derivedState.prevUuid = props.uuid;
  //   }
  //   return derivedState;
  // }

  // componentDidMount() {
  //   const {
  //     getFavoriteAwards,
  //     uuid,
  //   } = this.props;
  //   if (uuid) {
  //     getFavoriteAwards(uuid);
  //   }
  // }

  // componentDidUpdate() {
  //   const {
  //     uuid,
  //     getFavoriteAwards,
  //     needsUpdate,
  //   } = this.props;
  //   const {
  //     loadRequired,
  //   } = this.state;
  //   if ((loadRequired || needsUpdate) && uuid) {
  //     getFavoriteAwards(uuid);
  //   }
  // }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetFavoriteAwards;
