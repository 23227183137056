import React, { PureComponent } from 'react';
import { fromEvent } from 'rxjs';
import PropTypes from 'prop-types';

class MenuBar extends PureComponent {
  static propTypes = {
    msg: PropTypes.object,
    setScrolledBelowMenu: PropTypes.func.isRequired,
    hasScrolledBelowMenu: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    ([this.ele] = document.getElementsByClassName('CoachWorld'));
    this.subscriptions.push(fromEvent(this.ele, 'scroll').subscribe((result) => {
      this.onScroll(result);
    }));
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  onScroll = () => {
    const { setScrolledBelowMenu, hasScrolledBelowMenu } = this.props;
    if (!hasScrolledBelowMenu) {
      if (this.div.getBoundingClientRect().top <= 0) {
        setScrolledBelowMenu(true);
      }
    } else if (this.div.getBoundingClientRect().top > 0) {
      setScrolledBelowMenu(false);
    }
    // console.log('scroll', this.ele.scrollTop);
    // console.log('div', this.div);
    // console.log();
    // console.log(this.ele.offsetParent, this.ele.offsetTop);
  }

  setRef = (input) => {
    this.div = input;
  }

  subscriptions = [];

  render() {
    const { msg, loading } = this.props;
    return (
      <div
        className="CombineMenuBarWrapper"
        ref={this.setRef}
        style={{ display: loading ? 'none' : 'block' }}
      >
        <div className="CombineMenuBar">
          {msg && (
            <div className={`CombineMenuBar_Msg ${msg.msgType}`}>
              {msg.msg}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MenuBar;
