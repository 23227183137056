import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class IframeWrapper extends PureComponent {
  static propTypes = {
    media: PropTypes.object.isRequired,
  }

  state = {
    loading: true,
    width: 0,
    height: 0,
  }

  componentDidMount() {
    if (this.ref && this.ref.current) {
      this.setHeightWeight();
    }
  }

  componentDidUpdate() {
    if (this.ref && this.ref.current) {
      this.setHeightWeight();
    }
  }

  onLoad = () => {
    this.setState({
      loading: false,
    });
  }

  setHeightWeight = () => {
    this.setState({
      width: this.ref.current.offsetWidth,
      height: this.ref.current.offsetHeight,
    });
  }

  ref = React.createRef();

  render() {
    const { media } = this.props;
    const { loading, width, height } = this.state;
    let iframeWidth = '100%';
    let iframeHeight = '100%';
    if (width && height) {
      if ((height / width) > (9 / 16)) {
        iframeWidth = `${width}px`;
        iframeHeight = `${(width * 9) / 16}px`;
      } else {
        iframeWidth = `${(height * 16) / 9}px`;
        iframeHeight = `${height}px`;
      }
    }
    return (
      <div ref={this.ref} className="ExpandedPhotoDialog_IframeWrapper">
        {
          loading && (
            <div className="ExpandedPhotoDialog_IframeLoadingTxt">Loading ...</div>
          )
        }
        <div className="ExpandedPhotoDialog_IframeWrapper2">
          <iframe
            src={media.embedPath}
            frameBorder={0}
            allowFullScreen
            title="Hudl Video"
            className="ExpandedPhotoDialog_Iframe"
            style={{ height: iframeHeight, width: iframeWidth }}
            onLoad={this.onLoad}
          />
        </div>
      </div>
    );
  }
}
export default IframeWrapper;
