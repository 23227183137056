import { ajax } from 'rxjs/ajax';
import Urls, { sportsTables } from '../../../../config/urls';

/*-------------------------------------------------------------*/

export const sportGetSports = () => ajax.getJSON(
  Urls.sports(sportsTables.sports),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
);

class Sport {
  constructor() {
    this.id = 0;
    this.code = '';
    this.desc = '';
    this.name = '';
    this.orderNum = 0;
    this.createdAt = '';
    this.updatedAt = '';
    this.hasStats = 1;
    this.standardTestCategories = [];
    this.hasJerseyNumber = true;
    this.jerseyNumberLabel = 'Jersey Number(s)';
    this.statsTotalRowLabel = 'Total';
    this.statsSeasonLabel = 'Season';
    this.statsPositionLabel = 'Position';
    this.usesSeasonEndDate = true;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.code = response.code;
    this.desc = response.description;
    this.name = response.name;
    this.orderNum = response.order_num;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
    this.hasStats = response.has_stats;
    this.standardTestCategories = response._embedded.sportStandardTestCategories
      .map(cat => cat.standard_test_category_id);
    this.hasJerseyNumber = !!response.has_jersey_number;
    this.jerseyNumberLabel = response.jersey_number_label;
    this.statsTotalRowLabel = response.stats_total_row_label;
    this.statsSeasonLabel = response.stats_season_label;
    this.statsPositionLabel = response.stats_position_label;
    this.usesSeasonEndDate = !!response.uses_season_end_date;
  }
}

export const mapSportsApiToUi = response => (
  response._embedded.map((rawSport) => {
    const sport = new Sport();
    sport.fromApiResponse(rawSport);
    return sport;
  })
);

export const SPORT_GET_SPORTS_SUC = 'sport.sports.getSuccess';
export const sportsGetSuccess = sports => ({
  type: SPORT_GET_SPORTS_SUC,
  sports,
});

export const ORG_AFF_SPORTS = 'sports.org.getSuccess';
export const getOrgAffSports = (teams, schoolStyle, sports) => ({
  type: ORG_AFF_SPORTS,
  teams,
  schoolStyle,
  sports,
}); 