import React from 'react';
import { ScaleLoader } from 'react-spinners';
import PropTypes from 'prop-types';

function LoadingSpinner({ loading, loadingMsg }) {
  return (
    <div>
      {
        loading &&
        <div className="loading-backdrop d-flex justify-content-center align-items-center">
          <div className="loading-spinner">
            <h1>{loadingMsg}</h1>
            <ScaleLoader
              color="white"
              height={70}
              width={8}
              radius={4}
              margin="4px"
              loading={loading}
            />
          </div>
        </div>
      }
    </div>
  );
}
LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
  loadingMsg: PropTypes.string,
};

export default LoadingSpinner;
