import { Roles } from '../../../actions/data/user/profile/models';

const joinNowForm = {
  first: '',
  last: '',
  username: '',
  confirmUsername: '',
  password: '',
  confirmPassword: '',
  roleId: Roles.athlete,
};

const invitedSignUpForm = {
  first: '',
  last: '',
  confirmUsername: '',
  phone: '',
  confirmPhone: '',
  parentName: '',
  parent2Name: '',
  parentsEmail: '',
  confirmParentsEmail: '',
  parent2Email: '',
  confirmParent2Email: '',
  parentMobile: '',
  confirmParentMobile: '',
  parent2Mobile: '',
  confirmParent2Mobile: '',
  password: '',
  confirmPassword: '',
  roleId: Roles.athlete,
};

const confirmForm = {
  code: '',
};
const forgotPasswordForm = {
  username: '',
};
const resetPasswordForm = {
  code: '',
  password: '',
  confirmPassword: '',
};
const signInForm = {
  username: '',
  password: '',
};
const mergeSignInForm = {
  username: '',
  password: '',
  jerseyNumbers: '',
  positions: '',
};

const mergeAnotherAccountForm = {
  username: '',
};

const acceptInvitationForm = {
  jerseyNumbers: '',
  positions: '',
};

const secondSignInForm = {
  password: '',
};

const enterCodeForm = {
  code: '',
};

const forms = {
  joinNow: joinNowForm,
  confirmAccount: confirmForm,
  forgot: forgotPasswordForm,
  resetPassword: resetPasswordForm,
  login: signInForm,
  secondSignIn: secondSignInForm,
  enterCode: enterCodeForm,
  invitedSignUp: invitedSignUpForm,
  mergeLogin: mergeSignInForm,
  acceptInvitation: acceptInvitationForm,
  mergeAnotherAccount: mergeAnotherAccountForm,
};

export default forms;
