import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import Component from './EditCreateCombineTemplate.component-bak';
import { createCombineTemplate, getTestCategories, updateCombineTemplate, toggleDeleteModal, deleteCombineTemplate, clearDeleteModal } from '../../../+store/combine';

const getSports = state => state.data.sport.sports;

const selectSports = createSelector(
  getSports,
  sports => sports.reduce((prev, curr) => (
    { ...prev, [curr.id]: curr.name }
  ), {}),
);

const selectedSportsCode = createSelector(
  getSports,
  sports => sports.reduce((prev, curr) => (
    {...prev, [curr.id]: curr.code}
  ), {}),
);

const getStandardTestCats = state =>
  state.modules.coachWorld.combine.standardTestCats.standardTestCats || [];

const getCurrentTemplateId = (state, ownProps) => parseInt(ownProps.match.params.templateId, 10);
const getCombineTemplates = (state, ownProps) => state.modules.coachWorld.combine.combineTemplates[ownProps.match.params.schoolId];

export const selectCombineTemplate = createSelector(
  getCurrentTemplateId,
  getCombineTemplates,
  (id, combineTemplates) => {
    if (id && combineTemplates) {
      return combineTemplates.find(t => t.id === id);
    }
    return null;
  },
);

const selectStandardTestCats = createSelector(
  getStandardTestCats,
  selectCombineTemplate,
  cats => cats.reduce((prev, curr) => (
    { ...prev, [curr.id]: curr.name }
  ), {}),
);

const mapStateToProps = (state, ownProps) => ({
  user: state.data.cognito,
  combineTemplate: state.modules.coachWorld.combine.combineTemplate,
  sports: selectSports(state),
  sportsCode : selectedSportsCode(state),
  standardTestCatOptions: selectStandardTestCats(state, ownProps),
  standardTestCats: state.modules.coachWorld.combine.standardTestCats.stdTestCategories || [],
  sportedCats: state.modules.coachWorld.combine.standardTestCats.sportedCats || [],
  organizedSubbanks: state.modules.coachWorld.combine.standardTestCats.organizedSubbanks || {},
  loading: !!state.modules.coachWorld.combine.loadingMsg,
  loadingMsg: state.modules.coachWorld.combine.loadingMsg,
});


const mapDispatchToProps = dispatch => ({
  getTestCategories: () => {
    dispatch(getTestCategories());
  },
  createCombineTemplate: (data, cats, schoolId, redirectToCombine) => {
    dispatch(createCombineTemplate(data, cats, schoolId, redirectToCombine));
  },
  updateCombineTemplate: (data, id, newCats, removedCats, schoolId, redirectToCombine, combineId, redirectToCombineDataEntry) => {
    dispatch(updateCombineTemplate(data, id, newCats, removedCats, schoolId, redirectToCombine, combineId, redirectToCombineDataEntry));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
