import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

const BulkDeleteModal = ({ bulkDeleteData,clearCells, onCancel }) => {
  
  const [totalCell, setTotalCell] = useState(0);

  useEffect(()=>{
    bulkDeleteData.api.getCellRanges().forEach(range => {
      const length  = range.columns.length * ((range.endRow.rowIndex - range.startRow.rowIndex) + 1);
      setTotalCell(length);
    });
  }, []);
    
  const onBulkDelete = () =>{
    bulkDeleteData.api.getCellRanges().forEach(range => {
      const colIds = range.columns.map(col => col.standardTestObjectId);
      clearCells(range.startRow.rowIndex,  range.endRow.rowIndex, colIds, bulkDeleteData);
    });
  }

  return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <CloseButton size={15} onClick={onCancel} />
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">{`Hey Coach - you are about to permanently delete ${totalCell} cells of data.`}</div>
        <div className="CloseCombineModal_Text2">
          {"Are you sure ?"}
        </div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn primary"
          type="button"
          onClick={onBulkDelete}
        >
          Delete it Forever
        </ButtonRect>
        <ButtonRect
          className="CloseCombineModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </ButtonRect>
      </div>
    </div>
  </div>
};

// BulkDeleteModal.propTypes = {
//   onCloseCombine: PropTypes.func.isRequired,
//   onCancel: PropTypes.func.isRequired,
//   combine: PropTypes.object,
// };

export default BulkDeleteModal;
