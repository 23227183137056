import axios from 'axios';
import Urls from '../../config/urls';

export const getInviteLink = async (userId, sportId, schoolId) => {
  const res = await axios.get(Urls.getInviteLink(userId, sportId, schoolId));
  return res.data.inviteURL
};

export const getEmailSection = async (schoolId) => {
  const res = await axios.get(Urls.getEmailTemplate(schoolId));
  return res.data.emailSection
};
