export const Tab = {
  STRENGTH: "strength",
  PERF: "performance",
  VC: "vc_score"
};

export const BankSlugToLabel = {
  strength: "Strength",
  performance: "Performance",
  vc_score: "VC Score",
  measureables: "Measureables",
  hitting: "Hitting",
  pitching: "Pitching"
};

export const TabLabels = {
  STRENGTH: "Strength",
  PERF: "Performance",
  VC: "VC Score",
  HITTING: "Hitting",
  PITCHING: "Pitching",
  COACHEVAL: "Coach Eval",
  STATS: "Stats"
};

export const LeaderboardViewOptions = {
  outline: "outline",
  detailed: "detailed"
};

export const scoreTypeToAttr = {
  raw: "result",
  relative: "relativeStrength",
  rated: "ratedResult",
  "raw:STRENGTH": "multiRepMax",
  "relative:STRENGTH": "relative",
  "rated:STRENGTH": "rated"
};

export const SubbankCodes = {
  BASEBALLHITTINGBLAST: "BASEBALLHITTINGBLAST",
  COACHEVAL: "COACHEVAL",
  TRACKMANHITTING: "TRACKMANHITTING",
  TRACKMANPITCHING: "TRACKMANPITCHING",
  DKSBASEBALLPITCHING: "DKSBASEBALLPITCHING",
  RAPSODOHITTING: "RAPSODOHITTING",
  RAPSODOSOFTBALLPITCHING: "RAPSODOSOFTBALLPITCHING",
  RAPSODOBASEBALLPITCHING: "RAPSODOBASEBALLPITCHING",
  DIAMONDKINETICSHITTING: "DIAMONDKINETICSHITTING",
  PORPLAYPITCHING: "PPAIPITCHING",
  HOCKEYSPECIFICDASHR: "HOCKEYSPECIFICDASHR",
  HITTRAX: "HITTRAX"
};

export const SubbankTooltip = {
  BASEBALLHITTINGBLAST: "BLAST MOTION",
  Top100SportLogo: "TOP 100 SPORTS",
  TRACKMAN: "Trackman",
  DK: "Diamond Kinetics",
  RAPSODO: "Rapsodo",
  PORPLAYPITCHING: "Pro Play AI",
  HITTRAX: "HitTrax"
};

export const BEST_IN_US_SCHOOLS = [
  "f2623eb0-7587-11e9-9e7b-17f00fc31585",
  "f018b6e0-7587-11e9-bb71-338f1484a556",
  "efa66f20-7587-11e9-9d2f-c136c159f8b0",
  "f281afe0-7587-11e9-b9d3-1307208d0233",
  "ef964890-7587-11e9-b79e-19aa50405f33",
  "f3778dc0-7587-11e9-9e66-f383cf238daf",
  "f29865b0-7587-11e9-8b8e-5d750aef7eac",
  "f0a894b0-7587-11e9-b1c1-f9da0b87150d",
  "f27da720-7587-11e9-a135-1dbbc7d8be45",
  "f042e2f0-7587-11e9-bdcb-611283fbbb84",
  "f8b41440-7587-11e9-a8b4-eb70f06c9950",
  "f2853de0-7587-11e9-8956-959b00ce822c",
  "f00d6310-7587-11e9-b9b5-ed35c4fe136c",
  "f0560560-7587-11e9-ac36-f7fb773c4cea",
  "f29490a0-7587-11e9-9e3a-d73b15de0096",
  "f0a47d90-7587-11e9-99c0-7fc3b5007fd2",
  "f5ac6f00-7587-11e9-b4ef-1117c35cd4c7",
  "f0c38880-7587-11e9-a45c-6bb5ff6a4445",
  "f134bef0-7587-11e9-bc1f-8d7b7f878959",
  "f5ba1ce0-7587-11e9-8578-35a3ab0479c8",
  "f26dbae0-7587-11e9-b6d7-7b52c1bdee3b",
  "f6b01de0-7587-11e9-a769-85c1fa1f0577",
  "f0c72fb0-7587-11e9-b950-cb6d6848a0a1",
  "f26a2620-7587-11e9-9b85-c7949e23039b",
  "f0911700-7587-11e9-9d27-63b14de38121",
  "f8b86980-7587-11e9-8b73-d10953cd26b5",
  "f5590580-7587-11e9-ac74-8140c8b274fc",
  "f171d680-7587-11e9-8201-e76d124065d0",
  "f5512720-7587-11e9-937f-7d1f131a7ae0",
  "f593bc20-7587-11e9-a600-0b99e5a132a3",
  "f55c8340-7587-11e9-aaf7-55bdb7322eab",
  "f5554780-7587-11e9-949a-29fd8853bbfe"
];


export const TEXAS_PREMIER_SCHOOL = [
  "386a06e0-758a-11e9-b57b-ab5b591d14f6",
  "faaa2e40-7587-11e9-9db5-d778ff67b429",
  "f25ec4f0-7587-11e9-b917-39c29ccc9b53",
  "f68a0120-7587-11e9-b821-b52e181d5b47",
];

export const SPECTS_ALLTIME_SCHOOLS = [
  "fa8c2bd0-7587-11e9-a22e-7d1b17fd6ec3",
  "f25ad440-7587-11e9-894d-d3e36dd2c4cc",
  "fb6e40d0-7587-11e9-8aaf-15e06582130f",
];

export const DISTRICT_LEADERBOARD_COACHES = [
  'f8d16c2d-1c1d-4a3a-af30-2898eff372fe',
  'e6d0103b-9790-4c88-8b6d-70bbcf7da5b4',
  'e476699d-c321-4f8e-9346-92626353492a',
  '08dee0d0-9ab2-4e63-b2ce-a955917e05dd',
];
