import { PHOTO_GET_MEDIA_SUC, VIDEO_UPLOAD_SUC, ALBUM_CREATE_SUC, ALBUM_UPDATE_SUC, VIDEO_RESIZE_SUC, VIDEO_UPDATE_SUC, MARK_VIDEO_FAVORITE_SUC, VIDEO_DELETE_SUC, MULTI_DELETE_SUC, DELETE_ALBUM_EVERYTHING_SUC } from '../../../actions/data/photo';
import { ASYNC_ERROR, ASYNC_FINISH } from '../../../actions/ui/async';
import { UPDATE_ALBUM, CREATE_ALBUM, UPDATE_ATHLETE_VIDEOID } from '../../../actions/ui/uxProfile/photosVideos/actions';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../../actions/data/cognito';

const videos = (state = {}, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === UPDATE_ATHLETE_VIDEOID){
      return Object.assign({}, state, {
        [action.data]: [],
      });
    }
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.videos,
      });
    case VIDEO_UPLOAD_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [...state[action.uuid], action.video],
      });
    case ALBUM_CREATE_SUC:
      if (action.videos.length > 0 && action.uuid !== '') {
        return Object.assign({}, state, {
          [action.uuid]: [...state[action.uuid], ...action.videos],
        });
      }
      return state;
    case ALBUM_UPDATE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(video =>
            (action.videos.find(v => v.id !== video.id))),
          ...action.videos,
        ],
      });
    case VIDEO_RESIZE_SUC:
    case VIDEO_UPDATE_SUC:
    case MARK_VIDEO_FAVORITE_SUC:
      if (action.uuid !== '') {
        return Object.assign({}, state, {
          [action.uuid]: [
            ...state[action.uuid].filter(video => (video.id !== action.video.id)),
            action.video,
          ],
        });
      }
      return state;
    case VIDEO_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(video => (video.id !== action.mediaId)),
      });
    case MULTI_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(video => (action.ids.indexOf(video.id) < 0)),
      });
    case DELETE_ALBUM_EVERYTHING_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(video => (action.videoIds.indexOf(video.id) < 0)),
      });
    case ASYNC_ERROR:
      if ((action.model === UPDATE_ALBUM || action.model === CREATE_ALBUM) &&
          (action.desc === 'pollResized')) {
        const tempObject = Object.assign(
          {}, state[action.data.uuid].find(video => (video.id === action.data.photoId)),
          { isTimedOut: true },
        );
        return Object.assign({}, state, {
          [action.data.uuid]: [
            ...state[action.data.uuid].filter(video => (video.id !== action.data.photoId)),
            tempObject,
          ],
        });
      }
      return state;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default videos;
