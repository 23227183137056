import { connect } from 'react-redux';
import Component from './Banner.component';
import { selectIsCoachOrAthlete, selectSchoolStyle, selectCoachOrAthleteSchoolIds } from '../../+store';
import { schoolStyle } from '../../+store/nationalLeaderboard/nationalLeaderboard.constants';

const mapStateToProps = (state, ownProps) => ({
  isCoachOrAthlete: selectIsCoachOrAthlete(state),
  authenticatedUuid: state.data.cognito.uuid,
  schoolStyle,
  coachSchoolIds: selectCoachOrAthleteSchoolIds(state, ownProps),
});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
