import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import vcWhiteLogo from '../../../../assets/images/vc-white.png';
import vcBlackLogo from '../../../../assets/images/vc-black.png';
import { Routes } from '../../../../store/actions/ui/routes';
import TwoToneButton from '../../common/TwoToneButton';
import { ShareModal } from '../../../../components/UxCommon';

class Banner extends PureComponent {
  static propTypes = {
    isCoachOrAthlete: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    authenticatedUuid: PropTypes.string,
    schoolStyle: PropTypes.object,
    match: PropTypes.object.isRequired,
    customClass: PropTypes.any,
  };

  state = {
    shareUri: '',
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onPrintClick = () => {
    console.log('Print');
  };

  onShareClick = () => {
    const { match, location } = this.props;
    this.setState({
      shareUri: `${match.url}${location.search}`,
    });
  };

  onCloseShare = () => {
    this.setState({ shareUri: '' });
  };

  shouldUpdate = () => {};

  render() {
    const {
      isCoachOrAthlete,
      authenticatedUuid,
      schoolStyle,
      customClass,
    } = this.props;
    const { shareUri } = this.state;

    let dashboardLink;
    if (
      isCoachOrAthlete &&
      isCoachOrAthlete.status &&
      isCoachOrAthlete.userType === 'coach'
    ) {
      dashboardLink = `/${Routes.coach}/${Routes.dashboard}`;
    } else {
      dashboardLink = `/${Routes.profile}/${authenticatedUuid}`;
    }
    let accountLink;
    if (
      isCoachOrAthlete &&
      isCoachOrAthlete.status &&
      isCoachOrAthlete.userType === 'coach'
    ) {
      accountLink = `/${Routes.coach}/${Routes.account}`;
    } else {
      accountLink = `/${Routes.profile}/${authenticatedUuid}/${Routes.edit}`;
    }
    let logo;
    let logoBg;
    if (schoolStyle && schoolStyle.leaderboardStyle === 'dark') {
      logo = vcBlackLogo;
      logoBg = 'white';
    } else {
      logo = vcWhiteLogo;
      logoBg = '#171717';
    }
    return (
      <div className="LeaderboardBanner">
        <ShareModal uri={shareUri} onClose={this.onCloseShare} />
        <div className="LeaderboardBanner_TopRow">
          <div
            className="LeaderboardBanner_VCLogoContainer"
            style={{ background: logoBg }}>
            <img
              className="LeaderboardBanner_VCLogo"
              src={logo}
              alt="BACKGRO0ND"
            />
          </div>
          <div className="LeaderboardBanner_UpperRight">
            <div className="LeaderboardBanner_Links">
              {authenticatedUuid ? (
                <React.Fragment>
                  <Link className="LeaderboardBanner_Link" to={dashboardLink}>
                    Dashboard
                  </Link>
                  <div className="LeaderboardBanner_Line" />
                  <Link className="LeaderboardBanner_Link" to={accountLink}>
                    My Account
                  </Link>
                </React.Fragment>
              ) : (
                <Link
                  className="LeaderboardBanner_Link"
                  to={`/${Routes.welcome}/${Routes.signup}`}>
                  Sign Up
                </Link>
              )}
            </div>
            <TwoToneButton
              className="share"
              onClick={this.onShareClick}
              text="Share"
              iconName="share"
            />
          </div>
        </div>
        <div
          className={`LeaderboardBanner_LogoWrapper ${
            customClass ? customClass : ''
          }`}>
          {schoolStyle && schoolStyle.districtSocialMediaLogo && (
            <img
              className="LeaderboardBanner_Logo"
              src={schoolStyle.districtSocialMediaLogo}
              alt={schoolStyle.name}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Banner;
