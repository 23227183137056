import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AthleteRowMedia from './AthleteRowMedia.component';
import { AthleteGradesTh } from '../../../+store';
import { Routes, Sports } from '../../../../../store/actions/ui/routes';
import {formatPositionResponse} from '../../../../../store/actions/ui/formUtils/validators';

class AthleteRow extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  }

  render() {
    const { user,sportId,schoolId} = this.props;
    if (user) {
      const athletePositionJersey = [user.position, user.jerseyNumber && user.jerseyNumber.trim() ? `#${user.jerseyNumber.trim()}` : null].filter(n => !!n).join(' | ');
      return (
        <Link className="AthleteRowLink" to={`/${Routes.profile}/${user.userId}/${Routes.awards}/${Sports[sportId]}/${schoolId}`}>
          <div className="AthleteRow">
            <AthleteRowMedia athlete={user} />
            <div className="CombineAward_Title_Text">
              {`${user.first} ${user.last}`}
            </div>
            {
              (user.gradyear &&
                <div className="CombineAward_Title_Grade">
                  {user.gradyear}
                </div>
              )
            }
            {
              !!athletePositionJersey && (
                <div className="CombineAward_Position">
                  {formatPositionResponse(athletePositionJersey)}
                </div>
              )
            }
          </div>
        </Link>
      );
    }
    return null;
  }
}

export default AthleteRow;
