import { combineEpics, ofType } from 'redux-observable';
import {mergeMap, switchMap} from 'rxjs/operators';
import { from, of } from 'rxjs';

import { asyncFinishAction } from '../../../../store/actions/ui/async';
import {getTokenFragment, manualCancelApiCallFragment} from '../../../../store/actions/ui/uxProfile/utils';
import {CHECK_EMAIL_EXISTS, CREATE_ACCOUNT} from './accountPage.actions';
import { cognitoSignIn, cognitoSignInSuccess, cognitoSignup } from '../../../../store/actions/data/cognito';
import { mapUserApiToUi, mapUserUiToApi } from '../../../../store/actions/data/user/profile/models';
import { profileCreate, profileCreateSuccess } from '../../../../store/actions/data/user';
import {checkEmailExistsApi, registerCode} from './accountPage.api';
import { makeCognitoUsername } from '../common/common.utils';
import {DELETE_BULK_TEST_COMBINE} from "../../../CoachWorld/+store/combine";
import {bulkTestDelete} from "../../../CoachWorld/+store/combine/combine.api";

const createAccountEpic = action$ => (
  action$.pipe(
    ofType(CREATE_ACCOUNT),
    switchMap(action => (
      from(cognitoSignup({
        username: action.fields.username.toLowerCase(),
        password: action.fields.password,
      }, {
        'custom:code': action.code,
      })).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'codeSignUpCreateAccount',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return profileCreate(mapUserUiToApi(
          result.response.userSub,
          result.action.fields,
          false,
          true,
        )).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'codeSignUpCreateAccount',
          { cognitoSignUpResponse: result.response },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return from(cognitoSignIn(
          makeCognitoUsername(result.action.fields.username.toLowerCase()),
          result.action.fields.password,
        )).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'codeSignUpCreateAccount',
          {
            cognitoSignUpResponse: result.cognitoSignUpResponse,
            userServiceResponse: result.response,
          },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const token = result.response.signInUserSession.accessToken.jwtToken;
        return registerCode(token, {
          code: result.action.code,
        }).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'codeSignUpRegisterCode',
          {
            cognitoSignUpResponse: result.cognitoSignUpResponse,
            userServiceResponse: result.userServiceResponse,
            cognitoSignInResponse: result.response,
          },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
          profileCreateSuccess(mapUserApiToUi(result.userServiceResponse)),
          cognitoSignInSuccess(result.cognitoSignInResponse),
          asyncFinishAction(result.action.type, 'codeSignUpCreateAccount', {
            uuid: result.cognitoSignUpResponse.userSub,
            code: result.action.code,
            username: result.action.fields.username.toLowerCase(),
            password: result.action.fields.password,
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const checkEmailExistsEpic = action$ => (
  action$.pipe(
    ofType(CHECK_EMAIL_EXISTS),
    getTokenFragment(),
    mergeMap(({action, token}) => (
      checkEmailExistsApi(action.email, token)
        .pipe(manualCancelApiCallFragment(
          action$,
          action,
          'deleteBulkTestCombine',
        ))
    )),
    switchMap((result) => {
        return of(
          asyncFinishAction(CHECK_EMAIL_EXISTS, CHECK_EMAIL_EXISTS, {
            exists: result.success,
          }),
        );
    }),
  )
);

export default combineEpics(
  createAccountEpic,
  checkEmailExistsEpic
);
