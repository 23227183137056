import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PhotoCard from '../../../../containers/UxProfile/Photos/PhotoCard';
import AddNewCard from './AddNewCard/AddNewCard';
import ExpandedPhotoDialog from '../../../../containers/UxProfile/Photos/ExpandedPhotoDialog';
import SelectAlbumDialog from '../../../../containers/UxProfile/Photos/SelectAlbumDialog';
import TagDialog from '../../../../containers/UxProfile/Photos/TagDialog';
import { FavoritesDialog, GetWindowSize, ButtonRect } from '../../../UxCommon';

class PhotoVideoList extends PureComponent {
  static propTypes = {
    photosVideos: PropTypes.array,
    onAddPhotoVideoClick: PropTypes.func.isRequired,
    photoList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    selectAlbumDialogIsShowing: PropTypes.bool.isRequired,
    expandedDialogIsOpen: PropTypes.bool.isRequired,
    tagDialogIsShowing: PropTypes.bool.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    mySports: PropTypes.array.isRequired,
    onSaveFavorites: PropTypes.func.isRequired,
    getPhotoVideoFavorites: PropTypes.func.isRequired,
    isFilterApplied: PropTypes.bool.isRequired,
    onClearAllFiltersClick: PropTypes.func.isRequired,
    isTutorials: PropTypes.bool,
  };

  state = {
    favoriteDialogEditing: null,
    windowWidth: 10000,
  }

  componentDidMount() {
  }

  onFavoriteClick = (media) => {
    const { mySports, onSaveFavorites } = this.props;
    if (mySports.length === 1) {
      onSaveFavorites(media, { [mySports[0].id]: true });
    }
    this.setState({
      favoriteDialogEditing: media,
    });
  }

  onCloseFavoritesDialog = () => {
    this.setState({
      favoriteDialogEditing: null,
    });
  }

  onSaveFavorites = (media, favoriteSports) => {
    const { onSaveFavorites } = this.props;
    this.setState({
      favoriteDialogEditing: null,
    });
    onSaveFavorites(media, favoriteSports);
  }

  setWindowWidth = (width) => {
    this.setState({
      windowWidth: width,
    });
  }

  isAPhoto = photoVideo => photoVideo.constructor.name === 'Photo';

  renderExpandedPhotoDialog = (props) => {
    const { photoList, isTutorials } = this.props;
    return (
      <ExpandedPhotoDialog photoList={photoList} {...props} isTutorials={isTutorials} />
    );
  };

  render() {
    const {
      photosVideos,
      onAddPhotoVideoClick,
      match,
      selectAlbumDialogIsShowing,
      tagDialogIsShowing,
      isMyProfile,
      getPhotoVideoFavorites,
      mySports,
      isFilterApplied,
      onClearAllFiltersClick,
    } = this.props;
    const { favoriteDialogEditing, windowWidth } = this.state;
    return (
      <div className="Photos_PhotoVideoList">
        <GetWindowSize getWindowX={this.setWindowWidth} />
        {
          selectAlbumDialogIsShowing && (
            <SelectAlbumDialog match={match} />
          )
        }
        {
          tagDialogIsShowing && (
            <TagDialog match={match} />
          )
        }
        {
          favoriteDialogEditing && (
            <FavoritesDialog
              award={favoriteDialogEditing}
              favorites={getPhotoVideoFavorites(favoriteDialogEditing)}
              mySports={mySports}
              onSave={this.onSaveFavorites}
              onCancel={this.onCloseFavoritesDialog}
            />
          )
        }
        {
          (!photosVideos || !photosVideos.length) ?
            <div>No Photos or Videos Found.</div> :
            photosVideos.map(photoVideo => (
              <PhotoCard
                key={photoVideo.id}
                media={photoVideo}
                match={match}
                onFavoriteClick={this.onFavoriteClick}
                windowWidth={windowWidth}
              />
          ))
        }
        {
          isMyProfile && (
            <AddNewCard
              onClick={onAddPhotoVideoClick}
              iconName="plus"
              text="Add photo or video"
              className="hidden-md-down"
            />
          )
        }
        {
          isFilterApplied && (
            <div className="Photos_PhotoVideoList_Btns">
              <ButtonRect
                className="Photos_PhotoVideoList_Btn"
                onClick={onClearAllFiltersClick}
              >
                Clear All Filters
              </ButtonRect>
            </div>
          )
        }
      </div>
    );
  }
}

export default PhotoVideoList;
