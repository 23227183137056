import { combineEpics , ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import {GET_EXTERNAL_PROFILE} from "../actions";
import { getTokenFragment} from "../../utils";
import {getExternalProfile} from "../../../../data/user/externalProfile";
import {cancelOnRouteChange} from "../../../uxEditProfile/utils";
import {Routes} from "../../../routes";
import {of} from "rxjs";
import {asyncErrorAction, asyncFinishAction} from "../../../async";
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { externalProfileSuccess } from '../../../../data/user/externalProfile/actions'

const getExternalProfileEpic = action$ => (
  action$.pipe(
    ofType(GET_EXTERNAL_PROFILE),
    getTokenFragment(),
    switchMap( ({ action, token }) =>
        getExternalProfile(action.uuid, token)
          .pipe(
            // map( response => ( { action, success: true, externalProfile: mapExternalProfileToUi(response) })),
            map( response => ( { action, success: true, externalProfile: response })),
            takeUntil( cancelOnRouteChange( action$, Routes.externalProfile, () => action.uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError( error => {
             return  of( {
              success: false,
              action:  asyncErrorAction(action.type, 'getFavoriteAwards', error),
            })
            }),
            // commonApiCallFragment( action$, action, 'getExternalProfile', Routes.externalProfile)
          )),
    switchMap( (result) => {
      if (result.success) {
        return of (
          externalProfileSuccess(result.externalProfile, result.action.uuid),
          asyncFinishAction(result.action.type, 'getExternalProfile', {}),

        )


      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


const readEpics = combineEpics(
  getExternalProfileEpic,
);

export default readEpics;
