import {connect} from 'react-redux';
import Component from './ProspectSheet.component';
import {getRoster, getSchoolStyles, selectMySchoolIds, updateUserTeamInfo} from "../CoachWorld/+store";
import {playerProfileGet} from "../../store/actions/ui/uxProfile";
import { getAthleteProfile, getPlayerData, uploadTranscript, deleteTranscript, getTranscript } from './+store/prospectSheet';
import { getAllExternalProfile } from "../../store/actions/ui/uxProfile/externalProfile";
import { saveUserSport } from '../../store/actions/ui/uxEditProfile';

const mapStateToProps = (state, ownProps) => {
  const {uuid} = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  const athletes = state.modules.coachWorld.roster.athletes;
  const currentAthlete = athletes.find(athlete => athlete.userId === ownProps.match.params.athleteId);

  return {
    authenticating: state.data.cognito.authenticating,
    uuid,
    currentAthlete: currentAthlete || {},
    profile: state.modules.prospectSheet.prospectSheet.profile,
    personalInfo: state.modules.prospectSheet.prospectSheet.personalInfo,
    schoolTeam: state.modules.prospectSheet.prospectSheet.schoolTeam,
    personalRecords: state.modules.prospectSheet.prospectSheet.personalRecords,
    schoolIds: selectMySchoolIds(state, ownProps),
    currentSportId: state.modules.prospectSheet.prospectSheet.schoolTeam.sportId,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle[0],
    externalProfiles : state.data.user.externalProfile[ownProps.match.params.athleteId] || [],
    videos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    loadingProspect: state.modules.coachWorld.roster.savingProfiles || 
                    state.ui.uxEditProfile.editSports.loadingUserSports ||
                    state.modules.prospectSheet.prospectSheet.loadingTranscript,
    transcript: state.modules.prospectSheet.prospectSheet.transcript,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, {dispatch}, ownProps) => {
  const {state, ...newStateProps} = stateProps;
  
  return {
    ...newStateProps,
    ...ownProps,
  getAthleteProfile: (teamId, athleteId) => {
    dispatch(getAthleteProfile(teamId, athleteId))
  },
  getRoster: (teamId, athleteId) => {
    dispatch(getRoster(teamId, athleteId, null))
  },
  playerData: (uuid, sport) => {
    dispatch(getPlayerData(uuid, sport));
  },
  getSchoolStyles: (schoolIds) => {
    dispatch(getSchoolStyles(schoolIds));
  },
  getExternalProfile: (uuid) => {
    dispatch( getAllExternalProfile(uuid) )
  },
  saveUserTeam : (value, userTeamId, userId, schoolId) => {
    dispatch( updateUserTeamInfo(
      userId,
      userTeamId,
      value,
      ownProps.match,
      schoolId,
      ownProps.location,
      ))
  },
  saveUserSport : ( userSport, profile ) => {
    dispatch( saveUserSport( userSport, profile ));
  },
  getTranscript: (sportId, athleteId) => {
    dispatch( getTranscript(sportId, athleteId));
  },
  saveTranscript: ( sportId, athleteId, file ) => {
    dispatch( uploadTranscript(sportId, athleteId, file) );
  },
  deleteTranscript: (athleteId, sportId, transcriptId) => {
    dispatch(deleteTranscript(athleteId, sportId, transcriptId));
  }
  }
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default Container;
