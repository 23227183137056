import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PhotosIconButton } from '../../../../UxCommon';
import AlbumButton from './AlbumButton/AlbumButton';

class Albums extends PureComponent {
  static propTypes = {
    onEditClick: PropTypes.func.isRequired,
    albums: PropTypes.array.isRequired,
    onAlbumSelected: PropTypes.func.isRequired,
    onAlbumUnselected: PropTypes.func.isRequired,
    selectedAlbums: PropTypes.array.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      onEditClick,
      albums,
      selectedAlbums,
      onAlbumSelected,
      onAlbumUnselected,
      isMyProfile,
    } = this.props;
    return (
      <div className="UxProfile_Photos_SideBar_Albums">
        <div className="UxProfile_Photos_SideBar_Albums_Top">
          <div className="UxProfile_Photos_SideBar_Albums_Txt">
            Albums
          </div>
          {
            isMyProfile && (
              <PhotosIconButton className="hidden-md-down" iconName="edit" onClick={onEditClick} />
            )
          }
        </div>
        <div className="UxProfile_Photos_SideBar_Albums_AlbumBtns">
          {
            albums.map((album) => {
              const selected = (selectedAlbums.indexOf(album.id) >= 0);
              return (
                <AlbumButton
                  key={album.id}
                  album={album}
                  onClick={selected ? onAlbumUnselected : onAlbumSelected}
                  selected={selected}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default Albums;
