import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';
import { Routes, Sports } from '../../../../../store/actions/ui/routes';
import Icon from '../../../../UxCommon/Icon/Icon';
import {ButtonRect, CloseButton} from "../../../../UxCommon";
import SignOutModal from "../../../../../modules/CoachWorld/Dashboard/SignOutModal";
import {Tooltip} from "../../../../../store/actions/ui/tooltip";

class NavLink extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isMenuShown: PropTypes.bool.isRequired,
    page: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    txt: PropTypes.string.isRequired,
    isSearchFocused: PropTypes.bool.isRequired,
    submenu: PropTypes.bool,
    nudge: PropTypes.object,
    wrapperStyle: PropTypes.object,
    schoolId: PropTypes.any,
    tooltip: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  componentDidMount() {
  }

  checkSport = () => {
    const { match } = this.props;
    if (Object.values(Sports)
      .indexOf(match.params.sport) >= 0) {
      return match.params.sport;
    }
    return '';
  };

  render() {
    const {
      match,
      page,
      txt,
      isMenuShown,
      isSearchFocused,
      submenu,
      iconName,
      nudge,
      wrapperStyle,
      schoolId,
      tooltip,
      sportId,
      coachSelectedSchool,
      isDisabled,
      ...other
    } = this.props;
    const sportUrl = this.checkSport();
    const toUrl = (page === Routes.leaderboard || page === Routes.collegeContact)
                  ? `/${Routes.athlete}/dashboard/${schoolId}/${page}`
                  : `/${Routes.profile}/${match.params.uuid}/${page}${sportUrl? (coachSelectedSchool && coachSelectedSchool != 'profile-photo') ?  `/${sportUrl}/${coachSelectedSchool}` : `/${sportUrl}`
                    : `${sportId? `/${Sports[sportId]}`: ''}${coachSelectedSchool && coachSelectedSchool != 'profile-photo' ? `/${coachSelectedSchool}` : ''}`}`;
    return (
      page !== 'Training-video' ?
        page === 'levelUp' ?
         <div>
           <a
             href="#"
             {...other}
             className={[
               'UxProfile_SidebarNav_NavLink levelup_badge_sidebar',
               ((match.params.page === page) && (!isSearchFocused)) ? 'selected' : '',
               isMenuShown ? 'open' : '',
               isSearchFocused ? 'hidden' : '',
               submenu ? 'submenu' : '',
               isDisabled ? 'Menu-Disabled' : '',
             ].join(' ')}>
             <div className="UxProfile_SidebarNav_IconWrapper" style={wrapperStyle || {}}>
               <Icon
                 iconName={iconName}
                 className="UxProfile_SidebarNav_IconIcon"
                 style={nudge || {}}
                 tooltip={tooltip}
               />
               {/*{!isMenuShown && <span className="badge badge-danger">1</span>}*/}
             </div>
             <div className="UxProfile_SidebarNav_IconTxt UxProfile_levelUp">
               <p className="m-0 position-relative">{txt}</p>
               <span className="badge badge-danger">1</span>
             </div>
           </a>
         </div>
          :
      <Link
        className={[
          'UxProfile_SidebarNav_NavLink',
          ((match.params.page === page) && (!isSearchFocused)) ? 'selected' : '',
          isMenuShown ? 'open' : '',
          isSearchFocused ? 'hidden' : '',
          submenu ? 'submenu' : '',
          isDisabled ? 'Menu-Disabled' : '',
        ].join(' ')}
        to={toUrl}
        {...other}
      >
        <div className="UxProfile_SidebarNav_IconWrapper" style={wrapperStyle || {}}>
          <Icon
            iconName={iconName}
            className="UxProfile_SidebarNav_IconIcon"
            style={nudge || {}}
            tooltip={tooltip}
          />
        </div>
        <div className="UxProfile_SidebarNav_IconTxt">
          {txt}
        </div>
      </Link>
      :
        <a href="https://www.youtube.com/channel/UCX_XKq-0IPsXNPv9s7fQ2Qw/playlists?view=1&sort=dd"
          target="_blank"
          className={[
            'UxProfile_SidebarNav_NavLink',
            ((match.params.page === page) && (!isSearchFocused)) ? 'selected' : '',
            isMenuShown ? 'open' : '',
            isSearchFocused ? 'hidden' : '',
            submenu ? 'submenu' : '',
            isDisabled ? 'Menu-Disabled' : '',
          ].join(' ')}>
            <div className="UxProfile_SidebarNav_IconWrapper" style={wrapperStyle || {}}>
              <Icon
                iconName={iconName}
                className="UxProfile_SidebarNav_IconIcon"
                style={nudge || {}}
                tooltip={tooltip}
              />
            </div>
            <div className="UxProfile_SidebarNav_IconTxt">
              {txt}
            </div>
        </a>
    );
  }
}

export default NavLink;
