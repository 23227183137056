import { Roles } from '../../../actions/data/user/profile/models';

const initialUserSportForm = {
  id: '',
  sportId: 0,
  aboutMe: '',
  bio: '',
  commits: '',
  offers: '',
  position: '',
  positionArray: [],
  jerseyNumbers: '',
  photoUrl: '',
  bats: '',
  throws: '',
  primary: true,
};

const initialAccountForm = {
  first: '',
  last: '',
  email: '',
  phone: '',
  birthdate: null,
  gradYear: '',
  grad_year: '',
  grade: '',
  parentsName: '',
  parentsEmail: '',
  parentsPhone: '',
  parent2Name: '',
  parent2Email: '',
  parents2Phone: '',
  gender: '',
  heightFt: '',
  heightMt: '',
  heightInch: '',
  heightCm: '',
  height: '',
  weight: '',
  weightKg: '',
  school: '',
  act: '',
  sat: '',
  coreGpa: '',
  classRank: '',
  classSize: '',
  schoolCredits: '',
  ncaaRegistered: false,
  roleId: Roles.athlete,
  city: '',
  state: '',
  zip: '',
  ncaaIdNumber: '',
};

const changePasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const confirmEmailPhoneForm = {
  code: '',
};

const vanityUrlForm = {
  url: '',
};

const twitterHandleForm = {
  url: '@',
};

const forms = {
  userSport: initialUserSportForm,
  account: initialAccountForm,
  changePassword: changePasswordForm,
  confirmEmailPhone: confirmEmailPhoneForm,
  vanityUrl: vanityUrlForm,
  twitterHandle: twitterHandleForm,
};

export default forms;
