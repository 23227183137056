import { Auth } from 'aws-amplify';
import Urls from '../../../../config/urls';
import promiseTimeout from '../../../../utils/promise-timeout';
import { mapUserApiToUi, mapUserUiToApi } from '../user/profile/models';
import { validateEmail, validatePhone } from '../../ui/formUtils/validators';
import { emailToUsername, formatPhoneForCognito, phoneToUsername } from '../../../../utils/cognito';

export const COGNITO_SIGN_UP_SUC = 'cognito.Auth.signUpSuccess';
export const cognitoSignUpSuccess = awsResponse => ({
  type: COGNITO_SIGN_UP_SUC,
  awsResponse,
});

export const cognitoSignup = (user, customAttributes = {}) => {
  const signUpData = {
    password: user.password,
    attributes: { ...customAttributes },
    validationData: [],
  };
  if (user.username && validatePhone()(user.username).phone === '') {
    const phone = formatPhoneForCognito(user.username);
    signUpData.username = phoneToUsername(phone);
    signUpData.attributes.phone_number = phone;
  } else if (user.username && validateEmail()(user.username).email === '') {
    signUpData.username = emailToUsername(user.username);
    signUpData.attributes.email = user.username;
  }
  return Auth.signUp(signUpData);
};

export const COGNITO_CONFIRM_SIGNUP_SUC = 'cognito.Auth.confirmSignUpSuccess';
export const cognitoConfirmSignUpSuccess = awsResponse => ({
  type: COGNITO_CONFIRM_SIGNUP_SUC,
  awsResponse,
});

export const cognitoConfirmSignUp = (username, code) => {
  let cognitoUsername;
  if (validatePhone()(username).phone === '') {
    cognitoUsername = phoneToUsername(formatPhoneForCognito(username));
  } else {
    cognitoUsername = emailToUsername(username);
  }
  return Auth.confirmSignUp(cognitoUsername, code);
};

export const AUTH2_RESEND_CODE_REQ = 'AUTH_RESEND_CODE_REQ';
const resendCodeRequest = msg => ({
  type: AUTH2_RESEND_CODE_REQ,
  msg,
});
export const AUTH2_RESEND_CODE_SUC = 'AUTH2_RESEND_CODE_SUC';
const resendCodeSuccess = action => ({
  type: AUTH2_RESEND_CODE_SUC,
  action,
});
export const AUTH2_RESEND_CODE_ERR = 'AUTH2_RESEND_CODE_ERR';
const resendCodeError = error => ({
  type: AUTH2_RESEND_CODE_ERR,
  error,
});

export const resendCode = username => (dispatch) => {
  dispatch(resendCodeRequest('Resending confirmation code.'));
  let cognitoUsername;
  if (validatePhone()(username).phone === '') {
    cognitoUsername = phoneToUsername(formatPhoneForCognito(username));
  } else {
    cognitoUsername = emailToUsername(username);
  }
  Auth.resendSignUp(cognitoUsername)
    .then((data) => {
      dispatch(resendCodeSuccess(data));
    })
    .catch((err) => {
      dispatch(resendCodeError(err));
    });
};

export const AUTH2_CURRENT_USER_REQ = 'AUTH2_CURRENT_USER_REQ';
const currentUserRequest = msg => ({
  type: AUTH2_CURRENT_USER_REQ,
  msg,
});
export const AUTH2_CURRENT_USER_SUC = 'AUTH2_CURRENT_USER_SUC';
const currentUserSuccess = awsResponse => ({
  type: AUTH2_CURRENT_USER_SUC,
  awsResponse,
});
export const AUTH2_CURRENT_USER_AWS_ERR = 'AUTH2_CURRENT_USER_AWS_ERR';
const currentUserAwsError = error => ({
  type: AUTH2_CURRENT_USER_AWS_ERR,
  error,
});

export const getCurrentUser = dispatch => () => {
  dispatch(currentUserRequest('Authenticating'));
  promiseTimeout(
    10000,
    Auth.currentAuthenticatedUser(),
  )
    .then((data) => {
      dispatch(currentUserSuccess(data));
    })
    .catch((err) => {
      dispatch(currentUserAwsError(err));
    });
};

export const cognitoGetCurrent = () => Auth.currentAuthenticatedUser();

export const COGNITO_RENEW_SUC = 'cognito.auth.renewSuccess';
export const cognitoRenewSuccess = cognitoUser => ({
  type: COGNITO_RENEW_SUC,
  cognitoUser,
});

export const COGNITO_SIGN_IN_SUC = 'cognito.auth.signInSuccess';
export const cognitoSignInSuccess = cognitoUser => ({
  type: COGNITO_SIGN_IN_SUC,
  cognitoUser,
});
export const cognitoSignIn = (username, password) => {
  let cognitoUsername = username;
  if (validatePhone()(username).phone === '') {
    cognitoUsername = formatPhoneForCognito(username);
  }
  return Auth.signIn(cognitoUsername, password);
};

export const AUTH2_LOGOUT_REQ = 'AUTH2_LOGOUT_REQ';
const logoutRequest = msg => ({
  type: AUTH2_LOGOUT_REQ,
  msg,
});
export const AUTH2_LOGOUT_SUC = 'AUTH2_LOGOUT_SUC';
const logoutSuccess = () => ({
  type: AUTH2_LOGOUT_SUC,
});
export const AUTH2_LOGOUT_SUC_WITH_REDIRECT = 'AUTH2_LOGOUT_SUC_WITH_REDIRECT';
const logoutSuccessWithRedirect = redirectURL => ({
  type: AUTH2_LOGOUT_SUC_WITH_REDIRECT,
  redirectURL,
});
export const AUTH2_LOGOUT_ERR = 'AUTH2_LOGOUT_ERR';
const logoutError = error => ({
  type: AUTH2_LOGOUT_ERR,
  error,
});

export const logout = () => async (dispatch) => {
  dispatch(logoutRequest('Signing Out'));
  await Auth.signOut()
    .then(() => {
      dispatch(logoutSuccess());
    })
    .catch((err) => {
      dispatch(logoutError(err));
    });
  dispatch(logoutSuccess());
};

export const logoutWithRedirect = redirectURL => async (dispatch) => {
  await Auth.signOut()
    .then(() => {
      dispatch(logoutSuccessWithRedirect(redirectURL));
    })
    .catch((err) => {
      dispatch(logoutError(err));
    });
};

export const cognitoSignOut = () => Auth.signOut();

export const COGNITO_FORGOT_PW_SUC = 'cognito.auth.forgotPassSuccess';
export const cognitoForgotPasswordSuccess = data => ({
  type: COGNITO_FORGOT_PW_SUC,
  data,
});

export const cognitoForgotPassword = (username) => {
  let cognitoUsername = username;
  if (validatePhone()(username).phone === '') {
    cognitoUsername = username.replace(/[ ()-]/g, '');
  }
  return Auth.forgotPassword(cognitoUsername);
};
export const COGNITO_RESET_PW_SUC = 'cognito.Auth.resetPassSuccess';
export const cognitoResetPassSuccess = awsResponse => ({
  type: COGNITO_RESET_PW_SUC,
  awsResponse,
});

export const cognitoResetPass = (uname, code, password) => {
  let username = uname;
  if (username && validatePhone()(username).phone === '') {
    username = formatPhoneForCognito(username);
  }
  return Auth.forgotPasswordSubmit(username, code, password);
};

export const AUTH2_SAVE_PROFILE_REQ = 'AUTH2_SAVE_PROFILE_REQ';
const saveProfileRequest = msg => ({
  type: AUTH2_SAVE_PROFILE_REQ,
  msg,
});
export const AUTH2_SAVE_PROFILE_SUC = 'AUTH2_SAVE_PROFILE_SUC';
const saveProfileSuccess = (awsResponse, usResponse) => ({
  type: AUTH2_SAVE_PROFILE_SUC,
  awsResponse,
  usResponse,
});
export const AUTH2_SAVE_PROFILE_US_ERR = 'AUTH2_SAVE_PROFILE_US_ERR';
const saveProfileUsError = error => ({
  type: AUTH2_SAVE_PROFILE_US_ERR,
  error,
});
export const AUTH2_SAVE_PROFILE_AWS_ERR = 'AUTH2_SAVE_PROFILE_AWS_ERR';
const saveProfileAwsError = error => ({
  type: AUTH2_SAVE_PROFILE_AWS_ERR,
  error,
});

const userServiceUpdate = (uuid, data, token) => fetch(
  Urls.profile(uuid),
  {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  },
);

export const saveProfile = (cognitoUser, uuid, form) => (dispatch) => {
  dispatch(saveProfileRequest('Saving Profile ..'));
  const attr = {
    email: form.email,
    // phone_number: form.phone,
  };
  Auth.updateUserAttributes(cognitoUser, attr)
    .then((data) => {
      dispatch(saveProfileRequest('Saving Profile ...'));
      const update = mapUserUiToApi(uuid, form);
      userServiceUpdate(uuid, update, cognitoUser.signInUserSession.accessToken.jwtToken)
        .then(response => (response.status === 200 ?
          response.json() :
          response.json().then(Promise.reject.bind(Promise))))
        .then((json) => {
          dispatch(saveProfileSuccess(data, mapUserApiToUi(json)));
          dispatch(getCurrentUser());
        })
        .catch((json) => {
          dispatch(saveProfileUsError(json));
        });
    })
    .catch(err => (dispatch(saveProfileAwsError(err))));
};

export const AUTH2_CHANGE_PASS_REQ = 'AUTH2_CHANGE_PASS_REQ';
const changePassRequest = msg => ({
  type: AUTH2_CHANGE_PASS_REQ,
  msg,
});
export const AUTH2_CHANGE_PASS_SUC = 'AUTH2_CHANGE_PASS_SUC';
const changePassSuccess = data => ({
  type: AUTH2_CHANGE_PASS_SUC,
  data,
});
export const AUTH2_CHANGE_PASS_ERR = 'AUTH2_CHANGE_PASS_ERR';
const changePassError = error => ({
  type: AUTH2_CHANGE_PASS_ERR,
  error,
});

export const changePassword = (user, oldPassword, newPassword) => (dispatch) => {
  dispatch(changePassRequest('Saving Password'));
  Auth.changePassword(user, oldPassword, newPassword)
    .then((data) => {
      dispatch(changePassSuccess(data));
      // dispatch(getCurrentUser());
    })
    .catch(err => (dispatch(changePassError(err))));
};

export const COGNITO_CHANGE_PW_SUC = 'cognito.Auth.changePass.success';
export const cognitoChangePassSuccess = awsResponse => ({
  type: COGNITO_CHANGE_PW_SUC,
  awsResponse,
});

export const cognitoChangePass = (cognitoUser, oldPassword, newPassword) =>
  Auth.changePassword(cognitoUser, oldPassword, newPassword);

export const cognitoVerifyPhone = code => Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
export const cognitoVerifyEmail = code => Auth.verifyCurrentUserAttributeSubmit('email', code);
export const cognitoVerifyPhoneResend = () => Auth.verifyCurrentUserAttribute('phone_number');
export const cognitoVerifyEmailResend = () => Auth.verifyCurrentUserAttribute('email');

export const COGNITO_VERIFY_PHONE_SUC = 'cognito.Auth.verifyPhone.success';
export const COGNITO_VERIFY_EMAIL_SUC = 'cognito.Auth.verifyEmail.success';
export const cognitoVerifyEmailSuccess = awsResponse => ({
  type: COGNITO_VERIFY_EMAIL_SUC,
  awsResponse,
});
export const cognitoVerifyPhoneSuccess = awsResponse => ({
  type: COGNITO_VERIFY_PHONE_SUC,
  awsResponse,
});

export const AUTH2_DEL_ACCOUNT_REQ = 'AUTH2_DEL_ACCOUNT_REQ';
const deleteAccountRequest = msg => ({
  type: AUTH2_DEL_ACCOUNT_REQ,
  msg,
});
export const AUTH2_DEL_ACCOUNT_SUC = 'AUTH2_DEL_ACCOUNT_SUC';
const deleteAccountSuccess = (awsResponse, usResponse) => ({
  type: AUTH2_DEL_ACCOUNT_SUC,
  awsResponse,
  usResponse,
});
export const AUTH2_DEL_ACCOUNT_US_ERR = 'AUTH2_DEL_ACCOUNT_US_ERR';
const deleteAccountUsError = error => ({
  type: AUTH2_DEL_ACCOUNT_US_ERR,
  error,
});
export const AUTH2_DEL_ACCOUNT_AWS_ERR = 'AUTH2_DEL_ACCOUNT_AWS_ERR';
const deleteAccountAwsError = error => ({
  type: AUTH2_DEL_ACCOUNT_AWS_ERR,
  error,
});

const userServiceDelete = (uuid, token) => fetch(
  Urls.profile(uuid),
  {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  },
);

export const cognitoDeleteUser = cognitoUser => new Promise((resolve, reject) =>
  cognitoUser.deleteUser((error) => {
    if (error) {
      return reject(error);
    }
    resolve();
    return undefined;
  }));

export const deleteAccount = (cognitoUser, uuid) => (dispatch) => {
  dispatch(deleteAccountRequest('Deleting Account ..'));
  new Promise((resolve, reject) => cognitoUser.deleteUser((error) => {
    if (error) {
      return reject(error);
    }
    resolve();
    return undefined;
  }))
    .then((data) => {
      dispatch(deleteAccountRequest('Deleting Account ...'));
      userServiceDelete(uuid, cognitoUser.signInUserSession.accessToken.jwtToken)
        .then(response => (response.status === 200 ?
          response.json() :
          response.json().then(Promise.reject.bind(Promise))))
        .then((json) => {
          dispatch(deleteAccountSuccess(data, json));
        })
        .catch((json) => {
          dispatch(deleteAccountUsError(json));
        });
    })
    .catch(err => (dispatch(deleteAccountAwsError(err))));
};

export const cognitoDeleteAttributes = (cognitoUser, attrs) => new Promise((resolve, reject) => {
  const attributes = [];
  if (!attrs.email) attributes.push('email');
  if (!attrs.phone) attributes.push('phone_number');
  return cognitoUser.deleteAttributes(attributes, (error) => {
    if (error) {
      return reject(error);
    }
    resolve();
    return undefined;
  });
});

export const cognitoUpdateUser = (cognitoUser, attrs, isMobile) => {
  const attributes = {};
  if (attrs.email) attributes.email = attrs.email;
  if (attrs.phone) attributes.phone_number = attrs.phone;
  if (attrs.email && !attrs.phone) {
    attributes.preferred_username = attrs.email;
  } else if (!attrs.email && attrs.phone) {
    attributes.preferred_username = attrs.phone;
  } else if (isMobile) {
    attributes.preferred_username = attrs.phone;
  } else {
    attributes.preferred_username = attrs.email;
  }
  return Auth.updateUserAttributes(cognitoUser, attributes);
};

export const COGNITO_UPDATE_SUC = 'cognito.Auth.updateUser.success';
export const cognitoUpdateUserSuccess = awsResponse => ({
  type: COGNITO_SIGN_UP_SUC,
  awsResponse,
});

export const COGNITO_DELETE_SUC = 'cognito.Auth.deleteUser.success';
export const cognitoDeleteUserSuccess = uuid => ({
  type: COGNITO_DELETE_SUC,
  uuid,
});

export const currentToken = async () => {
  let token;
  let uuid;
  try {
    await Auth.currentSession().then((session) => {
      token = session.accessToken.jwtToken;
      uuid = session.accessToken.payload.sub;
    });
    return { token, uuid };
  } catch (err) {
    if (err === 'No current user') {
      return '';
    }
    throw err;
  }
};

export const checkEMailExists = async email => Auth.signIn(email, '12345678')
  .then(user =>
  // 2. I a user found, they get signin
  // You have to log out a user if found
  // Security vulnerability
    Auth.signOut())
  .then(res =>
  // 3. Here we show a user that email is taken
  // After logging them in and logging them out. LOL
    true).catch((err) => {
    switch (err.code) {
      case 'UserNotFoundException':
        return false;
      case 'InvalidParameterException':
        return true;
      case 'NotAuthorizedException':
        return true;
      case 'PasswordResetRequiredException':
        return true;
      case 'UserNotConfirmedException':
        return true;
      default:
        return true;
    }
  });

