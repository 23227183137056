import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col, CloseLink } from '../../../UxCommon';
import { Button } from '../../../UxCommon/Form';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import { Sports, Routes } from '../../../../store/actions/ui/routes';
import GetOldProfilePhotos from '../../../../containers/UxCommon/Loaders/GetOldProfilePhotos';
import OldProfilePhotos from './OldProfilePhotos/OldProfilePhotos';
import Dropzone from '../../../UxCommon/Dropzone/Dropzone';
import PhotoCropDialog from '../../../../containers/UxEditProfile/PhotoCropDialog';

class UpdateProfilePhoto extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    selectedUserSport: PropTypes.object,
    oldProfilePhotos: PropTypes.array.isRequired,
    onOldProfilePhotoSelected: PropTypes.func.isRequired,
    loadingPhoto: PropTypes.bool.isRequired,
    loadingPhotoMsg: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    dropError: PropTypes.bool.isRequired,
    photoDataToCrop: PropTypes.object,
    loadingPhotoProgress: PropTypes.object,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {}

  onOldProfilePhotoSelected = (photoUrl) => {
    const { selectedUserSport, onOldProfilePhotoSelected } = this.props;
    onOldProfilePhotoSelected(selectedUserSport.sportId, photoUrl, 'Updating your profile photo');
  }

  onFromComputerClick = () => {
    this.dropZoneRef.open();
  };

  saveDropZoneRef = (dropZoneRef) => {
    this.dropZoneRef = dropZoneRef;
  };

  render() {
    const {
      selectedUserSport,
      uuid,
      oldProfilePhotos,
      loadingPhoto,
      loadingPhotoMsg,
      onDrop,
      dropError,
      photoDataToCrop,
      loadingPhotoProgress,
      match,
      isCoach,
    } = this.props;
    return (
      <div className="UxEditProfile_UpdateProfilePhoto">
        {
          selectedUserSport &&
            <CloseLink to={isCoach ? `/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Sports[selectedUserSport.sportId]}${match.params.schoolId ? `/${match.params.schoolId}`: ''}`:
              `/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Sports[selectedUserSport.sportId]}`} />
        }
        {
          photoDataToCrop &&
            <div className="UxEP_UpdateProfilePhoto_CropDialogBackDrop">
              <PhotoCropDialog className="UxEP_UpdateProfilePhoto_CropDialog" />
            </div>
        }
        <Container className="UxEditProfile_UpdateProfilePhoto_Container">
          <Dropzone
            disableClick
            accept="image/*"
            ref={this.saveDropZoneRef}
            onDrop={onDrop}
            multiple={false}
          >
            {
              dropError &&
                <div className="UxEP_UpdateProfilePhoto_DropError">
                  Wrong file format. File must be an image.
                </div>
            }
            <Row>
              <Col className="UxEP_UpdateProfilePhoto_PhotoCol">
                <div className="UxEP_UpdateProfilePhoto_H1">
                  Your Current Profile Photo
                </div>
                <ProfilePhoto
                  selectedUserSport={selectedUserSport}
                  loading={loadingPhoto}
                  loadingMsg={loadingPhotoMsg}
                  loadingProgress={loadingPhotoProgress}
                />
                <div style={{ marginBottom: '10px' }} />
                <div className="UxEP_UpdateProfilePhoto_P">
                  Drag an image here!
                </div>
                <div className="UxEP_UpdateProfilePhoto_H2">
                  Or
                </div>
                <div className="UxEP_UpdateProfilePhoto_P">
                  Choose an image from
                </div>
                <div style={{ marginBottom: '20px' }} />
                <Button onClick={this.onFromComputerClick}>Your Computer</Button>
              </Col>
              <Col style={{ flexGrow: 2, width: '630px' }}>
                <div className="UxEP_UpdateProfilePhoto_H2">
                  Or
                </div>
                <div className="UxEP_UpdateProfilePhoto_P">
                  Choose from your previous profile photos
                </div>
                <div className="UxEP_UpdateProfilePhoto_OldPhotos">
                  <GetOldProfilePhotos match={match}>
                    <OldProfilePhotos
                      profilePhotos={oldProfilePhotos}
                      onPhotoClicked={this.onOldProfilePhotoSelected}
                    />
                  </GetOldProfilePhotos>
                </div>
              </Col>
            </Row>
          </Dropzone>
        </Container>
      </div>
    );
  }
}

export default UpdateProfilePhoto;
