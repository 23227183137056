import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../../UxCommon/Icon/Icon';
import { LoadingSpinner, VideoPlayer, LoadingImg, CloseLink, GetWindowSize, ShareModal, ButtonRect } from '../../../UxCommon';
import DeletePhotoDialog from '../PhotoVideoList/DeletePhotoDialog/DeletePhotoDialog';
import PhotoInfoForm from './PhotoInfoForm/PhotoInfoForm';
import TagsForm from './TagsForm/TagsForm';
import {
  Routes,
  closePhotoDialogPath,
  closeTutorialsDialogPath,
  expandedPhotoDialogTo,
  expandedTutorialDialogTo,
  closeLeaderboardDialogPath,
  closeCombineDialogPath,
  closeNationalLeaderboardDialogPath,
  closeProspectDialogPath,
  closeNorthEastLeaderboardDialogPath,
  closeAllTimeLeaderboardDialogPath,
  closeDistrictLeaderboardDialogPath
} from "../../../../store/actions/ui/routes";
import IframeWrapper from './IframeWrapper/IframeWrapper';
import { GridBreakpoints } from '../../../../config/responsive';
import { getImageUrl } from '../../../../store/actions/ui/uxProfile/photosVideos/utils';
import { Caption } from './Caption/Caption';
import { GetMediaDimension } from './GetMediaDimension/GetMediaDimension';

class ExpandedPhotoDialog extends PureComponent {
  static propTypes = {
    media: PropTypes.object,
    onSaveClick: PropTypes.func.isRequired,
    mediaNotFound: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    formInit: PropTypes.func.isRequired,
    isDeleteDialogShown: PropTypes.bool.isRequired,
    onConfirmDeleteClick: PropTypes.func.isRequired,
    onCancelDeleteClick: PropTypes.func.isRequired,
    onConfirmDeleteAllTagsClick: PropTypes.func.isRequired,
    onCancelDeleteAllTagsClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    albums: PropTypes.array.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    album: PropTypes.object,
    rrfTagForm: PropTypes.object.isRequired,
    onTagNameChange: PropTypes.func.isRequired,
    onAddTagClick: PropTypes.func.isRequired,
    onDeleteTagClick: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    onAddTagToPhotoClick: PropTypes.func,
    selectedTags: PropTypes.array.isRequired,
    prevMediaId: PropTypes.string.isRequired,
    nextMediaId: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    onSaveTagsClick: PropTypes.func.isRequired,
    onAlbumSelect: PropTypes.func.isRequired,
    isTutorials: PropTypes.bool,
    history: PropTypes.object.isRequired,
    profile: PropTypes.object,
    isCoach: PropTypes.bool
  }

  state = {
    isCaptionEditing: false,
    isAlbumPicking: false,
    isTagsPicking: false,
    scrollTop: 0,
    imgHeight: 0,
    imgWidth: 0,
    naturalImgHeight: 0,
    naturalImgWidth: 0,
    isDimSet: false,
    windowWidth: 0,
    windowHeight: 0,
    shareDialogUri: '',
    currentMedia: null,
    isEditListOpen: false,
    showEditForm: false,
    isFullScreen: false,
  };

  componentDidMount() {
    const { formInit, media, isSingleVideo,match,getVideo } = this.props;
    document.addEventListener('mousedown', this.handleOutsideClick, false);
    if (media) {
      formInit(media);
      this.initState();
    }
    if(isSingleVideo && !media){
      getVideo(match.params.mediaId);
    }
    this.scrollElement = document.getElementById('uxProfileRight');
    if (this.scrollElement) {
      this.saveScrollTop(this.scrollElement.scrollTop);
    }
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  componentWillUnmount() {
    this.props.clearVideoState();
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    this.setState({ currentMedia: null});
  }

  onToggleFullScreen = (value=null) => {
    const { isFullScreen } = this.state;
    if (value !== null) {
      this.setState({
        isFullScreen: value,
      });
    } else if (!isFullScreen) {
      let el = document.getElementsByTagName('video');
      if (!el || !el.length) {
        el = document.getElementsByTagName('iframe');
      }
      const video = el && el[0] ? el[0] : null;
      if (video && video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video && video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video && video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video && video.webkitEnterFullScreen) {
        video.webkitEnterFullScreen();
      } else {
        // none of the fullscreen method is supported, go full screen manually with css
        this.setState({
          isFullScreen: true,
        });
      }
    } else {
      this.setState({
        isFullScreen: false,
      });
    }
  }

  onEditCaptionClick = () => {
    this.setState({
      isCaptionEditing: true,
    });
  };

  onChangeAlbumClick = () => {
    this.setState({
      isAlbumPicking: true,
    });
  };

  onChooseTagsClick = () => {
    this.setState({
      isTagsPicking: true,
    });
  };

  onTagsBackClick = () => {
    this.setState({
      isTagsPicking: false,
    });
  };

  onShareClick = () => {
    const { match, media } = this.props;
    const uri = expandedPhotoDialogTo(match.params.uuid, media.isAPhoto, media.id, match.params.sport, match.params.schoolId);
    this.setState({
      shareDialogUri: uri,
    });
  }

  onShareCloseClick = () => {
    this.setState({
      shareDialogUri: '',
    });
  }

  onDeleteFromEditListClicked = () => {
    this.props.onDeleteClick();
    this.setState({ isEditListOpen: false });
  };

  onEditFromEditListClicked = () => {
    this.toggleShowEditForm();
    this.setState({ isEditListOpen: false });
  };

  onEditListClicked = () => {
    const {
      isEditListOpen,
      showEditForm,
    } = this.state;
    if (isEditListOpen || showEditForm) {
      this.setState({
        isEditListOpen: false,
        showEditForm: false,
      });
    } else {
      this.setState({
        isEditListOpen: true,
      });
    }
  }

  getCloseLink = () => {
    const { isTutorials, match,isSingleVideo,location,page=null} = this.props;
    if(page === Routes.leaderboard && isSingleVideo){
      const {schoolId, combineId, bank} = match.params;
      return closeLeaderboardDialogPath(schoolId, combineId, bank,location.search);
    }
    if(page === Routes.nationalLeaderboard && isSingleVideo){
      const {bank} = match.params;
      return closeNationalLeaderboardDialogPath(bank,location.search);
    }
    if(page === Routes.northEastLeaderboard && isSingleVideo){
      const {bank} = match.params;
      return closeNorthEastLeaderboardDialogPath(bank,location.search);
    }
    if(page === Routes.allTimeLeaderboard && isSingleVideo){
      const {bank,schoolId} = match.params;
      return closeAllTimeLeaderboardDialogPath(bank,location.search, schoolId);
    }

    if(page === Routes.districtLeaderboard && isSingleVideo){
      const {bank,schoolId} = match.params;
      return closeDistrictLeaderboardDialogPath(bank,location.search, schoolId);
    }
    
    if(page === Routes.combine && isSingleVideo){
      const {schoolId, combineId, bank} = match.params;
      return closeCombineDialogPath(schoolId, combineId, bank,location.search);
    }
    if(page === Routes.prospectSheet && isSingleVideo){
      const {schoolId, teamId, athleteId, sport} = match.params;
      return closeProspectDialogPath(schoolId, teamId, athleteId, sport);
    }
    return isTutorials ?
      closeTutorialsDialogPath(match.params.schoolId)
      : closePhotoDialogPath(match.params.uuid, match.params.sport, match.params.schoolId);
  };

  setWindowWidth = (width) => {
    this.setState({
      windowWidth: width,
    });
  }

  setWindowHeight = (height) => {
    this.setState({
      windowHeight: height,
    });
  }

  setHeightWidthHighlight = (width, height) => {
    const { media } = this.props;
    const { windowWidth, windowHeight } = this.state;
    const ratio = width / height;
    const isSmallScreen = windowWidth < GridBreakpoints.lg;
    let availableScreenHeight;
    let availableScreenWidth;
    if (isSmallScreen) {
      availableScreenHeight = media.isAPhoto ? windowHeight - 90 : windowHeight - 90 - 40;
      availableScreenWidth = windowWidth;
    } else {
      availableScreenWidth = windowWidth - 450 - 22;
      availableScreenHeight = media.isAPhoto ?
        windowHeight - 44 - 120 : windowHeight - 44 - 40 - 120;
    }
    let imgWidth = ratio < 1 ?
      Math.min(availableScreenHeight * ratio, availableScreenWidth) :
      availableScreenWidth;
    let imgHeight = ratio < 1 ?
      availableScreenHeight :
      Math.min(imgWidth / ratio, availableScreenHeight);
    if (Math.abs(ratio - (imgWidth / imgHeight)) > 0.5) {
      const tooTall = availableScreenWidth / ratio > availableScreenHeight;
      imgWidth = tooTall ? availableScreenHeight * ratio : windowWidth;
      imgHeight = tooTall ? availableScreenHeight : windowWidth / ratio;
    }
    this.setState({
      imgWidth,
      imgHeight,
      naturalImgHeight: height,
      naturalImgWidth: width,
      isDimSet: true,
    });
  }

  setImgHeight = (dims) => {
    const { windowWidth, windowHeight } = this.state;
    const realWidth = dims.width;
    const realHeight = dims.height;
    const imageHeightWrapper = windowHeight - 40;
    const imageRatio = realWidth / realHeight;
    const tooTall = windowWidth / imageRatio > imageHeightWrapper;
    const mediaWidth = tooTall ? imageHeightWrapper * imageRatio : windowWidth;
    const mediaHeight = tooTall ? imageHeightWrapper : windowWidth / imageRatio;
    this.setState({
      imgHeight: mediaHeight,
      imgWidth: mediaWidth,
    });
  }

  getDialogHeight = () => {
    const { scrollTop } = this.state;
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (scrollTop > 272) {
      return h - 36;
    }
    return h - 308;
  }

  getExpandedDialogPath = (mediaId) => {
    const { match, media } = this.props;
    return !media.isAPhoto && media.isCoachTutorial() ?
      expandedTutorialDialogTo(match.params.schoolId, mediaId)
      : expandedPhotoDialogTo(match.params.uuid, media.isAPhoto, mediaId, match.params.sport, match.params.schoolId);
  };

  updateOnWindowResize = () => {
    const {
      naturalImgHeight,
      naturalImgWidth,
      isDimSet,
    } = this.state;
    if (isDimSet) {
      this.setHeightWidthHighlight(naturalImgWidth, naturalImgHeight);
    }
  }

  toggleShowEditForm = () => {
    this.setState({
      showEditForm: !this.state.showEditForm,
    });
  }

  initState = () => {
    const { media } = this.props;
    this.setState({
      isCaptionEditing: false,
      isAlbumPicking: false,
      isTagsPicking: false,
      currentMedia: media,
    });
  };

  shouldInit = () => {
    const {
      formNeedsInit,
      formInit,
      media,
      history,
      mediaNotFound,
      isSingleVideo,
    } = this.props;
    const { currentMedia } = this.state;
    if (media && (formNeedsInit || (currentMedia && currentMedia.id !== media.id))) {
      formInit(media);
      this.initState();
    }

    if (!media && !isSingleVideo) {
      history.push(this.getCloseLink());
      mediaNotFound();
    }
  };

  handleOnAlbumSelect = (value) => {
    this.setState({
      isAlbumPicking: false,
    });
    const { onAlbumSelect } = this.props;
    onAlbumSelect(value);
  }

  handleOutsideClick = (e) => {
    const { history } = this.props;
    if (!this.node || this.node.contains(e.target)) {
      return;
    }
    if (history) {
      history.push(this.getCloseLink());
    }
  };

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  handleConfirmDelete = () => {
    const { onConfirmDeleteClick, history } = this.props;
    onConfirmDeleteClick();
    history.push(this.getCloseLink());
  }

  renderMedia = (isSmallScreen) => {
    const {
      media,
      onPrevClick,
      onNextClick,
    } = this.props;
    const {
      windowWidth,
      windowHeight,
      imgWidth,
      imgHeight,
      isFullScreen,
    } = this.state;
    if (media.isAPhoto) {
      return (
        <LoadingImg
          className="Photos_ExpandedPhotoDialog_Img black"
          srcUrl={getImageUrl(media, true)}
          onLoadCallback={() => {}}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
        />
      );
    }
    if (media.needsIframe) {
      return (
        <IframeWrapper media={media} windowWidth={windowWidth} onLoadCallback={this.setImgHeight} />
      );
    }
    return (
      <VideoPlayer
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        video={media}
        height={imgHeight}
        width={imgWidth}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        isSmallScreen={isSmallScreen}
        onLoadCallback={() => {}}
        isFullScreen={isFullScreen}
        toggleFullScreen={this.onToggleFullScreen}
      />
    );
  }

  renderTopBar = (isSmallScreen) => {
    const { isMyProfile } = this.props;
    const { isEditListOpen, isFullScreen } = this.state;
    if (isFullScreen) {
      return null;
    }
    if (isSmallScreen) {
      return (
        <div className="Photos_ExpandedPhotoDialog_SmallScreen_TopControls_Wrappers">
          <CloseLink
            to={this.getCloseLink()}
            className="Photos_ExpandedPhotoDialog_CloseLink"
          />
          <div className="Photos_ExpandedPhotoDialog_TopBar">
            <button onClick={this.onShareClick}>
              <i className="icon-share" />
            </button>
            <button onClick={this.onEditListClicked}>
              <i className="icon-ellipsis" />
            </button>
            {
              isEditListOpen && (
                <div className="Photos_ExpandedPhotoDialog_TopBar_EditList">
                  <button onClick={this.onEditFromEditListClicked}>
                    <Icon iconName="edit" />
                    { isMyProfile ? 'Edit Highlight' : 'More Info' }
                  </button>
                  {
                    isMyProfile && (
                      <button onClick={this.onDeleteFromEditListClicked}>
                        <Icon iconName="delete" />
                        Delete Highlight
                      </button>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      );
    }
    return (
      <CloseLink
        to={this.getCloseLink()}
        className="Photos_ExpandedPhotoDialog_CloseLink"
      />
    );
  }

  render() {
    const {
      onSaveClick,
      media,
      rrfForm,
      loading,
      loadingMsg,
      isDeleteDialogShown,
      onConfirmDeleteClick,
      onCancelDeleteClick,
      onDeleteClick,
      albums,
      album,
      rrfTagForm,
      onTagNameChange,
      onAddTagClick,
      onDeleteTagClick,
      tags,
      onAddTagToPhotoClick,
      selectedTags,
      onConfirmDeleteAllTagsClick,
      onCancelDeleteAllTagsClick,
      nextMediaId,
      prevMediaId,
      match,
      isMyProfile,
      onSaveTagsClick,
      isTutorials,
      profile,
      isSingleVideo,
      history,
      isCoach,
    } = this.props;
    const {
      isCaptionEditing,
      isAlbumPicking,
      isTagsPicking,
      windowWidth,
      shareDialogUri,
      showEditForm,
      windowHeight,
      isDimSet,
      isFullScreen,
    } = this.state;
    const isSideMedia = isTutorials || isSingleVideo;
    const isSmallScreen = windowWidth < GridBreakpoints.lg;
    const dialogHeight = isSmallScreen ? 'unset' : `${Math.max(600, this.getDialogHeight())}px`;

    if(isSingleVideo && !media){
     return  <div className="Photos_ExpandedPhotoDialog_Backdrop" >
      <LoadingSpinner
          loading={true}
          loadingMsg={"Loading. Please wait..."}
          style={{ backgroundColor: 'rgba(256, 256, 256, 0.7)' }}
      />
      </div>
    }

    if (!media) {
      return null;
    }

    if(media && !media.processed){
      return  <div className="CloseCombineModal_Backdrop">
        <div className="CloseCombineModal">
          <div className="CloseCombineModal_Header" />
          <div className="CloseCombineModal_Body">
            <div className="CloseCombineModal_Text1">Video Is Processing... Come Back later.</div>
          </div>
          <div className="CloseCombineModal_Footer">
            <ButtonRect
              className="CloseCombineModal_Btn"
              type="button"
              onClick={()=> history.push(this.getCloseLink())}
            >
              Ok
            </ButtonRect>
          </div>
        </div>
      </div>

    }

    return (
      <div className="Photos_ExpandedPhotoDialog_Backdrop" style={{ height: windowHeight }}>
        <GetWindowSize
          getWindowX={this.setWindowWidth}
          getWindowY={this.setWindowHeight}
          updateOthers={this.updateOnWindowResize}
        />
        <GetMediaDimension
          imgUrl={media.isAPhoto ? getImageUrl(media, true) : media.getThumb()}
          updateDimension={this.setHeightWidthHighlight}
        />
        {
           isDimSet && (
            <div
              className="Photos_ExpandedPhotoDialog"
              ref={(node) => {
                this.node = node;
              }}
            >
              { this.renderTopBar(isSmallScreen) }
              <LoadingSpinner
                loading={loading}
                loadingMsg={loadingMsg}
                style={{ backgroundColor: 'rgba(256, 256, 256, 0.7)' }}
              />
              {
                isSmallScreen && isDeleteDialogShown &&
                <DeletePhotoDialog
                  media={media}
                  onConfirmDeleteClick={this.handleConfirmDelete}
                  onCancelClick={onCancelDeleteClick}
                  canDelete={(isCoach && media.isCreatedByCoach) ? true : (!media.isCreatedByCoach)}
                />
              }
              {
                !!shareDialogUri &&
                <ShareModal uri={shareDialogUri} onClose={this.onShareCloseClick} backdropClassName="absolute" />
              }
              {
                (!loading && !media) && (
                  <div className="Photos_NotFound_Wrapper">
                    <div className="AlertIconWrapper"><Icon iconName="alert" /></div>
                    Oops! This video has been moved or deleted.
                  </div>
                )
              }
             { isSingleVideo ? (
              <div className="Photos_ExpandedPhotoDialog_ImgWrapper">
               {this.renderMedia(isSmallScreen)}
               </div>
              )
              :
              (media || loading) && !showEditForm && (
                  <div className="Photos_ExpandedPhotoDialog_ImgWrapper">
                    {
                      (media && !isSmallScreen) &&
                      <Link
                        className={[
                          'Photos_ExpandedPhotoDialog_PrevNextBtn prev',
                          media.isAPhoto ? '' : 'video',
                        ].join(' ')}
                        to={this.getExpandedDialogPath(prevMediaId)}
                      >
                        <Icon iconName="back-arrow" />
                      </Link>
                    }
                    { this.renderMedia(isSmallScreen) }
                    {
                      (media && !isSmallScreen) &&
                      <Link
                        className={[
                          'Photos_ExpandedPhotoDialog_PrevNextBtn next',
                          media.isAPhoto ? '' : 'video',
                        ].join(' ')}
                        to={this.getExpandedDialogPath(nextMediaId)}
                      >
                        <Icon iconName="arrow-right" />
                      </Link>
                    }
                  </div>
                )
              }
              {
                (media || loading) && !showEditForm && isSmallScreen && !isFullScreen && (
                  <Caption media={media} profile={profile} />
                )
              }
              {
                (media || loading) && (!isSmallScreen || showEditForm) && (
                  <div
                    className={[
                      (isSideMedia) ? 'Photos_ExpandedTutorialDialog_InfoCol' : 'Photos_ExpandedPhotoDialog_InfoCol',
                      isTagsPicking ? 'Tag_Pick_Width' : '',
                    ].join(' ')}
                    style={{
                      height: `${dialogHeight}px`,
                    }}
                  >
                    {
                      isDeleteDialogShown && (
                        <DeletePhotoDialog
                          media={media}
                          onConfirmDeleteClick={onConfirmDeleteClick}
                          onCancelClick={onCancelDeleteClick}
                          canDelete={(isCoach && media.isCreatedByCoach) ? true : (!media.isCreatedByCoach)}
                        />
                      )
                    }
                    {
                      !isSideMedia && (isTagsPicking ? (
                        <TagsForm
                          media={media}
                          rrfForm={rrfTagForm}
                          onTagNameChange={onTagNameChange}
                          onAddTagClick={onAddTagClick}
                          onDeleteTagClick={onDeleteTagClick}
                          tags={tags}
                          onAddTagToPhotoClick={onAddTagToPhotoClick}
                          selectedTags={selectedTags}
                          onConfirmDeleteClick={onConfirmDeleteAllTagsClick}
                          onCancelDeleteClick={onCancelDeleteAllTagsClick}
                          onSaveTagsClick={onSaveTagsClick}
                          dialogHeight={dialogHeight}
                          onTagsBackClick={this.onTagsBackClick}
                        />
                      ) : (
                        <PhotoInfoForm
                          media={media}
                          onSmallScreenCloseClick={() => this.setState({ showEditForm: false })}
                          onSaveClick={onSaveClick}
                          rrfForm={rrfForm}
                          onDeleteClick={onDeleteClick}
                          albums={albums}
                          isAlbumPicking={isAlbumPicking}
                          isCaptionEditing={isCaptionEditing}
                          onEditCaptionClick={this.onEditCaptionClick}
                          onChangeAlbumClick={this.onChangeAlbumClick}
                          onChooseTagsClick={this.onChooseTagsClick}
                          album={album}
                          selectedTags={selectedTags}
                          match={match}
                          isMyProfile={isMyProfile}
                          onShareClick={this.onShareClick}
                          onOptionSelected={this.handleOnAlbumSelect}
                          isSmallScreen={isSmallScreen}
                        />
                      )
                    )}
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

export default ExpandedPhotoDialog;
