import React, { PureComponent } from 'react';
import pathToRegexp from 'path-to-regexp';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '../../store/actions/ui/routes';
import Dashboard from './Dashboard';
import Roster from './Roster';
import School from '../CoachWorld/School/School.container';
import Combine from './Combine';
import Report from './Report';
import PrivateRoute from '../../containers/App/PrivateRoute';
import FormExamplePage from './FormExamplePage';
import CoachAccount from './CoachAccount';
import Leaderboard from './Leaderboard';
import { checkIfUpdateNeeded, schoolTeamListId } from './+store/combine/combine.utils';
import NationalLeaderboard from './NationalLeaderboard';
import NorthEastLeaderboard from "./NorthEastLeaderboard";
import AllTimeLeaderboard from "./AllTimeLeaderboard";
import DistrictLeaderboard from "./DistrictLeaderboard";

// import { Roles } from '../../store/actions/data/user/profile/models';

class CoachWorld extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string,
    schoolIds: PropTypes.array,
    // profile: PropTypes.object,
    getUserProfile: PropTypes.func.isRequired,
    getUserSchoolTeams: PropTypes.func.isRequired,
    getSchoolStyles: PropTypes.func.isRequired,
    // loadingMsg: PropTypes.string.isRequired,
    shouldUpdateTeamsList: PropTypes.array.isRequired,
    getSchoolTeamsList: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    hideFilters: PropTypes.bool,
  };

  componentDidMount() {
    const {
      location,
      shouldUpdateTeamsList,
      getSchoolTeamsList,
    } = this.props;
    this.shouldUpdate();
    const coachWorld = document.getElementById('CoachWorld');
    coachWorld.addEventListener('scroll', this.handleScroll);
    const re = pathToRegexp(`/${Routes.school}/:schoolId/:path+`);
    const params = re.exec(location.pathname);
    if (params) {
      const [, schoolId] = params;
      const shouldUpdate = checkIfUpdateNeeded(
        shouldUpdateTeamsList,
        schoolTeamListId(schoolId),
      );
      if (shouldUpdate) {
        getSchoolTeamsList(schoolId);
      }
    }
  }

  componentDidUpdate() {
    this.shouldUpdate();
    const coachWorld = document.getElementById('CoachWorld');
    if(this.props.hideFilters){
      coachWorld.classList.add('hideFilters');
    }else{
      coachWorld.classList.value.includes('hideFilters') &&
        coachWorld.classList.remove('hideFilters');
    }
  }

  componentWillUnmount() {
    const coachWorld = document.getElementById('CoachWorld');
    coachWorld.removeEventListener('scroll', this.handleScroll);

    // setTimeout(() => {
    //   const coachFilterSidebar = document.getElementById('CoachFilterSidebar');
    //   const mutationObserver = new MutationObserver((mutationsList, observer) => {
    //     mutationsList.forEach((mutation) => {
    //       if (mutation.attributeName === 'class') {
    //         console.log('class changed!');
    //       }
    //     });
    //   });
    //   mutationObserver.observe(coachFilterSidebar, { attributes: true });
    // }, 10);
  }

  handleScroll = () => {
    const coachWorld = document.getElementById('CoachWorld');
    if (coachWorld.scrollTop > 325) {
      coachWorld.classList.add('scroll');
    } else {
      coachWorld.classList.remove('scroll');
    }
    this.bankHeaderFix(coachWorld);
  };

  bankHeaderFix = (coachWorld) => {
    if( coachWorld && !coachWorld.classList.value.includes("scroll") && this.props.hideFilters){
      coachWorld.classList.add('bankHeaderFix');
    }
    else{
      coachWorld.classList.remove('bankHeaderFix');
    }
  }

  profileFetched = false;
  teamsFetched = false;
  teamTypesFetched = false;
  schoolStyleFetched = 0;

  shouldUpdate = () => {
    const {
      uuid,
      schoolIds,
      getUserProfile,
      getUserSchoolTeams,
      getSchoolStyles,
    } = this.props;
    if (!this.profileFetched && uuid) {
      getUserProfile(uuid);
      this.profileFetched = true;
    }
    if (!this.teamsFetched && uuid) {
      getUserSchoolTeams(uuid);
      this.teamsFetched = true;
    }
    if (this.schoolStyleFetched !== schoolIds.length) {
      getSchoolStyles(schoolIds);
      this.schoolStyleFetched = schoolIds.length;
    }

    // const re = pathToRegexp(`/${Routes.school}/:schoolId/:path+`);
    // const params = re.exec(location.pathname);
    // if (params) {
    //   const [, schoolId] = params;
    //   const shouldUpdate = checkIfUpdateNeeded(
    //     shouldUpdateTeamsList,
    //     schoolTeamListId(schoolId),
    //     location
    //   );
    //   if (shouldUpdate) {
    //     getSchoolTeamsList(schoolId);
    //   }
    // }
  };

  redirectToDashboard = () => <Redirect to={`/${Routes.coach}/${Routes.dashboard}`} />

  render() {
    return (
      <div className={`CoachWorld `} id="CoachWorld">
        <Switch>
          <Route path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId/:tab(${Routes.awards})`} component={Dashboard} />
          <PrivateRoute
            path={`/(${Routes.coach}|${Routes.athlete})/${Routes.dashboard}/:schoolId?/:tab?`}
            component={Dashboard}
          />
          <PrivateRoute
            path={`/${Routes.coach}/${Routes.account}/:tab?`}
            component={CoachAccount}
          />
          <Route
            path={`/${Routes.coach}/${Routes.nationalLeaderboard}/:bank?`}
            component={NationalLeaderboard}
          />
          <Route
            path={`/${Routes.coach}/${Routes.northEastLeaderboard}/:bank?`}
            component={NorthEastLeaderboard}
          />
          <Route
            path={`/${Routes.coach}/:schoolId/${Routes.allTimeLeaderboard}/:bank?`}
            component={AllTimeLeaderboard}
          />
          <Route
            path={`/${Routes.coach}/:schoolId/${Routes.districtLeaderboard}/:bank?`}
            component={DistrictLeaderboard}
          />
          <Route path={`/${Routes.coach}/formExample`} component={FormExamplePage} />
          <Route path={`/${Routes.coach}`} render={this.redirectToDashboard} />
          <Route exact path={`/${Routes.school}/:schoolId/`} component={School} />
          <Route path={`/${Routes.school}/:schoolId/${Routes.roster}/:sport?`} component={Roster} />
          <PrivateRoute
            path={`/${Routes.school}/:schoolId/${Routes.combine}/:combineId/:bank?`}
            component={Combine}
          />
          <PrivateRoute
            path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/:bank?`}
            component={Report}
          />
          <PrivateRoute
            path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/:bank?`}
            component={Report}
          />
          <PrivateRoute
            path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/:tab?`}
            component={Report}
          />
          <Route
            path={`/${Routes.school}/:schoolId/${Routes.leaderboard}/:combineId/:bank?`}
            component={Leaderboard}
          />
        </Switch>
      </div>
    );
  }
}

export default CoachWorld;
