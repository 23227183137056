import { combineEpics, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { getTokenFragment, manualCancelApiCallFragment } from "../../../../store/actions/ui/uxProfile/utils";
import { asyncFinishAction } from "../../../../store/actions/ui/async";
import { NationalLeaderboardRosters } from "../nationalLeaderboard/nationalLeaderboard.models";
import { Routes } from "../../../../store/actions/ui/routes";
import { GET_NORTH_EAST_LEADERBOARD_RESULTS } from "./northEastLeaderboard.actions";
import { apiGetNorthEastLeaderboardResults } from "./northEastLeaderboard.api";


const getNorthEastLeaderboardResultsEpics = (action$, state$) => (
  action$.pipe(
    ofType(GET_NORTH_EAST_LEADERBOARD_RESULTS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetNorthEastLeaderboardResults(token, action).pipe(manualCancelApiCallFragment(
        action$,
        action,
        "getNorthEastLeaderboardResults",
        Routes.northEastLeaderboard
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const { loadMore } = result.action;
        const { categories } = state$.value.modules.coachWorld.nationalLeaderboard;
        let newRosters = NationalLeaderboardRosters.fromApi(
          result.response[0].result.data,
          categories
        );
        if (loadMore) {
          const { rosters } = state$.value.modules.coachWorld.northEastLeaderboard.results;
          if (result.action.page > 1) {
            newRosters = [...rosters, ...newRosters];
          }
        }

        const showMore = result.response[0].result.to < result.response[0].result.total;

        return of(asyncFinishAction(result.action.type, GET_NORTH_EAST_LEADERBOARD_RESULTS, {
          rosters: newRosters,
          showMore
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  )
);

export default combineEpics(
  getNorthEastLeaderboardResultsEpics
);
