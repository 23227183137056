import { connect } from 'react-redux';
import Component from './TeamBox.component';
import { selectAthletes, selectCoaches, selectCanEditRoster } from '../../+store/roster/roster.selectors';
import {
  deleteSchedule,
  getSchoolTeamSchedule,
  updateTeamName,
  uploadSchedule
} from '../../+store/roster/roster.actions';
import { selectCoachSchoolIds } from '../../+store';
import {uploadTranscript} from "../../../ProspectSheet/+store";
import queryString from "query-string";

const getSchedule = (state, ownProps)=>{
  const schedules = state.modules.coachWorld.roster.schedules;
  return schedules[ownProps.team.id];
}

const sortByCreatedBy = (arr, location) => {
  if (!arr || !Array.isArray(arr) || location.search) {
    return arr;
  }
  return arr.sort((a, b) => {
    return a.profileId - b.profileId;
  });
};

const mapStateToProps = (state, ownProps) => {
  const { team, location } = ownProps;
  const allAthletes = selectAthletes(state, ownProps);
  const allCoaches = selectCoaches(state, ownProps);
  const teamName = team.name || team.teamRef.team;
  return {
    athletes: sortByCreatedBy(allAthletes[team.id], location) || [],
    coaches: allCoaches[team.id] || [],
    team,
    teamName,
    profilesLoading: state.modules.coachWorld.roster.loadingProfiles,
    viewSelection: state.modules.coachWorld.roster.viewSelection,
    canEdit: selectCanEditRoster(state, ownProps),
    coachSchoolIds: selectCoachSchoolIds(state),
    userProfileId: state.data.cognito.uuid,
    schoolStyle: state.modules.coachWorld.coachWorld.schoolStyle,
    schedule: getSchedule(state, ownProps),
    loadingSchedule : state.modules.coachWorld.roster.loadingSchedule,
  };
};

const mapDispatchToProps = dispatch => ({
  updateTeamName: (values) => {
    dispatch(updateTeamName(values));
  },
  saveSchedule: ( schoolTeamId, file ) => {
    dispatch( uploadSchedule(schoolTeamId, file) );
  },
  deleteSchedule: ( schoolTeamId, scheduleId ) => {
    dispatch( deleteSchedule(schoolTeamId, scheduleId) );
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
