import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import pathToRegexp from 'path-to-regexp';

import { ROUTE_CHANGED, Routes } from '../../routes';
import { testVanityUrl } from '../../../data/user/vanity';
import { commonApiCallFragment } from '../../uxProfile/utils';
import { updateUuidInStore } from '../actions';

const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const updateCurrentUuidEpic = action$ => (
  action$.pipe(
    ofType(ROUTE_CHANGED),
    switchMap((action) => {
      const re = pathToRegexp(`/${Routes.profile}/:uuid/:page?`, [], {
        end: false,
      });
      const params = re.exec(action.pathname);
      if (params && params[1]) {
        if (isUuid.test(params[1])) {
          return of({ success: true, uuid: params[1] });
        }
        return testVanityUrl(params[1]).pipe(
          commonApiCallFragment(
            action$,
            action,
            'vanityUrl',
            Routes.edit,
          ),
          map((results) => {
            if (results.success) {
              return {
                ...results,
                uuid: results.response.owner_id,
              };
            }
            return results;
          }),
        );
      }
      return of({ success: true });
    }),
    switchMap((results) => {
      if (!results.success
      && results.action.error
      && results.action.error.response === 'Name Not In Use') {
        return of(updateUuidInStore('unknown'));
      }
      if (results.success) {
        return of(updateUuidInStore(results.uuid));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);

export const routesEpics = combineEpics(updateCurrentUuidEpic);

export default 'vanity/create.js';
