import Base from './Base';

export default class Album extends Base {
  constructor() {
    super();
    this.title = '';
    this.description = '';
    this.media = [];
  }

  fromApiResponse(response) {
    super.fromApiResponse(response);
    this.title = response.title;
    this.description = response.description;
  }

  addMedia(media) {
    this.media.push(media);
  }

  clone(mediaToRemove) {
    let cloneAlbum = new Album();
    cloneAlbum = cloneAlbum.super.clone();
    cloneAlbum.id = this.id;
    cloneAlbum.owneerId = this.ownerId;
    cloneAlbum.createdAt = this.createdAt;
    cloneAlbum.updatedAt = this.updatedAt;
    cloneAlbum.title = this.title;
    cloneAlbum.description = this.description;
    cloneAlbum.media = this.media.filter(m => m.id !== mediaToRemove.id);
    return cloneAlbum;
  }
}
