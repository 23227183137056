import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import { getCroppedImg } from '../../../../../utils';
import { Button } from '../../../../../components/UxCommon/Form';
import { LoadingSpinner } from '../../../../../components/UxCommon';


class PhotoCropDialog extends PureComponent {
  static propTypes = {
    photoDataToCrop: PropTypes.object,
    savePhoto: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    athleteId: PropTypes.string,
    coachId: PropTypes.string,
  }

  state = {
    crop: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.photoDataToCrop !== state.photoDataToCrop) {
      const { data, height, width } = props.photoDataToCrop;
      let w;
      let h;
      let x;
      let y;
      if (height > width) {
        w = 100;
        h = (width / height) * 100;
        x = 0;
        y = (100 - h) / 2;
      } else {
        h = 100;
        w = (height / width) * 100;
        y = 0;
        x = (100 - w) / 2;
      }
      return {
        crop: {
          x,
          y,
          width: w,
          height: h,
          aspect: 1,
        },
        photoDataToCrop: props.photoDataToCrop,
        photoData: data,
      };
    }
    return null;
  }

  onImageLoaded = () => {
    // onImageLoaded = (image) => {
    // console.log('onCropComplete', image);
  };

  onCropComplete = () => {
    // onCropComplete = (crop) => {
    // console.log('onCropComplete', crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onSaveClick = () => {
    const { savePhoto, athleteId, coachId } = this.props;
    const { photoData, crop } = this.state;
    getCroppedImg(photoData, crop)
      .then((croppedFile) => {
        document.getElementsByTagName('canvas').forEach(item => item.remove());
        savePhoto(croppedFile, athleteId, coachId);
      });
  };

  render() {
    const {
      style,
      className,
      onCancel,
      loading,
      photoDataToCrop,
    } = this.props;
    const { photoData, crop } = this.state;
    return (
      <div
        className={['UxCommon_PhotoCropDialog', (className || '')].join(' ')}
        style={style || {}}
      >
        <LoadingSpinner loading={loading} loadingMsg="Opening your photo"/>
        <div className="UxCommon_PhotoCropDialog_Content">
          <div className="UxCommon_PhotoCropDialog_Title">
            {'Awesome, let\'s crop your photo real quick so it looks good on your profile'}
          </div>
          {
            photoDataToCrop &&
            <ReactCrop
              src={photoData}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          }
          {
            photoDataToCrop &&
            <div className="UxCommon_PhotoCropDialog_Buttons">
              <Button
                onClick={this.onSaveClick}
                className="UxCommon_PhotoCropDialog_Button"
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                className="UxCommon_PhotoCropDialog_Button"
              >
                Cancel
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PhotoCropDialog;
