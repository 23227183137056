import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ButtonRect from '../../../../UxCommon/ButtonRect/ButtonRect';
import CloseButton from '../../../../UxCommon/CloseButton/CloseButton';

class DeleteAlbumDialog extends PureComponent {
  static propTypes = {
    onDeleteEverythingClick: PropTypes.func.isRequired,
    onDeleteAlbumOnlyClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    albumTitle: PropTypes.string,
  }

  render() {
    const {
      onDeleteEverythingClick,
      onDeleteAlbumOnlyClick,
      onCancelClick,
      albumTitle,
    } = this.props;
    let question;
    if (albumTitle) {
      const wont = "won't";
      question = (
        <span>
          Are you sure you want to delete the album <b style={{ color: '#171717' }}>{albumTitle}</b>?
          You {wont} get it back if you do.
        </span>
      );
    } else {
      question = "Are you sure you want to delete your data?  You won't get it back if you do.";
    }
    return (
      <div className="AlbumDialod_DeleteAlbumDialog">
        <CloseButton size={10} onClick={onCancelClick} />
        <div className="AlbumDialod_DeleteAlbumDialog_Title">
          Delete Album?
        </div>
        <div className="AlbumDialod_DeleteAlbumDialog_Question">
          {question}
        </div>
        <ButtonRect
          className="AlbumDialod_DeleteAlbumDialog_Btn primary"
          onClick={onDeleteEverythingClick}
        >
          Delete Everything in Album
        </ButtonRect>
        <ButtonRect
          className="AlbumDialod_DeleteAlbumDialog_Btn primary"
          onClick={onDeleteAlbumOnlyClick}
          style={{ marginBottom: '30px' }}
        >
          Delete Album Only.  Keep Photos and Videos
        </ButtonRect>
        <ButtonRect
          className="AlbumDialod_DeleteAlbumDialog_Btn"
          onClick={onCancelClick}
        >
          Cancel
        </ButtonRect>
      </div>
    );
  }
}

export default DeleteAlbumDialog;
