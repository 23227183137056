import { connect } from 'react-redux';
import Component from './ToggleView.component';
import {changeViewSelection, updateCardView} from '../../+store/roster/roster.actions';

const mapStateToProps = state => ({
  isLoggedIn: !!state.data.cognito.uuid,
  viewSelection: state.modules.coachWorld.roster.viewSelection,
});

const mapDispatchToProps = dispatch => ({
  changeViewSelection: (newSelection) => {
    dispatch(changeViewSelection(newSelection));
  },
  updateCardView: (newSelection) => {
    dispatch(updateCardView(newSelection))
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
