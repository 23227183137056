
export class Team {
  constructor() {
    this.id = 0;
    this.sportId = 0;
    this.googleId = '';
    this.googleAddress = '';
    this.googleName = '';
    this.mascot = '';
    this.createdAt = '';
    this.updatedAt = '';
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.sportId = response.sport_id;
    this.googleId = response.g_places_uuid;
    this.googleAddress = response.g_address;
    this.googleName = response.g_name;
    this.mascot = response.mascot;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}

export const mapTeamApiToUi = (response) => {
  const team = new Team();
  team.fromApiResponse(response);
  return team;
};

export const mapTeamsApiToUi = (response) => {
  const teams = {};
  response.forEach((rawTeam) => {
    const team = mapTeamApiToUi(rawTeam);
    if (teams[team.sportId]) {
      teams[team.sportId].push(team);
    } else {
      teams[team.sportId] = [team];
    }
  });
  return teams;
};

export const mapTeamsApiToUiFlat = response => (
  response.map(rawTeam => mapTeamApiToUi(rawTeam))
);
