import { connect } from 'react-redux';
import queryString from 'query-string';

import Component from './SidebarSportSelect.component';

const mapStateToProps = (state, ownProps) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { sport } = parsedQuery;
  const { defaultValue } = ownProps;
  return {
    sport: sport || String(defaultValue),
  };
};

const mapDispatchToProps = () => ({
  onSortByChange: (location, history, value) => {
    const parsedQuery = queryString.parse(location.search);
    parsedQuery.sport = value;
    history.push({
      search: queryString.stringify(parsedQuery),
    });
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
