import { PHOTO_GET_PROFILE_PHOTOS_SUC } from '../../../actions/data/photo';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../../actions/data/cognito';

const profilePhotos = (state = [], action) => {
  switch (action.type) {
    case PHOTO_GET_PROFILE_PHOTOS_SUC:
      return action.photos;
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return [];
    default:
      return state;
  }
};

export default profilePhotos;
