import { createSelector } from 'reselect';
import { getFilteredSearchedSortedSelectorCreator } from '../roster/roster.selectors';

export const getAthletes = state => state.modules.coachWorld.report.athletes;
export const getReportId = (state, ownProps) => ownProps.match.params.reportId;
export const getAthletesRoster = state => state.modules.coachWorld.roster.athletes;

export const selectReportAthletes = createSelector(
  getReportId,
  getAthletes,
  getAthletesRoster,
  (reportId, athletes, profiles) => {
    if (!athletes[reportId] || !athletes[reportId].length) return [];
    const athletesWithProfiles = [];
    if (profiles) {
      athletes[reportId].forEach((athlete) => {
        const clonedAthlete = { ...athlete };
        const profile = profiles.find(p => (p.userId === athlete.athlete_uuid));
        if (profile) {
          clonedAthlete.name = profile.name;
          clonedAthlete.first = profile.first;
          clonedAthlete.last = profile.last;
          clonedAthlete.grade = profile.grade;
          clonedAthlete.gender = profile.gender;
          clonedAthlete.heightFt = profile.heightFt;
          clonedAthlete.heightIn = profile.heightIn;
          clonedAthlete.weight = profile.weight;
          clonedAthlete.gradYear = profile.gradYear;
          clonedAthlete.fromProfile = true;
        }
        athletesWithProfiles.push(clonedAthlete);
      });
      return athletesWithProfiles;
    }
    return athletes;
  },
);

export const selectFilteredReportAthletes = (
  getFilteredSearchedSortedSelectorCreator(selectReportAthletes)
);

const getSchoolTeams = state => state.modules.coachWorld.roster.schoolTeams;
const getSchoolId = (state, ownProps) => ownProps.match.params.schoolId;
const getReportTeams = state => state.modules.coachWorld.report.teams;
const getSchoolStyles = state => state.modules.coachWorld.coachWorld.schoolStyle;

export const selectTeams = createSelector(
  getSchoolTeams,
  getReportTeams,
  getSchoolStyles,
  getSchoolId,
  getReportId,
  (schoolTeams, allReportTeams, schoolStyles, schoolId, reportId) => {
   // debugger;
    const reportTeams = allReportTeams[reportId];
    const schoolStyle = schoolStyles.find(ss => (ss.id === schoolId));
    if (reportTeams && schoolTeams.length > 0) {
      const combinedTeams = [];
      reportTeams.forEach((rt) => {
        const schoolTeam = schoolTeams.find(st => st.id === rt.team_uuid) || {};
        combinedTeams.push({
          schoolStyle,
          ...schoolTeam,
          ...rt,
        });
      });
      // combinedTeams.sort((a, b) => a.teamRef.orderby - b.teamRef.orderby);
      combinedTeams.sort((a, b) => {
        const aTeamRefOrderBy = a.teamRef && a.teamRef.orderby || 0;
        const bTeamRefOrderBy = b.teamRef && b.teamRef.orderby || 0;
        return aTeamRefOrderBy - bTeamRefOrderBy
      });
      return combinedTeams;
    }
    return [];
  },
);

