import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ControlErrors from '../ControlErrors';

class TextControl extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    myOnChange: PropTypes.func,
    myOnBlur: PropTypes.func,
    errors: PropTypes.array,
    help: PropTypes.any,
    defaultValue: PropTypes.any,
  }

  constructor(props) {
    super(props);
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  state = {
    value: '',
    defaultValue: '',
  }

  componentDidUpdate() {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
    && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  setDefault = (defaultValue) => {
    const { myOnChange } = this.props;
    let value = defaultValue;
    if (myOnChange) {
      value = myOnChange(value);
    }
    this.setState({
      defaultValue,
      value,
    });
  }

  handleChange = (event) => {
    const { myOnChange, label, type } = this.props;
    let value = '';
    if (myOnChange) {
      value = myOnChange(event.target.value);
    }
    if (type === 'password') {
      this.setState({ value: value  });
    } else {
      this.setState({ value: value || event.target.value });
    }
  }

  handleBlur = (event) => {
    const { myOnBlur } = this.props;
    let value = '';
    if (myOnBlur) {
      value = myOnBlur(event.target.value);
    }
    this.setState({ value: value || event.target.value });
  }

  render() {
    const {
      className, label, errors, help, myOnChange, myOnBlur, defaultValue, ...inputProps
    } = this.props;
    const { value } = this.state;
    return (
      <div className={`SimpleTextControl_Group ${className}`}>
        <label className="SimpleTextControl_Label" htmlFor={this.id}>{label}</label>
        <input
          id={this.id}
          className="SimpleTextControl"
          type="text"
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          {...inputProps}
        />
        {!!help && help}
        <ControlErrors errors={errors} />
      </div>
    );
  }
}

export default TextControl;
