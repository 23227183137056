import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Caption from '../../../UxCommon/Form/DatePicker/DatePickerCaption';
import {SingleDatePicker} from 'react-dates-custom-month-year-navigation';
import ControlErrors from '../../../../modules/CodeSignUp/SimpleForm/ControlErrors/index';



const DatePicker = (props) => {
    const [dobDate, setdobDate] = useState(null);
    const [focused, setfocused] = useState(false);
    const [dateDefaultValue, setDateDefaultValue] = useState('');
    const {errors} = props;

    const onDobDateChanged = (date) => {
        const {onMyChange} = props;
        if(onMyChange) {
            onMyChange(date);
        }
        setdobDate(date);
    }
    
    const onDateChange = (date) => {
        const {onMyChange} = props;
        if(onMyChange) {
            onMyChange(date);
        }
        setdobDate(date);
    }

    const onFocusChange = ({focused}) =>{
        setfocused(focused);
    }

    const setDefault = (defaultValue) => {
        const { onMyChange } = props;
        let value = defaultValue;
        if (onMyChange) {
            value = onMyChange(value);

        }
        setdobDate(value);
        setDateDefaultValue(value);
    }

    useEffect( () => {
        const {defaultValue} = props;
        if (defaultValue !== null 
            && defaultValue !== undefined 
            && dobDate !== dateDefaultValue) {
                setDefault(defaultValue);
            }
    }, [props.defaultValue]);


    const isOutsideRange = () => false;
    const renderCaption = (captionProps) => {
    const { onMonthSelect, onYearSelect, ...otherProps } = captionProps;

    const myOnMonthSelect = (month, value) => {
        onDateChange(moment(month).month(value));
        onMonthSelect(month, value);
    };

    const myOnYearSelect = (month, value) => {
        onDateChange(moment(month).year(value));
        onYearSelect(month, value);
    };

    return (
      <Caption
        {...otherProps}
        onMonthSelect={myOnMonthSelect}
            onYearSelect={myOnYearSelect}
          />
        );
    }
    
    return ( <div className='Date_picker_athlete'>
        <label className="Athlete_birth_date_picker_label">{'Athlete Birthday'}</label>
        <div className='Athlete_birth_date_picker'>
            <SingleDatePicker
                date={dobDate} // momentPropTypes.momentObj or null
                onDateChange={(date) => onDobDateChanged(date)} // PropTypes.func.isRequired
                focused={focused} // PropTypes.bool
                onFocusChange= {onFocusChange} // PropTypes.func.isRequired
                id="123" // PropTypes.string.isRequired,
                numberOfMonths={1}
                block
                isOutsideRange={ isOutsideRange}
                renderCaption={renderCaption}
                placeholder={"Athlete Birthday"}
            />
        </div>
        <div className='date_picker_error_message'>
            <ControlErrors errors={errors} />
        </div>
    </div>

    )


}

export default DatePicker;