export default class Base {
  constructor() {
    this.id = '';
    this.ownerId = '';
    this.createdAt = '';
    this.updatedAt = '';
  }

  clone() {
    const base = new Base();
    base.id = this.id;
    base.ownerId = this.ownerId;
    base.createdAt = this.createdAt;
    base.updatedAt = this.updatedAt;
  }

  fromApiResponse(response) {
    this.id = response.id;
    this.ownerId = response.owner_id;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}
