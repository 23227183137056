import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/PlayerData/PlayerData';

import { getPlayerData, getSelectedSport, getCurrentUuid, getProfile,getCurrentSportId } from '../../UxCommon/Utils';
import { sidebarOpenShareDialog, playerProfileGet } from '../../../store/actions/ui/uxProfile';
import { dataPageShareUri, routeChangedAlert } from '../../../store/actions/ui/routes';


const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  return {
    currentUuid,
    authenticating: state.ui.app.mobile.authenticating,
    currentRoute: ownProps.currentRoute || state.ui.app.routes.currentRoute,
    playerData: getPlayerData(state, ownProps.match),
    loading:!!state.ui.uxPlayerData.tests.perfLoadingMsg,
    selectedUserSport: getSelectedSport(state, ownProps.match),
    sports: state.data.sport.sports,
    currentSportId: getCurrentSportId(state),
    videoData: state.data.photo.videos,
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    profile: getProfile(state, ownProps.match),
    isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { isCoach,...newStateProps } = stateProps;
  const { selectedUserSport, currentUuid } = newStateProps;
  const coachSelectedSchoolID  = ownProps.match.params.schoolId;

  return {
    ...newStateProps,
    ...ownProps,
    dataShareUri: selectedUserSport ? dataPageShareUri(
      currentUuid,
      newStateProps.selectedUserSport.sportId,
      coachSelectedSchoolID,
    ) : undefined,
    onShareClick: () => {
      dispatch(sidebarOpenShareDialog(dataPageShareUri(currentUuid, selectedUserSport.sportId,coachSelectedSchoolID)));
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);
export default Container;
