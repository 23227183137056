import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import AwardPrintExperiment from '../PrintExperiment/AwardPrintExperiment.component';
import CombineAwardPage from './CombineAwardPage.component';
class CombineAwards extends PureComponent {
  static propTypes = {
    athletes: PropTypes.array.isRequired,
    combine: PropTypes.object.isRequired,
    comparedAwards: PropTypes.array,
    createAward: PropTypes.func,
    denyAward: PropTypes.func,
    updateCombine: PropTypes.func,
    getCombineAwards: PropTypes.func,
    savedCombineAwards: PropTypes.array,
    getCombineAndPrevCombineTest: PropTypes.func,
    hideCombineAwardDialog: PropTypes.func,
    showCombineAwardDialog: PropTypes.func,
    isAwardGeneratedDialogVisible: PropTypes.bool,
    loadingAwardButton: PropTypes.bool,
    isSaveCombineAwardsDialogVisible: PropTypes.bool,
    hideGeneratedAwardDialog: PropTypes.func,
    shouldUpdate: PropTypes.bool,
  }

  render() {
    const {
      athletes,
      comparedAwards,
      combine,
      savedCombineAwards,
      bank,
      match,
      columns,
      currentSchoolStyle
    } = this.props;
    return (
      <React.Fragment>
        <CombineAwardPage
          {...this.props}
        />
      
        <AwardPrintExperiment
          combine={combine}
          bank={bank}
          columns={columns}
          athletes={athletes}
          match={match}
          comparedAwards={comparedAwards}
          currentSchoolStyle={currentSchoolStyle}
          savedCombineAwards={savedCombineAwards}
        />
      </React.Fragment>
    );
  }
}


export default CombineAwards;
