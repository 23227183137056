import React from 'react';
import PropTypes from 'prop-types';

const CollapsableButton = ({ onClick, className, collapsed }) => (
  <div className="CoachCollapsableButton_Wrapper">
    <button
      className={`CoachCollapsableButton ${className || ''}`}
      onClick={onClick}
    >
      {
        collapsed ?
          <div className="CoachCollapsableButton_Plus" />
        :
          <div className="CoachCollapsableButton_Minus" />
      }
    </button>
  </div>
);
CollapsableButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  collapsed: PropTypes.bool.isRequired,
};

export default CollapsableButton;
