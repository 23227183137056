import { combineEpics } from "redux-observable";

import {
  formValidationEpicsFactory,
  usernameOnChangeEpic,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory
} from "../formUtils/operators";

import { validateIsPhoneOrEmail, validateLength, validateRequired } from "../formUtils/validators";

const mergeAnotherAccountFormValidation = {
  'forms.mergeAnotherAccount.username': [
    value => validateLength(1)(value),
    value => validateLength(1)(value),
    value => validateIsPhoneOrEmail()(value),
    value => validateRequired()(value),
  ],
};


export const mergeAnotherAccountFormEpic = combineEpics(
  validationOnSubmitEpicFactory(mergeAnotherAccountFormValidation, 'forms.mergeAnotherAccount', 'mergeAnotherAccount'),
  validationFailedEpicFactory('forms.mergeAnotherAccount', 'mergeAnotherAccount'),
  ...formValidationEpicsFactory(mergeAnotherAccountFormValidation, 'mergeAnotherAccount'),
  usernameOnChangeEpic('forms.mergeAnotherAccount.username', state => state.forms.mergeAnotherAccount.username),
);

export const MERGE_ANOTHER_ACCOUNT_FORM_INIT = 'MERGE_ANOTHER_ACCOUNT_FORM_INIT';
export const mergeAnotherAccountFormInit = () => ({
  type: MERGE_ANOTHER_ACCOUNT_FORM_INIT,
});
