import { PHOTO_GET_MEDIA_SUC, MULTI_DELETE_TAG_PHOTO_SUC, TAG_CREATE_SUC, ALBUM_CREATE_SUC, ALBUM_UPDATE_SUC, PHOTO_DELETE_SUC, VIDEO_DELETE_SUC, DELETE_ALBUM_EVERYTHING_SUC } from '../../../actions/data/photo';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT
} from '../../../actions/data/cognito';

const tagRelMediaCollection = (state = {}, action) => {
  switch (action.type) {
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.tagRelMediaCol,
      });
    case MULTI_DELETE_TAG_PHOTO_SUC:
      return Object.assign({}, state, {
        [action.uuid]:
            state[action.uuid].filter(rel => !(rel.mediaId === action.media.id &&
              rel.tagId === action.tags.id))
        ,
      });
    case TAG_CREATE_SUC: {
      if (action.tagRelMedia) {
        return Object.assign({}, state, {
          [action.uuid]: [
            ...state[action.uuid].filter(rel => !(rel.mediaId === action.tagRelMedia.mediaId &&
                rel.tagId === action.tagRelMedia.tagId)),
            action.tagRelMedia,
          ],
        });
      }
      return state;
    }
    case ALBUM_CREATE_SUC:
    case ALBUM_UPDATE_SUC: {
      if (!action.tagRelMediaCollection || action.tagRelMediaCollection.length === 0) {
        return state;
      }

      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(rel => action.tagRelMediaCollection
            .some(tagRel => tagRel.tagId !== rel.tagId && tagRel.mediaId !== rel.mediaId)),
          ...action.tagRelMediaCollection,
        ],
      });
    }
    case PHOTO_DELETE_SUC:
    case VIDEO_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(rel => rel.mediaId !== action.mediaId),
      });
    case DELETE_ALBUM_EVERYTHING_SUC: {
      const mediaIds = [...action.photoIds, ...action.videoIds];
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(rel => !mediaIds.includes(rel.mediaId)),
      });
    }
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default tagRelMediaCollection;
