import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../../config/urls';

export const getPlayerSchool = (uuid, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.school(uuid),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    )
  );
};

export const getPlayerLatestSchool = (uuid, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.latestSchool(uuid),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    )
  );
};

export const getPlayerEnrolledSchool = (uuid, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.enrolledSchool(uuid),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    )
  );
};

export const getPlayerProfile = (uuid, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.profile(uuid),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    )
  );
};

export const getProfileData = (uuid, sportId, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.profileData(uuid, sportId),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    ));
};

export const getProfileDuplicates = (uuid) => {
  return (
    ajax.getJSON(
      Urls.profileDuplicates(uuid),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    ));
};

export const getPlayerTeamsAndDetails = (uuid, sportId, token) => {
  const Authorization = token ? `Bearer ${token}` : '';
  return (
    ajax.getJSON(
      Urls.userTeamsAndDetails(uuid, sportId),
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization,
      },
    ));
};

export const profileDelete = (uuid, token) => ajax.delete(
  Urls.profile(uuid),
  {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const profileUpdate = (uuid, apiUser, token, isCoach = false) => ajax.put(
  isCoach ? Urls.profileByCoach(uuid) : Urls.profile(uuid),
  JSON.stringify(apiUser),
  {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const savePrivacyData = (toggle, privacyType, token, playerUuid = null, combineId = null) => ajax.put(
  playerUuid && !combineId ?
    Urls.privacy.saveCoach(toggle, privacyType, playerUuid) :
    (!playerUuid && combineId ? Urls.privacy.saveCoachLeaderboardPrivacy(toggle,combineId):
    Urls.privacy.save(toggle, privacyType)),
  JSON.stringify(),
  {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export default 'profile/apiCalls.js';
