import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetOldProfilePhotos';
import { getOldProfilePhotos } from '../../../store/actions/ui/uxEditProfile';
import { getCurrentUuid } from '../Utils';

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    uuid: isCoach ? getCurrentUuid(state, ownProps.match) : state.data.cognito.uuid,
    loading: state.ui.uxEditProfile.profilePhoto.loadingPhotos,
    loadingMsg: state.ui.uxEditProfile.profilePhoto.loadingPhotosMsg,
    needsInit: state.ui.uxEditProfile.profilePhoto.oldPhotosNeedsInit,
  });
};

const mapDispatchToProps = dispatch => ({
  getOldProfilePhotos: getOldProfilePhotos(dispatch),
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
