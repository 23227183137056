import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton, ButtonIconOnly } from '../../../../components/UxCommon';

const AddTeamModal = ({
  coachCode,
  athleteCode,
  onCancel,
  handleCoachCopy2Clipboard,
  handleAthleteCopy2Clipboard,
  message,
}) => (
  <div className="CoachSignOutModal_Backdrop">
    <div className="CoachSignOutModal" style={{ minWidth: 'unset' }}>
      <CloseButton size={15} onClick={onCancel} />
      <div className="CoachSignOutModal_Header" />
      <div className="CoachSignOutModal_Body">
        <div className="Dashboard_CoachCodesBody">
          <div className="Dashboard_CoachCodesRow">
            <div className="Dashboard_CoachCodesRowTitle">
              Coach Code:
            </div>
            <div id="coachCodeText" className="Dashboard_CoachCodesRowCode">
              {coachCode}
            </div>
            <ButtonIconOnly className="CoachCode_Btn" onClick={handleCoachCopy2Clipboard} iconName="docs" />
          </div>
          <div className="Dashboard_CoachCodesRow">
            <div className="Dashboard_CoachCodesRowTitle">
              Athlete Code:
            </div>
            <div id="athleteCodeText" className="Dashboard_CoachCodesRowCode">
              {athleteCode}
            </div>
            <ButtonIconOnly className="CoachCode_Btn" onClick={handleAthleteCopy2Clipboard} iconName="docs" />
          </div>
        </div>
      </div>
      {
            message &&
            <div className="CoachCode_Info">
              {message}
            </div>
      }
      <div className="CoachSignOutModal_Footer">
        <ButtonRect
          className="CoachSignOutModal_Btn"
          type="button"
          onClick={onCancel}
        >
          Close
        </ButtonRect>
      </div>
    </div>
  </div >
);
AddTeamModal.propTypes = {
  coachCode: PropTypes.string.isRequired,
  athleteCode: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleCoachCopy2Clipboard: PropTypes.func.isRequired,
  handleAthleteCopy2Clipboard: PropTypes.func.isRequired,
};

export default AddTeamModal;
