import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect } from '../../../../components/UxCommon';
import coachAward from '../../../../assets/images/CoachAward.png';

class CombineAwardRowInner extends PureComponent {
  static propTypes = {
    combine: PropTypes.object,
    athleteId: PropTypes.string,
    award: PropTypes.object,
    createCombineAward: PropTypes.func,
    savedCombineAwards: PropTypes.array,
    loadingAwardButton: PropTypes.bool,
    isAthleteCoachedByUser: PropTypes.bool,
  };


  state = {
    isGranting: false,
    isChecked: true,
  };

  componentDidMount() {
    this.props.awardList(this.state.isChecked, this.props.award);
  }

  componentDidUpdate(oldProps) {
    const { loadingAwardButton, isSelectAll, awardsCount } = this.props;
    if (oldProps.isSelectAll !== isSelectAll) {
        this.setState({
          isChecked: isSelectAll
        });
        this.props.awardList(isSelectAll, this.props.award);
      }
    else if(oldProps.awardsCount !== awardsCount && awardsCount && isSelectAll) {
      this.setState({
        isChecked: true
      })
      this.props.awardList(isSelectAll, this.props.award);
    }
    if (oldProps.loadingAwardButton !== loadingAwardButton && !loadingAwardButton) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isGranting: false,
      });
    }
  }

  createCombineAward = () => {
    this.setState({
      isGranting: true,
    });
    const {
      createCombineAward,
      athleteId,
      combine,
      award,
    } = this.props;
    const data = {
      name: award.awardTitle,
      description: award.awardDesc,
      award_type: 'combine',
      user_id: athleteId,
    };
    createCombineAward(combine.id, data);
  }

  //handle change
  handleChange =  async() => {
    await this.setState({
      isChecked: !this.state.isChecked
    })
    this.props.awardList(this.state.isChecked, this.props.award);
    this.props.handleAwardsCount(document.querySelectorAll("input[name='grant_award[]']").length)
  }

  render() {
    const {
      combine,
      award,
      savedCombineAwards,
      isAthleteCoachedByUser,
      schoolAwardLogo
    } = this.props;

    const { isChecked } = this.state;

    if (!award) {
      return null;
    }
    let awardImgSrc = coachAward;
    if (!award.isVcTest && schoolAwardLogo)
    {
      awardImgSrc = schoolAwardLogo.combine_award_logo;
    }
    const savedBeforeObject = savedCombineAwards && savedCombineAwards
      .find(c => (c.name === award.awardTitle)
        && c.combineId === combine.id.toString()
        && c.ownerId === award.athleteId);
    if (combine.awardsGeneratedBool === 1 && !savedBeforeObject) {
      return null;
    }
    return (
      <div className="Combine_Title_Row_Awards">
        <div className="Combine_Title_Row_Awards_Left">
          <div className="Combine_Title_Row_Awards_Image">
            <img height={85} width={85} src={awardImgSrc} alt="award-img" />
          </div>
          <div className="Combine_Title_Row_Awards_Title checkupdate">
            {award.awardTitle}
          </div>
        </div>
        <div className="Combine_Title_Row_Awards_Title_Detail">
          {award.awardDesc.replace( /\s-\s?/i , '  |  ')}
        </div>
        <div className="Combine_Title_Row_Awards_Date">
          {`Awarded ${combine.closeDate.format('MM/DD/YY')}`}
        </div>
        <div className="Combine_Title_Row_Awards_GrantDeny">
          {
            !savedBeforeObject ?
              combine.isCombineCreator ?
                <div>
                  <input type="checkbox" name="grant_award[]" id="grant_award[]" checked={isChecked} onChange={this.handleChange} className= {`margin-3 CoachCheckboxControl ${isChecked ? 'checked' : ''}`} />
                  <label htmlFor="">Grant Award</label>
                </div>
                 : null
              :
                 <div className="Combine_Title_Row_Awards_Granted">
                   Granted Award
                 </div>
          }
        </div>
      </div>

    );
  }
}

export default CombineAwardRowInner;
