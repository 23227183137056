import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import {  Row, Col } from "../../UxCommon";
import { TextControl, Button, Errors } from "../../UxCommon/Form";
import { parserTwitterHandleSlug } from "../../../utils/parsers";
import { Link } from 'react-router-dom';
class TwitterHandle extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    formInit: PropTypes.func.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
  };


  componentDidMount() {
    const { formInit, profile } = this.props;

    if (profile.twitterHandle) {
      formInit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile, formNeedsInit, formInit } = this.props;
      if(profile.twitterHandle && formNeedsInit ){
      formInit();
    }
  }

  submit = () => {
    this.props.submit();
  };

  renderTwitterUrl = twitterHandle => {
    if (typeof window !== "undefined") {
      return `https://twitter.com/${twitterHandle.replaceAll(/[@.]/gi, '')}`;
    }
    return "";
  };
  render() {
    const { rrfForm, message, profile } = this.props;
    return (
      <Form model="forms.twitterHandle" onSubmit={this.submit}>
        <Row>
          <Col className="UxEditProfile_TwitterHandle_Input">
            {profile ? (
              <div>
                <TextControl
                  label="Twitter Handle"
                  placeholder="Twitter Handle"
                  model="forms.twitterHandle.url"
                  rrfField={rrfForm.url}
                  parser={parserTwitterHandleSlug}
                  defaultValue={profile.twitterHandle.replace('.','')}
                  maxLength = {25}
                />
                {(profile.twitterHandle).length > 1 &&

                   <a className="UxEditProfile_TwitterHandle_Displayer" target="_blank" href=   {this.renderTwitterUrl(profile.twitterHandle)}>https://twitter.com/{(profile.twitterHandle).replaceAll(/[@.]/gi, "")}</a>

                }
              </div>
            ) : (
              <div />
            )}
          </Col>
          <Col className="col-auto UxEditProfile_TwitterHandle_BtnWrapper">
            <Button type="submit" className="UxEditProfile_TwitterHandle_Btn">
              Save Changes
            </Button>
          </Col>
        </Row>
        <Errors
          className="UxEditProfile_TwitterHandle_Errors"
          valid={
            rrfForm.$form.valid || !Object.values(rrfForm.$form.errors).length
          }
          errors={rrfForm.$form.errors}
        />
        {message && (
          <Row>
            <Col> {message} </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export default TwitterHandle;
