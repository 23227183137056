import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RosterViewOptions } from '../../+store';
import EditButton from '../../common/EditButton';
import Icon from '../../common/Icon';

class CoachCard extends PureComponent {
  static propTypes = {
    coach: PropTypes.object.isRequired,
    viewSelection: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  flipCard = () => {
    this.setState({ flipped: !this.state.flipped });
  }

  shouldUpdate = () => { }

  render() {
    const {
      coach, viewSelection,
    } = this.props;
    const { flipped } = this.state;

    let noLineStyle = {};
    if (coach.coachCollege === null) {
      noLineStyle = { borderRight: 'none' };
    }

    const titleStr = coach.coachPosition || 'Coach';
    let yearStr = '';
    if (coach.coachCareerCoaching && !Number.isNaN(Number(coach.coachCareerCoaching))) {
      yearStr = `${coach.coachCareerCoaching} Years`;
    } else if (coach.coachCareerCoaching) {
      yearStr = `${coach.coachCareerCoaching}`;
    }

    let playStr = '';
    if (coach.coachCareerPlaying && !Number.isNaN(Number(coach.coachCareerPlaying))) {
      playStr = `${coach.coachCareerPlaying} Years`;
    } else if (coach.coachCareerPlaying) {
      playStr = `${coach.coachCareerPlaying}`;
    }

    if (viewSelection === RosterViewOptions.card) {
      return (
        <div className="CoachCard">
          <div className={['CoachCard_Container', flipped ? 'flipped' : ''].join(' ')}>
            <div className="side CoachCard_Front">
              {
                coach.headshotUrl ?
                  <div
                    className="CoachCard_Image"
                    style={{
                      backgroundImage: `url(${coach.headshotUrl})`,
                    }}
                  />
                  :
                  <div className="CoachCard_Image">
                    <Icon iconName="photo2" className="CoachCard_PhotoIcon" />
                  </div>
              }
              {
                coach === undefined && (
                  <div className="CoachCard_Loading">Loading ...</div>
                )
              }
              {
                coach &&
                (
                  <React.Fragment>
                    <div className="CoachCard_Details">
                      <div className="CoachCard_Name">
                        {coach.name}
                      </div>
                      <div className="CoachCard_LineSeparator" />
                      <div className="CoachCard_Detail">
                        {titleStr}
                      </div>
                      <div className="CoachCard_Detail">
                        {coach.coachCollege}
                      </div>
                      <div className="CoachListItem_Buttons">
                        <div className="CoachListItem_FlipButton">
                          <EditButton iconName="flip-card" onClick={this.flipCard} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>

                )
              }
            </div>
            <div className="side CoachCard_Back">
              {
                coach.headshotUrl ?
                  <div
                    className="CoachCard_Image"
                    style={{
                      backgroundImage: `url(${coach.headshotUrl})`,
                    }}
                  />
                  :
                  <div className="CoachCard_Image">
                    <Icon iconName="photo2" className="CoachCard_PhotoIcon" />
                  </div>
              }
              {
                coach === undefined && (
                  <div className="CoachCard_Loading">Loading ...</div>
                )
              }
              {
                coach && (
                  <React.Fragment>
                    <div className="CoachCard_BackDetails">
                      {/* {
                         coach.coachSpouse && (
                          <div className="CoachCard_BackDetail">
                            <div className="CoachCard_BackDetail_Label">Spouse</div>
                            <div className="CoachCard_BackDetail_Content">
                             {coach.coachSpouse || '-'}
                            </div>
                          </div>
                         )
                      }
                      {
                        coach.coachKids && (
                          <div className="CoachCard_BackDetail">
                            <div className="CoachCard_BackDetail_Label">Children</div>
                            <div className="CoachCard_BackDetail_Content">
                            {coach.coachKids || '-'}
                            </div>
                          </div>
                        )
                      } */}
                      <div className="CoachCard_BackDetail">
                        <div className="CoachCard_BackDetail_Label">Title</div>
                        <div className="CoachCard_BackDetail_Content">
                        {coach.coachHighSchool || '-'}
                        </div>
                      </div>
                      <div className="CoachCard_BackDetail">
                        <div className="CoachCard_BackDetail_Label">Instagram Handle</div>
                        <div className="CoachCard_BackDetail_Content">
                        {yearStr || '-'}
                        </div>
                      </div>
                      <div className="CoachCard_BackDetail">
                        <div className="CoachCard_BackDetail_Label">Twitter Handle</div>
                        <div className="CoachCard_BackDetail_Content">
                        {playStr || '-'}
                        </div>
                      </div>
                      <div className="CoachCard_BackDetail">
                        <div className="CoachCard_BackDetail_Label">Website</div>
                        <div className="CoachCard_BackDetail_Content">
                        {coach.coachHobbies || '-'}
                        </div>
                      </div>
                      <div className="CoachListItem_BackButtons">
                        <div className="CoachListItem_FlipButton">
                          <EditButton iconName="flip-card" onClick={this.flipCard} className="CoachCard-FlipButton" />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="CoachListItem">
        {
          coach.headshotUrl ?
            <div
              className="CoachListItem_Image"
              style={{
                backgroundImage: `url(${coach.headshotUrl})`,
              }}
            />
            :
            <div className="CoachListItem_Image">
              <Icon iconName="photo2" className="CoachListItem_PhotoIcon" />
            </div>
        }
        {
          coach === undefined && (
            <div className="CoachListItem_Loading">Loading ...</div>
          )
        }
        <div className="CoachListItem_Info">
          {
            coach && (
              <React.Fragment>
                <div className="CoachListItem_Name">
                  {coach.name}
                </div>
                <div className="CoachAthleteListItem_Line" />
                <div className="CoachListItem_Details">
                  <div
                    className="CoachListItem_Position"
                    style={noLineStyle}
                  >
                    {coach.coachPosition || 'Coach'}
                  </div>
                  <div className="CoachListItem_University">
                    {coach.coachCollege}
                  </div>
                </div>
                <div className="CoachListItem_FlexGrow" />
              </React.Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

export default CoachCard;
