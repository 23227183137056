export class Report {
  constructor() {
    this.id = 0;
    this.orderNum = 0;
    this.combineId = 0;
    this.secondCombineId = 0;
  }

  static fromApi(response) {
    const report = new Report();
    report.id = response.id;
    report.orderNum = response.order_num;
    report.combineId = response.combine_id;
    report.secondCombineId = response.second_combine_id;
    return report;
  }
}

export default {};
