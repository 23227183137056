import React from 'react';

import {validateEmail} from '../../../../store/actions/ui/formUtils/validators';


export const checkUsername = (value) => {
  const possibleErrors = [
    validateEmail()(value).email,
  ];
  const errors = possibleErrors.reduce((prev, curr) => {
    if (curr) {
      prev.push(curr);
    }
    return prev;
  }, []);
  return errors;
};

