import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ControlErrors from '../../../../modules/CodeSignUp/SimpleForm/ControlErrors/index';

const InputControl = (props) => {
    propTypes = {
        label: PropTypes.string,
        className: PropTypes.string,
        myOnChange: PropTypes.func,
        myOnBlur: PropTypes.func,
        errors: PropTypes.array,
        help: PropTypes.any,
        defaultValue: PropTypes.any,
      }

      const {
        className, label, errors, help, myOnChange, myOnBlur, defaultValue, ...inputProps
      } = props;

      let id = `${Math.floor(Math.random() * 100000)}`;
      const [value, setValue] = useState('');
      const [inputDefaultValue, setInputDefaultValue] = useState('');

      useEffect(() => {
        if (defaultValue !== undefined 
          && defaultValue !== inputDefaultValue
          && !!defaultValue) {
            setDefault(defaultValue)
        }
      }, [defaultValue]);


      const setDefault = (defaultValue) => {
        setValue(defaultValue);
        setInputDefaultValue(defaultValue)
      }


      const handleChange = (event) => {
        const { myOnChange, label, type } = props;
        let value = '';
        if (myOnChange) {
          value = myOnChange(event.target.value);
        }
        if (type === 'password') {
          setValue(value);
        } else {
            setValue(value);
        }
      }
    
      const handleBlur = (event) => {
        const { myOnBlur } = props;
        let value = '';
        if (myOnBlur) {
          value = myOnBlur(event.target.value);
        }
        setValue(value);
      }
      

      return (
        <div className={`SimpleTextControl_Group`}>
          <label className="SimpleTextControl_Label" htmlFor={this.id}>{label}</label>
          <input
            id={id}
            className="SimpleTextControl"
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...inputProps}
          />
          {!!help && help}
          <ControlErrors errors={errors} />
        </div>
      );      
}


export default InputControl;