import { PHOTO_GET_MEDIA_SUC, ALBUM_CREATE_SUC, ALBUM_UPDATE_SUC, VIDEO_UPDATE_SUC, PHOTO_UPDATE_SUC } from '../../../actions/data/photo';
import {DELETE_PHOTO, DELETE_VIDEO, MULTI_CHANGE_ALBUM_SUCCESS} from '../../../actions/ui/uxProfile/photosVideos';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT
} from '../../../actions/data/cognito';
import {ASYNC_FINISH} from "../../../actions/ui/async";

const albumRelMediaCollection = (state = {}, action) => {
  switch (action.type) {
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.albumRelMediaCol,
      });
    case ALBUM_CREATE_SUC:
    case ALBUM_UPDATE_SUC: {
      if (!action.albumRelMediaCollection || action.albumRelMediaCollection.length === 0) {
        return state;
      }
      const albumIds = action.albumRelMediaCollection.map(x => x.albumId);
      const mediaIds = action.albumRelMediaCollection.map(x => x.mediaId);
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(rel => !(albumIds.includes(rel.albumId)
            && mediaIds.includes(rel.mediaId))),
          ...action.albumRelMediaCollection,
        ],
      });
    }
    case MULTI_CHANGE_ALBUM_SUCCESS:
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid]
            .filter(rel => !(action.mediaList.some(media => rel.albumId === media.media.oldAlbumId
              && rel.mediaId === media.media.id))),
          ...action.albumRelMedia,
        ],
      });
    case VIDEO_UPDATE_SUC:
    case PHOTO_UPDATE_SUC: {
      if (action.noAlbumChange) {
        return state;
      }
      if (action.album) {
        if (action.oldAlbums.length > 0) {
          return Object.assign({}, state, {
            [action.uuid]: [
              ...state[action.uuid]
                .filter(rel => !(rel.albumId === action.oldAlbums[0].id
                  && rel.mediaId === (action.video ? action.video.id : action.photo.id))),
              action.albumRelMedia,
            ],
          });
        }
        return Object.assign({}, state, {
          [action.uuid]: [...state[action.uuid], action.albumRelMedia],
        });
      }
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(rel => !(rel.albumId === action.oldAlbums[0].id
            && rel.mediaId === (action.video ? action.video.id : action.photo.id))),
        ],
      });
    }
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    case ASYNC_FINISH:
      if ((action.model === DELETE_PHOTO) || (action.model === DELETE_VIDEO)) {
        return Object.assign({}, state, {
          [action.data.uuid]: [
            ...state[action.data.uuid].filter(rel => rel.mediaId !== action.data.id),
          ],
        });
      }
      return state;
    default:
      return state;
  }
};

export default albumRelMediaCollection;
