import {combineEpics, ofType} from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {of} from 'rxjs';
import {getTokenFragment, manualCancelApiCallFragment, cancelOnRouteChange} from '../../../../store/actions/ui/uxProfile/utils';
import { asyncFinishAction, asyncErrorAction } from '../../../../store/actions/ui/async';
import {apiGetAthleteProfile, apiGetPlayerData} from './prospectSheet.api'
import { 
  GET_ATHLETE_DETAILS, 
  GET_PLAYER_DATA, 
  UPLOAD_TRANSCRIPT,
  DELETE_TRANSCRIPT,
  GET_TRANSCRIPT
} from './prospectSheet.actions';
import {mapUserApiToUi} from '../../../../store/actions/data/user/profile/models';
import {personalInfo, athleteSchoolTeamInfo} from './prospectSheet.model';
import {PlayerDataTestResult} from '../../../../store/actions/data/sport/tests'

import {
  createTranscript,
  uploadTranscriptToS3,
  flagTranscriptUploaded,
  getTranscript,
  deleteTranscript,
  mapTranscriptApiToUi,
} from '../../../../store/actions/data/photo';

import { Routes } from '../../../../store/actions/ui/routes';

import { ajaxErrorHandlerEpicFragment } from '../../../../store/actions/ui/ajaxErrorHandlers';

const getAthleteProfile = action$ => (
  action$.pipe(
    ofType(GET_ATHLETE_DETAILS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetAthleteProfile(token, action.teamId, action.athleteId,).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getAthleteProfile',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const profile = mapUserApiToUi(result.response.profile)
        const personalData = personalInfo(result.response);
        const schoolTeam = athleteSchoolTeamInfo(result.response.schoolTeam)
        return of(asyncFinishAction(result.action.type, GET_ATHLETE_DETAILS, {
          profile,
          personalInfo: personalData,
          schoolTeam
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

const getPlayerData = action$ => (
  action$.pipe(
    ofType(GET_PLAYER_DATA),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetPlayerData(token, action.uuid, action.sport).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getPlayerData',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const playerData = result.response.personal_records.map(data => {
          const playerInfo = new PlayerDataTestResult();
          playerInfo.fromApiResponse(data);
          return playerInfo
        });
        return of(asyncFinishAction(result.action.type, GET_PLAYER_DATA, {
          playerData
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export const getTranscriptEpic = ( action$, state$ ) => {
  return action$.pipe(
    ofType(GET_TRANSCRIPT),
    getTokenFragment(),
    switchMap(({action, token}) => (
      getTranscript(token, action.sportId, action.athleteId).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getTranscript',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
         
        return of(asyncFinishAction(result.action.type, GET_TRANSCRIPT, {
          data: mapTranscriptApiToUi(result.response), 
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
}

export const createTranscriptEpic = (action$, state$) => {
  // const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  // const isCoach = () => {
  //   const canEditObj = canEditProfile();
  //   return !!(canEditObj && canEditObj.isCoach);
  // };

  const uuid = () => {
    return state$.value.modules.prospectSheet.prospectSheet.profile.uuid;
  };
  return action$.pipe(
    ofType(UPLOAD_TRANSCRIPT),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      return createTranscript(token, { sport_id: action.sportId, athlete_id: uuid(), ext: action.file.name.split('.')[1] }).pipe(
      map(response => ({
        action,
        success: true,
        response,
        token,
      })),
      takeUntil(cancelOnRouteChange(action$, Routes.transcript, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(action.type, 'createTranscript', error),
      })),
    )}),
    switchMap((result) => {
      if (result.success) {
        return uploadTranscriptToS3(result.response.upload_url, result.action.file).pipe(
          map(response => ({ ...result, s3Response: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.transcript, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createTranscript', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return flagTranscriptUploaded(result.action.athleteId, result.action.sportId, result.response.id, result.token).pipe(
          map(response => ({ ...result, flagUploadedResponse: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.transcript, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createTranscript', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return of(
            asyncFinishAction(UPLOAD_TRANSCRIPT, 'uploadTranscript', {
              data: mapTranscriptApiToUi(result.response), 
            }),
          )
      }
      return of(result.action);
    }),
  );
};


export const deleteTranscriptEpic = (action$, state$) => {
  // const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const currSportId = () => state$.value.ui.app.routes.currentSportId;
  const currSchoolId =() => state$.value.ui.app.routes.currentSchoolId;
  // const isCoach = () => {
  //   const canEditObj = canEditProfile();
  //   return !!(canEditObj && canEditObj.isCoach);
  // };
  const uuid = () => {
    // const canEditObj = canEditProfile();
    return state$.value.modules.prospectSheet.prospectSheet.profile.uuid;;
  };
  return action$.pipe(
    ofType(DELETE_TRANSCRIPT),
    getTokenFragment(),
    switchMap(({ action, token }) => deleteTranscript(action.athleteId, action.sportId, action.transcriptId, token).pipe(
      map(() => ({ action, success: true })),
      takeUntil(cancelOnRouteChange(action$, Routes.transcript, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(DELETE_TRANSCRIPT, 'deleteTranscript', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(DELETE_TRANSCRIPT, 'deleteTranscript',
           { 
            id: result.action.transcriptId, 
            uuid: uuid(),
            sportId: currSportId(),
            schoolId: currSchoolId()
          }),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );
};


export default combineEpics(
  getAthleteProfile,
  getPlayerData,
  getTranscriptEpic,
  createTranscriptEpic,
  deleteTranscriptEpic
);
