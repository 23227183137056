import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/Bio/Bio';
import {
  playerProfileShowBio,
  playerProfileHideBio,
} from '../../store/actions/ui/uxProfile';
import { getSelectedSport } from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => ({
  userSport: getSelectedSport(state, ownProps.match),
  isBioShown: state.ui.uxProfile.playerProfile.isBioShown,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onShowBioClick: () => {
      dispatch(playerProfileShowBio());
    },
    onHideBioClick: () => {
      dispatch(playerProfileHideBio());
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
