import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../../config/urls';

export const createTwitterHandleUrl = (name, token, body = {}) => (
  ajax.post(
    Urls.twitterHandle.post(name),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const updateTwitterHandleUrl = (name, token, body = {}) => (
  ajax.put(
    Urls.twitterHandle.put(name),
    JSON.stringify(body),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const testTwitterHandleUrl = name => (
  ajax.getJSON(
    Urls.twitterHandle.get(name),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  )
);

export default 'twitterHandle/apiCalls.js';
