import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/UxCommon/Icon/Icon';

const EditButton = ({
  children, iconName, className, ...props
}) => (
  <button className={['CoachWorld_EditButton', className || ''].join(' ')} {...props}>
    <Icon iconName={iconName} />
  </button>
);
EditButton.propTypes = {
  children: PropTypes.any,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default EditButton;
