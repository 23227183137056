import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetFavorites extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getFavorites: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number,
    loading: PropTypes.object,
  };

  state = {
    loadFavoritesRequired: false,
  };

  static getDerivedStateFromProps(props, state) {
    const derivedState = {
      loadFavoritesRequired: false,
    };
    if (props.uuid !== state.prevUuid) {
      derivedState.loadFavoritesRequired = true;
      derivedState.prevUuid = props.uuid;
    }
    if (props.sportId !== state.prevSportId) {
      derivedState.loadFavoritesRequired = true;
      derivedState.prevSportId = props.sportId;
    }
    return derivedState;
  }

  componentDidMount() {
    const {
      getFavorites,
      uuid,
      sportId,
    } = this.props;
    if (uuid && sportId && !this.isLoading()) {
      getFavorites(uuid, sportId, 'Loading photos & videos');
    }
  }

  componentDidUpdate() {
    const {
      getFavorites,
      uuid,
      sportId,
    } = this.props;
    const {
      loadFavoritesRequired,
    } = this.state;
    if (loadFavoritesRequired) {
      if (uuid && sportId && !this.isLoading()) {
        getFavorites(uuid, sportId, 'Loading photos & videos');
      }
    }
  }

  isLoading = () => {
    const { loading, uuid, sportId } = this.props;
    if (loading && (loading.uuid === uuid) && (loading.sportId === sportId)) {
      return true;
    }
    return false;
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetFavorites;
