import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';
import Urls from '../../../../config/urls';

export const getUsersTeams = (uuid, token) => (
  ajax.getJSON(
    Urls.schools.getUsersTeams(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getSchoolTeamsUsers = (sportId, schoolId, token) => (
  ajax.getJSON(
    Urls.schools.getTeamUsers(sportId, schoolId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const getSchoolStyle = (schoolID, token) => (
  ajax.getJSON(
    Urls.schools.getSchoolStyle(schoolID),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetSchoolTeamsList = (schoolId, sportId = 0, token) => (
  ajax.getJSON(
    Urls.schools.getSchoolTeamsList(schoolId, sportId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const updateSchoolStyle = (schoolID, token, newSchoolStyle) => (
  ajax.put(
    Urls.schools.getSchoolStyle(schoolID),
    JSON.stringify(newSchoolStyle),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export const saveCoachProfile = (uuid, data, token) => (
  ajax.put(
    Urls.profile(uuid),
    JSON.stringify(data),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response))
);

export default 'codeSignUp.api.js';

