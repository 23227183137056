import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const SplitButton = ({
  iconName,
  onClickLeft,
  onClickRight,
  children,
  className,
  dataIconName,
  ...props
}) => (
  <div className={['UxCommon_SplitButton', className || ''].join(' ')} {...props}>
    <button onClick={onClickLeft} className="UxCommon_SplitButton_Left">
      <Icon iconName={iconName} />
    </button>
    <button onClick={onClickRight} className="UxCommon_SplitButton_Right">
      {children}
      <Icon iconName={dataIconName} style={{marginLeft:4}} />
    </button>
  </div>
);
SplitButton.propTypes = {
  children: PropTypes.any,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
};

export default SplitButton;
