import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SportButton from './SportButton/SportButton';
import { Errors } from '.././../../UxCommon/Form';

class SportPicker extends PureComponent {
  static propTypes = {
    sports: PropTypes.array.isRequired,
    pickedSportId: PropTypes.number.isRequired,
    onSportPick: PropTypes.func.isRequired,
    rrfField: PropTypes.object.isRequired,
  }

  render() {
    const {
      sports,
      pickedSportId,
      onSportPick,
      rrfField,
    } = this.props;
    return (
      <div className="EditSports_SportPicker">
        <div className="EditSports_SportPicker_Txt">
          Please select a sport to add
        </div>
        <div className="d-flex">
          {
            sports.map(sport => (
              <SportButton
                key={sport.id}
                sport={sport}
                selected={pickedSportId === sport.id}
                onSportPick={onSportPick}
              />
            ))
          }
        </div>
        <Errors model="forms.userSport.sportId" errors={rrfField.errors} valid={rrfField.valid} />
      </div>
    );
  }
}

export default SportPicker;
