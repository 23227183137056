import { validatePhone } from '../../../../store/actions/ui/formUtils/validators';
import { phoneToUsername, formatPhoneForCognito, emailToUsername } from '../../../../utils';

export const makeCognitoUsername = (username) => {
  if (validatePhone()(username).phone === '') {
    return phoneToUsername(formatPhoneForCognito(username));
  }
  return emailToUsername(username);
};

export const isMetricUnits = (state) => {
  const { schoolInfo } = state.modules.codeSignUp.codeSignUp;
  const metricSchoolCodes = ['OSGR', 'OSGT'];
  return schoolInfo && metricSchoolCodes.includes(schoolInfo.athlete_code);
};

export const getCountryCode = state => (isMetricUnits(state) ? 'za' : 'us');

export default 'common.utils.js';
