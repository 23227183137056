import { combineReducers } from 'redux';

import {
  Banks,
  isPerfTestsCreateUpdateFormModel,
  isStrengthTestsCreateUpdateFormModel,
  PD_DELETE_TEST_RESULT,
  PD_GET_CAT_PERF_TESTS,
  PD_GET_CAT_STRENGTH_TESTS,
  PD_GET_TESTS,
  PD_GET_DATA,
  TR_HIDE_CREATE_UPDATE_DIALOG,
  TR_HIDE_CRUD_DIALOG,
  TR_HIDE_DELETE_DIALOG,
  TR_SHOW_CREATE_UPDATE_DIALOG,
  TR_SHOW_CREATE_UPDATE_VIDEO_DIALOG,
  TR_SHOW_CRUD_DIALOG,
  TR_SHOW_DELETE_DIALOG,
} from '../../../actions/ui/uxPlayerData';

import { FORM_SUBMIT_PENDING, FORM_SUBMIT_SUCCESS } from '../../../actions/ui/formUtils';

import { ASYNC_ERROR, ASYNC_FINISH, ASYNC_START } from '../../../actions/ui/async';
import {PLAYER_DATA_CATEGORY_TEST_RESULT_SUC} from "../../../actions/data/sport";
import { GET_PLAYER_DATA } from '../../../../modules/ProspectSheet/+store/prospectSheet';

const isCreateUpdateDialogShown = (
  state = {
    [Banks.perf]: false,
    [Banks.strength]: false,
  },
  action,
) => {
  switch (action.type) {
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? true : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? true : state[Banks.strength],
      };
    case TR_HIDE_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case TR_HIDE_CRUD_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case FORM_SUBMIT_SUCCESS:
      if (isPerfTestsCreateUpdateFormModel(action.model)) {
        return {
          [Banks.perf]: false,
          [Banks.strength]: state[Banks.strength],
        };
      }
      if (isStrengthTestsCreateUpdateFormModel(action.model)) {
        return {
          [Banks.perf]: state[Banks.perf],
          [Banks.strength]: false,
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        return {
          [Banks.perf]: action.data.bank === Banks.perf ? false : state[Banks.perf],
          [Banks.strength]: action.data.bank === Banks.strength ? false : state[Banks.strength],
        };
      }
      return state;
    default:
      return state;
  }
};

const isCRUDDialogShown = (
  state = {
    [Banks.perf]: false,
    [Banks.strength]: false,
  },
  action,
) => {
  switch (action.type) {
    case TR_SHOW_CRUD_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? true : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? true : state[Banks.strength],
      };
    case TR_HIDE_CRUD_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? true : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? true : state[Banks.strength],
      };
    case FORM_SUBMIT_SUCCESS:
      if (isPerfTestsCreateUpdateFormModel(action.model)) {
        return {
          [Banks.perf]: false,
          [Banks.strength]: state[Banks.strength],
        };
      }
      if (isStrengthTestsCreateUpdateFormModel(action.model)) {
        return {
          [Banks.perf]: state[Banks.perf],
          [Banks.strength]: false,
        };
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        return {
          [Banks.perf]: action.data.bank === Banks.perf ? false : state[Banks.perf],
          [Banks.strength]: action.data.bank === Banks.strength ? false : state[Banks.strength],
        };
      }
      return state;
    default:
      return state;
  }
};

const deleteDialogIsShown = (
  state = {
    [Banks.perf]: false,
    [Banks.strength]: false,
  },
  action,
) => {
  switch (action.type) {
    case TR_SHOW_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? true : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? true : state[Banks.strength],
      };
    case TR_HIDE_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? false : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? false : state[Banks.strength],
      };
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        return {
          [Banks.perf]: action.data.bank === Banks.perf ? false : state[Banks.perf],
          [Banks.strength]: action.data.bank === Banks.strength ? false : state[Banks.strength],
        };
      }
      return state;
    default:
      return state;
  }
};

const perfTestResultBeingEdited = (state = null, action) => {
  switch (action.type) {
    case TR_SHOW_CREATE_UPDATE_VIDEO_DIALOG:
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      if (action.bank === Banks.perf) {
        return action.testResult;
      }
      return state;
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      if (action.bank === Banks.perf) {
        return null;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        if (action.bank === Banks.perf) {
          return null;
        }
      }
      return state;
    case FORM_SUBMIT_SUCCESS:
      if (isPerfTestsCreateUpdateFormModel(action.model)) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const strengthTestResultBeingEdited = (state = null, action) => {
  switch (action.type) {
    case TR_SHOW_CREATE_UPDATE_VIDEO_DIALOG:
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      if (action.bank === Banks.strength) {
        return action.testResult;
      }
      return state;
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      if (action.bank === Banks.strength) {
        return null;
      }
      return state;
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        if (action.data.bank === Banks.strength) {
          return null;
        }
      }
      return state;
    case FORM_SUBMIT_SUCCESS:
      if (isStrengthTestsCreateUpdateFormModel(action.model)) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

const perfLoadingCategories = (state = [], action) => {
  switch (action.type) {
    case PD_GET_CAT_PERF_TESTS:
      if (!state.includes(action.categoryId)) {
        return [...state, action.categoryId];
      }
      return state;
    case PLAYER_DATA_CATEGORY_TEST_RESULT_SUC:
      return state.filter(cat => cat !== action.categoryId);
    default:
      return state;
  }
};

const strengthLoadingCategories = (state = [], action) => {
  switch (action.type) {
    case PD_GET_CAT_STRENGTH_TESTS:
      if (!state.includes(action.categoryId)) {
        return [...state, action.categoryId];
      }
      return state;
    case PLAYER_DATA_CATEGORY_TEST_RESULT_SUC:
      return state.filter(cat => cat !== action.categoryId);
    default:
      return state;
  }
};

const perfLoadingMsg = (state = 'Loading Performance Test Results', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === PD_GET_TESTS) {
        return '';
      }
      if (action.model === PD_GET_CAT_PERF_TESTS) {
        return '';
      }
      if(action.model === GET_PLAYER_DATA){
        return '';
      }
      return state;
    case ASYNC_START:
      if (action.model === PD_GET_TESTS) {
        return action.msg || 'Saving ...';
      }
      if (action.model === PD_GET_CAT_PERF_TESTS) {
        return action.msg || 'Saving ...';
      }
      if(action.model === GET_PLAYER_DATA){
        return action.msg || "Loading...";
      }
      return state;
    default:
      return state;
  }
};

const perfSavingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if ((action.model === PD_DELETE_TEST_RESULT)
        && (action.data.bank === Banks.perf)) {
        return '';
      }
      return state;
    case ASYNC_START:
      if ((action.model === PD_DELETE_TEST_RESULT)
        && (action.attrs.bank === Banks.perf)) {
        return action.msg;
      }
      return state;
    case FORM_SUBMIT_PENDING:
      if (isPerfTestsCreateUpdateFormModel(action.model)) {
        return 'Saving your data';
      }
      return state;
    case FORM_SUBMIT_SUCCESS:
      if (isPerfTestsCreateUpdateFormModel(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const getData = (state = false, action) =>{
  switch (action.type) {
    case ASYNC_START:
      if (action.model === PD_GET_DATA) {
        return false;
      }
    case ASYNC_FINISH:
      if (action.model === PD_GET_DATA) {
        return true;
      }
    default:
      return state;
  }
}

const strengthLoadingMsg = (state = 'Loading Performance Test Results', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if (action.model === PD_GET_TESTS) {
        return '';
      }
      if (action.model === PD_GET_CAT_STRENGTH_TESTS) {
        return '';
      }
      return state;
    case ASYNC_START:
      if (action.model === PD_GET_TESTS) {
        return action.msg || 'Saving ...';
      }
      if (action.model === PD_GET_CAT_STRENGTH_TESTS) {
        return action.msg || 'Saving ...';
      }
      return state;
    default:
      return state;
  }
};

const strengthSavingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_FINISH:
    case ASYNC_ERROR:
      if ((action.model === PD_DELETE_TEST_RESULT)
        && (action.data.bank === Banks.strength)) {
        return '';
      }
      return state;
    case ASYNC_START:
      if ((action.model === PD_DELETE_TEST_RESULT)
        && (action.attrs.bank === Banks.strength)) {
        return action.msg;
      }
      return state;
    case FORM_SUBMIT_PENDING:
      if (isStrengthTestsCreateUpdateFormModel(action.model)) {
        return 'Saving your data';
      }
      return state;
    case FORM_SUBMIT_SUCCESS:
      if (isStrengthTestsCreateUpdateFormModel(action.model)) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const selectedTestResult = (
  state = {
    [Banks.perf]: null,
    [Banks.strength]: null,
  },
  action,
) => {
  switch (action.type) {
    case TR_SHOW_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf && action.testResult ?
          action.testResult : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength && action.testResult ?
          action.testResult : state[Banks.strength],
      };
    case TR_HIDE_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? action.testResult : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ?
          action.testResult : state[Banks.strength],
      };
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case TR_HIDE_CRUD_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        return {
          [Banks.perf]: action.data.bank === Banks.perf ? null : state[Banks.perf],
          [Banks.strength]: action.data.bank === Banks.strength ? null : state[Banks.strength],
        };
      }
      return state;
    default:
      return state;
  }
};


const selectedTestResultIdx = (
  state = {
    [Banks.perf]: null,
    [Banks.strength]: null,
  },
  action,
) => {
  switch (action.type) {
    case TR_SHOW_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf && action.testResultIdx ?
          action.testResultIdx : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength && action.testResultIdx ?
          action.testResultIdx : state[Banks.strength],
      };
    case TR_SHOW_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? action.testResultIdx : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ?
          action.testResultIdx : state[Banks.strength],
      };
    case TR_HIDE_DELETE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case TR_HIDE_CREATE_UPDATE_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case TR_HIDE_CRUD_DIALOG:
      return {
        [Banks.perf]: action.bank === Banks.perf ? null : state[Banks.perf],
        [Banks.strength]: action.bank === Banks.strength ? null : state[Banks.strength],
      };
    case ASYNC_FINISH:
      if (action.model === PD_DELETE_TEST_RESULT) {
        return {
          [Banks.perf]: action.data.bank === Banks.perf ? null : state[Banks.perf],
          [Banks.strength]: action.data.bank === Banks.strength ? null : state[Banks.strength],
        };
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isCreateUpdateDialogShown,
  isCRUDDialogShown,
  perfLoadingMsg,
  getData,
  perfSavingMsg,
  strengthLoadingMsg,
  strengthSavingMsg,
  deleteDialogIsShown,
  perfTestResultBeingEdited,
  strengthTestResultBeingEdited,
  selectedTestResult,
  selectedTestResultIdx,
  strengthLoadingCategories,
  perfLoadingCategories,
});

export default reducer;
