import { ajax } from "rxjs/ajax";
import Urls from "../../../../config/urls";

export const apiGetNationalLeaderboardResults = (token, action) => (
  ajax.getJSON(
    Urls.nationalLeaderboard.results(action),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);


export const apiGetNationalLeaderboardTemplate = (token, sportId) => (
  ajax.getJSON(
    Urls.nationalLeaderboard.template(sportId),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`
    }
  )
);

export const apiGetNationalLeaderboardSports = () => (
  ajax.getJSON(
    Urls.nationalLeaderboard.sports(),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);

export const apiGetNationalLeaderboardGradYears = () => (
  ajax.getJSON(
    Urls.nationalLeaderboard.gradYears(),
    {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  )
);



export default "nationalLeaderboard.api";

