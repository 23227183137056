import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetPhotosVideos';
import { loadMedia } from '../../../store/actions/ui/uxProfile/photosVideos';
import { getCurrentUuid } from '../Utils';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  const profile = state.data.user.profiles[uuid];
  return {
    uuid,
    loading: state.ui.uxProfile.photosVideos.loading.loading,
    loadingMsg: state.ui.uxProfile.photosVideos.loading.msg,
    needsToLoadPhotos: state.ui.uxProfile.photosVideos.needsToLoadPhotos,
    needsToLoadVideos: state.ui.uxProfile.photosVideos.needsToLoadVideos,
    needsToLoadProfile: !profile,
  };
};

const mapDispatchToProps = dispatch => ({
  getMedia: (uuid) => {
    dispatch(loadMedia(uuid));
  },
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
