import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Routes, awardsDialogTo } from '../../../../store/actions/ui/routes';
import CloseLink from '../../../UxCommon/CloseLink/CloseLink';
import LoadingSpinner from '../../../UxCommon/LoadingSpinner/LoadingSpinner';
import AwardDetailsForm from './AwardDetailsForm/AwardDetailsForm';
import AwardDetails from './AwardDetails/AwardDetails';

import coachAward from '../../../../assets/images/CoachAwardBig.png';
import personalAward from '../../../../assets/images/award-personal.png';
import { ShareModal } from '../../../UxCommon';
import CombineAwardDetails from './CombineAwardDetails/CombineAwardDetails';
import { getAwardLogo } from '../../../../modules/CoachWorld/+store/award';
import AwardLogo from '../AwardLogo/AwardLogo';

class CreateUpdateDialog extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    award: PropTypes.object,
    formInit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    onAwardDateChange: PropTypes.func.isRequired,
    onAwardDeleteClick: PropTypes.func.isRequired,
    onAwardSubmitForm: PropTypes.func.isRequired,
    onAwardHideShowToggleClick: PropTypes.func.isRequired,
    onAwardTypeSelected: PropTypes.func.isRequired,
    onAwardHideClick: PropTypes.func.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    schoolAwardLogo: PropTypes.object,
    isCoach: PropTypes.bool
  }

  state = {
    scrollTop: 0,
    shareDialogUri: '',
  };

  componentDidMount() {
    const { formInit, award } = this.props;
    formInit(award);
    this.scrollElement = document.getElementById('uxProfileRight');
    this.saveScrollTop(this.scrollElement.scrollTop);
  }

  componentDidUpdate() {
    this.shouldInit();
  }

  onShareClick = () => {
    const { match, award } = this.props;
    const uri = awardsDialogTo(match.params.uuid, award.id, match.params.sport, match.params.schoolId);
    this.setState({
      shareDialogUri: uri,
    });
  }

  onShareCloseClick = () => {
    this.setState({
      shareDialogUri: '',
    });
  }

  onSubmitForm = () => {
    const { award, onAwardSubmitForm } = this.props;
    onAwardSubmitForm(award);
  };

  getPaddingTop = () => {
    const { scrollTop } = this.state;
    if (scrollTop > 272) {
      return scrollTop - 154;
    }
    return 118;
  }

  shouldInit = () => {
    const { formNeedsInit, formInit, award } = this.props;
    if (formNeedsInit && award) {
      formInit(award);
    }
  };

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  render() {
    const {
      match,
      loading,
      loadingMsg,
      rrfForm,
      onAwardDateChange,
      onAwardDeleteClick,
      onAwardHideShowToggleClick,
      onAwardTypeSelected,
      onAwardHideClick,
      award,
      history,
      isMyProfile,
      schoolAwardLogo,
      isCoach
    } = this.props;
    const { shareDialogUri } = this.state;
    const {sport, schoolId} = match.params;
    const {type} = rrfForm;
    console.log(award, 'award award', type);
    return (
      <div
        className="Awards_CreateUpdateDialog_BackDrop"
        style={{ paddingTop: `${this.getPaddingTop()}px` }}
      >
        <div
          className={[
            'Awards_CreateUpdateDialog',
            loading ? 'loading' : '',
          ].join(' ')}
        >
          <CloseLink
            to={`/${Routes.profile}/${match.params.uuid}/${Routes.awards}${sport ? `/${sport}` : ''}${schoolId? `/${schoolId}`: ''}`}
            className="Awards_CreateUpdateDialog_CloseLink"
          />
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
            style={{ backgroundColor: 'rgba(256, 256, 256, 0.7)', zIndex: 100 }}
          />
          {
            !!shareDialogUri && (
              <ShareModal
                uri={shareDialogUri}
                onClose={this.onShareCloseClick}
                backdropClassName="absolute"
              />
            )
          }
          <div className="row rowMargin" style={{ width: '100%' }}>
            <div className="Awards_CreateUpdateDialog_ImgSide col-sm-12 col-md-7">
              <AwardLogo
                award={award}
                classes ="Awards_CreateUpdateDialog_Img"
                schoolAwardLogo={schoolAwardLogo}
                isCoach={isCoach}
                awardType={type}
                isNeedToDisPlayBiggerLogo = {true}
                className = "generic_award_modal"
              />
            </div>
            <div className="Awards_CreateUpdateDialog_Details col-md-5 col-sm-12">
              {
                isMyProfile && ((award && award.awardType !== 'combine') || !award) && (
                  <AwardDetailsForm
                    rrfForm={rrfForm}
                    onAwardDateChange={onAwardDateChange}
                    onDeleteClick={onAwardDeleteClick}
                    onSubmitForm={this.onSubmitForm}
                    onHideShowToggleClick={onAwardHideShowToggleClick}
                    onAwardTypeSelected={onAwardTypeSelected}
                    onHideImmediatelyClick={onAwardHideClick}
                    match={match}
                    award={award}
                    history={history}
                    onShareClick={this.onShareClick}
                  />
                )
              }
              {
                isMyProfile && award && award.awardType === 'combine' && (
                  <CombineAwardDetails
                    rrfForm={rrfForm}
                    onAwardDateChange={onAwardDateChange}
                    onDeleteClick={onAwardDeleteClick}
                    onSubmitForm={this.onSubmitForm}
                    onHideShowToggleClick={onAwardHideShowToggleClick}
                    onAwardTypeSelected={onAwardTypeSelected}
                    onHideImmediatelyClick={onAwardHideClick}
                    match={match}
                    award={award}
                    history={history}
                    onShareClick={this.onShareClick}
                  />
                )
              }
              {
                !isMyProfile && (
                  <AwardDetails award={award} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateUpdateDialog;
