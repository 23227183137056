import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import wordsToNumbers from 'words-to-numbers';
import HelpPopper from '../../../../components/UxCommon/Form/HelpPopper/HelpPopper';
import LoadingIndicatorCard
  from '../../Dashboard/Combines/CombineControl/LoadingIndicatorCard/LoadingIndicatorCard.component';
import { fromVoiceDictation } from '../../+store/combine/combine.utils';
import { CustomVideoIcon } from '../../../../components/UxCommon';
import { fromEvent } from 'rxjs';
import { Link } from 'react-router-dom';
import { expandedCombineDialogPathTo } from '../../../../store/actions/ui/routes';
class CombineEntry extends PureComponent {
  static propTypes = {
    value: PropTypes.any,
    onSave: PropTypes.func.isRequired,
    formatToDisplay: PropTypes.func.isRequired,
    formatToEdit: PropTypes.func.isRequired,
    help: PropTypes.func.isRequired,
    allowedCharTest: PropTypes.func,
    validate: PropTypes.func.isRequired,
    parse: PropTypes.func.isRequired,
    canNotEditReason: PropTypes.string.isRequired,
    isCoach: PropTypes.bool.isRequired,
    setExpiringCombineMsg: PropTypes.func.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    onEnterPressed: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    isAthleteCoachedByUser: PropTypes.bool,
    isCombineOpen: PropTypes.bool,
    setIsSelected: PropTypes.func.isRequired,
  }

  timer = null;
  WAIT_INTERVAL = 2000;
  defaultInputAttrs = {
    type: "number",
    step: "0.01",
    min: "0",
    lang:"en"
  };
  state = {
    isEditing: false,
    editValue: '',
    hasFocus: false,
    error: '',
    customInputAttrs: this.defaultInputAttrs,
    willSave: false,
    currentTimeCount: 0,
  }

  componentDidMount = () => {
    document.addEventListener(this.isTouchDevice() ? 'touchstart' : 'mousedown', this.handleClickOutside);
    let eventName = 'click';
    if (this.isTouchDevice()) {
      eventName = 'touchstart';
    }
    this.windowClickSubscription = fromEvent(window, eventName)
      .subscribe((e) => {
        const { willSave } = this.state;
        if (e.target !== this.formRef.current && willSave) {
          const { onSave, validate, parse, value, setOverFlowClassToTable } = this.props;
          const { editValue } = this.state;
          const videoId = (!!value && value.videoId) || '';
          this.setState({
            isEditing: false,
            hasFocus: false,
            willSave: false,
            customInputAttrs: this.defaultInputAttrs,
          });
          setOverFlowClassToTable(false)
          if (!validate(editValue)) {
            onSave({ result:parse(editValue),videoId }, {result: value && value.result || value, videoId: value && value.videoId || value});
          }
        }
      });
  };

  componentWillUnmount() {
    if (this.windowClickSubscription) {
      this.windowClickSubscription.unsubscribe();
    }
    document.removeEventListener(this.isTouchDevice() ? 'touchstart' : 'mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { setIsSelected } = this.props;
    if (this.formRef && this.formRef.current && !this.formRef.current.contains(event.target)) {
        setIsSelected(false);
    }
  }

  onEditClick = () => {
  }

  onEditSubmit = (e) => {
    const {
      onEnterPressed,
      rowIndex,
      columnIndex,
      setIsSelected,
      setOverFlowClassToTable,
    } = this.props;
    this.setState({ isEditing: false });
    setOverFlowClassToTable(false);
    setIsSelected(false);
    onEnterPressed(rowIndex, columnIndex);
    if (e) {
      e.preventDefault();
    }
  }

  onEditKeyDown = (e) => {
    const {
      allowedCharTest,
      setIsSelected,
      setOverFlowClassToTable,
    } = this.props;
    if (e.keyCode === 27 || e.key === 'Escape') {
      this.setState({ isEditing: false });
      setIsSelected(false);
      setOverFlowClassToTable(false);
    }
    if (e.metaKey
      || ['Backspace', 'Delete',
        'ArrowLeft', 'ArrowRight',
        'Shift', 'Control',
        'Alt', 'Tab', 'Enter'].includes(e.key)) {
      return;
    }
    if (allowedCharTest) {
      if (!allowedCharTest(e.key)) {
        e.preventDefault();
      }
    }
  }

  onInputChange = (e) => {
    // clearTimeout(this.timer);
    const { validate } = this.props;
    let val = e.target.value.replace(',', '.');
    // const res = fromVoiceDictation(val);
    // val = res.value;
    const error = validate(val);
    this.setState({
      editValue: val,
      error,
    });
    // if (res.shouldSubmit) {
    //   this.entryInput.blur();
    // } else if (res.shouldGoNext) {
    //   this.entryInput.blur();
    //   this.onEditSubmit();
    // } else if (res.shouldExit) {
    //   this.setState({ isEditing: false });
    //   setIsSelected(false);
    // }
  }

  onInputFocus = () => {
    this.setState({
      hasFocus: true,
      customInputAttrs: { type: 'text' },
    });
  }

  onInputBlur = () => {
    const { onSave, parse, validate, setIsSelected, setOverFlowClassToTable } = this.props;
    const { editValue } = this.state;
    this.setState({
      isEditing: false,
      hasFocus: false,
      customInputAttrs: this.defaultInputAttrs,
    });
    setOverFlowClassToTable(false);
    setIsSelected(false);
    if (!validate(editValue)) {
      onSave(parse(editValue));
    }
  }

  onButtonFocus = () => {
    const {
      canNotEditReason,
      isCoach,
      setExpiringCombineMsg,
      value,
      formatToEdit,
      isAthleteCoachedByUser,
      isCombineOpen,
      setIsSelected,
      setOverFlowClassToTable
    } = this.props;
    if (!isCombineOpen || !isAthleteCoachedByUser) {
      return false;
    }
    if (!canNotEditReason) {
      let editValue = null;
      if (value) {
        editValue = value.result;
      }
      this.setState({
        editValue: formatToEdit(editValue),
        isEditing: true,
        willSave: true,
      }, () => {
        this.entryInput.focus();
        this.entryInput.select();
      });
      setOverFlowClassToTable(true);
      setIsSelected(true);
      if (this.isTouchDevice()) {
        window.dispatchEvent(new Event('touchstart'));
      } else {
        window.dispatchEvent(new Event('click'));
      }
      // window.dispatchEvent(new Event('click'));
    } else if (isCoach) {
      setExpiringCombineMsg(canNotEditReason, 'error', 3);
    }
  }

  onClickFormButtonIcon = (isDelete =null) =>{
    const {
      unstageNewMedia,
      stagedMedia,
      updateAthleteVideoId,
      athleteId,
      onVideoUploadHandler,
      onVideoDeleteHandler,
      athlete, column, combine,value
     } =  this.props;
    stagedMedia.length>0 && unstageNewMedia(stagedMedia[0]);
    updateAthleteVideoId(athleteId);
    return isDelete ? onVideoDeleteHandler(athlete, column, combine,value) : onVideoUploadHandler(athlete, column, combine,value);
  }

  setRef = (input) => {
    this.entryInput = input;
  }

  windowClickSubscription = null;

  formClick = (e) => {
    e.stopPropagation();
  };

  isTouchDevice = () => ('ontouchstart' in window);
  formRef = React.createRef();

  renderVideoData = (realValue) =>{
    const {isCombineOpen,isMobile,value,bank,combineId, schoolId,location,updateAthleteVideoId,athleteId}= this.props;
    const videoId =  value && value.videoId ? value.videoId : null;
    return <div>
    {
     !(videoId) ?
     (isCombineOpen && <CustomVideoIcon
     multiLine={true}
     iconName={"videocam"}
     iconStyle={{fontSize: 36}}
     onClick={() => this.onClickFormButtonIcon()}
     tooltip={isMobile ? '': "Upload Video"}
     className={isMobile ? '' : "showToolTip"}
     place={"right"}
     />)
     :
     <div style={{display:"flex"}}>
      <Link to={expandedCombineDialogPathTo(schoolId,combineId,bank,value.videoId,location.search)}
        className="Leaderboard_Laser">
        <CustomVideoIcon
        multiLine={true}
        iconName="play-circle2"
        iconStyle={{fontSize: 25, color: "red"}}
        onClick={()=> updateAthleteVideoId(athleteId)}
        disabled={realValue ? false : true}
        place={"right"}
        />
      </Link>
      {isCombineOpen && <CustomVideoIcon
          iconName="delete-video"
          iconStyle={{fontSize: 25}}
          onClick={() => this.onClickFormButtonIcon("delete")}
          tooltip={isMobile ? '' : "Delete Video" }
          className={isMobile ? '' : "showToolTip"}
          disabled={realValue ? false : true}
          place={"right"}
        />}
    </div>
     }
     </div>

  }

  render() {
    const {
      value,
      formatToDisplay,
      help,
      parse,
      isSaving,
      isMobile,
      withinEstimated,
      getRefreshPopUp,
    } = this.props;
    const {
      isEditing,
      editValue,
      hasFocus,
      error,
      customInputAttrs,
    } = this.state;
    const realValue = (value && value.result) || value;
    if(Number(withinEstimated) === 5){
      getRefreshPopUp();
    }
    if (isSaving) {
      setTimeout(()=>{
        const currentTime = new Date().getTime();
        const isOnline = navigator.onLine;
        if(!isOnline){
          getRefreshPopUp();
        }
          if((isSaving && Number(withinEstimated) !==1 &&(currentTime >= Number(withinEstimated)+5000))){
            getRefreshPopUp();
          }
          this.setState({currentTimeCount: currentTime})
      },1000);
      return (
        <form className="CombineEntry_Form" onSubmit={this.onEditSubmit}>
          <LoadingIndicatorCard loading loadingMsg="Please Wait..." />
        </form>
      );
    }
    return (
      isEditing ? (
        <form ref={this.formRef} className="CombineEntry_Form" onSubmit={this.onEditSubmit}
        onTouchStart={this.formClick} onClick={this.formClick}>
          {
            error && (
              <HelpPopper error focus={hasFocus} helpClass="bottom">
                {error}
              </HelpPopper>
            )
          }
          {
            !error && help && (
              <HelpPopper focus={hasFocus} helpClass="bottom">
                {help(parse(editValue))}
              </HelpPopper>
            )
          }
          <input
            {...customInputAttrs}
            ref={this.setRef}
            className="CombineEntry_Input"
            value={editValue || ''}
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            onKeyDown={this.onEditKeyDown}
          />
          <button style={{ display: 'none' }} />
        </form>
      ) : (
        <React.Fragment>
        <button
          className="CombineEntry_Btn"
          onClick={this.onButtonFocus}
          onFocus={this.onButtonFocus}
        >
          {realValue !== null && realValue.result !== null ? formatToDisplay(realValue) : '-'}
        </button>
         { this.renderVideoData(realValue)}
         </React.Fragment>
      )
    );
  }
}

export default CombineEntry;
