export const getTagToAlbumMediaArray = (savedMedia, mediaResults) =>
  (savedMedia && savedMedia.length > 0 ?
    [...mediaResults, ...savedMedia] : mediaResults);

export const calculateTotalBytes = (stagedMedia) => {
  let bytes = 0;
  stagedMedia.filter(s => s.isNew).forEach((media) => {
    if (media.file) {
      bytes += media.file.size;
    } else {
      bytes += 1;
    }
  });
  return bytes;
};

