import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../../components/UxProfile/Awards/CreateUpdateDialog/CreateUpdateDialog';
import { initAwardsForm, deleteAward, hideAward } from '../../../store/actions/ui/uxProfile/awards';
import { formSubmit } from '../../../store/actions/ui/formUtils';
import { awardFormInputs } from '../../../store/actions/ui/uxProfile/awards/utils';

import { getCurrentUuid, getCurrentUuidSchoolStyle } from '../../UxCommon/Utils';
import { alertOrNavigate } from '../../UxCommon/NativeUtils';

const getAward = (uuid, state, ownProps) => {
  if (ownProps.match) {
    if (ownProps.match.params.awardId) {
      const { awardId } = ownProps.match.params;
      if (awardId === 'new') {
        return null;
      }
      const userAwards = state.data.user.awards[uuid];
      if (awardId && userAwards) {
        return userAwards.find(a => a.id === awardId) || null;
      }
    }
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  const currentUuidSchoolStyle =  getCurrentUuidSchoolStyle(state, ownProps.match);
  const schoolId = currentUuidSchoolStyle && currentUuidSchoolStyle.id;
  const schoolAwardLogo =  state.data.awardLogo.schoolAwardLogo
    && state.data.awardLogo.schoolAwardLogo[schoolId];
  return {
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    award: getAward(currentUuid, state, ownProps),
    loading: !!state.ui.uxProfile.awards.createUpdateDialog.loadingMsg,
    loadingMsg: state.ui.uxProfile.awards.createUpdateDialog.loadingMsg,
    formNeedsInit: state.ui.uxProfile.awards.createUpdateDialog.needsInit,
    formSubmitSuccess: state.ui.uxProfile.awards.createUpdateDialog.formSuccess,
    rrfForm: state.forms.forms.awards,
    inputs: awardFormInputs,
    state,
    schoolAwardLogo,
    isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onAwardSubmitForm: (award = stateProps.award) => {
      dispatch(formSubmit('forms.awards', { award, dispatch }));
    },
    formInit: (award) => {
      dispatch(actions.reset('forms.awards'));
      if (award) {
        dispatch(actions.merge('forms.awards', {
          name: award.name,
          type: award.type,
          date: award.date,
          url: award.url,
          desc: award.desc,
          display: award.display,
        }));
      } else {
        dispatch(actions.setInitial('forms.awards'));
      }
      dispatch(initAwardsForm());
    },
    onAwardDateChange: (date) => {
      dispatch(actions.change('forms.awards.date', date));
    },
    onAwardTypeSelected: (option) => {
      dispatch(actions.change('forms.awards.type', option));
    },
    onAwardDeleteClick: (award = stateProps.award) => {
      dispatch(deleteAward(award.id));
    },
    onAwardHideClick: () => {
      const { award } = stateProps;
      dispatch(hideAward(award.id));
    },
    onAwardHideShowToggleClick: () => {
      dispatch(actions.change('forms.awards.display', !stateProps.rrfForm.display.value));
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
