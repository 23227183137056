import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ButtonLink, Container, Row, Col } from '../../UxCommon';
import { Headline } from '../../UxCommon/Form';
import { Routes, Sports } from '../../../store/actions/ui/routes';

class MoreActions extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
  }

  render() {
    const {
      uuid,
      sportId,
      match,
    } = this.props;
    let sportSlug = '';
    if (sportId && Sports[sportId]) {
      sportSlug = Sports[sportId];
    }
    return (
      <div className="UxEditProfile_MoreActions">
        <Container className="UxEditProfile_MoreActions_Container">
          <Row>
            <Col>
              <Headline>
                {'More Actions'}
              </Headline>
              <div className="d-flex UxEditProfile_MoreActions_ButtonGroup">
                <ButtonLink
                  to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportSlug ? `/${sportSlug}` : ''}/${Routes.changePassword}`}
                  className="UxEditProfile_MoreActions_Button"
                >
                  Change Password
                </ButtonLink>
                <ButtonLink
                  className="UxEditProfile_MoreActions_Button delete"
                  to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}${sportSlug ? `/${sportSlug}` : ''}/${Routes.deleteAccount}`}
                >
                  Delete Account
                </ButtonLink>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MoreActions;
