import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/UxCommon/Icon/Icon';

const ShowButton = ({ className, onClick, ...props }) => (
  <button className={`CoachShowButton ${className || ''}`} onClick={onClick} {...props}>
    <Icon iconName="hide" className="CoachShowButton_Icon" />
  </button>
);
ShowButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ShowButton;
