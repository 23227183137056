import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetEditProfile extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getProfile: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
  }

  state = {
    loadRequired: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.uuid !== state.prevUuid) {
      return {
        loadRequired: true,
        prevUuid: props.uuid,
      };
    }
    return {
      loadRequired: false,
    };
  }

  componentDidMount() {
    const { getProfile, uuid, match,updateSchoolIdInStore } = this.props;
    getProfile(uuid);
    if(match.params.hasOwnProperty("schoolId") && match.params.schoolId){
      updateSchoolIdInStore(match.params.schoolId);
    }
  }

  componentDidUpdate() {
    if (this.state.loadRequired) {
      const { getProfile, uuid } = this.props;
      getProfile(uuid);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetEditProfile;
