import React,{useEffect, useState} from 'react';
import { ButtonRect, LoadingSpinner} from '../../../../components/UxCommon';

const VideoProgressDialog = (props) => {

 const [videoLength, setVideoLength] = useState(0);
const { 
  loading, onCancel, loadingMsg,loadingProgress,saveVideo,videos
} =props;
const isUploadingFromPlayerDataPage = props && props.isUploadingFromPlayerDataPage ? true : false;

useEffect(() =>{
  setVideoLength(videos.length)
} ,[])

useEffect( () =>{
  if(loadingProgress.percent === 100){
    if(isUploadingFromPlayerDataPage) {
      if(videos.length !== videoLength){
        saveVideo();
      }
    }
    if(videos.length !== videoLength && !(videos.length > 1)){
      saveVideo();
    }  
    setVideoLength(videos.length)
  }
},[loadingProgress.percent,videos])

return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <LoadingSpinner
            loading={loading}
            loadingMsg={"Uploading Video. Please Wait..."}
            style={{ zIndex: 100 }}
            loadingProgress={loadingProgress}
       />
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">Video Uploaded Successfully</div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn CloseCombineModal_Color"
          type="button"
          onClick={onCancel}
        >
          Ok
        </ButtonRect>
      </div>
    </div>
  </div>
};

export default VideoProgressDialog;
