import React from 'react';
import PropTypes from 'prop-types';

const ErrorsGroup = ({ valid, errors, className }) => (
  <div
    className={[
      'UxCommon_Form_Errors',
      valid ? '' : 'show',
      className || '',
    ].join(' ')}
  >
    <i className="icon-alert-icon" />
    <div className="UxCommon_Form_ErrorMsgs">
      { errors && Object.values(errors).map((error, index) => (
          error
          ? <span key={index}>{error}</span>
          : ''
        ))
      }
    </div>
  </div>
);
ErrorsGroup.propTypes = {
  valid: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
};

export default ErrorsGroup;
