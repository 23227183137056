import {map, switchMap} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {forkJoin, of} from 'rxjs';
import {combineEpics, ofType} from 'redux-observable';

import Urls from '../../../../../config/urls';
import {asyncFinishAction} from '../../async';
import {checkForkErrors, commonApiCallFragment, getTokenFragment} from '../../uxProfile/utils';
import {CHECK_IF_CAN_EDIT} from '../actions';
import {getPlayerProfile} from '../../../data/user/profile';


export const canCoachEditPlayer = (playerUuid, uuid, token) => ajax({
  url: Urls.canCoachEdit(playerUuid),
  // url:  Urls.isCoachOfAthlete(uuid, playerUuid),
  method: 'GET',
  headers: {
    Accept: '*/*',
    Authorization: `Bearer ${token}`,
  },
}).pipe(map(response => ({response, uuid})));

export const getUserTeam = (playerUuid, uuid, token) => ajax({
  url: Urls.getUserTeams(playerUuid),
  // url:  Urls.isCoachOfAthlete(uuid, playerUuid),
  method: 'GET',
  headers: {
    Accept: '*/*',
    Authorization: `Bearer ${token}`,
  },
}).pipe(map(response => ({response, uuid})));


const checkIfCanEditEpic = action$ => (
  action$.pipe(
    ofType(CHECK_IF_CAN_EDIT),
    getTokenFragment(),
    switchMap(({action, uuid, token}) => forkJoin(
      canCoachEditPlayer(action.uuid, uuid, token).pipe(commonApiCallFragment(action$, action, 'checkIfCanEdit')),
      getPlayerProfile(action.uuid, token)
        .pipe(commonApiCallFragment(action$, action, 'getPlayerProfile')),
      getUserTeam(action.uuid, uuid, token).pipe(commonApiCallFragment(action$, action, 'getUserTeam')),

      // get player teams.
      //1 nyi api hit krani h yha par
      //phir reducer m jayega
    ).pipe(
      checkForkErrors(),
      map((forkResults) => {
        if (forkResults.success) {
          return {
            ...forkResults,
            action,
          };
        }
        return forkResults;
      }),
    )),
    switchMap((results) => {
      if (results.success) {
        const allUserSchoolTeam = [];
        // const doesHaveEmail = !!results.results[1].response.email;
        // const canEdit = !!(results.results[0].response.response.response) && doesHaveEmail;
        const canEdit = !!(results.results[0].response.response.response);
        const isCoach = results.results[0].response.uuid &&
          results.results[0].response.uuid !== results.action.uuid &&
          canEdit;

        // get all sports team of user
        let response = results.results;
        response.forEach((school) => {
          newResponse = school.response.response
          if (newResponse && Array.isArray(newResponse.response)) {
            userTeam = newResponse.response
            userTeam && userTeam.forEach((team) => {
              let schoolTeam = team.school_team[0]
              allScoolTeam = {
                sportId: schoolTeam.sport_id,
                name: schoolTeam.name
              }
              allUserSchoolTeam.push(allScoolTeam);
            })
          }
        });
        return of(asyncFinishAction(results.action.type, 'checkIfCanEdit', {
          isCoach,
          canEdit,
          playerUuid: results.action.uuid,
          allUserSchoolTeam
        }));
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);

export const contextEpics = combineEpics(checkIfCanEditEpic);
