import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TableRow, TableCell, TableHeader } from '../../common/Table';
import TableCornerCell from '../../common/Table/TableCornerCell';
import { BankSlugToLabel, setAllLaser } from "../../+store/combine";
import { renderCategoryLogo } from "./CombineHeaderLogo";
import dashrRunningLogoUrl from '../../../../assets/images/DashrRunningBlueMen.png'
import { useDispatch } from "react-redux";

const CombineHeader = React.memo(({
    bank,
    columns,
    tableWidth,
    frozenCellWidth,
    rightHeaderCellWidth,
    hasScrolledBelowMenu,
    selectedColumn,
    combine
 }) => {
    const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const [scrollEl, setScrollEl] = useState(null);
    const cbRef = useRef(null);
    handleScroll = e => {
        setOffset(e.target.scrollLeft);
    };

    useEffect(() => {
        const scrollRosterTableWrapper = document.getElementById('Combine_RosterTableWrapper');
        scrollRosterTableWrapper.scrollLeft = 0;

    },[bank]);

    useLayoutEffect(() => {
        let scrollElement = scrollEl;
        if (!scrollEl) {
            scrollElement = document.getElementById('Combine_RosterTableWrapper');
            setScrollEl(scrollElement);
        }
        if (!cbRef || !cbRef.current) {
            const cb = _.throttle(handleScroll);
            cbRef.current = cb;
        }
        scrollElement.addEventListener('scroll',  cbRef.current)
        return () => scrollElement.removeEventListener('scroll',  cbRef.current)
    }, [])

  const toggleAllLaser = (standardTestCategoryId,standardTestObjectId) => {
    dispatch(
      setAllLaser(combine.id, standardTestCategoryId, standardTestObjectId),
    );
  };



  return (
        <TableHeader className={"combineHeader"} fixedHeaders={hasScrolledBelowMenu}>
            <TableRow style={{ width: tableWidth, transform: `translateX(${-offset}px)` }}>
                <TableCornerCell style={{ width: frozenCellWidth, left: `${offset}px` }} isHeader>
                    {BankSlugToLabel[bank]}
                </TableCornerCell>
                {
                    columns.map((column, index) => (
                    <TableCell
                        isHeader
                        colSpan={index + 1}
                        className={[selectedColumn === index ? 'selectedColumn' : '', columns.length < 2 ? 'combineOneCell' : ''].join(" ")}
                        key={column.testCategoryCode}
                        style={{ width: rightHeaderCellWidth}}
                    >
                        <div className="CombineTable_ColumnHeader iconTextWrap d-block text-center">
                        {
                          renderCategoryLogo(column.subbankCode, column.testCategoryCode)
                        }
                        {
                            !!column.isDashr &&
                            <div className="CombineTable_Column_Header_Logo_Label">
                             <img src={dashrRunningLogoUrl} className="Combine_Column_Header_Logo"/>
                            </div>
                        }


                        <div className="CombineTable_ColumnHeaderText">
                            {column.testCategoryName}
                        </div>
                          {!!column.useLaser && (
                            <div className="mt-1 d-flex">
                              <input
                                type="checkbox"
                                disabled={!combine.isOpen}
                                onClick={() => toggleAllLaser(column.standardTestCategoryId,column.standardTestObjectId)}
                                checked={column.allLaser}
                              />
                              <span  className="pl-1" style={{ color: 'black' }}>All Laser? </span>

                            </div>
                          )}
                        </div>
                    </TableCell>
                    ))
                }
            </TableRow>
        </TableHeader>
    );
})

CombineHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    bank: PropTypes.string.isRequired,
    tableWidth: PropTypes.any,
    frozenCellWidth: PropTypes.any,
    rightHeaderCellWidth: PropTypes.any,
    hasScrolledBelowMenu: PropTypes.bool,
    selectedColumn: PropTypes.number,
  }

export default CombineHeader;
