import { Route } from "react-router-dom";
import ValidateInviteContainer from "../../../containers/Welcome/ValidateInviteContainer";
import React from "react";

export const Routes = {
  signup: 'signup',
  login: 'login',
  levelUp: 'levelUp',
  mergeLogin: 'merge-login',
  mergeLoginAuth: 'merge-login-auth',
  mergeLoginForgotPassword: 'merge-login-forgot-password',
  mergeLoginAuthForgotPassword: 'merge-login-auth-forgot-password',
  mergeLoginResetPassword: 'merge-login-reset-password',
  mergeLoginAuthResetPassword: 'merge-login-auth-reset-password',
  mergeAnotherAccount: 'merge-another-account',
  mergeAnotherAccountAuth: 'merge-another-account-auth',
  dashboard: 'dashboard',
  confirmAccount: 'confirm-account',
  forgot: 'forgot',
  invited: 'invited',
  resetPassword: 'reset-password',
  profile: 'profile',
  welcome: 'welcome',
  edit: 'edit',
  changePassword: 'change-password',
  deleteAccount: 'delete-account',
  myData: 'my-data',
  results: 'results',
  newTest: 'new-test',
  secondSignIn: 'second-signin',
  mySports: 'my-sports',
  player: 'player',
  private: 'private',
  data: 'data',
  team: 'team',
  report: 'report',
  reports: 'reports',
  addSport: 'add-sport',
  profilePhoto: 'profile-photo',
  editAccount: 'edit-account',
  editSports: 'edit-sports',
  editAcademic: 'edit-academic',
  editSportsList: 'edit-sports-list',
  editSportExistingPhotos: 'edit-sport-existing-photos',
  photos: 'photos',
  awards: 'awards',
  joinTeam: 'join-team',
  endorsements: 'endorsements',
  confirmPhone: 'confirm-phone',
  confirmEmail: 'confirm-email',
  expanded: 'expanded',
  video: 'video',
  photo: 'photo',
  deletePhoto: 'deletePhoto',
  hashStats: '#stats',
  hashPerformance: '#perf',
  code: 'code',
  physique: 'physique',
  coach: 'coach',
  combine: 'combine',
  roster: 'roster',
  grid: 'grid',
  reconfigureGrid: 'reconfigure-grid',
  school: 'school',
  rosters: 'rosters',
  combines: 'combines',
  leaderboard: 'leaderboard',
  account: 'account',
  summary: 'summary',
  measurables: 'measurables',
  combineMeasurables: 'combineMeasurables',
  strength: 'strength',
  performance: 'perf',
  athlete: 'athlete',
  compare: 'compare',
  combineTemplate: 'combine-template',
  editCombineTemplate: 'edit-combine-template',
  tutorials: 'tutorials',
  nationalLeaderboard: 'nationalLeaderboard',
  northEastLeaderboard: 'northEastLeaderboard',
  allTimeLeaderboard: 'allTimeLeaderboard',
  districtLeaderboard: 'districtLeaderboard',
  collegeContact: 'college-contact',
  prospectSheet: 'prospect-sheet',
  playerProfile: 'player-profile',
  playerDataGraph: 'player-data-graph',
  externalProfile: 'external-profile',
  selectSports: 'select-sports',
  TrainingVideo: 'Training-video',
  transcript: 'transcript',
  schedule: 'schedule',
  combineReport: 'combine-report',
  pma: 'pma',
  activateProfileMembership: 'activateProfileMembership',
  mailChimpUserInvite: 'mailChimpUserInvite',
  noAlbum:'no-album',
};

export const Sports = {
  1: 'football',
  2: 'baseball',
  3: 'soccer',
  4: 'softball',
  5: 'basketball',
  6: 'Powerlifting',
  7: 'volleyball',
  8: 'crosscountry',
  9: 'swimming',
  10: 'diving',
  11: 'golf',
  12: 'tennis',
  13: 'AthleticFacility',
  14: 'wrestling',
  15: 'lacrosse',
  16: 'trackfield',
  17: 'waterpolo',
  18: 'hockey',
  19: 'cheerleading',
  20: 'intlrugby',
  21: 'intlnetball',
};
export const SportIds = {
  football: 1,
  baseball: 2,
  soccer: 3,
  softball: 4,
  basketball: 5,
  Powerlifting: 6,
  volleyball: 7,
  crosscountry: 8,
  swimming: 9,
  diving: 10,
  golf: 11,
  tennis: 12,
  AthleticFacility: 13,
  wrestling: 14,
  lacrosse: 15,
  trackfield: 16,
  waterpolo: 17,
  hockey: 18,
  cheerleading: 19,
  intlrugby: 20,
  intlnetball: 21,
};

export const dummy = {};

export const ROUTE_CHANGED = 'ROUTE_CHANGED';
export const routeChangedAlert = (pathname, query) => ({
  type: ROUTE_CHANGED,
  pathname,
  query,
});

export const REDIRECT = 'REDIRECT';
export const redirect = (history, to) => ({
  type: REDIRECT,
  history,
  to,
});

export const REDIRECT_URL = 'REDIRECT_URL';
export const redirectUrl = (url) => ({
  type: REDIRECT_URL,
  url
});

export const UPDATE_SPORT_ID = 'UPDATE_SPORT_ID';
export const updateSportIdInStore = sportId => ({
  type: UPDATE_SPORT_ID,
  sportId,
});

export const UPDATE_SCHOOL_ID = 'UPDATE_SCHOOL_ID';
export const updateSchoolIdStore = schoolId => ({
  type: UPDATE_SCHOOL_ID,
  schoolId,
});

const encodeEmailToUrl = (email) => `${email}`.replaceAll('.', 'aAdDoOtT');

export const invited = (uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.invited}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
export const pmaInvited = (uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.pma}/${Routes.invited}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
export const mergeSignIn = (mergeType, uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.mergeLogin}/${mergeType}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;

export const mergeSignInAuth = (mergeType, email ) => `/${Routes.welcome}/${Routes.mergeLoginAuth}/${mergeType}/${encodeEmailToUrl(email)}`;
export const activateProfileMembershipURL = (uuid, sportId, schoolId ) => `/${Routes.activateProfileMembership}/${uuid}/${sportId}/${schoolId}`;

export const mergeForgotPassword = (mergeType, uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.mergeLoginForgotPassword}/${mergeType}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
export const mergeAuthForgotPassword = (mergeType, uuid, newEmail) => `/${Routes.welcome}/${Routes.mergeLoginAuthForgotPassword}/${mergeType}/${uuid}/${newEmail}`;
export const mergeResetPassword = (mergeType, uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.mergeLoginResetPassword}/${mergeType}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
export const mergeAuthResetPassword = (mergeType, uuid) => `/${Routes.welcome}/${Routes.mergeLoginAuthResetPassword}/${mergeType}/${uuid}`;
export const mergeAnotherAccount = (mergeType, uuid, token, teamName, coachName, sportId, userTeamId, schoolId) => `/${Routes.welcome}/${Routes.mergeAnotherAccount}/${mergeType}/${uuid}/${token}/${teamName}/${coachName}/${sportId}/${userTeamId}/${schoolId}`;
export const mergeAnotherAccountAuth = (mergeType) => `/${Routes.welcome}/${Routes.mergeAnotherAccountAuth}/${mergeType}`;
export const expandedPhotoDialogTo = (uuid, isAPhoto, mediaId,sport ,schoolId) => `/${Routes.profile}/${uuid}/${Routes.photos}/${Routes.expanded}/${isAPhoto ? Routes.photo : Routes.video}/${mediaId}${sport ? `/${sport}`: ''}${schoolId ? `/${schoolId}` : ''}`;
export const expandedPhotoDialogPath = `/${Routes.profile}/:uuid/${Routes.photos}/${Routes.expanded}/(${Routes.video}|${Routes.photo})/:mediaId/:sport?/:schoolId?`;
export const closePhotoDialogPath = (uuid, sport, schoolId) => `/${Routes.profile}/${uuid}/${Routes.photos}${sport ? `/${sport}${schoolId ? `/${schoolId}` : ''}` : ''}`;
export const expandedTutorialDialogTo = (schoolId, mediaId) => `/${Routes.coach}/${Routes.dashboard}/${schoolId}/${Routes.tutorials}/${Routes.expanded}/${Routes.video}/${mediaId}`;
export const expandedTutorialsDialogPath = `/${Routes.coach}/${Routes.dashboard}/:schoolId/${Routes.tutorials}/${Routes.expanded}/${Routes.video}/:mediaId`;
export const closeTutorialsDialogPath = schoolId => `/${Routes.coach}/${Routes.dashboard}/${schoolId}/${Routes.tutorials}`;
export const awardsDialogTo = (uuid, awardId, sport,schoolId) => `/${Routes.profile}/${uuid}/${Routes.awards}${sport ? `/${sport}` : ''}${schoolId ? `/${schoolId}`: ''}/edit/${awardId}`;
export const awardsDialogPath = `/${Routes.profile}/:uuid/${Routes.awards}/:sport?/:schoolId?/edit/:awardId`;
export const profileShareUri = (uuid, sportId, schoolId) => `/${Routes.profile}/${uuid}/${Routes.player}/${Sports[sportId]}${schoolId ? `/${schoolId}` : ''}`;
export const photoPageShareUri = (uuid, schoolId, sportId) => `/${Routes.profile}/${uuid}/${Routes.photos}/${Sports[sportId]}${schoolId ? `/${schoolId}` : ''}`;
export const awardPageShareUri = (uuid, schoolId,sport)  => `/${Routes.profile}/${uuid}/${Routes.awards}${sport ? `/${sport}` : ''}${schoolId ? `/${schoolId}` : ''}`;
export const dataPageShareUri = (uuid, sportId,schoolId) => `/${Routes.profile}/${uuid}/${Routes.data}/${Sports[sportId]}${schoolId ? `/${schoolId}` : ''}`;
export const expandedEditPlayerPath = `/${Routes.school}/:schoolId/${Routes.roster}/:sport/${Routes.expanded}/:athleteId`;
export const expandedEditPlayersPath = `/${Routes.school}/:schoolId/${Routes.roster}/:sport/${Routes.expanded}/athletes`;
export const expandedRosterGridPath = `/${Routes.school}/:schoolId/${Routes.roster}/:sport/${Routes.grid}/${Routes.team}/:teamId`;
export const expandedRosterReconfigureGridPath = `/${Routes.school}/:schoolId/${Routes.roster}/:sport/${Routes.reconfigureGrid}/${Routes.team}/:teamId`;
export const expandedRosterGrid = (schoolId, sportId, teamId) => `/${Routes.school}/${schoolId}/${Routes.roster}/${Sports[sportId]}/${Routes.grid}/${Routes.team}/${teamId}`;
export const expandedEditPlayers = (schoolId, sportId) => `/${Routes.school}/${schoolId}/${Routes.roster}/${Sports[sportId]}/${Routes.expanded}/athletes`;
export const expandedEditPlayer = (schoolId, athleteId, sportId) => `/${Routes.school}/${schoolId}/${Routes.roster}/${Sports[sportId]}/${Routes.expanded}/${athleteId}`;
export const expandedGenerateReportPath = `/${Routes.school}/:schoolId/${Routes.combine}/:combineId/:bank/${Routes.expanded}/:combineId`;
export const expandedGenerateReport = (schoolId, combineId, bank) => `/${Routes.school}/${schoolId}/${Routes.combine}/${combineId}/${bank}/${Routes.expanded}/${combineId}`;
export const editCombineTests = (schoolId, combineId, combineTemplateId) => `/${Routes.coach}/${Routes.dashboard}/${schoolId}/${Routes.editCombineTemplate}/${combineTemplateId}/${combineId}`;
export const expandedLeaderboardDialogPath= `/${Routes.school}/:schoolId/${Routes.leaderboard}/:combineId/:bank?/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedLeaderboardDialogPathTo = (schoolId, combineId,bank,mediaId,search) => `/${Routes.school}/${schoolId}/${Routes.leaderboard}/${combineId}/${bank}/${Routes.expanded}/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeLeaderboardDialogPath = (schoolId, combineId,bank,search)=> `/${Routes.school}/${schoolId}/${Routes.leaderboard}/${combineId}/${bank}${search? search : ''}`;

export const expandedProspectDialogPath = `/${Routes.school}/:schoolId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/${Routes.prospectSheet}/:sport/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedProspectDialogPathTo = (schoolId, teamId, athleteId, sport, mediaId) => `/${Routes.school}/${schoolId}/${Routes.team}/${teamId}/${Routes.athlete}/${athleteId}/${Routes.prospectSheet}/${sport}/${Routes.expanded}/${Routes.video}/${mediaId}`;
export const closeProspectDialogPath = (schoolId, teamId, athleteId, sport)=> `/${Routes.school}/${schoolId}/${Routes.team}/${teamId}/${Routes.athlete}/${athleteId}/${Routes.prospectSheet}/${sport}`;

export const expandedCombineDialogPath= `/${Routes.school}/:schoolId/${Routes.combine}/:combineId/:bank?/expandedVideo/${Routes.video}/:mediaId`;
export const expandedCombineDialogPathTo = (schoolId, combineId,bank,mediaId,search) => `/${Routes.school}/${schoolId}/${Routes.combine}/${combineId}/${bank}/expandedVideo/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeCombineDialogPath = (schoolId, combineId,bank,search)=> `/${Routes.school}/${schoolId}/${Routes.combine}/${combineId}/${bank}${search? search : ''}`;

export const expandedNationalLeaderboardDialogPath= `/${Routes.coach}/${Routes.nationalLeaderboard}/:bank?/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedNationalLeaderboardDialogPathTo = (bank,mediaId,search) => `/${Routes.coach}/${Routes.nationalLeaderboard}/${bank}/${Routes.expanded}/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeNationalLeaderboardDialogPath = (bank,search)=> `/${Routes.coach}/${Routes.nationalLeaderboard}/${bank}${search? search : ''}` ;

export const expandedNorthEastLeaderboardDialogPath= `/${Routes.coach}/${Routes.northEastLeaderboard}/:bank?/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedNorthEastLeaderboardDialogPathTo = (bank,mediaId,search) => `/${Routes.coach}/${Routes.northEastLeaderboard}/${bank}/${Routes.expanded}/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeNorthEastLeaderboardDialogPath = (bank,search)=> `/${Routes.coach}/${Routes.northEastLeaderboard}/${bank}${search? search : ''}` ;

export const expandedAllTimeLeaderboardDialogPath= `/${Routes.coach}/:schoolId/${Routes.allTimeLeaderboard}/:bank?/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedAllTimeLeaderboardDialogPathTo = (bank,mediaId,search,schoolId) => `/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${bank}/${Routes.expanded}/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeAllTimeLeaderboardDialogPath = (bank,search,schoolId)=> `/${Routes.coach}/${schoolId}/${Routes.allTimeLeaderboard}/${bank}${search? search : ''}` ;

export const expandedDistrictLeaderboardDialogPath= `/${Routes.coach}/:schoolId/${Routes.districtLeaderboard}/:bank?/${Routes.expanded}/${Routes.video}/:mediaId`;
export const expandedDistrictLeaderboardDialogPathTo = (bank,mediaId,search,schoolId) => `/${Routes.coach}/${schoolId}/${Routes.districtLeaderboard}/${bank}/${Routes.expanded}/${Routes.video}/${mediaId}${search? search : ''}`;
export const closeDistrictLeaderboardDialogPath = (bank,search,schoolId)=> `/${Routes.coach}/${schoolId}/${Routes.districtLeaderboard}/${bank}${search? search : ''}` ;


export const externalProfileDialogTo = (uuid, externalProfileId, sport,schoolId) => `/${Routes.profile}/${uuid}/${Routes.externalProfile}${sport ? `/${sport}` : ''}${schoolId? `/${schoolId}`: ''}/edit/${externalProfileId}`;
export const externalProfileDialogPath = `/${Routes.profile}/:uuid/${Routes.externalProfile}/:sport?/:schoolId?/edit/:externalProfileId?`;

