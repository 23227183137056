import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

class GetOldProfilePhotos extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getOldProfilePhotos: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    needsInit: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { getOldProfilePhotos, uuid } = this.props;
    getOldProfilePhotos(uuid, 'Previous profile photos will load here');
  }

  componentDidUpdate() {
    const { needsInit, uuid, getOldProfilePhotos } = this.props;
    if (needsInit) {
      getOldProfilePhotos(uuid, 'Previous profile photos will load here');
    }
  }

  render() {
    const { children, loading, loadingMsg } = this.props;
    return (
      <React.Fragment>
        {
          loading
          ? <LoadingSpinner loading loadingMsg={loadingMsg} />
          : children
        }
      </React.Fragment>
    );
  }
}

export default GetOldProfilePhotos;
