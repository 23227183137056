import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Tab extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { id, onClick } = this.props;
    onClick(id);
  }

  render() {
    const { label, selected } = this.props;
    return (
      <button
        className={['UxCommon_Tabs_Tab', selected ? 'selected' : ''].join(' ')}
        onClick={this.onClick}
      >
        { label }
      </button>
    );
  }
}

const Tabs = ({
  tabs,
  currentTabId,
  setTab,
  className,
}) => (
  <div className={['UxCommon_Tabs', className || ''].join(' ')}>
    {
      tabs.map(({ id, label }) => (
        <Tab key={id} id={id} label={label} onClick={setTab} selected={currentTabId === id} />
      ))
    }
  </div>
);
Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  currentTabId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  setTab: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default Tabs;
