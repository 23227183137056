import { asyncStartAction } from '../../../../store/actions/ui/async';

export const SAVE_SPORT = 'codeSignUp.saveSports';
export const saveSports = (params, isMobile) => (dispatch) => {
  /*
    params: {
      profile,
      roleId,
      teams: myTeams.filter(s => s),
      newTeams,
      code: match.params.code,
      schoolId: schoolInfo.id,
    }
  */
  dispatch(asyncStartAction(SAVE_SPORT));
  dispatch({
    type: SAVE_SPORT,
    ...params,
    dispatch,
    isMobile,
  });
};

export const GET_SCHOOL_SPORT_TEAMS_LIST = 'codeSignUp.getSchoolSportTeamsList';
export const getSchoolSportTeamsList = (schoolId, sportId) => (dispatch) => {
  dispatch(asyncStartAction(GET_SCHOOL_SPORT_TEAMS_LIST, 'school list', {
    schoolId,
    sportId,
  }));
  dispatch({
    type: GET_SCHOOL_SPORT_TEAMS_LIST,
    schoolId,
    sportId,
  });
};
