import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'react-frame-component';
import Banner from '../../common/Banner';
import SchoolLogo from '../../common/SchoolLogo';
import Icon from '../../common/Icon';
import TableHeader from '../../common/Table/TableHeader.component';
import TableRow from '../../common/Table/TableRow.component';
import TableCell from '../../common/Table/TableCell.component';
import TableBody from '../../common/Table/TableBody.component';
import Table from '../../common/Table/Table.component';
import cssVar from '../../../../components/App/App.utils';
import vcLogo from "../../../../assets/images/vc-black.png";
import bgImageUrl from "../../../../assets/images/bannerImage.png";

// eslint-disable-next-line no-unused-vars
const renderCoaches = () => {
  // eslint-disable-next-line no-undef
  const coaches = allCoaches[team.id] || [];
  const coachColumns = {
    name: 'Name',
    position: 'Title',
    coachSpouse: 'Spouse',
    coachKids: 'Children',
    coachHighSchool: 'High School',
    coachCareerCoaching: 'Coaching',
    coachCareerPlaying: 'Playing',
    coachHobbies: 'Hobbies',
  };
  return (
    <React.Fragment>
      {
        !!coaches.length &&
        <div className="CoachTeamBox_Header">
          <div className="UxCommon_Collapsable_Minus" />
          <div className="CoachTeamBox_TeamName">{ team.name || team.teamRef.team} Coaches</div>
        </div>
      }
      {
        !!coaches.length &&
        <Table className="CoachTable">
          <TableHeader>
            <TableRow>
              <TableCell style={{ width: '10%' }} />
              {
                Object.values(coachColumns).map((column, index) => (
                  <TableCell
                    key={index}
                    style={{ width: `${90 / Object.values(coachColumns).length}%` }}
                  >
                    {column}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              coaches.map((coach, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: '10%' }}>
                    {
                      coach.headshotUrl ?
                        <div
                          className="CoachCard_Image"
                          style={{
                            backgroundImage: `url(${coach.headshotUrl})`,
                          }}
                        />
                        :
                        <div className="CoachCard_Image">
                          <Icon iconName="photo2" className="CoachCard_PhotoIcon" />
                        </div>
                    }
                  </TableCell>
                  {
                    Object.keys(coachColumns).map(column => (
                      <TableCell
                        key={column}
                        style={{ width: `${90 / Object.keys(coachColumns).length}%` }}
                      >
                        {coach[column] ? coach[column] : '-'}
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
            <TableRow style={{ height: 30 }} />
          </TableBody>
        </Table>
      }
    </React.Fragment>
  );
};

const PrintExperiment = (props) => {
  const {
    match,
    currentSchoolStyle,
    teams,
    // allCoaches,
    allAthletes,
  } = props;
  if (!currentSchoolStyle) return null;
  if (!teams.length) return null;
  const styles = document.getElementsByTagName('style')[0].innerHTML;
  let backGroundImage = bgImageUrl;
  if (currentSchoolStyle.background_image !== null) {
    backGroundImage = currentSchoolStyle.background_image;
  }
  return (
    <Frame className="PrintExperiment_IFrame" id="printable">
      <div>
        <style>{styles}</style>
        <style>
          {`* {
              -webkit-print-color-adjust: exact;
              --primary: ${cssVar('primary')};
              --secondary: ${cssVar('secondary')};
            }
            @page {
              margin-bottom: 0;
            }
            html, body, #root {
              overflow: hidden;
              height: unset;
            }
            html {
              overflow: auto;
            }
            .CoachRoster {
              width: 100%;
            }
            .CoachBanner {
              height: 40px;
              justify-content: center;
              margin-bottom: 10px;
              background-position: center;
              background-size: cover;
              position: relative;
            }
            .CoachRoster_BannerChildren2 {
              height: 100%;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-between;
            }
            .CoachRoster_BannerLeft {
              padding-bottom: 15px;
            }
            .CoachTable {
              width: 100%;
              margin-top:-25px
            }
            .CoachCard_Image {
              width: 100%;
              height: 93px;
              border-radius: 0;
            }
            .CoachTableCell {
              height: 15px;
              font-size: 12px;
            }
            .CoachCard_PhotoIcon {
              font-size: 70px;
            }
            .CoachTeamBox_Header {
              font-size: 16px;
              height: 40px;
            }
            .CoachTeamBox_Header {
              justify-content: flex-start;
            }
            .CoachTeamBox_TeamName {
              margin-top: 0px;
              text-transform: capitalize;
            }
            .CoachRoster_SchoolName {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .CoachBanner_VCRoundBgrd {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
            }
            .Print_SchoolLogo {
              height:40px;
              width:40px;
              margin-right:10px;
            }
            .Print_SchoolRosterName {
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
            }
            .Position_column_wrap {
              overflow-wrap:anywhere;
              width:25px !important;
              padding: 0px 5px 0px 0px;
            }
          `}
        </style>
        <div
          className="CoachBanner"
          // style={{ backgroundImage: `url(${backGroundImage})` }}
        >
          <div className="CoachRoster_BannerChildren2">
            <div className="CoachRoster_SchoolName">
              <SchoolLogo
                className="CoachRoster_BannerTeamLogo Print_SchoolLogo"
                defaultClassName="Print_SchoolLogo"
                schoolStyle={currentSchoolStyle || null}
              />
              <div className="CoachRoster_BannerFullTitle Print_SchoolRosterName">{currentSchoolStyle ? currentSchoolStyle.name : ''} - {match && match.params.sport ? match.params.sport : ''}</div>
            </div>
          </div>
        </div>
        <div style={{}} />
        {
          teams.map((team, idx) => {
            const athletes = allAthletes[team.id] || [];
            if (!athletes.length) return null;
            const athleteColumns = {
              position: 'Position',
              name: 'Name',
              grade: 'Grade',
              height: 'Height',
              weight: 'Weight',
            };
            return (
              <div key={idx}>
                <div className="CoachTeamBox_Body">
                  <div className="CoachTeamBox_Header">
                    <div className="UxCommon_Collapsable_Minus" />
                    <div className="CoachTeamBox_TeamName">{ team.name || team.teamRef.team} Athletes</div>
                  </div>
                  <Table className="CoachTable">
                    <TableHeader>
                      <TableRow>
                        {/* <TableCell style={{ width: '10%' }} /> */}
                        {
                          Object.values(athleteColumns).map((column, index) => (
                            <TableCell
                              key={index}
                              style={{ width: `${90 / Object.values(athleteColumns).length}%` }}
                              className={column === 'Position' ? 'Position_column_wrap' : ''}
                            >
                              {column}
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        athletes.map((athlete, index) => (
                          <TableRow key={index}>
                            {/* <TableCell style={{ width: '10%' }}>
                              {
                                athlete.headshotUrl ?
                                  <div
                                    className="CoachCard_Image"
                                    style={{
                                      backgroundImage: `url(${athlete.headshotUrl})`,
                                    }}
                                  />
                                  :
                                  <div className="CoachCard_Image">
                                    <Icon iconName="photo2" className="CoachCard_PhotoIcon" />
                                  </div>
                              }
                            </TableCell> */}
                            {
                              Object.keys(athleteColumns).map((column) => {
                                let data = athlete[column] ? athlete[column] : '-';
                                if (column === 'height' && (athlete.heightFt || athlete.heightIn)) {
                                  data = `${athlete.heightFt || '0'}' ${athlete.heightIn || '0'}"`;
                                } else if (column === 'weight') {
                                  data = `${athlete.weight || '-'} lbs`;
                                } else if (column === 'position') {
                                  let position = athlete.position && athlete.position.replaceAll(',', ', ');
                                  data = `${athlete.jerseyNumber && athlete.jerseyNumber.trim() ? `# ${athlete.jerseyNumber.trim()} |` : '# - |'} ${position || ''}`;
                                }
                                return (
                                  <TableCell
                                    key={column}
                                    style={{ width: `${90 / Object.keys(athleteColumns).length}%` }}
                                    className={column === 'position' ? 'Position_column_wrap' : ''}
                                  >
                                    {data}
                                  </TableCell>
                                );
                              })
                            }
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          })
        }
      </div>
    </Frame>
  );
};
PrintExperiment.propTypes = {
  match: PropTypes.any,
  currentSchoolStyle: PropTypes.any,
  teams: PropTypes.array,
  // allCoaches: PropTypes.object,
  allAthletes: PropTypes.object,
};

export default PrintExperiment;
