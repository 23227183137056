import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavButton = ({ to, icon, text }) => (
  <div className="d-flex justify-content-start align-items-center signup-login-bar">
    <Link className="d-flex align-items-center welcome-nav-btn" to={to}>
      <div className="welcome-nav-icon">
        <svg viewBox="0 0 24 24">
          <path fill="#000000" d={icon} />
        </svg>
      </div>
      <div className="welcome-nav-text">{text}</div>
    </Link>
  </div>
);
NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavButton;
