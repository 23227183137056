import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetAwards';
import { getCurrentUuid } from '../Utils';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';
import { getAllAwards } from '../../../store/actions/ui/uxProfile/awards';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  const profile = state.data.user.profiles[uuid];
  return {
    uuid,
    loading: !!state.ui.uxProfile.awards.loadingMsg,
    loadingMsg: state.ui.uxProfile.awards.loadingMsg,
    needsToLoadAwards: state.ui.uxProfile.awards.needsToLoadAwards,
    needsToLoadProfile: !profile,
  };
};

const mapDispatchToProps = dispatch => ({
  getAwards: (uuid) => {
    dispatch(getAllAwards(uuid));
  },
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
