import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetAllTeams';
import { getCurrentUuid, getCurrentSportId } from '../Utils';
import { getPlayerTeamsAndDetails } from '../../../store/actions/ui/uxProfile';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return {
    uuid,
    loading: !!state.ui.uxProfile.playerProfile.athleteTeamRostersLoadingMsg,
    loadingMsg: state.ui.uxProfile.playerProfile.athleteTeamRostersLoadingMsg,
    sportId: getCurrentSportId(state),
    athleteTeamRosters: state.ui.uxProfile.playerProfile.athleteTeamRosters,
  };
};

const mapDispatchToProps = dispatch => ({
  getPlayerTeamsAndDetails: (uuid, sportId) => {
    dispatch(getPlayerTeamsAndDetails(uuid, sportId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
