import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/ResetPass/ResetPass';
import { resetPassFormInit } from '../../store/actions/ui/welcome';
import { formSubmit } from '../../store/actions/ui/formUtils';

const mapStateToProps = state => ({
  needsInit: state.ui.welcome.resetPass.formNeedsInit,
  formGeneralError: state.ui.welcome.resetPass.formError,
  form: state.forms.resetPassword,
  rrfForm: state.forms.forms.resetPassword,
  username: state.ui.welcome.resetPass.username,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    formInit: (code) => {
      dispatch(actions.setInitial('forms.resetPassword'));
      dispatch(actions.reset('forms.resetPassword'));
      dispatch(resetPassFormInit());
      if (code) {
        dispatch(actions.change('forms.resetPassword.code', code));
      }
    },
    submit: (isMobile = false, toRedirectUrl) => {
      let username;
      if (isMobile) {
        // eslint-disable-next-line prefer-destructuring
        username = state.ui.welcome.resetPass.username;
      } else {
        username = decodeURI(ownProps.match.params.username).replace('aAdDoOtT', '.');
      }
      dispatch(formSubmit(
        'forms.resetPassword',
        {
          username,
          toRedirectUrl,
        },
      ));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
