import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonRect, CloseButton } from '../../../../components/UxCommon';

class AlertDialog extends PureComponent {
  static propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    msg: PropTypes.string,
    btnMsg: PropTypes.string,
  };

  render() {
    const {
      onCancelClick,
      msg,
    } = this.props;
    let { title } = this.props;
    const question = (
      <span>
        {msg || 'Are you sure you want to delete this?'}
      </span>
    );

    if (!title) {
      title = 'Delete Combine?';
    }

    return (
      <div className="AlertDialogWrapper">
        <div className="AlertDialog">
          <CloseButton size={10} onClick={onCancelClick} />
          <div className="AlbumDialod_DeleteAlbumDialog_Title">
            {title}
          </div>
          <div className="AlbumDialod_DeleteAlbumDialog_Question text-dark font-20">
            <b>{question}</b>
          </div>
          <ButtonRect
            className="AlbumDialod_DeleteAlbumDialog_Btn primary"
            onClick={onCancelClick}
          >
            Ok
          </ButtonRect>
        </div>
      </div>
    );
  }
}

export default AlertDialog;
