import { connect } from 'react-redux';
import Component from './Report.component';
import { selectLoadingMsg, selectSchoolStyle } from '../+store/coachWorld';
import { getFilters } from '../+store/coachWorld/coachWorld.utils';
import { getReport } from '../+store/report';
import { selectFilteredReportAthletes } from '../+store/report/report.selectors';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const { reportId } = ownProps.match.params;
  return {
    reportId,
    loading: !!loadingMsg,
    loadingMsg,
    selectedFilters: getFilters(state, ownProps),
    report: state.modules.coachWorld.report.report[reportId],
    teamReports: state.modules.coachWorld.report.teamReports,
    schoolTeams: state.modules.coachWorld.roster.schoolTeams,
    athleteId: ownProps.match.params.athleteId,
    teamId: ownProps.match.params.teamId,
    schoolStyle: selectSchoolStyle(state, ownProps) || {},
    athletes: selectFilteredReportAthletes(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => ({
  getReport: (reportId) => {
    dispatch(getReport(reportId));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
