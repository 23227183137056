import React from 'react';
import { bank } from '../ProfileStats/ProfileStats';
import ReactTooltip from 'react-tooltip';
import Icon from '../../../UxCommon/Icon/Icon';
import { Link,matchPath } from 'react-router-dom';
import LaserImg from '../../../../assets/images/Laser_v1.png';
import { expandedPhotoDialogTo,Routes, Sports } from '../../../../store/actions/ui/routes';
import { renderLeaderboardColoumLogo } from '../../../../modules/CoachWorld/Leaderboard/CellContent/CellLogo';
import dahsrBlueLogo from '../../../../assets/images/DashrBlue.png'
import nonMembershipLogo from '../../../../assets/images/nonMembershipSmall.png'
import useHasMembership from "../../../../hooks/useHasMembership";



const CombineRecords = ({currentUuid,

  videoData,
  banks,
  personalRecords,
  classNameCombine,
  sportId,
  match,
  isCombineRecords
}) =>{

  const hasMembership = useHasMembership(match);
    const  renderEachRecord = (record,index) =>{
      const dummyValue = Math.round(Math.random() * 99).toFixed(1);
      const result = record.videoId && videoData && Object.keys(videoData).length!==0 &&
          videoData[currentUuid] && videoData[currentUuid].find((data)=> data.id===record.videoId);
      const date = new Date(record.resultDate) ;
      const {schoolId} = match.params;
      const isProfileLandingPage = !!matchPath(location.pathname, {
        path: `/${Routes.profile}/:uuid/${Routes.player}/:sport?/:schoolId?`,
        exact: true,
      });
        return   (record.displayValueWithLabel &&
          <div className={[
            "Combine_Record_Container",
            `${isProfileLandingPage ? `Combine_Record_Container_Profile` : ''}`,
            classNameCombine || '',
            !hasMembership ? 'Membership_Margin' : '',
          ].join(' ')} key={`${record.stdTestBanksCode}${index}`}>
            <div className="PlayerProfile_Laser">
            { !!hasMembership && renderLeaderboardColoumLogo(record.stdTestSubbanksCode, !!record, record.stdTestCategoryCode,Routes.playerProfile,"white","black","right")}
            {
              !!record.isDashr &&
              <div className="personal_record_dashr_label" >
                <img data-place="bottom" data-background-color="black" data-text-color="white" className='personal_record_dashr_logo' src={dahsrBlueLogo} alt="Dashr Laser Verified" data-tip="Dashr Laser Verified" data-place={"right"} height={20} width={20}/>
                  <ReactTooltip />
              </div>
            }
              {(record.testResultLaserBool ? true: false) && !(!!record.isDashr) && (
               <div className="Profile_Performance_Laser" >
               <img src={LaserImg} alt="Laser verified" data-tip="Laser Verified" data-place={"right"} width="28px" />
               <ReactTooltip />
              </div>
              )
              }
            </div>
            <div className={`PlayerProfile_CombineRecords_Value_Header ${ !hasMembership ? 'PlayerProfile_CombineRecords_Value_Header_NMB mb-4' : '' }`}>
              {
                !hasMembership && (
                  <img src={nonMembershipLogo} />
                )
              }
              <div className="d-flex justify-content-center align-items-center Member_Ship_Logo">
              {` ${hasMembership ? record.displayValueWithLabel : dummyValue }`}
              { record.displayValueWithLabel && isCombineRecords && record.schoolName &&
                  <Icon
                  iconName= {"ok-circled"}
                  tooltip={"Verified Data"}
                  place={"right"}
                  style={{color:"#007bff", fontSize:26}}
                  tooltiptextColor={"white"}
                  tooltipbackgroundColor={"black"}
                />
                }
              </div>
            </div>
            <div className={"PlayerProfile_CombineRecords_Bank"}>
              {`${record.stdTestCategoryName}`}
            </div>
            <div className={"Combine_Record_Stamp"}>
              {
                (record.schoolName ? record.schoolName
                : record.location ? record.location
                :'UNVERIFIED') + ' ' +
              (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear()
            }
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
            {
            record.videoId && (<Link
              to={ record.processed || (result && result.processed)  ? expandedPhotoDialogTo(currentUuid, false, record.videoId, Sports[sportId],schoolId): null }
              className={ `PlayerData_StatsTable_CellWithComment PlayerData_VideoIcon ${!hasMembership ? 'Membership-Disabled' : '' }` }>
                <Icon
                iconName="play-circle2"
                style={{color:"red", fontSize:30}}
                />
                <span
                className="PlayerData_StatsTable_CellComment right coach-verified"
                >
                { record.processed || (result && result.processed)  ? "Play video": "Processing... Please Wait."}
              </span>
              </Link>)
              }
            </div>
          </div>)
      }
  switch(banks){
    case bank.PERF:
      return personalRecords && personalRecords.map((record,index)=> {
        // if(sportId !== 2 && sportId !== 4 ){
          return (record.stdTestBanksCode === bank.PERF) && renderEachRecord(record,index)
        // }
        // else if(sportId === 2){
        //   return record.stdTestCategoryCode ==='30YD' && renderEachRecord(record,index)
        // }
      });
    case bank.STRENGTH:
      return personalRecords && personalRecords.map((record,index)=> {
        return (record.stdTestBanksCode === bank.STRENGTH) && renderEachRecord(record,index)
      }
      );
    case bank.PITCHING:
      return personalRecords && personalRecords.sort((record1, record2) => record1.stdTestCategoryOrderNum - record2.stdTestCategoryOrderNum).map((record,index)=>
      (record.stdTestBanksCode === bank.PITCHING) && renderEachRecord(record,index)
      );
    case bank.HITTING:
      return personalRecords && personalRecords.sort((record1, record2) => record1.stdTestCategoryOrderNum - record2.stdTestCategoryOrderNum).map((record,index)=>
      (record.stdTestBanksCode === bank.HITTING) && renderEachRecord(record,index)
      );
    case bank.STATS:
      return personalRecords && personalRecords.map((record,index)=>
      (record.stdTestBanksCode === bank.STATS) && renderEachRecord(record,index)
      );
    default:
      return <div className="PlayerData_PersonalRecords_Header">Unknown Data</div>
  }
  }

export default CombineRecords;
