import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import { ButtonRect, Collapsable, Line } from '../../../../UxCommon';
import Icon from '../../../../UxCommon/Icon/Icon';
import TagItem from '../../SideBar/Tags/TagItem';
import { SelectControl } from '../../../../UxCommon/Form';

class TagsForm extends PureComponent {
  static propTypes = {
    onAddTagClick: PropTypes.func,
    onSaveTagsClick: PropTypes.func,
    rrfForm: PropTypes.object.isRequired,
    onTagNameChange: PropTypes.func.isRequired,
    onDeleteTagClick: PropTypes.func.isRequired,
    tags: PropTypes.array,
    selectedTags: PropTypes.array,
    onAddTagToPhotoClick: PropTypes.func,
    dialogHeight: PropTypes.string.isRequired,
    onTagsBackClick: PropTypes.func.isRequired,
  };

  componentDidMount() {}

  getHeight = () => {
    const { dialogHeight } = this.props;
    return Math.max(dialogHeight, 598);
  }

  render() {
    const {
      onAddTagClick,
      rrfForm,
      onTagNameChange,
      onDeleteTagClick,
      tags,
      onAddTagToPhotoClick,
      selectedTags,
      onSaveTagsClick,
      onTagsBackClick,
    } = this.props;
    const setSelectedTags = new Set(selectedTags);
    const tagsDiffer = [
      ...new Set([...tags].filter(item => !setSelectedTags.has(item))),
    ];
    const dialogHeight = this.getHeight();
    return (
      <div className="Photos_TagsFormWrapper">
        <div className="Photos_TagsFormBackBtnWrapper">
          <button className="Photos_TagsFormBackBtn" onClick={onTagsBackClick} >
            <Icon className="Photos_TagsFormBackBtnIcon" iconName="back-arrow" />
            <div className="Photos_TagsFormBackBtnText">
              Back
            </div>
          </button>
        </div>
        <Form
          className="Photos_TagsForm"
          model="forms.dynamic.expanded.tags"
          style={{ height: `${dialogHeight - 75}px` }}
        >
          <Collapsable
            title="Create a tag"
            keepInside
            topRowStyle={{ minHeight: '59px' }}
          >
            <div />
            <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
              <TagItem
                isEditMode
                isInModal
                isNewTagEnable
                rrfForm={rrfForm}
                onTagNameChange={onTagNameChange}
              />
              <ButtonRect
                style={{ marginLeft: 'auto', width: '100px', float: 'right' }}
                className="Photos_ExpandedPhotoDialog_Button"
                onClick={onAddTagClick}
                type="button"
              >
                Create Tag
              </ButtonRect>
            </div>
          </Collapsable>
          {
            tagsDiffer.length > 0 && (
              <Collapsable
                title="or Select an Existing Tag"
                keepInside
                topRowStyle={{ minHeight: '59px' }}
              >
                <div />
                <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                  <SelectControl
                    label="Tag"
                    placeholder="Select a Tag"
                    rrfField={rrfForm.selectedTag}
                    model="forms.tagDialog.selectedTag"
                    options={tagsDiffer.map(tag => ({
                      value: tag.id,
                      text: tag.label,
                    }))}
                  />
                  <ButtonRect
                    style={{ marginLeft: 'auto', width: '100px', float: 'right' }}
                    className="Photos_ExpandedPhotoDialog_Button"
                    onClick={onAddTagToPhotoClick}
                    type="button"
                  >
                    Add Tag to Photo
                  </ButtonRect>
                </div>
              </Collapsable>
            )
          }
          {
            !!selectedTags.length && (
              <div style={{ paddingLeft: '15px', paddingRight: '15px', marginTop: '30px' }}>
                <Line />
              </div>
            )
          }
          {
            !!selectedTags.length && (
              <Collapsable
                title="Edit Tags"
                keepInside
                topRowStyle={{ minHeight: '59px' }}
              >
                <div />
                <div className="UxProfile_Photos_AlbumDialog_InputWrapper">
                  { selectedTags.map((tag, index) => (
                    <TagItem
                      key={index}
                      tag={tag}
                      isEditMode
                      isInModal
                      rrfForm={rrfForm}
                      onTagNameChange={onTagNameChange}
                      onTagDelete={onDeleteTagClick}
                      isNewTagEnable={false}
                    />
                    ))
                  }
                  <div className="Photos_TagsForm_ButtonsRow">
                    <ButtonRect
                      style={{ marginLeft: 'auto', width: '100px' }}
                      className="Photos_ExpandedPhotoDialog_Button"
                      onClick={onSaveTagsClick}
                      type="button"
                    >
                      Save Tags
                    </ButtonRect>
                  </div>
                </div>
              </Collapsable>
            )
          }
        </Form>
      </div>
    );
  }
}

export default TagsForm;
