import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';

const CheckboxControl = (props) => {
  const {
    label,
    model,
    type,
    help,
    rrfField,
    errors,
    ...otherProps
  } = props;
  return (
    <div className="UxCommon_Form_Group UxCommon_Form_Checkbox">
      <label
        className="UxCommon_Form_CheckboxContainer"
        htmlFor={model}
      >
        <Control.checkbox
          className="UxCommon_Form_CheckboxInput"
          model={model}
          id={model}
          value={rrfField.value}
          {...otherProps}
        />
        <div className="UxCommon_Form_CheckboxLabel">{label}</div>
      </label>
      <HelpPopper>
        {help}
      </HelpPopper>
      <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
    </div>
  );
};
CheckboxControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  help: PropTypes.any,
  rrfField: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default CheckboxControl;
