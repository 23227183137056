import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import Urls from '../../../../../config/urls';


export const getExternalProfile = (uuid, token) => (
  ajax.getJSON( Urls.externalProfile.getAll(uuid), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  } )
)


export const createExternalProfile = (uuid, token , values ) => ajax.post(
    Urls.externalProfile.post(uuid),
    JSON.stringify(values),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
).pipe( map( response  => response.response ) );

export const deleteExternalProfileApi = (uuid, externalProfileId,token) =>  ajax.delete(
    Urls.externalProfile.delete(uuid, externalProfileId),
    {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
  ).pipe(map(response => response.response));



export const updateExternalProfile = (uuid, token, externalProfileId, values) =>
  ajax.put(
    Urls.externalProfile.put(uuid,externalProfileId ),
    JSON.stringify(values),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
  ).pipe(map(response => response.response));
