export const RosterSortByOptions = {
  numberLowHigh: 'numberLowHigh',
  numberHighLow: 'numberHighLow',
  firstNameAZ: 'firstNameAZ',
  firstNameZA: 'firstNameZA',
  lastNameAZ: 'lastNameAZ',
  lastNameZA: 'lastNameZA',
  registered: 'registered',
  nonRegistered: 'nonRegistered',
  // combineNumberLowHigh: 'combineNumberLowHigh',
  // combineNumberHighLow: 'combineNumberHighLow',
};

export const RosterSortByLabels = {
  numberLowHigh: 'Jersey 1-2-3',
  numberHighLow: 'Jersey 3-2-1',
  firstNameAZ: 'First Name A-Z',
  firstNameZA: 'First Name Z-A',
  lastNameAZ: 'Last Name A-Z',
  lastNameZA: 'Last Name Z-A',
  registered: 'Registered Athletes',
  nonRegistered: 'Non-Registered Athletes',
  // combineNumberLowHigh: 'Combine Number 1-2-3',
  // combineNumberHighLow: 'Combine Number 3-2-1',
};

export const RosterViewOptions = {
  card: 'card',
  list: 'list',
};


export const AthleteGrades = [
  ['12', '12th'],
  ['11', '11th'],
  ['10', '10th'],
  ['9', '9th'],
  ['8', '8th'],
  ['7', '7th'],
  ['6', '6th'],
  ['5', '5th'],
  ['4', '4th'],
  ['3', '3rd'],
  ['2', '2nd'],
  ['1', '1st'],
  ['K', 'K'],
  ['13', '13th'],
  ['Redshirt Freshman', 'Redshirt Freshman'],
  ['College Freshman', 'College Freshman'],
  ['College Sophomore', 'College Sophomore'],
  ['College Junior', 'College Junior'],
  ['College Senior', 'College Senior'],

];
export const AthleteGradesTh = {
  'K': 'K Grade',
  1: '1st Grade',
  2: '2nd Grade',
  3: '3rd Grade',
  4: '4th Grade',
  5: '5th Grade',
  6: '6th Grade',
  7: '7th Grade',
  8: '8th Grade',
  9: '9th Grade',
  10: '10th Grade',
  11: '11th Grade',
  12: '12th Grade',
  13: '13th Grade',
  'Redshirt Freshman': 'Redshirt Freshman',
  'College Freshman': 'College Freshman',
  'College Sophomore': 'College Sophomore',
  'College Junior': 'College Junior',
  'College Senior': 'College Senior',
};
