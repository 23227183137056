import React, { useState } from 'react';
import { ButtonRect } from '../../../../components/UxCommon';

const RefreshPopUp = (props) => {

  const [onPressed, setOnPressed] = useState(false);

  const onClicked= () =>{
    const { onCancel } = props;
    setOnPressed(true);
    return onCancel();
  }
const { msz, buttonMsz, buttonPressed } = props;

return <div className="CloseCombineModal_Backdrop">
    <div className="CloseCombineModal">
      <div className="CloseCombineModal_Header" />
      <div className="CloseCombineModal_Body">
        <div className="CloseCombineModal_Text1">
          {msz}
        </div>
      </div>
      <div className="CloseCombineModal_Footer">
        <ButtonRect
          className="CloseCombineModal_Btn CloseCombineModal_Color"
          type="button"
          disabled={onPressed}
          onClick={onClicked}
        >
         {onPressed ? buttonPressed : buttonMsz }
        </ButtonRect>
      </div>
    </div>
  </div>
};

export default RefreshPopUp;
