import { connect } from 'react-redux';
import Component from './Banner.component';
import { selectProfile, selectCoachSchoolIds, resetUpdatingStyleStatus, selectSchoolStyle } from '../../+store';

const mapStateToProps = (state, ownProps) => ({
  authenticatedUuid: state.data.cognito.uuid,
  profile: selectProfile(state),
  coachSchoolIds: selectCoachSchoolIds(state),
  schoolStyle: selectSchoolStyle(state, ownProps) || {},
});

const mapDispatchToProps = dispatch => ({
  resetUpdatingStyleStatus: () => {
    dispatch(resetUpdatingStyleStatus());
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
