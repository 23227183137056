export const MOBILE_SELECT_SPORT = 'mobile.onSelectUserSport';
export const onSelectUserSport = sport => ({
  type: MOBILE_SELECT_SPORT,
  sport,
});

export const MOBILE_TOGGLE_SPORT_NAV = 'mobile.toggleSportNav';
export const toggleSportNav = () => ({
  type: MOBILE_TOGGLE_SPORT_NAV,
});

export const MOBILE_SELECT_PROFILE = 'mobile.selectProfile';
export const selectProfile = uuid => ({
  type: MOBILE_SELECT_PROFILE,
  uuid,
});

