import React from 'react';
import PropTypes from 'prop-types';
import vcLogo from '../../../../assets/images/vc-black.png';

const SchoolLogo = ({ className, schoolStyle, defaultClassName }) => (
  schoolStyle ?
    <div className="CoachRoster_BannerTeamRoundBgrd" >
      <img
        className={`SchoolLogo ${className}`}
        src={schoolStyle.school_logo}
        alt="School Logo"
      />
    </div>
    :
    <div className="CoachRoster_BannerTeamRoundBgrd" >
      <img
        className={`SchoolLogo DefaultSchoolLogo ${defaultClassName? defaultClassName: ''}`}
        src={vcLogo}
        alt="VC Logo"
      />
    </div>
);
SchoolLogo.propTypes = {
  className: PropTypes.string,
  schoolStyle: PropTypes.object,
};

export default SchoolLogo;
