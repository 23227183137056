import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import bugsnag from 'bugsnag-js';
/* eslint-disable */
const bugsnagClient = bugsnag('5c66703aef3221a2c2863055426438b9');
import React from 'react';
import ReactDOM from 'react-dom';
import createPlugin from 'bugsnag-react';

import 'react-dates-custom-month-year-navigation/initialize';

import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-042161 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Virtual Combine LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Virtual Combine )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Virtual Combine )_need_to_be_licensed___( Virtual Combine )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 July 2024 )____[v2]_MTcyMDkxMTYwMDAwMA==019ffda7e9ae6f0518c3f3abcb365b09',
);

import Root from './src/Root';
import { ErrorPage } from './src/components/UxCommon';
/* eslint-enable */

const beforeSend = () => {
  if (__DEV__) {
    return false;
  }
  return true;
};

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorPage} beforeSend={beforeSend}>
    <Root />
  </ErrorBoundary>,
  document.getElementById('root'),
);
