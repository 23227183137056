import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ProfileStatsRow from '../ProfileStatsRow/ProfileStatsRow';

class ProfileStatsTable extends PureComponent {
  static propTypes = {
    position: PropTypes.object.isRequired,
    seasons: PropTypes.array.isRequired,
    currentSport: PropTypes.object.isRequired,
    stdCats: PropTypes.array.isRequired,
  }

  isLastTdInCat = (statPositions, statPosIndex, stdCats, catIndex) => {
    if ((statPosIndex === (statPositions.length - 1))
      && (catIndex !== (stdCats.length - 1))) {
      return true;
    }
    return false;
  }

  render() {
    const {
      position,
      seasons,
      currentSport,
      stdCats,
    } = this.props;
    const { statsSeasonLabel } = currentSport;

    // get statPosition for filtered by onCard
    const tempArr = [];
    const seasonCategoriesInternal = seasons.map(season => season.categories);
    seasonCategoriesInternal.forEach((item) => {
      item.forEach((x) => {
        x.stats.forEach((stat) => {
          tempArr.push(stat.stdStatPosition);
        });
      });
    });

    // filter stdCats by onCard
    stdCats.forEach((item) => {
      const itemStdCats = [];
      item.statPositions.forEach((stPos) => {
        const commonObject = tempArr.find(seasonStatPos => seasonStatPos.id === stPos.id);
        if (commonObject) {
          itemStdCats.push(commonObject);
        }
      });
      item.statPositions = itemStdCats;
    });
    const filteredStdCats = stdCats.filter(stdCatItem => stdCatItem.statPositions.length > 0);
    return (
      <div className="PlayerProfile_StatsTable">
        <div className="PlayerProfile_Stats_Header">
          <div className="PlayerProfile_Stats_Team">{position.name}</div>
        </div>
        <div className="PlayerProfile_Stats_TableWrapper">
          <table className="PlayerProfile_Stats_Table">
            <thead>
              <tr>
                <th className="PlayerProfile_StatsTable_FirstCell" scope="col" />
                {
                  filteredStdCats
                  .map(cat => (
                    cat.category.code === 'GLOBAL' ? (
                      <th
                        key={cat.category.id}
                        className="PlayerProfile_StatsTable_CatName"
                        colSpan={cat.statPositions.length}
                      />
                    ) : (
                      <th
                        className="PlayerProfile_StatsTable_CatName"
                        key={cat.category.id}
                        colSpan={cat.statPositions.length}
                        scope="col"
                      >
                        {cat.category.name}
                      </th>
                    )
                  ))
                }
              </tr>
              <tr>
                <th scope="col">{statsSeasonLabel}</th>
                {
                  filteredStdCats.map((cat, catIndex) => (
                    cat.statPositions
                      .map((sp, spIndex) => (
                        <th
                          key={sp.id}
                          scope="col"
                          className={
                            this.isLastTdInCat(
                              cat.statPositions,
                              spIndex,
                              filteredStdCats,
                              catIndex,
                            )
                            ? 'CatBorder PlayerProfile_StatsTable_CellWithComment'
                            : 'PlayerProfile_StatsTable_CellWithComment'
                          }
                        >
                          {sp.stdStat.code}
                          <span
                            className="PlayerProfile_StatsTable_CellComment right"
                          >{sp.stdStat.name}
                          </span>
                        </th>
                      ))
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                seasons.map(season => (
                  <ProfileStatsRow
                    key={season.season.id}
                    season={season.season}
                    categories={season.categories}
                    stdCats={filteredStdCats}
                    position={position}
                    team={season.season.team}
                    currentSport={currentSport}
                  />
                ))
              }
              <tr className="PlayerProfile_StatsTable_LastRow">
                <td className="PlayerProfile_StatsTable_NameCell" />
                {
                  filteredStdCats.map((cat, catIndex) =>
                    cat.statPositions
                      .map((statPos, statPosIndex) => (
                        <td
                          key={statPos.id}
                          className={
                            this.isLastTdInCat(
                              cat.statPositions,
                              statPosIndex,
                              filteredStdCats,
                              catIndex,
                            )
                            ? 'CatBorder'
                            : ''
                          }
                        />
                    )))
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ProfileStatsTable;
