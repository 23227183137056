import { combineEpics } from 'redux-observable';

import { readEpics } from './read';
import { createEpics } from './create';
import { deleteEpics } from './delete';
import { updateEpics } from './update';
import validationEpics from './validation';

const epics = combineEpics(
  readEpics,
  createEpics,
  deleteEpics,
  updateEpics,
  validationEpics,
);

export default epics;
