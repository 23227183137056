import { combineReducers } from 'redux';
import { SCHOOL_AWARD_LOGO } from '../../actions/data/awardLogo';
import {  ASYNC_FINISH } from '../../actions/ui/async';

const schoolAwardLogo = (state= {}, action) => {
  switch (action.type)
  {
    case ASYNC_FINISH:
      if (action.model === SCHOOL_AWARD_LOGO)
      {
        return action.data.awardLogo;
     }
      return state;
    default:
      return state;
  }
}
const reducers = combineReducers(
  {
    schoolAwardLogo
  }
);
export  default reducers;
