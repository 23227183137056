import { connect } from 'react-redux';

import Component from '../../../components/UxProfile/Photos/SelectAlbumDialog/SelectAlbumDialog';
import {
  closeAlbumSelectDialog,
  openAlbumDialog,
  deleteAlbumEverything,
  deleteAlbumOnly,
} from '../../../store/actions/ui/uxProfile/photosVideos';
import { makeGetAlbumsState, getAlbumRelMedia } from '../../../selectors/Photos';

const getAlbumsState = makeGetAlbumsState();
const mapStateToProps = (state, ownProps) => ({
  albums: getAlbumsState(state, ownProps),
  albumRelMedia: getAlbumRelMedia(state, ownProps),
  loading: !!state.ui.uxProfile.photosVideos.selectAlbumDialog.loadingMsg,
  loadingMsg: state.ui.uxProfile.photosVideos.selectAlbumDialog.loadingMsg,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onCloseClick: () => {
      dispatch(closeAlbumSelectDialog());
    },
    onDeleteEverythingClick: (album) => {
      const albumRelMedia = newStateProps.albumRelMedia
        .filter(rel => rel.albumId === album.id);
      dispatch(deleteAlbumEverything(album, albumRelMedia));
    },
    onDeleteAlbumOnlyClick: (album) => {
      dispatch(deleteAlbumOnly(album));
    },
    onAlbumClick: (album) => {
      if (newStateProps.albumRelMedia && newStateProps.albumRelMedia.length > 0) {
        album.media = newStateProps.albumRelMedia.filter(rel => rel.albumId === album.id);
      }
      dispatch(openAlbumDialog(album));
    },
    onAddAlbumClick: () => {
      dispatch(openAlbumDialog(null));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
