import { createSelector } from 'reselect';
import { getAthletes, getProfiles, getLoadingProfiles } from '../coachWorld/coachWorld.utils';
import { getFilteredSearchedSortedSelectorCreator, getFilteredSearchedSelectorCreator } from '../roster/roster.selectors';
import { selectSchoolTeams } from '../coachWorld';

// import { RosterSortByOptions } from './combine.constants';
const getCurrentCombineSchoolId = (state, ownProps) =>
  (state.modules.coachWorld.combine.combine[ownProps.match.params.combineId] ?
    state.modules.coachWorld.combine.combine[ownProps.match.params.combineId].schoolId : null);

const getAthleteResults = state => state.modules.coachWorld.combine.athleteCombineResults;
const getCombineId = (state, ownProps) => ownProps.match.params.combineId;
const getSchoolTeams = state => state.modules.coachWorld.coachWorld.schoolTeamsLists;
const getMyTeams = state => state.modules.coachWorld.coachWorld.myTeams;
const getCurrentCombine = (state, ownProps) =>
  state.modules.coachWorld.combine.combine[ownProps.match.params.combineId];

export const selectCoachSchoolTeamsListFromCombine = createSelector(
  getCurrentCombine,
  selectSchoolTeams,
  getMyTeams,
  (combine, schoolTeams, coachTeams) => {
    if (combine && schoolTeams && coachTeams) {
      return schoolTeams.filter(s => s.team.sportId === combine.sportId && !!(coachTeams.find(t => t.schoolTeamId === s.team.id && t.userType === 'coach')));
    }
    return [];
  },
);

export const selectCombineCoachAthletes = createSelector(
  getAthletes,
  getProfiles,
  getAthleteResults,
  getCombineId,
  getSchoolTeams,
  getCurrentCombineSchoolId,
  getMyTeams,
  (athletes, profiles, athleteResults, combineId, schoolTeams, schoolId, myTeams) => {
    let coachTeams = {};
    if (schoolTeams && schoolId && schoolTeams[schoolId]) {
      schoolTeams[schoolId]
        .forEach(s => {
          if (!!(myTeams.find(t => t.schoolTeamId === s.team.id && t.userType === 'coach'))) {
            coachTeams[s.team.id] = s.team.teamRef.orderby;
          }
        });
    }
    const isEmpty = Object.values(coachTeams).length === 0;
    const athletesWithProfiles = [];
    if (!isEmpty) {
      athletes.forEach((athlete) => {
        if (athlete.schoolTeamId in coachTeams) {
          // const clonedAthlete = athlete.clone();
          const clonedAthlete = Object.assign({}, athlete);
          if (coachTeams[athlete.schoolTeamId]) {
            clonedAthlete.orderByTeam = coachTeams[athlete.schoolTeamId];
          }
          const combineResults = athleteResults[combineId];
          if (combineResults) {
            const results = athleteResults[combineId][athlete.userId];
            if (results) {
              clonedAthlete.test = results.test;
              clonedAthlete.testResults = results.testResults;
              clonedAthlete.vcScore = results.vcScore;
              clonedAthlete.fromProfile = true;
            }
          }
          athletesWithProfiles.push(clonedAthlete);
        }
      });
    }
    return athletesWithProfiles;
  },
);


export const selectCombineAthletes = createSelector(
  getAthletes,
  getProfiles,
  getAthleteResults,
  getCombineId,
  getSchoolTeams,
  getCurrentCombineSchoolId,
  (athletes, profiles, athleteResults, combineId, schoolTeams, schoolId) => {
    const athletesWithProfiles = [];
    athletes.forEach((athlete) => {
      if (schoolTeams && schoolId && schoolTeams[schoolId]) {
        const schoolTeam = schoolTeams[schoolId]
          .find(t => t.team.id === athlete.schoolTeamId);
        if (schoolTeam && schoolTeam.team) {
          athlete.orderByTeam = schoolTeam.team.teamRef.orderby;
        }
      }
    });
    athletes.forEach((athlete) => {
      const clonedAthlete = Object.assign({}, athlete);
      if (athlete.orderByTeam) {
        clonedAthlete.orderByTeam = athlete.orderByTeam;
      }
      const combineResults = athleteResults[combineId];
      if (combineResults) {
        const results = athleteResults[combineId][athlete.userId];
        if (results) {
          clonedAthlete.test = results.test;
          clonedAthlete.testResults = results.testResults;
          clonedAthlete.vcScore = results.vcScore;
          clonedAthlete.fromProfile = true;
        }
      }
      athletesWithProfiles.push(clonedAthlete);
    });
    return athletesWithProfiles;
  },
);

export const selectFilteredCombineAthletes = (
  getFilteredSearchedSortedSelectorCreator(selectCombineAthletes)
);

export const selectFilteredCombineCoachAthletes = (
  getFilteredSearchedSelectorCreator(selectCombineCoachAthletes)
);

const getExpiringMsg = state => state.modules.coachWorld.combine.expiringMsg;
const getSavingMsg = state => state.modules.coachWorld.combine.savingMsg;

export const selectStatusMsg = createSelector(
  getExpiringMsg,
  getSavingMsg,
  (expiringMsg, savingMsg) => expiringMsg || savingMsg,
);


export const selectSchoolTeamsList = createSelector(
  getCurrentCombine,
  selectSchoolTeams,
  (combine, schoolTeams) => {
    if (combine && schoolTeams && combine.combineTemplate) {
      return schoolTeams.filter(s => s.team.sportId === combine.combineTemplate.sportId);
    }
    return [];
  },
);

export const selectSchoolTeamsListFromCombine = createSelector(
  getCurrentCombine,
  selectSchoolTeams,
  (combine, schoolTeams) => {
    if (combine && schoolTeams) {
      return schoolTeams.filter(s => s.team.sportId === combine.sportId);
    }
    return [];
  },
);

const getLoadingResults = state => state.modules.coachWorld.combine.loadingCombineResults;
const getLoadingCombine = state => state.modules.coachWorld.combine.loadingMsg;

export const selectLongLoading = createSelector(
  getLoadingProfiles,
  getLoadingResults,
  getLoadingCombine,
  (profiles, results, combine) => (
    profiles || results || combine
  ),
);

export default 'assessment.selectors.js';
