import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ControlErrors from '../ControlErrors';
import Icon from '../../../../components/UxCommon/Icon/Icon';

class SelectControl extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    options: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    myOnChange: PropTypes.func,
    myOnBlur: PropTypes.func,
    errors: PropTypes.array,
    help: PropTypes.any,
    useRadio: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  state = {
    value: '',
    isFocused: false,
    defaultValue: '',
  };

  componentDidUpdate() {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
      && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  setDefault = (defaultValue) => {
    const { myOnChange } = this.props;
    let value = defaultValue;
    this.setState({
      defaultValue,
      value,
    });
    if (myOnChange) {
      value = myOnChange(value);
    }
  };

  handleChange = (event) => {
    const { myOnChange } = this.props;
    const newState = {};
    let targetValue = event.target.value;
    if (event.target.dataset.type === 'option') {
      newState.isFocused = false;
      targetValue = event.target.dataset.value;
    }
    let value = '';
    if (myOnChange) {
      value = myOnChange(targetValue);
    }
    newState.value = value || targetValue;
    this.setState(newState);
  };

  handleBlur = (event) => {
    const { myOnBlur } = this.props;
    let isFocused = false;
    if (event.relatedTarget && event.relatedTarget.dataset.type === 'option') {
      isFocused = true;
    }
    let value = '';
    if (myOnBlur) {
      value = myOnBlur(event.target.value);
    }
    this.setState({
      value: value || event.target.value,
      isFocused,
    });
  };

  handleFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  toggleFocus = () => {
    const { isFocused } = this.state;
    this.setState({
      isFocused: !isFocused,
    });
  }

  render() {
    const {
      className, label, errors, help, myOnChange, myOnBlur, classNameRadio,
      options, defaultValue, placeholder, useRadio, ...inputProps
    } = this.props;
    const { value, isFocused } = this.state;
    const selectedLabel = options[value] || '';
    if (useRadio) {
      return (
        <div className={`SimpleTextControl_Group ${className || ''}`}>
          <label className="SimpleTextControl_Label" htmlFor={this.id}>{label}</label>
          { Object.entries(options)
          .map(([optionValue, optionLabel], index) => (
            <div className={`SimpleSelectControl_RadioOption ${classNameRadio || ''}`} key={index}>
              <input
                type="radio"
                id={optionValue}
                name={this.id}
                value={optionValue}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                {...inputProps}
              />
              <label htmlFor={optionValue}>{optionLabel}</label>
            </div>
          ))}
          <ControlErrors errors={errors} />
        </div>
      );
    }
    return (
      <div className={`SimpleTextControl_Group ${className}`}>
        <label className="SimpleTextControl_Label" htmlFor={this.id}>{label}</label>
        <select
          id={this.id}
          className="SimpleSelectControl hidden-lg-up"
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          {...inputProps}
        >
          {
            placeholder && (
              <option value="">{placeholder}</option>
            )
          }
          {
            Object.entries(options)
              .map(([optionValue, optionLabel], index) => (
                <option key={index} value={optionValue}>{optionLabel}</option>
              ))
          }
        </select>
        <div className="SimpleSelectControl_Styled hidden-md-down">
          <button
            className="SimpleSelectControl SimpleSelectControl_Selected"
            type="button"
            // onFocus={this.handleFocus} // Does not work for Safari 12 nor Firefox 63
            onClick={this.toggleFocus}
            onBlur={this.handleBlur}
          >
            {selectedLabel || placeholder}
            <Icon className="SimpleSelectControl_Arrow" iconName="down-chevron" />
          </button>
          <div
            className="SimpleSelectControl_Options"
            style={{ display: isFocused ? 'block' : 'none' }}
          >
            {
                  placeholder && (
                    <button
                      className="SimpleSelectControl_Option"
                      type="button"
                      onClick={this.handleChange}
                      data-type="option"
                      data-value=""
                    >
                      {placeholder}
                    </button>
                  )
                }
            {
                  Object.entries(options)
                    .map(([optionValue, optionLabel], index) => (
                      <button
                        className="SimpleSelectControl_Option"
                        type="button"
                        onClick={this.handleChange}
                        key={index}
                        data-type="option"
                        data-value={optionValue}
                      >
                        {optionLabel}
                      </button>
                    ))
                }
          </div>
        </div>
        {!!help && typeof help === 'string' ? (
          <div className="SimpleTextControl_Help">{help}</div>
        ) : (
          help
        )}
        <ControlErrors errors={errors} />
      </div>
    );
  }
}

export default SelectControl;
