import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PasswordHelp extends PureComponent {
  static propTypes = {
    passwordHelp: PropTypes.object,
  };

  render = () => (
    this.props.passwordHelp &&
      <React.Fragment>
        <div className="password-help-msg">{this.props.passwordHelp.msg}</div>
        <div className="password-help-tests">
          {
            this.props.passwordHelp.tests.map((test, index) => (
              <div
                key={index}
                className={[
                  'align-items-center',
                  'd-flex',
                  'password-help-test',
                  test.passing ? 'passing' : 'failing',
                ].join(' ')}
              >
                <div className="password-help-svg">
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d={
                        test.passing ?
                          'M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z'
                        :
                          'M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
                      }
                    />
                  </svg>
                </div>
                <div className="password-help-msg">
                  {test.msg}
                </div>
              </div>
            ))
          }
        </div>
      </React.Fragment>
  );
}

export default PasswordHelp;
