import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ControlErrors extends PureComponent {
  static propTypes = {
    errors: PropTypes.array,
    style: PropTypes.object,
  }

  componentDidMount() {}

  render() {
    const { errors, style } = this.props;
    return (!!errors && !!errors.length && (
      <div className="SimpleForm_ControlErrors" style={style || null}>
        {
          errors.map((error, index) => (
            typeof error === 'string' ? (
              error && (
                <div key={index} className="SimpleForm_ControlError form-error">
                  {error}
                </div>
              )
            ) : (
              error && (
                <div key={index}>
                  {error}
                </div>
              )
            )
          ))
        }
      </div>
    )) || null;
  }
}

export default ControlErrors;
