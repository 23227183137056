import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { from, Observable } from 'rxjs';
import { combineAll, concatMap } from 'rxjs/operators';

import { Routes, expandedPhotoDialogTo } from '../../../../store/actions/ui/routes';
import { LoadingSpinner, LoadingImg } from '../../../UxCommon';
import Icon from '../../../UxCommon/Icon/Icon';
import LetsAddSome from '../LetsAddSome/LetsAddSome';
import NoneYet from '../NoneYet/NoneYet';
import { getImageUrl } from '../../../../store/actions/ui/uxProfile/photosVideos/utils';

const getRandomInt = (min, max) => Math.floor(Math.random() * ((max - min) + 1)) + min;

class PhotosRow extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    favorites: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    currSport: PropTypes.object,
  };

  state = {
    imageDisplay: null,
  };

  componentDidMount() {
    this.imgs = [];
  }

  componentDidUpdate() {
    const { favorites } = this.props;
    this.shouldReload();
    if (favorites.length) {
      if (!this.loaded) {
        this.loadImages();
        this.loaded = true;
      }
    }
  }

  componentWillUnmount() {
    this.imgs.forEach((img) => {
      img.onload = undefined;
    });
  }

  getRoute = (page) => {
    const { match } = this.props;
    return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}`;
  };

  loaded = false;
  prevUuid = '';
  prevSportId = 0;
  shouldReloadImages = false;

  shouldReload = () => {
    const { match, currSport , shouldReloadImages} = this.props;
    if (match.params.uuid !== this.prevUuid) {
      this.prevUuid = match.params.uuid;
      this.loaded = false;
    }
    const sportId = currSport ? currSport.sportId : 0;
    if (sportId !== this.prevSportId) {
      this.prevSportId = sportId;
      this.loaded = false;
    }

    if(this.shouldReloadImages !== shouldReloadImages){
      this.shouldReloadImages = shouldReloadImages;
      this.loaded = false;
    }


  }

  simpleDisplayImages = () => {
    const { favorites } = this.props;
    return (
      <div className="container-fluid">
        <div className="UxProfile_PhotosRow_SimpleImgs row">
          {
            favorites.map(media => (
              media.isAPhoto ? (
                !!getImageUrl(media) && (
                  <div
                    key={media.id}
                    className="UxProfile_PhotosRow_SimpleImgWrapper col-4"
                  >
                    <LoadingImg
                      srcUrl={getImageUrl(media)}
                      className="UxProfile_PhotosRow_SimpleImg"
                      cover
                    />
                  </div>
                )
              ) : (
                !!media.processed && (
                  <div
                    key={media.id}
                    className="UxProfile_PhotosRow_SimpleImgWrapper col-4"
                  >
                    <LoadingImg
                      key={media.id}
                      srcUrl={media.getThumb()}
                      className="UxProfile_PhotosRow_SimpleImg"
                      style={{ alignItems: 'center', background: 'black' }}
                    />
                    <Icon iconName="curved-play" className="UxProfile_PhotosRow_PlayIcon" />
                  </div>
                )
              )
            ))
          }
        </div>
      </div>
    );
  }

  orgranizeImages = (images) => {
    const rowHeight = 300;
    const totalX = 12;
    const totalY = 12;

    const choice1 = {
      choice: 'one',
      x: 4,
      y: 6,
      width: '33.3333%',
      height: `${rowHeight / 2}px`,
      setX: (xLeft, yLeft) => {
        if (yLeft === totalY) {
          return xLeft;
        }
        if ((xLeft - 4) <= 0) {
          return totalX;
        }
        return xLeft - 4;
      },
      setY: (xLeft, yLeft) => {
        if (yLeft === totalY) {
          return yLeft - 6;
        }
        return totalY;
      },
    };
    const choice2 = {
      choice: 'two',
      x: 8,
      y: 12,
      width: '66.6666%',
      height: `${rowHeight}px`,
      setX: (xLeft) => {
        if ((xLeft - 8) <= 0) {
          return totalX;
        }
        return xLeft - 8;
      },
      setY: () => totalY,
    };
    const choice3 = {
      choice: 'three',
      x: 4,
      y: 12,
      width: '33.3333%',
      height: `${rowHeight}px`,
      setX: (xLeft) => {
        if ((xLeft - 4) <= 0) {
          return totalX;
        }
        return xLeft - 4;
      },
      setY: () => totalY,
    };
    const portraitChoices = [choice3];
    const landscapeChoices = [choice1, choice2];

    const shuffle = (array) => {
      let currentIndex = array.length;
      let temporaryValue;
      let randomIndex;
      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    };

    // portrait: bool
    const getChoices = (portrait, xLeft, yLeft) => {
      const choices = [];
      if (portrait) {
        portraitChoices.forEach((potential) => {
          if ((yLeft - potential.y >= 0) &&
            (xLeft - potential.x >= 0)) {
            choices.push(potential);
          }
        });
      } else {
        landscapeChoices.forEach((potential) => {
          if ((yLeft - potential.y >= 0) &&
            (xLeft - potential.x >= 0)) {
            choices.push(potential);
          }
        });
      }
      return shuffle(choices);
    };

    // const getRandomInt = (min, max) => Math.floor(Math.random() * ((max - min) + 1)) + min;

    const placedImages = [];
    let xLeft = 12;
    let yLeft = 12;
    const imagesToPlace = shuffle(images);
    const stack = [];

    // let imageToTry = imagesToPlace.pop();
    let choices;
    imagesToPlace.forEach((imageToTry) => {
      choices = getChoices(imageToTry.isPortrait, xLeft, yLeft);
      choices.forEach((choice) => {
        stack.push({
          choice,
          image: imageToTry,
        });
      });
    });

    const filterImagesByAlreadyPlaced = (img => (!placedImages.find(choic => choic.image === img)));
    while (stack.length) {
      // console.log('stack', stack);
      const choiceToTry = stack.pop();

      if (!placedImages.find(p => p.image === choiceToTry.image)) {
        const oldXLeft = xLeft;
        const oldYLeft = yLeft;
        const newXLeft = choiceToTry.choice.setX(xLeft, yLeft);
        yLeft = choiceToTry.choice.setY(xLeft, yLeft);
        xLeft = newXLeft;
        // console.log('choiceToTry', choiceToTry, 'yLeft', yLeft, 'xLeft', xLeft);
        placedImages.push(choiceToTry);

        if (placedImages.length === imagesToPlace.length) {
          break;
        }

        let filteredImagesToPlace = imagesToPlace.filter(img => img !== choiceToTry.image);
        filteredImagesToPlace = filteredImagesToPlace.filter(filterImagesByAlreadyPlaced);
        let choicesAvailable = false;
        for (let j = 0; j < filteredImagesToPlace.length; j += 1) {
          const imageToTry = filteredImagesToPlace[j];
          choices = getChoices(imageToTry.isPortrait, xLeft, yLeft);
          if (choices.length) {
            choicesAvailable = true;
          }
          // console.log('imageToPlace', imageToTry, 'choices', choices);
          for (let i = 0; i < choices.length; i += 1) {
            stack.push({
              choice: choices[i],
              image: imageToTry,
            });
          }
        }
        if (!choicesAvailable) {
          placedImages.pop();
          xLeft = oldXLeft;
          yLeft = oldYLeft;
        }
      }
    }
    return this.renderPlacedImages(placedImages);
  };

  loadImages = () => {
    const { favorites } = this.props;

    const loadImage = imagePath => (
      Observable.create((observer) => {
        const img = new Image();
        img.src = imagePath.url;
        img.onload = () => {
          observer.next([{
            image: img,
            isAPhoto: imagePath.isAPhoto,
            mediaId: imagePath.mediaId,
          }]);
          observer.complete();
        };
        img.onError = (err) => {
          observer.error(err);
        };
        this.imgs.push(img);
      })
    );

    const imagePaths = favorites.map((fav) => {
      if (fav.isAPhoto) {
        return {
          url: fav.resizedPhotos.medium,
          isAPhoto: true,
          mediaId: fav.id,
        };
      }
      return {
        url: fav.getThumb(),
        isAPhoto: false,
        mediaId: fav.id,
      };
    }).filter(i => !!i.url); // remove if the url is not defined or null

    const isPortrait = (img) => {
      let shape;
      if (img.height > img.width) {
        shape = 'portrait';
      } else if (img.width > img.height) {
        shape = 'landscape';
      } else {
        shape = 'square';
      }
      return shape === 'portrait';
    };

    from(imagePaths).pipe(
      concatMap(loadImage),
      combineAll(),
    ).subscribe((arr) => {
      const imgs = [];
      arr.forEach((i) => {
        imgs.push({
          height: i.image.height,
          width: i.image.width,
          src: i.image.src,
          isPortrait: isPortrait(i.image),
          isAPhoto: i.isAPhoto,
          mediaId: i.mediaId,
        });
      });
      this.orgranizeImages(imgs);
    });
  };

  renderIcon = () => {
    return (
      <Icon iconName="curved-play" className="UxProfile_PhotosRow_PlayIcon" />
    )
  }

  renderCard = (choice, isCol) => {
    const { match, hasMembership } = this.props;
    const {sport,schoolId} = match.params;
    return (
      <div
        key={getRandomInt(0, 1000000)}
        className={ `UxProfile_PhotosRow_PhotoCard`}
        style={{
          width: isCol ? choice.choice.width : '100%',
          height: choice.choice.height,
        }}
      >
        <Link
          className={`UxProfile_PhotosRow_PhotoCardImg ${!hasMembership ? 'UxProfile_PhotosRow_PhotoCardImg_NonMembership' : '' }`}
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${choice.image.src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          to={ hasMembership ? expandedPhotoDialogTo(match.params.uuid, choice.image.isAPhoto, choice.image.mediaId,sport,schoolId) : false}
        >
          {
            !choice.image.isAPhoto ? (
              this.renderIcon()
            ) : (
              <div />
            )
          }
        </Link>
      </div>
    );
  };

  renderPlacedImages = (imageChoices) => {
    const rows = [];

    const totalX = 12;
    const totalY = 12;
    let xLeft = 12;
    let yLeft = 12;

    let col = [];
    let row = [];
    imageChoices.forEach((choice) => {
      // console.log('choice', choice);
      col.push(choice);
      const newYLeft = choice.choice.setY(xLeft, yLeft);
      if (newYLeft === totalY) {
        if (col.length === 1) {
          const c = col[0];
          row.push((this.renderCard(c, true)));
        } else {
          row.push((
            <div
              key={getRandomInt(0, 1000000)}
              style={{
                width: choice.choice.width,
              }}
            >
              {col.map(c => (this.renderCard(c, false)))}
            </div>
          ));
        }
        col = [];
      }
      xLeft = choice.choice.setX(xLeft, yLeft);
      yLeft = newYLeft;
      if ((xLeft === totalX) && (row.length)) {
        rows.push((
          <div
            className="UxProfile_PhotosRow_Row"
            key={getRandomInt(0, 1000000)}
          >
            {row.map(c => c)}
          </div>
        ));
        row = [];
      }
    });

    // If a user has one favorite make sure it's displayed
    if ((imageChoices.length > 0) && (rows.length === 0)) {
      if (col.length > 0) {
        if (col.length === 1) {
          const c = col[0];
          row.push((this.renderCard(c, true)));
        } else {
          row.push((
            <div
              key={getRandomInt(0, 1000000)}
              style={{
                width: imageChoices[0].choice.width, // TODO Aaron check
              }}
            >
              {col.map(c => (this.renderCard(c, false)))}
            </div>
          ));
        }
      }
      if (row.length) {
        rows.push((
          <div
            className="UxProfile_PhotosRow_Row"
            key={getRandomInt(0, 1000000)}
          >
            {row.map(c => c)}
          </div>
        ));
      }
    }

    const imageDisplay = (
      <div className= {`UxProfile_PhotosRow_Photos`}>
        {rows}
      </div>
    );
    this.setState({
      imageDisplay,
    });
  };

  renderEmptyRow = () => {
    const { isMyProfile, match , hasMembership} = this.props;
    if (isMyProfile && hasMembership ) {
      return (
        <LetsAddSome
          msg="Let's add some photos"
          page={Routes.photos}
          btnMsg="Add Photos"
          match={match}
        />
      );
    }
    return (
      <NoneYet msg="No photos or videos have been added yet" />
    );
  }

  render() {
    const {
      favorites,
      loadingMsg,
      loading,
    } = this.props;
    const { imageDisplay } = this.state;
    const isLoading = (loading || (!!favorites.length && !imageDisplay));
    return (
      <div className="UxProfile_PhotosRow" style={{ minHeight: (isLoading ? '300px' : '0px') }}>
        <LoadingSpinner
          loading={isLoading}
          loadingMsg={loadingMsg}
        />
        {
          !favorites.length && this.renderEmptyRow()
        }
        {
          imageDisplay
        }
      </div>
    );
  }
}

// !!favorites.length && (
//   this.simpleDisplayImages()
// )

export default PhotosRow;
