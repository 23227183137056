/* eslint react/no-unused-state: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GetPlayerData extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    getStats: PropTypes.func.isRequired,
    getTests: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    sportId: PropTypes.number,
    getProfile: PropTypes.func.isRequired,
  };

  state = {
    loadDataRequired: false,
    loadStatsRequired: false,
    prevUuid: '',
    prevSportId: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const derivedState = {
      loadDataRequired: false,
      loadStatsRequired: false,
      loadProfileRequired: false,
    };
    if (props.uuid !== state.prevUuid) {
      derivedState.loadDataRequired = true;
      derivedState.loadStatsRequired = true;
      derivedState.loadProfileRequired = true;
      derivedState.prevUuid = props.uuid;
    }
    if (props.sportId !== state.prevSportId) {
      derivedState.loadDataRequired = true;
      derivedState.loadStatsRequired = true;
      derivedState.prevSportId = props.sportId;
    }
    return derivedState;
  }

  componentDidMount() {
    const {
      getStats,
      uuid,
      sportId,
      getTests,
      getProfile,
    } = this.props;
    getProfile(uuid);
    if (sportId) {
      getTests(uuid, sportId, 'Loading tests');
      getStats(uuid, sportId);
    }
  }

  componentDidUpdate() {
    const {
      uuid,
      sportId,
      getStats,
      getTests,
      getProfile,
    } = this.props;
    const {
      loadDataRequired,
      loadStatsRequired,
      loadProfileRequired,
    } = this.state;
    if (loadProfileRequired) {
      getProfile(uuid);
    }
    if (loadDataRequired && uuid && sportId) {
      getTests(uuid, sportId, 'Loading tests');
    }
    if (loadStatsRequired && uuid && sportId) {
      getStats(uuid, sportId, 'Loading stats');
    }
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default GetPlayerData;
