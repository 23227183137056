import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import Urls from '../../../../../config/urls';

export const getAwards = (uuid, token) =>
  ajax.getJSON(
    Urls.awards.getAll(uuid),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export const getAward = (uuid, token, awardId) =>
  ajax.getJSON(
    Urls.awards.get(uuid, awardId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  );

export const createAward = (uuid, token, values, isCoach=false) =>
  ajax.post(
    Urls.awards.post(uuid, isCoach),
    JSON.stringify(values),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const deleteAward = (uuid, awardId, token, isCoach=false) =>
  ajax.delete(
    Urls.awards.delete(uuid, awardId, isCoach),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const favoriteAward = (uuid, awardId, sportId, token) =>
  ajax.post(
    Urls.awards.favorites.post(uuid, awardId, sportId),
    JSON.stringify({}),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const unfavoriteAward = (uuid, awardId, sportId, token) =>
  ajax.delete(
    Urls.awards.favorites.delete(uuid, awardId, sportId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const updateAward = (uuid, token, awardId, values, isCoach=false) =>
  ajax.put(
    Urls.awards.put(uuid, awardId, isCoach),
    JSON.stringify(values),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const toggleAllLaserApi = (combineId, standardTestCategoryId, token) =>
  ajax.put(
    Urls.combineStandardTestCategories.toggleAllLaser(combineId, standardTestCategoryId),
    JSON.stringify([]),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const endorseAward = (uuid, token, awardId) =>
  ajax.post(
    Urls.awards.endorse.post(uuid, awardId),
    JSON.stringify({}),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export const unendorseAward = (uuid, token, awardId) =>
  ajax.delete(
    Urls.awards.endorse.delete(uuid, awardId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));
