import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SearchResult extends PureComponent {
  static propTypes = {
    result: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { result, onClick } = this.props;
    onClick(result);
  }

  render() {
    const { result } = this.props;
    return (
      <button
        className="TeamTypeAhead_SearchResult"
        type="button"
        onClick={this.onClick}
      >
        {result.text}
      </button>
    );
  }
}

export default SearchResult;
