import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import Banner from '../common/Banner';
import { Routes } from '../../../store/actions/ui/routes';
import TwoToneButton from '../common/TwoToneButton';
import AthleteBase from './AthleteReport';
import TeamBase from './TeamReport';
import RawComparisonPage from './RawComparisonPage';
import CompareBanner from './Banners/CompareBanner.component';
import AthleteBanner from './Banners/AthleteBanner.component';
import TeamBanner from './Banners/TeamBanner.component';

class Report extends PureComponent {
  static propTypes = {
    getReport: PropTypes.func.isRequired,
    reportId: PropTypes.string.isRequired,
    athleteId: PropTypes.string,
    teamId: PropTypes.string,
    match: PropTypes.object.isRequired,
    report: PropTypes.object,
    athletes: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onPrintClick = () => {
    const pri = document.getElementById('printable').contentWindow;
    pri.focus();
    pri.print();
  }

  reportFetched = false;

  shouldUpdate = () => {
    const { getReport, reportId } = this.props;
    if (!this.reportFetched) {
      this.reportFetched = true;
      getReport(reportId);
    }
  };

  redirectToTab = () => {
    const { reportId, match } = this.props;
    return <Redirect to={`/${Routes.school}/${match.params.schoolId}/${Routes.report}/${reportId}/${Routes.compare}`} />;
  };

  renderAthleteBanner = () => {
    const { report, athleteId, athletes } = this.props;
    const currentAthlete = athletes.find(a => a.athlete_uuid === athleteId);
    return (
      <AthleteBanner
        currentAthlete={currentAthlete}
        report={report}
        athleteId={athleteId}
      />
    );
  };

  renderTeamBanner = () => {
    const { report, teamId } = this.props;
    return <TeamBanner report={report} teamId={teamId} />;
  };

  renderTwoCombinesBanner = () => {
    const { report } = this.props;
    return <CompareBanner report={report} />;
  };

  render() {
    const { match } = this.props;
    return (
      <div className="CombineReport">
        <Banner match={match}>
          <div className="CoachRoster_BannerChildren">
            <div className="CoachRoster_BannerLeft">
              <Switch>
                <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/:bank?`} render={this.renderAthleteBanner} />
                <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/:bank?`} render={this.renderTeamBanner} />
                <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId`} render={this.renderTwoCombinesBanner} />
              </Switch>
            </div>
            <div className="CoachRoster_BannerButtonGroup">
              {/*
                <TwoToneButton
                  onClick={this.onPrintClick}
                  text="Download & Share Report"
                  iconName="report" />
              */}
              <TwoToneButton className="print" onClick={this.onPrintClick} text="Print" iconName="print" />
            </div>
          </div>
        </Banner>
        <Switch>
          <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/${Routes.athlete}/:athleteId/:bank?`} component={AthleteBase} />
          <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.team}/:teamId/:bank?`} component={TeamBase} />
          <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId/${Routes.compare}/:bank?`} component={RawComparisonPage} />
          <Route path={`/${Routes.school}/:schoolId/${Routes.report}/:reportId`} render={this.redirectToTab} />
        </Switch>
      </div>
    );
  }
}

export default Report;
