import {asyncStartAction, asyncFinishAction} from '../../../../../store/actions/ui/async';


export const UPDATE_INVITE_PROFILE = 'update.invite.profile';
export const playerProfileUpdateTest = (uuid, profileData = {}, teamData = {}) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_INVITE_PROFILE));
  dispatch({
    type: UPDATE_INVITE_PROFILE,
    uuid,
    profileData,
    teamData
  });
};


export const UPDATE_INVITE_PROFILE_FORM_FINAL = 'update.invite.profile.form.final';
export const updateInviteProfileFinalForm = (uuid, profileData = {}) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_INVITE_PROFILE_FORM_FINAL));
  dispatch({
    type: UPDATE_INVITE_PROFILE_FORM_FINAL,
    uuid,
    profileData,
  });
};

export const INVITE_BACK_TO_PREVIOUS_STEP = 'invite.back.to.previous.step';
export const backToPreviousStep = (uuid) => (dispatch) => {
  dispatch(asyncFinishAction(INVITE_BACK_TO_PREVIOUS_STEP, {
   uuid,
  }));
};
