import React from 'react';
import PropTypes from 'prop-types';
import { ContainerFluid, Row, Col, Collapsable, BarGraph, LoadingSpinner } from '../../../../UxCommon';
import RatingLabel from './RatingLabel/RatingLabel';

const VcScoreBarGraph = ({ vcScore }) => {
  const vcScoreYAxis = {
    start: 0,
    end: 1000,
    interval: 100,
  };
  let vcPerfValue = vcScore.performanceRating;
  let vcPerfText = '';
  if (vcPerfValue === 'N/A') {
    vcPerfValue = 0;
    vcPerfText = 'N/A';
  }
  let vcStrengthValue = vcScore.strengthRating;
  let vcStrengthText = '';
  if (vcStrengthValue === 'N/A') {
    vcStrengthValue = 0;
    vcStrengthText = 'N/A';
  }
  const vcScoreBars = [
    {
      value: vcPerfValue,
      text: vcPerfText,
      label: <RatingLabel label="Performance" value={vcScore.performanceRating} />,
    },
    {
      value: vcStrengthValue,
      text: vcStrengthText,
      label: <RatingLabel label="Strength" value={vcScore.strengthRating} />,
    },
  ];
  let maxValue = null;
  const values = Object.values(vcScore);
  if (values) {
    maxValue = Math.max(...values);
  }
  if (maxValue && maxValue > vcScoreYAxis.end) {
    vcScoreYAxis.end = (Math.round(maxValue / 100) * 100);
    vcScoreYAxis.interval = vcScoreYAxis.end / 10;
  }
  return (
    <BarGraph
      data={{ yAxis: vcScoreYAxis, bars: vcScoreBars }}
    />
  );
};
VcScoreBarGraph.propTypes = { vcScore: PropTypes.object.isRequired };

const StrengthBarGraph = ({ strength }) => {
  const strengthYAxis = {
    start: 0,
    end: 1000,
    interval: 100,
  };
  const strengthBars = [
    {
      value: strength.upperBodyStrengthRating,
      text: strength.upperBodyStrengthRating === 0 ? 'N/A' : '',
      label: <RatingLabel label="Upper" value={strength.upperBodyStrengthRating} />,
    },
    {
      value: strength.legHipStrengthRating,
      text: strength.legHipStrengthRating === 0 ? 'N/A' : '',
      label: <RatingLabel label={`Leg & Hip${''}`} value={strength.legHipStrengthRating} />,
    },
    {
      value: strength.explosiveStrengthRating,
      text: strength.explosiveStrengthRating === 0 ? 'N/A' : '',
      label: <RatingLabel label="Explosive" value={strength.explosiveStrengthRating} />,
    },
  ];
  let maxValue = null;
  const values = Object.values(strength);
  if (values) {
    maxValue = Math.max(...values);
  }
  if (maxValue && maxValue > strengthYAxis.end) {
    strengthYAxis.end = (Math.round(maxValue / 100) * 100);
    strengthYAxis.interval = Math.floor(strengthYAxis.end / 10);
  }
  return (
    <BarGraph
      data={{ yAxis: strengthYAxis, bars: strengthBars }}
    />
  );
};
StrengthBarGraph.propTypes = { strength: PropTypes.object.isRequired };

const PerfBarGraph = ({ performance }) => {
  const perfYAxis = {
    start: 0,
    end: 1000,
    interval: 100,
  };
  const performanceBars = [
    {
      value: performance.speedRating,
      text: performance.speedRating === 0 ? 'N/A' : '',
      label: <RatingLabel label="Speed" value={performance.speedRating} />,
    },
    {
      value: performance.agilityRating,
      text: performance.agilityRating === 0 ? 'N/A' : '',
      label: <RatingLabel label="Agility" value={performance.agilityRating} />,
    },
    {
      value: performance.explosionRating,
      text: performance.explosionRating === 0 ? 'N/A' : '',
      label: <RatingLabel label="Explosion" value={performance.explosionRating} />,
    },
  ];
  let maxValue = null;
  const values = Object.values(performance);
  if (values) {
    maxValue = Math.max(...values);
  }
  if (maxValue && maxValue > perfYAxis.end) {
    perfYAxis.end = (Math.round(maxValue / 100) * 100);
    perfYAxis.interval = perfYAxis.end / 10;
  }
  return (
    <BarGraph
      data={{ yAxis: perfYAxis, bars: performanceBars }}
    />
  );
};
PerfBarGraph.propTypes = { performance: PropTypes.object.isRequired };

const renderSelectedGraph = (vcScore, strength, performance, selectedTab) => {
  switch (selectedTab) {
    case 'vcscore':
      return (
        <Col>
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              vcScore && <VcScoreBarGraph vcScore={vcScore} />
            }
          </div>
        </Col>
      );
    case 'perfsign':
      return (
        <Col>
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              performance && <PerfBarGraph performance={performance} />
            }
          </div>
        </Col >
      );
    case 'strsign':
      return (
        <Col>
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              strength && <StrengthBarGraph strength={strength} />
            }
          </div>
        </Col>
      );
    default:
      return null;
  }
};

const RatingsRow = ({
  vcScore, strength, performance, selectedTab,
}) => (
  <ContainerFluid className="RatingContainer" style={{ position: 'relative' }}>
    <LoadingSpinner loading={!vcScore} loadingMsg="Loading your ratings" />
    <Row className="RatingRowForSmallSize">
      {renderSelectedGraph(vcScore, strength, performance, selectedTab)}
    </Row>
    <Row className="RatingRow">
      <Col>
        <Collapsable title="VC Score">
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              vcScore && <VcScoreBarGraph vcScore={vcScore} />
            }
          </div>
        </Collapsable>
      </Col>
      <Col>
        <Collapsable title="Performance Signature">
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              performance && <PerfBarGraph performance={performance} />
            }
          </div>
        </Collapsable>
      </Col>
      <Col>
        <Collapsable title="Strength Signature">
          <div />
          <div style={{ minHeight: '100px', width: '100%' }}>
            {
              strength && <StrengthBarGraph strength={strength} />
            }
          </div>
        </Collapsable>
      </Col>
    </Row>
  </ContainerFluid>
);
RatingsRow.propTypes = {
  vcScore: PropTypes.object,
  strength: PropTypes.object,
  performance: PropTypes.object,
  selectedTab: PropTypes.string,
};

export default RatingsRow;
