import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/UxProfile/PlayerCard/PlayerCard';
import {
  getSelectedSport,
  getProfile,
  getCurrentUuid, getCurrentSportId,
} from '../UxCommon/Utils';
import {
  sidebarShowMenu,
  sidebarHideMenu,
  sidebarFocusSearch,
  sidebarUnfocusSearch,
  sidebarShowInfo,
  sidebarHideInfo,
  sidebarShowLogoutDialog,
  sidebarOpenShareDialog,
  playerPositionDetail,
  playerPositionShowDetail,
  playerPositionHideDetail, enableCalendlyModal,
} from '../../store/actions/ui/uxProfile';
import { profileShareUri } from '../../store/actions/ui/routes';
import { checkIfCanEdit } from '../../store/actions/ui/app';

const mapStateToProps = (state, ownProps) =>
{
  const currentUUid = getCurrentUuid(state, ownProps.match);

  return {
    isMyProfile: getCurrentUuid(state, ownProps.match) === state.data.cognito.uuid
    || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    uuid: state.data.cognito.uuid,
    playerUuid: getCurrentUuid(state, ownProps.match),
    selectedUserSport: getSelectedSport(state, ownProps.match),
    isMenuShown: state.ui.uxProfile.sidebar.isMenuShown,
    user: getProfile(state, ownProps.match),
    isSearchFocused: state.ui.uxProfile.sidebar.isSearchFocused,
    isInfoShown: state.ui.uxProfile.sidebar.isInfoShown,
    playerPositionDetail: state.ui.uxProfile.playerProfile.playerPositionDetail,
    canEditProfile: state.ui.app.context.canEditProfile,
    externalProfile : state.data.user.externalProfile[currentUUid] || [],
    currentSportId: getCurrentSportId(state),
    athleteTeamRosters: state.ui.uxProfile.playerProfile.athleteTeamRosters,
    state,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const { playerUuid } = newStateProps;
  return {
    ...newStateProps,
    ...ownProps,
    selectedUserSport: getSelectedSport(state, ownProps.match),
    onMenuClick: () => {
      if (stateProps.isMenuShown) {
        dispatch(sidebarHideMenu());
      } else {
        dispatch(sidebarShowMenu());
      }
    },
    onClearSearchClick: () => {
      dispatch(actions.change('forms.sidebarSearch.search', ''));
    },
    onSearchClick: () => {
      dispatch(sidebarFocusSearch());
    },
    onSearchLoseFocus: () => {
      dispatch(sidebarUnfocusSearch());
      dispatch(sidebarHideMenu());
    },
    showPlayerInfo: () => { dispatch(sidebarShowInfo()); },
    hidePlayerInfo: () => { dispatch(sidebarHideInfo()); },
    showLogoutDialog: () => { dispatch(sidebarShowLogoutDialog()); },
    onPositionDetail: (detail) => {
      if (stateProps.playerPositionDetail === detail) {
        dispatch(playerPositionHideDetail());
      } else {
        dispatch(playerPositionDetail(detail));
        dispatch(playerPositionShowDetail());
      }
    },
    onShareClick: () => {
      dispatch(sidebarOpenShareDialog(profileShareUri(
        playerUuid,
        newStateProps.selectedUserSport.sportId,
        ownProps.match.params.hasOwnProperty("schoolId") && ownProps.match.params.schoolId
      )));
    },
    checkIfCanEdit: () => {
      dispatch(checkIfCanEdit(playerUuid));
    },
    updateCalendlyModal: () => {
      dispatch(enableCalendlyModal(true));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
