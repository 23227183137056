import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Bio extends PureComponent {
  static propTypes = {
    userSport: PropTypes.object,
    isBioShown: PropTypes.bool.isRequired,
    onShowBioClick: PropTypes.func.isRequired,
    onHideBioClick: PropTypes.func.isRequired,
  };

  state = {
    showReadMore: false,
  };

  componentWillReceiveProps() {
    const shortBioDiv = document.getElementsByClassName('UxProfile_PP_BioRow_Bio');
    const longBioDiv = document.getElementsByClassName('BioText_Selector');

    if ((shortBioDiv && longBioDiv) && (shortBioDiv.length > 0 && longBioDiv.length > 0) &&
      (longBioDiv.length > 3 || longBioDiv[0].offsetHeight > shortBioDiv[0].offsetHeight)) {
      this.setState({
        showReadMore: true,
      });
    }
  }

  onBioClick = () => {
    const { isBioShown, onShowBioClick, onHideBioClick } = this.props;
    if (isBioShown) {
      onHideBioClick();
    } else {
      onShowBioClick();
    }
  };

  filterAboutMe = (aboutMe) => {
    if (aboutMe && (aboutMe).length > 1 && (/^@/).test(aboutMe)) { // check if first character is @
      return {
        status: true,
        showText: `${aboutMe}`,
        twitterUrl: `https://twitter.com/${(aboutMe.replace('@', ''))}`,
      };
    }
    return {
      status: false,
    };
  };

  render() {
    const { userSport, isBioShown } = this.props;
    const { showReadMore } = this.state;
    const fontSize = 14;
    const lineHeight = 1.5;
    const bioStyle = {
      fontSize: `${fontSize}px`,
      lineHeight,
    };
    if (!isBioShown) {
      bioStyle.height = `${fontSize * lineHeight * 3}px`;
    }

    return (
      <React.Fragment>
        <button
          className="UxProfile_PP_BioRow_Bio HeightAuto"
          onClick={this.onBioClick}
          style={bioStyle}
        >
          {userSport && userSport.bio.split('\n')
            .map((line, index) => <div className="BioText_Selector" key={index}>{line}</div>)}
          {
            isBioShown ?
              <React.Fragment>
                {' '}
                <div
                  className={[
                    'UxProfile_PP_BioRow_Bio_Hide',
                    showReadMore ? '' : 'd-none',
                  ].join(' ')}
                >
                  Hide Bio
                </div>
              </React.Fragment>
              :
              <div
                className={[
                  'UxProfile_PP_BioRow_Bio_ReadMore',
                  showReadMore ? '' : 'd-none',
                ].join(' ')}
              >
                ... Read More
              </div>
          }
        </button>
      </React.Fragment>
    );
  }
}

export default Bio;
