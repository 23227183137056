import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../UxCommon/Form';
import { ButtonLink } from '../../../UxCommon';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import { Sports, Routes } from '../../../../store/actions/ui/routes';

class ProfilePhotoCol extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    selectedUserSport: PropTypes.object,
    onRemoveClicked: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {}

  render() {
    const {
      selectedUserSport,
      onRemoveClicked,
      uuid,
      match,
      isCoach,
    } = this.props;
    return (
      <div className="UxEditProfile_ProfilePhotoCol">
        <ProfilePhoto selectedUserSport={selectedUserSport} />
        <div className="UxEditProfile_ProfilePhotoCol_Buttons">
          {
            selectedUserSport &&
            <ButtonLink
              className="UxEditProfile_ProfilePhotoCol_Button"
              to={isCoach ? `/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Sports[selectedUserSport.sportId]}${match.params.schoolId ? `/${match.params.schoolId}` :''}/${Routes.profilePhoto}` :
              `/${Routes.profile}/${match.params.uuid || uuid}/${Routes.edit}/${Sports[selectedUserSport.sportId]}/${Routes.profilePhoto}`}
            >
              {
                selectedUserSport.photoUrl
                ? 'Update Profile Photo'
                : 'Add a Profile Photo'
              }
            </ButtonLink>
          }

        </div>
      </div>
    );
  }
}

export default ProfilePhotoCol;
