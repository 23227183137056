import React from 'react';
import LockIcon from '../../../assets/images/Lock_icon.png';
import { ContactUsSchoolEmails } from '../../../store/actions/ui/schoolLogo';
// '../../assets/images/Dashr.png';

const PrivateProfilePage = (props) => {
  const { profile, match } = props;
  const coachSelectedSchoolId =
    profile && (match.params.schoolId || match.params.schoolId === undefined);
  const userSchools =
    profile &&
    profile.schools.find(
      (userSchool) => userSchool.school.id === coachSelectedSchoolId,
    );
  const schoolDetail = userSchools
    ? userSchools
    : profile && profile.schools[0];
  const school = schoolDetail && schoolDetail.school;
  const schoolLogo = school && school.school_logo;
  let subscriptionTitle =
    school &&
    `For the athlete profile details to display, the ${school.name} data platform membership needs to be updated. Athletes
                            and Parents should check their inbox or spam email folder to update their account.`;
  let contactEmail =
    coachSelectedSchoolId && school
      ? `mailto:${school.paid_invite_email}`
      : `mailto:TexasSunDevils@virtualcombine.com`;

  return (
    <div className="UxCommon_ErrorPage">
      <div className="UxCommon_ErrorPage_Fg_Privacy_page">
        <div className="UxCommon_ErrorPage_TxtWrapper_privacy_page">
          <div class="Privacy_page_org_logo d-block mobile-logo-view">
            <img class="privacy_prfile_logo" src={schoolLogo} alt="logo" />
          </div>
          <div className="UxCommon_ErrorPage_Title_privacy_page">
            Unlock the Athlete's full potential
          </div>
          <h4 className="pricacy_update_description">{subscriptionTitle} </h4>
          <br />
          <div className="privccy_update_text">
            Once the Athlete's Membership is updated here is what will display:
          </div>
          <ul className="privacy_page_list">
            <li>All verified combine data.</li>
            <li>Any awards related to the combine data.</li>
            <li>Player data page showing progressions.</li>
            <li>
              Any athlete or parent uploaded pictures, videos and academic
              information.
            </li>
            <li>
              All inputted external profiles and social media profile links.
            </li>
          </ul>
          <br />
          <div>
            Having challenges updating your membership?{' '}
            <a href={contactEmail}> Email us.</a>
          </div>
        </div>
        {/* <div className="Privacy_page_org_logo">
          <img className="privacy_prfile_logo" src={school.school_logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};

export default PrivateProfilePage;
