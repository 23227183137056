import React from 'react';
import PropTypes from 'prop-types';
import NavBarLink from '../../common/NavBarLink';
import { Routes } from '../../../../store/actions/ui/routes';
import { TabLabels } from '../../+store';
import ToggleView from '../ToggleView';
import SelectControl from '../../common/FormControls/SelectControl';
import NavBar from '../../common/NavBar';
import { BankSlugs , BankCodes } from '../../+store/combine';

const resultNumObject = {
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
};
const notLoggedInResults = {
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
};
export const scoreTypeOptions = {
  [BankSlugs.STRENGTH]: {
    raw: 'Raw',
    relative: 'PFP Strength',
    // rated: 'Score',
  },
  [BankSlugs.PERF]: {
    raw: 'Raw',
    // rated: 'Score',
  },
  [BankSlugs.HITTING]: {
    raw: 'Raw',
    // rated: 'Score',
  },
  [BankSlugs.PITCHING]: {
    raw: 'Raw',
    // rated: 'Score',
  },
  [BankSlugs.COACHEVAL]: {
    raw: 'Raw',
    // rated: 'Score',
  },
  [BankSlugs.STATS]: {
    raw: 'Raw',
    // rated: 'Score',
  },
  [BankSlugs.VC_SCORE]: {
    raw: 'Score',
  },
};

const LeaderboardHeader = ({
  match, onResultsChange, numResultsShown,
  onScoreTypeChange, selectedScoreType, location, isLoggedIn,
  showFilters, banks
}) => {
  let scoreOptions = {};
  if (match.params.bank) {
    scoreOptions = scoreTypeOptions[match.params.bank] || {};
  }
  return (
    <div className="LeaderboardHeader">
      <div className="LeaderboardHeader_ControlsWrapper">
        <div className="LeaderboardHeader_Tabs">
          <NavBar className="Leaderboard">

            { banks.includes(BankCodes.PERFORMANCE) &&
              <div className="LeaderboardNavBarLinkWrapper">
                <NavBarLink
                  to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.PERF}`}
                  match={match}
                  location={location}
                  className="Leaderboard Performance Leaderboard-nav"
                >
                  {TabLabels.PERF}
                </NavBarLink>
              </div>
            }
           {banks.includes(BankCodes.HITTING) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.HITTING}`}
                match={match}
                location={location}
                className="Leaderboard Hitting Leaderboard-nav"
              >
                {TabLabels.HITTING}
              </NavBarLink>
            </div>
            }
           {banks.includes(BankCodes.PITCHING) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.PITCHING}`}
                match={match}
                location={location}
                className="Leaderboard Pitching Leaderboard-nav"
              >
                {TabLabels.PITCHING}
              </NavBarLink>
            </div>
          }

          {
            banks.includes(BankCodes.COACHEVAL) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.COACHEVAL}`}
                match={match}
                location={location}
                className="Leaderboard Pitching Leaderboard-nav"
              >
                {TabLabels.COACHEVAL}
              </NavBarLink>
            </div>
          }
          {
            banks.includes(BankCodes.STRENGTH) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.STRENGTH}`}
                match={match}
                location={location}
                className="Leaderboard Strength Leaderboard-nav"
              >
                {TabLabels.STRENGTH}
              </NavBarLink>
            </div>
          }
          {
            banks.includes(BankCodes.STATS) &&
            <div className="LeaderboardNavBarLinkWrapper">
              <NavBarLink
                to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.STATS}`}
                match={match}
                location={location}
                className="Leaderboard Strength Leaderboard-nav"
              >
                {TabLabels.STATS}
              </NavBarLink>
            </div>
          }
            {/*Hides on request*/}
            {/*<div className="LeaderboardNavBarLinkWrapper">*/}
            {/*  <NavBarLink*/}
            {/*    to={`/${Routes.school}/${match.params.schoolId}/${Routes.leaderboard}/${match.params.combineId}/${BankSlugs.VC_SCORE}`}*/}
            {/*    match={match}*/}
            {/*    location={location}*/}
            {/*    className="Leaderboard VC_Score Leaderboard-nav"*/}
            {/*  >*/}
            {/*    {TabLabels.VC}*/}
            {/*  </NavBarLink>*/}
            {/*</div>*/}
          </NavBar>
        </div>
        <div className="LeaderboardHeader_Other">
          <div className="LeaderboardHeader_ToggleView">
            <ToggleView />
          </div>
          {/*<button className="Leaderboard_Filter_Button CoachSelectControl2_Btn" onClick={showFilters}>Filter</button>*/}
          <SelectControl
            name="raw"
            options={scoreOptions}
            defaultValue={selectedScoreType}
            myOnChange={onScoreTypeChange}
          />
          <SelectControl
            name="result"
            label="Results"
            options={isLoggedIn ? resultNumObject : notLoggedInResults}
            defaultValue={isLoggedIn ? numResultsShown : numResultsShown}
            myOnChange={onResultsChange}
          />
        </div>
      </div>
    </div>
  );
};
LeaderboardHeader.propTypes = {
  match: PropTypes.object.isRequired,
  onResultsChange: PropTypes.func.isRequired,
  numResultsShown: PropTypes.string.isRequired,
  onScoreTypeChange: PropTypes.func.isRequired,
  selectedScoreType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showFilters: PropTypes.func,
  banks: PropTypes.array
};


export default LeaderboardHeader;
