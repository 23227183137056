import React from 'react';
import { Control } from 'react-redux-form';
import PropTypes from 'prop-types';
import HelpPopper from '../HelpPopper/HelpPopper';
import Errors from '../Errors/Errors';
import { CheckForNewLine } from '../utils';
import { ButtonIconOnly } from '../../../UxCommon';
import { parserPassword } from '../../../../utils/parsers'
// from '../../../utils/parsers';

const TextControl = (props) => {
  const {
    label,
    model,
    type,
    help,
    helpClass,
    rrfField,
    errors,
    customIcon,
    onCustomIconClick,
    disabled,
    ...otherProps
  } = props;
  if (rrfField.value == null && otherProps.defaultValue !== undefined) {
    otherProps.value = otherProps.defaultValue;
  }
  return (
    <div className="UxCommon_Form_Group">
      <div
        className={[
          'UxCommon_Form_InputGroup',
          rrfField.valid ? '' : 'error',
          type === 'textarea' ? 'UxCommon_Form_TextareaGroup' : '',
          disabled ? 'disabled' : '',
        ].join(' ')}
      >
        <div className="UxCommon_Form_ErrorMarker" />
        <div className="UxCommon_Form_LabelWrapper">
          <label
            className={[
              'UxCommon_Form_Label',
              rrfField.value && rrfField.value.length ? 'show' : '',
            ].join(' ')}
            htmlFor={model}
          >
            {label}
          </label>
        </div>
        {
          type === 'textarea' ?
            <Control.textarea
              className={[
                'UxCommon_Form_Input UxCommon_Form_Textarea',
                rrfField.value && rrfField.value.length ? 'not-empty' : '',
              ].join(' ')}
              type={type || 'text'}
              model={model}
              id={model}
              onKeyDown={CheckForNewLine(model)}
              disabled={disabled}
              {...otherProps}
            />
            :
            <div>
              <Control
                className={[
                  'UxCommon_Form_Input',
                  rrfField.value && rrfField.value.length ? 'not-empty' : '',
                ].join(' ')}
                type={type || 'text'}
                model={model}
                id={model}
                disabled={disabled}
                parser = { type && type === 'password' &&  parserPassword }
                {...otherProps}
              />
              { onCustomIconClick && <ButtonIconOnly
                className="trashClass"
                iconName={customIcon}
                onClick={onCustomIconClick}
              />}
            </div>

        }
      </div>
      {
        help &&
        <HelpPopper focus={rrfField.focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
      }
      <Errors errors={errors || rrfField.errors} valid={rrfField.valid} />
    </div>
  );
};
TextControl.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  type: PropTypes.string,
  help: PropTypes.any,
  rrfField: PropTypes.object.isRequired,
  helpClass: PropTypes.string,
  onCustomIconClick: PropTypes.func,
  errors: PropTypes.object,
  customIcon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextControl;
