import { connect } from 'react-redux';
import queryString from 'query-string';
import Component from './DistrictLeaderboard.component';
import {
  getFilters,
  getQueryFromFilters,
  getSchoolStyles,
  selectLoadingMsg,
  selectSchoolStyle,
  setSortBy,
} from '../+store/coachWorld';
import { BankSlugs, getTestCategories } from '../+store/combine';
import { getSports } from '../+store/nationalLeaderboard';
import {
  getCurrentTemplate,
  getSearch,
  selectDistrictLeaderboard,
  selectCurrentColumns,
  selectSortBy,
  currentSport,
} from '../+store/districtLeaderboard/districtLeaderboard.selectors';
import {
  getDistrictLeaderboardGradYears,
  getDistrictLeaderboardResults,
  getDistrictLeaderboardSports,
  getDistrictLeaderboardTemplate,
} from '../+store/districtLeaderboard';
import {
  getRadius,
  getZipCode,
} from '../+store/nationalLeaderboard/nationalLeaderboard.selectors';

const getNumResultsShown = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { results } = parsedQuery;
  return results || '100';
};

const setNumResultsShown = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.results = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

const getScoreType = (state) => {
  const { currentQuery } = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const { score } = parsedQuery;
  return score || 'raw';
};

const setScoreType = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.score = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  return {
    isLoggedIn: !!state.data.cognito.uuid,
    numResultsShown: getNumResultsShown(state),
    loading: !!state.modules.coachWorld.districtLeaderboard.loading,
    displayShowMore: state.modules.coachWorld.districtLeaderboard.results
      ? state.modules.coachWorld.districtLeaderboard.results.showMore
      : false,
    loadingMsg,
    template: getCurrentTemplate(state),
    columns: selectCurrentColumns(state, ownProps),
    measureableColumns: selectCurrentColumns(state, {
      match: {
        params: {
          bank: BankSlugs.PHYSIQUE,
        },
      },
    }),
    sortByTestId: selectSortBy(state, ownProps),
    zip: getZipCode(state, ownProps),
    videos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    search: getSearch(state, ownProps),
    radius: getRadius(state, ownProps),
    currentSport: currentSport(state, ownProps),
    viewSelection: state.modules.coachWorld.leaderboard.viewSelection,
    selectedScoreType: getScoreType(state),
    results: selectDistrictLeaderboard(state, ownProps),
    testCats: state.modules.coachWorld.combine.standardTestCats,
    sports: state.data.sport.sports,
    schoolSports: state.modules.coachWorld.districtLeaderboard.sports,
    gradYears: state.modules.coachWorld.districtLeaderboard.gradYears,
    selectedFilters: getFilters(state, ownProps),
    parsedFilters: getQueryFromFilters(state, ownProps),
    schoolStyle: selectSchoolStyle(state, ownProps),
    eventAverage: state.modules.coachWorld.districtLeaderboard.eventAverage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDistrictLeaderboardTestRes: (params) => {
    dispatch(getDistrictLeaderboardResults(params));
  },
  getDistrictLeaderboardTemplate: (schoolId, sportId) => {
    dispatch(getDistrictLeaderboardTemplate(schoolId, sportId));
  },
  getTestCategories: () => {
    dispatch(getTestCategories());
  },
  getSports: () => {
    dispatch(getSports());
  },
  getSchoolStyles: (schoolIds) => {
    dispatch(getSchoolStyles(schoolIds));
  },
  getSchoolSports: (schoolId) => {
    dispatch(getDistrictLeaderboardSports(schoolId));
  },
  getSchoolGradYears: (schoolId) => {
    dispatch(getDistrictLeaderboardGradYears(schoolId));
  },
  handleTestSortClicked: (location, history, stdTestObjectId) => {
    setSortBy(location, history, stdTestObjectId);
  },
  onResultsChange: (location, history, value) => {
    setNumResultsShown(location, history, value);
  },
  onScoreTypeChange: (location, history, value) => {
    setScoreType(location, history, value);
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
