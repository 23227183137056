export const USER_GET_PROFILE_ERR = 'user.getProfileError';
export const getProfileError = error => ({
  type: USER_GET_PROFILE_ERR,
  error,
});

export const USER_GET_PROFILE_SUC = 'user.getPlayerProfile.success';
export const getPlayerProfileSuccess = (uuid, data) => ({
  type: USER_GET_PROFILE_SUC,
  uuid,
  data,
});

export const USER_UPDATE_PHYSIQUE = 'user.updatePhysique.success';
export const userUpdatePhysique = (uuid, fields) => ({
  type: USER_UPDATE_PHYSIQUE,
  uuid,
  fields,
});

export const GET_BULK_PROFILES_SUC = 'user.getBulkProfiles.success';
export const getBulkProfilesSuccess = profiles => ({
  type: GET_BULK_PROFILES_SUC,
  profiles,
});
