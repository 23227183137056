import { combineEpics } from 'redux-observable';
import { statsEpics } from './stats';
import { testsEpics } from './tests';
import { teamsEpics } from './teams';

export const uxPlayerDataEpics = combineEpics(
  statsEpics,
  testsEpics,
  teamsEpics,
);

export * from './stats';
export * from './tests';
export * from './teams';
