import { connect } from 'react-redux';
import Component from './CoachCard.component';

const mapStateToProps = state => ({
  viewSelection: state.modules.coachWorld.roster.viewSelection,
});

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
