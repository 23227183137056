import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';

import SportNav from '../../../containers/UxProfile/SportNav';
import VcLogo from '../../UxProfile/Common/VcLogo/VcLogo';
import { Routes, Sports } from '../../../store/actions/ui/routes';
import Header from '../../UxCommon/Header/Header';

class StickyHeader extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    sports: PropTypes.array.isRequired,
    userSports: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
  }

  renderSportNav = (props) => {
    const { sports, userSports } = this.props;
    return <SportNav {...props} showAddSport={userSports.length !== sports.length} />;
  }

  render() {
    const {
      uuid,
      match,
      selectedUserSport,
    } = this.props;
    const { schoolId } = match.params;
    const sportId = selectedUserSport && selectedUserSport.sportId;
    return (
      <div className="StickyHeader">
        <div className="StickyHeader_Left">
          <div className="StickyHeader_Title">
            <Header>
              Edit Your Sports and Account Info
            </Header>
          </div>
        </div>
        <div className="StickyHeader_Center">
          <div className="StickyHeader_SportNav">
            <Route path={`/${Routes.profile}/:uuid/:page/:sport?/:schoolId?`} render={this.renderSportNav} />
          </div>
        </div>
        <div className="StickyHeader_Right">
          <Link className="StickyHeader_VcLogo" to={`/${Routes.profile}/${match.params.uuid || uuid}/${Routes.player}/${Sports[sportId]}${schoolId ? `/${schoolId}` : ''}`}>
            <VcLogo />
          </Link>
        </div>
      </div>
    );
  }
}

export default StickyHeader;
