import { combineReducers } from 'redux';

import { ASYNC_FINISH, ASYNC_START, ASYNC_ERROR } from '../../../../store/actions/ui/async';
import { cognitoErrors } from '../accountPage/accountPage.errors';
import { SIGN_IN } from './signInPage.actions';

const loadingMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.model === SIGN_IN) {
        return 'Validating your credentials ...';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if (action.model === SIGN_IN) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ASYNC_ERROR:
      if (action.model === SIGN_IN) {
        return cognitoErrors(action.error);
      }
      return state;
    case ASYNC_START:
    case ASYNC_FINISH:
      if (action.model === SIGN_IN) {
        return '';
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  loadingMsg,
  errorMsg,
});

export default reducer;
