import { connect } from "react-redux";
import { isInviteValid } from "../../store/actions/ui/welcome";
import { logoutWithRedirect } from "../../store/actions/data/cognito";
import Component from "../../components/Welcome/ValidateInvite/ValidateInviteComponent";
import {
  playerProfileDuplicatesGet,
  playerProfileGet,
  profileShowCreateNewAccount
} from "../../store/actions/ui/uxProfile";

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};

const checkInviteUserLoggedIn = (state, invitedUserId, uuid) => {
  let flag = false;
  if (invitedUserId && uuid) {
    flag = invitedUserId === uuid;
  }
  return flag;
};

const mapStateToProps = (state, ownProps) => {
  const authUuid = state.data.cognito.uuid;
  return {
    loading: !!state.ui.welcome.checkInvitation.loadingMsg,
    loadingMsg: state.ui.welcome.checkInvitation.loadingMsg,
    needsInit: state.ui.welcome.invitedSignUp.formNeedsInit,
    shouldCheckInviteValid: state.ui.welcome.checkInvitation.needsInit,
    isInvitationValid: state.ui.welcome.checkInvitation.isInvitationValid,
    loggedIn: !!state.data.cognito.token,
    authenticatedProfile: getProfile(state, authUuid),
    profile: getProfile(state, ownProps.match.params.uuid),
    duplicateProfiles: state.data.user.duplicateProfiles,
    mergeProfile: state.ui.welcome.mergeAnotherAccount.mergeProfile,
    loadingDuplicateProfiles: state.data.user.loadingDuplicateProfiles,
    showCreateNewAccount: state.data.user.showCreateNewAccount,
    isInviteUserLoggedIn: checkInviteUserLoggedIn(state, ownProps.match.params.uuid, authUuid),
    authUuid,
    state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkIfInviteIsValid: (userTeamId, token) => {
    dispatch(isInviteValid(userTeamId, token));
  },
  forceLogout: (redirectURL) => {
    dispatch(logoutWithRedirect(redirectURL));
  },
  profileShowCreateNewAccount: (value) =>{
    dispatch(profileShowCreateNewAccount(value));
  },
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  getDuplicateProfiles: (uuid) => {
    dispatch(playerProfileDuplicatesGet(uuid));
  },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
