import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetGameStats';
import { getCurrentUuid, getCurrentSportId } from '../Utils';
import { playerStatsGet } from '../../../store/actions/ui/uxPlayerData';

const mapStateToProps = (state, ownProps) => ({
  uuid: getCurrentUuid(state, ownProps.match),
  sportId: getCurrentSportId(state),
});

const mapDispatchToProps = dispatch => ({
  getStats: (uuid, sportId, msg = '') => {
    dispatch(playerStatsGet(uuid, sportId, msg));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
