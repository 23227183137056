import { ajax } from 'rxjs/ajax';
import Urls from '../../../../config/urls';

export const getCollegeContactsApi = token => (
  ajax.getJSON(
    Urls.collegeContact(),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'collegeContact.api.js';

