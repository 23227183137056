import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTokenFragment,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { apiGetLeaderboard } from './leaderboard.api';
import { GET_LEADERBOARD, getLeaderboard } from './leaderboard.actions';
import { CachedCombine } from './leaderboard.models';


const getLeaderboardEpic = action$ => (
  action$.pipe(
    ofType(GET_LEADERBOARD),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetLeaderboard(action.combineId, token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getCombineTemplate',
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        if (result.response && result.response.updating) {
          setTimeout(() => {
            result.action.dispatch(getLeaderboard(result.action.combineId, true));
          }, 20000);
          // return of(asyncFinishAction(result.action.type, 'getCombineTemplate'));
        }
        const currentCombine = CachedCombine.fromApi(result.response.data.current_combine);
        const previousCombine = CachedCombine.fromApi(result.response.data.previous_combine);
        const isCoachPartOfTeam = result.response.data.isCoachPartOfTeam;
        // const lastUpdated = result.response.last_updated;
        // if ((((lastUpdated * 1000) - Number(new Date())) / 1000) > 3600) {
        //   setTimeout(() => {
        //     result.action.dispatch(getLeaderboard(result.action.combineId));
        //   }, 20000);
        // }
        return of(asyncFinishAction(result.action.type, 'getCombineTemplate', {
          currentCombine,
          previousCombine,
          isCoachPartOfTeam
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);


export default combineEpics(getLeaderboardEpic);
