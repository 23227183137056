import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/Icon';

class CombineContainer extends PureComponent {
  static propTypes = {
    combine: PropTypes.object,
    athleteCountsList: PropTypes.array.isRequired,
    selectedCombine: PropTypes.object,
    onSelectCombine: PropTypes.func,
    // getSchoolCombines: PropTypes.func.isRequired,
    athletes: PropTypes.array,
    getCombine: PropTypes.func.isRequired,
    // athletes: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onChange = (e) => {
    const { combine, onSelectCombine } = this.props;
    if (e.target.checked) {
      onSelectCombine(combine);
    } else {
      onSelectCombine(null);
    }
  }

  combinesFetched = false;

  shouldUpdate = () => {
    const {
      combine,
      getCombine,
    } = this.props;
    if (!this.combinesFetched && combine) {
      getCombine(combine.id);
      this.combinesFetched = true;
    }
  }

  render() {
    const {
      combine,
      athleteCountsList,
      selectedCombine,
      athletes,
    } = this.props;
    let openDate = '';
    let toDate = '';
    let athleteCount = null;
    let athleteList = null;
    if (combine) {
      if (combine.openDate) {
        openDate = combine.openDate.format('MM/DD/YY');
      }
      if (combine.isOpen) {
        toDate = combine.endDate ? combine.endDate.format('MM/DD/YY') : '';
      } else {
        toDate = combine.closeDate ? combine.closeDate.format('MM/DD/YY') : '';
      }
      const countObject = athleteCountsList.find(a => a.id === combine.id) || null;
      athleteCount = (countObject !== null) ? countObject.athleteCount : null;
      athleteList = athletes;
      if (athletes) {
        if (athletes.length > 5) {
          athleteList = athleteList.slice(0, 5);
        }
      }
    }

    const isSelected = !!combine && !!selectedCombine && selectedCombine.id === combine.id;
    if (!combine) return null;
    return combine && (
      <label htmlFor={`combineList${combine.id}`} style={{ width: '100%' }}>
        <input
          id={`combineList${combine.id}`}
          type="radio"
          name="combine"
          onChange={this.onChange}
          value={isSelected}
          style={{ display: 'none' }}
        />
        <div className={`Combine_GenerateReport_CombineContainer ${isSelected ? 'chosen' : ''}`}>
          <div className="Combine_GenerateReport_CombineContainerLeft">
            <div className="Combine_GenerateReport_Tick">
              <Icon iconName="tick" className="Combine_GenerateReport_TickIcon" />
            </div>
            <div className="Combine_GenerateReport_CombineDetails">
              <div className="Combine_GenerateReport_CombineName">{combine.name}</div>
              <div className="Combine_GenerateReport_CombineDate">{`${openDate} - ${toDate}`}</div>
            </div>
          </div>
          <div className="Combine_GenerateReport_CombineContainerRight">
            <div className="Combine_GenerateReport_AthletePhotos">
              {
                athleteList && athleteList.map((athlete, index) =>
                  (
                    <div key={index}>
                      {
                        athlete.headshotUrl ?
                          <div
                            className="Combine_GenerateReport_AthletePhoto"
                            style={{
                              backgroundImage: `url(${athlete.headshotUrl})`,
                              backgroundSize: 'cover',
                            }}
                          />
                        :
                          <div className="Combine_GenerateReport_DefaultPhoto">
                            <Icon iconName="photo2" />
                          </div>
                      }
                    </div>
                  ))
              }
            </div>
            <div className="Combine_GenerateReport_AthleteCounts">
              <div className="AthleteCounts_Number">{athleteCount}</div>
              <div className="AthleteCounts_Text">Athletes</div>
            </div>
          </div>
        </div>
      </label>
    );
  }
}

export default CombineContainer;
