import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getTokenFragment,
  manualCancelApiCallFragment,
} from '../../../../store/actions/ui/uxProfile/utils';
import { asyncFinishAction } from '../../../../store/actions/ui/async';
import {
  CombineTemplate,
  NationalLeaderboardRosters,
} from './districtLeaderboard.models';
import { Routes } from '../../../../store/actions/ui/routes';
import {
  GET_DISTRICT_LEADERBOARD_GRAD_YEARS,
  GET_DISTRICT_LEADERBOARD_RESULTS,
  GET_DISTRICT_LEADERBOARD_SPORTS,
  GET_DISTRICT_LEADERBOARD_TEMPLATE,
} from './districtLeaderboard.actions';
import {
  apiGetDistrictLeaderboardGradYears,
  apiGetDistrictLeaderboardResults,
  apiGetDistrictLeaderboardSports,
  apiGetDistrictLeaderboardTemplate,
} from './districtLeaderboard.api';

const getDistrictLeaderboardTemplateEpic = (action$) =>
  action$.pipe(
    ofType(GET_DISTRICT_LEADERBOARD_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      apiGetDistrictLeaderboardTemplate(
        token,
        action.schoolId,
        action.sportId,
      ).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_DISTRICT_LEADERBOARD_TEMPLATE,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const template = CombineTemplate.fromApi(result.response);
        const categories = result.response;
        return of(
          asyncFinishAction(
            result.action.type,
            GET_DISTRICT_LEADERBOARD_TEMPLATE,
            {
              template,
              categories,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getDistrictLeaderboardSportsEpic = (action$) =>
  action$.pipe(
    ofType(GET_DISTRICT_LEADERBOARD_SPORTS),
    getTokenFragment(),
    switchMap(({ action }) =>
      apiGetDistrictLeaderboardSports(action.schoolId).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_DISTRICT_LEADERBOARD_SPORTS,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(
            result.action.type,
            GET_DISTRICT_LEADERBOARD_SPORTS,
            {
              sports: result.response,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getDistrictLeaderboardGradYearsEpic = (action$) =>
  action$.pipe(
    ofType(GET_DISTRICT_LEADERBOARD_GRAD_YEARS),
    getTokenFragment(),
    switchMap(({ action }) =>
      apiGetDistrictLeaderboardGradYears(action.schoolId).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_DISTRICT_LEADERBOARD_GRAD_YEARS,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        return of(
          asyncFinishAction(
            result.action.type,
            GET_DISTRICT_LEADERBOARD_GRAD_YEARS,
            {
              gradYears: result.response,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getDistrictLeaderboardResultsEpics = (action$, state$) =>
  action$.pipe(
    ofType(GET_DISTRICT_LEADERBOARD_RESULTS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      apiGetDistrictLeaderboardResults(token, action).pipe(
        manualCancelApiCallFragment(
          action$,
          action,
          GET_DISTRICT_LEADERBOARD_RESULTS,
          Routes.districtLeaderboard,
        ),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const { loadMore } = result.action;
        const {
          categories,
        } = state$.value.modules.coachWorld.districtLeaderboard;
        let newRosters = NationalLeaderboardRosters.fromApi(
          result.response[0].result.data,
          categories,
        );
        if (loadMore) {
          const {
            rosters,
          } = state$.value.modules.coachWorld.districtLeaderboard.results;
          if (result.action.page > 1) {
            newRosters = [...rosters, ...newRosters];
          }
        }

        const showMore =
          result.response[0].result.to < result.response[0].result.total;

        return of(
          asyncFinishAction(
            result.action.type,
            GET_DISTRICT_LEADERBOARD_RESULTS,
            {
              rosters: newRosters,
              showMore,
              eventAverage: result.response.eventAverage,
            },
          ),
        );
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

export default combineEpics(
  getDistrictLeaderboardResultsEpics,
  getDistrictLeaderboardTemplateEpic,
  getDistrictLeaderboardSportsEpic,
  getDistrictLeaderboardGradYearsEpic,
);
``;
