import {combineReducers} from 'redux';
import {ASYNC_ERROR, ASYNC_FINISH, ASYNC_START} from "../../../../actions/ui/async";
import {
  CREATE_EXTERNAL_PROFILE, DELETE_EXTERNAL_PROFILE, EXTERNAL_PROFILE_FORM_INIT,
  GET_EXTERNAL_PROFILE, HIDE_EXTERNAL_PROFILE,
  UPDATE_EXTERNAL_PROFILE
} from "../../../../actions/ui/uxProfile/externalProfile";

const needsInit = (state= true, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if ( [ CREATE_EXTERNAL_PROFILE, DELETE_EXTERNAL_PROFILE,
        GET_EXTERNAL_PROFILE, HIDE_EXTERNAL_PROFILE,
        UPDATE_EXTERNAL_PROFILE ].includes(action.model)) {
        return true;
      }
    return state;
    case EXTERNAL_PROFILE_FORM_INIT:
      return false;
    default:
      return state;
  }
}


const loadingMsg = (state = '', action) => {
  switch( action.type )
  {
    case ASYNC_START:
      if ( action.model === CREATE_EXTERNAL_PROFILE )
      {
        return 'Creating External Profile'
      }
      if (action.model === UPDATE_EXTERNAL_PROFILE) {
        return 'Saving External Profile';
      }
      if (action.model === GET_EXTERNAL_PROFILE)
      {
        return 'Loading External Profile';
      }
      if ( action.model === HIDE_EXTERNAL_PROFILE)
      {
        return 'Hiding External Profile';
      }
      if ( action.model === DELETE_EXTERNAL_PROFILE)
      {
        return 'Deleting External Profile';
      }
      return state;
    case ASYNC_ERROR:
    case ASYNC_FINISH:
      if ( [ CREATE_EXTERNAL_PROFILE, DELETE_EXTERNAL_PROFILE,
        GET_EXTERNAL_PROFILE, HIDE_EXTERNAL_PROFILE,
        UPDATE_EXTERNAL_PROFILE ].includes(action.model)) {
        return ''
      }
      return state;
    case 'rrf/setErrors':
      if(action.model === 'forms.externalProfile') {
        if (action.errors.general) {
          console.log('new 27', action.errors)
          return '';
        }
      }
        return state;
    default:
      return state;
  }
}
const reducer =  combineReducers( {
  needsInit,
  loadingMsg
})

export default reducer;
