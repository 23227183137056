import { connect } from "react-redux";
import { actions } from "react-redux-form";
import Component, { MergeTypes } from "../../components/Welcome/MergeSignIn/MergeSignIn";
import { isInviteValid, mergeSignInFormInit } from "../../store/actions/ui/welcome";
import { formSubmit } from "../../store/actions/ui/formUtils";
import { routeChangedAlert } from "../../store/actions/ui/routes";
import { playerProfileDuplicatesGet, playerProfileGet } from "../../store/actions/ui/uxProfile";

const getProfile = (state, uuid) => {
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }
  return profile || null;
};


const mapStateToProps = (state, ownProps) => {
  const authUuid = state.data.cognito.uuid;
  return ({
    needsInit: state.ui.welcome.mergeSignIn.formNeedsInit,
    message: state.ui.welcome.mergeSignIn.msg,
    shouldCheckInviteValid: state.ui.welcome.checkInvitation.needsInit,
    isInvitationValid: state.ui.welcome.checkInvitation.isInvitationValid,
    rrfForm: state.data.cognito.token ?
      state.forms.forms.acceptInvitation : state.forms.forms.mergeLogin,
    formGeneralError: state.ui.welcome.mergeSignIn.formError,
    loggedIn: !!state.data.cognito.token,
    profile: getProfile(state, ownProps.match.params.uuid),
    duplicateProfiles: state.data.user.duplicateProfiles,
    authenticatedProfile: getProfile(state, authUuid),
    mergeProfile: state.ui.welcome.mergeAnotherAccount.mergeProfile,
    authUuid,
    state,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  const { loggedIn, authUuid } = newStateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    checkIfInviteIsValid: (userTeamId, token) => {
      dispatch(isInviteValid(userTeamId, token));
    },
    formInit: (profile) => {
      dispatch(actions.setInitial(loggedIn ? "forms.acceptInvitation" : "forms.mergeLogin"));
      if (profile) {
        const { mergeType } = ownProps.match.params;
        let email = "";
        if (mergeType === MergeTypes.mergeBoth.toString()) {
          email = profile.email;
        }
        dispatch(actions.merge("forms.mergeLogin", {
          username: email
        }));
      }
      // dispatch(actions.resets(loggedIn ? 'forms.acceptInvitation' : 'forms.mergeLogin'));
      dispatch(mergeSignInFormInit());
    },
    getProfile: (uuid) => {
      dispatch(playerProfileGet(uuid));
    },
    updatePosition: (positions) => {
      dispatch(actions.change(loggedIn ? "forms.acceptInvitation.positions" : "forms.mergeLogin.positions", positions.join(", ")));
    },
    submit: (uuid, token, userTeamId, isRegistered) => {
      let referrer = "";
      if (ownProps.location && ownProps.location.state) {
        referrer = ownProps.location.state.from;
      }
      dispatch(formSubmit(loggedIn ? "forms.acceptInvitation" : "forms.mergeLogin", {
        from: referrer,
        isMobile: false,
        uuid,
        authUuid,
        token,
        userTeamId,
        dispatch,
        isRegistered
      }));
    },
    getDuplicateProfiles: (uuid) => {
      dispatch(playerProfileDuplicatesGet(uuid));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export default Container;
