import React, { Component } from 'react';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { BehaviorSubject } from 'rxjs';

import Duration from './Duration';
import { ButtonIconOnly } from '..';
import QualityWidget from './QualityWidget/QualityWidget';
import SpeedWidget from './SpeedWidget/SpeedWidget';
import SettingsWidget from './SettingsWidget/SettingsWidget';
import { isIOS, isAndroid } from '../../../containers/UxCommon/Utils';

class VideoPlayer extends Component {
  static propTypes = {
    video: PropTypes.object,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    onLoadCallback: PropTypes.func,
    windowWidth: PropTypes.number,
    isSmallScreen: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    toggleFullScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  state = {
    url: null,
    playing: true,
    volume: 0,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1,
    levels: [],
    loading: true,
    isHls: false,
    isSettingsOpen: false,
    isQualityOpen: false,
    isSpeedOpen: false,
  }

  componentDidMount() {
    this.mounted = true;
    this.setLoadingTrue();
    const isDesktop = !isIOS() && !isAndroid();
    if (isDesktop) {
      this.subject$ = new BehaviorSubject({ fullscreen: false });
      this.subject$.subscribe(o => console.log('o', o));
      if (screenfull.enabled) {
        screenfull.on('change', () => {
          if (this.mounted) {
            this.props.toggleFullScreen(screenfull.isFullscreen);
            this.subject$.next({ fullscreen: screenfull.isFullscreen });
          }
        });
      }
    }
  }

  componentDidUpdate() {
    const { video } = this.props;
    // TODO: if vertical video show mp4
    // https://videocdn.virtualcombine.com/${media.id}-1080p.mp4

    if (video.hlsUrl) {
      const url = video.hlsUrl;
      if (url !== this.state.url) {
        this.setHslUrl(url);
        this.setLoadingTrue();
      }
    } else if (video.embedPath) {
      const url = video.embedPath;
      if (url !== this.state.url) {
        this.setOtherUrl(url);
        this.setLoadingTrue();
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onReady = () => {
    // console.log('onReady');
    const { onLoadCallback } = this.props;
    if (onLoadCallback) {
      onLoadCallback({
        width: this.player.wrapper.offsetWidth,
        height: this.player.wrapper.offsetHeight,
      });
    }
    if (this.state.isHls) {
      this.hls = this.player.getInternalPlayer('hls');
      this.setState({
        levels: isIOS() ? [] : this.hls.levels,
        currentLevel: isIOS() ? null : this.hls.currentLevel,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onStart = () => {
    // console.log('onStart');
  };

  onBuffer = () => {
    // console.log('onBuffer');
  };

  onSeek = () => {
    // console.log('onSeek', e);
  };

  onError = (e) => {
    console.log('onError', e);
  };

  onSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  onToggleFullscreen = () => {
    const { toggleFullScreen, isFullScreen } = this.props;
    if (isIOS()) {
      toggleFullScreen();
    } else if (isFullScreen && screenfull.enabled) {
      screenfull.exit();
      toggleFullScreen(false);
    } else if (screenfull.enabled) {
      const ele = document.getElementById('UxCommon_VideoPlayer_Player');
      screenfull.request(ele);
      toggleFullScreen(true);
    }
  }

  onDuration = (duration) => {
    // console.log('onDuration', duration);
    this.setState({ duration });
  }

  onEnded = () => {
    // console.log('onEnded');
    this.setState({ playing: false });
  };

  onProgress = (state) => {
    // console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  onSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
    this.player.seekTo(e.target.value);
  };

  onPlay = () => {
    // console.log('onPlay');
    this.setState({ playing: true });
  };

  onPause = () => {
    // console.log('onPause');
    this.setState({ playing: false });
  }

  onOpenSettings = () => {
    const { isSettingsOpen, isSpeedOpen, isQualityOpen } = this.state;
    if (isSettingsOpen || isSpeedOpen || isQualityOpen) {
      this.setState({
        isSettingsOpen: false,
        isSpeedOpen: false,
        isQualityOpen: false,
      });
      return;
    }
    this.setState({ isSettingsOpen: !isSettingsOpen });
  }

  onOpenQuality = (value) => {
    this.setState({ isQualityOpen: value, isSettingsOpen: !value });
  }

  onOpenSpeed = (value) => {
    this.setState({ isSpeedOpen: value, isSettingsOpen: !value });
  }

  setPlaybackRate = (rate) => {
    this.setState({ playbackRate: rate, isSpeedOpen: false });
  };

  setLevel = (level) => {
    if (this.hls) {
      this.hls.currentLevel = level;
    }
    this.setState({
      currentLevel: level,
      isQualityOpen: false,
    });
  }

  setLoadingTrue = () => {
    this.setState({
      loading: true,
    });
  }

  setOtherUrl = (url) => {
    this.setState({ url, isHls: false });
  }

  setVolume = (volume) => {
    this.setState(() => ({ volume }));
    if (volume > 0) {
      this.unMute();
    } else this.mute();
  };

  setHslUrl = (url) => {
    this.setState({ url, isHls: true });
  };

  unMute = () => {
    this.setState({ muted: false });
  };

  mute = () => {
    this.setState({ muted: true });
  };

  ref = (player) => {
    this.player = player;
  }

  stop = () => {
    this.setState({ url: null, playing: false });
  };

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
    });
  };

  renderControls = (className = '') => {
    const {
      playing,
      volume,
      played,
      loaded,
      duration,
    } = this.state;
    return (
      <div className={`UxCommon_VideoPlayer_NewControls ${className}`}>
        <div className="UxCommon_VideoPlayer_PlayPauseWrapper">
          <ButtonIconOnly
            className="UxCommon_VideoPlayer_Btn UxCommon_VideoPlayer_PlayPause"
            iconName={playing ? 'pause' : 'play'}
            onClick={this.playPause}
          />
          {
            volume === 0 ? (
              <ButtonIconOnly
                className="UxCommon_VideoPlayer_Btn UxCommon_VideoPlayer_Mute"
                iconName="mute"
                onClick={() => this.setVolume(1)}
              />
            ) : (
              <ButtonIconOnly
                className="UxCommon_VideoPlayer_Btn UxCommon_VideoPlayer_Volume"
                iconName="volume"
                onClick={() => this.setVolume(0)}
              />
            )
          }
        </div>
        <Duration seconds={duration * played} className="UxCommon_VideoPlayer_Duration" />
        <div className="UxCommon_VideoPlayer_ProgressBars">
          <input
            className="UxCommon_VideoPlayer_SeekInput"
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={this.onSeekMouseDown}
            onChange={this.onSeekChange}
            onMouseUp={this.onSeekMouseUp}
          />
          <div
            className="UxCommon_VideoPlayer_PlayedProgress"
            style={{
              width: `${played * 100}%`,
            }}
          />
          <div
            className="UxCommon_VideoPlayer_LoadedProgress"
            style={{
              width: `${loaded * 100}%`,
            }}
          />
          <div className="UxCommon_VideoPlayer_ProgressBackdrop" />
        </div>
        <Duration seconds={duration} className="UxCommon_VideoPlayer_Elapsed" />
        <ButtonIconOnly
          className="UxCommon_VideoPlayer_Btn UxCommon_VideoPlayer_Settings"
          iconName="settings"
          onClick={this.onOpenSettings}
        />
        {
          !isAndroid() && (
            <ButtonIconOnly
              className="UxCommon_VideoPlayer_Btn UxCommon_VideoPlayer_Settings"
              iconName="full-screen"
              onClick={this.onToggleFullscreen}
            />
          )
        }
      </div>
    );
  }

  render() {
    const {
      url,
      playing,
      volume,
      muted,
      playbackRate,
      levels,
      currentLevel,
      loading,
      isHls,
      isSettingsOpen,
      isQualityOpen,
      isSpeedOpen,
    } = this.state;
    const {
      height,
      width,
      isSmallScreen,
      isFullScreen,
      windowWidth,
    } = this.props;
    let config = {};
    if (isHls && !isIOS()) {
      config = {
        file: {
          forceHLS: true,
          hlsOptions: {
            startLevel: 3,
          },
        },
      };
    }
    const isDesktop = !isIOS() && !isAndroid();
    return (
      <div
        className="UxCommon_VideoPlayer"
        style={{ width: isSmallScreen ? windowWidth : width }}
      >
        <div
          className="UxCommon_VideoPlayer_PlayerWrapper"
          style={{ height: isFullScreen ? '100%' : height, width: isFullScreen ? '100%' : width }}
          id="UxCommon_VideoPlayer_Player"
          onClick={this.playPause}
          role="button"
          aria-hidden
        >
          {
            loading && (
              <div className="UxCommon_VideoPlayer_PlayerLoading">
                Loading ...
              </div>
            )
          }
          {
            url && (
              <ReactPlayer
                ref={this.ref}
                className="UxCommon_VideoPlayer_Player"
                width="100%"
                height={isFullScreen ? '100%' : height}
                url={url}
                playing={playing}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}
                onReady={this.onReady}
                onStart={this.onStart}
                onPlay={this.onPlay}
                onPause={this.onPause}
                onBuffer={this.onBuffer}
                onSeek={this.onSeek}
                onEnded={this.onEnded}
                onError={this.onError}
                onProgress={this.onProgress}
                onDuration={this.onDuration}
                progressInterval={100}
                config={config}
                playsinline={playing}
              />
            )
          }
          {
            (isDesktop && isFullScreen) && this.renderControls('fullscreen-web')
          }
        </div>
        <div>
          { isSettingsOpen &&
            <SettingsWidget
              onOpenQuality={this.onOpenQuality}
              onOpenSpeed={this.onOpenSpeed}
            />
          }
          { isQualityOpen &&
            <QualityWidget
              levels={levels}
              currentLevel={currentLevel}
              onSetLevel={this.setLevel}
              onOpenQuality={this.onOpenQuality}
            />
          }
          { isSpeedOpen &&
            <SpeedWidget
              currentSpeed={playbackRate}
              onSetSpeed={this.setPlaybackRate}
              onOpenSpeed={this.onOpenSpeed}
            />
          }
        </div>
        { this.renderControls() }
      </div>
    );
  }
}

export default VideoPlayer;
