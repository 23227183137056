import React from 'react';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../store/actions/ui/routes';

const invitationCompleted = 'Oops! Expired Link';
const invitationCompletedDesc = ` You have clicked on a link that is no longer valid. Please head back to your inbox and click on the most recent email invitation. If you get stuck, `;
let contactEmail = `mailto:support@virtualcombine.com`;

const invitationInvalid = 'Invitation Invalid';
const invitationInvalidDesc = ` This invite link is no longer active.\n
                Please contact your coach to see if there is a new link available.`;

const getContent = () => {
  const content = {};
  content.invitationCompleted = 'Onboarding Completed';
  content.invitationCompletedDesc = `You have already completed the onboarding. Please go to the main Virtual Combine website and login with the email and password associated with your account.`;
  return content;
}

const InvitationInvalidComponent = ({isInvitationValid, profile}) => {
  const content = getContent();

  if (profile && profile.registered) {
    return (
      <div className="container welcome-first">
        <div className="row">
          <div className="col">
            <div className="welcome-msg">
              {content.invitationCompleted}
            </div>
            <div className="welcome-desc">
              {content.invitationCompletedDesc}
              <div className="welcome-btns">
                <Link
                  className="welcome-btn"
                  to={`/${Routes.welcome}/${Routes.login}`}>
                  {' '}
                  Sign In{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container welcome-first">
      <div className="row">
        <div className="col">
          <div className="welcome-msg">
            {!isInvitationValid ? invitationCompleted : invitationInvalid}
          </div>
          <div className="welcome-desc">
            {!isInvitationValid
              ? invitationCompletedDesc
              : invitationInvalidDesc}

            {!isInvitationValid && <a href={contactEmail}>email us.</a>}
            <div className="welcome-btns">
              <Link
                className="welcome-btn"
                to={`/${Routes.welcome}/${Routes.login}`}>
                {' '}
                Sign In{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationInvalidComponent;
