import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import { PD_DELETE_TEST_RESULT, playerTestsGet } from '../actions';

import { testsTestResultsDelete } from '../../../../data/sport';

import { cancelOnRouteChange, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';

export const deleteTestResultEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(PD_DELETE_TEST_RESULT),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      testsTestResultsDelete(action.testResult.testResultId, token).pipe(
        map(response => ({ success: true, response, outer: action })),
        takeUntil(cancelOnRouteChange(action$, Routes.data, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(PD_DELETE_TEST_RESULT, 'deleteTest', error, { bank: action.bank }),
        })),
      )),
    switchMap((results) => {
      if (results.success) {
        return of(
          playerTestsGet(uuid(), results.outer.sportId, 'Loading your test results', results.outer.categoryId),
          asyncFinishAction(PD_DELETE_TEST_RESULT, 'deleteTest', { bank: results.outer.bank }),
        );
      }
      return of(results.action);
    }),
  );
};

export const deleteEpics = combineEpics(deleteTestResultEpic);
