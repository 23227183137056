import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const ButtonIconOnly = ({
  iconName,
  className,
  ...props
}) => (
  <button className={['UxCommon_ButtonIconOnly', className || ''].join(' ')} {...props}>
    <Icon iconName={iconName} />
  </button>
);
ButtonIconOnly.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ButtonIconOnly;
