import {connect} from 'react-redux';
import queryString from 'query-string';
import Component from './Leaderboard.component';
import {selectLoadingMsg, selectSchoolStyle, getFilters} from '../+store/coachWorld';
import {getCombine, BankSlugs, getTestCategories} from '../+store/combine';
import {selectFilteredCombineAthletes, selectSchoolTeamsList} from '../+store/combine/combine.selectors';
import {
  selectCurrentColumns,
  selectSortBy,
  selectLeaderBoard,
  getSelectedEvent, getCurrentCombine, getLeaderboardRosters, getLeaderboardAvg
} from '../+store/leaderboard/leaderboard.selectors';
import {setSortBy} from '../+store/coachWorld/coachWorld.utils';
import {checkIfUpdateNeeded, combinePreCombineUpdateId, combineUpdateId} from '../+store/combine/combine.utils';
import {changeViewSelection, getLeaderboard} from '../+store/leaderboard';
import {selectIsCoach} from '../+store';
import React from "react";

const getNumResultsShown = (state) => {
  const {currentQuery} = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const {results} = parsedQuery;
  return results || '20';
};

const setNumResultsShown = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.results = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

const getScoreType = (state) => {
  const {currentQuery} = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const {score} = parsedQuery;
  return score || 'raw';
};

const setScoreType = (location, history, value) => {
  const parsedQuery = queryString.parse(location.search);
  parsedQuery.score = value;
  history.push({
    search: queryString.stringify(parsedQuery),
  });
};

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const {schoolId, combineId} = ownProps.match.params;

  const combine = state.modules.coachWorld.combine.combine[combineId];
  const leaderboardPrivacy = combine? combine.leaderboardPrivacy :null;
  //const gradYears = state.modules.coachWorld.leaderboard.leaderboard ? state.modules.coachWorld.leaderboard.leaderboard.currentCombine.gradYears : [];
  const getSchoolTeamsLists = state.modules.coachWorld.coachWorld.schoolTeamsLists[schoolId];
  let combineTemplateSportId = 0;
  const templates = state.modules.coachWorld.combine.combineTemplates[schoolId];
  if (templates && combine && combine.combineTemplateId) {
    const combineTemplate = templates.find(tem => tem.id === combine.combineTemplateId)
    if (combineTemplate) {
      combineTemplateSportId = combineTemplate.sportId;
    }

  }

  const rosters = getLeaderboardRosters(state);

  const isCoachPartOfTeam = state.modules.coachWorld.leaderboard.leaderboard
    ? state.modules.coachWorld.leaderboard.leaderboard.isCoachPartOfTeam
    : null;


  const gradYears = [...new Set(rosters
    .filter(ath => !!ath.gradyear)
    .map(ath => ath.gradyear)
  )].sort((a, b) => b - a);

  const results = selectLeaderBoard(state, ownProps);
  const data = results;
  const columns = selectCurrentColumns(state, ownProps);
  const athletes = selectFilteredCombineAthletes(state, ownProps);
  const selectedScoreType = getScoreType(state);
  const isAthletePartOfTeam = athletes.filter(athlete => {
    return athlete.userId === state.data.cognito.uuid
  });
  const isCoach = selectIsCoach(state);
  let testAverage = [];
  const testAvgVisibility = isCoach ? true :
    (
      state.data.user.profiles[state.data.cognito.uuid] && !!state.data.user.profiles[state.data.cognito.uuid].isPaidProfile && !!isAthletePartOfTeam.length
        ? true : !!isAthletePartOfTeam.length
    );

  if (data && columns && !!state.data.cognito.uuid && testAvgVisibility) {
    testAverage = getLeaderboardAvg(columns, data, selectedScoreType);
  }

  return {
    isCoach: isCoach,
    leaderboardPrivacy,
    isCoachPartOfTeam,
    isLoggedIn: !!state.data.cognito.uuid,
    numResultsShown: getNumResultsShown(state),
    combineId,
    videos: state.ui.uxProfile.photosVideos.expandedDialog.videos,
    loading: !!loadingMsg,
    loadingMsg,
    combine,
    combineTemplateSportId,
    combineTemplates: state.modules.coachWorld.combine.combine[combineId],
    athletes: athletes,
    sports: state.data.sport.sports,
    sportId: state.modules.coachWorld.leaderboard.leaderboard &&
             state.modules.coachWorld.leaderboard.leaderboard.currentCombine.sportId,
    columns: columns,
    measureableColumns: selectCurrentColumns(state, {
      match: {
        params: {
          bank: BankSlugs.PHYSIQUE,
          combineId,
        },
      },
    }),
    sortByTestId: selectSortBy(state, ownProps),
    viewSelection: state.modules.coachWorld.leaderboard.viewSelection,
    shouldUpdatePrev: checkIfUpdateNeeded(
      state.modules.coachWorld.combine.shouldUpdate,
      combinePreCombineUpdateId(combineId),
    ),
    shouldUpdateCurr: checkIfUpdateNeeded(
      state.modules.coachWorld.combine.shouldUpdate,
      combineUpdateId(combineId),
    ),
    schoolTeamsList: getSchoolTeamsLists,//selectSchoolTeamsList(state, ownProps),
    selectedScoreType: selectedScoreType,
    schoolStyle: selectSchoolStyle(state, ownProps),
    results: results,
    testCats: state.modules.coachWorld.combine.standardTestCats,
    selectedEvent: getSelectedEvent(state, ownProps),
    selectedFilters: getFilters(state, ownProps),
    gradYears,
    testAverage: testAverage,
  };
};

const mapDispatchToProps = dispatch => ({
  getCombineTestRes: (combineId, silent = false) => {
    // dispatch(getCombinePreCombineTestRes(combineId));
    dispatch(getLeaderboard(combineId, silent));
  },
  getCombine: (combineId) => {
    dispatch(getCombine(combineId, true));
  },
  getTestCategories: () => {
    dispatch(getTestCategories());
  },
  handleTestSortClicked: (location, history, stdTestObjectId) => {
    setSortBy(location, history, stdTestObjectId);
  },
  onResultsChange: (location, history, value) => {
    setNumResultsShown(location, history, value);
  },
  onScoreTypeChange: (location, history, value) => {
    setScoreType(location, history, value);
  },
  changeViewSelection: (newSelection) => {
    dispatch(changeViewSelection(newSelection));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
