import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';

import { cognitoResetPass, cognitoResetPassSuccess } from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateConfirmPassword,
  validateLength,
  validateRequired,
  validatePassword,
} from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const resetPassFormValidation = {
  'forms.resetPassword.code': [
    value => validateRequired()(value),
  ],
  'forms.resetPassword.password': [
    value => validatePassword(6)(value),
  ],
  'forms.resetPassword.confirmPassword': [
    (value, form) => validateConfirmPassword()(form.password, value),
  ],
};

const resetPassSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.resetPassword;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.resetPassword', 'resetPassword'),
    filter(action => !!action.attrs),
    switchMap(action => from(cognitoResetPass(
      action.attrs.username,
      form().code,
      form().password,
    )).pipe(
      map(awsResponse => ({
        success: true,
        awsResponse,
        outer: action,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: formServerError('forms.resetPassword', 'aws', error),
        outer: action,
      })),
    )),
    switchMap(({
      success,
      awsResponse,
      action,
      outer,
    }) => {
      if (success) {
        return of(
          formSubmitSuccess('forms.resetPassword', {
            awsResponse,
            username: outer.attrs.username,
            password: form().password,
            toRedirectUrl: outer.attrs.toRedirectUrl
          }),
          cognitoResetPassSuccess(awsResponse),
        );
      }
      return of(action);
    }),
  );
};

export const resetPassFormEpic = combineEpics(
  resetPassSubmitEpic,
  validationOnSubmitEpicFactory(resetPassFormValidation, 'forms.resetPassword', 'resetPassword'),
  validationFailedEpicFactory('forms.resetPassword', 'resetPassword'),
  ...formValidationEpicsFactory(resetPassFormValidation, 'resetPassword'),
);

export const RESET_PASS_FORM_INIT = 'RESET_PASS_FORM_INIT';
export const resetPassFormInit = () => ({
  type: RESET_PASS_FORM_INIT,
});
