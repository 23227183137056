import {createSelector} from 'reselect';
import {Roles} from '../../../../store/actions/data/user/profile/models';
import {getFilteredSearchedSortedSelectorCreator} from '../roster/roster.selectors';
import {feetAndInchesMasterFormater, UnitFormats} from '../combine/combine.constants';

import { masterFormater} from '../combine';

import { AthleteResult } from './coachWorld.models';

const getCoachWorldLoading = state => state.modules.coachWorld.coachWorld.loadingMsg;
const getRosterLoading = state => state.modules.coachWorld.roster.loadingMsg;
const getCombinesLoading = state => state.modules.coachWorld.combine.loadingMsg;
const getCombinesLoadingMsg = state => state.modules.coachWorld.combine.loadingCombinesMsg;
const getSchoolsLoading = state => state.modules.coachWorld.coachWorld.loadingSchools;
const getCompareCombinesResult = (state, ownProps) => {
  const combineId = ownProps.combine ? ownProps.combine.id
                    : ownProps.match.params.combineId ? ownProps.match.params.combineId
                    :null;
  return (combineId ? state.modules.coachWorld.combine.comparedTestResults[combineId]
    : null
    )
};
const getCombineColumns = (state, ownProps) => {
  const combine = state.modules.coachWorld.combine.combine[ownProps.match.params.combineId];
  return (ownProps.combine ? ownProps.combine.columns : [])
};
const getLoadingComparedCombines = state => (
  state.modules.coachWorld.combine.loadingComparedCombines
);
const getLoadingSchoolTeamsList = state =>
  state.modules.coachWorld.coachWorld.loadingSchoolTeamsListMsg;
const getReportsLoading = state => state.modules.coachWorld.report.loadingMsg;
const getLeaderboardLoading = state => state.modules.coachWorld.leaderboard.loadingMsg;

export const getCombineCompareAwards = (results) => {
  console.log(results, 'results');
  return results.map((result) => {
    let mostImprovedValue = 0;
    // let bestValue = 0.1;
    let bestValue = Number.MIN_SAFE_INTEGER;
    const mostImprovedObject = {};
    let mostImprovedObjectArr = [];
    let bestObject = {};
    let bestObjectArr = [];
    let bestValueAssignedForMinScore = false;
    result.athletesResults.forEach((atResult, index) => {
      const ar = AthleteResult.fromApi(atResult);
      if (ar.oldValue && ar.newValue) {
        if (mostImprovedValue <= ar.difference && ar.difference !== 0) {
          Object.assign(mostImprovedObject, ar);
          mostImprovedValue = ar.difference;
          mostImprovedObject.awardTitle = `Most Improved in ${result.testCategoryName}`;
          if(result.standardUnitOfMeasureCode === 'FEET-INCHES'){
            mostImprovedObject.awardDesc = `Most Improved in ${result.testCategoryName} - ${feetAndInchesMasterFormater(result.standardFormatCode)(ar.newValue)}`;
          }else{
            mostImprovedObject.awardDesc = `Most Improved in ${result.testCategoryName} - ${masterFormater(result.standardFormatCode)(ar.newValue)}`;
          }
          mostImprovedObject.isVcTest = result.isVcTest;
          mostImprovedObject.standardTestObjectId = result.stdTestObjectId;

          mostImprovedObjectArr.push(mostImprovedObject);
          mostImprovedObjectArr = mostImprovedObjectArr
            .filter(ath => mostImprovedValue <= ath.difference);
        }
      }
      if (ar.newValue) {


        if (!bestValueAssignedForMinScore&& ar.calculateForMin) {
          bestValue = Number.MAX_SAFE_INTEGER;
          bestValueAssignedForMinScore = true;
        }
        const isBest = ar.calculateForMin ? (bestValue >= ar.newValue)
          : (bestValue <= ar.newValue);

        if (isBest) {
          bestObject = {};
          Object.assign(bestObject, ar);
          bestObject.awardTitle = `Best in ${result.testCategoryName}`;
          if(result.standardUnitOfMeasureCode === 'FEET-INCHES'){
            bestObject.awardDesc = `Best in ${result.testCategoryName} - ${feetAndInchesMasterFormater(result.standardFormatCode)(ar.newValue)}`;
          }else{
            bestObject.awardDesc = `Best in ${result.testCategoryName} - ${masterFormater(result.standardFormatCode)(ar.newValue)}`;

          }
          bestObject.isVcTest = result.isVcTest;
          bestObject.standardTestObjectId = result.stdTestObjectId;
          // bestObject.awardDesc = `Best in ${result.testCategoryName} - ${UnitFormats[result.standardUnitOfMeasureCode](ar.newValue)}`;
          bestValue = ar.newValue;
          bestObjectArr.push(bestObject);
          if (ar.calculateForMin) {
            bestObjectArr = bestObjectArr.filter(ath => ath.newValue <= bestValue);
          } else {
            bestObjectArr = bestObjectArr.filter(ath => ath.newValue >= bestValue);
          }
        }
      }
    });
    return {
      isVcTest: result.isVcTest,
      standardUnitOfMeasureCode: result.standardUnitOfMeasureCode,
      stdTestObjectId: result.stdTestObjectId,
      testCategoryName: result.testCategoryName,
      bestObject,
      mostImprovedObject,
      bestObjectArr,
      mostImprovedObjectArr,
    };
  });
}


export const getAthleteCombineAwards = (athletes, combineResults) => {
  const comparedAwards = getCombineCompareAwards(combineResults);
  let data = [];
  athletes.map(( athlete ) => {
    let awards = comparedAwards && comparedAwards
    .filter(x => (x.bestObjectArr.length > 0 && x.bestObjectArr
        .find(ath => ath.athleteId === athlete.athlete_uuid))
        || (x.mostImprovedObjectArr.length > 0 && x.mostImprovedObjectArr
        .find(ath => ath.athleteId === athlete.athlete_uuid)));

    awards = awards && awards.map((award) => {
    const bestObject = award.bestObjectArr
        .find(ath => ath.athleteId === athlete.athlete_uuid);
    const mostImprovedObject = award.mostImprovedObjectArr
        .find(ath => ath.athleteId === athlete.athlete_uuid);
    return { ...award, bestObject, mostImprovedObject };
    });

    const filteredAwards = awards && awards.reduce((currentValue, award) => {
      currentValue.push(award.bestObject, award.mostImprovedObject);
      return currentValue;
    }, []).filter(a => a && a.athleteId === athlete.athlete_uuid);

    filteredAwards && filteredAwards.map( (award, i) => {
     data.push({
        name: award.awardTitle,
        description: award.awardDesc,
        award_type: 'combine',
        user_id: athlete.athlete_uuid,
        standardTestObjectId: award.standardTestObjectId,
      });
    });
  });
  return data;
}
export const selectLoadingMsg = createSelector(
  getCoachWorldLoading,
  getRosterLoading,
  getCombinesLoading,
  getSchoolsLoading,
  getLoadingComparedCombines,
  getLoadingSchoolTeamsList,
  getReportsLoading,
  getLeaderboardLoading,
  getCombinesLoadingMsg,
  (
    coachWorld, schoolTeamsList, roster, combine, schools, compared, reports, leaderboard,
    combineLoading,
  ) =>
    coachWorld || schoolTeamsList || roster || combine
    || schools || compared || reports || leaderboard || combineLoading,
);

const getMyTeams = state => state.modules.coachWorld.coachWorld.myTeams;

export const selectIsCoach = createSelector(
  getCoachWorldLoading,
  getMyTeams,
  (loadingMsg, myTeams) => {
    if (loadingMsg) {
      return false;
    }
    return myTeams.reduce((prev, curr) => {
      if (curr.userType === 'coach') {
        return true;
      }
      return prev;
    }, false);
  },
);

export const selectIsCoachOrAthlete = createSelector(
  getCoachWorldLoading,
  getMyTeams,
  (loadingMsg, myTeams) => {
    if (loadingMsg) {
      return false;
    }
    return myTeams.reduce((prev, curr) => {
      if (curr.userType === 'coach' || curr.userType === 'athlete') {
        return {
          status:true,
          userType: curr.userType
        };
      }
      return prev;
    }, false);
  },
)

export const selectCoachProfilePhotoUrl = createSelector(
  getMyTeams,
  (myTeams) => {
    if (myTeams && myTeams.length > 0) {
      return myTeams[0].headshotUrl;
    }
    return '';
  },
);

export const selectCoachSchoolIds = createSelector(
  getCoachWorldLoading,
  getMyTeams,
  (loadingMsg, myTeams) => {
    if (loadingMsg) {
      return null;
    }
    const schoolIds = {};
    myTeams.forEach((userTeam) => {
      if (userTeam.userType === 'coach') {
        userTeam.schoolTeams.forEach((schoolTeam) => {
          schoolIds[schoolTeam.schoolId] = true;
        });
      }
    });
    return Object.keys(schoolIds);
  },
);


export const selectCoachOrAthleteSchoolIds = createSelector(
  getCoachWorldLoading,
  getMyTeams,
  (loadingMsg, myTeams) => {
    if (loadingMsg) {
      return null;
    }
    const schoolIds = {};
    myTeams.forEach((userTeam) => {
      if (userTeam.userType === 'coach' || userTeam.userType === 'athlete') {
        userTeam.schoolTeams.forEach((schoolTeam) => {
          schoolIds[schoolTeam.schoolId] = true;
        });
      }
    });
    return Object.keys(schoolIds);
  },
);

const getCurrentSchoolId = (state, ownProps) => ownProps.match.params.schoolId;

export const selectMySchoolIds = createSelector(
  getMyTeams,
  getCurrentSchoolId,
  (myTeams, currentSchoolId) => {
    const schoolIds = {};
    if (currentSchoolId) {
      schoolIds[currentSchoolId] = true;
    }
    myTeams.forEach((myTeam) => {
      schoolIds[myTeam.schoolTeams[0].schoolId] = true;
    });
    return Object.keys(schoolIds);
  },
);



export const getComparedAwards = createSelector(
  getCombinesLoading,
  getCompareCombinesResult,
  getCombineColumns,
  (loadingMsg, results, columns) => {
    if (loadingMsg || !results || !columns) {
      return null;
    }
    return getCombineCompareAwards(results);
  },
);
const getSchoolStyles = state => state.modules.coachWorld.coachWorld.schoolStyle;
const getSchoolId = (state, ownProps) => (ownProps.match ? ownProps.match.params.schoolId : '');

export const selectSchoolStyle = createSelector(
  getSchoolStyles,
  getSchoolId,
  (schoolStyles, schoolId) => {
    if (schoolStyles && schoolId) {
      return schoolStyles.find(s => (s.id === schoolId));
    } else if (schoolStyles) {
      return schoolStyles[0];
    }
    return null;
  },
);

const getAuthenticatedUuid = state => state.data.cognito.uuid;
const getProfiles = state => state.data.user.profiles;
const getNotSure = state => state.data.cognito.authenticating;

export const selectProfile = createSelector(
  getAuthenticatedUuid,
  getProfiles,
  getNotSure,
  (uuid, profiles, notSure) => {
    if (notSure) return {status: 'notsure'};
    if (!uuid) return {status: 'noauth'};
    const profile = profiles[uuid];
    if (profile) {
      return {
        status: 'profile',
        isCoach: profile.roleId === Roles.coach,
      };
    }
    return {status: 'loading'};
  },
);

const getSchoolTeamsLists = state => state.modules.coachWorld.coachWorld.schoolTeamsLists;
const getSchoolIdFromUrl = (state, ownProps) => ownProps.match.params.schoolId;
export const selectSchoolTeams = createSelector(
  getSchoolTeamsLists,
  getSchoolIdFromUrl,
  (schoolTeamsLists, schoolId) => (
    schoolTeamsLists[schoolId] || []
  ),
);

export const selectRosters = createSelector(
  getMyTeams,
  getSchoolIdFromUrl,
  (teams, schoolId) => {
    if (schoolId) {
      return teams.filter(team => team.schoolId === schoolId);
    }
    return teams;
  },
);
export const selectFilteredRosters = (
  getFilteredSearchedSortedSelectorCreator(selectRosters)
);

const getCombines = state => state.modules.coachWorld.combine.schoolCombines;
export const selectCombines = createSelector(
  getCombines,
  getSchoolIdFromUrl,
  (combines, schoolId) => {
    if (schoolId) {
      return combines.filter(combine => combine.schoolId === schoolId);
    }
    return combines;
  },
);

const getCurrentUUID = state => state.data.cognito.uuid;
const getCurrentCombineTemplates = (state,ownProps) => state.modules.coachWorld.combine.combineTemplates[ownProps.match.params.schoolId];
export const selectCombineTemplatesList = createSelector(
  getCurrentUUID,
  getCurrentCombineTemplates,
  (uuid, combineTemplates) => {
    return uuid && combineTemplates ? combineTemplates : []
  },
);

const getAwardCombines = (state, ownProps) => state.modules.coachWorld.award.combines[ownProps.match.params.schoolId]
export const selectCombineListForAwards = createSelector(
  getCombines,
  getAwardCombines,
  (combines, combineAward) => {
    if (combineAward) {
      return combineAward
    }
    return combines;
  },
);
export const selectFilteredCombines = (
  getFilteredSearchedSortedSelectorCreator(selectCombines)
);
export const selectFilteredCombinesTemplate = getFilteredSearchedSortedSelectorCreator(selectCombineTemplatesList);
export const selectFilteredCombinesForAwards = getFilteredSearchedSortedSelectorCreator(selectCombineListForAwards)




export default 'assessment.selectors.js';
