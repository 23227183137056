import React from 'react';
import PropTypes from 'prop-types';

const Table = ({
  className,
  style,
  setReference,
  children,
}) => (
  <div
    ref={setReference || null}
    className={['LeaderboardTable', className || ''].join(' ')}
    style={style || {}}
  >
    {children}
  </div>
);
Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  setReference: PropTypes.func,
};

export default Table;
