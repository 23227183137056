import { connect } from 'react-redux';
import Component from './CodeSignUp.component';
import { playerProfileGet } from '../../store/actions/ui/uxProfile';
import { checkSignUpCode, getTeamTypes } from './+store/codeSignUp/codeSignUp.actions';
import { selectLoadingMsg } from './+store/codeSignUp/codeSignUp.selectors';
import { getUserSchoolTeams } from '../CoachWorld/+store';
import { logoutWithRedirect } from '../../store/actions/data/cognito';

const mapStateToProps = (state) => {
  const { uuid } = state.data.cognito;
  let profile = null;
  if (uuid) {
    profile = state.data.user.profiles[uuid];
  }

  const loadingMsg = selectLoadingMsg(state);
  return {
    authenticating: state.data.cognito.authenticating,
    uuid,
    profile,
    loading: !!loadingMsg,
    loadingMsg,
    loggedIn: !!state.data.cognito.token,
    isCodeValid: state.modules.codeSignUp.codeSignUp.isCodeValid,
    notValidMsg: state.modules.codeSignUp.codeSignUp.notValidMsg,
    schoolInfo: state.modules.codeSignUp.codeSignUp.schoolInfo,
    loadingProfile: state.ui.app.mobile.loadingProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  getUserProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  getUserTeams: (uuid) => {
    dispatch(getUserSchoolTeams(uuid));
  },
  checkCode: (code, isMobile=false) => {
    dispatch(checkSignUpCode(code, isMobile));
  },
  getTypesOfTeams: () => {
    dispatch(getTeamTypes());
  },
  forceLogout: (redirectURL) => {
    dispatch(logoutWithRedirect(redirectURL));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
