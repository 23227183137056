import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ControlErrors from '../ControlErrors';
import Icon from '../../Icon';
import HelpPopper from '../../../../../components/UxCommon/Form/HelpPopper/HelpPopper';

class TextControl extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    myOnChange: PropTypes.func,
    myOnBlur: PropTypes.func,
    errors: PropTypes.array,
    help: PropTypes.any,
    defaultValue: PropTypes.any,
    iconName: PropTypes.string,
    focus: PropTypes.any,
    helpClass: PropTypes.string,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  state = {
    value: '',
    defaultValue: '',
    hasFocus: false,
  }

  componentDidMount() {
    this.checkDefault();
  }

  componentDidUpdate() {
    this.checkDefault();
  }

  setDefault = (defaultValue) => {
    const { myOnChange } = this.props;
    let value = defaultValue;
    if (myOnChange) {
      value = myOnChange(value);
    }
    this.setState({
      defaultValue,
      value,
    });
  }

  checkDefault = () => {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
      && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  handleChange = (event) => {
    const { myOnChange } = this.props;
    let value = '';
    if (myOnChange) {
      value = myOnChange(event.target.value);
    }
    this.setState({ value: value || event.target.value });
  }

  handleBlur = (event) => {
    const { myOnBlur } = this.props;
    let value = '';
    if (myOnBlur) {
      value = myOnBlur(event.target.value);
    }
    this.setState({
      value: value || event.target.value,
      hasFocus: false,
    });
  }

  handleFocus = (event) => {
    const { myOnFocus } = this.props;
    let value = '';
    if (myOnFocus) {
      value = myOnFocus(event.target.value);
    }
    this.setState({
      value: value || event.target.value,
      hasFocus: true,
    });
  }

  render() {
    const {
      className, modalLabel, 
      iconName, label, focus, helpClass,autofocus,
      errors, help, myOnChange, myOnBlur, defaultValue, disabled, ...inputProps
    } = this.props;
    const { value, hasFocus } = this.state;
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <div
        className={`CoachTextControl_Group ${
          className} ${
          hasFocus ? 'focus' : ''} ${
          (value || this.props.value) ? 'not-empty' : ''} ${
          errors && errors.length ? 'error' : ''}`}
      >
        <div className={`CoachTextControl_InputWrapper ${disabledClass} `}
             style={{ background: disabled ? '#D1D1D1' : '' }}>
          <label className={`CoachTextControl_Label`} style={{ color: disabled ? '#fff' : '' }}
                 htmlFor={this.id}>{label}</label>
          <input
            id={this.id}
            className={`CoachTextControl `}
            type="text"
            value={value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            disabled={disabled}
            autoFocus={autofocus || false}
            {...inputProps}
          />
          {iconName && <Icon iconName={iconName} className="TextWithIcon"/>}
        </div>
        {!!help && help &&
        <HelpPopper focus={focus} helpClass={helpClass}>
          {help}
        </HelpPopper>
        }
        <ControlErrors errors={errors}/>
      </div>
    );
  }
}

export default TextControl;
