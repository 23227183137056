import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Component from '../../components/Welcome/JoinNow/JoinNow';
import { joinNowFormInit } from '../../store/actions/ui/welcome';
import { formSubmit } from '../../store/actions/ui/formUtils';
import { routeChangedAlert } from '../../store/actions/ui/routes';

const mapStateToProps = state => ({
  form: state.forms.joinNow,
  rrfForm: state.forms.forms.joinNow,
  formGeneralError: state.ui.welcome.joinNow.formError,
  needsInit: state.ui.welcome.joinNow.formNeedsInit,
  phoneHelp: state.ui.welcome.joinNow.formPhoneHelp,
  showPhoneHelp: state.ui.welcome.joinNow.formShowPhoneHelp,
  passwordHelp: state.ui.welcome.joinNow.formPasswordHelp,
  // showPasswordHelp: state.ui.welcome.joinNow.formShowPasswordHelp,
  showParentsNameHelp: state.ui.welcome.joinNow.formShowParentsNameHelp,
  showParentsEmailHelp: state.ui.welcome.joinNow.formShowParentsEmailHelp,
  state,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    submit: (isMobile = false) => {
      dispatch(formSubmit('forms.joinNow', { isMobile }));
    },
    formInit: () => {
      dispatch(actions.setInitial('forms.joinNow'));
      dispatch(actions.reset('forms.joinNow'));
      dispatch(joinNowFormInit());
    },
    onBirthdateChange: (date) => {
      dispatch(actions.change('forms.joinNow.birthdate', date));
    },
    resetPwErrors: () => {
      dispatch(actions.setErrors('forms.joinNow.password', {}));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
