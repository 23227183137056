import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from '../../common/Icon';

const AthleteBanner = ({
                         currentAthlete, report, athleteId,
                       }) => {
  if (!report) {
    return <div className="CoachRoster_BannerFullTitle">Athlete Report</div>;
  }
  const athlete = report.roster[athleteId];
  const athleteInfo = athlete ? `${athlete[0].position} | #${athlete[0].jersey_number}` : 'Athlete Report';
  let closeDate = '';
  const closeDate1 = moment(report.combine.closed_at);
  const closeDate2 = moment(report.second_combine.closed_at);
  const compareDate = closeDate1 > closeDate2;
  if (compareDate) {
    closeDate = ` - ${closeDate1.format('MM.DD.YYYY')}`;
  } else {
    closeDate = ` - ${closeDate2.format('MM.DD.YYYY')}`;
  }
  return (
    <div className="CoachRoster_BannerFullSubtitle">
      {
        currentAthlete.headshot_url ?
          <img src={currentAthlete.headshot_url} alt="headshot" className="CombineAthleteBanner_Headshot"/>
          :
          <Icon iconName="photo2" className="CombineAthleteBanner_Headshot"/>
      }
      <div className="CombineAthleteBanner_Info">
        <div className="CombineReportPage_BannerText">{`REPORT${closeDate}`}</div>
        <div
          className="CombineAthleteBanner_Name">{currentAthlete.school_team.given_name + ' ' + currentAthlete.school_team.family_name}</div>
        <div className="CombineAthleteBanner_Infos">
          {`${currentAthlete.gradYear || '-'} - ${athleteInfo}`}
        </div>
        {(report.sport.id ===2 || report.sport.id ===4 ) && 
        <div className="CombineAthleteBanner_Infos">{(currentAthlete.bat || currentAthlete.throw) ? `${currentAthlete.bat || '-'}/${currentAthlete.throw || '-'}` : ''}</div>
        }
      </div>
    </div>
  );
};
AthleteBanner.propTypes = {
  currentAthlete: PropTypes.object,
  report: PropTypes.object,
  athleteId: PropTypes.string,
};

export default AthleteBanner;
