import { asyncStartAction } from '../async';

export const UPDATE_CURRENT_UUID = 'UPDATE_CURRENT_UUID';
export const updateUuidInStore = uuid => ({
  type: UPDATE_CURRENT_UUID,
  uuid,
});

export const CHECK_IF_CAN_EDIT = 'CHECK_IF_CAN_EDIT';
export const checkIfCanEdit = uuid => (dispatch) => {
  dispatch(asyncStartAction(CHECK_IF_CAN_EDIT));
  dispatch({ type: CHECK_IF_CAN_EDIT, uuid });
};

export const HIDE_FILTERS = 'HIDE_FILTERS';
export const hideFilters = hide => (dispatch) => {
  dispatch({ type: HIDE_FILTERS, hide });
};
