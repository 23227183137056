import React, { useEffect, useRef, useState } from 'react';
import HelpPopper from '../../../../components/UxCommon/Form/HelpPopper/HelpPopper';
import Icon from '../../../../components/UxCommon/Icon/Icon';
import LoadingIndicatorCard from '../../Dashboard/Combines/CombineControl/LoadingIndicatorCard/LoadingIndicatorCard.component';
// eslint-disable-next-line import/first
import { fromEvent } from 'rxjs';
import { CustomVideoIcon } from '../../../../components/UxCommon';
import { Link } from 'react-router-dom';
import { expandedCombineDialogPathTo } from '../../../../store/actions/ui/routes';

const CombineLaserEntry = ({
  value,
  onSave,
  formatToDisplay,
  formatToEdit,
  help,
  allowedCharTest,
  validate,
  canNotEditReason,
  isCoach,
  setExpiringCombineMsg,
  rowIndex,
  columnIndex,
  parse,
  onEnterPressed,
  isSaving,
  isAthleteCoachedByUser,
  isCombineOpen,
  setIsSelected,
  withinEstimated,
  getRefreshPopUp,
  unstageNewMedia,
  stagedMedia,
  updateAthleteVideoId,
  athleteId,
  onVideoUploadHandler,
  onVideoDeleteHandler,
  athlete,
  column,
  combine,
  isMobile,
  schoolId,
  bank,
  location,
  combineId,
  setOverFlowClassToTable,
}) => {
  const formRef = useRef(null);
  const [myFormRef, setMyFormRef] = useState(null);
  const prevValue = useRef(value);
  const [isEditing, setIsEditing] = useState(false);
  const [editResult, setEditResult] = useState('');
  const [isLaser, setIsLaser] = useState(!!column.allLaser);
  const [hasFocus, setHasFocus] = useState(false);
  const [error, setError] = useState('');
  const [willSave, setWillSave] = useState(false);
  const entryInputRef = useRef();

  useEffect(() => {
    setIsLaser(!!column.allLaser);
  }, [column.allLaser]);

  useEffect(() => {
    if (
      value &&
      prevValue.current &&
      prevValue.current.isLaser !== value.isLaser
    ) {
      prevValue.current = value;
      setIsLaser(!!value.isLaser);
    } else if (value && !prevValue.current) {
      prevValue.current = value;
      setIsLaser(!!value.isLaser);
    }
  }, [value]);

  useEffect(() => {
    const eventType = isTouchDevice() ? 'touchstart' : 'mousedown';
    document.addEventListener(eventType, handleClickOutside);
    let eventName = 'click';
    if (isTouchDevice()) {
      eventName = 'touchstart';
    }
    const windowClickSubscription = fromEvent(window, eventName).subscribe(
      (e) => {
        if (
          e.target !== formRef.current &&
          formRef.current !== null &&
          willSave
        ) {
          const videoId = (!!value && value.videoId) || '';
          setIsEditing(false);
          setHasFocus(false);
          setWillSave(false);
          setOverFlowClassToTable(false);
          if (!validate(editResult)) {
            onSave({ result: editResult, isLaser, videoId }, {result: (value && value.result) || value, videoId: (value && value.videoId) || null});
          }
        }
      },
    );

    return () => {
      windowClickSubscription.unsubscribe();
      document.removeEventListener(eventType, handleClickOutside);
    };
  }, [isLaser, myFormRef, editResult]);

  const handleClickOutside = (event) => {
    if (formRef && formRef.current && !formRef.current.contains(event.target)) {
      setIsSelected(false);
    }
  };

  const onEditKeyDown = (e) => {
    if (e.keyCode === 27) {
      setIsEditing(false);
      setIsSelected(false);
      setOverFlowClassToTable(false);
    }
    if (
      e.metaKey ||
      [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Shift',
        'Control',
        'Alt',
        'Tab',
        'Enter',
        'Unidentified',
      ].includes(e.key)
    ) {
      return;
    }
    if (allowedCharTest) {
      if (!allowedCharTest(e.key)) {
        e.preventDefault();
      }
    }
  };

  const onResultChange = (e) => {
    const val = e.target.value.replace(',', '.');
    const error = validate(val);
    setEditResult(val);
    setError(error);
  };

  const onRepsChange = (e) => {
    e.target.focus();
    setIsLaser((value) => {
      return e.target.checked;
    });
  };

  const onInputFocus = () => {
    setHasFocus(true);
  };

  const onClickFormButtonIcon = (isDelete = null) => {
    stagedMedia.length > 0 && unstageNewMedia(stagedMedia[0]);
    updateAthleteVideoId(athleteId);
    return isDelete
      ? onVideoDeleteHandler(athlete, column, combine, value, athleteId)
      : onVideoUploadHandler(athlete, column, combine, value, athleteId);
  };

  const onEditSubmit = (e) => {
    setIsEditing(false);
    setIsSelected(false);
    setOverFlowClassToTable(false);
    onEnterPressed(rowIndex, columnIndex, 'laser');
    e.preventDefault();
  };

  const onButtonFocus = () => {
    if (!isCombineOpen || !isAthleteCoachedByUser) {
      return false;
    }
    if (!canNotEditReason) {
      let editResult = null;
      if (value) {
        editResult = value.result;
        setIsLaser(value.isLaser);
      }
      setEditResult(formatToEdit(editResult));
      setIsEditing(true);
      setWillSave(true);
      setIsSelected(true);
      setOverFlowClassToTable(true);
      if (isTouchDevice()) {
        window.dispatchEvent(new Event('touchstart'));
      } else {
        window.dispatchEvent(new Event('click'));
      }
    } else if (isCoach) {
      setExpiringCombineMsg(canNotEditReason, 'error', 3);
    }
  };

  useEffect(() => {
    if (entryInputRef.current && isEditing) {
      entryInputRef.current.focus();
      entryInputRef.current.select();
    }
  }, [isEditing]);

  const isTouchDevice = () => 'ontouchstart' in window;

  const formClick = (e) => {
    e.stopPropagation();
  };

  const renderVideoData = (realValue) => {
    const  videoId  = value && value.videoId ? value.videoId : null ;
    return (
      <div>
        {!videoId ? (
          isCombineOpen && (
            <CustomVideoIcon
              multiLine={true}
              iconName={'videocam'}
              iconStyle={{ fontSize: 36 }}
              onClick={() => ( onClickFormButtonIcon() )}
              tooltip={isMobile ? '' : 'Upload Video'}
              className={isMobile ? '' : 'showToolTip'}
              place={'right'}
            />
          )
        ) : (
          <div style={{ display: 'flex' }}>
            <Link
              to={expandedCombineDialogPathTo(
                schoolId,
                combineId,
                bank,
                value.videoId,
                location.search,
              )}
              className="Leaderboard_Laser">
              <CustomVideoIcon
                multiLine={true}
                iconName="play-circle2"
                iconStyle={{ fontSize: 25, color: 'red' }}
                onClick={() => updateAthleteVideoId(athleteId)}
                place={'right'}
              />
            </Link>
            {isCombineOpen && (
              <CustomVideoIcon
                iconName="delete-video"
                iconStyle={{ fontSize: 25 }}
                onClick={() => onClickFormButtonIcon('delete')}
                tooltip={isMobile ? '' : 'Delete Video'}
                className={isMobile ? '' : 'showToolTip'}
                place={'right'}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const realValue = (value && value.result) || null;
  if (Number(withinEstimated) === 5) {
    getRefreshPopUp();
  }

  if (isSaving) {
    setTimeout(() => {
      const currentTime = new Date().getTime();
      const isOnline = navigator.onLine;
      if (!isOnline) {
        getRefreshPopUp();
      }
      if (
        isSaving &&
        Number(withinEstimated) !== 1 &&
        currentTime >= Number(withinEstimated) + 5000
      ) {
        getRefreshPopUp();
      }
    }, 1000);
    return (
      <form className="CombineEntry_Form" onSubmit={onEditSubmit}>
        <LoadingIndicatorCard loading loadingMsg="Please Wait..." />
      </form>
    );
  }

  const setFormRef = (el) => {
    formRef.current = el;
    setMyFormRef(true);
  };
  if (isEditing) {
    return (
      <form
        ref={setFormRef}
        className="CombineEntry_Form"
        onSubmit={onEditSubmit}
        onClick={formClick}
        onTouchStart={formClick}>
        {error && (
          <HelpPopper error focus={hasFocus} helpClass="bottom">
            {error}
          </HelpPopper>
        )}
        {!error && help && (
          <HelpPopper focus={hasFocus} helpClass="bottom">
            {help(parse(editResult))}
          </HelpPopper>
        )}
        <input
          id={`${rowIndex}-${columnIndex}-result`}
          className="CombineEntry_Input laser"
          type="number"
          step="0.01"
          min="0"
          lang="en"
          value={editResult || ''}
          ref={entryInputRef}
          onChange={onResultChange}
          onKeyDown={onEditKeyDown}
          onFocus={onInputFocus}
        />
        <div className="CombineEntry_LaserCheckbox">
          <input
            id={`${rowIndex}-${columnIndex}-cb`}
            className="CombineEntry_LaserCheckboxInput laser"
            checked={isLaser}
            onChange={onRepsChange}
            type="checkbox"
          />
          <label
            className="CombineEntry_Label"
            htmlFor={`${rowIndex}-${columnIndex}-cb`}>
            Laser?
          </label>
        </div>
        <button style={{ display: 'none' }} />
      </form>
    );
  } else {
    return (
      <React.Fragment>
        <button
          className="CombineEntry_Btn"
          onClick={onButtonFocus}
          onFocus={onButtonFocus}>
          {realValue !== null 
            ? formatToDisplay(realValue)
            : '-'}
          {!!(value && isLaser) && (
            <Icon iconName="tick" className="CombineEntry_Laser" />
          )}
        </button>
        {renderVideoData(realValue)}
      </React.Fragment>
    );
  }
};

export default CombineLaserEntry;
