import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner} from '../../../UxCommon';
import NoneYet from '../NoneYet/NoneYet';
import ProfileStatsTable from './ProfileStatsTable/ProfileStatsTable';
import LetsAddSome from '../LetsAddSome/LetsAddSome';
import { Routes } from '../../../../store/actions/ui/routes';

class GameStats extends PureComponent {
  static propTypes = {
    userStats: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    stdTables: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    currentSport: PropTypes.object.isRequired,
  }

  componentDidMount() {
  }

  renderEmptyRow = () => {
    const { isMyProfile, match } = this.props;
    if (isMyProfile) {
      return (
        <LetsAddSome
          match={match}
          msg="Let's enter your stats!"
          page={Routes.data}
          to={Routes.hashStats}
          btnMsg="Enter Stats"
        />
      );
    }
    return (
      <NoneYet msg="No stats have been entered yet" />
    );
  }

  render() {
    const {
      userStats,
      loading,
      loadingMsg,
      stdTables,
      currentSport,
    } = this.props;
    return (
      <div className="PlayerProfile_Stats">
        <div style={{ marginBottom: '20px' }} />
        <div className="PlayerProfile_Stats_Container" style={{ minHeight: (loading ? '300px' : '0px') }}>
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
          {
            userStats &&
            !!userStats.profile.length &&
            userStats.profile
            .map(({ position, seasons }) => (
              <ProfileStatsTable
                key={`${position.id}`}
                position={position}
                seasons={seasons}
                currentSport={currentSport}
                stdCats={stdTables[position.id].categories}
              />
            ))
          }
          {
            userStats && !userStats.profile.length && (
              this.renderEmptyRow()
            )
          }
        </div>
      </div>
    );
  }
}

export default GameStats;
