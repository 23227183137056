import { connect } from 'react-redux';
import Component from './TeamBase.component';
import { selectLoadingMsg, getCombineNames, selectSchoolStyle } from '../../+store/coachWorld';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const { teamReports } = state.modules.coachWorld.report;
  const { teamId, reportId, bank } = ownProps.match.params;
  const combineNames = getCombineNames(state, reportId);
  const teamReport = teamReports ? teamReports.find(t => t.team_uuid === teamId) : null;
  const report = state.modules.coachWorld.report.report[reportId];
  return {
    report,
    teamReport,
    reportId,
    teamId,
    bank,
    loading: !!loadingMsg,
    loadingMsg,
    combineNames,
    schoolStyle: selectSchoolStyle(state, ownProps) || {},
  };
};


const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
