import { connect } from 'react-redux';
import Component from './CollegeContact.component';
import { getCollegeContacts } from '../+store/collegeContact';

const isCollegeContactsLoaded = state =>
  state.modules.coachWorld.collegeContact.contacts.length > 0;

const mapStateToProps = state => ({
  collegeContacts: state.modules.coachWorld.collegeContact.contacts,
  isContactLoaded: isCollegeContactsLoaded(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCollegeContacts: () => {
    dispatch(getCollegeContacts());
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
