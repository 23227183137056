import { combineEpics, ofType } from 'redux-observable';
import { SCHOOL_AWARD_LOGO } from './actions';
import { switchMap, map } from 'rxjs/operators';
import { getSchoolAwardLogo } from './apiCalls';
import { checkForkErrors, manualCancelApiCallFragment } from '../../ui/uxProfile/utils';
import { asyncFinishAction } from '../../ui/async';
import { forkJoin, of } from 'rxjs';

const schoolAwardLogoEpic = action$ => (
  action$.pipe(
    ofType(SCHOOL_AWARD_LOGO),
    switchMap(action  =>  (
          forkJoin((action.schoolIds).map(schoolId => (
              getSchoolAwardLogo(schoolId)
                .pipe(manualCancelApiCallFragment(action$, action, 'schoolAwardLogo'))
              )),
          ))
          .pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                return {
                  ...forkResults,
                  action,
                };
              }
              return forkResults;
            }),
          )
    ),
    switchMap((result) => {
      if(result.success) {
        const schoolAwardsLogo = {};
        result.results.map((res, index) => {
          schoolAwardsLogo[result.action.schoolIds[index]]  = res.response
        })
        return of(
          asyncFinishAction(result.action.type, 'schoolAwardLogo', {
            awardLogo: schoolAwardsLogo
          }),
        );
      }
      if (result.actions) return of (...result.actions);
        return of(result.action);
    }),
  )
);


const schoolAwardLogoEpics = combineEpics(schoolAwardLogoEpic);
export  default schoolAwardLogoEpics;
