import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { actions } from 'react-redux-form';

import {
  profileUpdateSuccess,
  userSportCreate,
  userSportCreateSuccess,
  userSportDelete,
  userSportDeleteSuccess,
  userSportUpdate,
  userSportUpdateSuccess,
} from '../../data/user';
import { mapUserApiToUi } from '../../data/user/profile/models';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import { validateJersey, validateBatsRequired, validateMaxLength, validateRequired } from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../async';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';
import { updateSportIdInStore } from '../routes';
import { getTokenFragment } from '../uxProfile/utils';
import { getPlayerProfile } from '../../data/user/profile';

const formValidation = {
  'forms.userSport.bio': [
    // value => validateRequired()(value),
  ],
  'forms.userSport.sportId': [
    value => validateRequired('You have to choose a sport')(value),
  ],
  'forms.userSport.commits': [
    value => validateMaxLength(30)(value),
  ],
  'forms.userSport.offers': [
    value => validateMaxLength(451)(value),
  ],
  'forms.userSport.jerseyNumbers': [
    value => validateMaxLength(10)(value),
    value => validateJersey()(value),
  ],
  'forms.userSport.bats': [
    (value,{sportId} )=> validateBatsRequired(sportId)(value)
  ],
  'forms.userSport.throws': [
    (value,{sportId} )=> validateBatsRequired(sportId)(value)
  ]
};

export const SAVE_USER_SPORT = 'editProfile.sports.saveSport';
export const saveUserSport = (userSport, profile) => (dispatch) => {
  dispatch(asyncStartAction(SAVE_USER_SPORT));
  dispatch({
    type: SAVE_USER_SPORT,
    userSport,
    profile,
  });
};

const saveUserSportEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const currSchoolId = () => state$.value.ui.app.routes.currentSchoolId;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(SAVE_USER_SPORT),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (action.userSport.id) {
        // Update
        return from(userSportUpdate(
          action.profile,
          action.userSport,
          token,
          isCoach(),
        )).pipe(
          map(response => ({
            success: true,
            response,
            token,
            action,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(SAVE_USER_SPORT, 'userSportUpdate', error, { action }),
          })),
        );
      }
      // Create
      return from(userSportCreate(
        action.profile,
        action.userSport,
        token,
        isCoach(),
      )).pipe(
        map(response => ({
          success: true,
          response,
          token,
          action,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(SAVE_USER_SPORT, 'userSportCreate', error, { action }),
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const schoolResponse = results.action.profile.schools;
        return from(getPlayerProfile(uuid(), results.token))
          .pipe(
            map(response => ({
              success: true,
              action: results.action,
              profile: mapUserApiToUi(response, schoolResponse),
            })),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(SAVE_USER_SPORT, 'userProfileGet', error, { action: results.action }),
            })),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        // Update
        if (results.action.userSport.id) {
          return of(
            asyncFinishAction(SAVE_USER_SPORT, 'update', {
              uuid: results.profile.uuid,
            }),
            userSportUpdateSuccess(results.profile),
          );
        }
        // Create
        const successActions = [
          asyncFinishAction(SAVE_USER_SPORT, 'create', {
            uuid: results.profile.uuid,
            state: state$,
            schoolId: currSchoolId(),
          }),
          userSportCreateSuccess(results.profile, results.action.userSport),
        ];
        if (results.profile.sports.length === 1) {
          successActions.push(updateSportIdInStore(results.profile.sports[0].sportId));
        }
        return of(...successActions);
      }
      if (results.actions) return of(...results.actions);
      return of(results.errorAction);
    }),
  );
};

const userSportSubmitAsyncStartEpic = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.userSport', 'userSport'),
  map(() => asyncStartAction('forms.userSport', 'Saving your sport')),
);

const userSportSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.userSport;
  const currSchoolId = () => state$.value.ui.app.routes.currentSchoolId;
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    isPendingIsValid(state$, 'forms.userSport', 'userSport'),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      if (form().id) {
        // Update
        return from(userSportUpdate(
          action.attrs.profile,
          form(),
          token,
          isCoach(),
        )).pipe(
          map(response => ({
            success: true,
            response,
            token,
            outer: action,
          })),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            errorAction: formServerError('forms.userSport', 'userSportUpdate', error),
          })),
        );
      }
      // Create
      return from(userSportCreate(
        action.attrs.profile,
        form(),
        token,
        isCoach(),
      )).pipe(
        map(response => ({
          success: true,
          response,
          token,
          outer: action,
        })),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          errorAction: formServerError('forms.userSport', 'userSportCreate', error),
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const schoolResponse = results.outer.attrs.profile.schools;
        return from(getPlayerProfile(uuid(), results.token))
          .pipe(
            map(response => ({
              success: true,
              outer: results.outer,
              profile: mapUserApiToUi(response,schoolResponse),
            })),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              errorAction: formServerError('forms.userSport', 'userProfileGet', error),
            })),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        // Update
        if (form().id) {
          return of(
            formSubmitSuccess('forms.userSport', {
              profile: results.profile,
            }),
            asyncFinishAction('forms.userSport', 'update', {
              uuid: results.profile.uuid,
            }),
            userSportUpdateSuccess(results.profile),
          );
        }
        // Create
        const successActions = [
          formSubmitSuccess('forms.userSport', {
            profile: results.profile,
          }),
          asyncFinishAction('forms.userSport', 'create', {
            uuid: results.profile.uuid,
            profile: results.profile,
            schoolId: currSchoolId(),
          }),
          profileUpdateSuccess(results.profile),
          //userSportCreateSuccess(results.profile),
        ];
        if (results.profile.sports.length === 1) {
          successActions.push(updateSportIdInStore(results.profile.sports[0].sportId));
        }
        return of(...successActions);
      }
      if (results.actions) return of(...results.actions);
      return of(results.errorAction);
    }),
  );
};

export const EP_SPORT_SELECT_SPORT = 'editProfile.sports.selectSport';
export const onEditUserSport = sportId => ({
  type: EP_SPORT_SELECT_SPORT,
  sportId,
});

export const EP_SPORT_FORM_INIT = 'editProfile.sports.sportFormInit';
export const sportFormInitAction = userSport => ({ type: EP_SPORT_FORM_INIT, userSport });

export const editSportInit = dispatch => () => {
  dispatch(sportFormInitAction());
};

export const editSportFormInit = dispatch => (userSport, sportId = null) => {
  dispatch(actions.reset('forms.userSport'));
  if (userSport) {
    dispatch(actions.change('forms.userSport', userSport));
  } else {
    dispatch(actions.setInitial('forms.userSport'));
    if (sportId) {
      dispatch(actions.change('forms.userSport.sportId', sportId));
    }
  }
  dispatch(sportFormInitAction(userSport));
};

export const EP_SPORT_SHOW_DELETE_DIALOG = 'editProfile.sports.showDeleteDialog';
export const editSportShowDeleteDialog = dispatch => () => {
  dispatch({ type: EP_SPORT_SHOW_DELETE_DIALOG });
};
export const EP_SPORT_HIDE_DELETE_DIALOG = 'editProfile.sports.hideDeleteDialog';
export const editSportHideDeleteDialog = dispatch => () => {
  dispatch({ type: EP_SPORT_HIDE_DELETE_DIALOG });
};

export const EP_SPORT_DELETE = 'editProfile.sports.delete';
export const editSportDelete = dispatch => (userSport, msg) => {
  dispatch(asyncStartAction('forms.userSport', msg));
  dispatch({ type: EP_SPORT_DELETE, userSport });
};

const userSportDeleteEpic = (action$, state$) => {
  const uuid = () => state$.value.ui.app.routes.currentUuid;
  const currSchoolId = () => state$.value.ui.app.routes.currentSchoolId;
  return action$.pipe(
    ofType(EP_SPORT_DELETE),
    getTokenFragment(),
    switchMap(({ action, token }) => from(userSportDelete(
      uuid(),
      action.userSport.sportId,
      token,
    )).pipe(
      map(response => ({
        success: true,
        response,
        token,
        outer: action,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        retAction: asyncErrorAction('forms.userSport', 'delete', error),
      })),
    )),
    switchMap((results) => {
      if (results.success) {
        return from(getPlayerProfile(uuid(), results.token))
          .pipe(
            map(response => ({
              success: true,
              outer: results.outer,
              profile: mapUserApiToUi(response),
            })),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              retAction: asyncErrorAction('forms.userSport', 'userProfileGet', error),
            })),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        return of(
          userSportDeleteSuccess(results.profile),
          asyncFinishAction('forms.userSport', 'delete', {
            uuid: uuid(),
            profile: results.profile,
            schoolId: currSchoolId(),
          }),
        );
      }
      return of(results.retAction);
    }),
  );
};

export const EP_SPORT_TOGGLE_ACTIVE_SWITCH = 'editProfile.sports.toggleActiveSwitch';
export const toggleActiveSwitch = () => ({ type: EP_SPORT_TOGGLE_ACTIVE_SWITCH });

export const editSportsFormEpics = combineEpics(
  userSportSubmitEpic,
  userSportSubmitAsyncStartEpic,
  saveUserSportEpic,
  userSportDeleteEpic,
  validationOnSubmitEpicFactory(formValidation, 'forms.userSport', 'userSport'),
  validationFailedEpicFactory('forms.userSport', 'userSport'),
  ...formValidationEpicsFactory(formValidation, 'userSport'),
);
