import { combineReducers } from "redux";
import { ROUTE_CHANGED } from "../../../actions/ui/routes";

import { FORM_SERVER_ERROR, FORM_SUBMIT_INTENT, FORM_SUBMIT_SUCCESS } from "../../../actions/ui/formUtils";
import { cognitoUsernameError } from "../utils";
import { USER_GET_PROFILE_SUC } from "../../../actions/data/user";
import { MERGE_ANOTHER_ACCOUNT_FORM_INIT } from "../../../actions/ui/welcome";
import { ASYNC_FINISH } from "../../../actions/ui/async";
import { GUEST_PROFILE_BY_EMAIL_GET } from "../../../../modules/CoachWorld/+store";
import { PLAYER_PROFILE_DUPLICATES } from "../../../actions/ui/uxProfile";

const formNeedsInit = (state = true, action) => {
  switch (action.type) {
    case ROUTE_CHANGED:
    case USER_GET_PROFILE_SUC:
      return true;
    case MERGE_ANOTHER_ACCOUNT_FORM_INIT:
      return false;
    default:
      return state;
  }
};

const profileLoaded = (state = false,action)=>{
  switch (action.type){
    case ASYNC_FINISH :
      return action.model === GUEST_PROFILE_BY_EMAIL_GET;
    default : return false;
  }
}

const mergeProfile = (state = null,action)=>{
  switch (action.type) {
    case ASYNC_FINISH:
      if (action.model === GUEST_PROFILE_BY_EMAIL_GET) {
        return {...action.data};
      }
      return state;
    default:
      return state;
  }
}

const formError = (state = '', action) => {
  switch (action.type) {
    case FORM_SUBMIT_INTENT:
    case FORM_SUBMIT_SUCCESS:
    case MERGE_ANOTHER_ACCOUNT_FORM_INIT:
      return '';
    case FORM_SERVER_ERROR:
      if (action.model === 'forms.mergeAnotherAccount') {
        return cognitoUsernameError(action.error, action);
      }
      return state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  formNeedsInit,
  formError,
  profileLoaded,
  mergeProfile,
});

export default reducer;
