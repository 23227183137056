import { combineEpics, ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { asyncFinishAction } from '../../../../store/actions/ui/async';
import { getTokenFragment, manualCancelApiCallFragment } from '../../../../store/actions/ui/uxProfile/utils';
import { GET_COLLEGE_CONTACTS } from './collegeContact.actions';
import { getCollegeContactsApi } from './collegeContact.api';
import { CollegeContactResults } from './collegeContact.models';

const getCollegeContactsEpics = action$ => (
  action$.pipe(
    ofType(GET_COLLEGE_CONTACTS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      getCollegeContactsApi(token).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'getCollegeContacts',
        {},
        false,
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, 'getCollegeContacts', CollegeContactResults.fromApi(result.response)));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )
);

export default combineEpics(getCollegeContactsEpics);

