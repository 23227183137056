import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import LaserImg from '../../../../assets/images/Laser_v1.png';

class PersonalRecordsTable extends PureComponent {
  static propTypes = {
    numTopRows: PropTypes.number.isRequired,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    records1: PropTypes.array.isRequired,
    records2: PropTypes.array.isRequired,
  };

  renderForSmallSize = (props) => {
    const {
      records1, records2,
    } = props;
    return (
      <div className="PR_Data_Flex_Container">
        {[...records1, ...records2].map((sr, index) => (
          <div key={index} className="PR_Data_Flex_Row">
            <div className="UxPlayerData_PRs_cellName">{sr.stdTestCategoryName ? sr.stdTestCategoryName.toUpperCase() : ''}</div>
            <div className="UxPlayerData_PRs_cellScore">{sr.displayValueWithLabel}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      title1, title2, records1, records2, numTopRows,
    } = this.props;
    const numBlanks = (numTopRows - records1.length);
    const blankRows = [];
    for (let i = 0; i < numBlanks; i += 1) {
      blankRows.push(i);
    }
    // return this.renderForSmallSize(this.props, blankRows);
    return (
      <div style={{ width: '100%' }}>
        {this.renderForSmallSize(this.props, blankRows)}
        <table className="UxPlayerData_PRs_Table">
          <tbody>
            <tr>
              <th colSpan="2" className="UxPlayerData_PRs_SubTitle">{title1}</th>
            </tr>
            {records1.map((sr, index) => (
              <tr key={index}>
                <td className="UxPlayerData_PRs_cellName">{sr.stdTestCategoryName.toUpperCase()}</td>
                <td className="UxPlayerData_PRs_cellScore">{sr.displayValueWithLabel}</td>
                {sr.testResultLaserBool === 1 &&
                <td><img className="LaserImage_PRs_Record" src={LaserImg} alt="Laser verified" data-tip="Laser verified" data-place="right" width="16px" />
                  <ReactTooltip />
                </td>
                }
              </tr>
            ))}
            {blankRows.map((sr, index) => (
              <tr key={index}>
                <td className="UxPlayerData_PRs_cellName">&nbsp;</td>
                <td className="UxPlayerData_PRs_cellScore">&nbsp;</td>
              </tr>
            ))}
            <tr>
              <th colSpan="2" className="UxPlayerData_PRs_SubTitle">{title2}</th>
            </tr>
            {records2.map((sr, index) => (
              <tr key={index}>
                <td className="UxPlayerData_PRs_cellName">{sr.stdTestCategoryName.toUpperCase()}</td>
                <td className="UxPlayerData_PRs_cellScore">{sr.displayValueWithLabel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PersonalRecordsTable;
