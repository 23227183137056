import { TAG_CREATE_SUC, PHOTO_GET_MEDIA_SUC, TAG_DELETE_SUC, ALBUM_CREATE_SUC, ALBUM_UPDATE_SUC, MULTI_UPDATE_TAGS_SUC } from '../../../actions/data/photo';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT,
} from '../../../actions/data/cognito';

const tags = (state = {}, action) => {
  switch (action.type) {
    case TAG_CREATE_SUC: {
      if (action.tagRelMedia) {
        return Object.assign({}, state, {
          [action.uuid]: [...state[action.uuid]
            .filter(t => t.id !== action.tagRelMedia.tagId), action.tag],
        });
      }
      return Object.assign({}, state, {
        [action.uuid]: [...state[action.uuid], action.tag],
      });
    }
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.tags,
      });
    case TAG_DELETE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(tag => (tag.id !== action.tagId)),
      });
    case ALBUM_CREATE_SUC:
    case ALBUM_UPDATE_SUC: {
      if (!action.tags || action.tags.length === 0) {
        return state;
      }
      const tagids = action.tags.map(x => x.id);
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(tag => tagids.indexOf(tag.id) === -1),
          ...action.tags,
        ],
      });
    }
    case MULTI_UPDATE_TAGS_SUC: {
      const ids = action.tags.map(x => x.id);
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(tag => ids.indexOf(tag.id) === -1),
          ...action.tags,
        ],
      });
    }
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default tags;
