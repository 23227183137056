import {connect} from 'react-redux';
import queryString from 'query-string';
import Component from './RawComparisonPage.component';
import {selectLoadingMsg, selectSchoolStyle} from '../../+store/coachWorld';
import {getFilters} from '../../+store/coachWorld/coachWorld.utils';
import {selectSchoolTeamsList} from '../../+store/combine/combine.selectors';
import {getReportResults} from '../../+store/report';
import {
  getAthletes,
  getAthletesRoster, getReportId,
  selectFilteredReportAthletes, selectReportAthletes,
  selectTeams
} from '../../+store/report/report.selectors';

const mapStateToProps = (state, ownProps) => {
  const loadingMsg = selectLoadingMsg(state);
  const {bank, reportId} = ownProps.match.params;
  const {currentQuery} = state.ui.app.routes;
  const parsedQuery = queryString.parse(currentQuery);
  const {sortBy} = parsedQuery;
  let rosters = selectReportAthletes(state, ownProps);
  rosters = rosters ? rosters : [];
  const gradYears = [...new Set(rosters
    .filter(ath => !!ath.gradYear)
    .map(ath => ath.gradYear)
  )].sort((a, b) => b - a);

  return {
    sortBy: sortBy || '',
    loading: !!loadingMsg,
    loadingMsg,
    reportId,
    selectedFilters: getFilters(state, ownProps),
    athletes: selectFilteredReportAthletes(state, ownProps),
    bank,
    schoolTeamsList: selectSchoolTeamsList(state, ownProps),
    report: state.modules.coachWorld.report.report[reportId],
    columns: state.modules.coachWorld.report.columns[reportId],
    banks: state.modules.coachWorld.report.banks[reportId],
    teams: selectTeams(state, ownProps),
    schoolStyle: selectSchoolStyle(state, ownProps) || {},
    gradYears,
  };
};

const mapDispatchToProps = dispatch => ({
  getReportResults: (reportId) => {
    dispatch(getReportResults(reportId));
  },
  onSortByChange: (location, history, value) => {
    const parsedQuery = queryString.parse(location.search);
    parsedQuery.sortBy = value;
    history.push({
      search: queryString.stringify(parsedQuery),
    });
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
