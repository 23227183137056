import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonLink } from '../../../UxCommon';
import { Routes } from '../../../../store/actions/ui/routes';

const getRoute = (match,page,to) => `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}${match.params.schoolId ? `/${match.params.schoolId}`: ''}${to||''}`;

const LetsAddSome = ({
  page,
  msg,
  match,
  btnMsg,
  to,
}) => (
  <div style={{ width: '100%' }}>
    <div className="LetsAddSome_Txt">
      {msg}
    </div>
    <Row className="justify-content-center">
      <Col className="col-auto LetsAddSome_BtnWrapper">
        <ButtonLink
          to={getRoute(match, page,to)}
        >
          {btnMsg}
        </ButtonLink>
      </Col>
    </Row>
  </div>
);
LetsAddSome.propTypes = {
  msg: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  btnMsg: PropTypes.string.isRequired,
};

export default LetsAddSome;
