import { catchError, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { actions } from 'react-redux-form';

import { cognitoChangePass, cognitoChangePassSuccess } from '../../data/cognito';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../formUtils/operators';

import {
  validateConfirmPassword,
  validateLength,
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialChar,
  validatePasswordUppercase,
  validateRequired,
  validatePassword,
  validatePasswordLength
} from '../formUtils/validators';

import { formServerError, formSubmitSuccess } from '../formUtils/actions';

import { asyncFinishAction, asyncStartAction } from '../async';
import { ajaxErrorHandlerEpicFragment } from '../ajaxErrorHandlers';

const formValidation = {
  'forms.changePassword.oldPassword': [
    value => validatePassword(6)(value),
  ],
  'forms.changePassword.newPassword': [
    // value => validatePasswordUppercase()(value),
    // value => validatePasswordLowercase()(value),
    // value => validatePasswordNumber()(value),
    // value => validatePasswordSpecialChar()(value),
    // value => validateLength(8)(value),
    value => validatePassword(6)(value),
  ],
  'forms.changePassword.confirmNewPassword': [
    (value, form) => validateConfirmPassword()(form.newPassword, value),
  ],
};

const changePasswordSubmitAsyncStartEpic = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.changePassword', 'changePassword'),
  map(() => asyncStartAction('forms.changePassword', 'Saving your new password')),
);

const changePasswordSubmitEpic = (action$, state$) => {
  const form = () => state$.value.forms.changePassword;
  const cognitoUser = () => state$.value.data.cognito.cognitoUser;
  const uuid = () => state$.value.data.cognito.uuid;
  const sportId = () => state$.value.ui.app.routes.currentSportId;
  return action$.pipe(
    isPendingIsValid(state$, 'forms.changePassword', 'changePassword'),
    switchMap(() => from(cognitoChangePass(
      cognitoUser(),
      form().oldPassword,
      form().newPassword,
    )).pipe(
      map(response => ({
        success: true,
        response,
      })),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        errorAction: formServerError('forms.changePassword', 'changePasswordUpdate', error),
      })),
    )),
    switchMap((results) => {
      if (results.success) {
        return of(
          formSubmitSuccess('forms.changePassword', { uuid: uuid(), sportId: sportId() }),
          asyncFinishAction('forms.changePassword', 'save'),
          cognitoChangePassSuccess(results.response),
        );
      }
      return of(results.errorAction);
    }),
  );
};

export const EP_CHANGE_PW_FORM_INIT = 'editProfile.changePassword.formInit';
export const changePasswordInitAction = () => ({ type: EP_CHANGE_PW_FORM_INIT });

export const changePasswordFormInit = dispatch => () => {
  dispatch(actions.reset('forms.changePassword'));
  dispatch(actions.setInitial('forms.changePassword'));
  dispatch(changePasswordInitAction());
};

export const changePassEpics = combineEpics(
  changePasswordSubmitEpic,
  changePasswordSubmitAsyncStartEpic,
  validationOnSubmitEpicFactory(formValidation, 'forms.changePassword', 'changePassword'),
  validationFailedEpicFactory('forms.changePassword', 'changePassword'),
  ...formValidationEpicsFactory(formValidation, 'changePassword'),
);
