import { connect } from 'react-redux';
import {actions} from "react-redux-form";
import {getCurrentUuid} from "../../UxCommon/Utils";
import Component from "../../../components/UxProfile/ExternalProfile/GetExternalProfile";

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = ( stateProps, { dispatch }, ownProps ) => {
  const { state, currentUuid, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    onHideClick: () => {
      dispatch({ type: 'noop' });
    },
    formInit: () => {
      dispatch(actions.reset('forms.externalProfile'));
      dispatch( actions.setInitial('forms.externalProfile') );
    },
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentUuid = getCurrentUuid(state, ownProps.match);
  return {
    isMyProfile: currentUuid === state.data.cognito.uuid
      || !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.canEdit),
    isAuthorized: !!state.data.cognito.uuid,
    authorizedUuid: state.data.cognito.uuid,
    externalProfile : state.data.user.externalProfile[currentUuid],
    loadingMsg: state.ui.uxProfile.externalProfile.loadingMsg,
    loading: !!state.ui.uxProfile.externalProfile.loadingMsg,
    currentUuid,
    isCoach: !!(state.ui.app.context.canEditProfile && state.ui.app.context.canEditProfile.isCoach),
    state
  }
}
const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component)

export default Container;
