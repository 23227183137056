import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  share,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { forkJoin, interval, merge, of, Subscriber } from 'rxjs';
import { actions } from 'react-redux-form';

import {
  addMediaToAlbum,
  addTagToPhoto,
  addTagToVideo,
  createAlbum,
  createPhoto,
  createTag,
  createVideo,
  flagPhotoUploaded,
  flagVideoUploaded,
  getAlbum,
  getPhoto,
  getVideo,
  getVideoMeta,
  mapAlbumApiToUi,
  mapPhotoApiToUi,
  mapPhotosVideosAlbumsApiToUi,
  mapTagApiToUi,
  mapTagMediaApiToUI,
  mapVideoApiToUi,
  photoAlbumCreateSuccess,
  photoAlbumUpdateSuccess,
  photoPhotoResizeSuccess,
  photoPhotoUploadSuccess,
  photoTagCreateSuccess,
  photoVideoResizeSuccess,
  photoVideoUploadSuccess,
  updateAlbum,
  updatePhoto,
  updateVideo,
  uploadPhotoToS3,
  uploadVideoToS3,
} from '../../../../data/photo';

import { calculateTotalBytes, getTagToAlbumMediaArray } from './utils';

import { asyncErrorAction, asyncFinishAction, asyncStartAction } from '../../../async';

import {
  formValidationEpicsFactory,
  isPendingIsValid,
  validationFailedEpicFactory,
  validationOnSubmitEpicFactory,
} from '../../../formUtils/operators';

import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import {
  ADD_TAG,
  ADD_TAG_TO_PHOTO,
  albumMediaFormDataObject,
  CHECK_VIDEO_URL,
  CREATE_ALBUM,
  LOAD_MEDIA,
  UPDATE_ALBUM,
  UPLOAD_PHOTO,
  UPLOAD_VIDEO,
  uploadProgressUpdate,
} from '../actions';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { fixPhotoOrientation } from '../../../../../../containers/UxCommon/NativeUtils';

const genericPollEpicFragment = (fnMap, type, description, uuid) => source =>
  source.pipe(
    fnMap(),
    takeUntil(cancelOnRouteChange(source, Routes.photos, uuid)),
    ajaxErrorHandlerEpicFragment(),
    catchError(error => of({
      success: false,
      action: asyncErrorAction(type, description, error),
    })),
  );

export const createPhotoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(UPLOAD_PHOTO),
    getTokenFragment(),
    switchMap(({ action, token }) => createPhoto(token, { caption: '', athlete_id: uuid() }).pipe(
      map(response => ({
        action,
        success: true,
        response,
        token,
      })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(action.type, 'createPhoto', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return uploadPhotoToS3(result.response.upload_url, result.action.file).pipe(
          map(response => ({ ...result, s3Response: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.dataaction.type, 'createPhoto', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return flagPhotoUploaded(result.response.id, result.token).pipe(
          map(response => ({ ...result, flagUploadedResponse: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createPhoto', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        let done = false;
        const source = interval(1000);
        const limit = source.pipe(
          takeWhile(val => ((!done) && (val <= 10))),
          filter(val => val === 10),
          map(() => asyncErrorAction(UPLOAD_PHOTO, 'pollResized', { error: 'Timed out waiting for resize', uuid: uuid() })),
        );
        const poll = source.pipe(
          takeWhile(() => !done),
          switchMap(() => getPhoto(result.token, result.response.id).pipe(
            filter(response => !!response.resized_photos),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(UPLOAD_PHOTO, 'pollResized', error),
            })),
          )),
          tap((response) => {
            if (response.resized_photos) {
              done = true;
            }
          }),
          map(response => photoPhotoResizeSuccess(mapPhotoApiToUi(response), uuid())),
          takeUntil(limit),
        );
        return merge(
          limit,
          poll,
          of(
            photoPhotoUploadSuccess(mapPhotoApiToUi(result.response), uuid()),
            asyncFinishAction(UPLOAD_PHOTO, 'uploadPhoto', {}),
            asyncStartAction(LOAD_MEDIA),
            { type: LOAD_MEDIA, uuid: uuid() },
          ),
        );
      }
      return of(result.action);
    }),
  );
};

export const createVideoEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  return action$.pipe(
    ofType(UPLOAD_VIDEO),
    getTokenFragment(),
    switchMap(({ token, action }) => createVideo(token, { caption: '', athlete_id: uuid()}).pipe(
      map(response => ({
        action,
        success: true,
        response,
        token,
      })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        action: asyncErrorAction(action.type, 'createVideo', error),
      })),
    )),
    switchMap((result) => {
      if (result.success) {
        return uploadVideoToS3(result.response.upload_url, result.action.file).pipe(
          map(response => ({ ...result, s3Response: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createVideo', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        return flagVideoUploaded(result.response.id, result.token, { athlete_id: uuid() }).pipe(
          map(response => ({ ...result, flagUploadedResponse: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(result.action.type, 'createVideo', error),
          })),
        );
      }
      return result;
    }),
    switchMap((result) => {
      if (result.success) {
        let done = false;
        const source = interval(5000);
        const limit = source.pipe(
          takeWhile(val => ((!done) && (val <= 500))),
          filter(val => val === 500),
          map(() => asyncErrorAction(UPLOAD_VIDEO, 'pollResized', { error: 'Timed out waiting for resize', uuid: uuid() })),
        );
        const poll = source.pipe(
          takeWhile(() => !done),
          switchMap(() => getVideo(result.token, result.response.id).pipe(
            filter(response => !!response.processed),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(UPLOAD_VIDEO, 'pollResized', error),
            })),
          )),
          tap((response) => {
            if (response.processed) {
              done = true;
            }
          }),
          map(response => photoVideoResizeSuccess(mapVideoApiToUi(response), uuid())),
          takeUntil(limit),
        );
        // Poll until videos are resized, then update app
        return merge(
          limit,
          poll,
          of(
            photoVideoUploadSuccess(mapVideoApiToUi(result.response), uuid()),
            asyncFinishAction(UPLOAD_VIDEO, 'uploadVideo', {}),
          ),
        );
      }
      return of(result.action);
    }),
  );
};

const pollMediaEpicFragment = action$ => source =>
  source.pipe(switchMap((incoming) => {
    const {
      uuid,
      token,
      asyncModel,
      media,
      isAPhoto,
      intervalMs,
      timeoutInts,
      outer,
    } = incoming;
    let done = false;
    const intervalSource = interval(intervalMs);
    const limit = intervalSource.pipe(
      takeWhile(val => ((!done) && (val <= timeoutInts))),
      filter(val => val === timeoutInts),
      map(() => asyncErrorAction(
        asyncModel,
        'pollResized',
        { error: 'Timed out waiting for resize' },
        { photoId: media.id, uuid },
      )),
    );
    const apiCall = isAPhoto ? getPhoto : getVideo;
    const poll = intervalSource.pipe(
      takeWhile(() => !done),
      switchMap(() => apiCall(token, media.id).pipe(
        filter((response) => {
          if (isAPhoto) {
            return !!response.resized_photos;
          }
          return !!response.processed;
        }),
        takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(asyncModel, 'pollResized', error),
        })),
      )),
      tap((response) => {
        if ((isAPhoto) && (response.resized_photos)) {
          done = true;
        } else if (response.processed) {
          done = true;
        }
      }),
      map((response) => {
        if (isAPhoto) {
          return photoPhotoResizeSuccess(mapPhotoApiToUi(response), uuid, outer);
        }
        return photoVideoResizeSuccess(mapVideoApiToUi(response), uuid, outer);
      }),
      takeUntil(limit),
    );
    // Poll until videos are resized, then update app
    return merge(
      limit,
      poll,
    );
  }));

export const updateMediaEpicFragment = action$ => source =>
  source.pipe(switchMap((incoming) => {
    const {
      uuid,
      token,
      asyncModel,
      caption,
      isAPhoto,
      mediaId,
      loggedInUser,
    } = incoming;
    const apiCall = isAPhoto ? updatePhoto : updateVideo;
    const athleteId = loggedInUser === uuid ? "" : uuid;
    return apiCall(mediaId, token, { caption, athlete_id: athleteId }).pipe(
      map(response => ({
        incoming,
        isAPhoto,
        success: true,
        response,
      })),
      takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
      ajaxErrorHandlerEpicFragment(),
      catchError(error => of({
        success: false,
        actions: [
          asyncErrorAction(asyncModel, `update${isAPhoto ? 'Photo' : 'Video'}`, error),
          actions.setErrors('forms.albumDialog', {
            general: `Uh oh, there was an error saving your ${isAPhoto ? 'photo' : 'video'}`,
          }),
        ],
      })),
    );
  }));

export const createMediaEpicFragment = action$ => source =>
  source.pipe(
    switchMap((incoming) => {
      const {
        uuid,
        token,
        asyncModel,
        caption,
        isAPhoto,
        file,
        externalVideo,
        loggedInUser,
      } = incoming;
      const athleteId = loggedInUser === uuid ? "" : uuid;
      if (file) {
        const apiCall = isAPhoto ? createPhoto : createVideo;
        let fileExtension = file && file.name && file.name.split('.');
        fileExtension = fileExtension ? fileExtension.pop() : '';
        return apiCall(token, { caption, athlete_id: athleteId, created_by:loggedInUser ,fileExtension}).pipe(
          map(response => ({ incoming, success: true, response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
          catchError((error) => {
            if (error.status === 403) {
              return of({
                success: false,
                actions: [
                  asyncErrorAction(asyncModel, `create${isAPhoto ? 'Photo' : 'Video'}`, error),
                  actions.setErrors('forms.albumDialog', {
                    general: 'Uh oh, you are not authorized to add media for this athlete',
                  }),
                ],
              });
            }
            return of({
              success: false,
              actions: [
                asyncErrorAction(asyncModel, `create${isAPhoto ? 'Photo' : 'Video'}`, error),
                actions.setErrors('forms.albumDialog', {
                  general: `Uh oh, there was an error saving your ${isAPhoto ? 'photo' : 'video'}`,
                }),
              ],
            });
          }),
        );
      } else if (externalVideo) {
        return createVideo(token, {
          athlete_id: athleteId,
          embed_path: externalVideo.url,
          external_thumb: externalVideo.thumb,
          caption,
          created_by: loggedInUser
        }).pipe(
          map(response => ({ incoming, success: true, response })),
          takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            actions: [
              asyncErrorAction(asyncModel, `create${isAPhoto ? 'Photo' : 'Video'}`, error),
              actions.setErrors('forms.albumDialog', {
                general: `Uh oh, there was an error saving your ${isAPhoto ? 'photo' : 'video'}`,
              }),
            ],
          })),
        );
      }
      return of({
        success: false,
        actions: [asyncErrorAction(asyncModel, `create${isAPhoto ? 'Photo' : 'Video'}`, 'Unknown Error')],
      });
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          asyncModel,
          file,
          isAPhoto,
        } = result.incoming;
        if (isAPhoto && file && file.originalRotation && file.originalRotation !== 0) {
          return fixPhotoOrientation(file)
            .pipe(
              map(response => ({
                ...result,
                incoming: { ...result.incoming, file: response },
              })),
              takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
              ajaxErrorHandlerEpicFragment(),
              catchError(error => of({
                success: false,
                action: asyncErrorAction(asyncModel, 'fixPhotoOrientation', error),
              })),
            );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          asyncModel,
          file,
          isAPhoto,
          dispatch,
          totalBytes,
          mediaId,
        } = result.incoming;
        if (file) {
          const ps = Subscriber.create(
            (e) => {
              dispatch(uploadProgressUpdate(e, totalBytes, mediaId));
            },
            e => console.log('error', e),
            () => console.log('upload complete'),
          );
          const apiCall = isAPhoto ? uploadPhotoToS3 : uploadVideoToS3;
          return apiCall(result.response.upload_url, file, ps).pipe(
            map(response => ({ ...result, s3Response: response })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(asyncModel, `upload${isAPhoto ? 'Photo' : 'Video'}ToS3`, error),
            })),
          );
        }
        dispatch(uploadProgressUpdate({
          total: 1,
          loaded: 1,
          lengthComputable: true,
        }, totalBytes, mediaId));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          token,
          asyncModel,
          isAPhoto,
          file,
        } = result.incoming;
        if (file) {
          const apiCall = isAPhoto ? flagPhotoUploaded : flagVideoUploaded;
          const args = isAPhoto ?
            [result.response.id, token] : [result.response.id, token, { athlete_id: uuid }];
          return apiCall(...args).pipe(
            map(response => ({ ...result, response, isAPhoto })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(asyncModel, `flag${isAPhoto ? 'Photo' : 'Video'}Uploaded`, error),
            })),
          );
        }
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          uuid,
          token,
          asyncModel,
          isAPhoto,
          album,
        } = result.incoming;
        if (album) {
          let media;
          if (isAPhoto) {
            media = {
              photo_id: result.response.id,
              ordering: 0,
              athlete_id: uuid,
            };
          } else {
            media = {
              video_id: result.response.id,
              ordering: 0,
              athlete_id: uuid,
            };
          }
          return addMediaToAlbum(token, album.id, result.response.id, media, isAPhoto).pipe(
            map(response => ({ ...result, album: mapAlbumApiToUi(response) })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, () => uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(asyncModel, `add${isAPhoto ? 'Photo' : 'Video'}ToAlbum`, error),
            })),
          );
        }
        return of({ ...result });
      }
      return of(result);
    }),
  );

const albumFormValidation = {};

const submitAlbumFormSuccess = (action$, state$) => action$.pipe(
  isPendingIsValid(state$, 'forms.albumDialog', 'albumDialog'),
  switchMap((action) => {
    if (action.attrs && action.attrs.album) {
      return of(
        asyncStartAction(UPDATE_ALBUM),
        actions.setErrors('forms.albumDialog', { general: '' }),
        { type: UPDATE_ALBUM, album: action.attrs.album, dispatch: action.attrs.dispatch },
      );
    }
    return of(
      asyncStartAction(CREATE_ALBUM),
      actions.setErrors('forms.albumDialog', { general: '' }),
      { type: CREATE_ALBUM, album: null, dispatch: action.attrs.dispatch },
    );
  }),
);

const createAlbumEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : (state$.value.ui.app.routes.currentUuid || state$.value.ui.uxProfile.photosVideos.isCoachUploadVideoId);
  };
  const loggedInUser = () => state$.value.data.cognito.uuid;
  const stagedMedia = () => state$.value.ui.uxProfile.photosVideos.albumDialog.stagedMedia;
  const form = () => state$.value.forms.albumDialog;
  const savedMedia = () => state$.value.ui.uxProfile.photosVideos.albumDialog.albumToEdit
    && state$.value.ui.uxProfile.photosVideos.albumDialog.albumToEdit.media;
  const savedTags = () => state$.value.ui.uxProfile.photosVideos.albumDialog.albumTag;
  const stagedTags = () => state$.value.ui.uxProfile.photosVideos.albumDialog.stagedTag;
  return action$.pipe(
    filter(action => ((action.type === UPDATE_ALBUM) || (action.type === CREATE_ALBUM))),
    getTokenFragment(),
    switchMap(({ token, action }) => {
      const thisForm = form();
      if (action.type === CREATE_ALBUM) {
        if (form().newAlbumName) {
          return createAlbum(token, {
            title: thisForm.newAlbumName,
            description: '',
            athlete_id: uuid(),
          }).pipe(
            map(response => ({
              action,
              success: true,
              album: mapAlbumApiToUi(response),
              token,
            })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(action.type, 'createAlbum', error),
            })),
          );
        }
        return of({
          token,
          action,
          success: true,
          album: null,
        });
      }
      return updateAlbum(thisForm.selectedAlbum, token, {
        title: thisForm.albumName || action.album.title,
        description: '',
        athlete_id: uuid(),
      }).pipe(
        map(response => ({
          action,
          success: true,
          album: mapAlbumApiToUi(response),
          token,
        })),
        takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          action: asyncErrorAction(action.type, 'createAlbum', error),
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        results.action.dispatch(uploadProgressUpdate(null, 0, '', 'Creating tags', true));
        const forks = [];
        stagedTags().forEach((tag) => {
          forks.push(createTag(results.token, {
            label: tag.label,
            athlete_id: uuid(),
          }).pipe(
            map(response => (
              {
                action: results.action,
                success: true,
                tag: mapTagApiToUi(response),
              })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(results.action.type, 'createTag', error),
            })),
          ));
        });
        if (forks.length) {
          return forkJoin(...forks)
            .pipe(
              checkForkErrors(),
              map((forkResults) => {
                if (forkResults.success) {
                  if (savedTags() && savedTags().length > 0) {
                    savedTags().forEach((savedTag) => {
                      forkResults.results.push({ tag: savedTag, success: true, savedTag: true });
                    });
                  }
                  return {
                    ...results,
                    tagResults: forkResults.results,
                  };
                }
                return forkResults;
              }),
            );
        } else if (savedTags() && savedTags().length > 0) {
          const savedTagResults = [];
          savedTags().forEach((savedTag) => {
            savedTagResults.push({ tag: savedTag, success: true, savedTag: true });
          });
          return of({
            ...results,
            tagResults: savedTagResults,
          });
        }
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        if (results.album) {
          return getAlbum(results.album.id, results.token).pipe(
            map(response => ({
              albumDetailsResponse: response,
              ...results,
            })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(results.action.type, 'getAlbumDetails', error),
            })),
          );
        }
        return of({ ...results, albumDetailsResponse: null });
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        if (stagedMedia().length) {
          // todo add update changed photos to forkJoin
          const totalBytes = calculateTotalBytes(stagedMedia());
          results.action.dispatch(uploadProgressUpdate(null, totalBytes, '', 'Uploading media', true));
          const forks = [];
          stagedMedia().forEach((media) => {
            if (media.isNew) {
              forks.push(of({
                uuid: uuid(),
                token: results.token,
                asyncModel: results.action.type,
                caption: albumMediaFormDataObject(state$.value, media.id).caption || '',
                mediaId: media.id,
                file: media.file,
                externalVideo: media.externalVideo,
                isAPhoto: media.isAPhoto,
                album: results.album,
                dispatch: results.action.dispatch,
                totalBytes,
                isNew: true,
                outer: results.action,
                loggedInUser: loggedInUser(),
              }).pipe(createMediaEpicFragment(action$)));
            } else {
              forks.push(of({
                uuid: uuid(),
                token: results.token,
                asyncModel: results.action.type,
                caption: albumMediaFormDataObject(state$.value, media.id).caption || '',
                mediaId: media.id,
                isAPhoto: media.isAPhoto,
                album: results.album,
                isNew: false,
                outer: results.action,
                loggedInUser: loggedInUser(),
              }).pipe(updateMediaEpicFragment(action$)));
            }
          });
          if (forks.length) {
            return forkJoin(...forks)
              .pipe(
                checkForkErrors(),
                map((forkResults) => {
                  if (forkResults.success) {
                    return {
                      ...results,
                      mediaResults: forkResults.results,
                    };
                  }
                  return forkResults;
                }),
              );
          }
        }
        return of({ ...results, mediaResults: [] });
      }
      return of(results);
    }),
    switchMap((results) => {
      const forks = [];
      // add tag to staged media
      const mediaArray = getTagToAlbumMediaArray(savedMedia(), results.mediaResults);
      const checkTagResultsLength = mediaArray && mediaArray.length > 0
        && results.tagResults && results.tagResults.length > 0;

      if (checkTagResultsLength) {
        mediaArray.forEach((media) => {
          results.tagResults.forEach((tag) => {
            // if new media old tag or new tag old media
            if (tag.success && (!(tag.savedTag && !media.response))) {
              const mediaId = (media.response ? media.response.id : media.mediaId);
              const tagId = (tag.savedTag ? tag.tag : tag.tag.id);
              const isAPhoto = { media };
              let apiCallMedia;
              if (media.isAPhoto) {
                apiCallMedia = addTagToPhoto(results.token, {
                  tag_id: tagId,
                  athlete_id: uuid(),
                }, mediaId);
              } else {
                apiCallMedia = addTagToVideo(results.token, {
                  tag_id: tagId,
                  athlete_id: uuid(),
                }, mediaId);
              }
              forks.push(apiCallMedia.pipe(
                map(response => (
                  {
                    success: true,
                    tagRelMedia: mapTagMediaApiToUI(response.id, tagId, isAPhoto),
                  })),
                takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
                ajaxErrorHandlerEpicFragment(),
                catchError(error => of({
                  success: false,
                  action: asyncErrorAction(ADD_TAG_TO_PHOTO, 'addTagToPhoto', error),
                })),
              ));
            }
          });
        });
      }
      if (forks.length) {
        return forkJoin(...forks)
          .pipe(
            checkForkErrors(),
            map((forkResults) => {
              if (forkResults.success) {
                const arrRes = forkResults.results.map(item => item.tagRelMedia);
                return {
                  ...results,
                  tagRelMediaCol: arrRes,
                };
              }
              return forkResults;
            }),
          );
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        results.action.dispatch(uploadProgressUpdate(null, 0, '', 'Reloading album list'));
        if (results.album) {
          return getAlbum(results.album.id, results.token).pipe(
            map(response => ({
              ...results,
              albumDetailsResponse: response,
            })),
            takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
            ajaxErrorHandlerEpicFragment(),
            catchError(error => of({
              success: false,
              action: asyncErrorAction(results.action.type, 'getAlbumDetails', error),
            })),
          );
        }
        return of({ ...results, albumDetailsResponse: null });
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        const {
          photos,
          videos,
          albums,
          tags,
          albumRelMediaCollection,
        } = mapPhotosVideosAlbumsApiToUi(
          results.mediaResults.filter(result => result.isAPhoto).map(result => result.response),
          results.mediaResults.filter(result => !result.isAPhoto).map(result => result.response),
          results.albumDetailsResponse ? [results.albumDetailsResponse] : [],
          results.tagResults ? results.tagResults.filter(x => !x.savedTag)
            .map(x => x.tag) : [],
        );

        let successAction;
        if (results.action.type === CREATE_ALBUM) {
          successAction = photoAlbumCreateSuccess(
            photos,
            videos,
            albums[0],
            uuid() || loggedInUser(),
            tags,
            albumRelMediaCollection,
            results.tagRelMediaCol,
          );
        } else {
          successAction = photoAlbumUpdateSuccess(
            photos,
            videos,
            albums[0],
            uuid(),
            tags,
            albumRelMediaCollection,
            results.tagRelMediaCol,
          );
        }
        return merge(
          ...results.mediaResults.filter(m => m.incoming.isNew).map((mediaResult) => {
            const photo = photos.find(p => p.id === mediaResult.response.id);
            if (photo) {
              return of({
                uuid: uuid(),
                token: results.token,
                asyncModel: results.action.type,
                media: photo,
                isAPhoto: true,
                intervalMs: 2500,
                timeoutInts: 150,
              }).pipe(pollMediaEpicFragment(action$, state$));
            }
            const video = videos.find(v => v.id === mediaResult.response.id);
            if (video) {
              return of({
                uuid: uuid(),
                token: results.token,
                asyncModel: results.action.type,
                media: video,
                isAPhoto: false,
                intervalMs: 4000,
                timeoutInts: 500,
              }).pipe(pollMediaEpicFragment(action$, state$));
            }
            return of({ type: 'noop' });
          }),
          of(
            successAction,
            asyncFinishAction(results.action.type, 'createAlbum', {}),
          ),
        );
      }
      if (results.actions) {
        return of(...results.actions);
      }
      return of(results.action);
    }),
  );
};

const createTagEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const form = () => state$.value.forms.tagDialog;
  return action$.pipe(
    filter(action => ((action.type === ADD_TAG) || (action.type === ADD_TAG_TO_PHOTO))),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      const thisForm = form();
      if (action.type === ADD_TAG) {
        const fnMap = () => map(response => ({
          action,
          success: true,
          tag: mapTagApiToUi(response),
          token,
        }));
        return createTag(token, {
          label: thisForm.newTagName.new,
          athlete_id: uuid(),
        }).pipe(
          share(),
          genericPollEpicFragment(
            fnMap,
            action.type,
            'createTag',
            uuid,
          ),
        );
      }
      return of({
        token,
        action,
        success: true,
        tag: action.tags.find(a => a.id === thisForm.selectedTag),
        isNewTagAdded: false,
      });
    }),
    switchMap((results) => {
      if (results.success) {
        const fnMap = () => map(response => (
          {
            ...results,
            success: true,
            tagRelMedia: mapTagMediaApiToUI(response.id, results.tag.id, true),
          }));
        let apiCallMedia;
        if (results.action.photoId) {
          apiCallMedia = addTagToPhoto(results.token, {
            tag_id: results.tag.id,
            athlete_id: uuid(),
          }, results.action.photoId);
        } else if (results.action.videoId) {
          apiCallMedia = addTagToVideo(results.token, {
            tag_id: results.tag.id,
            athlete_id: uuid(),
          }, results.action.videoId);
        }
        if (apiCallMedia) {
          return apiCallMedia.pipe(
            share(),
            genericPollEpicFragment(
              fnMap,
              results.action.type,
              'addTagToPhoto',
              uuid,
            ),
          );
        }
      }
      return of(results);
    }),
    switchMap((results) => {
      if (results.success) {
        if (results.tagRelMedia) {
          const successForPhoto = photoTagCreateSuccess(
            results.tag,
            results.tagRelMedia,
            uuid(),
          );
          return of(
            actions.change('forms.tagDialog.selectedTag', ''),
            actions.change('forms.tagDialog.newTagName.new', ''),
            successForPhoto,
            asyncFinishAction(ADD_TAG_TO_PHOTO, 'createTagPhoto', {}),
          );
        }
        const successAction = photoTagCreateSuccess(
          results.tag,
          null,
          uuid(),
        );
        return of(
          (actions.change('forms.tagDialog.newTagName.new', '')),
          successAction,
          asyncFinishAction(ADD_TAG, 'createTag', {}),
        );
      }
      if (results.actions) {
        return of((actions.change('forms.tagDialog.newTagName.new', '')), ...results.actions);
      }
      return of(results.action);
    }),
  );
};

const checkVideoUrlEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const form = () => state$.value.forms.externalVideo;
  return action$.pipe(
    ofType(CHECK_VIDEO_URL),
    switchMap((action) => {
      const { url } = form();
      if (!url) {
        return of(
          actions.setErrors('forms.externalVideo.url', {
            general: 'Please enter a link.',
          }),
          asyncErrorAction(action.type, 'getMeta', 'Blank link'),
        );
      }
      return getVideoMeta(url).pipe(
        map(response => (
          {
            action,
            success: true,
            response,
          })),
        takeUntil(cancelOnRouteChange(action$, Routes.photos, uuid)),
        ajaxErrorHandlerEpicFragment(),
        catchError(error => of({
          success: false,
          actions: [
            actions.setErrors('forms.externalVideo', {
              general: 'We apologize, there was an error validating your link',
            }),
            asyncErrorAction(action.type, 'getMeta', error),
          ],
        })),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        const { response } = results;
        if ((response.provider === 'Hudl') ||
          (response.provider === 'YouTube')) {
          const r = /src="([ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789\-._~:/?#[\]@!$&'()*+,;=]+)"/;
          const res = r.exec(response.code);
          if (res && res[1]) {
            return of(asyncFinishAction(results.action.type, 'getMeta', {
              url: res[1],
              thumb: response.mainImage,
              provider: response.provider,
              title: response.title,
              description: response.description,
            }));
          }
        }
        return of(
          actions.setErrors('forms.externalVideo.url', {
            general: "The link you provided doesn't look like a valid YouTube or Hudl video",
          }),
          asyncErrorAction(results.action.type, 'getMeta', 'Invalid link'),
        );
      }
      if (results.actions) {
        return of(...results.actions);
      }
      if (results.action.error) {
        return of(
          actions.setErrors('forms.externalVideo.url', {
            general: results.action.error.message,
          }),
          asyncErrorAction(results.action.error.type, 'getMeta', results.action.error.name),
        );
      }
      return of(results.action);
    }),
  );
};

export const createEpics = combineEpics(
  createPhotoEpic,
  createVideoEpic,
  createAlbumEpic,
  createTagEpic,
  validationOnSubmitEpicFactory(albumFormValidation, 'forms.albumDialog', 'albumDialog'),
  validationFailedEpicFactory('forms.albumDialog', 'albumDialog'),
  ...formValidationEpicsFactory(albumFormValidation, 'albumDialog'),
  submitAlbumFormSuccess,
  checkVideoUrlEpic,
);
