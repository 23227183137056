import { connect } from 'react-redux';
import Component from './MenuBar.component';
import { selectStatusMsg } from '../../+store/combine/combine.selectors';
import { setScrolledBelowMenu } from '../../+store/combine';

const mapStateToProps = state => ({
  msg: selectStatusMsg(state),
  hasScrolledBelowMenu: state.modules.coachWorld.combine.userHasScrolledBelowMenu,
});

const mapDispatchToProps = dispatch => ({
  setScrolledBelowMenu: (hasScrolledBelowMenu) => {
    dispatch(setScrolledBelowMenu(hasScrolledBelowMenu));
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
