import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import { ButtonWithIcon, ButtonRect, LoadingSpinner } from '../../../../UxCommon';
import { Errors } from '../../../../UxCommon/Form';
import StatsRow from '../../../../../containers/UxProfile/PlayerData/StatsRow';
import StatsRowNew from '../../../../../containers/UxProfile/PlayerData/StatsRowNew';
import AddSeasonForm from '../../../../../containers/UxProfile/PlayerData/AddSeasonForm';

class StatsTable extends PureComponent {
  static propTypes = {
    newSeasons: PropTypes.array.isRequired,
    editing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    addSeasonFormIsShown: PropTypes.bool.isRequired,
    team: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    seasons: PropTypes.array.isRequired,
    onAddSeasonClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onCancelEditClick: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    getRrfFormModel: PropTypes.func.isRequired,
    getRrfFormObject: PropTypes.func.isRequired,
    stdCats: PropTypes.array.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    currentSport: PropTypes.object.isRequired,
  }


  isLastTdInCat = (statPositions, statPosIndex, stdCats, catIndex) => {
    if ((statPosIndex === (statPositions.length - 1))
    && (catIndex !== (stdCats.length - 1))) {
      return true;
    }
    return false;
  }

  handleMouseOver = (event) => {
    if (event.target && event.target.children && event.target.children.length > 0) {
      const tableLeftToScreen = event.target.offsetParent.getClientRects()[0].left;
      const elementLeftToScreen = event.target.getClientRects()[0].left;
      const tooltipWidth = event.target.children[0].offsetWidth;
      if (elementLeftToScreen - tableLeftToScreen < tooltipWidth) {
        event.target.children[0].className = 'PlayerData_StatsTable_CellComment right';
      }
    }
  }

  render() {
    const {
      newSeasons,
      editing,
      loading,
      loadingMsg,
      addSeasonFormIsShown,
      team,
      position,
      seasons,
      onAddSeasonClick,
      onEditClick,
      onCancelEditClick,
      saveData,
      getRrfFormModel,
      getRrfFormObject,
      stdCats,
      isMyProfile,
      currentSport,
    } = this.props;
    const rrfForm = getRrfFormObject();
    const { statsSeasonLabel, statsPositionLabel } = currentSport;
    const positionLabel = `${statsPositionLabel}: ${position.name}`;
    return (
      <div className="PlayerData_Stats_Div" style={{ position: 'relative', paddingBottom: '80px' }}>
        <AddSeasonForm
          isShown={addSeasonFormIsShown}
          team={team}
          position={position}
          newSeasons={newSeasons}
          currentSport={currentSport}
        />
        <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
        <div className="PlayerData_Stats_Header">
          <div className="PlayerData_Stats_Team">{team.googleName}</div>
          <div className="PlayerData_Stats_Position">{positionLabel}</div>
        </div>
        <div className="PlayerData_Stats_TableWrapper">
          <Form model={getRrfFormModel()} onSubmit={saveData}>
            <table className="PlayerData_Stats_Table">
              <thead>
                <tr>
                  <th className="PlayerData_StatsTable_FirstCell" scope="col" />
                  {
                    stdCats
                    .map(cat => (
                      cat.category.code === 'GLOBAL' ? (
                        <th
                          key={cat.category.id}
                          className="PlayerData_StatsTable_CatName"
                          colSpan={cat.statPositions.length}
                        />
                      ) : (
                        <th
                          className="PlayerData_StatsTable_CatName"
                          key={cat.category.id}
                          colSpan={cat.statPositions.length}
                          scope="col"
                        >
                          {cat.category.name}
                        </th>
                      )
                    ))
                  }
                </tr>
                <tr>
                  <th scope="col">{statsSeasonLabel}</th>
                  {
                    stdCats.map((cat, catIndex) => (
                      cat.statPositions.map((sp, spIndex) => (
                        <th
                          key={sp.id}
                          scope="col"
                          onMouseOver={this.handleMouseOver}
                          onFocus={this.handleMouseOver}
                          className={
                            this.isLastTdInCat(
                              cat.statPositions,
                              spIndex,
                              stdCats,
                              catIndex,
                            )
                            ? 'CatBorder PlayerData_StatsTable_CellWithComment'
                            : 'PlayerData_StatsTable_CellWithComment'
                          }
                        >
                          {sp.stdStat.code}
                          <span
                            className="PlayerData_StatsTable_CellComment left"
                          >{sp.stdStat.name}
                          </span>
                        </th>
                      ))
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  seasons.map(season => (
                    <StatsRow
                      key={season.season.id}
                      season={season.season}
                      categories={season.categories}
                      stdCats={stdCats}
                      currentSport={currentSport}
                      position={position}
                      team={team}
                      editing={editing}
                      isMyProfile={isMyProfile}
                    />
                  ))
                }
                {
                  newSeasons && newSeasons.map(row => (
                    <StatsRowNew
                      key={`newSeason${row.rowId}`}
                      season={row.season}
                      rowId={row.rowId}
                      stdCats={stdCats}
                      currentSport={currentSport}
                      position={position}
                      team={team}
                    />
                  ))
                }
                <tr className="PlayerData_StatsTable_LastRow">
                  <td className="PlayerData_StatsTable_NameCell" />
                  {
                    stdCats
                    .map((cat, catIndex) =>
                      cat.statPositions
                      .map((statPos, statPosIndex) => (
                        <td
                          key={statPos.id}
                          className={
                            this.isLastTdInCat(
                              cat.statPositions,
                              statPosIndex,
                              stdCats,
                              catIndex,
                            )
                            ? 'CatBorder'
                            : ''
                          }
                        />
                    )))
                  }
                  <td className="PlayerData_StatsRow_DeleteCell" />
                </tr>
              </tbody>
            </table>
          </Form>
        </div>
        <div className="PlayerData_Stats_Btns">
          <div>
            {
              rrfForm && rrfForm.$form &&
                <Errors valid={rrfForm.$form.valid} errors={rrfForm.$form.errors} />
            }
          </div>
          {
            isMyProfile && (
              <div className="d-flex">
                {
                  editing ?
                    <React.Fragment>
                      <ButtonWithIcon
                        className="PlayerData_Stats_Btn"
                        iconName="close"
                        onClick={onCancelEditClick}
                      >
                        Cancel
                      </ButtonWithIcon>
                      <ButtonRect
                        onClick={saveData}
                        className="PlayerData_Stats_Btn"
                      >
                        Save Data
                      </ButtonRect>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <ButtonWithIcon
                        className="PlayerData_Stats_Btn"
                        iconName="lead-pencil"
                        onClick={onEditClick}
                      >
                        Edit
                      </ButtonWithIcon>
                      <ButtonWithIcon
                        className="PlayerData_Stats_Btn"
                        iconName="plus"
                        onClick={onAddSeasonClick}
                      >
                        Add {statsSeasonLabel}
                      </ButtonWithIcon>
                    </React.Fragment>
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default StatsTable;
