import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../UxCommon/Icon/Icon';

const CloseButton = ({ onClick, size, style }) => (
  <button
    className="UxCommon_CloseButton"
    onClick={onClick}
    style={Object.assign({
      top: `${size}px`,
      right: `${size}px`,
      padding: `${size * 0.75}px`,
      height: `${size * 3}px`,
      width: `${size * 3}px`,
    }, style || {})}
  >
    <Icon className="UxCommon_CloseButton_Icon" style={{ fontSize: `${size}px` }} iconName="close" />
  </button>
);
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default CloseButton;
