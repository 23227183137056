import { connect } from 'react-redux';
import Component from '../../../components/UxCommon/Loaders/GetEditProfile';
import { playerProfileGet } from '../../../store/actions/ui/uxProfile';
import { getCurrentUuid } from '../Utils';
import { updateSchoolIdStore } from '../../../store/actions/ui/routes';

const mapStateToProps = (state, ownProps) => ({
  uuid: getCurrentUuid(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  getProfile: (uuid) => {
    dispatch(playerProfileGet(uuid));
  },
  updateSchoolIdInStore: (schoolId)=>{
    dispatch(updateSchoolIdStore(schoolId))
  },
});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
