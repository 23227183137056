import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LoadingSpinner, PhotosCircleButton, LoadingImg } from '../../../../UxCommon';
import DeletePhotoDialog from '../DeletePhotoDialog/DeletePhotoDialog';
import VideoView from './VideoView/VideoView';
import Icon from '../../../../UxCommon/Icon/Icon';
import { expandedPhotoDialogTo, expandedTutorialDialogTo } from '../../../../../store/actions/ui/routes';
import { getImageUrl } from '../../../../../store/actions/ui/uxProfile/photosVideos/utils';

import { GridBreakpoints } from '../../../../../config/responsive';

class PhotoVideoCard extends PureComponent {
  static propTypes = {
    media: PropTypes.object.isRequired,
    onFavoriteClick: PropTypes.func.isRequired,
    isDeleteDialogShown: PropTypes.bool.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onConfirmDeleteClick: PropTypes.func.isRequired,
    onCancelDeleteClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    multiDeleteIsOpen: PropTypes.bool.isRequired,
    multiDeleteIsSelected: PropTypes.bool.isRequired,
    onMultiDeleteClick: PropTypes.func.isRequired,
    onMultiDeleteUnclick: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    onShareClick: PropTypes.func.isRequired,
  };

  state = {
    width: 230,
  }

  onMultiDeleteClick = () => {
    const {
      onMultiDeleteClick,
      onMultiDeleteUnclick,
      media,
      multiDeleteIsSelected,
    } = this.props;
    if (multiDeleteIsSelected) {
      onMultiDeleteUnclick(media);
    } else {
      onMultiDeleteClick(media);
    }
  };

  onImgLoad = ({ width, height }) => {
    this.setState({
      width: 300 * (width / height),
    });
  }

  onFavoriteClick = () => {
    const { onFavoriteClick, media } = this.props;
    onFavoriteClick(media);
  }

  getImage = (media) => {
    if (media.isAPhoto) {
      const imgUrl = getImageUrl(media);
      if (imgUrl) {
        return (<LoadingImg
          className="Photos_PhotoCard_Img"
          srcUrl={imgUrl}
          onLoadCallback={this.onImgLoad}
        />);
      }
      return <LoadingSpinner loading loadingMsg="Processing Photo" style={{ zIndex: 1 }} />;
    }
    return <VideoView video={media} onLoadCallback={this.onImgLoad} />;
  };

  getExpandedDialogPath = (media) => {
    const { match } = this.props;
    return !media.isAPhoto && media.isCoachTutorial() ?
      expandedTutorialDialogTo(match.params.schoolId, media.id)
      : expandedPhotoDialogTo(match.params.uuid, media.isAPhoto, media.id,match.params.sport, match.params.schoolId);
  }

  render() {
    const {
      media,
      isDeleteDialogShown,
      onConfirmDeleteClick,
      onCancelDeleteClick,
      onDeleteClick,
      loading,
      loadingMsg,
      isFavorite,
      multiDeleteIsOpen,
      multiDeleteIsSelected,
      isMyProfile,
      windowWidth,
      onShareClick,
      isCreatedByCoach,
      isCoach,
    } = this.props;
    const { width } = this.state;
    const cardWidth = windowWidth < GridBreakpoints.md ? '100%' : `${width}px`;
    const { caption } = media;
    const maxLength = 50;
    let updatedCaption = caption ? caption.slice(0, maxLength) : '';
    if (updatedCaption) {
      const captionArr = updatedCaption.split('\n');
      updatedCaption =
        captionArr.length > 2
          ? captionArr.slice(0, 1).join('\n')
          : updatedCaption;
      updatedCaption = `${updatedCaption}${
        updatedCaption.length !== caption.length ? '...' : ''
      }`;
    }

    return (
      <div className="Photos_PhotoVideoCard" style={{ width: cardWidth, display: media.isTimedOut ? 'none' : 'block' }}>
        <div
          className="Photos_PhotosVideoCard_ImgWrapper"
          style={{
            width: cardWidth,
            overflow: isDeleteDialogShown ? 'inherit' : 'hidden',
          }}
        >
          {
            multiDeleteIsOpen ? (
              <button
                className={[
                  'Photos_PhotosVideoCard_MultiDeleteOverlay',
                  multiDeleteIsSelected ? 'selected' : '',
                ].join(' ')}
                onClick={this.onMultiDeleteClick}
              />
            ) : (
              <div>
                {isFavorite &&
                <div className="Photos_PhotosVideoCard_FavoritBtnImg">
                  <div className="UxCommon_PhotosCircleButtonImg">
                    <Icon iconName="star-filled" style={{ left: '3px', top: '2px', color: 'white' }} />
                  </div>
                </div>}
                <div className="Photos_PhotosVideoCard_Hoverlay">
                  {
                    isMyProfile && !!media.processed && (
                      <React.Fragment>
                        <PhotosCircleButton
                          className="Photos_PhotosVideoCard_FavoritBtn"
                          iconName={isFavorite ? 'star-filled' : 'star-outline'}
                          onClick={this.onFavoriteClick}
                          isFavorite={isFavorite}
                        />
                        <PhotosCircleButton
                          className="Photos_PhotosVideoCard_DeleteBtn"
                          iconName="trash"
                          onClick={onDeleteClick}
                        />
                        <PhotosCircleButton
                          className="Photos_PhotosVideoCard_ShareBtn"
                          iconName="share"
                          onClick={onShareClick}
                        />
                      </React.Fragment>
                    )
                  }
                  {
                    (!loading && ((media.isAPhoto && (media.resizedPhotos)) ||
                      (!media.isAPhoto && (!!media.processed)))) && (
                      <Link
                        to={this.getExpandedDialogPath(media)}
                        className="Photos_PhotosVideoCard_Clickable"
                      />
                    )
                  }
                </div>
                {
                  (!loading && ((media.isAPhoto && (media.resizedPhotos)) ||
                    (!media.isAPhoto && (!!media.processed)))) && (
                    <Link
                      to={this.getExpandedDialogPath(media)}
                      className="Photos_PhotosVideoCard_Clickable hidden-lg-up"
                    />
                  )
                }
              </div>
            )
          }
          {
            !media.isAPhoto && (
              <Icon iconName="curved-play" className="Photos_PhotosVideoCard_VideoIcon" />
            )
          }
          {
            isDeleteDialogShown && (
              <DeletePhotoDialog
                media={media}
                onConfirmDeleteClick={onConfirmDeleteClick}
                onCancelClick={onCancelDeleteClick}
                canDelete={(isCoach && media.isCreatedByCoach) ? true : (media.isCreatedByCoach? false:true)}
              />
            )
          }
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
          {this.getImage(media)}
        </div>
        <div className="Photos_PhotoVideoCard_CaptionBtns_Row">
          <div className="Photos_PhotoVideoCard_Caption">
            {updatedCaption}
          </div>
          <div className="Photos_PhotoVideoCard_CaptionBtns">
            {
              isMyProfile && !!media.processed && !loading && (
                <React.Fragment>
                  <PhotosCircleButton
                    className="Photos_PhotosVideoCard_CaptionFavoritBtn"
                    iconName={isFavorite ? 'star-filled' : 'star-outline'}
                    onClick={this.onFavoriteClick}
                    isFavorite={isFavorite}
                  />
                  <PhotosCircleButton
                    className="Photos_PhotosVideoCard_CaptionDeleteBtn"
                    iconName="trash"
                    onClick={onDeleteClick}
                  />
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PhotoVideoCard;
