import { connect } from 'react-redux';
import Component from '../../components/UxProfile/PlayerProfile/TeamsRow/TeamsRow';
import { getCurrentUuid, getCurrentSportId } from '../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const uuid = getCurrentUuid(state, ownProps.match);
  return {
    uuid,
    loading: !!state.ui.uxProfile.playerProfile.athleteTeamRostersLoadingMsg,
    loadingMsg: state.ui.uxProfile.playerProfile.athleteTeamRostersLoadingMsg,
    sportId: getCurrentSportId(state),
    athleteTeamRosters: state.ui.uxProfile.playerProfile.athleteTeamRosters,
  };
};

const mapDispatchToProps = () => ({});

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default Container;
