import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { getInviteLink } from './MailChimpUserInvite.api';
import LoadingSpinner from '../Common/LoadingSpinner/LoadingSpinner';

const MailChimpUserInviteComponent = () => {
  const { uuid, schoolId, sportId } = useParams();
  const [inviteLink, setInviteLink] = useState();

  useEffect(() => {
    getInviteLink(uuid, sportId, schoolId).then((inviteURL) =>
      setInviteLink(inviteURL),
    );
  }, [uuid]);

  if (inviteLink) {
    return <Redirect to={inviteLink} />;
  }

  return <LoadingSpinner loading loadingMsg={'Loading'} />;
};

export default MailChimpUserInviteComponent;
