export const PHOTO_GET_MEDIA_SUC = 'photo.getMedia.success';
export const photoGetMediaSuccess = (
  photos, videos, albums, tags,
  albumRelMediaCol, tagRelMediaCol, uuid,
) => ({
  type: PHOTO_GET_MEDIA_SUC,
  photos,
  videos,
  albums,
  tags,
  albumRelMediaCol,
  tagRelMediaCol,
  uuid,
});

export const PHOTO_UPLOAD_SUC = 'photo.photoUpload.success';
export const photoPhotoUploadSuccess = (photo, uuid) => ({
  type: PHOTO_UPLOAD_SUC,
  photo,
  uuid,
});

export const UPDATE_TEST_TEST_RESULT_VIDEO= 'update.test.testResult.video';
export const updateTestTestResult = (testResult) =>({
    type:UPDATE_TEST_TEST_RESULT_VIDEO,
    testResult
});


export const PHOTO_RESIZE_SUC = 'photo.photoResize.success';
export const photoPhotoResizeSuccess = (photo, uuid, outer) => ({
  type: PHOTO_RESIZE_SUC,
  photo,
  uuid,
  outer,
});

export const VIDEO_UPLOAD_SUC = 'photo.videoUpload.success';
export const photoVideoUploadSuccess = (video, uuid, outer) => ({
  type: VIDEO_UPLOAD_SUC,
  video,
  uuid,
  outer,
});
export const VIDEO_RESIZE_SUC = 'photo.videoResize.success';
export const photoVideoResizeSuccess = (video, uuid) => ({
  type: VIDEO_RESIZE_SUC,
  video,
  uuid,
});

export const ALBUM_CREATE_SUC = 'photo.albumCreate.success';
export const photoAlbumCreateSuccess = (
  photos, videos, album, uuid, tags,
  albumRelMediaCollection, tagRelMediaCollection,
) => ({
  type: ALBUM_CREATE_SUC,
  photos,
  videos,
  album,
  uuid,
  tags,
  albumRelMediaCollection,
  tagRelMediaCollection,
});
export const ALBUM_UPDATE_SUC = 'photo.albumUpdate.success';
export const photoAlbumUpdateSuccess = (
  photos, videos, album, uuid, tags,
  albumRelMediaCollection, tagRelMediaCollection,
) => ({
  type: ALBUM_UPDATE_SUC,
  photos,
  videos,
  album,
  uuid,
  tags,
  albumRelMediaCollection,
  tagRelMediaCollection,
});

export const TAG_CREATE_SUC = 'photo.tagCreate.success';
export const photoTagCreateSuccess = (tag, tagRelMedia, uuid) => ({
  type: TAG_CREATE_SUC,
  tag,
  uuid,
  tagRelMedia,
});

export const TAG_DELETE_SUC = 'photo.tagDelete.success';
export const photoTagDeleteSuccess = (tagId, uuid) => ({
  type: TAG_DELETE_SUC,
  tagId,
  uuid,
});
export const GET_FAVORITES_SUC = 'photo.getFavorites.success';
export const photoGetFavoritesSuccess = (favorites, uuid) => ({
  type: GET_FAVORITES_SUC,
  favorites,
  uuid,
});

export const PHOTO_DELETE_SUC = 'photo.photoDelete.success';
export const photoPhotoDeleteSuccess = (photoId, uuid) => ({
  type: PHOTO_DELETE_SUC,
  mediaId: photoId,
  uuid,
});
export const VIDEO_DELETE_SUC = 'photo.videoDelete.success';
export const photoVideoDeleteSuccess = (videoId, uuid) => ({
  type: VIDEO_DELETE_SUC,
  mediaId: videoId,
  uuid,
});
export const MULTI_DELETE_SUC = 'photo.multiDelete.success';
export const photoMultiDeleteSuccess = (ids, uuid) => ({
  type: MULTI_DELETE_SUC,
  ids,
  uuid,
});
export const MULTI_DELETE_TAG_PHOTO_SUC = 'photo.multiDeleteTagPhoto.success';
export const photoMultiDeleteTagPhotoSuccess = (tags, uuid, media) => ({
  type: MULTI_DELETE_TAG_PHOTO_SUC,
  tags,
  uuid,
  media,
});

export const VIDEO_UPDATE_SUC = 'photo.videoUpdate.success';
export const photoVideoUpdateSuccess = (
  video, noAlbumChange, album, oldAlbums,
  uuid, albumRelMedia,
) => ({
  type: VIDEO_UPDATE_SUC,
  video,
  noAlbumChange,
  album,
  oldAlbums,
  uuid,
  albumRelMedia,
});
export const PHOTO_UPDATE_SUC = 'photo.photoUpdate.success';
export const photoPhotoUpdateSuccess = (
  photo, noAlbumChange, album, oldAlbums,
  uuid, albumRelMedia,
) => ({
  type: PHOTO_UPDATE_SUC,
  photo,
  noAlbumChange,
  album,
  oldAlbums,
  uuid,
  albumRelMedia,
});

export const MARK_PHOTO_FAVORITE_SUC = 'photo.markPhotoFavorite.success';
export const photoMarkPhotoFavoriteSuccess = (photo, uuid) => ({
  type: MARK_PHOTO_FAVORITE_SUC,
  photo,
  uuid,
});
export const MARK_VIDEO_FAVORITE_SUC = 'photo.markVideoFavorite.success';
export const photoMarkVideoFavoriteSuccess = (video, uuid) => ({
  type: MARK_VIDEO_FAVORITE_SUC,
  video,
  uuid,
});
export const MULTI_UPDATE_TAGS_SUC = 'photo.multiUpdateTags.success';
export const photoMultiUpdateTagsSuccess = (tags, uuid) => ({
  type: MULTI_UPDATE_TAGS_SUC,
  tags,
  uuid,
});

export const PHOTO_GET_PROFILE_PHOTOS_SUC = 'photo.getProfilePhotos.success';
export const photoGetProfilePhotosSuccess = photos => ({
  type: PHOTO_GET_PROFILE_PHOTOS_SUC,
  photos,
});

export const DELETE_ALBUM_EVERYTHING_SUC = 'photo.album.deleteEverything.success';
export const albumDeleteEverythingSuccess = (uuid, albumId, photoIds, videoIds) => ({
  type: DELETE_ALBUM_EVERYTHING_SUC,
  uuid,
  albumId,
  photoIds,
  videoIds,
});

export const DELETE_ALBUM_ONLY_SUC = 'photo.album.deleteAlbumOnly.success';
export const albumDeleteAlbumOnlySuccess = (uuid, albumId) => ({
  type: DELETE_ALBUM_ONLY_SUC,
  uuid,
  albumId,
});
