import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CloseButton, LoadingSpinner } from '../../../UxCommon';
import Album from '../SelectAlbumDialog/Album/Album';

class MoveAlbumDialog extends PureComponent {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    albums: PropTypes.array.isRequired,
    onAlbumClick: PropTypes.func.isRequired,
  }

  state = {
    scrollTop: 0,
  };

  componentWillMount() {
    this.scrollElement = document.getElementById('uxProfileRight');
    // this.scrollElement.addEventListener('scroll', this.onScroll);
    this.saveScrollTop(this.scrollElement.scrollTop);
  }

  getPaddingTop = () => {
    const { albums } = this.props;
    const { scrollTop } = this.state;
    if (scrollTop > 272) {
      return scrollTop - 236;
    }
    if (albums.length >= 4) {
      return 36;
    }
    return 115;
  }

  saveScrollTop = (scrollTop) => {
    this.setState({
      scrollTop,
    });
  };

  render() {
    const {
      onCloseClick,
      loading,
      loadingMsg,
      albums,
      onAlbumClick,
    } = this.props;
    return (
      <div
        className="Photos_SelectAlbumDialog_Backdrop"
        style={{ paddingTop: `${this.getPaddingTop()}px` }}
      >
        <div className="Photos_SelectAlbumDialog MoveAlbumDialogContainer">
          <CloseButton onClick={onCloseClick} size={10} style={{ top: 22, right: 18 }} />
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
          <div className="Photos_SelectAlbumDialog_Title" style={{ textAlign: 'left' }}>
            Move selected media to album ...
          </div>
          <div className="Photos_SelectAlbumDialog_Albums">
            {
              albums.map(album => (
                <Album
                  key={album.id}
                  album={album}
                  onClick={onAlbumClick}
                />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default MoveAlbumDialog;
