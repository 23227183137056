import { asyncStartAction } from '../../ui/async';

export const SCHOOL_AWARD_LOGO = "SCHOOL_AWARD_LOGO";
export const SCHOOL_AWARD_LOGO_RESULT = "SCHOOL_AWARD_LOGO_RESULT";

export const  getSchoolAwardLogo = schoolIds => (dispatch) => {
  dispatch(asyncStartAction(SCHOOL_AWARD_LOGO));
  dispatch({
    type: SCHOOL_AWARD_LOGO,
    schoolIds,
  });
}

export const schoolAwardLogo = ( schoolAwardLogo ) => dispatch => {
  dispatch(asyncStartAction(SCHOOL_AWARD_LOGO_RESULT));
  dispatch({
    type: SCHOOL_AWARD_LOGO_RESULT,
    schoolAwardLogo,
    dispatch
  })
}
