import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import Urls from '../../../../config/urls';

export const putPhysique = (uuid, token, props = {}) =>
  ajax.put(
    Urls.profile(uuid),
    JSON.stringify(props),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  ).pipe(map(response => response.response));

export default 'physique.api.js';
