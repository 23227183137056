import { combineEpics } from 'redux-observable';
import { createUpdateEpics } from './createUpdate';
import { readEpics } from './read';

const epics = combineEpics(
    createUpdateEpics,
    readEpics,
);

export default epics;
