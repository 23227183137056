import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import Component from '../../components/UxEditProfile/VanityUrl/VanityUrl';
import {getAuthenticatedUserProfile, getProfile} from '../UxCommon/Utils';
import { routeChangedAlert } from '../../store/actions/ui/routes';
import { vanityUrlFormInit, updateVanityUrl, createVanityUrl } from '../../store/actions/ui/uxEditProfile/vanityUrl';

const mapStateToProps = (state, ownProps) => {
  const { canEditProfile } = state.ui.app.context;
  const isCoach = canEditProfile && canEditProfile.isCoach;
  return ({
    profile: isCoach ? getProfile(state, ownProps.match) : getAuthenticatedUserProfile(state),
    loading: !!state.ui.uxEditProfile.vanityUrl.loadingMsg,
    loadingMsg: state.ui.uxEditProfile.vanityUrl.loadingMsg,
    rrfForm: state.forms.forms.vanityUrl,
    formNeedsInit: state.ui.uxEditProfile.vanityUrl.formNeedsInit,
    message: state.ui.uxEditProfile.vanityUrl.message,
    state,
  });
}

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    submit: () => {
      const hasPrevValue = !!(stateProps.profile.instagram);
      if (newStateProps.profile.instagram) {
        dispatch(updateVanityUrl(newStateProps.rrfForm.url.value, hasPrevValue));
      } else {
        dispatch(createVanityUrl(newStateProps.rrfForm.url.value, hasPrevValue));
      }
    },
    formInit: () => {
      dispatch(actions.reset('forms.vanityUrl'));
      dispatch(actions.change('forms.vanityUrl.url', stateProps.profile.instagram));
      dispatch(vanityUrlFormInit());
    },
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
  };
};


const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
