import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Icon from '../Icon/Icon';
import LaserImg from '../../../assets/images/Laser_v1.png';
import { expandedPhotoDialogTo, Routes } from '../../../store/actions/ui/routes';
import { Link } from 'react-router-dom';
import { LinkButton } from '../../UxCommon';
import { SubbankCodes } from "../../../modules/CoachWorld/+store";
import { renderLeaderboardColoumLogo } from '../../../modules/CoachWorld/Leaderboard/CellContent/CellLogo';
import dahsrBlueLogo from '../../../assets/images/DashrBlue.png'
class BarGraph extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    isVerified: PropTypes.bool,
    startingGraphIdx: PropTypes.number,
    onPreviousGraph: PropTypes.func,
    onNextGraph: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.labelsRef = React.createRef();
  }

  state = {
    labelsHeight: 0,
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onMount();
  }

  onMount = () => {
    if (this.labelsRef.current) {
      // to fix a " Maximum update depth exceeded" bug on android where the
      // size of the labels is changing by a pixel constantly
      if (Math.abs(this.state.labelsHeight - this.labelsRef.current.scrollHeight) > 10) {
        this.setState({
          labelsHeight: this.labelsRef.current.scrollHeight,
        });
      }
    }
  };

  render() {
    const {
      data,
      className,
      style,
      isVerified,
      startingGraphIdx,
      onPreviousGraph,
      onNextGraph,
      currentUuid,
      videoClick,
      match,
      ...props
    } = this.props;
    const { labelsHeight } = this.state;
    let axes = [];
    let y = Math.round(data.yAxis.start * 1000) / 1000;
    while (y <= data.yAxis.end) {
      axes.push(y);
      y += data.yAxis.interval;
      y = Math.round(y * 1000) / 1000;
    }
    axes = axes.reverse();
    const range = axes[0] - axes[axes.length - 1];
    const axisHeight = 40;
    const totalHeight = (axes.length - 1) * axisHeight;
    // console.log('range: ', range, ' totalHeight: ', totalHeight);
    // console.log("data.bars ", data.bars)
    return (
      <div
        className={['UxCommon_BarGraph', className || ''].join(' ')}
        style={{ ...(style || {}), paddingBottom: `${labelsHeight}px` }}
        {...props}
      >
        <div className="axes">
          {
            axes.map((axis, index) => (
              <div key={`axis${index}`} className="UxCommon_BarGraph_Axis" style={{ height: axisHeight }}>
                <div className="UxCommon_BarGraph_AxisValueWrapper">
                  <div className="UxCommon_BarGraph_AxisValue">
                    {axis}
                  </div>
                </div>
                <div className="UxCommon_BarGraph_AxisLine" />
              </div>
            ))
          }
        </div>

        <div
          className="UxCommon_BarGraph_Bars"
          style={{ paddingBottom: `${labelsHeight}px` }}
        >
          {
            data.bars.map((bar, index) => (
              (typeof bar.value === 'number') ?
                (<div key={`bar-${bar.value}-${index}`}>
                  <div className="UxCommon_BarGraph_Icons_Wrapper">
                    <div style={bar.result.stdTestSubbanksCode === SubbankCodes.TRACKMANHITTING ||
                    bar.result.stdTestSubbanksCode === SubbankCodes.TRACKMANPITCHING ||
                      bar.videoId ? {marginBottom:0} : {marginBottom:5}}>
                    {renderLeaderboardColoumLogo(bar.result.stdTestSubbanksCode, !!bar.result, bar.result.stdTestCategoryCode,Routes.playerDataGraph,"white","black","right")}
                    {
                      !!bar.result.stdTestCategory.isDashr && 
                      <div className="player_data_dashr_label" >
                        <img 
                          className='player_data_dashr_logo' src={dahsrBlueLogo} alt="Dashr Laser Verified"
                          height={18} width={40}
                          data-tip="Dashr Laser Verified" data-place={"right"}
                          data-background-color="black" data-text-color="white" 
                          data-place="bottom" 
                        />
                        <ReactTooltip />
                      </div>
                    }
                    </div>
                    {
                      bar.isLaser && !(!!bar.result.stdTestCategory.isDashr) &&
                        <div className="Profile_Performance_Laser" >
                          <img src={LaserImg} alt="Laser verified" data-tip="Laser verified" data-place={"right"} width="18px" />
                          <ReactTooltip />
                        </div>
                    }
                    {/* {
                      bar.isVerified &&
                        <div className="PlayerData_StatsTable_CellWithComment bargraph">
                          <Icon
                            iconName="ok-circled"
                            className={`UxCommon_BarGraph_Verified_Icon bar${(index % 4) + 1}`}
                          />
                          <span
                            className="PlayerData_StatsTable_CellComment left coach-verified"
                          >
                            Coach Verified
                          </span>
                        </div>
                    } */}
                    {
                      bar.videoId &&
                        <Link 
                        to={bar.processed ? expandedPhotoDialogTo(currentUuid, false, bar.videoId,match.params.sport, match.params.schoolId): null }
                        className="PlayerData_StatsTable_CellWithComment bargraph">
                          <Icon
                          iconName="play-circle2"
                          style={{color:"red", fontSize:30}}
                          />
                          <span
                          className="PlayerData_StatsTable_CellComment right coach-verified"
                          >
                          {bar.processed ? "Play video": "Processing... Please Wait."}
                        </span>
                        </Link>
                    }
                  </div>
                  <div
                    className={`UxCommon_BarGraph_Bar bar${(index % 4) + 1}`}
                    style={{
                      height: ((bar.value / range) * totalHeight),
                    }}
                  />
                </div>
                )
                :
                bar.value(index)
            ))
          }
        </div>
        <div className="UxCommon_BarGraph_Labels" ref={this.labelsRef}>
          {
            data.bars.map((bar, index) => (
              <div
                key={`label-${bar.value}-${index}`}
                className="UxCommon_BarGraph_Label"
              >
                {bar.label}
              </div>
            ))
          }
          {/* <div style={{ flexGrow: 1, marginLeft: "5%" }}> */}
                  {/* <LinkButton 
                  iconName="videocam" 
                  onClick={videoClick} 
                  type="button" 
                  className={"UxPlayerData_VideoButton"}
                  style={{fontSize:40, color: "black" }} /> */}
              {/* </div> */}
        </div>
        {
          data && data.totalBars > 2 && (
            <div className="UxCommon_BarGraph_Navigator">
              {
                startingGraphIdx > 1 && (
                  <button onClick={onPreviousGraph} className="UxCommon_BarGraph_NavigatorBtn UxCommon_BarGraph_Back">
                    <Icon iconName="back-arrow" />
                  </button>
                )
              }
              {
                startingGraphIdx < data.totalBars - 1 && (
                  <button onClick={onNextGraph} className="UxCommon_BarGraph_NavigatorBtn UxCommon_BarGraph_Next">
                    <Icon iconName="back-arrow" />
                  </button>
                )
              }
              
            </div>
          )
        }
         
      </div>
    );
  }
}

export default BarGraph;
