import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonIconOnly } from '../..';
import Icon from '../../Icon/Icon';

class QualityWidget extends PureComponent {
  static propTypes = {
    currentLevel: PropTypes.number.isRequired,
    levels: PropTypes.array.isRequired,
    onSetLevel: PropTypes.func.isRequired,
    onOpenQuality: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.wasOpen = false;
    this.mouseIn = false;
  }

  state = {
    isOpen: false,
  };

  componentDidUpdate() {
  }

  onMouseEnter = () => {
    this.mouseIn = true;
  }

  onMouseLeave = () => {
    this.mouseIn = false;
  }


  render() {
    const { levels, currentLevel, onSetLevel, onOpenQuality } = this.props;
    return (
          <div className="VideoPlayer_SettingsWidget">
            <button
              className="VideoPlayer_BackButton"
              onClick={() => onOpenQuality(false)}
              style={{ borderBottomWidth: 1, borderBottomColor: 'white' }}
              >
            <div className="VideoPlayer_BackButton_Left">
              <Icon iconName="chevron-left"/>
            </div>
            <div className="VideoPlayer_BackButton_Left">
              Back
            </div>
          </button>
          {
            levels.map((level, index) => (
              level && level.height && 
                <button
                  key={index}
                  className="VideoPlayer_ButtonWithIcon"
                  style={{ fontWeight: currentLevel === index ? 'bold' : 'normal', fontSize: currentLevel === index ? 15 : 12 }}
                  onClick={() => onSetLevel(index)}
                >
                  {level.height + 'p'}
                </button>
            ))
          }
        </div>
    );
  }
}

export default QualityWidget;
