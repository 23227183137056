import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner} from '../../../UxCommon';
import NoneYet from '../NoneYet/NoneYet';
import CombineRecords from '../CombineRecords/CombineRecords';
import Icon from '../../../UxCommon/Icon/Icon';

export const bank = {
  PERF :"PERF",
  HITTING : "HITTING",
  PITCHING : "PITCHING",
  STRENGTH : "STRENGTH",
  STATS:  "STATS"
};
class ProfileStats extends PureComponent {
  static propTypes = {
    userStats: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    stdTables: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    currentSport: PropTypes.object.isRequired,
  }

  componentDidMount() {
  }

  render() {
    const {
      loading,
      loadingMsg,
      personalRecords,
      currUuid,
      videoData,
      selectedUserSport,
      match
    } = this.props;
    const showRecord = personalRecords && [...new Set(personalRecords.map(record => record.stdTestBanksCode))];
    const filterShowRecord = showRecord && showRecord.filter(s => s===bank.PERF || s===bank.STRENGTH || s===bank.PITCHING || s===bank.HITTING)
    if(filterShowRecord && filterShowRecord.length!==0){
      filterShowRecord.sort((record1, record2) => (record1 > record2) - (record1 < record2))
      if( filterShowRecord.length > 1 && filterShowRecord[0] === bank.HITTING && filterShowRecord[1] === bank.PERF){
        let tempRecord = filterShowRecord[0];
        filterShowRecord[0] = filterShowRecord[1];
        filterShowRecord[1] = tempRecord;
      }
    }

    return (
      <div className="PlayerProfile_Stats">
        <div style={{ marginBottom: '20px' }} />
        <div className="PlayerProfile_Stats_Container" style={{ minHeight: (loading ? '300px' : '0px') }}>
          <LoadingSpinner loading={loading} loadingMsg={loadingMsg} />
           {
            filterShowRecord && filterShowRecord.length !==0 &&
            filterShowRecord.map((banks,i) => <div key={`${banks}${i}`}>
              <div style={{display:"flex"}}>
                  <div className="PlayerProfile_CombineRecords mb-5">
                    {banks === bank.PERF ? 'PERFORMANCE' : banks}
                  </div>
              </div>
              <div className="PlayerProfile_CombineRecord_Container">
                {selectedUserSport && <CombineRecords
                isCombineRecords={true}
                videoData={videoData}
                classNameCombine={'PlayerProfile_Combine'}
                currentUuid={currUuid}
                banks={banks}
                match={match}
                sportId={selectedUserSport.sportId}
                personalRecords={personalRecords} />}
              </div>
            </div>)
          }
          {
            personalRecords.length === 0 && <div className="PlayerProfile_Empty_CombineRecords">
              <NoneYet msg="No combine record have been entered yet" />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ProfileStats;
