import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import ControlErrors from '../ControlErrors';
import {formatPhone} from "../../../../utils";

class PhoneControlComponent extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    myOnChange: PropTypes.func,
    myOnBlur: PropTypes.func,
    errors: PropTypes.array,
    help: PropTypes.any,
    defaultValue: PropTypes.any,
    defaultCountry:PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.id = `${Math.floor(Math.random() * 100000)}`;
  }

  state = {
    value: '',
    defaultValue: '',
  }

  componentDidUpdate() {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
      && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined
      && defaultValue !== this.state.defaultValue) {
      this.setDefault(defaultValue);
    }
  }

  setDefault = (defaultValue) => {
    const { myOnChange } = this.props;
    let value = defaultValue;
    if (myOnChange) {
      value = myOnChange(value);
    }
    this.setState({
      defaultValue,
      value,
    });
  }

  handleChange = (phone, data, event, formattedValue) => {
    const { myOnChange } = this.props;
    let value = '';
    if (myOnChange) {
      value = myOnChange(phone, data, event, formattedValue);
    }
    this.setState({ value: value || phone });
  }

  handleBlur = (phone, data, event, formattedValue) => {
    const { myOnBlur } = this.props;
    if (myOnBlur) {
      let value = '';
      value = myOnBlur(phone, data, event, formattedValue);
      this.setState({ value: value || phone });
    }
  }

  render() {
    const {
      className,
      label,
      errors,
      help,
      myOnChange,
      myOnBlur,
      defaultValue,
      defaultCountry,
      disabled,
      ...inputProps
    } = this.props;
    const { value } = this.state;
    const country = defaultCountry || 'us';
    return (
      <div className={`SimpleTextControl_Group ${className}`}>
        <label className="SimpleTextControl_Label" htmlFor={this.id}>{label}</label>
        { defaultValue && defaultValue.length > 0 ?
          <PhoneInput
            dropdownStyle={{color: 'black'}}
            inputClass="SimpleTextControl customPhoneControl"
            value={formatPhone(value)}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onlyCountries={['us','ca', 'za']}
            preserveOrder = {['onlyCountries']}
            priority={{ca: 1, us: 0}}
            inputProps={{ ...inputProps }}
            disableDropdown = {disabled}
          />
        :
          <PhoneInput
            dropdownStyle={{color: 'black'}}
            inputClass="SimpleTextControl customPhoneControl"
            country={country}
            value={value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onlyCountries={['us','ca', 'za']}
            preserveOrder = {['onlyCountries']}
            priority={{ca: 1, us: 0}}
            inputProps={{ ...inputProps }}
            disableDropdown = {disabled}
          />
        }
        {!!help && help}
        <ControlErrors errors={errors} style={{ marginTop: 3 }} />
      </div>
    );
  }
}

export default PhoneControlComponent;
