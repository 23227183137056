import { combineEpics, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { getTokenFragment, manualCancelApiCallFragment } from "../../../../store/actions/ui/uxProfile/utils";
import { asyncFinishAction } from "../../../../store/actions/ui/async";
import { NationalLeaderboardRosters } from "./allTimeLeaderboard.models";
import { Routes } from "../../../../store/actions/ui/routes";
import {
  GET_ALL_TIME_LEADERBOARD_GRAD_YEARS,
  GET_ALL_TIME_LEADERBOARD_RESULTS,
  GET_ALL_TIME_LEADERBOARD_SPORTS,
  GET_ALL_TIME_LEADERBOARD_TEMPLATE
} from "./allTimeLeaderboard.actions";
import {
  apiGetAllTimeLeaderboardGradYears,
  apiGetAllTimeLeaderboardResults,
  apiGetAllTimeLeaderboardSports,
  apiGetAllTimeLeaderboardTemplate
} from "./allTimeLeaderboard.api";
import { CombineTemplate } from "./allTimeLeaderboard.models";

const getAllTimeLeaderboardTemplateEpic = action$ => (
  action$.pipe(
    ofType(GET_ALL_TIME_LEADERBOARD_TEMPLATE),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetAllTimeLeaderboardTemplate(token, action.schoolId, action.sportId).pipe(manualCancelApiCallFragment(
        action$,
        action,
        GET_ALL_TIME_LEADERBOARD_TEMPLATE
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const template = CombineTemplate.fromApi(result.response);
        const categories = result.response;
        return of(asyncFinishAction(result.action.type, GET_ALL_TIME_LEADERBOARD_TEMPLATE, {
          template,
          categories
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  )
);

const getAllTimeLeaderboardSportsEpic = action$ => (
  action$.pipe(
    ofType(GET_ALL_TIME_LEADERBOARD_SPORTS),
    getTokenFragment(),
    switchMap(({ action }) => (
      apiGetAllTimeLeaderboardSports(action.schoolId).pipe(manualCancelApiCallFragment(
        action$,
        action,
        GET_ALL_TIME_LEADERBOARD_SPORTS
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, GET_ALL_TIME_LEADERBOARD_SPORTS, {
          sports: result.response
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  )
);


const getAllTimeLeaderboardGradYearsEpic = action$ => (
  action$.pipe(
    ofType(GET_ALL_TIME_LEADERBOARD_GRAD_YEARS),
    getTokenFragment(),
    switchMap(({ action }) => (
      apiGetAllTimeLeaderboardGradYears(action.schoolId).pipe(manualCancelApiCallFragment(
        action$,
        action,
        GET_ALL_TIME_LEADERBOARD_GRAD_YEARS
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        return of(asyncFinishAction(result.action.type, GET_ALL_TIME_LEADERBOARD_GRAD_YEARS, {
          gradYears: result.response
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  )
);


const getAllTimeLeaderboardResultsEpics = (action$, state$) => (
  action$.pipe(
    ofType(GET_ALL_TIME_LEADERBOARD_RESULTS),
    getTokenFragment(),
    switchMap(({ action, token }) => (
      apiGetAllTimeLeaderboardResults(token, action).pipe(manualCancelApiCallFragment(
        action$,
        action,
        GET_ALL_TIME_LEADERBOARD_RESULTS,
        Routes.allTimeLeaderboard
      ))
    )),
    switchMap((result) => {
      if (result.success) {
        const { loadMore, sortBy } = result.action;
        const { categories } = state$.value.modules.coachWorld.allTimeLeaderboard;
        let newRosters = NationalLeaderboardRosters.fromApi(
          result.response[0].result.data,
          categories,
          categories.find(category => category.standard_test_category_id === sortBy)
        );
        if (loadMore) {
          const { rosters } = state$.value.modules.coachWorld.allTimeLeaderboard.results;
          if (result.action.page > 1) {
            newRosters = [...rosters, ...newRosters];
          }
        }

        const showMore = result.response[0].result.to < result.response[0].result.total;

        return of(asyncFinishAction(result.action.type, GET_ALL_TIME_LEADERBOARD_RESULTS, {
          rosters: newRosters,
          showMore,
          eventAverage: result.response.eventAverage,
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    })
  )
);

export default combineEpics(
  getAllTimeLeaderboardResultsEpics,
  getAllTimeLeaderboardTemplateEpic,
  getAllTimeLeaderboardSportsEpic,
  getAllTimeLeaderboardGradYearsEpic,
);
