import { combineReducers } from 'redux';
import { ASYNC_FINISH } from '../../actions/ui/async';
import { GET_COACH_SCHOOLS } from '../../actions/ui/uxProfile';

const coachSchool = (state = null, action) => {
  switch (action.type) {
    case ASYNC_FINISH:
      if(action.model === GET_COACH_SCHOOLS){
        return action.data
      }
    default:
      return state;
  }
};

const reducer = combineReducers({
  coachSchool
});

export default reducer;