import React from 'react';
import PropTypes from 'prop-types';
import CombineTitle from './CombineTitle/CombineTitle.component';
import CombineCard from './CombineCard/CombineCard.component';

const CombineControl = ({
  title, onClickNew, onClickUpdate, combineList, athleteCombineResults,
  showEditDialog, onClickTemplates, updateCombine,recordNotFoundMsg, updateOrCreateCombineAndAwards, match
}) =>
  (
    <React.Fragment>
      <CombineTitle title={title} onClickNew={onClickNew} onClickTemplates={onClickTemplates} />
      <div className="CombineCard_Card_Container">
        {
          (combineList && combineList.length > 0) ?
            combineList.map((combine, idx) =>
              (
                <CombineCard
                  key={`${combine.type === 'report' ? `r${combine.id}-${combine.secondCombineId}` : combine.id}-${idx}`}
                  combine={combine}
                  onClickUpdate={onClickUpdate}
                  athleteCombineResults={athleteCombineResults}
                  showEditDialog={showEditDialog}
                  updateCombine={updateCombine}
                  updateOrCreateCombineAndAwards = {updateOrCreateCombineAndAwards}
                  match = {match}
                />
              ))
            :
            <div className="CombineCard_Card_ContainerNoCards">
              {recordNotFoundMsg}
            </div>
        }
      </div>
    </React.Fragment>
  );
CombineControl.propTypes = {
  title: PropTypes.string.isRequired,
  combineList: PropTypes.array.isRequired,
  onClickNew: PropTypes.func,
  onClickUpdate: PropTypes.func,
  athleteCombineResults: PropTypes.array,
  showEditDialog: PropTypes.func,
  onClickTemplates: PropTypes.func,
  updateCombine: PropTypes.func,
  recordNotFoundMsg: PropTypes.string,
  updateOrCreateCombineAndAwards: PropTypes.func
};

export default CombineControl;
