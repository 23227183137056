import React from "react";
import { School } from "../../../../store/actions/ui/schoolLogo";


const CheckoutSuccess = ({ profile, product, schoolStyle, onContinue }) => {

  let line1 = '';
  if(product.subs_type === 'monthly'){
    line1 = `Your monthly subscription of only
    $${product.displayPrice} per month for ${profile.first}’s ${schoolStyle ? schoolStyle.name : School[schoolId]} Branding `;
  }
  if(product.subs_type === 'yearly'){
    line1 = `Your yearly subscription of only
    $${product.displayPrice} per year for ${profile.first}’s ${schoolStyle ? schoolStyle.name : School[schoolId]} Branding `;
  }

  if(product.subs_type === 'every-7-months'){
    line1 = `Your one time payment of only
    $${product.displayPrice} for 7 months for ${profile.first}’s ${schoolStyle ? schoolStyle.name : School[schoolId]} Branding `;
  }

  return (
    <div className="row">
      <div className="col">
        <div className="success-msg">
          Welcome {profile.parentName},<br />
          {line1}
          &
          Data Profile account is fully
          activated! An email receipt is on its way.
          <br />
          Now, let’s invest a few minutes to complete the onboarding
          process.
          <br />
          {profile.first} will soon have a first-class athlete branding profile to
          show the world what sets them apart.
        </div>
        <div className="welcome-btns">
          <button
            className="welcome-btn"
            type="submit"
            onClick={() => onContinue()}>
            Continue Onboarding
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
