import { combineEpics, ofType } from 'redux-observable';
import { switchMap, tap, map, takeUntil, catchError, retryWhen, delay } from 'rxjs/operators';
import { of, Subscriber } from 'rxjs';

import { asyncFinishAction, asyncErrorAction } from '../../../../store/actions/ui/async';
import { manualCancelApiCallFragment, getTokenFragment, cancelOnAction } from '../../../../store/actions/ui/uxProfile/utils';
import { createPhoto, uploadPhotoToS3, flagPhotoUploaded, addMediaToAlbum, mapAlbumApiToUi, mapPhotoApiToUi, getPhoto, getAlbums, createAlbum } from '../../../../store/actions/data/photo';
import { ajaxErrorCustomHandlerFragment } from '../../../../store/actions/ui/ajaxErrorHandlers';
import { PHOTO_DROP_SCHOOL_STYLE, updateSchoolStyles, uploadProgressUpdate } from './coachWorld.actions';

export const createMediaEpicFragment = action$ => source =>
  source.pipe(
    switchMap((result) => {
      if (result.success) {
        return createPhoto(result.token, {
          caption: result.caption,
        }).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'createPhoto',
          { ...result },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          dispatch, totalBytes, mediaId, file,
        } = result.action;
        const ps = Subscriber.create(
          (e) => {
            dispatch(uploadProgressUpdate(e, totalBytes, mediaId));
          },
          e => console.log('error', e),
          () => console.log('upload complete'),
        );
        return uploadPhotoToS3(
          result.response.upload_url,
          file,
          ps,
        ).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'uploadPhoto',
          { createPhoto: result.response, ...result },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const { dispatch, totalBytes, mediaId } = result.action;
        dispatch(uploadProgressUpdate({
          total: 1,
          loaded: 1,
          lengthComputable: true,
        }, totalBytes, mediaId));
        return flagPhotoUploaded(
          result.createPhoto.id,
          result.token,
        ).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'flagPhotoUploaded',
          { uploadPhoto: result.response, ...result },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const { uuid } = result.action;
        return getAlbums(uuid, result.token).pipe(manualCancelApiCallFragment(
          action$,
          result.action,
          'getAlbums',
          { flagPhotoUploaded: result.response, ...result },
        ));
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const albums = result.response;
        let schoolAlbum = null;
        if (albums && albums.length) {
          schoolAlbum = albums.find(a => a.title === 'Shared Photos');
        }
        if (!schoolAlbum) {
          return createAlbum(result.token, {
            title: 'Shared Photos',
            description: '',
          }).pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'createAlbum',
            {
              albums,
              ...result,
              album: schoolAlbum,
            },
          ));
        }
        return of({
          albums: result.response,
          ...result,
          album: schoolAlbum,
        });
      }
      return of({ ...result });
    }),
    switchMap((result) => {
      if (result.success) {
        const {
          token,
        } = result;
        let myAlbum;
        if (result.album) {
          myAlbum = result.album;
        } else {
          myAlbum = mapAlbumApiToUi(result.response);
        }
        if (myAlbum) {
          const media = {
            photo_id: result.createPhoto.id,
            ordering: 0,
          };
          return addMediaToAlbum(
            token,
            myAlbum.id,
            result.createPhoto.id,
            media,
            true,
          ).pipe(manualCancelApiCallFragment(
            action$,
            result.action,
            'createAlbum',
            { ...result },
          ));
        }
        return of(result);
      }
      return of(result);
    }),
    switchMap((result) => {
      if (result.success) {
        const { response, ...other } = result;
        return of({ ...other, album: mapAlbumApiToUi(response) });
      }
      return of(result);
    }),
  );

export const saveSchoolStyleEpic = action$ => action$.pipe(
  ofType(PHOTO_DROP_SCHOOL_STYLE),
  getTokenFragment(),
  tap(({ action }) => action.dispatch(uploadProgressUpdate(
    null,
    action.totalBytes,
    '',
    'Uploading photo',
    true,
  ))),
  map(results => ({ ...results, success: true })),
  createMediaEpicFragment(action$),
  tap((results) => {
    if (results.success) {
      results.action.dispatch(uploadProgressUpdate(
        null,
        results.action.totalBytes,
        '',
        'Processing photo',
      ));
    }
  }),
  switchMap((results) => {
    if (results.success) {
      let retryCount = 0;
      return getPhoto(results.token, results.createPhoto.id).pipe(
        map((response) => {
          if (response.resized_photos) {
            return {
              ...results,
              media: mapPhotoApiToUi(response),
            };
          }
          retryCount += 1;
          const error = {
            tryAgain: true,
          };
          throw error;
        }),
        ajaxErrorCustomHandlerFragment(),
        catchError((error) => {
          if (retryCount > 60) {
            return of({
              success: false,
              action: asyncErrorAction(
                results.action.type,
                'pollResized',
                { name: 'Timeout', desc: 'Timed out while waiting for photo to process' },
              ),
            });
          }
          if (error.tryAgain) throw error;
          return of({
            success: false,
            action: asyncErrorAction(results.action.type, 'pollResized', error),
          });
        }),
        retryWhen(errors => errors.pipe(
          delay(1000),
          tap(val => console.log('Trying again', val)),
        )),
        takeUntil(cancelOnAction(action$)),
      );
    }
    return results;
  }),
  tap((results) => {
    if (results.success) {
      results.action.dispatch(uploadProgressUpdate(
        null,
        results.action.totalBytes,
        '',
        'Saving photo',
      ));
    }
  }),
  switchMap((results) => {
    const style = results.action.schoolStyle;
    if (results.success) {
      const values = {};
      values.name = style.name;
      values.color1 = style.color1;
      values.color2 = style.color2;
      values.darkmode = style.darkmode;
      values.background_image = results.action.isBackground ?
        results.media.url : style.background_image;
      values.school_logo = !results.action.isBackground ? results.media.url : style.school_logo;
      values.leaderboard_logo = style.leaderboardLogo;
      values.leaderboard_background = style.leaderboardBackground;
      values.leaderboard_style = style.leaderboardStyle;
      return of(
        asyncFinishAction(results.action.type, null, {
          message: 'whew',
        }),
        updateSchoolStyles(style.id, values),
      );
    }
    if (results.actions) return of(...results.actions);
    return of(results);
  }),
);


export default combineEpics(saveSchoolStyleEpic);
