import { ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import moment from 'moment';

import {
  formatResult,
  sortPositions,
  sortStatPositions,
  sortStdCats,
} from '../../../../../../containers/UxCommon/Utils';
import { cancelOnRouteChange, checkForkErrors, getTokenFragment } from '../../../uxProfile/utils';
import {
  mapPlayerStatsApiToUi,
  mapSeasonsApiToUi,
  mapSportsApiToUi,
  mapStandardStatsApiToUi,
  mapStdPositionsApiToUi,
  mapStdStatCategoriesApiToUi,
  mapStdStatPositionsApiToUi,
  sportGetSports,
  statsGetStandardSuccess,
  statsGetUserStatsSuccess,
  statsPlayerStatsGet,
  statsSeasonsGet,
  statsStandardFormatsGet,
  statsStandardPositionsGet,
  statsStandardStatCategoriesGet,
  statsStandardStatPositionsGet,
  statsStdStatsGet,
  statsTeamsGet,
} from '../../../../data/sport';
import { Routes } from '../../../routes';

import { asyncErrorAction, asyncFinishAction } from '../../../async';

import { PD_GET_STATS } from '../actions';
import { ajaxErrorHandlerEpicFragment } from '../../../ajaxErrorHandlers';
import { mapTeamsApiToUi } from '../../../../data/sport/teams';

const organizeStatsForDataPage = (teams, playerStats, statsStd, sportId, seasons) => {
  let teamTable;
  let positionTable;
  let seasonTable;
  const tables = [];
  teams.forEach((team) => {
    const statsByTeam = playerStats
      .filter(ps => ps.season.team.id === team.id);
    if (statsByTeam.length) {
      teamTable = {
        team,
        positions: [],
      };
      tables.push(teamTable);
    }
    const { positions } = statsStd[sportId];
    positions.forEach((position) => {
      const statsByPosition = statsByTeam
        .filter(ps => ps.stdPosition.id === position.id);
      if (statsByPosition.length) {
        positionTable = {
          position,
          seasons: [],
          totals: {},
        };
        teamTable.positions.push(positionTable);
      }
      seasons.forEach((season) => {
        const statsBySeason = statsByPosition
          .filter(ps => ps.season.id === season.id);
        if (statsBySeason.length) {
          seasonTable = {
            season,
            categories: [],
          };
          positionTable.seasons.push(seasonTable);
        }
        const { categories } = statsStd[sportId].tables[position.id];
        categories.forEach(({ category }) => {
          const statsByCat = statsBySeason
            .filter(ps => ps.stdCategory.id === category.id);
          if (statsByCat.length) {
            seasonTable.categories.push({
              category,
              stats: statsByCat,
            });
            statsByCat.forEach((stat) => {
              if (positionTable.totals[stat.stdStatPosition.id]) {
                positionTable.totals[stat.stdStatPosition.id] += stat.results;
              } else {
                positionTable.totals[stat.stdStatPosition.id] = stat.results;
              }
            });
          }
        });
      });
    });
  });
  return tables;
};

const organizeStatsForProfile = (playerStats, statsStd, sportId, seasons) => {
  let positionTable;
  let seasonTable;
  const tables = [];
  const { positions } = statsStd[sportId];
  positions.forEach((position) => {
    const statsByPosition = playerStats
      .filter(ps => ps.stdPosition.id === position.id && ps.onCard === 1);
    if (statsByPosition.length) {
      positionTable = {
        position,
        seasons: [],
        totals: {},
      };
      tables.push(positionTable);
    }
    const idsToAverage = {};
    const totalsInfo = {};
    seasons.forEach((season) => {
      const statsBySeason = statsByPosition
        .filter(ps => ps.season.id === season.id);
      if (statsBySeason.length) {
        seasonTable = {
          season,
          categories: [],
        };
        positionTable.seasons.push(seasonTable);
      }
      const { categories } = statsStd[sportId].tables[position.id];
      categories.forEach(({ category }) => {
        const statsByCat = statsBySeason
          .filter(ps => ps.stdCategory.id === category.id);
        if (statsByCat.length) {
          seasonTable.categories.push({
            category,
            stats: statsByCat,
          });
          statsByCat.forEach((stat) => {
            const val = stat.results;
            const { statFormatCode } = stat;
            const statPosId = stat.stdStatPosition.id;

            // Calculate the totals based on stat.totalType
            // sum, average, max, min, or latest(season.startDate)
            switch (stat.totalType) {
              case 'sum':
                if (positionTable.totals[statPosId]) {
                  positionTable.totals[statPosId] += val;
                } else {
                  positionTable.totals[statPosId] = val;
                  totalsInfo[statPosId] = {
                    format: statFormatCode,
                  };
                }
                break;
              case 'average':
                if (positionTable.totals[statPosId]) {
                  positionTable.totals[statPosId] += val;
                  idsToAverage[statPosId].divideBy += 1;
                } else {
                  positionTable.totals[statPosId] = val;
                  idsToAverage[statPosId] = {
                    places: 2,
                    mult: 1,
                    divideBy: 1,
                  };
                  totalsInfo[statPosId] = {
                    format: statFormatCode,
                  };
                }
                break;
              case 'max':
                if (positionTable.totals[statPosId]) {
                  if (val > positionTable.totals[statPosId]) {
                    positionTable.totals[statPosId] = val;
                  }
                } else {
                  positionTable.totals[statPosId] = val;
                  totalsInfo[statPosId] = {
                    format: statFormatCode,
                  };
                }
                break;
              case 'min':
                if (positionTable.totals[statPosId]) {
                  if (val < positionTable.totals[statPosId]) {
                    positionTable.totals[statPosId] = val;
                  }
                } else {
                  positionTable.totals[statPosId] = val;
                  totalsInfo[statPosId] = {
                    format: statFormatCode,
                  };
                }
                break;
              case 'latest':
                if (positionTable.totals[statPosId]) {
                  const valDate = moment(stat.season.startDate);
                  if (valDate.isAfter(totalsInfo[statPosId].latest)) {
                    positionTable.totals[statPosId] = val;
                    totalsInfo[statPosId].latest = valDate;
                  }
                } else {
                  const valDate = moment(stat.season.startDate);
                  positionTable.totals[statPosId] = val;
                  totalsInfo[statPosId] = {
                    format: statFormatCode,
                    latest: valDate,
                  };
                }
                break;
              default:
                positionTable.totals[statPosId] += val;
                break;
            }
          });
        }
      });
    });

    Object.entries(idsToAverage).forEach(([id, options]) => {
      positionTable.totals[id] = formatResult(((positionTable.totals[id] /
        options.divideBy) *
        options.mult), options.statFormatCode);
    });
    Object.entries(totalsInfo).forEach(([id, options]) => {
      // console.log('info', id, options);
      positionTable.totals[id] = formatResult(positionTable.totals[id], options.format);
    });
  });

  return tables;
};

export const getPlayerStatsEpicFragment = action$ => source =>
  source.pipe(switchMap((incoming) => {
    if (incoming.success) {
      const {
        statsStd,
        uuid,
        sportId,
        token,
      } = incoming;
      return forkJoin(
        statsSeasonsGet(uuid, sportId).pipe(
          map(response => ({ success: true, ...mapSeasonsApiToUi(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getSeasons', error),
          })),
        ),
        statsPlayerStatsGet(uuid, sportId, token).pipe(
          map(response => ({
            success: true,
            rawPlayerStats: response,
          })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getPlayerStats', error),
          })),
        ),
        statsTeamsGet().pipe(
          map(response => ({ success: true, allTeams: mapTeamsApiToUi(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getTeams', error),
          })),
        ),
      ).pipe(
        checkForkErrors(),
        map((results) => {
          if (results.success) {
            const { seasons, teams } = results.results[0];
            const { rawPlayerStats } = results.results[1];
            const { allTeams } = results.results[2];
            const { categories } = statsStd[sportId];
            const { statPositions } = statsStd[sportId];
            return {
              success: true,
              seasons,
              teams,
              allTeams,
              playerStats: mapPlayerStatsApiToUi(
                uuid,
                categories,
                statPositions,
                seasons,
                rawPlayerStats,
              ),
            };
          }
          return results;
        }),
        map((results) => {
          if (results.success) {
            const {
              seasons,
              teams,
              allTeams,
              playerStats,
            } = results;
            const userStats = {};
            userStats[uuid] = {};
            // Organize the data once and for all here
            userStats[uuid][sportId] = {
              tables: organizeStatsForDataPage(teams, playerStats, statsStd, sportId, seasons),
              profile: organizeStatsForProfile(playerStats, statsStd, sportId, seasons),
              teams,
              playerStats,
            };
            return {
              success: true,
              userStats,
              allTeams,
              ...incoming,
            };
          }
          return results;
        }),
      );
    }
    return of(incoming);
  }));

export const playerStatsGetEpic = action$ => (
  action$.pipe(
    ofType(PD_GET_STATS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      forkJoin(
        statsStandardPositionsGet(action.sportId).pipe(
          map(response => ({ success: true, positions: mapStdPositionsApiToUi(response) })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getStdPositions', error),
          })),
        ),
        statsStandardStatCategoriesGet(action.sportId).pipe(
          map(response => mapStdStatCategoriesApiToUi(response)),
          map(categories => ({ success: true, categories })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getStdStatCategories', error),
          })),
        ),
        statsStdStatsGet(action.sportId).pipe(
          map(response => ({ success: true, rawStats: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getStdStats', error),
          })),
        ),
        statsStandardStatPositionsGet(action.sportId).pipe(
          map(response => ({ success: true, rawStatPositions: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction(PD_GET_STATS, 'getStdStatPositions', error),
          })),
        ),
        sportGetSports(token).pipe(
          map(response => ({ success: true, sports: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction('playerData.tests.stdSports', 'get', error),
          })),
        ),
        statsStandardFormatsGet().pipe(
          map(response => ({ success: true, standardStatFormats: response })),
          takeUntil(cancelOnRouteChange(action$, Routes.data, () => action.uuid)),
          ajaxErrorHandlerEpicFragment(),
          catchError(error => of({
            success: false,
            action: asyncErrorAction('playerData.tests.stdSports', 'get', error),
          })),
        ),
      ).pipe(
        checkForkErrors(),
        map((results) => {
          if (results.success) {
            const { positions } = results.results[0];
            const { categories } = results.results[1];
            const stats = mapStandardStatsApiToUi(results.results[2].rawStats, categories);
            const statPositions = mapStdStatPositionsApiToUi(
              stats,
              positions,
              results.results[3].rawStatPositions,
            );
            const sports = mapSportsApiToUi(results.results[4].sports);
            const standartFormats = results.results[5].standardStatFormats._embedded;
            return {
              success: true,
              positions,
              stats,
              categories,
              statPositions,
              sports,
              standartFormats,
              outer: action,
              token,
            };
          }
          return results;
        }),
      )),
    switchMap((results) => {
      if (results.success) {
        const {
          positions,
          categories,
          statPositions,
          stats,
          outer,
          standartFormats,
        } = results;

        // Organize the data once and for all here
        const std = {};
        const sportObject = {
          tables: {},
          categories,
          statPositions,
          stats,
          positions,
        };
        positions.sort(sortPositions).forEach((p) => {
          const posObject = {
            position: p,
            categories: [],
          };
          categories.sort(sortStdCats).forEach((c) => {
            const stdPoss = statPositions.filter(sp =>
              ((sp.stdPosition.id === p.id)
                && (sp.stdStat.stdCategory.id === c.id)));
            if (stdPoss.length) {
              posObject.categories.push({
                category: c,
                statPositions: stdPoss.sort(sortStatPositions),
              });
            }
          });
          sportObject.tables[p.id] = posObject;
        });
        std[outer.sportId] = sportObject;
        return of({
          success: true,
          statsStd: std,
          standartFormats,
          uuid: outer.uuid,
          sportId: outer.sportId,
          token: results.token,
          outer,
        });
      }
      return of(results);
    }),
    getPlayerStatsEpicFragment(action$),
    switchMap((results) => {
      if (results.success) {
        return of(
          statsGetStandardSuccess(results.statsStd, results.standartFormats),
          statsGetUserStatsSuccess(
            results.uuid,
            results.sportId,
            results.userStats,
            results.allTeams,
          ),
          asyncFinishAction(PD_GET_STATS, 'get', {}),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);
