import {formatResult} from '../../../../containers/UxCommon/Utils';

export const BankCodes = {
  HITTING: 'HITTING',
  PITCHING: 'PITCHING',
  COACHEVAL: 'COACHEVAL',
  PERFORMANCE: 'PERF',
  STRENGTH: 'STRENGTH',
  STATS: 'STATS'
}

export const BankSlugs = {
  PERF: 'performance',
  STRENGTH: 'strength',
  PHYSIQUE: 'measureables',
  HITTING: 'hitting',
  PITCHING: 'pitching',
  VC_SCORE: 'vcscore',
  COACHEVAL: 'coach eval',
  STATS: 'stats'
};

export const BANKORDER = ['PERF', 'STRENGTH', 'COACHEVAL', 'HITTING', 'PITCHING', 'STATS', 'PHYSIQUE'];

export const BankSlugToLabel = {
  performance: 'Performance',
  strength: 'Strength',
  measureables: 'Measureables',
  vcscore: 'VC Score',
  hitting: 'Hitting',
  pitching: 'Pitching',
  coacheval: 'Coach Eval',
  stats: 'Stats'
};

export const BankSlugToApi = {
  performance: 'perf',
  strength: 'strength',
  measureables: 'physique',
  hitting: 'hitting',
  pitching: 'pitching',
  vcscore: 'vc_score',
  coacheval: 'coach eval',
  stats: 'stats'
};

export const BankLabels = {
  PERF: 'Performance',
  STRENGTH: 'Strength',
  PHYSIQUE: 'Measureables',
  HITTING: 'Hitting',
  PITCHING: 'Pitching',
  COACHEVAL: 'Coach Eval',
  STATS: 'STATS'
};

export const ShouldUpdate = {
  DashboardCombine: 'DashboardCombine',
  CombinePerf: 'Performance',
  CombineAwards: 'CombineAwards',
  SchoolTeamList: 'SchoolTeamList',
};

export const CombineSortByLabels = {
  numberLowHigh: 'Jersey 1-2-3',
  numberHighLow: 'Jersey 3-2-1',
  firstNameAZ: 'First Name A-Z',
  firstNameZA: 'First Name Z-A',
  lastNameAZ: 'Last Name A-Z',
  lastNameZA: 'Last Name Z-A',
  combineNumberLowHigh: 'Combine Number 1-2-3',
  combineNumberHighLow: 'Combine Number 3-2-1',
};

const limitDecimals = (val) => {
  if (String(val).includes('.')) {
    return Number(val).toFixed(2);
  }
  return val;
};

export const feetAndInchesMasterFormater = format => (inches) => {
  return masterFormater(format)(Number(inches))
}

export const masterFormater = format => value => (
  formatResult(value, format)
);

export const repsMasterFormater = format => value => (
  `${formatResult(value.result, format)} x ${value.reps}`
);

export const isEmpty = v => (v === null || v === undefined || v === '');

export const editMasterFormater = format => value => (
  isEmpty(value) ? '' : formatResult(value, format, false)
);

export const UnitFormats = {
  COUNT: c => `${c}`,
  'COUNT-TENTHS': c => `${c}`,
  POUNDS: lbs => `${limitDecimals(lbs)} lbs.`,
  SECONDS: s => `${limitDecimals(s)}s`,
  INCHES: i => `${Math.floor(i / 12)}-${limitDecimals(i % 12)}`,
  // INCHES: i => `${i}"`,
  FEET: i => `${Math.floor(i / 12)}-${limitDecimals(i % 12)}`,
  METERS: m => `${m} m`,
  'SPEED-MPH': m => `${m} mph`,
  'SPEED-RPM': m => `${m} rpm`,
  PERCENTAGE: p => `${p}%`,
  MINUTES: m => `${Math.floor(m / 60)}: ${limitDecimals(m % 60)}`,
  'INTEGER-INCHES': i => `${i}"`,
  'QUARTER-INCHES': i => `${i}"`,
  DEGREES: d => `${d}°`,
  'HOURS-MINUTES': h => `${h}`,
  CM: c => `${c} cm`,
  KG: k => `${k} kg`,
  MLKGMIN: m => `${m}`,
  YARD: y => `${y} yds`,
  WATT: w => `${w} w`,
};


export const DiffUnitFormats = {
  COUNT: c => `${c}`,
  POUNDS: lbs => `${limitDecimals(lbs)}`,
  SECONDS: s => `${limitDecimals(s)}`,
  INCHES: i => `${Math.floor(i / 12)}' ${limitDecimals(i % 12)}"`,
  FEET: i => `${Math.floor(i / 12)}' ${limitDecimals(i % 12)}"`,
  METERS: m => `${m} m`,
  'SPEED-MPH': m => `${m} mph`,
  'SPEED-RPM': m => `${m} rpm`,
  PERCENTAGE: p => `${p}%`,
};

export const EditFormats = {
  COUNT: c => (isEmpty(c) ? '' : `${c}`),
  POUNDS: lbs => (isEmpty(lbs) ? '' : `${lbs}`),
  SECONDS: s => (isEmpty(s) ? '' : `${s}`),
  INCHES: i => (isEmpty(i) ? '' : `${Math.floor(i / 12)}' ${i % 12}"`),
  FEET: i => (isEmpty(i) ? '' : `${Math.floor(i / 12)}' ${i % 12}"`),
  METERS: i => (isEmpty(i) ? '' : `${i}`),
  'SPEED-MPH': i => (isEmpty(i) ? '' : `${i}`),
  'SPEED-RPM': i => (isEmpty(i) ? '' : `${i}`),
  PERCENTAGE: p => (isEmpty(p) ? '' : `${p}`),
  MINUTES: m => (isEmpty(m) ? '' : `${Math.floor(m / 60)}: ${m % 60}"`),

  'INTEGER-INCHES': i => (isEmpty(i) ? '' : `${Math.floor(i / 12)}' ${i % 12}"`),
  'QUARTER-INCHES': i => (isEmpty(i) ? '' : `${Math.floor(i / 12)}' ${i % 12}"`),

};

export const UnitHelps = format => ({
  COUNT: c => (c === null ? 'Counts' : `Count (${c})`),
  POUNDS: lbs => (lbs === null ? 'Pounds' : `Pounds (${lbs} lbs.)`),
  SECONDS: (s) => {
    switch (format) {
      case 'TIME-HUNDREDTHS':
      case 'TIME-INTEGER':
      case 'TIME-TENTHS':
        return (s === null ? 'Time (01:23.4)' : `Time (${formatResult(s, format)})`);
      case 'TIME-SEC-HUNDREDTHS':
      case 'TIME-SEC-INTEGER':
      case 'TIME-SEC-TENTHS':
      default:
        return (s === null ? 'Seconds' : `Seconds (${formatResult(s, format)})`);
    }
  },
  INCHES: i => (i === null ? 'Inches (24")' : `Inches (${formatResult(i, format)}")`),
  FEET: i => (i === null ? 'Feet & Inches (1\' 6")' : `Feet & Inches (${formatResult(i, format)})`),
  METERS: i => (i === null ? 'Meters' : `Meters (${formatResult(i)})`),
  'SPEED-MPH': i => (i === null ? 'MPH' : `MPH (${formatResult(i)})`),
  'SPEED-RPM': i => (i === null ? 'RPM' : `RPM (${formatResult(i)})`),
});

export const AllowedCharacters = format => ({
  // COUNT: c => /[0-9]/.test(c),
  COUNT: (c) => {
    switch (format) {
      case 'NUMERIC-HUNDREDTHS':
        return /[0-9.]/.test(c);
      case 'NUMERIC-TENTHS':
        return /[0-9.]/.test(c);
      case 'NEGATIVE-NUMERIC-HUNDREDTHS':
        return /[-0-9.]/.test(c);
      default:
        return /[0-9]/.test(c);
    }
  },
  'COUNT-TENTHS': c => /[0-9.]/.test(c),
  POUNDS: (lbs) => {
    switch (format) {
      case 'WEIGHT-POUNDS-INTEGER':
        return /[0-9]/.test(lbs);
      default:
        return /[0-9.]/.test(lbs);
    }
  },
  SECONDS: (s) => {
    switch (format) {
      case 'TIME-INTEGER':
        return /[0-9:]/.test(s);
      case 'TIME-TENTHS':
      case 'TIME-HUNDREDTHS':
        return /[0-9.:]/.test(s);
      case 'TIME-SEC-HUNDREDTHS':
      case 'TIME-SEC-INTEGER':
      case 'TIME-SEC-TENTHS':
      case 'NEGATIVE-TIME-SEC-HUNDREDTHS':
        return /[-0-9.:]/.test(s);
      default:
        return /[0-9.]/.test(s);
    }
  },
  INCHES: (i) => {
    switch (format) {
      case 'LENGTH-INCHES-QUARTERINCH':
        return /[0-9.]/.test(i);
      case 'LENGTH-INCHES-INTEGER':
        return /[0-9]/.test(i);
      case 'LENGTH-INCHES-TENTHS':
        return /[0-9.]/.test(i);
      case 'NEGATIVE-INCHES-HUNDREDTH':
        return /[-0-9.]/.test(i);
      default:
        return /[0-9'". ]/.test(i);
    }
  },
  FEET: (i) => {
    switch (format) {
      case 'LENGTH-QUARTERINCH':
        return /[0-9'". ]/.test(i);
      case 'LENGTH-INCHES-HALFINCH':
        return /[0-9.]/.test(i);
      case 'LENGTH-DASH-QUARTERINCH':
        return /[0-9-.]/.test(i);
      default:
        return /[0-9. ]/.test(i);
    }
  },
  METERS: (i) => {
    switch (format) {
      case 'LENGTH-METERS-INTEGER':
        return /[0-9]/.test(i);
      default:
        return /[0-9.]/.test(i);
    }
  },
  'SPEED-MPH': i => /[0-9.]/.test(i),
  'SPEED-RPM': i => /[0-9.]/.test(i),
  PERCENTAGE: (p) => {
    switch (format) {
      case 'PERCENTAGE-INTEGER-HUNDREDTHS':
        return /[0-9]/.test(p);
      case 'NEGATIVE-PERCENTAGE-HUNDREDTHS':
        return /[-0-9.]/.test(p);
      default:
        return /[0-9.]/.test(p);
    }
  },
  DEGREES: d => {
    switch (format) {
      case 'DEGREES-TENTHS':
      case 'DEGREES-HUNDREDTHS':
        return /[0-9.]/.test(d);
      case 'NEGATIVE-DEGREE-HUNDREDTH':
        return /[-0-9.]/.test(d);
      default:
        return /[0-9]/.test(d);
    }
  },

  // /[0-9]/.test(d),
  'HOURS-MINUTES': h => /[0-9-:]/.test(h),
  CM: (c) => {
    switch (format) {
      case 'CM-HUNDREDTHS':
      case 'CM-TENTHS':
        return /[0-9.]/.test(c);
      default:
        return /[0-9]/.test(c);
    }
  },
  KG: (k) => {
    switch (format) {
      case 'KG-HUNDREDTHS':
      case 'KG-TENTHS':
        return /[0-9.]/.test(k);
      default:
        return /[0-9]/.test(k);
    }
  },
  MLKGMIN: m => /[0-9.]/.test(m),
  YARD: (y) => {
    switch (format) {
      case 'YARD-INTEGER':
        return /[0-9]/.test(y);
      case 'YARD-TENTHS':
      case 'YARD-HUNDREDTHS':
        return /[0-9.]/.test(y);
      default:
        return /[0-9]/.test(y);
    }
  },
  WATT: w => {
    switch (format) {
      case 'WATT-INTEGER':
        return /[0-9]/.test(w);
      case 'WATT-TENTHS':
      case 'WATT-HUNDREDTHS':
        return /[0-9.]/.test(w);
      default:
        return /[0-9]/.test(w);
    }
  },
  'KG-M-SEC': k => {
    switch (format) {
      case 'KG-M-SEC-INTEGER':
        return /[0-9]/.test(k);
      case 'KG-M-SEC-TENTH':
      case 'KG-M-SEC-HUNDREDTH':
        return /[0-9.]/.test(k);
      default:
        return /[0-9]/.test(k);
    }
  },
  MS: m => {
    switch (format) {
      case 'MS-TENTH':
      case 'MS-HUNDREDTH':
        return /[0-9.]/.test(m);
      default:
        return /[0-9]/.test(m);
    }
  },
  'G-FORCE': g => {
    switch (format) {
      case 'G-FORCE-TENTH':
      case 'G-FORCE-HUNDREDTH':
        return /[0-9.]/.test(g);
      default:
        return /[0-9]/.test(g);
    }
  },
});

const validateDecimal = (v) => {
  if (Number.isNaN(Number(v))) {
    return 'Oops! This must be a valid decimal number.';
  }
  return '';
};
const validateInteger = (v) => {
  if (!Number.isInteger(Number(v))) {
    return 'Oops! This must be an integer.';
  }
  return '';
};
const validateInches = (v) => {
  if (!/(^[0-9]+'( [0-9]+")?$)|(^[0-9]+"$)|(^[0-9]+$)/.test(v)) {
    return 'Oops! This must be in the format 1\' 6"';
  }
  return '';
};
const validateTime = (v) => {
  if (isEmpty(v)) {
    return '';
  }
  if (!/(^([0-9]+):([0-9]+\.?)$)|(^([0-9]+):([0-9]+)\.([0-9]+)$)/.test(v)) {
    return 'Oops! This must be in the format 00:00.0';
  }
  return '';
};

const validateHourTime = (v) => {
  if (isEmpty(v)) {
    return '';
  }
  if (!/(^([0-9]+):([0-9]+)$)/.test(v)) {
    return 'Oops! This must be in the format 00:00';
  }
  return '';
};

export const validateNegativeDecimal = v => {
  if (isEmpty(v)) {
    return '';
  }
  if (Number.isNaN(Number(v))) {
    return 'Oops! This Must be a valid positive or negative decimal number.';
  }
  // if  (! /(?<=^-?| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/.test(v)) {
  //   return 'Must be a valid postive or negative decimal number';
  // }
  return '';
}

export const validateMinimum = (standardTestRange) => (value) => {
  if (standardTestRange && value) {
    const min = Number(standardTestRange.min);
    const number = Number(value);
    if (number < min) {
      return `Oops! This field's value should not be less than ${min}.`;
    } else {
      return ''
    }
  } else {
    return ''
  }
};

// user for validation when enter record in combine test result
export const CombineValidation = format => ({
  COUNT: (c) => {
    switch (format) {
      case 'NUMERIC-HUNDREDTHS':
      case 'NUMERIC-TENTHS':
        return validateDecimal(c);
      case 'NEGATIVE-NUMERIC-HUNDREDTHS':
        return validateNegativeDecimal(c);
      default:
        return validateInteger(c);
    }
  },
  'COUNT-TENTHS': c => validateDecimal(c),
  POUNDS: lbs => validateDecimal(lbs),
  SECONDS: (s) => {
    switch (format) {
      case 'TIME-HUNDREDTHS':
      case 'TIME-INTEGER':
      case 'TIME-TENTHS':
        return validateTime(s);
      case 'TIME-SEC-HUNDREDTHS':
      case 'TIME-SEC-INTEGER':
      case 'TIME-SEC-TENTHS':
      case 'NEGATIVE-TIME-SEC-HUNDREDTHS':
        return validateNegativeDecimal(s);
      default:
        return validateDecimal(s);
    }
  },
  MINUTES: (m) => validateTime(m),

  'QUARTER-INCHES': i => {
    if ((i * 100) % 25 !== 0) {
      return 'Oops! This must use 1/4 inch increments.';
    }
    return '';
  },
  'INTEGER-INCHES': i => validateInteger(i),
  INCHES: (i) => {
    switch (format) {
      case 'LENGTH-INCHES-QUARTERINCH':
        if ((i * 100) % 25 !== 0) {
          return 'Oops! This must use 1/4 inch increments.';
        }
        return '';
      case 'LENGTH-INCHES-TENTHS':
        return validateDecimal(i);
      case 'LENGTH-INCHES-INTEGER':
        return validateInteger(i);
      case 'NEGATIVE-INCHES-HUNDREDTH':
        return validateNegativeDecimal(i);
      default:
        return validateInches(i);
    }
  },
  FEET: (f) => {
    switch (format) {
      case 'LENGTH-FEET-INTEGER':
        return validateInteger(f);
      case 'LENGTH-QUARTERINCH':
        if ((f * 100) % 25 !== 0) {
          return 'Oops! This must use 1/4 inch increments.';
        }
        return '';
      case 'LENGTH-INCHES-HALFINCH':
        if ((f * 100) % 50 !== 0) {
          return 'Oops! This must use 1/2 inch increments.';
        }
        return '';
      case 'LENGTH-DASH-QUARTERINCH': {
        const results = /(^[0-9]+$)|(^([0-9]+)-([0-9.]+)$)/.exec(f);
        if (results === null) {
          return '';
        }
        if (!results) {
          return 'Oops! This must be in the format 0-00.00';
        }
        const [, , , , inc] = results;
        if (inc) {
          const inch = Number(inc);
          if ((inch * 100) % 25 !== 0) {
            return 'Oops! This must use 1/4 inch increments.';
          }
        }
        return '';
      }
      default:
        return validateDecimal(f);
    }
  },
  'FEET-INCHES': (f) => {
    switch (format) {
      case 'LENGTH-QUARTERINCH':
        if ((f * 100) % 25 !== 0) {
          return 'Oops! This must use 1/4 inch increments.';
        }
        return '';
      default:
        return validateDecimal(f);
    }
  },

  'HOURS-MINUTES': (h) => validateHourTime(h),
  METERS: i => validateDecimal(i),
  'SPEED-MPH': i => validateDecimal(i),
  'SPEED-RPM': r => {
    switch (format) {
      case 'SPEED-RPM-HUNDREDTH':
        return validateDecimal(r);
      default:
        return validateInteger(r);
    }
  },
  PERCENTAGE: p => {
    if (p < -100) {
      return 'Oops! This should not be more than -100';
    }
    if (p > 100) {
      return 'Oops! This should not be more than 100';
    }
    if (format === 'DECIMAL-PERCENTAGE') {
      if (p > 1) {
        return 'Oops! This should not be more than 1';
      }
    }
    if (format === 'TOURNAMENT-STAT-DECIMAL-PERCENTAGE') {
      if (p > 5) {
        return 'Oops! This should not be more than 5';
      }
    }
    switch (format) {
      case 'PERCENTAGE-INTEGER-HUNDREDTHS':
        return ''
      case 'NEGATIVE-PERCENTAGE-HUNDREDTHS':
        return validateNegativeDecimal(p);
      default:
        return validateDecimal(p);
    }
  },
  REPS: r => validateInteger(r),
  DEGREES: d => {
    switch (format) {
      case 'DEGREES-TENTHS':
      case 'DEGREES-HUNDREDTHS':
        return validateDecimal(d)
      case 'NEGATIVE-DEGREE-HUNDREDTH':
        return validateNegativeDecimal(d);
      default:
        return validateInteger(d);

    }
  },
  CM: c => validateDecimal(c),
  KG: k => validateDecimal(k),
  MLKGMIN: v => validateDecimal(v),
  YARD: y => {
    switch (format) {
      case 'YARD-TENTHS':
      case 'YARD-HUNDREDTHS':
        return validateDecimal(y)
      default:
        return validateInteger(y);

    }
  },
  WATT: w => {
    switch (format) {
      case 'WATT-TENTHS':
      case 'WATT-HUNDREDTHS':
        return validateDecimal(w);
      default:
        return validateInteger(w);
    }
  },
  'G-FORCE': g => {
    switch (format) {
      case 'G-FORCE-TENTH':
      case 'G-FORCE-HUNDREDTH':
        return validateDecimal(g);
      default:
        return validateInteger(g);
    }
  },
  'KG-M-SEC': k => {
    switch (format) {
      case 'KG-M-SEC-TENTH':
      case 'KG-M-SEC-HUNDREDTH':
        return validateDecimal(k);
      default:
        return validateInteger(k);
    }
  },
  MS: m => {
    switch (format) {
      case 'MS-TENTH':
      case 'MS-HUNDREDTH':
        return validateDecimal(m);
      default:
        return validateInteger(m);
    }
  },
});

export const parseInches = (v) => {
  let results = /^([0-9]+)' ([0-9]+)"$/.exec(v);
  if (results) {
    const [, ft, inc] = results;
    const feet = Number(ft);
    const inches = Number(inc);
    return (feet * 12) + inches;
  }
  results = /^([0-9]+)'$/.exec(v);
  if (results) {
    const [, ft] = results;
    return Number(ft) * 12;
  }
  results = /^([0-9]+)"$/.exec(v);
  if (results) {
    const [, inc] = results;
    return Number(inc);
  }
  if (/^[0-9]+$/.exec(v)) {
    return Number(v);
  }
  return null;
};

const parseTime = (v) => {
  let results = /^([0-9]+):([0-9]+)(\.[0-9]+)$/.exec(v);
  if (results) {
    const [, min, sec, tens] = results;
    const minutes = Number(min);
    const seconds = Number(sec);
    const tenths = Number(tens);
    return (minutes * 60) + seconds + tenths;
  }
  results = /^([0-9]+):([0-9]+\.?)$/.exec(v);
  if (results) {
    const [, min, sec] = results;
    const minutes = Number(min);
    const seconds = Number(sec);
    return (minutes * 60) + seconds;
  }
  return null;
};

const parseDashFeet = (v) => {
  const results = /(^[0-9]+$)|(^([0-9]+)-([0-9.]+)$)/.exec(v);
  if (!results) {
    return null;
  }
  const [, ft, , ftt, inc] = results;
  if (ft) {
    return Number(ft) * 12;
  }
  if (ftt && inc) {
    return (Number(ftt) * 12) + Number(inc);
  }
  return 0;
};

const parseHoursMintue = (h) => {
  const results = /^([0-9]+):([0-9]+)$/.exec(h);
  if (!results) {
    return null;
  }
  const [total, hour, min] = results;
  const minutes = Number(min);
  const hours = Number(hour) * 60;
  return hours + minutes;
}
export const ParseEntries = format => (
  {
    COUNT: c => (isEmpty(c) ? null : Number(c)),
    'COUNT-TENTHS': c => (isEmpty(c) ? null : Number(c)),
    POUNDS: lbs => (isEmpty(lbs) ? null : Number(lbs)),
    SECONDS: (s) => {

      switch (format) {
        case 'TIME-HUNDREDTHS':
        case 'TIME-INTEGER':
        case 'TIME-TENTHS':
          return (isEmpty(s) ? null : parseTime(s));
        case 'TIME-SEC-HUNDREDTHS':
        case 'TIME-SEC-INTEGER':
        case 'TIME-SEC-TENTHS':
        default:
          return (isEmpty(s) ? null : Number(s));

      }
    },
    MINUTES: m => (isEmpty(m) ? null : parseTime(m)),
    INCHES: (i) => {
      switch (format) {
        case 'LENGTH-INCHES-QUARTERINCH':
          return (isEmpty(i) ? null : Number(i));
        case 'LENGTH-INCHES-TENTHS':
          return (isEmpty(i) ? null : Number(i));
        case 'NEGATIVE-INCHES-HUNDREDTH':
          return (isEmpty(i) ? null : Number(i));
        default:
          return (isEmpty(i) ? null : parseInches(i));
      }
    },
    FEET: (f) => {
      switch (format) {
        case 'LENGTH-INCHES-HALFINCH':
        case 'LENGTH-QUARTERINCH':
          return (isEmpty(f) ? null : Number(f));
        case 'LENGTH-DASH-QUARTERINCH':
          return (isEmpty(f) ? null : parseDashFeet(f));
        default:
          return (isEmpty(f) ? null : Number(f));
      }
    },
    METERS: s => (isEmpty(s) ? null : Number(s)),
    'SPEED-MPH': s => (isEmpty(s) ? null : Number(s)),
    'SPEED-RPM': s => (isEmpty(s) ? null : Number(s)),
    PERCENTAGE: p => (isEmpty(p) ? null : Number(p)),
    DEGREES: d => (isEmpty(d) ? null : Number(d)),
    'HOURS-MINUTES': h => (isEmpty(h) ? null : parseHoursMintue(h)),
    'QUARTER-INCHES': i => (isEmpty(i) ? null : Number(i)),
    'INTEGER-INCHES': i => (isEmpty(i) ? null : parseInches(i)),
    CM: c => (isEmpty(c) ? null : Number(c)),
    KG: k => (isEmpty(k) ? null : Number(k)),
    MLKGMIN: m => (isEmpty(m) ? null : Number(m)),
    YARD: y => (isEmpty(y) ? null : Number(y)),
    WATT: w => (isEmpty(w) ? null : Number(w)),
    'KG-M-SEC': v => (isEmpty(v) ? null : Number(v)),
    'G-FORCE': g => isEmpty(g) ? null : Number(g),
    'MS': m => isEmpty(m) ? null : Number(m)
  });


export const RepsUnitFormats = format => ({
  COUNT: c => `${c.result} x ${c.reps}`,
  POUNDS: lbs => `${formatResult(lbs.result, format)} x ${lbs.reps}`,
  SECONDS: s => `${formatResult(s.result, format)} x ${s.reps}`,
  INCHES: i => `${formatResult(i.result, format)} x ${i.reps}`,
  FEET: i => `${Math.floor(i.result / 12)}' ${limitDecimals(i.result % 12)}" x ${i.reps}`,
});

export const RepsUnitHelps = format => ({
  COUNT: c => (c === null ? 'Counts' : `Count (${c.result} x ${c.reps} reps)`),
  POUNDS: lbs => (lbs === null ? 'Pounds' : `Pounds (${lbs.result} lbs x ${lbs.reps} reps)`),
  SECONDS: s => (s === null ? 'Seconds' : `Seconds (${formatResult(s.result, format)} x ${s.reps} reps)`),
  INCHES: i => (i === null ? 'Inches (1.23")' : `Inches (${formatResult(i.result, format)} x ${i.reps} reps)`),
  FEET: i => (i === null ? 'Feet & Inches (1\' 6")' : `Feet & Inches (${formatResult(i.result, format)} x ${i.reps} reps)`),
});
export const testCodesForZeroValues = ["TIMEANDROOMSHOTACCURACY", "MOVINGPASSACCURACY", "OUTLETPASSACCURACY"]

