/* eslint react/jsx-curly-brace-presence: 0 */

import React, { PureComponent } from 'react';
import { Form } from 'react-redux-form';
import PropTypes from 'prop-types';
import { TextControl, GeneralErrors } from '../../../../UxCommon/Form';
import { ButtonRect, LoadingSpinner } from '../../../../UxCommon';

class ExternalVideoDialog extends PureComponent {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
  }

  componentDidMount() {}

  render() {
    const {
      onAdd,
      onCancel,
      rrfForm,
      loading,
      loadingMsg,
    } = this.props;
    return (
      <div className="AlbumDialog_ExternalVideoDialog_Backdrop">
        <div className="AlbumDialog_ExternalVideoDialog">
          <div className="AlbumDialog_ExternalVideoDialog_Title">
            Add a video from YouTube or Hudl
          </div>
          <div className="AlbumDialog_ExternalVideoDialog_Instr">
            {"Grab your video's URL address or share link"}
          </div>
          <LoadingSpinner
            loading={loading}
            loadingMsg={loadingMsg}
          />
          <Form
            model="forms.externalVideo"
            onSubmit={onAdd}
          >
            <TextControl
              label="Video Link"
              placeholder="And then paste your video's URL here"
              model="forms.externalVideo.url"
              rrfField={rrfForm.url}
            />
            <div className="AlbumDialog_ExternalVideoDialog_Btns">
              <ButtonRect
                className="AlbumDialog_ExternalVideoDialog_Btn"
                onClick={onCancel}
                type="button"
              >
                Cancel
              </ButtonRect>
              <ButtonRect
                className="AlbumDialog_ExternalVideoDialog_Btn primary"
              >
                Done
              </ButtonRect>
            </div>
            <GeneralErrors rrfForm={rrfForm} style={{ paddingBottom: 0, minHeight: 0 }} />
          </Form>
        </div>
      </div>
    );
  }
}

export default ExternalVideoDialog;
