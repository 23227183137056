import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../UxCommon/Icon/Icon';

class AddNewCard extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    iconName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  componentDidMount() {
  }

  render() {
    const {
      onClick,
      iconName,
      text,
      className,
    } = this.props;
    return (
      <button
        className={[
          'UxProfile_Photos_AddNewCard',
          className || '',
        ].join(' ')}
        onClick={onClick}
      >
        <Icon className="UxProfile_Photos_AddNewCard_Plus" iconName={iconName} />
        {text}
      </button>
    );
  }
}

export default AddNewCard;
