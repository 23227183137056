import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import moment from 'moment';

import {
  mapDuplicateProfileToUi,
  mapProfileDataApiToUi,
  mapUserApiToUi,
} from '../../../../data/user/profile/models';
import { getProfileDataSuccess } from '../../../../data/sport/playerData/actions';
import {
  getPlayerLatestSchool,
  getPlayerProfile,
  getPlayerProfileSuccess,
  getPlayerTeamsAndDetails,
  getProfileData,
  getProfileDuplicates,
} from '../../../../data/user/profile';
import { asyncFinishAction } from '../../../async';
import {
  checkForkErrors,
  commonApiCallFragment,
  getTokenFragment,
} from '../../utils';
import {
  GET_FAVORITES_ERR,
  getFavoritePhotos,
  getFavoriteVideos,
  mapPhotosApiToUi,
  mapVideosApiToUi,
  photoGetFavoritesSuccess,
} from '../../../../data/photo';
import { combineAndSortMedia } from '../../photosVideos/utils';
import {
  GET_FAVORITES,
  GET_PLAYER_TEAMS_AND_DETAILS,
  PP_GET_REQ,
  PROFILE_DATA_GET,
  GET_COACH_SCHOOLS,
  PLAYER_PROFILE_DUPLICATES,
} from '../actions';
import { Routes, Sports } from '../../../routes';
import {
  mapSportsApiToUi,
  sportGetSports,
  sportsGetSuccess,
} from '../../../../data/sport';
import { getPlayerSchool } from '../../../../data/user';
import { getPlayerEnrolledSchool } from '../../../../data/user/profile';
import { FORBIDDEN_ERROR } from '../../../ajaxErrorHandlers';
import { getCoachSchools } from '../../../../data/coach';
import { School } from '../../../../../../modules/CoachWorld/+store/roster/roster.models';

const getProfileEpic = (action$) =>
  action$.pipe(
    ofType(PP_GET_REQ),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      forkJoin(
        getPlayerProfile(action.uuid, token).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getPlayerProfile',
            Routes.player,
          ),
        ),
        sportGetSports().pipe(
          commonApiCallFragment(action$, action, 'getSports', Routes.player),
        ),
        getPlayerSchool(action.uuid).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getPlayerSchool',
            Routes.player,
          ),
        ),
        getPlayerLatestSchool(action.uuid).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getPlayerSchool',
            Routes.player,
          ),
        ),
        getPlayerEnrolledSchool(action.uuid).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getPlayerSchool',
            Routes.player,
          ),
        ),
      ).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      ),
    ),
    switchMap((results) => {
      if (results.success) {
        const playerProfile = mapUserApiToUi(
          results.results[0].response,
          results.results[2].response,
          null,
          results.results[3].response,
          results.results[4].response,
        );
        const sports = mapSportsApiToUi(results.results[1].response);
        return of(
          getPlayerProfileSuccess(results.action.uuid, playerProfile),
          sportsGetSuccess(sports),
          asyncFinishAction(results.action.type, 'getPlayerProfile', {}),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );

const playerProfileDuplicatesGetEpic = (action$) =>
  action$.pipe(
    ofType(PLAYER_PROFILE_DUPLICATES),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      getProfileDuplicates(action.uuid).pipe(
        commonApiCallFragment(
          action$,
          action,
          PLAYER_PROFILE_DUPLICATES,
          Routes.player,
        ),
      ),
    ),
    switchMap((results) => {
      if (results.success) {
        const duplicateProfiles = results.response.map((profile) =>
          mapDuplicateProfileToUi(profile),
        );
        return of(
          asyncFinishAction(
            results.action.type,
            PLAYER_PROFILE_DUPLICATES,
            duplicateProfiles,
          ),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );

const getProfileDataEpic = (action$) =>
  action$.pipe(
    ofType(PROFILE_DATA_GET),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      getProfileData(action.uuid, action.sportId, token).pipe(
        commonApiCallFragment(action$, action, 'getProfileData', Routes.player),
      ),
    ),
    switchMap((results) => {
      if (results.success) {
        const profileData =
          results.response.length !== 0 &&
          mapProfileDataApiToUi(results.response);
        return of(
          getProfileDataSuccess(
            results.action.uuid,
            results.action.sportId,
            profileData,
          ),
          asyncFinishAction(results.action.type, 'getProfileData', {}),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );

const getPlayerTeamsAndDetailsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PLAYER_TEAMS_AND_DETAILS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      getPlayerTeamsAndDetails(action.uuid, action.sportId, token).pipe(
        commonApiCallFragment(action$, action, 'getProfileData', Routes.player),
      ),
    ),
    switchMap((results) => {
      if (results.success) {
        const { schools } = results.response;
        const teamRosterResults = {},
          allAthleteTeamResult = [];
        if (schools) {
          Object.entries(schools).forEach((school) => {
            const { teams, combines } = school[1];
            if (teams) {
              Object.entries(teams).forEach((team) => {
                const schoolTeam = team[1].school_team;
                if (schoolTeam && schoolTeam.length > 0) {
                  schoolTeam.forEach((st) => {
                    teamRosterResults[st.school_id] = {
                      schoolId: st.school_id,
                      sportId: Sports[st.sport_id],
                      schoolName: school[1].name,
                      combines: [],
                    };
                  });
                  //get all athlete teams for a particular sport
                  schoolTeam.forEach((st) => {
                    athleteTeam = {
                      schoolId: st.school_id,
                      sportId: Sports[st.sport_id],
                      schoolName: school[1].name,
                      teamName: st.name,
                    };
                    allAthleteTeamResult.push(athleteTeam);
                  });
                }
              });
            }
            if (combines) {
              Object.entries(combines).forEach(([name, combineInfo]) => {
                const { date, leaderboard, url } = combineInfo;
                const schoolId = leaderboard.split('/')[2];
                teamRosterResults[schoolId].combines.push({
                  name,
                  date: moment(date),
                  leaderboard,
                  report: url,
                });
              });
            }
          });
        }
        return of(
          asyncFinishAction(results.action.type, 'getProfileData', {
            teamRosterResults: Object.values(teamRosterResults),
            allTeamResults: allAthleteTeamResult,
          }),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  );

//copy obve..............

const getFavoritesEpic = (action$) =>
  action$.pipe(
    ofType(GET_FAVORITES),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      forkJoin(
        getFavoriteVideos(action.uuid, action.sportId, token).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getFavoriteVideos',
            Routes.player,
          ),
        ),
        getFavoritePhotos(action.uuid, action.sportId, token).pipe(
          commonApiCallFragment(
            action$,
            action,
            'getFavoritePhotos',
            Routes.player,
          ),
        ),
      ).pipe(
        checkForkErrors(),
        map((forkResults) => {
          if (forkResults.success) {
            return {
              ...forkResults,
              action,
            };
          }
          return forkResults;
        }),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const videos = mapVideosApiToUi(result.results[0].response);
        const photos = mapPhotosApiToUi(result.results[1].response);
        const favorites = combineAndSortMedia(photos, videos);
        return of(
          photoGetFavoritesSuccess(favorites, result.action.uuid),
          asyncFinishAction(GET_FAVORITES, 'getFavorites', {}),
        );
      }
      if (result.actions[0].type === FORBIDDEN_ERROR) {
        return of({ type: GET_FAVORITES_ERR });
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

const getCoachSchool = (action$) =>
  action$.pipe(
    ofType(GET_COACH_SCHOOLS),
    getTokenFragment(),
    switchMap(({ action, token }) =>
      getCoachSchools(action.uuid, token).pipe(
        commonApiCallFragment(action$, action),
      ),
    ),
    switchMap((result) => {
      if (result.success) {
        const coachData = result.response.map((response) =>
          School.fromApi(response.school),
        );
        return of(
          asyncFinishAction(GET_COACH_SCHOOLS, 'getCoachData', coachData),
        );
      }
      if (result.actions[0].type === FORBIDDEN_ERROR) {
        return of({ type: GET_FAVORITES_ERR });
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  );

export default combineEpics(
  getProfileEpic,
  getProfileDataEpic,
  getFavoritesEpic,
  getCoachSchool,
  getPlayerTeamsAndDetailsEpic,
  playerProfileDuplicatesGetEpic,
);
