import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import Urls from '../../../../../config/urls';
import { mapUserApiToUi } from './models';
import { ajaxErrorHandlerEpicFragment } from '../../../ui/ajaxErrorHandlers';

// action creators
export const USER_SAVE_PROFILE_PHOTO_REQ = 'USER_SAVE_PROFILE_PHOTO_REQ';
export const USER_SAVE_PROFILE_PHOTO_SUC = 'USER_SAVE_PROFILE_PHOTO_SUC';
export const USER_SAVE_PROFILE_PHOTO_ERR = 'USER_SAVE_PROFILE_PHOTO_ERR';
export const saveProfilePhoto = (msg, uuid, sportId, photoUrl, token) => ({
  type: USER_SAVE_PROFILE_PHOTO_REQ,
  msg,
  uuid,
  sportId,
  photoUrl,
  token,
});
export const saveProfilePhotoSuccess = user => ({ type: USER_SAVE_PROFILE_PHOTO_SUC, user });
export const saveProfilePhotoError = error => ({ type: USER_SAVE_PROFILE_PHOTO_ERR, error });

// epic
export const saveProfilePhotoEpic = action$ =>
  action$.pipe(
    ofType(USER_SAVE_PROFILE_PHOTO_REQ),
    switchMap(
      action => ajax.put(
        Urls.userSports(action.uuid, action.sportId),
        JSON.stringify({
          aws_uuid: action.uuid,
          photo_url: action.photoUrl,
        }),
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${action.token}`,
        },
      ),
      action2 => (action2),
    ),
    switchMap(action =>
      ajax.getJSON(Urls.profile(action.uuid))),
    map(response => mapUserApiToUi(response)),
    map(user => saveProfilePhotoSuccess(user)),
    ajaxErrorHandlerEpicFragment(),
    catchError(error => of(saveProfilePhotoError(error))),
  );

export const USER_PROFILE_CREATE_SUC = 'user.profile.createSuccess';
export const profileCreateSuccess = user => ({
  type: USER_PROFILE_CREATE_SUC,
  user,
});

export const profileCreate = apiUser => ajax.post(
  Urls.profile(),
  JSON.stringify(apiUser),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const getAjaxProfileByEmail = (email, token) => ajax.get(
  Urls.getProfileByEmail(email),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const getGuestProfileByEmail = (email) => ajax.get(
  Urls.guestProfileByEmail(email),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const invitedResetPassword = apiUser => ajax.post(
  Urls.profileInvited(),
  JSON.stringify(apiUser),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const mergeSignIn = apiUser => ajax.post(
  Urls.mergeProfiles(),
  JSON.stringify(apiUser),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const mergeWithoutInvite = apiUser => ajax.post(
  Urls.mergeWithoutInvite(),
  JSON.stringify(apiUser),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const checkIfInviteIsValid = (userTeamId, token) => ajax.get(
  Urls.schools.checkInviteValidation(userTeamId, token),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
).pipe(map(response => response.response));

export const mergeGridProfile = (uuids, token) => ajax.post(
  Urls.gridMerge(),
  JSON.stringify(uuids),
  {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
).pipe(map(response => response.response));

export const USER_PROFILE_UPDATE_SUC = 'user.profile.updateSuccess';
export const profileUpdateSuccess = user => ({
  type: USER_PROFILE_UPDATE_SUC,
  user,
});


export const USER_PROFILE_DELETE_SUC = 'user.profile.deleteSuccess';
export const profileDeleteSuccess = uuid => ({
  type: USER_PROFILE_DELETE_SUC,
  uuid,
});

