import {ajax} from 'rxjs/ajax';
import Urls from '../../../../config/urls';

export const apiGetAthleteProfile = (token, teamId, athleteId) => (
  ajax.getJSON(
    Urls.prospectSheet.personalInfo(teamId, athleteId),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export const apiGetPlayerData = (token, uuid, sport) => (
  ajax.getJSON(
    Urls.prospectSheet.personalRecord(uuid, sport),
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  )
);

export default 'prospectSheet.api';
