import { combineEpics, ofType } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { actions } from 'react-redux-form';

import { commonApiCallFragment, getTokenFragment } from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import { testVanityUrl } from '../../../../data/user/vanity/apiCalls';
import { TEST_VANITY_URL, testVanityUrlAction } from '../actions';
import { getRrfChangeValue, ofTypeOfModel } from '../../../formUtils/operators';
import { asyncErrorAction, asyncFinishAction } from '../../../async';

const typeAndCheckEpic = (action$, state$) => {
  const canEditProfile = () => state$.value.ui.app.context.canEditProfile;
  const isCoach = () => {
    const canEditObj = canEditProfile();
    return !!(canEditObj && canEditObj.isCoach);
  };
  const uuid = () => {
    const canEditObj = canEditProfile();
    return isCoach() ? canEditObj.playerUuid : state$.value.data.cognito.uuid;
  };
  const profile = () => state$.value.data.user.profiles[uuid()];
  return action$.pipe(
    ofTypeOfModel('rrf/change', 'forms.vanityUrl.url'),
    map(action => {
      return getRrfChangeValue(action)
    }),
    filter(name => !!name),
    filter(name => profile().instagram !== name),
    map(name => testVanityUrlAction(name)),
  );
};

export const testVanityUrlEpic = action$ => (
  action$.pipe(
    ofType(TEST_VANITY_URL),
    getTokenFragment(),
    switchMap( ( {action , token}) => {
      return  (
        testVanityUrl(action.name, token).pipe(commonApiCallFragment(
          action$,
          action,
          'vanityUrl',
          Routes.edit,
        ))
    ) }
    ),
    switchMap((results) => {
      if (!results.success
      && results.action.error
      && results.action.error.response === 'Name Not In Use') {
        if (results.originalAction.isMobile) {
          return of(asyncFinishAction(results.action.type, 'testVanityUrl'));
        }
        return of(
          asyncFinishAction(results.action.type, 'testVanityUrl'),
          actions.setErrors('forms.vanityUrl.url', {
            validation: '',
          }),
        );
      }
      if (results.success) {
        if (results.action.isMobile) {
          return of(asyncErrorAction(results.action.type, 'testVanityUrl', 'Already being used by another athlete.'));
        }
        return of(
          asyncFinishAction(results.action, 'testVanityUrl', { message : 'Already being used by another athlete.'} ),
          actions.setErrors('forms.vanityUrl.url', {
            validation: 'Already being used by another athlete.',
          }),
        );
      }
      if (results.actions) return of(...results.actions);
      return of(results.action);
    }),
  )
);

export const readEpics = combineEpics(
  testVanityUrlEpic,
  typeAndCheckEpic,
);

export default 'vanity/create.js';
