import { combineReducers } from 'redux';
import sport from './sport';
import user from './user';
import photo from './photo';
import cognito from './cognito';
import awardLogo from './awardLogo';
import coach from './coach';

const reducer = combineReducers({
  sport,
  user,
  coach,
  photo,
  cognito,
  awardLogo
});

export default reducer;
