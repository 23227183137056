import React from 'react';
import PropTypes from "prop-types";
import {Route} from "react-router-dom";
import {
  expandedPhotoDialogPath,
  externalProfileDialogTo, Routes
} from "../../../../store/actions/ui/routes";
import {SecondaryIconButton, SecondaryIconLink} from "../../../UxCommon";


const  TopBar = ( props ) => {
  const { history, match,isMyProfile } = props;
  const onAddClick = () => {
    const { history, match } = props;
    history.push(externalProfileDialogTo(match.params.uuid, 'new',match.params.sport,match.params.schoolId ) )
  }

 const renderHoverlay = () => {
   return (
     <div className="UxProfile_Photos_TopBar_BtnsHoverlay" />
   )
 }

const  getRoute = (page) => {
   const { match } = props;
   return `/${Routes.profile}/${match.params.uuid}/${page}${match.params.sport ? `/${match.params.sport}` : ''}${match.params.schoolId ? `/${match.params.schoolId}`: ''}`;
 };


  return (
    <div className ="Awards_TopBar">
        <div className = "Awards_TopBar_Title">
          External Sports Profile
        </div>

      <div className ="Awards_TopBar_BtnLayer">
        <Route
          path={expandedPhotoDialogPath}
          render={() => renderHoverlay()}
        />

        <div className="UxProfile_Photos_TopBar_Btns">
          <div className="d-flex" style={{ marginRight: 'auto' }}>
            <SecondaryIconLink iconName="back-arrow" to={getRoute(Routes.player)}>
              <span className="hidden-lg-up">To Profile</span>
              <span className="hidden-md-down"> {isMyProfile ? 'Back to My Profile' : 'Back to Profile'}</span>
            </SecondaryIconLink>

          </div>

          {
            isMyProfile && (
              <React.Fragment>
                <SecondaryIconButton iconName="plus" onClick = {() => onAddClick()}>
                  Add
                </SecondaryIconButton>
              </React.Fragment>
            )
          }


      </div>
    </div>
    </div>
  );
}
TopBar.propTypes = {
  match: PropTypes.object.isRequired,
  isMyProfile: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}
export default TopBar;
