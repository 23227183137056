import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getSportIconName } from '../../../../../utils';

class SportButton extends PureComponent {
  static propTypes = {
    sport: PropTypes.object.isRequired,
    onSportPick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  }

  onPick = () => {
    const { onSportPick, sport } = this.props;
    onSportPick(sport.id);
  }

  render() {
    const {
      sport,
      selected,
    } = this.props;
    return (
      <button
        className={['EditSports_SportButton', selected ? 'selected' : ''].join(' ')}
        type="button"
        onClick={this.onPick}
      >
        <i className={`EditSports_SportButton_Icon icon-${getSportIconName(sport.id)}`} />
        <div className="EditSports_SportButton_Txt">
          {selected && sport.name}
        </div>
      </button>
    );
  }
}

export default SportButton;
