import { PHOTO_GET_MEDIA_SUC, ALBUM_CREATE_SUC, ALBUM_UPDATE_SUC, DELETE_ALBUM_EVERYTHING_SUC, DELETE_ALBUM_ONLY_SUC } from '../../../actions/data/photo';
import {
  AUTH2_DEL_ACCOUNT_SUC,
  AUTH2_LOGOUT_SUC,
  AUTH2_LOGOUT_SUC_WITH_REDIRECT
} from '../../../actions/data/cognito';

const albums = (state = {}, action) => {
  switch (action.type) {
    case PHOTO_GET_MEDIA_SUC:
      return Object.assign({}, state, {
        [action.uuid]: action.albums,
      });
    case ALBUM_CREATE_SUC:
      if (action.album) {
        return Object.assign({}, state, {
          [action.uuid]: [...state[action.uuid], action.album],
        });
      }
      return state;
    case ALBUM_UPDATE_SUC:
      return Object.assign({}, state, {
        [action.uuid]: [
          ...state[action.uuid].filter(album => (album.id !== action.album.id)),
          action.album,
        ],
      });
    case DELETE_ALBUM_EVERYTHING_SUC:
    case DELETE_ALBUM_ONLY_SUC:
      return Object.assign({}, state, {
        [action.uuid]: state[action.uuid].filter(album => (album.id !== action.albumId)),
      });
    case AUTH2_LOGOUT_SUC:
    case AUTH2_LOGOUT_SUC_WITH_REDIRECT:
    case AUTH2_DEL_ACCOUNT_SUC:
      return {};
    default:
      return state;
  }
};

export default albums;
