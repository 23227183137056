import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes, Sports } from '../../../../store/actions/ui/routes';

class Rosters extends PureComponent {
  static propTypes = {
    teams: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    schoolStyle: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.shouldUpdate();
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  schoolStyleExist = false;

  shouldUpdate = () => {}

  render() {
    const {
      teams,
      sports,
      loading,
      schoolStyle,
    } = this.props;
    if (loading) return null;

    const uniqueSchoolSports = {};
    // console.log('teams', teams);
    if (schoolStyle.length && teams.length && sports.length) {
      teams.forEach(team => team.schoolTeams.forEach((schoolTeam) => {
        const sport = sports.find(s => s.id === schoolTeam.sportId);
        uniqueSchoolSports[`${schoolTeam.schoolId}:${schoolTeam.sportId}`] = {
          schoolId: schoolTeam.schoolId,
          sportId: schoolTeam.sportId,
          sportSlug: Sports[schoolTeam.sportId],
          sportName: sport.name,
          style: schoolStyle.find(s => s.id === team.schoolTeams[0].schoolId) || {},
        };
        // console.log('unique', uniqueSchoolSports);
      }));
    }
    const rosters = Object.values(uniqueSchoolSports);

    return (
      <div className="CoachDashboardRosters">
        <div className="CoachDashboard_TeamsHeader">Rosters</div>
        <div className="CoachDashboardRosters_Cards">
          {
            rosters.map((schoolSport, index) => (
              <Link
                to={`/${Routes.school}/${schoolSport.schoolId}/${Routes.roster}/${schoolSport.sportSlug}`}
                key={index}
                className="CoachDashboardRosters_Card"
              >
                <div className="CoachDashboardRosters_CardSchoolName">
                  {`${schoolSport.style.name}`}
                </div>
                <div className="CoachDashboardRosters_CardSportName">
                  {`${schoolSport.sportName}`}
                </div>
                <div className="CoachDashboardRosters_CardLink">
                  Go to Roster Page
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    );
  }
}

export default Rosters;
