import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/PlayerData/PersonalRecords/PersonalRecords';
import { getPlayerData, getVcRating, getCurrentSportId,getSortedSports } from '../../UxCommon/Utils';

const mapStateToProps = (state, ownProps) => {
  const userSports = getSortedSports(state, ownProps.match);
    return {
    playerData: getPlayerData(state, ownProps.match),
    vcRating: getVcRating(state, ownProps),
    currentSportId: getCurrentSportId(state),
    userSportsIds : userSports ?  userSports.map(userSport => userSport.sportId): [],
    state,
  }
};
const Container = connect(
  mapStateToProps,
  null,
)(Component);

export default Container;
