import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isInviteValid } from '../../../store/actions/ui/welcome';
import {
  playerProfileDuplicatesGet,
  playerProfileGet,
} from '../../../store/actions/ui/uxProfile';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../../modules/CoachWorld/common/LoadingIndicator';
import PropTypes from 'prop-types';
import useInviteSelector from './hooks/useInviteSelector';
import withRenderComponent from './hooks/withRenderComponent';

const InviteComponent = ({ match }) => {
  const dispatch = useDispatch();

  const { token, userTeamId } = useParams();

  const {
    loading,
    loadingMsg,
    shouldCheckInviteValid,
    isInvitationValid,
    profile,
  } = useInviteSelector();
  const isInvitationInvalid = () => {
    return (
      !shouldCheckInviteValid &&
      (!isInvitationValid || isInvitationValid.isValid === false)
    );
  };

  useEffect(() => {
    if (shouldCheckInviteValid) {
      dispatch(isInviteValid(userTeamId, token));
    }
  }, [userTeamId, token]);

  useEffect(() => {
    if (match.params.uuid) {
      dispatch(playerProfileGet(match.params.uuid));
      dispatch(playerProfileDuplicatesGet(match.params.uuid));
    }
  }, [match.params.uuid]);

  useEffect(() => {
    if (!isInvitationInvalid() && !profile && match.params.uuid) {
      dispatch(playerProfileGet(match.params.uuid));
    }
  }, [shouldCheckInviteValid, isInvitationValid, match.params.uuid, profile]);

  const loadView = () => {
    return withRenderComponent({match});
  };

  return (
    <div>
      <LoadingIndicator
        loading={loading}
        loadingMsg={loadingMsg}
        wrapperStyle={{ top: 120 }}
      />
      {loadView()}
    </div>
  );
};

InviteComponent.propTypes = {
  match: PropTypes.object.isRequired,
};

export default InviteComponent;
