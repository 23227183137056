import {asyncStartAction, asyncFinishAction} from '../../../../store/actions/ui/async';
import {dispatch} from "rxjs/internal/observable/pairs";
import {USER_GET_PROFILE_SUC} from "../../../../store/actions/data/user";

export const GET_ROSTER = 'roster.getRoster';
export const getRoster = (schoolId, sportId, query = null) => (dispatch) => {
  dispatch(asyncStartAction(GET_ROSTER));
  dispatch({
    type: GET_ROSTER,
    schoolId,
    sportId,
    query
  });
};


export const GET_SCHOOL_TEAM_SCHEDULE = 'roster.getSchoolTeamSchedule';
export const getSchoolTeamSchedule = (schoolTeamIds) => (dispatch) => {
    dispatch(asyncStartAction(GET_SCHOOL_TEAM_SCHEDULE));
    dispatch({
        type: GET_SCHOOL_TEAM_SCHEDULE,
        schoolTeamIds,
        dispatch,
    });
};

export const GET_SCHOOL_TEAM_SCHEDULE_SUCCESS = 'roster.getSchoolTeamScheduleSuccess';
export const getSchoolTeamScheduleSuccess = (data) => ({
  type: GET_SCHOOL_TEAM_SCHEDULE_SUCCESS,
  data,
});



export const UPDATE_HEAD_COACH = 'roster.updateHeadCoach';
export const updateHeadCoach = (id, props) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_HEAD_COACH));
  dispatch({
    type: UPDATE_HEAD_COACH,
    id,
    props,
  });
};

export const CREATE_HEAD_COACH = 'roster.createHeadCoach';
export const createHeadCoach = (props) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_HEAD_COACH));
  dispatch({
    type: CREATE_HEAD_COACH,
    props,
  });
};

export const DELETE_HEAD_COACH = 'roster.deleteHeadCoach';
export const deleteHeadCoach = (id, schoolTeamId) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_HEAD_COACH));
  dispatch({
    type: DELETE_HEAD_COACH,
    id,
    schoolTeamId,
  });
};

export const UPDATE_TWITTER_LINK = 'roster.updateTwitterLink';
export const updateTwitterLink = (props) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_TWITTER_LINK));
  dispatch({
    type: UPDATE_TWITTER_LINK,
    props,
  });
};

export const DELETE_TWITTER_LINK = 'roster.deleteTwitterLink';
export const deleteTwitterLink = (id, schoolTeamId) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_TWITTER_LINK));
  dispatch({
    type: DELETE_TWITTER_LINK,
    id,
    schoolTeamId,
  });
};

export const UPDATE_RECRUITING_COORDINATOR = 'roster.updateRecruitingCoordinator';
export const updateRecruitingCoordinator = (id, props) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_RECRUITING_COORDINATOR));
  dispatch({
    type: UPDATE_RECRUITING_COORDINATOR,
    id,
    props,
  });
};

export const CREATE_RECRUITING_COORDINATOR = 'roster.createRecruitingCoordinator';
export const createRecruitingCoordinator = (props) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_RECRUITING_COORDINATOR));
  dispatch({
    type: CREATE_RECRUITING_COORDINATOR,
    props,
  });
};

export const DELETE_RECRUITING_COORDINATOR = 'roster.deleteRecruitingCoordinator';
export const deleteRecruitingCoordinator = (id, schoolTeamId) => (dispatch) => {
  dispatch(asyncStartAction(DELETE_RECRUITING_COORDINATOR));
  dispatch({
    type: DELETE_RECRUITING_COORDINATOR,
    id,
    schoolTeamId,
  });
};

export const GET_PROFILES = 'roster.getProfiles';
export const getProfiles = uuids => (dispatch) => {
  dispatch(asyncStartAction(GET_PROFILES));
  dispatch({
    type: GET_PROFILES,
    uuids,
    dispatch,
  });
};

export const UPDATE_ROSTER_ATHLETE = 'roster.updateRosterAthlete';
export const updateRosterAthlete = (athlete, updatedFormValue, allAthlete) => (dispatch) => {
  dispatch(asyncFinishAction(UPDATE_ROSTER_ATHLETE, UPDATE_ROSTER_ATHLETE, {
    type: UPDATE_ROSTER_ATHLETE,
    athlete,
    updatedFormValue,
    allAthlete
  }));
};

export const UPDATE_MOVED_ATHLETE = 'roster.updateMovedAthlete';
export const updateMovedAthlete = (teamId, athletes, allAthlete) => (dispatch) => {
  dispatch(asyncFinishAction(UPDATE_MOVED_ATHLETE, UPDATE_MOVED_ATHLETE, {
    type: UPDATE_MOVED_ATHLETE,
    athletes,
    teamId,
    allAthlete
  }));
};

export const GET_SCHOOL_TEAMS_USERS = 'roster.getSchoolTeamsUsers';
export const getSchoolTeamsUsers = (sportId, schoolId) => (dispatch) => {
  dispatch(asyncStartAction(GET_SCHOOL_TEAMS_USERS));
  dispatch({
    type: GET_SCHOOL_TEAMS_USERS,
    sportId,
    schoolId,
  });
};


export const REMOVE_USER_FROM_TEAM = 'roster.removeUserFromTeam';
export const removeUserFromTeam = (userTeamId,total,currentCount) => (dispatch) => {
  dispatch(asyncStartAction(REMOVE_USER_FROM_TEAM));
  dispatch({
    type: REMOVE_USER_FROM_TEAM,
    userTeamId,
    total,
    currentCount,
  });
};
export const REMOVE_USER_FROM_PROSPECT_TAM = 'roster.removeUserFromProspectTeam';
export const removeUserFromProspectTeam = (userId, schoolTeamId) => (dispatch) => {
  dispatch(asyncStartAction(REMOVE_USER_FROM_PROSPECT_TAM));
  dispatch(asyncStartAction(SET_LOADING_FOR_RECONFIGURE_PAGE));
  dispatch({
    type: REMOVE_USER_FROM_PROSPECT_TAM,
    userId,
    schoolTeamId,
  });
}

export const ADD_USER_TO_SCHOOL_TEAM = 'roster.addUserToSchoolTeam';
export const addUserToSchoolTeam = (userId,values,athlete,total,currentCount) => (dispatch) => {
  dispatch(asyncStartAction(ADD_USER_TO_SCHOOL_TEAM));
  dispatch({
    type: ADD_USER_TO_SCHOOL_TEAM,
    userId,
    values,
    athlete,
    total,
    currentCount,
  });
};



export const CREATE_BULK_ATHLETE_PROFILES = 'roster.createBulkAthleteProfiles';
export const createBulkAthleteProfiles =
  (profiles, schoolId, sportId) => (dispatch) => {
    dispatch(asyncStartAction(CREATE_BULK_ATHLETE_PROFILES));
    dispatch({
      type: CREATE_BULK_ATHLETE_PROFILES,
      profiles,
      schoolId,
      sportId,
      dispatch,
    });
  };

export const CREATE_ATHLETE_PROFILE = 'roster.createAthleteProfile';
export const createAthleteProfile = (profile, schoolId, sportId, rowNumber) => (dispatch) => {
  dispatch(asyncStartAction(CREATE_ATHLETE_PROFILE));
  dispatch({
    type: CREATE_ATHLETE_PROFILE,
    profile,
    schoolId,
    sportId,
    dispatch,
    rowNumber,
  });
};

export const CREATE_BULK_ATHLETE_PROFILES_AND_INVITE = 'roster.createBulkAthleteProfilesAndInvite';
export const createBulkAthletesProfileAndInvite = dispatch =>
  (profiles, schoolId, sportId) => {
    dispatch(asyncStartAction(CREATE_BULK_ATHLETE_PROFILES_AND_INVITE));
    dispatch({
      type: CREATE_BULK_ATHLETE_PROFILES_AND_INVITE,
      dispatch,
      profiles,
      schoolId,
      sportId,
    });
  };

export const CREATE_ATHLETE_PROFILE_AND_INVITE = 'roster.createAthleteProfileAndInvite';
export const createAthleteProfileAndInvite = dispatch => (profile, schoolId, sportId, rowNumber) => {
  dispatch(asyncStartAction(CREATE_ATHLETE_PROFILE_AND_INVITE));
  dispatch({
    type: CREATE_ATHLETE_PROFILE_AND_INVITE,
    dispatch,
    profile,
    schoolId,
    sportId,
    rowNumber,
  });
};

export const ATHLETE_INVITE = 'roster.athleteInvite';
export const athleteInvite = dispatch => (profile, schoolId, sportId, rowNumber) => {
  dispatch(asyncStartAction(ATHLETE_INVITE));
  dispatch({
    type: ATHLETE_INVITE,
    dispatch,
    profile,
    schoolId,
    sportId,
    rowNumber,
  });
};
export const ATHLETE_INVITE_CHUNK_COUNT = 'roster.athleteInviteChunkCount';
export const athleteInviteChunkCount = dispatch => (count) => {
  dispatch(asyncFinishAction(ATHLETE_INVITE_CHUNK_COUNT, ATHLETE_INVITE_CHUNK_COUNT, {}));
}

export const ATHLETE_INVITE_CHUNK_STATE = 'roster.athleteInviteChunkState';
export const UPDATE_ATHLETE_INVITE_CHUNK_STATE = 'roster.updateAthleteInviteChunkState';
export const athleteInviteChunkState = (chunks) => dispatch => {
  dispatch(asyncFinishAction(ATHLETE_INVITE_CHUNK_STATE, ATHLETE_INVITE_CHUNK_STATE, {chunks: chunks}));
}

export const UPDATE_ATHLETE_PROFILE_INFO = 'roster.updateAthleteProfileInfo';
export const updateAthleteProfileInfo = (
  uuid,
  profile,
) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_ATHLETE_PROFILE_INFO));
  dispatch({
    type: UPDATE_ATHLETE_PROFILE_INFO,
    uuid,
    profile,
  });
};

export const UPDATE_ATHLETE_TEAM_INFO = 'roster.updateAthleteTeamInfo';
export const SET_LOADING_FOR_RECONFIGURE_PAGE = 'roster.setLoadingForReconfigure';
export const updateAthleteTeamInfo = (
  teamUserId,
  athleteId,
  values,
  match,
  schoolId,
  location,
  total,
  currentCount,
) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_ATHLETE_TEAM_INFO));
  if(values.teamName){
    dispatch(asyncStartAction(SET_LOADING_FOR_RECONFIGURE_PAGE));
  }
  dispatch({
    type: UPDATE_ATHLETE_TEAM_INFO,
    teamUserId,
    values,
    athleteId,
    match,
    schoolId,
    location,
    total,
    currentCount,
  });
};

export const UPDATE_ATHLETE_INFO = 'roster.updateAthleteInfo';
export const updateAthleteInfo = (
  teamUserId,
  athleteId,
  values,
  match,
  schoolId,
  location,
  loading = false
) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_ATHLETE_INFO));
  if (loading) {
    dispatch(asyncStartAction(SET_LOADING_FOR_RECONFIGURE_PAGE));
  }
  dispatch({
    type: UPDATE_ATHLETE_INFO,
    teamUserId,
    values,
    athleteId,
    match,
    schoolId,
    location,
  });
};


export const UPDATE_USER_TEAM_INFO = 'roster.updateUserTeamInfo';
export const updateUserTeamInfo = (
  teamUserId,
  athleteId,
  values,
  match,
  schoolId,
  location,
) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_USER_TEAM_INFO));
  dispatch({
    type: UPDATE_USER_TEAM_INFO,
    teamUserId,
    values,
    athleteId,
    match,
    schoolId,
    location,
  });
};

export const UPDATE_USER_SPORTS_INFO = 'roster.updateUserSportsInfo';
export const updateUserSportsInfo = (
  userId,
  sportId,
  values,
  userTeamId,
) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_USER_SPORTS_INFO));
  dispatch({
    type: UPDATE_USER_SPORTS_INFO,
    userId,
    sportId,
    values,
    userTeamId,
  });
};

export const CHANGE_ATHLETES_TEAM = 'roster.changeAthletesTeam';
export const changeAthletesTeam = (teamUserId, newTeamId) => (dispatch) => {
  dispatch(asyncStartAction(CHANGE_ATHLETES_TEAM));
  dispatch({
    type: CHANGE_ATHLETES_TEAM,
    teamUserId,
    newTeamId,
  });
};

export const CHANGE_VIEW_SELECTION = 'roster.changeViewSelection';
export const changeViewSelection = viewSelection => ({
  type: CHANGE_VIEW_SELECTION,
  viewSelection,
});

export const UPDATE_CARD_VIEW = 'roster.updateCardView';
export const updateCardView = (viewSelection) => (dispatch) => {
  dispatch(asyncStartAction(UPDATE_CARD_VIEW));
  dispatch({
    type: UPDATE_CARD_VIEW,
    viewSelection,
  });
};

export const CHECK_IF_CARD_VIEW = 'roster.checkIfCardView';
export const checkIfCardView = () => (dispatch) => {
  dispatch(asyncStartAction(CHECK_IF_CARD_VIEW));
  dispatch({
    type: CHECK_IF_CARD_VIEW,
  })
};

export class AthleteFilter {
  constructor() {
    this.name = '';
    this.selected = [];
  }
}

export const UPDATE_FILTERS = 'roster.updateFilters';
export const updateFilters = athleteFilter => ({
  type: UPDATE_FILTERS,
  athleteFilter,
});

export const UPDATE_SEARCH = 'roster.updateSearch';
export const updateSearch = search => ({
  type: UPDATE_SEARCH,
  search,
});

export const CHANGE_SORT_BY = 'roster.changeSortBySelection';
export const changeSortBySelection = sortBySelection => ({
  type: CHANGE_SORT_BY,
  sortBySelection,
});

export const UPDATE_TEAM_NAME = 'roster.updateTeamName';
export const updateTeamName = values => ({
  type: UPDATE_TEAM_NAME,
  values,
});

export const GET_PROFILE_BY_EMAIL = 'roster.getProfileByEmail';
export const getProfileByEmail = email => (dispatch) => {
  dispatch(asyncStartAction(GET_PROFILE_BY_EMAIL));
  dispatch({
   type: GET_PROFILE_BY_EMAIL,
   email,
 });
}

export const UPLOAD_SCHEDULE = 'SchoolTeam.upload.schedule';
export const uploadSchedule = ( schoolTeamId, file ) => dispatch => {
  dispatch(asyncStartAction(UPLOAD_SCHEDULE, "Loading..."))
  dispatch({
    type: UPLOAD_SCHEDULE,
    schoolTeamId,
    file,
    dispatch,
  });
}

export const DELETE_SCHEDULE = 'UserTeam.delete.schedule';
export const deleteSchedule = ( schoolTeamId, scheduleId ) => dispatch => {
  dispatch(asyncStartAction(DELETE_SCHEDULE, "Loading..."))
  dispatch({
    type: DELETE_SCHEDULE,
    schoolTeamId,
    scheduleId,
    dispatch,
  });
}

export const GUEST_PROFILE_BY_EMAIL_GET = 'roster.guestProfileByEmailGet';
export const guestProfileByEmailGet = email => (dispatch) => {
  dispatch(asyncStartAction(GUEST_PROFILE_BY_EMAIL_GET));
  dispatch({
    type: GUEST_PROFILE_BY_EMAIL_GET,
    email,
  });
}

export const CONFIRM_MERGE_PROFILES = 'roster.confirmMergeProfiles';
export const confirmMergeProfiles = (existingUuid, athleteUuid, sportId, schoolId) => (dispatch) => {
  dispatch(asyncStartAction(CONFIRM_MERGE_PROFILES));
  dispatch({
    type: CONFIRM_MERGE_PROFILES,
    dispatch,
    existingUuid,
    athleteUuid,
    schoolId,
    sportId,
  });
};

export const RESET_GRID_EMAIL_PROFILE = 'roster.resetGridEmailProfile';
export const resetGridEmailProfile = () => ({
  type: RESET_GRID_EMAIL_PROFILE,
});

export default 'roster.actions.js';
