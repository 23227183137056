import { combineEpics } from 'redux-observable';

import readEpics from './read';
import createEpics from './create';
import deleteEpics from './delete';
import updateEpics from './update';

const epics = combineEpics(
  readEpics,
  createEpics,
  deleteEpics,
  updateEpics,
);

export default epics;
