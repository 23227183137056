import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {externalProfileDialogPath, externalProfileDialogTo, Routes, SportIds} from "../../../store/actions/ui/routes";
import {Route} from "react-router-dom";
import PageHeader from "../../../containers/UxProfile/PageHeader";
import TopBar from "./TopBar/TopBar";
import CreateUpdateDialog from "../../../containers/UxProfile/ExternalProfile/CreateUpdateDialog";
import ExternalProfileCard from "./ExternalProfileCard";
import {ButtonRect} from "../../UxCommon";

class GetExternalProfile extends PureComponent {
  static  propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isMyProfile: PropTypes.bool.isRequired,
    isCoach: PropTypes.bool,
    onHideClick: PropTypes.func.isRequired,
    externalProfile: PropTypes.array.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
  }


  onAddClick = () => {
    const { history, match , formInit } = this.props;
    formInit();
    history.push(externalProfileDialogTo(match.params.uuid, 'new', match.params.sport,match.params.schoolId));
  }

  render() {
    const {
      match,
      history,
      onHideClick,
      externalProfile,
      isMyProfile,
      isCoach,
      isAuthorized
    } = this.props;
    let currentSportExternalProfile  =  externalProfile ? externalProfile.filter( ep => ep.sport_id ===  SportIds[match.params.sport] ) : [];


    return (
      <div  className="Awards">
        <Route path={`/${Routes.profile}/:uuid/:page`} component={PageHeader} />
        <div style={{ position: 'relative' }}>
          <TopBar isMyProfile = {isMyProfile} onHideClick={onHideClick} match={match}   history={history}/>

          <Route path = {externalProfileDialogPath} component={CreateUpdateDialog} />
          {
            (currentSportExternalProfile.length > 0) ? (
              <div className="Awards_AwardsList vc-external-profile-logo">
                {
                  currentSportExternalProfile.map((externalProfile) => {

                    return (
                      <ExternalProfileCard
                        key={externalProfile.id}
                        currentSportExternalProfile={ externalProfile}
                        match ={match}
                        isAuthorized={isAuthorized}
                      />
                    );
                  })
                }
              </div>

            ) : (
              <div className="Awards_NoAwards">
                <div className="Awards_NoAwardsMsg">
                  {`No external profiles have been added to ${(isMyProfile || isCoach) ? 'your' : 'this'} profile`}
                </div>
                {
                  (isMyProfile || isCoach) && (
                    <ButtonRect
                      className="Awards_NoAwardsBtn primary"
                      onClick = {this.onAddClick}

                    >
                      Add an External Profile
                    </ButtonRect>
                  )
                }
              </div>
            )
          }

        </div>
      </div>
    )

  }
}

export default GetExternalProfile
