import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ButtonRect } from '../../../components/UxCommon';
import { AllModules } from '@ag-grid-enterprise/all-modules';

const staffRenderer = (params) => {
  if (params.value === '' || params.value === 'N/A') return 'N/A';
  return `<a target="_blank" href="${params.value}">Staff</a>`;
};

const websiteRenderer = (params) => {
  if (params.value === '' || params.value === 'N/A') return 'N/A';
  return `<a target="_blank" href="${params.value}">Website</a>`;
};

const admissionsRenderer = (params) => {
  if (params.value === '' || params.value === 'N/A') return 'N/A';
  return `<a target="_blank" href="${params.value}">Admissions</a>`;
};

class CollegeContact extends PureComponent {
  static propTypes = {
    isContactLoaded: PropTypes.bool.isRequired,
    fetchCollegeContacts: PropTypes.func.isRequired,
    collegeContacts: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.allFilterRef = React.createRef();
  }

  state = {
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center',
        },
      ],
    },
    columnDefs: [
      {
        headerName: 'School', field: 'school', resizable: true, minWidth: 300
      },
      {
        headerName: 'Division',
        field: 'division',
        sortable: true,
        filter: 'agSetColumnFilter',
        resizable: true,
        filterParams: {
          suppressSorting: true,
        },
        floatingFilter: true,
      },
      {
        headerName: 'State',
        field: 'state',
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
      },
      {
        headerName: 'Athletic Website',
        field: 'athleticWebsite',
        resizable: true,
        cellRenderer: websiteRenderer,
        floatingFilter: true,
        maxWidth: 140,
      },
      {
        headerName: 'Staff Directory',
        field: 'staffDirectory',
        resizable: true,
        cellRenderer: staffRenderer,
        maxWidth: 140,
      },
      {
        headerName: 'Admission',
        field: 'admissionLink',
        resizable: true,
        cellRenderer: admissionsRenderer,
        maxWidth: 120,
      }],
    modules: AllModules,
  };

  componentDidMount() {
    const { isContactLoaded, fetchCollegeContacts } = this.props;
    if (!isContactLoaded) {
      fetchCollegeContacts();
    } else {
      this.shouldUpdate();
    }
  }

  componentDidUpdate() {
    this.shouldUpdate();
  }

  onFilterTextBoxChanged = () => {
    this.gridApi.setQuickFilter(this.allFilterRef.current.value);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.allFilterRef.current.focus();
  }

  clearFilters = () => {
    this.allFilterRef.current.value = '';
    this.gridApi.setQuickFilter(null);
    this.gridApi.setFilterModel(null);
  }

  shouldUpdate = () => {
    const { isContactLoaded, collegeContacts } = this.props;
    if (isContactLoaded && !this.isContactsMapped) {
      this.isContactsMapped = true;
      this.setState({ rowData: collegeContacts });
    }
  }

  isContactsMapped = false;

  render() {
    return (
      <div className="CoachDashboardLeaderboards" style={{ width: '100%', height: '900px' }}>
        <div className="CoachDashboard_TeamsHeader">College Directory</div>
        <div>
          <input
            type="text"
            id="filter-text-box"
            className="searchDirectory"
            placeholder="Search College Directory"
            onInput={this.onFilterTextBoxChanged}
            ref={this.allFilterRef}
          />
          <ButtonRect onClick={this.clearFilters} className="clearFilterButton">Clear All Filters</ButtonRect>
        </div>
        <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            sideBar={this.state.sideBar}
            onGridReady={this.onGridReady}
            statusBar={this.state.statusBar}
            suppressDragLeaveHidesColumns
          />
        </div>
      </div>
    );
  }
}

export default CollegeContact;
