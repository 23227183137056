import { combineEpics, ofType } from 'redux-observable';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import {
  getPlayerDataCategoryTestResultSuccess,
  mapPlayerDataApiToUi,
  mapStandardTestBankApiToUi,
  mapStandardTestCategoryApiToUi,
  mapStandardTestObjectApiToUi,
  mapStandardTestSubbankApiToUi,
  mapStandardUnitApiToUi,
  playerGetTestCategories,
  testsGetPerCategory,
  testsGetPlayerData,
  testsGetPlayerDataSuccess,
  testsGetStandardSuccess,
  testsGetStdTestBanks,
  testsGetStdTestCategories,
  testsGetStdTestObjects,
  testsGetStdTestSubbanks,
  testsGetStdUnits,
  apiGetPlayerPersonalRecord, sortByCategoryOrderAsc
} from '../../../../data/sport';

import { asyncFinishAction, asyncStartAction } from '../../../async';

import {
  checkForkErrors,
  commonApiCallFragment,
  getTokenFragment,
  manualCancelApiCallFragment,
  getTokenFragmentMergeMap,
} from '../../../uxProfile/utils';
import { Routes } from '../../../routes';
import {
PD_GET_CAT_PERF_TESTS,
PD_GET_CAT_STRENGTH_TESTS,
PD_GET_TESTS,
PD_GET_PERSONAL_RECORDS,
PD_GET_DATA
} from '../actions';
import {PlayerDataTestResult} from '../../../../data/sport/tests';

const getCategoryPerfTests = action$ => (
  action$.pipe(
    ofType(PD_GET_CAT_PERF_TESTS),
    getTokenFragmentMergeMap(),
    mergeMap(({ action, token }) => (
      testsGetPerCategory(action.uuid, action.categoryId, token).pipe(commonApiCallFragment(
        action$,
        action,
        'getCategoryTests',
      ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(
          asyncStartAction(PD_GET_DATA),
          getPlayerDataCategoryTestResultSuccess(
            result.action.uuid,
            result.action.categoryId,
            result.response,
          ),
          asyncFinishAction(
            PD_GET_CAT_PERF_TESTS, 'get',
            {
              bank: result.action.bank,
            },
          ),
          asyncFinishAction(PD_GET_DATA)
        );
      }
      return of(result.action);
    }),
  )
);

const getCategoryStrTests = action$ => (
  action$.pipe(
    ofType(PD_GET_CAT_STRENGTH_TESTS),
    getTokenFragmentMergeMap(),
    mergeMap(({ action, token }) => (
      testsGetPerCategory(action.uuid, action.categoryId, token).pipe(commonApiCallFragment(
        action$,
        action,
        'getCategoryTests',
      ))
    )),
    mergeMap((result) => {
      if (result.success) {
        return of(
          asyncStartAction(PD_GET_DATA),
          getPlayerDataCategoryTestResultSuccess(
            result.action.uuid,
            result.action.categoryId,
            result.response,
          ),
          asyncFinishAction(
            PD_GET_CAT_STRENGTH_TESTS, 'get',
            {
              bank: result.action.bank,
            },
          ),
          asyncFinishAction(PD_GET_DATA),
        );
      }
      return of(result.action);
    }),
  )
);

export const getTestsEpic = (action$, state$) => {
  const std = () => state$.value.data.sport.testsStd;
  return action$.pipe(
    ofType(PD_GET_TESTS),
    getTokenFragment(),
    switchMap(({ action, token }) => {
      let stdGets = [];
      if (!std()) {
        stdGets = [
          testsGetStdUnits(token).pipe(commonApiCallFragment(action$, action, 'getStdUnits', Routes.data)),
          testsGetStdTestCategories(token).pipe(commonApiCallFragment(action$, action, 'getStdTestCats', Routes.data)),
          testsGetStdTestBanks(token).pipe(commonApiCallFragment(action$, action, 'getStdTestBanks', Routes.data)),
          testsGetStdTestSubbanks(token).pipe(commonApiCallFragment(action$, action, 'getStdTestSubbanks', Routes.data)),
          testsGetStdTestObjects(token).pipe(commonApiCallFragment(action$, action, 'getStdTestObjects', Routes.data)),
          playerGetTestCategories(action.uuid, token).pipe(commonApiCallFragment(action$, action, 'getPlayerTestCats', Routes.data)),
        ];
      }
      return forkJoin(
        testsGetPlayerData(action.uuid, action.sportId, token, action.categoryId).pipe(commonApiCallFragment(action$, action, 'getPlayerData', Routes.data)),
        ...stdGets,
      ).pipe(
        checkForkErrors(),
        map((results) => {
          if (results.success) {
            if (results.results.length > 1) {
              const stdUnits = mapStandardUnitApiToUi(results.results[1].response);
              const stdTestCategories = mapStandardTestCategoryApiToUi(
                results.results[2].response,
                stdUnits,
              );
              const stdTestBanks = mapStandardTestBankApiToUi(results.results[3].response);
              const stdTestSubbanks = mapStandardTestSubbankApiToUi(
                results.results[4].response,
                stdTestBanks,
              );
              const stdTestObjects = mapStandardTestObjectApiToUi(
                results.results[5].response,
                stdTestSubbanks,
                stdTestCategories,
              );

              // categories which player has any test
              const rawPlayerTestCategories = results.results[6].response;
              const playerData = mapPlayerDataApiToUi(
                results.results[0].response,
                stdTestObjects,
                stdUnits,
                rawPlayerTestCategories,
              );
              return {
                success: true,
                std: {
                  stdUnits,
                  stdTestCategories,
                  stdTestBanks,
                  stdTestSubbanks,
                  stdTestObjects,
                  rawPlayerTestCategories,
                },
                playerData,
                outer: action,
              };
            }
            const playerData = mapPlayerDataApiToUi(
              results.results[0].response,
              std().stdTestObjects,
              std().stdUnits,
              std().rawPlayerTestCategories,
            );
            return {
              success: true,
              playerData,
              outer: action,
            };
          }
          return results;
        }),
      );
    }),
    switchMap((results) => {
      if (results.success) {
        if (std()) {
          return of(
            testsGetPlayerDataSuccess(
              results.outer.uuid,
              results.playerData,
              results.outer.categoryId,
            ),
            asyncFinishAction(PD_GET_TESTS, 'get', {}),
          );
        }
        return of(
          testsGetPlayerDataSuccess(results.outer.uuid, results.playerData),
          testsGetStandardSuccess(results.std),
          asyncFinishAction(PD_GET_TESTS, 'get', {}),
        );
      }
      return of(...results.actions);
    }),
  );
};

const getCombineRecordsEpic = (action$) => {
  return action$.pipe(
    ofType(PD_GET_PERSONAL_RECORDS),
    getTokenFragment(),
    switchMap(({action, token}) => (
      apiGetPlayerPersonalRecord(token, action.uuid, action.sport).pipe(manualCancelApiCallFragment(
        action$,
        action,
        'playerCombineRecords',
      ))
    )),
    switchMap((result) => {

      if (result.success) {
        const playerData = result.response.combine_records.sort((a,b) => sortByCategoryOrderAsc(a,b)).map(data => {
          const playerInfo = new PlayerDataTestResult();
          playerInfo.fromApiResponse(data);
          return playerInfo
        });
        return of(asyncFinishAction(result.action.type, PD_GET_PERSONAL_RECORDS, {
          playerData
        }));
      }
      if (result.actions) return of(...result.actions);
      return of(result.action);
    }),
  )

}

export const readEpics = combineEpics(
  getTestsEpic,
  getCombineRecordsEpic,
  getCategoryStrTests,
  getCategoryPerfTests);
