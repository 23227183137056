import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import { Row, Col } from '../../UxCommon';
import { TextControl, Button, Errors } from '../../UxCommon/Form';
import {parserInstagramHandleSlug, parserNumberAndCharcater, parserSlug} from '../../../utils/parsers';

class VanityUrl extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingMsg: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    rrfForm: PropTypes.object.isRequired,
    formInit: PropTypes.func.isRequired,
    formNeedsInit: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { formInit, profile } = this.props;
    if (profile.instagram) {
      formInit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile, formNeedsInit, formInit } = this.props;
    if ((prevProps.profile.instagram != profile.instagram) && formNeedsInit) {
      formInit();
    }
  }

  submit = () => {
    this.props.submit();
  };

  renderUrl = (url) => {
    if (typeof window !== 'undefined') {
      return `https://www.instagram.com/${url.replace('@', '')}`;
    //   let virtualCombineMktSite = "instgram.com/";
    //   switch (window.location.host) {
    //     case 'staging-app.virtualcombine.com':
    //       virtualCombineMktSite = 'https://staging-mktg.virtualcombine.com';
    //       break;
    //     case 'dev-app.virtualcombine.com':
    //       virtualCombineMktSite = 'https://mktg-beta.virtualcombine.com';
    //       break;
    //     default:
    //       virtualCombineMktSite = 'https://virtualcombine.com';
    //       break;
    //   }
    //   return `${virtualCombineMktSite}/me/${url}`;
    }
    return '';
  };

  render() {
    const {
      rrfForm,
      message,
      profile,
    } = this.props;
    return (
      <Form model="forms.vanityUrl" onSubmit={this.submit}>
        <Row>
          <Col className="UxEditProfile_VanityUrl_Input">
            {profile ? (
              <div>
                <TextControl
                  label="Instagram"
                  placeholder="Instagram"
                  model="forms.vanityUrl.url"
                  help="Instagram Handle"
                  rrfField={rrfForm.url}
                  parser={parserInstagramHandleSlug}
                  defaultValue=""
                  maxLength={50}
                />
                {
                  profile.instagram && <a className="UxEditProfile_VanityUrl_Displayer" target="_blank" href={this.renderUrl(profile.instagram)} >
                    {this.renderUrl(profile.instagram.replace('@', ''))}
                  </a>
                  }
              </div>
            ) : (
              <div />
            )}
          </Col>
          <Col className="col-auto UxEditProfile_VanityUrl_BtnWrapper">
            <Button
              id="forms.VanityUrl.submit"
              type="submit"
              className="UxEditProfile_VanityUrl_Btn"
            >
              Save Changes
            </Button>
          </Col>
        </Row>
        <Errors
          className="UxEditProfile_VanityUrl_Errors"
          valid={
            rrfForm.$form.valid || !Object.values(rrfForm.$form.errors).length
          }
          errors={rrfForm.$form.errors}
        />

      </Form>
    );
  }
}

export default VanityUrl;
