import { connect } from 'react-redux';
import Component from '../../../components/UxProfile/PlayerData/Stats/Stats';
import {
  getSelectedSport,
  getCurrentUuid,
  getSeasonStats,
  getUserTeams,
} from '../../UxCommon/Utils';
import { alertOrNavigate } from '../../UxCommon/NativeUtils';
import { routeChangedAlert } from '../../../store/actions/ui/routes';
import {
  hideDeleteSeasonDialog,
  statsDeleteSeason,
} from '../../../store/actions/ui/uxPlayerData';

const mapStateToProps = (state, ownProps) => {
  const currUuid = getCurrentUuid(state, ownProps.match);
  const selectedUserSport = getSelectedSport(state, ownProps.match);
  let stdPositions = [];
  if (selectedUserSport
    && state.data.sport.statsStd
    && state.data.sport.statsStd[selectedUserSport.sportId]) {
    stdPositions = state.data.sport.statsStd[selectedUserSport.sportId].positions;
  }
  let sportInfo = null;
  if (selectedUserSport) {
    sportInfo = state.data.sport.sports.find(s => s.id === selectedUserSport.sportId);
  }
  return {
    currentRoute: state.ui.app.routes.currentRoute,
    authUuid: state.data.cognito.uuid,
    currUuid,
    userStats: getSeasonStats(state, ownProps.match),
    selectedUserSport,
    isDeleteSeasonDialogShown: !!state.ui.uxPlayerData.stats.isDeleteSeasonDialogShown,
    loading: !!state.ui.uxPlayerData.stats.statsLoadingMsg,
    loadingMsg: state.ui.uxPlayerData.stats.statsLoadingMsg,
    newSeasons: state.ui.uxPlayerData.stats.newSeasons
      .filter(ns => ns.season.team.sportId === state.ui.app.routes.currentSportId),
    stdPositions,
    teams: getUserTeams(state, ownProps.match),
    addSeasonFormIsShown: !!Object.values(state.ui.uxPlayerData.stats.addSeasonFormsShown).length,
    sportInfo,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { state, ...newStateProps } = stateProps;
  return {
    ...newStateProps,
    ...ownProps,
    changeRoute: (pathname) => {
      dispatch(routeChangedAlert(pathname));
    },
    onCancelDeleteSeasonClick: () => {
      dispatch(hideDeleteSeasonDialog());
    },
    deleteSeason: () => {
      const { teamId, positionId, season } = state.ui.uxPlayerData.stats.isDeleteSeasonDialogShown;
      dispatch(statsDeleteSeason(teamId, positionId, season));
    },
    alertOrNavigate: navigateTo => alertOrNavigate(state, dispatch, navigateTo),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Component);

export default Container;
