import moment from 'moment';
import {
  convertInchesToCm,
  convertInchesToMetre
} from '../../../modules/CodeSignUp/PhysiquePage/PhysiquePage.validation';
import { customTOFixed } from '../../../store/actions/data/user/profile/models';

export const formOnEnterPress = submitBtnId =>
  (e) => {
    if (e.key === 'Enter') {
      document.getElementById(submitBtnId)
        .focus();
    }
  };

export const CheckForNewLine = inputId => (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    const ele = document.getElementById(inputId);
    ele.value += '\n';
  }
};

export const gradYears = () => {
  let y = 2000;
  const ys = [];
  while (y < moment()
    .year() + 10) {
    ys.push({
      value: `${y}`,
      text: `${y}`,
    });
    y += 1;
  }
  return ys;
};


export const gender = () => [
  {
    value: 'male',
    text: 'Male',
  },
  {
    value: 'female',
    text: 'Female',
  },
];

export const roles = () => [
  {
    value: 'coach',
    text: 'Coaches',
  },
  {
    value: 'athlete',
    text: 'Athletes',
  },
];

export const sortedGrade = {
  'N/A': 'N/A',
  'College Senior': 'College Senior',
  'College Junior': 'College Junior',
  'College Sophomore': 'College Sophomore',
  'College Freshman': 'College Freshman',
  'Redshirt Freshman': 'Redshirt Freshman',
  13: '13th',
  12: '12th',
  11: '11th',
  10: '10th',
  9: '9th',
  8: '8th',
  7: '7th',
  6: '6th',
  5: '5th',
  4: '4th',
  3: '3rd',
  2: '2nd',
  1: '1st',
  K: 'K',
};

export const batOptions = {
  R: "Right",
  L: "Left",
  S: "Switch",
  Right: "Right",
  Left: "Left",
  Switch: "Switch"
}

export const sortedGradeArray = [
  { value: 'N/A', label: 'N/A' },
  { value: 'College Senior', label: 'College Senior' },
  { value: 'College Junior', label: 'College Junior' },
  { value: 'College Sophomore', label: 'College Sophomore' },
  { value: 'College Freshman', label: 'College Freshman' },
  { value: 'Redshirt Freshman', label: 'Redshirt Freshman' },
  { value: '13', label: '13th' },
  { value: '12', label: '12th' },
  { value: '11', label: '11th' },
  { value: '10', label: '10th' },
  { value: '9', label: '9th' },
  { value: '8', label: '8th' },
  { value: '7', label: '7th' },
  { value: '6', label: '6th' },
  { value: '5', label: '5th' },
  { value: '4', label: '4th' },
  { value: '3', label: '3rd' },
  { value: '2', label: '2nd' },
  { value: '1', label: '1st' },
  { value: 'K', label: 'K' },
];

export const batsThrows = (option) => {
  const batsThrowsOptions = [
    {
      value: '',
      text: option,
    },
    {
      value: 'L',
      text: 'Left',
    },
    {
      value: 'R',
      text: 'Right',
    },
    {
      value: 'S',
      text: 'Switch',
    }
  ];
  return batsThrowsOptions;
};

export const restrictedSchoolIds = [
  'f5649710-7587-11e9-9c22-6902c03479e6',
  'f19c2da0-7587-11e9-b472-bb3a79041759',
  'f196c330-7587-11e9-9d31-1335259d5897',
  'f78e75a0-7587-11e9-9346-eb603dc65bdd',
  'f5687f30-7587-11e9-af06-4d14b16f103f',
  'f1dc9ef0-7587-11e9-b2b7-dd47116c59ae',
  'f1e23480-7587-11e9-b6f9-897170ac5ba9',
  'efb99b20-7587-11e9-9de9-e14bf5680e4c',
  'f8c4d180-7587-11e9-be81-710d9c79d557',
  '4ad4c180-758a-11e9-988a-a35b1b4c9a6f',
  '0c4b2da0-7588-11e9-9c0f-f14da7b96b99',
  'f7deec50-7587-11e9-b23e-f305352f7adc',
]

export const grades = () => {
  const gradeOptions = [
    {
      value: 'N/A',
      text: 'N/A',
    },
    {
      value: '12',
      text: '12th',
    },
    {
      value: '11',
      text: '11th',
    },
    {
      value: '10',
      text: '10th',
    },
    {
      value: '9',
      text: '9th',
    },
    {
      value: '8',
      text: '8th',
    },
    {
      value: '7',
      text: '7th',
    },
    {
      value: '6',
      text: '6th',
    },
    {
      value: '5',
      text: '5th',
    },
    {
      value: '4',
      text: '4th',
    },
    {
      value: '3',
      text: '3rd',
    },
    {
      value: '2',
      text: '2nd',
    },
    {
      value: '1',
      text: '1st',
    },
    {
      value: 'K',
      text: 'K',
    },
    {
      value: '13',
      text: '13th',
    },
    {
      value: 'Redshirt Freshman',
      text: 'Redshirt Freshman',
    },
    {
      value: 'College Freshman',
      text: 'College Freshman',
    },
    {
      value: 'College Sophomore',
      text: 'College Sophomore',
    },
    {
      value: 'College Junior',
      text: 'College Junior',
    },
    {
      value: 'College Senior',
      text: 'College Senior',
    },
  ];
  return gradeOptions;
};

export const gradeYears = () => {
  const gradYearOptions = [
    {
      value: '2019',
      text: '2019',
    },
    {
      value: '2020',
      text: '2020',
    },
    {
      value: '2021',
      text: '2021',
    },
    {
      value: '2022',
      text: '2022',
    },
    {
      value: '2023',
      text: '2023',
    },
    {
      value: '2024',
      text: '2024',
    },
    {
      value: '2025',
      text: '2025',
    },
    {
      value: '2026',
      text: '2026',
    },
    {
      value: '2027',
      text: '2027',
    },
    {
      value: '2028',
      text: '2028',
    },
    {
      value: '2029',
      text: '2029',
    },
    {
      value: '2030',
      text: '2030',
    },
    {
      value: '2031',
      text: '2031',
    },
    {
      value: '2032',
      text: '2032',
    },
    {
      value: '2033',
      text: '2033',
    },
    {
      value: '2034',
      text: '2034',
    },
    {
      value: '2035',
      text: '2035',
    },
    {
      value: '2036',
      text: '2036',
    },
    {
      value: '2037',
      text: '2037',
    },
    {
      value: '2038',
      text: '2038',
    },
    {
      value: '2039',
      text: '2039',
    },
    {
      value: '2040',
      text: '2040',
    },
  ];
  return gradYearOptions;
};
export const getPositionWidgetLabel = (sportId) => {
  let label = 'Select the positions you play';
  if (sportId === 9) { // swimming
    label = 'Select your events';
  } else if (sportId === 13) { // athletictraining
    label = 'Select your sports';
  } else if (sportId === 16) { // trackfield
    label = 'Select your events';
  } else if (sportId === 14) { // wrestling
    label = 'Select your weight class';
  }
  return label;
};

export const positions = (sportId, profileGender) => {
  const positionOptions = [];
  positionOptions[1] = [
    {
      code: 'CB',
      name: 'Cornerback',
    },
    {
      code: 'DE',
      name: 'Defensive End',
    },
    {
      code: 'DL',
      name: 'Defensive Line',
    },
    {
      code: 'ILB',
      name: 'Inside Linebacker',
    },
    {
      code: 'KR',
      name: 'Kick Return Specialist',
    },
    {
      code: 'KO',
      name: 'Kickoff Specialist',
    },
    {
      code: 'OL',
      name: 'Offensive Line',
    },
    {
      code: 'OLB',
      name: 'Outside Linebacker',
    },
    {
      code: 'K',
      name: 'Place Kicker',
    },
    {
      code: 'P',
      name: 'Punter',
    },
    {
      code: 'QB',
      name: 'Quarterback',
    },
    {
      code: 'RB',
      name: 'Running Back',
    },
    {
      code: 'S',
      name: 'Safety',
    },
    {
      code: 'TE',
      name: 'Tight End',
    },
    {
      code: 'WR',
      name: 'Wide Receiver',
    },
    {
      code: 'LS',
      name: 'Long Snapper'
    }
  ];
  positionOptions[2] = [
    {
      code: 'C',
      name: 'Catcher',
    },
    {
      code: '1B',
      name: 'First Base',
    },
    {
      code: 'OF',
      name: 'Outfielder',
    },
    {
      code: 'P',
      name: 'Pitcher',
    },
    {
      code: '2B',
      name: 'Second Base',
    },
    {
      code: 'SS',
      name: 'Short Stop',
    },
    {
      code: '3B',
      name: 'Third Base',
    },
    {
      code: 'UT',
      name: 'Utility',
    },
  ];
  positionOptions[3] = [
    {
      code: 'D',
      name: 'Defense',
    },
    {
      code: 'F',
      name: 'Forward',
    },
    {
      code: 'GK',
      name: 'Goal Keeper',
    },
    {
      code: 'MF',
      name: 'Midfielder',
    },
  ];
  positionOptions[4] = [
    {
      code: 'C',
      name: 'Catcher',
    },
    {
      code: '1B',
      name: 'First Base',
    },
    {
      code: 'OF',
      name: 'Outfielder',
    },
    {
      code: 'P',
      name: 'Pitcher',
    },
    {
      code: '2B',
      name: 'Second Base',
    },
    {
      code: 'SS',
      name: 'Short Stop',
    },
    {
      code: '3B',
      name: 'Third Base',
    },
    {
      code: 'UT',
      name: 'Utility',
    },
  ];
  positionOptions[5] = [
    {
      code: 'C',
      name: 'Center',
    },
    {
      code: 'PG',
      name: 'Point Guard',
    },
    {
      code: 'PF',
      name: 'Power Forward',
    },
    {
      code: 'SG',
      name: 'Shooting Guard',
    },
    {
      code: 'SF',
      name: 'Small Forward',
    },
  ];
  positionOptions[6] = [
    {
      code: 'Amateur',
      name: 'Amateur',
    },
    {
      code: 'Pro',
      name: 'Pro',
    },
  ];
  positionOptions[7] = [
    {
      code: 'DS',
      name: 'Defensive Specialist',
    },
    {
      code: 'L',
      name: 'Libero',
    },
    {
      code: 'MB',
      name: 'Middle Blocker',
    },
    {
      code: 'OH',
      name: 'Outside Hitter',
    },
    {
      code: 'RH',
      name: 'Right Side Hitter',
    },
    {
      code: 'S',
      name: 'Setter',
    },
  ];
  positionOptions[8] = [
    {
      code: 'Runner',
      name: 'Runner',
    },
  ];
  positionOptions[9] = [
    {
      code: '50yd Freestyle',
      name: '50yd Freestyle',
    },
    {
      code: '100yd Freestyle',
      name: '100yd Freestyle',
    },
    {
      code: '200yd Freestyle',
      name: '200yd Freestyle',
    },
    {
      code: '500yd Freestyle',
      name: '500yd Freestyle',
    },
    {
      code: '100yd Backstroke',
      name: '100yd Backstroke',
    },
    {
      code: '100yd Breastroke',
      name: '100yd Breastroke',
    },
    {
      code: '100yd Butterfly',
      name: '100yd Butterfly',
    },
    {
      code: '200yd Medley Relay',
      name: '200yd Medley Relay',
    },
    {
      code: '200yd Individual Medley',
      name: '200yd Individual Medley',
    },
    {
      code: '200yd Freestyle Relay',
      name: '200yd Freestyle Relay',
    },
    {
      code: '400yd Freestyle Relay',
      name: '400yd Freestyle Relay',
    },
  ];
  positionOptions[10] = [
    {
      code: 'Diver',
      name: 'Diver',
    },
  ];
  positionOptions[11] = [
    {
      code: 'Amateur',
      name: 'Amateur',
    },
    {
      code: 'Pro',
      name: 'Pro',
    },
  ];
  positionOptions[12] = [
    {
      code: 'Singles',
      name: 'Singles',
    },
    {
      code: 'Doubles',
      name: 'Doubles',
    },
  ];
  positionOptions[13] = [
    {
      code: 'Baseball',
      name: 'Baseball',
    },
    {
      code: 'Basketball',
      name: 'Basketball',
    },
    {
      code: 'Cheerleading',
      name: 'Cheerleading',
    },
    {
      code: 'Cross Country',
      name: 'Cross Country',
    },
    {
      code: 'Diving',
      name: 'Diving',
    },
    {
      code: 'Football',
      name: 'Football',
    },
    {
      code: 'Golf',
      name: 'Golf',
    },
    {
      code: 'Hockey',
      name: 'Hockey',
    },
    {
      code: 'Lacrosse',
      name: 'Lacrosse',
    },
    {
      code: 'Soccer',
      name: 'Soccer',
    },
    {
      code: 'Softball',
      name: 'Softball',
    },
    {
      code: 'Swimming',
      name: 'Swimming',
    },
    {
      code: 'Tennis',
      name: 'Tennis',
    },
    {
      code: 'Track',
      name: 'Track',
    },
    {
      code: 'Water Polo',
      name: 'Water Polo',
    },
    {
      code: 'Wrestling',
      name: 'Wrestling',
    },
    {
      code: 'Volleyball',
      name: 'Volleyball',
    },
  ];

  // wrestling, sportId = 14
  const maleWeightClasses = [
    {
      code: '106',
      name: '106',
    },
    {
      code: '113',
      name: '113',
    },
    {
      code: '120',
      name: '120',
    },
    {
      code: '126',
      name: '126',
    },
    {
      code: '132',
      name: '132',
    },
    {
      code: '138',
      name: '138',
    },
    {
      code: '144',
      name: '144',
    },
    {
      code: '150',
      name: '150',
    },
    {
      code: '157',
      name: '157',
    },
    {
      code: '165',
      name: '165',
    },
    {
      code: '175',
      name: '175',
    },
    {
      code: '190',
      name: '190',
    },
    {
      code: '215',
      name: '215',
    },
    {
      code: '285',
      name: '285',
    },
  ];
  const femaleWeightClasses = [
    {
      code: '100',
      name: '100',
    },
    {
      code: '107',
      name: '107',
    },
    {
      code: '114',
      name: '114',
    },
    {
      code: '120',
      name: '120',
    },
    {
      code: '126',
      name: '126',
    },
    {
      code: '132',
      name: '132',
    },
    {
      code: '138',
      name: '138',
    },
    {
      code: '145',
      name: '145',
    },
    {
      code: '152',
      name: '152',
    },
    {
      code: '165',
      name: '165',
    },
    {
      code: '185',
      name: '185',
    },
    {
      code: '235',
      name: '235',
    },
  ];
  if (profileGender === 'female' || profileGender === 'Female' ) {
    positionOptions[14] = femaleWeightClasses;
  } else if (profileGender === 'male' || profileGender === 'Male') {
    positionOptions[14] = maleWeightClasses;
  } else {
    positionOptions[14] = [
      {
        code: '100',
        name: '100',
      },
      {
        code: '106',
        name: '106',
      },
      {
        code: '107',
        name: '107',
      },
      {
        code: '113',
        name: '113',
      },
      {
        code: '114',
        name: '114',
      },
      {
        code: '120',
        name: '120',
      },
      {
        code: '126',
        name: '126',
      },
      {
        code: '132',
        name: '132',
      },
      {
        code: '138',
        name: '138',
      },
      {
        code: '144',
        name: '144',
      },
      {
        code: '145',
        name: '145',
      },
      {
        code: '150',
        name: '150',
      },
      {
        code: '152',
        name: '152',
      },
      {
        code: '157',
        name: '157',
      },
      {
        code: '165',
        name: '165',
      },
      {
        code: '175',
        name: '175',
      },
      {
        code: '185',
        name: '185',
      },
      {
        code: '190',
        name: '190',
      },
      {
        code: '215',
        name: '215',
      },
      {
        code: '235',
        name: '235',
      },
      {
        code: '285',
        name: '285',
      },
    ];
  }
  positionOptions[15] = [
    {
      code: 'A',
      name: 'Attackers',
    },
    {
      code: 'M',
      name: 'Midfielders',
    },
    {
      code: 'D',
      name: 'Defenders',
    },
    {
      code: 'G',
      name: 'Goalie',
    },
  ];
  positionOptions[16] = [
    {
      code: '60m',
      name: '60m',
    },
    {
      code: '100m',
      name: '100m',
    },
    {
      code: '200m',
      name: '200m',
    },
    {
      code: '400m',
      name: '400m',
    },
    {
      code: '800m',
      name: '800m',
    },
    {
      code: '1600m',
      name: '1600m',
    },
    {
      code: '3200m',
      name: '3200m',
    },
    {
      code: '100m Hurdles',
      name: '100m Hurdles',
    },
    {
      code: '110m Hurdles',
      name: '110m Hurdles',
    },
    {
      code: '200m Hurdles',
      name: '200m Hurdles',
    },
    {
      code: '300m Hurdles',
      name: '300m Hurdles',
    },
    {
      code: '400m Hurdles',
      name: '400m Hurdles',
    },
    {
      code: 'Long Jump',
      name: 'Long Jump',
    },
    {
      code: 'Triple Jump',
      name: 'Triple Jump',
    },
    {
      code: 'High Jump',
      name: 'High Jump',
    },
    {
      code: 'Pole Vault',
      name: 'Pole Vault',
    },
    {
      code: 'Shot Put',
      name: 'Shot Put',
    },
    {
      code: 'Discus',
      name: 'Discus',
    },
    {
      code: 'Javelin Throw',
      name: 'Javelin Throw',
    },
    {
      code: 'Hammer Throw',
      name: 'Hammer Throw',
    },
    {
      code: '4x100m Relay',
      name: '4x100m Relay',
    },
    {
      code: '4x200m Relay',
      name: '4x200m Relay',
    },
    {
      code: '4x400m Relay',
      name: '4x400m Relay',
    },
    {
      code: 'Seated 100m',
      name: 'Seated 100m',
    },
    {
      code: 'Seated 400m',
      name: 'Seated 400m',
    },
    {
      code: 'Seated 800m',
      name: 'Seated 800m',
    },
    {
      code: 'Seated Shot Put',
      name: 'Seated Shot Put',
    },
  ];
  positionOptions[17] = [
    {
      code: 'GK',
      name: 'Goal Keeper',
    },
    {
      code: '2M',
      name: 'Two Meters',
    },
    {
      code: 'DR',
      name: 'Driver',
    },
    {
      code: '2MD',
      name: 'Two Meter Defender',
    },
    {
      code: 'UT',
      name: 'Utility',
    },
    {
      code: 'ATK',
      name: 'Attacker',
    },
    {
      code: 'C',
      name: 'Center',
    },
    {
      code: 'CD',
      name: 'Center Defender',
    },
  ];
  positionOptions[18] = [
    {
      code: 'C',
      name: 'Center',
    },
    {
      code: 'D',
      name: 'Defenseman',
    },
    {
      code: 'G',
      name: 'Goalie',
    },
    {
      code: 'LW',
      name: 'Left Wing',
    },
    {
      code: 'RW',
      name: 'Right Wing',
    },
  ];
  positionOptions[19] = [
    {
      code: 'Lead',
      name: 'Lead',
    },
    {
      code: 'Squad',
      name: 'Squad',
    },
  ];
  positionOptions[20] = [
    {
      code: 'LHP',
      name: 'Loose-head prop',
    },
    {
      code: 'H',
      name: 'Hooker',
    },
    {
      code: 'THP',
      name: 'Tight-head prop',
    },
    {
      code: 'L',
      name: 'Lock',
    },
    {
      code: 'FL',
      name: 'Flank(openside & blindside)',
    },
    {
      code: '8M',
      name: '8th man',
    },
    {
      code: 'SH',
      name: 'Scrum-half',
    },
    {
      code: 'FH',
      name: 'Fly-half',
    },
    {
      code: 'LW',
      name: 'Left Wing',
    },
    {
      code: 'IC',
      name: 'Inside centre',
    },
    {
      code: 'OC',
      name: 'Outside centre',
    },
    {
      code: 'RW',
      name: 'Right Wing',
    },
    {
      code: 'FB',
      name: 'Full back',
    },
  ];
  positionOptions[21] = [
    {
      code: 'GK',
      name: 'Goal Keeper',
    },
    {
      code: 'GD',
      name: 'Goal Defence',
    },
    {
      code: 'WD',
      name: 'Wing Defence',
    },
    {
      code: 'C',
      name: 'Centre',
    },
    {
      code: 'WA',
      name: 'Wing Attack',
    },
    {
      code: 'GA',
      name: 'Goal Attack',
    },
    {
      code: 'GS',
      name: 'Goal Shooter',
    },
  ];
  const options = [];
  if (sportId) {
    if (positionOptions.length > 0) {
      positionOptions[sportId].forEach((y) => {
        options.push({
          value: `${y.code}`,
          name: `${y.name}`,
        });
      });
    }
  }
  return options;
};

export const batThrow = [
  {name: "Left" , value: "L"},
  {name: "Right" , value: "R"},
  {name: "Switch" , value: "S"}
];


// Used by Custom Form Components for unit conversions

export const convertStoredOuncesToPounds = (ounces) => {
  const pounds = Number(ounces) / 16;
  return {
    pounds,
  };
};

export const convertPoundsToOunces = (pounds) => {
  const newOunces = (Number(pounds) * 16);
  return { ounces: newOunces };
};

export const convertStoredInches = (inches) => {
  const feet = Math.floor(Number(inches) / 12);
  const newInches = Number(inches) % 12;
  return {
    feet,
    inches: newInches,
  };
};

export const convertStoredMeter = (meter) => {
  const newMeter = Math.floor(Number(meter));
  const cm = Math.round((Number(meter) * 100) - (newMeter * 100));
  return {
    meter: newMeter,
    cm,
  };
};

export const convertStoredInchesToMetric = (inches) => {
  let newMeter = Number.isNaN(inches) ? null : Math.floor(convertInchesToMetre(inches));
  let cm = Number.isNaN(inches) ? null :
    customTOFixed(Math.abs(convertInchesToCm(inches) - (newMeter * 100)));
  if (cm >= 100) {
    newMeter += 1;
    cm -= 100;
  }
  return {
    meter: newMeter,
    cm,
  };
};


export const convertToInches = (feet, inches) => {
  const newInches = (Number(feet) * 12) + Number(inches);
  return { inches: newInches };
};

export const convertToMeter = (meter, cm) => {
  const newMeter = (Number(meter)) + (Number(cm) / 100);
  console.log(newMeter);
  return { meter: newMeter };
};

export const convertMeterToInches = meter => meter * 39.3701;

export const convertMetricToInches = (meter, cm) => {
  const newMeter = Number(meter) + (Number(cm) / 100);
  const inches = convertMeterToInches(newMeter);
  return { inches };
};


export const convertStoredSeconds = (seconds) => {
  let sec = Number(seconds);
  const maxAllowedValue = 3600; // 1 hour
  const minAllowedValue = 0; // can not have negative time
  if (sec > maxAllowedValue) {
    sec %= maxAllowedValue;
  }
  let minutes = Math.floor((sec % 3600) / 60);
  let newSeconds = (sec - (minutes * 60));

  if (minutes < minAllowedValue) {
    minutes = 0;
  }
  if (newSeconds < minAllowedValue) {
    newSeconds = 0;
  }
  return {
    minutes,
    seconds: newSeconds,
  };
};
export const convertToSeconds = (minutes, seconds) => {
  const newSeconds = (Number(minutes) * 60) + Number(seconds);
  return { seconds: newSeconds };
};

export const softballBaseballIdsArray = [2,4];
